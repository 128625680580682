function buildAirBookModifyRQ(flightModifyFormModel) {
  const modificationType = flightModifyFormModel.modificationType;
  const tripId = flightModifyFormModel.tripId;
  const tripAirItemId = flightModifyFormModel.tripAirItemId;
  const airItineraryRPH = flightModifyFormModel.airItineraryRPH;
  const recordLocator = flightModifyFormModel.recordLocator;
  const idContext = flightModifyFormModel.idContext;
  const servicesSelected = flightModifyFormModel.servicesSelected;
  const paymentDetails = flightModifyFormModel.paymentDetails;

  let offerPriced = [];
  if (servicesSelected && servicesSelected.length > 0) {
    for (const serviceSelected of servicesSelected) {
      const quantity = serviceSelected.quantity;
      const seatRowNumber = serviceSelected.seatRowNumber;
      const seatColumnNumber = serviceSelected.seatColumnNumber;
      const flightSegmentRPH = serviceSelected.flightSegmentRPH;
      const travelerRPH = serviceSelected.travelerRPH;
      const ancillaryId = serviceSelected.ancillaryId;
      let pricing = null;
      if (quantity) {
        pricing = {
          offerQty: quantity,
        };
      }

      let seatInfo = null;
      if (seatColumnNumber && seatRowNumber) {
        seatInfo = {
          columnNumber: seatColumnNumber,
          rowNumber: seatRowNumber,
        };
      }

      offerPriced.push({
        id: ancillaryId,
        travelerRPH: travelerRPH,
        flightSegmentRPH: flightSegmentRPH,
        pricing: pricing,
        seatInfo: seatInfo,
      });
    }
  }

  let fulfillment = null;
  if (paymentDetails && paymentDetails.paymentType === "CASH") {
    fulfillment = {
      paymentDetailList: [
        {
          paymentType: "CASH",
        },
      ],
    };
  } else if (paymentDetails && paymentDetails.paymentType === "CREDITCARD") {
    const cardCode = paymentDetails.cardCode;
    const cardNumber = paymentDetails.cardNumber;
    const channelCode = paymentDetails.channelCode;
    const expireDate = paymentDetails.expireDate;
    const seriesCode = paymentDetails.seriesCode;

    fulfillment = {
      paymentDetailList: [
        {
          paymentType: "CREDITCARD",
          paymentCard: {
            cardCode: cardCode,
            cardNumber: cardNumber,
            expireDate: expireDate + "-01",
            seriesCode: {
              encryptionValue: seriesCode,
            },
            threeDomainSecurity: {
              gateway: {
                channelCode: channelCode,
              },
            },
          },
        },
      ],
    };
  }

  let airItinerary = null;
  if (airItineraryRPH) {
    airItinerary = { airItineraryRPH: airItineraryRPH };
  }

  return {
    airBookModify: {
      modificationType: modificationType,
      offer: {
        priced: offerPriced,
      },
      airItinerary: airItinerary,
    },
    airReservation: {
      bookingReferenceIdList: [
        {
          type: "RECORD_LOCATOR",
          id: recordLocator,
          id_context: idContext,
        },
        {
          type: "BOOKING_ID",
          id: tripId,
          id_context: "ERP",
        },
        {
          type: "BOOKING_AIR_ITEM_ID",
          id: tripAirItemId,
          id_context: "ERP",
        },
      ],
      fulfillment: fulfillment,
    },
  };
}

export default {
  buildAirBookModifyRQ,
};

<script setup>
import { reactive, ref, watchEffect } from "vue";
import BaseActivityPassengerFormChildItem from "@/views/v2/sales/activity/items/form/BaseActivityPassengerFormChildItem.vue";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  activityFormModel: {},
  childAgesValidCheck: {},
});

let activityFormModel = reactive(props.activityFormModel);
let passengerLabelShow = ref("");
const isRoomPassengersFormCorrectRef = ref(true);
//region 数据初始化
watchEffect(() => {
  if (activityFormModel) {
    let adultNum = activityFormModel.adultNum;
    let seniorNum = activityFormModel.seniorNum;
    let childNum = activityFormModel.childNum;
    passengerLabelShow.value =
      adultNum +
      " adult(s) , " +
      seniorNum +
      " senior(s) ," +
      childNum +
      " child(s)";
  }
});
//endregion
//当用户未选择儿童岁数时，要主动校验，让输入框显示错误
watchEffect(() => {
  let formElement = document.getElementById("roomPassengerForm");
  if (props.childAgesValidCheck && formElement) {
    //展開
    formElement.classList.add("show");
  } else if (!props.childAgesValidCheck && formElement) {
    formElement.classList.remove("show");
  }
});
//endregion 页面vue监听事件
//region 辅助函数
function increaseChild() {
  activityFormModel.childAges.splice(activityFormModel.childAges.length - 1, 1);
  activityFormModel.childNum--;
}

function decreaseChild() {
  activityFormModel.childNum++;
  activityFormModel.childAges.push({ age: null });
}

//提交房间信息表单
const v = useVuelidate();

async function submitRoomPassengersForm() {
  //在父组件中调用，则会校验所有子组件的规则，并统一返回校验结果
  isRoomPassengersFormCorrectRef.value = await v.value.$validate();
  if (!isRoomPassengersFormCorrectRef.value) return;
  let formElement = document.getElementById("roomPassengerForm");
  formElement.classList.remove("show");
}

//endregion
</script>

<template>
  <div class="search_boxed dropdown_passenger_area" v-if="activityFormModel">
    <p>{{ $t("travelers") }}</p>
    <div class="dropdown">
      <button
        class="dropdown-toggle final-count"
        data-toggle="dropdown"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
      >
        {{ passengerLabelShow }}
      </button>

      <div
        class="dropdown-menu dropdown_passenger_info fs-sm dropdown-menu-400"
        aria-labelledby="dropdownMenuButton1"
        id="roomPassengerForm"
      >
        <div class="traveller-calulate-persons">
          <div class="passengers">
            <form class="p-2" @submit.prevent>
              <div class="passenger-form">
                <div class="mb-4 row">
                  <div class="col-7">
                    <label class="form-label" for="dropdown-content-form-adults"
                      >{{ $t("adult") }}(26-59)</label
                    >
                  </div>
                  <div class="col-5 number-choice">
                    <div class="passenger_operator">
                      <button
                        class="btn btn_md"
                        :class="{
                          disabled: activityFormModel.adultNum <= 1,
                        }"
                        @click="activityFormModel.adultNum--"
                      >
                        -
                      </button>
                    </div>
                    <input
                      type="text"
                      :value="activityFormModel.adultNum"
                      class="text-center"
                      placeholder="0"
                      id="dropdown-content-form-adults"
                      name="dropdown-content-form-adults"
                    />
                    <div class="passenger_operator">
                      <button
                        class="btn btn_md"
                        @click="activityFormModel.adultNum++"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mb-4 row">
                  <div class="col-7">
                    <label class="form-label" for="dropdown-content-form-adults"
                      >{{ $t("senior") }}(60+)</label
                    >
                  </div>
                  <div class="col-5 number-choice">
                    <div class="passenger_operator">
                      <button
                        class="btn btn_md"
                        :class="{
                          disabled: activityFormModel.seniorNum <= 0,
                        }"
                        @click="activityFormModel.seniorNum--"
                      >
                        -
                      </button>
                    </div>
                    <input
                      type="text"
                      :value="activityFormModel.seniorNum"
                      class="text-center"
                      placeholder="0"
                      id="dropdown-content-form-adults"
                      name="dropdown-content-form-adults"
                    />
                    <div class="passenger_operator">
                      <button
                        class="btn btn_md"
                        @click="activityFormModel.seniorNum++"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mb-4 row">
                  <div class="col-7">
                    <label class="form-label" for="dropdown-content-form-adults"
                      >{{ $t("youth-child") }}(0-25)</label
                    >
                  </div>
                  <div class="col-5 number-choice">
                    <div class="passenger_operator">
                      <button
                        class="btn btn_md"
                        :class="{
                          disabled: activityFormModel.childNum <= 0,
                        }"
                        @click="increaseChild"
                      >
                        -
                      </button>
                    </div>
                    <input
                      type="text"
                      :value="activityFormModel.childNum"
                      class="text-center"
                      placeholder="0"
                      id="dropdown-content-form-adults"
                      name="dropdown-content-form-adults"
                    />
                    <div class="passenger_operator">
                      <button class="btn btn_md" @click="decreaseChild">
                        +
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mb-4 row">
                  <BaseActivityPassengerFormChildItem
                    :child-age="childAge"
                    :child-index="childIndex"
                    :key="childIndex"
                    v-for="(
                      childAge, childIndex
                    ) in activityFormModel.childAges"
                  />
                </div>
              </div>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn col-11"
                  @click="submitRoomPassengersForm"
                >
                  {{ $t("done") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <span>
      {{
        parseInt(activityFormModel.adultNum) +
        parseInt(activityFormModel.seniorNum) +
        parseInt(activityFormModel.childNum)
      }}
      {{ $t("traveler") }}(s)</span
    >
  </div>
</template>

<style lang="scss" scoped></style>

<script setup>
import { useRoute, useRouter } from "vue-router";
import CarRentalAvailForm from "@/views/v2/sales/carrental/items/form/availability/CarRentalAvailForm.vue";
import { onMounted, ref, watch } from "vue";
import { CarRentalItemsSortFormModel } from "@/formmodel/sales/carrental/availablility/carRentalItemsSortFormModel";
import { CarRentalItemsFilterFormModel } from "@/formmodel/sales/carrental/availablility/carRentalItemsFilterFormModel";
import dateUtils from "@/utils/dateUtils";
import { useCarRentalAvailabilityStore } from "@/stores/v2/carrental/availablility/carRentalAvailabilityStore";
import { Empty, Spin } from "ant-design-vue";
import CarRentalItem from "@/views/v2/sales/carrental/items/group/CarRentalItem.vue";
import CarRentalResultsSortAndFilterForm from "@/views/v2/sales/carrental/items/form/availability/CarRentalResultsSortAndFilterForm.vue";
import { useCmsContentStore } from "@/stores/cms";

const AEmpty = Empty;
const ASpin = Spin;

const router = useRouter();
const route = useRoute();
const carRentalAvailStore = useCarRentalAvailabilityStore();

const carRentalItemsShowRef = ref([]);
const currentPageRef = ref(1); //每次load more添加一页显示
const isLoadMoreRef = ref(true);
const daysApartRef = ref(1); //租期

watch(
  () => carRentalAvailStore.carRentalItemsShow,
  (newValue) => {
    if (newValue) {
      carRentalItemsShowRef.value = newValue;
    } else {
      carRentalItemsShowRef.value = [];
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => carRentalAvailStore.carRentalAvailFormModel,
  (newValue) => {
    if (newValue) {
      let pickUpDateTime = newValue.pickupDatetime;
      let returnDateTime = newValue.returnDatetime;

      daysApartRef.value = dateUtils.getDaysByDateRange(
        pickUpDateTime,
        returnDateTime
      );
    }
  },
  { immediate: true, deep: true }
);

//过滤排序相关
const carRentalItemsSortFormModelRef = ref(
  new CarRentalItemsSortFormModel("PRICE", "ASC")
);
const carRentalItemsFilterFormModelRef = ref(
  new CarRentalItemsFilterFormModel("", [], [])
);

//过滤排序总入口
function sortAndFilter(sortFormModel, filterFormModel) {
  carRentalAvailStore.changeCarRentalItemsShow(
    null,
    null,
    filterFormModel,
    sortFormModel
  );
}

//endregion

//region 辅助函数

//-------------酒店数据滑动到底部自动加载---------------------
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

function handleScroll() {
  /*
   * document.documentElement.scrollHeight:
   * document.documentElement.scrollTop
   * document.documentElement.clientHeight
   * */
  const distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;
  if (distance > -10 && distance < 10) {
    loadMoreItems();
  }
}

function loadMoreItems() {
  if (carRentalItemsShowRef.value.length >= carRentalAvailStore.totalResult) {
    isLoadMoreRef.value = false;
  }
  currentPageRef.value += 1;
  carRentalAvailStore.changeCarRentalItemsShow(currentPageRef.value, true);
}

//------------酒店数据滑动到底部自动加载-----------------------

function selectVehicle(carClassCode) {
  const query = route.query;
  query.preferredCarType = carClassCode;
  const routeUrl = router.resolve({
    name: "sales-carrental-extras-v2",
    query: query,
  });
  window.open(routeUrl.href, "_blank");

  // router.push({ name: "sales-carrental-extras-v2", query: query });
}

//endregion

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.CarRentalBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.CarRentalBackgroundImage
            .url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>Car Rental List</h2>
        <ul>
          <li class="pre-page" @click="router.push({ name: 'home' })">
            {{ $t("home") }}
          </li>

          <li>
            <i class="fas fa-circle"></i>
            Car Rental List
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="theme_search_form_tour">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!--搜索表单 -->
          <CarRentalAvailForm :jumpable="false" />
          <!--搜索表单-->
        </div>
      </div>
    </div>
  </section>

  <section class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <CarRentalResultsSortAndFilterForm
            :car-rental-filter-form-model="carRentalItemsFilterFormModelRef"
            :car-rental-items-length="
              carRentalAvailStore.carRentalItems
                ? carRentalAvailStore.carRentalItems.length
                : 0
            "
            :car-rental-sort-form-model="carRentalItemsSortFormModelRef"
            @sortAndFilter="sortAndFilter"
          />
        </div>

        <div class="col-lg-9">
          <div
            class="row"
            v-if="carRentalItemsShowRef && carRentalItemsShowRef.length > 0"
          >
            <div
              v-for="(
                carRentalItem, carRentalItemIndex
              ) in carRentalItemsShowRef"
              :key="carRentalItemIndex"
            >
              <CarRentalItem
                :car-rental-item-index="carRentalItemIndex"
                :car-rental-item="carRentalItem"
                :days-apart="daysApartRef"
                @selectVehicle="selectVehicle"
              />
            </div>
            <div
              class="text-center"
              v-if="
                isLoadMoreRef &&
                carRentalAvailStore.carRentalItems &&
                carRentalAvailStore.carRentalItems.length > 0 &&
                carRentalAvailStore.carRentalItems.length >
                  carRentalAvailStore.limit &&
                carRentalItemsShowRef > carRentalAvailStore.limit
              "
            >
              <Spin></Spin>
              <span class="mg-l-8 text-primary">{{ $t("load-more") }}..</span>
            </div>
          </div>
          <div v-else>
            <AEmpty></AEmpty>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

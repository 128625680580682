export class HotelPhotoVO {
    /**
     *
     * @param type string，图片类型，hotel为酒店图片，room为房间内图片
     * @param url string，图片路径
     * @param caption string，图片说明，可以为房型代码
     */
    constructor(type, url, caption) {
        this.type = type;
        this.url = url;
        this.caption = caption;
    }
}
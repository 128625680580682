const messages = {
    en: {
        'ZY': 'Ada Air',
        'ZX': 'Air British Columbia',
        'ZW': 'Air Wisconsin Inc',
        'ZV': 'Air Midwest Inc',
        'ZU': 'Helios Airways',
        'ZT': 'Air Zambezi',
        'ZS': 'Azzurra Air',
        'ZR': 'Muk Air',
        'ZQ': 'Caribbean Sun Airlines',
        'ZP': 'Silk Way',
        'ZO': 'Great Plains Airlines',
        'ZN': 'Eagle Air',
        'ZM': 'Alliance Express Rwanda',
        'ZL': 'Regional Express',
        'ZK': 'Great Lakes',
        'ZJ': 'Zambezi Airlines',
        'ZI': 'Aigle Azur',
        'ZH': 'Shenzhen Airlines',
        'ZG': 'Viva Macau Limited',
        'ZF': 'Athens Airways',
        'ZE': 'Lineas Aereas Azteca S.A. de C.V',
        'ZD': 'Dolphin Air',
        'ZC': 'Royal Swazi National',
        'ZB': 'Monarch Airlines',
        'ZA': 'Astair Limited Company',
        'YZ': 'Tacaecuador S.A.',
        'YY': 'All airlines',
        'Z9': 'Aero Zambia',
        'YX': 'Midwest Express Airlines',
        'Z8': 'Amaszonas SA',
        'YW': 'Air Nostrum',
        'Z7': 'ADC Airlines',
        'YV': 'Mesa Airlines',
        'Z6': 'Dnieproavia Joint Stock Aviation Company',
        'YU': 'Aerolineas Dominicanas S.A. Domina',
        'Z5': 'GMG Airlines',
        'YT': 'Silverjet',
        'Z4': 'Zoom Airlines Inc',
        'YS': 'Regional CAE',
        'Z3': 'Promech Inc',
        'YR': 'Scenic Air Lines Inc',
        'Z2': 'Zest Airways',
        'YQ': 'Aircompany Polet',
        'YP': 'Aero Lloyd',
        'YO': 'Heli Air Monaco',
        'YN': 'Air Creebec',
        'YM': 'Montenegro Airlines',
        'YL': 'Yamal Airlines',
        'YK': 'Kibris Turkish Airlines',
        'YJ': 'Amiyi Airlines',
        'YI': 'Air Sunshine',
        'YH': 'West Caribbean Airways',
        'YG': 'South Airlines',
        'YE': 'Yanda Airlines',
        'YD': 'Mauritania Airways',
        'YC': 'Yamal Airlines',
        'YB': 'South African Express Airlines',
        'XZ': 'Flugfelag Austurlands',
        'XY': 'National Air Services',
        'Y9': 'Kish Airlines',
        'Y8': 'Yangtze River Express',
        'XW': 'Sky Express',
        'Y7': 'Silverjet',
        'XV': 'Waltair I Linkoping AB',
        'Y6': 'Cambodia Airlines',
        'XU': 'African Express Airways',
        'Y5': 'Asia Wings',
        'XT': 'Skystar Airways',
        'Y4': 'Eagle Aviation',
        'XS': 'SITA-Airlines Worldwide',
        'Y3': 'Driessen Services Incorporated',
        'XR': 'Skywest Airlines',
        'Y2': 'Alliance Air',
        'XQ': 'Sun Express',
        'XP': 'Tahoe Air',
        'XO': 'China Xinjiang Airline',
        'XN': 'Air Nepal',
        'XM': 'Alitalia Express',
        'XL': 'Proair',
        'XK': 'CCM Airlines',
        'XJ': 'Mesaba Aviation',
        'XI': 'Aeronautical Telecommunications Limited',
        'XH': 'Hndlg Angent',
        'XG': 'Air Mali International',
        'XF': 'Vladivostok Air',
        'XE': 'ExpressJet',
        'XD': 'Airlines Reporiting Corporation',
        'XB': 'Internatioanl Air Transport Association',
        'XA': 'ARINC Aeronautical Radio',
        'WZ': 'West African Airlines',
        'WY': 'Oman Air',
        'X9': 'City Star Airlines',
        'WX': 'City Jet',
        'X8': 'Icaro',
        'WW': 'bmibaby',
        'X7': 'Air Service',
        'WV': 'Air South Air',
        'X6': 'Airlines Reporting Corporation',
        'WU': 'Tikal Jets SA',
        'X5': 'Afrique Airlines',
        'WT': 'Wasaya Airways',
        'WS': 'Westjet Airlines Ltd',
        'X3': 'TUIfly',
        'WR': 'Royal Tongan',
        'X2': 'Airlines Reporting Corporation',
        'WQ': 'Romavia',
        'WP': 'Island Air',
        'WO': 'World Airways',
        'WN': 'Southwest Airlines Dallas',
        'WM': 'Windward Islands Airways Intl',
        'WL': 'Aeroperlas',
        'WK': 'Edelweiss Air',
        'WJ': 'Air Labrador',
        'WI': 'U-Land Airlines',
        'WH': 'China N West',
        'WG': 'Wasaya Airways L.P.',
        'WF': 'Wideroes Flyveselskap A/S',
        'WE': 'Thai Smile',
        'WD': 'Halisa Air',
        'WC': 'Islena Airlines',
        'WB': 'Rwandair Express',
        'WA': 'Klm Cityhoppe',
        'VZ': 'MyTravel Airways Limited',
        'VY': 'Vueling Airlines SA',
        'W9': 'Air Bagan',
        'VX': 'Virgin America',
        'VW': 'Transportesaeromar',
        'W7': 'Sayakhat Airlines',
        'VV': 'Aerosvit Airlines',
        'W6': 'West Isle Air',
        'VU': 'Air Ivoire',
        'W5': 'Mahan Airlines',
        'VT': 'Air Tahiti',
        'W4': 'LC Busre',
        'VS': 'Virgin Atlantic Airways',
        'W3': 'Arik Air',
        'VR': 'Transportes Aereos De Cabo Verde',
        'W2': 'FlexFlight',
        'VQ': 'Viking Hellas Airlines',
        'VP': 'Vasp Brazilian Airlines',
        'VO': 'Tyrolean Air',
        'VN': 'Vietnam Airlines',
        'VM': 'Aerochaco',
        'VL': 'North Vancouver Air',
        'VK': 'Air Nigeria',
        'VJ': 'Royal Air Cambodge',
        'VI': 'Volga-Dnepr',
        'VH': 'Aeropostal',
        'VG': 'VLM Airlines',
        'VF': 'Valuair',
        'VE': 'Aerovias Venezulanas',
        'VD': 'T.A.T. European Airlines',
        'VC': 'Strategic Aviation',
        'VB': 'Maersk Air Lt',
        'VA': 'Virgin Australia',
        'UZ': 'Buraq Air Transport',
        'UY': 'Cameroon Airlines',
        'V9': 'Bashkir Airlines',
        'UX': 'Air Europa',
        'V8': 'Iliamna Air Taxi Inc',
        'UW': 'Universal Airlines',
        'V7': 'Air Senegal International',
        'UV': 'Helicopteros Del Sureste',
        'V6': 'Air Atlantique',
        'UU': 'Air Austral',
        'V5': 'VIP Wings Limited',
        'UT': 'Union de transports Aeriens',
        'V4': 'Vensecar International',
        'US': 'US Airways',
        'V3': 'Carpatair',
        'UR': 'Ukraine National Airlines',
        'V2': 'Vision Airlines',
        'UQ': 'OConnor\' Airlines',
        'UP': 'Bahamasair',
        'V0': 'Conviasa',
        'UO': 'Hong Kong Express',
        'UN': 'Transaero',
        'UM': 'Air Zimbabwe',
        'UL': 'Sri Lankan Airlines',
        'UK': 'Klm Uk',
        'UJ': 'Aerosanta Airlines',
        'UI': 'Eurocypria Airlines',
        'UH': 'US Helicopter Corp',
        'UG': 'Sevenair S.A.',
        'UF': 'Ukranian-Mediterranean Airlines',
        'UE': 'U Air',
        'UD': 'Hex Air',
        'UC': 'Linea Aerea Del Cobre',
        'UB': 'Myanma Airways',
        'UA': 'United Airlines',
        'TZ': 'American Trans Air',
        'TY': 'Air Caledonie',
        'U9': 'Tatarstan',
        'TX': 'Air Caraibes',
        'U8': 'Armavia',
        'TW': 'TWA Airlines',
        'U7': 'Air Uganda',
        'TV': 'Virgin Express',
        'U6': 'Ural Airlines',
        'TU': 'Tunis Air',
        'U5': 'USA 3000 Airlines',
        'TT': 'Tiger Airways Australia',
        'U4': 'Progress Multitrade',
        'TS': 'Air Transat A.T.',
        'U3': 'AS Avies',
        'TR': 'Tiger Airways',
        'U2': 'Easyjet Airline Company',
        'TQ': 'Tandem Aero',
        'TP': 'Tap Air Portugal',
        'U0': 'Sabre Airline',
        'TO': 'President Airlines',
        'TN': 'Air Tahiti Nui',
        'TM': 'Lam Linhas Aereas De Mocambique',
        'TL': 'AirNorth Regional',
        'TK': 'Turkish Airlines',
        'TJ': 'Transportes Aereos Nacionales de Selva',
        'TI': 'Air Continental Africa',
        'TH': 'Transmile Air Services',
        'TG': 'Thai Airways International Limited',
        'TF': 'Malmo Aviation',
        'TE': 'FlyLal',
        'TD': 'Atlantis European Airways',
        'TC': 'Air Tanzania Corp',
        'TB': 'Jetairfly.com',
        'TA': 'Taca International Airlines',
        'SZ': 'Air South West Limited',
        'SY': 'Sun Country Airlines',
        'T9': 'Varmlandsflyg',
        'SX': 'Aeroejecutivo S.A. de C.V.',
        'T8': 'STA Trans African Air',
        'SW': 'Air Namibia',
        'T7': 'Twin Jet',
        'SV': 'Saudi Arabian Airlines',
        'T6': 'Tavrey Aircompany',
        'SU': 'Aeroflot Russian International Airlines',
        'T5': 'Turkmenistan Airlines',
        'ST': 'Yanda Airlines',
        'T4': 'Trip Linhas Aereas',
        'SS': 'Corsair International',
        'T3': 'Eastern Airways',
        'SR': 'Swiss Air Transport Co Ltd',
        'T2': 'Nakima Air Service',
        'SQ': 'Singapore Airlines',
        'SP': 'Sociedade Acoreana De Transportes',
        'T0': 'TACA Peru',
        'SO': 'Salso D Haiti',
        'SN': 'Brussels Airlines',
        'SM': 'Swedline Express',
        'SL': 'Rio Sul Servicos Aereos Regionais',
        'SK': 'Scandinavian Airlines',
        'SJ': 'Aviandina S.A.C.',
        'SI': 'Skynet Airlines',
        'SH': 'Air Toulouse International',
        'SG': 'SpiceJet',
        'SF': 'Tassili Airlines',
        'SE': 'XL Airways France',
        'SD': 'Sudan Airways',
        'SC': 'Shandong Airlines',
        'SB': 'Air Caledonie International',
        'SA': 'South African Airways',
        'RZ': 'SANSA',
        'RY': 'European Executive Express',
        'S9': 'SWE Aviation Europe',
        'RX': 'Aviaexpress Airlines',
        'S8': 'Estonian Aviation Company',
        'RW': 'Republic Airline Inc',
        'S7': 'S7 Airlines',
        'RV': 'Caspian Airlines',
        'S6': 'Sun Air',
        'RU': 'TCI Skyking',
        'S5': 'Shuttle America',
        'RT': 'RAK Airways',
        'S4': 'Sata International',
        'RS': 'Royal Flight of Oman',
        'S3': 'Santa Barbara Airlines',
        'RR': 'Royal Air Force-38 Transport Group',
        'S2': 'Jet Lite',
        'RQ': 'Kam Air',
        'S1': 'Lufthansa System AG',
        'RP': 'Chantangua Airlines',
        'S0': 'Slok Air International',
        'RO': 'Tarom Romanian',
        'RN': 'Paradise Air / Sum Air',
        'RM': 'Air Moldova I',
        'RL': 'Royal Falcon Airlines',
        'RK': 'Regional Airlines',
        'RJ': 'Royal Jordanian',
        'RI': 'P.T.Mandala Airlines',
        'RH': 'Regionair',
        'RG': 'Varig Brazilian Airlines',
        'RF': 'ORD Air Charter',
        'RE': 'Aer Arann Express',
        'RD': 'Alitalia Team',
        'RC': 'Atlantic Airways Faroe Islands',
        'RB': 'Syrian Arab Airlines',
        'RA': 'Royal Nepal Airlines',
        'QZ': 'Indonesia AirAsia',
        'QY': 'European Air Transport',
        'R9': 'Camai Air',
        'QX': 'Horizon Air',
        'R8': 'Kyrghyzstan Airlines',
        'QW': 'Blue Wings AG',
        'R7': 'Aserco Airlines',
        'QV': 'Lao Airlines',
        'R6': 'Air Srpska',
        'QU': 'Airline Utair Ukraine',
        'R5': 'Malta Air Charter',
        'QT': 'Regional Air',
        'R4': 'The State Transport Company Russia',
        'QS': 'Smartwings',
        'R3': 'Yakutia Airlines',
        'QR': 'Qatar Airways',
        'R2': 'Orenair',
        'QQ': 'Alliance Airlines',
        'QP': 'Regional Air',
        'R0': 'Royal Airlines',
        'QO': 'Origin Pacific Airways',
        'QN': 'Air Armenia',
        'QM': 'Air Malawi',
        'QL': 'Aero Lanka',
        'QK': 'Air Canada Jazz',
        'QJ': 'Latpass Airlines',
        'QI': 'Cimber Sterling',
        'QH': 'Altyn Air Airlines',
        'QG': 'Delta Air Transport',
        'QF': 'Qantas Airways',
        'QE': 'CrossAir Europe',
        'QD': 'Air Class Lineas Aereas',
        'QC': 'Cameroon Arilines',
        'QB': 'Georgian National Airlines',
        'QA': 'Click Mexicana',
        'PZ': 'Transportes Aereos Del Mercosur',
        'PY': 'Surinam Airways Ltd',
        'Q9': 'Afrinat International Airlines',
        'PX': 'Air Niugini',
        'Q8': 'Trans Air Congo',
        'PW': 'Precision Air',
        'Q7': 'Sobelair',
        'PV': 'Saint Barth Commuter',
        'Q6': 'Skytrans',
        'PU': 'Primeras Lineas Uruguayas',
        'Q5': '40 Mile Air Ltd',
        'PT': 'West Air Sweden',
        'Q4': 'Swazi Express Airways',
        'PS': 'Ukraine International Airlines',
        'Q3': 'Zambian Airways',
        'PR': 'Philippine Airlines',
        'Q2': 'Island Aviation',
        'PQ': 'Panafrican Airways',
        'PP': 'Jet Aviation Business Jets AG',
        'PO': 'Polar Air Cargo',
        'PN': 'China West Air',
        'PM': 'Tropic Air',
        'PL': 'Aeroperu Resa de Transportes',
        'PK': 'Pakistan International Airlines',
        'PJ': 'Air Saint-Pierre',
        'PI': 'Sol Paraguay',
        'PH': 'Polynesian Airlines Ltd',
        'PG': 'Bangkok Airways',
        'PF': 'Palestinian Airlines',
        'PE': 'Air Europe',
        'PD': 'Porter Airlines',
        'PC': 'Pegasus Airlines',
        'PB': 'Provincial Airlines',
        'PA': 'Florida Costal Airlines',
        'OZ': 'Asiana Airlines',
        'OY': 'Andes Lineas Aereas S.A.',
        'P9': 'Peruvian Air Lines',
        'OX': 'Orient Thai',
        'P8': 'Pantanal Linhas Aereas Sul',
        'OW': 'Executive Airlines/American Eagle',
        'P7': 'East Line Airlines',
        'OV': 'Estonian Air',
        'P6': 'Trans Air',
        'OU': 'Croatia Airlines',
        'P5': 'Aerorepublica',
        'OT': 'Aeropelican Air Services',
        'P4': 'Aero Lineas Sosa',
        'OS': 'Austrian Airlines',
        'P3': 'Passaredo Linhas Aereas',
        'OR': 'TUI Airlines Netherland',
        'P2': 'TyumenaviaTrans',
        'OQ': 'Sunrise Airlines',
        'P1': 'Public Charters',
        'OP': 'Chalk\'s Ocean Airways',
        'P0': 'Proflight Commuter Services',
        'OO': 'Skywest Air',
        'ON': 'Our Airline',
        'OM': 'Miat Mongolian Airlines',
        'OL': 'Ostfriesische Lufttransport Gmbh',
        'OK': 'Czech Airline',
        'OJ': 'Overland Airways Ltd.',
        'OI': 'Aspiring Air',
        'OH': 'Comair Inc',
        'OG': 'One Two Go Airlines',
        'OF': 'Air Finland Ltd',
        'OE': 'Asia Overnight Express',
        'OD': 'Zuliana De Aviacion',
        'OC': 'Omni',
        'OB': 'BOA',
        'OA': 'Olympic Airways',
        'NZ': 'Air New Zealand',
        'NY': 'Air Iceland',
        'NX': 'Air Macau Company Limited',
        'O8': 'Oasis Hong Kong Airlines',
        'NW': 'Northwest Airlines',
        'O7': 'OzJet',
        'NV': 'Northwest Territorial Airways',
        'O6': 'OceanAir Linhas Aereas',
        'NU': 'Japan Transocean',
        'O5': 'Orange Air Sierra Leone',
        'NT': 'Binter Canarias',
        'O4': 'Antrak Air',
        'NS': 'Silk Route Airways',
        'NR': 'Pamir Air',
        'O2': 'Jet Air Spolka Z.O.O.',
        'NQ': 'Air Japan',
        'NP': 'Nile Air',
        'NO': 'Aus Air',
        'NN': 'VIM Airlines',
        'NM': 'Mount Cook Airlines',
        'NL': 'Shaheen Air International',
        'NK': 'Spirit Airlines',
        'NJ': 'Vanguard Airlines',
        'NI': 'Portugalia',
        'NH': 'All Nippon Airways',
        'NG': 'Lauda Air',
        'NF': 'Air Vanuatu',
        'NE': 'Skyeurope Airlines',
        'ND': 'Airlink Limited',
        'NC': 'National Jet Systems Pty',
        'NB': 'Sterling Airlines',
        'NA': 'North American Airlines',
        'MZ': 'Merpati Nusantara Dba Air Mali',
        'MY': 'Midwest Airlines (Egypt)',
        'N9': 'Niger Air Continental',
        'MX': 'Mexicana Airlines',
        'N8': 'CR Airways',
        'MW': 'Mokulele Airlines',
        'N7': 'Lagun Air',
        'MV': 'Manus Air',
        'N6': 'Nuevo Continente S.A.',
        'MU': 'China Eastern Airlines',
        'N5': 'Norfolk Air',
        'MT': 'Great Western Aviation',
        'N4': 'Minerva Airlines',
        'MS': 'Egyptair',
        'N3': 'Omskavia Airline',
        'MR': 'Air Mauritanie',
        'N2': 'Daghestan Airlines',
        'MQ': 'American Eagle',
        'MP': 'Martinair',
        'MO': 'Calm Air International Limited',
        'MN': 'Comair Ltd.',
        'MM': 'SAM',
        'ML': 'African Transport Trading And Investment Co.',
        'MK': 'Air Mauritius',
        'MJ': 'Mihin Lanka PVT Ltd',
        'MI': 'Silkair',
        'MH': 'Malaysia Airlines',
        'MG': 'Champion Air',
        'MF': 'Xiamen Airlines',
        'ME': 'Middle East Airlines',
        'MD': 'Air Madagascar',
        'MC': 'Military Air',
        'MB': 'MNG Airlines',
        'MA': 'Malev Hungarian Airlines',
        'LZ': 'Belle Air',
        'LY': 'El Al Israel Airlines',
        'M9': 'Motor-Sich JSC',
        'LX': 'Swiss International Air Lines',
        'M8': 'Mekong Airlines',
        'LW': 'Pacific Wings',
        'M7': 'Marsland Aviation',
        'LV': 'Albanian Airlines',
        'M6': 'AmeriJet International',
        'LU': 'Lan Express',
        'M5': 'Kenmore Air',
        'LT': 'LTU Intnl Airways',
        'M4': 'Avioimpex A.D.P.O.',
        'LS': 'Jet2.com Limited',
        'M3': 'Air Norway',
        'LR': 'Lineas Aereas Costarricenses',
        'M2': 'Mahfooz Aviation',
        'LQ': 'Lebanese Air Transport',
        'M1': 'Markair Express',
        'LP': 'LanPeru',
        'LO': 'Lot Polish Airlines',
        'LN': 'Libyan Airlines',
        'LM': 'Livington SPA',
        'LL': 'Allegro',
        'LK': 'Air Luxor',
        'LJ': 'Jin Air Limited',
        'LI': 'Liat',
        'LH': 'Lufthansa',
        'LG': 'Luxair',
        'LF': 'FlyNordic',
        'LE': 'Efly',
        'K0': 'Worldwide Air',
        'LD': 'Air Hong Kong',
        'LC': 'Legend Airlines, Inc.',
        'LB': 'Boliviano',
        'LA': 'Lanchile Airlines',
        'KZ': 'Nippon Cargo Airlines',
        'KY': 'Air Sao Tome e Principe',
        'L9': 'Awsaj Aviation Services',
        'KX': 'Cayman Airways Ltd',
        'L8': 'Air Luxor GB LDA',
        'KW': 'Wataniya Airways',
        'L7': 'Laoag International Airlines',
        'KV': 'Kavminvodyavi',
        'L6': 'Mauritanian Airlines International',
        'KU': 'Kuwait Airways Corporation',
        'L5': 'Helikopter Service',
        'KT': 'Kyrgyz Air',
        'L4': 'Lauda-air S.p.A.',
        'KS': 'Penair',
        'L3': 'Lynx Aviation',
        'KR': 'MDA Airlines',
        'L2': 'Lynden Air',
        'KQ': 'Kenya Airways',
        'KP': 'Asky',
        'KO': 'Alaska Central Express',
        'KN': 'China United Airlines',
        'KM': 'Air Malta',
        'KL': 'Klm Royal Dutch Airlines',
        'KK': 'Atlasjet',
        'KJ': 'British Mediterranean',
        'KI': 'Adam SkyConnection Airlines PT',
        'KH': 'Kyrgyz Airways',
        'KG': 'Aerogaviota S.A.',
        'KF': 'Blue 1',
        'KE': 'Korean Air',
        'KD': 'Kendell Airlines',
        'KC': 'Air Astana',
        'KB': 'Druk Air',
        'KA': 'Dragonair',
        'JZ': 'Skyways Ab',
        'JY': 'Air Turks and Caicos',
        'JX': 'Nice Helicopters',
        'K9': 'Skyward Aviation Ltd',
        'JW': 'Skippers Aviation',
        'K8': 'Zambia Skyways Limited',
        'JV': 'Bearskin Airlines',
        'K7': 'Sakha Avia',
        'JU': 'Jat Airways',
        'K6': 'Cambodia Angkor Air\n',
        'JT': 'Lion Air',
        'K5': 'Seaport Airlines',
        'JS': 'Air Koryo',
        'K4': 'Kronflyg',
        'JR': 'Aero California',
        'K3': 'Taquan Air Services',
        'JQ': 'Jetstar Airways',
        'K2': 'Eurolot S.A.',
        'JP': 'Adria Airways',
        'K1': 'Topas',
        'JO': 'JALways',
        'JN': 'Excel Airways',
        'JM': 'Air Jamaica Ltd',
        'JL': 'Japan Airlines',
        'JK': 'Spanair',
        'JJ': 'TAM Linhas Aereas',
        'JI': 'Jade Cargo International',
        'JH': 'Nordeste-Linhas Aereas Regionais Sa',
        'JG': 'Air Greece-Aerodromisis S.A.',
        'JF': 'Lab/Flying Service Inc',
        'JE': 'Manx Airlines',
        'JD': 'Deer Jet',
        'JC': 'JAL Express',
        'JB': 'Helijet International Inc.',
        'JA': 'B & H Airlines',
        'IZ': 'Arkia Israel Airlines',
        'IY': 'Yemenia Yemen Airways',
        'IX': 'Air India Express',
        'J9': 'Guinee Airlines',
        'IW': 'Wings Air',
        'J8': 'Berjaya Air',
        'IV': 'Wind Jet',
        'J7': 'JMC Airlines Ltd',
        'IU': 'Hevi Lift PNG PTY Ltd.',
        'J6': 'Larrys Flying',
        'IT': 'Kingfisher Airlines',
        'J5': 'Donghai Airlines',
        'IS': 'Island Airlines Inc',
        'J4': 'Buffalo Air',
        'IR': 'Iran National Airlines Corporation',
        'J3': 'Northwestern Air Lease',
        'IQ': 'Augsburg Airways',
        'J2': 'Azerbaijan Airlines',
        'IP': 'Atyrau Airways',
        'IO': 'Indonesian Airlines',
        'J0': 'Jet Link Express',
        'IN': 'Mat Macedonia',
        'IM': 'Menajet',
        'IL': 'Istanbul Airlines',
        'IK': 'Imair',
        'IJ': 'Great Wall Airlines',
        'II': 'Business Air',
        'IH': 'Falcon Air',
        'IG': 'Meridiana Fly S.P.A',
        'IF': 'Islas Airways',
        'IE': 'Solomon Airlines',
        'ID': 'Interlink Airlines PTY Ltd.',
        'IC': 'Indian Airlines',
        'IB': 'Iberia',
        'IA': 'Iraqi Airways',
        'HZ': 'Sakhalinskie Aviatrassy',
        'HY': 'Uzbekistan Airway',
        'HX': 'Hong Kong Airlines',
        'I9': 'Air Italy S.P.A',
        'HW': 'Iceland Express',
        'HV': 'Transavia Airlines',
        'I7': 'Paramount Airways',
        'HU': 'Hainan Airlines',
        'I6': 'Sky Eyes',
        'HT': 'Hellenic Imperial Airway',
        'I5': 'Air Mali',
        'HS': 'Direktflyg',
        'I4': 'Rutair Ltd DBA Airlink',
        'HR': 'Hahn Air',
        'HQ': 'Harmony Airways',
        'HP': 'America West Airlines',
        'I1': 'Eyeone',
        'HO': 'Juneyao Airlines',
        'HN': 'Proteus Helicopteres',
        'HM': 'Air Seychelles',
        'HK': 'Yangon Airways',
        'HJ': 'Hellas Jet SA',
        'HI': 'Papillon Airways',
        'HH': 'Islandsflug',
        'HG': 'Harbor Airlines Inc',
        'HF': 'Hapag Lloyd',
        'HE': 'Lgw Luftfahrtgesellschaft',
        'HD': 'Hokkaido International Airlines',
        'HC': 'Aero-Tropics Air Services',
        'HB': 'Harbor Airlines',
        'HA': 'Hawaiian Airlines Inc',
        'GZ': 'Air Rarotonga',
        'GY': 'Gabon Airlines',
        'GX': 'Pacificair',
        'H9': 'Pegasus',
        'GW': 'Air Lines Of Kuban',
        'H8': 'Dalavia-Far East Airways Khabarovsk',
        'GV': 'Riga Airlines',
        'H7': 'Eagle Aviation Uganda',
        'GU': 'Aviateca S A',
        'H6': 'Hageland',
        'GT': 'Gibraltar Airways Ltd',
        'H5': 'Magadan Airlines',
        'GS': 'Tianjin Airlines',
        'H4': 'Inter Islands Airlines',
        'GR': 'Aurigny Air Services Ltd',
        'H3': 'Harbour Air',
        'GQ': 'Big Sky Airlines',
        'H2': 'Sky Service S.A.',
        'GP': 'Palau Trans Pacific Airlines Inc.',
        'GO': 'Go Fly Limited',
        'GN': 'Air Gabon',
        'GM': 'Air Slovakia Bwj Ltd',
        'GL': 'AIR GREENLAND INC',
        'GK': 'Go One Airways',
        'GJ': 'Eurofly',
        'GI': 'Air Company Itek-Air Ltd.',
        'GH': 'Globus LLC',
        'GG': 'Air Guyane',
        'GF': 'Gulf Air',
        'GE': 'Transasia Airways',
        'GD': 'Air Alpha Greenland A/S',
        'GC': 'Gambia International Airlines',
        'GB': 'Great Barrier Airlines',
        'GA': 'Garuda Indonesia',
        'FZ': 'FlyDubai',
        'FY': 'Firefly',
        'FX': 'Fedex Airlines',
        'G9': 'Air Arabia',
        'FW': 'Fair',
        'G8': 'Joint Stock Co Enkor',
        'FV': 'Rossiya Airlines',
        'G7': 'GoJet Airlines',
        'FU': 'Air Littoral',
        'G6': 'Angkor Airways',
        'FT': 'Siem Reap Airways',
        'G5': 'Enkor Airlines',
        'FS': 'Missionary Av',
        'G4': 'Allegiant Air LLC',
        'FR': 'Ryan Air Dublin',
        'G3': 'Varig-Gol Airlines',
        'FQ': 'Brindabella Airlines',
        'G2': 'Avirex Gabon',
        'FP': '',
        'FO': 'Expedition Airways',
        'G0': 'Ghana International Airlines',
        'FN': 'Regional Air Lines',
        'FM': 'Shanghai Airlines',
        'FL': 'Airtran Airways',
        'FK': 'Keewatin Air',
        'FJ': 'Air Pacific',
        'FI': 'Icelandair',
        'FH': '',
        'FG': 'Ariana Afghan Airlines',
        'FF': 'Tower Air',
        'FE': 'Far Eastern Air Trans',
        'FD': 'Thai AirAsia',
        'FC': 'Finncomm Commuter Air',
        'FB': 'Bulgaria Air',
        'FA': 'Safair',
        'EZ': 'Sun Air of Scandinavia',
        'EY': 'Etihad Airways',
        'EX': 'Air Santo Domingo',
        'F9': 'Frontier Airlines',
        'EW': 'Eurowings',
        'F8': 'Freedom Airlines Incorporated',
        'EV': 'Atlantic Southeast Airlines',
        'F7': 'Darwin Airline SA',
        'EU': 'United Eagle Airlines',
        'F6': 'China National Aviation Corporation',
        'ET': 'Ethiopian Airlines',
        'F5': 'Cosmic Air',
        'ES': 'DHL International',
        'F4': 'Albarka Air',
        'ER': 'Astar Air Cargo Incorporated',
        'F3': 'Faso Airways',
        'EQ': 'Transportes Aereos Militates Ecuat',
        'F2': 'Fly Air',
        'EP': 'Iran Asseman Airlines',
        'F1': 'FareLogix',
        'EO': 'Hewa Bora Airways',
        'F0': 'Fset Partition 2P',
        'EN': 'Air Dolomiti',
        'EM': 'Aero Benin SA',
        'EL': 'Air Nippon Co',
        'EK': 'Emirates Airlines',
        'EJ': 'New England Airlines Inc',
        'EI': 'Aer Lingus Teoranta Aerlinte Eirean',
        'EH': 'All Nippon Network Co.',
        'EG': 'Enerjet',
        'EF': 'Far Eastern Air Transport Corp',
        'EE': 'Aero Airlines A.S.',
        'ED': 'Air Dominicana',
        'EC': 'Openskies BA European Ltd',
        'EB': 'Pullmantur Air',
        'EA': 'European Air Express',
        'DZ': 'Transcaraibes',
        'DY': 'Norwegian Air Shuttle',
        'DX': 'Danish Air Transport',
        'E9': 'Pan Am Clipper Connect',
        'DW': 'Aero-Charter',
        'E8': 'Alpi Eagles',
        'DV': 'PL Scat AirCompany',
        'E7': 'Fly European',
        'DU': 'Hemus Air',
        'E6': 'Aviaexpresscruise Airline',
        'DT': 'Taag Angola Airlines',
        'E5': 'Samara Airlines',
        'DS': '',
        'E4': 'Aero Asia International Ltd',
        'DR': 'Air Link',
        'E3': 'Domodedovo Airlines',
        'DQ': 'Coastal Air Transport',
        'E2': 'RIO Grande Air',
        'DP': 'First Choice Airways',
        'DO': 'Air Vallee',
        'E0': 'Eos Airlines',
        'DN': 'Senegal Airlines',
        'DM': 'Sterling Blue',
        'DL': 'Delta Air Lines Inc',
        'DK': 'Eastland Air',
        'DJ': 'Virgin Australia',
        'DI': 'Deutsche BA',
        'DH': 'Independence Air',
        'DG': 'South East Asian',
        'DF': 'Aerolineas De Baleares',
        'DE': 'Condor Flugdienst Gmbh',
        'DD': 'Nok Air',
        'DC': 'Golden Air Flyg Ab',
        'DB': 'Brittany Air International',
        'DA': 'Air Georgia',
        'CZ': 'China Southern Airlines',
        'CY': 'Cyprus Airways Ltd',
        'CX': 'Cathay Pacific  Airways',
        'D9': 'Donavia',
        'CW': 'Air Marshall Islands',
        'D8': 'Djibouti Airlines',
        'CV': 'Cargolux Airlines',
        'D7': 'Dinar Lineas Aereas S.A.',
        'CU': 'CUBANA AIRLINES',
        'D6': 'Interair South Africa',
        'CT': 'Companie Aereas De Viage Expresos CA',
        'D5': 'DAU Air Verwaltungs AG',
        'CS': 'Continental Micronesia',
        'D4': 'Alidaunai',
        'CR': 'OAG Worldwide',
        'D3': 'Daallo Airlines',
        'CQ': 'Czech Connect Airlines',
        'D2': 'Severstal Joint Stock Co',
        'CP': 'Compass Airlines',
        'CO': 'Continental Airlines',
        'CN': 'Islands Nationair',
        'CM': 'Copa Airlines',
        'CL': 'Lufthansa Cityline Gmbh',
        'CK': 'China Cargo Airlines',
        'CJ': 'China Northern Airlines',
        'CI': 'China Airlines',
        'CH': 'Bemidji Aviation',
        'CG': 'Airline Papua New Guinea',
        'CF': 'City Airline',
        'CE': 'Chalair Aviation',
        'CD': 'Alliance-Air',
        'CC': 'Macair Airlines',
        'CB': 'ScotAirways',
        'CA': 'Air China',
        'BZ': 'Blue Dart Aviation',
        'BY': 'Thomsonfly Limited',
        'BX': 'Air Busan',
        'C9': 'Cirrus Airlines',
        'BW': 'Caribbean Airlines',
        'C8': 'Chicago Express Airlines Inc',
        'BV': 'Blue Panorama Airlines',
        'C7': 'Samarkand Airways',
        'BU': 'Braathens ASA',
        'C6': 'Canjet Airlines',
        'BT': 'Air Baltic',
        'C5': 'CommutAir',
        'BS': 'British Intl',
        'BR': 'Eva Airways',
        'C3': 'Icar',
        'BQ': 'Aeromar C. Pro. A.',
        'BP': 'Air Botswana',
        'BO': 'Bouraq Airlines',
        'C0': 'Centralwings',
        'BN': 'Bahrain Air',
        'BM': 'Bayu Indonesia',
        'BL': 'Jetstar Pacific',
        'BK': 'Potomac Air',
        'BJ': 'Nouvelair Tunisie',
        'BI': 'Royal Brunei Airlines',
        'BH': 'Hawkair Aviation Services',
        'BG': 'Biman Bangladesh Airlines',
        'BF': 'Markair',
        'BE': 'Flybe',
        'BD': 'bmi british midland',
        'BC': 'Skymark Airlines',
        'BB': 'Seaborne Aviation',
        'BA': 'British Airways',
        'AZ': 'Alitalia',
        'AY': 'FinnAir',
        'B9': 'Iran Air Tours',
        'AX': 'Trans States Airlines',
        'B8': 'Eritrean Airlines',
        'AW': 'Dirgantata Air Services',
        'B7': 'Uni Airways',
        'AV': 'Avianca',
        'B6': 'Jetline Airways',
        'AU': 'Austral Lineas Aereas',
        'B5': 'East African Safari Air Express',
        'AT': 'Royal Air Maroc',
        'B4': 'Zanair',
        'AS': 'Alaska Airlines',
        'B3': 'Bellview Airlines',
        'AR': 'Aerolineas Argentinas',
        'B2': 'Belavia',
        'AQ': 'Aloha Airline',
        'B1': 'Brewster Bus',
        'AP': 'Air One',
        'AO': 'Aviaco Airlines Ltd.',
        'AN': 'Ansett Australia',
        'AM': 'Aeromexico Aeronaves De Mexico',
        'AL': 'Astral Aviation',
        'AK': 'Airasia',
        'AJ': '',
        'AI': 'Air India',
        'AH': 'Air Algerie',
        'AG': 'Provincial Airlines',
        'AF': 'Air France',
        'AE': 'Mandarin Airlines',
        'AD': 'Air Paradise International',
        'AC': 'Air Canada',
        'AB': 'Air Berlin Plc and Co',
        'AA': 'American Airlines',
        'A9': 'Georgian Airlines',
        'A8': 'Benin Golf Air',
        'A7': 'Air Comet',
        'A6': 'Air Alps Aviation',
        'A5': 'Airlinair',
        'A4': 'Aerocon',
        'A3': 'Aegean Airlines',
        'A2': 'Cielos Del Peru',
        'A1': 'G2 Switchworks Corporation',
        'A0': 'L Avion',
        '9Y': 'Air Kazakstan',
        '9X': 'New Axis Airways',
        '9W': 'Jet Airways',
        '9V': 'Avior Airlines',
        '9U': 'Air Moldova',
        '9T': 'ACT Airlines',
        '9S': 'Spring Airlines',
        '9R': 'Phuket Airlines Co. Ltd.',
        '9Q': 'PB Air',
        '9P': 'Pelangi Airways',
        '9O': 'Transportes Aereos Inter',
        '9N': 'Trans State Airlines Inc',
        '9M': 'Central Mountain Air Ltd',
        '9L': 'Colgan Air',
        '9K': 'Cape Air',
        '9J': 'Dana Air',
        '9I': 'Thai Sky Airlines',
        '9H': 'Dutch Antilles Express',
        '9G': 'Galaxy Airways',
        '9F': 'Eurostar',
        '9E': 'Pinnacle Airlines',
        '9D': 'Toumai Air Tchad',
        '9C': 'Spring Airlines',
        '9B': 'Accesrail Inc.',
        '9A': 'Visa Airways',
        '8Z': 'Laser',
        '8Y': 'China Postal Airlines',
        '8W': 'Air Team A.S.',
        '8V': 'Wrightair Svc',
        '8U': 'Afriqiyah Airways',
        '8T': 'Air Tindi Ltd',
        '8S': 'TurboJet',
        '8R': 'Trip Air',
        '8Q': 'Baker Aviation Inc',
        '8P': 'Pacific Coastal',
        '8O': 'West Coast Air',
        '8N': 'Nordkalottlkyg',
        '8M': 'MAI (Myanmar)',
        '8L': 'Lucky Air',
        '8K': 'Angel Airlines SA',
        '8J': 'Societe D\'Investissement Aerien dba Jet4You',
        '8I': 'MyAir',
        '8H': 'Highland Airways',
        '8G': 'Angel Airlines',
        '8F': 'Fischer Air',
        '8E': 'Bering Air',
        '8D': 'Expo Aviation',
        '8C': 'East Star Airlines',
        '8B': 'Caribbean Star Airlines',
        '8A': 'Atlas Blue',
        '7Z': 'Laker Airways Bahamas',
        '7Y': 'Mid Airlines',
        '7W': 'Wind Rose',
        '7V': 'Federal Airlines Pty Ltd',
        '7U': 'Aviaenegro',
        '7T': 'Trans Cote',
        '7S': 'C Air Jet Airlines',
        '7R': 'BRA Transportes Aereos',
        '7Q': 'Pawa Dominicana',
        '7P': 'Batavia Air',
        '7O': 'Galaxy Air',
        '7N': 'Inland Aviations Services Inc.',
        '7M': 'MayAir',
        '7L': 'Aerocaribbean S. A.',
        '7K': 'Kogalymavia',
        '7J': 'Tajikair',
        '7I': 'Insel Air',
        '7H': 'Era Helicopters Inc',
        '7G': 'MK Airlines',
        '7F': 'Firstair',
        '7E': 'Sylt Air',
        '7D': 'Donbass Eastern Ukranian Airlines',
        '7C': 'Jeju Air Co Ltd',
        '7B': 'Atlant-Soyuz',
        '7A': 'Air Next',
        '6Z': '',
        '6Y': '',
        '6W': 'Saratov Airlines',
        '6V': 'Lignes Aeriennes Congolaises',
        '6U': 'Air Ukraine',
        '6T': 'Almaty Aviation',
        '6S': 'Proteus Helicopteres',
        '6R': 'Georgian Airlines',
        '6Q': 'Slovak Airlines',
        '6P': 'Clubair Sixgo SPA',
        '6N': 'Trans Travel Airlines',
        '6M': 'Majestic Air PL',
        '6L': 'Aklak Inc',
        '6K': 'Asian Spirit',
        '6J': 'Skyservice Airlinesd/b/a Roots Air',
        '6H': 'Israir Airlines',
        '6G': 'Air Wales',
        '6F': 'Aerotrans Airlines Ltd',
        '6E': 'City Air Germany AG',
        '6D': 'Pelita Air Service',
        '6C': 'Cape Smythe Air Service',
        '6B': 'Britannia Airways AB',
        '6A': 'Aviacsa-Consorcio Aviaxsa',
        '5Z': 'Bismillah Airlines',
        '5Y': 'Atlas Air',
        '5X': 'UPS',
        '5W': 'Iceland Express',
        '5V': 'Lviv Airlines',
        '5U': 'Lineas Aereas Del Estado',
        '5T': 'Canadian North',
        '5S': 'Servicios Aereos Profesionales',
        '5R': 'Karthago Airlines',
        '5Q': 'Best Aviation',
        '5P': 'Aerolinea Principal',
        '5N': 'Nordavia RA',
        '5M': 'Mega Maldives',
        '5L': 'Aerosur',
        '5K': 'Hi Fly Transportes S.A',
        '5J': 'Cebu Pacific',
        '5I': 'Air G',
        '5H': 'Five Fourty Aviation Ltd',
        '5G': 'SkyService Airlines',
        '5F': 'Arctic Circle Air Service',
        '5E': 'Equaflight Service',
        '5D': 'Aerolitoral S.A. de C.V.',
        '5C': 'C.A.L.Cargo Airlines',
        '5B': 'Euro-Asia International',
        '5A': 'Alpine Aviation',
        '4Z': 'South African Airlink',
        '4Y': 'Yute Air Alaska, Inc.',
        '4W': 'Allied Air',
        '4V': 'Birdy Airlines S.A.',
        '4U': 'Germanwings',
        '4T': 'Gawne Aviation',
        '4S': 'Sol Air',
        '4R': 'Orient Eagle Airways',
        '4Q': 'Safi Airways',
        '4P': 'Business Aviation',
        '4O': 'Ocean Airlines',
        '4N': 'Air North',
        '4M': 'Lan Argentina',
        '4L': 'Georgian International Airlines',
        '4K': 'Askari Aviation',
        '4J': 'Somon Air',
        '4H': 'United Airways Bangladesh',
        '4G': 'Gazpromavia Aviation',
        '4F': 'Air City',
        '4E': 'Tanana Air',
        '4D': 'Air Sinai',
        '4C': 'Aires',
        '4B': 'Olson Air Service',
        '4A': 'F. S. Air Service Inc.',
        '3Z': 'Everts Air',
        '3Y': 'Kartika Airlines',
        '3X': 'Japan Air Commuter',
        '3W': 'Euromanx',
        '3V': 'TNT Airways SA',
        '3U': 'Sichuan Airlines',
        '3T': 'Turan Air Airline',
        '3S': 'C.A.I.R.E.',
        '3R': 'Gromov Flight Research',
        '3Q': 'China Yunnan Airlines',
        '3P': 'Tiara Air',
        '3O': 'Air Arabia Maroc',
        '3N': 'Air Urga',
        '3M': 'Gulfstream Intl Airlines',
        '3L': 'Intersky Luftfahrt',
        '3K': 'Jetstar Asia Airways',
        '3J': 'Zip Air Inc.',
        '3I': 'Aerolineas Del Sur',
        '3H': 'Air Inuit',
        '3G': 'Atlant-Soyuz',
        '3F': 'Pacific Airways Inc.',
        '3E': 'Air Choice One',
        '3D': 'Denim Air',
        '3C': 'Corporate Express Airlines',
        '3B': 'Burkina Airlines',
        '3A': 'Chu Kong Passenger Trans',
        '2Z': 'Joint Stock Co Voronezh',
        '2Y': 'Air Andaman',
        '2X': 'Airway Express, Inc.',
        '2W': 'Welcome Air Luftfahrt',
        '2V': 'AMTRAK',
        '2U': 'Sun d\'Or International Airlines',
        '2T': 'Alajnihah Airways',
        '2S': 'Star Equatorial Airlines',
        '2R': 'VIA Rail Canada',
        '2Q': 'Phoenix Airways',
        '2P': 'Air Philippines',
        '2N': 'NEX Time Jet',
        '2M': 'Moldavian Airlines',
        '2L': 'Helvetic',
        '2K': 'Aerogal',
        '2J': 'Air Burkina',
        '2I': 'Star Peru',
        '2H': 'Transavia',
        '2G': 'Debonair',
        '2F': 'Frontier Flying Service',
        '2E': 'Smokey Bay Air',
        '2D': 'Aero VIP S.A.',
        '2C': 'SNCF',
        '2B': 'Aerocondor',
        '2A': 'Aeroservicios Ecuatorian',
        '1U': 'ITA Software',
        '1T': '1Time Airline',
        '1P': 'Worldspan',
        '1N': 'Navitaire Inc.',
        '1L': '',
        '1K': 'Southern Cross Distribution Systems',
        '1J': 'Axess International Network Inc.',
        '1I': '',
        '1G': 'Galileo',
        '1F': 'Infini',
        '1E': 'TravelSky Technology',
        '1C': 'EDS Information',
        '1B': 'Abacus International',
        '1A': 'Amadeus',
        '0Y': 'Yeti Airline',
        '0P': 'Palau Asia Pacific Air',
        '0O': 'STA Travel',
        '0D': 'Darwin Airline',
        '0B': 'Blue Air Transport'
    },
    zh_HK: {
        'ZY': 'Ada Air',
        'ZX': 'AIR ALLIANCE',
        'ZW': '\u5A01\u65AF\u5EB7\u661F\u822A\u7A7A',
        'ZV': '\u4E2D\u897F\u90E8\u822A\u7A7A',
        'ZU': 'HELIOS AIRWAYS',
        'ZT': 'AIR ZAMBEZI',
        'ZS': '\u610F\u5927\u5229\u963F\u7956\u62C9\u822A\u7A7A',
        'ZR': '\u7A46\u514B\u822A\u7A7A',
        'ZQ': 'CARIBBEAN SUN AIRLINES',
        'ZP': 'Silk Way',
        'ZO': 'GREAT PLAINS AIRLINES',
        'ZN': 'AIR BOURBON',
        'ZM': 'ALLIANCE EXPRESS RWANDA',
        'ZL': '\u5730\u5340\u5FEB\u905E/\u9ED1\u6FA4\u723E\u9813\u822A\u7A7A',
        'ZK': '\u5927\u6E56\u822A\u7A7A',
        'ZJ': 'Zambezi Airlines',
        'ZI': '\u963F\u7956\u723E\u5929\u9DF9\u5EA7\u822A\u7A7A',
        'ZH': '\u6DF1\u5733\u822A\u7A7A',
        'ZG': 'Viva Macau Limited',
        'ZF': 'Athens Airways',
        'ZE': 'LINEAS AEREAS AZTECA',
        'ZD': 'FLYING DANDY',
        'ZC': '\u65AF\u5A01\u58EB\u862D\u7687\u5BB6\u822A\u7A7A',
        'ZB': '\u541B\u4E3B\u822A\u7A7A',
        'ZA': 'ASTAIR LIMITED COMPANY',
        'YZ': 'TACAECUADOR S.A.',
        'YY': '\u6240\u6709\u822A\u7A7A\u516C\u53F8',
        'Z9': 'Aero Zambia',
        'YX': '\u4E2D\u897F\u90E8\u5FEB\u904B\u822A\u7A7A',
        'Z8': 'Amaszonas SA',
        'YW': '\u90A3\u65AF\u7DBD\u822A\u7A7A',
        'Z7': 'ADC Airlines',
        'YV': '\u6885\u85A9\u822A\u7A7A',
        'Z6': 'Dnieproavia Joint Stock Aviation Company',
        'YU': 'DOMINAIR AEROLINEAS DOMINICANAS S.A. DOMINA',
        'Z5': 'GMG Airlines',
        'YT': 'Silverjet',
        'Z4': 'Zoom Airlines Inc',
        'YS': 'Regional CAE',
        'Z3': 'Promech Inc',
        'YR': '\u98A8\u666F\u822A\u7A7A',
        'Z2': 'Zest Airways',
        'YQ': 'Aircompany Polet',
        'YP': '\u52DE\u57C3\u5FB7\u822A\u7A7A',
        'YO': 'HELI AIR MONACO',
        'YN': '\u52A0\u62FF\u5927\u514B\u5229\u6BD4\u514B\u822A\u7A7A',
        'YM': 'MONTENEGRO AIRLINES',
        'YL': 'YAMAL AIRLINES',
        'YK': 'KIBRIS TURKISH AIRLINES',
        'YJ': 'Amiyi Airlines',
        'YI': 'AIR SUNSHINE INC',
        'YH': 'WEST CARIBBEAN AIRWAYS',
        'YG': '\u5357\u65B9\u822A\u7A7A',
        'YE': 'YANDA AIRLINES',
        'YD': 'Mauritania Airways',
        'YC': 'Yamal Airlines',
        'YB': '\u5357\u975E\u5FEB\u905E',
        'XZ': 'FLUGFELAG AUSTURLANDS',
        'XY': 'National Air Services',
        'Y9': 'Kish Airlines',
        'Y8': 'Yangtze River Express',
        'XW': 'Sky Express',
        'Y7': 'Silverjet',
        'XV': 'WALTAIR I LINKOPING AB',
        'Y6': 'Cambodia Airlines',
        'XU': 'AFRICAN EXPRESS AIRWAYS',
        'Y5': 'Asia Wings',
        'XT': 'Skystar Airways',
        'Y4': 'Eagle Aviation',
        'XS': 'SITA-Airlines Worldwide',
        'Y3': 'Driessen Services Incorporated',
        'XR': '\u897F\u65B9\u5929\u7A7A\u822A\u7A7A',
        'Y2': 'Alliance Air',
        'XQ': '\u592A\u967D\u5FEB\u905E\u822A\u7A7A',
        'XP': 'TAHOE AIR',
        'XO': '\u4E2D\u570B\u65B0\u7586\u822A\u7A7A',
        'XN': 'Air Nepal',
        'XM': '\u610F\u5927\u5229\u822A\u7A7A\u6377\u904B',
        'XL': 'LANECUADOR AEROLANE SA',
        'XK': 'CCM Airlines',
        'XJ': '\u6885\u85A9\u5DF4\u822A\u7A7A',
        'XI': 'Aeronautical Telecommunications Limited',
        'XH': 'Hndlg Angent',
        'XG': 'AIR MALI INTERNATIONAL',
        'XF': '\u6D77\u53C3\u5A01\u822A\u7A7A',
        'XE': 'ExpressJet',
        'XD': 'Airlines Reporiting Corporation',
        'XB': 'Internatioanl Air Transport Association',
        'XA': 'ARINC Aeronautical Radio',
        'WZ': 'West African Airlines',
        'WY': '\u963F\u66FC\u822A\u7A7A',
        'X9': 'City Star Airlines',
        'WX': 'City Jet',
        'X8': 'Icaro',
        'WW': 'bmibaby',
        'X7': 'Air Service',
        'WV': 'WESTEASTAIR AB',
        'X6': 'Airlines Reporting Corporation',
        'WU': 'TIKAL JETS SA',
        'X5': 'Afrique Airlines',
        'WT': 'Wasaya Airways',
        'WS': '\u897F\u65B9\u5674\u6C23\u822A\u7A7A',
        'X3': 'TUIfly',
        'WR': '\u6E6F\u52A0\u7687\u5BB6\u822A\u7A7A',
        'X2': 'Airlines Reporting Corporation',
        'WQ': '\u7F85\u99AC\u7DAD\u4E9E\u822A\u7A7A',
        'WP': 'ALOHA ISLANDAIR',
        'WO': '\u7F8E\u570B\u4E16\u754C\u822A\u7A7A',
        'WN': '\u7F8E\u570B\u897F\u5357\u822A\u7A7A',
        'WM': '\u5411\u98A8\u7FA4\u5CF6\u570B\u969B\u822A\u7A7A',
        'WL': 'AEROPERLAS',
        'WK': 'Edelweiss Air',
        'WJ': 'Air Labrador',
        'WI': 'U-LAND AIRLINES',
        'WH': '\u4E2D\u570B\u897F\u5317\u822A\u7A7A',
        'WG': 'WASAYA AIRWAYS L.P.',
        'WF': '\u632A\u5A01\u7DAD\u5FB7\u52D2\u822A\u7A7A',
        'WE': '\u6CF0\u570B\u5FAE\u7B11\u822A\u7A7A',
        'WD': 'DAS AIR LIMITED',
        'WC': '\u4F0A\u65AF\u840A\u7D0D\u822A\u7A7A',
        'WB': 'RWANDAIR EXPRESS',
        'WA': '\u8377\u862D\u7687\u5BB6\u7A7A\u57CE\u5E02\u77ED\u9014',
        'VZ': 'MyTravel Airways Limited',
        'VY': 'Vueling Airlines SA',
        'W9': '\u84B2\u7518\u822A\u7A7A',
        'VX': 'Virgin America',
        'VW': 'Transportesaeromar',
        'W7': 'Sayakhat Airlines',
        'VV': '\u70CF\u514B\u862D\u4E16\u754C\u822A\u7A7A',
        'W6': 'West Isle Air',
        'VU': 'Air Ivoire',
        'W5': '\u4F0A\u6717\u6EFF\u6F22\u822A\u7A7A',
        'VT': '\u5854\u5E0C\u63D0\u822A\u7A7A',
        'W4': 'LC Busre',
        'VS': '\u7DAD\u73CD\u822A\u7A7A',
        'W3': 'Arik Air',
        'VR': 'TACV-CABO VERDE AIRLINE',
        'W2': 'FlexFlight',
        'VQ': 'Viking Hellas Airlines',
        'VP': '\u5DF4\u897F\u8056\u4FDD\u7F85\u822A\u7A7A',
        'VO': '\u63D0\u6D1B\u6797\u822A\u7A7A',
        'VN': '\u8D8A\u5357\u822A\u7A7A',
        'VM': 'Aerochaco',
        'VL': 'INTL EXPRESS AIR CHARTER',
        'VK': 'Air Nigeria',
        'VJ': 'JATAYU AIRLINES',
        'VI': '\u5225\u514B\u65AF\u822A\u7A7A',
        'VH': 'Aeropostal',
        'VG': '\u6BD4\u5229\u6642\u7DAD\u723E\u59C6\u822A\u7A7A',
        'VF': '\u60E0\u65C5\u822A\u7A7A',
        'VE': '\u59D4\u5167\u745E\u62C9\u570B\u5167\u822A\u7A7A',
        'VD': '\u6B50\u6D32\u8DE8\u5730\u5340\u822A\u7A7A',
        'VC': 'Strategic Aviation',
        'VB': '\u82F1\u570B\u6885\u723E\u65AF\u514B\u822A\u7A7A',
        'VA': '\u7DAD\u73CD\u6FB3\u6D32\u822A\u7A7A',
        'UZ': 'Buraq Air Transport',
        'UY': '\u5580\u9EA5\u9686\u822A\u7A7A',
        'V9': 'Bashkir Airlines',
        'UX': '\u897F\u73ED\u7259\u6B50\u6D32\u822A\u7A7A',
        'V8': 'Iliamna Air Taxi Inc',
        'UW': 'Universal Airlines',
        'V7': 'Air Senegal International',
        'UV': 'HELICOPTEROS DEL SURESTE',
        'V6': 'Air Atlantique',
        'UU': '\u6FB3\u6D32\u822A\u7A7A',
        'V5': 'VIP Wings Limited',
        'UT': 'UTA',
        'V4': 'Vensecar International',
        'US': '\u5168\u7F8E\u822A\u7A7A',
        'V3': 'Carpatair',
        'UR': '\u70CF\u514B\u862D\u570B\u5BB6\u822A\u7A7A',
        'V2': 'Vision Airlines',
        'UQ': 'OCONNOR AIRLINES',
        'UP': '\u5DF4\u54C8\u99AC\u822A\u7A7A',
        'V0': 'Conviasa',
        'UO': '\u6E2F\u806F\u822A\u7A7A',
        'UN': '\u74B0\u7A7A\u822A\u7A7A',
        'UM': '\u6D25\u5DF4\u5E03\u97CB\u822A\u7A7A',
        'UL': '\u65AF\u91CC\u862D\u5361\u822A\u7A7A',
        'UK': '\u8377\u862D\u7687\u5BB6\u822A\u7A7A\u82F1\u570B',
        'UJ': 'MONTAIR AVIATION INC',
        'UI': '\u6B50\u6D32\u585E\u6D66\u8DEF\u65AF\u822A\u7A7A',
        'UH': 'US Helicopter Corp',
        'UG': 'Sevenair S.A.',
        'UF': 'UKRAINIAN MEDITERRANEAN AIRLINES',
        'UE': 'U Air',
        'UD': '\u8D6B\u514B\u65AF\u822A\u7A7A',
        'UC': '\u62C9\u5FB7\u79D1\u822A\u7A7A',
        'UB': '\u7DEC\u7538\u822A\u7A7A',
        'UA': '\u7F8E\u570B\u806F\u5408\u822A\u7A7A',
        'TZ': 'AVIACSA/\u5EB7\u7D22\u55AC\u822A\u7A7A',
        'TY': '\u5580\u91CC\u591A\u5C3C\u4E9E\u822A\u7A7A',
        'U9': 'Tatarstan',
        'TX': 'AIR CARAIBES AERIENS',
        'U8': 'Armavia',
        'TW': '\u7F8E\u570B\u74B0\u7403\u822A\u7A7A',
        'U7': 'Air Uganda',
        'TV': '\u7DAD\u723E\u4EAC\u5FEB\u904B',
        'U6': 'Ural Airlines',
        'TU': '\u7A81\u5C3C\u65AF\u822A\u7A7A',
        'U5': 'USA 3000 Airlines',
        'TT': 'Tiger Airways Australia',
        'U4': 'Progress Multitrade',
        'TS': '\u8DE8\u5927\u897F\u6D0B\u822A\u7A7A',
        'U3': 'AS Avies',
        'TR': '\u74B0\u5DF4\u897F\u822A\u7A7A',
        'U2': 'Easyjet Airline Company',
        'TQ': 'TANDEM AERO LTD',
        'TP': 'Tap Air Portugal',
        'U0': 'Sabre Airline',
        'TO': '\u7E3D\u7D71\u822A\u7A7A',
        'TN': 'AIR TAHITI NUI',
        'TM': '\u975E\u6D32\u83AB\u6851\u6BD4\u514B\u822A\u7A7A',
        'TL': 'AirNorth Regional',
        'TK': '\u571F\u8033\u5176\u822A\u7A7A',
        'TJ': '\u7955\u9B6F\u570B\u969B\u822A\u7A7A',
        'TI': 'Air Continental Africa',
        'TH': 'Transmile Air Services',
        'TG': '\u6CF0\u570B\u570B\u969B\u822A\u7A7A',
        'TF': '\u99AC\u723E\u83AB\u822A\u7A7A',
        'TE': 'FlyLal',
        'TD': 'ATLANTIS EUROPEAN',
        'TC': '\u5766\u6851\u5C3C\u4E9E\u822A\u7A7A',
        'TB': 'Jetairfly.com',
        'TA': '\u85A9\u723E\u74E6\u822A\u7A7A',
        'SZ': 'Air South West Limited',
        'SY': '\u967D\u5149\u4E4B\u9109\u822A\u7A7A',
        'T9': 'TRANSMERIDIAN AIRLINES',
        'SX': 'AEROEJECUTIVO S.A. DE C.V.',
        'T8': 'STA Trans African Air',
        'SW': '\u5167\u7C73\u6BD4\u4E9E\u822A\u7A7A',
        'T7': 'Twin Jet',
        'SV': '\u6C99\u7279\u963F\u62C9\u4F2F\u822A\u7A7A',
        'T6': 'Tavrey Aircompany',
        'SU': '\u4FC4\u7F85\u65AF\u822A\u7A7A',
        'T5': 'Turkmenistan Airlines',
        'ST': '\u5FB7\u570B\u5305\u6A5F',
        'T4': 'Trip Linhas Aereas',
        'SS': 'Corsair International',
        'T3': 'Eastern Airways',
        'SR': '\u745E\u58EB\u822A\u7A7A',
        'T2': 'Nakima Air Service',
        'SQ': '\u65B0\u52A0\u5761\u822A\u7A7A',
        'SP': 'SATA',
        'T0': 'TACA Peru',
        'SO': 'Sosoliso Airlines',
        'SN': '\u5E03\u9B6F\u585E\u723E\u822A\u7A7A\u516C\u53F8',
        'SM': 'Swedline Express',
        'SL': 'RIO SUL SERVICOS',
        'SK': '\u5317\u6B50\u822A\u7A7A',
        'SJ': 'AVIANDINA S.A.C.',
        'SI': 'SKYNET AIRLINES',
        'SH': '\u5716\u76E7\u7386\u570B\u969B\u822A\u7A7A',
        'SG': 'SpiceJet',
        'SF': 'TASSILI AIRLINES',
        'SE': 'XL Airways France',
        'SD': '\u8607\u4E39\u822A\u7A7A',
        'SC': '\u5C71\u6771\u822A\u7A7A',
        'SB': '\u5580\u91CC\u591A\u5C3C\u4E9E\u822A\u7A7A',
        'SA': '\u5357\u975E\u822A\u7A7A',
        'RZ': 'SANSA/\u570B\u6C11\u822A\u7A7A\u670D\u52D9',
        'RY': 'EUROPEAN EXECUTIVE EXP',
        'S9': 'SWE Aviation Europe',
        'RX': '\u5308\u7259\u5229\u822A\u7A7A\u5FEB\u905E',
        'S8': 'Estonian Aviation Company',
        'RW': 'RAS\u822A\u7A7A',
        'S7': 'S7 Airlines',
        'RV': 'Caspian Airlines',
        'S6': 'Sun Air',
        'RU': 'TCI Skyking',
        'S5': 'Shuttle America',
        'RT': 'RAK Airways',
        'S4': 'Sata International',
        'RS': 'Royal Flight of Oman',
        'S3': 'Santa Barbara Airlines',
        'RR': 'ROYAL AIRFORCE',
        'S2': 'Jet Lite',
        'RQ': 'Kam Air',
        'S1': 'Lufthansa System AG',
        'RP': '\u7F8E\u570B\u8096\u6258\u5938\u822A\u7A7A',
        'S0': 'Slok Air International',
        'RO': '\u7F85\u99AC\u5C3C\u4E9E\u822A\u7A7A',
        'RN': 'PARADISE AIR',
        'RM': '\u6469\u723E\u591A\u74E6\u822A\u7A7A',
        'RL': 'Royal Falcon Airlines',
        'RK': '\u975E\u6D32\u822A\u7A7A',
        'RJ': '\u7687\u5BB6\u7D04\u65E6\u822A\u7A7A',
        'RI': '\u5370\u5EA6\u5C3C\u897F\u4E9E\u66FC\u9054\u62C9\u822A\u7A7A',
        'RH': 'Regionair',
        'RG': '\u5DF4\u897F\u822A\u7A7A',
        'RF': 'ORD AIR CHARTER',
        'RE': '\u963F\u502B\u822A\u7A7A',
        'RD': '\u745E\u5B89\u570B\u969B\u822A\u7A7A',
        'RC': '\u6CD5\u7F85\u7FA4\u5CF6\u5927\u897F\u6D0B\u822A\u7A7A',
        'RB': '\u963F\u62C9\u4F2F\u6558\u5229\u4E9E\u822A\u7A7A',
        'RA': '\u7687\u5BB6\u5C3C\u6CCA\u723E\u822A\u7A7A',
        'QZ': 'Indonesia AirAsia',
        'QY': 'AERO VIRGIN ISLANDS',
        'R9': 'Camai Air',
        'QX': '\u7F8E\u6D32\u5730\u5E73\u7DDA\u822A\u7A7A',
        'R8': 'Kyrghyzstan Airlines',
        'QW': 'Blue Wings AG',
        'R7': 'Aserco Airlines',
        'QV': '\u5BEE\u570B\u822A\u7A7A',
        'R6': 'Air Srpska',
        'QU': 'Airline Utair Ukraine',
        'R5': 'Malta Air Charter',
        'QT': 'REGIONAL AIR PTY',
        'R4': 'The State Transport Company Russia',
        'QS': 'Smartwings',
        'R3': 'Yakutia Airlines',
        'QR': '\u5361\u5854\u723E\u822A\u7A7A',
        'R2': 'Orenair',
        'QQ': '\u806F\u76DF\u822A\u7A7A',
        'QP': '\u5730\u5340\u822A\u7A7A',
        'R0': 'Royal Airlines',
        'QO': '\u592A\u5E73\u6D0B\u4E4B\u6E90\u822A\u7A7A',
        'QN': 'Air Armenia',
        'QM': '\u975E\u6D32\u99AC\u62C9\u5A01\u822A\u7A7A',
        'QL': 'Aero Lanka',
        'QK': 'Air Canada Jazz',
        'QJ': 'AIR COMPANY LATPASS',
        'QI': '\u4E39\u9EA5\u8F9B\u4F2F\u822A\u7A7A',
        'QH': 'ALTYN AIR AIRLINES',
        'QG': 'DYNAMIC AIR',
        'QF': '\u6FB3\u6D32\u822A\u7A7A',
        'QE': '\u514B\u7F85\u65AF\u6B50\u6D32\u822A\u7A7A/\u6B50\u6D32\u5927\u9678\u822A\u7A7A',
        'QD': 'AIR CLASS LINEAS AEREAS',
        'QC': 'Cameroon Arilines',
        'QB': 'Georgian National Airlines',
        'QA': 'Click Mexicana',
        'PZ': '\u5DF4\u62C9\u572D\u822A\u7A7A',
        'PY': '\u8607\u91CC\u5357\u822A\u7A7A',
        'Q9': 'Afrinat International Airlines',
        'PX': '\u65B0\u757F\u5167\u4E9E\u822A\u7A7A',
        'Q8': 'Trans Air Congo',
        'PW': 'PRECISION AIR',
        'Q7': 'Sobelair',
        'PV': 'SAINT BARTH COMMUTER',
        'Q6': 'Skytrans',
        'PU': 'Primeras Lineas Uruguayas',
        'Q5': 'FORTY MILE AIR',
        'PT': '\u745E\u5178\u897F\u65B9\u822A\u7A7A',
        'Q4': 'Swazi Express Airways',
        'PS': '\u70CF\u514B\u862D\u822A\u7A7A',
        'Q3': 'Zambian Airways',
        'PR': '\u83F2\u5F8B\u8CD3\u822A\u7A7A',
        'Q2': 'Island Aviation',
        'PQ': 'Panafrican Airways',
        'PP': 'JET AVIATION BUSINESS',
        'PO': '\u9D5C\u9D98\u822A\u7A7A',
        'PN': 'China West Air',
        'PM': '\u4F2F\u5229\u8332\u71B1\u5E36\u822A\u7A7A',
        'PL': '\u79D8\u9B6F\u822A\u7A7A',
        'PK': '\u5DF4\u57FA\u65AF\u5766\u822A\u7A7A',
        'PJ': '\u8056\u76AE\u57C3\u723E\u822A\u7A7A',
        'PI': 'Sol Paraguay',
        'PH': '\u6CE2\u5229\u5C3C\u897F\u4E9E\u822A\u7A7A',
        'PG': '\u66FC\u8C37\u822A\u7A7A',
        'PF': '\u5DF4\u52D2\u65AF\u5766\u822A\u7A7A',
        'PE': '\u6B50\u6D32\u822A\u7A7A',
        'PD': 'Porter Airlines',
        'PC': 'Pegasus Airlines',
        'PB': '\u52A0\u62FF\u5927\u7701\u822A\u7A7A',
        'PA': 'FLORIDA COASTAL AIRLINES',
        'OZ': '\u97D3\u4E9E\u822A\u7A7A',
        'OY': 'Andes Lineas Aereas S.A.',
        'P9': 'Peruvian Air Lines',
        'OX': '\u6CF0\u570B\u6771\u65B9\u822A\u7A7A',
        'P8': 'Pantanal Linhas Aereas Sul',
        'OW': '\u7F8E\u5229\u5805\u9DF9\u822A/\u57F7\u884C\u822A\u7A7A',
        'P7': 'East Line Airlines',
        'OV': '\u611B\u6C99\u5C3C\u4E9E\u822A\u7A7A',
        'P6': 'Trans Air',
        'OU': '\u514B\u7F85\u57C3\u897F\u4E9E\u822A\u7A7A',
        'P5': 'Aerorepublica',
        'OT': '\u9D5C\u9D98\u822A\u7A7A',
        'P4': 'Aero Lineas Sosa',
        'OS': '\u5967\u5730\u5229\u822A\u7A7A',
        'P3': 'Passaredo Linhas Aereas',
        'OR': 'TUI Airlines Netherland',
        'P2': 'TyumenaviaTrans',
        'OQ': 'SUNRISE AIRLINES INC',
        'P1': 'Public Charters',
        'OP': 'CHALKS OCEAN AIRWAYS',
        'P0': 'Proflight Commuter Services',
        'OO': '\u897F\u65B9\u5929\u7A7A\u822A\u7A7A',
        'ON': 'Our Airline',
        'OM': '\u8499\u53E4\u822A\u7A7A',
        'OL': 'OLT-OSTFRIESISCHE LUFFT',
        'OK': '\u6377\u514B\u822A\u7A7A',
        'OJ': 'OVERLAND AIRWAYS LTD',
        'OI': '\u666E\u96F7\u65AF\u8482\u822A\u7A7A',
        'OH': '\u79D1\u59C6\u822A\u7A7A',
        'OG': 'One Two Go Airlines',
        'OF': 'Air Finland Ltd',
        'OE': 'Asia Overnight Express',
        'OD': '\u8607\u5229\u4E9E\u90A3\u822A\u7A7A',
        'OC': 'OMNI',
        'OB': 'BOA',
        'OA': '\u5967\u6797\u5339\u514B\u822A\u7A7A',
        'NZ': '\u65B0\u897F\u862D\u822A\u7A7A',
        'NY': '\u51B0\u5CF6\u822A\u7A7A',
        'NX': '\u6FB3\u9580\u822A\u7A7A',
        'O8': '\u7518\u6CC9\u9999\u6E2F\u822A\u7A7A',
        'NW': '\u7F8E\u570B\u897F\u5317\u822A\u7A7A',
        'O7': 'OzJet',
        'NV': 'NAKANIHON AIRLINES',
        'O6': 'OceanAir Linhas Aereas',
        'NU': '\u65E5\u672C\u8D8A\u6D0B\u822A\u7A7A',
        'O5': 'Orange Air Sierra Leone',
        'NT': 'Binter Canarias',
        'O4': 'Antrak Air',
        'NS': 'SILK ROUTE AIRWAYS',
        'NR': 'Pamir Air',
        'O2': 'Jet Air Spolka Z.O.O.',
        'NQ': 'AIR JAPAN COMPANY LTD',
        'NP': 'SKYTRANS',
        'NO': '\u6FB3\u6D32\u822A\u7A7A',
        'NN': 'VIM Airlines',
        'NM': '\u5EAB\u514B\u5C71\u822A\u7A7A',
        'NL': '\u6C99\u6B23\u570B\u969B\u822A\u7A7A',
        'NK': '\u7CBE\u795E\u822A\u7A7A',
        'NJ': '\u524D\u885B\u822A\u7A7A',
        'NI': 'PORTUGALIA/\u8461\u8404\u7259\u822A\u7A7A',
        'NH': '\u5168\u65E5\u7A7A\u822A\u7A7A',
        'NG': '\u7DAD\u4E5F\u7D0D\u822A\u7A7A',
        'NF': '\u74E6\u52AA\u963F\u5716\u822A\u7A7A',
        'NE': 'SKYEUROPE AIRLINES',
        'ND': '\u93C8\u74B0\u822A\u7A7A',
        'NC': 'NATIONAL JET SYSTEMS',
        'NB': '\u65AF\u7279\u6797\u6B50\u6D32\u822A\u7A7A',
        'NA': '\u5317\u7F8E\u822A\u7A7A',
        'MZ': '\u6885\u5DF4\u8FEA\u822A\u7A7A',
        'MY': 'Midwest Airlines (Egypt)',
        'N9': 'Niger Air Continental',
        'MX': '\u58A8\u897F\u54E5\u822A\u7A7A',
        'N8': 'CR Airways',
        'MW': '\u99AC\u96C5\u5CF6\u822A\u7A7A',
        'N7': 'Lagun Air',
        'MV': '\u4E9E\u7F8E\u5C3C\u4E9E\u822A\u7A7A',
        'N6': 'Nuevo Continente S.A.',
        'MU': '\u4E2D\u570B\u6771\u65B9\u822A\u7A7A',
        'N5': 'Norfolk Air',
        'MT': '\u5927\u897F\u90E8\u822A\u7A7A',
        'N4': 'Minerva Airlines',
        'MS': '\u57C3\u53CA\u822A\u7A7A',
        'N3': 'Omskavia Airline',
        'MR': '\u6BDB\u91CC\u5854\u5C3C\u4E9E\u822A\u7A7A',
        'N2': 'Daghestan Airlines',
        'MQ': '\u7F8E\u5229\u5805\u9DF9\u822A',
        'MP': '\u8377\u862D\u99AC\u4E01\u822A\u7A7A',
        'MO': 'Calm Air International Limited',
        'MN': 'COMAIR LTD.',
        'MM': 'SAM',
        'ML': 'African Transport Trading And Investment Co.',
        'MK': '\u6BDB\u91CC\u88D8\u65AF\u822A\u7A7A',
        'MJ': 'Mihin Lanka PVT Ltd',
        'MI': '\u8056\u5B89\u822A\u7A7A',
        'MH': '\u99AC\u4F86\u897F\u4E9E\u822A\u7A7A',
        'MG': '\u51A0\u8ECD\u822A\u7A7A',
        'MF': '\u5EC8\u9580\u822A\u7A7A',
        'ME': '\u4E2D\u6771\u822A\u7A7A',
        'MD': '\u99AC\u9054\u52A0\u65AF\u52A0\u822A\u7A7A',
        'MC': 'AIR MOBILITY COMMAND',
        'MB': 'MNG Airlines',
        'MA': '\u5308\u7259\u5229\u822A\u7A7A',
        'LZ': 'Belle Air',
        'LY': '\u4EE5\u8272\u5217\u822A\u7A7A',
        'M9': 'Motor-Sich JSC',
        'LX': '\u745E\u58EB\u570B\u969B\u822A\u7A7A',
        'M8': '\u6E44\u516C\u822A\u7A7A',
        'LW': 'PACIFIC WINGS',
        'M7': 'Marsland Aviation',
        'LV': 'Albanian Airlines',
        'M6': 'AmeriJet International',
        'LU': 'LAN EXPRESS',
        'M5': 'Kenmore Air',
        'LT': 'LTU\u6EAB\u7279\u5167\u66FC\u570B\u969B\u822A\u7A7A',
        'M4': 'Avioimpex A.D.P.O.',
        'LS': 'Jet2.com Limited',
        'M3': 'Air Norway',
        'LR': '\u54E5\u65AF\u5927\u9ECE\u52A0\u822A\u7A7A',
        'M2': 'Mahfooz Aviation',
        'LQ': 'Lebanese Air Transport',
        'M1': 'Markair Express',
        'LP': '\u7955\u9B6F\u822A\u7A7A',
        'LO': '\u6CE2\u862D\u822A\u7A7A',
        'LN': '\u5229\u6BD4\u4E9E\u963F\u62C9\u4F2F\u822A\u7A7A',
        'LM': 'Livington SPA',
        'LL': '\u963F\u52D2\u683C\u96F7\u822A\u7A7A',
        'LK': 'AIR LUXOR S.A',
        'LJ': 'Jin Air Limited',
        'LI': '\u5317\u98A8\u7FA4\u5CF6\u822A\u7A7A',
        'LH': '\u5FB7\u570B\u6F22\u838E\u822A\u7A7A',
        'LG': '\u76E7\u68EE\u5821\u822A\u7A7A',
        'LF': 'FlyNordic',
        'LE': 'Efly',
        'LD': '\u9999\u6E2F\u83EF\u6C11\u822A\u7A7A\u6709\u9650\u516C\u53F8',
        'LC': 'LEGEND AIRLINES INC',
        'LB': '\u73BB\u5229\u7DAD\u4E9E\u52DE\u57C3\u5FB7\u822A\u7A7A',
        'LA': '\u667A\u5229\u822A\u7A7A',
        'KZ': '\u65E5\u672C\u8CA8\u904B\u822A\u7A7A',
        'KY': '\u8056\u591A\u7F8E\u548C\u666E\u6797\u897F\u6BD4\u822A\u7A7A',
        'L9': 'Awsaj Aviation Services',
        'KX': '\u958B\u66FC\u822A\u7A7A',
        'L8': 'Air Luxor GB LDA',
        'KW': 'Wataniya Airways',
        'L7': 'Laoag International Airlines',
        'KV': 'KAVMINVODYAVIA',
        'L6': 'Mauritanian Airlines International',
        'KU': '\u79D1\u5A01\u7279\u822A\u7A7A',
        'L5': 'Helikopter Service',
        'KT': 'KYRGYZ AIR',
        'L4': 'Lauda-air S.p.A.',
        'KS': 'PENAIR',
        'L3': 'Lynx Aviation',
        'KR': 'MDA AIRLINES',
        'L2': 'Lynden Air',
        'KQ': '\u80AF\u5C3C\u4E9E\u822A\u7A7A',
        'KP': 'Asky',
        'KO': '\u963F\u62C9\u65AF\u52A0\u4E2D\u90E8\u5FEB\u905E',
        'KN': 'China United Airlines',
        'KM': '\u99AC\u8033\u4ED6\u822A\u7A7A',
        'KL': '\u8377\u862D\u822A\u7A7A',
        'KK': 'Atlasjet',
        'KJ': '\u82F1\u570B\u5730\u4E2D\u6D77\u822A\u7A7A',
        'KI': 'ADAM SKYCONNECTION AIR/ADAM SKYCONNECTION AIRLINES PT',
        'KH': 'Kyrgyz Airways',
        'KG': 'Aerogaviota S.A.',
        'KF': '\u535A\u7279\u5C3C\u4E9E\u822A\u7A7A',
        'KE': '\u5927\u97D3\u822A\u7A7A',
        'KD': '\u80AF\u5FB7\u723E\u822A\u7A7A',
        'KC': 'AIR ASTANA/AIR ASTANA',
        'KB': '\u4E0D\u4E39\u822A\u7A7A',
        'KA': '\u6E2F\u9F8D\u822A\u7A7A',
        'JZ': '\u5929\u7A7A\u4E4B\u8DEF\u822A\u7A7A',
        'JY': 'Air Turks and Caicos',
        'JX': 'NICE HELICOPTERS',
        'K9': 'Skyward Aviation Ltd',
        'JW': 'SKIPPERS AVIATION',
        'K8': 'Zambia Skyways Limited',
        'JV': 'Bearskin Airlines',
        'K7': 'Sakha Avia',
        'JU': 'Jat Airways',
        'K6': 'Cambodia Angkor Air\n',
        'JT': '\u5370\u5C3C\u7345\u5B50\u822A\u7A7A',
        'K5': 'Seaport Airlines',
        'JS': '\u671D\u9BAE\u822A\u7A7A',
        'K4': 'Kronflyg',
        'JR': '\u52A0\u5229\u798F\u5C3C\u4E9E\u822A\u7A7A',
        'K3': 'Taquan Air Services',
        'JQ': 'JETSTAR AIRWAYS/JETSTAR AIRWAYS PTY LIMITED',
        'K2': 'Eurolot S.A.',
        'JP': '\u4E9E\u5F97\u5229\u4E9E\u822A\u7A7A',
        'K1': 'Topas',
        'JO': '\u65E5\u7DDA\u822A\u7A7A',
        'K0': 'Worldwide Air',
        'JN': 'EXCEL AIRWAYS',
        'JM': '\u7259\u8CB7\u52A0\u822A\u7A7A',
        'JL': '\u65E5\u672C\u822A\u7A7A',
        'JK': '\u897F\u73ED\u7259\u65AF\u6F58\u822A\u7A7A',
        'JJ': '\u5DF4\u897F\u5929\u99AC\u822A\u7A7A',
        'JI': 'Jade Cargo International',
        'JH': '\u6771\u5317\u5730\u5340\u822A\u7A7A',
        'JG': 'AIR GREECE-AERODROMISIS S.A.',
        'JF': 'LAB\u98DB\u884C\u670D\u52D9',
        'JE': '\u99AC\u601D\u514B\u65AF\u822A\u7A7A',
        'JD': 'Deer Jet',
        'JC': 'JAL EXPRESS',
        'JB': 'Helijet International Inc.',
        'JA': 'B & H Airlines',
        'IZ': '\u963F\u57FA\u4E9E\u4EE5\u8272\u5217\u822A\u7A7A',
        'IY': '\u4E5F\u9580\u822A\u7A7A',
        'IX': 'Air India Express',
        'J9': 'Guinee Airlines',
        'IW': 'Wings Air',
        'J8': 'Berjaya Air',
        'IV': 'Wind Jet',
        'J7': 'JMC Airlines Ltd',
        'IU': '\u65B0\u51E0\u5167\u4E9E',
        'J6': 'Larrys Flying',
        'IT': 'KINGFISHER AIRLINES',
        'J5': 'Donghai Airlines',
        'IS': 'ISLAND AIRLINES',
        'J4': 'Buffalo Air',
        'IR': '\u4F0A\u6717\u822A\u7A7A',
        'J3': 'Northwestern Air Lease',
        'IQ': '\u5967\u683C\u65AF\u5821\u822A\u7A7A',
        'J2': 'Azerbaijan Airlines',
        'IP': 'ATYRAU AUE JOLY',
        'IO': 'INDONESIAN  AIRLINES',
        'J0': 'Jet Link Express',
        'IN': '\u99AC\u5176\u9813\u822A\u7A7A',
        'IM': 'Menajet',
        'IL': '\u4F0A\u65AF\u5766\u5E03\u723E\u822A\u7A7A',
        'IK': 'IM\u822A\u7A7A',
        'IJ': 'Great Wall Airlines',
        'II': '\u7F8E\u570B\u5546\u696D\u822A\u7A7A',
        'IH': 'FALCON AVIATION',
        'IG': '\u9ED8\u91CC\u8FEA\u7D0D\u822A\u7A7A',
        'IF': 'ISLAS AIRWAYS',
        'IE': '\u6240\u7F85\u9580\u822A\u7A7A',
        'ID': 'Interlink Airlines PTY Ltd.',
        'IC': '\u5370\u5EA6\u5B89\u822A\u7A7A',
        'IB': '\u897F\u73ED\u7259\u570B\u5BB6\u822A\u7A7A',
        'IA': '\u4F0A\u62C9\u514B\u822A\u7A7A',
        'HZ': '\u85A9\u54C8\u6797\u822A\u7A7A',
        'HY': '\u70CF\u8332\u5225\u514B\u822A\u7A7A',
        'HX': '\u9999\u6E2F\u822A\u7A7A',
        'I9': 'Air Italy S.P.A',
        'HW': 'Iceland Express',
        'HV': '\u8CAB\u7A7A\u822A\u7A7A',
        'I7': 'Paramount Airways',
        'HU': '\u6D77\u5357\u822A\u7A7A',
        'I6': 'Sky Eyes',
        'HT': 'Hellenic Imperial Airway',
        'I5': 'Air Mali',
        'HS': 'Direktflyg',
        'I4': 'Rutair Ltd DBA Airlink',
        'HR': '\u6F22\u65AF\u822A\u7A7A',
        'HQ': 'HARMONY AIRWAYS',
        'HP': '\u7F8E\u897F\u822A\u7A7A',
        'I1': 'Eyeone',
        'HO': '\u5409\u7965\u822A\u7A7A',
        'HN': 'PROTEUS HELICOPTERES',
        'HM': '\u585E\u5E2D\u723E\u822A\u7A7A',
        'HK': '\u4EF0\u5149\u822A\u7A7A',
        'HJ': 'Hellas Jet SA',
        'HI': 'Papillon Airways',
        'HH': '\u51B0\u5CF6\u65D7\u5E5F\u822A\u7A7A',
        'HG': 'HAITI INTL AIRLINES',
        'HF': '\u54C8\u5E15\u514B-\u52DE\u57C3\u5FB7\u822A\u7A7A',
        'HE': '\u74E6\u723E\u7279\u822A\u7A7A',
        'HD': '\u5317\u6D77\u9053\u570B\u969B\u822A\u7A7A',
        'HC': 'LIP AIR LTD',
        'HB': 'HOMER AIR INC',
        'HA': '\u590F\u5A01\u5937\u822A\u7A7A',
        'GZ': '\u62C9\u7F85\u6E6F\u52A0\u822A\u7A7A',
        'GY': 'Gabon Airlines',
        'GX': '\u83F2\u5F8B\u8CD3\u592A\u5E73\u6D0B\u822A\u7A7A',
        'H9': 'Pegasus',
        'GW': '\u5EAB\u73ED\u822A\u7A7A',
        'H8': 'Dalavia-Far East Airways Khabarovsk',
        'GV': '\u91CC\u52A0\u5FEB\u905E',
        'H7': 'Eagle Aviation Uganda',
        'GU': '\u5A01\u5E1D\u99AC\u62C9\u822A\u7A7A',
        'H6': 'Hageland',
        'GT': '\u76F4\u5E03\u7F85\u9640\u822A\u7A7A',
        'H5': 'Magadan Airlines',
        'GS': 'Tianjin Airlines',
        'H4': 'Inter Islands Airlines',
        'GR': '\u5967\u91CC\u5C3C\u822A\u7A7A',
        'H3': 'Harbour Air',
        'GQ': '\u9AD8\u7A7A\u822A\u7A7A',
        'H2': 'Sky Service S.A.',
        'GP': 'PALAU TRANS PACIFIC',
        'GO': 'Go Fly Limited',
        'GN': '\u52A0\u84EC\u822A\u7A7A',
        'GM': 'AIR SLOVAKIA BWJ',
        'GL': 'AIR GREENLAND INC',
        'GK': 'Go One Airways',
        'GJ': '\u6B50\u6D32\u98DB\u884C\u822A\u7A7A',
        'GI': 'AIR COMPANY ITEK-AIR LTD',
        'GH': 'Globus LLC',
        'GG': 'AIR GUYANE',
        'GF': '\u6D77\u7063\u822A\u7A7A',
        'GE': '\u5FA9\u8208\u822A\u7A7A',
        'GD': 'AIR ALPHA GREENLAND',
        'GC': 'Gambia International Airlines',
        'GB': '\u5B5F\u52A0\u62C9\u822A\u7A7A',
        'GA': '\u5370\u5C3C\u5609\u9B6F\u9054\u822A\u7A7A',
        'FZ': 'FlyDubai',
        'FY': 'Firefly',
        'FX': '\u806F\u90A6\u5FEB\u905E',
        'G9': 'Air Arabia',
        'FW': 'FAIR INCORPORATED',
        'G8': 'Joint Stock Co Enkor',
        'FV': 'Rossiya Airlines',
        'G7': 'GoJet Airlines',
        'FU': '\u6CD5\u570B\u6FF1\u6D77\u822A\u7A7A',
        'G6': 'Angkor Airways',
        'FT': '\u66B9\u7C92\u822A\u7A7A',
        'G5': 'Enkor Airlines',
        'FS': 'MISSIONARY AVIATION',
        'G4': 'Allegiant Air LLC',
        'FR': '\u745E\u5B89\u822A\u7A7A',
        'G3': 'Varig-Gol Airlines',
        'FQ': 'Brindabella Airlines',
        'G2': 'Avirex Gabon',
        'FP': '\u81EA\u7531\u570B\u969B\u822A\u7A7A',
        'FO': 'EXPEDITION AIRWAYS',
        'G0': 'Ghana International Airlines',
        'FN': '\u6CD5\u570B\u5730\u5340\u822A\u7A7A',
        'FM': '\u4E0A\u6D77\u822A\u7A7A',
        'FL': '\u74B0\u4FC4\u7F85\u65AF\u822A',
        'FK': 'KEEWATIN AIR',
        'FJ': '\u6590\u6FDF\u822A\u7A7A',
        'FI': '\u51B0\u5CF6\u822A\u7A7A',
        'FH': '\u672A\u4F86\u570B\u969B\u822A\u7A7A',
        'FG': 'Ariana Afghan Airlines',
        'FF': '\u5BF6\u5854\u822A\u7A7A',
        'FE': 'Far Eastern Air Trans',
        'FD': 'Thai AirAsia',
        'FC': 'Finncomm Commuter Air',
        'FB': 'Bulgaria Air',
        'FA': 'Safair',
        'EZ': 'Sun Air of Scandinavia',
        'EY': '\u963F\u63D0\u54C8\u5FB7\u822A\u7A7A',
        'EX': 'AIR SANTO DOMINGO',
        'F9': 'Frontier Airlines',
        'EW': '\u6B50\u6D32\u4E4B\u7FFC\u822A\u7A7A',
        'F8': 'Freedom Airlines Incorporated',
        'EV': '\u6771\u5357\u4E9E\u5927\u897F\u6D0B\u822A\u7A7A',
        'F7': 'Darwin Airline SA',
        'EU': 'United Eagle Airlines',
        'F6': 'China National Aviation Corporation',
        'ET': '\u57C3\u8CFD\u4FC4\u6BD4\u4E9E\u822A\u7A7A',
        'F5': 'Cosmic Air',
        'ES': 'DHL International',
        'F4': 'Albarka Air',
        'ER': 'Astar Air Cargo Incorporated',
        'F3': 'Faso Airways',
        'EQ': 'Transportes Aereos Militates Ecuat',
        'F2': 'Fly Air',
        'EP': '\u4F0A\u6717\u963F\u68EE\u66FC\u822A\u7A7A',
        'F1': 'FareLogix',
        'EO': 'HEWA BORA AIRWAYS',
        'F0': 'Fset Partition 2P',
        'EN': '\u591A\u6D1B\u7C73\u8482\u822A\u7A7A',
        'EM': 'Aero Benin SA',
        'EL': '\u65E5\u7A7A\u822A\u7A7A',
        'EK': '\u963F\u806F\u914B\u822A\u7A7A',
        'EJ': '\u65B0\u82F1\u683C\u862D\u822A\u7A7A',
        'EI': '\u611B\u723E\u862D\u570B\u969B\u822A\u7A7A',
        'EH': 'ALL NIPPON NETWORK CO',
        'EG': 'Enerjet',
        'EF': '\u9060\u6771\u822A\u7A7A',
        'EE': 'AERO AIRLINES A.S.',
        'ED': 'Air Dominicana',
        'EC': 'Openskies BA European Ltd',
        'EB': 'Pullmantur Air',
        'EA': '\u6B50\u6D32\u822A\u7A7A\u5FEB\u904B',
        'DZ': 'TRANSCARAIBES AIR INTL',
        'DY': '\u632A\u5A01\u77ED\u9014\u822A\u7A7A',
        'DX': 'DANISH AIR TRANSPORT',
        'E9': 'Pan Am Clipper Connect',
        'DW': 'AERO-CHARTER',
        'E8': 'Alpi Eagles',
        'DV': 'PL Scat AirCompany',
        'E7': 'Fly European',
        'DU': 'HEMUS AIR',
        'E6': 'Aviaexpresscruise Airline',
        'DT': 'Taag Angola Airlines',
        'E5': 'Samara Airlines',
        'DS': '\u745E\u58EB\u6613\u6377\u822A\u7A7A',
        'E4': 'Aero Asia International Ltd',
        'DR': 'AIR LINK PTY. LTD',
        'E3': 'Domodedovo Airlines',
        'DQ': 'COASTAL AIR TRANSPORT',
        'E2': 'RIO Grande Air',
        'DP': '2000\u822A\u7A7A',
        'DO': 'AIR VALLEE S.P.A.',
        'E0': 'Eos Airlines',
        'DN': 'Senegal Airlines',
        'DM': 'Sterling Blue',
        'DL': '\u9054\u7F8E\u822A\u7A7A',
        'DK': 'EASTLAND AIR',
        'DJ': 'Virgin Australia',
        'DI': '\u5FB7\u82F1\u822A\u7A7A',
        'DH': 'Independence Air',
        'DG': '\u6771\u5357\u4E9E\u822A\u7A7A',
        'DF': 'Aerolineas De Baleares',
        'DE': '\u5EB7\u591A\u723E\u822A\u7A7A',
        'DD': 'Nok Air',
        'DC': '\u91D1\u8272\u822A\u7A7A',
        'DB': '\u4E0D\u91CC\u7279\u822A\u7A7A',
        'DA': 'AIR GEORGIA',
        'CZ': '\u4E2D\u570B\u5357\u65B9\u822A\u7A7A',
        'CY': '\u585E\u6D66\u8DEF\u65AF\u822A\u7A7A',
        'CX': '\u570B\u6CF0\u822A\u7A7A',
        'D9': 'Donavia',
        'CW': '\u99AC\u7D39\u723E\u5CF6\u822A\u7A7A',
        'D8': 'Djibouti Airlines',
        'CV': '\u76E7\u68EE\u5821\u822A\u7A7A',
        'D7': 'FLY ASIAN XPRESS',
        'CU': 'CUBANA AIRLINES',
        'D6': 'Interair South Africa',
        'CT': 'CAVE',
        'D5': 'DAU Air Verwaltungs AG',
        'CS': '\u7F8E\u570B\u5927\u9678\u822A\u7A7A (Mic)',
        'D4': 'Alidaunai',
        'CR': 'OAG Worldwide',
        'D3': 'Daallo Airlines',
        'CQ': 'Czech Connect Airlines',
        'D2': 'Severstal Joint Stock Co',
        'CP': 'Compass Airlines',
        'CO': '\u7F8E\u570B\u5927\u9678\u822A\u7A7A',
        'CN': 'ISLANDS NATIONAIR',
        'CM': '\u5DF4\u62FF\u99AC\u822A\u7A7A',
        'CL': '\u6F22\u838E\u57CE\u5E02\u822A\u7A7A',
        'CK': 'China Cargo Airlines',
        'CJ': 'China Northern Airlines',
        'CI': '\u4E2D\u83EF\u822A\u7A7A',
        'CH': 'BEMIDJI AVIATION SERVICE',
        'CG': '\u5DF4\u5E03\u4E9E\u65B0\u5E7E\u5167\u4E9E\u822A\u7A7A',
        'CF': 'CITY AIRLINE',
        'CE': 'Chalair Aviation',
        'CD': '\u806F\u76DF\u822A\u7A7A',
        'CC': 'MACAIR AIRLINES',
        'CB': 'SCOTAIRWAYS',
        'CA': '\u4E2D\u570B\u570B\u969B\u822A\u7A7A',
        'BZ': 'Blue Dart Aviation',
        'BY': 'Thomsonfly Limited',
        'BX': 'Air Busan',
        'C9': 'Cirrus Airlines',
        'BW': 'Caribbean Airlines',
        'C8': 'Chicago Express Airlines Inc',
        'BV': 'BLUE PANORAMA AIRLINES',
        'C7': 'Samarkand Airways',
        'BU': '\u5E03\u62C9\u68EE\u822A\u7A7A',
        'C6': 'CANJET AIRLINES',
        'BT': '\u6CE2\u7F85\u7684\u6D77\u822A\u7A7A',
        'C5': 'CommutAir',
        'BS': 'BRITISH INTL',
        'BR': '\u9577\u69AE\u822A\u7A7A',
        'C3': 'Icar',
        'BQ': 'Aeromar C. Pro. A.',
        'BP': '\u535A\u8328\u74E6\u7D0D\u822A\u7A7A',
        'BO': 'Bouraq Airlines',
        'C0': 'Centralwings',
        'BN': 'Bahrain Air',
        'BM': 'Bayu Indonesia',
        'BL': 'Jetstar Pacific',
        'BK': 'POTOMAC AIR',
        'BJ': '\u7A81\u5C3C\u65AF\u52AA\u5A01\u723E\u822A\u7A7A',
        'BI': '\u6C76\u840A\u7687\u5BB6\u822A\u7A7A',
        'BH': 'Hawkair Aviation Services',
        'BG': '\u5B5F\u52A0\u62C9\u822A\u7A7A',
        'BF': '\u822A\u7A7A\u670D\u52D9',
        'BE': 'FLYBE/\u6FA4\u897F\u6B50\u6D32\u822A\u7A7A\u6709\u9650',
        'BD': '\u82F1\u502B\u822A\u7A7A',
        'BC': 'SKYMARK AIRLINES',
        'BB': 'SANSA SVC AEREO NACIONAL',
        'BA': '\u82F1\u570B\u822A\u7A7A/\u82F1\u4E9E\u822A\u7A7A',
        'AZ': '\u7FA9\u5927\u5229\u822A\u7A7A',
        'AY': '\u82AC\u862D\u822A\u7A7A',
        'B9': 'Iran Air Tours',
        'AX': 'Trans States Airlines',
        'B8': 'Eritrean Airlines',
        'AW': 'DIRGANTARA AIR SERVICES',
        'B7': '\u7ACB\u69AE\u822A\u7A7A',
        'AV': 'AVIANCA\u54E5\u502B\u6BD4\u4E9E\u822A\u7A7A',
        'B6': 'Jetline Airways',
        'AU': '\u6FB3\u65AF\u7279\u62C9\u822A\u7A7A',
        'B5': 'East African Safari Air Express',
        'AT': '\u6469\u6D1B\u54E5\u7687\u5BB6\u822A',
        'B4': 'Zanair',
        'AS': '\u963F\u62C9\u65AF\u52A0\u822A\u7A7A',
        'B3': 'Bellview Airlines',
        'AR': '\u963F\u6839\u5EF7\u822A\u7A7A',
        'B2': 'Belavia',
        'AQ': '\u963F\u56C9\u54C8\u822A\u7A7A',
        'B1': 'Brewster Bus',
        'AP': '\u4E00\u865F\u822A\u7A7A',
        'AO': '\u6FB3\u4E9E\u822A\u7A7A',
        'AN': '\u6FB3\u6D32\u5B89\u6377\u822A\u7A7A',
        'AM': '\u58A8\u897F\u54E5\u822A\u7A7A',
        'AL': '\u7F8E\u570B\u4E2D\u897F\u90E8\u806F\u904B/\u7A7A\u4E2D\u4E4B\u8DEF\u822A\u7A7A',
        'AK': '\u4E9E\u6D32\u822A\u7A7A',
        'AJ': '\u6BD4\u5229\u6642\u570B\u969B\u822A\u7A7A',
        'AI': '\u5370\u5EA6\u822A\u7A7A',
        'AH': '\u963F\u723E\u53CA\u5229\u4E9E\u822A\u7A7A',
        'AG': '\u7701\u822A\u7A7A',
        'AF': '\u6CD5\u570B\u822A\u7A7A',
        'AE': '\u83EF\u4FE1\u822A\u7A7A',
        'AD': 'AIR PARADISE INTL',
        'AC': '\u52A0\u62FF\u5927\u822A\u7A7A',
        'AB': 'Air Berlin Plc and Co',
        'AA': '\u7F8E\u570B\u822A\u7A7A',
        'A9': 'Georgian Airlines',
        'A8': 'Benin Golf Air',
        'A7': 'Air Comet',
        'A6': 'AIR ALPS AVIATION',
        'A5': 'Airlinair',
        'A4': 'Aerocon',
        'A3': 'Aegean Airlines',
        'A2': 'Cielos Del Peru',
        'A1': 'G2 Switchworks Corporation',
        'A0': 'L Avion',
        '9Y': 'Air Kazakstan',
        '9X': 'New Axis Airways',
        '9W': '\u5370\u5EA6\u6377\u9054\u822A\u7A7A',
        '9V': 'Avior Airlines',
        '9U': '\u9ED8\u723E\u591A\u74E6\u822A\u7A7A',
        '9T': 'ACT Airlines',
        '9S': 'Spring Airlines',
        '9R': '\u666E\u5409\u822A\u7A7A',
        '9Q': 'PB Air',
        '9P': 'PANAIR S.P.A.',
        '9O': 'Transportes Aereos Inter',
        '9N': 'SATENA',
        '9M': '\u4E2D\u592E\u5CB3\u822A\u7A7A',
        '9L': '\u79D1\u723E\u6839\u822A\u7A7A',
        '9K': '\u79D1\u5FB7\u89D2\u822A\u7A7A',
        '9J': '\u592A\u5E73\u6D0B\u5CF6\u822A\u7A7A',
        '9I': '\u6CF0\u570B\u5929\u9DF9\u822A\u7A7A',
        '9H': 'Dutch Antilles Express',
        '9G': 'Galaxy Airways',
        '9F': 'Eurostar',
        '9E': 'Pinnacle Airlines',
        '9D': 'Toumai Air Tchad',
        '9C': 'Spring Airlines',
        '9B': 'Accesrail Inc.',
        '9A': 'VISA AIRWAYS PVT LTD',
        '8Z': 'Laser',
        '8Y': 'China Postal Airlines',
        '8W': 'AIR TEAM',
        '8V': 'WRIGHT AIR',
        '8U': 'AFRIQIYAH AIRWAYS',
        '8T': 'Air Tindi Ltd',
        '8S': 'TurboJet',
        '8R': 'TRIP AIR',
        '8Q': 'Baker Aviation Inc',
        '8P': '\u592A\u5E73\u6D0B\u4E4B\u5CB8\u822A\u7A7A',
        '8O': 'West Coast Air',
        '8N': 'NORDKALOTTFLYG AB',
        '8M': '\u7DEC\u7538\u822A\u7A7A',
        '8L': 'Lucky Air',
        '8K': 'ANGEL AIRLINES SA',
        '8J': 'Societe D\'Investissement Aerien dba Jet4You',
        '8I': 'MyAir',
        '8H': 'Highland Airways',
        '8G': 'ANGEL AIRLINES',
        '8F': '\u83F2\u585E\u723E\u822A\u7A7A',
        '8E': '\u767E\u4EE4\u822A\u7A7A',
        '8D': 'Expo Aviation',
        '8C': '\u4E1C\u661F\u822A\u7A7A',
        '8B': 'CARIBBEAN STAR AIRLINES',
        '8A': 'Atlas Blue',
        '7Z': '\u840A\u514B\u822A\u7A7A\uFF08\u5DF4\u54C8\u99AC\uFF09',
        '7Y': 'Mid Airlines',
        '7W': 'Wind Rose',
        '7V': 'PELICAN AIR',
        '7U': 'AVIAENEGRO',
        '7T': 'AIR GLACIERS',
        '7S': 'C Air Jet Airlines',
        '7R': 'BRA Transportes Aereos',
        '7Q': 'Pawa Dominicana',
        '7P': 'Batavia Air',
        '7O': 'Galaxy Air',
        '7N': 'INLAND AVIATION SVCS INC',
        '7M': '\u79CB\u660E\u822A\u7A7A',
        '7L': 'AEROCARIBBEAN S. A.',
        '7K': 'VISA AIRWAYS PVT LTD',
        '7J': 'Tajikair',
        '7I': 'Insel Air',
        '7H': '\u6642\u4EE3\u822A\u7A7A',
        '7G': 'MK Airlines',
        '7F': '\u4E00\u6D41\u822A\u7A7A',
        '7E': 'AEROLINE GMBH',
        '7D': 'DONBASS',
        '7C': '\u6FDF\u5DDE\u822A\u7A7A',
        '7B': 'Atlant-Soyuz',
        '7A': 'Air Next',
        '6Z': '\u70CF\u514B\u862D\u8CA8\u904B\u822A\u7A7A',
        '6Y': '\u62C9\u812B\u7DAD\u4E9E\u5305\u6A5F\u822A\u7A7A',
        '6W': '\u85A9\u62C9\u6258\u592B\u822A\u7A7A',
        '6V': 'LIGNES AERIENNES',
        '6U': '\u70CF\u514B\u862D\u822A\u7A7A',
        '6T': 'Almaty Aviation',
        '6S': 'KATO AIRLINES',
        '6R': '\u683C\u9B6F\u5409\u4E9E\u822A\u7A7A',
        '6Q': 'Slovak Airlines',
        '6P': 'CLUBAIR SIXGO SPA',
        '6N': 'TRANS TRAVEL AIRLINES',
        '6M': 'MAJESTIC AIR PL',
        '6L': 'Aklak Inc',
        '6K': 'ASIAN SPIRIT\u822A\u7A7A\u516C\u53F8',
        '6J': 'Skyservice Airlinesd/b/a Roots Air',
        '6H': '\u4EE5\u65AF\u96F7\u822A\u7A7A',
        '6G': 'AIR WALES',
        '6F': 'Aerotrans Airlines Ltd',
        '6E': 'CITY AIR GERMANY AG',
        '6D': '\u67CF\u5229\u9054\u822A\u7A7A',
        '6C': 'CAPE SMYTHE AIR SERVICE',
        '6B': '\u4E0D\u5217\u985B\u822A\u7A7A',
        '6A': 'AVIACSA',
        '5Z': 'Bismillah Airlines',
        '5Y': 'Atlas Air',
        '5X': 'UPS',
        '5W': 'Iceland Express',
        '5V': 'LVIV AIRLINES',
        '5U': 'LINEAS AEREAS DEL ESTADO',
        '5T': 'CANADIAN NORTH',
        '5S': 'SERVICIOS AEREOS',
        '5R': 'Karthago Airlines',
        '5Q': 'Best Aviation',
        '5P': 'Aerolinea Principal',
        '5N': 'ARKHANGELSK AIRLINES',
        '5M': '\u7F8E\u4F73\u822A\u7A7A',
        '5L': 'AEROSUR',
        '5K': 'Hi Fly Transportes S.A',
        '5J': '\u5BBF\u9727\u592A\u5E73\u6D0B\u822A\u7A7A',
        '5I': 'Air G',
        '5H': 'Five Fourty Aviation Ltd',
        '5G': '\u7A7A\u4E2D\u822A\u7A7A\u670D\u52D9',
        '5F': 'Arctic Circle Air Service',
        '5E': 'Equaflight Service',
        '5D': 'AEROLITORAL S.A. DE C.V',
        '5C': 'C.A.L.Cargo Airlines',
        '5B': 'Euro-Asia International',
        '5A': 'Alpine Aviation',
        '4Z': 'SA\u822A\u7A7A',
        '4Y': 'YUTE AIR ALASKA INC',
        '4W': 'Allied Air',
        '4V': 'Birdy Airlines S.A.',
        '4U': 'Germanwings',
        '4T': 'GAWNE AVIATION',
        '4S': 'Sol Air',
        '4R': 'Orient Eagle Airways',
        '4Q': 'Safi Airways',
        '4P': 'BUSINESS AVIATION',
        '4O': 'Ocean Airlines',
        '4N': '\u5317\u65B9\u822A\u7A7A',
        '4M': 'LAN DOMINICANA',
        '4L': 'Georgian International Airlines',
        '4K': 'Askari Aviation',
        '4J': 'Somon Air',
        '4H': 'United Airways Bangladesh',
        '4G': 'Gazpromavia Aviation',
        '4F': '\u822A\u90FD',
        '4E': 'TANANA AIR SERVICES',
        '4D': '\u897F\u5948\u822A\u7A7A',
        '4C': 'Aires',
        '4B': 'OLSON AIR SERVICE',
        '4A': 'F. S. AIR SERVICE INC',
        '3Z': 'Everts Air',
        '3Y': 'Kartika Airlines',
        '3X': 'Japan Air Commuter',
        '3W': 'Euromanx',
        '3V': 'TNT Airways SA',
        '3U': '\u56DB\u5DDD\u822A\u7A7A',
        '3T': 'Turan Air Airline',
        '3S': 'C.A.I.R.E.',
        '3R': 'Gromov Flight Research',
        '3Q': '\u96F2\u5357\u822A\u7A7A',
        '3P': 'Tiara Air',
        '3O': 'Air Arabia Maroc',
        '3N': '\u70CF\u723E\u52A0\u822A\u7A7A',
        '3M': '\u7063\u6D41\u570B\u969B\u822A\u7A7A',
        '3L': 'INTERSKY LUFTFAHRT',
        '3K': '\u6377\u661F\u822A\u7A7A',
        '3J': 'ZIP AIR INC',
        '3I': 'Aerolineas Del Sur',
        '3H': '\u4F0A\u52AA\u4F0A\u7279\u822A\u7A7A',
        '3G': '\u963F\u7279\u862D\u7D22\u5C24\u8332\u822A\u7A7A',
        '3F': 'PACIFIC AIRWAYS INC',
        '3E': 'Air Choice One',
        '3D': '\u4EE3\u5C3C\u59C6\u822A\u7A7A',
        '3C': 'CORPORATE AIRLINES',
        '3B': 'Burkina Airlines',
        '3A': 'Chu Kong Passenger Trans',
        '2Z': 'Joint Stock Co Voronezh',
        '2Y': 'Air Andaman',
        '2X': 'AIRWAY EXPRESS INC',
        '2W': 'Welcome Air Luftfahrt',
        '2V': 'AMTRAK',
        '2U': 'Sun d\'Or International Airlines',
        '2T': 'Alajnihah Airways',
        '2S': 'ISLAND EXPRESS',
        '2R': '\u52A0\u62FF\u5927\u570B\u5BB6\u9435\u8DEF',
        '2Q': 'Phoenix Airways',
        '2P': '\u83F2\u9DF9\u822A\u7A7A',
        '2N': 'NEX Time Jet',
        '2M': 'Moldavian Airlines',
        '2L': 'Helvetic',
        '2K': 'AEROLINEAS GALAPAGOS',
        '2J': 'Air Burkina',
        '2I': 'Star Peru',
        '2H': 'Transavia',
        '2G': 'Debonair',
        '2F': 'Frontier Flying Service',
        '2E': 'SMOKEY BAY AIR INC.',
        '2D': 'AERO VIP S.A.',
        '2C': 'SNCF',
        '2B': 'Aerocondor',
        '2A': 'AEROSERVICIOS ECUATORIAN',
        '1U': 'ITA Software',
        '1T': '1Time Airline',
        '1P': 'Worldspan',
        '1N': 'Navitaire Inc.',
        '1L': '\u897F\u65B9\u822A\u7A7A\u5FEB\u6377',
        '1K': 'SOUTHERN CROSS DISTRIBUTION SYSTEMS',
        '1J': 'Axess International Network Inc.',
        '1I': '\u5766\u5E15\u822A\u7A7A',
        '1G': 'Galileo',
        '1F': 'INFINI TRAVEL INFORMATION INC',
        '1E': 'TRAVELSKY TECHNOLOGY',
        '1C': 'EDS Information',
        '1B': 'Abacus International',
        '1A': 'Amadeus',
        '0Y': 'Yeti Airline',
        '0P': 'Palau Asia Pacific Air',
        '0O': 'STA Travel',
        '0D': 'Darwin Airline',
        '0B': 'Blue Air Transport'
    },
    zh_CN: {
        'ZY': 'Ada Air',
        'ZX': 'BC\u822A\u7A7A\u516C\u53F8',
        'ZW': '\u5A01\u65AF\u5EB7\u661F\u822A\u7A7A\u516C\u53F8',
        'ZV': '\u4E2D\u897F\u90E8\u822A\u7A7A\u516C\u53F8',
        'ZU': 'HELIOS AIRWAYS/HELIOS AIRWAYS',
        'ZT': 'AIR ZAMBEZI/AIR ZAMBEZI',
        'ZS': '\u610F\u5927\u5229\u963F\u7956\u62C9\u822A\u7A7A',
        'ZR': '\u7A46\u514B\u822A\u7A7A',
        'ZQ': 'CARIBBEAN SUN AIRLINES/CARIBBEAN SUN AIRLINES INC.',
        'ZP': 'Silk Way',
        'ZO': 'GREAT PLAINS AIRLINES/OZARK AIR LINES INC.',
        'ZN': 'AIR BOURBON/AIR BOURBON',
        'ZM': 'ALLIANCE EXPRESS RWANDA/ALLIANCE EXPRESS RWANDA',
        'ZL': '\u5730\u533A\u5FEB\u9012/\u9ED1\u6CFD\u5C14\u987F\u822A\u7A7A',
        'ZK': '\u5927\u6E56\u822A\u7A7A',
        'ZJ': 'Zambezi Airlines',
        'ZI': '\u963F\u7956\u5C14\u5929\u9E70\u5EA7\u822A\u7A7A',
        'ZH': '\u6DF1\u5733\u822A\u7A7A',
        'ZG': 'Viva Macau Limited',
        'ZF': 'Athens Airways',
        'ZE': 'LINEAS AEREAS AZTECA/LINEAS AEREAS AZTECA S.A. DE C.V',
        'ZD': 'FLYING DANDY/AIR NET 21',
        'ZC': '\u65AF\u5A01\u58EB\u5170\u7687\u5BB6\u822A\u7A7A',
        'ZB': '\u541B\u4E3B\u822A\u7A7A',
        'ZA': 'ASTAIR LIMITED COMPANY/ASTAIR LIMITED COMPANY',
        'YZ': 'TACAECUADOR S.A./TACAECUADOR S.A.',
        'YY': '\u6240\u6709\u822A\u7A7A\u516C\u53F8',
        'Z9': 'Aero Zambia',
        'YX': '\u4E2D\u897F\u90E8\u5FEB\u8FD0\u822A\u7A7A',
        'Z8': 'Amaszonas SA',
        'YW': '\u7EB3\u65AF\u7EF0\u822A\u7A7A',
        'Z7': 'ADC Airlines',
        'YV': '\u6885\u8428\u822A\u7A7A',
        'Z6': 'Dnieproavia Joint Stock Aviation Company',
        'YU': 'DOMINAIR//AEROLINEAS DOMINICANAS S.A. DOMINA',
        'Z5': 'GMG Airlines',
        'YT': 'Silverjet',
        'Z4': 'Zoom Airlines Inc',
        'YS': 'Regional CAE',
        'Z3': 'Promech Inc',
        'YR': '\u98CE\u666F\u822A\u7A7A',
        'Z2': 'Zest Airways',
        'YQ': 'Aircompany Polet',
        'YP': '\u52B3\u57C3\u5FB7\u822A\u7A7A',
        'YO': 'HELI AIR MONACO/HELI AIR MONACO',
        'YN': '\u514B\u91CC\u6BD4\u514B\u822A\u7A7A\u516C\u53F8',
        'YM': 'MONTENEGRO AIRLINES/MONTENEGRO AIRLINES',
        'YL': 'YAMAL AIRLINES/YAMAL AIRLINES',
        'YK': 'KIBRIS TURKISH AIRLINES/KIBRIS TURKISH AIRLINES',
        'YJ': 'Amiyi Airlines',
        'YI': 'AIR SUNSHINE INC/AIR SUNSHINE INC.',
        'YH': 'WEST CARIBBEAN AIRWAYS/WEST CARIBBEAN AIRWAYS',
        'YG': '\u5357\u65B9\u822A\u7A7A',
        'YE': 'YANDA AIRLINES/YANDA AIRLINES',
        'YD': 'Mauritania Airways',
        'YC': 'Yamal Airlines',
        'YB': '\u5357\u975E\u5FEB\u9012',
        'XZ': 'FLUGFELAG AUSTURLANDS/FLUGFELAG AUSTURLANDS',
        'XY': 'National Air Services',
        'Y9': 'Kish Airlines',
        'Y8': 'Yangtze River Express',
        'XW': 'Sky Express',
        'Y7': 'Silverjet',
        'XV': 'WALTAIR I LINKOPING AB/WALTAIR I LINKOPING AB',
        'Y6': 'Cambodia Airlines',
        'XU': 'AFRICAN EXPRESS AIRWAYS/AFRICAN EXPRESS AIRWAYS K LTD.',
        'Y5': 'Asia Wings',
        'XT': 'Skystar Airways',
        'Y4': 'Eagle Aviation',
        'XS': 'SITA-Airlines Worldwide',
        'Y3': 'Driessen Services Incorporated',
        'XR': '\u897F\u65B9\u5929\u7A7A\u822A\u7A7A',
        'Y2': 'Alliance Air',
        'XQ': '\u592A\u9633\u5FEB\u9012\u822A\u7A7A',
        'XP': 'TAHOE AIR/TAHOE AIR',
        'XO': '\u65B0\u7586\u822A\u7A7A',
        'XN': 'Air Nepal',
        'XM': '\u610F\u5927\u5229\u822A\u7A7A\u6377\u8FD0',
        'XL': 'LANECUADOR AEROLANE SA/LINEAS AEREAS NACIONALES DE ECUADOR',
        'XK': 'CCM Airlines',
        'XJ': '\u6885\u8428\u5DF4\u822A\u7A7A',
        'XI': 'Aeronautical Telecommunications Limited',
        'XH': 'Hndlg Angent',
        'XG': 'AIR MALI INTERNATIONAL/AIR MALI INTERNATIONAL',
        'XF': 'VLADIVOSTOK AIR/VLADIVOSTOK AIR',
        'XE': 'ExpressJet',
        'XD': 'Airlines Reporiting Corporation',
        'XB': 'Internatioanl Air Transport Association',
        'XA': 'ARINC Aeronautical Radio',
        'WZ': 'West African Airlines',
        'WY': '\u963F\u66FC\u822A\u7A7A',
        'X9': 'City Star Airlines',
        'WX': 'City Jet',
        'X8': 'Icaro',
        'WW': 'bmibaby',
        'X7': 'Air Service',
        'WV': 'WESTEASTAIR AB/WESTEASTAIR AB',
        'X6': 'Airlines Reporting Corporation',
        'WU': 'TIKAL JETS SA/TIKAL JETS SA',
        'X5': 'Afrique Airlines',
        'WT': 'Wasaya Airways',
        'WS': '\u897F\u65B9\u55B7\u6C14\u822A\u7A7A',
        'X3': 'TUIfly',
        'WR': '\u6C64\u52A0\u7687\u5BB6\u822A\u7A7A',
        'X2': 'Airlines Reporting Corporation',
        'WQ': '\u7F57\u9A6C\u7EF4\u4E9A\u822A\u7A7A',
        'WP': 'ALOHA ISLANDAIR/ALOHA ISLANDAIR INC.',
        'WO': '\u7F8E\u56FD\u4E16\u754C\u822A\u7A7A',
        'WN': '\u7F8E\u56FD\u897F\u5357\u822A\u7A7A',
        'WM': '\u5411\u98A8\u7FA4\u5CF6\u570B\u969B\u822A\u7A7A\u516C\u53F8',
        'WL': 'AEROPERLAS/AEROPERLAS',
        'WK': 'Edelweiss Air',
        'WJ': 'Air Labrador',
        'WI': 'U-LAND AIRLINES/U-LAND AIRLINES',
        'WH': '\u4E2D\u570B\u897F\u5317\u822A\u7A7A',
        'WG': 'WASAYA AIRWAYS L.P./WASAYA AIRWAYS L.P.',
        'WF': '\u632A\u5A01\u7EF4\u5FB7\u52D2\u822A\u7A7A',
        'WE': '\u6CF0\u56FD\u5FAE\u7B11\u822A\u7A7A',
        'WD': 'DAS AIR LIMITED/DAS AIR LIMITED',
        'WC': '\u4F0A\u65AF\u83B1\u7EB3\u822A\u7A7A',
        'WB': 'RWANDAIR EXPRESS/RWANDAIR EXPRESS',
        'WA': '\u8377\u5170\u7687\u5BB6\u822A\u7A7A\u57CE\u5E02\u77ED\u9014',
        'VZ': 'MyTravel Airways Limited',
        'VY': 'Vueling Airlines SA',
        'W9': '\u84B2\u7518\u822A\u7A7A',
        'VX': 'Virgin America',
        'VW': 'Transportesaeromar',
        'W7': 'Sayakhat Airlines',
        'VV': 'AEROSVIT AIRLINES/AEROSVIT AIRLINES',
        'W6': 'West Isle Air',
        'VU': 'Air Ivoire',
        'W5': '\u4F0A\u6717\u7A46\u7F55\u822A\u7A7A',
        'VT': '\u5854\u5E0C\u63D0\u822A\u7A7A',
        'W4': 'LC Busre',
        'VS': '\u7EF4\u73CD\u822A\u7A7A',
        'W3': 'Arik Air',
        'VR': 'TACV-CABO VERDE AIRLINE',
        'W2': 'FlexFlight',
        'VQ': 'Viking Hellas Airlines',
        'VP': '\u5723\u4FDD\u7F57\u822A\u7A7A',
        'VO': '\u5965\u5730\u5229\u8482\u7F57\u6797\u822A\u7A7A',
        'VN': '\u8D8A\u5357\u822A\u7A7A',
        'VM': 'Aerochaco',
        'VL': 'INTL EXPRESS AIR CHARTER/INTL EXPRESS AIR CHARTER LTD',
        'VK': 'Air Nigeria',
        'VJ': 'JATAYU AIRLINES/JATAYU AIRLINES',
        'VI': '\u4F0F\u723E\u52A0\u822A\u7A7A\u516C\u53F8',
        'VH': 'Aeropostal',
        'VG': '\u6BD4\u5229\u65F6\u7EF4\u5C14\u59C6\u822A\u7A7A',
        'VF': '\u60E0\u65C5\u822A\u7A7A',
        'VE': '\u59D4\u5185\u745E\u62C9\u56FD\u5185\u822A\u7A7A',
        'VD': '\u6B27\u6D32\u8DE8\u5730\u533A\u822A\u7A7A',
        'VC': 'Strategic Aviation',
        'VB': '\u82F1\u56FD\u6885\u5C14\u65AF\u514B\u822A\u7A7A',
        'VA': '\u7DAD\u73CD\u6FB3\u6D32\u822A\u7A7A',
        'UZ': 'Buraq Air Transport',
        'UY': '\u5580\u9EA5\u9686\u822A\u7A7A\u516C\u53F8',
        'V9': 'Bashkir Airlines',
        'UX': '\u6B50\u7F85\u5DF4\u822A\u7A7A\u516C\u53F8',
        'V8': 'Iliamna Air Taxi Inc',
        'UW': 'Universal Airlines',
        'V7': 'Air Senegal International',
        'UV': 'HELICOPTEROS DEL SURESTE/HELICOPTEROS DEL SURESTE S.A.',
        'V6': 'Air Atlantique',
        'UU': '\u6FB3\u5927\u5229\u4E9A\u822A\u7A7A',
        'V5': 'VIP Wings Limited',
        'UT': 'UTA/UNION DE TRANSPORTS AERIENS',
        'V4': 'Vensecar International',
        'US': '\u5408\u4F17\u56FD\u822A\u7A7A',
        'V3': 'Carpatair',
        'UR': '\u4E4C\u514B\u5170\u56FD\u5BB6\u822A\u7A7A',
        'V2': 'Vision Airlines',
        'UQ': 'OCONNOR AIRLINES/OCONNOR AIRLINES',
        'UP': '\u5DF4\u54C8\u9A6C\u822A\u7A7A',
        'V0': 'Conviasa',
        'UO': '\u6E2F\u8054\u822A\u7A7A',
        'UN': '\u4FC4\u7F85\u65AF\u5168\u797F',
        'UM': '\u6D25\u5DF4\u5E03\u97CB\u822A\u7A7A\u516C\u53F8',
        'UL': '\u65AF\u91CC\u862D\u5361\u822A\u7A7A',
        'UK': '\u8377\u5170\u7687\u5BB6\u822A\u7A7A\u82F1\u56FD',
        'UJ': 'MONTAIR AVIATION INC/MONTAIR AVIATION INC',
        'UI': '\u6B27\u6D32\u585E\u6D66\u8DEF\u65AF\u822A\u7A7A',
        'UH': 'US Helicopter Corp',
        'UG': 'Sevenair S.A.',
        'UF': 'UKRAINIAN MEDITERRANEAN AIRLINES',
        'UE': 'U Air',
        'UD': '\u8D6B\u514B\u65AF\u822A\u7A7A',
        'UC': '\u62C9\u5FB7\u79D1\u822A\u7A7A',
        'UB': '\u7F05\u7538\u822A\u7A7A',
        'UA': '\u7F8E\u56FD\u8054\u5408\u822A\u7A7A',
        'TZ': '\u73AF\u7F8E\u56FD\u822A\u7A7A\u516C\u53F8',
        'TY': '\u5580\u91CC\u591A\u5C3C\u4E9A\u822A\u7A7A',
        'U9': 'Tatarstan',
        'TX': 'AIR CARAIBES/LA CARIBEENE DES TRANSPORTS AERIENS',
        'U8': 'Armavia',
        'TW': 'TWA \u822A\u7A7A',
        'U7': 'Air Uganda',
        'TV': '\u7EF4\u5C14\u4EAC\u5FEB\u8FD0',
        'U6': 'Ural Airlines',
        'TU': '\u7A81\u5C3C\u65AF\u822A\u7A7A\u516C\u53F8',
        'U5': 'USA 3000 Airlines',
        'TT': 'Tiger Airways Australia',
        'U4': 'Progress Multitrade',
        'TS': '\u8DE8\u5927\u897F\u6D0B\u822A\u7A7A',
        'U3': 'AS Avies',
        'TR': '\u73AF\u5DF4\u897F\u822A\u7A7A',
        'U2': 'Easyjet Airline Company',
        'TQ': 'TANDEM AERO LTD/TANDEM AERO LTD',
        'TP': 'Tap Air Portugal',
        'U0': 'Sabre Airline',
        'TO': '\u603B\u7EDF\u822A\u7A7A',
        'TN': 'AIR TAHITI NUI/AIR TAHITI NUI',
        'TM': '\u83AB\u6851\u6BD4\u514B\u822A\u7A7A\u516C\u53F8',
        'TL': 'AirNorth Regional',
        'TK': '\u571F\u8033\u5176\u822A\u7A7A',
        'TJ': 'TANS/TRANSPORTES AEREOS NACIONALES DE SELVA',
        'TI': 'Air Continental Africa',
        'TH': 'Transmile Air Services',
        'TG': '\u6CF0\u56FD\u56FD\u9645\u822A\u7A7A',
        'TF': '\u9A6C\u5C14\u83AB\u822A\u7A7A',
        'TE': 'FlyLal',
        'TD': 'ATLANTIS EUROPEAN/ATLANTIS EUROPEAN AIRWAYS',
        'TC': '\u5766\u6851\u5C3C\u4E9A\u822A\u7A7A',
        'TB': 'Jetairfly.com',
        'TA': '\u5854\u5361\u570B\u969B\u822A\u7A7A\u516C\u53F8',
        'SZ': 'Air South West Limited',
        'SY': '\u9633\u5149\u4E4B\u4E61\u822A\u7A7A',
        'T9': 'TRANSMERIDIAN AIRLINES/TRANSMERIDIAN AIRLINES INC',
        'SX': 'AEROEJECUTIVO/AEROEJECUTIVO S.A. DE C.V.',
        'T8': 'STA Trans African Air',
        'SW': '\u7EB3\u7C73\u6BD4\u4E9A\u822A\u7A7A',
        'T7': 'Twin Jet',
        'SV': '\u6C99\u7279\u963F\u62C9\u4F2F\u822A\u7A7A',
        'T6': 'Tavrey Aircompany',
        'SU': '\u4FC4\u7F57\u65AF\u822A\u7A7A',
        'T5': 'Turkmenistan Airlines',
        'ST': '\u5FB7\u56FD\u5305\u673A',
        'T4': 'Trip Linhas Aereas',
        'SS': 'Corsair International',
        'T3': 'Eastern Airways',
        'SR': '\u745E\u58EB\u822A\u7A7A\u516C\u53F8',
        'T2': 'Nakima Air Service',
        'SQ': '\u65B0\u52A0\u5761\u822A\u7A7A',
        'SP': 'Sociedade Acoreana De Transportes',
        'T0': 'TACA Peru',
        'SO': 'Sosoliso Airlines',
        'SN': '\u5E03\u9C81\u585E\u5C14\u822A\u7A7A\u516C\u53F8',
        'SM': 'Swedline Express',
        'SL': 'RIO SUL SERVICOS',
        'SK': '\u5317\u6B27\u822A\u7A7A',
        'SJ': 'AVIANDINA S.A.C./AVIANDINA S.A.C.',
        'SI': 'SKYNET AIRLINES/SKYNET AIRLINES LIMITED',
        'SH': '\u56FE\u5362\u5179\u56FD\u9645\u822A\u7A7A',
        'SG': 'SpiceJet',
        'SF': 'TASSILI AIRLINES/TASSILI AIRLINES',
        'SE': 'XL Airways France',
        'SD': '\u8607\u4E39\u822A\u7A7A\u516C\u53F8',
        'SC': '\u5C71\u4E1C\u822A\u7A7A',
        'SB': '\u5580\u91CC\u591A\u5C3C\u4E9A\u822A\u7A7A',
        'SA': '\u5357\u975E\u822A\u7A7A',
        'RZ': 'SANSA/\u56FD\u6C11\u822A\u7A7A\u670D\u52A1',
        'RY': 'EUROPEAN EXECUTIVE EXP/EUROPEAN EXECUTIVE EXPRESS',
        'S9': 'SWE Aviation Europe',
        'RX': '\u5308\u7259\u5229\u822A\u7A7A\u5FEB\u9012',
        'S8': 'Estonian Aviation Company',
        'RW': 'RAS\u822A\u7A7A',
        'S7': 'S7 Airlines',
        'RV': 'Caspian Airlines',
        'S6': 'Sun Air',
        'RU': 'TCI Skyking',
        'S5': 'Shuttle America',
        'RT': 'RAK Airways',
        'S4': 'Sata International',
        'RS': 'Royal Flight of Oman',
        'S3': 'Santa Barbara Airlines',
        'RR': 'ROYAL AIRFORCE/ROYAL AIRFORCE',
        'S2': 'Jet Lite',
        'RQ': 'Kam Air',
        'S1': 'Lufthansa System AG',
        'RP': '\u7F8E\u56FD\u8096\u6258\u5938\u822A\u7A7A',
        'S0': 'Slok Air International',
        'RO': '\u7F85\u99AC\u5C3C\u4E9E\u822A\u7A7A\u516C\u53F8',
        'RN': 'PARADISE AIR/SUM AIR',
        'RM': '\u6469\u5C14\u591A\u74E6\u822A\u7A7A',
        'RL': 'Royal Falcon Airlines',
        'RK': '\u975E\u6D32\u822A\u7A7A',
        'RJ': '\u7687\u5BB6\u7EA6\u65E6\u822A\u7A7A',
        'RI': '\u5370\u5EA6\u5C3C\u897F\u4E9A\u66FC\u8FBE\u62C9\u822A\u7A7A',
        'RH': 'Regionair',
        'RG': '\u5DF4\u897F\u822A\u7A7A',
        'RF': 'ORD AIR CHARTER/ORD AIR CHARTER PTY LTD',
        'RE': '\u963F\u4F26\u822A\u7A7A',
        'RD': '\u745E\u5B89\u56FD\u9645\u822A\u7A7A',
        'RC': '\u6CD5\u7F57\u7FA4\u5C9B\u5927\u897F\u6D0B\u822A\u7A7A',
        'RB': '\u963F \u62C9\u4F2F\u6558\u5229\u4E9E\u822A\u7A7A\u516C\u53F8',
        'RA': '\u7687\u5BB6\u5C3C\u6CCA\u5C14\u822A\u7A7A',
        'QZ': 'Indonesia AirAsia',
        'QY': 'AERO VIRGIN ISLANDS/AERO VIRGIN ISLANDS CORP',
        'R9': 'Camai Air',
        'QX': '\u7F8E\u6D32\u5730\u5E73\u7EBF\u822A\u7A7A',
        'R8': 'Kyrghyzstan Airlines',
        'QW': 'Blue Wings AG',
        'R7': 'Aserco Airlines',
        'QV': '\u8001\u631D\u822A\u7A7A',
        'R6': 'Air Srpska',
        'QU': 'Airline Utair Ukraine',
        'R5': 'Malta Air Charter',
        'QT': 'REGIONAL AIR PTY/REGIONAL AIR PTY LTD',
        'R4': 'The State Transport Company Russia',
        'QS': 'Smartwings',
        'R3': 'Yakutia Airlines',
        'QR': '\u5361\u5854\u5C14\u822A\u7A7A',
        'R2': 'Orenair',
        'QQ': '\u8054\u76DF\u822A\u7A7A',
        'QP': 'Regional Air',
        'R0': 'Royal Airlines',
        'QO': '\u592A\u5E73\u6D0B\u4E4B\u6E90\u822A\u7A7A',
        'QN': 'Air Armenia',
        'QM': '\u99AC\u62C9\u7DAD\u822A\u7A7A\u516C\u53F8',
        'QL': 'Aero Lanka',
        'QK': 'Air Canada Jazz',
        'QJ': 'AIR COMPANY LATPASS/AIR COMPANY LATPASS',
        'QI': '\u4E39\u9EA6\u8F9B\u4F2F\u822A\u7A7A',
        'QH': 'ALTYN AIR AIRLINES/ALTYN AIR AIRLINES',
        'QG': 'DYNAMIC AIR/DYNAMIC AIR',
        'QF': '\u6FB3\u6D32\u822A\u7A7A',
        'QE': '\u514B\u7F57\u65AF\u6B27\u6D32\u822A\u7A7A/\u6B27\u6D32\u5927\u9646\u822A\u7A7A',
        'QD': 'AIR CLASS LINEAS/AIR CLASS LINEAS AEREAS',
        'QC': 'Cameroon Arilines',
        'QB': 'Georgian National Airlines',
        'QA': 'Click Mexicana',
        'PZ': 'TAM MERCOSUR/TRANSPORTES AEREOS DEL MERCOSUR SA',
        'PY': '\u82CF\u91CC\u5357\u822A\u7A7A',
        'Q9': 'Afrinat International Airlines',
        'PX': '\u65B0\u757F\u5185\u4E9A\u822A\u7A7A',
        'Q8': 'Trans Air Congo',
        'PW': 'PRECISION AIR/PRECISION AIR',
        'Q7': 'Sobelair',
        'PV': 'SAINT BARTH COMMUTER/SAINT BARTH COMMUTER',
        'Q6': 'Skytrans',
        'PU': '\u70CF\u62C9\u572D\u822A\u7A7A\u516C\u53F8',
        'Q5': 'FORTY MILE AIR/FORTY MILE AIR LTD',
        'PT': '\u745E\u5178\u897F\u65B9\u822A\u7A7A',
        'Q4': 'Swazi Express Airways',
        'PS': '\u4E4C\u514B\u5170\u56FD\u9645\u822A\u7A7A',
        'Q3': 'Zambian Airways',
        'PR': '\u83F2\u5F8B\u5BBE\u822A\u7A7A',
        'Q2': 'Island Aviation',
        'PQ': 'Panafrican Airways',
        'PP': 'JET AVIATION BUSINESS/JET AVIATION BUSINESS JETS AG',
        'PO': '\u9E48\u9E55\u822A\u7A7A',
        'PN': 'China West Air',
        'PM': '\u4F2F\u5229\u5179\u70ED\u5E26\u822A\u7A7A',
        'PL': '\u79D8\u9C81\u822A\u7A7A',
        'PK': '\u9A6C\u57FA\u65AF\u5766\u822A\u7A7A',
        'PJ': '\u5723\u76AE\u57C3\u5C14\u822A\u7A7A\u516C\u53F8',
        'PI': 'Sol Paraguay',
        'PH': '\u6CE2\u5229\u5C3C\u897F\u4E9A\u822A\u7A7A',
        'PG': '\u66FC\u8C37\u822A\u7A7A',
        'PF': '\u5DF4\u52D2\u65AF\u5766\u822A\u7A7A',
        'PE': '\u6B27\u6D32\u822A\u7A7A',
        'PD': 'Porter Airlines',
        'PC': 'Pegasus Airlines',
        'PB': '\u52A0\u62FF\u5927\u7701\u822A\u7A7A',
        'PA': 'FLORIDA COASTAL AIRLINES/FLORIDA COASTAL AIRLINES INC',
        'OZ': '\u97E9\u4E9A\u822A\u7A7A',
        'OY': 'Andes Lineas Aereas S.A.',
        'P9': 'Peruvian Air Lines',
        'OX': '\u6CF0\u56FD\u4E1C\u65B9\u822A\u7A7A',
        'P8': 'Pantanal Linhas Aereas Sul',
        'OW': '\u7F8E\u5229\u575A\u9E70\u822A/\u6267\u884C\u822A\u7A7A',
        'P7': 'East Line Airlines',
        'OV': '\u7231\u6C99\u5C3C\u4E9A\u822A\u7A7A',
        'P6': 'Trans Air',
        'OU': '\u514B\u7F57\u5730\u4E9A\u822A\u7A7A',
        'P5': 'Aerorepublica',
        'OT': '\u9E48\u9E55\u822A\u7A7A',
        'P4': 'Aero Lineas Sosa',
        'OS': '\u5967\u5730\u5229\u822A\u7A7A\u516C\u53F8',
        'P3': 'Passaredo Linhas Aereas',
        'OR': 'TUI Airlines Netherland',
        'P2': 'TyumenaviaTrans',
        'OQ': 'SUNRISE AIRLINES INC/SUNRISE AIRLINES INC',
        'P1': 'Public Charters',
        'OP': 'CHALKS OCEAN AIRWAYS/CHALKS INTERNATIONAL AIRLINES',
        'P0': 'Proflight Commuter Services',
        'OO': '\u897F\u65B9\u5929\u7A7A\u822A\u7A7A',
        'ON': 'Our Airline',
        'OM': '\u8499\u53E4\u822A\u7A7A',
        'OL': 'OLT-OSTFRIESISCHE LUFFT',
        'OK': '\u6377\u514B\u822A\u7A7A\u516C\u53F8',
        'OJ': 'OVERLAND AIRWAYS LTD/OVERLAND AIRWAYS LIMITED',
        'OI': '\u666E\u96F7\u65AF\u8482\u822A\u7A7A',
        'OH': '\u79D1\u59C6\u822A\u7A7A',
        'OG': 'One Two Go Airlines',
        'OF': 'Air Finland Ltd',
        'OE': 'Asia Overnight Express',
        'OD': '\u82CF\u5229\u4E9A\u90A3\u822A\u7A7A',
        'OC': 'OMNI/OMNI AVIACAO E TECNOLOHIA, LDA',
        'OB': 'BOA',
        'OA': '\u5967\u6797\u5339\u514B\u822A\u7A7A\u516C\u53F8',
        'NZ': '\u65B0\u897F\u5170\u822A\u7A7A',
        'NY': '\u51B0\u5C9B\u822A\u7A7A',
        'NX': '\u6FB3\u9580\u822A\u7A7A\u516C\u53F8',
        'O8': '\u7518\u6CC9\u9999\u6E2F\u822A\u7A7A',
        'NW': '\u7F8E\u56FD\u897F\u5317\u822A\u7A7A',
        'O7': 'OzJet',
        'NV': 'NAKANIHON AIRLINES/NAKANIHON AIRLINES CO LTD',
        'O6': 'OceanAir Linhas Aereas',
        'NU': '\u65E5\u672C\u8D8A\u6D0B\u822A\u7A7A',
        'O5': 'Orange Air Sierra Leone',
        'NT': 'Binter Canarias',
        'O4': 'Antrak Air',
        'NS': 'SILK ROUTE AIRWAYS/SILK ROUTE AIRWAYS',
        'NR': 'Pamir Air',
        'O2': 'Jet Air Spolka Z.O.O.',
        'NQ': 'AIR JAPAN COMPANY LTD/AIR JAPAN COMPANY LTD',
        'NP': 'SKYTRANS/SKYTRANS',
        'NO': '\u6FB3\u5927\u5229\u4E9A\u822A\u7A7A',
        'NN': 'VIM Airlines',
        'NM': '\u5E93\u514B\u5C71\u822A\u7A7A',
        'NL': '\u6C99\u6B23\u56FD\u9645\u822A\u7A7A',
        'NK': '\u7CBE\u795E\u822A\u7A7A',
        'NJ': '\u524D\u536B\u822A\u7A7A',
        'NI': 'PORTUGALIA/\u8461\u8404\u7259\u822A\u7A7A',
        'NH': '\u5168\u65E5\u7A7A\u822A\u7A7A',
        'NG': '\u7EF4\u4E5F\u7EB3\u822A\u7A7A',
        'NF': '\u74E6\u52AA\u963F\u56FE\u822A\u7A7A',
        'NE': 'SKYEUROPE AIRLINES/SKYEUROPE AIRLINES',
        'ND': '\u94FE\u73AF\u822A\u7A7A',
        'NC': 'NATIONAL JET SYSTEMS/NATIONAL JET SYSTEMS PTY. LTD.',
        'NB': '\u65AF\u7279\u6797\u6B27\u6D32\u822A\u7A7A',
        'NA': '\u5317\u7F8E\u822A\u7A7A',
        'MZ': '\u6885\u5DF4\u8FEA\u822A\u7A7A',
        'MY': 'Midwest Airlines (Egypt)',
        'N9': 'Niger Air Continental',
        'MX': '\u58A8\u897F\u54E5\u822A\u7A7A',
        'N8': 'CR Airways',
        'MW': '\u9A6C\u96C5\u5C9B\u822A\u7A7A',
        'N7': 'Lagun Air',
        'MV': '\u4E9A\u7F8E\u5C3C\u4E9A\u822A\u7A7A',
        'N6': 'Nuevo Continente S.A.',
        'MU': '\u4E2D\u56FD\u4E1C\u65B9\u822A\u7A7A',
        'N5': 'Norfolk Air',
        'MT': '\u5927\u897F\u90E8\u822A\u7A7A',
        'N4': 'Minerva Airlines',
        'MS': '\u57C3\u53CA\u822A\u7A7A',
        'N3': 'Omskavia Airline',
        'MR': '\u6BDB\u91CC\u5854\u5C3C\u4E9E\u822A\u7A7A\u516C\u53F8',
        'N2': 'Daghestan Airlines',
        'MQ': '\u7F8E\u5229\u575A\u9E70\u822A\u7A7A\u516C\u53F8',
        'MP': '\u8377\u862D\u99AC\u4E01',
        'MO': 'Calm Air International Limited',
        'MN': '\u5546\u7528\u822A\u7A7A\u516C\u53F8',
        'MM': 'SAM',
        'ML': 'African Transport Trading And Investment Co.',
        'MK': '\u6BDB\u91CC\u88D8\u65AF\u822A\u7A7A',
        'MJ': 'Mihin Lanka PVT Ltd',
        'MI': '\u80DC\u5B89\u822A\u7A7A',
        'MH': '\u9A6C\u6765\u897F\u4E9A\u822A\u7A7A',
        'MG': 'MN',
        'MF': '\u53A6\u95E8\u822A\u7A7A',
        'ME': '\u4E2D\u4E1C\u822A\u7A7A',
        'MD': '\u99AC\u723E\u52A0\u4EC0\u570B\u5BB6\u822A\u7A7A\u516C\u53F8',
        'MC': 'AIR MOBILITY COMMAND/AIR MOBILITY COMMAND',
        'MB': 'MNG Airlines',
        'MA': '\u5308\u7259\u5229\u822A\u7A7A\u516C\u53F8',
        'LZ': 'Belle Air',
        'LY': '\u4EE5\u8272\u5217\u822A\u7A7A',
        'M9': 'Motor-Sich JSC',
        'LX': '\u745E\u58EB\u570B\u969B\u822A\u7A7A',
        'M8': '\u6E44\u516C\u822A\u7A7A',
        'LW': 'PACIFIC WINGS/PACIFIC WINGS',
        'M7': 'Marsland Aviation',
        'LV': 'Albanian Airlines',
        'M6': 'AmeriJet International',
        'LU': 'LAN EXPRESS/LAN EXPRESS',
        'M5': 'Kenmore Air',
        'LT': '\u5FB7\u570B\u7A7A\u904B\u516C\u53F8',
        'M4': 'Avioimpex A.D.P.O.',
        'LS': 'Jet2.com Limited',
        'M3': 'Air Norway',
        'LR': '\u54E5\u65AF\u9054\u9ECE\u52A0\u822A\u7A7A\u516C\u53F8',
        'M2': 'Mahfooz Aviation',
        'LQ': 'Lebanese Air Transport',
        'M1': 'Markair Express',
        'LP': 'LAN PERU S.A/LAN PERU S.A',
        'LO': '\u6CE2\u862D\u822A\u7A7A\u516C\u53F8',
        'LN': '\u963F\u62C9\u4F2F\u5229\u6BD4\u4E9E\u822A\u7A7A\u516C\u53F8',
        'LM': '\u5B89\u8482\u5229\u5B89\u65AF\u822A\u7A7A\u516C\u53F8',
        'LL': '\u963F\u52D2\u683C\u96F7\u822A\u7A7A',
        'LK': 'AIR LUXOR S.A/AIR LUXOR S.A.',
        'LJ': 'Jin Air Limited',
        'LI': '\u80CC\u98A8\u7FA4\u5CF6\u822A\u7A7A\u516C\u53F8',
        'LH': '\u5FB7\u56FD\u6C49\u838E\u822A\u7A7A',
        'LG': '\u76E7\u68EE\u5821\u822A\u7A7A\u516C\u53F8',
        'LF': 'FlyNordic',
        'LE': 'Efly',
'LD': '\u9999\u6E2F\u83EF\u6C11\u822A\u7A7A\u6709\u9650\u516C\u53F8',
    'LC': 'LEGEND AIRLINES INC/LEGEND AIRLINES INC',
    'LB': '\u6CE2\u5229\u7DAD\u4E9E\u822A\u7A7A\u516C\u53F8',
    'LA': '\u667A\u5229\u822A\u7A7A',
    'KZ': '\u65E5\u672C\u8D27\u8FD0\u822A\u7A7A',
    'KY': '\u5723\u591A\u7F8E\u548C\u666E\u6797\u897F\u6BD4\u822A\u7A7A',
    'L9': 'Awsaj Aviation Services',
    'KX': '\u958B\u66FC\u822A\u7A7A\u516C\u53F8',
    'L8': 'Air Luxor GB LDA',
    'KW': 'Wataniya Airways',
    'L7': 'Laoag International Airlines',
    'KV': 'KAVMINVODYAVIA/KAVMINVODYAVIA',
    'L6': 'Mauritanian Airlines International',
    'KU': '\u79D1\u5A01\u7279\u822A\u7A7A\u516C\u53F8',
    'L5': 'Helikopter Service',
    'KT': 'KYRGYZ AIR/KYRGYZ AIR',
    'L4': 'Lauda-air S.p.A.',
'KS': 'PENAIR/PENINSULA AIR INC.',
'L3': 'Lynx Aviation',
    'KR': 'MDA AIRLINES/MDA AIRLINES',
    'L2': 'Lynden Air',
    'KQ': '\u80AF\u5C3C\u4E9A\u822A\u7A7A',
    'KP': 'Asky',
    'KO': '\u963F\u62C9\u65AF\u52A0\u4E2D\u90E8\u5FEB\u9012\u516C\u53F8',
    'KN': 'China United Airlines',
    'KM': '\u99AC\u8033\u4ED6\u822A\u7A7A\u516C\u53F8',
    'KL': '\u8377\u5170\u822A\u7A7A',
    'KK': 'Atlasjet',
    'KJ': '\u82F1\u56FD\u5730\u4E2D\u6D77\u822A\u7A7A',
    'KI': 'ADAM SKYCONNECTION AIR/ADAM SKYCONNECTION AIRLINES PT',
    'KH': 'Kyrgyz Airways',
    'KG': 'Aerogaviota S.A.',
'KF': '\u535A\u7279\u5C3C\u4E9A\u822A\u7A7A',
    'KE': '\u5927\u97E9\u822A\u7A7A',
    'KD': '\u80AF\u5FB7\u5C14\u822A\u7A7A',
    'KC': 'AIR ASTANA/AIR ASTANA',
    'KB': '\u5FB7\u9C81\u514B\u822A\u7A7A',
    'KA': '\u6E2F\u9F99\u822A\u7A7A',
    'JZ': '\u5929\u7A7A\u4E4B\u8DEF\u822A\u7A7A',
    'JY': 'Air Turks and Caicos',
    'JX': 'NICE HELICOPTERS/NICE HELICOPTERS',
    'K9': 'Skyward Aviation Ltd',
    'JW': 'SKIPPERS AVIATION/SKIPPERS AVIATION PTY LTD.',
'K8': 'Zambia Skyways Limited',
    'JV': 'Bearskin Airlines',
    'K7': 'Sakha Avia',
    'JU': 'Jat Airways',
    'K6': 'Cambodia Angkor Air\n',
    'JT': '\u5370\u5C3C\u72EE\u5B50\u822A\u7A7A',
    'K5': 'Seaport Airlines',
    'JS': '\u5317\u97D3\u9AD8\u9E97\u822A\u7A7A\u516C\u53F8',
    'K4': 'Kronflyg',
    'JR': '\u52A0\u5229\u798F\u5C3C\u4E9A\u822A\u7A7A\u516C\u53F8',
    'K3': 'Taquan Air Services',
    'JQ': 'JETSTAR AIRWAYS/JETSTAR AIRWAYS PTY LIMITED',
    'K2': 'Eurolot S.A.',
'JP': '\u963F\u5F97\u91CC\u4E9E\u822A\u7A7A\u516C\u53F8',
    'K1': 'Topas',
    'JO': '\u65E5\u7EBF\u822A\u7A7A',
    'K0': 'Worldwide Air',
    'JN': 'EXCEL AIRWAYS/EXCEL AIRWAYS',
    'JM': '\u7259\u8CB7\u52A0\u822A\u7A7A\u516C\u53F8',
    'JL': '\u65E5\u672C\u822A\u7A7A',
    'JK': '\u897F\u73ED\u7259\u65AF\u6F58\u822A\u7A7A',
    'JJ': 'TAM LINHAS AEREAS S.A./TAM LINHAS AEREAS S.A.',
'JI': 'Jade Cargo International',
    'JH': '\u4E1C\u5317\u5730\u533A\u822A\u7A7A',
    'JG': 'AIR GREECE-AERODROMISIS/AIR GREECE-AERODROMISIS S.A.',
'JF': 'LAB\u98DE\u884C\u670D\u52A1',
    'JE': '\u9A6C\u6069\u514B\u65AF\u822A\u7A7A',
    'JD': 'Deer Jet',
    'JC': 'JAL EXPRESS/JAL EXPRESS',
    'JB': 'Helijet International Inc.',
'JA': 'B & H Airlines',
    'IZ': '\u4EE5\u8272\u5217\u570B\u5167\u822A\u7A7A\u516C\u53F8',
    'IY': '\u4E5F\u9580\u822A\u7A7A\u516C\u53F8',
    'IX': 'Air India Express',
    'J9': 'Guinee Airlines',
    'IW': 'Wings Air',
    'J8': 'Berjaya Air',
    'IV': 'Wind Jet',
    'J7': 'JMC Airlines Ltd',
    'IU': '\u65B0\u51E0\u5185\u4E9A',
    'J6': 'Larrys Flying',
    'IT': 'KINGFISHER AIRLINES',
    'J5': 'Donghai Airlines',
    'IS': 'ISLAND AIRLINES/ISLAND AIRLINES',
    'J4': 'Buffalo Air',
    'IR': '\u4F0A\u6717\u570B\u5BB6\u822A\u7A7A\u516C\u53F8',
    'J3': 'Northwestern Air Lease',
    'IQ': '\u5965\u683C\u65AF\u5821\u822A\u7A7A',
    'J2': 'Azerbaijan Airlines',
    'IP': 'ATYRAU AUE JOLY/ATYRAU AUE JOLY',
    'IO': 'INDONESIAN  AIRLINES/PT INDONESIAN AIRLINES',
    'J0': 'Jet Link Express',
    'IN': '\u9A6C\u5176\u987F\u822A\u7A7A',
    'IM': 'Menajet',
    'IL': '\u4F0A\u65AF\u5766\u5E03\u5C14\u822A\u7A7A',
    'IK': 'IM\u822A\u7A7A',
    'IJ': 'Great Wall Airlines',
    'II': '\u7F8E\u56FD\u5546\u4E1A\u822A\u7A7A',
    'IH': 'FALCON AVIATION/FALCON AVIATION AB',
    'IG': '\u9ED8\u91CC\u8FEA\u7EB3\u822A\u7A7A',
    'IF': 'ISLAS AIRWAYS/ISLAS AIRWAYS',
    'IE': '\u6240\u7F85\u9580\u822A\u7A7A',
    'ID': 'Interlink Airlines PTY Ltd.',
'IC': '\u5370\u5EA6\u822A\u7A7A\u516C\u53F8',
    'IB': '\u897F\u73ED\u7259\u56FD\u5BB6\u79C0\u822A\u7A7A',
    'IA': '\u4F0A\u62C9\u514B\u822A\u7A7A\u516C\u53F8',
    'HZ': '\u8428\u54C8\u6797\u822A\u7A7A',
    'HY': '\u70CF\u8332\u5225\u514B\u65AF\u5766\u822A\u7A7A\u516C\u53F8',
    'HX': '\u9999\u6E2F\u822A\u7A7A',
    'I9': 'Air Italy S.P.A',
    'HW': 'Iceland Express',
    'HV': '\u8377\u862D\u6CDB\u822A\u822A\u516C\u53F8',
    'I7': 'Paramount Airways',
    'HU': '\u6D77\u5357\u822A\u7A7A',
    'I6': 'Sky Eyes',
    'HT': 'Hellenic Imperial Airway',
    'I5': 'Air Mali',
    'HS': 'Direktflyg',
    'I4': 'Rutair Ltd DBA Airlink',
    'HR': 'HAHN AIR LINES/HAHN AIR LINES',
    'HQ': 'HARMONY AIRWAYS',
    'HP': '\u7F8E\u6D32\u897F\u90E8\u822A\u7A7A\u516C\u53F8',
    'I1': 'Eyeone',
    'HO': '\u5409\u7965\u822A\u7A7A',
    'HN': 'PROTEUS HELICOPTERES/PROTEUS HELICOPTERES',
    'HM': '\u585E\u820C\u723E\u822A\u7A7A',
    'HK': '\u4EF0\u5149\u822A\u7A7A',
    'HJ': 'Hellas Jet SA',
    'HI': 'Papillon Airways',
    'HH': '\u51B0\u5CF6\u65D7\u5E5F\u822A\u7A7A',
    'HG': 'HAITI INTL AIRLINES/HAITI INTERNATIONAL AIRLINES',
    'HF': '\u54C8\u6015\u683C\u52DE\u57C3\u5FB7\u822A\u7A7A\u516C\u53F8',
    'HE': '\u74E6\u723E\u7279\u822A\u7A7A',
    'HD': '\u5317\u6D77\u9053\u570B\u969B\u822A\u7A7A',
    'HC': 'LIP AIR LTD/AERO-TROPICS AIR SERVICES',
    'HB': 'HOMER AIR INC/HOMER AIR INC',
    'HA': '\u590F\u5A01\u5937\u822A\u7A7A\u516C\u53F8',
    'GZ': '\u62C9\u7F85\u6E6F\u52A0\u822A\u7A7A',
    'GY': 'Gabon Airlines',
    'GX': '\u83F2\u5F8B\u8CD3\u592A\u5E73\u6D0B\u822A\u7A7A',
    'H9': 'Pegasus',
    'GW': '\u5EAB\u73ED\u822A\u7A7A',
    'H8': 'Dalavia-Far East Airways Khabarovsk',
    'GV': '\u91CC\u52A0\u5FEB\u905E',
    'H7': 'Eagle Aviation Uganda',
    'GU': '\u5371\u5730\u99AC\u62C9\u822A\u7A7A\u516C\u53F8',
    'H6': 'Hageland',
    'GT': '\u76F4\u5E03\u7F85\u9640\u822A\u7A7A\u516C\u53F8',
    'H5': 'Magadan Airlines',
    'GS': 'Tianjin Airlines',
    'H4': 'Inter Islands Airlines',
    'GR': '\u5967\u91CC\u5C3C\u822A\u7A7A',
    'H3': 'Harbour Air',
    'GQ': '\u9AD8\u7A7A\u822A\u7A7A',
    'H2': 'Sky Service S.A.',
'GP': 'PALAU TRANS PACIFIC/PALAU TRANS PACIFIC AIRLINES INC.',
'GO': 'Go Fly Limited',
    'GN': '\u52A0\u84EC\u822A\u7A7A',
    'GM': 'AIR SLOVAKIA BWJ/AIR SLOVAKIA BWJ LTD.',
'GL': 'AIR GREENLAND INC/AIR GREENLAND INC',
    'GK': 'Go One Airways',
    'GJ': '\u6B50\u6D32\u98DB\u884C\u822A\u7A7A',
    'GI': 'AIR COMPANY ITEK-AIR LTD/AIR COMPANY ITEK-AIR LTD',
    'GH': 'Globus LLC',
    'GG': 'AIR GUYANE/AIR GUYANE',
    'GF': '\u6D77\u7063\u822A\u7A7A\u516C\u53F8',
    'GE': '\u5FA9\u8208\u822A\u7A7A',
    'GD': 'AIR ALPHA GREENLAND/AIR ALPHA GREENLAND A/S',
    'GC': 'Gambia International Airlines',
    'GB': 'GREAT BARRIER AIRLINES/GREAT BARRIER AIRLINE',
    'GA': '\u5370\u5C3C\u5609\u9C81\u8FBE\u822A\u7A7A',
    'FZ': 'FlyDubai',
    'FY': 'Firefly',
    'FX': '\u806F\u90A6\u5FEB\u905E',
    'G9': 'Air Arabia',
    'FW': 'FAIR INCORPORATED/FAIR INCORPORATED',
    'G8': 'Joint Stock Co Enkor',
    'FV': 'Rossiya Airlines',
    'G7': 'GoJet Airlines',
    'FU': '\u6CD5\u570B\u6FF1\u6D77\u822A\u7A7A',
    'G6': 'Angkor Airways',
    'FT': '\u66B9\u7C92\u822A\u7A7A',
    'G5': 'Enkor Airlines',
    'FS': 'MISSIONARY AVIATION/MISSIONARY AVIATION FELL',
    'G4': 'Allegiant Air LLC',
    'FR': '\u745E\u5B89\u822A\u7A7A',
    'G3': 'Varig-Gol Airlines',
    'FQ': 'Brindabella Airlines',
    'G2': 'Avirex Gabon',
    'FP': '\u81EA\u7531\u570B\u969B\u822A\u7A7A',
    'FO': 'EXPEDITION AIRWAYS/EXPEDITION AIRWAYS',
    'G0': 'Ghana International Airlines',
    'FN': '\u6CD5\u570B\u5730\u5340\u822A\u7A7A',
    'FM': '\u4E0A\u6D77\u822A\u7A7A',
    'FL': '\u7A7F\u8D8A\u822A\u7A7A\u516C\u53F8',
    'FK': 'KEEWATIN AIR/KEEWATIN AIR',
    'FJ': '\u6590\u6FDF\u822A\u7A7A',
    'FI': '\u51B0\u5CF6\u822A\u7A7A',
    'FH': '\u672A\u4F86\u570B\u969B\u822A\u7A7A',
    'FG': 'Ariana Afghan Airlines',
    'FF': '\u5BF6\u5854\u822A\u7A7A',
    'FE': 'Far Eastern Air Trans',
    'FD': 'Thai AirAsia',
    'FC': 'Finncomm Commuter Air',
    'FB': 'Bulgaria Air',
    'FA': 'Safair',
    'EZ': 'Sun Air of Scandinavia',
    'EY': '\u963F\u63D0\u54C8\u5FB7\u822A\u7A7A',
    'EX': 'AIR SANTO DOMINGO/AEROLINEAS SANTO DOMINGO',
    'F9': 'Frontier Airlines',
    'EW': '\u6B50\u6D32\u4E4B\u7FFC\u822A\u7A7A',
    'F8': 'Freedom Airlines Incorporated',
    'EV': '\u4E1C\u5357\u5927\u897F\u6D0B\u822A\u7A7A\u516C\u53F8',
    'F7': 'Darwin Airline SA',
    'EU': 'United Eagle Airlines',
    'F6': '',
    'ET': '\u57C3\u8D5B\u4FC4\u6BD4\u4E9A\u822A\u7A7A',
    'F5': 'Cosmic Air',
    'ES': 'DHL International',
    'F4': 'Albarka Air',
    'ER': 'Astar Air Cargo Incorporated',
    'F3': 'Faso Airways',
    'EQ': 'Transportes Aereos Militates Ecuat',
    'F2': 'Fly Air',
    'EP': '\u4F0A\u6717\u963F\u68EE\u66FC\u822A\u7A7A',
    'F1': 'FareLogix',
    'EO': 'HEWA BORA AIRWAYS/HEWA BORA AIRWAYS',
    'F0': 'Fset Partition 2P',
    'EN': '\u591A\u6D1B\u7C73\u8482\u822A\u7A7A',
    'EM': 'Aero Benin SA',
    'EL': '\u65E5\u672C\u7A7A\u8FD0',
    'EK': '\u963F\u8054\u914B\u822A\u7A7A',
    'EJ': '\u65B0\u82F1\u683C\u862D\u822A\u7A7A',
    'EI': '\u827E\u723E\u6797\u683C\u65AF',
    'EH': 'ALL NIPPON NETWORK CO/ALL NIPPON NETWORK CO',
    'EG': 'Enerjet',
    'EF': '\u9060\u6771\u822A\u7A7A',
    'EE': 'AERO AIRLINES A.S./AERO AIRLINES A.S.',
'ED': 'Air Dominicana',
    'EC': 'Openskies BA European Ltd',
    'EB': 'Pullmantur Air',
    'EA': '\u6B27\u6D32\u822A\u7A7A\u5FEB\u8FD0',
    'DZ': 'TRANSCARAIBES AIR INTL/TRANSCARAIBES AIR INTL',
    'DY': '\u632A\u5A01\u77ED\u9014\u822A\u7A7A',
    'DX': 'DANISH AIR TRANSPORT/DANISH AIR TRANSPORT',
    'E9': 'Pan Am Clipper Connect',
    'DW': 'AERO-CHARTER/AERO-CHARTER',
    'E8': 'Alpi Eagles',
    'DV': 'PL Scat AirCompany',
    'E7': 'Fly European',
    'DU': 'HEMUS AIR/HEMUS AIR',
    'E6': 'Aviaexpresscruise Airline',
    'DT': '\u5B89\u54E5\u62C9\u822A\u7A7A\u516C\u53F8',
    'E5': 'Samara Airlines',
    'DS': '\u745E\u58EB\u6613\u6377\u822A\u7A7A',
    'E4': 'Aero Asia International Ltd',
    'DR': 'AIR LINK PTY. LTD/AIR LINK PTY.LTD.',
'E3': 'Domodedovo Airlines',
    'DQ': 'COASTAL AIR TRANSPORT/COASTAL AIR TRANSPORT',
    'E2': 'RIO Grande Air',
    'DP': '2000\u822A\u7A7A',
    'DO': 'AIR VALLEE/AIR VALLEE S.P.A.',
'E0': 'Eos Airlines',
    'DN': 'Senegal Airlines',
    'DM': 'Sterling Blue',
    'DL': '\u8FBE\u7F8E\u822A\u7A7A',
    'DK': 'EASTLAND AIR/EASTLAND AIR',
    'DJ': 'Virgin Australia',
    'DI': '\u5FB7\u82F1\u822A\u7A7A',
    'DH': 'Independence Air',
    'DG': '\u6771\u5357\u4E9E\u822A\u7A7A',
    'DF': 'Aerolineas De Baleares',
    'DE': '\u5EB7\u591A\u723E\u822A\u7A7A',
    'DD': 'Nok Air',
    'DC': '\u91D1\u8272\u822A\u7A7A',
    'DB': '\u4E0D\u91CC\u7279\u822A\u7A7A',
    'DA': 'AIR GEORGIA/AIR GEORGIA',
    'CZ': '\u4E2D\u56FD\u5357\u65B9\u822A\u7A7A',
    'CY': '\u585E\u6D66\u8DEF\u65AF\u822A\u7A7A\u516C\u53F8',
    'CX': '\u56FD\u6CF0\u822A\u7A7A',
    'D9': 'Donavia',
    'CW': '\u99AC\u7D39\u723E\u5CF6\u822A\u7A7A',
    'D8': '\u5409\u5E03\u5730\u822A\u7A7A\u516C\u53F8',
    'CV': '\u76E7\u68EE\u5821\u822A\u7A7A',
    'D7': 'FLY ASIAN XPRESS',
    'CU': '\u53E4\u5DF4\u7D71\u4E00\u822A\u7A7A\u516C\u53F8',
    'D6': 'Interair South Africa',
    'CT': 'CAVE/COMPANIE AEREA DE VIAJE EXPRESOS C.A.',
'D5': 'DAU Air Verwaltungs AG',
    'CS': '\u7F8E\u56FD\u5927\u9646\u822A\u7A7A (Mic)',
'D4': 'Alidaunai',
    'CR': 'OAG Worldwide',
    'D3': 'Daallo Airlines',
    'CQ': 'Czech Connect Airlines',
    'D2': 'Severstal Joint Stock Co',
    'CP': 'Compass Airlines',
    'CO': '\u7F8E\u56FD\u5927\u9646\u822A\u7A7A',
    'CN': 'ISLANDS NATIONAIR/ISLANDS NATIONAIR',
    'CM': '\u5DF4\u62FF\u9A6C\u822A\u7A7A',
    'CL': '\u6F22\u838E\u57CE\u5E02\u822A\u7A7A',
    'CK': 'China Cargo Airlines',
    'CJ': '\u4E2D\u570B\u5317\u65B9\u822A\u7A7A',
    'CI': '\u4E2D\u534E\u822A\u7A7A',
    'CH': 'BEMIDJI AVIATION SERVICE/BEMIDJI AIRLINES',
    'CG': '\u5DF4\u5E03\u4E9E\u65B0\u5E7E\u5167\u4E9E\u822A\u7A7A',
    'CF': 'CITY AIRLINE/CITY AIRLINE',
    'CE': 'Chalair Aviation',
    'CD': '\u806F\u76DF\u822A\u7A7A',
    'CC': 'MACAIR AIRLINES',
    'CB': 'SCOTAIRWAYS/SCOTAIRWAYS',
    'CA': '\u4E2D\u56FD\u56FD\u9645\u822A\u7A7A',
    'BZ': 'Blue Dart Aviation',
    'BY': 'Thomsonfly Limited',
    'BX': 'Air Busan',
    'C9': 'Cirrus Airlines',
    'BW': 'Caribbean Airlines',
    'C8': 'Chicago Express Airlines Inc',
    'BV': 'BLUE PANORAMA AIRLINES/BLUE PANORAMA AIRLINES S.P.A.',
'C7': 'Samarkand Airways',
    'BU': '\u5E03\u62C9\u68EE\u5357\u7F8E\u822A\u7A7A\u516C\u53F8',
    'C6': 'CANJET AIRLINES',
    'BT': '\u6CE2\u7F57\u7684\u6D77\u822A\u7A7A',
    'C5': 'CommutAir',
    'BS': 'BRITISH INTL/BRITISH INTERNATIONAL',
    'BR': '\u957F\u8363\u822A\u7A7A',
    'C3': 'Icar',
    'BQ': 'Aeromar C. Pro. A.',
'BP': '\u535A\u8328\u74E6\u7D0D\u822A\u7A7A',
    'BO': 'Bouraq Airlines',
    'C0': 'Centralwings',
    'BN': 'Bahrain Air',
    'BM': 'Bayu Indonesia',
    'BL': 'Jetstar Pacific',
    'BK': 'POTOMAC AIR/POTOMAC AIR',
    'BJ': '\u7A81\u5C3C\u65AF\u52AA\u5A01\u723E\u822A\u7A7A',
    'BI': '\u6C76\u83B1\u7687\u5BB6\u822A\u7A7A',
    'BH': 'Hawkair Aviation Services',
    'BG': '\u5B5F\u52A0\u62C9\u822A\u7A7A',
    'BF': '\u822A\u7A7A\u670D\u52D9',
    'BE': 'FLYBE/\u6FA4\u897F\u6B50\u6D32\u822A\u7A7A\u6709\u9650',
    'BD': '\u82F1\u570B\u7C73\u862D\u822A\u7A7A\u516C\u53F8',
    'BC': 'SKYMARK AIRLINES/SKYMARK AIRLINES CO. LTD',
    'BB': 'SANSA SVC AEREO NACIONAL',
    'BA': '\u82F1\u56FD\u822A\u7A7A/\u82F1\u4E9A\u822A\u7A7A',
    'AZ': '\u610F\u5927\u5229\u822A\u7A7A\u516C\u53F8',
    'AY': '\u82AC\u5170\u822A\u7A7A',
    'B9': 'Iran Air Tours',
    'AX': 'Trans States Airlines',
    'B8': 'Eritrean Airlines',
    'AW': 'DIRGANTARA AIR SERVICES/DIRGANTARA AIR SERVICES',
    'B7': '\u7ACB\u69AE\u822A\u7A7A',
    'AV': 'AVIANCA\u54E5\u4F26\u6BD4\u4E9A\u56FD\u5BB6\u822A\u7A7A',
    'B6': 'Jetline Airways',
    'AU': '\u6FB3\u65AF\u62C9\u723E\u822A\u7A7A\u516C\u53F8',
    'B5': 'East African Safari Air Express',
    'AT': '\u6469\u6D1B\u54E5\u822A\u7A7A\u516C\u53F8',
    'B4': 'Zanair',
    'AS': '\u963F\u62C9\u65AF\u52A0\u822A\u7A7A\u516C\u53F8',
    'B3': 'Bellview Airlines',
    'AR': '\u963F\u6839\u5EF7\u822A\u7A7A\u516C\u53F8',
    'B2': 'Belavia',
    'AQ': '\u963F\u6D1B\u54C8\u822A\u7A7A\u516C\u53F8',
    'B1': 'Brewster Bus',
    'AP': '\u4E00\u865F\u822A\u7A7A',
    'AO': '\u6FB3\u4E9E\u822A\u7A7A\u516C\u53F8',
    'AN': '\u6FB3\u6D32\u5B89\u6377\u822A\u7A7A',
    'AM': '\u58A8\u897F\u54E5\u822A\u7A7A\u516C\u53F8',
    'AL': '\u963F\u65AF\u7279\u62C9\u822A\u7A7A\u516C\u53F8',
    'AK': '\u4E9E\u6D32\u822A\u7A7A',
    'AJ': '\u6BD4\u5229\u6642\u570B\u969B\u822A\u7A7A',
    'AI': '\u5370\u5EA6\u822A\u7A7A',
    'AH': '\u963F\u723E\u53CA\u5229\u4E9E\u822A\u7A7A\u516C\u53F8',
    'AG': '\u7701\u822A\u7A7A',
    'AF': '\u6CD5\u56FD\u822A\u7A7A',
    'AE': '\u534E\u4FE1\u822A\u7A7A',
    'AD': 'AIR PARADISE INTL/PT. AIR PARADISE INTERNATIONAL',
    'AC': '\u52A0\u62FF\u5927\u822A\u7A7A',
    'AB': 'Air Berlin Plc and Co',
    'AA': '\u7F8E\u56FD\u822A\u7A7A',
    'A9': 'Georgian Airlines',
    'A8': 'Benin Golf Air',
    'A7': 'Air Comet',
    'A6': 'AIR ALPS AVIATION/AIR ALPS AVIATION',
    'A5': 'Airlinair',
    'A4': 'Aerocon',
    'A3': 'Aegean Airlines',
    'A2': 'Cielos Del Peru',
    'A1': 'G2 Switchworks Corporation',
    'A0': 'L Avion',
    '9Y': 'Air Kazakstan',
    '9X': 'New Axis Airways',
    '9W': '\u5370\u5EA6\u6377\u9054\u822A\u7A7A',
    '9V': 'Avior Airlines',
    '9U': '\u9ED8\u723E\u591A\u74E6\u822A\u7A7A',
    '9T': 'ACT Airlines',
    '9S': 'Spring Airlines',
    '9R': 'PHUKET AIRLINES CO LTD/PHUKET AIRLINES CO LTD',
    '9Q': 'PB Air',
    '9P': 'PANAIR S.P.A./PANAIR S.P.A.',
'9O': 'Transportes Aereos Inter',
    '9N': 'SATENA/SERVICIO AERO A TERRITORIOS NACIONALES',
    '9M': '\u4E2D\u592E\u5CB3\u822A\u7A7A',
    '9L': '\u79D1\u723E\u6839\u822A\u7A7A',
    '9K': '\u79D1\u5FB7\u89D2\u822A\u7A7A',
    '9J': '\u592A\u5E73\u6D0B\u5CF6\u822A\u7A7A',
    '9I': '\u6CF0\u570B\u5929\u9DF9\u822A\u7A7A',
    '9H': 'Dutch Antilles Express',
    '9G': 'Galaxy Airways',
    '9F': 'Eurostar',
    '9E': 'Pinnacle Airlines',
    '9D': 'Toumai Air Tchad',
    '9C': 'Spring Airlines',
    '9B': 'Accesrail Inc.',
'9A': 'VISA AIRWAYS PVT LTD/VISA AIRWAYS PRIVATE LTD',
    '8Z': 'Laser',
    '8Y': 'China Postal Airlines',
    '8W': 'AIR TEAM/AIR TEAM A.S.',
'8V': 'WRIGHT AIR/WRIGHT AIR SERVICE INC.',
'8U': 'AFRIQIYAH AIRWAYS/AFRIQIYAH AIRWAYS',
    '8T': 'Air Tindi Ltd',
    '8S': 'TurboJet',
    '8R': 'TRIP AIR/TRIP AIR',
    '8Q': 'Baker Aviation Inc',
    '8P': '\u592A\u5E73\u6D0B\u4E4B\u5CB8\u822A\u7A7A',
    '8O': 'West Coast Air',
    '8N': 'NORDKALOTTFLYG AB/NORDKALOTTFLYG AB',
    '8M': '\u7DEC\u7538\u570B\u969B\u822A\u7A7A',
    '8L': 'Lucky Air',
    '8K': 'ANGEL AIRLINES SA/ANGEL AIRLINES SA',
    '8J': 'Societe D\'Investissement Aerien dba Jet4You',
'8I': 'MyAir',
    '8H': 'Highland Airways',
    '8G': 'ANGEL AIRLINES/ANGEL AIRLINES CO. LTD',
    '8F': '\u83F2\u585E\u5C14\u822A\u7A7A',
    '8E': '\u767E\u4EE4\u822A\u7A7A',
    '8D': 'Expo Aviation',
    '8C': '\u4E1C\u661F\u822A\u7A7A',
    '8B': 'CARIBBEAN STAR AIRLINES/CARIBBEAN STAR AIRLINES',
    '8A': 'Atlas Blue',
    '7Z': '\u840A\u514B\u822A\u7A7A\uFF08\u5DF4\u54C8\u99AC\uFF09',
    '7Y': 'Mid Airlines',
    '7W': 'Wind Rose',
    '7V': 'PELICAN AIR/PELICAN AIR SERVICES CC',
    '7U': 'AVIAENEGRO/AVIAENEGRO',
    '7T': 'AIR GLACIERS/AIR GLACIERS',
    '7S': 'C Air Jet Airlines',
    '7R': 'BRA Transportes Aereos',
    '7Q': 'Pawa Dominicana',
    '7P': 'Batavia Air',
    '7O': 'Galaxy Air',
    '7N': 'INLAND AVIATION SVCS INC/INLAND AVIATION SVCS INC',
    '7M': '\u79CB\u660E\u822A\u7A7A',
    '7L': 'AEROCARIBBEAN S. A./AEROCARIBBEAN S. A.',
'7K': '\u79D1\u52A0\u96F7\u59C6\u822A\u7A7A',
    '7J': '0',
    '7I': 'Insel Air',
    '7H': '\u6642\u4EE3\u822A\u7A7A',
    '7G': 'MK Airlines',
    '7F': '\u4E00\u6D41\u822A\u7A7A',
    '7E': 'AEROLINE GMBH/AEROLINE GMBH',
    '7D': 'DONBASS/DONBASS EASTERN UKRANIAN AIRLINES',
    '7C': '\u6D4E\u5DDE\u822A\u7A7A',
    '7B': 'Atlant-Soyuz',
    '7A': 'Air Next',
    '6Z': '\u70CF\u514B\u862D\u8CA8\u904B\u822A\u7A7A',
    '6Y': '\u62C9\u812B\u7DAD\u4E9E\u5305\u6A5F\u822A\u7A7A',
    '6W': '\u8428\u62C9\u6258\u592B\u822A\u7A7A',
    '6V': 'LIGNES AERIENNES/LIGNES AERIENNES CONGOLAISES',
    '6U': '\u70CF\u514B\u862D\u822A\u7A7A',
    '6T': 'Almaty Aviation',
    '6S': 'KATO AIRLINES/KATO AIRLINES',
    '6R': '\u683C\u9B6F\u5409\u4E9E\u822A\u7A7A',
    '6Q': 'Slovak Airlines',
    '6P': 'CLUBAIR SIXGO SPA/CLUBAIR SIXGO SPA',
    '6N': 'TRANS TRAVEL AIRLINES/TRANS TRAVEL AIRLINES',
    '6M': 'MAJESTIC AIR PL/MAJESTIC AIR PL',
    '6L': 'Aklak Inc',
    '6K': 'ASIAN SPIRIT\u822A\u7A7A\u516C\u53F8',
    '6J': 'Skyservice Airlinesd/b/a Roots Air',
    '6H': '\u4EE5\u65AF\u96F7\u822A\u7A7A',
    '6G': 'AIR WALES/AIR WALES',
    '6F': 'Aerotrans Airlines Ltd',
    '6E': 'CITY AIR GERMANY AG/CITY AIR GERMANY AG',
    '6D': '\u67CF\u5229\u9054\u822A\u7A7A',
    '6C': 'CAPE SMYTHE AIR SERVICE/CAPE SMYTHE AIR SERV',
    '6B': '\u4E0D\u5217\u985B\u822A\u7A7A',
    '6A': 'AVIACSA/\u5EB7\u7D22\u4E54\u822A\u7A7A',
    '5Z': 'Bismillah Airlines',
    '5Y': 'Atlas Air',
    '5X': 'UPS',
    '5W': 'Iceland Express',
    '5V': 'LVIV AIRLINES/LVIV AIRLINES',
    '5U': 'LINEAS AEREAS DEL ESTADO/LINEAS AEREAS DEL ESTADO',
    '5T': 'CANADIAN NORTH/AIR NORTERRA INC.',
'5S': 'SERVICIOS AEREOS/SERVICIOS AEREOS PROFESIONALES',
    '5R': 'Karthago Airlines',
    '5Q': 'Best Aviation',
    '5P': 'Aerolinea Principal',
    '5N': 'ARKHANGELSK AIRLINES/ARKHANGELSK AIRLINES',
    '5M': '\u7F8E\u4F73\u822A\u7A7A',
    '5L': '\u73BB\u5229\u7EF4\u4E9A\u82CF\u5C14\u822A\u7A7A\u516C\u53F8',
    '5K': 'Hi Fly Transportes S.A',
    '5J': '\u5BBF\u96FE\u592A\u5E73\u6D0B\u822A\u7A7A',
    '5I': 'Air G',
    '5H': 'Five Fourty Aviation Ltd',
    '5G': '\u7A7A\u4E2D\u822A\u7A7A\u670D\u52D9',
    '5F': 'Arctic Circle Air Service',
    '5E': 'Equaflight Service',
    '5D': '\u6D77\u5CB8\u822A\u7A7A\u516C\u53F8',
    '5C': 'C.A.L.Cargo Airlines',
    '5B': 'Euro-Asia International',
    '5A': 'Alpine Aviation',
    '4Z': 'SA\u822A\u7A7A',
    '4Y': 'YUTE AIR ALASKA INC/YUTE AIR ALASKA, INC.',
'4W': 'Allied Air',
    '4V': 'Birdy Airlines S.A.',
'4U': 'Germanwings',
    '4T': 'GAWNE AVIATION/GAWNE AVIATION',
    '4S': 'Sol Air',
    '4R': 'Orient Eagle Airways',
    '4Q': 'Safi Airways',
    '4P': 'BUSINESS AVIATION/BUSINESS AVIATION',
    '4O': 'Ocean Airlines',
    '4N': '\u5317\u65B9\u822A\u7A7A\u516C\u53F8',
    '4M': 'LAN DOMINICANA/LINEA AEREA DE NAVEGACION DOMINICANA SA',
    '4L': 'Georgian International Airlines',
    '4K': 'Askari Aviation',
    '4J': 'Somon Air',
    '4H': 'United Airways Bangladesh',
    '4G': 'Gazpromavia Aviation',
    '4F': '\u822A\u90FD',
    '4E': 'TANANA AIR SERVICES/TANANA AIR SERVICE',
    '4D': '\u897F\u5948\u822A\u7A7A',
    '4C': 'Aires',
    '4B': 'OLSON AIR SERVICE/OLSON AIR SERVICE INC.',
'4A': 'F. S. AIR SERVICE INC/F. S. AIR SERVICE INC',
    '3Z': 'Everts Air',
    '3Y': 'Kartika Airlines',
    '3X': 'Japan Air Commuter',
    '3W': 'Euromanx',
    '3V': 'TNT Airways SA',
    '3U': '\u56DB\u5DDD\u822A\u7A7A',
    '3T': 'Turan Air Airline',
    '3S': 'C.A.I.R.E.',
'3R': 'Gromov Flight Research',
    '3Q': '\u4E2D\u570B\u96F2\u5357\u822A\u7A7A',
    '3P': 'Tiara Air',
    '3O': 'Air Arabia Maroc',
    '3N': '\u70CF\u723E\u52A0\u822A\u7A7A',
    '3M': '\u6E7E\u6D41\u56FD\u9645\u822A\u7A7A',
    '3L': 'INTERSKY LUFTFAHRT/INTERSKY LUFTFAHRT GMBH',
    '3K': '\u6377\u661F\u822A\u7A7A',
    '3J': 'ZIP AIR INC/ZIP AIR INC',
    '3I': 'Aerolineas Del Sur',
    '3H': '\u4F0A\u52AA\u4F0A\u7279\u822A\u7A7A',
    '3G': '\u963F\u7279\u862D\u7D22\u5C24\u8332\u822A\u7A7A',
    '3F': 'PACIFIC AIRWAYS INC/PACIFIC AIRWAYS INC',
    '3E': 'Air Choice One',
    '3D': '\u4EE3\u5C3C\u59C6\u822A\u7A7A',
    '3C': 'CORPORATE AIRLINES/CORPORATE AIRLINES',
    '3B': 'Burkina Airlines',
    '3A': 'Chu Kong Passenger Trans',
    '2Z': 'Joint Stock Co Voronezh',
    '2Y': 'Air Andaman',
    '2X': 'AIRWAY EXPRESS INC/AIRWAY EXPRESS, INC.',
'2W': 'Welcome Air Luftfahrt',
    '2V': 'AMTRAK',
    '2U': 'Sun d\'Or International Airlines',
'2T': 'Alajnihah Airways',
    '2S': 'ISLAND EXPRESS/SAFE AIR INTERNATIONAL INC',
    '2R': '\u52A0\u62FF\u5927\u570B\u5BB6\u9435\u8DEF',
    '2Q': 'Phoenix Airways',
    '2P': '\u83F2\u9E70\u822A\u7A7A',
    '2N': 'NEX Time Jet',
    '2M': 'Moldavian Airlines',
    '2L': 'Helvetic',
    '2K': '\u76D6\u5C14\u822A\u7A7A\u516C\u53F8',
    '2J': 'Air Burkina',
    '2I': 'Star Peru',
    '2H': 'Transavia',
    '2G': 'Debonair',
    '2F': 'Frontier Flying Service',
    '2E': 'SMOKEY BAY AIR INC./SMOKEY BAY AIR INC.',
'2D': 'AERO VIP S.A./AERO VIP S.A.',
'2C': 'SNCF',
    '2B': 'Aerocondor',
    '2A': 'AEROSERVICIOS ECUATORIAN/AEROSERVICIOS ECUAT',
    '1U': 'ITA Software',
    '1T': '1Time Airline',
    '1P': 'Worldspan',
    '1N': 'Navitaire Inc.',
'1L': '\u897F\u65B9\u822A\u7A7A\u5FEB\u6377',
    '1K': 'SOUTHERN CROSS/SOUTHERN CROSS DISTRIBUTION SYSTEMS',
    '1J': 'Axess International Network Inc.',
'1I': '\u5766\u5E15\u822A\u7A7A',
    '1G': 'Galileo',
    '1F': 'INFINI/INFINI TRAVEL INFORMATION INC',
    '1E': '\u4E2D\u56FD\u6C11\u822A\u4FE1\u606F\u7F51\u7EDC\u80A1\u4EFD\u6709\u9650',
    '1C': 'EDS Information',
    '1B': 'Abacus International',
    '1A': 'Amadeus',
    '0Y': 'Yeti Airline',
    '0P': 'Palau Asia Pacific Air',
    '0O': 'STA Travel',
    '0D': 'Darwin Airline',
    '0B': 'Blue Air Transport'
}
}

export default {
    messages
}

<script setup>
defineProps({
  hotelViewItem: {},
});
function getUserRatingDescription(value) {
  let desc = "";
  if (parseFloat(value) >= 4.5) {
    desc = "WONDERFUL";
  } else if (parseFloat(value) >= 4 && parseFloat(value) < 4.5) {
    desc = "VERY-GOOD";
  } else if (parseFloat(value) >= 3.5 && parseFloat(value) < 4) {
    desc = "GOOD";
  } else {
    desc = "";
  }
  return desc;
}
</script>

<template>
  <div class="tour_details_heading_wrapper margin-top-20" v-if="hotelViewItem">
    <div class="tour_details_top_heading">
      <h2>{{ hotelViewItem.hotelName }}</h2>
      <h5><i class="fas fa-map-marker-alt"></i>{{ hotelViewItem.address }}</h5>
    </div>
    <div
      class="tour_details_top_heading_right"
      style="margin-left: 80px; margin-top: -20px"
    >
      <h6>{{ getUserRatingDescription(hotelViewItem.starRating) }}</h6>
      <h6>{{ hotelViewItem.userRating }}/5</h6>
    </div>
  </div>
</template>

<style scoped></style>

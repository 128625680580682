<script setup>
import { ref, watch } from "vue";
import FlatPickr from "vue-flatpickr-component";
import useVuelidate from "@vuelidate/core";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { adultNameTitleOptions } from "@/formmodel/sales/flight/common/options/flightCommonOption";
import * as regexUtils from "@/utils/regexUtils";
import { required } from "@vuelidate/validators";

const props = defineProps({
  userProfileFormModal: {},
  isAdditionalTraveller: {},
});

const emits = defineEmits([]);
const userProfileFormModalRef = ref(null);

const genderOptionsRef = ref([
  { name: "Male", code: "MALE" },
  { name: "Female", code: "FEMALE" },
]);

const adultNameTitleOptionsRef = ref(adultNameTitleOptions);

watch(
  () => props.userProfileFormModal,
  (newValue) => {
    userProfileFormModalRef.value = newValue;
  },
  { immediate: true, deep: true }
);

const surnameRule = (value) => regexUtils.surnameRegx.test(value);
const givenNameRule = (value) => regexUtils.givenNameRegx.test(value);

let rules = {
  gender: { required },
  nameTitle: { required },
  birthDate: { required },
  surname: { required, surnameRule },
  givenName: { required, givenNameRule },
};

const v = useVuelidate(rules, userProfileFormModalRef);

async function modifyBasicInformation(userProfileFormModal) {
  const result = await v.value.$validate();
  if (!result) return;
  emits("modifyBasicInformation", userProfileFormModal, "BASICINFO");
}
</script>

<template>
  <form
    action="!#"
    id="profile_form_area"
    @submit.prevent="modifyBasicInformation(userProfileFormModalRef)"
  >
    <h3>{{ $t("basic-information") }}</h3>
    <div class="row mg-t-20" v-if="userProfileFormModalRef">
      <div class="col-2">
        <div class="form-group">
          <label for="f-name">{{ $t("gender") }}</label>
          <select
            id="passenger-form-nameTitle"
            v-model="userProfileFormModalRef.gender"
            :class="{
              'is-invalid': v.gender.$errors.length,
            }"
            class="form-control"
            type="text"
          >
            <option
              v-for="(genderOption, genderOptionIndex) in genderOptionsRef"
              :key="genderOptionIndex"
              :value="genderOption.code"
            >
              {{ genderOption.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <label for="f-name">{{ $t("name-title") }}</label>
          <select
            id="passenger-form-nameTitle"
            v-model="userProfileFormModalRef.nameTitle"
            :class="{
              'is-invalid': v.nameTitle.$errors.length,
            }"
            class="form-control"
            type="text"
          >
            <option
              v-for="(
                nameTitleOption, nameTitleOptionIndex
              ) in adultNameTitleOptionsRef"
              :key="nameTitleOptionIndex"
              :value="nameTitleOption.code"
            >
              {{ nameTitleOption.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="l-name">{{ $t("last-name") }}</label>
          <input
            type="text"
            class="form-control"
            id="profile-givenName"
            name="profile-givenName"
            :class="{
              'is-invalid': v.surname.$errors.length,
            }"
            :placeholder="$t('last-name')"
            @blur="v.surname.$touch"
            v-model="userProfileFormModalRef.surname"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="f-name">{{ $t("first-name") }}</label>
          <input
            type="text"
            class="form-control"
            id="profile-surname"
            name="profile-surname"
            :class="{
              'is-invalid': v.givenName.$errors.length,
            }"
            @blur="v.givenName.$touch"
            :placeholder="$t('first-name')"
            v-model="userProfileFormModalRef.givenName"
          />
        </div>
      </div>

      <div class="col-2">
        <div class="form-group">
          <label for="f-name">{{ $t("date-of-birth") }}</label>
          <FlatPickr
            v-model="userProfileFormModalRef.birthDate"
            :class="{
              'is-invalid': v.birthDate.$errors.length,
            }"
            name="birth-flatpickr"
            :config="{
              maxDate: 'today',
            }"
            :placeholder="$t('birth-date')"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-lg-12" align="center">
        <button class="btn btn_theme">{{ $t("save") }}</button>
        <!--        <button class="btn btn-secondary mg-l-10" v-if="isAdditionalTraveller">-->
        <!--          {{ $t("cancel") }}-->
        <!--        </button>-->
      </div>
    </div>
  </form>
</template>

<style scoped></style>

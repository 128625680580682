export const flightAncillaryServiceMenu = [
  {
    code: "0064",
    quantity: 0,
    name: "Flight",
    subGroups: [
      {
        code: "00C8",
        quantity: 0,
        name: "Servicing",
        subGroups: [
          { code: "012C", quantity: 0, name: "Change" },
          { code: "0190", quantity: 0, name: "Refund" },
        ],
      },
      {
        code: "01F4",
        quantity: 0,
        name: "Entertainment",
        subGroups: [
          { code: "0258", quantity: 0, name: "WI-FI" },
          { code: "02BC", quantity: 0, name: "Television" },
          { code: "0320", quantity: 0, name: "Games" },
          { code: "0384", quantity: 0, name: "Internet" },
          { code: "03E8", quantity: 0, name: "Power" },
        ],
      },
      {
        code: "044C",
        quantity: 0,
        name: "Meal",
        subGroups: [
          { code: "04B0", quantity: 0, name: "Breakfast" },
          { code: "0514", quantity: 0, name: "Lunch" },
          { code: "0578", quantity: 0, name: "Dinner" },
          { code: "05DC", quantity: 0, name: "Snack" },
        ],
      },
      {
        code: "0640",
        quantity: 0,
        name: "Beverage",
        subGroups: [
          { code: "06A4", quantity: 0, name: "Alcoholic" },
          { code: "0708", quantity: 0, name: "Non-Alcoholic" },
        ],
      },
      {
        code: "076C",
        quantity: 0,
        name: "Medical Equipment",
        subGroups: [
          { code: "07D0", quantity: 0, name: "Oxygen" },
          { code: "0834", quantity: 0, name: "Stretcher" },
          { code: "0898", quantity: 0, name: "Wheelchair" },
          { code: "08FC", quantity: 0, name: "Incubator" },
        ],
      },
      {
        code: "0960",
        quantity: 0,
        name: "Escort",
        subGroups: [
          { code: "09C4", quantity: 0, name: "Minor" },
          { code: "0A28", quantity: 0, name: "Adults" },
          { code: "0A8C", quantity: 0, name: "Secured" },
          { code: "0AF0", quantity: 0, name: "Senior" },
          { code: "0B54", quantity: 0, name: "Medical" },
          { code: "0BB8", quantity: 0, name: "VIP" },
          { code: "0C1C", quantity: 0, name: "Service Animal" },
          { code: "0C80", quantity: 0, name: "Emotional Support Animal" },
        ],
      },
      { code: "0CE4", quantity: 0, name: "Loyalty" },
      { code: "0D48", quantity: 0, name: "Upgrades" },
      {
        code: "0DAC",
        quantity: 0,
        name: "Charity",
        subGroups: [
          { code: "0E10", quantity: 0, name: "Environment" },
          { code: "0E74", quantity: 0, name: "Humanitarian" },
        ],
      },
      {
        code: "0ED8",
        quantity: 0,
        name: "Purchases",
        subGroups: [{ code: "0F3C", quantity: 0, name: "Duty Free" }],
      },
      {
        code: "0FA0",
        quantity: 0,
        name: "Cabin Baggage",
        subGroups: [
          { code: "1004", quantity: 0, name: "Bag" },
          { code: "1068", quantity: 0, name: "Car Seat" },
          { code: "10CC", quantity: 0, name: "Stroller" },
          { code: "1130", quantity: 0, name: "Cot" },
          { code: "1194", quantity: 0, name: "Pet" },
          { code: "11F8", quantity: 0, name: "Excess" },
          { code: "125C", quantity: 0, name: "Human Remains" },
          { code: "12C0", quantity: 0, name: "Media Equipment" },
          { code: "1324", quantity: 0, name: "Music Equipment" },
          { code: "1388", quantity: 0, name: "Sports Equipment" },
        ],
      },
      {
        code: "13EC",
        quantity: 0,
        name: "Checked Baggage",
        subGroups: [
          { code: "1450", quantity: 0, name: "Bag" },
          { code: "14B4", quantity: 0, name: "Car Seat" },
          { code: "1518", quantity: 0, name: "Stroller" },
          { code: "157C", quantity: 0, name: "Cot" },
          { code: "15E0", quantity: 0, name: "Pet" },
          { code: "1644", quantity: 0, name: "Excess" },
          { code: "16A8", quantity: 0, name: "Human Remains" },
          { code: "170C", quantity: 0, name: "Media Equipment" },
          { code: "1770", quantity: 0, name: "Music Equipment" },
          { code: "17D4", quantity: 0, name: "Sports Equipment" },
        ],
      },
      {
        code: "1838",
        quantity: 0,
        name: "Seat",
        subGroups: [
          { code: "189C", quantity: 0, name: "Preferred" },
          { code: "1900", quantity: 0, name: "Bassinet" },
        ],
      },
      {
        code: "1964",
        quantity: 0,
        name: "Assistance",
        subGroups: [
          { code: "19C8", quantity: 0, name: "Minor" },
          { code: "1A2C", quantity: 0, name: "Adults" },
          { code: "1A90", quantity: 0, name: "Senior" },
        ],
      },
    ],
  },
  {
    code: "1AF4",
    quantity: 0,
    name: "Airport",
    subGroups: [
      {
        code: "1B58",
        quantity: 0,
        name: "Lounge",
        subGroups: [
          {
            code: "1BBC",
            quantity: 0,
            name: "Amenities",
            subGroups: [
              { code: "1C20", quantity: 0, name: "Seating" },
              { code: "1C84", quantity: 0, name: "Restrooms" },
              { code: "1CE8", quantity: 0, name: "Shower" },
              { code: "1D4C", quantity: 0, name: "Meal" },
              { code: "1DB0", quantity: 0, name: "Beverage" },
              { code: "1E14", quantity: 0, name: "Office" },
              { code: "1E78", quantity: 0, name: "Internet" },
              { code: "1EDC", quantity: 0, name: "Sleeping" },
            ],
          },
        ],
      },
      {
        code: "1F40",
        quantity: 0,
        name: "Location",
        subGroups: [
          { code: "1FA4", quantity: 0, name: "Departure" },
          { code: "2008", quantity: 0, name: "Arrival" },
        ],
      },
      {
        code: "206C",
        quantity: 0,
        name: "Terminal",
        subGroups: [
          {
            code: "20D0",
            quantity: 0,
            name: "Amenities",
            subGroups: [
              { code: "2134", quantity: 0, name: "Shower" },
              { code: "2198", quantity: 0, name: "Food and Beverage" },
              { code: "21FC", quantity: 0, name: "Sleeping" },
              { code: "2260", quantity: 0, name: "Shops" },
              { code: "22C4", quantity: 0, name: "Internet" },
            ],
          },
        ],
      },
      {
        code: "2328",
        quantity: 0,
        name: "Check In",
        subGroups: [
          { code: "238C", quantity: 0, name: "Priority" },
          { code: "23F0", quantity: 0, name: "Manual" },
          { code: "2454", quantity: 0, name: "Internet" },
          { code: "24B8", quantity: 0, name: "Automated" },
          { code: "251C", quantity: 0, name: "Kiosk" },
        ],
      },
      {
        code: "2580",
        quantity: 0,
        name: "Boarding",
        subGroups: [{ code: "25E4", quantity: 0, name: "Priority" }],
      },
      {
        code: "2648",
        quantity: 0,
        name: "Security",
        subGroups: [{ code: "26AC", quantity: 0, name: "Priority" }],
      },
      {
        code: "2710",
        quantity: 0,
        name: "Medical Equipment",
        subGroups: [
          { code: "2774", quantity: 0, name: "Oxygen" },
          { code: "27D8", quantity: 0, name: "Stretcher" },
          { code: "283C", quantity: 0, name: "Wheelchair" },
          { code: "28A0", quantity: 0, name: "Incubator" },
        ],
      },
      {
        code: "2904",
        quantity: 0,
        name: "Escort",
        subGroups: [
          { code: "2968", quantity: 0, name: "Minor" },
          { code: "29CC", quantity: 0, name: "Adults" },
          { code: "2A30", quantity: 0, name: "Secured" },
          { code: "2A94", quantity: 0, name: "Senior" },
          { code: "2AF8", quantity: 0, name: "Medical" },
          { code: "2B5C", quantity: 0, name: "VIP" },
          { code: "2BC0", quantity: 0, name: "Service Animal" },
          { code: "2C24", quantity: 0, name: "Emotional Support Animal" },
        ],
      },
    ],
  },
  {
    code: "2C88",
    quantity: 0,
    name: "Ground",
    subGroups: [
      {
        code: "2CEC",
        quantity: 0,
        name: "Transport",
        subGroups: [
          { code: "2D50", quantity: 0, name: "Car Hire" },
          { code: "2DB4", quantity: 0, name: "Taxi" },
          { code: "2E18", quantity: 0, name: "Ride Share" },
          { code: "2E7C", quantity: 0, name: "Train" },
          { code: "2EE0", quantity: 0, name: "Boat" },
          { code: "2F44", quantity: 0, name: "Cruise" },
          { code: "2FA8", quantity: 0, name: "Bus" },
          { code: "300C", quantity: 0, name: "Tour" },
          { code: "3070", quantity: 0, name: "Limo" },
        ],
      },
      { code: "30D4", quantity: 0, name: "Parking" },
      { code: "3138", quantity: 0, name: "Accommodation" },
    ],
  },
  {
    code: "319C",
    quantity: 0,
    name: "Journey",
    subGroups: [
      { code: "3200", quantity: 0, name: "Insurance" },
      { code: "3264", quantity: 0, name: "Visa Services" },
      { code: "32C8", quantity: 0, name: "Book and Hold" },
    ],
  },
];

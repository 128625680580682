import { defineStore } from "pinia";
import carRentalAvailabilityTools from "@/tools/v2/carrental/availability/carRentalAvailabilityTools";
import carRentalAvailApi from "@/apis/v2/carrental/carRentalAvailApi";
import carRentalSortTools from "@/tools/v2/carrental/availability/carRentalSortTools";
import { CarRentalItemsSortFormModel } from "@/formmodel/sales/carrental/availablility/carRentalItemsSortFormModel";
import carRentalFilterTools from "@/tools/v2/carrental/availability/carRentalFilterTools";

export const useCarRentalAvailabilityStore = defineStore(
  "carRentalAvailability",
  {
    state: () => ({
      carRentalAvailFormModel: null,
      carRentalItems: null,
      carRentalItemsMatch: null,
      totalResult: 0,
      carRentalItemsShow: null,
      filterOptions: null,
      limit: 6, //每页限制条数
      currentPage: 1,
      pageSize: 0, //总页数
    }),
    actions: {
      carRentalAvail(carRentalAvailFormModel) {
        let thisRef = this;
        thisRef.carRentalAvailFormModel = carRentalAvailFormModel;
        const availCarRentalRQ =
          carRentalAvailabilityTools.buildCarRentalAvailRQ(
            carRentalAvailFormModel
          );

        //搜索可租车辆
        return carRentalAvailApi
          .carRentalAvail(availCarRentalRQ)
          .then((res) => {
            const availVehiclesRS = res.data;
            thisRef.carRentalItems =
              carRentalAvailabilityTools.buildCarRentalItems(availVehiclesRS);

            if (!thisRef.carRentalItems) thisRef.carRentalItems = [];
            thisRef.pageSize = Math.ceil(
              thisRef.carRentalItems.length / thisRef.limit
            );
            thisRef.totalResult = thisRef.carRentalItems.length;
            //先排序
            if (thisRef.carRentalItems && thisRef.carRentalItems.length > 0) {
              thisRef.carRentalItems = carRentalSortTools.sortCarRentalItems(
                thisRef.carRentalItems,
                new CarRentalItemsSortFormModel("PRICE", "ASC")
              );
            }
            thisRef.carRentalItemsShow =
              carRentalAvailabilityTools.findSpecificCarRentalItems(
                thisRef.carRentalItems,
                1,
                thisRef.limit
              );

            thisRef.carRentalItemsMatch = thisRef.carRentalItems;
          })
          .catch((err) => {
            thisRef.carRentalItems = null;
            thisRef.carRentalItemsShow = null;
            thisRef.carRentalItemsMatch = null;
            console.error(err);
          });
      },
      changeCarRentalItemsShow(
        currentPage,
        isLoadMore,
        carRentalItemsFilterFormModel,
        carRentalItemsSortFormModel
      ) {
        const thisRef = this;
        if (isLoadMore) {
          thisRef.currentPage = currentPage;
          thisRef.carRentalItemsShow =
            carRentalAvailabilityTools.findSpecificCarRentalItems(
              thisRef.carRentalItemsMatch,
              currentPage,
              thisRef.limit
            );
        } else {
          thisRef.currentPage = 1;
          let filterCarRentalItemsMatch = [];
          if (carRentalItemsFilterFormModel) {
            filterCarRentalItemsMatch =
              carRentalFilterTools.filterCarRentalItems(
                thisRef.carRentalItems,
                carRentalItemsFilterFormModel
              );
          }

          if (carRentalItemsSortFormModel) {
            filterCarRentalItemsMatch = carRentalSortTools.sortCarRentalItems(
              filterCarRentalItemsMatch,
              carRentalItemsSortFormModel
            );
          }
          thisRef.carRentalItemsMatch = filterCarRentalItemsMatch;
          if (filterCarRentalItemsMatch) {
            thisRef.totalResult = filterCarRentalItemsMatch.length;
            thisRef.carRentalItemsShow =
              carRentalAvailabilityTools.findSpecificCarRentalItems(
                filterCarRentalItemsMatch,
                1,
                thisRef.limit
              );
          } else {
            thisRef.totalResult = 0;
            thisRef.carRentalItemsShow = [];
          }
        }
      },
    },
  }
);

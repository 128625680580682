<script setup>
import { useRouter } from "vue-router";

const props = defineProps({
  isReservation: {},
  tripCarRentalItem: {},
});

const router = useRouter();
const emits = defineEmits([]);

function sortCharges(charges) {
  return charges.sort((a, b) => {
    //charges 排序
    //第一个Basic rental
    if (a.description === "Basic Rental") {
      return -1;
    } else if (b.description === "Basic Rental") {
      return 1;
    }
    //最后VAT
    else if (a.description === "VAT" && b.description === "VAT") {
      return a.amount - b.amount;
    } else if (a.description === "VAT") {
      return 1;
    } else if (b.description === "VAT") {
      return -1;
    }
    //Basic Rental-Free Miles
    else if (a.description === "Basic Rental-Free Miles") {
      return -1;
    } else if (b.description === "Basic Rental-Free Miles") {
      return 1;
    }
    //包含- amount =0；
    else if (a.amount === 0 && b.amount !== 0) {
      return -1;
    } else if (b.amount === 0 && a.amount !== 0) {
      return 1;
    }
    //不包含的 - amount > 0
    else if (a.amount !== 0 && b.amount !== 0) {
      return a.amount - b.amount;
    }
  });
}

function confirmNow() {
  emits("confirmNow");
}
</script>

<template>
  <div class="tour_details_boxed" v-if="tripCarRentalItem">
    <h3 class="heading_theme">{{ $t("price-summary") }}</h3>
    <div class="row">
      <div class="col-12">
        <div class="row text-muted">
          <div class="col-8">{{ $t("description-uppercase") }}</div>
          <div class="col-4 text-end">
            {{ $t("price-uppercase") }}
            <!--            ({{ tripCarRentalItem.currencyCode }})-->
          </div>
        </div>
        <hr />
        <div
          class="row"
          v-if="
            tripCarRentalItem.charges && tripCarRentalItem.charges.length > 0
          "
        >
          <div class="col-lg-8 col-8">
            <figure
              v-for="(charge, index) in sortCharges(tripCarRentalItem.charges)"
              :key="index"
            >
              {{ charge.description }}
            </figure>
          </div>
          <div class="col-lg-4 col-4">
            <figure
              class="text-end"
              v-for="(charge, index) in sortCharges(tripCarRentalItem.charges)"
              :key="index"
            >
              {{ charge.currency }}
              {{ charge.amount ? charge.amount.toFixed(2) : "0.00" }}
            </figure>
          </div>
          <hr class="mg-up-10" />
        </div>

        <div class="row">
          <div
            class="col-lg-8 col-8 text-muted"
            v-if="tripCarRentalItem.origNetPrice"
          >
            {{ $t("original-net-price") }}
          </div>
          <div
            class="col-lg-4 col-4 text-muted text-end"
            v-if="tripCarRentalItem.origNetPrice"
          >
            {{ tripCarRentalItem.origNetPrice }}
          </div>
          <div class="col-lg-8 col-8 font_weight">
            <figure>
              <div>
                {{ $t("total-uppercase") }}
                ({{ tripCarRentalItem.currencyCode }})
              </div>
            </figure>
          </div>
          <div class="col-lg-4 col-4 font_weight">
            <figure class="text-end text-danger">
              <div>
                {{ tripCarRentalItem.currencyCode }}
                {{ tripCarRentalItem.totalAmount.toFixed(2) }}
              </div>
            </figure>
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-t-10 mg-b-20" v-if="!isReservation">
      <div class="col-12 text-center">
        <button class="btn btn_theme" type="button" @click="confirmNow">
          {{ $t("confirm") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

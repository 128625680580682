import { defineStore } from "pinia";
import carRentalRatesTools from "@/tools/v2/carrental/rates/carRentalRatesTools";
import carRentalRatesApi from "@/apis/v2/carrental/carRentalRatesApi";

export const useCarRentalRatesStore = defineStore("carRentalRates", {
  state: () => ({
    carRentalRatesFormModel: null,
    carRentalItem: null,
    equipmentOptions: null, //租车设备可选项
    coverageOptions: null, //租车保险可选项
  }),
  actions: {
    carRentalRates(carRentalRatesFormModel) {
      const rateRQ = carRentalRatesTools.buildCarRentalRateRQ(
        carRentalRatesFormModel
      );
      let thisRef = this;
      thisRef.carRentalRatesFormModel = carRentalRatesFormModel;
      //搜索可租车辆
      return carRentalRatesApi
        .carRentalRates(rateRQ)
        .then((res) => {
          const availVehicleRatesRS = res.data;

          if (availVehicleRatesRS.vehAvailRSCore) {
            thisRef.carRentalItem =
              carRentalRatesTools.buildCarRentalItem(availVehicleRatesRS);

            thisRef.equipmentOptions =
              carRentalRatesTools.buildEquipmentOptions(availVehicleRatesRS);

            thisRef.coverageOptions =
              carRentalRatesTools.buildCoverageOptions(availVehicleRatesRS);
          } else {
            thisRef.carRentalItem = null;
            thisRef.equipmentOptions = null;
            thisRef.coverageOptions = null;
          }

          return availVehicleRatesRS;
        })
        .catch((err) => {
          console.error(err);
          thisRef.carRentalItem = null;
        });
    },
    carRentalRatesQuote(carRentalRatesQuoteFormModel) {
      const rateQuoteRQ = carRentalRatesTools.buildCarRentalRatesQuoteRQ(
        carRentalRatesQuoteFormModel
      );
      let thisRef = this;
      //保险以及设备验价
      return carRentalRatesApi
        .carRentalRates(rateQuoteRQ)
        .then((res) => {
          const availVehicleRatesRS = res.data;

          if (availVehicleRatesRS.vehAvailRSCore) {
            thisRef.carRentalItem =
              carRentalRatesTools.buildCarRentalItem(availVehicleRatesRS);
          } else {
            thisRef.carRentalItem = null;
          }

          return availVehicleRatesRS;
        })
        .catch((err) => {
          console.error(err);
          thisRef.carRentalItem = null;
        });
    },
  },
});

<script setup>
import dateUtils from "@/utils/dateUtils";

defineProps({
  cancelPolicies: {
    type: Array,
  },
  roomTypeName: {},
});

function sortByDate(cancelPolicies) {
  if (!cancelPolicies) return cancelPolicies;

  return cancelPolicies.sort((a, b) => {
    return a.beginDate - b.beginDate;
  });
}
</script>

<template>
  <BaseBlock :title="$t('cancel-policies')" class="mb-0 p-3" transparent>
    <h4 class="mb-2">{{ roomTypeName }}</h4>
    <template #content>
      <div
        v-for="(policy, itemIndex) in sortByDate(cancelPolicies)"
        :key="itemIndex"
        class="block-content fs-sm"
      >
        <p v-if="itemIndex === 0" class="fs-sm fw-medium mb-2 text-muted">
          {{ $t("times-are-based-on-local") }}
        </p>
        <p
          v-if="policy.unitType === 'Amount'"
          class="fs-sm fw-medium mb-2 text-muted"
        >
          <span
            v-if="policy.nmbrOfUnit"
            v-html="
              $t('cancellation-policy-refundable-caution', {
                beginTime: policy.beginDate
                  ? policy.beginDate.replace('T', ' ')
                  : '',
                endTime: dateUtils.getOffsetDateTime(
                  policy.beginDate,
                  policy.offsetUnitMultiplier,
                  policy.offsetTimeUnit
                ),
                currency: policy.currencyCode,
                amount: policy.nmbrOfUnit,
              })
            "
          >
          </span>
        </p>
        <p
          v-else-if="policy.unitType === 'Night'"
          class="fs-sm fw-medium mb-2 text-muted"
        >
          <span
            v-html="
              $t('cancellation-policy-nights-caution', {
                beginTime: policy.beginDate
                  ? policy.beginDate.replace('T', ' ')
                  : '',
                endTime: dateUtils.getOffsetDateTime(
                  policy.beginDate,
                  policy.offsetUnitMultiplier,
                  policy.offsetTimeUnit
                ),
                nmbrOfUnit: policy.nmbrOfUnit,
              })
            "
          >
          </span>
        </p>
      </div>
      <div class="block-content block-content-full text-end bg-body">
        <button
          class="btn btn-sm btn-alt-secondary me-1"
          data-bs-dismiss="modal"
          type="button"
        >
          {{ $t("close") }}
        </button>
      </div>
    </template>
  </BaseBlock>
</template>

<style lang="scss" scoped></style>

<script setup>
import dateUtils from "@/utils/dateUtils";
import BaseCancelPolicyContent from "@/views/sales/hotel/items/panel/BaseCancelPolicyPanel.vue";

defineProps({
  hotelViewShow: {},
});

function getPolicyModelKey(modelIndex) {
  return "C" + modelIndex;
}
</script>

<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="booking_tour_form">
        <div>
          <div
            class="tour_booking_form_box hotel_details_content_area"
            v-if="hotelViewShow"
          >
            <h3>{{ hotelViewShow.hotelName }}</h3>
            <span class="address">
              {{ hotelViewShow.address }}
            </span>
            <h4>
              {{ hotelViewShow.roomType }} * {{ hotelViewShow.roomNum }} Room(s)
            </h4>
            <p>
              <i class="fas fa-bell" v-if="hotelViewShow.mealName"></i>
              {{ hotelViewShow.mealName }}
            </p>
            <!--            <p-->
            <!--              class="import-content mb-2"-->
            <!--              v-if="hotelViewShow.refundable"-->
            <!--              data-bs-toggle="modal"-->
            <!--              :data-bs-target="'#' + getPolicyModelKey(1)"-->
            <!--              style="color: red"-->
            <!--            >-->
            <!--              {{ $t("refundable") }} <i class="fa fa-circle-info"></i>-->
            <!--            </p>-->
            <p class="import-content mb-2" style="color: red">
              {{ $t("non-refundable") }}
              <i class="fa fa-circle-info"></i>
            </p>
            <p>
              <span class="check-date">{{ $t("check-in") }}: </span
              >{{ hotelViewShow.checkIn }}
            </p>
            <p>
              <span class="check-date">{{ $t("check-out") }}: </span>
              {{ hotelViewShow.checkOut }}
            </p>
            <p>
              {{
                $t("num-night-stay", {
                  num: dateUtils.getDaysByDateRange(
                    hotelViewShow.checkIn,
                    hotelViewShow.checkOut
                  ),
                })
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Cancel Policies Slide Left Block Modal -->
  <div
    class="modal fade"
    :id="getPolicyModelKey(1)"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="getPolicyModelKey(1)"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <BaseCancelPolicyContent
          :title="'Cancellation Policies'"
          :room-type-name="hotelViewShow.roomType"
          :items="hotelViewShow.roomViewItems"
        ></BaseCancelPolicyContent>
      </div>
    </div>
  </div>
  <!-- END Cancel Policies Slide Left Block Modal -->
</template>

<style scoped></style>

<template>
     <section id="news_main_arae" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="news_two_card">
                        <div class="news_item_boxed">
                            <div class="news_item_img">
                                <router-link to="/templates/news-details"><img src="../../../assets/img/news/news-two-1.png" alt="img"></router-link>
                            </div>
                            <div class="news_author_area">
                                <div class="news_author_img">
                                    <img src="../../../assets/img/news/author-1.png" alt="img">
                                </div>
                                <div class="news_author_area_name news_two_author">
                                    <h4>Jennifer lawrence</h4>
                                    <p><i class="fas fa-circle"></i> <router-link to="/templates/news">26 Oct 2021</router-link> <i
                                            class="fas fa-circle"></i> 8 min read</p>
                                </div>
                            </div>
                            <div class="news_item_content news_two_content">
                                <h2>
                                    <router-link to="/templates/news-details">
                                        Veniam ex tempor qui ad amet mollit cillum aliqua aliqua. Fugiat tempor eu magna
                                    </router-link>
                                </h2>
                                <p>
                                    It is a long established fact that a reader will be distracted by the readable
                                    content of a page when looking at its fru layout. The point of using Lorem Ipsum is
                                    that it has a more. <router-link to="/templates/news-details"> Read more...</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="news_two_card">
                        <div class="news_item_boxed">
                            <div class="news_item_img">
                                <router-link to="/templates/news-details"><img src="../../../assets/img/news/news-two-2.png" alt="img"></router-link>
                            </div>
                            <div class="news_author_area">
                                <div class="news_author_img">
                                    <img src="../../../assets/img/news/author-1.png" alt="img">
                                </div>
                                <div class="news_author_area_name news_two_author">
                                    <h4>Jennifer lawrence</h4>
                                    <p><i class="fas fa-circle"></i> <router-link to="/templates/news">26 Oct 2021</router-link> <i
                                            class="fas fa-circle"></i> 8 min read</p>
                                </div>
                            </div>
                            <div class="news_item_content news_two_content">
                                <h2>
                                    <router-link to="/templates/news-details">
                                        Veniam ex tempor qui ad amet mollit cillum aliqua aliqua. Fugiat tempor eu magna
                                    </router-link>
                                </h2>
                                <p>
                                    It is a long established fact that a reader will be distracted by the readable
                                    content of a page when looking at its fru layout. The point of using Lorem Ipsum is
                                    that it has a more. <router-link to="/templates/news-details"> Read more...</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="news_two_card">
                        <div class="news_item_boxed">
                            <div class="news_item_img">
                                <router-link to="/templates/news-details"><img src="../../../assets/img/news/news-two-3.png" alt="img"></router-link>
                            </div>
                            <div class="news_author_area">
                                <div class="news_author_img">
                                    <img src="../../../assets/img/news/author-1.png" alt="img">
                                </div>
                                <div class="news_author_area_name news_two_author">
                                    <h4>Jennifer lawrence</h4>
                                    <p><i class="fas fa-circle"></i> <router-link to="/templates/news">26 Oct 2021</router-link> <i
                                            class="fas fa-circle"></i> 8 min read</p>
                                </div>
                            </div>
                            <div class="news_item_content news_two_content">
                                <h2>
                                    <router-link to="/templates/news-details">
                                        Veniam ex tempor qui ad amet mollit cillum aliqua aliqua. Fugiat tempor eu magna
                                    </router-link>
                                </h2>
                                <p>
                                    It is a long established fact that a reader will be distracted by the readable
                                    content of a page when looking at its fru layout. The point of using Lorem Ipsum is
                                    that it has a more. <router-link to="/templates/news-details"> Read more...</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="news_two_card">
                        <div class="news_item_boxed">
                            <div class="news_item_img">
                                <router-link to="/templates/news-details"><img src="../../../assets/img/news/news-two-4.png" alt="img"></router-link>
                            </div>
                            <div class="news_author_area">
                                <div class="news_author_img">
                                    <img src="../../../assets/img/news/author-1.png" alt="img">
                                </div>
                                <div class="news_author_area_name news_two_author">
                                    <h4>Jennifer lawrence</h4>
                                    <p><i class="fas fa-circle"></i> <router-link to="/templates/news">26 Oct 2021</router-link> <i
                                            class="fas fa-circle"></i> 8 min read</p>
                                </div>
                            </div>
                            <div class="news_item_content news_two_content">
                                <h2>
                                    <router-link to="/templates/news-details">
                                        Veniam ex tempor qui ad amet mollit cillum aliqua aliqua. Fugiat tempor eu magna
                                    </router-link>
                                </h2>
                                <p>
                                    It is a long established fact that a reader will be distracted by the readable
                                    content of a page when looking at its fru layout. The point of using Lorem Ipsum is
                                    that it has a more. <router-link to="/templates/news-details"> Read more...</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="pagination_area">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">«</span>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">»</span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="news_area_sidebar_area">
                        <div class="news_sidebar_content">
                            <div class="news_sidebar_heading">
                                <h3>Search by name</h3>
                            </div>
                            <div class="news_sidebar_search">
                                <form action="#!">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="e.g Hotel castle salam"
                                            required>
                                        <button type="submit" class="btn"><img src="../../../assets/img/icon/search.png"
                                                alt="icon"></button>
                                    </div>

                                </form>

                            </div>
                        </div>
                        <div class="news_sidebar_content">
                            <div class="news_sidebar_heading">
                                <h3>Category</h3>
                            </div>
                            <div class="news_sidebar_content_inner">
                                <ul class="news_sidebar_category">
                                    <li><a href="#!">Travel agent</a></li>
                                    <li><a href="#!">Honeymoon</a></li>
                                    <li><a href="#!">Flights</a></li>
                                    <li><a href="#!">Adventure</a></li>
                                    <li><a href="#!">Hotels</a></li>
                                    <li><a href="#!">Apartments</a></li>
                                    <li><a href="#!">Flight service</a></li>
                                    <li><a href="#!">Visa processing</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="news_sidebar_content">
                            <div class="news_sidebar_heading">
                                <h3>Tags</h3>
                            </div>
                            <div class="news_sidebar_content_inner">
                                <ul class="news_sidebar_tags">
                                    <li><a href="#!">Flight</a></li>
                                    <li><a href="#!">Travel</a></li>
                                    <li><a href="#!">Agency</a></li>
                                    <li><a href="#!">Hotel</a></li>
                                    <li><a href="#!">Lifestyle</a></li>
                                    <li><a href="#!">Healthy</a></li>
                                    <li><a href="#!">Group</a></li>
                                    <li><a href="#!">Couple</a></li>
                                    <li><a href="#!">Drinks</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="news_sidebar_content">
                            <div class="sidebar_advasting_area">
                                <a href="#!"><img src="../../../assets/img/news/news-sidebar.png" alt="img"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
name: "NewsAreaTwo"
};
</script>
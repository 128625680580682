<script setup>
import BasePackagePassengerFormItem from "@/views/sales/package/items/form/BasePackagePassengerFormItem";
import { ref } from "vue";
import contentApi from "@/apis/contentApi";

defineProps({
  roomResGuest: {
    type: Object,
  },
  roomIndex: {},
});

const passengerFormItemRef = ref(null);
//获取所有的航空公司联盟
let frequentFlyerProgramOption = ref([]);
frequentFlyerProgramOption.value.push({
  programID: null,
  programName: "Choose Frequent Flyer Airline(option)",
});
contentApi.frequentFlyerPrograms().then((res) => {
  if (res.data && res.data.length > 0) {
    res.data.sort(function (a, b) {
      if (a.programName < b.programName) {
        return -1;
      }
      if (a.programName > b.programName) {
        return 1;
      }
      return 0;
    });
    frequentFlyerProgramOption.value.push(...res.data);

    sessionStorage.setItem(
      "frequentFlyerProgram",
      JSON.stringify(frequentFlyerProgramOption.value)
    );
  }
});
const validatePhoneAndPassport = () => {
  passengerFormItemRef.value.forEach((ele) => {
    ele.validateAllCustom();
  });
};

defineExpose({
  validatePhoneAndPassport,
});
</script>
<template>
  <div
    class="booking_tour_form tour_booking_form_box"
    v-if="roomResGuest.resGuests && roomResGuest.resGuests.length > 0"
  >
    <h4>{{ $t("room") }}{{ roomIndex }}:</h4>
    <!--传入roomIndex区分乘客表单同项-->
    <BasePackagePassengerFormItem
      :is-flight="false"
      class="margin-bottom-20"
      ref="passengerFormItemRef"
      v-for="(passenger, passengerIndex) in roomResGuest.resGuests"
      :key="passengerIndex"
      :passenger-index="passengerIndex + 1"
      :passenger-model="passenger"
      :room-index="roomIndex"
      :frequent-flyer-programs="frequentFlyerProgramOption"
    />
  </div>
</template>

<style scoped></style>

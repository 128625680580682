import { defineStore } from "pinia";
import contentApi from "@/apis/contentApi";

export const useContentStore = defineStore("content", {
  state: () => ({
    childrenPlaces: null,
  }),
  actions: {
    loadChildrenPlaces(placeId) {
      let _this = this;
      return contentApi.childrenPlaces(placeId).then((res) => {
        _this.childrenPlaces = res.data;
        return {};
      });
    },
  },
});

<script setup>
import { useTemplateStore } from "@/stores/template";
import { computed, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import dateUtils from "@/utils/dateUtils";
import ActivityDateCheckGroup from "@/views/v2/sales/activity/items/goup/ActivityDateCheckGroup.vue";

// Main store and Router
const templateStore = useTemplateStore();
const router = useRouter();

const props = defineProps({
  activityDetails: {},
});
const emits = defineEmits();
let activitySearchFormModel = reactive(
  JSON.parse(sessionStorage.getItem("activitySearchFormModel"))
);

//region 数据初始化
let activeDateIndex = ref(0); //选择的时间
//textItems 描述信息分组渲染
const textGroup = computed(() => {
  if (props.activityDetails) {
    const textItems = props.activityDetails.textItems;

    let textMap = {};
    let textGroupMap = {};
    if (textItems && textItems.length > 0) {
      textMap = textItems.reduce((acc, currentObj) => {
        const key = currentObj.category;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(currentObj);
        return acc;
      }, {});
    }

    if (textMap) {
      for (const key in textMap) {
        if (key == "BASIC_DESCRIPTION") {
          textGroupMap["basic_description"] = textMap[key];
        } else if (key == "QUESTION") {
          textGroupMap["questions_to_be_answered"] = textMap[key];
          sessionStorage.setItem(
            "activityQuestion",
            JSON.stringify(textGroupMap["questions_to_be_answered"])
          );
        } else {
          let keyFormat = key.toLocaleLowerCase();
          textGroupMap[keyFormat] = textMap[key];
        }
      }
    }

    return textGroupMap;
  }
});

let tourActivityGroupViewItems = ref([]); //选择不同的date筛选到的group组

watch(
  () => props.activityDetails,
  (newValue) => {
    tourActivityGroupViewItems.value = [];
    changeDate(newValue.priceAvailDate[0].start, 0);
  },
  { immediate: true, deep: true }
);

//endregion

function submitPassengerForm(reqBoby) {
  emits("initialDetails", reqBoby);
}

//region 辅助函数
function scrollInto(id) {
  if (!document.getElementById(id + "panel")) return;
  document.getElementById(id + "panel").scrollIntoView();
}

//切换时间，筛选对应的group
function changeDate(day, index) {
  tourActivityGroupViewItems.value = [];
  activeDateIndex.value = index;
  //所有的group
  const groups = props.activityDetails.groups;

  const participantCategories = props.activityDetails.participantCategories;
  if (groups && groups.length > 0) {
    groups.forEach((group) => {
      const prices = group.prices;
      const groupCode = group.groupCode;
      const groupName = group.groupName;
      let groupCategories = []; //该group的所有价格分类

      if (participantCategories && participantCategories.length > 0) {
        participantCategories.forEach((item) => {
          const rph = item.price.rph;
          if (rph === groupCode) {
            groupCategories.push(item);
          }
        });
      }

      //判断该组是否符合选择的该时间
      let languageGroupMap = {};
      if (prices && prices.length > 0) {
        prices.forEach((price) => {
          const operationDates = price.operationDates;
          let isAvailDay = operationDates.some((item) => item.start == day); //是否有满足的日期
          if (isAvailDay) {
            //当前语言组符合这个
            const currencyCode = price.currencyCode;
            const amount = price.amount; //总价
            const languageRPH = price.rph; //语言分组标识
            const description = price.description;

            let languageAgeGroup = groupCategories.filter(
              (priceItem) => priceItem.price.rateCode == languageRPH
            ); //所有该语言组里面包含的价格信息（年龄区分）

            let languageAgeGroupMap = {};
            if (languageAgeGroup && languageAgeGroup.length > 0) {
              languageAgeGroupMap = languageAgeGroup.reduce(
                (acc, currentObj) => {
                  let key = null;
                  if (currentObj.maxAge != "999") {
                    key = currentObj.minAge + "-" + currentObj.maxAge;
                  } else {
                    key = currentObj.minAge + "+";
                  }
                  if (!acc[key]) {
                    acc[key] = {};
                  }
                  acc[key] = currentObj;
                  return acc;
                },
                {}
              );
            }

            const keys = Object.keys(languageAgeGroupMap).sort(
              (a, b) => b.split("-")[0] - a.split("-")[0]
            );
            let sortedLanguageAgeGroupMap = {};
            for (let key of keys) {
              sortedLanguageAgeGroupMap[key] = languageAgeGroupMap[key];
            }

            const language =
              languageAgeGroup[0].tpaExtensionsType.valueMap.LANGUAGE;

            if (!language) {
              languageGroupMap["Default"] = {
                currencyCode: currencyCode,
                amount: amount,
                description: description,
                language: language,
                rph: languageRPH,

                ageGroupMap: sortedLanguageAgeGroupMap,
              };
            } else {
              languageGroupMap[language] = {
                currencyCode: currencyCode,
                amount: amount,
                description: description,
                language: language,
                rph: languageRPH,

                ageGroupMap: sortedLanguageAgeGroupMap,
              };
            }
          }
        });
      }

      tourActivityGroupViewItems.value.push({
        groupCode: groupCode,
        groupName: groupName,
        languageGroupMap: languageGroupMap,
      });
    });
  }
}

function selectActivityGroupItem(languageKey, groupItem) {
  const startDate =
    props.activityDetails.priceAvailDate[activeDateIndex.value].start;

  const languageGroupMap = groupItem.languageGroupMap;

  if (languageGroupMap) {
    const groupItem = languageGroupMap[languageKey];
    const languageRPH = groupItem.rph;
    sessionStorage.setItem(
      "selectActivityGroupInfo",
      JSON.stringify({ startDate: startDate, languageRPH: languageRPH })
    );
  }
  router.push({ name: "sales-activity-passengers-v2" });
}

//endregion
</script>

<template>
  <div v-if="activityDetails">
    <div class="tour_details_boxed">
      <h3 class="heading_theme">{{ $t("overview") }}</h3>
      <div class="row">
        <div class="col-5">
          <h3 class="fw-normal mb-1">
            {{ activityDetails.name }}
          </h3>
          <p>{{ activityDetails.shortName }}</p>

          <p class="fs-sm text-overflow-hidden mb-0">
            <span
              >{{ $t("duration") }}：
              {{ activityDetails.operationDuration }}</span
            >
            <span class="text-danger mg-l-20"
              >{{ $t("cancel-at") }}
              {{ dateUtils.getOffsetDate(activityDetails.cancelDate, 0) }}</span
            >
          </p>
          <p v-if="activityDetails.meetingPoint">
            {{ activityDetails.meetingPoint }}
          </p>
          <div
            v-if="activityDetails.supplierInfo && activityDetails.supplierInfo"
            class="mb-0"
          >
            {{ activityDetails.supplierInfo.name.companyShortName }} ({{
              activityDetails.supplierInfo.name.code
            }})
          </div>
          <div class="row mg-t-10">
            <div
              v-if="
                activityDetails.routePoints &&
                activityDetails.routePoints.length > 0
              "
              class="col-12 text-start"
            >
              <span class="h6">{{ $t("visiting") }}: </span
              ><span
                v-for="(point, index) in activityDetails.routePoints"
                :key="index"
                >{{ point.address }},{{ point.cityName }}
                <i
                  v-if="index != activityDetails.routePoints.length - 1"
                  class="bi bi-arrow-right mg-l-8 mg-r-8 main_color"
                ></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-7">
          <h6>{{ $t("extras") }}</h6>
          <div class="features">
            <span
              v-for="(extra, index) in activityDetails.extras"
              class="mg-r-8"
              ><span v-if="extra.description"
                >{{ index + 1 }}. {{ extra.description }}</span
              ></span
            >
          </div>
          <h6 class="mg-t-10">{{ $t("overview") }}</h6>
          <div class="activity_overview">
            <div v-if="activityDetails.pickupLocation">
              <i class="bi bi-circle-fill mg-r-8" style="font-size: 8px"></i
              ><span v-html="activityDetails.pickupLocation"></span>
            </div>
            <div v-if="activityDetails.dropoffLocation">
              <i class="bi bi-circle-fill mg-r-8" style="font-size: 8px"></i
              ><span v-html="activityDetails.dropoffLocation"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tour_details_boxed available_date">
      <h3 class="heading_theme">{{ $t("check-availability") }}</h3>
      <!-- avail date  start  -->
      <div class="date_items">
        <div
          v-for="(day, index) in activityDetails.priceAvailDate"
          :class="{ item_active: activeDateIndex === index }"
          class="item"
          @click="changeDate(day.start, index)"
        >
          <span class="date-item">{{
            new Date(day.start).toDateString().replace(" ", " ,")
          }}</span>
        </div>
      </div>
      <!-- avail date  end  -->
      <!-- group start   -->
      <div
        v-if="
          tourActivityGroupViewItems && tourActivityGroupViewItems.length > 0
        "
        class="mg-t-10"
      >
        <ActivityDateCheckGroup
          v-for="(groupViewItem, index) in tourActivityGroupViewItems"
          :key="index"
          :group-view-item="groupViewItem"
          @initialDetails="submitPassengerForm"
          @selectActivityGroupItem="selectActivityGroupItem"
        />
      </div>
      <!-- group end   -->
    </div>

    <div
      class="tour_details_boxed"
      v-if="textGroup && Object.keys(textGroup).length > 0"
    >
      <h3 class="heading_theme">{{ $t("description") }}</h3>

      <div class="tour_details_boxed_inner mg-t-10">
        <div v-for="(text, textKey) in textGroup" :key="textKey" class="row">
          <div class="col-4">
            <h3 id="btabs-alt-static-rooms-tabpanel" class="fw-normal mb-1">
              {{ textKey.replaceAll("_", " ") }}
            </h3>
          </div>
          <div class="col-8 about">
            <div
              v-if="textGroup[textKey] && textGroup[textKey].length > 0"
              class="list"
            >
              <div
                v-for="(textItem, textIndex) in textGroup[textKey]"
                :key="textIndex"
              >
                <div
                  v-if="
                    textItem.descriptionList &&
                    textItem.descriptionList.length > 0
                  "
                >
                  <i class="bi bi-check-lg mg-r-8"></i
                  ><span
                    v-html="textItem.descriptionList[0].stringValue"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// Flatpickr + Custom overrides
@import "flatpickr/dist/flatpickr.css";
</style>

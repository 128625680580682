<script setup>
import { reactive, ref, watch } from "vue";
import BaseFlightSearchFormItem from "@/views/sales/flight/items/form/BaseFlightSearchFormItem.vue";
import flightTools from "@/tools/flightTools";
import dateUtils from "@/utils/dateUtils";
import useVuelidate from "@vuelidate/core";
import { useTemplateStore } from "@/stores/template";
import { useFlightStore } from "@/stores/flight";
import { useRouter, useRoute } from "vue-router";

let props = defineProps({
  jumboable: {
    type: Boolean,
  },
  jumpTo: {
    type: String,
    description: "route name to jump",
  },
  equipmentType: {},
});
const emits = defineEmits(["resetShow"]);
let router = useRouter();
let route = useRoute();
let templateStore = useTemplateStore();
let flightStore = useFlightStore();
let tripType = ref("roundtrip");

let oneWayflightSearchFormModelDefault = reactive(
  getFlightSearchFormModelDefault("oneway_flight")
);
let roundWayflightSearchFormModelDefault = reactive(
  getFlightSearchFormModelDefault("roundtrip")
);
let multiWayflightSearchFormModelDefault = reactive(
  getFlightSearchFormModelDefault("multi_way")
);

let flightSearchFormModel = changeFormModelByTripType(tripType.value);

//列表页
if (!props.jumboable) {
  if (route.query != null && Object.keys(route.query).length > 0) {
    let flightSearchFormModelDefault = getFlightSearchFormModel(route);
    tripType.value = flightSearchFormModelDefault.tripType;
    flightSearchFormModel = reactive(flightSearchFormModelDefault);
  }

  let flightSearchFormModelCopy = JSON.parse(
    JSON.stringify(flightSearchFormModel)
  );

  if (tripType.value == "roundtrip") {
    let item = {
      departureDateTime:
        flightSearchFormModelCopy.originDestinations[0].returnDateTime,
      originLocationCode:
        flightSearchFormModelCopy.originDestinations[0].destinationLocationCode,
      originLocationName:
        flightSearchFormModelCopy.originDestinations[0].destinationLocationName,
      originAirportName:
        flightSearchFormModelCopy.originDestinations[0].destinationAirportName,
      destinationLocationCode:
        flightSearchFormModelCopy.originDestinations[0].originLocationCode,
      destinationLocationName:
        flightSearchFormModelCopy.originDestinations[0].originLocationName,
      destinationAirportName:
        flightSearchFormModelCopy.originDestinations[0].originAirportName,
    };
    flightSearchFormModelCopy.originDestinations.push(item);
  }
  searchFlightByFormModel(flightSearchFormModelCopy);
} else {
  //如果是首页
}

function getFlightSearchFormModel(route) {
  let originDestinationsFormat = route.query.originDestinationsFormat;
  originDestinationsFormat;
  if (typeof originDestinationsFormat === "string") {
    originDestinationsFormat = [originDestinationsFormat];
  }
  let originDestinations = parseOriginDestinations(originDestinationsFormat);
  let adultNum = route.query.adultNum;
  let childNum = route.query.childNum;
  let infantNum = route.query.infantNum;
  let cabinClass = route.query.cabinClass;
  let tripType = route.query.tripType;
  let flightSearchFormModel = {
    originDestinations: originDestinations,
    adultNum: adultNum,
    childNum: childNum,
    infantNum: infantNum,
    cabinClass: cabinClass,
    tripType: tripType,
  };
  return flightSearchFormModel;
}

function parseOriginDestinations(formats) {
  let originDestinations = [];
  // ("1111111", formats);
  if (formats.length > 0) {
    formats.forEach((format) => {
      let splits = format.split(":");
      let originLocationCode = splits[0];
      let originLocationName = splits[1];
      let originAirportName = splits[2];
      let destinationLocationCode = splits[3];
      let destinationLocationName = splits[4];
      let destinationAirportName = splits[5];
      let departureDateTime = splits[6];
      let returnDate = splits[7];
      // (returnDate);

      let originDestination = {
        originLocationCode: originLocationCode,
        originLocationName: originLocationName,
        originAirportName: originAirportName,
        destinationLocationCode: destinationLocationCode,
        destinationLocationName: destinationLocationName,
        destinationAirportName: destinationAirportName,
        departureDateTime: departureDateTime,
        returnDateTime: returnDate,
      };
      originDestinations.push(originDestination);
    });
  }
  return originDestinations;
}

function changeTripType(val) {
  tripType.value = val;
  flightSearchFormModel = changeFormModelByTripType(tripType.value);
}

//根据tripType构建默认表单对象
let todayDate = dateUtils.format(new Date());

function changeFormModelByTripType(tripType) {
  return tripType === "oneway_flight"
    ? oneWayflightSearchFormModelDefault
    : tripType === "roundtrip"
    ? roundWayflightSearchFormModelDefault
    : multiWayflightSearchFormModelDefault;
}

function getFlightSearchFormModelDefault(tripType) {
  let flightSearchFormModelDefault = null;
  if (tripType != "multi_way") {
    flightSearchFormModelDefault = flightTools.getDefaultFlightSearchFormModel(
      [
        {
          departureDateTime: dateUtils.getOffsetDate(todayDate, 7),
          originLocationCode: null,
          originLocationName: null,
          originAirportName: null,
          destinationLocationCode: null,
          destinationLocationName: null,
          destinationAirportName: null,
          returnDateTime: null,
        },
      ],
      1,
      0,
      0,
      "Y"
    );
  } else {
    flightSearchFormModelDefault = flightTools.getDefaultFlightSearchFormModel(
      [
        {
          departureDateTime: dateUtils.getOffsetDate(todayDate, 7),
          originLocationCode: null,
          originLocationName: null,
          originAirportName: null,
          destinationLocationCode: null,
          destinationLocationName: null,
          destinationAirportName: null,
        },
        {
          departureDateTime: dateUtils.getOffsetDate(todayDate, 8),
          originLocationCode: null,
          originLocationName: null,
          originAirportName: null,
          destinationLocationCode: null,
          destinationLocationName: null,
          destinationAirportName: null,
        },
      ],
      1,
      0,
      0,
      "Y"
    );
  }

  return flightSearchFormModelDefault;
}

//校验和表单提交
const v = useVuelidate();
function formatOriginDestinations(originDestinations) {
  let formatArr = [];
  originDestinations.forEach((originDestination) => {
    //("1", originDestination);
    //HKG:Hong kong:SIN:Singapore:2012-12-12
    let format =
      originDestination.originLocationCode +
      ":" +
      originDestination.originLocationName +
      ":" +
      originDestination.originAirportName +
      ":" +
      originDestination.destinationLocationCode +
      ":" +
      originDestination.destinationLocationName +
      ":" +
      originDestination.destinationAirportName +
      ":" +
      originDestination.departureDateTime +
      ":" +
      originDestination.returnDateTime;
    //("2", format);
    formatArr.push(format.toString());
  });
  return formatArr;
}

let isOriginLocationShow = ref(false);
let isDestinationLocationShow = ref(false);
async function submitFlightSearchForm(formModel) {
  const result = await v.value.$validate();
  isOriginLocationShow.value = false;
  isDestinationLocationShow.value = false;
  if (v.value.$errors && v.value.$errors.length > 0) {
    v.value.$errors.forEach((item) => {
      if (item.$property == "originLocationCode") {
        isOriginLocationShow.value = true;
      } else if (item.$property == "destinationLocationCode") {
        isDestinationLocationShow.value = true;
      }
    });
  }
  if (!result) return;
  let flightSearchFormModelCopy = JSON.parse(JSON.stringify(formModel));
  flightSearchFormModelCopy.tripType = tripType.value;
  //jump to
  let format = formatOriginDestinations(
    flightSearchFormModelCopy.originDestinations
  );
  flightSearchFormModelCopy.originDestinationsFormat = format;
  formModel.originDestinationsFormat = format;
  formModel.tripType = tripType.value;
  router.replace({ query: formModel });

  if (tripType.value == "roundtrip") {
    if (!props.jumboable) {
      //如果是首页跳转过来
      let item = {
        departureDateTime:
          flightSearchFormModelCopy.originDestinations[0].returnDateTime,
        originLocationCode:
          flightSearchFormModelCopy.originDestinations[0]
            .destinationLocationCode,
        destinationLocationCode:
          flightSearchFormModelCopy.originDestinations[0].originLocationCode,
      };
      flightSearchFormModelCopy.originDestinations.push(item);
    }
  }

  emits("resetShow");
  if (props.jumboable) {
    // flightSearchFormModelCopy.originDestinations = null;
    router.push({
      name: props.jumpTo,
      query: flightSearchFormModelCopy,
    });
  } else {
    searchFlightByFormModel(flightSearchFormModelCopy);
  }
}

async function searchFlightByFormModel(formModel) {
  templateStore.pageLoader({ mode: "on" });
  //每次搜索之后存储表单
  sessionStorage.setItem("flightSearchFormModel", JSON.stringify(formModel));
  let format = formatOriginDestinations(formModel.originDestinations);
  let res = await flightStore.searchFlight(formModel);
  // ("1");
  templateStore.pageLoader({ mode: "off" });
}
</script>

<template>
  <!--程型选择-->
  <div class="row">
    <div class="col-lg-12">
      <div class="flight_categories_search">
        <!--          决定是行程类型-->
        <ul class="nav nav-tabs">
          <li>
            <button
              class="nav-link"
              :class="{ active: tripType == 'oneway_flight' }"
              type="button"
              @click="changeTripType('oneway_flight')"
            >
              {{ $t("one-way") }}
            </button>
          </li>
          <li style="margin-left: 25px">
            <button
              class="nav-link"
              :class="{ active: tripType == 'roundtrip' }"
              type="button"
              @click="changeTripType('roundtrip')"
            >
              {{ $t("round-trip") }}
            </button>
          </li>
          <!--          <li style="margin-left: 25px">-->
          <!--            <button-->
          <!--              class="nav-link"-->
          <!--              :class="{ active: tripType == 'multi_way' }"-->
          <!--              id="multi_city-tab"-->
          <!--              type="button"-->
          <!--              @click="changeTripType('multi_way')"-->
          <!--            >-->
          <!--              {{ $t("multi-city") }}-->
          <!--            </button>-->
          <!--          </li>-->
        </ul>
      </div>
    </div>
  </div>

  <div v-if="tripType == 'oneway_flight' && flightSearchFormModel">
    <!--        v-if 的方式实时渲染 -->
    <BaseFlightSearchFormItem
      :equipment-type="equipmentType"
      :trip-type="tripType"
      :is-origin-location-show="isOriginLocationShow"
      :is-destination-location-show="isDestinationLocationShow"
      :flight-search-form-model="flightSearchFormModel"
      @submitFormModel="submitFlightSearchForm"
    >
    </BaseFlightSearchFormItem>
  </div>
  <!--双-->
  <div v-else-if="tripType == 'roundtrip' && flightSearchFormModel">
    <BaseFlightSearchFormItem
      :equipment-type="equipmentType"
      :trip-type="tripType"
      :is-origin-location-show="isOriginLocationShow"
      :is-destination-location-show="isDestinationLocationShow"
      :flight-search-form-model="flightSearchFormModel"
      @submitFormModel="submitFlightSearchForm"
    ></BaseFlightSearchFormItem>
  </div>
  <!--多-->
  <div v-else-if="tripType == 'multi_way' && flightSearchFormModel">
    <BaseFlightSearchFormItem
      :trip-type="tripType"
      :is-origin-location-show="isOriginLocationShow"
      :is-destination-location-show="isDestinationLocationShow"
      :flight-search-form-model="flightSearchFormModel"
      @submitFormModel="submitFlightSearchForm"
      :equipment-type="equipmentType"
    ></BaseFlightSearchFormItem>
  </div>
</template>

<style scoped></style>

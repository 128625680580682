<script setup>
import CommonAuthorTwo from "@/views/security/item/CommonAuthorTwo.vue";
import Cta from "@/views/security/item/Cta.vue";
import { useCmsContentStore } from "@/stores/cms";
import { reactive, ref, watchEffect } from "vue";

const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContent = cmsContentStore.productPageBackgroundImage;
  imageUrl.value =
    cmsContentStore.productPageBackgroundImage &&
    cmsContentStore.productPageBackgroundImage.forgetPasswordBackgroundImage
      ? cmsContentStore.productPageBackgroundImage.forgetPasswordBackgroundImage
          .url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});
</script>
<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("reset-password") }}</h2>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <CommonAuthorTwo />

  <!-- Cta Area -->
  <Cta />
</template>

/*
 * 房型库存保持
 * @param hotelResRQ object 必填，通过 @tools/hotelTools#getHoldRQ 方法获取
 * */
import axios from "@/utils/axiosUtils";

function hold(hotelResRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-res-confirm";
  return axios.post(url, hotelResRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/*
 * 房间订单初始化
 * @param preBookRQ object 必填，通过 @tools/hotelTools#getPreBookRQ 方法获取
 */
function preBook(preBookRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-res-prebook";
  return axios.post(url, preBookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/*
 * 订单确认
 * @param bookRQ object 必填，通过 @tools/hotelTools#getBookRQ 方法获取
 */
function book(bookRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-res";
  return axios.post(url, bookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  hold,
  preBook,
  book,
};

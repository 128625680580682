import { defineStore } from "pinia";
import carRentalReserveTools from "@/tools/v2/carrental/reserve/carRentalReserveTools";
import carRentalReserveApi from "@/apis/v2/carrental/carRentalReserveApi";

export const useCarRentalReserveStore = defineStore("carRentalReserve", {
  state: () => ({
    tripCarRentalItem: null,
    carRentalBookRQ: null,
  }),
  actions: {
    carRentalInitiate(carRentalBookFormModel) {
      const _this = this;
      const initiateRQ = carRentalReserveTools.buildCarRentalInitiateRQ(
        carRentalBookFormModel
      );
      return carRentalReserveApi
        .carRentalReserve(initiateRQ)
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.vehResRSCore &&
            res.data.vehResRSCore.vehReservation
          ) {
            _this.carRentalBookRQ = carRentalReserveTools.buildCarRentalBookRQ(
              res.data.vehResRSCore.vehReservation.vehSegmentCore.confIDs
            );
            return res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
});

import { TripAirItemAncillaryGroupVO } from "@/viewobject/trip/flight/ancillary/tripAirItemAncillaryGroupVO";

export class TripAirItemAncillaryFareVO {
  /**
   *
   * @param status string，状态，PRICED-已验价未付款，PURCHASED-已付款
   * @param type string，附加服务类型，SERVICE-普通服务，SEAT-座位选择服务
   * @param code string，附加服务代码，为IATA Taxonomy代码
   * @param name string，名字
   * @param currencyCode string，货币单位
   * @param amount string，总金额
   * @param ancillaryId string，服务Id
   * @param flightSegmentRPH string，服务所属航段唯一标识
   * @param travelerRPH string，此服务对应的乘客唯一标识
   * @param quantity number，此服务数量
   * @param seatColumnNumber string，若type不为SEAT时，该值为null
   * @param seatRowNumber number，若type不为SEAT时，该值为null
   * @param bookingInstruction string，服务要求
   * @param imageUrl string,图片路径
   * @param descriptions Array，DescriptionItemVO对象数组
   * @param groups Array，TripAirItemAncillaryGroupVO对象数组
   */
  constructor(
    status,
    type,
    code,
    name,
    currencyCode,
    amount,
    ancillaryId,
    flightSegmentRPH,
    travelerRPH,
    quantity,
    seatColumnNumber,
    seatRowNumber,
    bookingInstruction,
    imageUrl,
    descriptions,
    groups
  ) {
    this.status = status;
    this.type = type;
    this.code = code;
    this.name = name;
    this.currencyCode = currencyCode;
    this.amount = amount;
    this.ancillaryId = ancillaryId;
    this.flightSegmentRPH = flightSegmentRPH;
    this.travelerRPH = travelerRPH;
    this.quantity = quantity;
    this.seatColumnNumber = seatColumnNumber;
    this.seatRowNumber = seatRowNumber;
    this.bookingInstruction = bookingInstruction;
    this.imageUrl = imageUrl;
    this.descriptions = descriptions;
    this.groups = groups;
  }

  addNewDescription(name, description) {
    if (this.descriptions == null) this.descriptions = [];
    this.descriptions.push(new DescriptionItemVO(name, description));
  }

  addNewGroup(groupCode, groupName, subGroupCode, subGroupName) {
    if (this.groups == null) this.groups = [];

    const tripAirItemAncillaryGroupVO = new TripAirItemAncillaryGroupVO(
      groupCode,
      groupName,
      subGroupCode,
      subGroupName
    );
    this.groups.push(tripAirItemAncillaryGroupVO);
    return tripAirItemAncillaryGroupVO;
  }
}

export class DescriptionItemVO {
  constructor(name, text) {
    this.name = name;
    this.text = text;
  }
}

import dateUtils from "@/utils/dateUtils";
import { TripTourActivityItemVO } from "@/viewobject/trip/tourActivity/tripTourActivityItemVO";

function buildTripTourActivityItem(
  tourActivityReservation,
  tripItemStatus,
  receiptStatus,
  createdDateTime
) {
  const basicInfo = tourActivityReservation.basicInfo;
  let name = basicInfo.name;
  let groupName = basicInfo.shortName;
  let tourActivityID = basicInfo.tourActivityID;

  let confirmations = tourActivityReservation.confirmation;

  const description = tourActivityReservation.description;

  let shortDescription = description.shortDescription;
  const multimedia = description.multimedia;
  const textItems =
    multimedia.multimediaDescriptionLists &&
    multimedia.multimediaDescriptionLists.length > 0
      ? multimedia.multimediaDescriptionLists[0].textItems
      : [];

  const location = tourActivityReservation.location;
  let addressInfo = null;
  let addressLines = null;
  if (location) {
    addressInfo = location.address;
    addressLines =
      addressInfo.addressLines && addressInfo.addressLines.length > 0
        ? addressInfo.addressLines[0]
        : "";
  }

  const participantInfos = tourActivityReservation.participantInfos;

  let participantInfo = {};

  let contactPerson = {};
  let passengers = [];
  if (participantInfos && participantInfos.length > 0) {
    participantInfo = participantInfos.find(
      (item) => item.category.qualifierInfo.extension === "CONTACT_PERSON"
    );

    let group = participantInfo.group;

    let contact = group.contact;

    let nameTitle = contact.personName.nameTitle;
    let surname = contact.personName.surname;
    let givenName = contact.personName.givenName;
    let phoneNumber = contact.telephoneList[0].phoneNumber;
    let emailAddress = contact.emailList[0].stringValue;

    contactPerson = {
      nameTitle: nameTitle,
      surname: surname,
      givenName: givenName,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
    };
    passengers.push(contactPerson);
  }

  let cancelDete = dateUtils.formatDate(
    new Date(tourActivityReservation.policy.cancel.cancelDeadline.split("T")[0])
  );

  const schedule = tourActivityReservation.schedule;
  let scheduleDate = null;
  if (schedule) {
    scheduleDate = tourActivityReservation.schedule.start;
  }

  const supplierOperators = tourActivityReservation.supplierOperators;
  let supplierInfo = null;
  let operaterInfo = null;

  if (supplierOperators && supplierOperators.length > 0) {
    supplierOperators.forEach((item) => {
      if (item.name.codeContext === "OPERATER") {
        //运营商
        supplierInfo = item.name.companyShortName + " (" + item.name.code + ")";
      } else if (item.codeContext === "SUPPLIER") {
        operaterInfo = item.name.companyShortName + " (" + item.name.code + ")";
      }
    });
  }

  const pricing = tourActivityReservation.pricing;
  let participantCategories = pricing.participantCategories;
  let groupInfo =
    pricing.groups && pricing.groups.length > 0 ? pricing.groups[0] : {};

  let totalAmount = 0;
  let currencyCode = null;
  if (pricing) {
    currencyCode = pricing.summary.currencyCode;
  }
  if (groupInfo) {
    totalAmount = groupInfo.price.amount;
  }

  const tripTourActivityItemVO = new TripTourActivityItemVO(
    name,
    groupName,
    tourActivityID,
    confirmations,
    shortDescription,
    addressInfo,
    addressLines,
    participantInfo,
    contactPerson,
    cancelDete,
    scheduleDate,
    supplierInfo,
    operaterInfo,
    participantCategories,
    groupInfo,
    tripItemStatus === "BOOKING_CANCELLED" ? "CANCELLED" : tripItemStatus,
    receiptStatus,
    createdDateTime,
    null,
    passengers,
    totalAmount,
    currencyCode
  );

  if (textItems && textItems.length > 0) {
    textItems.forEach((textItem) => {
      const title = textItem.title;
      const category = textItem.category;
      const descriptionList = textItem.descriptionList;
      let description = null;
      if (descriptionList && descriptionList.length > 0) {
        description = descriptionList[0].stringValue;
      }
      tripTourActivityItemVO.addNewNecessaryInfo(title, description);
    });
  }

  return tripTourActivityItemVO;
}

export default {
  buildTripTourActivityItem,
};

import axios from "@/utils/axiosUtils";

function dynamicPkgAvail(searchRQ) {
  let url = "/api/v3/sales/shopping/dynamicpkg/dynamic-pkg-avail";
  return axios.post(url, searchRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  dynamicPkgAvail,
};

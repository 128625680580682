import axios from "../utils/axiosUtils";
function avail(pkgAvailRQ) {
  let url = "/api/v3/sales/shopping/dynamicpkg/dynamic-pkg-avail";
  return axios.post(url, pkgAvailRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function verifyPrice(pkgPriceRQ) {
  let url = "/api/v3/sales/shopping/dynamicpkg/dynamic-pkg-price";
  // console.info(pkgPriceRQ);
  return axios.post(url, pkgPriceRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
function prebook(pkgPrebookRQ) {
  let url = "/api/v3/sales/shopping/dynamicpkg/dynamic-pkg-prebook";
  return axios.post(url, pkgPrebookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function book(pkgBookRQ) {
  let url = "/api/v3/sales/shopping/dynamicpkg/dynamic-pkg-book";
  return axios.post(url, pkgBookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  avail,
  verifyPrice,
  prebook,
  book,
};

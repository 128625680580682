import ActivityHomeView from "@/views/v2/sales/activity/ActivityHomeView.vue";
import ActivityListView from "@/views/v2/sales/activity/ActivityListView.vue";
import ActivityPassengersView from "@/views/v2/sales/activity/ActivityPassengersView.vue";
import ActivityDetailsView from "@/views/v2/sales/activity/ActivityDetailsView.vue";
import ActivityCheckOutView from "@/views/v2/sales/activity/ActivityCheckOutView.vue";

export default [
  {
    path: "/sales/v2/activity",
    redirect: "/sales/v2/activity/activity-home",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "activity-home",
        name: "sales-activity-home-v2",
        meta: { requiresAuth: false, footerShow: true },
        component: ActivityHomeView,
      },
      {
        path: "activity-list",
        name: "sales-activity-list-v2",
        meta: { requiresAuth: false },
        component: ActivityListView,
      },
      {
        path: "activity-details",
        name: "sales-activity-details-v2",
        meta: { requiresAuth: false },
        component: ActivityDetailsView,
      },
      {
        path: "activity-passengers",
        name: "sales-activity-passengers-v2",
        meta: { requiresAuth: false },
        component: ActivityPassengersView,
      },
      {
        path: "activity-check-out",
        name: "sales-activity-check-out-v2",
        meta: { requiresAuth: false },
        component: ActivityCheckOutView,
      },
    ],
  },
];

<template>

    <!-- Common Banner Area -->
    <Banner />

    <!-- Contact Area -->
    <ContactArea />

    <!-- Cta Area -->
    <Cta />

</template>
<script>

import Banner from '@/components/templates/contact/Banner.vue'
import ContactArea from '@/components/templates/contact/ContactArea.vue'
import Cta from '@/components/templates/home/Cta.vue'
export default {
    name: "ContactView",
    components: {
        Banner, ContactArea, Cta
    }
};
</script>
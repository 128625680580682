export class FlightModifyFormModel {
  /**
   *
   * @param modificationType string，必需，
   * @param tripId string，必需，
   * @param tripAirItemId string，必需，
   * @param airItineraryRPH string，必需
   * @param recordLocator string，必需
   * @param idContext string，必需
   * @param servicesSelected Array，TripAirItemAncillaryFareVO对象数组
   * @param servicesRemoval Array，TripAirItemAncillaryFareVO对象数组
   * @param paymentDetails TripAirItemPaymentDetailVO对象
   */
  constructor(
    modificationType,
    tripId,
    tripAirItemId,
    airItineraryRPH,
    recordLocator,
    idContext,
    servicesSelected,
    paymentDetails,
    servicesRemoval
  ) {
    this.modificationType = modificationType;
    this.tripId = tripId;
    this.tripAirItemId = tripAirItemId;
    this.airItineraryRPH = airItineraryRPH;
    this.recordLocator = recordLocator;
    this.idContext = idContext;
    this.servicesSelected = servicesSelected;
    this.paymentDetails = paymentDetails;
    this.servicesRemoval = servicesRemoval;
  }

  //计算附加服务的价格
  calculateServicesTotalPrice() {
    if (!this.servicesSelected || this.servicesSelected.length === 0)
      return null;

    let totalAmount = 0;
    let currency = null;

    for (const serviceSelected of this.servicesSelected) {
      let quantity = serviceSelected.quantity;
      const currencyCode = serviceSelected.currencyCode;
      const amount = serviceSelected.amount;

      if (!currency && currencyCode) currency = currencyCode;

      if (!quantity) quantity = 1;
      totalAmount += amount * quantity;
    }

    return {
      amount: totalAmount,
      currencyCode: currency,
    };
  }
}

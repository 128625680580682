import tripAirItemTools from "@/tools/v2/trip/tripAirItemTools";
import { TripItemVO } from "@/viewobject/trip/tripItemVO";
import tripHotelItemTools from "@/tools/v2/trip/tripHotelItemTools";
import railTools from "@/tools/railTools";
import carTransferTools from "@/tools/carTransferTools";
import tripTourActivityItemTools from "@/tools/v2/trip/tripTourActivityItemTools";
import tripCarRentalItemTools from "@/tools/v2/trip/tripCarRentalItemTools";

function buildTripsReadRQ(tripsReadFormModel) {
  let type = tripsReadFormModel.type;
  let id = tripsReadFormModel.id;
  let limit = tripsReadFormModel.limit;
  let offset = tripsReadFormModel.offset;

  let readRequests = null;
  let status = tripsReadFormModel.status;
  let start = tripsReadFormModel.start;
  let end = tripsReadFormModel.end;
  if ((status != null && status != "") || start != null || end != null) {
    readRequests = {
      globalReservationReadRequests: [],
    };
    let globalReservationReadRequest = {};
    if (start) {
      globalReservationReadRequest = {
        // bookingTypeId: "Hotel",
        status: status != null && status !== "" ? status : null,
        start,
        end,
        dateType: "BookingDate",
      };
    } else {
      globalReservationReadRequest = {
        // bookingTypeId: "Hotel",
        status: status != null && status !== "" ? status : null,
      };
    }

    readRequests.globalReservationReadRequests.push(
      globalReservationReadRequest
    );
  }
  let uniqueID = null;
  if (id) {
    uniqueID = {
      type: type,
      id: id,
      id_context: "ERP",
    };
  }

  return {
    uniqueID: uniqueID,
    offset: offset,
    limit: limit,
    readRequests: readRequests,
  };
}

function buildResRetrieveRQ(id, type, idContext) {
  return {
    uniqueID: {
      type: type,
      id: id,
      id_context: idContext,
    },
  };
}

/**
 *
 * @param status string，取消请求类型，如QUOTE-请求取消罚款信息，COMMIT-提交取消请求
 * @param tripId
 * @param tripItemId
 * @param recordLocator
 * @param idContext
 * @returns {{cancelType: string, uniqueIDs: [{id_Context, id, type: string},{id_Context: string, id, type: string}]}}
 */
function buildTripAirItemCancelRQ(
  status,
  tripId,
  tripItemId,
  recordLocator,
  idContext
) {
  return {
    uniqueIDs: [
      {
        type: "BOOKING_ID",
        id: tripId,
        id_Context: "ERP",
      },
      {
        type: "BOOKING_ITEM_ID",
        id: tripItemId,
        id_Context: "ERP",
      },
      {
        type: "RECORD_LOCATOR",
        id: recordLocator,
        id_Context: idContext,
      },
    ],
    cancelType: status,
  };
}

function buildTicketCancelRQ(ticketNumber, recordLocator, idContext) {
  return {
    uniqueIDs: [
      {
        type: "TICKET",
        id: ticketNumber,
        instance: recordLocator,
        id_Context: idContext,
      },
    ],
    cancelType: "CANCEL",
  };
}

function buildTripCreateRQ(orderCreateFormModel) {
  return {
    partyIdFrom: orderCreateFormModel.partyIdFrom,
    payerId: orderCreateFormModel.payerId,
    requestorId: orderCreateFormModel.requestorId,
    statusId: orderCreateFormModel.statusId,
    orgUnitPath: orderCreateFormModel.orgUnitPath,
    bookingTypeId: orderCreateFormModel.bookingTypeId,
    dateCreated: orderCreateFormModel.dateCreated,
    recordLocators: orderCreateFormModel.recordLocators,
  };
}

function buildTripImportPNRRQ(orderCreateFormModel, bookingId) {
  return {
    bookingId: bookingId,
    infoSource: orderCreateFormModel.infoSource,
    ID: orderCreateFormModel.recordLocators,
  };
}

function buildTripItems(reservations) {
  let tripItemVOs = null;
  if (!reservations || reservations.length === 0) return null;
  tripItemVOs = [];
  for (const reservation of reservations) {
    const tripItemVO = buildTripItem(reservation);
    tripItemVOs.push(tripItemVO);
  }
  return tripItemVOs;
}

function buildTripItem(reservation) {
  //common info
  let bookingId = null;
  let invoiceId = null;
  let bookingIdInfo = null;

  let requestor = null;
  let tripTotalAmount = 0;
  let createdDateTime = null;
  const receiptStatus = reservation.receiptStatusId;
  const bookingStatus = reservation.statusId;
  let globalReservations = reservation.globalReservations;
  if (globalReservations && globalReservations.length > 0) {
    globalReservations.forEach((globalReservation) => {
      let uniqueID = globalReservation.uniqueID;
      if (uniqueID.type === "BOOKING_ID" && uniqueID.id_context === "ERP") {
        bookingId = uniqueID.id;
        bookingIdInfo = uniqueID;
      } else if (uniqueID.type === "INVOICE_ID") {
        invoiceId = uniqueID.id;
      }
    });
  }
  reservation.attributes.forEach((attribute) => {
    if ("requestor_name" === attribute.name) {
      requestor = attribute.value;
    } else if ("booking_sellingTotal" === attribute.name) {
      tripTotalAmount = parseInt(attribute.value);
    } else if ("booking_dateCreated" === attribute.name) {
      createdDateTime = attribute.value;
    }
  });
  const tripItemVO = new TripItemVO(
    bookingId,
    invoiceId,
    bookingStatus,
    null,
    tripTotalAmount,
    createdDateTime,
    requestor
  );

  const airReservations = reservation.airReservations;
  const hotelReservations = reservation.hotelReservations;
  const cruiseReservations = reservation.cruiseReservations;
  const vehicleReservations = reservation.vehicleReservations;
  const railReservations = reservation.railReservations;
  const tourActivityReservations = reservation.tourActivityReservations;
  const travelDocReservations = reservation.travelDocReservations;
  const groundReservations = reservation.groundReservations;

  //air
  if (airReservations != null && airReservations.length > 0) {
    airReservations.forEach((airReservation) => {
      const tripAirItemVO = buildTripAirItem(airReservation);
      tripItemVO.addNewAirItem(tripAirItemVO);
      if (!tripItemVO.currencyCode)
        tripItemVO.currencyCode = tripAirItemVO.tripAirItemFare.currencyCode;
      if (tripAirItemVO.passengers && tripAirItemVO.passengers.length > 0) {
        const passenger = tripAirItemVO.passengers[0];
        tripItemVO.passengerName =
          passenger.surname + "/" + passenger.givenName;
      }
    });
  }

  //hotel
  if (hotelReservations != null && hotelReservations.length > 0) {
    for (const hotelReservation of hotelReservations) {
      const tripHotelItemVO = buildTripHotelItem(hotelReservation, invoiceId);
      tripItemVO.addNewTripHotelItem(tripHotelItemVO);

      if (!tripItemVO.currencyCode)
        tripItemVO.currencyCode = tripHotelItemVO.currencyCode;

      tripItemVO.totalAmount = tripHotelItemVO.totalPrice;

      if (tripHotelItemVO.passengers && tripHotelItemVO.passengers.length > 0) {
        const passengers = tripHotelItemVO.passengers;
        if (passengers && passengers.length > 0) {
          const passenger = passengers.find(
            (item) =>
              (item.ageQualifyingCode === "10" &&
                item.surname.indexOf("TBA") < 0) ||
              item.mainIndicator
          );
          if (passenger)
            tripItemVO.passengerName =
              passenger.surname + "/" + passenger.givenName;
        }
      }
    }
  }
  //carRental
  if (vehicleReservations != null && vehicleReservations.length > 0) {
    for (const vehicleReservation of vehicleReservations) {
      const tripCarRentalItemVO = buildTripCarRentalItem(
        vehicleReservation,
        bookingStatus,
        receiptStatus,
        createdDateTime
      );
      tripItemVO.addNewTripCarRentalItem(tripCarRentalItemVO);
      if (tripCarRentalItemVO) {
        //租车价格信息
        if (!tripItemVO.currencyCode)
          tripItemVO.currencyCode = tripCarRentalItemVO.currencyCode;
        tripItemVO.totalAmount = tripCarRentalItemVO.totalAmount;

        //租车主要人信息
        const driver = tripCarRentalItemVO.driver;
        let nameTitle = null;
        if (driver) {
          nameTitle = driver.nameTitle;
          if (nameTitle) {
            tripItemVO.passengerName =
              nameTitle + "/" + driver.surname + "/" + driver.givenName;
          } else {
            tripItemVO.passengerName = driver.surname + "/" + driver.givenName;
          }
        }
      }
    }
  }
  //cruise
  if (cruiseReservations != null && cruiseReservations.length > 0) {
    for (const cruiseReservation of cruiseReservations) {
      const tripCruiseItemVO = buildTripCruiseItem(cruiseReservation);
      let totalPrice = 0;
      if (tripCruiseItemVO) {
        const guestPrices = tripCruiseItemVO.bookingPayment
          ? tripCruiseItemVO.bookingPayment.guestPrices
          : [];
        if (guestPrices && guestPrices.length > 0) {
          guestPrices.forEach((guestPrice) => {
            guestPrice.priceInfos.forEach((priceInfo) => {
              if (priceInfo.priceTypeCode === "8") {
                totalPrice += priceInfo.amount;
              }
            });
          });
        }

        //游轮价格信息
        if (!tripItemVO.currencyCode)
          tripItemVO.currencyCode =
            tripCruiseItemVO.sailingInfo.currency.currencyCode;
        tripItemVO.totalAmount = totalPrice;

        //游轮暂无乘客信息
      }
    }
  }
  if (railReservations != null && railReservations.length > 0) {
    for (const railReservation of railReservations) {
      const tripRailItemVO = buildTripRailItem(railReservation);
      if (tripRailItemVO) {
        // 火车价格信息
        let railBookPriceInfo =
          railTools.getInitiateBookedPrice(railReservation);
        if (!tripItemVO.currencyCode)
          tripItemVO.currencyCode = railBookPriceInfo.sellingCurrencyCode;
        tripItemVO.totalAmount = railBookPriceInfo.sellingTotalAmount;

        //火车主要人信息
        const passengerInfos = railReservation.passengerInfos;
        if (passengerInfos && passengerInfos.length > 0) {
          passengerInfos.forEach((item) => {
            const passengerDetails = item.passengerDetails[0];
            const identification = passengerDetails.identification;
            let nameTitle = identification.nameTitle;
            let lastName = identification.surname;
            let firstName = identification.givenName;
            let email =
              passengerDetails.emails && passengerDetails.emails.length > 0
                ? passengerDetails.emails[0].stringValue
                : null;
            if (email) {
              tripItemVO.passengerName =
                nameTitle + "/" + lastName + "/" + firstName;
            }
          });
        }
      }
    }
  }
  if (tourActivityReservations != null && tourActivityReservations.length > 0) {
    for (const tourActivityReservation of tourActivityReservations) {
      const tripActivityItemVO = buildTripActivityItem(
        tourActivityReservation,
        bookingStatus,
        receiptStatus,
        createdDateTime
      );
      tripItemVO.addNewTripActivityItem(tripActivityItemVO);
      if (tripActivityItemVO) {
        if (!tripItemVO.currencyCode)
          tripItemVO.currencyCode = tripActivityItemVO.currencyCode;
        if (!tripItemVO.totalAmount)
          tripItemVO.totalAmount = tripActivityItemVO.totalAmount;

        //活动主要人信息
        const contactPerson = tripActivityItemVO.contactPerson;

        if (contactPerson) {
          tripItemVO.passengerName = contactPerson.nameTitle
            ? contactPerson.nameTitle +
              "/" +
              contactPerson.surname +
              "/" +
              contactPerson.givenName
            : contactPerson.surname + "/" + contactPerson.givenName;
        }
      }
    }
  }
  if (travelDocReservations != null && travelDocReservations.length > 0) {
    for (const travelDocReservation of travelDocReservations) {
      const tripTravelDocumentItemVO =
        buildTripTravelDocumentItem(travelDocReservation);
      if (tripTravelDocumentItemVO) {
        //签证价格信息
        const documentType = tripTravelDocumentItemVO.documentType;
        const charges = tripTravelDocumentItemVO.cost.charges;
        let totalPrice = 0;
        if (charges && charges.length > 0) {
          charges.forEach((item) => {
            totalPrice += item.amount;
          });
        }
        if (!tripItemVO.currencyCode)
          tripItemVO.currencyCode = documentType.countryCode;
        tripItemVO.totalAmount = totalPrice;

        //活动主要人信息
        const travelers = travelDocReservation.travelers;

        if (travelers && travelers.length > 0) {
          const traveler = travelers[0];
          const personName = traveler.personName;
          tripItemVO.passengerName =
            personName.surname + "/" + personName.givenName;
        }
      }
    }
  }
  if (groundReservations != null && groundReservations.length > 0) {
    for (const groundReservation of groundReservations) {
      const tripGroundItemVO = buildTripGroundItem(groundReservation);
      if (tripGroundItemVO) {
        //车接价格信息
        const totalCharge = tripGroundItemVO.totalCharge;
        if (!tripItemVO.currencyCode)
          tripItemVO.currencyCode = totalCharge
            ? totalCharge.currencyCode
            : null;
        tripItemVO.totalAmount = totalCharge
          ? totalCharge.estimatedTotalAmount
          : null;

        //车接主要人信息
        const passenger = tripGroundItemVO.passenger;
        let carTransferItem = carTransferTools.getCarTransferBookingViewItem(
          passenger,
          tripGroundItemVO
        );
        const passengerInfo = carTransferItem.passenger;

        if (passengerInfo) {
          if (passengerInfo.nameTitle) {
            tripItemVO.passengerName =
              passengerInfo.nameTitle +
              "/" +
              passengerInfo.surname +
              "/" +
              passengerInfo.givenName;
          } else {
            tripItemVO.passengerName =
              passengerInfo.surname + "/" + passengerInfo.givenName;
          }
        }
      }
    }
  }
  return tripItemVO;
}

function buildTripAirItem(airReservation) {
  return tripAirItemTools.buildTripAirItem(airReservation);
}

function buildTripHotelItem(hotelReservation, invoiceId) {
  return tripHotelItemTools.buildTripHotelItem(hotelReservation, invoiceId);
}

function buildTripCruiseItem(cruiseReservation) {
  // return tripHotelItemTools.buildTripCruiseItem(cruiseReservation);
  return cruiseReservation;
}

function buildTripCarRentalItem(
  vehicleReservation,
  bookingStatus,
  receiptStatus,
  createdDateTime
) {
  return tripCarRentalItemTools.buildTripCarRentalItem(
    vehicleReservation,
    bookingStatus,
    receiptStatus,
    createdDateTime
  );
}

function buildTripRailItem(railReservation) {
  // return tripHotelItemTools.buildTripCruiseItem(railReservation);
  return railReservation;
}

function buildTripActivityItem(
  tourActivityReservation,
  tripItemStatus,
  receiptStatus,
  createdDateTime
) {
  return tripTourActivityItemTools.buildTripTourActivityItem(
    tourActivityReservation,
    tripItemStatus,
    receiptStatus,
    createdDateTime
  );
}

function buildTripTravelDocumentItem(travelDocReservation) {
  // return tripHotelItemTools.buildTripCruiseItem(travelDocReservation);
  return travelDocReservation;
}

function buildTripGroundItem(groundReservation) {
  // return tripHotelItemTools.buildTripCruiseItem(travelDocReservation);
  return groundReservation;
}

function formatApprovalReason(
  reason,
  approvalRequired,
  upgradeAllowed,
  outOfPolicy,
  tripItemType
) {
  let label = "";
  if (reason) {
    if (tripItemType === "AIR") {
      label = "The Air";
      let conditions = reason.split(";");
      let cabinItems = "";
      let airportCity = null;
      let airportCountry = null;
      let flightHours = null;

      if (conditions && conditions.length > 0) {
        conditions = conditions.filter((item) => item !== "");
        conditions.forEach((condition) => {
          if (condition.indexOf("air.cabin_class") !== -1) {
            if (condition.indexOf("Y,Economy,ECONOMY") !== -1) {
              cabinItems += "Economy";
            }
            if (condition.indexOf("W,Premium Economy,PremiumEconomy") !== -1) {
              cabinItems += " Premium Economy ";
            }
            if (condition.indexOf("Business") !== -1) {
              cabinItems += " Business ";
            }
            if (condition.indexOf("First") !== -1) {
              cabinItems += " First";
            }
            // cabinItems = cabinItems.replaceAll(" ", ",");
          }
          //指定地点
          if (condition.indexOf("air.to_airport") !== -1) {
            airportCity = condition.split(" ")[2];
          }
          //指定国家
          if (condition.indexOf("air.to_country") !== -1) {
            airportCountry = condition.split(" ")[2];
          }
          //指定时长
          if (condition.indexOf("air.flight_duration_in_mins") !== -1) {
            flightHours = condition.split(" ")[2];
          }
        });
      }
      if (cabinItems) label += "'s cabin in " + "[ " + cabinItems + " ]";
      if (airportCity)
        label += " and airport city in " + "[ " + airportCity + " ]";
      if (airportCountry)
        label += " or country in " + "[ " + airportCountry + " ]";
      if (flightHours)
        label += " and flying time more than " + flightHours / 60 + " hours";

      let action =
        approvalRequired && !upgradeAllowed
          ? " need  approval"
          : upgradeAllowed
          ? " need upgrade"
          : outOfPolicy
          ? " out of policy"
          : " in policy";

      if (action) label += action;
    } else if (tripItemType === "HOTEL") {
      label = "The Hotel";
      let conditions = reason.split(";");
      let rating = "";
      let price = "";

      if (conditions && conditions.length > 0) {
        conditions = conditions.filter((item) => item !== "");
        const ratingItem = conditions.find(
          (condition) => condition.indexOf("hotel.rating") !== -1
        );
        if (ratingItem) {
          rating = ratingItem.split(" ")[2];
          if (rating) label += "'s rating in " + "(" + rating + ")";
        }
        const priceItem = conditions.find(
          (condition) => condition.indexOf("hotel.unit_price") !== -1
        );
        if (priceItem) {
          price = priceItem.split(" ")[2];
          if (price) label += " and price not exceeding " + price;
        }
      }

      let action = approvalRequired
        ? " need  approval"
        : outOfPolicy
        ? " out of policy"
        : " in policy";

      if (action) label += action;
    }
  }
  return label;
}

export default {
  buildTripsReadRQ,
  buildResRetrieveRQ,
  buildTripAirItemCancelRQ,
  buildTripItems,
  buildTripItem,
  buildTripActivityItem,
  buildTripCarRentalItem,
  buildTicketCancelRQ,
  formatApprovalReason,
  buildTripCreateRQ,
  buildTripImportPNRRQ,
};

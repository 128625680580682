export class TripAirItemFareVO {
  /**
   *
   * @param repriceRequired boolean，出票前是否需要Reprice
   * @param priceExpirationDateTime string，该价格过期日期时间
   * @param totalAmount number，行程总价
   * @param totalDiscount number
   * @param currencyCode string，货币单位
   * @param passengerFares Array，TripAirItemPassengerFareVO数组
   * @param ancillaryFares Array，TripAirItemAncillaryFareVO数组
   * @param promotionRemarks
   * @param penaltyOptions
   */
  constructor(
    repriceRequired,
    priceExpirationDateTime,
    totalAmount,
    totalDiscount,
    currencyCode,
    passengerFares,
    ancillaryFares,
    promotionRemarks,
    penaltyOptions
  ) {
    this.repriceRequired = repriceRequired;
    this.priceExpirationDateTime = priceExpirationDateTime;
    this.totalAmount = totalAmount;
    this.totalDiscount = totalDiscount;
    this.currencyCode = currencyCode;
    this.passengerFares = passengerFares;
    this.ancillaryFares = ancillaryFares;
    this.promotionRemarks = promotionRemarks;
    this.penaltyOptions = penaltyOptions;
  }

  addPassengerFare(tripAirItemPassengerFareVO) {
    if (this.passengerFares == null) this.passengerFares = [];
    this.passengerFares.push(tripAirItemPassengerFareVO);
  }

  addAncillaryFare(tripAirItemAncillaryFareVO) {
    if (this.ancillaryFares == null) this.ancillaryFares = [];
    this.ancillaryFares.push(tripAirItemAncillaryFareVO);
  }

  addAncillaryFares(tripAirItemAncillaryFareVOs) {
    if (this.ancillaryFares == null) this.ancillaryFares = [];
    this.ancillaryFares.push(...tripAirItemAncillaryFareVOs);
  }

  addPenaltyOption(penaltyOption) {
    if (this.penaltyOptions == null) this.penaltyOptions = [];
    this.penaltyOptions.push(penaltyOption);
  }
}

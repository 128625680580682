import {HotelPolicyContentVO} from "@/viewobject/sales/hotel/availability/hotelPolicyContentVO";


export class HotelPolicyVO {
    /**
     *
     * @param code string，策略代码，例如CHECK_IN，FEES，POLICY
     * @param name string, 策略名
     * @param contents Array，HotelPolicyContentVO数组
     */
    constructor(code, name, contents) {
        this.code = code;
        this.name = name;
        this.contents = contents;
    }

    addNewPolicyContent(name, text){
        let hotelPolicyContentVO = new HotelPolicyContentVO(name, text);
        if (this.contents==null)this.contents = [];
        this.contents.push(hotelPolicyContentVO);
    }
}
import axios from "@/utils/axiosUtils";

function verifyPrice(verifyPriceRQ) {
  let url = "/api/v3/sales/shopping/dynamicpkg/dynamic-pkg-price";

  return axios.post(url, verifyPriceRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  verifyPrice,
};

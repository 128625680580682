<script setup>
import Slider from "@vueform/slider";
import { computed, reactive, watch } from "vue";

const props = defineProps({
  filterFlightOptions: {},
  filterOptionProps: {},
  fromAndTo: {},
});

const emits = defineEmits(["filter"]);
const departureTimeOption = [
  {
    value: "12:00+am-4:59+am",
    label: "Early Morning (12:00am - 4:59am)",
  },
  {
    value: "5:00+am-11:59+am",
    label: "Morning (5:00am - 11:59am)",
  },
  {
    value: "12:00+pm-5:59+pm",
    label: "Afternoon (12:00pm - 5:59pm)",
  },
  {
    value: "6:00+pm-11:59+pm",
    label: "Evening (6:00pm - 11:59pm)",
  },
];
const filterOptionDefault = computed(() => {
  let stopNumberArr = [];
  let cabinClassArr = [];
  let airlineArr = [];
  let filterFlightOptions = props.filterFlightOptions;
  if (filterFlightOptions) {
    let stopsFilterOptions = filterFlightOptions.stopsFilterOptions;
    let cabinClassFilterOptions = filterFlightOptions.cabinClassFliterOptions;
    let airlineFilterOptions = filterFlightOptions.airlineFilterOptions;
    if (stopsFilterOptions && stopsFilterOptions.length > 0) {
      stopsFilterOptions.forEach((item) => {
        stopNumberArr.push(item.stopNumber);
      });
    }
    if (cabinClassFilterOptions && cabinClassFilterOptions.length > 0) {
      cabinClassFilterOptions.forEach((item) => {
        cabinClassArr.push(item.cabinClass);
      });
    }
    if (airlineFilterOptions && airlineFilterOptions.length > 0) {
      airlineFilterOptions.forEach((item) => {
        airlineArr.push(item.airlineCode);
      });
    }
  }
  return {
    stopNumberArr: stopNumberArr,
    cabinClassArr: cabinClassArr,
    airlineArr: airlineArr,
  };
});
let minPrice = 0;
let maxPrice = 10000;

watch(
  () => props.filterOptionProps,
  (value, oldValue) => {
    filterOption = value;
  }
);
let filterOption = reactive(props.filterOptionProps);
let filterOptionOld = reactive(null);
filterOption.stopNumber = [];
filterOption.airlineCode = [];
filterOption.cabinClass = [];
filterOption.departureTimeQuantum = [];
filterOption.arrivalTimeQuantum = [];

function filter() {
  setTimeout(() => {
    filterOption = JSON.parse(JSON.stringify(filterOption));
    //stopNumber
    if (filterOption.stopNumber && filterOption.stopNumber.length > 0) {
      //新对象中“”的索引
      let indexOfAllTag = filterOption.stopNumber.indexOf("Any");

      //旧对象中“”的索引
      let indexOfAllOldTag = -1;
      if (filterOptionOld) {
        indexOfAllOldTag = filterOptionOld.stopNumber.indexOf("Any");
      }
      //新对象的数组长度是否大于旧对象的数组长度
      let isLonger =
        filterOptionOld == null ||
        filterOptionOld.stopNumber == null ||
        filterOption.stopNumber.length > filterOptionOld.stopNumber.length;
      //增加
      if (
        (indexOfAllTag > -1 ||
          (indexOfAllTag < 0 &&
          indexOfAllOldTag < 0 &&
          filterOption.stopNumber.length == filterOptionDefault.value &&
          filterOptionDefault.value.stopNumberArr &&
          filterOptionDefault.value.stopNumberArr.length > 0
            ? filterOptionDefault.value.stopNumberArr.length
            : 0)) &&
        isLonger
      ) {
        if (
          filterOptionDefault.value &&
          filterOptionDefault.value.stopNumberArr &&
          filterOptionDefault.value.stopNumberArr.length > 0
        ) {
          //如果全选了
          filterOptionDefault.value;
          filterOption.stopNumber = filterOptionDefault.value.stopNumberArr;
        }
      }
      //减少,并且新对象不包含“”，旧对象包好“”
      else if (indexOfAllTag < 0 && indexOfAllOldTag > -1 && !isLonger) {
        //如果全选了
        filterOption.stopNumber = [];
      }
      //减少
      else if (indexOfAllTag > -1 && !isLonger) {
        //如果全选了
        filterOption.stopNumber.splice(indexOfAllTag, 1);
      }
    }
    //cabinClass
    if (filterOption.cabinClass && filterOption.cabinClass.length > 0) {
      //新对象中“”的索引
      let indexOfAllTag = filterOption.cabinClass.indexOf("Any");

      //旧对象中“”的索引
      let indexOfAllOldTag = -1;
      if (filterOptionOld) {
        indexOfAllOldTag = filterOptionOld.cabinClass.indexOf("Any");
      }
      //新对象的数组长度是否大于旧对象的数组长度
      let isLonger =
        filterOptionOld == null ||
        filterOptionOld.cabinClass == null ||
        filterOption.cabinClass.length > filterOptionOld.cabinClass.length;
      //增加
      if (
        (indexOfAllTag > -1 ||
          (indexOfAllTag < 0 &&
          indexOfAllOldTag < 0 &&
          filterOption.cabinClass.length == filterOptionDefault.value &&
          filterOptionDefault.value.cabinClassArr &&
          filterOptionDefault.value.cabinClassArr.length > 0
            ? filterOptionDefault.value.cabinClassArr.length
            : 0)) &&
        isLonger
      ) {
        if (
          filterOptionDefault.value &&
          filterOptionDefault.value.cabinClassArr &&
          filterOptionDefault.value.cabinClassArr.length > 0
        ) {
          //如果全选了
          filterOption.cabinClass = filterOptionDefault.value.cabinClassArr;
        }
      }
      //减少,并且新对象不包含“”，旧对象包好“”
      else if (indexOfAllTag < 0 && indexOfAllOldTag > -1 && !isLonger) {
        //如果全选了
        filterOption.cabinClass = [];
      }
      //减少
      else if (indexOfAllTag > -1 && !isLonger) {
        //如果全选了
        filterOption.cabinClass.splice(indexOfAllTag, 1);
      }
    }

    //airlineCode
    if (filterOption.airlineCode && filterOption.airlineCode.length > 0) {
      //新对象中“”的索引
      let indexOfAllTag = filterOption.airlineCode.indexOf("anyAirline");

      //旧对象中“”的索引
      let indexOfAllOldTag = -1;
      if (filterOptionOld) {
        indexOfAllOldTag = filterOptionOld.airlineCode.indexOf("Any");
      }
      //新对象的数组长度是否大于旧对象的数组长度
      let isLonger =
        filterOptionOld == null ||
        filterOptionOld.airlineCode == null ||
        filterOption.airlineCode.length > filterOptionOld.airlineCode.length;
      //增加
      if (
        (indexOfAllTag > -1 ||
          (indexOfAllTag < 0 &&
          indexOfAllOldTag < 0 &&
          filterOption.airlineCode.length == filterOptionDefault.value &&
          filterOptionDefault.value.airlineArr &&
          filterOptionDefault.value.airlineArr.length > 0
            ? filterOptionDefault.value.airlineArr.length
            : 0)) &&
        isLonger
      ) {
        if (
          filterOptionDefault.value &&
          filterOptionDefault.value.airlineArr &&
          filterOptionDefault.value.airlineArr.length > 0
        ) {
          //如果全选了
          filterOptionDefault.value;
          filterOption.airlineCode = filterOptionDefault.value.airlineArr;
        }
      }
      //减少,并且新对象不包含“”，旧对象包好“”
      else if (indexOfAllTag < 0 && indexOfAllOldTag > -1 && !isLonger) {
        //如果全选了
        filterOption.airlineCode = [];
      }
      //减少
      else if (indexOfAllTag > -1 && !isLonger) {
        //如果全选了
        filterOption.airlineCode.splice(indexOfAllTag, 1);
      }
    }

    filterOptionOld = JSON.parse(JSON.stringify(filterOption));

    emits("filter", filterOption), 1000;
  });
}
//获得航空
function getAirlineName(airlineCode) {
  let airlineNameKey = "airlineName." + airlineCode;
  return airlineNameKey;
}
</script>

<template>
  <div class="left_side_search_boxed">
    <div class="left_side_search_heading">
      <h5>{{$t("filter-by-price")}}</h5>
    </div>
    <div class="filter-price">
      <Slider
        v-model="filterOption.priceRange"
        class="apply"
        :min="minPrice"
        :max="maxPrice"
        @change="filter"
      />
      <div
        class="d-flex justify-content-between align-items-center fs-sm text-muted py-2"
      >
        <span>0</span>
        <span>{{ Math.ceil(maxPrice / 5) }}</span>
        <span>{{ Math.ceil((maxPrice * 2) / 5) }}</span>
        <span>{{ Math.ceil((maxPrice * 3) / 5) }}</span>
        <span>{{ Math.ceil((maxPrice * 4) / 5) }}</span>
        <span>{{ Math.ceil(maxPrice) }}+</span>
      </div>
    </div>
  </div>
  <div class="left_side_search_boxed">
    <div class="left_side_search_heading">
      <h5>
        {{$t("departure-time")}} <span v-if="fromAndTo">in {{ fromAndTo.from }}</span>
      </h5>
    </div>
    <div class="filter_review">
      <form class="review_star">
        <div
          class="form-check"
          v-for="(time, index) in departureTimeOption"
          :key="index"
        >
          <input
            class="form-check-input"
            type="checkbox"
            :id="'example-radios-default' + index"
            :name="'example-radios-default' + index"
            :value="time.value"
            v-model="filterOption.departureTimeQuantum"
            @change="filter"
          />
          <label
            class="form-check-label"
            :for="'example-radios-default' + index"
            >{{ time.label }}
          </label>
        </div>
      </form>
    </div>
  </div>
  <div class="left_side_search_boxed">
    <div class="left_side_search_heading">
      <h5>
        {{$t("arrival-time")}} <span v-if="fromAndTo">in {{ fromAndTo.to }}</span>
      </h5>
    </div>
    <div class="filter_review">
      <form class="review_star">
        <div
          class="form-check"
          v-for="(time, index) in departureTimeOption"
          :key="index"
        >
          <input
            class="form-check-input"
            type="checkbox"
            :id="'example-radios-default' + index"
            :name="'example-radios-default' + index"
            :value="time.value"
            v-model="filterOption.arrivalTimeQuantum"
            @change="filter"
          />
          <label
            class="form-check-label"
            :for="'example-radios-default' + index"
            >{{ time.label }}
          </label>
        </div>
      </form>
    </div>
  </div>

  <div
    class="left_side_search_boxed"
    v-if="
      props.filterFlightOptions &&
      props.filterFlightOptions.stopsFilterOptions.length > 0
    "
  >
    <div class="left_side_search_heading">
      <h5>{{$t("number-of-stops")}}</h5>
    </div>
    <!-- 站点-->
    <div
      class="tour_search_type"
      v-for="(stops, stopIndex) in props.filterFlightOptions.stopsFilterOptions"
      :key="stopIndex"
    >
      <div class="form-check flight_check_flex">
        <input
          class="form-check-input"
          type="checkbox"
          :id="stops.stopNumber + stopIndex"
          :value="stops.stopNumber"
          v-model="filterOption.stopNumber"
          @change="filter"
        />
        <label class="form-check-label" :for="stops.stopNumber + stopIndex">
          <span v-if="stops.stopNumber != 'Any'"
            >{{ stops.stopNumber == 1 ? "Non" : stops.stopNumber - 1 }}
            <span>-stop</span></span
          >
          <span v-else>{{ stops.stopNumber }} </span>
        </label>
        <span>{{ stops.quality }}</span>
      </div>
    </div>
  </div>
  <div
    class="left_side_search_boxed"
    v-if="
      props.filterFlightOptions &&
      props.filterFlightOptions.cabinClassFliterOptions.length > 0
    "
  >
    <div class="left_side_search_heading">
      <h5>{{$t("flight-class")}}</h5>
    </div>
    <div
      class="tour_search_type"
      v-for="(cabinClass, cabinClassIndex) in props.filterFlightOptions
        .cabinClassFliterOptions"
      :key="cabinClassIndex"
    >
      <!--      舱型-->
      <div class="form-check flight_check_flex">
        <input
          class="form-check-input"
          type="checkbox"
          :id="cabinClass.cabinClass + cabinClassIndex"
          :value="cabinClass.cabinClass"
          v-model="filterOption.cabinClass"
          @change="filter"
        />
        <label
          class="form-check-label"
          :for="cabinClass.cabinClass + cabinClassIndex"
        >
          <span>
            {{ cabinClass.cabinClass }}
          </span>
        </label>
        <span>{{ cabinClass.quality }}</span>
      </div>
    </div>
  </div>

  <div
    class="left_side_search_boxed"
    v-if="
      props.filterFlightOptions &&
      props.filterFlightOptions.airlineFilterOptions.length > 0
    "
  >
    <div class="left_side_search_heading">
      <h5>{{$t("airlines")}}</h5>
    </div>
    <div
      class="tour_search_type"
      v-for="(airline, airlineIndex) in props.filterFlightOptions
        .airlineFilterOptions"
      :key="airlineIndex"
    >
      <!--      航空公司-->
      <div class="form-check flight_check_flex">
        <input
          class="form-check-input"
          type="checkbox"
          :id="airline.airlineCode + airlineIndex"
          @change="filter"
          :value="airline.airlineCode"
          v-model="filterOption.airlineCode"
        />
        <label
          class="form-check-label"
          :for="airline.airlineCode + airlineIndex"
        >
          <span v-if="airline.airlineCode != 'anyAirline'">
            {{ $t(getAirlineName(airline.airlineCode)) }}
          </span>
          <span v-else> {{$t("any")}} </span>
        </label>
        <span>{{ airline.quality }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

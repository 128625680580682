<script setup>
defineProps({
  passengers: {},
  equipmentType: {},
});

//获取联盟名
const frequentFlyerProgram = JSON.parse(
  sessionStorage.getItem("frequentFlyerProgram")
);

function getFrequentFlyerProgramName(Id) {
  let programName = "";
  frequentFlyerProgram.find((item) => {
    if (item.programID == Id) {
      programName = item.programName;
    }
  });
  return programName;
}
let warnExpireDate = JSON.parse(sessionStorage.getItem("warnExpireDate"));
</script>

<template>
  <div class="tour_booking_form_box" v-if="passengers">
    <h4>{{ $t("traveler-info") }}</h4>

    <div v-for="(passenger, passengerIndex) in passengers">
      <h4 style="margin-top: 20px">
        {{ $t("traveler") }} {{ passengerIndex + 1 }}
      </h4>
      <div class="row" style="margin-top: 15px">
        <div
          class="col-lg-5 col-xl-5 col-xxl-5 col-md-12 col-sm-12 col-12 flight_traver_info_item"
        >
          <span>{{ $t("traveler-name") }}:</span>
          <span style="opacity: 0.8; margin-left: 10px">
            {{ passenger.nameTitle }}. {{ passenger.surname }}/{{
              passenger.givenName
            }}
          </span>
        </div>
        <div
          class="col-lg-4 col-xl-4 col-xxl-4 col-md-12 col-sm-12 col-12"
          :class="{ flight_traver_info_item: equipmentType == 'iphone' }"
        >
          <span>{{ $t("birth-date") }}:</span>
          <span style="opacity: 0.8; margin-left: 10px">
            {{ passenger.birthDate }}
          </span>
        </div>
      </div>
      <div
        class="row"
        style="margin-top: 15px"
        v-if="passenger.phoneNumber && passenger.emailAddress"
      >
        <div
          class="col-lg-5 col-xl-5 col-xxl-5 col-md-12 col-sm-12 col-12 flight_traver_info_item"
          v-if="passenger.phoneNumber"
        >
          <span>{{ $t("phone-number") }}:</span>
          <span style="opacity: 0.8; margin-left: 10px">
            {{ passenger.phoneNumber }}
          </span>
        </div>
        <div
          class="col-lg-4 col-xl-4 col-xxl-4 col-md-12 col-sm-12 col-12"
          :class="{ flight_traver_info_item: equipmentType == 'iphone' }"
          v-if="passenger.emailAddress"
        >
          <span>{{ $t("email-address") }}:</span>
          <span style="opacity: 0.8; margin-left: 10px">
            {{ passenger.emailAddress }}
          </span>
        </div>
      </div>
      <div class="row" style="margin-top: 15px">
        <div
          class="col-lg-5 col-xl-5 col-xxl-5 col-md-12 col-sm-12 col-12 flight_traver_info_item"
        >
          <span>{{ $t("nationality") }}:</span>
          <span style="opacity: 0.8; margin-left: 10px">
            {{ passenger.docHolderNationalityName }}
          </span>
        </div>
        <div
          class="col-lg-4 col-xl-4 col-xxl-4 col-md-12 col-sm-12 col-12"
          :class="{ flight_traver_info_item: equipmentType == 'iphone' }"
        >
          <span>{{ $t("nationality") }}Passport Number:</span>
          <span style="opacity: 0.8; margin-left: 10px">
            {{ passenger.docID }}
          </span>
        </div>
        <div
          class="col-lg-3 col-xl-3 col-xxl-3 col-md-12 col-sm-12 col-12"
          :class="{ flight_traver_info_item: equipmentType == 'iphone' }"
        >
          <span>{{ $t("expiry-date") }}:</span>
          <span style="opacity: 0.8; margin-left: 10px">
            {{ passenger.docExpireDate }}
          </span>
        </div>
        <span
          style="margin-left: 48%; color: #ffc107"
          v-if="warnExpireDate"
          id="warnExpireDate"
          ><i class="bi bi-exclamation-circle"></i
          >{{ $t("passport-expiry-date") }}</span
        >
      </div>
      <div
        class="row"
        style="margin-top: 15px"
        v-if="passenger.ffpNumber && passenger.flyerAirline"
      >
        <div
          class="col-lg-5 col-xl-5 col-xxl-5 col-md-12 col-sm-12 col-12 flight_traver_info_item"
          v-if="passenger.flyerAirline"
        >
          <span>{{ $t("frequent-flyer-airline") }}:</span>
          <span style="opacity: 0.8; margin-left: 10px">
            {{ getFrequentFlyerProgramName(passenger.flyerAirline) }}
          </span>
        </div>
        <div
          class="col-lg-4 col-xl-4 col-xxl-4 col-md-12 col-sm-12 col-12"
          :class="{ flight_traver_info_item: equipmentType == 'iphone' }"
          v-if="passenger.ffpNumber"
        >
          <span>{{ $t("frequent-flyer-airline-number") }}:</span>
          <span style="opacity: 0.8; margin-left: 10px">
            {{ passenger.ffpNumber }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

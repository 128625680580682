export class DynamicPkgAvailFormModel {
  /**
   *
   * @param languageCode
   * @param locationType   //机场或者酒店
   * @param ttiCode
   * @param originLocationCode
   * @param originLocationName
   * @param destinationLocationCode
   * @param destinationLocationName
   * @param checkInDate
   * @param checkOutDate
   * @param roomPassengers
   * @param cabinTypeCode
   * @param referenceId
   * @param airItineraryRPH
   * @param fareSegmentBasisCodes
   * @param preferredCarType
   */
  constructor(
    languageCode,
    locationType,
    ttiCode,
    originLocationCode,
    originLocationName,
    destinationLocationCode,
    destinationLocationName,
    checkInDate,
    checkOutDate,
    roomPassengers,
    cabinTypeCode,
    referenceId,
    airItineraryRPH,
    fareSegmentBasisCodes,
    preferredCarType
  ) {
    this.languageCode = languageCode;
    this.locationType = locationType;
    this.ttiCode = ttiCode;
    this.originLocationCode = originLocationCode;
    this.originLocationName = originLocationName;
    this.destinationLocationCode = destinationLocationCode;
    this.destinationLocationName = destinationLocationName;
    this.checkInDate = checkInDate;
    this.checkOutDate = checkOutDate;
    this.roomPassengers = roomPassengers;
    this.cabinTypeCode = cabinTypeCode;
    this.referenceId = referenceId;
    this.airItineraryRPH = airItineraryRPH;
    this.fareSegmentBasisCodes = fareSegmentBasisCodes;
    this.preferredCarType = preferredCarType;
  }

  addNewRoomPassenger(adultNum, childNum, childAges) {
    // let roomPassenger = new HotelAvailRoomPassengerFormItem(1, 0, []);
    if (!this.roomPassengers) this.roomPassengers = [];

    if (this.roomPassengers.length === 0) {
      let roomPassenger = new DynamicPkgHotelAvailRoomPassengerFormItem(
        adultNum,
        childNum,
        childAges
      );

      this.roomPassengers.push(roomPassenger);
    } else {
      const roomPassenger = this.roomPassengers[0];

      this.roomPassengers.push(roomPassenger);
    }
  }

  removeRoomPassenger(index) {
    if (this.roomPassengers.length === 1) return;
    this.roomPassengers.splice(index, 1);
  }
}

class DynamicPkgHotelAvailRoomPassengerFormItem {
  /*
   * @param childAges number类型数组 [6,8,9]
   */
  constructor(adultNum, childNum, childAges) {
    this.adultNum = adultNum;
    this.childNum = childNum;
    this.childAges = childAges;
  }

  addChildNum() {
    this.childNum++;
    this.childAges.push({ age: null });
  }

  removeChildNum() {
    if (this.childNum === 0) return;
    this.childAges.splice(this.childAges.length - 1, 1);
    this.childNum--;
  }
}

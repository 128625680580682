export class FlightShoppingFilterFormModel {
  /**
   *
   * @param stops Array，number数组，0 for Direct
   * @param airlineCodes Array，string数组，航空公司代码数组，["SQ", "MH"]
   * @param travelAndBaggageCodes Array，string数组，服务数组，["SEAT_CHOICE", "NO_CANCEL_FEE"]
   * @param departureTimePeriods Array，string数组，时间段数组，["00:00-04:59"]
   * @param arrivalTimePeriods Array，string数组，时间段数组，["00:00-04:59"]
   * @param stopoverAirportCodes Array，string数组，停靠的机场代码数组，["HKG"]
   * @param supplierCodes Array，string数组，系统供应商代码，["SABRE", "TRAVELPORT", "FARELOGIX-EK"]
   * @param sourceCodes Array，string数组，价格系统源代码，["GDS"]，["NDC"]
   */
  constructor(
    stops,
    airlineCodes,
    travelAndBaggageCodes,
    departureTimePeriods,
    arrivalTimePeriods,
    stopoverAirportCodes,
    supplierCodes,
    sourceCodes
  ) {
    this.stops = stops;
    this.airlineCodes = airlineCodes;
    this.travelAndBaggageCodes = travelAndBaggageCodes;
    this.departureTimePeriods = departureTimePeriods;
    this.arrivalTimePeriods = arrivalTimePeriods;
    this.stopoverAirportCodes = stopoverAirportCodes;
    this.supplierCodes = supplierCodes;
    this.sourceCodes = sourceCodes;
  }
}

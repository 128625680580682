const seatOption = [
  {
    value: 2,
    label: "2",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 7,
    label: "7",
  },
];

const transmissionOption = [
  { value: "", label: "any" },
  { value: "Automatic", label: "automatic" },
  { value: "Manual", label: "manual" },
];

export default {
  seatOption,
  transmissionOption,
};

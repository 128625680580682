import FlightHomeView from "@/views/sales/flight/FlightHomeView.vue";
import FlightListView from "@/views/sales/flight/FlightListView.vue";
import FlightTicketTypeView from "@/views/sales/flight/FlightTicketTypeView.vue";
import FlightPassengersView from "@/views/sales/flight/FlightPassengersView.vue";
import FlightBookingPreviewView from "@/views/sales/flight/FlightBookingPreviewView.vue";
import FlightItineraryReviewView from "@/views/sales/flight/FlightItineraryReviewView.vue";
import FlightSeatMapView from "@/views/sales/flight/FlightSeatMapView.vue";
import FlightAncillaryServiceView from "@/views/sales/flight/FlightAncillaryServiceView.vue";
import FlightListViewForDemo from "@/views/sales/flight/FlightListViewForDemo.vue";

export default [
  {
    path: "/sales/flight",
    redirect: "/sales/flight/flight-home",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "flight-home",
        name: "sales-flight-home",
        meta: { requiresAuth: false },
        component: FlightHomeView,
      },
      {
        path: "flight-list",
        name: "sales-flight-list",
        component: FlightListView,
        meta: { requiresAuth: false },
      },
      {
        path: "flight-list-demo",
        name: "sales-flight-list-demo",
        component: FlightListViewForDemo,
        meta: { requiresAuth: false },
      },
      {
        path: "flight-ticket-type",
        name: "sales-flight-ticket-type",
        component: FlightTicketTypeView,
        meta: { requiresAuth: true },
      },
      {
        path: "flight-itinerary-review",
        name: "sales-flight-itinerary-review",
        component: FlightItineraryReviewView,
        meta: { requiresAuth: true },
      },
      {
        path: "flight-passengers",
        name: "sales-flight-passengers",
        component: FlightPassengersView,
        meta: { requiresAuth: true },
      },
      {
        path: "flight-seat-map",
        name: "sales-flight-seat-map",
        component: FlightSeatMapView,
        meta: { requiresAuth: true },
      },
      {
        path: "flight-ancillary-service",
        name: "sales-flight-ancillary-service",
        component: FlightAncillaryServiceView,
        meta: { requiresAuth: true },
      },
      {
        path: "flight-booking-preview",
        name: "sales-flight-booking-preview",
        component: FlightBookingPreviewView,
        meta: { requiresAuth: true },
      },
    ],
  },
];

export class CarRentalRatesFormModel {
  constructor(
    pickupLocation,
    pickupLocationName,
    returnLocation,
    returnLocationName,
    pickupDatetime,
    returnDatetime,
    countryOfResidence,
    countryName,
    preferredCarType
  ) {
    this.pickupLocation = pickupLocation;
    this.pickupLocationName = pickupLocationName;
    this.returnLocation = returnLocation;
    this.returnLocationName = returnLocationName;
    this.pickupDatetime = pickupDatetime;
    this.returnDatetime = returnDatetime;
    this.countryOfResidence = countryOfResidence;
    this.countryName = countryName;
    this.preferredCarType = preferredCarType;
  }
}

import DynamicPkgCheckOutView from "@/views/v2/sales/dynamicpkg/DynamicPkgCheckOutView.vue";
import DynamicPkgHomeView from "@/views/v2/sales/dynamicpkg/DynamicPkgHomeView.vue";
import DynamicPkgHotelListView from "@/views/v2/sales/dynamicpkg/DynamicPkgHotelListView.vue";
import DynamicPkgHotelDetailsView from "@/views/v2/sales/dynamicpkg/DynamicPkgHotelDetailsView.vue";
import DynamicPkgFlightListAndFareOptionsView from "@/views/v2/sales/dynamicpkg/DynamicPkgFlightListAndFareOptionsView.vue";
import DynamicPkgCarRentalListView from "@/views/v2/sales/dynamicpkg/DynamicPkgCarRentalListView.vue";
import DynamicPkgCarRentalExtrasView from "@/views/v2/sales/dynamicpkg/DynamicPkgCarRentalExtrasView.vue";

export default [
  {
    path: "/sales/v2/dynamicPkg",
    redirect: "/sales/v2/dynamicPkg/dynamicPkg-home",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "dynamicPkg-home",
        name: "sales-dynamicPkg-home-v2",
        meta: { requiresAuth: false, footerShow: true },
        component: DynamicPkgHomeView,
      },
      {
        path: "dynamicPkg-hotel-list",
        name: "sales-dynamicPkg-hotel-list-v2",
        meta: { requiresAuth: false },
        component: DynamicPkgHotelListView,
      },
      {
        path: "dynamicPkg-hotel-details",
        name: "sales-dynamicPkg-hotel-details-v2",
        meta: { requiresAuth: false },
        component: DynamicPkgHotelDetailsView,
      },
      {
        path: "dynamicPkg-flight-list",
        name: "sales-dynamicPkg-flight-list-v2",
        meta: { requiresAuth: false },
        component: DynamicPkgFlightListAndFareOptionsView,
      },
      {
        path: "dynamicPkg-car-rental-list",
        name: "sales-dynamicPkg-car-rental-list-v2",
        meta: { requiresAuth: false },
        component: DynamicPkgCarRentalListView,
      },
      {
        path: "dynamicPkg-car-rental-extra",
        name: "sales-dynamicPkg-car-rental-extra-v2",
        meta: { requiresAuth: false },
        component: DynamicPkgCarRentalExtrasView,
      },
      {
        path: "dynamicPkg-check-out",
        name: "sales-dynamicPkg-check-out-v2",
        meta: { requiresAuth: false },
        component: DynamicPkgCheckOutView,
      },
    ],
  },
];

export class DynamicPkgCarRentalRatesQuoteFormModel {
  constructor(
    quoteID,
    protectionPlan,
    selectedInsurances,
    selectedEquipments,
    currencyCode,
    baseAmount,
    totalAmount
  ) {
    this.quoteID = quoteID;
    this.protectionPlan = protectionPlan;
    this.selectedInsurances = selectedInsurances;
    this.selectedEquipments = selectedEquipments;
    this.currencyCode = currencyCode;
    this.baseAmount = baseAmount;
    this.totalAmount = totalAmount;
  }

  addNewProtectionPlan(protectionPlan) {
    this.protectionPlan = protectionPlan;
    this.calculateTotalPrice();
  }

  addNewInsurance(insurance) {
    if (!this.selectedInsurances || this.selectedInsurances.length === 0)
      this.selectedInsurances = [];
    const code = insurance.code;
    const isContain = this.selectedInsurances.find(
      (insurance) => insurance.code === code
    );
    if (!isContain) {
      this.selectedInsurances.push(insurance);
    }
    this.calculateTotalPrice();
  }

  removeInsurance(insuranceIndex) {
    if (this.selectedInsurances && this.selectedInsurances.length > 0) {
      this.selectedInsurances.splice(insuranceIndex, 1);
    }
    this.calculateTotalPrice();
  }

  addNewEquipment(equipment) {
    if (!this.selectedEquipments || this.selectedEquipments.length === 0)
      this.selectedEquipments = [];
    const code = equipment.code;
    let quantity = equipment.quantity;

    const isContainItem = this.selectedEquipments.find(
      (equipment) => equipment.code === code
    );

    if (!isContainItem) {
      equipment.quantity = 1;
      this.selectedEquipments.push(equipment);
    } else {
      isContainItem.quantity += 1;
    }
    this.calculateTotalPrice();
  }

  removeEquipment(insuranceIndex) {
    if (this.selectedEquipments && this.selectedEquipments.length > 0) {
      this.selectedEquipments.splice(insuranceIndex, 1);
    }
    this.calculateTotalPrice();
  }

  calculateTotalPrice() {
    let totalAmount = this.baseAmount;
    let currency = this.currencyCode;

    if (this.protectionPlan) {
      const currencyCode = this.protectionPlan.currency;
      const amount = this.protectionPlan.totalAmount;
      if (!currency && currencyCode) currency = currencyCode;
      totalAmount += amount;
    }
    if (this.selectedInsurances && this.selectedInsurances.length > 0) {
      for (const selectedInsurance of this.selectedInsurances) {
        const currencyCode = selectedInsurance.currency;
        const amount = selectedInsurance.amount;
        if (!currency && currencyCode) currency = currencyCode;
        totalAmount += amount;
      }
    }
    if (this.selectedEquipments && this.selectedEquipments.length > 0) {
      for (const selectedEquipment of this.selectedEquipments) {
        let quantity = selectedEquipment.quantity;
        const currencyCode = selectedEquipment.currencyCode;
        const amount = selectedEquipment.amount;
        if (!currency && currencyCode) currency = currencyCode;
        totalAmount += amount * quantity;
      }
    }

    this.totalAmount = totalAmount;
    this.currencyCode = currency;
    return {
      amount: totalAmount,
      currencyCode: currency,
    };
  }
}

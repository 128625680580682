<script setup>
import { useI18n } from "vue-i18n";

import { ref, watch } from "vue";
import contentApi from "@/apis/contentApi";
import { useFlightAncillaryServiceStore } from "@/stores/v2/flight/ancillaryservice/flightAncillaryServiceStore";
import { useProfileStore } from "@/stores/profile";
import airContentApi from "@/apis/v2/content/airContentApi";
import commonUtils from "@/utils/commonUtils";
import DynamicPkgFlightPassengerFormItem from "@/views/v2/sales/dynamicpkg/items/form/checkout/DynamicPkgFlightPassengerFormItem.vue";

const { t, locale } = useI18n(); // 解构调用函数

const emits = defineEmits();
const props = defineProps({
  passengerFormModelItems: {
    type: Object,
  },
  tripAirItem: {},
});

const user = JSON.parse(sessionStorage.getItem("user"));
const profileStore = useProfileStore();
profileStore.$reset(); //重置状态

if (user && user.partyId) loadUserProfile();

function loadUserProfile() {
  profileStore.loadUserProfile(user.partyId);
}

const ancillaryServiceStore = useFlightAncillaryServiceStore();
const earlyDepartureDateRef = ref(null);
const latestDepartureDateRef = ref(null);
const isPassportMandatoryRef = ref(null);

const originDestinationsRef = ref([]);
const profileViewItemsRef = ref(null);
const countryOptionsRef = ref(null);

watch(
  () => props.tripAirItem,
  (newValue) => {
    if (newValue && newValue.flightItems) {
      originDestinationsRef.value = newValue.flightItems;

      if (
        originDestinationsRef.value &&
        originDestinationsRef.value.length > 0
      ) {
        //需要校验护照过期时间（护照有效期为出发或返回后 6 个月内） 需要校验护照是否强制（根据OD countryCode）
        earlyDepartureDateRef.value =
          originDestinationsRef.value[0].departureDateTime.split("T")[0];
        latestDepartureDateRef.value =
          originDestinationsRef.value[
            originDestinationsRef.value.length - 1
          ].departureDateTime.split("T")[0];
        //只要里面有符合

        let isPassportMandatoryMatch = false;
        originDestinationsRef.value.forEach((flightItem) => {
          const originLocationCode = flightItem.originLocationCode;
          const destinationLocationCode = flightItem.destinationLocationCode;
          let originCountryCode = null;
          let destinationCountryCode = null;

          if (!isPassportMandatoryMatch) {
            const originLocationRSP =
              contentApi.airportCountryCode(originLocationCode);

            const destinationLocationRSP = contentApi.airportCountryCode(
              destinationLocationCode
            );
            let promises = [originLocationRSP, destinationLocationRSP];

            Promise.all(promises).then((res) => {
              if (res && res.length > 0) {
                const originLocationRS = res[0];
                const destinationLocationRS = res[1];
                originCountryCode =
                  originLocationRS && originLocationRS.data
                    ? originLocationRS.data.countryCode
                    : null;
                destinationCountryCode =
                  destinationLocationRS && destinationLocationRS.data
                    ? destinationLocationRS.data.countryCode
                    : null;

                contentApi
                  .passport(originCountryCode, destinationCountryCode)
                  .then((res) => {
                    const commentRS = res.data;

                    if (
                      commentRS &&
                      commentRS.comment &&
                      commentRS.comment.stringValue === "MANDATORY"
                    ) {
                      isPassportMandatoryRef.value = true; //强制要求
                      isPassportMandatoryMatch = true;
                    }
                  });
              }
            });
          }
        });
      }
      //所有的国家
      airContentApi.allCountries().then((res) => {
        countryOptionsRef.value = [];
        if (res) {
          if (res.data && res.data.length > 0) {
            res.data.forEach((item) => {
              countryOptionsRef.value.push({
                label: item.decode,
                value: item.code,
              });
            });
          }
        }
      });
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => profileStore.profileViewItem,
  (newValue) => {
    const profile = JSON.parse(JSON.stringify(newValue));
    if (profile) {
      const additionalTravellers = profile.additionalTravellers;
      const partyIds = [user.partyId];
      //将这些人的信息全部load出来
      if (additionalTravellers && additionalTravellers.length > 0) {
        additionalTravellers.forEach((additionalTraveller) => {
          const partyId = additionalTraveller.partyIdFrom;
          partyIds.push(partyId);
        });
      }
      profileStore.loadAdditionalTravellersProfile(partyIds);
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => profileStore.profileViewItems,
  (newValue) => {
    profileViewItemsRef.value = commonUtils.sortByParams(newValue, "surname");
  },
  { immediate: true, deep: true }
);

//获取所有的航空公司联盟
let frequentFlyerProgramOptionRef = ref([]);
frequentFlyerProgramOptionRef.value.push({
  programID: "",
  programName: t("all-programmes"),
});
contentApi.frequentFlyerPrograms().then((res) => {
  if (res.data && res.data.length > 0) {
    res.data.sort(function (a, b) {
      if (a.programName < b.programName) {
        return -1;
      }
      if (a.programName > b.programName) {
        return 1;
      }
      return 0;
    });
    frequentFlyerProgramOptionRef.value.push(...res.data);
  }
});

function openAncillaryService() {
  emits("openAncillaryService");
}

function openSeatSelection() {
  emits("openSeatSelection");
}
</script>

<template>
  <div
    class="tour_booking_form_box"
    v-if="passengerFormModelItems && passengerFormModelItems.length > 0"
  >
    <h3 class="heading_theme">
      {{ $t("who-is-flying") }}
    </h3>

    <DynamicPkgFlightPassengerFormItem
      v-for="(passenger, passengerIndex) in passengerFormModelItems"
      :key="passengerIndex"
      :early-departure-date="earlyDepartureDateRef"
      :is-passport-mandatory="isPassportMandatoryRef"
      :latest-departure-date="latestDepartureDateRef"
      :passenger-index="passengerIndex + 1"
      :passenger-model="passenger"
      :passenger-number="passengerFormModelItems.length"
      :frequent-flyer-program-options="frequentFlyerProgramOptionRef"
      :profile-view-items="profileViewItemsRef"
      :country-options="countryOptionsRef"
    />

    <!--    <div class="text-center mg-t-20">-->
    <!--      <button-->
    <!--        v-if="-->
    <!--          ancillaryServiceStore.ancillaryOptions &&-->
    <!--          ancillaryServiceStore.ancillaryOptions.filter(-->
    <!--            (item) => item.type === 'SERVICE'-->
    <!--          ) &&-->
    <!--          ancillaryServiceStore.ancillaryOptions.filter(-->
    <!--            (item) => item.type === 'SERVICE'-->
    <!--          ).length > 0-->
    <!--        "-->
    <!--        class="btn btn-outline-primary mb-2"-->
    <!--        @click="openAncillaryService"-->
    <!--      >-->
    <!--        {{ $t("purchase-ancillary-service") }}-->
    <!--      </button>-->

    <!--      <button-->
    <!--        v-if="ancillaryServiceStore.seatMaps"-->
    <!--        class="btn btn-outline-primary mg-l-10 mb-2"-->
    <!--        @click="openSeatSelection"-->
    <!--      >-->
    <!--        {{ $t("seat-selection") }}-->
    <!--      </button>-->
    <!--    </div>-->
  </div>
</template>

<style lang="scss" scoped></style>

<script setup>
import { useI18n } from "vue-i18n";
import { Select } from "ant-design-vue";
import { ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  ffpNumer: {},
  partyId: {},
  frequentFlyerProgramOptions: {},
});

const { t } = useI18n();

const ffpNumberFormModelRef = ref(null);
const emits = defineEmits([]);
watch(
  () => props.ffpNumer,
  (newValue) => {
    ffpNumberFormModelRef.value = newValue;
    if (!newValue) {
      ffpNumberFormModelRef.value = {
        programID: null,
        membershipID: null,
      };
    }
  },
  { deep: true, immediate: true }
);

const validateFFProgramRule = () => {
  if (
    (!ffpNumberFormModelRef.value.programID &&
      !ffpNumberFormModelRef.value.membershipID) ||
    !ffpNumberFormModelRef.value.membershipID ||
    (ffpNumberFormModelRef.value.programID &&
      ffpNumberFormModelRef.value.membershipID)
  ) {
    return true;
  }
  return false;
};

const validateFFNumberRule = () => {
  if (
    (!ffpNumberFormModelRef.value.programID &&
      !ffpNumberFormModelRef.value.membershipID) ||
    !ffpNumberFormModelRef.value.programID ||
    (ffpNumberFormModelRef.value.programID &&
      ffpNumberFormModelRef.value.membershipID)
  ) {
    return true;
  }
  return false;
};

const rulesRef = ref({
  programID: { validateFFProgramRule },
  membershipID: { validateFFNumberRule },
});

const v$ = useVuelidate(rulesRef.value, ffpNumberFormModelRef);

function addMembership() {
  emits("addMembership");
}

function removeMembership(ffpNumberFormModel) {
  ffpNumberFormModel.programID = "";
  ffpNumberFormModel.membershipID = "";
}
</script>

<template>
  <div class="col-5">
    <label class="form-label">{{ $t("frequent-flyer-programmes") }}</label>
    <select
      id="passenger-form-flyerAirline"
      v-model="ffpNumberFormModelRef.programID"
      :class="{
        'is-invalid': v$ && v$.programID && v$.programID.$errors.length > 0,
      }"
      :disabled="partyId"
      class="form-control main_input_height"
      type="text"
    >
      <option disabled>
        {{ $t("all-programmes") }}
      </option>
      <option
        v-for="(
          frequentFlyerProgram, frequentFlyerIndex
        ) in frequentFlyerProgramOptions"
        :key="frequentFlyerIndex"
        :value="frequentFlyerProgram.programID"
      >
        {{ frequentFlyerProgram.programName }}
      </option>
    </select>
  </div>
  <div class="col-5">
    <label class="form-label">{{ $t("frequent-flyer-airline-number") }} </label>
    <input
      id="passenger-form-surname"
      v-model="ffpNumberFormModelRef.membershipID"
      :class="{
        'is-invalid':
          v$ && v$.membershipID && v$.membershipID.$errors.length > 0,
      }"
      :disabled="partyId"
      :placeholder="$t('frequent-flyer-airline-number-option')"
      autocomplete="off"
      class="form-control main_input_height"
      name="passenger-form-surname"
      type="text"
    />
  </div>
  <div
    v-if="partyId"
    class="col-2 mg-t-20 cursor-point"
    style="display: flex; align-items: center"
  >
    <span class="text-primary" @click="addMembership">{{ $t("add") }}</span>
    <span
      v-if="ffpNumberFormModelRef.membershipID"
      class="text-danger mg-l-8"
      @click="removeMembership(ffpNumberFormModelRef)"
      >{{ $t("remove") }}</span
    >
  </div>
</template>

<style lang="scss" scoped></style>

import { defineStore } from "pinia";
import hotelTools from "@/tools/hotelTools";
import hotelApi from "@/apis/hotelApi";
import router from "@/router/modulesRouter";
export const useHotelStore = defineStore("hotel", {
  state: () => ({
    hotelViewItemMap: null, //ttiCode -> hotelViewItem 的映射表
    sortedHotelViewItems: null,
    holdRoomInfo: null,
    hotelImages: null,
    preBookRoomInfo: null,
    roomResGuestDetails: null,
    bookRQ: null,
  }),
  actions: {
    /*
     * 通过传入的hotelSearchFormModel对象实例，搜索匹配酒店
     * @param formModel HotelSearchFormModel类实例
     * */
    async searchHotels(formModel) {
      let hotelAvailRQ = hotelTools.getHotelAvailRQ(
        formModel.placeId,
        formModel.type,
        formModel.ttiCode,
        formModel.checkInDate,
        formModel.checkOutDate,
        formModel.roomPassengers,
        formModel.language,
        formModel.filterOption,
        formModel.sortingOption,
        formModel.bestOnlyIndicator
      );

      //区分是否是酒店详情搜索
      if (
        formModel.ttiCode != null &&
        formModel.ttiCode != "" &&
        !formModel.bestOnlyIndicator
      ) {
        let descriptiveRQ = hotelTools.getDescriptiveRQ(
          formModel.ttiCode,
          formModel.language
        );
        const hotelDescriptiveRSP = hotelApi
          .descriptive(descriptiveRQ)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.error(err);
            router.push({
              name: "error-500",
              query: { message: "hotel unavailable" },
            });
          });

        const hotelAvailRSP = hotelApi
          .avail(hotelAvailRQ)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.error(err);
            router.push({
              name: "error-500",
              query: { message: "hotel unavailable" },
            });
          });

        let _this = this;
        return Promise.all([hotelDescriptiveRSP, hotelAvailRSP]).then((res) => {
          const hotelDescriptiveRS = res[0];
          const hotelAvailRS = res[1];
          _this.hotelViewItemMap = hotelTools.getHotelViewItemMap(
            hotelAvailRS,
            hotelDescriptiveRS
          );
        });
      } else {
        let _this = this;
        return hotelApi.avail(hotelAvailRQ).then((res) => {
          let hotelAvailRS = res.data ? res.data : null;
          _this.hotelViewItemMap = hotelTools.getHotelViewItemMap(
            hotelAvailRS,
            null
          );
          _this.sortedHotelViewItems = hotelTools.getSortedHotelViewItems(
            _this.hotelViewItemMap,
            hotelAvailRS
          );
        });
      }
    },
    async getHotelImages(ttiCode, language) {
      let descriptiveRQ = hotelTools.getDescriptiveRQ(ttiCode, language);
      const { data } = await hotelApi.descriptive(descriptiveRQ).catch(() => {
        router.push({
          name: "error-500",
          query: { message: "hotel unavailable" },
        });
      });

      this.hotelImages = hotelTools.getHotelImages(data);
    },
    /*
     * 在选择房间后调用该方法，锁库存
     * */
    async holdRoomInventory(roomViewItem, roomQuantity, extraServices) {
      const hotelSearchFormModel = hotelTools.getRomGstFrmMdByAvail(
        roomViewItem,
        roomQuantity,
        extraServices
      );
      let holdRQ = hotelTools.getHoldRQ(hotelSearchFormModel);
      // let _this = this;
      return hotelApi.hold(holdRQ).then((res) => {
        let holdRS = res ? res.data : null;
        if (
          holdRS == null ||
          (holdRS.errors &&
            holdRS.errors.errors != null &&
            holdRS.errors.errors.length > 0)
        ) {
          return {};
        } else {
          let holdRoomInfo = hotelTools.getReservationRoomInfo(holdRS);
          let roomResGuestFormModel =
            hotelTools.getRoomResGuestFormModel(holdRS);
          // _this.holdRoomInfo = holdRoomInfo;
          return {
            holdRoomInfo,
            roomResGuestFormModel,
          };
        }
      });
    },
    /*
     * 在我们订单系统中，初始化订单
     * */
    async completeBooking(formModel) {
      let preBookRQ = hotelTools.getPreBookRQ(formModel);
      return hotelApi
        .preBook(preBookRQ)
        .then((res) => {
          let preBookRS = res.data;
          if (
            preBookRS == null ||
            (preBookRS.errors &&
              preBookRS.errors.errors != null &&
              preBookRS.errors.errors.length > 0)
          )
            router.push({
              name: "error-500",
              query: { message: "hotel unavailable" },
            });

          let preBookRoomInfo = hotelTools.getReservationRoomInfo(preBookRS);
          let roomResGuestDetails =
            hotelTools.getRoomResGuestDetails(preBookRS);

          let bookRQ = hotelTools.getBookRQ(preBookRQ, preBookRS);

          let data = {
            preBookRoomInfo,
            roomResGuestDetails,
            bookRQ,
          };
          return data;
        })
        .catch((err) => {
          console.error(err);
          router.push({
            name: "error-500",
            query: { message: "hotel unavailable" },
          });
        });
    },
  },
});

<script setup>
import HotelPhotosGroup from "@/views/v2/sales/hotel/items/group/HotelPhotosGroup.vue";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { usePackageHotelAvailStore } from "@/stores/v2/dynamicPkg/availability/packageHotelAvailStore";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import dynamicPkgCommonTools from "@/tools/v2/dynamicPkg/common/dynamicPkgCommonTools";
import DynamicPkgHotelTabItemGroup from "@/views/v2/sales/dynamicpkg/items/group/DynamicPkgHotelTabItemGroup.vue";
import { useCmsContentStore } from "@/stores/cms";

const { t, locale } = useI18n(); // 解构调用函数
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const packageHotelAvailStore = usePackageHotelAvailStore();

const dynamicPkgAvailFormModelRef = ref(null);
const hotelItemDetailsShowRef = ref(null);

//#region query参数请求，进行搜索
if (route.query != null && Object.keys(route.query).length > 0) {
  dynamicPkgAvailFormModelRef.value =
    dynamicPkgCommonTools.parseDynamicPkgAvailFormModel(route.query);
  searchHotelByFormModel(dynamicPkgAvailFormModelRef.value);
}
//#endregion

watch(
  () => packageHotelAvailStore.hotelItems,
  (newValue) => {
    if (!newValue && route.query && "UPDATE" !== route.query.type) {
      sessionStorage.removeItem("hotelItemDetails");
    }
    if (newValue && newValue.length > 0) {
      hotelItemDetailsShowRef.value = newValue[0];
      //当房型为空时做存储处理，给刷新页面时，请求无数据的情况赋值
      if (
        hotelItemDetailsShowRef.value &&
        hotelItemDetailsShowRef.value.roomTypes &&
        hotelItemDetailsShowRef.value.roomTypes.length === 0
      ) {
        sessionStorage.setItem(
          "hotelItemDetails",
          JSON.stringify(hotelItemDetailsShowRef.value)
        );
      }
    } else {
      if (sessionStorage.getItem("hotelItemDetails")) {
        hotelItemDetailsShowRef.value = JSON.parse(
          sessionStorage.getItem("hotelItemDetails")
        );
      }
    }
  },
  { immediate: true, deep: true }
);

async function searchHotelByFormModel(formModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await packageHotelAvailStore.hotelAvail(formModel);
  templateStore.pageLoader({ mode: "off" });
}

function goToHotelList() {
  window.close();
}

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.PackageBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.PackageBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("hotel-details") }}</h2>
        <ul>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("hotel-details") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_details_main" class="section_padding">
    <div class="container hotel_container" style="margin-left: 350px">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goToHotelList">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row" v-if="hotelItemDetailsShowRef">
        <div class="col-lg-10">
          <div class="tour_details_leftside_wrapper">
            <div
              class="tour_details_img_wrapper"
              v-if="
                hotelItemDetailsShowRef.photos &&
                hotelItemDetailsShowRef.photos.length > 0
              "
            >
              <HotelPhotosGroup
                :hotel-images="hotelItemDetailsShowRef.photos"
              />
            </div>

            <DynamicPkgHotelTabItemGroup
              :dynamic-pkg-avail-form-model="dynamicPkgAvailFormModelRef"
              :hotel-item-details="hotelItemDetailsShowRef"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

import { CarRentalAvailFormModel } from "@/formmodel/sales/carrental/availablility/carRentalAvailFormModel";

function buildCarRentalAvailFormModal(query) {
  const carRentalAvailFormModel = new CarRentalAvailFormModel(
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,

    null
  );
  carRentalAvailFormModel.pickupLocation = query.pickupLocation;
  carRentalAvailFormModel.pickupLocationName = query.pickupLocationName;
  carRentalAvailFormModel.returnLocation = query.returnLocation;
  carRentalAvailFormModel.returnLocationName = query.returnLocationName;
  carRentalAvailFormModel.pickupDatetime = query.pickupDatetime;
  carRentalAvailFormModel.returnDatetime = query.returnDatetime;
  carRentalAvailFormModel.countryOfResidence = query.countryOfResidence;
  carRentalAvailFormModel.countryName = query.countryName;
  carRentalAvailFormModel.preferredCarType = query.preferredCarType;

  return carRentalAvailFormModel;
}

export default {
  buildCarRentalAvailFormModal,
};

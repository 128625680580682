import axios from "@/utils/axiosUtils";

function getPartyRelationships(partyId) {
  let url = "/api/v3/parties/" + partyId + "/relationships";
  return axios.get(url);
}

function getRelationshiptypes() {
  let url = "/api/v3/party/relationshiptypes";
  return axios.get(url);
}

function getPartyRelationshipsType(partyId, roleTypeIdFrom) {
  let url =
    "/api/v3/parties/" +
    partyId +
    "/relationships?roleTypeIdFrom=" +
    roleTypeIdFrom;
  return axios.get(url);
}

function addRelationship(relationshipRQ) {
  let url = "/api/v3/party/relationships";
  return axios.post(url, relationshipRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function modifyRelationship(relationshipRQ, relationshipId) {
  let url = "/api/v3/party/relationships/" + relationshipId;
  return axios.put(url, relationshipRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function deleteRelationship(relationshipId) {
  let url = "/api/v3/party/relationships/" + relationshipId;
  return axios.delete(url);
}

export default {
  getPartyRelationships,
  getRelationshiptypes,
  getPartyRelationshipsType,
  addRelationship,
  modifyRelationship,
  deleteRelationship,
};

<template>

    <!-- Common Banner Area -->
    <HotelBookingBanner />

    <!-- Dashboard Area -->
    <HotelBookingDashboard />

</template>
<script>

import HotelBookingBanner from '@/components/templates/dashboard/HotelBookingBanner.vue'
import HotelBookingDashboard from '@/components/templates/dashboard/HotelBookingDashboard.vue'
import Cta from '@/components/templates/home/Cta.vue'

export default {
    name: "HotelBookingView",
    components: {
        HotelBookingBanner, HotelBookingDashboard, Cta
    }
};
</script>

<script setup>
import { reactive, ref, watchEffect } from "vue";
import { email, integer, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

let props = defineProps({
  questionOption: {
    type: Object,
  },
  questionIndex: {
    type: Number,
  },
});

const questionOptionRef = ref(props.questionOption);

let rules = reactive({
  answer: {
    required,
  },
});

const v$ = useVuelidate(rules, questionOptionRef.value);

watchEffect(() => {
  if (props.questionOption) {
    questionOptionRef.value = props.questionOption;
  }
});
</script>

<template>
  <div v-if="questionOptionRef.question">
    <div>
      {{ questionIndex + 1 }}.
      {{ questionOptionRef.question.toLocaleLowerCase() }}
    </div>
    <input
      id="passenger-form-surname"
      v-model="questionOptionRef.answer"
      autocomplete="off"
      class="form-control mg-t-10 main_input_height"
      :class="{
        'is-invalid': v$.answer.$errors.length > 0,
      }"
      name="passenger-form-surname"
      :placeholder="$t('enter-your-answer')"
      type="text"
    />
  </div>
</template>

<style scoped lang="scss"></style>

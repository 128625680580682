<template>

    <!-- Common Banner Area -->
    <RoomBookingBanner />

    <!-- Tour Booking Submission Areas -->
    <TourBookingSubmission />

    <!-- Cta Area -->
    <Cta />

</template>
<script>
import RoomBookingBanner from '@/components/templates/hotel/RoomBookingBanner.vue'
import TourBookingSubmission from '@/components/templates/hotel/TourBookingSubmission.vue'
import Cta from '@/components/templates/home/Cta.vue'

export default {
    name: "RoomBookingView",
    components: {
        RoomBookingBanner, TourBookingSubmission, Cta
    }
};
</script>
export class TripTourActivityItemVO {
  constructor(
    name,
    groupName,
    tourActivityID,
    confirmations,
    shortDescription,
    addressInfo,
    addressLines,
    participantInfo,
    contactPerson,
    cancelDete,
    scheduleDate,
    supplierInfo,
    operaterInfo,
    participantCategories,
    groupInfo,
    tripItemStatus,
    receiptStatus,
    createdDateTime,
    necessaryInfos,
    passengers,
    totalAmount,
    currencyCode
  ) {
    this.name = name;
    this.groupName = groupName;
    this.tourActivityID = tourActivityID;
    this.confirmations = confirmations;

    this.shortDescription = shortDescription;
    this.addressInfo = addressInfo;
    this.addressLines = addressLines;
    this.participantInfo = participantInfo;
    this.contactPerson = contactPerson;
    this.cancelDete = cancelDete;
    this.scheduleDate = scheduleDate;
    this.supplierInfo = supplierInfo;
    this.operaterInfo = operaterInfo;
    this.participantCategories = participantCategories;
    this.groupInfo = groupInfo;
    this.tripItemStatus = tripItemStatus;
    this.receiptStatus = receiptStatus;
    this.necessaryInfos = necessaryInfos;
    this.createdDateTime = createdDateTime;
    this.passengers = passengers;
    this.totalAmount = totalAmount;
    this.currencyCode = currencyCode;
  }

  addNewNecessaryInfo(question, answer) {
    if (this.necessaryInfos == null) this.necessaryInfos = [];
    this.necessaryInfos.push({ question, answer });
  }
}

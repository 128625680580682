import { defineStore } from "pinia";
import tripCommonTools from "@/tools/v2/trip/tripCommonTools";
import flightPrimeBookingApi from "@/apis/v2/flight/flightPrimeBookingApi";
import tripAirItemTools from "@/tools/v2/trip/tripAirItemTools";

export const useTripFlightStore = defineStore("tripFlight", {
  state: () => ({ tripAirItem: null, priceClasses: null }),
  actions: {
    retrieve(recordLocator, type, idContext) {
      const resRetrieveRQ = tripCommonTools.buildResRetrieveRQ(
        recordLocator,
        type,
        idContext
      );
      const thisRef = this;
      return flightPrimeBookingApi.resRetrieve(resRetrieveRQ).then((res) => {
        const resRetrieveRS = res.data;
        if (
          resRetrieveRS &&
          resRetrieveRS.reservationList &&
          resRetrieveRS.reservationList.airReservations
        ) {
          const airReservations = resRetrieveRS.reservationList.airReservations;
          thisRef.tripAirItem = tripAirItemTools.buildTripAirItem(
            airReservations[0]
          );
          thisRef.priceClasses = tripAirItemTools.buildPriceClasses(
            airReservations[0].offer
          );
        } else {
          thisRef.tripAirItem = null;
          thisRef.priceClasses = null;
        }

        return resRetrieveRS;
      });
    },
  },
});

<script setup>
import { ref, watch } from "vue";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  userProfileFormModal: {},
});

const emits = defineEmits([]);
const userProfileFormModalRef = ref(null);

watch(
  () => props.userProfileFormModal,
  (newValue) => {
    userProfileFormModalRef.value = newValue;
  },
  { immediate: true, deep: true }
);
let rules = {
  knownTravellerNumber: { required },
  redressNumber: { required },
};

const v = useVuelidate(rules, userProfileFormModalRef);

async function modifyAirportSecurity(userProfileFormModal) {
  const result = await v.value.$validate();
  if (!result) return;
  emits("modifyAirportSecurity", userProfileFormModal, "AIRPORTSECURITY");
}
</script>

<template>
  <form
    action="!#"
    id="profile_form_area"
    @submit.prevent="modifyAirportSecurity(userProfileFormModalRef)"
  >
    <h3>Airport security</h3>
    <div class="row mg-t-20">
      <div class="col-lg-6">
        <div class="form-group">
          <label for="f-name">TSA PreCheck</label>
          <input
            v-model="userProfileFormModalRef.knownTravellerNumber"
            :class="{
              'is-invalid': v.knownTravellerNumber.$errors.length,
            }"
            type="text"
            class="form-control"
            placeholder="Known Traveller Number(KTN)"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label for="f-name">{{ $t("redress-number") }}</label>
          <input
            v-model="userProfileFormModalRef.redressNumber"
            :class="{
              'is-invalid': v.redressNumber.$errors.length,
            }"
            type="text"
            class="form-control"
            :placeholder="$t('redress-number')"
          />
        </div>
      </div>

      <div class="col-lg-12" align="center">
        <button class="btn btn_theme">{{ $t("save") }}</button>
      </div>
    </div>
  </form>
</template>

<style scoped></style>

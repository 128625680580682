<script setup>
import { reactive, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import BaseActivityDetailPassengersForm from "@/views/v2/sales/activity/items/form/BaseActivityDetailPassengersForm.vue";

const props = defineProps({ groupViewItem: {}, participantCategories: {} });

//region 数据初始化

let activityFormModel = JSON.parse(
  sessionStorage.getItem("activitySearchFormModel")
);

const route = useRoute();

let passengerFormModel = reactive({
  adultNum: activityFormModel.adultNum,
  seniorNum: activityFormModel.seniorNum,
  childNum: activityFormModel.childNum,
  childAges: activityFormModel.childAges,
});

let totalPrice = ref({
  amount: 0,
  currencyCode: "",
  description: "",
});

let languageKey = ref(Object.keys(props.groupViewItem.languageGroupMap)[0]); //选择的语言
let basicLanguagePriceGroup = ref({}); //基础价格

watchEffect(
  () => {
    //拿到对应语言的ageGroup
    if (
      props.groupViewItem &&
      props.groupViewItem.languageGroupMap &&
      languageKey.value
    ) {
      const ageGroupMap =
        props.groupViewItem.languageGroupMap[languageKey.value].ageGroupMap;
      const amount =
        props.groupViewItem.languageGroupMap[languageKey.value].amount;
      const currencyCode =
        props.groupViewItem.languageGroupMap[languageKey.value].currencyCode;
      const description =
        props.groupViewItem.languageGroupMap[languageKey.value].description;

      totalPrice.value.amount = amount;
      totalPrice.value.currencyCode = currencyCode;
      totalPrice.value.description = description;

      //年龄分组价格显示
      const childAges = passengerFormModel.childAges;
      let adultPrice = {};
      let childPrice = {};
      if (ageGroupMap) {
        for (const ageKey in ageGroupMap) {
          const ageQualifyingCode = ageGroupMap[ageKey].ageQualifyingCode;
          if (ageQualifyingCode == "10") {
            //成人价格
            adultPrice.amount = ageGroupMap[ageKey].price.amount;
            adultPrice.currencyCode = ageGroupMap[ageKey].price.currencyCode;
            if (ageGroupMap[ageKey].maxAge == "999") {
              adultPrice.ageRange = ageGroupMap[ageKey].minAge + "+";
            } else {
              adultPrice.ageRange =
                ageGroupMap[ageKey].minAge + "-" + ageGroupMap[ageKey].maxAge;
            }
          } else {
            if (childAges && childAges.length > 0) {
              let childAgeOptionNum = childAges.filter(
                (item) =>
                  item.age <= ageGroupMap[ageKey].maxAge &&
                  item.age >= ageGroupMap[ageKey].minAge
              ).length;
              if (!childAgeOptionNum) {
                //没有该组表示的小孩年龄区间的年龄,放在大人里面
                const key = "-";
                childPrice[key] = {
                  amount: ageGroupMap[ageKey].price.amount,
                  currencyCode: ageGroupMap[ageKey].price.currencyCode,
                };
              } else {
                const key =
                  ageGroupMap[ageKey].minAge + "-" + ageGroupMap[ageKey].maxAge;
                childPrice[key] = {
                  amount: ageGroupMap[ageKey].price.amount,
                  currencyCode: ageGroupMap[ageKey].price.currencyCode,
                };
              }
            }
          }
        }
      }

      basicLanguagePriceGroup.value = {
        adultPrice: adultPrice,
        childPrice: childPrice,
      };
    }
  },
  { immediate: true }
);

//计算不同人的基础价格

//endregion

const emits = defineEmits();

//人数改变计算价格
function submitPassengersFormModel(passengersFormModel) {
  const childNum = passengersFormModel.childNum;
  const adultNum = passengersFormModel.adultNum;
  const seniorNum = passengersFormModel.seniorNum;
  const childAges = passengersFormModel.childAges;

  //进行新的请求
  if (route.query != null && Object.keys(route.query).length > 0) {
    let startDate = route.query.startDate;
    let endDate = route.query.endDate;
    let activityId = route.query.activityId;
    let detailsRequestBody = {
      startDate: startDate,
      endDate: endDate,
      childAges: childAges,
      adultNum: adultNum,
      childNum: childNum,
      seniorNum: seniorNum,
      activityId: activityId,
    };
    emits("initialDetails", detailsRequestBody);
  }
}
</script>

<template>
  <div v-if="groupViewItem" class="col-lg-12 col-12">
    <div class="check_list row">
      <div class="col-12">
        <h4>{{ groupViewItem.groupName }}</h4>
      </div>

      <div v-if="basicLanguagePriceGroup" class="col-5">
        <div v-if="basicLanguagePriceGroup.adultPrice" class="row">
          <div class="col-10">
            <div class="row">
              <div class="col-6">
                {{ $t("per-adult") }} ({{
                  basicLanguagePriceGroup.adultPrice.ageRange
                }})
              </div>
              <div class="col-6">
                {{ basicLanguagePriceGroup.adultPrice.currencyCode }}
                {{ basicLanguagePriceGroup.adultPrice.amount }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="basicLanguagePriceGroup.childPrice">
          <div
            v-for="(child, key, index) in basicLanguagePriceGroup.childPrice"
            class="row"
          >
            <div class="col-10">
              <div v-if="key !== '-'" class="row">
                <div class="col-6">
                  {{ $t("per") }} {{ $t("child") }} ({{ key }})
                </div>
                <div class="col-6">
                  {{ child.currencyCode }}
                  {{ child.amount }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div class="row">
          <div class="col-10">
            <div class="row" v-if="totalPrice">
              <div class="col-6 h6 text-warning">
                {{ $t("total-amount") }} ({{
                  totalPrice.description.toLocaleLowerCase()
                }})
              </div>
              <div class="col-6 h6 text-warning">
                {{ totalPrice.currencyCode }}
                {{ totalPrice.amount }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="groupViewItem.languageGroupMap" class="col-5">
        <div class="form-floating mg-b-10">
          <select
            id="example-select-floating"
            v-model="languageKey"
            aria-label="Floating label select example"
            class="form-select"
            name="example-select-floating"
          >
            <option
              v-for="(
                languageOption, languageKey, index
              ) in groupViewItem.languageGroupMap"
              :key="index"
              :value="languageKey"
            >
              {{ languageKey }}
            </option>
          </select>
          <label for="example-select-floating">{{ $t("language") }}</label>
        </div>
        <BaseActivityDetailPassengersForm
          :passenger-form-model="passengerFormModel"
          @submitPassengersFormModel="submitPassengersFormModel"
        />
      </div>
      <div class="text-center col-2">
        <button
          class="btn btn_theme mg-t-20"
          type="button"
          @click="$emit('selectActivityGroupItem', languageKey, groupViewItem)"
        >
          {{ $t("select") }}
        </button>
      </div>

      <!--      <div class="col-12 text-end">-->
      <!--        <p class="fs-sm text-overflow-hidden mb-0 text-danger">-->
      <!--          Until Fri, Oct 6-->
      <!--        </p>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

export class TripPassengerDocumentVO {
  /**
   *
   * @param docType string，证件类型，PASSPORT-护照，VISA-签证，REDRESS-美国身份识别码
   * @param docID string，证件号
   * @param expireDate string，证件过期日期，格式为yyyy-MM-dd
   * @param docIssueCountry string，证件签发国家代码，如US
   * @param docHolderNationality string，证件持有者所属国家代码，如CN
   */
  constructor(
    docType,
    docID,
    expireDate,
    docIssueCountry,
    docHolderNationality
  ) {
    this.docType = docType;
    this.docID = docID;
    this.expireDate = expireDate;
    this.docIssueCountry = docIssueCountry;
    this.docHolderNationality = docHolderNationality;
  }
}

import axios from "@/utils/axiosUtils.js";

function countriesAutocomplete(keyword) {
  let url = "/api/v3/content/common/countries/autocomplete";
  return axios.get(url, {
    params: {
      language: "en",
      keyword: keyword,
      type: "http",
    },
  });
}

function capabilityContent() {
  let url = "/api/v3/content/website/capability";
  return axios.get(url);
  // return Promise.resolve();
}

export default {
  countriesAutocomplete,
  capabilityContent,
};

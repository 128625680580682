export default class FlightPenaltyOptionVO {
  constructor(
    volChangeInd,
    passengerTypeCode,
    penaltyType,
    departureStatus,
    percent,
    amount,
    currencyCode
  ) {
    this.volChangeInd = volChangeInd;
    this.passengerTypeCode = passengerTypeCode;
    this.penaltyType = penaltyType;
    this.departureStatus = departureStatus;
    this.percent = percent;
    this.amount = amount;
    this.currencyCode = currencyCode;
  }
}

<script setup>
import { reactive, watchEffect } from "vue";

const props = defineProps({
  promotions: {},
  showUserRating: {},
});
let promotions = reactive([]);

watchEffect(() => {
  promotions = props.promotions;
  //按照SequenceNumber排序
  if (promotions && promotions.length > 0) {
    promotions.sort((a, b) => {
      return a.sequenceNumber - b.sequenceNumber;
    });
  }
});
const baseUrl = "/api/v3/content/collections/images";

function getAirportName(airportCode) {
  let airportNameKey = "airport." + airportCode;
  return airportNameKey;
}

//价格展示

function twoFixed(value) {
  value = Math.round(parseFloat(value) * 100) / 100;
  let s = value.toString().split(".");
  // 只有整数
  if (s.length === 1) {
    value = value.toString() + ".00";
    return value;
  }
  // 有小数
  if (s.length > 1) {
    // 只有一位小数
    if (s[1].length < 2) {
      value = value.toString() + "0";
    }
    return value;
  }
}
</script>
<template>
  <section id="explore_area" class="section_padding_top"  v-if="promotions && promotions.length > 0">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t("explore-our-hot-deals") }}</h2>
          </div>
        </div>
      </div>

      <!-- 数据展示-->
      <div class="row">
        <div class="col-lg-12">
          <div class="tab-content" id="nav-tabContent">
            <!-- Hotel-->
            <div
              class="tab-pane fade show active"
              id="nav-hotels"
              role="tabpanel"
              aria-labelledby="nav-hotels-tab"
            >
              <div class="row">
                <div
                  class="col-lg-3 col-md-6 col-sm-6 col-12"
                  v-for="(promotion, promotionIndex) in promotions"
                  :key="promotionIndex"
                >
                  <div class="theme_common_box_two img_hover">
                    <div class="theme_two_box_img">
                      <a
                        :herf="promotion.links"
                        v-if="promotion && promotion.image"
                      >
                        <img :src="baseUrl + promotion.image.url" alt="img" />
                      </a>
                      <p>
                        <i class="fas fa-map-marker-alt"></i
                        >{{ promotion.address }}
                      </p>
                    </div>
                    <div class="theme_two_box_content">
                      <h4>
                        <a :herf="promotion.links">{{ promotion.name }}</a>
                      </h4>
                      <p v-if="showUserRating">
                        <span class="review_rating"
                          >{{ promotion.userRating }}/{{
                            promotion.totalRating
                          }}
                          {{ $t("excellent") }}</span
                        >
                        <!--                        <span class="review_count">(1214 reviewes)</span>-->
                      </p>
                      <h3>
                        {{ $t("price-starts-from") }}
                        {{ promotion.currency }}
                        {{ promotion.amount }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

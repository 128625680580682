import { defineStore } from "pinia";
import flightPrimeBookingApi from "@/apis/v2/flight/flightPrimeBookingApi";
import flightRepriceTools from "@/tools/v2/flight/price/flightRepriceTools";
import tripAirItemTools from "@/tools/v2/trip/tripAirItemTools";
import flightReshopTools from "@/tools/v2/flight/reshop/flightReshopTools";
import { FlightReshopItemVO } from "@/viewobject/sales/flight/reshop/flightReshopItemVO";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";

export const useFlightRepriceStore = defineStore("flightReprice", {
  state: () => ({
    flightRepriceItem: null,
    priceClasses: null,
  }),
  actions: {
    airReprice(flightRepriceFormModel) {
      const airRepriceRQ = flightRepriceTools.buildAirRepriceRQ(
        flightRepriceFormModel
      );
      const thisRef = this;
      return flightPrimeBookingApi
        .verifyPrice(airRepriceRQ)
        .then((res) => {
          const airPriceRS = res.data;
          if (
            airPriceRS &&
            airPriceRS.pricedItineraryList &&
            airPriceRS.pricedItineraryList.length > 0
          ) {
            const tripAirItem = tripAirItemTools.buildTripAirItem(
              airPriceRS.pricedItineraryList[0]
            );

            let reshopAddItemVOs = null;
            if (
              airPriceRS.pricedItineraryList[0].addItemList &&
              airPriceRS.pricedItineraryList[0].addItemList.length > 0
            ) {
              reshopAddItemVOs = [];
              const addItemList = airPriceRS.pricedItineraryList[0].addItemList;
              addItemList.forEach((additem) => {
                const reshopAddItemVO =
                  flightReshopTools.buildFlightReshopItem(additem);
                reshopAddItemVOs.push(reshopAddItemVO);
              });
            }

            thisRef.flightRepriceItem = new FlightReshopItemVO(
              tripAirItem,
              reshopAddItemVOs
            );

            thisRef.priceClasses = flightCommonTools.buildPriceClasses(
              airPriceRS.pricedItineraryList[0].offer
            );
          } else {
            thisRef.flightRepriceItem = null;
            thisRef.priceClasses = null;
          }
          return airPriceRS;
        })
        .catch((error) => {
          //console.info(error);
          thisRef.flightRepriceItem = null;
          thisRef.priceClasses = null;
          return error;
        });
    },
  },
});

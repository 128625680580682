<script setup>
import { useActivityStore } from "@/stores/v2/activity/activity";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { ref, watch } from "vue";
import ActivityPhotosGroup from "@/views/v2/sales/activity/items/goup/ActivityPhotosGroup.vue";
import ActivityDescriptionTabsGroup from "@/views/v2/sales/activity/items/goup/ActivityDescriptionTabsGroup.vue";
import { useCmsContentStore } from "@/stores/cms";

const activityStore = useActivityStore();
const route = useRoute();
const router = useRouter();
const templateStore = useTemplateStore();

//region 解析query参数 ，初始化请求
if (route.query != null && Object.keys(route.query).length > 0) {
  let childAgesFormat = route.query.childAgesFormat;
  let childAges = parseChildAges(childAgesFormat);
  let startDate = route.query.startDate;
  let endDate = route.query.endDate;
  let adultNum = route.query.adultNum;
  let childNum = route.query.childNum;
  let seniorNum = route.query.seniorNum;
  let activityId = route.query.activityId;
  let detailsRequestBody = {
    startDate: startDate,
    endDate: endDate,
    childAges: childAges,
    adultNum: adultNum,
    childNum: childNum,
    seniorNum: seniorNum,
    activityId: activityId,
  };
  initialDetails(detailsRequestBody);
}
//endregion

//region 数据初始化

//endregion

async function initialDetails(detailsRequestBody) {
  templateStore.pageLoader({ mode: "on" });
  let res = await activityStore.activityDetails(detailsRequestBody);
  templateStore.pageLoader({ mode: "off" });
}

//region 辅助函数
//列表页解析房间住客数量信息
function parseChildAges(formats) {
  let splits = formats.split("-");
  let childAges = [];

  if (splits && splits.length > 0) {
    splits.forEach((childAgeStr) => {
      if (parseInt(childAgeStr)) {
        childAges.push({ age: parseInt(childAgeStr) });
      }
    });
  }
  return childAges;
}

function goBack() {
  let query = route.query;
  query.activityId = null;
  router.push({ name: "sales-activity-list-v2", query: query });
}

//region 设备相关

const equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
//endregion

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.ActivityBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.ActivityBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>Activity Details</h2>
        <ul>
          <li
            class="pre-page"
            v-if="route.query && route.query.isFromHome"
            @click="router.push({ name: 'home' })"
          >
            {{ $t("home") }}
          </li>
          <li
            v-else
            class="pre-page"
            @click="router.push({ name: 'sales-activity-home-v2' })"
          >
            Tour Activity
          </li>
          <li class="pre-page">
            <i class="fas fa-circle"></i>
            Activity List
          </li>
          <li>
            <i class="fas fa-circle"></i>
            Activity Details
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_details_main" class="section_padding">
    <div class="container hotel_container" style="margin-left: 350px">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goBack">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-10"
          :class="{ 'col-lg-12': equipmentType === 'ipad' }"
        >
          <div class="tour_details_leftside_wrapper">
            <div
              class="tour_details_img_wrapper"
              v-if="
                activityStore.activityDetailsView &&
                activityStore.activityDetailsView.imageItems &&
                activityStore.activityDetailsView.imageItems.length > 0
              "
            >
              <ActivityPhotosGroup
                :image-list="activityStore.activityDetailsView.imageItems"
              />
            </div>

            <ActivityDescriptionTabsGroup
              v-if="activityStore.activityDetailsView"
              :equipment-type="equipmentType"
              :activity-details="activityStore.activityDetailsView"
              @initialDetails="initialDetails"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

<script setup>
import BaseFlightSearchForm from "@/views/sales/flight/items/form/BaseFlightSearchForm.vue";
import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import BaseFlightFilterForm from "@/views/sales/flight/items/form/BaseFlightFilterForm.vue";
import BaseFlightItemPanel from "@/views/sales/flight/items/panel/BaseFlightItemPanel.vue";
import data from "@/data";
import Banner from "@/components/custom/Banner.vue";
import { useFlightStore } from "@/stores/flight";
import { useTemplateStore } from "@/stores/template";

const templateStore = useTemplateStore();

import { useRoute, useRouter } from "vue-router";
import flightTools from "@/tools/flightTools";
import BaseFlightSortForm from "@/views/sales/flight/items/form/BaseFlightSortForm.vue";
import { useCmsContentStore } from "@/stores/cms";
import dayjs from "dayjs";
import { Empty, Spin, Drawer } from "ant-design-vue";
import BaseFlightItemMobilePanel from "@/views/sales/flight/items/panel/BaseFlightItemMobilePanel.vue";
const AEmpty = Empty;
const ADrawer = Drawer;
const ASpin = Spin;
const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";
//ant design
let filterOpen = ref(false);
const showFilterDrawer = () => {
  filterOpen.value = true;
};
const closeFilterDrawer = () => {
  filterOpen.value = false;
};
let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrl.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.flightBackgroundImage
      ? bannerContentStore.productPageBackgroundImage.flightBackgroundImage.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});

const route = useRoute();
let router = useRouter();
const navigation = reactive({
  title: "Flight Search Result",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Flight Search Result",
    },
  ],
});

//region 初始化
const flightStore = useFlightStore();

let flightViewItemsShow = ref({});
let flightViewItemsShowLength = ref(0);
let offerViewItemMap = reactive(null);
let anyLength = ref(0);
//监听引用类型的所有属性
let activeGroupNumber = ref(0); //默认第一程
let formAndTo = reactive(null);
let origDestGroupMapRef = ref();

//package end
watchEffect(() => {
  if (flightStore.origDestGroupMap) {
    origDestGroupMapRef.value = flightStore.origDestGroupMap;
    let from = getAirportCityName(
      flightStore.origDestGroupMap[activeGroupNumber.value].originLocation
    );
    let to = getAirportCityName(
      flightStore.origDestGroupMap[activeGroupNumber.value].destinationLocation
    );
    formAndTo = reactive({
      from: from,
      to: to,
    });
  } else {
    formAndTo = reactive(null);
  }
});

watch(
  () => flightStore.matchFlightViewItemsGroup,
  (newValue) => {
    anyLength = ref(Object.keys(newValue).length);
    if (newValue) {
      flightViewItemsShow.value = null;
      templateStore.pageLoader({ mode: "on" });
      setTimeout(() => {
        flightViewItemsShow.value = JSON.parse(JSON.stringify(newValue));
        //統一迭代邏輯
        flightViewItemsShow.value[Symbol.iterator] = function* () {
          yield* [...Object.values(this)].sort((a, b) => {
            return a[0].score == b[0].score
              ? b[0].totalPrice - a[0].totalPrice
              : b[0].score - a[0].score;
          });
        };

        flightViewItemsShowLength.value = Object.keys(
          flightViewItemsShow.value
        ).length;

        templateStore.pageLoader({ mode: "off" });
      }, 10);
    } else {
      flightViewItemsShow.value = null;
    }
  }
);
watchEffect(() => {
  if (flightStore.offerViewItemMap) {
    offerViewItemMap = flightStore.offerViewItemMap;
  } else {
    offerViewItemMap = null;
  }
});

let showMaxIndex = ref(6);
let isShowMore = ref(true);

function resetShowMore() {
  showMaxIndex.value = 6;
  isShowMore.value = true;
}
function loadMoreItems() {
  showMaxIndex.value += 6;
  if (showMaxIndex.value >= flightViewItemsShowLength.value) {
    isShowMore.value = false;
  }
}

let value = reactive([0, 75]);
let preEquateKeys = ref([]);
let items = reactive([]);

onMounted(() => {
  items.push(...data.flightData);
});

//城市名
function getAirportCityName(code) {
  if (!flightStore.airportCityMap[code]) return code;
  //可以通过语言获取不同类型的名字
  return flightStore.airportCityMap[code].cityName;
}

async function selectNext(equateKey, airItineraryRPH) {
  //重置筛选条件
  filterOptionRef = reactive({
    priceRange: [0, 10000],
    stopNumber: null,
    airlineCode: "",
    cabinClass: "",
  });

  // (activeGroupNumber.value + " trip");
  // 如果已选择的程数等于总程数，则选择已完成
  // (airItineraryRPH);
  // (flightStore.airlowfareSearchRS.pricedItineraryList);
  if (
    Object.keys(flightStore.origDestGroupMap).length ===
    activeGroupNumber.value + 1
  ) {
    let specifiedPricedItinerary = flightTools.getSpecifiedPricedItinerary(
      airItineraryRPH,
      flightStore.airlowfareSearchRS.pricedItineraryList
    );
    templateStore.pageLoader({ mode: "on" });
    flightStore
      .verifyPrice(specifiedPricedItinerary)
      .then((res) => {
        templateStore.pageLoader({ mode: "off" });
        if (flightStore.pricedFlightViewItemsGroup) {
          sessionStorage.setItem(
            "pricedFlightViewItemsGroup",
            JSON.stringify(flightStore.pricedFlightViewItemsGroup)
          );
          sessionStorage.setItem("quoteID", flightStore.quoteID);
          sessionStorage.setItem(
            "airportCityMap",
            JSON.stringify(flightStore.airportCityMap)
          );
          sessionStorage.setItem(
            "offerViewItemMap",
            JSON.stringify(flightStore.offerViewItemMap)
          );
          sessionStorage.setItem(
            "flightPassengersFormModel",
            JSON.stringify(flightStore.flightPassengersFormModel)
          );
          sessionStorage.setItem(
            "passengerTicketFareBreakdownItems",
            JSON.stringify(flightStore.passengerTicketFareBreakdownItems)
          );

          router.push({ name: "sales-flight-itinerary-review" });
        }
      })
      .catch((err) => {
        router.push({
          name: "error-500",
          query: { message: "flight unavailable" },
        });
      });
  } else {
    // sortOptionRef = ref("");
    // sortAndFilter(sortOptionRef.value, filterOptionRef);
    let equateKeyCombine = "";
    // (preEquateKeys.value);
    // ("Next");
    // templateStore.pageLoader({ mode: "on" });
    for (let i = 0; i < activeGroupNumber.value; i++) {
      equateKeyCombine += preEquateKeys.value[i];
    }
    equateKeyCombine += equateKey;
    preEquateKeys.value[activeGroupNumber.value] = equateKey;
    // (equateKeyCombine);
    let nextActiveGroupNumber = activeGroupNumber.value + 1;
    flightStore.changeMatchFlightViewItemsGroup(
      nextActiveGroupNumber,
      equateKeyCombine
    );
    activeGroupNumber.value = nextActiveGroupNumber;
    // setTimeout(() => {
    //   templateStore.pageLoader({ mode: "off" });
    // }, 2000);
  }
}

function sortAndFilter(sortingOption, filterOption) {
  flightViewItemsShow.value = null;
  templateStore.pageLoader({ mode: "on" });
  setTimeout(() => {
    if (sortingOption) {
      sortOptionRef.value = sortingOption;
    }
    if (filterOption) {
      filterOptionRef = filterOption;
    }

    if (filterOptionRef) {
      filterByOptions(filterOptionRef);
    }

    if (sortOptionRef.value) {
      sortByOptions(sortOptionRef.value);
    }
    // ("After sort ", Object.keys(flightViewItemsShow.value).length);
    resetShowMore();
    templateStore.pageLoader({ mode: "off" });
  }, 1000);
}

//region 过滤逻辑
const filterByOptionsOptions = computed(() => {
  let flightViewItemsGroup = flightStore.matchFlightViewItemsGroup
    ? flightStore.matchFlightViewItemsGroup
    : {};
  let flightOptions = {
    stopsFilterOptions: [],
    cabinClassFliterOptions: [],
    airlineFilterOptions: [],
  };
  let stopsArr = [];
  let cabinClassArr = [];
  let airlineArr = [];
  let stopTimes = {};
  let cabinClassTimes = {};
  let airlineTimes = {};
  let cabinType = [];
  let airlineType = [];
  if (flightViewItemsGroup) {
    for (let key in flightViewItemsGroup) {
      //所有的stops
      stopsArr.push(flightViewItemsGroup[key][0].segments.length);
      stopTimes = countTimes(stopsArr); //{"1":8,"2":25}

      //所有的cabinClass--只要有一个满足就算
      //1，先看有多少种类型
      flightViewItemsGroup[key][0].segments.forEach((item) => {
        cabinType.push(item.cabinClass);
      });
      cabinType = Array.from(new Set(cabinType));

      //2.计算每个类型有多少个航程
      //如果有一个航段中包含这个类型，类型代表的数量+1
      cabinType.forEach((cabin) => {
        let res = flightViewItemsGroup[key][0].segments.some((cabinItem) => {
          return cabinItem.cabinClass == cabin;
        });
        if (res) {
          //当前遍历的类型在该航程存在
          cabinClassArr.push(cabin);
        }
      });
      cabinClassTimes = countTimes(cabinClassArr);

      flightViewItemsGroup[key][0].segments.forEach((item) => {
        airlineType.push(item.marketingAirlineCode);
      });

      airlineType = Array.from(new Set(airlineType));

      //2.计算每个类型有多少个航程
      //如果有一个航段中包含这个类型，类型代表的数量+1
      airlineType.forEach((airline) => {
        let res = flightViewItemsGroup[key][0].segments.some((airlineItem) => {
          return airlineItem.marketingAirlineCode == airline;
        });
        if (res) {
          //当前遍历的类型在该航程存在
          airlineArr.push(airline);
        }
      });
      airlineTimes = countTimes(airlineArr);

      // flightViewItemsGroup[key][0].segments.forEach((item) => {
      //   airlineArr.push(item.marketingAirlineCode);
      // });
    }

    getFlightFilterOptions(stopTimes, flightOptions.stopsFilterOptions, "stop");
    getFlightFilterOptions(
      cabinClassTimes,
      flightOptions.cabinClassFliterOptions,
      "cabinClass"
    );
    getFlightFilterOptions(
      airlineTimes,
      flightOptions.airlineFilterOptions,
      "airline"
    );
  }
  return flightOptions;
});

//组合flightFilterOptions的每一项
function getFlightFilterOptions(times, option, type) {
  let item = {};
  let itemDefault = {};
  if (type == "stop") {
    itemDefault.stopNumber = "Any";
    itemDefault.quality = anyLength.value;
  } else if (type == "cabinClass") {
    itemDefault.cabinClass = "Any";
    itemDefault.quality = anyLength.value;
  } else {
    itemDefault.airlineCode = "anyAirline";
    itemDefault.quality = anyLength.value;
  }

  option.push(itemDefault);
  for (let key in times) {
    if (type == "stop") {
      item.stopNumber = key;
      item.quality = times[key];
    } else if (type == "cabinClass") {
      item.cabinClass = key;
      item.quality = times[key];
    } else {
      item.airlineCode = key;
      item.quality = times[key];
    }
    let itemCopy = JSON.parse(JSON.stringify(item));
    option.push(itemCopy);
  }
  return option;
}

//计算数组中元素出现的次数
function countTimes(data) {
  var obj = {};
  return data.reduce(function (time, name) {
    if (name in time) {
      time[name]++;
    } else {
      time[name] = 1;
    }
    return time;
  }, {});
}

//过滤机票
let filterOptionRef = reactive({
  priceRange: [0, 10000],
  stopNumber: [],
  airlineCode: [],
  cabinClass: [],
  departureTimeQuantum: [],
  arrivalTimeQuantum: [],
});

function checkTimeRange(compareStr, dateStr) {
  let dateDefaultPre = "2000-01-01 ";
  // let timeStrSplit = dateStr.split("-");
  // let minDateTimeStr = dateDefaultPre + timeStrSplit[0].replace("+", " ");
  // let maxDateTimeStr = dateDefaultPre + timeStrSplit[1].replace("+", " ");
  let minDate = dayjs(
    dateDefaultPre + dateStr.split("-")[0].replace("+", " "),
    "YYYY-MM-DD h:mm a"
  );
  let maxDate = dayjs(
    dateDefaultPre + dateStr.split("-")[1].replace("+", " "),
    "YYYY-MM-DD h:mm a"
  );
  // let compareFormat = dayjs(compareStr).format("h:mm a");
  let dateTimeCompare = dayjs(
    dateDefaultPre + " " + dayjs(compareStr).format("h:mm a")
  );
  let below = dateTimeCompare.diff(minDate, "minutes", false);
  let upper = dateTimeCompare.diff(maxDate, "minutes", false);
  if (below >= 0 && upper <= 0) {
    return true;
  } else {
    return false;
  }
}
function filterByOptions(filterOption) {
  let flightViewItemsShowTemp = null;

  if (flightStore.matchFlightViewItemsGroup) {
    flightViewItemsShowTemp = JSON.parse(
      JSON.stringify(flightStore.matchFlightViewItemsGroup)
    );
  }

  //根据价格区间筛选
  if (filterOption.priceRange && filterOption.priceRange.length > 0) {
    // //过滤
    for (const key in flightViewItemsShowTemp) {
      if (
        flightViewItemsShowTemp[key] &&
        flightViewItemsShowTemp[key].length > 0
      ) {
        if (filterOption.priceRange[1] == 10000) {
          //这个时候选择的价格是xx-10000+,只需要做最小值处理
          if (
            !(
              flightViewItemsShowTemp[key][0].totalPrice >=
              filterOption.priceRange[0]
            )
          ) {
            delete flightViewItemsShowTemp[key];
          }
        } else {
          if (
            !(
              flightViewItemsShowTemp[key][0].totalPrice >=
                filterOption.priceRange[0] &&
              flightViewItemsShowTemp[key][0].totalPrice <=
                filterOption.priceRange[1]
            )
          ) {
            delete flightViewItemsShowTemp[key];
          }
        }
      }
    }
    flightViewItemsShow.value = flightViewItemsShowTemp;
    //統一迭代邏輯
    flightViewItemsShow.value[Symbol.iterator] = function* () {
      yield* [...Object.values(this)].sort((a, b) => {
        return 0;
      });
    };
  }

  //根据departure Time筛选
  if (
    filterOption.departureTimeQuantum &&
    filterOption.departureTimeQuantum.length > 0
  ) {
    let departureDateTimeKeyMap = {};
    //1.第一次遍历先选出key
    for (const key in flightViewItemsShowTemp) {
      //出发时间
      let departureDateTime = flightViewItemsShowTemp[key][0].departureDateTime;
      filterOption.departureTimeQuantum.forEach((departureTimeQuantum) => {
        let res = checkTimeRange(departureDateTime, departureTimeQuantum);
        if (res) {
          departureDateTimeKeyMap[key] = "1";
        }
      });
    }
    //2.再次去遍历时删除不符合上面的key
    for (const key in flightViewItemsShowTemp) {
      //4.如果当前的key和上面的都不符合，就delete
      if (!departureDateTimeKeyMap[key]) {
        delete flightViewItemsShowTemp[key];
      }
    }
    flightViewItemsShow.value = flightViewItemsShowTemp;
    //統一迭代邏輯
    flightViewItemsShow.value[Symbol.iterator] = function* () {
      yield* [...Object.values(this)].sort((a, b) => {
        return 0;
      });
    };
  }

  //根据arrival Time筛选
  if (
    filterOption.arrivalTimeQuantum &&
    filterOption.arrivalTimeQuantum.length > 0
  ) {
    let arrivalDateTimeKeyMap = {};
    //1.第一次遍历先选出key
    for (const key in flightViewItemsShowTemp) {
      //到达时间
      let arrivalDateTime = flightViewItemsShowTemp[key][0].arrivalDateTime;
      filterOption.arrivalTimeQuantum.forEach((arrivalTimeQuantum) => {
        let res = checkTimeRange(arrivalDateTime, arrivalTimeQuantum);
        if (res) {
          arrivalDateTimeKeyMap[key] = "1";
        }
      });
    }
    //2.再次去遍历时删除不符合上面的key
    for (const key in flightViewItemsShowTemp) {
      //4.如果当前的key和上面的都不符合，就delete
      if (!arrivalDateTimeKeyMap[key]) {
        delete flightViewItemsShowTemp[key];
      }
    }
    flightViewItemsShow.value = flightViewItemsShowTemp;
    //統一迭代邏輯
    flightViewItemsShow.value[Symbol.iterator] = function* () {
      yield* [...Object.values(this)].sort((a, b) => {
        return 0;
      });
    };
  }

  //根据stopNumber筛选
  if (filterOption.stopNumber && filterOption.stopNumber.length > 0) {
    if (filterOption.stopNumber.indexOf("Any") > -1) {
      //如果选择了any 統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    } else {
      let stopKeyMap = {};
      //1.第一次遍历先选出key
      for (const key in flightViewItemsShowTemp) {
        filterOption.stopNumber.forEach((stopNum) => {
          let stopNumber = parseInt(stopNum);
          if (flightViewItemsShowTemp[key][0].segments.length == stopNumber) {
            //等于的key先保存一份
            stopKeyMap[key] = "1";
          }
        });
      }
      //2.再次去遍历时删除不符合上面的key
      for (const key in flightViewItemsShowTemp) {
        //4.如果当前的key和上面的都不符合，就delete
        if (!stopKeyMap[key]) {
          delete flightViewItemsShowTemp[key];
        }
      }
      flightViewItemsShow.value = flightViewItemsShowTemp;
      //統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    }
  }

  //根据座位筛选
  if (filterOption.cabinClass && filterOption.cabinClass.length > 0) {
    if (filterOption.cabinClass.indexOf("Any") > -1) {
      //如果选择了any 統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    } else {
      let cabinClassKeyMap = {};
      //1.第一次遍历先选出key
      for (const key in flightViewItemsShowTemp) {
        filterOption.cabinClass.forEach((cabinClass) => {
          if (flightViewItemsShowTemp[key][0].segments.length > 0) {
            let res = flightViewItemsShowTemp[key][0].segments.some(
              (item) => item.cabinClass == cabinClass
            );
            if (res) {
              //等于的key先保存一份
              cabinClassKeyMap[key] = "1";
            }
          }
        });
      }
      //2.再次去遍历时删除不符合上面的key
      for (const key in flightViewItemsShowTemp) {
        //4.如果当前的key和上面的都不符合，就delete
        if (!cabinClassKeyMap[key]) {
          delete flightViewItemsShowTemp[key];
        }
      }
      flightViewItemsShow.value = flightViewItemsShowTemp;
      //統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    }
  }

  //根据航空公司筛选
  if (filterOption.airlineCode && filterOption.airlineCode.length > 0) {
    if (filterOption.airlineCode.indexOf("anyAirline") > -1) {
      //如果选择了any 統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    } else {
      let airlineCodeKeyMap = {};
      //1.第一次遍历先选出key
      for (const key in flightViewItemsShowTemp) {
        filterOption.airlineCode.forEach((airlineCode) => {
          if (flightViewItemsShowTemp[key][0].segments.length > 0) {
            let res = flightViewItemsShowTemp[key][0].segments.some(
              (item) => item.marketingAirlineCode == airlineCode
            );
            if (res) {
              //等于的key先保存一份
              airlineCodeKeyMap[key] = "1";
            }
          }
        });
      }
      //2.再次去遍历时删除不符合上面的key
      for (const key in flightViewItemsShowTemp) {
        //4.如果当前的key和上面的都不符合，就delete
        if (!airlineCodeKeyMap[key]) {
          delete flightViewItemsShowTemp[key];
        }
      }
      flightViewItemsShow.value = flightViewItemsShowTemp;
      //統一迭代邏輯
      flightViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    }
  }

  flightViewItemsShowLength.value = Object.keys(
    flightViewItemsShow.value
  ).length;
}

//endregion 过滤逻辑

//region 排序逻辑
let sortOptionRef = ref("");
function sortByOptions(sortingOptionRef) {
  let sortingOptionObj = null;
  if (sortingOptionRef.value) {
    const splits = sortingOptionRef.value.split("-");
    let type = splits[0];
    let order = splits[1];
    sortingOptionObj = new flightTools.SortingOption(type, order);
    templateStore.pageLoader({ mode: "on" });
    let flightViewItemsShowCopy = JSON.parse(
      JSON.stringify(flightViewItemsShow.value)
    );
    sort(
      sortingOptionObj.type,
      sortingOptionObj.order,
      flightViewItemsShowCopy
    );
  }
}
function sort(type, order, flightViewItemsShowCopy) {
  // ("排序前",flightViewItemsShowCopy)
  if (flightViewItemsShowCopy) {
    // (2);
    flightViewItemsShow.value = flightViewItemsShowCopy;
    flightViewItemsShow.value[Symbol.iterator] = function* () {
      if (type === "PRICE" && order === "ASC") {
        //升序
        yield* [...Object.values(this)].sort((a, b) => {
          return a[0].totalPrice - b[0].totalPrice;
        });
      } else if (type === "PRICE" && order === "DSC") {
        //降序
        yield* [...Object.values(this)].sort((a, b) => {
          return b[0].totalPrice - a[0].totalPrice;
        });
      } else if (type === "" || order === "") {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      }
    };

    flightViewItemsShowLength.value = Object.keys(
      flightViewItemsShow.value
    ).length;
  }
}
//endregion 排序逻辑
let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);

//region 移动端加载

function handleScroll() {
  let distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;

  if (distance == 0) {
    setTimeout(() => {
      loadMoreItems();
    }, 500);
  }
}
onMounted(() => {
  if (equipmentType.value == "iphone") {
    //是手机端的采用滚动
    window.addEventListener("scroll", handleScroll);
  }
});
//endregion
</script>

<template>
  <!-- Common Banner Area -->
  <Banner :navigate="navigation" :background-image-url="backgroundImageUrl" />
  <!-- Form Area -->
  <section class="fligth_top_search_main_form_wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <BaseFlightSearchForm
              @reset-show="resetShowMore"
              :equipment-type="equipmentType"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Flight Search Areas -->

  <section id="explore_area" class="section_padding">
    <div class="container">
      <!-- Section Heading -->
      <div class="row" v-if="equipmentType == 'iphone'">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mg-t-10">
          <div class="filter-sort">
            <div class="filtrate_btn h5" @click="showFilterDrawer">
              {{ $t("filtrate") }}<i class="fa fa-fw fa-angle-down"></i>
            </div>
            <div class="sort_btn">
              <BaseFlightSortForm
                @sort="
                  (sortOption) => sortAndFilter(sortOption, filterOptionRef)
                "
              />
            </div>
          </div>
          <!--  sort部分 -->
          <!--  filter部分 -->
          <a-drawer
            title="Filter Option"
            placement="bottom"
            :open="filterOpen"
            @close="closeFilterDrawer"
          >
            <BaseFlightFilterForm
              @filter="
                (filterOption) => sortAndFilter(sortOptionRef, filterOption)
              "
              :filter-option-props="filterOptionRef"
              :filter-flight-options="filterByOptionsOptions"
              :from-and-to="formAndTo"
            />
          </a-drawer>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div v-if="flightViewItemsShowLength" class="section_heading_center">
            <h2>
              {{
                $t("num-flights-found", {
                  flightViewItemsShowLength: flightViewItemsShowLength,
                })
              }}
            </h2>
          </div>
          <div v-else class="section_heading_center">
            <h2>
              {{ $t("num-flights-found", { flightViewItemsShowLength: 0 }) }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- 过滤-->
        <div class="col-lg-4" v-if="equipmentType != 'iphone'">
          <div class="left_side_search_area">
            <BaseFlightSortForm
              @sort="(sortOption) => sortAndFilter(sortOption, filterOptionRef)"
            />
            <BaseFlightFilterForm
              @filter="
                (filterOption) => sortAndFilter(sortOptionRef, filterOption)
              "
              :filter-option-props="filterOptionRef"
              :filter-flight-options="filterByOptionsOptions"
              :from-and-to="formAndTo"
            />
          </div>
        </div>

        <div class="col-lg-8">
          <div class="row" v-if="origDestGroupMapRef">
            <div class="col-lg-12 flight_header">
              <a
                v-for="(item, key, index) in origDestGroupMapRef"
                :href="key == activeGroupNumber ? 'javascript:void(0);' : null"
                style="margin-left: 5px"
              >
                <span>
                  {{ $t("from") }}
                  {{ getAirportCityName(item.originLocation) }} to
                  {{ getAirportCityName(item.destinationLocation) }}
                </span>
                <i
                  class="fa fa-arrow-circle-right"
                  v-if="index < Object.keys(origDestGroupMapRef).length - 1"
                  style="margin-left: 5px; color: #4ecca6"
                ></i
              ></a>
            </div>
          </div>
          <div class="row mg-t-10">
            <div class="col-lg-12">
              <div
                class="flight_search_result_wrapper"
                id="baseFlightItemPanel"
                v-if="flightViewItemsShow"
              >
                <div
                  v-for="(flightInfos, flightIndex) in flightViewItemsShow"
                  :key="flightIndex"
                  class="flight_search_item_wrappper"
                >
                  <BaseFlightItemPanel
                    v-if="
                      showMaxIndex > flightIndex && equipmentType != 'iphone'
                    "
                    :flight-info-index="flightIndex"
                    :flight-infos="flightInfos"
                    :offer-view-item-map="offerViewItemMap"
                    @select="selectNext"
                  />
                  <BaseFlightItemMobilePanel
                    v-else-if="
                      showMaxIndex > flightIndex && equipmentType == 'iphone'
                    "
                    :flight-info-index="flightIndex"
                    :flight-infos="flightInfos"
                    :offer-view-item-map="offerViewItemMap"
                    @select="selectNext"
                  />
                </div>
              </div>

              <div class="flight_search_result_wrapper" v-else>
                <AEmpty></AEmpty>
              </div>

              <div
                class="load_more_flight"
                v-if="
                  flightViewItemsShowLength > 6 &&
                  isShowMore &&
                  equipmentType != 'iphone'
                "
              >
                <button class="btn btn_md" @click="loadMoreItems()">
                  <i class="fas fa-spinner"></i> {{ $t("load-more") }}..
                </button>
              </div>
              <div
                class="text-center"
                v-if="
                  flightViewItemsShowLength > 6 &&
                  isShowMore &&
                  equipmentType == 'iphone'
                "
              >
                <Spin></Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

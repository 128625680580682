function buildAirRepriceRQ(flightRepriceFormModel) {
  const requestType = flightRepriceFormModel.requestType;
  const recordLocator = flightRepriceFormModel.recordLocator;
  const idType = flightRepriceFormModel.idType;
  const idContext = flightRepriceFormModel.idContext;
  const airItineraryRPH = flightRepriceFormModel.airItineraryRPH;
  const passengers = flightRepriceFormModel.passengers;
  const flightSegments = flightRepriceFormModel.flightSegments;
  const paymentDetails = flightRepriceFormModel.paymentDetails;
  const servicesSelected = flightRepriceFormModel.servicesSelected;

  let offerPriced = [];

  if (servicesSelected && servicesSelected.length > 0) {
    for (const serviceSelected of servicesSelected) {
      const quantity = serviceSelected.quantity;
      const seatRowNumber = serviceSelected.seatRowNumber;
      const seatColumnNumber = serviceSelected.seatColumnNumber;
      const flightSegmentRPH = serviceSelected.flightSegmentRPH;
      const travelerRPH = serviceSelected.travelerRPH;
      const ancillaryId = serviceSelected.ancillaryId;
      let pricing = null;
      if (quantity) {
        pricing = {
          offerQty: quantity,
        };
      }

      let seatInfo = null;
      if (seatColumnNumber && seatRowNumber) {
        seatInfo = {
          columnNumber: seatColumnNumber,
          rowNumber: seatRowNumber,
        };
      }

      offerPriced.push({
        id: ancillaryId,
        travelerRPH: travelerRPH,
        flightSegmentRPH: flightSegmentRPH,
        pricing: pricing,
        seatInfo: seatInfo,
      });
    }
  }

  let travelerInfoSummary = null;
  let flightReferences = null;
  let airItinerary = null;
  if (passengers && passengers.length > 0) {
    const airTravelerAvailList = [];
    for (const passenger of passengers) {
      airTravelerAvailList.push({
        airTraveler: {
          travelerRefNumber: {
            rph: passenger.travelerRefNumber,
          },
          passengerTypeCode: passenger.passengerTypeCode,
        },
      });
    }

    travelerInfoSummary = {
      airTravelerAvailList: airTravelerAvailList,
      priceRequestInformation: {
        validatingAirlineCode: idContext,
      },
      pricingPrefList: [
        {
          type: "ALL",
        },
      ],
    };
  }

  if (flightSegments && flightSegments.length > 0) {
    flightReferences = [];
    for (const flightSegment of flightSegments) {
      flightReferences.push({
        flightRefNumber: flightSegment.rph,
      });
    }
  }

  let fulfillment = null;
  if (paymentDetails && paymentDetails.paymentType === "CASH") {
    fulfillment = {
      paymentDetailList: [
        {
          paymentType: "CASH",
        },
      ],
    };
  } else if (paymentDetails && paymentDetails.paymentType === "CREDITCARD") {
    const cardCode = paymentDetails.cardCode;
    const cardNumber = paymentDetails.cardNumber;

    fulfillment = {
      paymentDetailList: [
        {
          paymentType: "CREDITCARD",
          paymentCard: {
            cardCode: cardCode,
            cardNumber: cardNumber,
          },
        },
      ],
    };
  }

  if (airItineraryRPH) {
    airItinerary = {
      airItineraryRPH: airItineraryRPH,
    };
  }

  return {
    type: requestType,
    bookingReferenceID: {
      type: idType,
      id: recordLocator,
      id_context: idContext,
    },
    travelerInfoSummary: travelerInfoSummary,
    flightReferences: flightReferences,
    fulfillment: fulfillment,
    airItinerary: airItinerary,
    offer: {
      priced: offerPriced,
    },
  };
}

export default {
  buildAirRepriceRQ,
};

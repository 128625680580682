import { HotelAmenityVO } from "@/viewobject/sales/hotel/availability/hotelAmenityVO";
import { HotelPhotoVO } from "@/viewobject/sales/hotel/availability/hotelPhotoVO";
import { HotelPolicyVO } from "@/viewobject/sales/hotel/availability/hotelPolicyVO";
import { HotelRoomTypeVO } from "@/viewobject/sales/hotel/availability/hotelRoomTypeVO";

export class HotelItemVO {
  /**
   *
   * @param mainImage string，主图片地址
   * @param hotelName string，酒店名
   * @param cityName string，城市名
   * @param starRating number，酒店星级
   * @param userRating number，用户评分
   * @param address string，酒店地址
   * @param currencyCode string，货币单位
   * @param bestAvgUnitPrice number，最优平均房单价(包含税费)，即每间每日
   * @param bestTotalPrice number，酒店内房间最低总价(包含税费)
   * @param promotionDescription string，优惠信息
   * @param longitude number，经度
   * @param latitude number，纬度
   * @param ttiCode number，IATA系统中酒店唯一代码
   * @param description string，酒店描述
   * @param roomTypes Array，HotelRoomTypeVO对象数组，酒店内符合搜索条件的房型数组
   * @param photos Array，HotelPhotoVO对象数组，酒店的图片
   * @param amenities Array，酒店内设施HotelAmenityVO类型数组
   * @param policies Array，HotelPolicyVO对象数组，策略数组
   * @param supplierCodes Array，string数组
   * @param propertyNames Array 酒店的不同语言名称
   */
  constructor(
    mainImage,
    hotelName,
    cityName,
    starRating,
    userRating,
    address,
    currencyCode,
    bestAvgUnitPrice,
    bestTotalPrice,
    promotionDescription,
    longitude,
    latitude,
    ttiCode,
    description,
    roomTypes,
    photos,
    amenities,
    policies,
    supplierCodes,
    propertyNames
  ) {
    this.mainImage = mainImage;
    this.hotelName = hotelName;
    this.cityName = cityName;
    this.starRating = starRating;
    this.userRating = userRating;
    this.address = address;
    this.currencyCode = currencyCode;
    this.bestAvgUnitPrice = bestAvgUnitPrice;
    this.bestTotalPrice = bestTotalPrice;
    this.promotionDescription = promotionDescription;
    this.longitude = longitude;
    this.latitude = latitude;
    this.ttiCode = ttiCode;
    this.supplierCodes = supplierCodes;
    this.description = description;
    this.roomTypes = roomTypes;
    this.photos = photos;
    this.amenities = amenities;
    this.policies = policies;
    this.propertyNames = propertyNames;
  }

  addSupplierCode(supplierCode) {
    if (this.supplierCodes == null) this.supplierCodes = [];
    if (this.supplierCodes.indexOf(supplierCode) >= 0) return;

    this.supplierCodes.push(supplierCode);
  }

  addNewAmenity(codeDetail, type) {
    let hotelAmenityVO = new HotelAmenityVO(codeDetail, type);
    if (this.amenities == null) this.amenities = [];
    this.amenities.push(hotelAmenityVO);
  }

  addNewPhoto(type, url, caption) {
    let hotelPhotoVO = new HotelPhotoVO(type, url, caption);
    if (this.photos == null) this.photos = [];
    this.photos.push(hotelPhotoVO);
  }

  addNewPolicy(code, name, contents) {
    let hotelPolicyVO = new HotelPolicyVO(code, name, contents);
    if (this.policies == null) this.policies = [];
    this.policies.push(hotelPolicyVO);
    return hotelPolicyVO;
  }

  addNewRoomType(
    roomTypeName,
    roomTypeCode,
    ttiCode,
    roomDescription,
    minOccupancy,
    maxOccupancy,
    amenities,
    rooms
  ) {
    const roomTypeVO = new HotelRoomTypeVO(
      roomTypeName,
      roomTypeCode,
      ttiCode,
      roomDescription,
      minOccupancy,
      maxOccupancy,
      amenities,
      rooms
    );
    if (this.roomTypes == null) this.roomTypes = [];
    this.roomTypes.push(roomTypeVO);

    return roomTypeVO;
  }

  addRoomType(roomTypeVO) {
    if (this.roomTypes == null) this.roomTypes = [];
    this.roomTypes.push(roomTypeVO);
    return roomTypeVO;
  }
}

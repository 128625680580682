const messages = {
    en:{
        '722': '722',
        '31Y': '31Y',
        '721': '721',
        '31X': '31X',
        '720': 'Boeing 727-200 Advanced pax',
        'F70': 'Fokker 70',
        'SHB': 'SHB',
        '7JB': 'Boeing 747',
        '321': 'Airbus A321',
        'TAT': 'AUTO TRAIN',
        '320': 'Airbus A320',
        'DC9': 'McDonnell Douglas DC-9',
        'B72': 'Boeing 720',
        'DC8': 'McDonnell Douglas DC-8',
        'PAZ': 'PIPER AZTEC',
        'DC6': 'DC6',
        'MU2': 'MU2',
        'DC4': 'McDonnell Douglas DC-4',
        'SH6': 'SH6',
        'DC3': 'DC3',
        'PAT': 'PAT',
        'DC1': 'DC1',
        '31F': '31F',
        'SH3': 'SH3',
        'PAS': 'PIPER SENECA',
        'MTL': 'METROLINER',
        'PAN': 'PAN',
        '717': 'Boeing 717-200',
        'PAH': 'PIPER CHIEFTAIN',
        'BNT': 'BNT',
        'PAG': 'PAG',
        '319': 'Airbus A319',
        '318': 'Airbus A310',
        '70M': 'Boeing 707 Combi',
        'Jet': 'Pure Jet',
        'PAC': 'PIPER CHEROKEE',
        'CHG': 'CHG',
        '313': 'Airbus A310',
        '312': 'Airbus A310',
        'ATT': 'ATT',
        '310': 'Airbus A310',
        '70F': '70F',
        'ATR': 'ATR',
        'BNI': 'BNI',
        'ATP': 'British Aerospace ATP',
        'SFW': 'SAAB 340',
        'PA6': 'PIPER AERO STAR 601',
        'YS1': 'YS1',
        'J4U': 'J4U',
        'PA2': 'PA2',
        'DAM': 'DASSAULT BREGUET MERCURE',
        'PA1': 'PIPER T-1040',
        'ATD': 'ATD',
        '707': 'Boeing 707/720',
        '703': '703',
        'F50': 'F50',
        'SFA': 'SFA',
        'AT7': 'AT7',
        'AT6': 'ATR TURBOPROP',
        'AT5': 'ATR TURBOPROP',
        'J4A': 'J4A',
        'AT4': 'AT4',
        'SF3': 'SF3',
        'I9Y': 'I9Y',
        'J41': 'J41',
        'I9X': 'I9X',
        'I9M': 'I9M',
        'DY4': 'DY4',
        'ART': 'ART',
        'ERJ': 'EMBRAER',
        'DY1': 'DY1',
        'I9F': 'I9F',
        'MR4': 'MR4',
        'ERD': 'EMBRAER RJ140',
        'ARJ': 'British Aerospace RJ7',
        'M95': 'Douglas MD-95',
        'J32': 'JETSTREAM 32',
        'M90': 'McDonnell Douglas MD-90',
        'J31': 'J31',
        'EQV': 'Equipment Varies',
        'I93': 'I93',
        'ER4': 'ERJ-145',
        'ER3': 'ERJ-135',
        'AR9': 'AR95',
        'AR8': 'Avro RJ85 Avroliner',
        'AR7': 'Avro RJ70 Avroliner',
        'E95': 'E95',
        'E90': 'EMBRAER EMB E90',
        'AR1': 'Avro RJ100 Avroliner',
        'RJA': 'RJA',
        '23S': 'Airbus A318',
        'M88': 'McDonnell Douglas MD-88',
        'M87': 'McDonnell Douglas MD-87',
        'FK7': 'FK7',
        'M83': 'McDonnell Douglas MD-83',
        'M82': 'McDonnell Douglas MD-82',
        'M81': 'McDonnell Douglas MD-81',
        'M80': 'McDonnell Douglas MD-80',
        'F28': 'Fokker 28',
        'F27': 'F27',
        'F24': 'FOKKER F28-4000 FELLOWSHIP',
        'F23': 'F23',
        'F22': 'F22',
        'TUS': 'Tupolev TU-144',
        'F21': 'F21',
        'CCX': 'CCX',
        'APH': 'APH',
        'LV2': 'LV2',
        'CD2': 'CD2',
        'TU5': 'Tupolev TU154',
        'CCJ': 'Canadair Challenger',
        'TU3': 'Tupolev TU-134',
        'F10': 'F10',
        'TU2': 'Tupolev TU-124',
        'E75': 'EMBRAER EMB 175',
        'B15': 'BAC 111-500',
        'B14': 'British Aerospace',
        'B13': 'B13',
        'B12': 'B12',
        'E70': 'EMBRAER',
        'B11': 'British Aerospace One-Eleven',
        'YN7': 'XIAN YUNSHUJI',
        'MO2': 'MOONEY MARK 20A',
        'YN5': 'YN5',
        'YN2': 'YN2',
        'VGP': 'BRITISH AEROSPACE VANGUARD',
        'HU5': 'HUGHES 500',
        'TSL': 'SLEEPER TRAIN',
        '7C4': 'Boeing 747',
        'SA2': 'SA2',
        '7C3': 'Boeing 767',
        'ANF': 'ANF',
        'RFS': 'RFS',
        'CAN': 'CESSNA ALL SERIES',
        'A5F': 'A5F',
        'TRS': 'HighSpeed Train',
        'BH2': 'BH2',
        'HSM': 'BRITISH AEROSPACE HAWKER SIDDELLY',
        'AN7': 'ANTONOV 72 74',
        'AN6': 'AN6',
        'TRN': 'TRN',
        'EMJ': 'EMBRAER EMB 190',
        'AN4': 'AN4',
        'AN2': 'AN2',
        'PRP': 'PROPELLER-UNDEFINED',
        'VF6': 'VFW FOKKER VFW 614',
        'EMB': 'EMB',
        'TRD': 'HAWKER SIDDELEY TRIDENT',
        'ILW': 'ILYUSHIN IL86',
        'EM9': 'Embraer RJ190',
        'HS7': 'HS7',
        'EM7': 'Embraer RJ170',
        'LRJ': 'LRJ',
        'A4F': 'A4F',
        'EM4': 'EMBRAER EMB 145',
        'EM2': 'EM2',
        'WWP': 'WWP',
        'SWW': 'SWW',
        'D9X': 'D9X',
        'PR1': 'Hawker 390',
        '14Z': '14Z',
        'VE6': 'VFW-FOKKER 614',
        'D9S': 'McDonnell Douglas DC-9',
        '14Y': '14Y',
        'YK4': 'Yakovlev Yak 40',
        'SWM': 'SWM',
        'IL9': 'ILYUSHIN IL96',
        '14X': '14X',
        'IL8': 'IL8',
        'YK2': 'Yakovlev Yak 42',
        'IL7': 'IL7',
        'IL6': 'llyushin Il-62/62M',
        '7A3': 'Boeing 767',
        'A40': 'A40',
        'IL4': 'IL4',
        'D9F': 'D9F',
        'BET': 'BET',
        'D9D': 'D9D',
        'BES': 'BEECHCRAFT B100 SERIES',
        'D9C': 'D9C',
        'BEQ': 'BEECHCRAFT QUEEN AIR',
        'WW2': 'WESTWIND',
        '14F': 'BRITISH AEROSPACE 146 FREIGHTER JET',
        'BEP': 'BEP',
        'BEO': 'BEECHCRAFT TWIN BONANZA',
        'BEK': 'BEK',
        'D8Y': 'D8Y',
        'D8X': 'D8X',
        'BEH': 'BEH',
        'VCX': 'VICKERS VC10',
        'SW2': 'SW2',
        'A38': 'A38',
        'VCV': 'VCV',
        'D8T': 'D8T',
        'D95': 'McDonnell Douglas DC-9',
        'NDH': 'NDH',
        'D8S': 'McDonnell Douglas DC-8',
        'D94': 'D94',
        'BEC': 'BEC',
        'D93': 'McDonnell Douglas DC-9',
        'BEB': 'BEECHCRAFT BARON',
        'D8Q': 'D8Q',
        'D92': 'D92',
        'NDE': 'NDE',
        'A32': 'A32',
        'D91': 'D91',
        '146': 'British Aerospace 146',
        'NDC': 'CORVETTE',
        'A30': 'A30',
        'D8M': 'D8M',
        '143': 'BRITISH AEROSPACE 146-300 PASSENGER JET',
        'D8L': 'D8L',
        '142': 'BRITISH AEROSPACE 146-200 PASSENGER JET',
        '141': 'BRITISH AEROSPACE 146-100 PASSENGER JET',
        'M1M': 'MCDONNELL DOUGLAS MD-11',
        'BE9': 'BE9',
        'BE8': 'BEECHCRAFT 18',
        'D8F': 'D8F',
        'BE4': 'Hawker 400',
        'CWC': 'Curtiss Wright C46 Commando',
        'M1F': 'M1F',
        'BE2': 'Beechcraft BE 200 Super Kingair',
        'D8A': 'Douglas DC8',
        'BE1': 'BE1',
        'ND2': 'ND2',
        'HPJ': 'HPJ',
        'HPH': 'HPH',
        'CVY': 'CVY',
        'CVX': 'CVX',
        'A28': 'Antonov AN-28 / PZL Miele M-28 Skytruck',
        'CVV': 'CVV',
        'D86': 'Douglas DC8',
        'A26': 'A26',
        'D85': 'Douglas DC8',
        'CVR': 'CVR',
        'DPH': 'DE HAVILLAND DHJC-2',
        'A22': 'A22',
        'MIH': 'MIH',
        'M11': 'McDonnell Douglas MD-II',
        'LOM': 'LOCKHEED ELECTRA',
        'HOV': 'HOVERCRAFT',
        'CVF': 'CVF',
        'I14': 'I14',
        'LOH': 'LOH',
        'LOF': 'LOF',
        'LOE': 'LOE',
        'SU9': 'SUKHOI SUPERJET',
        'DOS': 'DOS',
        'MI8': 'MI8',
        'CV8': 'Convair 880',
        'CV6': 'Convair',
        'CV5': 'CV5',
        'CV4': 'CV4',
        'T5M': 'TU-154M',
        'CV2': 'CV2',
        'D6F': 'D6F',
        'DO8': 'DO8',
        'PN6': 'PN6',
        'LMO': 'LIMO',
        'SSC': 'CONCORDE BRITISH AEROSPACE',
        'L4T': 'LET L410 TURBOLET',
        'BAT': 'BAT',
        'D4Y': 'D4Y',
        'BAC': 'Aerospatiale/BAC Concorde',
        'FA7': 'FA7',
        'AGH': 'AGH',
        'L49': 'L49',
        'D4J': 'D4J',
        '100': 'Fokker 100 Pure Jet',
        'GRS': 'GRS',
        'PL6': 'PL6',
        'GRM': 'GRM',
        'CRV': 'Caravelle Jet',
        'PL2': 'PILATUS PC 12',
        'CS5': 'CS5',
        'GRJ': 'GRJ',
        'CS2': 'CS2',
        'D42': 'CDN DND DASH 8',
        'GRG': 'GRG',
        'GRA': 'GRA',
        'CRJ': 'Canadair Regional let',
        'S8L': 'McDonnell Douglas MD-80',
        '7SB': 'Boeing 747',
        'D3F': 'D3F',
        'D3B': 'D3B',
        'CRA': 'CANADAIR CRJ SERIES 705',
        'MDZ': 'MDZ',
        'CR9': 'CANADAIR REGIONAL',
        'D38': 'FAIRCHILD DORNIER 328-100 TURBOPROP',
        'CR7': 'CRJ-700 CANADAIR REGIONAL',
        'T20': 'TUPOLEV 204J',
        'CR4': 'CR4',
        'CR2': 'Canadair Regional 200',
        'CR1': 'Canadair',
        'S83': 'McDonnell Douglas MD-80',
        'S80': 'McDonnell Douglas MD-80',
        '7RB': 'Boeing 747',
        'L1O': 'L1O',
        'H29': 'Hawker 900 XP',
        'H28': 'Hawker 850XP',
        'H25': 'H25',
        'H24': 'Hawker 4000',
        'MD9': 'MD9',
        'DK4': 'DK4',
        'MD8': 'MD8',
        'H21': 'Hawker 1000',
        'DK2': 'DK2',
        'D1Y': 'D1Y',
        'L1F': 'L1F',
        'H20': 'Hawker 200',
        'DK1': 'DK1',
        'D1X': 'D1X',
        'D28': 'FAIRCHILD DORNIER 228 TURBOPROP',
        'L1A': 'L1A',
        'S76': 'S76',
        'TIC': 'INTERCITY TRAIN',
        'D1M': 'McDonnell Douglas DC-10',
        'L19': 'L19',
        'D1J': 'D1J',
        'D1H': 'D1H',
        'L15': 'LOCKHEED L1011-500 TRISTAR',
        '7QB': 'Boeing 747',
        'D1F': 'D1F',
        'L12': 'Lockheed L1011',
        'L11': 'L11',
        'D1C': 'McDonnell Douglas DC-10',
        'THT': 'HOTEL TRAIN',
        'L10': 'LOCKHEED L1011',
        'THS': 'TRAIN A GRAND VITESSE',
        'ICE': 'INTER-CITY EXPRESS',
        'AD7': 'AD7',
        'ACT': 'ACT',
        '38F': '38F',
        'ACP': 'ACP',
        '77X': 'Boeing 777',
        '788': 'Boeing 787-8',
        '789': 'Boeing 787-9',
        '77W': 'Boeing 777',
        'D14': 'D14',
        '787': 'Boeing 787',
        'D13': 'D13',
        'D11': 'D11',
        'D10': 'McDonnell Douglas DC-10',
        'S61': 'S61',
        'MBH': 'MBH',
        'ACD': 'ACD',
        'BUS': 'BUS',
        '388': 'Airbus A380-800',
        '77L': 'Boeing 777',
        'TGV': 'TRAIN A GRAND VITESSE',
        '380': '380',
        'ABY': 'ABY',
        'ABX': 'ABX',
        'JU5': 'JU5',
        'DHT': 'DHT',
        'DHS': 'DHS',
        'DHR': 'DHR',
        'DHP': 'DHP',
        'D09': 'McDonnell Douglas D09',
        'DHO': 'DHO',
        'DHM': 'DEHAVILLAND DASH M',
        '76Y': '76Y',
        'S58': 'S58',
        'DHL': 'DHL',
        '76X': '76X',
        'ABM': 'ABM',
        'CNT': 'CESSNA STATIONAIR 207',
        '76W': 'Boeing 767-300',
        '777': 'Boeing 777',
        'DHH': 'DHH',
        'MZD': 'MZD',
        '76S': 'Boeing 767',
        '773': 'Boeing 777',
        'CNN': 'CESSNA CARIVAN 1 TURBO JET',
        '772': 'Boeing 777',
        'ABF': 'ABF',
        'DHD': 'DHD',
        'DHC': 'CALM AIR TWIN OTTER',
        'DHB': 'DHB',
        'CNJ': 'CNJ',
        'ABB': 'ABB',
        '76K': 'Boeing 767',
        'CNG': 'CNG',
        'CNC': 'CNC',
        '76F': '76F',
        '76E': 'Boeing 767',
        'CNA': 'CNA',
        'DH8': 'DH8',
        'JST': 'JST',
        'DH7': 'DH7',
        'AB6': 'Airbus A300-600',
        'DH4': 'DEHAVILLAND DASH 8-400',
        'DH3': 'DH3',
        'AB4': 'Airbus A300-B4',
        'DH2': 'DEHAVILLAND DASH8',
        'AB3': 'Airbus A300',
        'DH1': 'DH1',
        'DH0': 'DH0',
        'WLH': 'WLH',
        '75W': 'Boeing 757-200',
        'CN4': 'CN4',
        '767': 'Boeing 767',
        'CN2': 'CESSNA STATIONAIR 206',
        '75T': 'BOEING 757-300',
        'CN1': 'CN1',
        '764': 'Boeing 767-400 pax',
        '763': 'Boeing 767-300/300ER',
        '75R': 'Boeing 757',
        '762': 'Boeing 767-200',
        'K4A': 'BOEING 747 PRESSURIZED',
        '75N': 'Boeing 757',
        '75M': '75M',
        '75F': '75F',
        '74Z': 'Boeing 747',
        '74Y': '74Y',
        'DFL': 'DFL',
        '74X': '74X',
        'TEE': 'TRANS-EUROPE EXPRESS',
        '757': 'Boeing 757-200',
        '74V': '74V',
        '756': 'Boeing 757',
        '74U': 'Boeing 747-300 / 747-200 SUD Freighter',
        '755': 'Boeing 757',
        '74T': 'Boeing 747-100 Freighter',
        '74S': 'Boeing 747',
        '753': 'Boeing 757-300 pax',
        '74R': 'Boeing 747SR pax',
        '752': '752',
        'FRJ': 'FAIRCHILD AEROSPACE',
        'C3S': 'C3S',
        '74N': 'Boeing 747',
        '74M': 'Boeing 747 (Mix Configuration)',
        '74L': 'Boeing 747 SP',
        '74J': '74J',
        '74H': 'Boeing 747-8',
        '74F': '74F',
        '34N': 'Boeing 737-400',
        '74E': 'Boeing 747-400 (Mixed Configuration)',
        '74D': 'Boeing 747-300 (Combi)',
        '74C': 'Boeing 747-200 Combi',
        'HEL': 'HELICOPTER-UNDEFINED',
        'DF3': 'DF3',
        'DF2': 'DASSAULT FALCON 20',
        '34C': 'Airbus A340',
        '73Y': '73Y',
        'HEC': 'HEC',
        'FR4': 'Fairchild 428',
        '73X': '73X',
        'FR3': 'Fairchild 328',
        '73W': 'Boeing 747-200',
        'CL4': 'Canadair CL44',
        '747': 'Boeing 747',
        '744': 'Boeing 747',
        '73S': 'Boeing 737-200',
        '743': '743',
        '73R': 'Boeing 737-700',
        '742': 'Boeing 747-200',
        '73Q': 'Boeing 737-400',
        'S20': 'Saab 2000',
        '741': '741',
        '73M': 'Boeing 737',
        '346': 'Airbus A340-600',
        '73L': 'Boeing 737',
        '345': 'Airbus A340-500',
        '73J': 'Boeing 737',
        '343': 'Airbus A340',
        '73I': 'Boeing 737-800',
        '342': '342',
        '73H': 'Boeing 737-800 (winglets) pax',
        '73G': 'Boeing 737-700 pax',
        '340': 'Airbus A340',
        '73F': '73F',
        '73E': 'Boeing 737-500',
        '73C': 'Boeing 737-300',
        '73B': 'BOEING 737',
        '73A': 'Boeing 737-200 advanced',
        'TCM': 'COMMUTER TRAIN',
        '72Y': '72Y',
        '72X': '72X',
        '739': 'Boeing 737-900 pax',
        '738': 'Boeing 737-800 pax',
        '737': 'Boeing 737',
        '736': '736',
        '735': 'Boeing 737-500',
        '359': 'Boeing 737-900',
        '72S': 'Boeing 727-200 Advanced pax',
        '734': 'Boeing 737-400',
        '72R': 'Boeing 727',
        '733': 'Boeing 737-300',
        '732': '732',
        '731': '731',
        '72O': 'Boeing 727-200',
        '72N': 'Boeing 727',
        '32V': 'Airbus A320',
        '72M': 'Boeing 727 Combi',
        '32S': 'Airbus A320',
        '333': 'Airbus A333',
        '332': 'Airbus A330',
        'LCH': 'LAUNCH',
        '32P': 'Airbus A320',
        '330': 'Airbus A330',
        '72F': '72F',
        '72B': 'Boeing 727-100 Mixed Configuration',
        '72A': 'Boeing 727 Advanced',
        'F7B': 'F7B',
        'SHS': 'SHS',
        '7JS': 'Boeing 747',
        'SHP': 'SHP',
        '32B': 'Airbus A321',
        '32A': 'Airbus A320',
        '727': 'Boeing 727 Passenger'
    },
    zh_HK: {
        '722': '\u6CE2\u97F3 727-200',
        '31Y': '\u7A7A\u4E2D\u5DF4\u58EB 310-300',
        '721': '\u6CE2\u97F3 727-100',
        '31X': '\u7A7A\u4E2D\u5DF4\u58EB 310-200',
        '720': '\u6CE2\u97F3 727-200',
        'F70': '\u798F\u514B 70 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'SHB': 'HORTS SC.5 BELFAST \u6E26\u8F2A\u87BA\u65CB\u69F3',
        '7JB': '\u6CE2\u97F3 747',
        '321': '\u7A7A\u4E2D\u5DF4\u58EB 321',
        'TAT': 'UTO TRAIN',
        '320': '\u7A7A\u4E2D\u5DF4\u58EB 320',
        'DC9': '\u9EA5\u9053 DC9',
        'B72': '\u6CE2\u97F3720-020B',
        'DC8': '\u9EA5\u9053 DC8',
        'PAZ': 'IPER AZTEC',
        'DC6': '\u9EA5\u9053 DC6B',
        'MU2': '\u4E09\u83F1 MU2 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'DC4': 'cDonnell Douglas DC-4',
        'SH6': 'HORTS BROS 360 (SD3-60) \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'DC3': '\u9EA5\u9053 DC3',
        'PAT': 'IPER\uFF08\u8F15\u578B\u98DB\u6A5F\uFF0D\u96D9\u6E26\u8F2A\u87BA\u65CB\u69F3\u5F15\u64CE\uFF09',
        'DC1': 'C1',
        '31F': '\u7A7A\u4E2D\u5DF4\u58EB 310',
        'SH3': 'HORTS BROS 330 (SD3-30) \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'PAS': 'IPER SENECA',
        'MTL': 'ETROLINER',
        'PAN': 'AN',
        '717': '\u6CE2\u97F3 717-100',
        'PAH': 'IPER CHIEFTAIN',
        'BNT': 'RITTEN NORAM BN-2A TRISLANDER PROPELLER',
        'PAG': 'IPER\uFF08\u8F15\u578B\u98DB\u6A5F\uFF09\u6E26\u8F2A\u87BA\u65CB\u69F3',
        '319': '\u7A7A\u4E2D\u5DF4\u58EB 319',
        '318': '\u7A7A\u4E2D\u5DF4\u58EB 318',
        '70M': '\u6CE2\u97F3 707',
        'Jet': 'ure Jet',
        'PAC': 'IPER CHEROKEE',
        'CHG': 'HG',
        '313': '\u7A7A\u4E2D\u5DF4\u58EB 310-300',
        '312': '\u7A7A\u4E2D\u5DF4\u58EB 310-200',
        'ATT': 'TT',
        '310': '\u7A7A\u4E2D\u5DF4\u58EB 310',
        '70F': '\u6CE2\u97F3 707',
        'ATR': 'TR42/72 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'BNI': 'RITTEN NORAM BN-2A/BN-2B ISLANDER PROPELLER',
        'ATP': '\u82F1\u570B\u822A\u592AATP',
        'SFW': 'AAB 340',
        'PA6': 'IPER AERO STAR 601',
        'YS1': 'IHON YS-11 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'J4U': '4U',
        'PA2': 'IPER\uFF08\u8F15\u578B\u98DB\u6A5F\uFF0D\u96D9\u6D3B\u585E\u5F15\u64CE\uFF09\u6E26\u8F2A\u87BA\u65CB\u69F3',
        'DAM': 'ASSAULT BREGUET MERCURE',
        'PA1': 'IPER\uFF08\u8F15\u578B\u98DB\u6A5F\uFF0D\u55AE\u6D3B\u585E\u5F15\u64CE\uFF09\u6E26\u8F2A\u87BA\u65CB\u69F3',
        'ATD': 'TR42-400 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        '707': '\u6CE2\u97F3 707',
        '703': '\u6CE2\u97F3 707-320B/320C',
        'F50': '\u798F\u514B 50 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'SFA': 'FA',
        'AT7': 'TR 72 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'AT6': 'TR TURBOPROP',
        'AT5': 'TR 42-500 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'J4A': '4A',
        'AT4': 'TR 42-300/320 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'SF3': 'AAB 340 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'I9Y': 'IYUSHIN II-96T',
        'J41': '\u82F1\u570B\u822A\u592ASTREAM 41 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'I9X': 'IYUSHIN II-96-300',
        'I9M': 'IYUSHIN II-96M',
        'DY4': 'Y4',
        'ART': 'RT',
        'ERJ': 'MBRAER RJ135/RJ140/RJ145',
        'DY1': 'Y1',
        'I9F': 'IYUSHIN II-96',
        'MR4': 'R4',
        'ERD': 'MBRAER RJ140',
        'ARJ': '\u82F1\u570B\u822A\u592ARJ7',
        'M95': '\u9EA5\u9053 MD-95',
        'J32': 'ETSTREAM 32',
        'M90': '\u9EA5\u9053 MD-90',
        'J31': '\u82F1\u570B\u822A\u592ASTREAM 31 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'EQV': 'quipment Varies',
        'I93': 'IYUSHIN II-96-300',
        'ER4': 'MBRAER RJ145',
        'ER3': 'MBRAER RJ135',
        'AR9': 'R95',
        'AR8': 'J85-REGIONAL  85',
        'AR7': 'J70-REGIONAL  70',
        'E95': 'MBRAER 195 \u5674\u6C23\u5F0F\u98DB\u6A5F',
        'E90': 'MBRAER EMB E90',
        'AR1': 'vro RJ100 Avroliner',
        'RJA': 'JA',
        '23S': '\u7A7A\u4E2D\u5DF4\u58EB A318',
        'M88': '\u9EA5\u9053 MD-88',
        'M87': '\u9EA5\u9053 MD-87',
        'FK7': 'AIRCHILD INDUSTRIES FH-227 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'M83': '\u9EA5\u9053 MD-83',
        'M82': '\u9EA5\u9053 MD-82',
        'M81': '\u9EA5\u9053 MD-81',
        'M80': '\u9EA5\u9053 MD-80',
        'F28': '\u798F\u514BF28 FELLOWSHIP',
        'F27': '\u798F\u514BF28 FELLOWSHIP 4000',
        'F24': '\u798F\u514B F28 FELLOWSHIP 4000',
        'F23': '\u798F\u514B F28 FELLOWSHIP 3000',
        'F22': '\u798F\u514B F28 FELLOWSHIP 2000',
        'TUS': 'upolev TU-144',
        'F21': '\u798F\u514B F28 FELLOWSHIP 1000',
        'CCX': '\u9F90\u5DF4\u8FEA\u4E9E BD-700 GLOBAL EXPRESS',
        'APH': '\u6B50\u6D32\u76F4\u6607\u6A5F (AEROSPATIALE) SA330 PUMA/AS332 SUPER PUMA',
        'LV2': 'V2',
        'CD2': 'OVERNMENT AIRCRAFT FACTORIES N22B/N24A \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'TU5': '\u5716\u6CE2\u5217\u592BTU-154',
        'CCJ': 'ANANDAIR CL-600/601/604 \u6311\u6230\u8005',
        'TU3': '\u5716\u6CE2\u5217\u592BTU-134',
        'F10': '10',
        'TU2': 'upolev TU-124',
        'E75': 'MBRAER EMB 175',
        'B15': '\u82F1\u570B\u822A\u592AONE-ELEVEN 500/560',
        'B14': '\u82F1\u570B\u822A\u592AONE-ELEVEN 400/475',
        'B13': '\u82F1\u570B\u822A\u592AONE-ELEVEN 300',
        'B12': '\u82F1\u570B\u822A\u592AONE-ELEVEN 200',
        'E70': 'MBRAER 170\u5674\u6C23\u5F0F\u98DB\u6A5F',
        'B11': '\u82F1\u570B\u822A\u592A ONE-ELEVEN',
        'YN7': '\u897F\u5B89\u904B\u8F38\u6A5F Y7/MA-60 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'MO2': 'OONEY MARK 20A',
        'YN5': 'N5',
        'YN2': '\u54C8\u723E\u8CD3\u904B\u8F38\u6A5F Y12 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'VGP': 'RITISH AEROSPACE VANGUARD',
        'HU5': 'UGHES 500',
        'TSL': 'LEEPER TRAIN',
        '7C4': '\u6CE2\u97F3 747',
        'SA2': 'A2',
        '7C3': '\u6CE2\u97F3 767',
        'ANF': 'NTONOV AN-12',
        'RFS': '\u5730\u9762\u8A2D\u5099 \uFF0D \u9678\u8DEF\u63A5\u99C1\u670D\u52D9\uFF08\u8CA8\u8ECA\uFF09',
        'CAN': '\u8CFD\u65AF\u7D0D\uFF08\u8F15\u578B\u98DB\u6A5F\uFF09',
        'A5F': 'NTONOV AN-225',
        'TRS': 'ighSpeed Train',
        'BH2': 'ELL  RANGER HELICOPTER',
        'HSM': 'RITISH AEROSPACE HAWKER SIDDELLY',
        'AN7': 'NTONOV AN-72/AN-74',
        'AN6': 'NTONOV AN-26/AN-30/AN-32',
        'TRN': '\u5730\u9762\u8A2D\u5099 \uFF0D \u706B\u8ECA',
        'EMJ': 'MBRAER EMB 190 \u5674\u6C23\u5F0F\u98DB\u6A5F',
        'AN4': 'NTONOV AN-24',
        'AN2': 'N2',
        'PRP': 'ROPELLER-UNDEFINED',
        'VF6': 'FW FOKKER VFW 614',
        'EMB': 'MBRAER 110 BANDEIRANTE \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'TRD': 'AWKER SIDDELEY TRIDENT',
        'ILW': 'IYUSHIN II-86',
        'EM9': 'mbraer RJ190',
        'HS7': '\u82F1\u570B\u822A\u592A\uFF08\u970D\u514B\u862D\uFE52\u96EA\u5FB7\u840A\uFF09748/ANDOVER',
        'EM7': 'mbraer RJ170',
        'LRJ': 'EAR',
        'A4F': 'NTONOV AN-124 RUSLAN',
        'EM4': 'MBRAER EMB 145',
        'EM2': 'MBRAER 120 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'WWP': '\u4EE5\u8272\u5217\u98DB\u6A5F\u5DE5\u696D 1124 \u897F\u98A8 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'SWW': 'WW',
        'D9X': '\u9EA5\u9053 DC9-10',
        'PR1': 'awker 390',
        '14Z': '\u82F1\u570B\u822A\u592A 146-300',
        'VE6': 'FW-FOKKER 614',
        'D9S': 'cDonnell Douglas DC-9',
        '14Y': '\u82F1\u570B\u822A\u592A 146-200',
        'YK4': '\u96C5\u514B YAK-40',
        'SWM': 'AIRCHILD \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'IL9': 'IYUSHIN II-96',
        '14X': '\u82F1\u570B\u822A\u592A 146-100',
        'IL8': 'IYUSHIN II-18',
        'YK2': '\u96C5\u514B YAK-42/YAK-142',
        'IL7': 'IYUSHIN II-76',
        'IL6': 'IYUSHIN II-62',
        '7A3': '\u6CE2\u97F3 767',
        'A40': 'NTONOV AN-140',
        'IL4': 'L4',
        'D9F': '\u9EA5\u9053 DC9',
        'BET': 'EECHCRAFT AIRLINER',
        'D9D': '\u9EA5\u9053 DC9-40',
        'BES': 'EECHCRAFT 1900C SERIES AIRLINER',
        'D9C': '\u9EA5\u9053 DC9-30',
        'BEQ': 'EECHCRAFT QUEEN AIR',
        'WW2': 'ESTWIND',
        '14F': '\u82F1\u570B\u822A\u592A 146',
        'BEP': 'EECHCRAFT AIRLINER',
        'BEO': 'EECHCRAFT TWIN BONANZA',
        'BEK': 'EK',
        'D8Y': '\u9EA5\u9053 DC8-71/72/73',
        'D8X': '\u9EA5\u9053 DC8-61/62/63',
        'BEH': 'EECHCRAFT 1900D',
        'VCX': 'ICKERS VC10',
        'SW2': 'W2',
        'A38': 'NTONOV AN-38',
        'VCV': '\u82F1\u570B\u822A\u592A\uFF08\u7DAD\u514B\u7D72\uFF09\u5B50\u7235 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'D8T': '\u9EA5\u9053 DC8-50',
        'D95': '\u9EA5\u9053 DC9-50',
        'NDH': '\u6B50\u6D32\u76F4\u6607\u6A5F (AEROSPATIALE) ASA365C/SA365N DAUPHIN 2 \u76F4\u6607\u6A5F',
        'D8S': 'cDonnell Douglas DC-8',
        'D94': '\u9EA5\u9053 DC9-40',
        'BEC': 'EECHCRAFT \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'D93': 'cDonnell Douglas DC-9',
        'BEB': 'EECHCRAFT BARON',
        'D8Q': '\u9EA5\u9053 DC8-72',
        'D92': '\u9EA5\u9053 DC9-20',
        'NDE': '\u6B50\u6D32\u76F4\u6607\u6A5F (AEROSPATIALE) AS350 ECUREUIL/AS355 EUREUIL 2 \u76F4\u6607\u6A5F',
        'A32': 'NTONOV AN-32',
        'D91': '\u9EA5\u9053 DC9-10',
        '146': '\u82F1\u570B\u822A\u592A 146',
        'NDC': 'EROSPATIALE SN601 CORVETTE',
        'A30': 'NTONOV AN-30',
        'D8M': '\u9EA5\u9053 DC8-62 T',
        '143': '\u82F1\u570B\u822A\u592A 146-300',
        'D8L': '\u9EA5\u9053 DC8-62',
        '142': '\u82F1\u570B\u822A\u592A 146-200',
        '141': '\u82F1\u570B\u822A\u592A 146-100',
        'M1M': '\u9EA5\u9053 MD-11 PSGR/',
        'BE9': 'E9',
        'BE8': 'EECHCRAFT 18',
        'D8F': '\u9EA5\u9053 DC8',
        'BE4': 'awker 400',
        'CWC': 'urtiss Wright C46 Commando',
        'M1F': '\u9EA5\u9053 MD-11',
        'BE2': 'eechcraft BE 200 Super Kingair',
        'D8A': '\u9EA5\u9053 DC8',
        'BE1': 'EECHCRAFT BE100 SERIES \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'ND2': 'EROSPATIALE NORD 262 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'HPJ': 'PJ',
        'HPH': 'PH',
        'CVY': '\u5EB7\u7DAD\u723E 580/5800/600/640',
        'CVX': '\u5EB7\u7DAD\u723E 340/440',
        'A28': 'NTONOV AN-28/PZL MIELEC M-28 SKYTRUCK',
        'CVV': '\u5EB7\u7DAD\u723E 240',
        'D86': '\u9EA5\u9053 DC8',
        'A26': 'NTONOV AN-26',
        'D85': '\u9EA5\u9053 DC8',
        'CVR': '\u5EB7\u7DAD\u723E 240/340/440/580',
        'DPH': 'E HAVILLAND DHJC-2',
        'A22': 'NTONOV AN-22',
        'MIH': 'IKHAIL MIL MI-8/MI-17/MI-171/MI-172 \u76F4\u6607\u6A5F',
        'M11': '\u9EA5\u9053 MD-11',
        'LOM': '\u6D1B\u514B\u5E0C\u5FB7 L-188 ELECTRA TER  \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'HOV': '\u5730\u9762\u8A2D\u5099 - \u6C23\u588A\u8239',
        'CVF': '\u5EB7\u7DAD\u723E 240/340/440/580/5800/600/640',
        'I14': 'IYUSHIN II-114',
        'LOH': '\u6D1B\u514B\u5E0C\u5FB7L-182/L-282/L-382 (L-100) \u5927\u529B\u58EB \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'LOF': '\u6D1B\u514B\u5E0C\u5FB7L-188 ELECTRA \u8CA8\u6A5F \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'LOE': '\u6D1B\u514B\u5E0C\u5FB7L-188 ELECTRA \u5BA2\u6A5F \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'SU9': 'UKHOI SUPERJET',
        'DOS': 'OS',
        'MI8': 'I8',
        'CV8': 'onvair 880',
        'CV6': 'onvair',
        'CV5': '\u5EB7\u7DAD\u723E 580',
        'CV4': '\u5EB7\u7DAD\u723E 440 METROPOLITAN',
        'T5M': 'U-154M',
        'CV2': '\u5EB7\u7DAD\u723E 240',
        'D6F': '\u9EA5\u9053 DC6A/DC6B/DC6C',
        'DO8': 'O8',
        'PN6': 'ARTENAVIA P.68 PROPELLER',
        'LMO': '\u5730\u9762\u8A2D\u5099-\u8C6A\u83EF\u8F4E\u8ECA',
        'SSC': 'EROSPATIALE/\u82F1\u570B\u822A\u592A\u8D85\u97F3\u901F\u98DB\u6A5F',
        'L4T': 'ET L410 TURBOLET',
        'BAT': 'AT',
        'D4Y': '4Y',
        'BAC': 'erospatiale/BAC Concorde',
        'FA7': '\u8CBB\u67E5-\u591A\u5C3C\u723E 728',
        'AGH': 'GH',
        'L49': '\u6D1B\u514B\u5E0C\u5FB7 L-749 \u661F\u5EA7/L-1049 \u8D85\u7D1A\u661F\u5EA7',
        'D4J': '4J',
        '100': '\u798F\u514B 100',
        'GRS': '\u7063\u6D41\u822A\u592A (\u683C\u9B6F\u66FC) G-159 \u7063\u6D41 I',
        'PL6': 'ILATUS PC-6 TURBO PORTER \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'GRM': '\u683C\u9B6F\u66FCG-73 TURBO MALLARD (AMPHIBIAN)',
        'CRV': 'EROSPATIALE SE210 CARAVELLE',
        'PL2': 'ILATUS  PC-12 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'CS5': 'ASA/IPTN CN-235 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'GRJ': '\u7063\u6D41\u822A\u592A  (\u683C\u9B6F\u66FC) \u7063\u6D41 II/III/IV/V',
        'CS2': 'ASA/IPTN 212 AVIOCAR \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'D42': 'DN DND DASH 8',
        'GRG': '\u683C\u9B6F\u66FCG-21 GOOSE (AMPHIBIAN)',
        'GRA': 'RA',
        'CRJ': 'ANADAIR REGIONAL',
        'S8L': 'cDonnell Douglas MD-80',
        '7SB': '\u6CE2\u97F3 747',
        'D3F': '\u9EA5\u9053DC3',
        'D3B': '3B',
        'CRA': 'ANADAIR CRJ SERIES 705',
        'MDZ': 'DZ',
        'CR9': 'ANADAIR REGIONAL  900',
        'D38': '\u8CBB\u67E5-\u591A\u5C3C\u723E 328-100  \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'CR7': 'ANADAIR REGIONAL  700',
        'T20': '\u5716\u6CE2\u5217\u592BTU-204/TU-214',
        'CR4': 'R4',
        'CR2': 'anadair Regional 200',
        'CR1': 'ANADAIR REGIONAL  100',
        'S83': 'cDonnell Douglas MD-80',
        'S80': 'cDonnell Douglas MD-80',
        '7RB': '\u6CE2\u97F3 747',
        'L1O': '1O',
        'H29': 'awker 900 XP',
        'H28': 'awker 850XP',
        'H25': '\u970D\u514B\u862D\uFF08\u970D\u514B\u862D\uFE52\u96EA\u5FB7\u840A/\u82F1\u570B\u822A\u592A125\uFF09',
        'H24': 'awker 4000',
        'MD9': '\u9EA5\u9053 \u76F4\u6607\u6A5F 900 EXPLORER',
        'DK4': 'K4',
        'MD8': 'D8',
        'H21': 'awker 1000',
        'DK2': 'K2',
        'D1Y': '\u9EA5\u9053 DC10-30/40',
        'L1F': '\u6D1B\u514B\u5E0C\u5FB7 L-1011 \u4E09\u661F',
        'H20': 'awker 200',
        'DK1': 'K1',
        'D1X': '\u9EA5\u9053 DC10-10',
        'D28': '\u8CBB\u67E5-\u591A\u5C3C\u723E 228 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'L1A': '1A',
        'S76': '\u585E\u8003\u65AF\u57FA S-76 \u76F4\u6607\u6A5F',
        'TIC': 'NTERCITY TRAIN',
        'D1M': '\u9EA5\u9053 DC10-30',
        'L19': '19',
        'D1J': '1J',
        'D1H': '1H',
        'L15': '\u6D1B\u514B\u5E0C\u5FB7 L-1011 \u4E09\u661F 500',
        '7QB': '\u6CE2\u97F3 747',
        'D1F': '\u9EA5\u9053 DC10',
        'L12': 'ockheed L1011',
        'L11': '\u6D1B\u514B\u5E0C\u5FB7 L-1011  1/50/100/150/200/250',
        'D1C': '\u9EA5\u9053 DC10-30/40',
        'THT': 'OTEL TRAIN',
        'L10': '\u6D1B\u514B\u5E0C\u5FB7 L-1011 \u4E09\u661F',
        'THS': 'RAIN A GRAND VITESSE',
        'ICE': 'NTER-CITY EXPRESS',
        'AD7': 'D7',
        'ACT': 'WIN (AERO) TURBO COMMANDER/PROP COMMANDER',
        '38F': '\u7A7A\u4E2D\u5DF4\u58EB 380-800',
        'ACP': 'WIN (AERO) COMMANDER',
        '77X': '\u6CE2\u97F3 767-777',
        '788': '\u6CE2\u97F3 787-8',
        '789': '\u6CE2\u97F3 787-9',
        '77W': '\u6CE2\u97F3 767-777',
        'D14': '14',
        '787': '\u6CE2\u97F3 787',
        'D13': '13',
        'D11': '\u9EA5\u9053 DC10-10/15',
        'D10': '\u9EA5\u9053 DC10',
        'S61': '\u585E\u8003\u65AF\u57FA S-58T\u76F4\u6607\u6A5F',
        'MBH': '\u6B50\u6D32 \u76F4\u6607\u6A5F (MBB) BO105',
        'ACD': 'WIN (AERO) COMMANDER/TURBO COMMANDER/PROP COMMANDER',
        'BUS': '\u5730\u9762\u8A2D\u5099 - \u5DF4\u58EB',
        '388': '\u7A7A\u4E2D\u5DF4\u58EB A380-800',
        '77L': '\u6CE2\u97F3 767-777',
        'TGV': 'RAIN A GRAND VITESSE',
        '380': '\u7A7A\u4E2D\u5DF4\u58EB 380-800',
        'ABY': '\u7A7A\u4E2D\u5DF4\u58EB300-600',
        'ABX': '\u7A7A\u4E2D\u5DF4\u58EB300B4/300C4/300F4',
        'JU5': 'UNKERS JU 52/3M',
        'DHT': '\u8FEA\u54C8\u7DAD DHC-6 TWIN OTTER \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'DHS': '\u8FEA\u54C8\u7DAD DHC-3 OTTER  \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'DHR': '\u8FEA\u54C8\u7DAD DHC-2 TURBO BEAVER \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'DHP': '\u8FEA\u54C8\u7DAD DHC-2 BEAVER \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'D09': 'cDonnell Douglas D09',
        'DHO': 'HO',
        'DHM': 'EHAVILLAND DASH M',
        '76Y': '\u6CE2\u97F3 767-300',
        'S58': '\u585E\u8003\u65AF\u57FA S-58T\u76F4\u6607\u6A5F',
        'DHL': '\u8FEA\u54C8\u7DAD DHC-3 TURBO OTTER \u6E26\u8F2A\u87BA\u65CB\u69F3',
        '76X': '\u6CE2\u97F3 767-200',
        'ABM': 'BM',
        'CNT': '\u8CFD\u65AF\u7D0D\uFF08\u8F15\u578B\u98DB\u6A5F\uFF0D\u96D9\u6E26\u8F2A\u87BA\u65CB\u69F3\u5F15\u64CE\uFF09',
        '76W': '\u6CE2\u97F3 767-300',
        '777': '\u6CE2\u97F3 777',
        'DHH': '\u82F1\u570B\u822A\u592A\u8FEA\u54C8\u7DAD 114 HERON',
        'MZD': 'ZD',
        '76S': '\u6CE2\u97F3 767',
        '773': '\u6CE2\u97F3 777-300',
        'CNN': 'ESSNA CARIVAN 1 TURBO JET',
        '772': '\u6CE2\u97F3 777-200',
        'ABF': '\u7A7A\u4E2D\u5DF4\u58EB300',
        'DHD': '\u82F1\u570B\u822A\u592A\u8FEA\u54C8\u7DAD 104 DOVE',
        'DHC': '\u8FEA\u54C8\u7DADDHC-4 CARIBOU \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'DHB': '\u8FEA\u54C8\u7DADDHC-2 BEAVER/TURBO  BEAVER  \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'CNJ': '\u8CFD\u65AF\u7D0D CITATION',
        'ABB': '\u7A7A\u4E2D\u5DF4\u58EB300-600ST BELUGA',
        '76K': '\u6CE2\u97F3 767',
        'CNG': 'NG',
        'CNC': '\u8CFD\u65AF\u7D0D\uFF08\u8F15\u578B\u98DB\u6A5F\uFF0D\u55AE\u6E26\u8F2A\u87BA\u65CB\u69F3\u5F15\u64CE\uFF09',
        '76F': '\u6CE2\u97F3 767',
        '76E': '\u6CE2\u97F3 767',
        'CNA': 'NA',
        'DH8': '\u8FEA\u54C8\u7DADDHC-8 DASH 8 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'JST': '\u82F1\u570B\u822A\u592A STREAM \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'DH7': '\u8FEA\u54C8\u7DADDHC-7 DASH 7 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'AB6': '\u7A7A\u4E2D\u5DF4\u58EB300-600',
        'DH4': '\u8FEA\u54C8\u7DADDHC-8-400 DASH 8Q \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'DH3': '\u8FEA\u54C8\u7DADDHC-8-300 DASH 8/8Q \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'AB4': '\u7A7A\u4E2D\u5DF4\u58EB 300B2/300B4',
        'DH2': '\u8FEA\u54C8\u7DADDHC-8-200 DASH 8/8Q \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'AB3': '\u7A7A\u4E2D\u5DF4\u58EB 300',
        'DH1': '\u8FEA\u54C8\u7DADDHC-8-100 DASH 8/8Q \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'DH0': '\u8FEA\u54C8\u7DAD DHC-3 OTTER/TURBO OTTER \u6E26\u8F2A\u87BA\u65CB\u69F3',
        'WLH': 'LH',
        '75W': '\u6CE2\u97F3 757-200',
        'CN4': 'N4',
        '767': '\u6CE2\u97F3 767',
        'CN2': '\u8CFD\u65AF\u7D0D\uFF08\u8F15\u578B\u98DB\u6A5F\uFF0D\u96D9\u6D3B\u585E\u5F15\u64CE\uFF09',
        '75T': '\u6CE2\u97F3 757-300',
        'CN1': '\u8CFD\u65AF\u7D0D\uFF08\u8F15\u578B\u98DB\u6A5F\uFF0D\u55AE\u6D3B\u585E\u5F15\u64CE\uFF09',
        '764': '\u6CE2\u97F3 767-400',
        '763': '\u6CE2\u97F3 767-300',
        '75R': '\u6CE2\u97F3 757',
        '762': '\u6CE2\u97F3 757-200',
        'K4A': '\u6CE2\u97F3 747',
        '75N': '\u6CE2\u97F3 757',
        '75M': '\u6CE2\u97F3 757-200 SUD',
        '75F': '\u6CE2\u97F3 757-200',
        '74Z': '\u6CE2\u97F3 747',
        '74Y': '\u6CE2\u97F3 747-400',
        'DFL': '\u9054\u68ADFALCON',
        '74X': '\u6CE2\u97F3 747-200',
        'TEE': 'RANS-EUROPE EXPRESS',
        '757': '\u6CE2\u97F3 757',
        '74V': '\u6CE2\u97F3 747SR',
        '756': '\u6CE2\u97F3 757',
        '74U': '\u6CE2\u97F3 747-300/747-200 SUD',
        '755': '\u6CE2\u97F3 757',
        '74T': '\u6CE2\u97F3 747-100',
        '74S': '\u6CE2\u97F3 747',
        '753': '\u6CE2\u97F3 757-300',
        '74R': '\u6CE2\u97F3 747SR',
        '752': '\u6CE2\u97F3 757-200',
        'FRJ': 'AIRCHILD AEROSPACE 328',
        'C3S': '3S',
        '74N': '\u6CE2\u97F3 741',
        '74M': '\u6CE2\u97F3 747',
        '74L': '\u6CE2\u97F3 747 SP \u9577\u9014',
        '74J': '\u6CE2\u97F3 747-400 \uFF08\u570B\u5167\uFF09',
        '74H': '\u6CE2\u97F3 747-8',
        '74F': '\u6CE2\u97F3 747',
        '34N': '\u6CE2\u97F3 737-400',
        '74E': '\u6CE2\u97F3 747-400',
        '74D': '\u6CE2\u97F3 747-300 SUD',
        '74C': '\u6CE2\u97F3 747-200',
        'HEL': 'ELICOPTER-UNDEFINED',
        'DF3': '\u9054\u68ADFALCON 50/900',
        'DF2': '\u9054\u68ADFALCON 10/100/20/200/2000',
        '34C': '\u7A7A\u4E2D\u5DF4\u58EB A340',
        '73Y': '\u6CE2\u97F3 737-300',
        'HEC': 'ELIO H-250 COURIER/H-295/395 SUPER COURIER',
        'FR4': 'airchild 428',
        '73X': '\u6CE2\u97F3 737-200',
        'FR3': 'airchild 328',
        '73W': '\u6CE2\u97F3 737-700',
        'CL4': 'ANANDAIR CL-44D4 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        '747': '\u6CE2\u97F3 747',
        '744': '\u6CE2\u97F3 747-400',
        '73S': '\u6CE2\u97F3 737-200',
        '743': '\u6CE2\u97F3 747SUD',
        '73R': '\u6CE2\u97F3 737-700',
        '742': '\u6CE2\u97F3 747-200',
        '73Q': '\u6CE2\u97F3 737-400',
        'S20': 'AAB 2000 \u6E26\u8F2A\u87BA\u65CB\u69F3',
        '741': '\u6CE2\u97F3 747-100',
        '73M': '\u6CE2\u97F3 737-200',
        '346': '\u7A7A\u4E2D\u5DF4\u58EB 340-600',
        '73L': '\u6CE2\u97F3 737',
        '345': '\u7A7A\u4E2D\u5DF4\u58EB 340-500',
        '73J': '\u6CE2\u97F3 737',
        '343': '\u7A7A\u4E2D\u5DF4\u58EB 340-300',
        '73I': '\u6CE2\u97F3 737-800',
        '342': '\u7A7A\u4E2D\u5DF4\u58EB 340-200',
        '73H': '\u6CE2\u97F3 737-800',
        '73G': '\u6CE2\u97F3 737-700',
        '340': '\u7A7A\u4E2D\u5DF4\u58EB 340',
        '73F': '\u6CE2\u97F3 737',
        '73E': '\u6CE2\u97F3 737-500',
        '73C': '\u6CE2\u97F3 737-300',
        '73B': '\u6CE2\u97F3 737',
        '73A': '\u6CE2\u97F3 737-200',
        'TCM': 'OMMUTER TRAIN',
        '72Y': '\u6CE2\u97F3 727-200',
        '72X': '\u6CE2\u97F3 727-100',
        '739': '\u6CE2\u97F3 737-900',
        '738': '\u6CE2\u97F3 737-800',
        '737': '\u6CE2\u97F3 737',
        '736': '\u6CE2\u97F3 737-600',
        '735': '\u6CE2\u97F3 737-500',
        '359': '\u6CE2\u97F3 737-900',
        '72S': '\u6CE2\u97F3 727-200',
        '734': '\u6CE2\u97F3 737-400',
        '72R': '\u6CE2\u97F3 727',
        '733': '\u6CE2\u97F3 737-300',
        '732': '\u6CE2\u97F3 737-200',
        '731': '\u6CE2\u97F3 737-100',
        '72O': '\u6CE2\u97F3 727',
        '72N': '\u6CE2\u97F3 727',
        '32V': '\u7A7A\u4E2D\u5DF4\u58EB A320',
        '72M': '\u6CE2\u97F3 727',
        '32S': '\u7A7A\u4E2D\u5DF4\u58EB 318/319/320/321',
        '333': '\u7A7A\u4E2D\u5DF4\u58EB 330-300',
        '332': '\u7A7A\u4E2D\u5DF4\u58EB 330-220',
        'LCH': '\u5730\u9762\u8A2D\u5099-\u904A\u8247/\u8239\u96BB',
        '32P': '\u7A7A\u4E2D\u5DF4\u58EB A320',
        '330': '\u7A7A\u4E2D\u5DF4\u58EB 330',
        '72F': '\u6CE2\u97F3 727',
        '72B': '\u6CE2\u97F3 747',
        '72A': '\u6CE2\u97F3 727',
        'F7B': '7B',
        'SHS': 'HORTS BROS SKYVAN (SC-7) \u6E26\u8F2A\u87BA\u65CB\u69F3',
        '7JS': '\u6CE2\u97F3 747',
        'SHP': 'HP',
        '32B': '\u7A7A\u4E2D\u5DF4\u58EB A321',
        '32A': '\u7A7A\u4E2D\u5DF4\u58EB A320',
        '727': '\u6CE2\u97F3 727',
    },
    zh_CN: {
        '722':'\u6CE2\u97F3 727-200',
        '31Y':'\u7A7A\u4E2D\u5BA2\u8F66 310-300',
        '721':'\u6CE2\u97F3 727-100',
        '31X':'\u7A7A\u4E2D\u5BA2\u8F66 310-200',
        '720':'\u6CE2\u97F3 727-200',
        'F70':'\u798F\u514B 70 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'SHB':'\u8096\u7279 SHORTS SC.5 BELFAST \u6DA1\u8F6E\u87BA\u65CB\u6868',
        '7JB':'\u6CE2\u97F3 747',
        '321':'\u7A7A\u4E2D\u5BA2\u8F66 321',
        'TAT':'UTO TRAIN',
        '320':'\u7A7A\u4E2D\u5BA2\u8F66 320',
        'DC9':'\u9EA6\u9053 DC9',
        'B72':'\u6CE2\u97F3 720-020B',
        'DC8':'\u9EA6\u9053 DC8',
        'PAZ':'IPER AZTEC',
        'DC6':'\u9EA6\u9053 DC6B',
        'MU2':'\u4E09\u83F1 MU2 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'DC4':'\u9EA5\u9053\u516C\u53F8 DC-4',
        'SH6':'\u8096\u7279 SHORTS BROS 360 (SD3-60) \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'DC3':'\u9EA6\u9053 DC3',
        'PAT':'IPER\uFF08\u8F7B\u578B\u98DE\u673A - \u53CC\u6DA1\u8F6E\u87BA\u65CB\u6868\u5F15\u64CE\uFF09',
        'DC1':'C1',
        '31F':'\u7A7A\u4E2D\u5BA2\u8F66 310',
        'SH3':'\u8096\u7279 SHORTS BROS 330 (SD3-30) \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'PAS':'IPER SENECA',
        'MTL':'ETROLINER',
        'PAN':'AN',
        '717':'\u6CE2\u97F3 717-100',
        'PAH':'IPER CHIEFTAIN',
        'BNT':'RITTEN NORAM BN-2A TRISLANDER PROPELLER',
        'PAG':'IPER\uFF08\u8F7B\u578B\u98DE\u673A\uFF09\u6DA1\u8F6E\u87BA\u65CB\u6868',
        '319':'\u7A7A\u4E2D\u5BA2\u8F66 319',
        '318':'\u7A7A\u4E2D\u5BA2\u8F66 318',
        '70M':'\u6CE2\u97F3 707',
        'Jet':'ure Jet',
        'PAC':'IPER CHEROKEE',
        'CHG':'HG',
        '313':'\u7A7A\u4E2D\u5BA2\u8F66 310-300',
        '312':'\u7A7A\u4E2D\u5BA2\u8F66 310-200',
        'ATT':'TT',
        '310':'\u7A7A\u4E2D\u5BA2\u8F66 310',
        '70F':'\u6CE2\u97F3 707',
        'ATR':'TR42/72 TURBOPROP',
        'BNI':'RITTEN NORAM BN-2A/BN-2B ISLANDER PROPELLER',
        'ATP':'\u82F1\u56FD\u5B87\u822AATP',
        'SFW':'AAB 340',
        'PA6':'IPER AERO STAR 601',
        'YS1':'IHON YS-11 TURBOPROP',
        'J4U':'4U',
        'PA2':'IPER\uFF08\u8F7B\u578B\u98DE\u673A - \u53CC\u6D3B\u585E\u5F15\u64CE\uFF09\u6DA1\u8F6E\u87BA\u65CB\u6868',
        'DAM':'ASSAULT BREGUET MERCURE',
        'PA1':'IPER\uFF08\u8F7B\u578B\u98DE\u673A - \u5355\u6D3B\u585E\u5F15\u64CE\uFF09\u6DA1\u8F6E\u87BA\u65CB\u6868',
        'ATD':'TR42-400 TURBOPROP',
        '707':'\u6CE2\u97F3 707',
        '703':'\u6CE2\u97F3 707-320B/320C',
        'F50':'\u798F\u514B 50 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'SFA':'FA',
        'AT7':'TR 72 TURBOPROP',
        'AT6':'ATR TURBOPROP',
        'AT5':'TR 42-500 TURBOPROP',
        'J4A':'4A',
        'AT4':'TR 42-300/320 TURBOPROP',
        'SF3':'AAB 340 TURBOPROP',
        'I9Y':'\u4F0A\u5C14 II-96T',
        'J41':'\u82F1\u56FD\u5B87\u822A STREAM 41 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'I9X':'\u4F0A\u5C14 II-96-300',
        'I9M':'\u4F0A\u5C14 II-96M',
        'DY4':'Y4',
        'ART':'RT',
        'ERJ':'\u5DF4\u897F EMBRAER RJ135/RJ140/RJ145',
        'DY1':'Y1',
        'I9F':'\u4F0A\u5C14 II-96',
        'MR4':'R4',
        'ERD':'\u5DF4\u897F EMBRAER RJ140',
        'ARJ':'\u82F1\u56FD\u5B87\u822ARJ7',
        'M95':'\u9EA6\u9053 MD-95',
        'J32':'ETSTREAM 32',
        'M90':'\u9EA6\u9053 MD-90',
        'J31':'\u82F1\u56FD\u5B87\u822A STREAM 31 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'EQV':'quipment Varies',
        'I93':'\u4F0A\u5C14 II-96-300',
        'ER4':'\u5DF4\u897F EMBRAER RJ145',
        'ER3':'\u5DF4\u897F EMBRAER RJ135',
        'AR9':'AR95',
        'AR8':'J85-REGIONAL  85',
        'AR7':'J70-REGIONAL  70',
        'E95':'\u5DF4\u897F EMBRAER 195 T\u6DA1\u8F6E\u87BA\u65CB\u6868',
        'E90':'MBRAER EMB E90',
        'AR1':'vro RJ100 Avroliner',
        'RJA':'JA',
        '23S':'\u7A7A\u4E2D\u5BA2\u8F66 A318',
        'M88':'\u9EA5\u9053\u516C\u53F8 MD-88',
        'M87':'\u9EA6\u9053 MD-87',
        'FK7':'\u5F17\u5C14\u67F4\u5C14\u5FB7\u5DE5\u4E1A FH-227 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'M83':'\u9EA6\u9053 MD-83',
        'M82':'\u9EA6\u9053 MD-82',
        'M81':'\u9EA6\u9053 MD-81',
        'M80':'\u9EA6\u9053 MD-80',
        'F28':'\u798F\u514B F28 \u4F19\u4F34',
        'F27':'\u798F\u514B F27 \u4F19\u4F34/ \u5F17\u5C14\u67F4\u5C14\u5FB7\u5DE5\u4E1A F-27',
        'F24':'\u798F\u514B F28 \u4F19\u4F34 4000',
        'F23':'\u798F\u514B F28 \u4F19\u4F34 3000',
        'F22':'\u798F\u514B F28 \u4F19\u4F34 2000',
        'TUS':'upolev TU-144',
        'F21':'\u798F\u514B F28 \u4F19\u4F34 1000',
        'CCX':'OMDARDIER BD-700 GLOBAL EXPRESS',
        'APH':'\u6B27\u6D32\u76F4\u5347\u673A(AEROSPATIALE) SA330 PUMA/AS332 SUPER PUMA',
        'LV2':'V2',
        'CD2':'OVERNMENT AIRCRAFT FACTORIES N22B/N24A TURBOPROP',
        'TU5':'\u56FE\u6CE2\u5217\u592BTU-134',
        'CCJ':'ANANDAIR CL-600/601/604 CHALLENGER',
        'TU3':'\u56FE\u6CE2\u5217\u592BTU-134',
        'F10':'10',
        'TU2':'upolev TU-124',
        'E75':'MBRAER EMB 175',
        'B15':'\u82F1\u56FD\u5B87\u822A ONE-ELEVEN 500/560',
        'B14':'\u82F1\u56FD\u5B87\u822A ONE-ELEVEN 400/475',
        'B13':'\u82F1\u56FD\u5B87\u822A ONE-ELEVEN 300',
        'B12':'\u82F1\u56FD\u5B87\u822A ONE-ELEVEN 200',
        'E70':'70 \u55B7\u6C14\u5F0F\u98DE\u673A',
        'B11':'\u82F1\u56FD\u5B87\u822A ONE-ELEVEN',
        'YN7':'\u897F\u5B89\u8FD0\u8F93\u673A Y7/MA-60 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'MO2':'OONEY MARK 20A',
        'YN5':'N5',
        'YN2':'\u54C8\u5C14\u6EE8\u8FD0\u8F93\u673A Y12 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'VGP':'RITISH AEROSPACE VANGUARD',
        'HU5':'UGHES 500',
        'TSL':'LEEPER TRAIN',
        '7C4':'\u6CE2\u97F3 747',
        'SA2':'A2',
        '7C3':'\u6CE2\u97F3 767',
        'ANF':'\u5B89\u4E1C\u8BFA\u592B AN-12',
        'RFS':'\u5730\u9762\u8BBE\u5907 - \u9646\u8DEF\u63A5\u9A73\u670D\u52A1\uFF08\u8D27\u8F66\uFF09',
        'CAN':'ESSNA\uFF08\u8F7B\u578B\u98DE\u673A\uFF09',
        'A5F':'\u5B89\u4E1C\u8BFA\u592B AN-225',
        'TRS':'ighSpeed Train',
        'BH2':'ELL  RANGER HELICOPTER',
        'HSM':'RITISH AEROSPACE HAWKER SIDDELLY',
        'AN7':'\u5B89\u4E1C\u8BFA\u592B AN-72/AN-74',
        'AN6':'\u5B89\u4E1C\u8BFA\u592B AN-26/AN-30/AN-32',
        'TRN':'\u5730\u9762\u8BBE\u5907 - \u706B\u8F66',
        'EMJ':'\u5DF4\u897F EMBRAER 170/195',
        'AN4':'\u5B89\u4E1C\u8BFA\u592B AN-24',
        'AN2':'N2',
        'PRP':'ROPELLER-UNDEFINED',
        'VF6':'FW FOKKER VFW 614',
        'EMB':'\u5DF4\u897F EMBRAER 110 BANDEIRANTE \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'TRD':'AWKER SIDDELEY TRIDENT',
        'ILW':'\u4F0A\u5C14 II-86',
        'EM9':'mbraer RJ190',
        'HS7':'\u82F1\u56FD\u5B87\u822A (\u970D\u514B\u897F\u5FB7\u91CC) 748/ANDOVER',
        'EM7':'mbraer RJ170',
        'LRJ':'EAR',
        'A4F':'\u5B89\u4E1C\u8BFA\u592B AN-124 RUSLAN',
        'EM4':'MBRAER EMB 145',
        'EM2':'\u5DF4\u897F EMBRAER 120 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'WWP':'\u4EE5\u8272\u5217\u98DE\u673A\u5DE5\u4E1A 1124 \u897F\u98CE \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'SWW':'WW',
        'D9X':'\u9EA6\u9053 DC9-10',
        'PR1':'awker 390',
        '14Z':'\u82F1\u56FD\u5B87\u822A146-300',
        'VE6':'FW-FOKKER 614',
        'D9S':'\u9EA5\u9053\u516C\u53F8 DC-9',
        '14Y':'\u82F1\u56FD\u5B87\u822A146-200',
        'YK4':'\u96C5\u514BYAKOVLEV YAK-40',
        'SWM':'AIRCHILD TURBOPROP',
        'IL9':'\u4F0A\u5C14 II-96',
        '14X':'\u82F1\u56FD\u5B87\u822A146-100',
        'IL8':'\u4F0A\u5C14 II-18',
        'YK2':'\u96C5\u514BYAKOVLEV YAK-42/YAK-142',
        'IL7':'\u4F0A\u5C14 II-76',
        'IL6':'\u4F0A\u5C14 II-62',
        '7A3':'\u6CE2\u97F3 767',
        'A40':'\u5B89\u4E1C\u8BFA\u592B AN-140',
        'IL4':'L4',
        'D9F':'\u9EA6\u9053 DC9',
        'BET':'EECHCRAFT AIRLINER',
        'D9D':'\u9EA6\u9053 DC9-40',
        'BES':'EECHCRAFT 1900C SERIES AIRLINER',
        'D9C':'\u9EA6\u9053 DC9-30',
        'BEQ':'BEECHCRAFT QUEEN AIR',
        'WW2':'ESTWIND',
        '14F':'\u82F1\u56FD\u5B87\u822A146',
        'BEP':'EECHCRAFT AIRLINER',
        'BEO':'BEECHCRAFT TWIN BONANZA',
        'BEK':'EK',
        'D8Y':'\u9EA6\u9053 DC8-71/72/73',
        'D8X':'\u9EA6\u9053 DC8-61/62/63',
        'BEH':'EECHCRAFT 1900D SERIES AIRLINER',
        'VCX':'ICKERS VC10',
        'SW2':'W2',
        'A38':'\u5B89\u4E1C\u8BFA\u592B AN-38',
        'VCV':'\u82F1\u56FD\u5B87\u822A\uFF08\u7EF4\u514B\u65AF\uFF09\u5B50\u7235 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'D8T':'\u9EA6\u9053 DC8-50',
        'D95':'\u9EA6\u9053 DC9-50',
        'NDH':'\u6B27\u6D32\u76F4\u5347\u673A (AEROSPATIALE) SA365C/SA365N DAUPHIN 2 HELICOPTER',
        'D8S':'\u9EA5\u9053\u516C\u53F8 DC-8',
        'D94':'\u9EA6\u9053 DC9-40',
        'BEC':'EECHCRAFT TURBOPROP',
        'D93':'\u9EA5\u9053\u516C\u53F8 DC-9',
        'BEB':'BEECHCRAFT BARON',
        'D8Q':'\u9EA6\u9053 DC8-72',
        'D92':'\u9EA6\u9053 DC9-20',
        'NDE':'\u6B27\u6D32\u76F4\u5347\u673A (AEROSPATIALE) AS350 ECUREUIL/AS355 EUREUIL 2 HELICOPTER',
        'A32':'\u5B89\u4E1C\u8BFA\u592B AN-32',
        'D91':'\u9EA6\u9053 DC9-10',
        '146':'\u82F1\u56FD\u5B87\u822A146',
        'NDC':'EROSPATIALE SN601 CORVETTE',
        'A30':'\u5B89\u4E1C\u8BFA\u592B AN-30',
        'D8M':'\u9EA6\u9053 DC8-62 T',
        '143':'\u82F1\u56FD\u5B87\u822A146-300',
        'D8L':'\u9EA6\u9053 DC8-62',
        '142':'\u82F1\u56FD\u5B87\u822A146-200',
        '141':'\u82F1\u56FD\u5B87\u822A146-100',
        'M1M':'\u9EA5\u9053\u516C\u53F8 MD-11',
        'BE9':'E9',
        'BE8':'BEECHCRAFT 18',
        'D8F':'\u9EA6\u9053 DC8',
        'BE4':'awker 400',
        'CWC':'urtiss Wright C46 Commando',
        'M1F':'\u9EA6\u9053 MD-11',
        'BE2':'eechcraft BE 200 Super Kingair',
        'D8A':'\u9EA6\u9053 DC8',
        'BE1':'EECHCRAFT BE100 SERIES TURBOPROP',
        'ND2':'EROSPATIALE NORD 262 TURBOPROP',
        'HPJ':'PJ',
        'HPH':'PH',
        'CVY':'ONVAIR 580/5800/600/640',
        'CVX':'ONVAIR 340/440',
        'A28':'\u5B89\u4E1C\u8BFA\u592B AN-28/PZL MIELEC M-28 SKYTRUCK',
        'CVV':'ONVAIR 240',
        'D86':'\u9EA6\u9053 DC8',
        'A26':'\u5B89\u4E1C\u8BFA\u592B AN-26',
        'D85':'\u9EA6\u9053 DC8',
        'CVR':'ONVAIR 240/340/440/580',
        'DPH':'E HAVILLAND DHJC-2',
        'A22':'\u5B89\u4E1C\u8BFA\u592B AN-22',
        'MIH':'IKHAIL MIL MI-8/MI-17/MI-171/MI-172 HELICOPTER',
        'M11':'\u9EA6\u9053 MD-11',
        'LOM':'\u6D1B\u514B\u5E0C\u5FB7\u5747\u7B49 L-188 ELECTRA TER \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'HOV':'\u5730\u9762\u8BBE\u5907 - \u6C14\u57AB\u8239',
        'CVF':'ONVAIR 240/340/440/580/5800/600/640',
        'I14':'\u4F0A\u5C14 II-114',
        'LOH':'\u6D1B\u514B\u5E0C\u5FB7 L-182/L-282/L-382 (L-100)\u5927\u529B\u58EB \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'LOF':'\u6D1B\u514B\u5E0C\u5FB7 L-188 ELECTRA\u8D27\u673A \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'LOE':'\u6D1B\u514B\u5E0C\u5FB7 L-188 ELECTRA\u5BA2\u673A \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'SU9':'UKHOI SUPERJET',
        'DOS':'OS',
        'MI8':'I8',
        'CV8':'onvair 880',
        'CV6':'onvair',
        'CV5':'ONVAIR 580',
        'CV4':'ONVAIR 440 METROPOLITAN',
        'T5M':'U-154M',
        'CV2':'ONVAIR 240',
        'D6F':'\u9EA6\u9053 DC6A/DC6B/DC6C',
        'DO8':'O8',
        'PN6':'ARTENAVIA P.68 PROPELLER',
        'LMO':'\u5730\u9762\u8BBE\u5907 - \u8C6A\u534E\u8F7F\u8F66',
        'SSC':'EROSPATIALE/ \u82F1\u56FD\u5B87\u822A\u8D85\u97F3\u901F\u98DE\u673A',
        'L4T':'ET L410 TURBOLET',
        'BAT':'AT',
        'D4Y':'4Y',
        'BAC':'erospatiale/BAC Concorde',
        'FA7':'AIRCHILD DORNIER 728',
        'AGH':'GH',
        'L49':'\u6D1B\u514B\u5E0C\u5FB7 L-749 \u661F\u5EA7 / L-1049 \u8D85\u7EA7\u661F\u5EA7',
        'D4J':'4J',
        '100':'\u798F\u514B100',
        'GRS':'ULFSTREAM AEROSPACE \uFF08\u683C\u9C81\u66FC\uFF09G-159 GULFSTREAM I',
        'PL6':'ILATUS PC-6 TURBO PORTER TURBOPROP',
        'GRM':'\u683C\u9C81\u66FC G-73 TURBO MALLARD (AMPHIBIAN)',
        'CRV':'EROSPATIALE SE210 CARAVELLE',
        'PL2':'ILATUS PC-12 TURBOPROP',
        'CS5':'ASA/IPTN CN-235 TURBOPROP',
        'GRJ':'ULFSTREAM AEROSPACE\uFF08\u683C\u9C81\u66FC\uFF09GULFSTREAM II/III/IV/V',
        'CS2':'ASA/IPTN 212 AVIOCAR TURBOPROP',
        'D42':'DN DND DASH 8',
        'GRG':'\u683C\u9C81\u66FC G-21 GOOSE (AMPHIBIAN)',
        'GRA':'RA',
        'CRJ':'ANADAIR REGIONAL',
        'S8L':'\u9EA5\u9053\u516C\u53F8 MD-80',
        '7SB':'\u6CE2\u97F3 747',
        'D3F':'\u9EA6\u9053 DC3',
        'D3B':'3B',
        'CRA':'ANADAIR CRJ SERIES 705',
        'MDZ':'DZ',
        'CR9':'ANADAIR REGIONAL  900',
        'D38':'AIRCHILD DORNIER 328-100 TURBOPROP',
        'CR7':'ANADAIR REGIONAL  700',
        'T20':'\u56FE\u6CE2\u5217\u592BTU-204/TU-214',
        'CR4':'R4',
        'CR2':'anadair Regional 200',
        'CR1':'ANADAIR REGIONAL  100',
        'S83':'\u9EA5\u9053\u516C\u53F8 MD-80',
        'S80':'\u9EA5\u9053\u516C\u53F8 MD-80',
        '7RB':'\u6CE2\u97F3 747',
        'L1O':'1O',
        'H29':'awker 900 XP',
        'H28':'awker 850XP',
        'H25':'\u970D\u514B\uFF08\u970D\u514B\u897F\u5FB7\u91CC/\u82F1\u56FD\u5B87\u822A125\uFF09',
        'H24':'awker 4000',
        'MD9':'\u9EA6\u9053\u76F4\u5347\u673A 900 EXPLORER',
        'DK4':'K4',
        'MD8':'D8',
        'H21':'awker 1000',
        'DK2':'K2',
        'D1Y':'\u9EA6\u9053 DC10-30/40',
        'L1F':'\u6D1B\u514B\u5E0C\u5FB7 L-1011 \u4E09\u661F',
        'H20':'awker 200',
        'DK1':'K1',
        'D1X':'\u9EA6\u9053 DC10-10',
        'D28':'AIRCHILD DORNIER 228 TURBOPROP',
        'L1A':'1A',
        'S76':'\u7F8E\u56FD\u897F\u79D1\u65AF\u57FA S-76 \u76F4\u5347\u673A',
        'TIC':'NTERCITY TRAIN',
        'D1M':'\u9EA6\u9053 DC10-30',
        'L19':'19',
        'D1J':'1J',
        'D1H':'1H',
        'L15':'\u6D1B\u514B\u5E0C\u5FB7 L-1011 \u4E09\u661F',
        '7QB':'\u6CE2\u97F3 747',
        'D1F':'\u9EA6\u9053 DC10',
        'L12':'ockheed L1011',
        'L11':'\u6D1B\u514B\u5E0C\u5FB7 L-1011 \u4E09\u661F 1/50/100/150/200/250',
        'D1C':'\u9EA6\u9053 DC10-30/40',
        'THT':'OTEL TRAIN',
        'L10':'\u6D1B\u514B\u5E0C\u5FB7 L-1011 \u4E09\u661F',
        'THS':'RAIN A GRAND VITESSE',
        'ICE':'NTER-CITY EXPRESS',
        'AD7':'D7',
        'ACT':'WIN (AERO) TURBO COMMANDER/PROP COMMANDER',
        '38F':'\u7A7A\u4E2D\u5BA2\u8F66 380-800',
        'ACP':'WIN (AERO) COMMANDER',
        '77X':'\u6CE2\u97F3 767-777',
        '788':'\u6CE2\u97F3 787-8',
        '789':'\u6CE2\u97F3 787-9',
        '77W':'\u6CE2\u97F3 767-777',
        'D14':'14',
        '787':'\u6CE2\u97F3 787',
        'D13':'13',
        'D11':'\u9EA6\u9053 DC10-10/15',
        'D10':'\u9EA6\u9053 DC10',
        'S61':'\u7F8E\u56FD\u897F\u79D1\u65AF\u57FA S-58T \u76F4\u5347\u673A',
        'MBH':'\u6B27\u6D32\u76F4\u5347\u673A (MBB) BO105',
        'ACD':'WIN (AERO) COMMANDER/TURBO COMMANDER/PROP COMMANDER',
        'BUS':'\u5730\u9762\u8BBE\u5907 - \u5BA2\u8F66',
        '388':'\u7A7A\u4E2D\u5BA2\u8F66 A380-800',
        '77L':'\u6CE2\u97F3 767-777',
        'TGV':'RAIN A GRAND VITESSE',
        '380':'\u7A7A\u4E2D\u5BA2\u8F66 380-800',
        'ABY':'\u7A7A\u4E2D\u5BA2\u8F66 300-600',
        'ABX':'\u7A7A\u4E2D\u5BA2\u8F66 300B4/300C4/300F4',
        'JU5':'UNKERS JU 52/3M',
        'DHT':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-6 TWIN OTTER \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'DHS':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-3 OTTER \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'DHR':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-2 TURBO BEAVER \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'DHP':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-2 BEAVER \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'D09':'\u9EA5\u9053\u516C\u53F8 D09',
        'DHO':'HO',
        'DHM':'EHAVILLAND DASH M',
        '76Y':'\u6CE2\u97F3 767-300',
        'S58':'\u7F8E\u56FD\u897F\u79D1\u65AF\u57FA S-58T \u76F4\u5347\u673A',
        'DHL':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-3 TURBO OTTER \u6DA1\u8F6E\u87BA\u65CB\u6868',
        '76X':'\u6CE2\u97F3 767-200',
        'ABM':'BM',
        'CNT':'ESSNA \uFF08\u8F7B\u578B\u98DE\u673A - \u53CC\u6DA1\u8F6E\u87BA\u65CB\u6868\u5F15\u64CE\uFF09',
        '76W':'\u6CE2\u97F3 767-300',
        '777':'\u6CE2\u97F3 777',
        'DHH':'\u82F1\u56FD\u5B87\u822A \u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 114 HERON',
        'MZD':'ZD',
        '76S':'\u6CE2\u97F3 767',
        '773':'\u6CE2\u97F3 777-300',
        'CNN':'ESSNA CARIVAN 1 TURBO JET',
        '772':'\u6CE2\u97F3 777-200',
        'ABF':'\u7A7A\u4E2D\u5BA2\u8F66 300',
        'DHD':'\u82F1\u56FD\u5B87\u822A \u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 104 DOVE',
        'DHC':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-4 CARIBOU \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'DHB':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-2 BEAVER/TURBO BEAVER \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'CNJ':'ESSNA CITATION',
        'ABB':'\u7A7A\u4E2D\u5BA2\u8F66 300-600ST BELUGA',
        '76K':'\u6CE2\u97F3 767',
        'CNG':'NG',
        'CNC':'ESSNA\uFF08\u8F7B\u578B\u98DE\u673A - \u5355\u6DA1\u8F6E\u87BA\u65CB\u6868\u5F15\u64CE\uFF09',
        '76F':'\u6CE2\u97F3 767',
        '76E':'\u6CE2\u97F3 767',
        'CNA':'NA',
        'DH8':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-8 DASH 8 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'JST':'\u82F1\u56FD\u5B87\u822A STREAM \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'DH7':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-7 DASH 7 \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'AB6':'\u7A7A\u4E2D\u5BA2\u8F66 300-600',
        'DH4':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-8-400 DASH 8Q \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'DH3':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-8-300 DASH 8/8Q \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'AB4':'\u7A7A\u4E2D\u5BA2\u8F66 300B2/300B4',
        'DH2':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-8-200 DASH 8/8Q \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'AB3':'\u7A7A\u4E2D\u5BA2\u8F66 300',
        'DH1':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-8-100 DASH 8/8Q \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'DH0':'\u5FB7\u30FB\u54C8\u7EF4\u5170\u5FB7 DHC-3 OTTER/TURBO OTTER \u6DA1\u8F6E\u87BA\u65CB\u6868',
        'WLH':'LH',
        '75W':'\u6CE2\u97F3 757-200',
        'CN4':'N4',
        '767':'\u6CE2\u97F3 767',
        'CN2':'ESSNA\uFF08\u8F7B\u578B\u98DE\u673A - \u53CC\u6D3B\u585E\u5F15\u64CE\uFF09',
        '75T':'\u6CE2\u97F3 757-300',
        'CN1':'ESSNA\uFF08\u8F7B\u578B\u98DE\u673A - \u5355\u6D3B\u585E\u5F15\u64CE\uFF09',
        '764':'\u6CE2\u97F3 767-400',
        '763':'\u6CE2\u97F3 767-300',
        '75R':'\u6CE2\u97F3 757',
        '762':'\u6CE2\u97F3 767-200',
        'K4A':'\u6CE2\u97F3 747',
        '75N':'\u6CE2\u97F3 757',
        '75M':'\u6CE2\u97F3 757-200 SUD',
        '75F':'\u6CE2\u97F3 757-200',
        '74Z':'\u6CE2\u97F3 747',
        '74Y':'\u6CE2\u97F3 747-400',
        'DFL':'ASSAULT FALCON',
        '74X':'\u6CE2\u97F3 747-200',
        'TEE':'RANS-EUROPE EXPRESS',
        '757':'\u6CE2\u97F3 757',
        '74V':'\u6CE2\u97F3 747SR',
        '756':'\u6CE2\u97F3 757',
        '74U':'\u6CE2\u97F3 747-300/747-200 SUD',
        '755':'\u6CE2\u97F3 757',
        '74T':'\u6CE2\u97F3 747-100',
        '74S':'\u6CE2\u97F3 747',
        '753':'\u6CE2\u97F3 757-300',
        '74R':'\u6CE2\u97F3 747SR',
        '752':'\u6CE2\u97F3 757-200',
        'FRJ':'AIRCHILD AEROSPACE 328',
        'C3S':'3S',
        '74N':'\u6CE2\u97F3 741',
        '74M':'\u6CE2\u97F3 747',
        '74L':'\u6CE2\u97F3 747 SP \u957F\u9014',
        '74J':'\u6CE2\u97F3 747-400\uFF08\u56FD\u5185\uFF09',
        '74H':'\u6CE2\u97F3 747-8',
        '74F':'\u6CE2\u97F3 747',
        '34N':'\u6CE2\u97F3 737-400',
        '74E':'\u6CE2\u97F3 747-400',
        '74D':'\u6CE2\u97F3 747-300 SUD',
        '74C':'\u6CE2\u97F3 747-200',
        'HEL':'ELICOPTER-UNDEFINED',
        'DF3':'ASSAULT FALCON 50/900',
        'DF2':'ASSAULT FALCON 10/100/20/200/2000',
        '34C':'\u7A7A\u4E2D\u5BA2\u8F66 A340',
        '73Y':'\u6CE2\u97F3 737-300',
        'HEC':'ELIO H-250 COURIER/H-295/395 SUPER COURIER',
        'FR4':'airchild 428',
        '73X':'\u6CE2\u97F3 737-200',
        'FR3':'airchild 328',
        '73W':'\u6CE2\u97F3 737-700',
        'CL4':'ANANDAIR CL-44D4 TURBOPROP',
        '747':'\u6CE2\u97F3 747',
        '744':'\u6CE2\u97F3 747-400',
        '73S':'\u6CE2\u97F3 737-200',
        '743':'\u6CE2\u97F3 747 SUD',
        '73R':'\u6CE2\u97F3 737-700',
        '742':'\u6CE2\u97F3 747-200',
        '73Q':'\u6CE2\u97F3 737-400',
        'S20':'AAB 2000 TURBOPROP',
        '741':'\u6CE2\u97F3 747-100',
        '73M':'\u6CE2\u97F3 737-200',
        '346':'\u7A7A\u4E2D\u5BA2\u8F66 340-600',
        '73L':'\u6CE2\u97F3 737',
        '345':'\u7A7A\u4E2D\u5BA2\u8F66 340-500',
        '73J':'\u6CE2\u97F3 737',
        '343':'\u7A7A\u4E2D\u5BA2\u8F66 340-300',
        '73I':'\u6CE2\u97F3 737-800',
        '342':'\u7A7A\u4E2D\u5BA2\u8F66 340-200',
        '73H':'\u6CE2\u97F3 737-800',
        '73G':'\u6CE2\u97F3 737-700',
        '340':'\u7A7A\u4E2D\u5BA2\u8F66 340',
        '73F':'\u6CE2\u97F3 737',
        '73E':'\u6CE2\u97F3 737-500',
        '73C':'\u6CE2\u97F3 737-300',
        '73B':'\u6CE2\u97F3 737',
        '73A':'\u6CE2\u97F3 737-200',
        'TCM':'OMMUTER TRAIN',
        '72Y':'\u6CE2\u97F3 727-200',
        '72X':'\u6CE2\u97F3 727-100',
        '739':'\u6CE2\u97F3 737-900',
        '738':'\u6CE2\u97F3 737-800',
        '737':'\u6CE2\u97F3 737',
        '736':'\u6CE2\u97F3 737-600',
        '735':'\u6CE2\u97F3 737-500',
        '359':'\u6CE2\u97F3 737-900',
        '72S':'\u6CE2\u97F3 727-200',
        '734':'\u6CE2\u97F3 737-400',
        '72R':'\u6CE2\u97F3 727',
        '733':'\u6CE2\u97F3 737-300',
        '732':'\u6CE2\u97F3 737-200',
        '731':'\u6CE2\u97F3 737-100',
        '72O':'\u6CE2\u97F3 727',
        '72N':'\u6CE2\u97F3 727',
        '32V':'\u7A7A\u4E2D\u5BA2\u8F66 A320',
        '72M':'\u6CE2\u97F3 727',
        '32S':'\u7A7A\u4E2D\u5BA2\u8F66 318/319/320/321',
        '333':'\u7A7A\u4E2D\u5BA2\u8F66 330-300',
        '332':'\u7A7A\u4E2D\u5BA2\u8F66 330-200',
        'LCH':'\u5730\u9762\u8BBE\u5907 - \u6E38\u8247/\u8239\u53EA',
        '32P':'\u7A7A\u4E2D\u5BA2\u8F66 A320',
        '330':'\u7A7A\u4E2D\u5BA2\u8F66 330',
        '72F':'\u6CE2\u97F3 727',
        '72B':'\u6CE2\u97F3 727',
        '72A':'\u6CE2\u97F3 727',
        'F7B':'7B',
        'SHS':'\u8096\u7279 SHORTS BROS SKYVAN (SC-7) \u6DA1\u8F6E\u87BA\u65CB\u6868',
        '7JS':'\u6CE2\u97F3 747',
        'SHP':'HP',
        '32B':'\u7A7A\u4E2D\u5BA2\u8F66 A321',
        '32A':'\u7A7A\u4E2D\u5BA2\u8F66 A320',
        '727':'\u6CE2\u97F3 727',
    }
};

export default {
    messages
}

<script setup>
import { reactive, ref, watch } from "vue";
import HotelCheckOutRoomPassengerFormItem from "@/views/v2/sales/hotel/items/form/checkout/HotelCheckOutRoomPassengerFormItem.vue";
import { useProfileStore } from "@/stores/profile";
import commonUtils from "@/utils/commonUtils";

const props = defineProps({
  roomPassengerItems: {},
  bedTypeName: {},
  equipmentType: {},
});

const roomPassengerItemsRef = ref([]);

const state = reactive({
  upStatusList: [],
});
watch(
  () => props.roomPassengerItems,
  (newValue) => {
    roomPassengerItemsRef.value = newValue;

    if (newValue && newValue.length > 0) {
      newValue.forEach(() => {
        state.upStatusList.push(true);
      });
    }
  },
  { deep: true, immediate: true }
);

function changeUpStatus(index) {
  state.upStatusList[index] = !state.upStatusList[index];
}

// region additional traveller
const user = JSON.parse(sessionStorage.getItem("user"));
const profileStore = useProfileStore();
profileStore.$reset(); //重置状态

if (user && user.partyId) loadUserProfile();
const profileViewItemsRef = ref(null);

function loadUserProfile() {
  profileStore.loadUserProfile(user.partyId);
}

watch(
  () => profileStore.profileViewItem,
  (newValue) => {
    const profile = JSON.parse(JSON.stringify(newValue));
    if (profile) {
      const additionalTravellers = profile.additionalTravellers;
      const partyIds = [user.partyId];
      //将这些人的信息全部load出来
      if (additionalTravellers && additionalTravellers.length > 0) {
        additionalTravellers.forEach((additionalTraveller) => {
          const partyId = additionalTraveller.partyIdFrom;
          partyIds.push(partyId);
        });
      }
      profileStore.loadAdditionalTravellersProfile(partyIds);
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => profileStore.profileViewItems,
  (newValue) => {
    profileViewItemsRef.value = commonUtils.sortByParams(newValue, "surname");
  },
  { immediate: true, deep: true }
);

//endregion additional traveller
</script>

<template>
  <div class="tou_booking_form_Wrapper">
    <div class="booking_tour_form">
      <div
        v-if="roomPassengerItemsRef && roomPassengerItemsRef.length > 0"
        class="tour_booking_form_box"
      >
        <h3 class="heading_theme">
          {{ $t("guest-info") }}
        </h3>

        <!-- Room Passenger Form -->
        <HotelCheckOutRoomPassengerFormItem
          v-for="(roomResGuest, roomIndex) in roomPassengerItemsRef"
          :key="roomIndex"
          :equipment-type="equipmentType"
          :profile-view-items="profileViewItemsRef"
          :res-guests="roomResGuest.resGuests"
          :room-index="roomIndex"
          :sub-title="roomResGuest.bedTypeName"
          :title="roomResGuest.roomTypeName + ' ' + (roomIndex + 1)"
        >
          <template v-slot:content>
            <a
              class="main_color"
              href="javascript:void(0)"
              @click="changeUpStatus(roomIndex)"
              >{{ $t("special-requests-optional") }}
              <i
                v-if="state.upStatusList[roomIndex]"
                class="fa fa-angles-up fa-xs"
              ></i
              ><i v-else class="fa fa-angles-down fa-xs"></i
            ></a>
            <textarea
              v-show="!state.upStatusList[roomIndex]"
              id="example-textarea-input"
              v-model="roomResGuest.remarks"
              :placeholder="$t('hotel-remarks')"
              class="form-control"
              name="example-textarea-input"
              rows="3"
            ></textarea>
          </template>
        </HotelCheckOutRoomPassengerFormItem>
        <!-- END Room Passenger Form -->
      </div>
    </div>
  </div>
</template>

<style scoped></style>

export default {
  cancel: "取消",
  "print-voucher": "打印凭证",
  "available-credit": "可用信用额度",
  "under-17": "17岁以下",
  "please-enter-a-valid-firstname": "请输入一个有效的名字",
  "select-continue": "选择并继续",
  "how-many-rooms-need": "您需要多少个房间？",
  "order-type": "订单类型",
  "service-name": "服务名称",
  checkin: "到达",
  "hotel-price": "酒店价格",
  "child-less-than": "12 岁以下",
  "show-more-room-rate": "显示更多房价",
  "back-to-dinning": "返回餐饮",
  "who-is-traveling": "谁在旅行？",
  "applicant-personal-details": "申请人#{index}个人详细信息",
  "special-requests-optional": "特殊要求（可选）",
  excellent: "极好的",
  "number-of-stops": "停靠站数量",
  "confirm-your-choices": "确认您的订单",
  pool: "水池",
  "how-many-guests-each-room": "每间客房将容纳多少位客人？",
  condo: "公寓",
  "passport-information": "护照信息",
  economy: "经济舱",
  "our-partners": "我们的伙伴",
  "first-name": "名",
  "available-dates": "可用日期",
  "search-by-destination": "按目的地搜索",
  nationality: "国籍",
  "error-500-message": "很抱歉，我们的服务器遇到了内部错误",
  unticketed: "无票",
  "flight-list": "航班列表",
  "meal-plans-available": "提供餐饮",
  "passenger-info": "乘客信息",
  aboard: "船上",
  adult: "成人",
  "next-passenger": "下一位乘客",
  "taxes-total": "税金总计",
  "bag-allow": "允许携带行李",
  amenities: "便利设施",
  "error-503-message": "很抱歉，我们的服务目前不可用",
  flight: "机票",
  "pick-return-location": "接送地点",
  Visa: "签证",
  "flight-price": "航班价格",
  "property-type": "财产种类",
  "info-source": "信息来源：{supplierCode}",
  "number-of-entries": "入境次数",
  search: "搜索",
  "traveler-name": "旅客姓名",
  "hotel-location": "酒店位置",
  "type-of-vehicle": "什么类型的车辆？",
  hotel: "酒店",
  "expect-to-wait": "乘客等待时间",
  "departure-to-return": "出发返回",
  "hotel-name": "酒店名称",
  close: "关闭",
  "price-summary": "价格汇总",
  "flight-number": "航班号",
  "number-of-nights": "入住晚数",
  "confirm-booking": "确认预订",
  address: "地址",
  "car-rental": "汽车出租",
  business: "商业",
  "price-per-applicant": "每位申请人的价格",
  "operation-uppercase": "手术",
  "select-weight-of-baggage": "选择您托运行李的重量",
  "view-terms": "查看条款",
  "apart-hotel": "公寓式酒店",
  "any-uppercase": "任何",
  "guest-rating": "宾客评价",
  register: "登记",
  "agency-portal": "机构门户",
  rooms: "客房",
  "your-trip-details": "您的旅行详情",
  "view-your-booking": "查看您的订单",
  "passport-number": "护照号",
  "choose-your-category-location-room": "选择您的{categoryLocation}房间",
  "reactive-account": "激活账户",
  "finding-driver": "寻找您的司机",
  chinese: "中文",
  "charge-type": "收费类型",
  "check-out-your-choices": "查看您的订单",
  contact: "联系",
  "your-personal-details": "你个人详细资料",
  airlines: "航空公司",
  radius: "半径",
  info: "信息",
  "please-enter-your-username": "请输入您的用户名",
  "apply-now-for-document": "立即申请{toCountryName}旅行证件",
  journey: "旅程",
  "passenger-room-class": "旅客、房间、舱位",
  "room-price-total": "房价总计",
  "trip-total": "行程总计",
  "car-transfer": "专车接送",
  "add-check-in-baggage": "添加托运行李",
  "room-index": "房间 - 序号",
  "max-stay": "最多停留时间",
  "fare-family": "票价系列",
  "agree-to-terms-conditions": "我同意条款和条件状况",
  "all-bookings": "所有预订",
  "infant-price": "婴儿价格",
  "booking-in-process": "预订中",
  "price-info": "价格信息",
  "please-enter-a-valid-username": "请输入有效的用户名",
  "night-number-high-to-low": "住宿晚数从高到低",
  bookings: "订单",
  "child-index-age": "孩子 {childIndex} 年龄",
  "num-hotel": "  找到 {length} 个酒店",
  "radius-search": "半径搜索",
  "driver-details": "司机详细信息",
  child: "孩子",
  "required-for-travel": "旅行所需",
  unconfirmed: "未确认",
  "please-contact-email": "请联系邮箱",
  "please-enter-a-valid-password": "请输入有效密码",
  "rooms-can-accommodate": "客房最多可容纳 4 位客人",
  "rail-pass-review": "铁路通票审查",
  origin: "起源",
  back: "返回",
  "night-number": "{number} 晚",
  "continue-to-payment": "继续付款",
  title: "标题",
  "select-your-seat": "选择您的座位",
  "please-enter-a-valid-lastname": "请输入有效的姓氏",
  remove: "删除",
  "arrival-time": "到达时间",
  "sign-in-now": "立即登录",
  "go-beyond-your-imagination": "超越你的想象",
  company: "公司",
  "hotel-item": "酒店项目",
  "sign-up": "注册",
  "by-credit-card": "用信用卡",
  "rail-ticket-review": "火车票评论",
  "call-us": "如需任何帮助请致电我们",
  "expiry-date": "到期日期",
  "check-in-date": "入住日期",
  "sign-in-your-account": "登录您的帐户",
  "lower-available-rate": "较低价格",
  "journey-date": "旅程日期",
  "see-details": "查看具体信息",
  "guests-uppercase": "嘉宾",
  room: "房间",
  "cruise-home": "游轮首页",
  home: "首页",
  "flight-class": "飞行等级",
  "more-photos": "更多照片",
  "advance-question": "提前提问",
  "i-agree": "我同意",
  "insurance-home": "保险首页",
  "num-night-stay": "{num} 晚住宿",
  age: "年龄",
  "two-less-than": "2 - 12 岁以下",
  "flight-date": "航班日期",
  select: "选择",
  configurations: "配置",
  discount: "折扣",
  "log-out": "退出登录",
  "fare-rules": "票价规则",
  "total-amount": "总金额",
  "birth-date": "出生日期",
  "error-401-message": "很抱歉，您无权访问此页面",
  supplier: "供应商",
  "for-all-applicants": "对于所有申请人",
  "per-entry": "每个条目",
  "reactive-your-account": "回复您的帐户",
  "product-name": "产品名称",
  "booking-id": "预订编号",
  order: "命令",
  "your-application": "你的申请",
  passengers: "乘客",
  "baggage-option": "行李选项",
  "rate-comments": "评价评论",
  "drivers-can-wait": "司机最多可以等待",
  "each-passenger-can-select": "每位乘客可以选择一个选项。",
  index: "指数",
  "return-location": "返回地点",
  "residency-information": "居住信息",
  "frequent-answer-and-question": "常见的回答和问题",
  "ticketing-success": "票务成功",
  "cruise-itinerary": "邮轮行程",
  cancelled: "取消",
  "search-orders": "搜索订单",
  "airport-shuttle-included": "包括机场班车",
  "room-name-index": "房间 {index} { roomTypeName }",
  status: "地位",
  "today-price": "今日价格",
  "get-latest-news-offers": "获取最新消息和优惠",
  "price-starts-from": "{currency} {amount}价格从",
  "price-range": "价格范围",
  extras: "附加功能",
  "my-profile": "我的简历",
  "hostel-backpacker": "旅馆/背包客旅馆",
  holiday: "假期",
  "back-to-results": "返回结果",
  "privacy-policy": "隐私政策",
  "night-number-low-to-high": "住宿晚数从低到高",
  "common-area": "公共区域",
  "please-enter-a-valid-phone-number": "请输入一个有效的电话号码",
  surname: "姓",
  "your-booking-detail": "您的预订详细信息已发送至：{ emailAddress }",
  "room-uppercase": "房间",
  "stopover-in": "在#{到达机场名称}中途停留#{arrivalDateTime}",
  "bed-type": "床型",
  "cancellation-policy-refundable-caution":
    '在 <span class="text-warning">{beginTime } </span> 至 <span class="text-warning">{endTime} </span>取消您的预订，您将被收取<span class="text-warning">{currency} {amount}</span>。',
  "cancellation-policy-nights-caution":
    '在 <span class="text-warning">{beginTime } </span> 至 <span class="text-warning">{endTime} </span>取消您的预订，您将被收取<span class="font-18 text-danger">{nmbrOfUnit}晚</span>的住宿费用以及税费。',
  "sub-total": "小计",
  "select-the-child-age": "请选择孩子的年龄！",
  "base-fare": "基本做事",
  "basic-rate": "基本费率",
  "guest-total": "客人 { cabPriceIndex } 总计",
  "create-by": "创建者",
  "contact-with-us": "与我们联系",
  "num-passenger": "{passengersNum} 乘客",
  "no-seat-segment": "该航段无法选择座位",
  "copy-right": "版权所有 © 2023 保留所有权利",
  "processing-time-fee": "处理时间和费用",
  "travel-document-book": "旅行证件簿",
  "protections-extras": "附加功能",
  "subscribe-our-newsletter": "订阅我们的新闻",
  "total-uppercase": "全部价格",
  "enter-the-email": "请输入与您的帐户相结合的电子邮件",
  "enter-valid-credential": "请输入有效的凭据",
  "find-available-cruises": "查找可用的游轮",
  "travel-document-option": "旅行证件选项",
  "applying-for": "正在申请",
  fees: "费用",
  "select-your-type-of-inside": "选择您的房间类型",
  traveler: "游客",
  "double-room": "双人间",
  "round-trip": "往返",
  "price-detail": "价格详情",
  "ports-of-call": "停靠港",
  "delivered-at": "接送时间",
  "cabin-price": "舱位价格",
  "special-requests": "特别要求",
  "pax-num": "和平号",
  "discover-your-ideal": "与我们一起探索您的理想体验",
  "stateroom-amenities": "客舱设施",
  "submit-ticket-order": "提交门票订单",
  stopover: "中途停留",
  "pax-type": "乘客类型",
  "after-issued": "办理后",
  stock: "库存",
  "my-bookings": "我的预订",
  all: "全部",
  "pickup-and-return": "往返地点",
  "room-name": "房间名称",
  "show-less": "显示较少",
  "price-low-to-high": "价格：从低到高",
  "cabin-class": "舱位等级",
  "meet-your-driver": " 您的预订确认后，我们将发送与司机会面的分步说明。",
  spa: "温泉",
  "select-your-room": "选择您的房间",
  "add-another-person": "添加另一个人",
  "fill-following-details": "请填写以下详细信息以创建新帐户。",
  "logged-in-to-stay-in-touch": "登录以保持联系",
  "itinerary-review": "行程预览",
  "children-ages": "0至11岁儿童",
  "living-country": "居住的国家",
  "sort-by": "排序方式",
  "activities-list": "活动清单",
  "already-have-an-account": "已经有帐户？",
  position: "位置",
  basic: "基本的",
  "star-rating": "星级",
  "breakfast-included": "包含早餐",
  "same-return-location": "回程地点相同",
  "cabin-type": "舱型",
  exterior: "外部的",
  policies: "政策",
  "passenger-information": "旅客信息",
  "booking-summary": "预订摘要",
  "register-your-account": "注册您的帐户",
  "search-for-an-activities": "搜索活动",
  "where-am-i-from": "我从哪里来？",
  total: "全部的",
  "error-400-message": "您发出了错误的请求，或者请求已损坏并且服务器无法理解它",
  "guest-name": "客人姓名",
  "add-another-flight": "添加另一个航班",
  "are-you-sure-ticketing": "  您确定要出票#{ id } {idContext }吗？",
  reserve: "预订",
  "you-need-visa-travel":
    "如果您持有 {fromCountryName} 的护照，则需要签证才能前往 {toCountryName}",
  "check-in-to-check-out": "入住到退房",
  "remove-person": "删除人员",
  included: "已包含",
  "stay-in-touch": "保持联系",
  "return-time": "返回时间",
  "i-live-in": "我住在",
  or: "或者",
  "price-uppercase": "价格",
  "children-uppercase": "孩子们",
  "booking-success": "预订成功！",
  "arrival-date": "到达日期",
  "extra-service": "额外服务",
  "searching-result": "搜索结果",
  "order-list": "订单",
  any: "任何",
  "contact-information": "联系信息",
  "edit-seat": "编辑座位",
  "booking-preview": "订单预览",
  application: "应用",
  "Avg-person": "平均/人",
  activities: "活动",
  "hotel-detail": "酒店详情",
  "confirmation-number": "确认号码：",
  properties: "条",
  "phone-number": "电话号码",
  "export-to-sabre": "导出至 Sabre",
  "info-uppercase": "信息",
  "name-title": "名称",
  prev: "上一篇",
  "sailings-uppercase": "航行",
  "all-inclusive": "全包",
  "remember-me": "记住账号",
  ship: "船",
  "rail-home": "铁路之家",
  "select-fares": "选择票价",
  "reset-password": "重设密码",
  "hotel-home": "酒店首页",
  "send-mail": "发送邮件",
  "very-good-4.0+": "非常好4.0+",
  "send-message": "发信息",
  "create-date": "创建日期",
  "country-of-residence": "居住国家*",
  PNR: "订座记录",
  english: "英语",
  "choose-your-room": "选择您的房间",
  "view-dates": "{number} 个日期查看 ",
  "go-back": "返回",
  requester: "请求者",
  sleeps: "睡觉",
  "one-way": "单程",
  night: "晚",
  "pick-your-add-ons": "选择您的附加组件",
  "please-enter-a-valid-email": "请输入有效的电子邮件地址",
  "booking-confirm": "预订确认",
  "rate-description": "费率说明",
  "accessorial-service": "配套服务",
  "where-are-you-going": "你要去哪里",
  "print-invoice": "打印发票",
  stops: "停靠点",
  "travel-documents": "旅行证件",
  "guest-rating-our-picks": "宾客评分 + 我们的精选",
  "rail-search-list": "铁路搜索列表",
  "air-item": "航空项目",
  "includes-taxes-fees": "包括税费",
  cabin: "舱",
  "passenger-class": "乘客，舱位",
  "order-status": "订单状态",
  "pick-up": "出发地",
  "please-enter-a-username": "请填入一个用户名",
  "select-segments": "选择细分",
  "wonderful-4.5+": "精彩4.5+",
  "taxes-fees-and-port-expenses": "税费、费用和港口费用",
  "lessons-hours": "10 节课 · 3 小时",
  "total-include-amount": "总计 {currency} {amount}",
  "departure-port": "出发港",
  "quote-type": "报价类型",
  currency: "货币",
  "ticketed-uppercase": "已出票",
  "booking-status": "预订状态",
  email: "电子邮件",
  "last-name": "姓",
  departs: "出发",
  "cancellation-policy": "取消政策",
  protections: "保护措施",
  "pick-up-location": "接人的地方",
  "get-order-prices": "获取订单价格",
  "order-id": "订单编号",
  "adult-price": "成人价",
  "holiday-customise": "假期定制",
  people: "人",
  "forgot-password": "忘记密码",
  "total-due": "应付总额",
  confirm: "确认",
  "explore-this-itinerary": "探索此行程",
  "traveler-info": "旅客信息",
  "learn-html5": "通过 10 个简单易懂的步骤学习 HTML5",
  marketing: "营销",
  "booking-date-range": "预订日期范围",
  "package-flight-hotel": "套票(机票+酒店)",
  "good-3.5+": "好3.5+",
  "given-name": "名",
  to: "到",
  "ticketing-confirmed": "您的票务 #{ TicketId } 已确认",
  "visiting-uppercase": "参观",
  "desk-name": "办公桌名称",
  "explore-our-hot-deals": "探索我们的热门优惠",
  insurance: "保险",
  "expiry-time": "到期时间",
  "car-rental-home": "租车首页",
  "lunch-included": "含午餐",
  "register-now": "现在注册",
  "return-date": "归期",
  "report-it": "举报",
  "less-than-two": "不到2年",
  "next-flight": "下一航班",
  "confirmed-uppercase": "确认的",
  luggage: "行李",
  "price-high-to-low": "价格：从高到低",
  continue: "继续",
  "book-now": "现在预订",
  details: "详情",
  "read-and-accept-conditions": "我已阅读并接受所有条款和条件",
  "total-price-range": "总价格范围",
  "general-question": "一般问题",
  "arr-time": "到达。时间",
  "back-to-home": "回到家",
  "see-remark": "查看备注",
  "price-details": "价格详情",
  "send-links": "发送链接",
  "taxes-and-fees": "税费",
  "your-vehicle": "接送您的车辆",
  name: "姓名",
  "room-prices-details": "房价详情",
  "check-out": "订单确认",
  "welcome-please-login": "欢迎您，请登录。",
  "valid-for": "有效期为",
  "dept-time": "部门时间",
  "no-room-type-name": "无房型名称",
  support: "支持",
  "continue-to-confirm": "继续确认",
  next: "下一个",
  "car-rental-list": "租车列表",
  submit: "提交",
  "booking-confirmed": "您的订单 #{ bookingId } 已确认",
  adults: "成年人",
  "please-enter-your-password": "请输入您的密码",
  destination: "目的地",
  "num-flight": "  找到 {length} 个航班",
  "get-price": "获取价格",
  "multi-city": "多城市",
  "passenger-type": "旅客类型",
  "select-status": "选择状态",
  "measurement-infos": "测量信息",
  "cancellation-policy-nonrefundable-caution":
    "在 <span class='text-warning'>{beginDate}</span> 之前取消您的预订，您将获得全额退款。之后，您将被收取全部费用。时间以酒店当地时间为准。",
  "get-comfy-during-journey":
    " 旅途中尽享舒适！无论您想要安静的飞行还是只是想增加一点腿部空间，选择完全取决于您！",
  "your-choices": "您的选择",
  refundable: "可退款",
  "check-email-info":
    "您可以查看您的电子邮件以获取更多详细信息。我们已向您发送了一封包含详细信息的邮件。",
  "including-taxes": "含税",
  checkout: "查看",
  "price-total": "{ currency } {price} 总计",
  "provide-your-account":
    "请提供您帐户的电子邮件或用户名，我们将向您发送您的密码。",
  overview: "概述",
  "would-you-like-to-us-know": "您愿意让我们知道吗？",
  "email-address": "电子邮件地址",
  previous: "以前的",
  "traveling-for": "旅行的目的",
  "receipt-status": "收据状态",
  "view-fare-rules": "查看票价规则",
  start: "开始",
  "taxes-fees-port-expenses": "税费、费用和港口费用",
  "adults-uppercase": "成年人",
  "booking-amount": "预订金额",
  "hotel-search": "酒店搜索",
  "see-fare-details": " 查看票价详情",
  "family-name": "姓",
  "passport-expiry-date": "护照有效期为出发或返回后 6 个月内",
  "get-free-quote": "获取免费报价",
  hotels: "酒店",
  nights: "夜晚",
  "view-details": "查看详情",
  "room-quantity": "房间数量",
  "vans-trucks": "货车和卡车",
  "num-trip-advisor": "{num}/5 猫途鹰",
  taxes: "税费",
  itinerary: "行程",
  "we-choose": "我们选择",
  infant: "婴儿",
  type: "类型",
  "select-your-seats": " 选择您的座位",
  "select-passengers": "选择乘客",
  "dinner-included": "含晚餐",
  logout: "登出",
  "ticket-number": "票号",
  children: "孩子们",
  "sure-export-booking": "您确定要导出预订#{ bookingId }",
  "standard-days": "标准 - {num} 天",
  price: "价格",
  connection: "中转站",
  "all-photos": "所有照片",
  dashboard: "仪表板",
  "taxes-fees": "税费",
  "error-404-message": "很抱歉，找不到您要查找的页面",
  "expiry-time-min-s": "{ 分钟 } 分 {秒} 秒后过期",
  "created-date-range": "创建日期范围",
  "please-enter-hotel-location": "请输入酒店位置",
  "where-am-i-going": "我要去哪里",
  "more-details": "更多细节",
  "payment-method": "付款方式",
  "child-price": "儿童价",
  "confirmation-number-with-supplier": "确认号({supplierName})",
  "pickup-and-return-time": "接载地点及时间",
  "minimum-driving-age-years": "最低驾驶年龄：{minimumDriverAge}岁",
  "cruise-dates": "邮轮日期",
  doors: "门",
  "your-passport-information": "您的护照信息",
  "status-uppercase": "地位",
  "pick-up-time": "接载时间",
  "bookings-list": "预订清单",
  "go-to-home": "返回首页",
  reset: "重置",
  "select-your-room-category": "选择您的舱型",
  "departure-date": "出发日期",
  "remove-room": "移除房间",
  "contact-us": "联系我们",
  "sign-in": "登录",
  "hotel-amenities": "酒店设施",
  "travel-document-home": "签证首页",
  "check-out-date": "离开日期",
  "inbox-menu": "收件箱菜单",
  "leave-us-a-message": "给我们留言",
  "filter-by-price": "按价格过滤",
  tourism: "旅游",
  "room-types-rate-comments": "评价评论 - { roomTypes } - {roomIndex}",
  confirmed: "确认的",
  "airline-RLOC": "航空公司 RLOC",
  vehicle: "车辆",
  "ferries-and-buses": "渡轮和巴士",
  "bookings-found": "找到预订",
  "add-ons-pick": "附加组件选择",
  "package-home": "套餐首页",
  "fare-basic": "基本票价",
  "invoice-number": "发票号码：",
  "number-of-connections": "中转站数",
  "must-agree-service-terms": "您必须同意服务条款！",
  "fare-basis": "票价基础",
  "frequent-flyer-airline": "常飞计划航空公司",
  "see-all-properties": "返回搜索结果",
  "create-uppercase": "创造",
  "need-help": "需要帮助吗？",
  "cancel-booking": "取消预订",
  "non-refundable": "不退还",
  date: "日期",
  booking: "预订",
  "reset-your-password": "重置你的密码",
  "seat-unavailable": "没有座位",
  "extra-service-total": "额外服务总计",
  "departing-from": "出发地",
  "add-another-room": "添加另一个房间",
  "regular-question": "常规问题",
  "holiday-home": "渡假村",
  "night-stay": "晚",
  "name-submitted-successfully": "{surname}/{givenName}，您的订单已成功提交！",
  "taxes-include-total": "税费（包含在总额中）",
  sales: "销售量",
  "baggage-allowance": "行李限额",
  "check-in": "入住",
  "licenses-fees": "许可证和费用",
  "please-confirm the password": "请确认密码",
  "flexibility your own flying experience":
    "我们将为您带来全新的预订体验，让您拥有一切灵活定制属于您自己的飞行体验",
  "trip-advisor": "旅行顾问",
  discounts: "折扣",
  create: "创造",
  from: "从",
  "follow-us": "跟着我们",
  "for-room": "适合 { roomNum } 房间",
  "infant-less-than": "不满两岁",
  package: "机票套餐",
  "edit-meal": "编辑膳食",
  "include-in-total-amount": "包含在总金额中",
  "multi-way": "多程",
  minutes: "分钟",
  rail: "轨",
  "search-for-the-best-rooms-available":
    "我们将搜索所选类别中可用的最佳房间。客房最多可容纳 4 位客人",
  tax: "税",
  done: "完成",
  travelers: "旅行者",
  "cabin-number": "舱位号码",
  "trip-total-price": "行程总价",
  cruise: "游轮",
  "show-details": "显示详细资料",
  departure: "离开",
  "who-we": "我们是谁",
  "apply-now": "现在申请",
  "cruise-search-results": "邮轮搜索结果",
  "dont-have-a-account": "没有账户",
  "register-account": "注册账户",
  error: "错误",
  "sure-cancel-booking": "您确定要取消预订吗#{ bookingId }",
  "please-provide-a-password": "请提供密码",
  "protection-plans": "保护计划",
  "select-date": "选择日期",
  "please-enter-your-departure-point": "请输入您的出发地点",
  "load-more": "加载更多",
  "available-extras": "可用的附加功能",
  "edit-baggage": "编辑行李",
  "frequent-flyer-airline-number": "常飞计划航空公司号码",
  "see-fare-basis": "查看票价基础",
  amount: "数量",
  "departure-time": "出发时间",
  "go-back-to-dashboard": "返回仪表板",
  "room-price": "房价",
  "passport-requirements": "护照要求",
  subscribe: "订阅",
  "search-result": "搜索结果",
  "start-now": "现在开始",
  "error-403-message": "很抱歉，您没有访问此页面的权限",
  "from-to": "从{from}到{to}",
  "show-more": "展示更多",
  "created-date": "创建日期",
  recommended: "推荐",
  meal: "一顿饭",
  "mail-us": "邮寄给我们的支持团队",
  "please-enter-your-arrival-point": "请输入您的到达地点",
  "third-party-liability": "第三方责任",
  reselect: "重新选择",
  "flight-home": "机票首页",
  "going-to": "目的地",
  "processing-speed": "处理速度（尚未选择）",
  "prev-passenger": "上一位乘客",
  front: "正面",
  flights: "机票",
  in: "在",
  "cruise-package-code": "游轮套餐代码",
  deposit: "押金",
  "deposit-in": "押金( {key} )",
  "trip-total-in": "总价格( {key} )",
  "guest-info": "访客信息",

  "side-overlay-content": "侧面叠加内容..",
  carts: "购物车",
  reports: "报表",
  "edit-profile": "编辑资料",
  "current-language": "当前语言",
  "all-languages": "所有语言",
  "filter-by": "过滤条件",
  "basic-search": "基础搜索",
  "passengers-summary": "{passengersNum} 乘客 ,{cabinName} ",
  "passengers-room-summary":
    "{passengersNum} 乘客 ,{roomCount} 房间 ,{cabinName} ",
  "hotel-shopping": "酒店预订",
  "travel-room-num": "{travelersNumber} 名住客 , {roomCount} 间房",
  "who-checking": "登记人员",
  "cancellation-policies": "退款政策",
  "daily-prices": "价格详情",
  "cruise-shopping": "游轮预定",
  "booking-progress": "预定",
  guests: "乘客",
  "select-dining-preference": "选择您的用餐偏好",
  "cruise-dinning":
    "2019年2月，名人峰会参加了名人船队革命。餐厅和休息室不仅仅是餐厅和消磨时间的地方。贵宾室变成了远离家乡的家和客人的私人避难所。名人峰会不再只是一艘船，而是一种更好的体验世界的方式。",
  location: "位置",
  carRental: "租车",
  "car-rental-search": "租车搜索",
  seats: "座",
  return: "返回地",
  "car-transfer-home": "车接首页",
  "search-car-transfer": "车接搜索",
  infants: "婴儿",
  "adult-child-infant": "{adultNum}  成人, {childNum} 小孩 , {infantNum}  婴儿",
  "add-another": "增加",
  "your-journey": "您的旅程",
  "travel-document-apply": "旅游证件申请",
  validity: "有效期",
  review: "检查",
  documents: "证件",
  applicant: "申请人",
  gender: "性别",
  "personal-details": "个人信息",
  "package-search": "套餐搜索",
  "hotel-result": "酒店结果",
  "terms-and-condition": "条款及细则",

  expired: "过期",

  1023: "过滤",
  "num-flights-found": "{flightViewItemsShowLength} 条机票搜索结果",

  passport: "护照",
  phone: "手机",

  sort: "排序",
  "search-by-departure": "按出发地点搜索",

  "taxes-fees-included": "含税及费用",

  "your-choice": "您的选择",
  "view-on-map": "在地图上查看",

  "enter-a-email-address": "输入邮箱地址",
  "enter-your-username": "输入用户名",
  "enter-your-password": "输入密码",
  "enter-your-first-name": "输入名字",
  "enter-your-last-name": "输入姓氏",
  "enter-your-email-address": "输入邮箱地址",
  "enter-your-phone-number": "输入电话",
  "please-enter-price-range": "请输入正确的价格范围",
  "sleep-num": "最多 {num} 人 (按实际入住人数收费)",
  "sleep-num-range": " {min} - {max} 人 (按实际入住人数收费)",
  "payment-time-is-expired": "支付时间已经过期!",
  "this-is-an-error-message": "这是一个错误信息",
  "flexibility-your-own-flying-experience":
    "我们将为您带来全新的预订体验，让您拥有一切灵活定制属于您自己的飞行体验",
  "unpaid-hotel-reservation-notice":
    "本订单为未付款订单，如果在<span class='font_weight '> {date}</span>（时间以酒店当地时间为准）之前未完成支付，系统将自动取消该订单。请尽快完成支付以保留订单。",
  "basic-information": "基础信息",
  "name-info": "姓名",
  "date-of-birth": "生日",
  "mobile-number": "移动电话",
  "times-are-based-on-local": "*时间以酒店当地时间为准.",
  apply: "应用",
  "my-additional-services": "我的附加服务",
  "my-selected-seats": "我已选择的座位",
  "segment-already-contains-the-same-type-additional-services":
    "此段已包含相同类型的附加服务，请删除并添加",
  "segment-already-contains-the-same-type-seat":
    "此段已包含相同类型的座位，请删除并添加",
  "booking-cancelled-successfully": "预订成功取消",
  "fare-is-expired": "票价已经过期!",
  "please-enter-promo-code": "请输入优惠码 !",
  "please-select": "请选择",
  "hotel-list": "酒店列表",
  "hotel-num": "找到{hotelNum}家酒店",
  "trying-to-check-please-wait-for-a-moment": "正在请求中，请稍等!",
  "price-lowest": "价格(最低)",
  "price-highest": "价格(最高)",
  "user-rating-lowest": "用户评分(最低)",
  "user-rating-highest": "用户评分(最高)",
  "star-rating-lowest": "星级(最低)",
  "star-rating-highest": "星级(最高)",
  "hotel-name-lowest": "酒店名(A-Z)",
  "hotel-name-highest": "酒店名(Z-A)",
  "min-price": "最低价",
  "max-price": "最高价",
  "input-hotel-name": "输入酒店名",
  "hotel-details": "酒店详情",
  "cancel-policies": "取消政策",
  "room-only": "仅限房间",
  breakfast: "早餐",
  "years-old": "岁",
  "contact-info": "联系方式",
  "hotel-remarks": "限制250个字符",
  "description-uppercase": "描述",
  "view-price-breakdown": "查看价格明细",
  "view-price-summary": "查看价格摘要",
  "taxes-uppercase": "税费",
  "price-breakdown": "价格明细",
  "room-amount": "房间价格",
  "taxes-amount": "税费价格",
  arrival: "到达",
  "name-of-city-or-airport": "入住城市或机场名",
  "choose-flight-to": "选择前往 {locationName} 的航班",
  "duration-shortest": "时长(最短)",
  "duration-longest": "时长(最长)",
  "departure-earliest": "出发时间(最早)",
  "departure-latest": "出发时间(最晚)",
  "arrival-earliest": "到达时间(最早)",
  "arrival-latest": "到达时间(最晚)",
  "early-morning": "清晨",
  morning: "早上",
  afternoon: "下午",
  evening: "晚上",
  "stopover-airport": "经停机场",
  suppliers: "供应商(s)",
  "view-segments": "查看航段",
  "operating-airline": "运营航空公司",
  "the-booking-has-been-paid-and-is-waiting-to-be-issued":
    "订单已支付，等待出票中",
  "number-stops": "{stopNumber} 停靠点",
  "no-flights": "暂无航班",
  "no-flight-in-origin-des":
    "暂无 {month} {date} {originLocationName} - {destinationLocationName} 的航班",
  direct: "直达",
  stop: "站",
  "review--fare-to": "查看到 {locationCode} 的票价",
  "some-fare-options-for-you": "这里有一些票价供你选择!",
  loading: "加载中",
  "this-is-an-success-message": "这是一个成功信息",
  "this-is-a-warning-message": "这是一个警告信息",
  "change-flight": "修改航班",
  "fare-options": "票价选项",
  "departure-from": "出发地",
  "arrive-at": "目的地",
  "flight-duration": "航班时长",
  "please-enter": "请输入",
  "itinerary-info": "行程信息",
  "show-more-options": "展示更多选项",
  "add-promo-code": "添加优惠码",
  promotion: "优惠",
  "promo-code": "优惠码",
  "enter-your-passport": "输入您的护照",
  "enter-your-passport-optional": "输入您的护照号码 (可选)",
  "expiry-date-optional": "过期时间 (可选)",
  "enter-known-traveler-number-option": "输入已知的旅客号码(可选)",
  "enter-redress-number-option": "输入申诉号码(可选)",
  "redress-number": "旅客护照识别号码",
  "known-traveler-number": "已知旅客编号",
  "invalid-input": "无效输入!",
  "frequent-flyer-programmes": "常飞旅客计划航空公司",
  "all-programmes": "所有常飞计划",
  "user-name-and-password-mismatch": "账号和密码不匹配",
  actions: "操作",
  "air-availability": "可用航班",
  "successfully-confirm": "确认成功!",
  "basic-reservation": "基本预定",
  "passenger-select": "乘客选择",
  rule: "规则",
  "schedule-jobs": "任务",
  "view-all-job": "查看所有的任务",
  "this-trip-is-not-in-compliance": "这次旅行不符合以下旅行规则:",
  "you-need-submit-your-travel-approval": "你需要先提交旅行批准书",
  approver: "批准人",
  "valid-thru-date": "有效日期",
  "quote-remarks": "报价备注",
  "application-reason": "申请理由",
  "your-travel-approval-submitted": "您已提交旅行批准",
  "secure-flight-info": "安全航班信息",
  "change-information": "修改信息",
  "segment-already-contains-the-same-type-seat-should-modify":
    "此段已经包含相同类型的座位，现在将被修改为当前选择的座位",
  "you-will-change-your-info-now": "您现在将更改信息",
  "change-passenger-info": "修改乘客信息",
  "change-person-name": "修改乘客姓名",
  "add-contact-info": "添加联系方式",
  save: "保存",
  "employee-position-type": "员工职位",
  "travel-policy": "旅行政策",
  "switch-the-language": "切换语言",
  CHECK_IN: "入住",
  CHECK_OUT: "退住",
  FEES: "费用",
  INSTRUCTION: "说明",
  SPECIAL_INSTRUCTIONS: "特殊说明",
  MANDATORY: "强制",
  OPTIONAL: "可选",
  POLICY: "政策",
  KNOW_BEFORE_YOU_GO: "出发前了解",
  roles: "角色",
  "trip-id": "行程编号",
  "trip-hotel-item-id": "酒店订单编号",
  "party-name": "个人(组织) 名称",
  "party-roles": "个人(组织) 角色",
  "party-basic-info": "个人(组织)  基础信息",
  "party-type": "个人(组织) 类型",
  "party-code": "个人(组织) 代码",
  "group-company-name": "组/公司名称",
  "legal-name": "法定姓名",
  "organization-unit-path": "组织单位路径",
  disabled: "不可用的",
  "party-role-id": "个人(组织) 角色",
  "room-passengers": "住客",
  "approval-failure": "审批失败 !",
  "successful-approval": "审批成功 !",
  "user-login-type": "用户登录类型",
  internal: "内部的",
  external: "外部的",
  "employment-position": "职位",
  "send-notification-email": "是否发送通知邮件",
  "party-rules": "(个人或组织)规则",
  "new-employee": "新的职员",
  "you-will-add-this-employee": "你将添加这名职员",
  id: "编号",
  "edit-employee-info": "编辑职员信息",
  "you-will-modify-this-employee": "你将修改这名员工的信息",
  username: "账号",
  "fees-included-uppercase":
    "费用 <span class='font-14'>(包含了基础价格)</span>",
  "fees-included": "费用 <span class='font-14'>(包含了基础价格)</span>",
  "you-will-add-this-department": "现在你将添加这个部门",
  "you-will-modify-this-department": "你将修改这个部门的信息",
  "you-will-add-this-travel-policy": "你将添加这个旅行政策",
  "you-will-modify-this-travel-policy": "你将修改这个旅行政策的信息",
  "you-will-add-this-travel-rule": "你将添加这个旅行规则",
  "you-will-modify-this-travel-rule": "你将修改这个旅行规则的信息",
  "new-travel-rule": "新的旅行规则",
  "successfully-add": "添加成功",
  "fail-to-add": "添加失败",
  "password-enter-warning":
    "请输入大小写字母、数字、特殊字符(!{'@'}#%^&*?)密码大于8个字符",
  "confirm-password-enter-warning": "密码输入不一致",
  "successfully-modify": "修改成功",
  "fail-to-modify": "修改失败",
  "you-will-add-this-employee-position": "你将添加这个职位类型",
  "you-will-modify-this-employee-position": "你将修改这个职位类型的信息",
  "ticketing-remarks": "出票备注",
  "please-enter-english-remarks": "请输入英文备注",
  "select-the-existing-ID-information-join": "选择要加入的现有证件信息",
  "you-will-add-document-now": "你将添加这个证件信息",
  "party-documents": "证件信息",
  "document-type": "证件类型",
  "document-id": "证件号码",
  "party-document": "证件信息",
  "issue-authority": "发证单位",
  "holder-nationality": "持有者国籍",
  "holder-type": "持有者类型",
  "holder-name": "持有者名字",
  "effective-date": "生效日期",
  "issue-location": "发证地",
  "issue-state-province": "发证州/省",
  "issue-country": "发证国家",
  "birth-country": "出生国家",
  "birth-place": "出生地",
  "person-info": "个人信息",
  "contact-mechanisms": "联系方式",
  relationships: "关系",
  purpose: "计划",
  "airline-code": "航空公司代码",
  "travel-tips": "出行提示",
  "travel-tips-content":
    "订单价格已含房费及一般税费，不包含城市税（欧洲，马来西亚）、度假村费、设施费（北美）、市政费（迪拜）、旅游税（马来西亚）、销售与服务税（SST,马来西亚）等酒店向客人另行征收的费用；客人在酒店产生的额外消费、增项服务费、违规罚款等一切费用，需客人跟酒店另行结算。",
  "booking-instructions": "预订须知",
  "booking-instructions-content":
    '  <div class="foont-weight font-18 text-warning">为避免入住问题的发生，请务必仔细阅读</div>\n' +
    '    <div class=" font_weight mg-t-5"> 入住退房要求</div>\n' +
    "    <div>入住时间开始于 14:00</div>\n" +
    "    <div>退房时间正午</div>\n" +
    "    <div>可自行办理入住手续的最低年龄 18</div>\n" +
    "    <div>入住时间结束不限时间</div>\n" +
    "    <div>入住国内酒店时请使用名字拼音查询（名+姓）预订。</div>\n" +
    "    <div>请于页面展示酒店最晚入住时间前办理入住，无展示规定最晚入住时间时，请于首晚22点前办理入住。</div>\n" +
    '    <div class=" font_weight mg-t-5">特别入住说明</div>\n' +
    "    <div>此住宿接待方提供机场接车服务（可能会额外收费）。住客必须使用预订确认邮件上的联系信息，在出行前联系住宿接待方，提供详细到达信息。前台员工将在住客抵达时出面迎接。办理登记入住时，持卡人必须出示预订时所用的信用卡以及相匹配的带照片的身份证件。每位住客均需出示有效护照。酒店仅接受此类身份证件。\n" +
    "        未到店或晚至</div>\n" +
    "    <div>如订单延迟入住，请提前联系客服，否则酒店可能不会以任何方式通知客人，并直接取消整单不退费。</div>\n" +
    '    <div class="font_weight mg-t-5">其他</div>\n' +
    "    <div>您的特殊需求，酒店视当天入住情况尽量安排，不做任何保证。</div>\n" +
    "    <div>所有订单超过最晚免费取消日均视为不可修改，如您有特殊情况，可联系客服协助处理。</div>\n" +
    "    <div>请在订单的离店后的90天内申请发票，逾期将无法开具发票，谢谢您的理解。</div>",
  "please-enter-uppercase-code": "请输入大写字母",
  "please-enter-number": "请输入数字",
  profile: "资料",
  "customer-loyalties": "忠实用户",
  "travel-preferences": "旅行偏好",
  "you-will-add-this-data": "你将增加这条数据",
  "you-will-modify-this-data": "你将修改这条数据",
  "airline-prefs": "航空偏好",
  "out-of-policy": "超出政策",
  "requires-approval": "需要审批",
  "reprice-is-need": "需要重新验价!",
  "department-name": "部门名称",
  "score-increment": "增加权重",
  "recommends-highest": "优先推荐",
  capt: "上校.",
  dame: "女爵士.",
  lady: "女士.",
  lord: "勋爵.",
  "the-rt-hon": "总理(首相)、议长.",
  rabbi: "拉比.",
  rev: "大人(基督教教士).",
  sir: "先生.",
  baroness: "男爵.",
  baron: "从男爵.",
  viscount: "子爵.",
  viscountes: "子爵夫人.",
  "associated-user-logins": "关联用户登录",
  security: "安全",
  import: "导入",
  "fail-to-upload": "上传失败",
  "successfully-upload": "上传成功",
  "update-credits": "更新信用额度",
  corporation: "公司",
  family: "家庭",
  "modify-department": "修改部门",
  "you-will-delete-this-data": "你将删除这条数据",
  "employment-position-type": "员工职位",
  "class-type": "类型",
  "quoteId-is-null": "报价编号为空",
  "cancel-failed": "取消失败 !",
  "cancel-successfully": "取消成功 !",
  "reject-failed": "拒绝失败 !",
  "reject-successfully": "拒绝成功 !",
  "rule-name": "规则名称",
  "input-param-enum": "条件",
  "operator-enum-id": "操作",
  "travel-rule-condition": "旅行规则条件",
  "sequence-number": "序列号",
  "param-enum": "条件参数",
  operator: "操作",
  "new-trip": "添加行程",
  "trip-details": "行程详情",
  "import-pnr": "导入乘客记录编码",
  "company-logo": "公司Logo",
  "upload-logo": "上传Logo",
  "basic-profile": "基本资料",
  "telephone-numbers": "电话号码",
  "email-addresses": "邮箱地址",
  "membership-numbers": "会员",
  "traveller-membership": "旅游会员",
  "traveller-preference": "旅游偏好",
  approval: "审批",
  "approval-required": "需要审批",
  "require-reason-purpose-code": "要求理由/目的",
  "who-is-travelling": "谁在旅行 ?",
  "you-will-add-data-now": "你现在将添加这条数据? ",
  "you-will-modify-data-now": "你现在将修改这条数据?",
  "you-don't-have-permission-to-book": "抱歉！您没有权限预定",
  "please-select-optional": "请选择 (可选)",
  reload: "重新加载",
  "reload-success": "重新加载成功!",
  "insurance-mo": "保险(MO)",
  "ferries-mo": "渡轮(MO)",
  "holiday-package-mo": "度假套餐(MO)",
  "dynamic-package-coming-soon": "机加酒(即将推出)",
  "not-configured-yet": "尚未配置",
  "delete-fare-quote": "删除票价报价",
  "no-fares": "没有票价",
  "please-select-at-least-one-segment": "请选择至少一个航段 !",
  "please-select-at-least-one-passenger": "请选择至少一名乘客 !",
  "you-will-delete-this-fare-quote": "您现在将删除此票价报价",
  "this-type-document-already-exists": "此类型证件已经存在。请勿重复添加",
  grades: "等级",
  "employee-grade": "员工等级",
  "grade-code": "等级代码",
  "grade-name": "等级名",
  "you-will-add-this-employee-grade": "您将添加这个员工等级",
  "you-will-modify-this-employee-grade": "您将修改此员工等级",
  grade: "等级",
  "in-policy": "政策内",
  "the-preference-already-exists": "偏好已经存在",
  unsuccessful: "不成功",
  "logging-in": "登录中",
  "cabin-entitlement": "舱型权利",
  "hotel-entitlement": "酒店权利",
  "cabin-upgrade": "舱位升级",
  "upgrade-allowed-uppercase": "允许升舱",
  "username-and-password-mismatch": "用户名和密码不匹配",
  "country-access-code": "国家代码",
  "you-will-add-this-party": "你将添加个人/组织",
  "you-will-modify-this-party": "你将修改个人/组织信息",
  "discount-uppercase": "折扣",
  "whether-to-start-a-schedule-job": "是否开启一个定时任务",
  "successfully-import": "上传成功",
  "fail-to-import": "上传失败",
  "please-upload-the-correct-image-format": "请上传正确的图片格式",
  "add-document": "添加护照",
  "no-travel-information": "没有旅行信息",
  "country-code": "国家代码",
  "travel-breaks-some-rules": "旅行违反了一些规则",
  "please-mark-in-fill-in-within-seconds-to-avoid-the-room being":
    "请在{ second } 分 {minutes} 秒内完成填写，以免房间被订完或者价格发生变化",
  "room-rates-have-expired-please-re-search": "房间价格已经过期，请重新搜索",
  country: "国家",
  "require-reason-purpose": "要求理由/目的代码",
  connections: "转换站",
  "back-to-policy": "回到旅游政策",
  "normal-rules": "详细费用规则",
  "structured-rules": "简单费用规则",
  "before-departure": "出发前",
  "after-departure": "出发后",
  "change-fee": "修改费用",
  "cancellation-fee": "取消费用",
  unchangeable: "不可修改",
  "mainland-travel-permit": "回乡证",
  "add-documents": "添加证件",
  "alert-message": "提示信息",
  "place-of-birth": "出生地",
  mobile: "移动电话",
  "hong-kong-identity-card": "香港身份证",
  "there-are-no-optional-rooms": "没有可选的房间",
  "select-grade": "选择等级",
  others: "其它",
  "you-do-not-need-upgrade-jump-next-step": "你不需要升级，直接跳到下一步",
  "select-upgrade-type": "选择升级类型",
  "flying-hours": "飞行时长",
  "hours-more-than": "时长超过",
  city: "城市",
  "with-condition-not-exceeding": "条件不超过",
  "add-grade-policy": "添加等级旅行政策",
  "modify-grade-policy": "修改等级旅行政策",
  telephone: "电话",
  visa: "签证",
  "APEC-card": "APEC卡",
  "chinese-name": "中文名",
  "no-programmes": "无常飞计划航空公司",
  "transaction-fees": "手续费",
  "traveller-selected": "已选择{num}名旅行者 ",
  "please-enter-to-select": "点击此处或者回车选择",
  "please-enter-to-remove": "点击此处或者回车移除",
  "promotion-code": "促销码",
  "airline-seat-preferences": "航班座位偏好",
  "airline-meal-preferences": "航班餐饮偏好",
  "choose-the-loyalty-to-replace": "选择旅行忠实用户号码",
  "vendor-code": "供应商代码",
  "program-id": "航空公司",
  "membership-id": "忠实用户号码",
  "issue-date": "签发日期",
  "visa-entries": "入境次数",
  "single-visa": "单次",
  "multiple-visa": "多次",
  "visa-type": "签证类型",
  "visa-no": "签证号码",
  "APEC-card-no": "APEC号码",
  "seat-preference": "座位偏好",
  "meal-preference": "饮食偏好",
  "preference-type": "偏好类型",
  preference: "偏好",
  "meal-type": "餐饮类型",
  "group-division-name": "部门名称",
  "loyalty-level": "忠诚度",
  "customer-loyalty": "忠诚客户",
  "program-name": "航空公司名",
  arranger: "代购人",
  "approver-for": "为谁批准",
  "arranger-for": "为谁代购",
  "username-or-email": "用户名或电子邮件",
  "brand-fares-only": "仅限品牌报价",
  "brand-fares": "品牌报价",
  "best-price-only": "仅限最低价格",
  "flexibility-fare-info": "变更与取消",
  free: "免费",
  "penalty-options": "罚款项",
  "operated-by": "承办于",
  "currently-selected": "当前选择",
  "including-tax-uppercase": "包含税费",
  "original-selection-including-tax": "原选含税",
  "total-including-tax": "含税合计",
  "the-fare-is-out-of-policy": "此价格超政策",
  "order-histories": "Order 历史",
  "car-rental-shopping": "租车搜索",
  "vehicle-num": "找到 {carRentalItemsLength} 条车辆信息",
  "vehicle-name-lowest": "车辆名(A-Z)",
  "vehicle-name-highest": "车辆名(Z-A)",
  automatic: "自动",
  manual: "手动",
  "protection-extra": "额外保护",
  "create-order": "创建 Order",
  "no-segments": "没有航段",
  "no-passengers": "没有乘客",
  "please-select-at-least-one-segment-passenger":
    "请选择至少一个区段和一名乘客 !",
  "upsell-is-not-supported": "不支持upsell，请更改航班",
  "do-you-want-keep-issuing-tickets": "您是要继续出票，还是要查看预订详情 ?",
  "to-reservation": "去订单详情页",
  "continue-issue-ticket": "继续出票",
  "activity-summary": "活动摘要",
  "leaving-from": "从哪里出发",
  "driver-info": "司机信息",
  car: "车辆",
  purchase: "购买",
  "dynamic-package": "机加酒",
  "more-fare-options": "更多票价选择",
  "original-selection": "原先选择",
  Passport: "护照",
  "pick-up-point": "取车地点",
  "drop-off-point": "还车地点",
  "seats-num": "座位数量",
  "or-similar-vehicle-class": "类似于 {className}",
  "the-model-is-subject-to-availability": "您在取车当天得到的型号取决于可用性.",
  "basic-protection-included": "包括基本保护",
  "full-vehicle-details": "车辆详细信息",
  "basic-protection": "基本保护",
  "you-will-upgrade-protection-selecting-vehicle":
    "选择此车辆后，您将能够升级您的保护",
  "unlimited-mileage": "无限里程",
  "car-details": "车辆详情",
  "protection-plan": "保护计划",
  "packages-shopping": "机加酒搜索",
  added: "添加",
};

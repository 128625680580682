const cabinTypes = [
  { code: "Y", name: "economy" },
  { code: "W", name: "premium-economy" },
  { code: "C", name: "business" },
  { code: "F", name: "first" },
];

export default {
  cabinTypes,
};

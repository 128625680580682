//region 请求对象RQ

import commonUtils from "@/utils/commonUtils";
import dateUtils from "@/utils/dateUtils";

function getSailAvailRQ(sailAvailFormModel) {
  let customerCounts = [];
  const startDate = sailAvailFormModel.startDate;
  const endDate = sailAvailFormModel.endDate;
  const locationCode = sailAvailFormModel.locationCode;
  const adultNum = sailAvailFormModel.adultNum;
  const seniorNum = sailAvailFormModel.seniorNum;
  const childNum = sailAvailFormModel.childNum;
  let childAges = sailAvailFormModel.childAges;
  let childAgeMap = {};
  if (childAges && childAges.length > 0) {
    childAges = childAges.map((item) => item.age);
    childAgeMap = commonUtils.countTimes(childAges);
  }
  for (const key in childAgeMap) {
    customerCounts.push({
      age: key,
      quantity: childAgeMap[key],
    });
  }

  customerCounts.push(
    {
      age: 59,
      quantity: adultNum,
    },
    { age: 60, quantity: seniorNum }
  );
  return {
    searchCriteria: {
      dateTimePref: {
        start: startDate, //日期範圍，開始日期
        end: endDate, //日期範圍，結束日期
      },
      locationPrefs: [
        {
          region: {
            regionCode: locationCode, //城市
          },
        },
      ],
      customerCounts: customerCounts,
    },
  };
}

function getActivityDetailRQ(sailAvailFormModel) {
  let customerCounts = [];
  const startDate = sailAvailFormModel.startDate;
  const endDate = sailAvailFormModel.endDate;
  const adultNum = sailAvailFormModel.adultNum;
  const seniorNum = sailAvailFormModel.seniorNum;
  const childNum = sailAvailFormModel.childNum;
  const activityId = sailAvailFormModel.activityId;
  let childAges = sailAvailFormModel.childAges;
  let childAgeMap = {};
  if (childAges && childAges.length > 0) {
    childAges = childAges.map((item) => item.age);
    childAgeMap = commonUtils.countTimes(childAges);
  }
  for (const key in childAgeMap) {
    customerCounts.push({
      age: key,
      quantity: childAgeMap[key],
    });
  }

  customerCounts.push(
    {
      age: 59,
      quantity: adultNum,
    },
    { age: 60, quantity: seniorNum }
  );

  return {
    tourActivity: {
      basicInfos: [
        {
          tourActivityID: activityId, //列表接口搜索到的活动唯一标识
        },
      ],
      schedules: [
        {
          startPeriod: startDate,
          endPeriod: endDate,
        },
      ],
      participantCounts: customerCounts,
    },
  };
}

function getGroundBookRQ(payload) {
  const passengerModel = payload.passengerModel;
  const selectedIds = payload.selectedIds;
  const resStatus = payload.resStatus;

  let groundServices = [];
  if (selectedIds && selectedIds.length > 0) {
    selectedIds.forEach((item) => {
      let id = {
        reference: {
          id: item,
        },
      };
      groundServices.push(id);
    });
  }

  return {
    resStatus: resStatus,
    groundReservations: [
      {
        passenger: {
          primary: {
            personName: {
              givenName: passengerModel.givenName,
              surname: passengerModel.surname,
              nameTitle: passengerModel.nameTitle,
            },
            telephoneList: [
              {
                phoneNumber:
                  passengerModel.countryAccessCode +
                  "-" +
                  passengerModel.phoneNumber,
              },
            ],
            emailList: [
              {
                stringValue: passengerModel.emailAddress,
              },
            ],
          },
        },
        groundServices: groundServices,
      },
    ],
  };
}

//endregion

//region  初始化视图对象

/***
 * SearchFormModel对象
 */
class ActivitySearchFormModel {
  constructor(
    startDate,
    endDate,
    locationCode,
    locationName,
    adultNum,
    seniorNum,
    childNum,
    childAges
  ) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.locationCode = locationCode;
    this.locationCode = locationCode;
    this.adultNum = adultNum;
    this.seniorNum = seniorNum;
    this.childNum = childNum;
    this.childAges = childAges;
  }
}

/***
 *  ActivityViewItem对象
 */
class ActivityViewItem {
  constructor(
    name,
    shortName,
    tourActivityID,
    activityDescription,
    imageItems,
    cover,
    address,
    pickupLocation,
    dropoffLocation,
    minPrice,
    currencyCode
  ) {
    this.name = name;
    this.shortName = shortName;
    this.tourActivityID = tourActivityID;
    this.activityDescription = activityDescription;
    this.imageItems = imageItems;
    this.cover = cover;
    this.address = address;
    this.pickupLocation = pickupLocation;
    this.dropoffLocation = dropoffLocation;
    this.minPrice = minPrice;
    this.currencyCode = currencyCode;
  }
}

/***
 *  ActivityDetailsView对象
 */
class ActivityDetailsView {
  constructor(
    name,
    shortName,
    tourActivityID,
    operationDuration,
    operationDays,
    imageItems,
    textItems,
    extras,
    meetingPoint,
    routePoints,
    extraLocation,
    pickupLocation,
    dropoffLocation,
    currencyCode,
    participantCategories,
    groups,
    cancelDate,
    priceAvailDate,
    locationInfo
  ) {
    this.name = name;
    this.shortName = shortName;
    this.tourActivityID = tourActivityID;
    this.operationDuration = operationDuration;
    this.operationDays = operationDays;
    this.imageItems = imageItems;
    this.textItems = textItems;
    this.extras = extras;

    this.routePoints = routePoints;
    this.meetingPoint = meetingPoint;
    this.extraLocation = extraLocation;
    this.pickupLocation = pickupLocation;
    this.dropoffLocation = dropoffLocation;

    this.currencyCode = currencyCode;
    this.participantCategories = participantCategories;
    this.groups = groups;

    this.cancelDate = cancelDate;
    this.priceAvailDate = priceAvailDate;
    this.locationInfo = locationInfo;
  }
}

/***
 * ActivityPassengerFormModel对象
 */
class ActivityPassengerFormModel {
  constructor(
    nameTitle,
    surname,
    givenName,
    phoneNumber,
    emailAddress,
    addressLines,
    countryCode,
    countryName,
    postalCode,
    question
  ) {
    this.nameTitle = nameTitle;
    this.surname = surname;
    this.givenName = givenName;
    this.phoneNumber = phoneNumber;
    this.emailAddress = emailAddress;
    this.addressLines = addressLines;
    this.countryCode = countryCode;
    this.postalCode = postalCode;
    this.question = question;
  }
}

class ActivityBookingViewItem {
  constructor(
    name,
    groupName,
    tourActivityID,
    confirmations,
    textItems,
    shortDescription,
    addressInfo,
    addressLines,
    participantInfo,
    contactPerson,
    cancelDete,
    scheduleDate,
    supplierInfo,
    operaterInfo,
    participantCategories,
    groupInfo
  ) {
    this.name = name;
    this.groupName = groupName;
    this.tourActivityID = tourActivityID;
    this.confirmations = confirmations;
    this.textItems = textItems;
    this.shortDescription = shortDescription;
    this.addressInfo = addressInfo;
    this.addressLines = addressLines;
    this.participantInfo = participantInfo;
    this.contactPerson = contactPerson;
    this.cancelDete = cancelDete;
    this.scheduleDate = scheduleDate;
    this.supplierInfo = supplierInfo;
    this.operaterInfo = operaterInfo;
    this.participantCategories = participantCategories;
    this.groupInfo = groupInfo;
  }
}

class SortingOption {
  constructor(type, order) {
    //排序类型，可为PRICE，NAME，STAR_RATING，USER_RATING
    this.type = type;
    //升序降序，可为ASC-升序，DSC-降序
    this.order = order;
  }
}

//endregion

//region 构造视图对象
function getActivityViewItems(availTransferRS) {
  const tourActivityInfos = availTransferRS.tourActivityInfos;
  let tourActivityInfoView = [];
  if (tourActivityInfos && tourActivityInfos.length > 0) {
    tourActivityInfos.forEach((tourActivityInfo) => {
      const basicInfo = tourActivityInfo.basicInfo;
      let name = basicInfo.name;
      let shortName = basicInfo.shortName;
      let tourActivityID = basicInfo.tourActivityID; //活動唯一標識
      let activityDescription = basicInfo.tpaExtensionsType.valueMap; //活動唯一標識

      const description = tourActivityInfo.description;
      const multimedia = description.multimedia;
      const multimediaDescription =
        multimedia.multimediaDescriptionLists &&
        multimedia.multimediaDescriptionLists.length > 0
          ? multimedia.multimediaDescriptionLists[0]
          : {};
      let imageItems = multimediaDescription.imageItems;
      let cover = null;
      if (imageItems && imageItems.length > 0) {
        cover = imageItems.find(
          (item) => item.imageFormatList[0].dimensionCategory == "LARGE"
        ).url;
      }

      const location = tourActivityInfo.location.address;
      let address = location.countryName ? location.countryName.text : "";

      const pickupDropoffs = tourActivityInfo.pickupDropoffs;
      let pickupLocation = null;
      let dropoffLocation = null;
      if (pickupDropoffs && pickupDropoffs.length > 0) {
        pickupDropoffs.forEach((item) => {
          if (item.pickupInd) {
            //可接
            pickupLocation = item.otherInfo
              ? item.otherInfo
              : item.locationName;
          } else if (item.dropoffInd) {
            //可送
            dropoffLocation = item.otherInfo
              ? item.otherInfo
              : item.locationName;
          }
        });
      }

      const pricing = tourActivityInfo.pricing;
      let minPrice = pricing.minPrice;
      let currencyCode = pricing.currencyCode;

      const activityViewItem = new ActivityViewItem(
        name,
        shortName,
        tourActivityID,
        activityDescription,
        imageItems,
        cover,
        address,
        pickupLocation,
        dropoffLocation,
        minPrice,
        currencyCode
      );

      tourActivityInfoView.push(activityViewItem);
    });
    return tourActivityInfoView;
  } else {
    return null;
  }
}

//activity详情
function getActivityDetailsView(availTransferRS) {
  const tourActivityInfo =
    availTransferRS.tourActivityInfoList &&
    availTransferRS.tourActivityInfoList.length > 0
      ? availTransferRS.tourActivityInfoList[0]
      : {};

  if (tourActivityInfo) {
    const basicInfo = tourActivityInfo.basicInfo;
    let name = basicInfo.name;
    let shortName = basicInfo.shortName;
    let tourActivityID = basicInfo.tourActivityID; //活動唯一標識

    const schedule = tourActivityInfo.schedule;
    const details = schedule.details;

    let operationDuration = null;
    let operationDays = [];
    if (details && details.length > 0) {
      let operationItem = details.find((item) => item.duration);

      if (operationItem) {
        operationDuration = operationItem.duration;
        let operationTimes =
          operationItem.operationTimes &&
          operationItem.operationTimes.length > 0
            ? operationItem.operationTimes[0]
            : {};

        for (const key in operationTimes) {
          if (key != "duration") {
            let keyForMat = null;
            if (key == "mon") {
              keyForMat = "mon";
            } else if (key == "tue") {
              keyForMat = "mon";
            } else if (key == "wed") {
              keyForMat = "wed";
            } else if (key == "thur") {
              keyForMat = "thu";
            } else if (key == "fri") {
              keyForMat = "fri";
            } else if (key == "sat") {
              keyForMat = "sat";
            } else if (key == "sun") {
              keyForMat = "sun";
            }
            operationDays.push(keyForMat);
          }
        }
      }
    }

    const description = tourActivityInfo.description;
    const multimedia = description.multimedia;
    const multimediaDescription =
      multimedia.multimediaDescriptionLists &&
      multimedia.multimediaDescriptionLists.length > 0
        ? multimedia.multimediaDescriptionLists[0]
        : {};

    let imageItems = multimediaDescription.imageItems;

    let textItems = multimediaDescription.textItems;

    let extras = tourActivityInfo.extras; //额外服务

    const locations = tourActivityInfo.locations;

    let meetingPoint = null;
    let routePoints = [];
    let extraLocation = {};
    let locationInfo = {};
    if (locations && locations.length > 0) {
      locations.forEach((location) => {
        if (location.locationTypeId == "Meeting Point") {
          const addressLines = location.address.addressLines;
          if (addressLines && addressLines.length > 0) {
            meetingPoint = addressLines[0];
          }
        } else if (location.locationTypeId == "ROUTE_POINT") {
          let cityName = location.address.cityName;
          const tpaExtensionsType = location.address.tpaExtensionsType;
          let routeName = null;
          let isStop = null;
          let orderId = null;
          if (tpaExtensionsType) {
            routeName = tpaExtensionsType.valueMap.ROUTE_NAME;
            isStop = tpaExtensionsType.valueMap.STOP;
            orderId = tpaExtensionsType.valueMap.ORDERID;
          }

          let position = location.position;
          let address = null;
          const addressLines = location.address.addressLines;
          if (addressLines && addressLines.length > 0) {
            address = addressLines[0];
          }

          let routeItem = {
            cityName: cityName,
            routeName: routeName,
            isStop: isStop,
            position: position,
            orderId: orderId,
            address: address,
          };

          routePoints.push(routeItem);
        } else {
          let point = null;
          const addressLines = location.address.addressLines;
          const locationTypeId = location.locationTypeId;
          if (addressLines && addressLines.length > 0) {
            point = addressLines[0];
          }

          extraLocation[locationTypeId] = point;
        }
      });
      //按照 orderId 升序
      routePoints.sort((a, b) => (a.orderId > b.orderId ? 1 : -1));
    }

    const pickupDropoffs = tourActivityInfo.pickupDropoff;
    let pickupLocation = null;
    let dropoffLocation = null;
    if (pickupDropoffs && pickupDropoffs.length > 0) {
      pickupDropoffs.forEach((item) => {
        if (item.pickupInd) {
          //可接
          pickupLocation = item.otherInfo ? item.otherInfo : item.locationName;
        } else if (item.dropoffInd) {
          //可送
          dropoffLocation = item.otherInfo ? item.otherInfo : item.locationName;
        }
      });
    }

    const pricing = tourActivityInfo.pricing;
    let adultPrice = null;
    let adultPriceCurrency = null;
    let participantCategories = pricing.participantCategories;

    let currencyCode = pricing.summary.currencyCode;
    let groups = pricing.groups;

    let operationDates = []; //计算所有group中可以售卖的日期
    if (groups && groups.length > 0) {
      groups.forEach((item) => {
        const prices = item.prices;
        if (prices && prices.length > 0) {
          const operationDate = prices[0].operationDates;
          if (operationDate && operationDate.length > 0) {
            operationDate.forEach((item) => {
              operationDates.push(item);
            });
          }
        }
      });
    }

    let priceAvailDate = [];
    if (operationDates && operationDates.length > 0) {
      priceAvailDate = operationDates.filter((item) => {
        //可售卖的日期，与人数有关，进行去重
        return operationDates.find((el) => el.start == item.start) === item;
      });
    }

    const policies = tourActivityInfo.policies;
    const cancelDate = policies.cancel.cancelDeadline;

    const supplierOperators = tourActivityInfo.supplierOperators;

    let supplierInfo = null;
    let supplierCode = null;

    if (supplierOperators && supplierOperators.length > 0) {
      supplierOperators.forEach((item) => {
        if (item.name.codeContext == "SUPPLIER_INFO") {
          //供应商
          supplierInfo = item;
        } else if (item.codeContext == "SUPPLIER_CODE") {
          supplierCode = item.name.code;
        }
      });
    }

    const activityDetailsView = new ActivityDetailsView(
      name,
      shortName,
      tourActivityID,
      operationDuration,
      operationDays,
      imageItems,
      textItems,
      extras,
      meetingPoint,
      routePoints,
      extraLocation,
      pickupLocation,
      dropoffLocation,
      currencyCode,
      participantCategories,
      groups,

      cancelDate,
      priceAvailDate,
      locationInfo
    );
    return activityDetailsView;
  } else {
    return null;
  }
}

function getActivityBookingViewItem(activityReservationDetails) {
  const basicInfo = activityReservationDetails.basicInfo;
  let name = basicInfo.name;
  let groupName = basicInfo.shortName;
  let tourActivityID = basicInfo.tourActivityID;

  let confirmations = activityReservationDetails.confirmation;

  const description = activityReservationDetails.description;

  let shortDescription = description.shortDescription;
  const multimedia = description.multimedia;
  const textItems =
    multimedia.multimediaDescriptionLists &&
    multimedia.multimediaDescriptionLists.length > 0
      ? multimedia.multimediaDescriptionLists[0].textItems
      : [];

  let addressInfo = activityReservationDetails.location.address;
  let addressLines =
    addressInfo.addressLines && addressInfo.addressLines.length > 0
      ? addressInfo.addressLines[0]
      : "";

  const participantInfos = activityReservationDetails.participantInfos;

  let participantInfo = {};

  let contactPerson = {};
  if (participantInfos && participantInfos.length > 0) {
    participantInfo = participantInfos.find(
      (item) => item.category.qualifierInfo.extension == "CONTACT_PERSON"
    );

    let group = participantInfo.group;

    let contact = group.contact;

    let nameTitle = contact.personName.nameTitle;
    let surname = contact.personName.surname;
    let givenName = contact.personName.givenName;
    let phoneNumber = contact.telephoneList[0].phoneNumber;
    let emailAddress = contact.emailList[0].stringValue;

    contactPerson = {
      nameTitle: nameTitle,
      surname: surname,
      givenName: givenName,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
    };
  }

  let cancelDete = dateUtils.formatDate(
    new Date(
      activityReservationDetails.policy.cancel.cancelDeadline.split("T")[0]
    )
  );

  let scheduleDate = activityReservationDetails.schedule.start;

  const supplierOperators = activityReservationDetails.supplierOperators;
  let supplierInfo = null;
  let operaterInfo = null;

  if (supplierOperators && supplierOperators.length > 0) {
    supplierOperators.forEach((item) => {
      if (item.name.codeContext == "OPERATER") {
        //运营商
        supplierInfo = item.name.companyShortName + " (" + item.name.code + ")";
      } else if (item.codeContext == "SUPPLIER") {
        operaterInfo = item.name.companyShortName + " (" + item.name.code + ")";
      }
    });
  }

  const pricing = activityReservationDetails.pricing;
  let participantCategories = pricing.participantCategories;
  let groupInfo =
    pricing.groups && pricing.groups.length > 0 ? pricing.groups[0] : {};

  return new ActivityBookingViewItem(
    name,
    groupName,
    tourActivityID,
    confirmations,
    textItems,
    shortDescription,
    addressInfo,
    addressLines,
    participantInfo,
    contactPerson,
    cancelDete,
    scheduleDate,
    supplierInfo,
    operaterInfo,
    participantCategories,
    groupInfo
  );
}

function getBookInitialRQ(languageRPH, startDate, passengerFormModel) {
  const question = passengerFormModel.question;
  let questionArr = [];
  if (question && question.length > 0) {
    question.forEach((item) => {
      questionArr.push({ code: item.title, answer: item.answer });
    });
  }
  return {
    bookingInfo: {
      resStatus: "Initiate",
      pricing: {
        summary: {
          rph: languageRPH, //rateKey, price中的rph, 从Avail接口响应中获取
        },
      },
      schedule: {
        start: startDate, //预定日期
        end: startDate, //预定日期
      },
      participantInfoList: [
        {
          category: {
            contact: {
              contactType: "HOLDER", //联系人类型
              personName: {
                surname: passengerFormModel.surname,
                givenName: passengerFormModel.givenName,
                nameTitle: passengerFormModel.nameTitle,
              },
              addressList: [
                //地址信息
                {
                  addressLines: [passengerFormModel.addressLines],
                  countryName: {
                    code: passengerFormModel.countryCode,
                  },
                  postalCode: passengerFormModel.postalCode,
                },
              ],
              emailList: [
                //邮箱地址
                {
                  stringValue: passengerFormModel.emailAddress,
                },
              ],
              telephoneList: [
                {
                  phoneNumber:
                    "+" +
                    passengerFormModel.countryAccessCode +
                    " " +
                    passengerFormModel.phoneNumber,
                },
              ],
            },
          },
        },
      ],
      paymentInfoList: [
        {
          rph: "admin",
        },
      ],
      tpaExtensionsType: {
        valueMap: {
          ANSWER: JSON.stringify(questionArr), //问题的回答
        },
      },
    },
  };
}

function getBookResRQ(confIDs) {
  return {
    vehResRQCore: {
      status: "Book",
      uniqueIDs: confIDs,
    },
  };
}

//endregion
export default {
  getSailAvailRQ,
  getActivityDetailRQ,

  ActivitySearchFormModel,
  ActivityViewItem,
  ActivityDetailsView,
  ActivityBookingViewItem,
  ActivityPassengerFormModel,

  getActivityViewItems,
  getActivityDetailsView,
  getActivityBookingViewItem,

  getBookInitialRQ,
  getBookResRQ,
};

import { TripCarRentalItemVO } from "@/viewobject/trip/carrental/tripCarRentalItemVO";
import { TripCarRentalCustomerItemVO } from "@/viewobject/trip/carrental/tripCarRentalCustomerItemVO";

function buildTripCarRentalItem(
  vehicleReservation,
  bookingStatus,
  receiptStatus,
  createdDateTime
) {
  const customer = vehicleReservation.customer;
  const primary = customer.primary;
  const emailType = primary.emailList[0];
  const emailAddress = emailType.stringValue;
  const telephone = primary.telephoneList[0];
  const phoneNumber = telephone.phoneNumber;
  const countryAccessCode = telephone.countryAccessCode;

  const personName = primary.personName;
  const givenName = personName.givenName;
  const surname = personName.surname;
  const nameTitle = personName.nameTitle;

  //司机信息
  const customerItemVO = new TripCarRentalCustomerItemVO(
    nameTitle,
    surname,
    givenName,
    phoneNumber,
    countryAccessCode,
    emailAddress,
    null
  );

  //基本信息
  const vehSegmentCore = vehicleReservation.vehSegmentCore;
  const vehRentalCore = vehSegmentCore.vehRentalCore;
  const pickUpDateTime = vehRentalCore.pickUpDateTime;
  const returnDateTime = vehRentalCore.returnDateTime;
  const pickUpLocation = vehRentalCore.pickUpLocations[0].locationName;
  const returnLocation = vehRentalCore.returnLocations[0].locationName;
  const vehicle = vehSegmentCore.vehicle;
  const pricedEquips = vehSegmentCore.pricedEquips;
  const totalCharge = vehSegmentCore.totalCharge;
  const currencyCode = totalCharge.currencyCode;
  const totalAmount = totalCharge.estimatedTotalAmount;
  const rentalRate = vehSegmentCore.rentalRate;
  const vehicleCharges = rentalRate.vehicleCharges;
  const vehSegmentInfo = vehicleReservation.vehSegmentInfo;
  const pricedCoverages = vehSegmentInfo.pricedCoverages;
  const confIDs = vehSegmentCore.confIDs;

  const minimumDriverAge = rentalRate.rateRestrictions
    ? rentalRate.rateRestrictions.minimumAge
    : null;
  const quoteID = rentalRate.rateQualifier.rateQualifier;

  let vehType = null;
  let vehClass = null;
  let classCode = null;
  let className = null;
  let vehMakeModel = null;
  let vehName = null;
  let pictureURL = null;
  let passengerQuantity = null;
  let baggageQuantity = null;
  let airConditionInd = null;
  let transmissionType = null;
  let doorCount = null;

  if (vehicle) {
    vehType = vehicle.vehType;
    vehClass = vehicle.vehClass;
    classCode = vehClass ? vehClass.vehClassCode : null;
    className = vehClass ? vehClass.vehClassName : null;
    vehMakeModel = vehicle.vehMakeModel;
    vehName = vehMakeModel.name;
    pictureURL = vehicle.pictureURL;
    passengerQuantity = vehicle.passengerQuantity;
    baggageQuantity = vehicle.baggageQuantity;
    airConditionInd = vehicle.airConditionInd;
    transmissionType = vehicle.transmissionType;
    doorCount = vehType ? vehType.doorCount : null;
  }

  const tripCarRentalItemVO = new TripCarRentalItemVO(
    pictureURL,
    vehName,
    currencyCode,
    totalAmount,
    airConditionInd,
    transmissionType,
    doorCount,
    passengerQuantity,
    baggageQuantity,
    classCode,
    className,
    minimumDriverAge,
    quoteID,
    null,
    null,
    null,
    null,
    null,
    confIDs,
    customerItemVO,
    pickUpDateTime,
    returnDateTime,
    pickUpLocation,
    returnLocation,
    bookingStatus,
    receiptStatus,
    createdDateTime
  );
  //保险
  if (pricedCoverages != null && pricedCoverages.length > 0) {
    pricedCoverages.forEach((pricedCoverage) => {
      const selected = pricedCoverage.selected;
      const required = pricedCoverage.required;
      const coverageCode = pricedCoverage.coverage.code;
      const coverageDescription = pricedCoverage.coverage.description;
      const amount = pricedCoverage.charge.amount;
      const coverageCurrency = pricedCoverage.charge.currencyCode;
      const coverageIncluded = selected && amount === 0;

      tripCarRentalItemVO.addNewInsurance(
        coverageIncluded,
        selected,
        required,
        coverageCode,
        coverageDescription,
        amount,
        coverageCurrency
      );
    });
  }

  //设备
  if (pricedEquips && pricedEquips.length > 0) {
    pricedEquips.forEach((pricedEquip) => {
      const required = pricedEquip.required;
      const selected = pricedEquip.selected;
      const equipment = pricedEquip.equipment;
      const equipmentCode = equipment.code;
      const description = equipment.description;
      const charge = pricedEquip.charge;
      const equipAmount = charge.amount;
      const chargeCurrency = charge.currencyCode;
      //RENTAL 指计算价格单位为每次租赁， DAY指计算价格单位为每天
      const unitName = charge.calculations[0].unitName;

      tripCarRentalItemVO.addNewEquipment(
        required,
        selected,
        equipment,
        equipmentCode,
        unitName,
        equipAmount,
        chargeCurrency
      );
    });
  }

  //费用
  if (vehicleCharges && vehicleCharges.length > 0) {
    vehicleCharges.forEach((vehicleCharge) => {
      const description = vehicleCharge.description;
      const amount = vehicleCharge.amount;
      const currencyCode = vehicleCharge.currencyCode;
      const taxInclusive = vehicleCharge.taxInclusive;
      const taxAmounts = vehicleCharge.taxAmounts;
      const purpose = vehicleCharge.purpose; // 1 for vehicle rental, 4 for coverage, 6 for fee, 7 for tax

      tripCarRentalItemVO.addNewCharge(
        description,
        amount,
        currencyCode,
        taxInclusive,
        taxAmounts
      );
    });
  }

  return tripCarRentalItemVO;
}

export default {
  buildTripCarRentalItem,
};

export class TripAirItemTicketingVO {
  /**
   *
   * @param status string，出票状态，TICKETED - 已出票，UNTICKETED - 未出票
   * @param flightSegmentRefNumbers Array，string数组，飞机航段唯一标识数组
   * @param serviceReference string，服务id
   * @param ticketDocumentNbr string，票号
   * @param ticketType string，票类型，Ticket-机票，EMD-A-附加服务
   * @param travelerRefNumber string，乘客唯一标识
   * @param requestedTicketingDate string，出票日期时间，如2024-02-23
   * @param ticketingVendor string，出票的供应商代码，如SQ
   * @param ticketTimeLimit string，Deferred Payment后该票可出票的最后日期时间
   */
  constructor(
    status,
    flightSegmentRefNumbers,
    serviceReference,
    ticketDocumentNbr,
    ticketType,
    travelerRefNumber,
    requestedTicketingDate,
    ticketingVendor,
    ticketTimeLimit
  ) {
    this.status = status;
    this.flightSegmentRefNumbers = flightSegmentRefNumbers;
    this.serviceReference = serviceReference;
    this.ticketDocumentNbr = ticketDocumentNbr;
    this.ticketType = ticketType;
    this.travelerRefNumber = travelerRefNumber;
    this.requestedTicketingDate = requestedTicketingDate;
    this.ticketingVendor = ticketingVendor;
    this.ticketTimeLimit = ticketTimeLimit;
  }
}

import axios from "@/utils/axiosUtils";

function carRentalReserve(rq) {
  let url = "/api/v3/sales/shopping/vehicle/veh-res";
  return axios.post(url, rq, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  carRentalReserve,
};

<script setup>
import { reactive, watchEffect } from "vue";

const props = defineProps({
  promotionDeals: {},
});

let promotionDeals = reactive([]);

// promotionDeals = reactive(props.promotionDeals)

watchEffect(() => {
  props.promotionDeals, (promotionDeals = props.promotionDeals);
  //按照SequenceNumber排序
  if (promotionDeals && promotionDeals.length > 0) {
    promotionDeals.sort((a, b) => {
      return a.sequenceNumber - b.sequenceNumber;
    });
  }
});
const baseUrl = "/api/v3/content/collections/images";
</script>
<template>
  <section
    id="go_beyond_area"
    class="section_padding_top"
    v-if="promotionDeals && promotionDeals.length > 0"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="heading_left_area">
            <h2>{{ $t("go-beyond-your-imagination") }}</h2>
            <h5>{{ $t("discover-your-ideal") }}</h5>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-6 col-sm-6 col-12"
          v-for="(promotionDeal, promotionIndex) in promotionDeals"
          :key="promotionIndex"
        >
          <div class="imagination_boxed">
            <a
              :href="promotionDeal.links === null ? '#' : promotionDeal.links"
              v-if="promotionDeal && promotionDeal.image"
            >
              <img :src="baseUrl + promotionDeal.image.url" alt="img" />
            </a>
            <h3>
              <a
                :href="promotionDeal.links === null ? '#' : promotionDeal.links"
                >{{ promotionDeal.title }}</a
              >
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

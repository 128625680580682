import { FlightSegmentVO } from "@/viewobject/sales/flight/shopping/flightSegmentVO";
import { FlightItemVO } from "@/viewobject/sales/flight/shopping/flightItemVO";
import { FlightPriceClassVO } from "@/viewobject/sales/flight/shopping/flightPriceClassVO";
import { TripAirItemVO } from "@/viewobject/trip/flight/tripAirItemVO";
import { TripAirItemPaymentDetailVO } from "@/viewobject/trip/flight/payment/tripAirItemPaymentDetailVO";
import { TripAirItemTicketingVO } from "@/viewobject/trip/flight/ticketing/tripAirItemTicketingVO";
import { TripAirItemFareVO } from "@/viewobject/trip/flight/tripAirItemFareVO";
import flightAncillaryServiceTools from "@/tools/v2/flight/ancillaryservice/flightAncillaryServiceTools";
import { TripAirItemPassengerTypeFareVO } from "@/viewobject/trip/flight/passenger/tripAirItemPassengerTypeFareVO";
import { TripAirItemAncillaryFareVO } from "@/viewobject/trip/flight/ancillary/tripAirItemAncillaryFareVO";
import dateUtils from "@/utils/dateUtils";
import { FlightOriginDestinationFormItem } from "@/formmodel/sales/flight/shopping/flightShoppingFormModel";

import { TripAirItemPassengerVO } from "@/viewobject/trip/flight/passenger/tripAirItemPassengerVO";
import { TripPassengerEmailVO } from "@/viewobject/trip/common/tripPassengerEmailVO";
import { TripPassengerPhoneVO } from "@/viewobject/trip/common/tripPassengerPhoneVO";
import { TripPassengerDocumentVO } from "@/viewobject/trip/common/tripPassengerDocumentVO";
import FlightFareOptionBuilder from "@/stores/v2/flight/upsell/flightFareOptionBuilder";

function buildUpsellRQ(airItineraryRPH, fareSegmentBasisCodes) {
  if (fareSegmentBasisCodes && fareSegmentBasisCodes.length > 0) {
    for (const fareSegmentBasisCode of fareSegmentBasisCodes) {
      let number = fareSegmentBasisCode.fareBasisCode.indexOf("/");
      if (number >= 0) {
        fareSegmentBasisCode.fareBasisCode =
          fareSegmentBasisCode.fareBasisCode.substring(0, number);
      }
    }
  }

  return {
    type: "UPSELL",
    airItinerary: {
      airItineraryRPH: airItineraryRPH,
    },
    flightReferences: fareSegmentBasisCodes,
  };
}

function buildOldTripAirItem(pricedItineraryList) {
  if (pricedItineraryList && pricedItineraryList.length > 0) {
    let pricedItinerary = null;
    pricedItineraryList.forEach((item) => {
      let origFareIndicator = null;
      const airItineraryPricingInfo = item.airItineraryPricingInfo;
      if (
        airItineraryPricingInfo.tpaExtensions &&
        airItineraryPricingInfo.tpaExtensions.length > 0
      ) {
        const tpaExtension = airItineraryPricingInfo.tpaExtensions[0];
        origFareIndicator = tpaExtension.valueMap.origFareIndicator;
      }
      if (origFareIndicator === "true") {
        pricedItinerary = item;
      }
    });

    if (pricedItinerary) {
      const approvalRequired = pricedItinerary.approvalRequired;
      const outOfPolicy = pricedItinerary.outOfPolicy;
      const upgradeAllowed = pricedItinerary.upgradeAllowed;
      const reasonRequired = pricedItinerary.reasonRequired;
      const reasons = pricedItinerary.reasons;
      const statusId =
        pricedItinerary.statusId === "BOOKING_ITEM_CANCELLED"
          ? "CANCELLED"
          : pricedItinerary.statusId;

      const bookingReferenceIdList = pricedItinerary.bookingReferenceIdList;
      const airItinerary = pricedItinerary.airItinerary;
      const priceInfo = pricedItinerary.priceInfo;
      const travelerInfo = pricedItinerary.travelerInfo;
      const fulfillment = pricedItinerary.fulfillment;
      const ticketing = pricedItinerary.ticketing;
      const tpaExtensions = pricedItinerary.tpaExtensions;
      const airItineraryKey = airItinerary.airItineraryKey;
      const airItineraryRPH = airItinerary.airItineraryRPH;
      const originDestinationOptions = airItinerary.originDestinationOptions;
      const offer = pricedItinerary.offer;
      const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;

      const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
      const totalFare = itinTotalFare.totalFare;
      const totalFareAmount = totalFare.amount;

      const bookingSource = pricedItinerary.bookingSource;

      let quoteID = null;
      let supplierCode = null;
      if (airItineraryPricingInfo) {
        quoteID = airItineraryPricingInfo.quoteID;
        supplierCode =
          airItineraryPricingInfo.priceRequestInformation &&
          airItineraryPricingInfo.priceRequestInformation.negotiatedFareCodeList
            ? airItineraryPricingInfo.priceRequestInformation
                .negotiatedFareCodeList[0].supplierCode
            : airItineraryPricingInfo.pricingSource;
      }

      const bookingSourceType = pricedItinerary.bookingSourceType;

      let tripAirItemId = null;
      let tripItemId = null;
      let recordLocator = null;
      let idContext = null;
      if (bookingReferenceIdList && bookingReferenceIdList.length > 0) {
        for (const bookingReferenceId of bookingReferenceIdList) {
          const idType = bookingReferenceId.type;
          const id = bookingReferenceId.id;
          if (idType === "RECORD_LOCATOR") {
            recordLocator = id;
            idContext = bookingReferenceId.id_context;
          } else if (idType === "BOOKING_ITEM_ID") {
            tripItemId = id;
          } else if (idType === "BOOKING_AIR_ITEM_ID") {
            tripAirItemId = id;
          }
        }
      }

      let ssrRemarks = null;
      let origFareIndicator = null;
      if (
        pricedItinerary.tpaExtensions &&
        pricedItinerary.tpaExtensions.valueMap
      ) {
        ssrRemarks = pricedItinerary.tpaExtensions.valueMap["specialRemarks"];
        origFareIndicator =
          pricedItinerary.tpaExtensions.valueMap.origFareIndicator;
      }

      const tripAirItemVO = new TripAirItemVO(
        statusId,
        tripItemId,
        tripAirItemId,
        recordLocator,
        idContext,
        airItineraryRPH,
        airItineraryKey,
        quoteID,
        bookingSourceType,
        supplierCode,
        outOfPolicy,
        approvalRequired,
        upgradeAllowed,
        reasonRequired,
        reasons,
        null,
        null,
        null,
        null,
        null,
        null,
        ssrRemarks
      );

      //Passengers
      if (
        travelerInfo &&
        travelerInfo.airTravelerList &&
        travelerInfo.airTravelerList.length > 0
      ) {
        const airTravelerList = travelerInfo.airTravelerList;
        for (const airTraveler of airTravelerList) {
          const tripAirItemPassengerVO = buildPassenger(airTraveler);
          tripAirItemVO.addPassenger(tripAirItemPassengerVO);
        }
      }

      if (
        fulfillment &&
        fulfillment.paymentDetailList &&
        fulfillment.paymentDetailList.length > 0
      ) {
        const paymentDetailList = fulfillment.paymentDetailList;
        for (const paymentDetail of paymentDetailList) {
          const paymentType = paymentDetail.paymentType;
          const paymentAmount = paymentDetail.paymentAmount;
          const currencyCode = paymentAmount.currencyCode;
          const amount = paymentAmount.amount;
          const paymentCard = paymentDetail.paymentCard;
          const cardCode = paymentCard ? paymentCard.cardCode : null;
          const remark = paymentCard ? paymentCard.remark : null;
          const cardNumber = paymentCard ? paymentCard.cardNumber : null;

          const tripAirItemPaymentDetailVO = new TripAirItemPaymentDetailVO(
            null,
            paymentType,
            cardCode,
            cardNumber,
            null,
            remark,
            currencyCode,
            amount
          );
          tripAirItemVO.addPaymentDetail(tripAirItemPaymentDetailVO);
        }
      }

      if (ticketing && ticketing.length > 0) {
        for (const ticketingEle of ticketing) {
          const flightSegmentRefNumbers = ticketingEle.flightSegmentRefNumber;
          const travelerRefNumber = ticketingEle.travelerRefNumber;
          const ticketTimeLimit = ticketingEle.ticketTimeLimit;
          const serviceReference = ticketingEle.serviceReference;
          const ticketDocumentNbr = ticketingEle.ticketDocumentNbr;
          const ticketType = ticketingEle.ticketType;
          const requestedTicketingDate = ticketingEle.requestedTicketingDate;
          const ticketingVendor = ticketingEle.ticketingVendor
            ? ticketingEle.ticketingVendor.code
            : null;
          const status = ticketTimeLimit != null ? "UNTICKETED" : "TICKETED";
          const tripAirItemTicketingVO = new TripAirItemTicketingVO(
            status,
            flightSegmentRefNumbers,
            serviceReference,
            ticketDocumentNbr,
            ticketType,
            travelerRefNumber,
            requestedTicketingDate,
            ticketingVendor,
            ticketTimeLimit
          );

          tripAirItemVO.addTicketing(tripAirItemTicketingVO);
        }
      }

      if (
        tpaExtensions &&
        tpaExtensions.valueMap &&
        tpaExtensions.valueMap.ticketRemarks
      ) {
        tripAirItemVO.ticketingRemarks = tpaExtensions.valueMap.ticketRemarks;
      }

      //Air Item Fare
      const tripAirItemFareVO = buildTripAirItemFareVO(
        airItineraryPricingInfo,
        priceInfo,
        offer
      );
      tripAirItemVO.addTripAirItemFare(tripAirItemFareVO);

      if (!originDestinationOptions) return tripAirItemVO;

      //Flight Items
      let tripSequenceNumber = 0;
      for (const originDestinationOption of originDestinationOptions) {
        const flightItemVO = buildFlightItemVO(
          originDestinationOption,
          airItineraryKey,
          tripSequenceNumber,
          offer
        );
        tripAirItemVO.addFlightItem(flightItemVO);

        tripSequenceNumber++;
      }

      tripAirItemVO.flightItems.sort((a, b) => {
        const result =
          new Date(a.departureDateTime).getTime() -
          new Date(b.departureDateTime).getTime();
        return result;
      });
      return tripAirItemVO;
    }
  }
}

function buildTripAirItems(pricedItineraryList) {
  const tripAirItems = [];
  if (pricedItineraryList && pricedItineraryList.length > 0) {
    for (const pricedItinerary of pricedItineraryList) {
      const approvalRequired = pricedItinerary.approvalRequired;
      const outOfPolicy = pricedItinerary.outOfPolicy;
      const upgradeAllowed = pricedItinerary.upgradeAllowed;
      const reasonRequired = pricedItinerary.reasonRequired;
      const reasons = pricedItinerary.reasons;
      const statusId =
        pricedItinerary.statusId === "BOOKING_ITEM_CANCELLED"
          ? "CANCELLED"
          : pricedItinerary.statusId;

      const bookingReferenceIdList = pricedItinerary.bookingReferenceIdList;
      const airItinerary = pricedItinerary.airItinerary;
      const priceInfo = pricedItinerary.priceInfo;
      const travelerInfo = pricedItinerary.travelerInfo;
      const fulfillment = pricedItinerary.fulfillment;
      const ticketing = pricedItinerary.ticketing;
      const tpaExtensions = pricedItinerary.tpaExtensions;
      const airItineraryKey = airItinerary.airItineraryKey;
      const airItineraryRPH = airItinerary.airItineraryRPH;
      const originDestinationOptions = airItinerary.originDestinationOptions;
      const offer = pricedItinerary.offer;
      const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;

      const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
      const totalFare = itinTotalFare.totalFare;
      const totalFareAmount = totalFare.amount;

      const bookingSource = pricedItinerary.bookingSource;

      let quoteID = null;
      let supplierCode = null;
      if (airItineraryPricingInfo) {
        quoteID = airItineraryPricingInfo.quoteID;
        supplierCode =
          airItineraryPricingInfo.priceRequestInformation &&
          airItineraryPricingInfo.priceRequestInformation.negotiatedFareCodeList
            ? airItineraryPricingInfo.priceRequestInformation
                .negotiatedFareCodeList[0].supplierCode
            : airItineraryPricingInfo.pricingSource;
      }

      const bookingSourceType = pricedItinerary.bookingSourceType;

      let tripAirItemId = null;
      let tripItemId = null;
      let recordLocator = null;
      let idContext = null;
      if (bookingReferenceIdList && bookingReferenceIdList.length > 0) {
        for (const bookingReferenceId of bookingReferenceIdList) {
          const idType = bookingReferenceId.type;
          const id = bookingReferenceId.id;
          if (idType === "RECORD_LOCATOR") {
            recordLocator = id;
            idContext = bookingReferenceId.id_context;
          } else if (idType === "BOOKING_ITEM_ID") {
            tripItemId = id;
          } else if (idType === "BOOKING_AIR_ITEM_ID") {
            tripAirItemId = id;
          }
        }
      }

      let ssrRemarks = null;
      if (
        pricedItinerary.tpaExtensions &&
        pricedItinerary.tpaExtensions.valueMap
      ) {
        ssrRemarks = pricedItinerary.tpaExtensions.valueMap["specialRemarks"];
      }

      const tripAirItemVO = new TripAirItemVO(
        statusId,
        tripItemId,
        tripAirItemId,
        recordLocator,
        idContext,
        airItineraryRPH,
        airItineraryKey,
        quoteID,
        bookingSourceType,
        supplierCode,
        outOfPolicy,
        approvalRequired,
        upgradeAllowed,
        reasonRequired,
        reasons,
        null,
        null,
        null,
        null,
        null,
        null,
        ssrRemarks
      );

      //Passengers
      if (
        travelerInfo &&
        travelerInfo.airTravelerList &&
        travelerInfo.airTravelerList.length > 0
      ) {
        const airTravelerList = travelerInfo.airTravelerList;
        for (const airTraveler of airTravelerList) {
          const tripAirItemPassengerVO = buildPassenger(airTraveler);
          tripAirItemVO.addPassenger(tripAirItemPassengerVO);
        }
      }

      if (
        fulfillment &&
        fulfillment.paymentDetailList &&
        fulfillment.paymentDetailList.length > 0
      ) {
        const paymentDetailList = fulfillment.paymentDetailList;
        for (const paymentDetail of paymentDetailList) {
          const paymentType = paymentDetail.paymentType;
          const paymentAmount = paymentDetail.paymentAmount;
          const currencyCode = paymentAmount.currencyCode;
          const amount = paymentAmount.amount;
          const paymentCard = paymentDetail.paymentCard;
          const cardCode = paymentCard ? paymentCard.cardCode : null;
          const remark = paymentCard ? paymentCard.remark : null;
          const cardNumber = paymentCard ? paymentCard.cardNumber : null;

          const tripAirItemPaymentDetailVO = new TripAirItemPaymentDetailVO(
            null,
            paymentType,
            cardCode,
            cardNumber,
            null,
            remark,
            currencyCode,
            amount
          );
          tripAirItemVO.addPaymentDetail(tripAirItemPaymentDetailVO);
        }
      }

      if (ticketing && ticketing.length > 0) {
        for (const ticketingEle of ticketing) {
          const flightSegmentRefNumbers = ticketingEle.flightSegmentRefNumber;
          const travelerRefNumber = ticketingEle.travelerRefNumber;
          const ticketTimeLimit = ticketingEle.ticketTimeLimit;
          const serviceReference = ticketingEle.serviceReference;
          const ticketDocumentNbr = ticketingEle.ticketDocumentNbr;
          const ticketType = ticketingEle.ticketType;
          const requestedTicketingDate = ticketingEle.requestedTicketingDate;
          const ticketingVendor = ticketingEle.ticketingVendor
            ? ticketingEle.ticketingVendor.code
            : null;
          const status = ticketTimeLimit != null ? "UNTICKETED" : "TICKETED";
          const tripAirItemTicketingVO = new TripAirItemTicketingVO(
            status,
            flightSegmentRefNumbers,
            serviceReference,
            ticketDocumentNbr,
            ticketType,
            travelerRefNumber,
            requestedTicketingDate,
            ticketingVendor,
            ticketTimeLimit
          );

          tripAirItemVO.addTicketing(tripAirItemTicketingVO);
        }
      }

      if (
        tpaExtensions &&
        tpaExtensions.valueMap &&
        tpaExtensions.valueMap.ticketRemarks
      ) {
        tripAirItemVO.ticketingRemarks = tpaExtensions.valueMap.ticketRemarks;
      }

      //Air Item Fare
      const tripAirItemFareVO = buildTripAirItemFareVO(
        airItineraryPricingInfo,
        priceInfo,
        offer
      );
      tripAirItemVO.addTripAirItemFare(tripAirItemFareVO);

      if (!originDestinationOptions) return tripAirItemVO;

      //Flight Items
      let tripSequenceNumber = 0;
      for (const originDestinationOption of originDestinationOptions) {
        const flightItemVO = buildFlightItemVO(
          originDestinationOption,
          airItineraryKey,
          tripSequenceNumber,
          offer
        );
        flightItemVO.bestTotalPrice = totalFareAmount;

        tripAirItemVO.addFlightItem(flightItemVO);

        tripSequenceNumber++;
      }

      tripAirItemVO.flightItems.sort((a, b) => {
        const result =
          new Date(a.departureDateTime).getTime() -
          new Date(b.departureDateTime).getTime();
        return result;
      });

      tripAirItems.push(tripAirItemVO);
    }
  }

  return tripAirItems;
}

function buildFlightSegmentVO(flightSegment) {
  const departureDateTime = flightSegment.departureDateTime;
  const arrivalDateTime = flightSegment.arrivalDateTime;
  const segmentKey = flightSegment.segmentKey;
  const flightNumber = flightSegment.flightNumber;
  const fareBasisCode = flightSegment.fareBasisCode;
  const fareTypeName = flightSegment.fareTypeName;
  const resBookDesigCode = flightSegment.resBookDesigCode;
  const numberInParty = flightSegment.numberInParty;
  const stopoverInd = flightSegment.stopoverInd;
  const departureAirportCode = flightSegment.departureAirportCode;
  const arrivalAirportCode = flightSegment.arrivalAirportCode;
  const operatingAirlineCode = flightSegment.operatingAirlineCode;
  const operatingAirlineName = flightSegment.operatingAirlineName;
  const equipmentCode = flightSegment.equipmentCode;
  const marketingAirlineCode = flightSegment.marketingAirlineCode;
  const cabinClass = flightSegment.cabinClass;
  const baggageAllowance = flightSegment.baggageAllowance;
  const rph = flightSegment.rph;

  return new FlightSegmentVO(
    departureDateTime,
    arrivalDateTime,
    segmentKey,
    flightNumber,
    fareBasisCode,
    fareTypeName,
    resBookDesigCode,
    numberInParty,
    stopoverInd,
    departureAirportCode,
    arrivalAirportCode,
    operatingAirlineCode,
    operatingAirlineName,
    equipmentCode,
    marketingAirlineCode,
    cabinClass,
    baggageAllowance,
    rph
  );
}

function buildFlightItemVO(
  originDestinationOption,
  airItineraryKey,
  tripSequenceNumber,
  offer
) {
  const originLocationCode = originDestinationOption.originLocation;
  const destinationLocationCode = originDestinationOption.destinationLocation;
  const connections = originDestinationOption.connections;
  const numStops = originDestinationOption.numStops;
  const durationInMins = originDestinationOption.durationInMins;
  const originDestinationKey = originDestinationOption.originDestinationKey;
  const flightSegments = originDestinationOption.flightSegments;
  const originDestRPH = originDestinationOption.rph;
  let priceClassId = null;
  if (offer && offer.summary && offer.summary.length > 0) {
    for (const summaryEle of offer.summary) {
      if (summaryEle.origDestRPH === originDestRPH)
        priceClassId = summaryEle.bundleID;
    }
  }

  let originLocationName = null;
  let departureAirportCodeFlight = null;
  let destinationLocationName = null;
  let arrivalAirportCodeFlight = null;
  let departureDateTimeFlight = null;
  let arrivalDateTimeFlight = null;
  let currencyCode = null;
  let bestTotalPrice = null;

  const flightSegmentVOs = [];
  for (const flightSegment of flightSegments) {
    const flightSegmentVO = buildFlightSegmentVO(flightSegment);
    flightSegmentVOs.push(flightSegmentVO);

    if (!departureDateTimeFlight) {
      departureDateTimeFlight = flightSegmentVO.departureDateTime;
      departureAirportCodeFlight = flightSegmentVO.departureAirportCode;
    }
    arrivalDateTimeFlight = flightSegmentVO.arrivalDateTime;
    arrivalAirportCodeFlight = flightSegmentVO.arrivalAirportCode;
  }

  return new FlightItemVO(
    airItineraryKey,
    tripSequenceNumber,
    originLocationCode,
    originLocationName,
    departureAirportCodeFlight,
    destinationLocationCode,
    destinationLocationName,
    arrivalAirportCodeFlight,
    departureDateTimeFlight,
    arrivalDateTimeFlight,
    originDestRPH,
    originDestinationKey,
    durationInMins,
    connections,
    numStops,
    currencyCode,
    bestTotalPrice,
    null,
    null,
    priceClassId,
    flightSegmentVOs
  );
}

function buildTripAirItemFareVO(airItineraryPricingInfo, priceInfo, offer) {
  // const quoteID = airItineraryPricingInfo.quoteID;
  let priceExpirationDatetime = null;

  const itinTotalFare =
    airItineraryPricingInfo &&
    airItineraryPricingInfo.itinTotalFares &&
    airItineraryPricingInfo.itinTotalFares.length > 0
      ? airItineraryPricingInfo.itinTotalFares[0]
      : null;
  const totalFare = itinTotalFare ? itinTotalFare.totalFare : null;
  const discountList = itinTotalFare ? itinTotalFare.discountList : null;

  let totalDiscount = null;
  let promotionRemarks = null;
  if (discountList && discountList.length > 0) {
    const discount = discountList[0];
    if (discount) totalDiscount = discount.amount;
  }
  const localCreateDateTime = itinTotalFare
    ? itinTotalFare.localCreateDateTime
    : null;
  const totalFareCurrencyCode = totalFare ? totalFare.currencyCode : null;
  const totalFareAmount = totalFare ? totalFare.amount : null;
  const ptcFareBreakdowns = airItineraryPricingInfo
    ? airItineraryPricingInfo.PTC_FareBreakdowns
    : null;
  const tpaExtensions = airItineraryPricingInfo
    ? airItineraryPricingInfo.tpaExtensions
    : null;

  if (tpaExtensions && tpaExtensions.length > 0 && tpaExtensions[0].valueMap) {
    priceExpirationDatetime = tpaExtensions[0].valueMap.priceExpirationDatetime;
  }
  if (tpaExtensions && tpaExtensions.length > 0 && tpaExtensions[0].valueMap) {
    promotionRemarks = tpaExtensions[0].valueMap.Exception;
  }
  let repriceRequired = null;
  if (priceInfo && priceInfo.repriceRequired != null) {
    repriceRequired = priceInfo.repriceRequired;
  }
  if (
    priceInfo &&
    priceInfo.tpaExtensions &&
    priceInfo.tpaExtensions.length > 0 &&
    priceInfo.tpaExtensions[0].valueMap
  ) {
    priceExpirationDatetime =
      priceInfo.tpaExtensions[0].valueMap.priceExpirationDatetime;
  }

  const tripAirItemFareVO = new TripAirItemFareVO(
    repriceRequired,
    priceExpirationDatetime,
    totalFareAmount,
    totalDiscount,
    totalFareCurrencyCode,
    null,
    null,
    promotionRemarks
  );

  let totalAmountCount = 0;
  let currencyCode = null;
  if (ptcFareBreakdowns && ptcFareBreakdowns.length > 0) {
    for (const ptcFareBreakdown of ptcFareBreakdowns) {
      const airItemPassengerTypeFareVO =
        buildPassengerTypeFareVO(ptcFareBreakdown);

      totalAmountCount +=
        airItemPassengerTypeFareVO && airItemPassengerTypeFareVO.totalAmount
          ? airItemPassengerTypeFareVO.totalAmount
          : 0;
      currencyCode =
        airItemPassengerTypeFareVO && airItemPassengerTypeFareVO.currencyCode
          ? airItemPassengerTypeFareVO.currencyCode
          : null;

      tripAirItemFareVO.addPassengerFare(airItemPassengerTypeFareVO);
    }
  }

  if (offer && offer.purchased && offer.purchased.length > 0) {
    let purchased = offer.purchased;
    for (const offerPurchased of purchased) {
      const purchaseItemList = offerPurchased.purchaseItemList;
      for (const purchaseItem of purchaseItemList) {
        const airItemAncillaryFareVO = buildAncillaryFareVO(purchaseItem);
        totalAmountCount += airItemAncillaryFareVO.amount;
        if (!currencyCode) currencyCode = airItemAncillaryFareVO.currencyCode;
        tripAirItemFareVO.addAncillaryFare(airItemAncillaryFareVO);
      }
    }
  }
  if (offer && offer.priced && offer.priced.length > 0) {
    const airItemAncillaryFareVOs =
      flightAncillaryServiceTools.buildAncillaryOptions(offer.priced, "PRICED");
    tripAirItemFareVO.addAncillaryFares(airItemAncillaryFareVOs);
    for (const airItemAncillaryFareVO of airItemAncillaryFareVOs) {
      if (!currencyCode) currencyCode = airItemAncillaryFareVO.currencyCode;
      totalAmountCount += airItemAncillaryFareVO.amount;
    }
  }

  if (!tripAirItemFareVO.totalAmount) {
    tripAirItemFareVO.currencyCode = currencyCode;
    tripAirItemFareVO.totalAmount = totalAmountCount;
  }

  return tripAirItemFareVO;
}

function buildPassengerTypeFareVO(ptcFareBreakdown) {
  //fareUsage == 'TICKET FEE'
  const priceQuoteNumber = ptcFareBreakdown.rph;
  const localCreateDateTime = ptcFareBreakdown.localCreateDateTime;
  const flightRefNumberRPHList = ptcFareBreakdown.flightRefNumberRPHList;
  const travelerRefNumberList = ptcFareBreakdown.travelerRefNumberList;
  const passengerTypeCode = ptcFareBreakdown.passengerTypeQuantity.code;
  const quantity = ptcFareBreakdown.passengerTypeQuantity.quantity;
  const passengerFares = ptcFareBreakdown.passengerFareList;
  if (!passengerFares) return null;

  const travelerRefNumbers = [];
  for (const travelerRefNumber of travelerRefNumberList) {
    travelerRefNumbers.push(travelerRefNumber.rph);
  }

  let passengerFareBaseFare = null;
  let passengerFareTotalFare = null;

  let fareBaggageAllowanceList = null;
  let taxes = null;
  let currencyCode = null;
  let baseAmount = null;
  let taxesAmount = null;
  let feesAmount = null;
  let totalAmount = null;
  let discount = null;

  for (const passengerFare of passengerFares) {
    if (passengerFare.usage === "PassengerFare") {
      let baseFare = passengerFare.baseFare;
      let totalFare = passengerFare.totalFare;
      passengerFareBaseFare = baseFare.amount;
      passengerFareTotalFare = totalFare.amount;
    } else if (passengerFare.usage === "TicketFee") {
      fareBaggageAllowanceList = passengerFare.fareBaggageAllowanceList;
      const equivFares = passengerFare.equivFares;
      let baseFare = passengerFare.baseFare;
      if (equivFares && equivFares.length > 0) {
        baseFare = equivFares[0];
      }
      baseAmount = baseFare.amount;
      currencyCode = baseFare.currencyCode;
      taxesAmount = passengerFare.taxes.amount;
      const totalFare = passengerFare.totalFare;
      totalAmount = totalFare.amount;
      taxes = passengerFare.taxes.taxes;
      if (passengerFare.fees && passengerFare.fees.amount)
        feesAmount = passengerFare.fees.amount;
      const discountList = passengerFare.discountList;

      if (discountList && discountList.length > 0) {
        const discountItem = discountList[0];
        if (discountItem) discount = discountItem.amount;
      }
    }
  }

  const tripAirItemPassengerTypeFareVO = new TripAirItemPassengerTypeFareVO(
    priceQuoteNumber,
    flightRefNumberRPHList,
    travelerRefNumbers,
    passengerTypeCode,
    quantity,
    currencyCode,
    baseAmount,
    taxesAmount,
    feesAmount,
    totalAmount,
    null,
    null,
    localCreateDateTime,
    discount
  );

  if (fareBaggageAllowanceList && fareBaggageAllowanceList.length > 0) {
    for (const fareBaggageAllowance of fareBaggageAllowanceList) {
      const flightSegmentRPH = fareBaggageAllowance.flightSegmentRPH;
      const unitOfMeasureQuantity = fareBaggageAllowance.unitOfMeasureQuantity
        ? fareBaggageAllowance.unitOfMeasureQuantity
        : 0;
      const unitOfMeasure =
        fareBaggageAllowance.unitOfMeasure &&
        fareBaggageAllowance.unitOfMeasure.toLowerCase() === "kg"
          ? "Kg"
          : "Piece(s)";

      tripAirItemPassengerTypeFareVO.addNewBaggageAllowance(
        unitOfMeasureQuantity,
        unitOfMeasure,
        flightSegmentRPH
      );
    }
  }

  if (taxes && taxes.length > 0) {
    for (const tax of taxes) {
      const taxCode = tax.taxCode;
      const taxCurrencyCode =
        tax.currencyCode == null ? currencyCode : tax.currencyCode;
      const amount = tax.amount;
      const taxName = tax.taxName == null ? taxCode : tax.taxName;
      tripAirItemPassengerTypeFareVO.addNewTaxFeeSummary(
        taxCode,
        "TAX",
        true,
        taxName,
        taxCurrencyCode,
        amount
      );
    }
  }

  return tripAirItemPassengerTypeFareVO;
}

function buildAncillaryFareVO(purchaseItem) {
  let name = purchaseItem.name;
  const id = purchaseItem.id;
  const travelerRPH = purchaseItem.travelerRPH;
  const flightSegmentRPH = purchaseItem.flightSegmentRPH;
  const pricing = purchaseItem.pricing;
  const shortDescription = purchaseItem.shortDescription;
  const amount = pricing.amount;
  const pricingCurrency = pricing.pricingCurrency;
  const appliesTo = purchaseItem.appliesTo;
  let rowNumber = null;
  let seatNumber = null;
  if (appliesTo) {
    rowNumber = appliesTo.rowNumber;
    seatNumber = appliesTo.seatNumber;
  }

  let type = null;
  let code = purchaseItem.serviceCode;
  if ("189C" === code) {
    type = "SEAT";
    // name += " - " + shortDescription;
  } else {
    type = "SERVICE";
  }

  if ("1644" === code && shortDescription) {
    // name += " - " + shortDescription;
  }

  const tripAirItemAncillaryFareVO = new TripAirItemAncillaryFareVO(
    "PURCHASED",
    type,
    code,
    name,
    pricingCurrency,
    amount,
    id,
    flightSegmentRPH,
    travelerRPH,
    null,
    seatNumber,
    rowNumber,
    null
  );
  if (
    purchaseItem &&
    purchaseItem.productGroups &&
    purchaseItem.productGroups.length > 0
  ) {
    let productGroups = purchaseItem.productGroups;
    for (const productGroup of productGroups) {
      let groupCode = productGroup.code;
      let groupName = productGroup.description;
      let subGroupCode = null;
      let subGroupName = null;
      if (productGroup.subGroups && productGroup.subGroups.length > 0) {
        let subGroup = productGroup.subGroups[0];
        subGroupCode = subGroup.code;
        subGroupName = subGroup.description;
      }

      tripAirItemAncillaryFareVO.addNewGroup(
        groupCode,
        groupName,
        subGroupCode,
        subGroupName
      );
    }
  }

  return tripAirItemAncillaryFareVO;
}

function buildItineraryFareOptionKeyMap(airLowFareSearchRS) {
  if (
    !airLowFareSearchRS ||
    !airLowFareSearchRS.pricedItineraryList ||
    airLowFareSearchRS.pricedItineraryList.length === 0
  )
    return null;

  const pricedItineraryList = airLowFareSearchRS.pricedItineraryList;
  const itineraryFareOptionKeyMap = {};
  for (const pricedItinerary of pricedItineraryList) {
    const airItineraryRPH = pricedItinerary.airItinerary.airItineraryRPH;
    const airItineraryKey = pricedItinerary.rph;
    const bookingSource = pricedItinerary.bookingSource;
    const offer = pricedItinerary.offer;
    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
    const totalFare = itinTotalFare.totalFare;
    const totalFareCurrencyCode = totalFare.currencyCode;
    const totalFareAmount = totalFare.amount;

    const supplierCode =
      airItineraryPricingInfo.priceRequestInformation &&
      airItineraryPricingInfo.priceRequestInformation.negotiatedFareCodeList
        ? airItineraryPricingInfo.priceRequestInformation
            .negotiatedFareCodeList[0].supplierCode
        : airItineraryPricingInfo.pricingSource;
    let originDestinationKeys = "";
    const originDestinationOptions =
      pricedItinerary.airItinerary.originDestinationOptions;
    originDestinationOptions.forEach((originDes, originDesIndex) => {
      originDestinationKeys += originDes.originDestinationKey;
      if (originDesIndex < originDestinationOptions.length - 1) {
        originDestinationKeys += "#";
      }
    });

    let key =
      supplierCode +
      ":" +
      originDestinationKeys +
      ":!" +
      totalFareCurrencyCode +
      totalFareAmount;

    //OD分组
    let prePriceClassIdMap = {};
    if (offer && offer.summary && offer.summary.length > 0) {
      const summary = offer.summary;
      key += "!!";

      let priceClassIdGroup = [];
      for (const summaryEle of summary) {
        const priceClassId = summaryEle.bundleID;
        const originDestinationKey = summaryEle.origDestRPH;
        if (!priceClassId) continue;

        if (prePriceClassIdMap[originDestinationKey]) {
          let combinePriceClassId =
            priceClassIdGroup[priceClassIdGroup.length - 1] +
            "@@" +
            priceClassId;
          priceClassIdGroup.splice(priceClassIdGroup.length - 1);
          priceClassIdGroup.push(combinePriceClassId);
        } else {
          priceClassIdGroup.push(priceClassId);
          prePriceClassIdMap[originDestinationKey] = 1;
        }
      }

      key += priceClassIdGroup.join(":");
    } else {
      key += "!!";
    }
    itineraryFareOptionKeyMap[key] = airItineraryRPH;
  }

  return itineraryFareOptionKeyMap;
}

function buildFlightFareOptionsMap(upsellRS) {
  if (
    !upsellRS ||
    !upsellRS.pricedItineraryList ||
    upsellRS.pricedItineraryList.length === 0
  )
    return null;
  const pricedItineraryList = upsellRS.pricedItineraryList;

  const flightFareOptionsMap = {};
  for (const pricedItinerary of pricedItineraryList) {
    const airItinerary = pricedItinerary.airItinerary;
    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const airItineraryKey = airItinerary.airItineraryKey;
    const originDestinationOptions = airItinerary.originDestinationOptions;
    const offer = pricedItinerary.offer;

    for (const originDestinationOption of originDestinationOptions) {
      const originDestinationKey = originDestinationOption.originDestinationKey;
      const originDestinationRPH = originDestinationOption.rph;
      const flightSegments = originDestinationOption.flightSegments;
      const flightSegmentRPHs = [];
      flightSegments.forEach((segment) => {
        //为了兼容offer中航段航班的rph和segment key
        flightSegmentRPHs.push(segment.segmentKey);
        flightSegmentRPHs.push(segment.rph);
      });

      //使用创建者设计模式，构建Fare Option创建者
      //使用相同的创建代码生成不同类型和形式的对象。
      const flightFareOptionBuilder = new FlightFareOptionBuilder();
      const flightFareOption = flightFareOptionBuilder
        .buildBaseFareOption(pricedItinerary, originDestinationKey)
        .addPriceInfo(airItineraryPricingInfo)
        .addFareBasisCodes(airItineraryPricingInfo, flightSegmentRPHs)
        .addBrandFareInfos(
          offer,
          originDestinationKey,
          originDestinationRPH,
          flightSegmentRPHs
        )
        .addPenaltyInfos(airItineraryPricingInfo)
        .build();

      //当前OD下，航班组合存在的所有Fare Options
      const originDestFareOptions = flightFareOptionsMap[originDestinationKey];

      if (originDestFareOptions && originDestFareOptions.length > 0) {
        //去重，根据priceClassId，以及prePriceClassIdsKey决定，并且选择同样Brand Fare的最低价格，作为显示价格
        const sameDestFareOption = originDestFareOptions.find(
          (option) => option.priceClassId === flightFareOption.priceClassId
        );
        //确保上一步选择的价格依旧还在
        if (
          sameDestFareOption &&
          ((sameDestFareOption.totalPrice > flightFareOption.totalPrice &&
            flightFareOption.origFareIndicator) ||
            flightFareOption.origFareIndicator)
        ) {
          sameDestFareOption.totalPrice = flightFareOption.totalPrice;
        } else if (!sameDestFareOption) {
          originDestFareOptions.push(flightFareOption);
        }
      } else {
        flightFareOptionsMap[originDestinationKey] = [];
        flightFareOptionsMap[originDestinationKey].push(flightFareOption);
      }
    }
  }
  return flightFareOptionsMap;
}

function buildPriceClasses(offer) {
  if (!offer || !offer.summary || offer.summary.length === 0) return null;

  const priceClasses = [];
  const summary = offer.summary;

  for (const summaryItem of summary) {
    const name = summaryItem.name;
    const bundleID = summaryItem.bundleID;

    const subSections =
      summaryItem.shortDescription && summaryItem.shortDescription.subSections
        ? summaryItem.shortDescription.subSections
        : null;

    const flightPriceClassVO = new FlightPriceClassVO(bundleID, name, null);

    if (subSections) {
      for (const subSection of subSections) {
        const subCode = subSection.subCode;
        const text = subSection.paragraphs[0].text;
        flightPriceClassVO.addNewPriceDescription(subCode, text);
      }
    }

    priceClasses.push(flightPriceClassVO);
  }

  return priceClasses;
}

function findAllAirportCodes(tripAirItems) {
  if (!tripAirItems || tripAirItems.length == null) return [];

  const airportCodes = [];
  for (const tripAirItem of tripAirItems) {
    const flightItems = tripAirItem.flightItems;
    if (flightItems && flightItems.length > 0) {
      flightItems.forEach((flightItem) => {
        const arrivalAirportCode = flightItem.arrivalAirportCode;
        const departureAirportCode = flightItem.departureAirportCode;
        if (airportCodes.indexOf(arrivalAirportCode) < 0)
          airportCodes.push(arrivalAirportCode);
        if (airportCodes.indexOf(departureAirportCode) < 0)
          airportCodes.push(departureAirportCode);
        const flightSegments = flightItem.flightSegments;
        if (flightSegments && flightSegments.length > 0) {
          for (const flightSegment of flightSegments) {
            const segArrivalAirportCode = flightSegment.arrivalAirportCode;
            const segDepartureAirportCode = flightSegment.departureAirportCode;
            if (airportCodes.indexOf(segArrivalAirportCode) < 0)
              airportCodes.push(segArrivalAirportCode);
            if (airportCodes.indexOf(segDepartureAirportCode) < 0)
              airportCodes.push(segDepartureAirportCode);
          }
        }
      });
    }
  }
  return airportCodes;
}

function buildFlightItems(upsellRS) {
  if (
    !upsellRS ||
    !upsellRS.pricedItineraryList ||
    upsellRS.pricedItineraryList.length === 0
  )
    return null;

  const pricedItineraryList = upsellRS.pricedItineraryList;
  const airItineraryMap = upsellRS.airItineraryMap;
  const flightSegmentMap = upsellRS.flightSegmentMap;

  const flightItemMap = {};
  for (const pricedItinerary of pricedItineraryList) {
    const airItineraryKey = pricedItinerary.rph;
    const airItinerary = pricedItinerary.airItinerary;
    const originDestinationOptions = airItinerary.originDestinationOptions;
    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const score = pricedItinerary.score;
    const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
    const totalFare = itinTotalFare.totalFare;
    const totalFareCurrencyCode = totalFare.currencyCode;
    const totalFareAmount = totalFare.amount;

    const bookingSourceType = pricedItinerary.bookingSourceType;
    const reasons = pricedItinerary.reasons;
    const bookingSource = pricedItinerary.bookingSource;
    const supplierCode = bookingSource
      ? bookingSource
      : airItineraryPricingInfo.pricingSource;

    for (let i = 0; i < originDestinationOptions.length; i++) {
      const originDestinationOption = originDestinationOptions[i];
      const flightSegmentKeyList = originDestinationOption.flightSegmentKeyList;
      const flightSegments = originDestinationOption.flightSegments;
      const rph = originDestinationOption.rph;
      const originDestinationKey = originDestinationOption.originDestinationKey;
      //同一个OD会有多种价格
      if (flightItemMap[originDestinationKey]) {
        const flightItem = flightItemMap[originDestinationKey];
        // if (flightItem.bestTotalPrice > totalFareAmount)
        //   flightItem.bestTotalPrice = totalFareAmount;

        if (flightItem.bookingSourceTypes.indexOf(bookingSourceType) < 0)
          flightItem.bookingSourceTypes.push(bookingSourceType);

        if (flightItem.supplierCodes.indexOf(supplierCode) < 0)
          flightItem.supplierCodes.push(supplierCode);

        continue;
      }
      const tripSequenceNumber = i;
      const originLocationCode = originDestinationOption.originLocation;
      const destinationLocationCode =
        originDestinationOption.destinationLocation;
      const connections = originDestinationOption.connections;
      let numStops = originDestinationOption.numStops;

      let durationInMins = originDestinationOption.durationInMins;
      let originLocationName = null;
      let departureAirportCodeFlight = null;
      let destinationLocationName = null;
      let arrivalAirportCodeFlight = null;
      let departureDateTimeFlight = null;
      let arrivalDateTimeFlight = null;

      const flightSegmentVOs = [];
      flightSegments.sort((a, b) => {
        return (
          new Date(a.departureDateTime).getTime() -
          new Date(b.departureDateTime).getTime()
        );
      });
      for (const flightSegment of flightSegments) {
        const departureDateTime = flightSegment.departureDateTime;
        const arrivalDateTime = flightSegment.arrivalDateTime;
        const segmentKey = flightSegment.segmentKey;
        const flightNumber = flightSegment.flightNumber;
        const fareBasisCode = flightSegment.fareBasisCode;
        const fareTypeName = flightSegment.fareTypeName;
        const resBookDesigCode = flightSegment.resBookDesigCode;
        const numberInParty = flightSegment.numberInParty;
        const stopoverInd = flightSegment.stopoverInd;
        const departureAirportCode = flightSegment.departureAirportCode;
        const arrivalAirportCode = flightSegment.arrivalAirportCode;
        const operatingAirlineCode = flightSegment.operatingAirlineCode;
        const operatingAirlineName = flightSegment.operatingAirlineName;
        const equipmentCode = flightSegment.equipmentCode;
        const marketingAirlineCode = flightSegment.marketingAirlineCode;
        const cabinClass = flightSegment.cabinClass;
        // const baggageAllowance = flightSegment.baggageAllowance;
        const baggageAllowance = null;
        const rph = flightSegment.rph;

        const flightSegmentVO = new FlightSegmentVO(
          departureDateTime,
          arrivalDateTime,
          segmentKey,
          flightNumber,
          fareBasisCode,
          fareTypeName,
          resBookDesigCode,
          numberInParty,
          stopoverInd,
          departureAirportCode,
          arrivalAirportCode,
          operatingAirlineCode,
          operatingAirlineName,
          equipmentCode,
          marketingAirlineCode,
          cabinClass,
          baggageAllowance,
          rph
        );

        flightSegmentVOs.push(flightSegmentVO);

        if (!departureDateTimeFlight) {
          departureDateTimeFlight = departureDateTime;
          departureAirportCodeFlight = departureAirportCode;
        }
        arrivalDateTimeFlight = arrivalDateTime;
        arrivalAirportCodeFlight = arrivalAirportCode;
      }

      if (!durationInMins) {
        durationInMins = dateUtils.calcDurationInMinsWithTimeZone(
          departureDateTimeFlight,
          arrivalDateTimeFlight
        );
      }

      if (!numStops) {
        numStops = flightSegmentVOs.length - 1;
      }

      const flightItemVO = new FlightItemVO(
        null,
        tripSequenceNumber,
        originLocationCode,
        originLocationName,
        departureAirportCodeFlight,
        destinationLocationCode,
        destinationLocationName,
        arrivalAirportCodeFlight,
        departureDateTimeFlight,
        arrivalDateTimeFlight,
        rph,
        originDestinationKey,
        durationInMins,
        connections,
        numStops,
        totalFareCurrencyCode,
        totalFareAmount,
        null,
        null,
        null,
        flightSegmentVOs,
        score
      );
      flightItemVO.addBookingSourceType(bookingSourceType);
      flightItemVO.addSupplierCode(supplierCode);
      flightItemMap[originDestinationKey] = flightItemVO;
    }
  }
  return Object.values(flightItemMap);
}

function findSpecificFlightItems(flightItems, tripSequenceNumber) {
  return flightItems.filter(
    (flight) => flight.tripSequenceNumber === parseInt(tripSequenceNumber)
  );
}

/**
 * 构建 ItineraryArrangement
 * @param flightShoppingFormModel
 * @returns {{flights: []}}
 */
function buildItineraryArrangement(flightShoppingFormModel) {
  const originDestinations = flightShoppingFormModel.originDestinations;

  const itineraryArrangement = {
    flights: [],
  };
  for (const originDestination of originDestinations) {
    const refNumber = originDestination.refNumber;
    const originLocationCode = originDestination.originLocationCode;
    const destinationLocationCode = originDestination.destinationLocationCode;
    const departureDate = originDestination.departureDate;

    const flightOriginDestinationFormItem = new FlightOriginDestinationFormItem(
      refNumber,
      originLocationCode,
      null,
      destinationLocationCode,
      null,
      null,
      null,
      null,
      departureDate
    );
    itineraryArrangement.flights.push(flightOriginDestinationFormItem);
  }

  return itineraryArrangement;
}

function buildPassenger(airTraveler) {
  const travelerRefNumberRPH = airTraveler.travelerRefNumber.rph;
  const passengerTypeCode = airTraveler.passengerTypeCode;
  const birthDate = airTraveler.birthDate;
  const personName = airTraveler.personName;
  let givenName = null;
  let surname = null;
  let nameTitle = null;
  if (personName) {
    givenName = personName.givenName;
    surname = personName.surname;
    nameTitle = personName.nameTitle;
  }
  const tripAirItemPassengerVO = new TripAirItemPassengerVO(
    travelerRefNumberRPH,
    passengerTypeCode,
    givenName,
    surname,
    nameTitle,
    birthDate,
    null,
    null,
    null
  );

  const emailList = airTraveler.emailList;
  const telephoneList = airTraveler.telephoneList;
  const documents = airTraveler.documents;
  if (emailList && emailList.length > 0) {
    for (const email of emailList) {
      const emailAddress = email.emailAddress;
      const tripPassengerEmailVO = new TripPassengerEmailVO(null, emailAddress);
      tripAirItemPassengerVO.addContactEmail(tripPassengerEmailVO);
    }
  }
  if (telephoneList && telephoneList.length > 0) {
    for (const telephone of telephoneList) {
      const phoneTechType = telephone.phoneTechType;
      const countryAccessCode = telephone.countryAccessCode;
      const phoneNumber = telephone.phoneNumber;

      const tripPassengerPhoneVO = new TripPassengerPhoneVO(
        phoneTechType,
        countryAccessCode,
        phoneNumber
      );
      tripAirItemPassengerVO.addContractPhone(tripPassengerPhoneVO);
    }
  }
  if (documents && documents.length > 0) {
    for (const document of documents) {
      const docID = document.docID;
      const docType = document.docType;
      const expireDate = document.expireDate;
      const docIssueCountry = document.docIssueCountry;
      const docHolderNationality = document.docHolderNationality;
      const tripPassengerDocumentVO = new TripPassengerDocumentVO(
        docType,
        docID,
        expireDate,
        docIssueCountry,
        docHolderNationality
      );
      tripAirItemPassengerVO.addDocument(tripPassengerDocumentVO);
    }
  }

  return tripAirItemPassengerVO;
}

export default {
  buildUpsellRQ,
  buildOldTripAirItem,
  buildTripAirItems,
  buildFlightItems,
  buildItineraryFareOptionKeyMap,
  buildFlightFareOptionsMap,
  buildPriceClasses,
  findAllAirportCodes,
  findSpecificFlightItems,
  buildItineraryArrangement,
};

//region 请求对象
import { CarRentalItemVO } from "@/viewobject/sales/carrental/carRentalItemVO";
import { CarRentalCoverageItemVO } from "@/viewobject/sales/carrental/carRentalCoverageItemVO";
import { CarRentalEquipItemVO } from "@/viewobject/sales/carrental/carRentalEquipItemVO";

function buildCarRentalRateRQ(carRentalRatesFormModel) {
  let vehPrefs = null;
  if (carRentalRatesFormModel.preferredCarType) {
    vehPrefs = [
      {
        vehClass: {
          vehicleClassCode: carRentalRatesFormModel.preferredCarType,
        },
      },
    ];
  }

  return {
    vehAvailRQCore: {
      status: "rate",
      vehRentalCore: {
        pickUpDateTime: carRentalRatesFormModel.pickupDatetime,
        returnDateTime: carRentalRatesFormModel.returnDatetime,
        pickUpLocations: [
          {
            locationCode: carRentalRatesFormModel.pickupLocation,
          },
        ],
        returnLocations: [
          {
            locationCode: carRentalRatesFormModel.returnLocation,
          },
        ],
      },
      vehPrefs: vehPrefs,
    },
    vehAvailRQInfo: {
      customer: {
        primary: {
          citizenCountryName: {
            code: carRentalRatesFormModel.countryOfResidence,
          },
        },
      },
    },
  };
}

function buildCarRentalRatesQuoteRQ(carRentalRatesQuoteFormModel) {
  let specialEquipPrefs = null;
  const quoteID = carRentalRatesQuoteFormModel.quoteID;
  const protectionPlan = carRentalRatesQuoteFormModel.protectionPlan;
  const selectedInsurances = carRentalRatesQuoteFormModel.selectedInsurances;
  const selectedEquipments = carRentalRatesQuoteFormModel.selectedEquipments;
  if (selectedEquipments != null && selectedEquipments.length > 0) {
    specialEquipPrefs = [];
    selectedEquipments.forEach((equipment) => {
      specialEquipPrefs.push({
        code: equipment.code,
        quantity: equipment.quantity,
      });
    });
  }

  let coveragePrefs = null;
  if (selectedInsurances && selectedInsurances.length > 0) {
    if (!coveragePrefs) coveragePrefs = [];
    selectedInsurances.forEach((insurance) => {
      coveragePrefs.push({
        code: insurance.code,
      });
    });
  }

  if (protectionPlan) {
    if (!coveragePrefs) coveragePrefs = [];
    const code = protectionPlan.code;
    coveragePrefs.push({
      code: code,
    });
  }

  return {
    vehAvailRQCore: {
      status: "quote",
      rateQualifiers: [
        {
          rateQualifier: quoteID,
        },
      ],
      specialEquipPrefs: specialEquipPrefs,
    },
    vehAvailRQInfo: {
      coveragePrefs: coveragePrefs,
    },
  };
}

//endregion

//region 构建视图对象
function buildCarRentalItem(availVehicleRatesRS) {
  if (
    !availVehicleRatesRS ||
    !availVehicleRatesRS.vehAvailRSCore ||
    !availVehicleRatesRS.vehAvailRSCore.vehVendorAvails ||
    availVehicleRatesRS.vehAvailRSCore.vehVendorAvails.length === 0 ||
    !availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails ||
    !availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails ||
    availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails
      .length === 0
  )
    return null;

  const vehRentalCore = availVehicleRatesRS.vehAvailRSCore.vehRentalCore;
  const vehAvail =
    availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails[0];

  if (vehAvail) {
    const vehAvailInfo = vehAvail.vehAvailInfo;
    const pricedCoverages = vehAvailInfo.pricedCoverages;

    const vehAvailCore = vehAvail.vehAvailCore;
    const rentalRates = vehAvailCore.rentalRates[0];
    const minimumDriverAge = rentalRates.rateRestrictions
      ? rentalRates.rateRestrictions.minimumAge
      : null;
    let quoteID = rentalRates.quoteID;
    if (!quoteID)
      quoteID = rentalRates.rateQualifier
        ? rentalRates.rateQualifier.quoteID
        : null;

    let rateQualifier = null;
    const rateDistances = rentalRates.rateDistances;

    if (rateDistances && rateDistances.length > 0) {
      rateQualifier = rateDistances[0].unlimited;
    }
    const vehicleCharges = rentalRates.vehicleCharges;
    const totalCharges = vehAvailCore.totalCharges[0];
    const pricedEquips = vehAvailCore.pricedEquips;
    const currencyCode = totalCharges.currencyCode;
    const totalAmount = totalCharges.estimatedTotalAmount;
    let listItems = totalCharges.pricingDetails
      ? totalCharges.pricingDetails.listItems
      : null;
    let origNetPrice = null;
    if (listItems && listItems.length > 0) {
      listItems.forEach((listItem) => {
        if (listItem.stringValue === "ORIG_NET_PRICE") {
          origNetPrice = listItem.textFormat;
        }
      });
    }
    const vehicle = vehAvailCore.vehicle;

    let pictureURL = null;
    let vehName = null;
    let airConditionInd = null;
    let transmissionType = null;
    let doorCount = null;
    let passengerQuantity = null;
    let baggageQuantity = null;
    let classCode = null;
    let className = null;
    let fuelType = null;
    if (vehicle) {
      const vehType = vehicle.vehType;
      doorCount = vehType ? vehType.doorCount : null;
      const vehClass = vehicle.vehClass;
      classCode = vehClass.vehClassCode;
      className = vehClass.vehClassName;
      const vehMakeModel = vehicle.vehMakeModel;
      vehName = vehMakeModel.name;
      pictureURL = vehicle.pictureURL;
      passengerQuantity = vehicle.passengerQuantity;
      baggageQuantity = vehicle.baggageQuantity;

      airConditionInd = vehicle.airConditionInd;
      transmissionType = vehicle.transmissionType;
      fuelType = vehicle.fuelType;
    }

    let pickUpDateTime = null;
    let returnDateTime = null;
    let pickUpLocation = null;
    let returnLocation = null;
    if (vehRentalCore) {
      pickUpDateTime = vehRentalCore.pickUpDateTime;
      returnDateTime = vehRentalCore.returnDateTime;
      pickUpLocation =
        vehRentalCore.pickUpLocations &&
        vehRentalCore.pickUpLocations.length > 0
          ? vehRentalCore.pickUpLocations[0].locationCode
          : null;
      returnLocation =
        vehRentalCore.returnLocations &&
        vehRentalCore.returnLocations.length > 0
          ? vehRentalCore.returnLocations[0].locationCode
          : null;
    }

    const carRentalItemVO = new CarRentalItemVO(
      pictureURL,
      vehName,
      currencyCode,
      totalAmount,
      airConditionInd,
      transmissionType,
      fuelType,
      doorCount,
      passengerQuantity,
      baggageQuantity,
      classCode,
      className,
      minimumDriverAge,
      quoteID,
      rateQualifier,
      null,
      null,
      null,
      null,
      null,
      null,
      pickUpDateTime,
      returnDateTime,
      pickUpLocation,
      returnLocation,
      origNetPrice
    );

    //保险
    if (pricedCoverages != null && pricedCoverages.length > 0) {
      pricedCoverages.forEach((pricedCoverage) => {
        const selected = pricedCoverage.selected;
        const required = pricedCoverage.required;
        const coverageCode = pricedCoverage.coverage.code;
        const coverageDescription = pricedCoverage.coverage.description;
        const amount = pricedCoverage.charge.amount;
        const coverageCurrency = pricedCoverage.charge.currencyCode;
        const coverageIncluded = selected && amount === 0;

        carRentalItemVO.addNewInsurance(
          coverageIncluded,
          selected,
          required,
          coverageCode,
          coverageDescription,
          amount,
          coverageCurrency
        );
      });
    }

    //设备
    if (pricedEquips && pricedEquips.length > 0) {
      pricedEquips.forEach((pricedEquip) => {
        const required = pricedEquip.required;
        const selected = pricedEquip.selected;
        const equipment = pricedEquip.equipment;
        const equipmentCode = equipment.code;
        const description = equipment.description;
        const charge = pricedEquip.charge;
        const equipAmount = charge.amount;
        const chargeCurrency = charge.currencyCode;
        //RENTAL 指计算价格单位为每次租赁， DAY指计算价格单位为每天
        const unitName = charge.calculations[0].unitName;

        carRentalItemVO.addNewEquipment(
          required,
          selected,
          equipment,
          equipmentCode,
          unitName,
          equipAmount,
          chargeCurrency,
          0
        );
      });
    }

    //费用
    if (vehicleCharges && vehicleCharges.length > 0) {
      vehicleCharges.forEach((vehicleCharge) => {
        const description = vehicleCharge.description;
        const amount = vehicleCharge.amount;
        const currencyCode = vehicleCharge.currencyCode;
        const taxInclusive = vehicleCharge.taxInclusive;
        const taxAmounts = vehicleCharge.taxAmounts;
        const purpose = vehicleCharge.purpose; // 1 for vehicle rental, 4 for coverage, 6 for fee, 7 for tax

        carRentalItemVO.addNewCharge(
          description,
          amount,
          currencyCode,
          taxInclusive,
          taxAmounts
        );
      });
    }

    return carRentalItemVO;
  }
}

/**
 *
 * @param availVehicleRatesRS 构建设备可选项
 */
function buildEquipmentOptions(availVehicleRatesRS) {
  if (
    !availVehicleRatesRS ||
    !availVehicleRatesRS.vehAvailRSCore ||
    !availVehicleRatesRS.vehAvailRSCore.vehVendorAvails ||
    availVehicleRatesRS.vehAvailRSCore.vehVendorAvails.length === 0 ||
    !availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails ||
    !availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails ||
    availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails
      .length === 0
  )
    return null;

  const vehAvail =
    availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails[0];

  const equipments = [];
  if (vehAvail) {
    const vehAvailCore = vehAvail.vehAvailCore;
    const pricedEquips = vehAvailCore.pricedEquips;

    //设备
    if (pricedEquips && pricedEquips.length > 0) {
      pricedEquips.forEach((pricedEquip) => {
        const required = pricedEquip.required;
        const selected = pricedEquip.selected;
        const equipment = pricedEquip.equipment;
        const equipmentCode = equipment.code;
        const description = equipment.description;
        const charge = pricedEquip.charge;
        const equipAmount = charge.amount;
        const chargeCurrency = charge.currencyCode;
        //RENTAL 指计算价格单位为每次租赁， DAY指计算价格单位为每天
        const unitName = charge.calculations[0].unitName;

        const carRentalEquipItemVO = new CarRentalEquipItemVO(
          required,
          selected,
          description,
          equipmentCode,
          unitName,
          equipAmount,
          chargeCurrency,
          0
        );
        equipments.push(carRentalEquipItemVO);
      });
    }
  }

  return equipments;
}

/**
 *
 * @param availVehicleRatesRS 构建保险可选项
 */
function buildCoverageOptions(availVehicleRatesRS) {
  if (
    !availVehicleRatesRS ||
    !availVehicleRatesRS.vehAvailRSCore ||
    !availVehicleRatesRS.vehAvailRSCore.vehVendorAvails ||
    availVehicleRatesRS.vehAvailRSCore.vehVendorAvails.length === 0 ||
    !availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails ||
    !availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails ||
    availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails
      .length === 0
  )
    return null;

  const vehAvail =
    availVehicleRatesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails[0];

  const insurances = [];
  if (vehAvail) {
    const vehAvailInfo = vehAvail.vehAvailInfo;
    const pricedCoverages = vehAvailInfo.pricedCoverages;

    //保险
    if (pricedCoverages != null && pricedCoverages.length > 0) {
      pricedCoverages.forEach((pricedCoverage) => {
        const selected = pricedCoverage.selected;
        const required = pricedCoverage.required;
        const coverageCode = pricedCoverage.coverage.code;
        const coverageDescription = pricedCoverage.coverage.description;
        const amount = pricedCoverage.charge.amount;
        const coverageCurrency = pricedCoverage.charge.currencyCode;
        const coverageIncluded = selected && amount === 0;

        const carRentalCoverageItemVO = new CarRentalCoverageItemVO(
          coverageIncluded,
          selected,
          required,
          coverageCode,
          coverageDescription,
          amount,
          coverageCurrency
        );
        insurances.push(carRentalCoverageItemVO);
      });
    }
  }

  return insurances;
}

//endregion

export default {
  buildCarRentalRateRQ,
  buildCarRentalRatesQuoteRQ,
  buildCarRentalItem,
  buildEquipmentOptions,
  buildCoverageOptions,
};

<script setup>
import { ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { useTemplateStore } from "@/stores/template";
import DynamicPkgAvailRoomPassengerFormItem from "@/views/v2/sales/dynamicpkg/items/form/avail/DynamicPkgAvailRoomPassengerFormItem.vue";

const props = defineProps({
  dynamicPkgAvailFormModel: {},
  childAgesValidCheck: {},
});
const { t, locale } = useI18n(); // 解构调用函数

const templateStore = useTemplateStore();
const dynamicPkgAvailFormModelRef = ref(null);
const roomPassengerShowRef = ref(null);
const isRoomPassengersFormCorrectRef = ref(true);
const activeCabinTypeIndexRef = ref(0);
const user = JSON.parse(sessionStorage.getItem("user"));

watch(
  () => props.dynamicPkgAvailFormModel,
  (newValue) => {
    dynamicPkgAvailFormModelRef.value = newValue;

    if (
      newValue &&
      newValue.roomPassengers &&
      newValue.roomPassengers.length > 0
    ) {
      const roomCount = newValue.roomPassengers.length;
      let travelersNumber = 0;
      newValue.roomPassengers.forEach((roomPassenger) => {
        travelersNumber +=
          parseInt(roomPassenger.adultNum) + parseInt(roomPassenger.childNum);
      });

      // let cabinName = null;
      // const cabinItem = cabinTypeOptions.cabinTypes.find(
      //   (item) => item.code === newValue.cabinTypeCode
      // );
      // if (cabinItem) cabinName = t(cabinItem.name);
      //
      // roomPassengerShowRef.value = t("passengers-room-summary", {
      //   passengersNum: travelersNumber,
      //   roomCount: roomCount,
      //   cabinName: cabinName,
      // });

      roomPassengerShowRef.value = t("travel-room-num", {
        travelersNumber: travelersNumber,
        roomCount: roomCount,
      });
    }
  },
  { immediate: true, deep: true }
);

function chooseCabinClass(cabinOption, index) {
  dynamicPkgAvailFormModelRef.value.cabinTypeCode = cabinOption.code;
  activeCabinTypeIndexRef.value = index;
}

//当用户未选择儿童岁数时，要主动校验，让输入框显示错误
watchEffect(() => {
  let formElement = document.getElementById("dynamicPkgRoomPassengerForm");
  if (props.childAgesValidCheck && formElement) {
    formElement.classList.add("show");
  } else if (!props.childAgesValidCheck && formElement) {
    formElement.classList.remove("show");
  }
});

//添加房间
function addRoomPassenger(dynamicPkgAvailFormModel) {
  dynamicPkgAvailFormModel.addNewRoomPassenger();
}

//删除房间
function removeRoomPassengers(roomIndex) {
  dynamicPkgAvailFormModelRef.value.removeRoomPassenger(roomIndex);
}

//提交房间信息表单
const v = useVuelidate();

async function submitRoomPassengersForm() {
  //在父组件中调用，则会校验所有子组件的规则，并统一返回校验结果
  isRoomPassengersFormCorrectRef.value = await v.value.$validate();
  if (!isRoomPassengersFormCorrectRef.value) return;
  let formElement = document.getElementById("dynamicPkgRoomPassengerForm");
  formElement.classList.remove("show");
}
</script>

<template>
  <div class="search_boxed dropdown_passenger_area">
    <p>{{ $t("guests") }}, {{ $t("room") }}</p>
    <div class="dropdown">
      <button
        class="dropdown-toggle final-count"
        data-toggle="dropdown"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
      >
        {{ roomPassengerShowRef }}
      </button>

      <div
        class="dropdown-menu dropdown_passenger_info fs-sm dropdown-menu-400"
        aria-labelledby="dropdownMenuButton1"
        id="dynamicPkgRoomPassengerForm"
      >
        <div class="traveller-calulate-persons">
          <div class="passengers">
            <form class="p-2" @submit.prevent>
              <div
                v-for="(
                  roomPassenger, roomPassengerIndex
                ) in dynamicPkgAvailFormModelRef.roomPassengers"
                :key="roomPassengerIndex"
              >
                <DynamicPkgAvailRoomPassengerFormItem
                  :room-index="roomPassengerIndex"
                  :room-passenger-form-item-model="roomPassenger"
                  @removeRoomPassengers="removeRoomPassengers"
                />
              </div>
              <a
                class="d-flex justify-content-sm-end mt-sm-4 cursor-point"
                @click="addRoomPassenger(dynamicPkgAvailFormModelRef)"
                >{{ $t("add-another-room") }}</a
              >
              <div class="text-center">
                <button
                  type="submit"
                  class="btn col-11"
                  @click="submitRoomPassengersForm"
                >
                  {{ $t("done") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <span> {{ roomPassengerShowRef }}</span>
  </div>
</template>

<style scoped lang="scss"></style>

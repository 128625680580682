<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { FlightPrefsFormItem } from "@/formmodel/sales/flight/shopping/flightShoppingFormModel";
import cabinClass from "@/data/flight/cabinClass";
import { useI18n } from "vue-i18n";

const props = defineProps({
  passengers: {},
  originDestinations: {},
});
const { t, locale } = useI18n(); // 解构调用函数

const currentLanguage = ref("");
const passengersRef = ref(null);
const originDestinationsRef = ref(null);

//region 监听事件
watch(
  () => props.passengers,
  (newValue) => {
    passengersRef.value = newValue;
    if (passengersRef.value) {
      passengersRef.value.forEach((item) => {
        if (!item.quantity) {
          item.quantity = 0;
        }
      });
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.originDestinations,
  (newValue) => {
    originDestinationsRef.value = newValue;

    if (newValue && newValue.length > 0) {
      if (
        !newValue[0].cabinTypePrefs ||
        (newValue[0].cabinTypePrefs && newValue[0].cabinTypePrefs.length === 0)
      ) {
        if (!newValue[0].cabinTypePrefs) newValue[0].cabinTypePrefs = [];

        newValue[0].cabinTypePrefs.push(
          new FlightPrefsFormItem("Y", "INCLUDE")
        );
      }
    }
  },
  { immediate: true, deep: true }
);

const cabinTypePrefsOptions = ref([]);
watch(
  () => locale.value,
  (newValue) => {
    currentLanguage.value = "cabin_" + (newValue === "en" ? "en" : "zh");
    cabinTypePrefsOptions.value = cabinClass[currentLanguage.value];
  },
  { immediate: true }
);
//endregion

const passengersNumComputed = computed(() => {
  let num = 0;
  if (passengersRef.value && passengersRef.value.length > 0) {
    passengersRef.value.forEach((item) => {
      if (item.quantity) {
        num += parseInt(item.quantity);
      }
    });
  }
  return num;
});

function getCabin(code, originDestination) {
  originDestination.cabinTypePrefs = [];
  originDestination.cabinTypePrefs.push(
    new FlightPrefsFormItem(code, "INCLUDE")
  );
}

function isCabinClassActive(code, originDestination) {
  if (
    originDestination.cabinTypePrefs &&
    originDestination.cabinTypePrefs.length > 0 &&
    code === originDestination.cabinTypePrefs[0].code
  )
    return true;
}

// Validation rules
const v = useVuelidate();

const isFormCorrect = ref(true);

//主动校验，让输入框显示错误
onMounted(() => {
  document
    .getElementById("flightPassengerForm")
    .addEventListener("hidden.bs.dropdown", async () => {
      isFormCorrect.value = await v.value.$validate();
    });
});

async function submitFlightPassengersForm() {
  // //在父组件中调用，则会校验所有子组件的规则，并统一返回校验结果
  // isFormCorrect.value = await v.value.$validate();
  // if (!isFormCorrect.value) return;
  document.getElementById("flightPassengerForm").classList.remove("show");
}

function getCabinClass(originDestination, cabinTypePrefsOptions) {
  const code = originDestination.cabinTypePrefs[0].code;
  if (cabinTypePrefsOptions && cabinTypePrefsOptions.length > 0) {
    return cabinTypePrefsOptions.find((item) => item.code === code).name;
  }
}

function decreaseNum(passenger) {
  if (passenger) {
    if (passenger.passengerTypeCode === "ADT") {
      if (passenger.quantity > 1) {
        passenger.quantity--;
      }
    } else {
      if (passenger.quantity > 0) {
        passenger.quantity--;
      }
    }
  }
}
</script>
<template>
  <div class="search_boxed dropdown_passenger_area">
    <p>{{ $t("passenger-class") }}</p>
    <div class="dropdown">
      <button
        id="dropdownMenuButton2"
        aria-expanded="false"
        class="dropdown-toggle final-count"
        data-bs-auto-close="outside"
        data-bs-toggle="dropdown"
        data-toggle="dropdown"
        type="button"
      >
        {{ $t("num-passenger", { passengersNum: passengersNumComputed }) }}
      </button>

      <div
        id="flightPassengerForm"
        aria-labelledby="dropdownMenuButton2"
        class="dropdown-menu"
      >
        <div class="traveller-calulate-persons">
          <div class="passengers">
            <h6>{{ $t("passengers") }}</h6>
            <div class="passengers-types">
              <div
                class="passengers-type"
                v-for="(passenger, passengerIndex) in passengersRef"
                :key="passengerIndex"
              >
                <div class="text">
                  <span class="count">{{ passenger.quantity }}</span>
                  <div class="type-label">
                    <p>{{ passenger.passengerTypeCode }}</p>
                    <span>{{
                      passenger.passengerTypeCode === "ADT"
                        ? "12+ yrs"
                        : passenger.passengerTypeCode === "CNN"
                        ? $t("child-less-than")
                        : $t("infant-less-than")
                    }}</span>
                  </div>
                </div>
                <div class="button-set">
                  <button
                    class="btn-add"
                    type="button"
                    @click="passenger.quantity++"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                  <button
                    class="btn-subtract"
                    type="button"
                    @click="decreaseNum(passenger)"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="cabin-selection">
            <h6>{{ $t("cabin-class") }}</h6>
            <div
              v-for="(cabin, index) in cabinTypePrefsOptions"
              class="cabin-list"
            >
              <button
                :class="{
                  active: isCabinClassActive(
                    cabin.code,
                    originDestinationsRef[0]
                  ),
                }"
                class="label-select-btn"
                name="cabinClass"
                @click="getCabin(cabin.code, originDestinationsRef[0])"
              >
                <span class="muiButton-label">{{ cabin.name }}</span>
              </button>
            </div>
          </div>
          <div class="text-center">
            <button
              class="btn col-11"
              type="submit"
              @click="submitFlightPassengersForm"
            >
              {{ $t("done") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <span
      >{{ getCabinClass(originDestinationsRef[0], cabinTypePrefsOptions) }}
    </span>
  </div>
</template>

<!--该组件check out页面、reservation页面共用，操作的Form表单对象有price，modify-->
<script setup>
import { ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useTemplateStore } from "@/stores/template";
import { Modal } from "ant-design-vue";
import { useAirContentStore } from "@/stores/v2/content/airContentStore";

const { t, locale } = useI18n(); // 解构调用函数
const props = defineProps({
  tripAirItem: {},
  formModel: {},
  passengerItems: {},
  isReservationPage: {},
  airlineCapability: {},
  showTitle: {},
  filterSeat: {},
  filterAncillary: {},
  idContext: {},
});

const AModal = Modal;
const templateStore = useTemplateStore();
const airContentStore = useAirContentStore();

const segmentsRef = ref([]);
const ssrRemarksRef = ref(null);
const passengersRef = ref([]);
const formModelRef = ref(null);
const segmentPassengerServiceMapRef = ref({});
const errorMessageRef = ref();
const serviceTotalAmountRef = ref({
  amount: 0,
  currencyCode: null,
});

watch(
  () => props.tripAirItem,
  (newValue) => {
    segmentsRef.value = [];
    if (newValue && newValue.flightItems && newValue.flightItems.length > 0) {
      newValue.flightItems.forEach((item, index) => {
        segmentsRef.value.push(...item.flightSegments);
      });
    }

    if (newValue) {
      //1.获取所有airportCodes
      const flightItems = newValue.flightItems;
      ssrRemarksRef.value = newValue.ssrRemarks;

      const segments = [];
      if (flightItems && flightItems.length > 0) {
        flightItems.forEach((flight) => {
          segments.push(...flight.flightSegments);
        });
      }

      const airportCodeMap = {};
      if (segments && segments.length > 0) {
        segments.forEach((segment) => {
          if (!airportCodeMap[segment.departureAirportCode]) {
            const departureAirportCode = segment.departureAirportCode;
            airportCodeMap[departureAirportCode] = null;
          }
          if (!airportCodeMap[segment.arrivalAirportCode]) {
            const arrivalAirportCode = segment.arrivalAirportCode;
            airportCodeMap[arrivalAirportCode] = null;
          }
        });
      }
      airContentStore.findAirportCityMap(Object.keys(airportCodeMap));
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => props.passengerItems,
  (newValue) => {
    passengersRef.value = newValue;
  },
  { deep: true, immediate: true }
);

watchEffect(() => {
  if (props.formModel) {
    formModelRef.value = props.formModel;
    serviceTotalAmountRef.value = {};
    segmentPassengerServiceMapRef.value = {};
    //计算附加服务总价格
    if (
      props.formModel.servicesSelected &&
      props.formModel.servicesSelected.length > 0 &&
      formModelRef.value
    ) {
      serviceTotalAmountRef.value =
        formModelRef.value.calculateServicesTotalPrice();

      let segmentServiceMap = {};
      segmentServiceMap = props.formModel.servicesSelected.reduce(
        (acc, currentObj) => {
          const key = currentObj.flightSegmentRPH;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(currentObj);
          return acc;
        },
        {}
      );
      if (segmentServiceMap) {
        for (const segKey in segmentServiceMap) {
          const segmentServiceItem = segmentServiceMap[segKey];

          let passengerServiceMap = {};
          passengerServiceMap = segmentServiceItem.reduce((acc, currentObj) => {
            const key = currentObj.travelerRPH;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(currentObj);
            return acc;
          }, {});
          segmentPassengerServiceMapRef.value[segKey] = passengerServiceMap;
        }
      }
    }
  }
});

function getSegmentInfo(segments, segKey) {
  if (segments && segments.length > 0) {
    return segments.find((segment) => segment.rph === segKey);
  }
}

function getPassengerInfo(passengers, pax) {
  if (passengers && passengers.length > 0) {
    return passengers.find((passenger) => passenger.travelerRefNumber === pax);
  } else {
    return pax;
  }
}
</script>

<template>
  <div class="tour_booking_form_box">
    <h3 class="heading_theme">{{ $t("services-summary") }}</h3>
    <div class="row text-muted">
      <div class="col-8">{{ $t("description-uppercase") }}</div>
      <div class="col-4 text-end">
        {{ $t("price-uppercase") }} ({{ serviceTotalAmountRef.currencyCode }})
      </div>
    </div>
    <hr />
    <div
      v-for="(passengerServiceMap, segKey) in segmentPassengerServiceMapRef"
      :key="segKey"
    >
      <div class="font_weight" v-if="getSegmentInfo(segmentsRef, segKey)">
        <span v-if="getSegmentInfo(segmentsRef, segKey).departureDateTime">
          {{
            getSegmentInfo(segmentsRef, segKey).departureDateTime.split("T")[0]
          }}
        </span>

        <span class="mg-l-10"
          >{{ getSegmentInfo(segmentsRef, segKey).departureAirportCode }}
          <i class="bi bi-arrow-right main_color"></i>
          {{ getSegmentInfo(segmentsRef, segKey).arrivalAirportCode }}</span
        >

        <span class="mg-l-10">
          {{ getSegmentInfo(segmentsRef, segKey).marketingAirlineCode
          }}{{ getSegmentInfo(segmentsRef, segKey).flightNumber }}
        </span>
      </div>
      <div class="p-2">
        <div
          v-for="(passengerService, travelerRPH) in passengerServiceMap"
          :key="travelerRPH"
        >
          <div class="font_weight">
            {{ $t("passenger") }} -

            <span
              v-if="getPassengerInfo(passengersRef, travelerRPH)"
              class="text-muted"
            >
              {{
                getPassengerInfo(passengersRef, travelerRPH).surname +
                "/" +
                getPassengerInfo(passengersRef, travelerRPH).givenName
              }}
            </span>
            <span v-else class="text-muted">{{ travelerRPH }}</span>
          </div>
          <div
            v-for="(service, serviceIndex) in passengerService"
            :key="serviceIndex"
            class="row mg-t-10"
          >
            <div class="col-8">
              {{ service.name }}
              <span
                v-if="
                  service.type === 'SEAT' &&
                  service.seatRowNumber &&
                  service.seatColumnNumber
                "
                class="text-bg-secondary mg-l-10 p-1"
                >{{ service.seatRowNumber }}
                {{ service.seatColumnNumber }}</span
              >
              <span class="mg-l-10 text-muted" v-if="service.quantity">
                x {{ service.quantity }}</span
              >
              <!--              <span-->
              <!--                v-if="-->
              <!--                  service.status === 'PRICED' || service.status === 'OPTIONS'-->
              <!--                "-->
              <!--                class="text-danger mg-l-8"-->
              <!--                >{{ $t("unpaid") }}</span-->
              <!--              >-->
            </div>
            <div class="col-4 text-end">
              {{ service.currencyCode }}
              {{
                service.quantity
                  ? (service.amount * service.quantity).toFixed(2)
                  : service.amount
                  ? service.amount.toFixed(2)
                  : "0.00"
              }}
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div v-if="serviceTotalAmountRef" class="row font_weight">
      <div class="col-lg-8 col-8">
        <figure>
          <div>
            {{ $t("total-uppercase") }}
            ({{ serviceTotalAmountRef.currencyCode }})
          </div>
        </figure>
      </div>
      <div class="col-lg-4 col-4">
        <figure class="text-end text-danger">
          <div>
            {{ serviceTotalAmountRef.currencyCode }}
            {{
              serviceTotalAmountRef.amount
                ? serviceTotalAmountRef.amount.toFixed(2)
                : "0.00"
            }}
          </div>
        </figure>
      </div>
    </div>
    <div v-if="ssrRemarksRef">
      <i class="fa-solid fa-warning text-warning mg-r-8"></i>
      <span class="text-warning">{{ ssrRemarksRef }}</span>
    </div>
  </div>
</template>

<style scoped></style>

import tripAirItemTools from "@/tools/v2/trip/tripAirItemTools";
import tripHotelItemTools from "@/tools/v2/trip/tripHotelItemTools";
import { TripPackageItemVO } from "@/viewobject/trip/dynamicPkg/tripPackageItemVO";
import { FlightPassengerFormItem } from "@/formmodel/sales/flight/book/flightBookFormModel";

function buildDynamicVerifyPriceRQ(dynamicPkgVerifyPriceFormModel) {
  const airItineraryRPH = dynamicPkgVerifyPriceFormModel.airItineraryRPH;
  const checkInDate = dynamicPkgVerifyPriceFormModel.checkInDate;
  const checkOutDate = dynamicPkgVerifyPriceFormModel.checkOutDate;
  const roomResGuests = dynamicPkgVerifyPriceFormModel.roomResGuests;
  const travelerRefNumbers = dynamicPkgVerifyPriceFormModel.travelerRefNumbers;

  const roomStays = [];

  const resGuestsOta = [];

  let roomIndex = 0;

  let adultRefNumbers = null;
  let childRefNumbers = null;
  if (travelerRefNumbers && travelerRefNumbers.length > 0) {
    adultRefNumbers = travelerRefNumbers.filter(
      (item) => item.passengerTypeCode === "ADT"
    );
    childRefNumbers = travelerRefNumbers.filter(
      (item) => item.passengerTypeCode === "CNN"
    );
  }
  roomResGuests.forEach((roomResGuest) => {
    let guestCounts = [];
    roomResGuest.resGuests.forEach((resGuest) => {
      let ageQualifyingCode = resGuest.ageQualifyingCode;
      let age = parseInt(resGuest.age);

      let resGuestRPH = null;
      if (ageQualifyingCode === "10") {
        if (adultRefNumbers && adultRefNumbers.length > 0) {
          resGuestRPH = adultRefNumbers[0].travelerRefNumberRPH;
          adultRefNumbers.splice(0, 1);
        }
      } else {
        if (childRefNumbers && childRefNumbers.length > 0) {
          resGuestRPH = childRefNumbers[0].travelerRefNumberRPH;
          childRefNumbers.splice(0, 1);
        }
      }

      let guestCount = {
        resGuestRPH: resGuestRPH,
        ageQualifyingCode: ageQualifyingCode,
        age: age,
        count: 1,
      };
      resGuestsOta.push(guestCount);
      guestCounts.push(guestCount);
    });

    let roomStay = {
      roomTypes: [
        {
          quantity: 1,
        },
      ],
      guestCounts: {
        guestCountList: guestCounts,
      },
      reference: {
        type: "UUID",
        id: roomResGuest.referenceId,
      },
      roomStayIndex: roomIndex,
    };
    roomIndex++;
    roomStays.push(roomStay);
  });

  let resGuestRPH = 0;
  resGuestsOta.forEach((resGuest) => {
    if (
      resGuest.surname == null &&
      !resGuest.mainIndicator &&
      !resGuest.primaryIndicator
    ) {
      resGuest.surname = "TBA-" + resGuestRPH;
      resGuest.givenName = "TBA-" + resGuestRPH;
    }
    if (resGuest.nameTitle == null) {
      resGuest.nameTitle = "Mr.";
    }
    resGuestRPH++;
  });

  return {
    dynamicPackage: {
      dynamicPkgAction: "VERIFY_PRICE",
      components: {
        airComponents: [
          {
            airItinerary: {
              airItineraryRPH: airItineraryRPH,
            },
            quoteID: airItineraryRPH,
          },
        ],
        hotelComponents: [
          {
            resStatus: "Hold",
            resGlobalInfo: {
              timeSpan: {
                start: checkInDate,
                end: checkOutDate,
              },
            },
            roomStayList: roomStays,
          },
        ],
      },
      resGuests: resGuestsOta,
    },
  };
}

function buildFlightPassengerFormItems(pricedItinerary) {
  const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
  const ptcFareBreakdowns = airItineraryPricingInfo.PTC_FareBreakdowns;
  //Passengers
  const flightPassengerFormItems = [];
  for (const ptcFareBreakdown of ptcFareBreakdowns) {
    const travelerRefNumberList = ptcFareBreakdown.travelerRefNumberList;
    const passengerTypeCode = ptcFareBreakdown.passengerTypeQuantity.code;

    for (const travelerRefNumber of travelerRefNumberList) {
      const flightPassengerFormItem = new FlightPassengerFormItem(
        passengerTypeCode,
        travelerRefNumber.rph,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      flightPassengerFormItems.push(flightPassengerFormItem);
    }
  }

  return flightPassengerFormItems;
}

function buildTripPackageItem(components) {
  const airComponents = components.airComponents;
  const hotelComponents = components.hotelComponents;

  let tripAirItem = null;
  let tripHotelItem = null;
  //air
  if (airComponents && airComponents.length > 0) {
    tripAirItem = tripAirItemTools.buildTripAirItem(airComponents[0]);
  }
  //hotel
  if (hotelComponents && hotelComponents.length > 0) {
    tripHotelItem = tripHotelItemTools.buildTripHotelItem(hotelComponents[0]);
  }

  let currencyCode = tripAirItem
    ? tripAirItem.tripAirItemFare.currencyCode
    : null;
  let totalAmount = tripAirItem ? tripAirItem.tripAirItemFare.totalAmount : 0;

  if (!currencyCode && tripHotelItem) currencyCode = tripHotelItem.currencyCode;
  totalAmount += tripHotelItem ? tripHotelItem.totalPrice : 0;
  return new TripPackageItemVO(
    totalAmount,
    currencyCode,
    tripAirItem,
    tripHotelItem
  );
}

export default {
  buildDynamicVerifyPriceRQ,
  buildFlightPassengerFormItems,
  buildTripPackageItem,
};

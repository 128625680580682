<script setup>
import FaqArea from "@/components/templates/pages/FaqArea.vue";
import { useCmsContentStore } from "@/stores/cms";
import { reactive, ref, watchEffect } from "vue";

const faqStore = useCmsContentStore();
faqStore.getAdvanceQuestions();
faqStore.getRegularQuestions();
faqStore.getGeneralQuestions();

const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContent = bannerContentStore.productPageBackgroundImage;
  imageUrl.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.faqBackgroupImage
      ? bannerContentStore.productPageBackgroundImage.faqBackgroupImage.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});
</script>
<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>Faq</h2>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <!-- Faqs Area -->
  <FaqArea
    :advance-questions="faqStore.advanceQuestions"
    :general-questions="faqStore.generalQuestions"
    :regular-questions="faqStore.regularQuestions"
  />
</template>

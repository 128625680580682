import axios from "@/utils/axiosUtils";

function bookingsRetrieve(readRQ) {
  let url = "/api/v3/sales/booking/histories";
  return axios.post(url, readRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function bookingCancel(cancelRQ) {
  let url = "/api/v3/sales/booking/0/cancellation";
  return axios.post(url, cancelRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
function printVoucher(payload) {
  let url =
    "/api/v3/sales/booking/voucher?bookingId=" +
    payload.bookingId +
    "&type=" +
    payload.type +
    "&templateId=0&itemId=" +
    payload.itemId;

  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
      },
      //解决PDF空白问题
      responseType: "arraybuffer",
    })
    .then((res) => {
      let blob = new Blob([res.data], {
        type: "application/pdf",
      });
      let objectUrl = URL.createObjectURL(blob);
      let link = document.createElement("a");
      let fname = "Voucher-" + payload.bookingId;
      link.href = objectUrl;
      link.setAttribute("download", fname);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export default {
  bookingsRetrieve,
  bookingCancel,
  printVoucher,
};

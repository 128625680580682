import { CarRentalItemVO } from "@/viewobject/sales/carrental/carRentalItemVO";
import { CarRentalEquipItemVO } from "@/viewobject/sales/carrental/carRentalEquipItemVO";
import { CarRentalCoverageItemVO } from "@/viewobject/sales/carrental/carRentalCoverageItemVO";

function buildDynamicPkgCarRentalSearchRQ(dynamicPkgAvailFormModel) {
  const ttiCode = dynamicPkgAvailFormModel.tticode;
  const locationType = dynamicPkgAvailFormModel.locationType;
  const originLocation = dynamicPkgAvailFormModel.originLocationCode;
  const destinationLocation = dynamicPkgAvailFormModel.destinationLocationCode;
  const checkInDate = dynamicPkgAvailFormModel.checkInDate;
  const checkOutDate = dynamicPkgAvailFormModel.checkOutDate;
  const roomPassengers = dynamicPkgAvailFormModel.roomPassengers;
  const referenceId = dynamicPkgAvailFormModel.referenceId;
  const cabinTypeCode = dynamicPkgAvailFormModel.cabinTypeCode;
  const airItineraryRPH = dynamicPkgAvailFormModel.airItineraryRPH;

  let hotelRef = {
    hotelCityCode: null,
    hotelCodeContext: "PLACE",
  };

  if ("AIRPORT" === locationType) {
    hotelRef.hotelCodeContext = "AIRPORT";
  }

  if ("HOTEL" === locationType) {
    hotelRef.tticode = ttiCode;
    hotelRef.hotelCodeContext = "HOTEL";
  } else {
    hotelRef.hotelCityCode = destinationLocation;
  }
  const passengerTypeQuantities = [];
  let roomStayCandidates = [];

  if (roomPassengers && roomPassengers.length > 0) {
    roomPassengers.forEach((roomPassenger) => {
      let adultNum = roomPassenger.adultNum;
      passengerTypeQuantities.push({
        code: "ADT",
        quantity: adultNum,
      });
      let childNum = roomPassenger.childNum;

      if (childNum > 0)
        passengerTypeQuantities.push({
          code: "CNN",
          quantity: childNum,
        });
      let roomStayCandidate = {
        roomTypes: [
          {
            quantity: 1,
          },
        ],
        guestCounts: {
          guestCountList: [
            {
              ageQualifyingCode: "10",
              count: adultNum,
            },
          ],
        },
        reference: {
          type: "UUID",
          id: referenceId,
        },
      };

      roomStayCandidates.push(roomStayCandidate);
      let childAges = roomPassenger.childAges;
      if (childAges != null && childAges.length > 0) {
        childAges.forEach((childAge) => {
          let age = childAge.age;
          let childGuest = {
            ageQualifyingCode: "8",
            age: age,
            count: 1,
          };
          roomStayCandidate.guestCounts.guestCountList.push(childGuest);
        });
      }
    });
  }

  return {
    searchCriteria: {
      carSearch: [
        {
          vehicleAvailRQCore: {
            status: "vehicle",
            vehRentalCore: {
              pickUpDateTime: checkInDate + "T10:00",
              returnDateTime: checkOutDate + "T10:00",
              pickUpLocations: [
                {
                  locationCode: "LCYT01",
                },
              ],
              returnLocations: [
                {
                  locationCode: "LCYT01",
                },
              ],
            },
          },
          vehicleAvailRQInfo: {
            customer: {
              primary: {
                citizenCountryName: {
                  // "code": "HK"
                },
              },
            },
          },
        },
      ],
    },
    dynamicPackage: {
      components: {
        hotelComponents: [
          {
            roomStayList: roomStayCandidates,
          },
        ],
        airComponents: [
          {
            airItinerary: {
              airItineraryRPH: airItineraryRPH,
            },
            quoteID: airItineraryRPH,
          },
        ],
      },
    },
  };
}

function buildCarRentalItems(availVehiclesRS) {
  if (
    !availVehiclesRS ||
    !availVehiclesRS.vehicleAvailRSCore ||
    !availVehiclesRS.vehicleAvailRSCore.vehVendorAvails ||
    availVehiclesRS.vehicleAvailRSCore.vehVendorAvails.length === 0 ||
    !availVehiclesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails ||
    !availVehiclesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails ||
    availVehiclesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails
      .length === 0
  )
    return null;

  const vehAvails =
    availVehiclesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails;

  const carRentalItems = [];
  vehAvails.forEach((vehAvail) => {
    const vehAvailInfo = vehAvail.vehAvailInfo;
    const pricedCoverages = vehAvailInfo.pricedCoverages;

    const vehAvailCore = vehAvail.vehAvailCore;
    const rentalRates = vehAvailCore.rentalRates[0];
    const minimumDriverAge = rentalRates.rateRestrictions.minimumAge;

    const quoteID = rentalRates.quoteID;
    let rateQualifier = null;
    const rateDistances = rentalRates.rateDistances;

    if (rateDistances && rateDistances.length > 0) {
      rateQualifier = rateDistances[0].unlimited;
    }
    const vehicleCharges = rentalRates.vehicleCharges;
    const totalCharges = vehAvailCore.totalCharges[0];
    const pricedEquips = vehAvailCore.pricedEquips;
    const currencyCode = totalCharges.currencyCode;
    const totalAmount = totalCharges.estimatedTotalAmount;
    const vehicle = vehAvailCore.vehicle;
    const vehType = vehicle.vehType;
    const vehClass = vehicle.vehClass;
    const classCode = vehClass.vehClassCode;
    const className = vehClass.vehClassName;
    const vehMakeModel = vehicle.vehMakeModel;
    const vehName = vehMakeModel.name;

    const pictureURL = vehicle.pictureURL;
    const passengerQuantity = vehicle.passengerQuantity;
    const baggageQuantity = vehicle.baggageQuantity;

    const airConditionInd = vehicle.airConditionInd;
    const transmissionType = vehicle.transmissionType;
    const fuelType = vehicle.fuelType;
    const doorCount = vehType.doorCount;

    const carRentalItemVO = new CarRentalItemVO(
      pictureURL,
      vehName,
      currencyCode,
      totalAmount,
      airConditionInd,
      transmissionType,
      fuelType,
      doorCount,
      passengerQuantity,
      baggageQuantity,
      classCode,
      className,
      minimumDriverAge,
      quoteID,
      rateQualifier,
      null,
      null,
      null,
      null
    );

    //保险
    if (pricedCoverages != null && pricedCoverages.length > 0) {
      pricedCoverages.forEach((pricedCoverage) => {
        const selected = pricedCoverage.selected;
        const required = pricedCoverage.required;
        const coverageCode = pricedCoverage.coverage.code;
        const coverageDescription = pricedCoverage.coverage.description;
        const amount = pricedCoverage.charge.amount;
        const coverageCurrency = pricedCoverage.charge.currencyCode;
        const coverageIncluded = selected && amount === 0;

        carRentalItemVO.addNewInsurance(
          coverageIncluded,
          selected,
          required,
          coverageCode,
          coverageDescription,
          amount,
          coverageCurrency
        );
      });
    }

    //设备
    if (pricedEquips && pricedEquips.length > 0) {
      pricedEquips.forEach((pricedEquip) => {
        const required = pricedEquip.required;
        const selected = pricedEquip.selected;
        const equipment = pricedEquip.equipment;
        const equipmentCode = equipment.code;
        const description = equipment.description;
        const charge = pricedEquip.charge;
        const equipAmount = charge.amount;
        const chargeCurrency = charge.currencyCode;
        //RENTAL 指计算价格单位为每次租赁， DAY指计算价格单位为每天
        const unitName = charge.calculations[0].unitName;

        carRentalItemVO.addNewEquipment(
          required,
          selected,
          equipment,
          equipmentCode,
          unitName,
          equipAmount,
          chargeCurrency
        );
      });
    }

    //费用
    if (vehicleCharges && vehicleCharges.length > 0) {
      vehicleCharges.forEach((vehicleCharge) => {
        const description = vehicleCharge.description;
        const amount = vehicleCharge.amount;
        const currencyCode = vehicleCharge.currencyCode;
        const taxInclusive = vehicleCharge.taxInclusive;
        const taxAmounts = vehicleCharge.taxAmounts;
        const purpose = vehicleCharge.purpose; // 1 for vehicle rental, 4 for coverage, 6 for fee, 7 for tax

        carRentalItemVO.addNewCharge(
          description,
          amount,
          currencyCode,
          taxInclusive,
          taxAmounts
        );
      });
    }

    carRentalItems.push(carRentalItemVO);
  });

  return carRentalItems;
}

/**
 *
 * @param carRentalItems 数据源数组
 * @param currentPage 指定页
 * @param limit 每页数据量
 * @returns {*|null}
 */
function findSpecificCarRentalItems(carRentalItems, currentPage, limit) {
  let start = 0;
  let end = start + currentPage * limit;
  if (carRentalItems && end > carRentalItems.length) {
    end = carRentalItems.length;
  }

  const carRentalItemsCopy = JSON.parse(JSON.stringify(carRentalItems));
  return carRentalItemsCopy.slice(start, end);
}

function buildCarRentalItem(availVehicleRatesRS) {
  if (
    !availVehicleRatesRS ||
    !availVehicleRatesRS.vehicleAvailRSCore ||
    !availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails ||
    availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails.length === 0 ||
    !availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails ||
    !availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails ||
    availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails.length === 0
  )
    return null;

  const vehRentalCore = availVehicleRatesRS.vehicleAvailRSCore.vehRentalCore;
  const vehAvail =
    availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails[0];

  if (vehAvail) {
    const vehAvailInfo = vehAvail.vehAvailInfo;
    const pricedCoverages = vehAvailInfo.pricedCoverages;

    const vehAvailCore = vehAvail.vehAvailCore;
    const rentalRates = vehAvailCore.rentalRates[0];
    const minimumDriverAge = rentalRates.rateRestrictions.minimumAge;
    const quoteID = rentalRates.quoteID;
    let rateQualifier = null;
    const rateDistances = rentalRates.rateDistances;

    if (rateDistances && rateDistances.length > 0) {
      rateQualifier = rateDistances[0].unlimited;
    }
    const vehicleCharges = rentalRates.vehicleCharges;
    const totalCharges = vehAvailCore.totalCharges[0];
    const pricedEquips = vehAvailCore.pricedEquips;
    const currencyCode = totalCharges.currencyCode;
    const totalAmount = totalCharges.estimatedTotalAmount;
    const vehicle = vehAvailCore.vehicle;
    const vehType = vehicle.vehType;
    const vehClass = vehicle.vehClass;
    const classCode = vehClass.vehClassCode;
    const className = vehClass.vehClassName;
    const vehMakeModel = vehicle.vehMakeModel;
    const vehName = vehMakeModel.name;

    const pictureURL = vehicle.pictureURL;
    const passengerQuantity = vehicle.passengerQuantity;
    const baggageQuantity = vehicle.baggageQuantity;

    const airConditionInd = vehicle.airConditionInd;
    const transmissionType = vehicle.transmissionType;
    const fuelType = vehicle.fuelType;
    const doorCount = vehType.doorCount;

    const carRentalItemVO = new CarRentalItemVO(
      pictureURL,
      vehName,
      currencyCode,
      totalAmount,
      airConditionInd,
      transmissionType,
      fuelType,
      doorCount,
      passengerQuantity,
      baggageQuantity,
      classCode,
      className,
      minimumDriverAge,
      quoteID,
      rateQualifier,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    //保险
    if (pricedCoverages != null && pricedCoverages.length > 0) {
      pricedCoverages.forEach((pricedCoverage) => {
        const selected = pricedCoverage.selected;
        const required = pricedCoverage.required;
        const coverageCode = pricedCoverage.coverage.code;
        const coverageDescription = pricedCoverage.coverage.description;
        const amount = pricedCoverage.charge.amount;
        const coverageCurrency = pricedCoverage.charge.currencyCode;
        const coverageIncluded = selected && amount === 0;

        carRentalItemVO.addNewInsurance(
          coverageIncluded,
          selected,
          required,
          coverageCode,
          coverageDescription,
          amount,
          coverageCurrency
        );
      });
    }

    //设备
    if (pricedEquips && pricedEquips.length > 0) {
      pricedEquips.forEach((pricedEquip) => {
        const required = pricedEquip.required;
        const selected = pricedEquip.selected;
        const equipment = pricedEquip.equipment;
        const equipmentCode = equipment.code;
        const description = equipment.description;
        const charge = pricedEquip.charge;
        const equipAmount = charge.amount;
        const chargeCurrency = charge.currencyCode;
        //RENTAL 指计算价格单位为每次租赁， DAY指计算价格单位为每天
        const unitName = charge.calculations[0].unitName;

        carRentalItemVO.addNewEquipment(
          required,
          selected,
          equipment,
          equipmentCode,
          unitName,
          equipAmount,
          chargeCurrency
        );
      });
    }

    //费用
    if (vehicleCharges && vehicleCharges.length > 0) {
      vehicleCharges.forEach((vehicleCharge) => {
        const description = vehicleCharge.description;
        const amount = vehicleCharge.amount;
        const currencyCode = vehicleCharge.currencyCode;
        const taxInclusive = vehicleCharge.taxInclusive;
        const taxAmounts = vehicleCharge.taxAmounts;
        const purpose = vehicleCharge.purpose; // 1 for vehicle rental, 4 for coverage, 6 for fee, 7 for tax

        carRentalItemVO.addNewCharge(
          description,
          amount,
          currencyCode,
          taxInclusive,
          taxAmounts
        );
      });
    }

    return carRentalItemVO;
  }
}

/**
 *
 * @param availVehicleRatesRS 构建设备可选项
 */
function buildEquipmentOptions(availVehicleRatesRS) {
  if (
    !availVehicleRatesRS ||
    !availVehicleRatesRS.vehicleAvailRSCore ||
    !availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails ||
    availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails.length === 0 ||
    !availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails ||
    !availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails ||
    availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails.length === 0
  )
    return null;

  const vehAvail =
    availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails[0];

  const equipments = [];
  if (vehAvail) {
    const vehAvailCore = vehAvail.vehAvailCore;
    const pricedEquips = vehAvailCore.pricedEquips;

    //设备
    if (pricedEquips && pricedEquips.length > 0) {
      pricedEquips.forEach((pricedEquip) => {
        const required = pricedEquip.required;
        const selected = pricedEquip.selected;
        const equipment = pricedEquip.equipment;
        const equipmentCode = equipment.code;
        const description = equipment.description;
        const charge = pricedEquip.charge;
        const equipAmount = charge.amount;
        const chargeCurrency = charge.currencyCode;
        //RENTAL 指计算价格单位为每次租赁， DAY指计算价格单位为每天
        const unitName = charge.calculations[0].unitName;

        const carRentalEquipItemVO = new CarRentalEquipItemVO(
          required,
          selected,
          description,
          equipmentCode,
          unitName,
          equipAmount,
          chargeCurrency,
          0
        );
        equipments.push(carRentalEquipItemVO);
      });
    }
  }

  return equipments;
}

/**
 *
 * @param availVehicleRatesRS 构建保险可选项
 */
function buildCoverageOptions(availVehicleRatesRS) {
  if (
    !availVehicleRatesRS ||
    !availVehicleRatesRS.vehicleAvailRSCore ||
    !availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails ||
    availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails.length === 0 ||
    !availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails ||
    !availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails ||
    availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails.length === 0
  )
    return null;

  const vehAvail =
    availVehicleRatesRS.vehicleAvailRSCore.vehVendorAvails[0].vehAvails
      .vehAvails[0];

  const insurances = [];
  if (vehAvail) {
    const vehAvailInfo = vehAvail.vehAvailInfo;
    const pricedCoverages = vehAvailInfo.pricedCoverages;

    //保险
    if (pricedCoverages != null && pricedCoverages.length > 0) {
      pricedCoverages.forEach((pricedCoverage) => {
        const selected = pricedCoverage.selected;
        const required = pricedCoverage.required;
        const coverageCode = pricedCoverage.coverage.code;
        const coverageDescription = pricedCoverage.coverage.description;
        const amount = pricedCoverage.charge.amount;
        const coverageCurrency = pricedCoverage.charge.currencyCode;
        const coverageIncluded = selected && amount === 0;

        const carRentalCoverageItemVO = new CarRentalCoverageItemVO(
          coverageIncluded,
          selected,
          required,
          coverageCode,
          coverageDescription,
          amount,
          coverageCurrency
        );
        insurances.push(carRentalCoverageItemVO);
      });
    }
  }

  return insurances;
}

export default {
  buildDynamicPkgCarRentalSearchRQ,
  buildCarRentalItems,
  findSpecificCarRentalItems,
  buildCarRentalItem,
  buildCoverageOptions,
  buildEquipmentOptions,
};

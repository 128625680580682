export const passwordRegx =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#%^&*?])[A-Za-z\d!@#%^&*?]{8,}/;
// export const passwordRegx =
//   /^(?=.[0-9])(?=.[A-Z])(?=.[a-z])(?=.[!@#%^&*?]).{8,16}$/;
export const phoneNumberRegx = /^[1-9][0-9]{3,}/;
export const numberRegx = /^[0-9]+$/;
export const nameRegx = /^[a-zA-Z]+[a-zA-Z]$/;
export const chineseRegx = /^[\u4e00-\u9fa5]+$/;
export const givenNameRegx = /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/;
export const surnameRegx = /^[a-zA-Z]+$/;
export const startEndSpaceRegx = /^\S.*\S|(^\S{0,1}\S$)/;
// export const codeRegx = /^(\[a-zA-Z0-9]){2}(\[a-zA-Z0-9]$){2,}+$/;
export const codeRegx = /^[A-Z0-9]{2}[\/]{1}[a-zA-Z0-9]+$/;
export const enRegx = /^[a-zA-Z]*$/;
export const cnRegx = /^[^\u4e00-\u9fa5]+$/;

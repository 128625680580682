import { CarRentalCoverageItemVO } from "@/viewobject/sales/carrental/carRentalCoverageItemVO";

export class CarRentalCoverageTypeItemVO {
  constructor(
    included,
    selected,
    mandatory,
    type,
    code,
    description,
    totalAmount,
    currency,
    insuranceItems
  ) {
    this.included = included;
    this.selected = selected;
    this.mandatory = mandatory;
    this.type = type;
    this.code = code;
    this.description = description;

    this.totalAmount = totalAmount;
    this.currency = currency;
    this.insuranceItems = insuranceItems;
  }

  addInsuranceItem(insurance) {
    let carRentalCoverageItemVO = new CarRentalCoverageItemVO(
      insurance.included,
      insurance.selected,
      insurance.mandatory,
      insurance.code,
      insurance.description,
      insurance.amount,
      insurance.currency
    );
    if (this.insuranceItems == null) this.insuranceItems = [];
    this.insuranceItems.push(carRentalCoverageItemVO);
    return carRentalCoverageItemVO;
  }
}

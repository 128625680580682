<script setup>
import dateUtils from "@/utils/dateUtils";
import { Popover } from "ant-design-vue";

defineProps({
  carRentalItem: {},
});
const APopover = Popover;
</script>

<template>
  <div class="tour_details_boxed" v-if="carRentalItem">
    <h3 class="heading_theme">Car Details</h3>
    <div class="mg-b-10">
      <div class="flex">
        <div class="car_logo mg-r-20" v-if="carRentalItem.pictureURL">
          <img :src="carRentalItem.pictureURL" />
        </div>
        <div>
          <h5>{{ carRentalItem.vehName }}</h5>
          <!--  提示信息 - 车辆类型-->
          <div class="mg-b-10" v-if="carRentalItem.className">
            OR SIMILAR {{ carRentalItem.className }}

            <a-popover :z-index="22222" placement="right">
              <template #content>
                <div>
                  The model you get on your pick up day is subject to
                  availability.
                </div>
              </template>
              <i
                class="bi bi-exclamation-circle-fill mg-l-8 text-muted cursor-point"
              ></i>
            </a-popover>
          </div>
          <div class="ul_bott mg-t-5 font-14">
            <span v-if="carRentalItem.rateQualifier"
              ><i class="bi bi-check-lg text-primary"></i> Unlimited
              mileage</span
            >
          </div>
        </div>
      </div>
      <hr v-if="carRentalItem.pictureURL" />
      <div class="flex flex-wrap">
        <div class="mg-r-20" v-if="carRentalItem.passengerQuantity">
          <span
            ><i class="fa-solid fa-people-group text-muted mg-r-5"></i
            >{{ carRentalItem.passengerQuantity }} {{ $t("people") }}</span
          >
        </div>
        <div class="mg-r-20" v-if="carRentalItem.doorCount">
          <span>
            <img
              src="@/assets/img/car_door.png"
              style="width: 24px; height: 18px; margin-top: -3px"
            />{{ carRentalItem.doorCount }} {{ $t("doors") }}</span
          >
        </div>
        <div class="mg-r-20" v-if="carRentalItem.baggageQuantity">
          <span>
            <i class="fa-solid fa-suitcase-rolling text-muted mg-r-5"></i
            >{{ carRentalItem.baggageQuantity }} {{ $t("luggage") }}</span
          >
        </div>
        <div class="mg-r-20" v-if="carRentalItem.transmissionType">
          <span
            ><i class="fa-solid fa-tachometer text-muted mg-r-5"></i
            >{{ carRentalItem.transmissionType }}</span
          >
        </div>
        <div class="mg-r-20" v-if="carRentalItem.fuelType">
          <span
            ><i class="fa-solid fa-gas-pump text-muted mg-r-5"></i
            >{{ carRentalItem.fuelType }}</span
          >
        </div>
        <div class="mg-r-20" v-if="carRentalItem.airConditionInd">
          <span v-if="carRentalItem.airConditionInd"
            ><i class="fa-solid fa-snowflake text-muted mg-r-5"></i>A/C</span
          >
        </div>
        <div class="mg-r-20" v-if="carRentalItem.minimumDriverAge">
          <span
            ><i class="fa-regular fa-id-card text-muted mg-r-5"></i
            >{{
              $t("minimum-driving-age-years", {
                minimumDriverAge: carRentalItem.minimumDriverAge,
              })
            }}
          </span>
        </div>
      </div>
      <div
        class="mg-t-10"
        v-if="carRentalItem.pickUpLocation || carRentalItem.pickUpDateTime"
      >
        <div class="font_weight">Pick-up</div>
        <div class="p-1 text-muted">
          {{ carRentalItem.pickUpLocation }}
          <span v-if="carRentalItem.pickUpDateTime">
            |
            {{ dateUtils.formatEnglishDate(carRentalItem.pickUpDateTime).time }}
            {{
              dateUtils.formatEnglishDate(carRentalItem.pickUpDateTime).ddate
            }}
            {{
              dateUtils.formatEnglishDate(carRentalItem.pickUpDateTime).month
            }},
            {{ dateUtils.formatEnglishDate(carRentalItem.pickUpDateTime).year }}
          </span>
        </div>
      </div>
      <div
        class="mg-t-10"
        v-if="carRentalItem.returnLocation || carRentalItem.returnDateTime"
      >
        <div class="font_weight">Drop-off</div>
        <div class="p-1 text-muted">
          {{ carRentalItem.returnLocation }}
          <span v-if="carRentalItem.returnDateTime"
            >|
            {{ dateUtils.formatEnglishDate(carRentalItem.returnDateTime).time }}
            {{
              dateUtils.formatEnglishDate(carRentalItem.returnDateTime).ddate
            }}
            {{
              dateUtils.formatEnglishDate(carRentalItem.returnDateTime).month
            }},
            {{ dateUtils.formatEnglishDate(carRentalItem.returnDateTime).year }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

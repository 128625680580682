<script setup>
defineProps({
  passengerTicketFareBreakdownItems: {},
  totalAmountContent: {},
});

function getPassengerType(typeCode) {
  let typeName = "Adult";
  if (typeCode === "CNN") {
    typeName = "Child";
  } else if (typeCode === "INF") {
    typeName = "Infant";
  }
  return typeName;
}
</script>

<template>
  <div class="tour_booking_form_box">
    <h4>{{$t("price-summary")}}</h4>
    <div class="tour_booking_amount_area">
      <ul
        v-for="(
          fareBreakdownItem, fareBreakdownItemIndex
        ) in passengerTicketFareBreakdownItems"
        :key="fareBreakdownItemIndex"
      >
        <li>
          {{$t("traveller")}} {{ fareBreakdownItemIndex + 1 }}:

          {{ getPassengerType(fareBreakdownItem.passengerTypeCode) }}
          <span
            >{{ fareBreakdownItem.currency }}
            {{ fareBreakdownItem.totalFare }}</span
          >
        </li>
        <li>
          <p>{{$t("flight")}}</p>
          <span
            ><p>
              {{ fareBreakdownItem.currency }}
              {{ fareBreakdownItem.baseFare }}
            </p></span
          >
        </li>
        <li>
          <p>{{$t("taxes-and-fees")}}</p>
          <span
            ><p>
              {{ fareBreakdownItem.currency }}
              {{ fareBreakdownItem.taxes }}
            </p></span
          >
        </li>
      </ul>
      <div class="total_subtotal_booking">
        <h6>
          {{$t("trip-total-price")}}
          <span
            >{{ totalAmountContent.currency }}
            {{ totalAmountContent.totalAmount.toFixed(2) }}</span
          >
        </h6>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script setup>
import { Drawer, Empty, Spin } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import DynamicPkgAvailForm from "@/views/v2/sales/dynamicpkg/items/form/avail/DynamicPkgAvailForm.vue";
import { useTemplateStore } from "@/stores/template";
import { usePackageHotelAvailStore } from "@/stores/v2/dynamicPkg/availability/packageHotelAvailStore";
import { useI18n } from "vue-i18n";
import { onMounted, ref, watch } from "vue";
import { HotelItemsSortFormModel } from "@/formmodel/sales/hotel/availability/hotelItemsSortFormModel";
import { HotelItemsFilterFormModel } from "@/formmodel/sales/hotel/availability/hotelItemsFilterFormModel";
import DynamicPkgHotelListItem from "@/views/v2/sales/dynamicpkg/items/group/DynamicPkgHotelListItem.vue";
import HotelResultsSortAndFilterForm from "@/views/v2/sales/hotel/items/form/availability/HotelResultsSortAndFilterForm.vue";
import { useCmsContentStore } from "@/stores/cms";

const AEmpty = Empty;
const ADrawer = Drawer;
const ASpin = Spin;
const router = useRouter();
const route = useRoute();

const templateStore = useTemplateStore();

const packageHotelAvailStore = usePackageHotelAvailStore();
const { t, locale } = useI18n(); // 解构调用函数
//region 初始化数据
const hotelItemsShowRef = ref([]);
const currentPageRef = ref(1); //每次load more添加一页显示
const isLoadMoreRef = ref(true);

//过滤排序相关
const hotelSortFormModelRef = ref(new HotelItemsSortFormModel("PRICE", "ASC"));
const hotelFilterFormModelRef = ref(
  new HotelItemsFilterFormModel(null, null, [], [], [], [], [])
);
//endregion

//region watch监听pinia 获取最新的酒店数据视图列表
watch(
  () => packageHotelAvailStore.hotelItemsShow,
  (newValue) => {
    if (newValue) {
      hotelItemsShowRef.value = newValue;
    } else {
      hotelItemsShowRef.value = [];
    }
  },
  { immediate: true, deep: true }
);
//endregion

//region 过滤排序总入口
function sortAndFilter(sortFormModel, filterFormModel) {
  packageHotelAvailStore.changeHotelItemsShow(
    null,
    null,
    filterFormModel,
    sortFormModel
  );
}

//endregion

//region 辅助函数

//-------------酒店数据滑动到底部自动加载---------------------
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

function handleScroll() {
  /*
   * document.documentElement.scrollHeight:
   * document.documentElement.scrollTop
   * document.documentElement.clientHeight
   * */
  const distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;
  if (distance > -10 && distance < 10) {
    loadMoreItems();
  }
}

function loadMoreItems() {
  if (hotelItemsShowRef.value.length >= packageHotelAvailStore.totalResult) {
    isLoadMoreRef.value = false;
  }
  currentPageRef.value += 1;
  packageHotelAvailStore.changeHotelItemsShow(currentPageRef.value, true);
}

//------------酒店数据滑动到底部自动加载-----------------------

function selectHotel(ttiCode) {
  const query = route.query;
  query.ttiCode = ttiCode;
  query.locationType = "HOTEL";
  const routeUrl = router.resolve({
    name: "sales-dynamicPkg-hotel-details-v2",
    query: query,
  });
  window.open(routeUrl.href, "_blank");
}

//endregion

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.PackageBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.PackageBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("hotel-list") }}</h2>
        <ul>
          <li class="pre-page" @click="router.push({ name: 'home' })">
            {{ $t("home") }}
          </li>

          <li>
            <i class="fas fa-circle"></i>
            {{ $t("hotel-list") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="theme_search_form_tour">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!--搜索表单 -->
          <DynamicPkgAvailForm :jumpable="false" />
          <!--搜索表单-->
        </div>
      </div>
    </div>
  </section>

  <section class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <HotelResultsSortAndFilterForm
            :hotel-filter-form-model="hotelFilterFormModelRef"
            :hotel-items-length="packageHotelAvailStore.totalResult"
            :hotel-sort-form-model="hotelSortFormModelRef"
            @sortAndFilter="sortAndFilter"
          />
        </div>

        <div class="col-lg-9">
          <div
            class="row"
            v-if="hotelItemsShowRef && hotelItemsShowRef.length > 0"
          >
            <div
              v-for="(hotelViewItem, hotelIndex) in hotelItemsShowRef"
              :key="hotelIndex"
              class="col-lg-4 col-md-6 col-sm-6 col-12"
            >
              <DynamicPkgHotelListItem
                :item="hotelViewItem"
                class="text-pointer cursor-point"
                @click="selectHotel(hotelViewItem.ttiCode)"
              />
            </div>
            <div
              class="text-center"
              v-if="
                isLoadMoreRef &&
                packageHotelAvailStore.hotelItems &&
                packageHotelAvailStore.hotelItems.length > 0 &&
                packageHotelAvailStore.hotelItems.length >
                  packageHotelAvailStore.limit &&
                hotelItemsShowRef > packageHotelAvailStore.limit
              "
            >
              <Spin></Spin>
              <span class="mg-l-8 text-primary">{{ $t("load-more") }}..</span>
            </div>
          </div>
          <div v-else>
            <AEmpty></AEmpty>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

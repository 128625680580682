<script setup>
import FlatPickr from "vue-flatpickr-component";
import { reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import dateUtils from "@/utils/dateUtils";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import BaseActivitySearchPassengersForm from "@/views/v2/sales/activity/items/form/BaseActivitySearchPassengersForm.vue";
import activityTools from "@/tools/v2/activity/activityTools";
import activityApi from "@/apis/v2/activity/activityApi";
import commonUtils from "@/utils/commonUtils";
import { useActivityStore } from "@/stores/v2/activity/activity";
import { useTemplateStore } from "@/stores/template";

const props = defineProps({
  equipmentType: {},
  jumboable: {},
  jumpTo: {},
});
const router = useRouter();
const route = useRoute();
const activityStore = useActivityStore();
const templateStore = useTemplateStore();
//region 数据初始化
let activitySearchFormModelRef = ref(
  new activityTools.ActivitySearchFormModel(
    dateUtils.getOffsetDate(new Date(), 7),
    dateUtils.getOffsetDate(new Date(), 8),
    null,
    null,
    1,
    0,
    0,
    []
  )
);
// 列表页
if (!props.jumboable) {
  if (route.query != null && Object.keys(route.query).length > 0) {
    activitySearchFormModelRef.value = getTicketSearchFormModel(route);
  }
  searchActivity(activitySearchFormModelRef.value);
}

let suggestPlaceOptions = ref([]); //地点相关

const flatPickrState = reactive({
  // Initial values
  dateRange: [
    activitySearchFormModelRef.value.startDate,
    activitySearchFormModelRef.value.endDate,
  ],
  // Configuration, get more form https://chmln.github.io/flatpickr/options/
  configRange: {
    mode: "range",
    minDate: "today",
    dateFormat: "Y-m-d",
  },
});
//endregion

//根据路由参数获取表单对象
function getTicketSearchFormModel(route) {
  let childAgesFormat = route.query.childAgesFormat;
  let childAges = parseChildAges(childAgesFormat);
  let startDate = route.query.startDate;
  let endDate = route.query.endDate;
  let locationCode = route.query.locationCode;
  let locationName = route.query.locationName;
  let adultNum = route.query.adultNum;
  let childNum = route.query.childNum;
  let seniorNum = route.query.seniorNum;
  return {
    startDate: startDate,
    endDate: endDate,
    locationCode: locationCode,
    locationName: locationName,
    adultNum: adultNum,
    childNum: childNum,
    seniorNum: seniorNum,
    childAges: childAges,
  };
}

function chooseDates(dates) {
  if (dates.length === 2) {
    let checkIn = dateUtils.formatDate(dates[0]);
    let checkOut = dateUtils.formatDate(dates[1]);
    activitySearchFormModelRef.value.startDate = checkIn;
    activitySearchFormModelRef.value.endDate = checkOut;
  } else {
    activitySearchFormModelRef.value.startDate = null;
    activitySearchFormModelRef.value.endDate = null;
  }
}

//地点相关，地点选择和点自动补全
function selectPlace(suggestPlace, menuId) {
  activitySearchFormModelRef.value.locationCode = suggestPlace.cityCode;
  activitySearchFormModelRef.value.locationName = suggestPlace.name;
  document.getElementById(menuId).classList.remove("show");
}

function suggestPlaces(keyword) {
  activitySearchFormModelRef.value.locationCode = null;

  const searchDebounceFUN = commonUtils.debounce(
    function () {
      activityApi.locations(keyword, "en").then((res) => {
        suggestPlaceOptions.value = commonUtils.unique(res.data, "cityCode"); //去重
      });
    },
    "searchActivity",
    500
  );

  searchDebounceFUN();
  document.getElementById("activity-dropdown-menu").classList.add("show");
}

//endregion
//校验相关初始化
const rulesRef = ref({
  locationCode: {
    required,
  },
  startDate: {
    required,
  },
  endDate: {
    required,
  },
});
const v = useVuelidate(rulesRef.value, activitySearchFormModelRef);
let childAgesValidCheck = ref(false);

//region 搜索表单事件
async function submitActivityFormModel() {
  childAgesValidCheck.value = false;
  //在父组件中调用，则会校验所有子组件的规则，并统一返回校验结果
  const validateResult = await v.value.$validate();
  if (v.value.$errors && v.value.$errors.length > 0) {
    v.value.$errors.forEach((item) => {
      if (item != null && item.$property == "age") {
        childAgesValidCheck.value = true;
      }
    });
  }
  if (!validateResult) return;
  let activitySearchFormModelCopy = JSON.parse(
    JSON.stringify(activitySearchFormModelRef.value)
  );
  //jump to
  let childAgesFormat = formatChildAges(activitySearchFormModelCopy.childAges);
  activitySearchFormModelCopy.childAgesFormat = childAgesFormat;
  router.replace({ query: activitySearchFormModelCopy });
  //每次搜索之后存储表单
  sessionStorage.setItem(
    "activitySearchFormModel",
    JSON.stringify(activitySearchFormModelRef.value)
  );

  if (props.jumboable) {
    router.push({
      name: props.jumpTo,
      query: activitySearchFormModelCopy,
    });
  } else {
    searchActivity(activitySearchFormModelCopy);
  }
}

async function searchActivity(formModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await activityStore.availActivity(formModel);
  templateStore.pageLoader({ mode: "off" });
}

//endregion

function formatChildAges(childAges) {
  let format = "";
  if (childAges && childAges.length > 0) {
    childAges.forEach((child) => {
      format += "-" + child.age.toString();
    });
  }
  return format;
}

//列表页解析房间住客数量信息
function parseChildAges(formats) {
  let splits = formats.split("-");
  let childAges = [];

  if (splits && splits.length > 0) {
    splits.forEach((childAgeStr) => {
      if (parseInt(childAgeStr)) {
        childAges.push({ age: parseInt(childAgeStr) });
      }
    });
  }
  return childAges;
}
</script>

<template>
  <div
    class="row"
    :class="{
      theme_search_form_area: props.jumpTo == null,
    }"
  >
    <div class="col-lg-12 col-12">
      <div class="tour_search_form">
        <form action="javascript:void(0);">
          <div class="row">
            <div class="col-4">
              <div class="search_boxed">
                <p>Going to</p>
                <input
                  id="sales-hotel-destination"
                  v-model="activitySearchFormModelRef.locationName"
                  :class="{
                    'is-invalid': v.locationCode.$errors.length > 0,
                  }"
                  @blur="v.locationCode.$touch"
                  aria-expanded="false"
                  aria-haspopup="true"
                  autocomplete="off"
                  data-bs-toggle="dropdown"
                  name="sales-hotel-destination"
                  placeholder="Enter a location"
                  type="text"
                  @input="(event) => suggestPlaces(event.target.value)"
                />
                <span
                  id="whereGo"
                  class="text-danger"
                  v-if="v.locationCode.$errors.length > 0"
                  >Please enter location</span
                >
                <span id="whereGo" v-else
                  >{{ $t("where-are-you-going") }}?</span
                >

                <div
                  id="activity-dropdown-menu"
                  aria-labelledby="sales-hotel-destination"
                  class="dropdown-menu fs-sm full-width hotel_search_dropdown"
                  style="width: 120%"
                >
                  <div v-if="suggestPlaceOptions.length > 0">
                    <a
                      v-for="(place, suggestIndex) in suggestPlaceOptions"
                      :key="suggestIndex"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="selectPlace(place, 'activity-dropdown-menu')"
                    >
                      <div class="row">
                        <div class="col-1">
                          <i class="fa fa-location-dot"></i>
                        </div>
                        <div class="col-11 ps-0">
                          {{ place.name }}
                        </div>
                      </div>
                    </a>
                  </div>
                  <div v-else>
                    <div class="dropdown-item">
                      <i class="fa fa-magnifying-glass me-3"></i>
                      {{ $t("search-by-destination") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form_search_date">
                <div class="search_boxed">
                  <p>{{ $t("check-in-to-check-out") }}</p>
                  <FlatPickr
                    class="form-control"
                    name="flight-search-date"
                    placeholder="Y-m-d"
                    v-model="flatPickrState.dateRange"
                    :class="{
                      'is-invalid':
                        v.startDate.$errors.length > 0 ||
                        v.endDate.$errors.length > 0 ||
                        v.startDate.$model === v.endDate.$model,
                    }"
                    :config="flatPickrState.configRange"
                    @on-change="chooseDates"
                  />
                  <span class="mg-l-8"
                    >{{
                      dateUtils.getDaysByDateRange(
                        activitySearchFormModelRef.startDate,
                        activitySearchFormModelRef.endDate
                      )
                    }}
                    {{ $t("night") }} (s)
                  </span>
                </div>
              </div>
            </div>
            <div class="col-4 pad_search_passenger">
              <BaseActivitySearchPassengersForm
                :child-ages-valid-check="childAgesValidCheck"
                :activity-form-model="activitySearchFormModelRef"
              />
            </div>

            <div class="top_form_search_button">
              <button class="btn btn_theme btn_md" @click="submitActivityFormModel">
                {{ $t("search") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

import { defineStore } from "pinia";
import dynamicPkgAvailApi from "@/apis/v2/dynamicPkg/dynamicPkgAvailApi";
import dynamicPkgCarRentalRatesTools from "@/tools/v2/dynamicPkg/price/dynamicPkgCarRentalRatesTools";
import dynamicPkgCarRentalAvailTools from "@/tools/v2/dynamicPkg/availability/dynamicPkgCarRentalAvailTools";

export const usePackageCarRentalRatesStore = defineStore(
  "packageCarRentalRatesStore",
  {
    state: () => ({
      dynamicPkgAvailFormModel: null,
      carRentalItem: null,
      equipmentOptions: null, //租车设备可选项
      coverageOptions: null, //租车保险可选项
    }),
    actions: {
      carRentalRates(dynamicPkgAvailFormModel) {
        const rateRQ =
          dynamicPkgCarRentalRatesTools.buildDynamicPkgCarRentalRatesRQ(
            dynamicPkgAvailFormModel
          );
        let thisRef = this;
        thisRef.dynamicPkgAvailFormModel = dynamicPkgAvailFormModel;
        //搜索可租车辆
        return dynamicPkgAvailApi
          .dynamicPkgAvail(rateRQ)
          .then((res) => {
            const availVehicleRatesRS = res.data;
            if (
              availVehicleRatesRS &&
              availVehicleRatesRS.searchResults &&
              availVehicleRatesRS.searchResults.carResults
            ) {
              const carResult = availVehicleRatesRS.searchResults.carResults[0];
              thisRef.carRentalItem =
                dynamicPkgCarRentalAvailTools.buildCarRentalItem(carResult);

              thisRef.equipmentOptions =
                dynamicPkgCarRentalAvailTools.buildEquipmentOptions(carResult);

              thisRef.coverageOptions =
                dynamicPkgCarRentalAvailTools.buildCoverageOptions(carResult);
            } else {
              thisRef.carRentalItem = null;
              thisRef.equipmentOptions = null;
              thisRef.coverageOptions = null;
            }

            return availVehicleRatesRS;
          })
          .catch((err) => {
            console.error(err);
            thisRef.carRentalItem = null;
          });
      },
      carRentalRatesQuote(carRentalRatesQuoteFormModel) {
        // todo 机加酒租车验价
        // const rateQuoteRQ = carRentalRatesTools.buildCarRentalRatesQuoteRQ(
        //   carRentalRatesQuoteFormModel
        // );
        // let thisRef = this;
        // //保险以及设备验价
        // return carRentalRatesApi
        //   .carRentalRates(rateQuoteRQ)
        //   .then((res) => {
        //     const availVehicleRatesRS = res.data;
        //
        //     if (availVehicleRatesRS.vehAvailRSCore) {
        //       thisRef.carRentalItem =
        //         carRentalRatesTools.buildCarRentalItem(availVehicleRatesRS);
        //     } else {
        //       thisRef.carRentalItem = null;
        //     }
        //
        //     return availVehicleRatesRS;
        //   })
        //   .catch((err) => {
        //     console.error(err);
        //     thisRef.carRentalItem = null;
        //   });
      },
    },
  }
);

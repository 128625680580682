<script setup>
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { Empty, Spin } from "ant-design-vue";
import { useTemplateStore } from "@/stores/template";
import { usePackageCarRentalAvailStore } from "@/stores/v2/dynamicPkg/availability/packageCarRentalAvailStore";
import { onMounted, ref, watch } from "vue";
import dynamicPkgCommonTools from "@/tools/v2/dynamicPkg/common/dynamicPkgCommonTools";
import dateUtils from "@/utils/dateUtils";
import { CarRentalItemsSortFormModel } from "@/formmodel/sales/carrental/availablility/carRentalItemsSortFormModel";
import { CarRentalItemsFilterFormModel } from "@/formmodel/sales/carrental/availablility/carRentalItemsFilterFormModel";
import CarRentalItem from "@/views/v2/sales/carrental/items/group/CarRentalItem.vue";
import CarRentalResultsSortAndFilterForm from "@/views/v2/sales/carrental/items/form/availability/CarRentalResultsSortAndFilterForm.vue";
import { useCmsContentStore } from "@/stores/cms";

const router = useRouter();
const route = useRoute();

const { t, locale } = useI18n(); // 解构调用函数
const AEmpty = Empty;
const ASpin = Spin;

const templateStore = useTemplateStore();
const packageCarRentalAvailStore = usePackageCarRentalAvailStore();

const dynamicPkgAvailFormModelRef = ref(null);

//#region query参数请求，进行搜索
if (route.query != null && Object.keys(route.query).length > 0) {
  dynamicPkgAvailFormModelRef.value =
    dynamicPkgCommonTools.parseDynamicPkgAvailFormModel(route.query);
  searchCarRentalByFormModel(dynamicPkgAvailFormModelRef.value);
}

//#endregion

const carRentalItemsShowRef = ref([]);
const currentPageRef = ref(1); //每次load more添加一页显示
const isLoadMoreRef = ref(true);
const daysApartRef = ref(1); //租期

watch(
  () => packageCarRentalAvailStore.carRentalItemsShow,
  (newValue) => {
    if (newValue) {
      carRentalItemsShowRef.value = newValue;
    } else {
      carRentalItemsShowRef.value = [];
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => packageCarRentalAvailStore.dynamicPkgAvailFormModel,
  (newValue) => {
    if (newValue) {
      let pickUpDateTime = newValue.checkInDate;
      let returnDateTime = newValue.checkOutDate;

      daysApartRef.value = dateUtils.getDaysByDateRange(
        pickUpDateTime,
        returnDateTime
      );
    }
  },
  { immediate: true, deep: true }
);

//过滤排序相关
const carRentalItemsSortFormModelRef = ref(
  new CarRentalItemsSortFormModel("PRICE", "ASC")
);
const carRentalItemsFilterFormModelRef = ref(
  new CarRentalItemsFilterFormModel("", [], [])
);

//过滤排序总入口
function sortAndFilter(sortFormModel, filterFormModel) {
  packageCarRentalAvailStore.changeCarRentalItemsShow(
    null,
    null,
    filterFormModel,
    sortFormModel
  );
}

//endregion

//region 辅助函数

//-------------酒店数据滑动到底部自动加载---------------------
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

function handleScroll() {
  /*
   * document.documentElement.scrollHeight:
   * document.documentElement.scrollTop
   * document.documentElement.clientHeight
   * */
  const distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;
  if (distance > -10 && distance < 10) {
    loadMoreItems();
  }
}

function loadMoreItems() {
  if (
    carRentalItemsShowRef.value.length >= packageCarRentalAvailStore.totalResult
  ) {
    isLoadMoreRef.value = false;
  }
  currentPageRef.value += 1;
  packageCarRentalAvailStore.changeCarRentalItemsShow(
    currentPageRef.value,
    true
  );
}

//------------酒店数据滑动到底部自动加载-----------------------
async function searchCarRentalByFormModel(formModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await packageCarRentalAvailStore.carRentalAvail(formModel);
  templateStore.pageLoader({ mode: "off" });
}

function goToHotelDetails(query) {
  if (query.referenceId) delete query.referenceId;
  if (query.airSearchType) delete query.airSearchType;
  if (query.airItineraryRPH) delete query.airItineraryRPH;
  if (query.fareSegmentBasisCodesFormat)
    delete query.fareSegmentBasisCodesFormat;
  router.push({
    name: "sales-dynamicPkg-hotel-details-v2",
    query: query,
  });
}

function goToFlightList(query) {
  if (query.airSearchType) delete query.airSearchType;
  if (query.airItineraryRPH) delete query.airItineraryRPH;
  if (query.fareSegmentBasisCodesFormat)
    delete query.fareSegmentBasisCodesFormat;
  router.push({
    name: "sales-dynamicPkg-flight-list-v2",
    query: query,
  });
}

function selectVehicle(carClassCode) {
  const query = route.query;
  query.preferredCarType = carClassCode;
  router.push({
    name: "sales-dynamicPkg-car-rental-extra-v2",
    query: query,
  });
}

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.PackageBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.PackageBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>Car Rental List</h2>
        <ul>
          <li class="pre-page" @click="router.push({ name: 'home' })">
            {{ $t("home") }}
          </li>

          <li class="pre-page" @click="goToHotelDetails(route.query)">
            {{ $t("hotel-details") }}
          </li>

          <li class="pre-page" @click="goToFlightList(route.query)">
            <i class="fas fa-circle"></i>
            {{ $t("flight-list") }}
          </li>
          <li>
            <i class="fas fa-circle"></i>
            Car Rental List
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goToFlightList">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <CarRentalResultsSortAndFilterForm
            :car-rental-filter-form-model="carRentalItemsFilterFormModelRef"
            :car-rental-items-length="
              packageCarRentalAvailStore.carRentalItems
                ? packageCarRentalAvailStore.carRentalItems.length
                : 0
            "
            :car-rental-sort-form-model="carRentalItemsSortFormModelRef"
            @sortAndFilter="sortAndFilter"
          />
        </div>

        <div class="col-lg-9">
          <div
            class="row"
            v-if="carRentalItemsShowRef && carRentalItemsShowRef.length > 0"
          >
            <div
              v-for="(
                carRentalItem, carRentalItemIndex
              ) in carRentalItemsShowRef"
              :key="carRentalItemIndex"
            >
              <CarRentalItem
                :car-rental-item-index="carRentalItemIndex"
                :car-rental-item="carRentalItem"
                :days-apart="daysApartRef"
                @selectVehicle="selectVehicle"
              />
            </div>
            <div
              class="text-center"
              v-if="
                isLoadMoreRef &&
                packageCarRentalAvailStore.carRentalItems &&
                packageCarRentalAvailStore.carRentalItems.length > 0 &&
                packageCarRentalAvailStore.carRentalItems.length >
                  packageCarRentalAvailStore.limit &&
                carRentalItemsShowRef > packageCarRentalAvailStore.limit
              "
            >
              <Spin></Spin>
              <span class="mg-l-8 text-primary">{{ $t("load-more") }}..</span>
            </div>
          </div>
          <div v-else>
            <AEmpty></AEmpty>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

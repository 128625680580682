import FaqView from "@/views/faq/FaqView.vue";

export default [
  {
    path: "/faq",
    name: "faq",
    component: FaqView,
  meta: {
  footerShow: true,
},
  },
];

import HomeView from "@/templates/HomeView.vue";
import HomeTwoView from "@/templates/HomeTwoView.vue";
import TourSearchView from "@/templates/TourSearchView.vue";
import TourDetailsView from "@/templates/TourDetailsView.vue";
import TourBookingSubmissionView from "@/templates/TourBookingSubmissionView.vue";
import TopDestinationsView from "@/templates/TopDestinationsView.vue";
import FlightSearchResultView from "@/templates/FlightSearchResultView.vue";
import FlightBookingSubmission from "@/templates/FlightBookingSubmission.vue";
import HotelSearchView from "@/templates/HotelSearchView.vue";
import HotelDetailsView from "@/templates/HotelDetailsView.vue";
import RoomDetailsView from "@/templates/RoomDetailsView.vue";
import RoomBookingView from "@/templates/RoomBookingView.vue";
import AboutView from "@/templates/AboutView.vue";
import TourGuidesView from "@/templates/TourGuidesView.vue";
import TestimonialsView from "@/templates/TestimonialsView.vue";
import FaqsView from "@/templates/FaqsView.vue";
import BookingConfirmationView from "@/templates/BookingConfirmationView.vue";
import NewsView from "@/templates/NewsView.vue";
import NewsV2View from "@/templates/NewsV2View.vue";
import NewsDetailsView from "@/templates/NewsDetailsView.vue";
import LoginView from "@/templates/LoginView.vue";
import RegisterView from "@/templates/RegisterView.vue";
import ResetPasswordView from "@/templates/ResetPasswordView.vue";
import ForgotPasswordView from "@/templates/ForgotPasswordView.vue";
import VerifyOtpView from "@/templates/VerifyOtpView.vue";
import DashboardView from "@/templates/DashboardView.vue";
import HotelBookingView from "@/templates/HotelBookingView.vue";
import FlightBookingView from "@/templates/FlightBookingView.vue";
import TourBookingView from "@/templates/TourBookingView.vue";
import BookingHistoryView from "@/templates/BookingHistoryView.vue";
import MyProfileView from "@/templates/MyProfileView.vue";
import WalletView from "@/templates/WalletView.vue";
import NotificationView from "@/templates/NotificationView.vue";
import PrivacyPolicyView from "@/templates/PrivacyPolicyView.vue";
import ErrorView from "@/templates/ErrorView.vue";
import ContactView from "@/templates/ContactView.vue";
import BecomeVendorView from "@/templates/BecomeVendorView.vue";
import NotFound from "@/components/templates/NotFound.vue";

export default [
  {
    path: "/templates",
    redirect: "/templates/home",
    meta: {
      requireAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "home",
        name: "templates-home",
        component: HomeView,
      },
      {
        path: "home-two",
        name: "home-two",
        component: HomeTwoView,
      },
      {
        path: "tour-search",
        name: "tour-search",
        component: TourSearchView,
      },
      {
        path: "tour-details",
        name: "tour-details",
        component: TourDetailsView,
      },
      {
        path: "tour-booking-submission",
        name: "tour-booking-submission",
        component: TourBookingSubmissionView,
      },
      {
        path: "top-destinations",
        name: "top-destinations",
        component: TopDestinationsView,
      },
      {
        path: "flight-search-result",
        name: "flight-search-result",
        component: FlightSearchResultView,
      },
      {
        path: "flight-booking-submission",
        name: "flight-booking-submission",
        component: FlightBookingSubmission,
      },
      {
        path: "hotel-search",
        name: "hotel-search",
        component: HotelSearchView,
      },
      {
        path: "hotel-details",
        name: "hotel-details",
        component: HotelDetailsView,
      },
      {
        path: "room-details",
        name: "room-details",
        component: RoomDetailsView,
      },
      {
        path: "room-booking",
        name: "room-booking",
        component: RoomBookingView,
      },
      {
        path: "about",
        name: "about",
        component: AboutView,
      },
      {
        path: "tour-guides",
        name: "tour-guides",
        component: TourGuidesView,
      },
      {
        path: "testimonials",
        name: "testimonials",
        component: TestimonialsView,
      },
      {
        path: "faqs",
        name: "faqs",
        component: FaqsView,
      },
      {
        path: "booking-confirmation",
        name: "booking-confirmation",
        component: BookingConfirmationView,
      },
      {
        path: "news",
        name: "news",
        component: NewsView,
      },
      {
        path: "news-v2",
        name: "news-v2",
        component: NewsV2View,
      },
      {
        path: "news-details",
        name: "news-details",
        component: NewsDetailsView,
      },
      {
        path: "login",
        name: "login",
        component: LoginView,
      },
      {
        path: "register",
        name: "register",
        component: RegisterView,
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: ForgotPasswordView,
      },
      {
        path: "verify-otp",
        name: "verify-otp",
        component: VerifyOtpView,
      },
      {
        path: "reset-password",
        name: "reset-password",
        component: ResetPasswordView,
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: DashboardView,
      },
      {
        path: "hotel-booking",
        name: "hotel-booking",
        component: HotelBookingView,
      },
      {
        path: "flight-booking",
        name: "flight-booking",
        component: FlightBookingView,
      },
      {
        path: "tour-booking",
        name: "tour-booking",
        component: TourBookingView,
      },
      {
        path: "booking-history",
        name: "booking-history",
        component: BookingHistoryView,
      },
      {
        path: "my-profile",
        name: "my-profile",
        component: MyProfileView,
      },
      {
        path: "wallet",
        name: "wallet",
        component: WalletView,
      },
      {
        path: "notification",
        name: "notification",
        component: NotificationView,
      },
      {
        path: "privacy-policy",
        name: "privacy-policy",
        component: PrivacyPolicyView,
      },
      {
        path: "error",
        name: "error",
        component: ErrorView,
      },
      {
        path: "contact",
        name: "contact",
        component: ContactView,
      },
      {
        path: "become-vendor",
        name: "become-vendor",
        component: BecomeVendorView,
      },
      {
        path: ":cathAll(.*)",
        name: "NotFound",
        component: NotFound,
      },
    ],
  },
];

import TermsAndConditionsView from "@/views/terms/TermsAndConditionsView.vue";

export default [
  {
    path: "/terms-condition",
    name: "terms-condition",
    component: TermsAndConditionsView   ,
    meta: {
      footerShow: true,
    },
  },
];

<script setup>
import Banner from "@/components/custom/Banner.vue";
import { computed, reactive, ref, watch, watchEffect } from "vue";
import BaseFlightPassengersForm from "@/views/sales/flight/items/form/BaseFlightPassengersForm.vue";
import useVuelidate from "@vuelidate/core";
import router from "@/router/modulesRouter";
import BaseFlightPriceBreakdownPanel from "@/views/sales/flight/items/panel/BaseFlightPriceBreakdownPanel.vue";
import { useFlightStore } from "@/stores/flight";
import { useCmsContentStore } from "@/stores/cms";
import { useTemplateStore } from "@/stores/template";

const bannerContentStore = useCmsContentStore();
const templateStore = useTemplateStore();
bannerContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrl.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.flightBackgroundImage
      ? bannerContentStore.productPageBackgroundImage.flightBackgroundImage.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});
const navigation = reactive({
  title: "Passenger Information",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Flight Search Result",
      path: "/sales/flight/flight-list",
    },
    {
      name: "Itinerary Review",
      path: "/sales/flight/flight-itinerary-review",
    },
    {
      name: "Passenger Information",
    },
  ],
});

let flightPassengersFormModelSess = JSON.parse(
  sessionStorage.getItem("flightPassengersFormModel")
);
let passengerTicketFareBreakdownItems = JSON.parse(
  sessionStorage.getItem("passengerTicketFareBreakdownItems")
);

const flightStore = useFlightStore();
flightStore.updatePassengerTicketFareBreakdownItems(
  passengerTicketFareBreakdownItems
);

let totalAmountContent = computed(() => {
  let totalAmount = 0;
  let currency = "";
  let adultTotalAmount = 0;
  let childTotalAmount = 0;
  let infantTotalAmount = 0;
  let adultNum = 0;
  let childNum = 0;
  let infantNum = 0;
  let taxesTotalAmount = 0;
  if (flightStore.passengerTicketFareBreakdownItems) {
    flightStore.passengerTicketFareBreakdownItems.forEach((breakdownItem) => {
      let totalFare = breakdownItem.totalFare;
      totalAmount += totalFare;
      taxesTotalAmount += breakdownItem.taxes;
      currency = breakdownItem.currency;

      if (breakdownItem.passengerTypeCode == "ADT") {
        adultNum++;
        adultTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "CNN") {
        childNum++;
        childTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "INF") {
        infantNum++;
        infantTotalAmount += breakdownItem.baseFare;
      }
    });
  }
  return {
    totalAmount,
    currency,
    adultTotalAmount,
    childTotalAmount,
    infantTotalAmount,
    infantNum,
    adultNum,
    childNum,
    taxesTotalAmount,
  };
});

let flightPassengersFormModel = reactive(flightPassengersFormModelSess);
// (flightPassengersFormModel);
const v$ = useVuelidate();
const passengerFormRef = ref(null);

async function netStep() {
  const result = await v$.value.$validate();
  validatePhoneAndPassport(); //展示错误效果
  if (!result) {
    return;
  }

  sessionStorage.setItem(
    "flightPassengersFormModel",
    JSON.stringify(flightPassengersFormModel)
  );

  router.push({ name: "sales-flight-booking-preview" });
}

function validatePhoneAndPassport() {
  passengerFormRef.value.validatePhoneAndPassport();
}

function goBack() {
  router.push({ name: "sales-flight-itinerary-review" });
}

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <!-- Common Banner Area -->
  <Banner :navigate="navigation" :background-image-url="backgroundImageUrl" />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <h3 class="heading_theme">{{ $t("passenger-information") }}</h3>
      <div class="row">
        <div class="col-lg-8">
          <div class="tou_booking_form_Wrapper">
            <div class="booking_tour_form">
              <div class="tour_booking_form_box">
                <BaseFlightPassengersForm
                  ref="passengerFormRef"
                  :form-model="flightPassengersFormModel"
                />
              </div>
            </div>
            <div
              class="booking_tour_form_submit"
              v-if="equipmentType != 'iphone'"
            >
              <button @click="goBack" class="btn btn_theme">Back</button>
              <button
                @click="netStep"
                class="btn btn_theme"
                style="margin-left: 20px"
              >
                {{ $t("continue") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <BaseFlightPriceBreakdownPanel
            v-if="totalAmountContent"
            :total-amount-content="totalAmountContent"
            :show-button="false"
          />
        </div>
        <div class="col-lg-12 text-center" v-if="equipmentType == 'iphone'">
          <button
            @click="netStep"
            class="btn btn_theme"
            style="margin-top: -10px"
          >
            {{ $t("continue") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

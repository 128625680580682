<script setup>
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useCarRentalRatesStore } from "@/stores/v2/carrental/rates/carRentalRatesStore";
import { ref, watch } from "vue";
import { CarRentalRatesQuoteFormModel } from "@/formmodel/sales/carrental/rates/carRentalRatesQuoteFormModel";
import { CarRentalRatesFormModel } from "@/formmodel/sales/carrental/rates/carRentalRatesFormModel";
import { CarRentalCoverageTypeItemVO } from "@/viewobject/sales/carrental/carRentalCoverageTypeItemVO";
import CarRentalDetailsPanel from "@/views/v2/sales/carrental/items/panel/CarRentalDetailsPanel.vue";
import CarRentalExtrasShoppingCartPanel from "@/views/v2/sales/carrental/items/panel/CarRentalExtrasShoppingCartPanel.vue";
import CarRentalExtrasCategoryPanel from "@/views/v2/sales/carrental/items/panel/CarRentalExtrasCategoryPanel.vue";
import { useCmsContentStore } from "@/stores/cms";
import { Empty } from "ant-design-vue";

const route = useRoute();
const router = useRouter();

const AEmpty = Empty;

const templateStore = useTemplateStore();
const carRentalRatesStore = useCarRentalRatesStore();

const carRentalRatesFormModelRef = ref(null);
const carRentalItemRef = ref(null);
const equipmentOptionsRef = ref(null);
const coverageOptionsRef = ref(null);

const protectionPlansRef = ref(null);
const availableCoveragesRef = ref(null);
const activeProtectionPlanIndexRef = ref(0);
const carRentalRatesQuoteFormModelRef = ref(
  new CarRentalRatesQuoteFormModel(null, null, null, null, null, null, null)
); //用来组合验价

//region query获取
if (route.query != null && Object.keys(route.query).length > 0) {
  const query = route.query;
  carRentalRatesFormModelRef.value = new CarRentalRatesFormModel(
    query.pickupLocation,
    query.pickupLocationName,
    query.returnLocation,
    query.returnLocationName,
    query.pickupDatetime,
    query.returnDatetime,
    query.countryOfResidence,
    query.countryName,
    query.preferredCarType
  );
  availCarRentalRates(carRentalRatesFormModelRef.value);
}
//endregion

//region watch监听pinia
watch(
  () => carRentalRatesStore.carRentalItem,
  (newValue) => {
    carRentalItemRef.value = newValue;
    if (carRentalItemRef.value) {
      carRentalRatesQuoteFormModelRef.value.quoteID =
        carRentalItemRef.value.quoteID;
      carRentalRatesQuoteFormModelRef.value.baseAmount =
        carRentalItemRef.value.origNetPrice;
      carRentalRatesQuoteFormModelRef.value.totalAmount =
        carRentalItemRef.value.totalAmount;
      carRentalRatesQuoteFormModelRef.value.currencyCode =
        carRentalItemRef.value.currencyCode;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => carRentalRatesStore.equipmentOptions,
  (newValue) => {
    if (newValue) {
      equipmentOptionsRef.value = newValue;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => carRentalRatesStore.coverageOptions,
  (newValue) => {
    coverageOptionsRef.value = newValue;
    protectionPlansRef.value = [];
    availableCoveragesRef.value = [];
    //将保险计划分类
    if (coverageOptionsRef.value && coverageOptionsRef.value.length > 0) {
      // BASIC
      const basicCoverage = new CarRentalCoverageTypeItemVO(
        true,
        true,
        false,
        "BASIC",
        "",
        "",
        0,
        null,
        null
      );
      const mediumCoverage = new CarRentalCoverageTypeItemVO(
        false,
        false,
        false,
        "MEDIUM",
        null,
        null,
        0,
        null,
        null
      );
      const premiumCoverage = new CarRentalCoverageTypeItemVO(
        false,
        false,
        false,
        "PREMIUM",
        null,
        null,
        0,
        null,
        null
      );

      coverageOptionsRef.value.forEach((insurance) => {
        const currency = insurance.currency;
        const amount = insurance.amount;
        const code = insurance.code;
        const description = insurance.description;
        if (code === "MEDIUM") {
          mediumCoverage.totalAmount += amount;
          mediumCoverage.currency = currency;
          mediumCoverage.code = code;
          mediumCoverage.description = description;
          mediumCoverage.addInsuranceItem(insurance);
        } else if (code === "PREMIUM") {
          premiumCoverage.totalAmount += amount;
          premiumCoverage.currency = currency;
          premiumCoverage.code = code;
          premiumCoverage.description = description;
          premiumCoverage.addInsuranceItem(insurance);
        } else if (
          insurance.included &&
          insurance.selected &&
          !insurance.mandatory
        ) {
          // 包含 已选择 非强制
          basicCoverage.totalAmount += amount;
          basicCoverage.currency = currency;
          basicCoverage.addInsuranceItem(insurance);
        } else {
          //其余可购买的保险项目
          availableCoveragesRef.value.push(insurance);
        }
      });

      protectionPlansRef.value.push(
        basicCoverage,
        mediumCoverage,
        premiumCoverage
      );
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => carRentalRatesQuoteFormModelRef.value,
  (newValue) => {
    if (newValue) {
      //更新一下列表显示操作的设备数量
      const selectedEquipments = newValue.selectedEquipments;
      if (equipmentOptionsRef.value && equipmentOptionsRef.value.length > 0) {
        equipmentOptionsRef.value.forEach((equipment) => {
          let containItem = null;

          if (selectedEquipments && selectedEquipments.length > 0) {
            containItem = selectedEquipments.find(
              (item) => item.code === equipment.code
            );
            if (containItem) {
              equipment.quantity = containItem.quantity;
            } else {
              equipment.quantity = 0;
            }
          } else {
            equipment.quantity = 0;
          }
        });
      }
    }
  },
  { immediate: true, deep: true }
);

//endregion

async function availCarRentalRates(carRentalRatesFormModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await carRentalRatesStore.carRentalRates(carRentalRatesFormModel);
  templateStore.pageLoader({ mode: "off" });
}

function goToCarRentalList() {
  // const query = JSON.parse(sessionStorage.getItem("carRentalAvailFormModel"));
  // router.push({ name: "sales-carrental-list-v2", query: query });
  window.close();
}

function selectProtectionPlan(
  carRentalRatesQuoteFormModel,
  protectionPlan,
  protectionPlanIndex
) {
  activeProtectionPlanIndexRef.value = protectionPlanIndex;

  carRentalRatesQuoteFormModel.addNewProtectionPlan(protectionPlan);
}

function selectAvailableCoverage(carRentalRatesQuoteFormModel, insurance) {
  carRentalRatesQuoteFormModel.addNewInsurance(insurance);
}

function operateEquipment(carRentalRatesQuoteFormModel, equipment, type) {
  if (type === "ADD") {
    carRentalRatesQuoteFormModel.addNewEquipment(equipment);
  } else {
    if (
      carRentalRatesQuoteFormModel.selectedEquipments &&
      carRentalRatesQuoteFormModel.selectedEquipments.length > 0
    ) {
      const selectedEquipment =
        carRentalRatesQuoteFormModel.selectedEquipments.find(
          (item) => item.code === equipment.code
        );
      if (selectedEquipment.quantity > 0) selectedEquipment.quantity--;
    }
  }
}

function sortByPrice(equipments) {
  return equipments.sort((a, b) => a.amount - b.amount);
}

function isCoverageDisable(carRentalRatesQuoteFormModel, insurance) {
  const selectedInsurances = carRentalRatesQuoteFormModel.selectedInsurances;
  let disabled = false;
  if (selectedInsurances && selectedInsurances.length > 0) {
    disabled = selectedInsurances.some((item) => item.code === insurance.code);
  }

  return disabled;
}

function confirmQuoteRates(carRentalRatesQuoteFormModel) {
  //带到check out页面去验价 - 目前看只能单独选设备或者保险去验价
  let queryModel = appendPageQuery(route.query, carRentalRatesQuoteFormModel);
  router.push({ name: "sales-carrental-check-out-v2", query: queryModel });
}

function appendPageQuery(query, carRentalRatesQuoteFormModel) {
  const queryCopy = JSON.parse(JSON.stringify(query));
  queryCopy.quoteID = carRentalRatesQuoteFormModel.quoteID;
  queryCopy.protectionPlanFormat = formatProtectionPlan(
    carRentalRatesQuoteFormModel.protectionPlan
  );
  queryCopy.selectedInsurancesFormat = formatSimpleArray(
    carRentalRatesQuoteFormModel.selectedInsurances
  );
  queryCopy.selectedEquipmentsFormat = formatSimpleArray(
    carRentalRatesQuoteFormModel.selectedEquipments
  );
  return queryCopy;
}

function formatProtectionPlan(protectionPlan) {
  let format = "";
  for (const key in protectionPlan) {
    if (key !== "insuranceItems") {
      const value = protectionPlan[key];
      const keyValue = key + "==" + value;
      format += keyValue + "::";
    } else {
      const insuranceItemsFormat = formatSimpleArray(
        protectionPlan.insuranceItems
      );
      format += "insuranceItemsFormat==" + insuranceItemsFormat;
    }
  }

  return format;
}

function formatSimpleArray(array) {
  let format = "";
  if (array == null || array.length === 0) return format;

  for (const ele of array) {
    //遍历数组中的每一项
    if (!ele || (typeof ele === "object" && Object.keys(ele) === 0)) {
      continue;
    }

    if (typeof ele === "string") {
      format += ele; //如果是字符串数组则直接与元素分隔符拼接
    } else {
      for (const key of Object.keys(ele)) {
        if (!key) continue;
        const value = ele[key] ? ele[key] : "";
        if (!value) continue;
        if (format !== "") format += "@"; //变量分隔符
        format += key + "=" + value;
      }
    }
    format += "||"; //元素分隔符
  }
  return format;
}

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.CarRentalBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.CarRentalBackgroundImage
            .url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>Protection & Extra</h2>
        <ul>
          <li>
            <i class="fas fa-circle"></i>
            Protection & Extra
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_details_main" class="section_padding">
    <div class="container hotel_container" style="margin-left: 350px">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goToCarRentalList">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <div
            v-if="
              availableCoveragesRef && equipmentOptionsRef && protectionPlansRef
            "
          >
            <CarRentalExtrasCategoryPanel
              :available-coverages="availableCoveragesRef"
              :car-rental-rates-quote-form-model="
                carRentalRatesQuoteFormModelRef
              "
              :equipment-options="equipmentOptionsRef"
              :protection-plans="protectionPlansRef"
            />
          </div>

          <div v-else>
            <AEmpty></AEmpty>
          </div>
        </div>
        <div class="col-5">
          <CarRentalDetailsPanel :car-rental-item="carRentalItemRef" />
          <CarRentalExtrasShoppingCartPanel
            :available-coverages="availableCoveragesRef"
            :car-rental-rates-quote-form-model="carRentalRatesQuoteFormModelRef"
            @confirmQuoteRates="confirmQuoteRates"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

<template>

    <!-- Common Banner Area -->
    <Banner />

    <!-- News Area -->
    <NewsArea />

    <!-- Cta Area -->
    <Cta />

</template>
<script>
import Banner from '@/components/templates/news/Banner.vue'
import NewsArea from '@/components/templates/news/NewsArea.vue'
import Cta from '@/components/templates/home/Cta.vue'


export default {
    name: "NewsView",
    components: {
        Banner, NewsArea, Cta
    }
};
</script>

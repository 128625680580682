import CustomerProfilesView from "@/views/profiles/CustomerProfilesView.vue";
export default [
  {
    path: "/profiles",
    redirect: "/profiles/customer-dashboard",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "customer-dashboard",
        name: "profiles-customer-dashboard",
        component: CustomerProfilesView,
        meta: { requiresAuth: true,footerShow:true },
      },
    ],
  },
];

import Dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

Dayjs.extend(utc);
Dayjs.extend(tz);
Dayjs.tz.setDefault("Asia/Shanghai");

/*
 * 格式化日期，格式为yyyy-MM-dd
 * @param date object 必填，Date日期对象
 */

function format(date) {
  let fullYear = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  let dayStr = "";
  if (day < 10) {
    dayStr = "0" + day;
  } else {
    dayStr = day;
  }

  let monStr = "";
  if (month < 10) {
    monStr = "0" + month;
  } else {
    monStr = month;
  }

  let dateStr = fullYear + "-" + monStr + "-" + dayStr;
  return dateStr;
}

/*
 * 格式化日期，格式为yyyy-MM-dd
 * @param date object 必填，Date日期对象
 */

function formatDate(date) {
  let fullYear = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  let dayStr = "";
  if (day < 10) {
    dayStr = "0" + day;
  } else {
    dayStr = day;
  }

  let monStr = "";
  if (month < 10) {
    monStr = "0" + month;
  } else {
    monStr = month;
  }

  return fullYear + "-" + monStr + "-" + dayStr;
}

/**
 * 返回2002-03-12 00:00格式
 * @param date
 * @returns {string}
 */
function formatDateTime(date) {
  if (date) {
    const dateStr = date.split("T")[0];
    const timeStr = date.split("T")[1];
    let year = dateStr.split("-")[0];
    let month = dateStr.split("-")[1];
    let day = dateStr.split("-")[2];

    let time = timeStr.split(":")[0] + ":" + timeStr.split(":")[1];

    return year + "-" + month + "-" + day + " " + time;
  }
}

/**
 * 北京是getZoneTime(8),纽约是getZoneTime(-5),班加罗尔是getZoneTime(5.5). 偏移值是本时区相对于格林尼治所在时区的时区差值
 * @param date
 * @returns {string}
 */
function getZoneTime(date) {
  if (date) {
    // 将Date()对象转成YYYY-MM-DD HH:MM:SS格式
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth();
    const day =
      date.getDate() + 1 < 10 ? `0${date.getDate() + 1}` : date.getDate();
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minute =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const second =
      date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
  return "";
}

/*
 * 根据前一天的日期，获取默认日期，默认日期为传入日期的第二天
 *
 * @param date object 可选，指定日期，若日期为空，则默认取当天
 * @param offset number 可选,指定日期后相隔天数，若offset为空，则默认取1
 *
 * @return string 格式化日期，例如，2023-05-12
 */
function getOffsetDate(date, offset) {
  date = date == null ? new Date() : date;
  let offsetDate = new Date(date);
  offsetDate.setDate(offsetDate.getDate() + offset);
  return formatDate(offsetDate);
}

/**
 *
 * @param startDateTime 2024-12-21T23:59
 * @param offset   number
 * @param offsetType DAYS/HOURS
 * @returns {string}
 */
function getOffsetDateTime(startDateTime, offset, offsetType) {
  if (startDateTime) {
    if (offsetType === "DAYS") {
      const time = startDateTime ? startDateTime.split("T")[1] : "";
      return getOffsetDate(startDateTime, offset) + " " + time;
    } else if (offsetType === "HOURS") {
      const time = startDateTime ? startDateTime.split("T")[1] : "";
      return getOffsetHour(startDateTime, offset) + " " + time;
    } else if (offsetType === "MINUTES") {
      const time = startDateTime ? startDateTime.split("T")[1] : "";
      return getOffsetMinute(startDateTime, offset) + " " + time;
    } else {
      //其他情况,暂时没有处理
      return "";
    }
  } else {
    return "";
  }
}

/*
 * 计算两个日期之间相隔的年数
 * @param startDate object 必填，为Date对象
 * @param endDate object 必填，为Date对象
 *
 * @return number 年数
 */
function getYearsByDateRange(startDate, endDate) {
  let startYear = startDate.getFullYear();
  let startMonth = startDate.getMonth();

  let endYear = endDate.getFullYear();
  let endMonth = endDate.getMonth();

  let yearsDec = endYear - startYear;
  if (endMonth > startMonth) yearsDec--;
  return yearsDec;
}

/*
 * 计算两个日期之间相隔的天数
 * @param startDate object 必填，为Date对象
 * @param endDate object 必填，为Date对象
 *
 * @return number 天数
 */
function getDaysByDateRange(startDate, endDate) {
  return parseInt(
    Math.abs(new Date(endDate) - new Date(startDate)) / 1000 / 60 / 60 / 24
  );
}

/***
 * 计算当前日期是周几
 */

function getWeek(date) {
  let datelist = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return datelist[new Date(date).getDay()];
}

/****
 * 比较时间的早晚，baseDateStr is if before than compareDateStr
 */
function compareIfBefore(baseDateStr, compareDateStr) {
  const baseDate = new Date(baseDateStr);
  const compareDate = new Date(compareDateStr);
  return baseDate.getTime() <= compareDate.getTime() ? true : false;
}

// 将分钟数量转换为小时和分钟字符串
function formatHourMinute(minutes) {
  // //console.info(minutes);
  let time = {
    hour:
      Math.floor(minutes / 60) < 10 && Math.floor(minutes / 60) > 0
        ? "0" + Math.floor(minutes / 60)
        : Math.floor(minutes / 60),
    minute:
      minutes % 60 < 10 && minutes % 60 > 0
        ? "0" + (minutes % 60)
        : minutes % 60,
  };
  return time.hour + "h " + time.minute + "m";
}

function formatAmOrPm(time) {
  let type = Number(time.split(":")[0]) < 12 ? "AM" : "PM";
  return time + " " + type;
}

function getTrainDuration(arrivalDateTime, departureDateTime) {
  let diff = getDuration(arrivalDateTime, departureDateTime, "minute");
  let hourStr = Math.floor(diff / 60) + "h";
  let minuteStr = (diff % 60) + "m";
  return hourStr + " " + minuteStr;
}

//不超过24小时不算一天
function getDuration(dateTimeAfter, dateTime, type) {
  let diff;
  if (dateTimeAfter == "") {
    diff = Dayjs().diff(dateTime, type);
  } else if (dateTime == "") {
    diff = Dayjs(dateTimeAfter).diff(Dayjs(), type);
  } else {
    diff = Dayjs(dateTimeAfter).diff(dateTime, type);
  }

  return diff;
}

function getDiffDays(dateTimeAfter, dateTime) {
  let diff = Dayjs(Dayjs(dateTimeAfter).format("YYYY-MM-DD")).diff(
    Dayjs(dateTime).format("YYYY-MM-DD"),
    "day"
  );
  return diff;
}

function calcDurationInMinsWithTimeZone(startDateTime, endDateTime) {
  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);
  const number = Dayjs(endDate).diff(startDate, "minute");
  return number;
}

//美式英语的时间格式
function formatEnglishDate(date) {
  if (date) {
    const dateStr = date.split("T")[0];
    const timeStr = date.split("T")[1];

    let monthArr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    // let suffix = ["st", "nd", "rd", "th"];

    let year = dateStr.split("-")[0];
    let month = monthArr[dateStr.split("-")[1] - 1];
    let day = dateStr.split("-")[2];

    let time = timeStr
      ? timeStr.split(":")[0] + ":" + timeStr.split(":")[1]
      : null;

    // let time = date.toLocaleTimeString("chinese", { hour12: false }).slice(0, 5);

    // if (ddate % 10 < 1 || ddate % 10 > 3) {
    //   ddate = ddate + suffix[3];
    // } else if (ddate % 10 == 1) {
    //   ddate = ddate + suffix[0];
    // } else if (ddate % 10 == 2) {
    //   ddate = ddate + suffix[1];
    // } else {
    //   ddate = ddate + suffix[2];
    // }
    return { time: time, ddate: day, month: month, year: year };
  }
}

//获取当地时间
function formatLocalTime(dateTime) {
  let dateTimeStr = dateTime;
  if (dateTimeStr) {
    let timeZone = "";
    let timeSplit = dateTimeStr.split("T")[1];
    if (dateTimeStr.indexOf("Z") >= 0) {
      timeZone = "+00:00";
    } else {
      timeZone = timeSplit.substring(12);
    }

    let time = timeSplit.substring(0, 5);

    return {
      time: time,
      timeZone: timeZone,
    };
  }
}

//计算时间间隔，并用小时分展示
function TimeDecrement(timeOne, timeTwo) {
  timeOne = new Date(timeOne);
  timeTwo = new Date(timeTwo);
  let time = null;
  if (timeTwo > timeOne) {
    time = parseInt(timeTwo - timeOne) / 1000 / 60;
  } else {
    time = parseInt(timeOne - timeTwo) / 1000 / 60;
  }
  return formatHourMinute(time);
}

/**
 * 获取n小时之后的时间
 * @param start
 * @param offset
 * @returns {string}
 */
function getOffsetHour(start, offset) {
  let date = (start = new Date(start));
  let hour = date.getHours();
  date.setHours(hour + offset);
  let y = date.getFullYear();
  let m =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let f = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  return y + "-" + m + "-" + d + " " + h + ":" + f;
}

/**
 * 获取n分钟之后的时间
 * @param start
 * @param offset
 * @returns {string}
 */
function getOffsetMinute(start, offset) {
  let date = (start = new Date(start));
  let minute = date.getMinutes();
  date.setMinutes(minute + offset);
  let y = date.getFullYear();
  let m =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  let f = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  return y + "-" + m + "-" + d + " " + h + ":" + f;
}

function GMTToStr(time) {
  let date = new Date(time);

  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;

  const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  const hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  return year + "-" + month + "-" + day + "T" + hour + ":" + minutes;
}

export default {
  format,
  formatDate,
  getOffsetDate,
  getYearsByDateRange,
  getDaysByDateRange,
  getWeek,
  compareIfBefore,
  TimeDecrement,
  formatHourMinute,
  formatDateTime,
  formatAmOrPm,
  getTrainDuration,
  getDuration,
  getDiffDays,
  getZoneTime,
  calcDurationInMinsWithTimeZone,
  formatEnglishDate,
  formatLocalTime,
  getOffsetHour,
  getOffsetMinute,
  getOffsetDateTime,
  GMTToStr,
};

import axios from "@/utils/axiosUtils";

function findPrefs(partyId) {
  let url = "/api/v3/parties/" + partyId + "/travelpref/airline/prefs";
  return axios.get(url);
}

function findMealprefs(partyId) {
  let url = "/api/v3/parties/" + partyId + "/travelpref/airline/mealprefs";
  return axios.get(url);
}

function findMealpref(partyId, mealPrefId) {
  let url =
    "/api/v3/parties/" +
    partyId +
    "/travelpref/airline/mealprefs/" +
    mealPrefId;
  return axios.get(url);
}

function findSeatprefs(partyId) {
  let url = "/api/v3/parties/" + partyId + "/travelpref/airline/seatprefs";
  return axios.get(url);
}

function findSeatpref(partyId, seatPrefId) {
  let url =
    "/api/v3/parties/" +
    partyId +
    "/travelpref/airline/seatprefs/" +
    seatPrefId;
  return axios.get(url);
}

function addMealpref(rq, partyId) {
  let url = "/api/v3/parties/" + partyId + "/travelpref/airline/mealprefs";
  return axios.post(url, rq, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function addSeatpref(rq, partyId) {
  let url = "/api/v3/parties/" + partyId + "/travelpref/airline/seatprefs";
  return axios.post(url, rq, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function modifyMealpref(rq, partyId, mealPrefId) {
  let url =
    "/api/v3/parties/" +
    partyId +
    "/travelpref/airline/mealprefs/" +
    mealPrefId;
  return axios.put(url, rq, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function modifySeatpref(rq, partyId, seatPrefId) {
  let url =
    "/api/v3/parties/" +
    partyId +
    "/travelpref/airline/seatprefs/" +
    seatPrefId;
  return axios.put(url, rq, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function deleteMealpref(partyId, mealPrefId) {
  let url =
    "/api/v3/parties/" +
    partyId +
    "/travelpref/airline/mealprefs/" +
    mealPrefId;
  return axios.delete(url);
}

function deleteSeatpref(partyId, seatPrefId) {
  let url =
    "/api/v3/parties/" +
    partyId +
    "/travelpref/airline/seatprefs/" +
    seatPrefId;
  return axios.delete(url);
}

export default {
  findPrefs,
  findMealprefs,
  findMealpref,
  findSeatprefs,
  findSeatpref,
  addMealpref,
  addSeatpref,
  modifyMealpref,
  modifySeatpref,
  deleteMealpref,
  deleteSeatpref,
};

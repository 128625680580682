<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import * as regexUtils from "@/utils/regexUtils";
import { email, integer, required } from "@vuelidate/validators";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import contactTools from "@/tools/contactTools";
import contactApi from "@/apis/contactApi";

defineProps({
  contactInfo: {},
});
//region form表单
let contactFormModel = reactive({});
// Validation rules
const nameRule = (value) =>
  regexUtils.nameRegx.test(value) && regexUtils.startEndSpaceRegx.test(value);
const rules = computed(() => {
  return {
    givenName: { required, nameRule },
    surname: { required, nameRule },
    emailAddress: { required, email },
    phoneNumber: { required, integer },
    message: { required },
  };
});
const v$ = useVuelidate(rules, contactFormModel);

//电话号码
function phoneNumberInput(value, event) {
  if (value && typeof value !== "object") {
    contactFormModel.phoneNumber = value;
    validatePhoneNumber();
  }
}

function phoneBlur() {
  validatePhoneNumber();
}

function validatePhoneNumber() {
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
}

function countryChange(value) {
  contactFormModel.countryAccessCode = value.dialCode;
}

onMounted(() => {
  let phoneEle = document.getElementById("phoneEle");
  if (phoneEle) {
    phoneEle.style.border = "none";
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("form-control");
        childListElement.classList.add("bg_input");
      }
    }
  }
});

const validatePhoneNumberExpose = () => {
  if (!v$.value.phoneNumber) return;
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
};

let messageShow = ref(false);
let message = ref("");

async function sendMessage() {
  const result = await v$.value.$validate();
  validatePhoneNumberExpose();
  const contactFormModelCopy = JSON.parse(JSON.stringify(contactFormModel));
  const name =
    contactFormModelCopy.surname + "/" + contactFormModelCopy.givenName;
  const telephoneNumber =
    "+" +
    contactFormModelCopy.countryAccessCode +
    contactFormModelCopy.phoneNumber;

  const contactRQ = contactTools.getContactRQ(
    name,
    contactFormModelCopy.emailAddress,
    telephoneNumber,
    contactFormModelCopy.emailAddress
  );
  if (!result) {
    return;
  }
  contactApi.contact(contactRQ).then((res) => {
    const { data } = res;

    if (data.success) {
      message.value = data.comment.stringValue;
    } else {
      message.value = "Sorry! Failed to send message";
    }
    messageShow.value = true;
  });
}

//endregion
let active = ref(false);

function modalToggle() {
  const body = document.querySelector("body");
  active.value = !active.value;
  active.value
    ? body.classList.add("modal-open")
    : body.classList.remove("modal-open");
}
</script>
<template>
  <section id="contact_main_arae" class="section_padding">
    <div class="container">
      <div class="section_heading_center">
        <h2>{{ $t("leave-us-a-message") }}</h2>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="$router.back">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-if="
          contactInfo && contactInfo.offices && contactInfo.offices.length > 0
        "
      >
        <div
          class="col-lg-3 col-md-6 col-sm-12 col-12"
          v-for="(offer, index) in contactInfo.offices"
          :key="index"
        >
          <!--此处设置宽高是为了隐藏View on map后统一大小-->
          <div class="contact_boxed" style="width: 330px; height: 214px">
            <h6>{{ offer.officeName }}</h6>
            <h3>{{ offer.locationName }}</h3>
            <p>{{ offer.address }}</p>
            <a
              href="#"
              @click="modalToggle"
              v-if="offer.latitude && offer.longitude"
              >{{ $t("view-on-map") }}</a
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="contact_main_form_area">
            <div class="contact_form">
              <form action="!#" id="contact_form_content">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        :class="{
                          'is-invalid': v$.surname.$errors.length > 0,
                        }"
                        class="form-control bg_input"
                        placeholder="Last name*"
                        v-model="v$.surname.$model"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        :class="{
                          'is-invalid': v$.givenName.$errors.length > 0,
                        }"
                        v-model="v$.givenName.$model"
                        type="text"
                        class="form-control bg_input"
                        placeholder="First name*"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        :class="{
                          'is-invalid': v$.emailAddress.$errors.length > 0,
                        }"
                        class="form-control bg_input"
                        placeholder="Email address*"
                        v-model="v$.emailAddress.$model"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <VueTelInput
                        style="height: 54px"
                        mode="international"
                        :class="{
                          'is-invalid': v$.phoneNumber.$errors.length > 0,
                        }"
                        id="phoneEle"
                        autocomplete="off"
                        :autoFormat="false"
                        :inputOptions="{
                          placeholder: 'Phone number*',
                        }"
                        @input="phoneNumberInput"
                        @blur="phoneBlur"
                        @country-changed="countryChange"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <textarea
                        class="form-control bg_input"
                        rows="5"
                        placeholder="Message*"
                        :class="{
                          'is-invalid': v$.message.$errors.length > 0,
                        }"
                        v-model="contactFormModel.message"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-lg-12" v-if="messageShow">
                    <div class="form-group text-center">
                      <div
                        class="alert alert-success"
                        role="alert"
                        v-if="message"
                        style="display: flex; justify-content: space-between"
                      >
                        <div>{{ message }}</div>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div
                        class="alert alert-danger"
                        role="alert"
                        v-else
                        style="display: flex; justify-content: space-between"
                      >
                        <div>{{ message }}</div>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group text-center">
                      <button
                        type="button"
                        class="btn btn_theme"
                        @click="sendMessage"
                      >
                        {{ $t("send-message") }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-4" v-if="contactInfo">
          <div class="contact_info">
            <div class="contact_detais_item" v-if="contactInfo.phoneNumber">
              <h4>Help line</h4>
              <h5>
                <a
                  :href="'tel:' + contactInfo.phoneNumber"
                  class="main_color"
                  >{{ contactInfo.phoneNumber }}</a
                >
              </h5>
            </div>
            <div class="contact_detais_item" v-if="contactInfo.emailAddress">
              <h4>Support mail</h4>
              <h5>
                <a :href="contactInfo.emailAddress" class="main_color">{{
                  contactInfo.emailAddress
                }}</a>
              </h5>
            </div>
            <div class="contact_detais_item" v-if="contactInfo.facebookLink">
              <h4>Facebook</h4>
              <h5>
                <a :href="contactInfo.facebookLink" class="main_color">{{
                  contactInfo.facebookLink
                }}</a>
              </h5>
            </div>
            <div class="contact_detais_item" v-if="contactInfo.instagramLink">
              <h4>Instagram</h4>
              <h5>
                <a :href="contactInfo.instagramLink" class="main_color">{{
                  contactInfo.instagramLink
                }}</a>
              </h5>
            </div>
            <div class="contact_detais_item" v-if="contactInfo.address">
              <h4>Address</h4>
              <h6>{{ contactInfo.address }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

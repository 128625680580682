import axios from "@/utils/axiosUtils.js";

function locations(keyword, language) {
  language = language == null ? "en" : language;
  let url = "/api/v3/content/air/airport-cities";
  return axios.get(url, {
    params: {
      language: language,
      keyword: keyword,
      type: "http",
    },
  });
}

function availActivity(activityRQ) {
  let url = "/api/v3/sales/shopping/touactivity/tour-activity-search";
  return axios.post(url, activityRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function activityDetails(activityRQ) {
  let url = "/api/v3/sales/shopping/touactivity/tour-activity-avail";
  return axios.post(url, activityRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function activityBook(activityRQ) {
  let url = "/api/v3/sales/shopping/touactivity/tour-activity-book";
  return axios.post(url, activityRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  locations,
  availActivity,
  activityDetails,
  activityBook,
};

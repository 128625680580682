<script setup>
function mouseover(id) {
  let element = document.getElementById(id);
  let contain = element.classList.contains("active");
  if (contain) return;

  element.classList.add("hover");
}

function mouseout(id) {
  let element = document.getElementById(id);
  let contain = element.classList.contains("active");
  if (contain) return;

  element.classList.remove("hover");
}

function chooseTicketType(id) {
  let elements = document.getElementsByClassName("flight_ticket_content");
  for (let i = 0; i < elements.length; i++) {
    let element = elements[i];
    element.classList.remove("active");
    element.classList.remove("hover");
  }

  let element = document.getElementById(id);
  element.classList.add("active");
}
</script>

<template>
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="tou_booking_form_Wrapper">
            <div class="booking_tour_form">
              <h3 class="heading_theme">Choose Ticket Type</h3>
              <div class="airline-details">
                <div class="img">
                  <img src="@/assets/img/icon/bg.png" alt="img" />
                </div>
                <span class="airlineName fw-500"
                  >Biman Bangladesh Airlines 123 &nbsp; BG435</span
                >
                <span class="flightNumber">BOEING 737-800 - 738</span>
              </div>
              <div class="flight_inner_show_component">
                <div class="flight_det_wrapper">
                  <div class="flight_det">
                    <div class="code_time">
                      <span class="code">DAC</span>
                      <span class="time">15:00</span>
                    </div>
                    <p class="airport">
                      Hazrat Shahjalal International Airport
                    </p>
                    <p class="date">7th Jun 2022</p>
                  </div>
                </div>
                <div class="flight_duration">
                  <div class="arrow_right"></div>
                  <span>01h 15m</span>
                </div>
                <div class="flight_det_wrapper">
                  <div class="flight_det">
                    <div class="code_time">
                      <span class="code">DAC</span>
                      <span class="time">15:00</span>
                    </div>
                    <p class="airport">
                      Hazrat Shahjalal International Airport
                    </p>
                    <p class="date">7th Jun 2022</p>
                  </div>
                </div>
              </div>
              <div class="row flight_ticket_list">
                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="theme_common_box_two img_hover">
                    <div
                      class="flight_ticket_content active"
                      @mouseover="mouseover('flight_type_1')"
                      @mouseout="mouseout('flight_type_1')"
                      @click="chooseTicketType('flight_type_1')"
                      id="flight_type_1"
                    >
                      <h3>HKD 4000.00</h3>
                      <h3>Economy Flex</h3>
                      <h3><span>Cabin: Economy</span></h3>
                      <h4>Bags</h4>
                      <ul>
                        <li>
                          <p>
                            <span class="review_count"
                              >* Carry-on bags included*</span
                            >
                          </p>
                        </li>
                        <li>
                          <p>
                            <span class="review_count"
                              >* 1st checked bag included**</span
                            >
                          </p>
                        </li>
                        <li>** Hand baggage included for up to 7 kg</li>
                        <li>** Checked bag included up to 30 kg</li>
                      </ul>
                      <h4>Flexibility</h4>
                      <ul>
                        <li>
                          <p>
                            <span class="review_count"
                              >Cancellation not allowed</span
                            >
                          </p>
                        </li>
                        <li>
                          <p>
                            <span class="review_count">No change fees</span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="theme_common_box_two img_hover">
                    <div
                      class="flight_ticket_content"
                      @mouseenter="mouseover('flight_type_2')"
                      @mouseleave="mouseout('flight_type_2')"
                      @click="chooseTicketType('flight_type_2')"
                      id="flight_type_2"
                    >
                      <h3>HKD 8000.00</h3>
                      <h3>Freedom International</h3>
                      <h3><span>Cabin: Economy</span></h3>
                      <h4>Bags</h4>
                      <ul>
                        <li>
                          <p>
                            <span class="review_count"
                              >* Carry-on bags included*</span
                            >
                          </p>
                        </li>
                        <li>
                          <p>
                            <span class="review_count"
                              >* 1st checked bag included**</span
                            >
                          </p>
                        </li>
                        <li>** Hand baggage included for up to 7 kg</li>
                        <li>** Checked bag included up to 30 kg</li>
                      </ul>
                      <h4>Flexibility</h4>
                      <ul>
                        <li>
                          <p>
                            <span class="review_count"
                              >Cancellation not allowed</span
                            >
                          </p>
                        </li>
                        <li>
                          <p>
                            <span class="review_count">Change fee applies</span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="booking_tour_form_submit">
              <router-link to="/sales/flight/flight-list" class="btn btn_theme"
                >Go back
              </router-link>
              <router-link
                to="/sales/flight/flight-passengers"
                class="btn btn_theme"
                >Continue
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="tour_details_right_sidebar_wrapper">
            <div class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>Flights</h3>
                </div>
                <div class="flight_sidebar_right">
                  <div class="flight_search_left_sidebar">
                    <div class="flight_search_destination_sidebar">
                      <p>From</p>
                      <h3>New York</h3>
                      <h6>JFK - John F. Kennedy International...</h6>
                    </div>
                  </div>
                  <div class="flight_search_middel_sidebar">
                    <div class="flight_right_arrow_sidebar">
                      <img
                        src="../../../assets/img/icon/right_arrow.png"
                        alt="icon"
                      />
                      <h6>Non-stop</h6>
                      <p>01h 05minute</p>
                    </div>
                    <div class="flight_search_destination_sidebar">
                      <p>To</p>
                      <h3>London</h3>
                      <h6>LCY, London city airport</h6>
                    </div>
                  </div>
                </div>
                <div class="tour_package_details_bar_list">
                  <h5>Ticket Type</h5>
                  <p>Economy Flex</p>
                </div>
              </div>
            </div>
            <div class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>{{ $t("booking-amount") }}</h3>
                </div>

                <div class="tour_booking_amount_area">
                  <ul>
                    <li>Adult Price x 1 <span>$40,000.00</span></li>
                    <li>Discount <span>-10%</span></li>
                    <li>Tax<span>5%</span></li>
                  </ul>
                  <div class="tour_bokking_subtotal_area">
                    <h6>Subtotal <span>$38,000.00</span></h6>
                  </div>
                  <div class="coupon_add_area">
                    <h6>
                      <span class="remove_coupon_tour">Remove</span> Coupon code
                      (OFF 5000)
                      <span>$5,000.00</span>
                    </h6>
                  </div>
                  <div class="total_subtotal_booking">
                    <h6>Total Amount <span>$33,000.00</span></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

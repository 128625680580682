<script setup>
defineProps({
  aboutInfos: {},
});
const baseUrl = "/api/v3/content/collections/images";
</script>

<template>
  <section id="about_us_top" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="$router.back">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row" v-if="aboutInfos">
        <div class="col-lg-6">
          <div class="about_us_left">
            <h2>{{ aboutInfos.title }}</h2>
            <p>{{ aboutInfos.description }}</p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about_us_right" v-if="aboutInfos.image">
            <img :src="baseUrl + aboutInfos.image.url" alt="img" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>



export class FlightShoppingFormModel {
  /**
   *
   * @param originDestinations Array，FlightPassengerFormItem数组，长度不能超过6
   * @param passengers Array，FlightPassengerFormItem数组，人数最多为9人
   * @param loyaltyAccounts Array, FlightLoyaltyAccountFormItem对象数组，长飞计划码数组
   * @param bestPricingOption string，最优价格识别码，例如，在SQ系统中，CHJ-返回最优价格, UPS-返回全部价格；而在IB系统中，有Y，C，N
   * @param contentChannel string，结果内容类型，如ALL-全部返回，NDC-仅返回NDC内容，GDS-仅返回GDS内容
   * @param corporateCodes Array，字符串数组，公司代码数组，格式为代码登记航司/公司代码，例如，SQ/123456
   * @param contractID string，合同代码,格式为代码登记航司/合同代码，例如，AF/123456
   * @param fareBasisCodes Array，字符串,票价基础代码
   * @param fareTypePrefs Array，FlightPrefsFormItem对象数组，票价类型偏好数组，进行服务关联搜索
   * @param promotionCode string，优惠码,格式为优惠码归属航司/优惠码，例如SQ/123456
   * @param servicePrefs Array，FlightPrefsFormItem对象数组，服务偏好数组，代码为IATA Taxonomy Code，进行服务关联搜索
   * @param vendorPrefs Array，FlightPrefsFormItem对象数组，服务偏好数组
   */
  constructor(
    originDestinations,
    passengers,
    loyaltyAccounts,
    bestPricingOption,
    contentChannel,
    corporateCodes,
    contractID,
    fareBasisCodes,
    fareTypePrefs,
    promotionCode,
    servicePrefs,
    vendorPrefs
  ) {
    this.originDestinations = originDestinations;
    this.passengers = passengers;
    this.loyaltyAccounts = loyaltyAccounts;
    this.bestPricingOption = bestPricingOption;
    this.corporateCodes = corporateCodes;
    this.contentChannel = contentChannel;
    this.contractID = contractID;
    this.fareBasisCodes = fareBasisCodes;
    this.fareTypePrefs = fareTypePrefs;
    this.promotionCode = promotionCode;
    this.servicePrefs = servicePrefs;
    this.vendorPrefs = vendorPrefs;

    if (passengers == null || passengers.length === 0) {
      this.initiateBasePassengers();
    }
  }

  initiateBasePassengers() {
    this.addNewPassenger("ADT", 1);
    this.addNewPassenger("CNN", 0);
    this.addNewPassenger("INF", 0);
  }

  addNewOriginDestination(
    refNumber,
    originLocationCode,
    originLocationName,
    destinationLocationCode,
    destinationLocationName,
    equipPrefs,
    cabinTypePrefs,
    airlinePrefs,
    departureDate,
    departureTime,
    connections,
    stops,
    departureTimeWindowBefore,
    departureTimeWindowAfter,
    daysBeforeDeparture,
    daysAfterDeparture,
    arrivalDate,
    arrivalTime
  ) {
    let originDestinationFormItem = new FlightOriginDestinationFormItem(
      refNumber,
      originLocationCode,
      originLocationName,
      destinationLocationCode,
      destinationLocationName,
      equipPrefs,
      cabinTypePrefs,
      airlinePrefs,
      departureDate,
      departureTime,
      connections,
      stops,
      departureTimeWindowBefore,
      departureTimeWindowAfter,
      daysBeforeDeparture,
      daysAfterDeparture,
      arrivalDate,
      arrivalTime
    );
    if (this.originDestinations == null) this.originDestinations = [];
    this.originDestinations.push(originDestinationFormItem);

    return originDestinationFormItem;
  }

  removeOriginDestination(originDestinationIndex) {
    if (this.originDestinations == null || this.originDestinations.length <= 1)
      return;

    this.originDestinations.splice(originDestinationIndex, 1);
  }

  //添加乘客类型
  addNewPassenger(passengerTypeCode, quantity) {
    if (!quantity) quantity = 0;

    let flightPassengerFormItem = this.findPassengerByType(passengerTypeCode);
    if (flightPassengerFormItem) {
      flightPassengerFormItem.quantity += quantity;
    } else {
      flightPassengerFormItem = new FlightShoppingPassengerFormItem(
        passengerTypeCode,
        quantity
      );
      if (this.passengers == null) this.passengers = [];
      this.passengers.push(flightPassengerFormItem);
    }

    return flightPassengerFormItem;
  }

  //根据索引删除乘客
  removePassenger(passengerIndex) {
    if (this.passengers == null || this.passengers.length <= 1) return;
    this.passengers.splice(passengerIndex, 1);
  }

  //根据乘客类型删除乘客
  removePassengerByTypeCode(passengerTypeCode) {
    if (this.passengers == null || this.passengers.length <= 1) return;

    let passengersCopy = JSON.parse(JSON.stringify(this.passengers));

    for (
      let passengerIndex = 0;
      passengerIndex < passengersCopy.length;
      passengerIndex++
    ) {
      let passenger = passengersCopy[passengerIndex];
      if (passenger.passengerTypeCode === passengerTypeCode) {
        this.passengers.slice(passengerIndex, 1);
      }
    }
  }

  findPassengerByType(passengerTypeCode) {
    if (!this.passengers) return null;

    const filterArr = this.passengers.filter(
      (passenger) => passenger.passengerTypeCode === passengerTypeCode
    );
    if (!filterArr) return null;

    return filterArr[0];
  }

  countPassengerTypeQuantity(passengerTypeCode) {
    let passengerTypeQuantity = 0;
    for (const passenger of this.passengers) {
      if (passengerTypeCode === passenger.passengerTypeCode) {
        passengerTypeQuantity += passenger.quantity;
      }
    }

    return passengerTypeQuantity;
  }

  addNewLoyaltyAccount(passengerTypeCode, ffpNumber) {
    let passengerTypeQuantity =
      this.countPassengerTypeQuantity(passengerTypeCode);
    let psgTypeLoyaltyAccountQuantity =
      this.countPsgTypeLoyaltyAccountQuantity(passengerTypeCode);
    if (psgTypeLoyaltyAccountQuantity >= passengerTypeQuantity) return null;

    let flightLoyaltyAccount = new FlightLoyaltyAccountFormItem(
      passengerTypeCode,
      ffpNumber
    );
    if (this.loyaltyAccounts == null) this.loyaltyAccounts = [];

    this.loyaltyAccounts.push(flightLoyaltyAccount);
    return flightLoyaltyAccount;
  }

  removeLoyaltyAccount(index) {
    if (this.loyaltyAccounts == null || this.loyaltyAccounts.length === 0)
      return;

    this.loyaltyAccounts.splice(index, 1);
  }

  removeLoyaltyAccountByType(passengerTypeCode, passengerTypeAccountIndex) {
    if (this.loyaltyAccounts == null || this.loyaltyAccounts.length === 0)
      return;

    if (passengerTypeAccountIndex) {
      const passengerTypeLoyaltyAccounts =
        this.findLoyaltyAccountsByTypeCode(passengerTypeCode);
      if (
        !passengerTypeLoyaltyAccounts ||
        passengerTypeLoyaltyAccounts.length <= passengerTypeAccountIndex
      )
        return;

      const passengerTypeLoyaltyAccountRemove =
        passengerTypeLoyaltyAccounts[passengerTypeAccountIndex];
      for (let i = 0; i < this.loyaltyAccounts.length; i++) {
        const loyaltyAccount = this.loyaltyAccounts[i];
        if (loyaltyAccount !== passengerTypeLoyaltyAccountRemove) continue;

        this.loyaltyAccounts.splice(i, 1);
      }
    } else {
      for (let i = 0; i < this.loyaltyAccounts.length; i++) {
        const loyaltyAccount = this.loyaltyAccounts[i];
        if (loyaltyAccount.passengerTypeCode !== passengerTypeCode) continue;

        this.loyaltyAccounts.splice(i, 1);
      }
    }
  }

  findLoyaltyAccountsByTypeCode(passengerTypeCode) {
    if (!this.loyaltyAccounts) return [];

    return this.loyaltyAccounts.filter(
      (item) => passengerTypeCode === item.passengerTypeCode
    );
  }

  countPsgTypeLoyaltyAccountQuantity(passengerTypeCode) {
    let psgTypeLoyaltyAccountQuantity = 0;
    if (!this.loyaltyAccounts || this.loyaltyAccounts.length === 0)
      return psgTypeLoyaltyAccountQuantity;

    for (const loyaltyAccount of this.loyaltyAccounts) {
      if (passengerTypeCode === loyaltyAccount.passengerTypeCode) {
        psgTypeLoyaltyAccountQuantity++;
      }
    }

    return psgTypeLoyaltyAccountQuantity;
  }
}

export class FlightLoyaltyAccountFormItem {
  /**
   *
   * @param passengerTypeCode string，FFPNumber归属的乘客类型
   * @param ffpNumber string，常飞计划码，格式为"常飞计划码所属航司/常飞计划码"，例如,SQ/123456
   */
  constructor(passengerTypeCode, ffpNumber) {
    this.passengerTypeCode = passengerTypeCode;
    this.ffpNumber = ffpNumber;
  }
}

export class FlightOriginDestinationFormItem {
  /**
   *
   * @param refNumber string, OD参考号，自定义，例如，OD1，OD2
   * @param originLocationCode string，起飞地代码（机场代码，或城市代码），为IATA Code
   * @param originLocationName string，起飞地名（机场名，或城市名）
   * @param destinationLocationCode string，到达地代码（机场代码，或城市代码），为IATA Code
   * @param destinationLocationName string，到达地名（机场名，或城市名）
   * @param equipPrefs Array，FlightPrefsFormItem数组，飞机型号偏好数据
   * @param cabinTypePrefs Array，FlightPrefsFormItem数组，座位偏好数组，如YY-全部座位类型，Y-经济舱，C-商务舱等等
   * @param airlinePrefs Array，FlightPrefsFormItem数组，航司偏好数组，
   * @param departureDate string, 起飞日期，格式为yyyy-MM-dd
   * @param departureTime string, 起飞时间，格式为HH:mm
   * @param connections number，转机次数
   * @param stops number，停靠次数
   * @param departureTimeWindowBefore number，时间窗口，预设的起飞时间前n个小时，单位为小时，前后窗口总宽度不能超过12小时
   * @param departureTimeWindowAfter number，时间窗口，预设的起飞时间后n个小时，单位为小时
   * @param daysBeforeDeparture number，日期窗口，预设的起飞日期前n天，单位为天数，前后窗口总宽度不能超过7天
   * @param daysAfterDeparture number，日期窗口，预设的起飞日期后n天，单位为天数
   * @param arrivalDate string，到达日期，格式为yyyy-MM-dd
   * @param arrivalTime string，到达时间，格式为HH:mm
   */
  constructor(
    refNumber,
    originLocationCode,
    originLocationName,
    destinationLocationCode,
    destinationLocationName,
    equipPrefs,
    cabinTypePrefs,
    airlinePrefs,
    departureDate,
    departureTime,
    connections,
    stops,
    departureTimeWindowBefore,
    departureTimeWindowAfter,
    daysBeforeDeparture,
    daysAfterDeparture,
    arrivalDate,
    arrivalTime
  ) {
    this.refNumber = refNumber;
    this.originLocationCode = originLocationCode;
    this.originLocationName = originLocationName;
    this.destinationLocationCode = destinationLocationCode;
    this.destinationLocationName = destinationLocationName;
    this.equipPrefs = equipPrefs;
    this.cabinTypePrefs = cabinTypePrefs;
    this.airlinePrefs = airlinePrefs;
    this.departureDate = departureDate;
    this.departureTime = departureTime;
    this.connections = connections;
    this.stops = stops;
    this.departureTimeWindowBefore = departureTimeWindowBefore;
    this.departureTimeWindowAfter = departureTimeWindowAfter;
    this.daysBeforeDeparture = daysBeforeDeparture;
    this.daysAfterDeparture = daysAfterDeparture;
    this.arrivalDate = arrivalDate;
    this.arrivalTime = arrivalTime;
  }
}

export class FlightShoppingPassengerFormItem {
  /**
   *
   * @param passengerTypeCode string，乘客类型，例如，ADT, B15, BNN, C15, CHD, CMA, CMP, EMI, HOF, INF, INN, INS, ITF, ITS, ITX,  JCB, JNF, JNN, JNS, LBR, LIF, LNN, MIS, OFW, SEA, SPS, SRC, STU, VFR, YTH
   * @param quantity number，该乘客类型数量
   */
  constructor(passengerTypeCode, quantity) {
    this.passengerTypeCode = passengerTypeCode;
    this.quantity = quantity;
  }
}

export class FlightPrefsFormItem {
  /**
   *
   * @param code string，代码
   * @param preference string，是否包含，例如，包含-INCLUDE，排除-EXCLUDE
   */
  constructor(code, preference) {
    this.code = code;
    this.preference = preference;
  }
}

export class CarRentalAvailFormModel {
  /*
   * pickupLocation string， 取车地点代码，例如，LCYN01
   * pickupDatetime string, 取车日期时间，yyyy-MM-ddTHH:mm:ss，例如，2023-09-01T10:00
   * returnDatetime string, 还车日期时间，yyyy-MM-ddTHH:mm:ss，例如，2023-09-02T10:00
   * preferredCarType string, 车辆类型，1 for car, 5 for truck
   * */
  constructor(
    pickupLocation,
    pickupLocationName,
    returnLocation,
    returnLocationName,
    pickupDatetime,
    returnDatetime,
    countryOfResidence,
    countryName,
    preferredCarType
  ) {
    this.pickupLocation = pickupLocation;
    this.pickupLocationName = pickupLocationName;
    this.returnLocation = returnLocation;
    this.returnLocationName = returnLocationName;
    this.pickupDatetime = pickupDatetime;
    this.returnDatetime = returnDatetime;
    this.countryOfResidence = countryOfResidence;
    this.countryName = countryName;
    this.preferredCarType = preferredCarType;
  }
}

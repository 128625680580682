import axios from "@/utils/axiosUtils";

function lowFareSearch(lowFareSearchRQ) {
  let url = "/api/v3/sales/shopping/air/air-low-fare-search";
  return axios.post(url, lowFareSearchRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function verifyPrice(verifyPriceRQ) {
  let url = "/api/v3/sales/shopping/air/air-price";

  return axios.post(url, verifyPriceRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function fareRules(airRuleRQ) {
  let url = "/api/v3/sales/shopping/air/air-fare-rules";
  return axios.post(url, airRuleRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function offers(airGetOfferRQ) {
  let url = "/api/v3/sales/shopping/air/air-get-offers";
  return axios.post(url, airGetOfferRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function book(airBookRQ) {
  let url = "/api/v3/sales/shopping/air/air-book";
  return axios.post(url, airBookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function resRetrieve(resRetrieveRQ) {
  let url = "/api/v3/sales/booking/res-retrieve";
  return axios.post(url, resRetrieveRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  lowFareSearch,
  offers,
  verifyPrice,
  fareRules,
  book,
  resRetrieve,
};

export class TripsReadFormModel {
  /**
   *
   * @param type string，id类型，USERNAME-用户名，BOOKING_ID-按照TripID搜索指定订单
   * @param id string，用户名或tripID
   * @param limit string，每页数量
   * @param offset string，偏移量
   * @param productType string，订单类型，hotel-酒店，flight-机票
   * @param start string，订单创建日期范围，开始日期，格式为yyyy-MM-dd
   * @param end string，订单创建日期范围，结束日期，格式为yyyy-MM-dd
   * @param status string，订单状态
   */
  constructor(type, id, limit, offset, productType, start, end, status) {
    this.type = type;
    this.id = id;
    this.limit = limit;
    this.offset = offset;
    this.productType = productType;
    this.start = start;
    this.end = end;
    this.status = status;
  }
}

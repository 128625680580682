export class TripAirItemAncillaryGroupVO {
    /**
     *
     * @param groupCode string，附加服务代码，为IATA Taxonomy代码
     * @param groupName
     * @param subGroupCode string，附加服务代码，为IATA Taxonomy代码
     * @param subGroupName
     */
    constructor(groupCode, groupName, subGroupCode, subGroupName) {
        this.groupCode = groupCode;
        this.groupName = groupName;
        this.subGroupCode = subGroupCode;
        this.subGroupName = subGroupName;
    }
}
<script setup>
import { reactive, ref, watch, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useProfileStore } from "@/stores/profile";
import { Modal } from "ant-design-vue";

import BaseCustomerMyProfilePanel from "@/views/profiles/items/panel/BaseCustomerMyProfilePanel.vue";
import BaseCustomerMyBookingsPanel from "@/views/profiles/items/panel/BaseCustomerMyBookingsPanel.vue";
import { useSecurityStore } from "@/stores/security";
import { useI18n } from "vue-i18n";
import BaseCustomerSecurityAndSettingsPanel from "@/views/profiles/items/panel/BaseCustomerSecurityAndSettingsPanel.vue";
import BaseCustomerCommunicationsPanel from "@/views/profiles/items/panel/BaseCustomerCommunicationsPanel.vue";
import UserProfileFormModel from "@/formmodel/user/profile/userProfileFormModel";
import partyContentApi from "@/apis/v2/party/partyContentApi";
import { useCmsContentStore } from "@/stores/cms";

const router = useRouter();
const route = useRoute();

const { t, locale } = useI18n(); // 解构调用函数
const AModal = Modal;

const user = JSON.parse(sessionStorage.getItem("user"));

const profileStore = useProfileStore();
profileStore.$reset(); //重置状态
const securityStore = useSecurityStore();

const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContent = cmsContentStore.productPageBackgroundImage;
  imageUrl.value =
    cmsContentStore.productPageBackgroundImage &&
    cmsContentStore.productPageBackgroundImage.customerDashboardBackground
      ? cmsContentStore.productPageBackgroundImage.customerDashboardBackground
          .url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});

if (user && user.partyId) loadUserProfile();

function loadUserProfile() {
  profileStore.loadUserProfile(user.partyId);
}

const tabsRef = ref([
  { code: "BOOKING", name: "Bookings", icon: "fa-solid fa-list-ul" },
  { code: "PROFILE", name: "Profile", icon: "fas fa-user-circle" },
  {
    code: "COMMUNICATIONS",
    name: "Communications",
    icon: "fa-solid fa-envelope",
  },
  { code: "SECURITY", name: "Security and settings", icon: "fa-solid fa-gear" },
  { code: "LOGOUT", name: "Logout", icon: "fas fa-bell" },
]);
const profileActiveTabRef = ref(
  sessionStorage.getItem("profileActiveTab")
    ? sessionStorage.getItem("profileActiveTab")
    : "BOOKING"
);
const profileViewItemShowRef = ref(null);
const userProfileFormModalRef = ref(null);
const documentTypesRef = ref(null);

watch(
  () => profileStore.profileViewItem,
  (newValue) => {
    profileViewItemShowRef.value = newValue;
    const profile = JSON.parse(JSON.stringify(newValue));
    if (profile) {
      userProfileFormModalRef.value = new UserProfileFormModel(
        profile.partyId,
        profile.username,
        profile.nameTitle,
        profile.gender,
        profile.givenName,
        profile.surname,
        profile.birthDate,
        profile.mobileNumber,
        profile.mobileCountryAccessCode,
        profile.mobileContactMechId,
        profile.emergencyNumber,
        profile.emergencyCountryAccessCode,
        profile.emergencyContactName,
        profile.emergencyContactMechId,
        profile.emailAddress,
        profile.emailContactMechId,
        profile.country,
        profile.province,
        profile.city,
        profile.region,
        profile.postCode,
        profile.addressContactMechId,
        profile.knownTravellerNumber,
        profile.knownTravellerNumberId,
        profile.redressNumber,
        profile.redressNumberId,
        profile.documents,
        profile.seatPref,
        profile.seatPrefId,
        profile.mealPref,
        profile.mealPrefId,
        profile.custLoyalties,
        profile.additionalTravellers
      );
      //找出所有additional traveller的信息
      const additionalTravellers = profile.additionalTravellers;
      const partyIds = [];
      //将这些人的信息全部load出来
      if (additionalTravellers && additionalTravellers.length > 0) {
        additionalTravellers.forEach((additionalTraveller) => {
          const partyId = additionalTraveller.partyIdFrom;
          partyIds.push(partyId);
        });
      }
      profileStore.profileViewItems = [];
      profileStore.loadAdditionalTravellersProfile(partyIds);
    }

    //所有的证件类型
    partyContentApi.getDocumentTypes().then((res) => {
      if (res) {
        documentTypesRef.value = res.data;
      }
    });
  },
  { immediate: true, deep: true }
);

function changeActive(val) {
  if (val === "LOGOUT") {
    logOut();
  } else {
    profileActiveTabRef.value = val;
    sessionStorage.setItem("profileActiveTab", val);
  }
}

async function logOut() {
  sessionStorage.removeItem("user");
  await securityStore.signOut();
  router.push({ name: "home" });
}

function goBack() {
  router.back();
}
</script>
<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("my-profile") }}</h2>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->
  <section id="dashboard_main_arae" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goBack">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="dashboard_sidebar">
            <!-- 左上用戶信息展示-->
            <div class="dashboard_sidebar_user" v-if="profileViewItemShowRef">
              <i
                class="bi bi-person-circle text-muted"
                style="font-size: 80px"
              ></i>
              <h3>
                {{ profileViewItemShowRef.surname }}/{{
                  profileViewItemShowRef.givenName
                }}
              </h3>
              <p>{{ profileViewItemShowRef.username }}</p>
              <p>
                +{{ profileViewItemShowRef.mobileCountryAccessCode }}
                {{ profileViewItemShowRef.mobileNumber }}
              </p>
              <p>
                <a>{{ profileViewItemShowRef.emailAddress }}</a>
              </p>
            </div>

            <div class="dashboard_menu_area">
              <ul>
                <li v-for="(tab, tabIndex) in tabsRef" :key="tabIndex">
                  <a
                    @click="changeActive(tab.code)"
                    :class="[
                      profileActiveTabRef === tab.code ? 'activeColor' : '',
                    ]"
                    ><i :class="tab.icon"></i>{{ tab.name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div
            class="dashboard_common_table"
            v-if="profileActiveTabRef === 'BOOKING'"
          >
            <BaseCustomerMyBookingsPanel />
          </div>
          <div
            class="dashboard_common_table"
            v-else-if="profileActiveTabRef === 'PROFILE'"
          >
            <BaseCustomerMyProfilePanel
              :profile-info="profileViewItemShowRef"
              :document-types="documentTypesRef"
              :user-profile-form-modal="userProfileFormModalRef"
              @loadUserProfile="loadUserProfile"
            />
          </div>
          <div
            class="dashboard_common_table"
            v-else-if="profileActiveTabRef === 'COMMUNICATIONS'"
          >
            <BaseCustomerCommunicationsPanel />
          </div>
          <div
            class="dashboard_common_table"
            v-else-if="profileActiveTabRef === 'SECURITY'"
          >
            <BaseCustomerSecurityAndSettingsPanel />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

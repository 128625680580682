<template>
    <!-- Common Banner Area -->
    <Banner />

    <!-- Form Area -->
    <Form />

    <!-- Hotel Search Areas -->
    <SearchResult />

    <!-- Cta Area -->
    <Cta />

</template>
  
<script>
// @ is an alias to /src
import Banner from '@/components/templates/hotel/Banner.vue'
import Form from '@/components/templates/hotel/Form.vue'
import SearchResult from '@/components/templates/hotel/SearchResult.vue'
import Cta from '@/components/templates/home/Cta.vue'
export default {
    name: 'HotelSearchView',
    components: {
        Banner, Form, SearchResult, Cta
    }
}
</script>
<script setup>
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import tripActivityItemTools from "@/tools/v2/trip/tripActivityItemTools";
import { createVNode, ref, watch } from "vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";
import ActivityItinerarySummaryPanel from "@/views/v2/sales/activity/items/panel/ActivityItinerarySummaryPanel.vue";
import ReservationContactInfoPanel from "@/views/v2/trip/booking/commom/ReservationContactInfoPanel.vue";
import ActivityPassengerInfoPanel from "@/views/v2/sales/activity/items/panel/ActivityPassengerInfoPanel.vue";
import ActivityNecessaryInfoPanel from "@/views/v2/sales/activity/items/panel/ActivityNecessaryInfoPanel.vue";
import ActivityPriceSummaryPanel from "@/views/v2/sales/activity/items/panel/ActivityPriceSummaryPanel.vue";
import { Modal } from "ant-design-vue";
import { useCmsContentStore } from "@/stores/cms";

const route = useRoute();
const router = useRouter();
const { t, locale } = useI18n(); // 解构调用函数
const AModal = Modal;
const templateStore = useTemplateStore();

const activityReservationDetails = JSON.parse(
  sessionStorage.getItem("activityReservationDetails")
);

let tripActivityItemRef = ref(null);
if (activityReservationDetails) {
  tripActivityItemRef.value = tripActivityItemTools.buildTripTourActivityItem(
    activityReservationDetails
  );
}

function confirmNow() {
  AModal.confirm({
    content: t("you-will-confirm-the-trip-now"),
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    width: 600,
    wrapClassName: "confirmModal",
    onOk() {
      return new Promise((resolve, reject) => {
        const confIds = tripActivityItemRef.value.confirmations;
        const bookingInfo = getBookingInfo(confIds);

        router.push({
          name: "v2-payment-methods",
          query: {
            bookingId: bookingInfo.bookingId,
            invoiceId: bookingInfo.invoiceId,
            productType: "DESTACTIVITY",
          },
        });
        AModal.destroyAll();
      }).catch((err) => {
        console.log(err);
        AModal.destroyAll();
      });
    },
    cancelText: "Cancel",
    onCancel() {
      AModal.destroyAll();
    },
  });
}

function getBookingInfo(bookingReferenceIdList) {
  let bookingId = null;
  let invoiceId = null;
  if (bookingReferenceIdList && bookingReferenceIdList.length > 0) {
    bookingReferenceIdList.forEach((item) => {
      if (item.type === "BOOKING_ID") {
        bookingId = item.id;
      } else if (item.type === "INVOICE_ID") {
        invoiceId = item.id;
      }
    });
  }
  return {
    bookingId,
    invoiceId,
  };
}

function goBack() {
  router.push({ name: "sales-activity-passengers-v2" });
}

function buildContactInfo(passengers) {
  if (passengers && passengers.length > 0) {
    return passengers.find((item) => item.phoneNumber);
  }
}

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.ActivityBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.ActivityBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>Check Out</h2>
        <ul>
          <li
            v-if="route.query && route.query.isFromHome"
            class="pre-page"
            @click="router.push({ name: 'home' })"
          >
            {{ $t("home") }}
          </li>
          <li
            v-else
            class="pre-page"
            @click="router.push({ name: 'sales-activity-home-v2' })"
          >
            Tour Activity
          </li>
          <li class="pre-page">
            <i class="fas fa-circle"></i>
            Activity List
          </li>
          <li>
            <i class="fas fa-circle"></i>
            Activity Details
          </li>
          <li>
            <i class="fas fa-circle"></i>
            Passengers Info
          </li>
          <li>
            <i class="fas fa-circle"></i>
            Check Out
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_details_main" class="section_padding">
    <div class="container hotel_container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goBack">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-10">
          <!-- tour Info -->
          <ActivityItinerarySummaryPanel
            :trip-activity-item="tripActivityItemRef"
          />
          <!-- tour Info END-->

          <!-- contact Info -->
          <ReservationContactInfoPanel
            :contact-info="buildContactInfo(tripActivityItemRef.passengers)"
          />
          <!-- contact Info END-->

          <!-- Passenger Info -->
          <ActivityPassengerInfoPanel
            :passengers="tripActivityItemRef.passengers"
          />
          <!-- Passenger Info END-->

          <!--   necessary info       -->
          <ActivityNecessaryInfoPanel
            :necessary-infos="tripActivityItemRef.necessaryInfos"
          />
          <!--    necessary info   END  -->

          <!-- Price Summary -->
          <ActivityPriceSummaryPanel
            :is-reservation="false"
            :trip-activity-item="tripActivityItemRef"
            @confirmNow="confirmNow"
          />
          <!-- Price Summary   END -->
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

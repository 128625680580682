export class FlightPriceFormModel {
  /**
   *
   * @param type string，Price类型，有PRICE，REPRICE，UPSELL
   * @param airItineraryRPH string，行程唯一标识
   * @param servicesSelected Array，TripAirItemAncillaryFareVO对象数组
   * @param promotionCode
   * @param airItineraryRPHUpsell optional， 可选，当存在时，使用该rph
   */
  constructor(
    type,
    airItineraryRPH,
    servicesSelected,
    promotionCode,
    airItineraryRPHUpsell
  ) {
    this.type = type;
    this.airItineraryRPH = airItineraryRPH;
    this.airItineraryRPHUpsell = airItineraryRPHUpsell;
    this.servicesSelected = servicesSelected;
    this.promotionCode = promotionCode;
  }

  addServiceSelected(serviceSelected) {
    if (!this.servicesSelected) this.servicesSelected = [];

    const ancillaryId = serviceSelected.ancillaryId;
    const travelerRPH = serviceSelected.travelerRPH;
    const flightSegmentRPH = serviceSelected.flightSegmentRPH;
    const quantity = serviceSelected.quantity;
    if (!quantity) return;

    if (this.servicesSelected.length === 0) {
      this.servicesSelected.push(serviceSelected);
    } else {
      let contain = false;
      this.servicesSelected.forEach((service) => {
        if (
          service &&
          service.ancillaryId === ancillaryId &&
          service.travelerRPH === travelerRPH &&
          service.flightSegmentRPH === flightSegmentRPH
        ) {
          service.quantity += quantity;
          contain = true;
        }
      });

      if (!contain) {
        this.servicesSelected.push(serviceSelected);
      }
    }
  }

  updateServiceSelected(serviceSelected) {
    if (!this.servicesSelected) this.servicesSelected = [];
    const serviceType = serviceSelected.type;
    const ancillaryId = serviceSelected.ancillaryId;
    const travelerRPH = serviceSelected.travelerRPH;
    const flightSegmentRPH = serviceSelected.flightSegmentRPH;
    const quantity = serviceSelected.quantity;
    const serviceCode = serviceSelected.code;
    if (serviceType !== "SEAT" && !quantity) return;

    if (this.servicesSelected.length === 0) {
      this.servicesSelected.push(serviceSelected);
    } else if (serviceType === "SEAT") {
      let contain = false;

      const find = this.servicesSelected.find((service) => {
        const seatColumnNumberCompare = serviceSelected.seatColumnNumber;
        const seatRowNumberCompare = serviceSelected.seatRowNumber;
        const seatColumnNumber = service.seatColumnNumber;
        const seatRowNumber = service.seatRowNumber;

        if (
          service &&
          service.travelerRPH !== travelerRPH &&
          service.flightSegmentRPH === flightSegmentRPH &&
          service.type === serviceType &&
          seatRowNumber === seatRowNumberCompare &&
          seatColumnNumber === seatColumnNumberCompare
        ) {
          return true;
        }

        return false;
      });

      if (find) return;

      for (const service of this.servicesSelected) {
        if (
          service &&
          service.travelerRPH === travelerRPH &&
          service.flightSegmentRPH === flightSegmentRPH &&
          service.type === serviceType
        ) {
          service.status = serviceSelected.status;
          service.type = serviceSelected.type;
          service.code = serviceSelected.code;
          service.name = serviceSelected.name;
          service.currencyCode = serviceSelected.currencyCode;
          service.amount = serviceSelected.amount;
          service.ancillaryId = serviceSelected.ancillaryId;
          service.flightSegmentRPH = serviceSelected.flightSegmentRPH;
          service.travelerRPH = serviceSelected.travelerRPH;
          service.quantity = serviceSelected.quantity;
          service.seatColumnNumber = serviceSelected.seatColumnNumber;
          service.seatRowNumber = serviceSelected.seatRowNumber;
          service.bookingInstruction = serviceSelected.bookingInstruction;
          service.imageUrl = serviceSelected.imageUrl;
          service.descriptions = serviceSelected.descriptions;
          service.groups = serviceSelected.groups;

          contain = true;
        }
      }

      if (!contain) {
        this.servicesSelected.push(serviceSelected);
      }
    } else {
      let contain = false;
      this.servicesSelected.forEach((service) => {
        if (
          service &&
          service.code === "044C" &&
          serviceSelected.code === "044C" &&
          service.travelerRPH === travelerRPH &&
          service.flightSegmentRPH === flightSegmentRPH
        ) {
          service.status = serviceSelected.status;
          service.type = serviceSelected.type;
          service.code = serviceSelected.code;
          service.name = serviceSelected.name;
          service.currencyCode = serviceSelected.currencyCode;
          service.amount = serviceSelected.amount;
          service.ancillaryId = serviceSelected.ancillaryId;
          service.flightSegmentRPH = serviceSelected.flightSegmentRPH;
          service.travelerRPH = serviceSelected.travelerRPH;
          service.quantity = serviceSelected.quantity;
          service.seatColumnNumber = serviceSelected.seatColumnNumber;
          service.seatRowNumber = serviceSelected.seatRowNumber;
          service.bookingInstruction = serviceSelected.bookingInstruction;
          service.imageUrl = serviceSelected.imageUrl;
          service.descriptions = serviceSelected.descriptions;
          service.groups = serviceSelected.groups;

          contain = true;
        } else if (
          service &&
          service.ancillaryId === ancillaryId &&
          service.travelerRPH === travelerRPH &&
          service.flightSegmentRPH === flightSegmentRPH
        ) {
          service.quantity = quantity;
          contain = true;
        } else if (
          service.flightSegmentRPH === flightSegmentRPH &&
          service.code === serviceCode
        ) {
          service.status = serviceSelected.status;
          service.type = serviceSelected.type;
          service.code = serviceSelected.code;
          service.name = serviceSelected.name;
          service.currencyCode = serviceSelected.currencyCode;
          service.amount = serviceSelected.amount;
          service.ancillaryId = serviceSelected.ancillaryId;
          service.flightSegmentRPH = serviceSelected.flightSegmentRPH;
          service.travelerRPH = serviceSelected.travelerRPH;
          service.quantity = serviceSelected.quantity;
          service.seatColumnNumber = serviceSelected.seatColumnNumber;
          service.seatRowNumber = serviceSelected.seatRowNumber;
          service.bookingInstruction = serviceSelected.bookingInstruction;
          service.imageUrl = serviceSelected.imageUrl;
          service.descriptions = serviceSelected.descriptions;
          service.groups = serviceSelected.groups;
          contain = true;
        }
      });

      if (!contain) {
        this.servicesSelected.push(serviceSelected);
      }
    }
  }

  /**
   *
   * @param flightSegmentRPH
   * @param travelerRPH
   * @param ancillaryId
   * @param quantity number，减去附加服务的数量
   */
  removeService(flightSegmentRPH, travelerRPH, ancillaryId, quantity) {
    if (!this.servicesSelected || this.servicesSelected.length === 0) return;

    for (let i = 0; i < this.servicesSelected.length; i++) {
      const serviceSelected = this.servicesSelected[i];
      if (
        serviceSelected.ancillaryId === ancillaryId &&
        serviceSelected.travelerRPH === travelerRPH &&
        serviceSelected.flightSegmentRPH.indexOf(flightSegmentRPH) !== -1
      ) {
        if (quantity) {
          serviceSelected.quantity -= quantity;
          if (serviceSelected.quantity <= 0) {
            this.servicesSelected.splice(i, 1);
          }

          return;
        }

        this.servicesSelected.splice(i, 1);
      }
    }
  }

  calculateServicesTotalPrice() {
    if (!this.servicesSelected || this.servicesSelected.length === 0)
      return null;

    let totalAmount = 0;
    let currency = null;

    for (const serviceSelected of this.servicesSelected) {
      let quantity = serviceSelected.quantity;
      const currencyCode = serviceSelected.currencyCode;
      const amount = serviceSelected.amount;

      if (!currency && currencyCode) currency = currencyCode;

      if (!quantity) quantity = 1;
      totalAmount += amount * quantity;
    }

    return {
      amount: totalAmount,
      currencyCode: currency,
    };
  }
}

<script setup>
import { useTemplateStore } from "@/stores/template";
import { useRoute, useRouter } from "vue-router";
import { reactive, ref } from "vue";
import BasePackageSearchFormItem from "@/views/sales/package/items/form/BasePackageSearchFormItem.vue";
import dateUtils from "@/utils/dateUtils";
import useVuelidate from "@vuelidate/core";
import { useDynamicPackageStore } from "@/stores/dynamicPackage";

let router = useRouter();
let route = useRoute();
let templateStore = useTemplateStore();
let dynamicPackageStore = useDynamicPackageStore();
const emits = defineEmits(["resetShow"]);
let props = defineProps({
  jumboable: {
    type: Boolean,
  },
  jumpTo: {
    type: String,
    description: "route name to jump",
  },
  equipmentType: {},
});
let todayDate = dateUtils.format(new Date());
let packageSearchFormModel = reactive({
  departureDate: dateUtils.getOffsetDate(todayDate, 7),
  returnDate: dateUtils.getOffsetDate(todayDate, 8),
  originLocationCode: null,
  originLocationName: null,
  originAirportName: null,
  destinationLocationCode: null,
  destinationLocationName: null,
  destinationAirportName: null,
  tticode: null,
  roomNum: 1,
  adultNum: 1,
  cabinClass: "Y",
  childAges: [],
});

//列表页
if (!props.jumboable) {
  if (route.query != null && Object.keys(route.query).length > 0) {
    let packageSearchFormModelDefault = getPackageSearchFormModel(route);
    packageSearchFormModel = reactive(packageSearchFormModelDefault);
  }
  // (packageSearchFormModel);
  searchPackage(packageSearchFormModel);
}

function getPackageSearchFormModel(route) {
  let childAgesFormat = route.query.childAgesFormat;
  // (childAgesFormat);
  if (typeof childAgesFormat === "string") {
    childAgesFormat = [childAgesFormat];
  }
  let childAges = parseChildAges(childAgesFormat);
  let adultNum = route.query.adultNum;
  let cabinClass = route.query.cabinClass;
  let departureDate = route.query.departureDate;
  let destinationAirportName = route.query.destinationAirportName;
  let destinationLocationCode = route.query.destinationLocationCode;
  let destinationLocationName = route.query.destinationLocationName;
  let originAirportName = route.query.originAirportName;
  let originLocationCode = route.query.originLocationCode;
  let originLocationName = route.query.originLocationName;
  let returnDate = route.query.returnDate;
  let roomNum = route.query.roomNum;
  let packageSearchFormModel = {
    adultNum: adultNum,
    childNum: route.query.childNum,
    cabinClass: cabinClass,
    childAges: childAges,
    departureDate: departureDate,
    destinationAirportName: destinationAirportName,
    destinationLocationCode: destinationLocationCode,
    destinationLocationName: destinationLocationName,
    originAirportName: originAirportName,
    originLocationCode: originLocationCode,
    originLocationName: originLocationName,
    returnDate: returnDate,
    roomNum: roomNum,
  };
  return packageSearchFormModel;
}

function parseChildAges(formats) {
  let childAges = [];
  if (formats && formats.length > 0) {
    formats.forEach((format) => {
      let splits = format.split(":");
      let roomId = splits[0];
      let age = splits[1];

      let childAge = {
        roomId: roomId,
        age: age,
      };
      childAges.push(childAge);
    });
  }
  return childAges;
}
function formatChildAges(childAges) {
  let formatArr = [];
  childAges.forEach((childAge) => {
    let format = childAge.roomId + ":" + childAge.age;
    formatArr.push(format.toString());
  });
  return formatArr;
}

//校验和表单提交
const v = useVuelidate();
let isMenuShow = ref(false);
let isOriginLocationShow = ref(false);
let isDestinationLocationShow = ref(false);

async function searchByPackageSearchFormModel(packageSearchFormModel) {
  const result = await v.value.$validate();
  isMenuShow.value = false;
  isOriginLocationShow.value = false;
  isDestinationLocationShow.value = false;
  if (v.value.$errors && v.value.$errors.length > 0) {
    v.value.$errors.forEach((item) => {
      if (item.$property == "age") {
        isMenuShow.value = true;
      } else if (item.$property == "originLocationCode") {
        isOriginLocationShow.value = true;
      } else if (item.$property == "destinationLocationCode") {
        isDestinationLocationShow.value = true;
      }
    });
  }
  if (!result) return;

  //首页跳转传参
  let format = formatChildAges(packageSearchFormModel.childAges);
  packageSearchFormModel.childAgesFormat = format;
  router.replace({ query: packageSearchFormModel });
  emits("resetShow");
  if (props.jumboable) {
    router.push({
      name: props.jumpTo,
      query: packageSearchFormModel,
    });
  } else {
    searchPackage(packageSearchFormModel);
  }
}

async function searchPackage(formModel) {
  templateStore.pageLoader({ mode: "on" });
  //每次搜索之后存储表单
  sessionStorage.setItem("packageSearchFormModel", JSON.stringify(formModel));
  let res = await dynamicPackageStore.availDynamicPkg(formModel);
  // (res);
  templateStore.pageLoader({ mode: "off" });
}

function go(pathName) {
  router.push({ name: pathName });
}
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="oneway_search_form">
        <form action="javascript:void(0);">
          <div class="row">
            <div class="col-12">
              <BasePackageSearchFormItem
                :is-menu-show="isMenuShow"
                :equipment-type="equipmentType"
                :is-origin-location-show="isOriginLocationShow"
                :is-destination-location-show="isDestinationLocationShow"
                :package-search-form-model="packageSearchFormModel"
                @submitFormModel="searchByPackageSearchFormModel"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

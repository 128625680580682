<script setup>
import { useRoute } from "vue-router";
import { reactive, ref, watch, watchEffect } from "vue"; //region cms内容管理
import { useCmsContentStore } from "@/stores/cms";

let route = useRoute();

const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();

const contentRef = ref(null);

watch(
  () => route.query,
  (newValue) => {
    if (newValue) {
      if (newValue.contentId) finContentById(newValue.contentId);
    }
  },
  { immediate: true, deep: true }
);

function finContentById(contentId) {
  //set contentRef.value from response of CMS api
  cmsContentStore.findSlotContent(contentId).then((res) => {
    if (res) {
      contentRef.value = res;
      contentRef.value.content = formatContent(res.content, res.images);
    }
  });
}

let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContent = cmsContentStore.productPageBackgroundImage;
  imageUrl.value =
    cmsContentStore.productPageBackgroundImage &&
    cmsContentStore.productPageBackgroundImage.contentViewBackgroupImage
      ? cmsContentStore.productPageBackgroundImage.contentViewBackgroupImage.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});

function formatContent(content, images) {
  const parser = new DOMParser();
  //解析HTML字符串为文档对象
  const doc = parser.parseFromString(content, "text/html");

  const imageTags = doc.querySelectorAll("img");

  if (imageTags && imageTags.length > 0) {
    imageTags.forEach((imageTag, imageTagIndex) => {
      if (imageTagIndex < images.length) {
        const imageFind = images.find(
          (item) =>
            item.name === imageTagIndex + 1 + ".png" ||
            item.name === imageTagIndex + 1 + ".jpg"
        );

        if (imageFind) imageTag.src = baseUrl + imageFind.url;
      }
    });
  }

  return doc.body.innerHTML;
}
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2 v-if="contentRef">{{ contentRef.name }}</h2>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="about_us_top" class="section_padding">
    <div class="container">
      <div v-if="contentRef">
        <h2 v-if="contentRef.name">{{ contentRef.name }}</h2>
        <div v-html="contentRef.content"></div>
        <div></div>
      </div>
    </div>
  </section>
</template>

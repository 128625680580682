import axios from "../utils/axiosUtils";

function getWebsiteInfo() {
  let url = "/api/v3/content/collections/customerportal-content?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getAbout() {
  let url = "/api/v3/content/collections/about-us-content?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getContact() {
  let url = "/api/v3/content/collections/contact-us-content?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getAdvanceQuestions() {
  let url = "/api/v3/content/collections/faq-advance-questions?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getGeneralQuestions() {
  let url = "/api/v3/content/collections/faq-general-questions?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getRegularQuestions() {
  let url = "/api/v3/content/collections/faq-regular-questions?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getFlightHeaderContent() {
  let url = "/api/v3/content/collections/flight-page-header-content?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getFlightPromotionHotDeals() {
  let url =
    "/api/v3/content/collections/flight-page-promotion-primary-deals?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getFooterContent() {
  let url = "/api/v3/content/collections/footer-content?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function searchHeaderContent() {
  let url = "/api/v3/content/collections/header-content?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getHotelHeaderContent() {
  let url = "/api/v3/content/collections/hotel-page-header-content?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getHotelPromotionHotDeals() {
  let url =
    "/api/v3/content/collections/hotel-page-promotion-primary-deals?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getPartners() {
  let url = "/api/v3/content/collections/front-page-our-partners?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getPrivacyPolicyApi() {
  let url = "/api/v3/content/collections/privacy-policy-contents?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getBannerBackgroundImage() {
  let url =
    "/api/v3/content/collections/product-page-background-image?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getFrontIdealExperiences() {
  let url =
    "/api/v3/content/collections/front-page-ideal-experiences?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getPromotionFlights() {
  let url =
    "/api/v3/content/collections/front-page-promotion-flights?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getPromotionHotels() {
  let url =
    "/api/v3/content/collections/front-page-promotion-hotels?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

//Promotion Management
function getPromotionManagement() {
  let url = "/api/v3/content/collections/promotion-management?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

//Theme Color Management
function getThemeColorManagement() {
  let url = "/api/v3/content/collections/theme-color-management?populate=*";
  return axios.get(url);
}

//Content Management
function getContentManagement() {
  let url = "/api/v3/content/collections/content-management?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

/*
 * 获取优惠活动集合
 *@param promotionsName string 必填，优惠活动集合名，例如，popular-holiday-destinations，popular-hotel-destinations
 * */
function getPromotions(promotionsName) {
  let url = "/api/v3/content/collections/" + promotionsName + "?populate=*";
  return axios.get(url);
}

/*
 * 获取指定优惠活动
 * @param promotionsName string 必填，优惠活动集合名，例如，popular-holiday-destinations，popular-hotel-destinations
 * @param promotionId number 必填，优惠活动Id，由获取优惠活动集合的响应中获取，例如，1
 * */
function getPromotion(promotionsName, promotionId) {
  let url =
    "/api/v3/content/collections/" +
    promotionsName +
    "/" +
    promotionId +
    "?populate=*";
  return axios.get(url);
}

/*
 * 获取优惠活动的图片
 * @param imageName string 必填，优惠活动的图片名，由获取活动集合，或者由获取指定优惠活动的响应中获取，例如，thumbnail_PIC_2006_000682_b4ff639bfe.jpg
 */
function getPromotionImage(imageName) {
  let url =
    "/api/v3/content/collections/images/uploads/" + imageName + "?populate=*";
  return axios.get(url);
}

function getPackageHeaderContent() {
  let url =
    "/api/v3/content/collections/package-page-header-content?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getCarRentalHeaderContent() {
  let url =
    "/api/v3/content/collections/car-rental-page-header-content?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getActivityHeaderContent() {
  let url =
    "/api/v3/content/collections/activity-page-header-content?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getPackageIdealExperiences() {
  let url =
    "/api/v3/content/collections/package-page-ideal-experiences?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getCarRentalIdealExperiences() {
  let url =
    "/api/v3/content/collections/car-rental-page-ideal-experiences?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getActivityIdealExperiences() {
  let url =
    "/api/v3/content/collections/activity-page-ideal-experiences?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getPackagePromotionHotDeals() {
  let url =
    "/api/v3/content/collections/package-page-promotion-hot-deals?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getCarRentalPromotionHotDeals() {
  let url =
    "/api/v3/content/collections/car-rental-page-promotion-hot-deals?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getActivityPromotionHotDeals() {
  let url =
    "/api/v3/content/collections/activity-page-promotion-hot-deals?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function getSlotContents() {
  let url = "/api/v3/content/collections/slot-contents?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function findSlotContent(contentId) {
  let url =
    "/api/v3/content/collections/slot-contents/" + contentId + "?populate=*";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export default {
  getWebsiteInfo,
  getAbout,
  getContact,
  getAdvanceQuestions,
  getGeneralQuestions,
  getRegularQuestions,
  getFlightHeaderContent,
  getFlightPromotionHotDeals,
  getFooterContent,
  searchHeaderContent,
  getHotelHeaderContent,
  getHotelPromotionHotDeals,
  getPartners,
  getPrivacyPolicyApi,
  getBannerBackgroundImage,
  getFrontIdealExperiences,
  getPromotionFlights,
  getPromotionHotels,
  getPromotionManagement,
  getThemeColorManagement,
  getContentManagement,
  getPackageHeaderContent,
  getCarRentalHeaderContent,
  getActivityHeaderContent,
  getPackageIdealExperiences,
  getCarRentalIdealExperiences,
  getActivityIdealExperiences,
  getPackagePromotionHotDeals,
  getCarRentalPromotionHotDeals,
  getActivityPromotionHotDeals,
  getSlotContents,
  findSlotContent,
};

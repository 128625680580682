import axios from "@/utils/axiosUtils";

function getInvoiceById(invoiceId) {
  let url = "/api/v3/accounting/salesinvoices/" + invoiceId;
  return axios.get(url);
}

export default {
  getInvoiceById,
};

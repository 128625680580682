<script setup>
import { useRoute, useRouter } from "vue-router";
import { computed, nextTick, ref } from "vue";
import { Image } from "ant-design-vue";

const props = defineProps({
  imageList: {},
});

const AImage = Image;
const route = useRoute();
const router = useRouter();

//region 图片按照尺寸分类
const imageListShow = computed(() => {
  let categoryImageMap = {};
  if (props.imageList && props.imageList.length > 0) {
    categoryImageMap = props.imageList.reduce((acc, currentObj) => {
      const key =
        currentObj.imageFormatList && currentObj.imageFormatList.length > 0
          ? currentObj.imageFormatList[0].dimensionCategory
          : "";

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(currentObj);
      return acc;
    }, {});
  }
  return categoryImageMap;
});

//endregion

//图片滚动相关
const slideIndex = ref(0);
const scroll_div = ref(null);

function currentSlide(index) {
  slideIndex.value = index;
}

function move(n, imageList) {
  if (imageList && imageList.length > 0) {
    if (imageList.length <= slideIndex.value + n) {
      slideIndex.value = 0;
      slideBelow(slideIndex);
    } else if (slideIndex.value + n < 0) {
      slideIndex.value = imageList.length - 1;
      slideBelow(slideIndex);
    } else {
      slideIndex.value += n;
      slideBelow(slideIndex);
    }
  }
}

// 小图轮播
function slideBelow(slideIndex) {
  nextTick(() => {
    scroll_div.value.scrollLeft = 170 * slideIndex.value;
  });
}

//endregion
</script>

<template>
  <div
    class="image__gallery"
    v-if="
      imageListShow &&
      imageListShow['LARGE2'] &&
      imageListShow['LARGE2'].length > 0
    "
  >
    <div class="gallery">
      <div class="gallery__slideshow">
        <div class="gallery__slides">
          <div class="gallery__slide" style="width: 930px; height: 450px">
            <img
              v-if="imageListShow['LARGE2'][slideIndex]"
              class="gallery__img"
              alt=""
              :src="imageListShow['LARGE2'][slideIndex].url"
              style="
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin-left: 120px;
              "
            />
          </div>
        </div>
        <a
          @click.prevent="move(1, imageListShow['LARGE2'])"
          class="gallery__prev"
          >&#10095;
        </a>
        <a
          @click.prevent="move(-1, imageListShow['LARGE2'])"
          class="gallery__next"
          >&#10094;</a
        >
      </div>

      <div class="gallery__content">
        <div
          class="gallery__items"
          v-if="imageListShow['LARGE2'] && imageListShow['LARGE2'].length > 0"
          ref="scroll_div"
        >
          <div
            class="gallery__item"
            :class="{
              'gallery__item--is-acitve': slideIndex === imageIndex,
            }"
            v-for="(hotelImage, imageIndex) in imageListShow['LARGE2']"
            :key="imageIndex"
            style="width: 170px; height: 80px"
          >
            <img
              :src="hotelImage.url"
              @click="currentSlide(imageIndex)"
              class="gallery__item-img"
              style="width: 100%; height: 100%; object-fit: cover"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

import axios from "../utils/axiosUtils";
/*
 * 酒店搜索，根据请求内容的不同，分为城市范围搜索和指定酒店搜索
 * @param hotelAvailRQ object 必填，通过@tools/hotelTools#getHotelAvailRQ方法获取
 * */
function avail(hotelAvailRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-avail";
  return axios.post(url, hotelAvailRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/*
 * 酒店描述搜索，搜索指定酒店的描述内容，包括星级，地址，文字描述，图片，设施等
 * @param hotelDescriptiveRQ object 必填，通过@tools/hotelTools#getHotelDescriptiveRQ方法获取
 * */
function descriptive(hotelDescriptiveRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-descriptive-info";
  return axios.post(url, hotelDescriptiveRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/*
 * 房型库存保持
 * @param hotelResRQ object 必填，通过 @tools/hotelTools#getHoldRQ 方法获取
 * */
function hold(hotelResRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-res-confirm";
  return axios.post(url, hotelResRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/*
 * 房间订单初始化
 * @param preBookRQ object 必填，通过 @tools/hotelTools#getPreBookRQ 方法获取
 */
function preBook(preBookRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-res-prebook";
  return axios.post(url, preBookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/*
 * 订单确认
 * @param bookRQ object 必填，通过 @tools/hotelTools#getBookRQ 方法获取
 */
function book(bookRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-res";
  return axios.post(url, bookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
/*
 * 订单同步
 * @param hotelResModifyRQ object 必填，通过 @tools/bookingTools#getSynchronizeRQ 方法获取
 */
function hotelResModify(modifyRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-res-modify";
  return axios.post(url, modifyRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  avail,
  descriptive,
  hold,
  preBook,
  book,
  hotelResModify,
};

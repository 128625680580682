export class HotelReserveFormModel {
  /*
   *
   * @param languageCode
   * @param checkIn string 入住日期，格式为yyyy-MM-dd，例如，2023-06-12
   * @param checkOut string 离开日期，格式为yyyy-MM-dd，例如，2023-06-16
   * */
  constructor(languageCode, checkIn, checkOut, roomResGuests) {
    this.languageCode = languageCode;
    this.checkIn = checkIn;
    this.checkOut = checkOut;
    this.roomResGuests = roomResGuests;
  }

  /*
   *  新增一个房间-住客信息的映射
   * */
  addNewRoomResGuest(referenceId, roomTypeName, bedTypeName) {
    const roomResGuest = new HotelResRoomResGuestFormItem(
      referenceId,
      roomTypeName,
      bedTypeName
    );
    if (!this.roomResGuests) this.roomResGuests = [];
    this.roomResGuests.push(roomResGuest);
    return roomResGuest;
  }
}

export class HotelResRoomResGuestFormItem {
  /**
   *
   * @param referenceId string，房间房型唯一标识
   * @param roomTypeName
   * @param bedTypeName
   * @param adultNum
   * @param childNum
   * @param resGuests Array，HotelResGuestFormItem对象数组
   * @param remarks string，房间特殊要求
   */
  constructor(
    referenceId,
    roomTypeName,
    bedTypeName,
    adultNum,
    childNum,
    resGuests,
    remarks
  ) {
    this.referenceId = referenceId;
    this.resGuests = resGuests;
    this.roomTypeName = roomTypeName;
    this.bedTypeName = bedTypeName;
    this.adultNum = adultNum;
    this.childNum = childNum;
    this.remarks = remarks;
  }

  /*
   *
   * @param primaryIndicator boolean 判断是否为联系人,若为true，则为联系人
   * */
  addNewResGuest(primaryIndicator, rph, guestType, age) {
    let guestInfo = new HotelResGuestFormItem(
      primaryIndicator,
      age,
      guestType,
      rph,
      null,
      null,
      "MR",
      null,
      null,
      null,
      null
    );
    if (!this.resGuests) this.resGuests = [];
    this.resGuests.push(guestInfo);
    return guestInfo;
  }
}

export class HotelResGuestFormItem {
  /**
   *
   * @param primaryIndicator boolean，联系人标识
   * @param age
   * @param ageQualifyingCode
   * @param resGuestRPH
   * @param givenName
   * @param surname
   * @param nameTitle
   * @param emailAddress
   * @param countryAccessCode
   * @param phoneNumber
   * @param mainIndicator boolean，房间的主要人标识
   */
  constructor(
    primaryIndicator,
    age,
    ageQualifyingCode,
    resGuestRPH,
    givenName,
    surname,
    nameTitle,
    emailAddress,
    countryAccessCode,
    phoneNumber,
    mainIndicator
  ) {
    this.primaryIndicator = primaryIndicator;
    this.age = age;
    this.ageQualifyingCode = ageQualifyingCode;
    this.nameTitle = nameTitle;
    this.givenName = givenName;
    this.surname = surname;
    this.resGuestRPH = resGuestRPH;
    this.countryAccessCode = countryAccessCode;
    this.phoneNumber = phoneNumber;
    this.emailAddress = emailAddress;
    this.mainIndicator = mainIndicator;
  }
}

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import BaseRoomPassengerFormChildItem from "@/views/sales/hotel/items/form/BaseRoomPassengerFormChildItem.vue";
import BaseFlightPassengerChildAgeSelect from "@/views/sales/flight/items/form/BaseFlightPassengerChildAgeSelect.vue";

const props = defineProps({
  flightPassengers: {
    type: Object,
    description: "初始化航班乘客人数与作为类型",
  },
});

const cabinList = ["Y", "W", "C", "F"];

let cabinName = ref("Economy");
let activeClassIndex = ref(0);
let flightPassengers = reactive(props.flightPassengers);

let passengersNum = ref(computedflightPassengers());

watch(flightPassengers, () => {
  computedflightPassengers();
  emits("submitFlightPassengers", flightPassengers);
});

function computedflightPassengers() {
  return (passengersNum =
    parseInt(flightPassengers.adultNum) + parseInt(flightPassengers.childNum));
}
function getCabinName(cabinType) {
  if (cabinType == "Y") return "Economy";
  else if (cabinType == "W") return "Premium Economy";
  else if (cabinType == "F") return "First";
  else return "Business";
}
const cabinTypeName = computed(() => (cabinType) => {
  if (cabinType == "Y") return "Economy";
  else if (cabinType == "W") return "Premium Economy";
  else if (cabinType == "F") return "First";
  else return "Business";
});

function getCabin(index, cabinType) {
  activeClassIndex.value = index;
  flightPassengers.cabinClass = cabinType;
  cabinName = getCabinName(cabinType);
}

// Validation rules
const v = useVuelidate();
//定义传值函数
const emits = defineEmits(["submitFlightPassengers"]);

let isFormCorrect = ref(true);

//主动校验，让输入框显示错误
onMounted(() => {
  document
    .getElementById("flightPassengerForm")
    .addEventListener("hidden.bs.dropdown", async () => {
      isFormCorrect.value = await v.value.$validate();
    });
});

async function submitFlightPassengersForm() {
  //在父组件中调用，则会校验所有子组件的规则，并统一返回校验结果
  isFormCorrect.value = await v.value.$validate();
  if (!isFormCorrect.value) return;

  emits("submitFlightPassengers", flightPassengers);
  document.getElementById("flightPassengerForm").classList.remove("show");
}

//数量加
function increment(type, num) {
  if (
    parseInt(flightPassengers.adultNum) +
      parseInt(flightPassengers.childNum) +
      parseInt(flightPassengers.infantNum) <
    9
  ) {
    num++;
    if (type == "a") {
      flightPassengers.adultNum = num;
    } else if (type == "b") {
      flightPassengers.childNum = num;
    } else {
      flightPassengers.infantNum = num;
    }
  }
}

//数量减
function decrement(type, num) {
  if (num === 0) return;
  num -= 1;

  if (type == "a") {
    flightPassengers.adultNum = num;
  } else if (type == "b") {
    flightPassengers.childNum = num;
  } else {
    flightPassengers.infantNum = num;
  }
}
</script>
<template>
  <div class="search_boxed dropdown_passenger_area">
    <p>{{ $t("passenger-class") }}</p>
    <div class="dropdown">
      <button
        id="dropdownMenuButton2"
        aria-expanded="false"
        class="dropdown-toggle final-count"
        data-bs-auto-close="outside"
        data-bs-toggle="dropdown"
        data-toggle="dropdown"
        type="button"
      >
        {{ $t("num-passenger", { passengersNum: passengersNum }) }}
      </button>

      <div
        id="flightPassengerForm"
        aria-labelledby="dropdownMenuButton2"
        class="dropdown-menu dropdown_passenger_info"
      >
        <div class="traveller-calulate-persons">
          <div class="passengers">
            <h6>{{ $t("passengers") }}</h6>
            <div class="passengers-types">
              <div class="passengers-type">
                <div class="text">
                  <span class="count">{{ flightPassengers.adultNum }}</span>
                  <div class="type-label">
                    <p>{{ $t("adult") }}</p>
                    <span>12+ yrs</span>
                  </div>
                </div>
                <div class="button-set">
                  <button
                    class="btn-add"
                    type="button"
                    @click="increment('a', flightPassengers.adultNum)"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                  <button
                    class="btn-subtract"
                    type="button"
                    @click="decrement('a', flightPassengers.adultNum)"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="passengers-type">
                <div class="text">
                  <span class="count">{{ flightPassengers.childNum }}</span>
                  <div class="type-label">
                    <p class="fz14 mb-xs-0">{{ $t("children") }}</p>
                    <span>{{ $t("child-less-than") }}</span>
                  </div>
                </div>
                <div class="button-set">
                  <button
                    class="btn-add-c"
                    type="button"
                    @click="increment('b', flightPassengers.childNum)"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                  <button
                    class="btn-subtract-c"
                    type="button"
                    @click="decrement('b', flightPassengers.childNum)"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
              <!--              <div class="passengers-type">-->
              <!--                <div class="text">-->
              <!--                  <span class="count">{{ flightPassengers.infantNum }}</span>-->
              <!--                  <div class="type-label">-->
              <!--                    <p class="fz14 mb-xs-0">{{ $t("infant") }}</p>-->
              <!--                    <span>{{ $t("infant-less-than") }}</span>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="button-set">-->
              <!--                  <button-->
              <!--                    class="btn-add-in"-->
              <!--                    type="button"-->
              <!--                    @click="increment('i', flightPassengers.infantNum)"-->
              <!--                  >-->
              <!--                    <i class="fas fa-plus"></i>-->
              <!--                  </button>-->
              <!--                  <button-->
              <!--                    class="btn-subtract-in"-->
              <!--                    type="button"-->
              <!--                    @click="decrement('i', flightPassengers.infantNum)"-->
              <!--                  >-->
              <!--                    <i class="fas fa-minus"></i>-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
            <!--            <div class="mb-4 row mg-t-10">-->
            <!--              <BaseFlightPassengerChildAgeSelect-->
            <!--                v-if="flightPassengers.childNum > 0"-->
            <!--              />-->
            <!--            </div>-->
          </div>
          <div class="cabin-selection">
            <h6>{{ $t("cabin-class") }}</h6>
            <div v-for="(cabinType, index) in cabinList" class="cabin-list">
              <button
                :class="{ active: index === activeClassIndex }"
                class="label-select-btn"
                type="radio"
                name="cabinClass"
                @click="getCabin(index, cabinType)"
              >
                <span class="muiButton-label">{{
                  cabinTypeName(cabinType)
                }}</span>
              </button>
            </div>
          </div>
          <div class="text-center">
            <button
              class="btn col-11"
              type="submit"
              @click="submitFlightPassengersForm"
            >
              {{ $t("done") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <span>{{ cabinName }} </span>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const props = defineProps({
  childIndex: {
    type: Number,
    description: "儿童索引",
  },
  childAge: {
    type: Object,
    description: "儿童岁数",
  },
  disabled: {
    type: Boolean,
  },
});

// options for select
const childAgeOptions = reactive([
  { value: "1", text: "1" },
  { value: "2", text: "2" },
  { value: "3", text: "3" },
  { value: "4", text: "4" },
  { value: "5", text: "5" },
  { value: "6", text: "6" },
  { value: "7", text: "7" },
  { value: "8", text: "8" },
  { value: "9", text: "9" },
  { value: "10", text: "10" },
  { value: "11", text: "11" },
  { value: "12", text: "12" },
]);

// Use vuelidate
const rules = {
  age: {
    required,
  },
};
const v = useVuelidate(rules, props.childAge);
</script>

<template>
  <div class="col-6">
    <label class="form-label flex" for="'val-childAge' + childIndex"
      ><span>{{ $t("child") }} {{ childIndex + 1 }} {{ $t("age") }}</span>
      <span class="text-danger mg-l-5">*</span></label
    >
    <select
      id="'val-childAge' + childIndex"
      v-model="v.age.$model"
      :class="{
        'is-invalid': v.age.$errors.length > 0,
      }"
      :disabled="disabled"
      class="form-select"
      @blur="v.age.$touch"
    >
      <option :value="null">
        {{ $t("please-select") }}
      </option>
      <option
        v-for="(option, index) in childAgeOptions"
        :key="`option-${index}`"
        :class="{
          'is-invalid': v.age.$errors.length > 0,
        }"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
    <div
      v-if="v.age.$errors.length > 0"
      class="invalid-feedback animated fadeIn"
    >
      {{ $t("select-the-child-age") }}
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

export class CarRentalDriverFormModel {
  constructor(
    nameTitle,
    surname,
    givenName,
    phoneNumber,
    countryAccessCode, //电话号码区号
    emailAddress,
    citizenCountry //司机所在国家
  ) {
    this.nameTitle = nameTitle;
    this.surname = surname;
    this.givenName = givenName;
    this.phoneNumber = phoneNumber;
    this.countryAccessCode = countryAccessCode;
    this.emailAddress = emailAddress;
    this.citizenCountry = citizenCountry;
  }
}

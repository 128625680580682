export class FlightReshopFareItemVO {
  /**
   *
   * @param currencyCode
   * @param taxAmount
   * @param totalAmount
   * @param flightRefNumbers
   * @param travelerRefNumbers
   */
  constructor(
    currencyCode,
    taxAmount,
    totalAmount,
    flightRefNumbers,
    travelerRefNumbers
  ) {
    this.currencyCode = currencyCode;
    this.taxAmount = taxAmount;
    this.totalAmount = totalAmount;
    this.flightRefNumbers = flightRefNumbers;
    this.travelerRefNumbers = travelerRefNumbers;
  }

  addFlightRefNumber(flightRefNumber) {
    if (!this.flightRefNumbers) this.flightRefNumbers = [];
    if (this.flightRefNumbers.indexOf(flightRefNumber) >= 0) return;

    this.flightRefNumbers.push(flightRefNumber);
  }

  addTravelerRefNumber(travelerRefNumber) {
    if (!this.travelerRefNumbers) this.travelerRefNumbers = [];
    if (this.travelerRefNumbers.indexOf(travelerRefNumber) >= 0) return;

    this.travelerRefNumbers.push(travelerRefNumber);
  }
}

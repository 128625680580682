import AboutUsView from "@/views/about/AboutUsView.vue";

export default [
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUsView,
    meta: {
      footerShow: true,
    },
  },
];

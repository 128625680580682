<script setup>
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { reactive, ref, watch, watchEffect } from "vue";
import { useCmsContentStore } from "@/stores/cms";
import { Modal } from "ant-design-vue";
import { TripsReadFormModel } from "@/formmodel/sales/trip/tripsReadFormModel";
import { useTripStore } from "@/stores/v2/trip/tripStore";
import BackButton from "@/components/custom/BackButton.vue";
import ReservationDetailsFlightPanel from "@/views/v2/trip/booking/items/panel/flight/ReservationDetailsFlightPanel.vue";
import ReservationDetailsHotelPanel from "@/views/v2/trip/booking/items/panel/hotel/ReservationDetailsHotelPanel.vue";
import ReservationDetailsActivityPanel from "@/views/v2/trip/booking/items/panel/activity/ReservationDetailsActivityPanel.vue";
import ReservationDetailsCarRentalPanel from "@/views/v2/trip/booking/items/panel/carrental/reservation/ReservationDetailsCarRentalPanel.vue";

const AModal = Modal;
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const tripStore = useTripStore();

const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContent = cmsContentStore.productPageBackgroundImage;
  imageUrl.value =
    cmsContentStore.productPageBackgroundImage &&
    cmsContentStore.productPageBackgroundImage.bookingDetialsBackground
      ? cmsContentStore.productPageBackgroundImage.bookingDetialsBackground.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});

const tripId = route.query.tripId;
const recordLocator = route.query.recordLocator;
const idContext = route.query.idContext;
const tripItemType = route.query.tripItemType;
const isFromTripList =
  route.query && route.query.isFromTripList
    ? JSON.parse(route.query.isFromTripList)
    : false;

const tripsReadFormModelRef = ref(
  new TripsReadFormModel("BOOKING_ID", tripId, 1, 0, null, null, null, null)
);
let messageContent = reactive({
  show: false,
  message: null,
  type: null,
});

syncSessionAndSearch(tripsReadFormModelRef.value);

async function syncSessionAndSearch(tripsReadFormModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await tripStore.tripsRead(tripsReadFormModel);
  templateStore.pageLoader({ mode: "off" });
}

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("booking-details") }}</h2>
        <ul>
          <li class="pre-page" @click="router.push('/')">
            {{ $t("home") }}
          </li>
          <li
            class="pre-page"
            @click="router.push('/profiles/customer-dashboard')"
          >
            Customer Dashboard
          </li>

          <li>
            <i class="fas fa-circle"></i>
            {{ $t("Customer Dashboard") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row" v-if="equipmentType !== 'iphone'">
        <div
          class="col-lg-12 col-md-12 col-sm-12 col-12"
          style="margin-left: 8%"
        >
          <BackButton
            :button-name="'Go to booking list'"
            :route-name="'profiles-customer-dashboard'"
          ></BackButton>
        </div>
      </div>
      <div class="row margin-top-20">
        <div class="col-lg-10 mx-auto">
          <div class="tou_booking_form_Wrapper">
            <div
              class="alert d-flex align-items-center justify-content-between"
              :class="{
                'alert-danger': messageContent.showType === 'alert-danger',
                'alert-success': messageContent.showType === 'alert-success',
              }"
              role="alert"
              v-if="messageContent.show"
            >
              <div class="flex-grow-1 me-3">
                <p class="mb-0">
                  {{ messageContent.message }}
                </p>
              </div>
              <div class="flex-shrink-0">
                <i
                  class="fa fa-fw fa-times-circle"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></i>
              </div>
            </div>

            <!-- Page Content -->
            <div class="content">
              <ReservationDetailsFlightPanel
                v-if="tripItemType === 'AIR'"
                :is-from-trip-list="isFromTripList"
                :trip-id="tripId"
                :id-context="idContext"
                :record-locator="recordLocator"
                :trip-item-type="tripItemType"
                :equipment-type="equipmentType"
                @syncSessionAndSearch="
                  syncSessionAndSearch(tripsReadFormModelRef)
                "
              />

              <ReservationDetailsHotelPanel
                v-else-if="tripItemType === 'HOTEL'"
                :is-from-trip-list="isFromTripList"
                :trip-id="tripId"
                :trip-item-type="tripItemType"
                @syncSessionAndSearch="
                  syncSessionAndSearch(tripsReadFormModelRef)
                "
              />

              <ReservationDetailsActivityPanel
                v-else-if="tripItemType === 'ACTIVITY'"
                :is-from-trip-list="isFromTripList"
                :trip-id="tripId"
                :trip-item-type="tripItemType"
                @syncSessionAndSearch="
                  syncSessionAndSearch(tripsReadFormModelRef)
                "
              />

              <ReservationDetailsCarRentalPanel
                v-else-if="tripItemType === 'CARRENTAL'"
                :is-from-trip-list="isFromTripList"
                :trip-id="tripId"
                :trip-item-type="tripItemType"
                @syncSessionAndSearch="
                  syncSessionAndSearch(tripsReadFormModelRef)
                "
              />
            </div>

            <!-- END Page Content -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

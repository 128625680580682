<template>

    <!-- Common Banner Area -->
    <BookingHistoryBanner />

    <!-- Dashboard Area -->
    <BookingHistoryDashboard />

    <!-- Cta Area -->
    <Cta />


</template>
<script>
import BookingHistoryBanner from '@/components/templates/dashboard/BookingHistoryBanner.vue'
import BookingHistoryDashboard from '@/components/templates/dashboard/BookingHistoryDashboard.vue'
import Cta from '@/components/templates/home/Cta.vue'

export default {
    name: "BookingHistoryView",
    components: {
        BookingHistoryBanner, BookingHistoryDashboard, Cta
    }
};
</script>
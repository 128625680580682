/**
 *
 * @param carRentalItems Array，carRentalItemVO对象数组，数据源
 * @param filterFormModel Object，carRentalResultsFilterFormModel对象，包含过滤条件
 * @returns carRentalItems Array，carRentalItemVO对象数组，过滤后的数组
 */
function filterCarRentalItems(carRentalItems, filterFormModel) {
  if (!carRentalItems || carRentalItems.length === 0) return carRentalItems;
  const carRentalItemsCopy = JSON.parse(JSON.stringify(carRentalItems));

  const transmission = filterFormModel.transmission;
  const priceRange = filterFormModel.priceRange;
  const seats = filterFormModel.seats;

  return carRentalItemsCopy.filter((carRentalItem) => {
    const passengerQuantityCompare = carRentalItem.passengerQuantity;
    const transmissionTypeCompare = carRentalItem.transmissionType;
    const totalAmountCompare = carRentalItem.totalAmount;

    //根据transmission过滤
    if (transmission && transmission !== "") {
      if (transmission !== transmissionTypeCompare) return false;
    }

    //根据seats筛选
    if (seats && seats.length > 0) {
      let oneMatch = false;
      for (const seat of seats) {
        if (seat === passengerQuantityCompare) {
          oneMatch = true;
        }
      }
      if (!oneMatch) return false;
    }

    //根据平均每天房价范围过滤
    if (priceRange && priceRange.length > 0) {
      const minDailyPrice = priceRange[0];
      const maxDailyPrice = priceRange[1];

      if (totalAmountCompare < minDailyPrice) return false;
      if (maxDailyPrice !== 1000 && totalAmountCompare > maxDailyPrice)
        return false;
    }

    return true;
  });
}

export default {
  filterCarRentalItems,
};

<script setup>
import { ref, watch } from "vue";
import dateUtils from "@/utils/dateUtils";
import DateUtils from "@/utils/dateUtils";
import FlatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/zh";
import "flatpickr/dist/l10n/ja";
import "flatpickr/dist/l10n/ko";
import "flatpickr/dist/l10n/fr";

import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import commonUtils from "@/utils/commonUtils";
import { DynamicPkgAvailFormModel } from "@/formmodel/sales/dynamicPkg/availability/dynamicPkgAvailFormModel";
import { usePackageHotelAvailStore } from "@/stores/v2/dynamicPkg/availability/packageHotelAvailStore";
import dynamicPkgCommonTools from "@/tools/v2/dynamicPkg/common/dynamicPkgCommonTools";
import DynamicPkgAvailRoomPassengerForm from "@/views/v2/sales/dynamicpkg/items/form/avail/DynamicPkgAvailRoomPassengerForm.vue";
import cabinTypeOptions from "@/formmodel/sales/common/cabinTypeOptions";
import contentApi from "@/apis/contentApi";

const props = defineProps({
  jumboable: {
    type: Boolean,
  },
  jumpTo: {
    type: String,
    description: "route name to jump",
  },
});

const { t, locale } = useI18n(); // 解构调用函数
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const packageHotelAvailStore = usePackageHotelAvailStore();

//region 初始化数据
const suggestPlaceOptionsRef = ref([]);
const childAgesValidCheckRef = ref(false);
const isMoreTwoItemsRef = ref(true); //是否超过两个项目
const productOptionsRef = ref([
  { code: "FLIGHT", name: "flight" },
  { code: "HOTEL", name: "hotel" },
  // { code: "CAR", name: "car" },
]);
const selectedProductMapRef = ref({
  FLIGHT: true,
  HOTEL: true,
  CAR: false,
}); //记录已选择车辆类型的状态

const dynamicPkgAvailFormModelRef = ref(
  new DynamicPkgAvailFormModel(
    locale.value,
    "AIRPORT",
    null,
    null,
    null,
    null,
    null,
    dateUtils.getOffsetDate(new Date(), 7),
    dateUtils.getOffsetDate(new Date(), 8),
    [],
    "Y",
    null,
    null,
    null,
    null
  )
);
dynamicPkgAvailFormModelRef.value.addNewRoomPassenger(1, 0, []);

const flatPickrStateRef = ref({
  // Initial values
  dateRange: [
    dynamicPkgAvailFormModelRef.value.checkInDate,
    dynamicPkgAvailFormModelRef.value.checkOutDate,
  ],
  configRange: {
    mode: "range",
    minDate: dateUtils.getOffsetDate(new Date(), 2),
    dateFormat: "Y-m-d",
    locale:
      locale.value === "zh_CN" ||
      locale.value === "zh_TW" ||
      locale.value === "zh_HK"
        ? "zh"
        : locale.value,
  },
});

//endregion

//region 表单校验和提交
const rulesRef = ref({
  originLocationCode: {
    required,
  },
  destinationLocationCode: {
    required,
  },
  checkInDate: {
    required,
  },
  checkOutDate: {
    required,
  },
});
const v = useVuelidate(rulesRef.value, dynamicPkgAvailFormModelRef);

async function submitHotelAvailFormModel(
  dynamicPkgAvailFormModel,
  selectedProductMap
) {
  childAgesValidCheckRef.value = false;
  //在父组件中调用，则会校验所有子组件的规则，并统一返回校验结果
  const validateResult = await v.value.$validate();
  if (v.value.$errors && v.value.$errors.length > 0) {
    v.value.$errors.forEach((item) => {
      if (item != null && item.$property === "age") {
        childAgesValidCheckRef.value = true;
      }
    });
  }
  if (!validateResult) return;

  let formModelCopy = JSON.parse(JSON.stringify(dynamicPkgAvailFormModel));
  const shoppingQueryModel =
    dynamicPkgCommonTools.formatHotelAvailFormModel(formModelCopy);
  shoppingQueryModel.roomPassengersFormat =
    dynamicPkgCommonTools.formatRoomPassengers(formModelCopy.roomPassengers);
  shoppingQueryModel.carChoose = selectedProductMap["CAR"];
  shoppingQueryModel.flightChoose = selectedProductMap["FLIGHT"];
  shoppingQueryModel.hotelChoose = selectedProductMap["HOTEL"];

  if (props.jumboable) {
    router.push({
      name: props.jumpTo,
      query: shoppingQueryModel,
    });
  } else {
    router.replace({ query: shoppingQueryModel });
    searchHotelByFormModel(dynamicPkgAvailFormModel);
  }
}

//endregion

//#region query参数请求，进行搜索
if (!props.jumboable) {
  if (route.query != null && Object.keys(route.query).length > 0) {
    selectedProductMapRef.value["CAR"] = JSON.parse(route.query.carChoose);
    selectedProductMapRef.value["FLIGHT"] = JSON.parse(
      route.query.flightChoose
    );
    selectedProductMapRef.value["HOTEL"] = JSON.parse(route.query.hotelChoose);
    dynamicPkgAvailFormModelRef.value =
      dynamicPkgCommonTools.parseDynamicPkgAvailFormModel(route.query);

    flatPickrStateRef.value.dateRange = [
      dynamicPkgAvailFormModelRef.value.checkInDate,
      dynamicPkgAvailFormModelRef.value.checkOutDate,
    ];
    searchHotelByFormModel(dynamicPkgAvailFormModelRef.value);
  }
}

//#endregion

//region 语言改变，重新搜索
watch(
  () => locale.value,
  (newValue) => {
    dynamicPkgAvailFormModelRef.value.languageCode = newValue;
    const queryCopy = JSON.parse(JSON.stringify(route.query));
    if (queryCopy && queryCopy.languageCode) {
      queryCopy.languageCode = newValue;
      router.replace({ query: queryCopy });
    }

    flatPickrStateRef.value.configRange.locale =
      newValue === "zh_CN" || newValue === "zh_TW" || newValue === "zh_HK"
        ? "zh"
        : newValue;
  }
);
//endregion
//region 表单相关函数
function getSuggestPlaces(keyword, type) {
  const fromId = document.getElementById("leaving-from-dropdown-menu");
  const toId = document.getElementById("going-to-dropdown-menu");
  if (type === "from") {
    toId.classList.remove("show");
    if (!dynamicPkgAvailFormModelRef.value.destinationLocationCode) {
      dynamicPkgAvailFormModelRef.value.destinationLocationName = null;
    }
    fromId.classList.add("show");
  } else {
    fromId.classList.remove("show");
    if (!dynamicPkgAvailFormModelRef.value.originLocationCode) {
      dynamicPkgAvailFormModelRef.value.originLocationName = null;
    }
    toId.classList.add("show");
  }
  //当对应码相同时优先，对应码相同且popularity更先优先，对应码相同且popularity相同对比name，其余比较popularity
  const searchDebounceFUN = commonUtils.debounce(
    function () {
      contentApi.airportCities(keyword, "en").then((res) => {
        if (res.data && res.data.length > 0) {
          res.data.sort(function (a, b) {
            if (
              a.code === keyword.toUpperCase() &&
              b.code !== keyword.toUpperCase()
            ) {
              return -1;
            } else if (
              b.code === keyword.toUpperCase() &&
              a.code !== keyword.toUpperCase()
            ) {
              return 1;
            } else if (a.code === a.cityCode && b.code !== b.cityCode) {
              return -1;
            } else if (a.code !== a.cityCode && b.code === b.cityCode) {
              return 1;
            } else if (a.popularity === b.popularity) {
              return a.name - b.name;
            } else {
              return a.popularity - b.popularity;
            }
          });
        }
        suggestPlaceOptionsRef.value = res.data;
      });
    },
    "searchDynamicPkg",
    500
  );

  searchDebounceFUN();
}

function selectPlace(suggestPlace, type) {
  const fromId = document.getElementById("leaving-from-dropdown-menu");
  const toId = document.getElementById("going-to-dropdown-menu");
  let name = suggestPlace.name;
  if (suggestPlace.code === suggestPlace.cityCode)
    name = suggestPlace.city + "(" + suggestPlace.code + ")";
  if (type === "from") {
    dynamicPkgAvailFormModelRef.value.originLocationCode = suggestPlace.code;

    dynamicPkgAvailFormModelRef.value.originLocationName = name;
    fromId.classList.remove("show");
  } else {
    dynamicPkgAvailFormModelRef.value.destinationLocationCode =
      suggestPlace.code;
    dynamicPkgAvailFormModelRef.value.destinationLocationName = name;
    toId.classList.remove("show");
  }
}

function updateCheckInAndCheckOutDates(dates) {
  if (dates.length === 2) {
    const checkIn = DateUtils.formatDate(dates[0]);
    const checkOut = DateUtils.formatDate(dates[1]);
    dynamicPkgAvailFormModelRef.value.checkInDate = checkIn;
    dynamicPkgAvailFormModelRef.value.checkOutDate = checkOut;
  } else {
    dynamicPkgAvailFormModelRef.value.checkInDate = null;
    dynamicPkgAvailFormModelRef.value.checkOutDate = null;
  }
}

async function searchHotelByFormModel(formModel) {
  templateStore.pageLoader({ mode: "on" });
  //每次搜索之后存储表单
  sessionStorage.setItem("dynamicPkgAvailFormModel", JSON.stringify(formModel));
  let res = await packageHotelAvailStore.hotelAvail(formModel);
  templateStore.pageLoader({ mode: "off" });
}

//endregion

function chooseProductType(productCode) {
  if (selectedProductMapRef.value[productCode]) {
    selectedProductMapRef.value[productCode] = false;
  } else {
    selectedProductMapRef.value[productCode] = true;
  }

  //判断是否选择超过两个项目
  let selectedValues = [];
  if (selectedProductMapRef.value) {
    selectedValues = Object.values(selectedProductMapRef.value).filter(
      (product) => product
    );
  }
  if (selectedValues.length >= 2) {
    isMoreTwoItemsRef.value = true;
  } else {
    isMoreTwoItemsRef.value = false;
  }
}
</script>

<template>
  <div
    :class="{
      theme_search_form_area: props.jumpTo == null,
    }"
  >
    <div class="row">
      <div class="col-3">
        <button
          type="button"
          class="btn rounded-pill mg-r-5 mg-b-10 font-14"
          :class="{
            'btn-outline-secondary': !selectedProductMapRef[production.code],
            'btn-secondary': selectedProductMapRef[production.code],
          }"
          v-for="(production, productionIndex) in productOptionsRef"
          :key="productionIndex"
          @click="chooseProductType(production.code)"
        >
          {{ production.name ? $t(production.name) : null }}
          <span v-if="selectedProductMapRef[production.code]">{{
            $t("added")
          }}</span>
        </button>
      </div>
      <div class="col-2" v-if="isMoreTwoItemsRef">
        <select
          v-model="dynamicPkgAvailFormModelRef.cabinTypeCode"
          class="form-select"
          name="stops-select"
        >
          <option
            v-for="(cabinOption, index) in cabinTypeOptions.cabinTypes"
            :key="index"
            :value="cabinOption.code"
          >
            {{ cabinOption.name ? $t(cabinOption.name) : null }}
          </option>
        </select>
      </div>
    </div>
    <form class="row mg-t-10" @sumbit.prevent v-if="isMoreTwoItemsRef">
      <div class="col-3">
        <div class="search_boxed">
          <p>{{ $t("leaving-from") }}</p>
          <input
            id="leaving-from"
            v-model="dynamicPkgAvailFormModelRef.originLocationName"
            :class="{
              'is-invalid':
                v.originLocationCode.$errors.length > 0 ||
                (dynamicPkgAvailFormModelRef.originLocationName &&
                  dynamicPkgAvailFormModelRef.destinationLocationName &&
                  dynamicPkgAvailFormModelRef.originLocationName ===
                    dynamicPkgAvailFormModelRef.destinationLocationName),
            }"
            @blur="v.originLocationCode.$touch"
            aria-expanded="false"
            aria-haspopup="true"
            autocomplete="off"
            class="form-control empty-border-left"
            data-bs-toggle="dropdown"
            :placeholder="$t('leaving-from')"
            name="leaving-from"
            type="text"
            @input="(event) => getSuggestPlaces(event.target.value, 'from')"
          />
          <span
            id="whereGo"
            class="text-danger"
            v-if="
              v.originLocationCode.$errors.length > 0 ||
              (dynamicPkgAvailFormModelRef.originLocationName &&
                dynamicPkgAvailFormModelRef.destinationLocationName &&
                dynamicPkgAvailFormModelRef.originLocationName ===
                  dynamicPkgAvailFormModelRef.destinationLocationName)
            "
            >Please enter location</span
          >
          <span id="whereGo" v-else>Where are you leaving?</span>
          <div
            id="leaving-from-dropdown-menu"
            aria-labelledby="leaving-from"
            class="dropdown-menu fs-sm full-width"
          >
            <div
              v-if="suggestPlaceOptionsRef && suggestPlaceOptionsRef.length > 0"
            >
              <a
                v-for="(suggest, suggestIndex) in suggestPlaceOptionsRef"
                :key="suggestIndex"
                class="dropdown-item"
                href="javascript:void(0)"
                @click="selectPlace(suggest, 'from')"
              >
                <i
                  v-if="suggest.code === suggest.cityCode"
                  class="fa fa-location-dot"
                ></i>
                <i v-else class="fa fa-plane-departure"></i>
                {{
                  suggest.code === suggest.cityCode
                    ? suggest.city + "(" + suggest.code + ")"
                    : suggest.name
                }}
              </a>
            </div>
            <div v-else>
              <div class="dropdown-item">
                <i class="fa fa-magnifying-glass me-3"></i>
                {{ $t("search-by-destination") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="search_boxed">
          <p>{{ $t("going-to") }}</p>
          <input
            id="going-to"
            v-model="dynamicPkgAvailFormModelRef.destinationLocationName"
            :class="{
              'is-invalid':
                v.destinationLocationCode.$errors.length > 0 ||
                (dynamicPkgAvailFormModelRef.originLocationName &&
                  dynamicPkgAvailFormModelRef.destinationLocationName &&
                  dynamicPkgAvailFormModelRef.originLocationName ===
                    dynamicPkgAvailFormModelRef.destinationLocationName),
            }"
            @blur="v.destinationLocationCode.$touch"
            aria-expanded="false"
            aria-haspopup="true"
            autocomplete="off"
            class="form-control empty-border-left"
            data-bs-toggle="dropdown"
            :placeholder="$t('going-to')"
            name="sales-hotel-destination"
            type="text"
            @input="(event) => getSuggestPlaces(event.target.value, 'to')"
          />
          <span
            id="whereGo"
            class="text-danger"
            v-if="
              v.destinationLocationCode.$errors.length > 0 ||
              (dynamicPkgAvailFormModelRef.originLocationName &&
                dynamicPkgAvailFormModelRef.destinationLocationName &&
                dynamicPkgAvailFormModelRef.originLocationName ===
                  dynamicPkgAvailFormModelRef.destinationLocationName)
            "
            >Please enter location</span
          >
          <span id="whereGo" v-else>{{ $t("where-are-you-going") }}?</span>
          <div
            id="going-to-dropdown-menu"
            aria-labelledby="going-to"
            class="dropdown-menu fs-sm full-width"
          >
            <div
              v-if="suggestPlaceOptionsRef && suggestPlaceOptionsRef.length > 0"
            >
              <a
                v-for="(suggest, suggestIndex) in suggestPlaceOptionsRef"
                :key="suggestIndex"
                class="dropdown-item"
                href="javascript:void(0)"
                @click="selectPlace(suggest, 'to')"
              >
                <i
                  v-if="suggest.code === suggest.cityCode"
                  class="fa fa-location-dot"
                ></i>
                <i v-else class="fa fa-plane-departure"></i>
                {{
                  suggest.code === suggest.cityCode
                    ? suggest.city + "(" + suggest.code + ")"
                    : suggest.name
                }}
              </a>
            </div>
            <div v-else>
              <div class="dropdown-item">
                <i class="fa fa-magnifying-glass me-3"></i>
                {{ $t("search-by-destination") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="form_search_date">
          <div class="search_boxed">
            <p>{{ $t("check-in-to-check-out") }}</p>
            <FlatPickr
              id="sales-flatpickr-range"
              v-model="flatPickrStateRef.dateRange"
              :class="{
                'is-invalid':
                  v.checkInDate.$errors.length > 0 ||
                  v.checkOutDate.$errors.length > 0 ||
                  v.checkInDate.$model === v.checkOutDate.$model,
              }"
              :config="flatPickrStateRef.configRange"
              class="form-control"
              placeholder="Check Date Range"
              @on-change="updateCheckInAndCheckOutDates"
            />
            <span class="mg-l-8"
              >{{
                dateUtils.getDaysByDateRange(
                  dynamicPkgAvailFormModelRef.checkInDate,
                  dynamicPkgAvailFormModelRef.checkOutDate
                )
              }}
              {{ $t("night") }} (s)
            </span>
          </div>
        </div>
      </div>
      <div class="col-3">
        <DynamicPkgAvailRoomPassengerForm
          :child-ages-valid-check="childAgesValidCheckRef"
          :dynamic-pkg-avail-form-model="dynamicPkgAvailFormModelRef"
        />
      </div>
      <div class="col-lg-12 col-12 mg-t-10 text-center">
        <button
          class="btn btn_theme btn_md"
          type="button"
          @click="
            submitHotelAvailFormModel(
              dynamicPkgAvailFormModelRef,
              selectedProductMapRef
            )
          "
        >
          {{ $t("search") }}
        </button>
      </div>
    </form>
    <!-- 空项目提示-->
    <div class="text-center mg-b-10 mg-t-10" v-else>
      <div><i class="fa-solid fa-magnifying-glass font-24"></i></div>
      <div class="font_weight">Please select two or more items</div>
      <div class="text-muted">
        To start building your trip, choose two or more items
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

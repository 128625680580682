import { HotelItemsSortFormModel } from "@/formmodel/sales/hotel/availability/hotelItemsSortFormModel";

function buildResultsSortFormModel() {
  return new HotelItemsSortFormModel();
}

/**
 *
 * @param hotelItems Array，HotelItemVO对象数组，数据源
 * @param sortFormModel Object，HotelResultsSortFormModel对象，包含排序条件
 * @returns hotelItems Array，HotelItemVO对象数组，排序后的数组
 */
function sortHotelItems(hotelItems, sortFormModel) {
  if (!hotelItems || hotelItems.length === 0) return hotelItems;

  let type = sortFormModel.type;
  let order = sortFormModel.order;

  return hotelItems.sort((a, b) => {
    if (type === "RATING" && order === "ASC") {
      //升序
      return a.starRating - b.starRating;
    } else if (type === "RATING" && order === "DSC") {
      //降序
      return b.starRating - a.starRating;
    } else if (type === "USER_RATING" && order === "ASC") {
      //升序
      return a.userRating - b.userRating;
    } else if (type === "USER_RATING" && order === "DSC") {
      //降序
      return b.userRating - a.userRating;
    } else if (type === "PRICE" && order === "ASC") {
      //升序
      return a.bestAvgUnitPrice - b.bestAvgUnitPrice;
    } else if (type === "PRICE" && order === "DSC") {
      //降序
      return b.bestAvgUnitPrice - a.bestAvgUnitPrice;
    } else if (type === "NAME" && order === "ASC") {
      //升序
      return a.hotelName.localeCompare(b.hotelName);
    } else if (type === "NAME" && order === "DSC") {
      //降序
      return b.hotelName.localeCompare(a.hotelName);
    }
  });
}

export default {
  buildResultsSortFormModel,
  sortHotelItems,
};

<script setup>
import Banner from "@/components/custom/Banner.vue";
import { useHotelStore } from "@/stores/hotel";
import { computed, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import BaseCancelPolicyPanel from "@/views/sales/hotel/items/panel/BaseCancelPolicyPanel.vue";
import BaseBookingPassengerInfoPanel from "@/views/sales/hotel/items/panel/BaseBookingPassengerInfoPanel.vue";
import BaseBookingRemarksPanel from "@/views/sales/hotel/items/panel/BaseBookingRemarksPanel.vue";
import BaseBookingExtraServicesInfoPanel from "@/views/sales/hotel/items/panel/BaseBookingExtraServicesInfoPanel.vue";
import HotelPolicyItemGroup from "@/views/sales/hotel/items/group/HotelPolicyItemGroup.vue";
import { usePaymentStore } from "@/stores/payment";
import { useCmsContentStore } from "@/stores/cms";

const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();
cmsContentStore.getAboutContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgroundImage) {
      const backgoundImage = newValue.backgroundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const navigation = reactive({
  title: "Booking Preview",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Hotel Search Result",
      path: "/sales/hotel/hotel-list",
    },
    {
      name: "Hotel Details",
      path: "/sales/hotel/hotel-details",
    },
    {
      name: "Passenger Info",
      path: "/sales/hotel/hotel-passengers",
    },
    {
      name: "Booking Preview",
    },
  ],
});

// Main store and Router
const templateStore = useTemplateStore();
const router = useRouter();
const item = sessionStorage.getItem("preBookRoomInfo");
if (!item) {
  router.push({ name: "sales-hotel-home" });
}

const bookRQSession = JSON.parse(sessionStorage.getItem("bookRQ"));
const preBookRoomInfoSession = JSON.parse(item);
const roomResGuestDetailsSession = JSON.parse(
  sessionStorage.getItem("roomResGuestDetails")
);

let hotelStore = useHotelStore();
let paymentStore = usePaymentStore();
hotelStore.$reset();
hotelStore.preBookRoomInfo = preBookRoomInfoSession;
hotelStore.roomResGuestDetails = roomResGuestDetailsSession;
hotelStore.bookRQ = bookRQSession;
//(bookRQSession);
paymentStore.saveNecessaryInfo(getIdMap().invoiceId, "hotel", bookRQSession);

const reservationInfo = reactive({
  preBookRoomInfo: hotelStore.preBookRoomInfo,
  roomResGuestDetails: hotelStore.roomResGuestDetails,
  bookRQ: hotelStore.bookRQ,
});

const roomViewItems = computed(() => {
  return hotelStore.preBookRoomInfo.roomViewItems;
});

const totalPrices = computed(() => {
  let roomPriceTotal = null;
  let taxesPriceTotal = null;
  let totalPrice = null;
  let currency = null;
  if (
    hotelStore.preBookRoomInfo &&
    hotelStore.preBookRoomInfo.roomViewItems &&
    hotelStore.preBookRoomInfo.roomViewItems.length > 0
  ) {
    hotelStore.preBookRoomInfo.roomViewItems.forEach((roomViewItem) => {
      roomPriceTotal += roomViewItem.roomPrice;
      totalPrice += roomViewItem.totalPrice;
      taxesPriceTotal += roomViewItem.feesTaxesInc;
      currency = roomViewItem.currency;
    });

    let extraServiceTotal = null;
    if (
      hotelStore.preBookRoomInfo.extraServices &&
      hotelStore.preBookRoomInfo.extraServices.length > 0
    ) {
      let extraServices = hotelStore.preBookRoomInfo.extraServices;
      extraServices.forEach((service) => {
        service.supplements.forEach((supplement) => {
          totalPrice += supplement.amount;
          extraServiceTotal += supplement.amount;
        });
      });
    }

    return {
      currency,
      roomPriceTotal,
      taxesPriceTotal,
      extraServiceTotal,
      totalPrice,
    };
  }

  return null;
});

function goToPaymentPage() {
  router.push({
    name: "v2-payment-methods",
    query: {
      bookingId: getIdMap().bookingId,
      invoiceId: getIdMap().invoiceId,
      productType: "hotel",
    },
  });
}

function getIdMap() {
  let invoiceId = null;
  let bookingId = null;
  bookRQSession.hotelReservationList.forEach((hotelReservation) => {
    hotelReservation.uniqueIDList.forEach((uniqueID) => {
      if ("INVOICE_ID" == uniqueID.type) {
        invoiceId = uniqueID.id;
      } else if ("BOOKING_ID" == uniqueID.type) {
        bookingId = uniqueID.id;
      }
    });
  });
  //console.info(invoiceId, bookingId);
  return { invoiceId, bookingId };
}

function goBack() {
  router.push({ name: "sales-hotel-passengers" });
}

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />

  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 booking_preview_form" style="margin-left: 15%">
          <div class="tou_booking_form_Wrapper">
            <!-- Page Content -->
            <div class="content content-boxed">
              <!-- Booking Info -->
              <BaseBlock>
                <div class="p-sm-2 p-xl-3">
                  <!-- Hotel/rooms Info -->
                  <div class="row mb-4">
                    <div
                      class="col-6 fs-sm"
                      :class="{ 'col-12': equipmentType == 'iphone' }"
                      v-if="reservationInfo.preBookRoomInfo"
                    >
                      <p class="h3 mb-2">
                        {{ reservationInfo.preBookRoomInfo.hotelName }}
                      </p>
                      <address>
                        {{ reservationInfo.preBookRoomInfo.address }}
                      </address>
                      <p class="h5 mb-2">
                        {{ reservationInfo.preBookRoomInfo.roomQuantity }}
                        <span
                          v-if="
                            reservationInfo.preBookRoomInfo.roomQuantity == 1
                          "
                          >{{ $t("room") }}*</span
                        >
                        <span v-else>{{ $t("room") }}*</span>
                        {{ reservationInfo.preBookRoomInfo.roomTypeNames }}
                      </p>
                      <p
                        class="fs-sm fw-medium mb-2"
                        v-if="
                          reservationInfo.preBookRoomInfo.roomViewItems &&
                          reservationInfo.preBookRoomInfo.roomViewItems.length >
                            0 &&
                          reservationInfo.preBookRoomInfo.roomViewItems[0]
                            .bedTypeName
                        "
                      >
                        {{ $t("bed-type") }}:
                        <span class="text-muted">{{
                          reservationInfo.preBookRoomInfo.roomViewItems[0]
                            .bedTypeName
                        }}</span>
                      </p>
                      <div
                        v-if="
                          reservationInfo.preBookRoomInfo.roomViewItems &&
                          reservationInfo.preBookRoomInfo.roomViewItems
                            .length &&
                          reservationInfo.preBookRoomInfo.roomViewItems[0]
                            .mealsIncluded
                        "
                      >
                        <i class="fa fa-bell-concierge"></i>
                        {{
                          reservationInfo.preBookRoomInfo.roomViewItems[0]
                            .mealName
                        }}
                      </div>
                      <p
                        class="import-content mb-2"
                        v-if="reservationInfo.preBookRoomInfo.refundable"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-cancel-policies"
                        style="color: red"
                      >
                        {{ $t("refundable") }}
                        <i class="fa fa-circle-info" style="color: red"></i>
                      </p>
                      <p class="import-content mb-2" style="color: red" v-else>
                        {{ $t("non-refundable") }}
                        <i class="fa fa-circle-info" style="color: red"></i>
                      </p>
                      <p class="h6 mb-3">
                        {{ $t("check-in") }}
                        <span class="text-muted">{{
                          reservationInfo.preBookRoomInfo.checkIn
                        }}</span>
                      </p>
                      <p class="h6 mb-3">
                        {{ $t("check-out") }}
                        <span class="text-muted">{{
                          reservationInfo.preBookRoomInfo.checkOut
                        }}</span>
                      </p>
                      <p class="fs-sm fw-medium mb-2">
                        <span class="text-muted"
                          >{{ reservationInfo.preBookRoomInfo.daysDuration }}-{{
                            $t("night-stay")
                          }}</span
                        >
                      </p>
                    </div>
                  </div>
                  <!-- END Hotel/rooms Info -->

                  <!-- Cancel Policies Slide Left Block Modal -->
                  <div
                    class="modal fade"
                    id="modal-cancel-policies"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-cancel-policies"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-slideleft"
                      role="document"
                    >
                      <div class="modal-content">
                        <BaseCancelPolicyPanel
                          :title="'Cancellation Policies'"
                          v-if="reservationInfo.preBookRoomInfo"
                          :room-type-name="
                            reservationInfo.preBookRoomInfo.roomTypeNames
                          "
                          :items="reservationInfo.preBookRoomInfo.roomViewItems"
                        ></BaseCancelPolicyPanel>
                      </div>
                    </div>
                  </div>
                  <!-- END Cancel Policies Slide Left Block Modal -->

                  <!-- Passenger Info -->
                  <BaseBookingPassengerInfoPanel
                    v-if="reservationInfo.preBookRoomInfo"
                    :room-res-guests="
                      reservationInfo.roomResGuestDetails.roomResGuests
                    "
                    :room-types="
                      reservationInfo.preBookRoomInfo.roomTypeNames.split(',')
                    "
                  />
                  <!-- END Passenger Info -->

                  <!-- Special Requests Info -->
                  <BaseBookingRemarksPanel
                    v-if="reservationInfo.preBookRoomInfo"
                    :room-view-items="
                      reservationInfo.preBookRoomInfo.roomViewItems
                    "
                    :room-types="
                      reservationInfo.preBookRoomInfo.roomTypeNames.split(',')
                    "
                  />
                  <!-- END Special Requests Info -->

                  <!-- Extra services Info -->
                  <BaseBookingExtraServicesInfoPanel
                    v-if="reservationInfo.preBookRoomInfo"
                    :extra-services="
                      reservationInfo.preBookRoomInfo.extraServices
                    "
                    :room-types="
                      reservationInfo.preBookRoomInfo.roomTypeNames.split(',')
                    "
                  />
                  <!-- END Extra services Info -->

                  <!-- Daily Prices -->
                  <div
                    class="table-responsive push"
                    v-if="totalPrices && totalPrices.totalPrice"
                  >
                    <p class="h5 mb-2">{{ $t("price-info") }}</p>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center bg-body-light">
                            {{ $t("room-index") }}
                          </th>
                          <th class="text-center bg-body-light">
                            {{ $t("date") }}
                          </th>
                          <th class="text-end bg-body-light">
                            {{ $t("room-price") }}
                          </th>
                          <th class="text-end bg-body-light">
                            {{ $t("taxes") }}
                          </th>
                          <th class="text-end bg-body-light">
                            {{ $t("amount") }} ({{ totalPrices.currency }})
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(roomViewItem, roomIndex) in roomViewItems"
                        :key="roomIndex"
                      >
                        <tr
                          v-for="(
                            dailyRate, rateIndex
                          ) in roomViewItem.dailyRates"
                          :key="rateIndex"
                        >
                          <td class="text-center">
                            {{ $t("room") }} {{ roomIndex + 1 }}
                          </td>
                          <td class="text-center">{{ dailyRate.date }}</td>
                          <td class="text-end">
                            {{ dailyRate.roomPrice.toFixed(2) }}
                          </td>
                          <td class="text-end" v-if="dailyRate.feesPrice">
                            {{ dailyRate.feesPrice.toFixed(2) }}
                          </td>
                          <td class="text-end" v-else>0.00</td>
                          <td class="text-end">
                            {{ dailyRate.totalPrice.toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td colspan="4" class="fw-semibold text-end">
                            {{ $t("room-price-total") }}
                          </td>
                          <td class="fw-bold text-end">
                            {{ totalPrices.roomPriceTotal.toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4" class="fw-semibold text-end">
                            {{ $t("taxes-total") }}
                          </td>
                          <td
                            class="fw-bold text-end"
                            v-if="totalPrices.taxesPriceTotal"
                          >
                            {{ totalPrices.taxesPriceTotal.toFixed(2) }}
                          </td>
                          <td class="fw-bold text-end" v-else>0.00</td>
                        </tr>
                        <tr>
                          <td colspan="4" class="fw-semibold text-end">
                            {{ $t("extra-service-total") }}
                          </td>
                          <td
                            class="fw-bold text-end"
                            v-if="totalPrices.extraServiceTotal"
                          >
                            {{ totalPrices.extraServiceTotal.toFixed(2) }}
                          </td>
                          <td class="fw-bold text-end" v-else>0.00</td>
                        </tr>
                        <tr>
                          <td
                            colspan="4"
                            class="fw-bold text-uppercase text-end bg-body-light"
                          >
                            {{ $t("total-due") }}
                          </td>
                          <td class="fw-bold text-end bg-body-light">
                            {{ totalPrices.currency }}
                            {{ totalPrices.totalPrice.toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- END Daily Prices -->
                </div>

                <!--   Policies     -->
                <HotelPolicyItemGroup id="btabs-alt-static-policies-tabpanel" />
                <!--  END Policies     -->
              </BaseBlock>
              <!-- END Booking Info -->
            </div>
            <!-- END Page Content -->

            <!-- Cancel Policies Slide Left Block Modal -->
            <div
              class="modal fade"
              id="modal-cancel-policies"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modal-cancel-policies"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-slideleft" role="document">
                <div class="modal-content">
                  <BaseCancelPolicyPanel
                    :title="'Cancellation Policies'"
                    v-if="reservationInfo.preBookRoomInfo"
                    :room-type-name="
                      reservationInfo.preBookRoomInfo.roomTypeNames
                    "
                    :items="reservationInfo.preBookRoomInfo.roomViewItems"
                  ></BaseCancelPolicyPanel>
                </div>
              </div>
            </div>
            <!-- END Cancel Policies Slide Left Block Modal -->
          </div>

          <!--   Policies     -->
          <HotelPolicyItemGroup id="btabs-alt-static-policies-tabpanel" />
          <!--  END Policies     -->

          <div
            class="booking_tour_form_submit hotel_book_btn"
            style="display: flex; justify-content: center"
          >
            <a
              @click="goBack"
              class="btn btn_theme"
              :class="{ btn_md: equipmentType != 'iphone' }"
              >Go back</a
            >
            <a
              @click="goToPaymentPage"
              :class="{ btn_md: equipmentType != 'iphone' }"
              class="btn btn_theme"
              style="margin-left: 100px"
              >Continue</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { Empty, Spin } from "ant-design-vue";
import { useTemplateStore } from "@/stores/template";
import { usePackageCarRentalRatesStore } from "@/stores/v2/dynamicPkg/price/packageCarRentalRatesStore";
import { ref, watch } from "vue";
import { DynamicPkgCarRentalRatesQuoteFormModel } from "@/formmodel/sales/dynamicPkg/price/dynamicPkgCarRentalRatesQuoteFormModel";
import dynamicPkgCommonTools from "@/tools/v2/dynamicPkg/common/dynamicPkgCommonTools";
import { CarRentalCoverageTypeItemVO } from "@/viewobject/sales/carrental/carRentalCoverageTypeItemVO";
import CarRentalDetailsPanel from "@/views/v2/sales/carrental/items/panel/CarRentalDetailsPanel.vue";
import DynamicPkgCarRentalExtrasShoppingCartPanel from "@/views/v2/sales/dynamicpkg/items/panel/DynamicPkgCarRentalExtrasShoppingCartPanel.vue";
import CarRentalExtrasCategoryPanel from "@/views/v2/sales/carrental/items/panel/CarRentalExtrasCategoryPanel.vue";
import { useCmsContentStore } from "@/stores/cms";

const router = useRouter();
const route = useRoute();

const { t, locale } = useI18n(); // 解构调用函数
const AEmpty = Empty;
const ASpin = Spin;

const templateStore = useTemplateStore();
const packageCarRentalRatesStore = usePackageCarRentalRatesStore();
const dynamicPkgAvailFormModelRef = ref(null);
const carRentalItemRef = ref(null);
const equipmentOptionsRef = ref(null);
const coverageOptionsRef = ref(null);

const protectionPlansRef = ref(null);
const availableCoveragesRef = ref(null);
const activeProtectionPlanIndexRef = ref(0);
const dynamicPkgCarRentalRatesQuoteFormModelRef = ref(
  new DynamicPkgCarRentalRatesQuoteFormModel(
    null,
    null,
    null,
    null,
    null,
    null,
    null
  )
);

//#region query参数请求，进行搜索
if (route.query != null && Object.keys(route.query).length > 0) {
  dynamicPkgAvailFormModelRef.value =
    dynamicPkgCommonTools.parseDynamicPkgAvailFormModel(route.query);
  searchCarRentalRatesByFormModel(dynamicPkgAvailFormModelRef.value);
}
//#endregion

//region watch监听pinia
watch(
  () => packageCarRentalRatesStore.carRentalItem,
  (newValue) => {
    carRentalItemRef.value = newValue;
    if (carRentalItemRef.value) {
      dynamicPkgCarRentalRatesQuoteFormModelRef.value.quoteID =
        carRentalItemRef.value.quoteID;
      dynamicPkgCarRentalRatesQuoteFormModelRef.value.baseAmount =
        carRentalItemRef.value.totalAmount;
      dynamicPkgCarRentalRatesQuoteFormModelRef.value.totalAmount =
        carRentalItemRef.value.totalAmount;
      dynamicPkgCarRentalRatesQuoteFormModelRef.value.currencyCode =
        carRentalItemRef.value.currencyCode;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => packageCarRentalRatesStore.equipmentOptions,
  (newValue) => {
    if (newValue) {
      equipmentOptionsRef.value = newValue;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => packageCarRentalRatesStore.coverageOptions,
  (newValue) => {
    coverageOptionsRef.value = newValue;
    protectionPlansRef.value = [];
    availableCoveragesRef.value = [];
    //将保险计划分类
    if (coverageOptionsRef.value && coverageOptionsRef.value.length > 0) {
      // BASIC
      const basicCoverage = new CarRentalCoverageTypeItemVO(
        true,
        true,
        false,
        "BASIC",
        "",
        0,
        null,
        null
      );
      const mediumCoverage = new CarRentalCoverageTypeItemVO(
        false,
        false,
        false,
        "MEDIUM",
        null,
        0,
        null,
        null
      );
      const premiumCoverage = new CarRentalCoverageTypeItemVO(
        false,
        false,
        false,
        "PREMIUM",
        null,
        0,
        null,
        null
      );

      coverageOptionsRef.value.forEach((insurance) => {
        const currency = insurance.currency;
        const amount = insurance.amount;
        const code = insurance.code;
        const description = insurance.description;
        if (description === "MEDIUM") {
          mediumCoverage.totalAmount += amount;
          mediumCoverage.currency = currency;
          mediumCoverage.code = code;
          mediumCoverage.addInsuranceItem(insurance);
        } else if (description === "PREMIUM") {
          premiumCoverage.totalAmount += amount;
          premiumCoverage.currency = currency;
          premiumCoverage.code = code;
          premiumCoverage.addInsuranceItem(insurance);
        } else if (
          insurance.included &&
          insurance.selected &&
          !insurance.mandatory
        ) {
          // 包含 已选择 非强制
          basicCoverage.totalAmount += amount;
          basicCoverage.currency = currency;
          basicCoverage.addInsuranceItem(insurance);
        } else {
          //其余可购买的保险项目
          availableCoveragesRef.value.push(insurance);
        }
      });

      protectionPlansRef.value.push(
        basicCoverage,
        mediumCoverage,
        premiumCoverage
      );
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => dynamicPkgCarRentalRatesQuoteFormModelRef.value,
  (newValue) => {
    if (newValue) {
      //更新一下列表显示操作的设备数量
      const selectedEquipments = newValue.selectedEquipments;
      if (equipmentOptionsRef.value && equipmentOptionsRef.value.length > 0) {
        equipmentOptionsRef.value.forEach((equipment) => {
          let containItem = null;

          if (selectedEquipments && selectedEquipments.length > 0) {
            containItem = selectedEquipments.find(
              (item) => item.code === equipment.code
            );
            if (containItem) {
              equipment.quantity = containItem.quantity;
            } else {
              equipment.quantity = 0;
            }
          } else {
            equipment.quantity = 0;
          }
        });
      }
    }
  },
  { immediate: true, deep: true }
);

//endregion

function selectProtectionPlan(
  dynamicPkgCarRentalRatesQuoteFormModel,
  protectionPlan,
  protectionPlanIndex
) {
  activeProtectionPlanIndexRef.value = protectionPlanIndex;

  dynamicPkgCarRentalRatesQuoteFormModel.addNewProtectionPlan(protectionPlan);
}

function selectAvailableCoverage(
  dynamicPkgCarRentalRatesQuoteFormModel,
  insurance
) {
  dynamicPkgCarRentalRatesQuoteFormModel.addNewInsurance(insurance);
}

function operateEquipment(
  dynamicPkgCarRentalRatesQuoteFormModel,
  equipment,
  type
) {
  if (type === "ADD") {
    dynamicPkgCarRentalRatesQuoteFormModel.addNewEquipment(equipment);
  } else {
    if (
      dynamicPkgCarRentalRatesQuoteFormModel.selectedEquipments &&
      dynamicPkgCarRentalRatesQuoteFormModel.selectedEquipments.length > 0
    ) {
      const selectedEquipment =
        dynamicPkgCarRentalRatesQuoteFormModel.selectedEquipments.find(
          (item) => item.code === equipment.code
        );
      if (selectedEquipment.quantity > 0) selectedEquipment.quantity--;
    }
  }
}

function sortByPrice(equipments) {
  return equipments.sort((a, b) => a.amount - b.amount);
}

function isCoverageDisable(dynamicPkgCarRentalRatesQuoteFormModel, insurance) {
  const selectedInsurances =
    dynamicPkgCarRentalRatesQuoteFormModel.selectedInsurances;
  let disabled = false;
  if (selectedInsurances && selectedInsurances.length > 0) {
    disabled = selectedInsurances.some((item) => item.code === insurance.code);
  }

  return disabled;
}

function confirmQuoteRates(dynamicPkgCarRentalRatesQuoteFormModel) {
  //验价
}

async function searchCarRentalRatesByFormModel(formModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await packageCarRentalRatesStore.carRentalRates(formModel);
  templateStore.pageLoader({ mode: "off" });
}

function goToHotelDetails(query) {
  if (query.referenceId) delete query.referenceId;
  if (query.airSearchType) delete query.airSearchType;
  if (query.airItineraryRPH) delete query.airItineraryRPH;
  if (query.fareSegmentBasisCodesFormat)
    delete query.fareSegmentBasisCodesFormat;
  if (query.preferredCarType) delete query.preferredCarType;
  router.push({
    name: "sales-dynamicPkg-hotel-details-v2",
    query: query,
  });
}

function goToFlightList(query) {
  if (query.airSearchType) delete query.airSearchType;
  if (query.airItineraryRPH) delete query.airItineraryRPH;
  if (query.fareSegmentBasisCodesFormat)
    delete query.fareSegmentBasisCodesFormat;
  if (query.preferredCarType) delete query.preferredCarType;
  router.push({
    name: "sales-dynamicPkg-flight-list-v2",
    query: query,
  });
}

function goToCarRentalList(query) {
  if (query.airSearchType) delete query.airSearchType;
  if (query.airItineraryRPH) delete query.airItineraryRPH;
  if (query.fareSegmentBasisCodesFormat)
    delete query.fareSegmentBasisCodesFormat;
  if (query.preferredCarType) delete query.preferredCarType;
  router.push({
    name: "sales-dynamicPkg-flight-list-v2",
    query: query,
  });
}

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.PackageBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.PackageBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>Protection & Extra</h2>
        <ul>
          <li class="pre-page" @click="router.push({ name: 'home' })">
            {{ $t("home") }}
          </li>

          <li class="pre-page" @click="goToHotelDetails(route.query)">
            {{ $t("hotel-details") }}
          </li>

          <li class="pre-page" @click="goToFlightList(route.query)">
            <i class="fas fa-circle"></i>
            {{ $t("flight-list") }}
          </li>
          <li class="pre-page" @click="goToCarRentalList(route.query)">
            <i class="fas fa-circle"></i>
            Car Rental List
          </li>
          <li>
            <i class="fas fa-circle"></i>
            Protection & Extra
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_details_main" class="section_padding">
    <div class="container hotel_container" style="margin-left: 350px">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goToCarRentalList(route.query)">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <CarRentalExtrasCategoryPanel
            :available-coverages="availableCoveragesRef"
            :car-rental-rates-quote-form-model="
              dynamicPkgCarRentalRatesQuoteFormModelRef
            "
            :equipment-options="equipmentOptionsRef"
            :protection-plans="protectionPlansRef"
          />
        </div>
        <div class="col-5">
          <CarRentalDetailsPanel :car-rental-item="carRentalItemRef" />
          <DynamicPkgCarRentalExtrasShoppingCartPanel
            :dynamic-pkg-car-rental-rates-quote-form-model="
              dynamicPkgCarRentalRatesQuoteFormModelRef
            "
            @confirmQuoteRates="confirmQuoteRates"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

export const passengerTypeOptions = [
  {
    code: "ADT",
    name: "ADULT",
  },
  {
    code: "CHD",
    name: "CHILD",
  },
  {
    code: "CNN",
    name: "CHILD",
  },
  {
    code: "CMA",
    name: "PRIMARY COMPANION",
  },
  {
    code: "CMP",
    name: "COMPANION",
  },
  {
    code: "EMI",
    name: "EMIGRANT",
  },
  {
    code: "HOF",
    name: "HEAD OF FAMILY",
  },
  {
    code: "INF",
    name: "INFANT",
  },
  {
    code: "INN",
    name: "INDIVIDUAL INCLUSIVE TOUR CHILD",
  },
  {
    code: "INS",
    name: "INFANT WITH A SEAT",
  },
  {
    code: "ITF",
    name: "INDIVIDUAL INCLUSIVE TOUR INFANT",
  },
  {
    code: "ITX",
    name: "INDIVIDUAL INCLUSIVE TOUR PSGR",
  },
  {
    code: "LBR",
    name: "LABORER/WORKER",
  },
  {
    code: "LIF",
    name: "LABORER/WORKER INFANT",
  },
  {
    code: "LNN",
    name: "LABORER/WORKER CHILD",
  },
  {
    code: "MIS",
    name: "MISSIONARY",
  },
  {
    code: "SEA",
    name: "SEAMAN",
  },
  {
    code: "SPS",
    name: "SPOUSE",
  },
  {
    code: "STU",
    name: "STUDENT",
  },
  {
    code: "VFR",
    name: "VISIT FRIENDS/RELATIVES",
  },
  {
    code: "YTH",
    name: "YOUTH CONFIRMED",
  },
];

export const adultNameTitleOptions = [
  { name: "Mr", code: "MR" },
  { name: "Mrs", code: "MRS" },
  { name: "Ms", code: "MS" },
  { name: "Capt", code: "CAPT" },
  { name: "Prof", code: "PROF" },
  { name: "Dr", code: "DR" },
  { name: "Dame", code: "DAME" },
  { name: "Lady", code: "LADY" },
  { name: "Lord", code: "LORD" },
  { name: "The Rt Hon", code: "THE RT HON" },
  { name: "Rabbi", code: "RABBI" },
  { name: "Rev", code: "REV" },
  { name: "Sir", code: "SIR" },
  { name: "Baroness", code: "BARONESS" },
  { name: "Baron", code: "BARON" },
  { name: "Viscount", code: "VISCOUNT" },
  { name: "Viscountes", code: "VISOUNTES" },
];

export const childNameTitleOptions = [
  { name: "Mstr", code: "MSTR" },
  { name: "Miss", code: "MISS" },
];

export const genderOptions = [
  { name: "Male", code: "MALE" },
  { name: "Female", code: "FEMALE" },
];

<script setup>
import { computed, reactive, ref, watch, watchEffect } from "vue";
import Banner from "@/components/custom/Banner.vue";
import BaseHotelAndFlightPricePanel from "@/views/sales/package/items/pannel/BaseHotelAndFlightPricePanel";
import BaseFlightItineraryPanel from "@/views/sales/flight/items/panel/BaseFlightItineraryPanel";
import { useDynamicPackageStore } from "@/stores/dynamicPackage";
import { useTemplateStore } from "@/stores/template";
import { useRouter } from "vue-router";
import BasePkgFlightTravelerPanel from "@/views/sales/package/items/pannel/BasePkgFlightTravelerPanel.vue";
import BasePkgHotelItemPanel from "@/views/sales/package/items/pannel/BasePkgHotelItemPanel.vue";
import { useCmsContentStore } from "@/stores/cms";
//region 数据初始化
const navigation = reactive({
  title: "Booking Preview",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Hotel Search Result",
      path: "/sales/package/hotel-list",
    },
    {
      name: "Hotel Details",
      path: "/sales/package/hotel-details",
    },
    {
      name: "Flight Search Result",
      path: "/sales/package/package-flight-list",
    },
    {
      name: "Itinerary Review",
      path: "/sales/package/package-itinerary-review",
    },
    {
      name: "flight-passengers",
      path: "/sales/package/package-flight-passengers",
    },
    {
      name: "Booking Preview",
    },
  ],
});

let dynamicPackageStore = useDynamicPackageStore();
let templateStore = useTemplateStore();
let router = useRouter();
let passengerTicketFareBreakdownItems = {};
let offerViewItemMap = {};
let pricedFlightViewItemsGroup = {};
let holdRoomInfo = {};
let roomInfo = {};
let hotelViewShow = {};
let pkgPassengerInfoShow = {};
let pkgRoomResGuestFormModel = JSON.parse(
  sessionStorage.getItem("pkgRoomResGuestFormModel")
);
let pkgItineraryReview = JSON.parse(
  sessionStorage.getItem("pkgItineraryReview")
);
let airportCityMap = JSON.parse(sessionStorage.getItem("pkgAirportCityMap"));
//hotel part

//passenger part
if (pkgRoomResGuestFormModel) {
  let roomResGuests = pkgRoomResGuestFormModel.roomResGuests;
  if (roomResGuests && roomResGuests.length > 0) {
    roomResGuests.forEach((roomItem, roomItemIndex) => {
      let resGuests = roomItem.resGuests;
      if (resGuests && resGuests.length > 0) {
        let roomPassenger = [];
        resGuests.forEach((passenger) => {
          roomPassenger.push(passenger);
        });
        pkgPassengerInfoShow[roomItemIndex + 1] = roomPassenger;
      }
    });
  }
  // pkgPassengerInfoShow = roomResGuests ? roomResGuests.resGuests : [];
}
//price part
if (pkgItineraryReview) {
  pricedFlightViewItemsGroup = pkgItineraryReview.pricedFlightViewItemsGroup;
  offerViewItemMap = pkgItineraryReview.offerViewItemMap;
  passengerTicketFareBreakdownItems =
    pkgItineraryReview.passengerTicketFareBreakdownItems;
  holdRoomInfo = pkgItineraryReview.holdRoomInfo;
  roomInfo =
    pkgItineraryReview.holdRoomInfo.roomViewItems &&
    pkgItineraryReview.holdRoomInfo.roomViewItems.length > 0
      ? pkgItineraryReview.holdRoomInfo.roomViewItems[0]
      : {};
  hotelViewShow = Object.assign(holdRoomInfo, roomInfo);
  hotelViewShow = Object.assign(holdRoomInfo, roomInfo);
  hotelViewShow.roomType = holdRoomInfo.roomTypeNames;
  hotelViewShow.roomNum = holdRoomInfo.roomViewItems.length;
}
let totalAmountContent = computed(() => {
  let totalAmount = 0;
  let currency = "";
  let adultTotalAmount = 0;
  let childTotalAmount = 0;
  let infantTotalAmount = 0;
  let adultNum = 0;
  let childNum = 0;
  let infantNum = 0;
  let taxesTotalAmount = 0;
  if (passengerTicketFareBreakdownItems) {
    passengerTicketFareBreakdownItems.forEach((breakdownItem) => {
      let totalFare = breakdownItem.totalFare;
      totalAmount += totalFare;
      taxesTotalAmount += breakdownItem.taxes;
      currency = breakdownItem.currency;

      if (breakdownItem.passengerTypeCode == "ADT") {
        adultNum++;
        adultTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "CNN") {
        childNum++;
        childTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "INF") {
        infantNum++;
        infantTotalAmount += breakdownItem.baseFare;
      }
    });
  }
  return {
    totalAmount,
    currency,
    adultTotalAmount,
    childTotalAmount,
    infantTotalAmount,
    infantNum,
    adultNum,
    childNum,
    taxesTotalAmount,
  };
});
//endregion

//region 辅助函数
async function goToPaymentPage() {
  pkgRoomResGuestFormModel.airItinerary = pkgItineraryReview.airItinerary;
  templateStore.pageLoader({ mode: "on" });
  await dynamicPackageStore.prebookDynamicPkg(pkgRoomResGuestFormModel);
  let prebookRS = dynamicPackageStore.prebookRS;
  let bookRQ = dynamicPackageStore.bookRQ;
  if (!prebookRS.success) {
    let shortText = prebookRS.errors.errors[0].shortText;
    router.push({ name: "error-500", query: { message: shortText } });
  } else {
    sessionStorage.setItem("pkgPrebookRS", JSON.stringify(prebookRS));
    sessionStorage.setItem("bookRQ", JSON.stringify(bookRQ));

    router.push({
      name: "v2-payment-methods",
      query: {
        bookingId: getIdMap(prebookRS).bookingId,
        invoiceId: getIdMap(prebookRS).invoiceId,
        productType: "package",
      },
    });
  }
  templateStore.pageLoader({ mode: "off" });
}

function getIdMap(prebookRS) {
  let bookingId = null;
  let invoiceId = null;
  prebookRS.dynamicPackage.globalInfo.dynamicPkgIDs.forEach((dynamicPkgID) => {
    if ("INVOICE_ID" == dynamicPkgID.type) {
      invoiceId = dynamicPkgID.id;
    } else if ("BOOKING_ID" == dynamicPkgID.type) {
      bookingId = dynamicPkgID.id;
    }
  });

  return {
    invoiceId,
    bookingId,
  };
}

function getPolicyModelKey(modelIndex) {
  return "C" + modelIndex;
}

function goBack() {
  router.push({ name: "sales-package-passengers" });
}

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
//endregion

const bannerContentStore = useCmsContentStore();
let bannerContent = reactive(null);
bannerContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrlRef = ref("");
let backgroundImageUrlRef = ref("");
watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrlRef.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.packageBackgroundImage
      ? bannerContentStore.productPageBackgroundImage.packageBackgroundImage.url
      : "";
  backgroundImageUrlRef.value = baseUrl + imageUrlRef.value;
});
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <h3 class="heading_theme">{{ $t("booking-preview") }}</h3>
      <div class="row">
        <div class="col-lg-9">
          <div class="tou_booking_form_Wrapper">
            <div class="booking_tour_form">
              <!--酒店相关-->
              <div class="booking_tour_form">
                <BasePkgHotelItemPanel :hotel-view-show="hotelViewShow" />
              </div>
              <BaseFlightItineraryPanel
                v-if="pricedFlightViewItemsGroup && airportCityMap"
                :priced-flight-view-items-group="pricedFlightViewItemsGroup"
                :airport-city-map="airportCityMap"
                :offer-view-item-map="offerViewItemMap"
              />
            </div>
            <div class="booking_tour_form">
              <BasePkgFlightTravelerPanel
                :equipment-type="equipmentType"
                v-if="pkgPassengerInfoShow"
                :passengers="pkgPassengerInfoShow"
              />
            </div>
            <div
              class="booking_tour_form_submit"
              v-if="equipmentType != 'iphone'"
            >
              <a @click="goBack" class="btn btn_theme">{{ $t("go-back") }}</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <BaseHotelAndFlightPricePanel
            v-if="totalAmountContent"
            :equipment-type="equipmentType"
            :total-amount-content="totalAmountContent"
            :room-info="roomInfo"
            :room-num="
              holdRoomInfo.roomViewItems &&
              holdRoomInfo.roomViewItems.length > 0
                ? holdRoomInfo.roomViewItems.length
                : 0
            "
            @nextStep="goToPaymentPage"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

<script setup>
import { reactive, watchEffect } from "vue"; // import Swiper core and required modules
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules"; // Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue"; // Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

const modules = [Navigation, Pagination, Scrollbar, A11y, Autoplay];

const props = defineProps({
  ourPartners: {},
});
let ourPartners = reactive([]);

const baseUrl = "/api/v3/content/collections/images";
watchEffect(() => {
  props.ourPartners, (ourPartners = props.ourPartners);
  //按照SequenceNumber排序
  if (ourPartners && ourPartners.length > 0) {
    ourPartners.sort((a, b) => {
      return a.sequenceNumber - b.sequenceNumber;
    });
  }
});
</script>
<template>
  <section id="our_partners" class="section_padding"  v-if="ourPartners && ourPartners.length > 0">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t("our-partners") }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="partner_slider_area owl-theme owl-carousel">
            <Swiper
              :slides-per-view="8"
              :modules="modules"
              loop
              :autoplay="{
                delay: 1000,
                disableOnInteraction: false, // 用户交互后是否停止自动播放
              }"

            >
              <SwiperSlide
                v-for="(ourPartner, ourPartnerIndex) in ourPartners"
                :key="ourPartnerIndex"
                :id="ourPartnerIndex"
              >
                <div class="partner_logo">
                  <a :href="ourPartner.links">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                        font-size: 14px;
                      "
                    >
                      <img
                        style="
                          width: 90px;
                          height: 90px;
                          border-radius: 100%;
                          object-fit: contain;
                        "
                        :src="baseUrl + ourPartner.icon.url"
                        alt="logo"
                        v-if="
                          ourPartner && ourPartner.icon && ourPartner.icon.url
                        "
                      />
                      <span
                        style="
                          font-family: FZZhunYuan-M02S;
                          margin-top: 10px;
                          font-size: 16px;
                        "
                      >
                        {{ ourPartner.companyName }}</span
                      >
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
            <!--            <swiper :slides-per-view="8">-->
            <!--              <swiper-slide>-->
            <!--                <div class="partner_logo">-->
            <!--                  <a href="#!"-->
            <!--                    ><img src="@/assets/img/partner/1.png" alt="logo"-->
            <!--                  /></a>-->
            <!--                </div>-->
            <!--              </swiper-slide>-->
            <!--              <swiper-slide>-->
            <!--                <div class="partner_logo">-->
            <!--                  <a href="#!"-->
            <!--                    ><img src="@/assets/img/partner/2.png" alt="logo"-->
            <!--                  /></a>-->
            <!--                </div>-->
            <!--              </swiper-slide>-->
            <!--              <swiper-slide>-->
            <!--                <div class="partner_logo">-->
            <!--                  <a href="#!"-->
            <!--                    ><img src="@/assets/img/partner/3.png" alt="logo"-->
            <!--                  /></a>-->
            <!--                </div>-->
            <!--              </swiper-slide>-->
            <!--              <swiper-slide>-->
            <!--                <div class="partner_logo">-->
            <!--                  <a href="#!"-->
            <!--                    ><img src="@/assets/img/partner/4.png" alt="logo"-->
            <!--                  /></a>-->
            <!--                </div>-->
            <!--              </swiper-slide>-->
            <!--              <swiper-slide>-->
            <!--                <div class="partner_logo">-->
            <!--                  <a href="#!"-->
            <!--                    ><img src="@/assets/img/partner/5.png" alt="logo"-->
            <!--                  /></a>-->
            <!--                </div>-->
            <!--              </swiper-slide>-->
            <!--              <swiper-slide>-->
            <!--                <div class="partner_logo">-->
            <!--                  <a href="#!"-->
            <!--                    ><img src="@/assets/img/partner/6.png" alt="logo"-->
            <!--                  /></a>-->
            <!--                </div>-->
            <!--              </swiper-slide>-->
            <!--              <swiper-slide>-->
            <!--                <div class="partner_logo">-->
            <!--                  <a href="#!"-->
            <!--                    ><img src="@/assets/img/partner/7.png" alt="logo"-->
            <!--                  /></a>-->
            <!--                </div>-->
            <!--              </swiper-slide>-->
            <!--              <swiper-slide>-->
            <!--                <div class="partner_logo">-->
            <!--                  <a href="#!"-->
            <!--                    ><img src="@/assets/img/partner/8.png" alt="logo"-->
            <!--                  /></a>-->
            <!--                </div>-->
            <!--              </swiper-slide>-->
            <!--            </swiper>-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

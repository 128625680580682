import FlightPenaltyOptionVO from "@/viewobject/sales/flight/shopping/FlightPenaltyOptionVO";
import { FlightFareOption } from "@/viewobject/sales/flight/shopping/flightFareOption";

export default class FlightFareOptionBuilder {
  constructor() {
    this.flightFareOption = new FlightFareOption();
  }

  buildBaseFareOption(pricedItinerary, airItinerary, specificODKey) {
    //由于价格信息在ODs外层，所以把整个行程都传进来
    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const supplierCode =
      airItineraryPricingInfo.priceRequestInformation &&
      airItineraryPricingInfo.priceRequestInformation.negotiatedFareCodeList
        ? airItineraryPricingInfo.priceRequestInformation
            .negotiatedFareCodeList[0].supplierCode
        : airItineraryPricingInfo.pricingSource;
    const bookingSourceType = pricedItinerary.bookingSourceType;

    let priceExpirationDatetime = null;
    let origFareIndicator = null;
    if (
      airItineraryPricingInfo.tpaExtensions &&
      airItineraryPricingInfo.tpaExtensions.length > 0 &&
      airItineraryPricingInfo.tpaExtensions[0] &&
      airItineraryPricingInfo.tpaExtensions[0].valueMap
    ) {
      const valueMap = airItineraryPricingInfo.tpaExtensions[0].valueMap;
      if (valueMap.origFareIndicator)
        origFareIndicator = JSON.parse(valueMap.origFareIndicator);
      priceExpirationDatetime = valueMap.priceExpirationDatetime;
    }

    //CBT
    const approvalRequired = pricedItinerary.approvalRequired;
    const notes = pricedItinerary.notes;
    const outOfPolicy = pricedItinerary.outOfPolicy;
    const upgradeAllowed = pricedItinerary.upgradeAllowed;
    const reasons = pricedItinerary.reasons;

    let flightFareOption = null;
    const originDestinationOptions = airItinerary.originDestinationOptions;
    const preItineraryKeys = []; //之前的OD组合
    for (const originDestinationOption of originDestinationOptions) {
      const originDestinationRPH = originDestinationOption.rph;
      // const originDestinationKey = originDestinationOption.originDestinationKey;
      //如果遍历到当前OD,创建一个fareOption,否则将遍历到的OD键放入
      if (originDestinationRPH === specificODKey) {
        break; //遍历到当前OD就不再循环
      } else {
        preItineraryKeys.push(originDestinationRPH);
      }
    }

    flightFareOption = new FlightFareOption(
      supplierCode,
      bookingSourceType,
      null,
      null,
      preItineraryKeys && preItineraryKeys.length > 0
        ? preItineraryKeys.join("#")
        : null,
      specificODKey,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      priceExpirationDatetime,
      outOfPolicy,
      approvalRequired,
      upgradeAllowed,
      reasons,
      null,
      notes,
      null,
      origFareIndicator,
      null,
      null
    );

    this.flightFareOption = flightFareOption;

    return this; //返回当前对象的引用，方便链调用
  }

  /*
   * 添加指定信息
   * */
  addPriceInfo(airItineraryPricingInfo) {
    const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
    const totalFare = itinTotalFare.totalFare;
    const totalFareCurrencyCode = totalFare.currencyCode;
    const totalFareAmount = totalFare.amount;

    this.flightFareOption.currencyCode = totalFareCurrencyCode;
    this.flightFareOption.totalPrice = totalFareAmount;

    return this;
  }

  /*
   * 仅添加指定OD下指定航段航班Cabin type的Fare Code相关信息
   * 包含Cabin type对应的booking class Code，fare basis code
   * */
  addFareBasisCodes(
    airItineraryPricingInfo,
    flightSegmentRPHs,
    passengerFareMap,
    fareInfoMap
  ) {
    const ptcFareBreakdowns = airItineraryPricingInfo.PTC_FareBreakdowns;

    for (const ptcFareBreakdown of ptcFareBreakdowns) {
      const fareBasisCodes = ptcFareBreakdown.fareBasisCodes;
      const fareInfoList = ptcFareBreakdown.fareInfoList;
      const passengerFare = ptcFareBreakdown.passengerFareList[0];
      const passengerFareDetails = passengerFareMap
        ? passengerFareMap[passengerFare.rph]
        : null;

      //fare basis code

      if (fareBasisCodes) {
        for (const fareBasisCode of fareBasisCodes) {
          const flightSegmentRPH = fareBasisCode.flightSegmentRPH;
          if (flightSegmentRPHs.indexOf(flightSegmentRPH) === -1) continue;

          const fareBasisCodeStringValue =
            fareBasisCode.fareBasisCodeStringValue;

          this.flightFareOption.addFareSegmentBasisCode({
            fareBasisCode: fareBasisCodeStringValue,
            flightRefNumber: flightSegmentRPH,
          }); //包含航段信息
        }
      }

      //booking class code \ cabin
      if (fareInfoList && fareInfoMap) {
        for (const fareInfo of fareInfoList) {
          const fareInfoDetails = fareInfoMap[fareInfo.rph];

          if (!fareInfoDetails) continue;

          const negotiatedFareCode = fareInfoDetails.negotiatedFareCode;
          this.flightFareOption.addNegotiatedFareCode(negotiatedFareCode);

          const fareReferences = fareInfoDetails.fareReferences;
          if (!fareReferences) continue;
          for (const fareReference of fareReferences) {
            const flightSegmentRPH = fareReference.flightSegmentRPH;

            if (flightSegmentRPHs.indexOf(flightSegmentRPH) === -1) continue;

            const cabinType = fareReference.cabinType;
            const resBookDesigCode = fareReference.resBookDesigCode;

            if (resBookDesigCode)
              this.flightFareOption.addBookingClassCode(resBookDesigCode);
            if (cabinType) this.flightFareOption.addCabinType(cabinType);
          }
        }
      }

      //baggage allowance
      if (
        passengerFareDetails &&
        passengerFareDetails.fareBaggageAllowanceList &&
        passengerFareDetails.fareBaggageAllowanceList.length > 0
      ) {
        for (const fareBaggageAllowance of passengerFareDetails.fareBaggageAllowanceList) {
          const flightSegmentRPH = fareBaggageAllowance.flightSegmentRPH;
          if (flightSegmentRPHs.indexOf(flightSegmentRPH) < 0) continue;

          const unitOfMeasureQuantity =
            fareBaggageAllowance.unitOfMeasureQuantity;
          const unitOfMeasure =
            fareBaggageAllowance.unitOfMeasure.toLowerCase() === "kg"
              ? "Kg"
              : "Piece(s)";

          this.flightFareOption.addBaggageAllowances(
            unitOfMeasureQuantity + " " + unitOfMeasure
          );
        }
      }
    }

    return this;
  }

  /*
   * 仅添加指定OD下指定航段航班的Brand Fare Codes
   * */
  addBrandFareInfos(offerType, odRPH, flightSegmentRPHs) {
    if (!offerType || !offerType.summary || offerType.summary.length === 0)
      return this;

    const offerSummary = offerType.summary;
    const priceClassIds = [];
    const prePriceClassIds = [];
    let preOrigDestRPH = null;

    //todo 特殊情况，可能存在多个航班中，只有一个OD有brand fare
    for (const offer of offerSummary) {
      const flightSegmentRPH = offer.flightSegmentRPH;
      const origDestRPH = offer.origDestRPH;
      const bundleID = offer.bundleID;
      // 该OD内航段
      if (
        flightSegmentRPH &&
        flightSegmentRPHs &&
        flightSegmentRPHs.length > 0 &&
        flightSegmentRPHs.indexOf(flightSegmentRPH) < 0
      ) {
        //OD之间使用冒号：连接，Segment之间用@@连接
        if (preOrigDestRPH && preOrigDestRPH !== origDestRPH) {
          const lastIndex = prePriceClassIds.length - 1;
          const prePriceClassId = prePriceClassIds[lastIndex]; //获取prePriceClassIds的最后一项
          prePriceClassIds[lastIndex] = prePriceClassId + ":" + bundleID;
        } else {
          //同一个OD
          prePriceClassIds.push(bundleID);
        }
        preOrigDestRPH = origDestRPH;
        continue;
      } else if (origDestRPH && origDestRPH !== odRPH) {
        continue;
      }

      priceClassIds.push(bundleID);
      this.flightFareOption.prePriceClassIdsKey = prePriceClassIds.join("@@");
      this.flightFareOption.addSegmentPriceClassID(flightSegmentRPH, bundleID);
    }

    this.flightFareOption.priceClassId = priceClassIds.join("@@");

    return this;
  }

  addPenaltyInfos(airItineraryPricingInfo, voluntaryChangesMap) {
    const fareInfoList = airItineraryPricingInfo.fareInfoList;
    if (!fareInfoList || fareInfoList.length === 0) return this;

    const fareRuleInfos = fareInfoList[0].ruleInfos;
    //Penalty Info
    if (fareRuleInfos && fareRuleInfos.length === 0) return this;

    fareRuleInfos.forEach((ruleInfo) => {
      const fareRuleKey = ruleInfo.fareRuleKey;
      const chargesRules = ruleInfo.chargesRules;
      if (chargesRules) {
        const voluntaryChangesKey =
          chargesRules.voluntaryChanges.voluntaryChangesKey;
        const voluntaryChanges = voluntaryChangesMap[voluntaryChangesKey];
        const voluntaryRefundsKey =
          chargesRules.voluntaryRefunds.voluntaryChangesKey;
        const voluntaryRefunds = voluntaryChangesMap[voluntaryRefundsKey];

        //修改费用
        if (voluntaryChanges && voluntaryChanges.penalty) {
          let penaltyType = voluntaryChanges.penalty.penaltyType;
          let departureStatus = voluntaryChanges.penalty.departureStatus;
          const currencyCode =
            voluntaryChanges && voluntaryChanges.penalty
              ? voluntaryChanges.penalty.currencyCode
              : null;
          let amount = voluntaryChanges.penalty.amount;
          let percent = voluntaryChanges.penalty.percent;
          let volChangeInd = voluntaryChanges.volChangeInd;

          const flightPenaltyOptionVO = new FlightPenaltyOptionVO(
            volChangeInd,
            fareRuleKey,
            penaltyType,
            departureStatus,
            percent,
            amount,
            currencyCode
          );
          this.flightFareOption.addPenaltyOption(flightPenaltyOptionVO);
        }
        //退票费用
        if (voluntaryRefunds && voluntaryRefunds.penalty) {
          let penaltyType = voluntaryRefunds.penalty.penaltyType;
          let departureStatus = voluntaryRefunds.penalty.departureStatus;
          const currencyCode =
            voluntaryChanges && voluntaryChanges.penalty
              ? voluntaryChanges.penalty.currencyCode
              : null;
          let amount = voluntaryRefunds.penalty.amount;
          let percent = voluntaryRefunds.penalty.percent;
          let volChangeInd = voluntaryRefunds.volChangeInd;

          const flightPenaltyOptionVO = new FlightPenaltyOptionVO(
            volChangeInd,
            fareRuleKey,
            penaltyType,
            departureStatus,
            percent,
            amount,
            currencyCode
          );
          this.flightFareOption.addPenaltyOption(flightPenaltyOptionVO);
        }
      }
    });

    return this;
  }

  build() {
    return this.flightFareOption;
  }
}

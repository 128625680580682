<script setup>
import { useHotelStore } from "@/stores/hotel";
import { useBookingStore } from "@/stores/booking";
import { computed } from "vue";
import BasePolicyItem from "@/views/sales/hotel/items/panel/BasePolicyItem.vue";
//Pinia store 相关
const hotelStore = useHotelStore();
const bookingStore = useBookingStore();

//酒店信息响应对象
const policyItems = computed(() => {
  const hotelViewItemMap = hotelStore.hotelViewItemMap;
  //酒店确认订单页需要
  const preBookRoomInfo = hotelStore.preBookRoomInfo;
  //订单详情页
  const bookingDetailsViewItem = bookingStore.bookingDetailsViewItem;

  let hotelViewItem = null;
  if (hotelViewItemMap != null) {
    for (const hotelViewItemMapKey in hotelViewItemMap) {
      hotelViewItem = hotelViewItemMap[hotelViewItemMapKey];
      break;
    }
  }

  if (hotelViewItem != null && hotelViewItem.propTextContent) {
    const propTextContent = hotelViewItem.propTextContent;
    let policyItems = {
      checkPolicy: propTextContent["CHECK_IN"],
      feesPolicy: propTextContent["FEES"],
      importantPolicy: propTextContent["POLICY"],
    };
    // (policyItems);
    return policyItems;
  } else if (
    preBookRoomInfo != null &&
    preBookRoomInfo.propTextContent != null
  ) {
    const propTextContent = preBookRoomInfo.propTextContent;
    let policyItems = null;
    if (propTextContent) {
      policyItems = {
        checkPolicy: propTextContent["CHECK_IN"],
        feesPolicy: propTextContent["FEES"],
        importantPolicy: propTextContent["POLICY"],
      };
    }
    return policyItems;
  } else if (
    bookingDetailsViewItem != null &&
    bookingDetailsViewItem.hotelItems &&
    bookingDetailsViewItem.hotelItems.length > 0
  ) {
    let policyItems = null;
    bookingDetailsViewItem.hotelItems.forEach((hotelViewItem) => {
      const propTextContent = hotelViewItem.propTextContent;
      if (propTextContent) {
        policyItems = {
          checkPolicy: propTextContent["CHECK_IN"],
          feesPolicy: propTextContent["FEES"],
          importantPolicy: propTextContent["POLICY"],
        };
      }
    });
    return policyItems;
  }
  return null;
});
</script>

<template>
  <BaseBlock v-if="policyItems && policyItems.checkPolicy">
    <BasePolicyItem
      :category-name="'Policies'"
      :items="policyItems.checkPolicy"
    />
  </BaseBlock>
  <BaseBlock v-if="policyItems && policyItems.feesPolicy">
    <BasePolicyItem :category-name="'Fees'" :items="policyItems.feesPolicy" />
  </BaseBlock>
  <BaseBlock v-if="policyItems && policyItems.importantPolicy">
    <BasePolicyItem
      :category-name="'Important Information'"
      :items="policyItems.importantPolicy"
    />
  </BaseBlock>
</template>

<style scoped lang="scss"></style>

<template>
  <section id="news_details_main_arae" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="news_detail_wrapper">
            <div class="news_details_content_area">
              <img src="../../../assets/img/news/news-details.png" alt="img" />
              <h2>
                Veniam ex tempor qui ad amet mollit cillum aliqua aliqua fugiat
                tempor eu magna
              </h2>
              <p>
                Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci
                phaedrum. There are many variations of passages of Lorem Ipsum
                available but the majority.
              </p>
              <p>
                If you are going to use a passage of Lorem Ipsum, you need to be
                sure there isn't anything embarrang hidden in the middle of
                text. All the Lorem Ipsum generators on the Internet tend to
                repeat predefined chunks as necessary, making this the first
                true.
              </p>
              <h3>We want to ensure the education for the kids.</h3>
              <p>
                Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci
                phaedrum. There are many variations of passages of Lorem Ipsum
                available, but the majority have alteration in some injected or
                words which don't look even slightly believable.
              </p>
              <ul>
                <li>
                  <i class="fas fa-circle"></i> Lorem ipsum dolor sit amet, cibo
                  mundi ea duo, vim exerci phaedrum.
                </li>
                <li>
                  <i class="fas fa-circle"></i> There are many variations of
                  passages of Lorem Ipsum.
                </li>
                <li>
                  <i class="fas fa-circle"></i> Available but the majority have
                  alteration in some injected or words.
                </li>
                <li>
                  <i class="fas fa-circle"></i> There are many variations of
                  passages of Lorem Ipsum which don't look even slightly
                  believable.
                </li>
              </ul>
              <div class="row">
                <div class="col-lg-6">
                  <div class="news_details_left_img">
                    <img
                      src="../../../assets/img/news/news_details_left.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="news_details_left_img">
                    <img
                      src="../../../assets/img/news/news_details_right.png"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci
                phaedrum. There are many variations of passages of Lorem Ipsum
                available, but the majority have alteration in some injected or
                words which don't look even slightly believable.
              </p>
            </div>
            <div class="download_pdf_area">
              <div class="downloads_pdf_icon">
                <img src="../../../assets/img/icon/pdf.png" alt="icon" />
                <h3>Travel guide for explorer.pdf</h3>
              </div>
              <div class="downloads_pdf_button">
                <button class="btn btn_theme">Download pdf</button>
              </div>
            </div>
            <div class="comment_area">
              <h3>2 Comments</h3>
              <div class="comment_area_boxed">
                <div class="comment_img">
                  <img src="../../../assets/img/news/comment.png" alt="img" />
                </div>
                <div class="comment_text">
                  <div class="comment_author_name">
                    <h4>James martin</h4>
                    <a href="#!"> <i class="fas fa-reply-all"></i> Reply</a>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci
                    phaedrum. There are many variations of passages of Lorem
                    Ipsum available but the majority.
                  </p>
                </div>
              </div>
              <div class="comment_area_boxed">
                <div class="comment_img">
                  <img src="../../../assets/img/news/comment.png" alt="img" />
                </div>
                <div class="comment_text">
                  <div class="comment_author_name">
                    <h4>James martin</h4>
                    <a href="#!"> <i class="fas fa-reply-all"></i> Reply</a>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci
                    phaedrum. There are many variations of passages of Lorem
                    Ipsum available but the majority.
                  </p>
                </div>
              </div>
            </div>
            <div class="comment_area_form">
              <h3>Leave a comment</h3>
              <form action="!#" id="news_comment_form">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-froup">
                      <input
                        type="text"
                        class="form-control bg_input"
                        placeholder="Enter full name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-froup">
                      <input
                        type="text"
                        class="form-control bg_input"
                        placeholder="Enter email address"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-froup">
                      <textarea
                        rows="6"
                        placeholder="Write your comments"
                        class="form-control bg_input"
                      ></textarea>
                    </div>
                    <div class="comment_form_submit">
                      <button class="btn btn_theme">Post comment</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="news_details_rightbar">
            <div class="news_details_right_item">
              <h3>Recent news</h3>
              <div class="recent_news_item">
                <div class="recent_news_img">
                  <img src="../../../assets/img/news/recent-1.png" alt="img" />
                </div>
                <div class="recent_news_text">
                  <h5>
                    <router-link to="/templates/news-details"
                      >Ea exercitation qui nostrud sint labore irure in
                      exercitation
                    </router-link>
                  </h5>
                  <p>
                    <router-link to="/templates/news">26 Oct 2021</router-link>
                    <i class="fas fa-circle"></i> 8 min read
                  </p>
                </div>
              </div>
              <div class="recent_news_item">
                <div class="recent_news_img">
                  <img src="../../../assets/img/news/recent-2.png" alt="img" />
                </div>
                <div class="recent_news_text">
                  <h5>
                    <router-link to="/templates/news-details"
                      >Amet cupidatat aliqua dolor del proident veniam proident
                    </router-link>
                  </h5>
                  <p>
                    <router-link to="/templates/news">26 Oct 2021</router-link>
                    <i class="fas fa-circle"></i> 8 min read
                  </p>
                </div>
              </div>
              <div class="recent_news_item">
                <div class="recent_news_img">
                  <img src="../../../assets/img/news/recent-3.png" alt="img" />
                </div>
                <div class="recent_news_text">
                  <h5>
                    <router-link to="/templates/news-details"
                      >Consequat enim aute sint sint ad et culpa eu magna
                    </router-link>
                  </h5>
                  <p>
                    <router-link to="/templates/news">26 Oct 2021</router-link>
                    <i class="fas fa-circle"></i> 8 min read
                  </p>
                </div>
              </div>
              <div class="recent_news_item">
                <div class="recent_news_img">
                  <img src="../../../assets/img/news/recent-4.png" alt="img" />
                </div>
                <div class="recent_news_text">
                  <h5>
                    <router-link to="/templates/news-details"
                      >Proident dolore dolore cillum pelo aliquip irure elit
                    </router-link>
                  </h5>
                  <p>
                    <router-link to="/templates/news">26 Oct 2021</router-link>
                    <i class="fas fa-circle"></i> 8 min read
                  </p>
                </div>
              </div>
            </div>
            <div class="news_details_right_item">
              <h3>Popular tags</h3>
              <div class="news_tags_area">
                <ul>
                  <li><a href="#!" class="active">Assistant</a></li>
                  <li><a href="#!">Tours</a></li>
                  <li><a href="#!">Tour guide</a></li>
                  <li><a href="#!">Business</a></li>
                  <li><a href="#!">Corporate</a></li>
                  <li><a href="#!">Manager</a></li>
                  <li><a href="#!">Travel agency</a></li>
                  <li><a href="#!">Destination</a></li>
                </ul>
              </div>
            </div>
            <div class="news_details_right_item">
              <h3>Share causes</h3>
              <div class="share_icon_area">
                <ul>
                  <li>
                    <a href="!#"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="!#"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="!#"><i class="fab fa-instagram"></i></a>
                  </li>
                  <li>
                    <a href="!#"><i class="fab fa-linkedin-in"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "NewsAreaThree",
};
</script>

import CarRentalCheckOutView from "@/views/v2/sales/carrental/CarRentalCheckOutView.vue";
import CarRentalExtrasView from "@/views/v2/sales/carrental/CarRentalExtrasView.vue";
import CarRentalListView from "@/views/v2/sales/carrental/CarRentalListView.vue";
import CarRentalHomeView from "@/views/v2/sales/carrental/CarRentalHomeView.vue";

export default [
  {
    path: "/sales/v2/carRental",
    redirect: "/sales/v2/carRental/carRental-home",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "carrental-home",
        name: "sales-carrental-home-v2",
        meta: { requiresAuth: false, footerShow: true },
        component: CarRentalHomeView,
      },
      {
        path: "carrental-list",
        name: "sales-carrental-list-v2",
        meta: { requiresAuth: false },
        component: CarRentalListView,
      },
      {
        path: "carrental-extras",
        name: "sales-carrental-extras-v2",
        meta: { requiresAuth: false },
        component: CarRentalExtrasView,
      },
      {
        path: "carrental-check-out",
        name: "sales-carrental-check-out-v2",
        meta: { requiresAuth: false },
        component: CarRentalCheckOutView,
      },
    ],
  },
];

<script setup>
import { useI18n } from "vue-i18n";
import { Modal } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useTripStore } from "@/stores/v2/trip/tripStore";
import { ref, watch } from "vue";
import HotelItinerarySummaryPanel from "@/views/v2/sales/hotel/items/panel/HotelItinerarySummaryPanel.vue";
import ReservationContactInfoPanel from "@/views/v2/trip/booking/commom/ReservationContactInfoPanel.vue";
import HotelPassengerInfoPanel from "@/views/v2/trip/booking/items/panel/hotel/HotelPassengerInfoPanel.vue";
import HotelRoomRemarksPanel from "@/views/v2/trip/booking/items/panel/hotel/HotelRoomRemarksPanel.vue";
import HotelPoliciesPanel from "@/views/v2/sales/hotel/items/panel/tab/HotelPoliciesPanel.vue";
import HotelPriceSummaryOrBreakdownPanel from "@/views/v2/sales/hotel/items/panel/HotelPriceSummaryOrBreakdownPanel.vue";
import ReservationOperationPanel from "@/views/v2/trip/booking/commom/ReservationOperationPanel.vue";
import ReservationOverViewPanel from "@/views/v2/trip/booking/commom/ReservationOverViewPanel.vue";

const props = defineProps({
  tripId: {},
  isFromTripList: {},
  tripItemType: {},
  equipmentType: {},
});

const { t, locale } = useI18n(); // 解构调用函数

const AModal = Modal;
const emits = defineEmits();
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const tripStore = useTripStore();

const tripHotelItemRef = ref(null);
let createdDateTime = null;
let tripStatus = null;
let tripLimitPaidTime = null;
let reservationRefundable = null;
let expireInSecondRef = ref(null);
let isPaymentTimeExpired = ref(false);
let createdDateTimeRef = ref(null);
let bookingStatusRef = ref(null);
let invoiceIdRef = ref(null);

watch(
  () => tripStore.tripItems,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      const tripItem = newValue[0];

      if (tripItem) {
        createdDateTimeRef.value = newValue[0].createdDateTime;
        bookingStatusRef.value = newValue[0].tripStatus;
        invoiceIdRef.value = newValue[0].invoiceId;
      }
      if (createdDateTimeRef.value && bookingStatusRef.value) {
        let createTime = new Date(createdDateTimeRef.value).getTime();
        if (
          bookingStatusRef.value !== "CANCELLED" &&
          bookingStatusRef.value !== "CONFIRMED"
        ) {
          let nowTime = new Date().getTime();
          expireInSecondRef.value = (createTime - nowTime) / 1000 + 15 * 60;
          if (expireInSecondRef.value < 0) {
            isPaymentTimeExpired.value = true;
          }
        }
      }

      if (tripItem && tripItem.tripHotelItems) {
        const tripHotelItems = tripItem.tripHotelItems;
        if (tripHotelItems && tripHotelItems.length > 0) {
          tripHotelItemRef.value = tripHotelItems[0];

          if (
            tripHotelItemRef.value &&
            tripHotelItemRef.value.roomTypes &&
            tripHotelItemRef.value.roomTypes.length > 0
          ) {
            const roomTypeItem = tripHotelItemRef.value.roomTypes[0];
            if (
              roomTypeItem &&
              roomTypeItem.rooms &&
              roomTypeItem.rooms.length > 0
            ) {
              const roomItem = roomTypeItem.rooms[0];
              if (roomItem) {
                reservationRefundable = roomItem.refundable;
                let cancelPolicies = roomItem.cancelPolicies;
                if (cancelPolicies && cancelPolicies.length > 0) {
                  cancelPolicies = cancelPolicies.sort(
                    (a, b) => a.beginDate - b.beginDate
                  );
                  tripLimitPaidTime = cancelPolicies[0].beginDate;
                }
              }
            }
          }
        }
      }
    }
  },
  { immediate: true, deep: true }
);

//region 辅助函数
function buildContactInfo(passengers) {
  if (passengers && passengers.length > 0) {
    return passengers.find((item) => item.phoneNumber);
  }
}

function buildRoomRemarks(roomTypes) {
  const roomRemarks = [];
  if (roomTypes && roomTypes.length > 0) {
    const roomTypeItem = roomTypes[0];
    const roomTypeName = roomTypeItem.roomTypeName;
    if (roomTypeItem && roomTypeItem.rooms && roomTypeItem.rooms.length > 0) {
      roomTypeItem.rooms.forEach((room, roomIndex) => {
        const roomName = roomTypeName + " - " + (roomIndex + 1);
        const remark = room.specialRequest;
        if (remark) {
          roomRemarks.push({ roomName, remark });
        }
      });
    }
  }
  return roomRemarks;
}

//endregion

function syncSessionAndSearch() {
  emits("syncSessionAndSearch");
}
</script>

<template>
  <div v-if="tripHotelItemRef" class="row">
    <div class="col-10">
      <!-- booking manage -->
      <ReservationOperationPanel
        :booking-id="tripId"
        :booking-status="bookingStatusRef"
        :equipment-type="equipmentType"
        :invoice-id="invoiceIdRef"
        :show-continue-to-payment="
          expireInSecondRef > 0 &&
          tripItemType === 'HOTEL' &&
          bookingStatusRef !== 'CANCELLED' &&
          bookingStatusRef !== 'CONFIRMED' &&
          bookingStatusRef !== 'BOOKING_RECEIVED'
        "
        :trip-hotel-item="tripHotelItemRef"
        :trip-item-id="tripHotelItemRef.tripItemId"
        :trip-item-type="tripItemType"
        @syncSessionAndSearch="syncSessionAndSearch"
      />
      <!-- booking manage END-->

      <ReservationOverViewPanel
        :booking-status="bookingStatusRef"
        :created-date-time="createdDateTimeRef"
        :expire-in-second="expireInSecondRef"
        :is-from-trip-list="isFromTripList"
        :trip-id="tripId"
        :trip-item-type="tripItemType"
      />

      <!-- Itinerary Info -->

      <HotelItinerarySummaryPanel :trip-hotel-item="tripHotelItemRef" />
      <!-- hotel Info END-->

      <!-- contact Info -->
      <ReservationContactInfoPanel
        :contact-info="buildContactInfo(tripHotelItemRef.passengers)"
      />
      <!-- contact Info END-->

      <!-- Passenger Info -->
      <HotelPassengerInfoPanel :trip-hotel-item="tripHotelItemRef" />
      <!-- Passenger Info END-->

      <!-- Remarks Info -->
      <HotelRoomRemarksPanel
        :room-remarks="buildRoomRemarks(tripHotelItemRef.roomTypes)"
      />
      <!-- Remarks Info END-->

      <!--   Policies     -->
      <HotelPoliciesPanel
        v-if="tripHotelItemRef.policies && tripHotelItemRef.policies.length > 0"
        :policies="tripHotelItemRef.policies"
        :title="$t('policies')"
      />
      <!--   Policies   END  -->

      <!-- Price Summary  Or Breakdown-->
      <HotelPriceSummaryOrBreakdownPanel
        :is-hotel-reservation="true"
        :trip-hotel-item="tripHotelItemRef"
      />
      <!-- Price Summary  Or Breakdown END -->
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

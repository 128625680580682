function buildDynamicPkgBookRQ(dynamicPkgBookFormModel) {
  const airItineraryRPH = dynamicPkgBookFormModel.airItineraryRPH;
  const checkInDate = dynamicPkgBookFormModel.checkInDate;
  const checkOutDate = dynamicPkgBookFormModel.checkOutDate;
  const roomResGuests = dynamicPkgBookFormModel.roomResGuests;
  const uniqueIDs = dynamicPkgBookFormModel.uniqueIDs;
  const passengers = dynamicPkgBookFormModel.passengers;

  const roomStays = [];
  const resGuestsOta = [];
  let roomIndex = 0;

  let childRPHs = null;
  let adultRPHs = null;
  if (passengers && passengers.length > 0) {
    adultRPHs = passengers.filter((item) => item.passengerTypeCode === "ADT");
    childRPHs = passengers.filter((item) => item.passengerTypeCode === "CNN");
  }

  roomResGuests.forEach((roomResGuest) => {
    let guestCounts = [];
    roomResGuest.resGuests.forEach((resGuest) => {
      const ageQualifyingCode = resGuest.ageQualifyingCode;
      const age = parseInt(resGuest.age);
      let resGuestRPH = resGuest.resGuestRPH;
      if (resGuestRPH && adultRPHs && adultRPHs.length > 0)
        adultRPHs = adultRPHs.filter(
          (item) => item.travelerRefNumberRPH !== resGuestRPH
        );
      //分配RPH
      if (ageQualifyingCode === "8" && childRPHs && childRPHs.length > 0) {
        resGuestRPH = childRPHs[0].travelerRefNumberRPH;
        childRPHs.splice(0, 1);
      } else if (
        ageQualifyingCode === "10" &&
        !resGuestRPH &&
        adultRPHs &&
        adultRPHs.length > 0
      ) {
        resGuestRPH = adultRPHs[0].travelerRefNumberRPH;
        adultRPHs.splice(0, 1);
      }

      const guestCount = {
        resGuestRPH: resGuestRPH,
        ageQualifyingCode: ageQualifyingCode,
        age: age,
        count: 1,
      };
      guestCounts.push(guestCount);
    });

    let roomStay = {
      roomTypes: [
        {
          quantity: 1,
        },
      ],
      guestCounts: {
        guestCountList: guestCounts,
      },
      reference: {
        type: "UUID",
        id: roomResGuest.referenceId,
      },
      roomStayIndex: roomIndex,
    };
    if (roomResGuest.remarks) {
      roomStay.specialRequests = {
        specialRequests: [
          {
            text: roomResGuest.remarks,
          },
        ],
      };
    }
    roomIndex++;
    roomStays.push(roomStay);
  });
  if (passengers && passengers.length > 0) {
    passengers.forEach((passenger) => {
      const documents =
        passenger.documents &&
        passenger.documents.filter((item) => item.docID) &&
        passenger.documents.filter((item) => item.docID).length > 0
          ? passenger.documents.filter((item) => item.docID)
          : null;
      const guest = {
        resGuestRPH: passenger.travelerRefNumberRPH,
        ageQualifyingCode: passenger.passengerTypeCode,
        profiles: {
          profileInfos: [
            {
              profileList: [
                {
                  customer: {
                    personName: {
                      givenName: passenger.givenName,
                      surname: passenger.surname,
                      nameTitle: passenger.nameTitle,
                      travelerRefNumber: passenger.travelerRefNumberRPH,
                    },
                    gender: passenger.gender,
                    documents: documents,
                    birthDate: passenger.birthDate,
                    emailList: [
                      {
                        stringValue: passenger.emailAddress,
                      },
                    ],
                    telephoneList: [
                      {
                        phoneUseType: null,
                        countryAccessCode: passenger.countryAccessCode,
                        areaCityCode: passenger.dialAreaCode,
                        phoneNumber: passenger.phoneNumber,
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      };

      resGuestsOta.push(guest);
    });
  }

  return {
    dynamicPackage: {
      dynamicPkgAction: "Book",
      components: {
        airComponents: [
          {
            airItinerary: {
              airItineraryRPH: airItineraryRPH,
            },
            quoteID: airItineraryRPH,
          },
        ],
        hotelComponents: [
          {
            resStatus: "Book",
            resGlobalInfo: {
              timeSpan: {
                start: checkInDate,
                end: checkOutDate,
              },
            },
            uniqueIDList: uniqueIDs,
            roomStayList: roomStays,
          },
        ],
      },
      resGuests: resGuestsOta,
    },
  };
}

function getUniqueIDs(components) {
  let uniqueIDs = null;
  const dynamicComponents = components.dynamicComponents;
  if (dynamicComponents && dynamicComponents.length > 0) {
    const dynamicComponent = dynamicComponents[0];

    if (dynamicComponent) {
      uniqueIDs = dynamicComponent.uniqueIDs;
    }
  }

  return uniqueIDs;
}

export default {
  buildDynamicPkgBookRQ,
  getUniqueIDs,
};

<script setup>
import Slider from "@vueform/slider";
import { onMounted, reactive, ref, watch } from "vue";

import hotelTools from "@/tools/hotelTools";

const props = defineProps({
  minPrice: {
    type: Number,
  },
  maxPrice: {
    type: Number,
  },
  neighborhoods: {},
});

const emits = defineEmits(["filter"]);
const guestRatingOption = [
  {
    value: "",
    label: "any",
  },
  {
    value: "4.5",
    label: "wonderful-4.5+",
  },
  {
    value: "4.0",
    label: "very-good-4.0+",
  },
  {
    value: "3.5",
    label: "good-3.5+",
  },
];

const starRatingOption = [
  {
    value: "",
    label: "any",
  },
  {
    value: "1",
    label: 1,
  },
  {
    value: "2",
    label: 2,
  },
  {
    value: "3",
    label: 3,
  },
  {
    value: "4",
    label: 4,
  },
  {
    value: "5",
    label: 5,
  },
];

const mealPlanOption = [
  {
    value: "19",
    label: "breakfast-included",
  },
  {
    value: "21",
    label: "lunch-included",
  },
  {
    value: "22",
    label: "dinner-included",
  },
  {
    value: "1",
    label: "all-inclusive",
  },
];
let filterOptionRef = ref(new hotelTools.FilterOption());
let filterOptionRefOld = ref(null);
filterOptionRef.value.starRatings = [];
filterOptionRef.value.mealPlans = [];
filterOptionRef.value.priceRange = [props.minPrice, props.maxPrice];
filterOptionRef.value.placeId = null;
function filter() {
  filterOptionRef.value = JSON.parse(JSON.stringify(filterOptionRef.value));
  if (
    filterOptionRef.value.starRatings &&
    filterOptionRef.value.starRatings.length > 0
  ) {
    //新对象中“”的索引
    let indexOfAllTag = filterOptionRef.value.starRatings.indexOf("");
    //旧对象中“”的索引
    let indexOfAllOldTag = -1;
    if (filterOptionRefOld.value) {
      indexOfAllOldTag = filterOptionRefOld.value.starRatings.indexOf("");
    }
    //新对象的数组长度是否大于旧对象的数组长度
    let isLonger =
      filterOptionRefOld.value == null ||
      filterOptionRefOld.value.starRatings == null ||
      filterOptionRef.value.starRatings.length >
        filterOptionRefOld.value.starRatings.length;
    //增加
    if (
      (indexOfAllTag > -1 ||
        (indexOfAllTag < 0 &&
          indexOfAllOldTag < 0 &&
          filterOptionRef.value.starRatings.length == 5)) &&
      isLonger
    ) {
      //如果全选了
      filterOptionRef.value.starRatings = ["", "1", "2", "3", "4", "5"];
    }
    //减少,并且新对象不包含“”，旧对象包好“”
    else if (indexOfAllTag < 0 && indexOfAllOldTag > -1 && !isLonger) {
      //如果全选了
      filterOptionRef.value.starRatings = [];
    }
    //减少
    else if (indexOfAllTag > -1 && !isLonger) {
      //如果全选了
      filterOptionRef.value.starRatings.splice(indexOfAllTag, 1);
    }
  }
  filterOptionRefOld.value = JSON.parse(JSON.stringify(filterOptionRef.value));
  setTimeout(() => {
    emits("filter", filterOptionRef.value), 1000;
  });
}
</script>

<template>
  <div class="left_side_search_area">
    <div class="left_side_search_boxed">
      <div class="left_side_search_heading">
        <h5>{{$t("filter-by-price")}}</h5>
      </div>
      <div class="filter-price">
        <Slider
          v-model="filterOptionRef.priceRange"
          class="apply"
          :min="minPrice"
          :max="maxPrice"
          @change="filter"
        />
        <div
          class="d-flex justify-content-between align-items-center fs-sm text-muted py-2"
        >
          <span>0</span>
          <span>{{ Math.ceil(props.maxPrice / 5) }}</span>
          <span>{{ Math.ceil((props.maxPrice * 2) / 5) }}</span>
          <span>{{ Math.ceil((props.maxPrice * 3) / 5) }}</span>
          <span>{{ Math.ceil((props.maxPrice * 4) / 5) }}</span>
          <span>{{ Math.ceil(props.maxPrice) }}+</span>
        </div>
      </div>
    </div>

    <div class="left_side_search_boxed">
      <div class="left_side_search_heading">
        <h5>{{ $t("guest-rating") }}</h5>
      </div>
      <div class="tour_search_type">
        <div
          class="form-check"
          v-for="(rating, index) in guestRatingOption"
          :key="index"
        >
          <input
            class="form-check-input"
            type="radio"
            :id="'example-radios-default' + index"
            :name="'example-radios-default' + index"
            :value="rating.value"
            v-model="filterOptionRef.userRating"
            @change="filter"
            :checked="index === 0"
          />
          <label
            class="form-check-label"
            :for="'example-radios-default' + index"
            >{{ $t(rating.label) }}</label
          >
        </div>
      </div>
    </div>

    <div class="left_side_search_boxed">
      <div class="left_side_search_heading">
        <h5>{{$t("star-rating")}}</h5>
      </div>
      <div class="filter_review">
        <form class="review_star">
          <div
            class="form-check"
            v-for="(rating, index) in starRatingOption"
            :key="index"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="rating.value"
              :id="'star-rating-radio' + index"
              :name="'example-checkbox-inline' + index"
              v-model="filterOptionRef.starRatings"
              @change="filter"
            />
            <label
              class="form-check-label"
              :for="'star-rating-radio' + index"
              v-if="rating.label != 'any'"
            >
              <i
                class="fas fa-star color_theme"
                v-for="(num, index) in rating.label"
              ></i>
              <i
                class="fas fa-star color_asse"
                v-for="(num, index) in 5 - rating.label"
              ></i>
            </label>

            <label
              v-else
              class="form-check-label"
              :for="'star-rating-radio' + index"
              >{{ $t("any") }}
            </label>
          </div>
        </form>
      </div>
    </div>

    <div class="left_side_search_boxed">
      <div class="left_side_search_heading">
        <h5>{{ $t("meal-plans-available") }}</h5>
      </div>
      <div class="tour_search_type">
        <div
          class="form-check"
          v-for="(mealPlan, index) in mealPlanOption"
          :key="index"
        >
          <input
            class="form-check-input"
            type="checkbox"
            :value="mealPlan.value"
            :id="'meal-checkbox-' + mealPlan.value"
            :name="'example-checkbox-default' + index"
            v-model="filterOptionRef.mealPlans"
            @change="filter"
          />
          <label
            class="form-check-label"
            :for="'meal-checkbox-' + mealPlan.value"
            >{{ $t(mealPlan.label) }}</label
          >
        </div>
      </div>
    </div>

    <div
      class="left_side_search_boxed"
      v-if="neighborhoods && neighborhoods.length > 0"
    >
      <div class="left_side_search_heading">
        <h5>{{ $t("neighborhood") }}</h5>
      </div>
      <div class="tour_search_type">
        <div
          class="form-check"
          v-for="(neighborhood, neighborhoodIndex) in neighborhoods"
          :key="neighborhoodIndex"
        >
          <input
            class="form-check-input"
            type="radio"
            :id="'neighborhood' + neighborhoodIndex"
            name="example-radios-default"
            :value="neighborhood.placeId"
            v-model="filterOptionRef.placeId"
            @change="filter"
            :checked="neighborhoodIndex === 0"
          />
          <label
            class="form-check-label"
            :for="'neighborhood' + neighborhoodIndex"
            >{{ neighborhood.name }}</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

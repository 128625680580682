export class FlightBaggageAllowanceVO {
    /**
     *
     * @param unitOfMeasureQuantity number，单位对应数值
     * @param unitOfMeasure 单位，K-公斤（Kg），P-件（Pieces）
     * @param flightSegmentRPH 航段代码
     */
    constructor(unitOfMeasureQuantity,
                unitOfMeasure,
                flightSegmentRPH
    ) {
            this.unitOfMeasureQuantity = unitOfMeasureQuantity;
            this.unitOfMeasure = unitOfMeasure;
            this.flightSegmentRPH = flightSegmentRPH;
    }
}
export class TripHotelPassengerItemVO {
  /**
   *
   * @param primaryIndicator boolean，标识是否为联系人
   * @param age number，岁数
   * @param ageQualifyingCode string，住客年龄代码
   * @param resGuestRPH string，住客唯一标识
   * @param givenName string，
   * @param surname string，
   * @param nameTitle string，称呼
   * @param emailAddress string，联系邮件
   * @param countryAccessCode string，区号
   * @param phoneNumber string，电话号码
   * @param mainIndicator boolean，标识是否为主要人
   */
  constructor(
    primaryIndicator,
    age,
    ageQualifyingCode,
    resGuestRPH,
    givenName,
    surname,
    nameTitle,
    emailAddress,
    countryAccessCode,
    phoneNumber,
    mainIndicator
  ) {
    this.primaryIndicator = primaryIndicator;
    this.age = age;
    this.ageQualifyingCode = ageQualifyingCode;
    this.resGuestRPH = resGuestRPH;
    this.givenName = givenName;
    this.surname = surname;
    this.nameTitle = nameTitle;
    this.emailAddress = emailAddress;
    this.countryAccessCode = countryAccessCode;
    this.phoneNumber = phoneNumber;
    this.mainIndicator = mainIndicator;
  }
}

<script setup>
import { useTemplateStore } from "@/stores/template";
import { useCmsContentStore } from "@/stores/cms";
import { onMounted, reactive, ref, watch, watchEffect } from "vue";
import { Drawer, Empty, Spin } from "ant-design-vue";
import HotelAvailForm from "@/views/v2/sales/hotel/items/form/availability/HotelAvailForm.vue";
import { HotelItemsFilterFormModel } from "@/formmodel/sales/hotel/availability/hotelItemsFilterFormModel";
import { HotelItemsSortFormModel } from "@/formmodel/sales/hotel/availability/hotelItemsSortFormModel";
import { useHotelAvailabilityStore } from "@/stores/v2/hotel/availability/hotelAvailabilityStore";
import { useRoute, useRouter } from "vue-router";
import HotelListItem from "@/views/v2/sales/hotel/items/group/HotelListItem.vue";
import HotelResultsSortAndFilterForm from "@/views/v2/sales/hotel/items/form/availability/HotelResultsSortAndFilterForm.vue";

const AEmpty = Empty;
const ADrawer = Drawer;
const ASpin = Spin;
const router = useRouter();
const route = useRoute();
const hotelAvailabilityStore = useHotelAvailabilityStore();
const templateStore = useTemplateStore();

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContent = cmsContentStore.productPageBackgroundImage;
  imageUrl.value =
    cmsContentStore.productPageBackgroundImage &&
    cmsContentStore.productPageBackgroundImage.HotelBackgroundImage
      ? cmsContentStore.productPageBackgroundImage.HotelBackgroundImage.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});
//endregion cms内容管理

//region 初始化数据
const hotelItemsShowRef = ref([]);
const currentPageRef = ref(1); //每次load more添加一页显示
const isLoadMoreRef = ref(true);

//过滤排序相关
const hotelSortFormModelRef = ref(new HotelItemsSortFormModel("PRICE", "ASC"));
const hotelFilterFormModelRef = ref(
  new HotelItemsFilterFormModel(null, null, [], null, [], [], [])
);

//region watch监听pinia 获取最新的酒店数据视图列表
watch(
  () => hotelAvailabilityStore.hotelItemsShow,
  (newValue) => {
    if (newValue) {
      hotelItemsShowRef.value = newValue;
    } else {
      hotelItemsShowRef.value = [];
    }
  },
  { immediate: true, deep: true }
);
//endregion

//region 过滤排序总入口
function sortAndFilter(sortFormModel, filterFormModel) {
  hotelAvailabilityStore.changeHotelItemsShow(
    null,
    null,
    filterFormModel,
    sortFormModel
  );
}

//endregion

//region 辅助函数
let filterOpen = ref(false);
const showFilterDrawer = () => {
  filterOpen.value = true;
};
const closeFilterDrawer = () => {
  filterOpen.value = false;
};

function loadMoreItems() {
  if (hotelItemsShowRef.value.length >= hotelAvailabilityStore.totalResult) {
    isLoadMoreRef.value = false;
  }
  currentPageRef.value += 1;
  hotelAvailabilityStore.changeHotelItemsShow(currentPageRef.value, true);
}

function selectHotel(ttiCode) {
  const query = route.query;
  query.ttiCode = ttiCode;
  const routeUrl = router.resolve({
    name: "sales-hotel-details-v2",
    query: query,
  });
  window.open(routeUrl.href, "_blank");
}

//endregion

//region 设备相关

const equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);

function handleScroll() {
  let distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;

  if (distance === 0) {
    loadMoreItems();
  }
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});
//endregion
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("hotel-list") }}</h2>
        <ul>
          <li
            class="pre-page"
            v-if="route.query && route.query.isFromHome"
            @click="router.push({ name: 'home' })"
          >
            {{ $t("home") }}
          </li>
          <li
            v-else
            class="pre-page"
            @click="router.push({ name: 'sales-hotel-home-v2' })"
          >
            {{ $t("hotel") }}
          </li>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("hotel-list") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="theme_search_form_tour">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!--搜索表单 -->
          <HotelAvailForm :jumpable="false" />
          <!--搜索表单-->
        </div>
      </div>
    </div>
  </section>

  <section class="section_padding">
    <div class="container">
      <div class="row" v-if="equipmentType === 'iphone'">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mg-t-10">
          <div class="filter-sort">
            <div class="filtrate_btn h5" @click="showFilterDrawer">
              {{ $t("filter-and-sort") }}<i class="fa fa-fw fa-angle-down"></i>
            </div>
          </div>
          <!--  sort部分 -->
          <!--  filter部分 -->
          <a-drawer
            title="Filter Option"
            placement="bottom"
            :open="filterOpen"
            @close="closeFilterDrawer"
          >
            <HotelResultsSortAndFilterForm
              :hotel-filter-form-model="hotelFilterFormModelRef"
              :hotel-items-length="hotelAvailabilityStore.totalResult"
              :hotel-sort-form-model="hotelSortFormModelRef"
              :max-price="1000"
              :min-price="0"
              @sortAndFilter="sortAndFilter"
            />
          </a-drawer>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3" v-if="equipmentType !== 'iphone'">
          <HotelResultsSortAndFilterForm
            :hotel-filter-form-model="hotelFilterFormModelRef"
            :hotel-items-length="hotelAvailabilityStore.totalResult"
            :hotel-sort-form-model="hotelSortFormModelRef"
            :max-price="1000"
            :min-price="0"
            @sortAndFilter="sortAndFilter"
          />
        </div>

        <div class="col-lg-9">
          <div
            class="row"
            v-if="hotelItemsShowRef && hotelItemsShowRef.length > 0"
          >
            <div
              class="col-lg-4 col-md-6 col-sm-6 col-12"
              v-for="(hotelViewItem, hotelIndex) in hotelItemsShowRef"
              :key="hotelIndex"
            >
              <HotelListItem
                :item="hotelViewItem"
                class="cursor-point"
                @click="selectHotel(hotelViewItem.ttiCode)"
              />
            </div>
            <div
              class="text-center"
              v-if="
                isLoadMoreRef &&
                hotelAvailabilityStore.hotelItems &&
                hotelAvailabilityStore.hotelItems.length > 0 &&
                hotelAvailabilityStore.hotelItems.length >
                  hotelAvailabilityStore.limit &&
                hotelItemsShowRef > hotelAvailabilityStore.limit
              "
            >
              <Spin></Spin>
              <span class="mg-l-8 text-primary">{{ $t("load-more") }}..</span>
            </div>
          </div>
          <div v-else>
            <AEmpty></AEmpty>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

import FlightChangeAncillaryServiceView from "@/views/v2/trip/servicing/FlightChangeAncillaryServiceView.vue";
import FlightChangeSeatSelectionView from "@/views/v2/trip/servicing/FlightChangeSeatSelectionView.vue";

export default [
  {
    path: "/sales/v2/servicing",
    redirect: "/sales/v2/servicing/ancillary-service",
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "ancillary-service",
        name: "servicing-ancillary-service",
        component: FlightChangeAncillaryServiceView,
        meta: { requiresAuth: true },
      },
      {
        path: "seat-selection",
        name: "servicing-seat-selection",
        component: FlightChangeSeatSelectionView,
        meta: { requiresAuth: true },
      },
    ],
  },
];

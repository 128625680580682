<script setup>
import Banner from "@/components/templates/home/Banner.vue";
import { reactive, ref, watch, watchEffect } from "vue";
import BaseFlightSearchForm from "@/views/sales/flight/items/form/BaseFlightSearchForm.vue";
import Imagination from "@/components/templates/home/Imagination.vue";
import Partners from "@/components/templates/home/Partners.vue";
import FlightExploreDeals from "@/views/sales/flight/items/panel/flightExploreDeals.vue";
import { useCmsContentStore } from "@/stores/cms";
import { useTemplateStore } from "@/stores/template";

const cmsContentStore = useCmsContentStore();
//背景图部分
cmsContentStore.getFlightHeaderContent();

//Promotion Manegement
cmsContentStore.getPromotionManagements();

const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
let flightHeaderContent = reactive(null);
watchEffect(() => {
  cmsContentStore.flightHeaderContent,
    (flightHeaderContent = cmsContentStore.flightHeaderContent);
  imageUrl.value =
    cmsContentStore.flightHeaderContent &&
    cmsContentStore.flightHeaderContent.backgoundImage
      ? cmsContentStore.flightHeaderContent.backgoundImage.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});

//promotion
cmsContentStore.getFlightIdealExperiences();

//Promotion Manegement
cmsContentStore.getPromotionManagements();

// Explore our hot deals
cmsContentStore.getFlightPromotionPrimaryDeals();
let promotionFlights = reactive(null);

watchEffect(() => {
  cmsContentStore.flightPromotionPrimaryDeals,
    (promotionFlights = cmsContentStore.flightPromotionPrimaryDeals);
});

cmsContentStore.getOurPartner();

const templateStore = useTemplateStore();

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <!--   Banner Area -->
  <Banner
    :background-image-url="backgroundImageUrl"
    :header-content="cmsContentStore.flightHeaderContent"
  />
  <section id="theme_search_form">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <div class="theme_search_form_tabbtn">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="presentation">
                  <!--                          <button-->
                  <!--                                  class="nav-link active"-->
                  <!--                                  id="hotels-tab"-->
                  <!--                                  data-bs-toggle="tab"-->
                  <!--                                  data-bs-target="#hotels"-->
                  <!--                                  type="button"-->
                  <!--                                  role="tab"-->
                  <!--                                  aria-controls="hotels"-->
                  <!--                                  aria-selected="false"-->
                  <!--                          >-->
                  <!--                              <i class="fas fa-hotel"></i>Hotels-->
                  <!--                          </button>-->
                </li>

                <li class="nav-item" role="presentation">
                  <!--                          <button-->
                  <!--                                  class="nav-link"-->
                  <!--                                  id="flights-tab"-->
                  <!--                                  data-bs-toggle="tab"-->
                  <!--                                  data-bs-target="#flights"-->
                  <!--                                  type="button"-->
                  <!--                                  role="tab"-->
                  <!--                                  aria-controls="flights"-->
                  <!--                                  aria-selected="true"-->
                  <!--                          >-->
                  <!--                              <i class="fas fa-plane-departure"></i>Flights-->
                  <!--                          </button>-->
                </li>

                <li class="nav-item" role="presentation">
                  <!--                          <button-->
                  <!--                                  class="nav-link"-->
                  <!--                                  id="package-tab"-->
                  <!--                                  data-bs-toggle="tab"-->
                  <!--                                  data-bs-target="#package"-->
                  <!--                                  type="button"-->
                  <!--                                  role="tab"-->
                  <!--                                  aria-controls="package"-->
                  <!--                                  aria-selected="false"-->
                  <!--                          >-->
                  <!--                              <i class="fas fa-globe"></i>Package(Flight + Hotel)-->
                  <!--                          </button>-->
                </li>
              </ul>
            </div>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="flights"
                role="tabpanel"
                aria-labelledby="flights-tab"
              >
                <BaseFlightSearchForm
                  :equipment-type="equipmentType"
                  :jumboable="true"
                  :jump-to="'sales-flight-list'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- imagination Area -->

  <Imagination
    :promotion-deals="cmsContentStore.flightPromotionDeals"
    v-if="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.showFlightPagePrimaryPromotion
        : ''
    "
  />
  <!-- Explore our hot deals -->
  <flight-explore-deals
    v-if="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.showFlightPageHotPromotion
        : ''
    "
    :promotion-flights="cmsContentStore.flightPromotionPrimaryDeals"
    :show-flight-promotion-user-rating="
      cmsContentStore.promotionManagement
        ? cmsContentStore.promotionManagement.showFlightPromotionUserRating
        : ''
    "
  />

  <!-- Our partners Area -->
  <Partners :our-partners="cmsContentStore.ourPartners" />
</template>

<style scoped></style>

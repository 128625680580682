<script setup>
import BaseHotelItemPanel from "@/views/sales/hotel/items/panel/BaseHotelItemPanel.vue";
import data from "../../../data";
import BaseHotelFilterForm from "@/views/sales/hotel/items/form/BaseHotelFilterForm.vue";
import Banner from "@/components/custom/Banner.vue";
import { reactive } from "vue";
import BasePackageSearchForm from "@/views/sales/package/items/form/BasePackageSearchForm.vue";
import { useDynamicPackageStore } from "@/stores/dynamicPackage";
import dynamicPkgFormModelDummy from "@/data/dynamicPackage/dynamicPkgFormModelDummy";
import dynamicPackageTools from "@/tools/dynamicPackageTools";

const items = data.hotelData;
const navigation = reactive({
  title: "Package Search Result",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Package Search Result",
    },
  ],
});

let dynamicPackageStore = useDynamicPackageStore();
//Hotel Place area + Flight Round Trip Search
console.info("Place Search action");
let roomNum = dynamicPkgFormModelDummy.roomNum;
await dynamicPackageStore.availDynamicPkg(dynamicPkgFormModelDummy);
console.info(JSON.stringify(dynamicPackageStore.flightViewItemsGroup));
console.info(JSON.stringify(dynamicPackageStore.hotelViewItemMap));
//Hotel details search + Flight Round Trip Search
console.info("TTI code Search action");
dynamicPkgFormModelDummy.ttiCode = Object.keys(
  dynamicPackageStore.hotelViewItemMap
)[3];
await dynamicPackageStore.availDynamicPkg(dynamicPkgFormModelDummy);
console.info(JSON.stringify(dynamicPackageStore.hotelViewItemMap));
console.info(JSON.stringify(dynamicPackageStore.flightViewItemsGroup));
console.info(JSON.stringify(dynamicPackageStore.offerViewItemMap));
console.info(JSON.stringify(dynamicPackageStore.matchFlightViewItemsGroup));

//region 酒店详情页
let hotelKeys = Object.keys(dynamicPackageStore.hotelViewItemMap);
let roomTypes = dynamicPackageStore.hotelViewItemMap[hotelKeys[0]].roomTypes;
let roomTypeKeys = Object.keys(roomTypes);

//* 选择酒店
let roomViewItemChoose = roomTypes[roomTypeKeys[0]].rooms[0];
roomViewItemChoose.guestCounts = roomTypes[roomTypeKeys[0]].guestCounts;
//endregion 酒店详情页

//region 机票列表页
console.info("First Way");
console.info("通过Pinia获取默认航程的组合，为页面显示的航程列表");
console.info(dynamicPackageStore.matchFlightViewItemsGroup);

console.info("选择第一个航程的飞机");
let firstKey = Object.keys(dynamicPackageStore.matchFlightViewItemsGroup)[2];
console.info(firstKey);

console.info("根据选择的第一个航程的飞机，更换页面显示的航程列表");
await dynamicPackageStore.changeMatchFlightViewItemsGroup(1, firstKey);
console.info("Second Way");
console.info(console.info(dynamicPackageStore.matchFlightViewItemsGroup));

console.info("选择第二个航程的飞机，更换页面显示的航程列表");
let secondKey = Object.keys(dynamicPackageStore.matchFlightViewItemsGroup)[0];
let pricedItineraries = dynamicPackageStore.pricedItineraries;
let airItineraryRPH =
  dynamicPackageStore.matchFlightViewItemsGroup[secondKey][0].airItineraryRPH;
console.info(airItineraryRPH);

let specifiedPricedItinerary = dynamicPackageTools.getSpecifiedPricedItinerary(
  airItineraryRPH,
  pricedItineraries
);
console.info(roomViewItemChoose);
console.info(roomNum);
console.info(specifiedPricedItinerary);
let pkgPassengerFormModel = dynamicPackageTools.getPkgPassengerFormModel(
  roomViewItemChoose,
  roomNum,
  specifiedPricedItinerary.airItinerary
);
console.info(pkgPassengerFormModel);
dynamicPackageStore.verifyPriceDynamicPkg(pkgPassengerFormModel).then((res) => {
  console.info(res);
});

//todo成功后，跳转至行程预览页
//endregion 机票列表页
</script>

<template>
  <!-- Common Banner Area -->
  <Banner :navigate="navigation" />
  <section
    id="theme_search_form_tour"
    class="fligth_top_search_main_form_wrapper"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <div class="row">
              <div class="col-lg-12"><BasePackageSearchForm /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section_padding">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>38 hotel(s) found</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <BaseHotelFilterForm />
        </div>
        <div class="col-lg-9">
          <div class="row">
            <div
              v-for="(hotelInfo, index) in items"
              class="col-lg-4 col-md-6 col-sm-6 col-12"
              :key="index"
            >
              <BaseHotelItemPanel
                :hotel-info="hotelInfo"
                :to-path="'/sales/package/hotel-details'"
              />
            </div>
            <div class="col-lg-12">
              <div class="pagination_area">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

export class TaxFeeVO {
  /**
   *
   * @param code string，费用代码
   * @param type string，类型，FEE - 费用，TAX - 税费
   * @param inclusive boolean，是否包含在总价内
   * @param name string，费用名
   * @param currencyCode string，货币单位
   * @param amount number，金额
   */
  constructor(code, type, inclusive, name, currencyCode, amount) {
    this.code = code;
    this.type = type;
    this.inclusive = inclusive;
    this.name = name;
    this.currencyCode = currencyCode;
    this.amount = amount;
  }
}

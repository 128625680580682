<script setup>
import { reactive, ref, watch } from "vue";

import "vue3-tel-input/dist/vue3-tel-input.css";
import partyTravelOptions from "@/formmodel/user/profile/options/partyTravelOptions";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  userProfileFormModal: {},
});

const emits = defineEmits([]);
const userProfileFormModalRef = ref(null);

const seatPreferenceOptions = reactive(
  partyTravelOptions.seatPreferenceOptions
);
const mealPreferenceOptions = reactive(
  partyTravelOptions.mealPreferenceOptions
);

watch(
  () => props.userProfileFormModal,
  (newValue) => {
    userProfileFormModalRef.value = newValue;
  },
  { immediate: true, deep: true }
);

let rules = {
  seatPref: { required },
  mealPref: { required },
};

const v = useVuelidate(rules, userProfileFormModalRef);

async function modifyFlightPreference(userProfileFormModal) {
  const result = await v.value.$validate();
  if (!result) return;
  emits("modifyFlightPreference", userProfileFormModal, "FLIGHTPREFERENCE");
}
</script>

<template>
  <form
    action="!#"
    id="profile_form_area"
    @submit.prevent="modifyFlightPreference(userProfileFormModal)"
  >
    <h3>Flight Preference</h3>
    <div class="row mg-t-20">
      <div class="col-lg-6">
        <div class="form-group">
          <label for="mail-address">Seat Preference</label>
          <select
            name="passenger-form-gender"
            class="form-control"
            :class="{
              'is-invalid': v.seatPref.$errors.length,
            }"
            v-model="v.seatPref.$model"
          >
            <option :value="null" selected>
              {{ $t("select") }}
            </option>
            <option
              v-for="(option, index) in seatPreferenceOptions"
              :key="index"
              :value="option.code"
            >
              {{ $t(option.label) }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label for="mail-address">Meal Preference</label>
          <select
            name="passenger-form-gender"
            class="form-control"
            :class="{
              'is-invalid': v.mealPref.$errors.length,
            }"
            v-model="v.mealPref.$model"
          >
            <option :value="null" selected>
              {{ $t("select") }}
            </option>
            <option
              v-for="(prefer, index) in mealPreferenceOptions"
              :key="index"
              :value="prefer.code"
            >
              {{ $t(prefer.label) }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-lg-12" align="center">
      <button class="btn btn_theme">{{ $t("save") }}</button>
    </div>
  </form>
</template>

<style scoped></style>

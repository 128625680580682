<script setup>
import { reactive } from "vue";
import Banner from "@/components/custom/Banner.vue";

const navigation = reactive({
  title: "Seat Map",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Flight Search Result",
      path: "/sales/flight/flight-list",
    },
    {
      name: "Itinerary Review",
      path: "/sales/flight/flight-itinerary-review",
    },
    {
      name: "flight-passengers",
      path: "/sales/flight/flight-passengers",
    },
    {
      name: "Ancillary Services",
      path: "/sales/flight/flight-ancillary-service",
    },
    {
      name: "Seat Map",
      path: "/sales/flight/flight-seat-map",
    },
  ],
});
</script>

<template>
  <!-- Common Banner Area -->
  <Banner :navigate="navigation" />
</template>

<style scoped></style>

import axios from "@/utils/axiosUtils";

function dynamicPkgBook(bookRQ) {
  let url = "/api/v3/sales/shopping/dynamicpkg/dynamic-pkg-book";
  return axios.post(url, bookRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  dynamicPkgBook,
};

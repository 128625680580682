import ChangePasswordView from "@/views/security/ChangePasswordView.vue";
import SignInView from "@/views/auth/SignInView.vue";
import SignUpView from "@/views/auth/SignUpView.vue";
import ForgetPasswordView from "@/views/auth/ForgetPasswordView.vue";
import ReactiveAccountForm from "@/views/auth/items/ReactiveAccountForm.vue";
import ReactiveAccountView from "@/views/auth/ReactiveAccountView.vue";
export default [
  {
    path: "/security",
    redirect: "/security/sign-in",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "change-password",
        name: "security-change-password",
        component: ChangePasswordView   ,
        meta: {
          footerShow: true,
        },
      },
      {
        path: "sign-in",
        name: "security-sign-in",
        component: SignInView   ,
        meta: {
          footerShow: true,
        },
      },
      {
        path: "sign-up",
        name: "security-sign-up",
        component: SignUpView   ,
        meta: {
          footerShow: true,
        },
      },
      {
        path: "forget-password",
        name: "security-forget-password",
        component: ForgetPasswordView   ,
        meta: {
          footerShow: true,
        },
      },
      {
        path: "reactive-account",
        name: "security-reactive-acount",
        component: ReactiveAccountView   ,
        meta: {
          footerShow: true,
        },
      },
    ],
  },
];

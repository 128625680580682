const sortOptions = [
  {
    name: "Price(Lowest)",
    label: "price-lowest",
    type: "PRICE",
    order: "ASC",
  },
  {
    name: "Price(Highest)",
    label: "price-highest",
    type: "PRICE",
    order: "DSC",
  },
  {
    name: "Vehicle Name(A-Z)",
    label: "hotel-name-lowest",
    type: "NAME",
    order: "ASC",
  },
  {
    name: "Vehicle Name(Z-A)",
    label: "hotel-name-highest",
    type: "NAME",
    order: "DSC",
  },
];

export default {
  sortOptions,
};

<script setup>
import { ref, watch, watchEffect } from "vue";
import { Empty } from "ant-design-vue";
import { useFlightPriceStore } from "@/stores/v2/flight/price/flightPriceStore";
import { useTemplateStore } from "@/stores/template";

import { useI18n } from "vue-i18n";

const props = defineProps({
  servicesSelected: {},
  segments: {},
  passengers: {},
  flightPriceFormModel: {},
  idContext: {},
});

const emits = defineEmits();
const { t, locale } = useI18n(); // 解构调用函数
const flightPriceStore = useFlightPriceStore();
let templateStore = useTemplateStore();

const AEmpty = Empty;

const serviceTotalAmountRef = ref({
  amount: 0,
  currencyCode: null,
});

const errorServerMessageRef = ref(null);

const flightPriceFormModelRef = ref(null);

watch(
  () => props.flightPriceFormModel,
  (newValue) => {
    flightPriceFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watchEffect(() => {
  if (props.servicesSelected) {
    serviceTotalAmountRef.value =
      flightPriceFormModelRef.value.calculateServicesTotalPrice();
  }
});

function removeService(serviceSelected) {
  const flightSegmentRPH = serviceSelected.flightSegmentRPH;
  const travelerRPH = serviceSelected.travelerRPH;
  const ancillaryId = serviceSelected.ancillaryId;
  // const quantity = serviceSelected.quantity;
  flightPriceFormModelRef.value.removeService(
    flightSegmentRPH,
    travelerRPH,
    ancillaryId,
    null
  );
}

function getSegmentPassengerServices(
  servicesSelected,
  segmentRPH,
  travelerRefNumberRPH
) {
  let services = [];
  if (servicesSelected && servicesSelected.length > 0) {
    services = servicesSelected.filter(
      (item) =>
        item.travelerRPH === travelerRefNumberRPH &&
        item.flightSegmentRPH.indexOf(segmentRPH) !== -1 &&
        item.code !== "189C"
    );
  }
  return services;
}

async function confirmServiceSelections(flightPriceFormModel, idContext) {
  if (idContext === "FARELOGIX-UA") {
    emits("closeServicesModalPanel");
  } else {
    templateStore.pageLoader({ mode: "on" });
    let res = await flightPriceStore.airPriceOffer(flightPriceFormModel);
    if (!res.success) {
      const error = res.errors.errors[0].stringValue;
      errorServerMessageRef.value = error ? error : "Sever error!";
      AModal.error({
        title: "This is an error message",
        content: errorServerMessageRef.value,
        centered: true,
        width: 600,
        onOk() {
          return new Promise((resolve, reject) => {}).catch(() =>
            console.log("Oops errors!")
          );
        },
      });
      templateStore.pageLoader({ mode: "off" });
    } else {
      emits("closeServicesModalPanel");
      templateStore.pageLoader({ mode: "off" });
    }
  }
}

function sortSegments(segments) {
  if (segments && segments.length > 0) {
    return segments.sort((a, b) => b.departureDateTime - a.departureDateTime);
  }
}
</script>

<template>
  <div v-for="(segment, segIndex) in sortSegments(segments)" :key="segIndex">
    <div class="font_weight">
      <span>
        {{ segment.departureDateTime.split("T")[0] }}
      </span>

      <span class="mg-l-10"
        >{{ segment.departureAirportCode }}
        <i class="bi bi-arrow-right main_color"></i>
        {{ segment.arrivalAirportCode }}</span
      >

      <span class="mg-l-10">
        {{ segment.marketingAirlineCode }}{{ segment.flightNumber }}
      </span>
    </div>
    <div class="p-2">
      <div
        v-for="(passenger, passengerIndex) in passengers"
        :key="passengerIndex"
      >
        <div class="font_weight">
          {{ $t("passenger") }} - {{ passenger.travelerRefNumberRPH }}
        </div>
        <div
          v-if="
            getSegmentPassengerServices(
              servicesSelected,
              segment.rph,
              passenger.travelerRefNumberRPH
            ) &&
            getSegmentPassengerServices(
              servicesSelected,
              segment.rph,
              passenger.travelerRefNumberRPH
            ).length > 0
          "
        >
          <div
            v-for="(service, serviceIndex) in getSegmentPassengerServices(
              servicesSelected,
              segment.rph,
              passenger.travelerRefNumberRPH
            )"
            :key="serviceIndex"
            class="row mg-t-10"
          >
            <div class="col-7">
              {{ service.name }}
              <span
                v-if="
                  service.type === 'SEAT' &&
                  service.seatRowNumber &&
                  service.seatColumnNumber
                "
                class="text-bg-secondary mg-l-10"
                >{{ service.seatRowNumber }}
                {{ service.seatColumnNumber }}</span
              >
              <span class="mg-l-10 text-muted"> x {{ service.quantity }}</span>
            </div>
            <div class="col-3 text-end">
              {{ service.currencyCode }}
              {{ (service.amount * service.quantity).toFixed(2) }}
            </div>
            <div class="col-2 font-18 text-warning">
              <i
                class="bi bi-x-square cursor-point"
                @click="removeService(service)"
              ></i>
            </div>
          </div>
        </div>
        <div v-else class="p-1">
          <span class="text-muted">{{ $t("no-selection") }}</span>
        </div>
      </div>
    </div>
    <hr />
  </div>

  <div v-if="serviceTotalAmountRef" class="row mg-t-10">
    <div class="col-7 font_weight">
      {{ $t("total-amount") }}
    </div>
    <div class="col-5 text-end font_weight">
      {{ serviceTotalAmountRef.currencyCode }}
      {{ serviceTotalAmountRef.amount.toFixed(2) }}
    </div>
  </div>

  <div class="text-center mg-t-20">
    <button
      class="btn btn-outline-primary mb-2"
      @click="confirmServiceSelections(flightPriceFormModelRef, idContext)"
    >
      {{ $t("confirm") }}
    </button>
  </div>
</template>

<style lang="scss"></style>

import { defineStore } from "pinia";
import securityApi from "@/apis/securityApi";

export const useSecurityStore = defineStore("security", {
  state: () => {
    return {
      userInfo: null,
      routeInfo: null,
    };
  },
  actions: {
    getUserInfo() {
      securityApi
        .getSessionInfo()
        .then((res) => {
          this.userInfo = res.data;
          sessionStorage.setItem("user", JSON.stringify(res.data));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async signOut() {
      return securityApi
        .signOut()
        .then((res) => {
          // (res);
          this.userInfo = null;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
});

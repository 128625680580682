const messages = {
  en: {
    JRC: "Rochester Charlton Building Heliport",
    JRB: "Downtown Manhattan Heliport",
    JRA: "West 30Th St Heliport",
    JQO: "Belorussky Rail Station",
    JQJ: "Qianjiang Railway Station",
    JQF: "Moscow Savelovsky Rail Station",
    JQE: "Jaque Apt",
    JQA: "Qaarsut Apt",
    JPT: "Park Ten Heliport",
    JPR: "Ji-Parana Apt",
    JPD: "Pasadena Apt",
    JPA: "Casto Pinto Apt",
    JOT: "Joliet Park District Apt",
    JOS: "Jos Apt",
    JOP: "Josephstaal Apt",
    JON: "Johnston Atoll Apt",
    JOM: "Njombe Apt",
    JOL: "Jolo Apt",
    JOK: "Joshkar-Ola Apt",
    JOI: "Cubatao Apt",
    JOH: "Port Saint Johns Apt",
    JOG: "Adisutjipto Apt",
    JOE: "Joensuu Apt",
    JOA: "John Day",
    JNZ: "Jinzhou Apt",
    JNX: "Naxos Island Apt",
    JNU: "Juneau Intl Apt",
    JNS: "Narsaq Apt",
    JNP: "Newport Beach Apt",
    JNN: "Nanortalik Apt",
    JNJ: "Jaaluni Airport",
    JNI: "Junin Apt",
    JNH: "North Park Inn Heliport",
    JNG: "Jining Apt",
    JNB: "Johannesburg Intl Apt",
    JNA: "Januaria Apt",
    JMY: "Mammy Yoko Heliport",
    JMU: "Jiamusi Apt",
    JMS: "Jamestown Municipal Apt",
    JMO: "Jomsom Apt",
    JMN: "Municipal Heliport",
    JMM: "Malmo Harbour Heliport",
    JMK: "Mikonos Apt",
    JMH: "Marriott Heliport",
    JMD: "Market Centre Heliport",
    JMB: "Jamba Apt",
    JMA: "Marriot Astrodome Arpt",
    JLX: "Union Station Heliport",
    JLR: "Jabalpur Apt",
    JLP: "Juan Les Pins Apt",
    JLO: "Jesolo Apt",
    JLN: "Joplin Municipal Apt",
    JLL: "Jilin Railway Station",
    JLD: "Landskrona Heliport",
    JLA: "Cooper Landing Apt",
    JKV: "Jacksonville Apt",
    JKT: "Jakarta",
    JKR: "Janakpur Apt",
    JKL: "Kalymnos Arpt",
    JKH: "Chios Apt",
    JKG: "Axamo Apt",
    JJU: "Qaqortoq Apt",
    JJN: "Jinjiang Apt",
    JJM: "Mulika Lodge Airport",
    JJI: "Juanjui Apt",
    JIW: "Jiwani Apt",
    JIU: "Jiujiang Apt",
    JIT: "Kitsault",
    JIR: "Jiri Apt",
    JIQ: "Qianjiang Wulingshan Arpt",
    JIP: "Jipijapa Apt",
    JIO: "International Heliport",
    JIN: "Jinja Apt",
    JIM: "Jimma Apt",
    JIL: "Jilin Apt",
    JIK: "Ikaria Island Apt",
    JIJ: "Jijiga Apt",
    JIC: "Jinchuan Arpt",
    JIB: "Djibouti Apt",
    JIA: "Juina Apt",
    JHY: "Cambridge Apt",
    JHW: "Chautauqua County/Jamestown Apt",
    JHS: "Sisimiut Apt",
    JHQ: "Shute Harbour Apt",
    JHM: "West Maui Apt",
    JHG: "Jinghong Apt",
    JHE: "Helsingborg Heliport",
    JHC: "Garden City Apt",
    JHB: "Sultan Ismail Intl Apt",
    JGX: "Glendale Apt",
    JGS: "Jing Gang Shan Arpt",
    JGR: "Groennedal Apt",
    JGQ: "Transco Twr Galleria",
    JGP: "Greenway Plaza Heliport",
    JGO: "Qeqertarsuaq Apt",
    JGN: "Jiayuguan Apt",
    JGL: "Galleria Arpt",
    JGE: "Goeje Apt",
    JGD: "Jia Ge Da Qi Airport",
    JGC: "Grand Canyon Heliport",
    JGB: "Jagdalpur Apt",
    JGA: "Govardhanpur Apt",
    JFR: "Paamiut Apt",
    JFN: "Jefferson County Airpark",
    JFM: "Fremantle Apt",
    JFK: "John F. Kennedy Intl Apt",
    JER: "States Apt",
    JEQ: "Jequie Apt",
    JEM: "Emeryville Apt",
    JEK: "Jeki Airport",
    JEJ: "Jeh Apt",
    JEG: "Aasiaat Apt",
    JEF: "Jefferson City Apt",
    JEE: "Jeremie Apt",
    JED: "Jeddah Apt",
    JEA: "Jaen Rail Station",
    JDZ: "Jingdezhen Apt",
    JDX: "Central Bus District Heliport",
    JDT: "Minneapolis Downtown Heliport",
    JDR: "O De Almeida Neves Arpt",
    JDP: "Issy Les Moulineaux Arpt",
    JDO: "Regional do Cariri Apt",
    JDN: "Jordan Apt",
    JDM: "Downtown Hlt",
    JDH: "Jodhpur Apt",
    JDF: "Francisco de Assisi Apt",
    JDB: "Downtown Heliport",
    JDA: "John Day Apt",
    JCY: "Johnson City Apt",
    JCT: "Kimble County Apt",
    JCR: "Jacareacanga Apt",
    JCN: "Incheon Apt",
    JCM: "Jacobina Apt",
    JCK: "Julia Creek Apt",
    JCJ: "Chuja Heliport",
    JCI: "Johnson Industrial Arpt",
    JCH: "Qasigiannguit Apt",
    JCE: "Oakland Conv Ctr Hlpt",
    JCD: "Downtown Hp",
    JCC: "China Basin Hlpt",
    JCB: "Joacaba Apt",
    JCA: "Croisette Heliport",
    JBT: "Bethel Sea Plane Base",
    JBS: "Pleasanton Apt",
    JBR: "Jonesboro Municipal Apt",
    JBQ: "Dr Joaquin Balaguer",
    JBK: "Berkeley Apt",
    JBC: "Boston City Heliport",
    JBB: "Noto Hadinegoro Arpt",
    JAX: "Jacksonville Intl Apt",
    JAV: "Ilulissat Apt",
    JAU: "Jauja Apt",
    JAT: "Jabot Apt",
    JAS: "Jasper Apt",
    JAR: "Arcadia",
    JAQ: "Jacquinot Bay Apt",
    JAP: "Punta Renes Apt",
    JAO: "Beaver Ruin Helpt",
    JAN: "Jackson Intl Apt",
    JAM: "Jambol Apt",
    JAL: "Jalapa Apt",
    JAK: "Jacmel Apt",
    JAJ: "Perimeter Hlpt",
    JAI: "Sangneer Apt",
    JAH: "Aubagne Apt",
    JAG: "Jacobabad Apt",
    JAF: "Jaffna Apt",
    JAE: "Technology Park Arpt",
    JAD: "Jandakot Apt",
    JAC: "Jackson Hole Apt",
    JAB: "Jabiru Apt",
    JAA: "Jalalabad Apt",
    IZT: "Ixtepec Arpt",
    IZO: "Izumo Apt",
    IZM: "Cumadvasi Apt",
    IZA: "Presidente I Franco Arpt",
    IYK: "Inyokern Apt",
    IXZ: "Port Blair Apt",
    IXY: "Kandla Apt",
    IXW: "Jamshedpur Apt",
    IXV: "Along Apt",
    IXU: "Chikkalthana Apt",
    IXT: "Pasighat Apt",
    IXS: "Kumbhirgram Apt",
    IXR: "Ranchi Apt",
    IXQ: "Kamalpur Apt",
    IXP: "Pathankot Apt",
    IXN: "Khowai Apt",
    IXM: "Madurai Apt",
    IXL: "Leh Apt",
    IXK: "Keshod Apt",
    IXJ: "Satwari Apt",
    IXI: "Lilabari Apt",
    IXH: "Kailashahar Apt",
    IXG: "Belgaum Apt",
    IXE: "Bajpe Apt",
    IXD: "Allahabad Apt",
    IXC: "Chandigarh Apt",
    IXB: "Bagdogra Apt",
    IXA: "Singerbhil Apt",
    IWS: "West Houston",
    IWO: "Iwo Jima Apt",
    IWK: "Iwakuni Airport",
    IWJ: "Iwami Apt",
    IWD: "Gogebic County Apt",
    IWA: "Ivanovo Apt",
    IVW: "Inverway Apt",
    IVR: "Inverell Apt",
    IVO: "Chivolo Apt",
    IVL: "Ivalo Apt",
    IVH: "Ivishak Apt",
    IVG: "Ivangrad Apt",
    IVC: "Invercargill Apt",
    IVA: "Ambanja Apt",
    IUS: "Inus Apt",
    IUO: "Xianning Railway Station",
    IUM: "Summit Lake Apt",
    IUL: "Ilu Apt",
    IUE: "Hanan Apt",
    ITT: "Turin Rail Stn",
    ITR: "Itumbiara Apt",
    ITQ: "Itaqui Apt",
    ITP: "Itaperuna Apt",
    ITO: "Hilo Intl Apt",
    ITN: "Itabuna Apt",
    ITM: "Itami Apt",
    ITK: "Itokama Apt",
    ITI: "Itambacuri Apt",
    ITH: "Tompkins County Apt",
    ITE: "Itubera Apt",
    ITB: "Itaituba Apt",
    ITA: "Itacoatiara Apt",
    ISW: "Alexander Field",
    ISU: "Sulaymaniyah Intl ARPT",
    IST: "Istanbul Apt",
    ISS: "Wiscasset Apt",
    ISR: "Salerno Rail Station",
    ISQ: "Schoolcraft County Apt",
    ISP: "Long Island MacArthur Apt",
    ISO: "Stallings Field",
    ISN: "Sloulin Field",
    ISM: "Kissimmee Apt",
    ISL: "Isabel Pass Apt",
    ISK: "Nasik Apt",
    ISJ: "Isla Mujeres Municipal Apt",
    ISI: "Isisford Apt",
    ISH: "Ischia Apt",
    ISG: "Ishigaki Apt",
    ISE: "Isparta Apt",
    ISD: "Iscuande Apt",
    ISC: "Sainte Mary's Apt",
    ISB: "Rawalpindi Apt",
    ISA: "Mount Isa Apt",
    IRZ: "Santa Isabel Rio Negro Airport",
    IRT: "Rome Tiburtina Rail Stn",
    IRS: "Kirsch Municipal Apt",
    IRR: "Rome Ostiense Rail Stn",
    IRP: "Isiro Apt",
    IRO: "Birao Apt",
    IRN: "Iriona Apt",
    IRM: "Igrim Arpt",
    IRK: "Kirksville Municipal Apt",
    IRJ: "La Rioja Municipal Apt",
    IRI: "Iringa Apt",
    IRG: "Lockhart River Apt",
    IRE: "Irece Apt",
    IRD: "Ishurdi Apt",
    IRC: "New Apt",
    IRB: "Iraan Apt",
    IRA: "Kirakira Apt",
    IQT: "C. F. Secada Apt",
    IQQ: "Cavancha Apt",
    IQN: "Qingyang Apt",
    IQM: "Qiemo Apt",
    IPW: "Ipswich Apt",
    IPU: "Ipiau Apt",
    IPT: "Lycoming County Apt",
    IPR: "Milan Porta Garibaldi Rail Stn",
    IPN: "Ipatinga Apt",
    IPL: "Imperial County Apt",
    IPI: "San Luis Apt",
    IPH: "Ipoh Apt",
    IPG: "Ipiranga Apt",
    IPE: "Ipil Apt",
    IPC: "Mataveri Intl Apt",
    IPA: "Ipota Apt",
    IOW: "Iowa City Municipal Apt",
    IOU: "Ile Ouen Apt",
    IOS: "Eduardo Gomes Apt",
    IOR: "Inishmore Apt",
    IOP: "Ioma Apt",
    ION: "Impfondo Apt",
    IOM: "Ronaldsway Apt",
    IOK: "Iokea Apt",
    IOB: "Innsbruck Railway Station",
    IOA: "Ioannina Apt",
    INZ: "In Salah Apt",
    INX: "Inanwatan Apt",
    INW: "Winslow Municipal Apt",
    INV: "Inverness Apt",
    INU: "Nauru Island Intl Apt",
    INT: "Smith-Reynolds Intl Apt",
    INR: "Kincheloe AFB",
    INQ: "Inisheer Apt",
    INP: "Naples Central Rail Stn",
    INO: "Inongo Apt",
    INN: "Kranebitten Apt",
    INM: "Innamincka Apt",
    INL: "Intl Falls Apt",
    INK: "Wink Apt",
    INJ: "Injune Apt",
    INI: "Nis Apt",
    INH: "Inhambane Apt",
    ING: "Lago Argentino Apt",
    INF: "In Guezzam Apt",
    INE: "Chinde Apt",
    IND: "Indianapolis Intl Apt",
    INC: "Yinchuan Apt",
    INB: "Independence Apt",
    INA: "Inta Apt",
    IMZ: "Nimroz Apt",
    IMT: "Ford Apt",
    IMR: "Milan Rogoredo Rail Stn",
    IMP: "Imperatriz Apt",
    IMO: "Zemio Apt",
    IMN: "Imane Apt",
    IMM: "Immokalee Apt",
    IML: "Imperial Apt",
    IMK: "Simikot Apt",
    IMI: "Ine Island Apt",
    IMG: "Inhaminga Apt",
    IMF: "Imphal Municipal Apt",
    IMD: "Imonda Apt",
    IMB: "Imbaimadai Apt",
    IMA: "Iamalele Apt",
    ILZ: "Zilina Apt",
    ILY: "Glenegedale Apt",
    ILX: "Ileg Apt",
    ILU: "Kilaguni Apt",
    ILR: "Ilorin Apt",
    ILQ: "Ilo Apt",
    ILP: "Ile Des Pins Apt",
    ILO: "Mandurriao Apt",
    ILN: "Airborne Airpark",
    ILM: "New Hanover Intl Apt",
    ILL: "Willmar Apt",
    ILK: "Ilaka Apt",
    ILI: "Iliamna Apt",
    ILH: "Illisheim Apt",
    ILG: "New Castle County Apt",
    ILF: "Ilford Apt",
    ILE: "Killeen Municipal Apt",
    ILD: "Alguaire",
    ILB: "Ilha Solteira Apt",
    ILA: "Illaga Apt",
    IKT: "Irkutsk Apt",
    IKS: "Tiksi Apt",
    IKP: "Inkerman Apt",
    IKO: "Nikolski Municipal Apt",
    IKL: "Ikela Apt",
    IKK: "Greater Kankakee Apt",
    IKI: "Iki Apt",
    IKB: "Wilkesboro Apt",
    IKA: "Tehran Khomeini",
    IJX: "Jacksonville Municipal Apt",
    IJU: "Ijui Apt",
    IJK: "Izhevsk Apt",
    IIS: "Nissan Island Apt",
    IIN: "Nishino-Omote Apt",
    IIA: "Inishmaan Apt",
    IHU: "Ihu Apt",
    IHO: "Ihosy Apt",
    IHN: "Qishn Apt",
    IHA: "Niihama Apt",
    IGU: "Cataratas Apt",
    IGT: "Sleptsovskaya Airport",
    IGS: "Ingolstadt Arpt",
    IGR: "Iguazu Intl Apt",
    IGO: "Chigorodo Apt",
    IGN: "Iligan Apt",
    IGM: "Kingman Apt",
    IGL: "Izmir Cigli Military",
    IGH: "Ingham Apt",
    IGG: "Igiugig Apt",
    IGE: "Iguela Apt",
    IGD: "Igdir Arpt",
    IGB: "Ingeniero Jacobacci Apt",
    IGA: "Inagua Apt",
    IFP: "Laughlin/Bullhead Intl Apt",
    IFO: "Ivano-Frankovsk Apt",
    IFN: "Isfahan Apt",
    IFL: "Innisfail Apt",
    IFJ: "Isafjordur Apt",
    IFF: "Iffley Apt",
    IFA: "Iowa Falls Apt",
    IEV: "Zhulyany Apt",
    IET: "IET",
    IES: "Riesa Apt",
    IEN: "Now Shahr",
    IEJ: "Iejima Apt",
    IEG: "Zielona Gora Apt",
    IDY: "Ile D'Yeu Apt",
    IDR: "Indore Apt",
    IDO: "Santa Isabel Do Morro Apt",
    IDN: "Indagen Apt",
    IDK: "Indulkana Apt",
    IDI: "Indiana County Apt",
    IDG: "Ida Grove Municipal Apt",
    IDF: "Idiofa Apt",
    IDB: "Idre Apt",
    IDA: "Fanning Field",
    ICY: "Icy Bay Apt",
    ICT: "Mid-Continent Apt",
    ICR: "Nicaro Apt",
    ICO: "Sicogon Island Apt",
    ICN: "Incheion Intl Apt",
    ICL: "Clarinda Apt",
    ICK: "Nieuw Nickerie Apt",
    ICI: "Cicia Apt",
    ICA: "Icabaru Apt",
    IBZ: "Ibiza Apt",
    IBT: "Bologna Central Rail Stn",
    IBR: "Ibaraki Airport",
    IBP: "Iberia Apt",
    IBO: "Ibo Apt",
    IBI: "Iboki Apt",
    IBE: "Ibague Apt",
    IBA: "Ibadan Apt",
    IAU: "Iaura Apt",
    IAS: "Iasi Apt",
    IAR: "Yaroslavl Arpt",
    IAQ: "Bahregan Apt",
    IAO: "Sayak Airport",
    IAN: "Bob Barker Memorial Apt",
    IAM: "In Amenas Apt",
    IAL: "Ialibu",
    IAH: "George Bush Intercontinental Apt",
    IAG: "Niagara Falls Intl Apt",
    IAD: "Washington Dulles Intl Apt",
    IAB: "Mcconnell Airforce Base",
    IAA: "Igarka Apt",
    HZV: "Hazyview Apt",
    HZL: "Hazleton Municipal Apt",
    HZK: "Husavik Apt",
    HZG: "Hanzhong Apt",
    HZC: "Huzhou Rail Station",
    HZB: "Merville Calonne Arpt",
    HYS: "Hays Municipal Apt",
    HYR: "Hayward Apt",
    HYN: "Huangyan Apt",
    HYL: "Hollis Municipal Apt",
    HYG: "Hydaburg Municipal Apt",
    HYF: "Hayfields Apt",
    HYD: "Begumpet Apt",
    HYC: "High Wycombe Apt",
    HYA: "Hyannis Apt",
    HXX: "Hay Apt",
    HWO: "North Perry Apt",
    HWN: "Hwange National Park Apt",
    HWK: "Hawker Apt",
    HWI: "Hawk Inlet Apt",
    HWD: "Hayward Air Terminal",
    HWA: "Hawabango Apt",
    HVT: "Helsinki Tikkurila Rail Station",
    HVS: "Hartsville Apt",
    HVR: "Havre City County Apt",
    HVN: "Tweed Apt",
    HVM: "Hvammstangi Apt",
    HVK: "Holmavik Apt",
    HVG: "Valan Apt",
    HVE: "Hanksville Apt",
    HVD: "Khovd Apt",
    HVB: "Hervey Bay Apt",
    HVA: "Analalava Apt",
    HUZ: "Huizhou Apt",
    HUY: "Humberside Apt",
    HUX: "Huatulco Apt",
    HUV: "Hudiksvall Apt",
    HUU: "Huanuco Apt",
    HUT: "Hutchinson Municipal Apt",
    HUS: "Hughes Municipal Apt",
    HUQ: "Houn Apt",
    HUN: "Hualien Apt",
    HUM: "Houma Apt",
    HUL: "Houlton Intl Apt",
    HUK: "Hukuntsi Apt",
    HUJ: "Hugo Apt",
    HUI: "Hue Apt",
    HUH: "Flying Boat Apt",
    HUG: "Huehuetenango Apt",
    HUF: "Hulman Regional Apt",
    HUE: "Humera Apt",
    HUD: "Humboldt Apt",
    HUC: "Humacao Apt",
    HUB: "Humbert River Apt",
    HUA: "Redstone AAF",
    HTZ: "Hato Corozal Apt",
    HTY: "Hatay Apt",
    HTW: "Lawrence County Airpark",
    HTV: "Huntsville Municipal Apt",
    HTU: "Hopetown Apt",
    HTS: "Milton Apt",
    HTR: "Hateruma Apt",
    HTO: "East Hampton Apt",
    HTN: "Hotan Apt",
    HTL: "Roscommon County Apt",
    HTI: "Hamilton Island Apt",
    HTH: "Hawthorne Municipal Apt",
    HTG: "Hatanga Apt",
    HTF: "Hatfield Arpt",
    HTB: "Terre-De-Bas Apt",
    HTA: "Chita Apt",
    HSZ: "Hsinchu Apt",
    HSV: "Madison County Apt",
    HST: "Homestead Municipal",
    HSR: "Hot Springs",
    HSP: "Hot Springs Apt",
    HSN: "Zhoushan Apt",
    HSM: "Horsham Apt",
    HSL: "Huslia Apt",
    HSK: "Pirineos Airport",
    HSI: "Hastings Apt",
    HSH: "Henderson Sky Harbor Arpt",
    HSG: "Saga Apt",
    HSD: "Huangshi Rail Station",
    HSC: "Shaoguan Apt",
    HSB: "Harrisburg Apt",
    HRZ: "Horizontina Apt",
    HRY: "Henbury Apt",
    HRT: "HRT",
    HRS: "Harrismith Apt",
    HRR: "Herrera Apt",
    HRO: "Boone City Apt",
    HRN: "Heron Island Apt",
    HRM: "Hassi R Mel Apt",
    HRL: "Rio Grande Valley Intl Apt",
    HRK: "Kharkov Apt",
    HRJ: "Chaurjhari Apt",
    HRI: "Mattala Rajapakse Intl",
    HRG: "Hurghada Apt",
    HRE: "Harare Apt",
    HRC: "Zhairem Apt",
    HRB: "Harbin Apt",
    HRA: "Mansehra Apt",
    HQM: "Bowerman Apt",
    HPY: "Baytown Apt",
    HPV: "Princeville Apt",
    HPT: "Hampton Municipal Apt",
    HPR: "Central Hpr Arpt",
    HPN: "Westchester County Apt",
    HPH: "Haiphong Apt",
    HPG: "Hongping Arpt",
    HPE: "Hope Vale Apt",
    HPB: "Hooper Bay Apt",
    HPA: "Ha'Apai Apt",
    HOY: "Hoy Apt",
    HOX: "Homalin Apt",
    HOV: "Hovden Apt",
    HOU: "William P. Hobby Apt",
    HOT: "Hot Springs Memorial Field",
    HOS: "Chos Malal Apt",
    HOR: "Horta Apt",
    HOQ: "Pirk Apt",
    HOP: "Hopkinsville Christian Country Arpt",
    HOO: "Quanduc Apt",
    HON: "Huron Regional Apt",
    HOM: "Homer Apt",
    HOL: "Holikachuk Apt",
    HOK: "Hooker Creek Apt",
    HOI: "Hao Island Apt",
    HOH: "Hohenems Apt",
    HOG: "Frank Pais Apt",
    HOF: "Hofuf Apt",
    HOE: "Houeisay Apt",
    HOD: "Hodeidah Apt",
    HOC: "Komako Apt",
    HOB: "Lea County Apt",
    HOA: "Hola Apt",
    HNY: "Hengyang Apt",
    HNX: "Hanna Apt",
    HNS: "Haines Apt",
    HNO: "Hercegnovi Apt",
    HNN: "Honinabi Apt",
    HNM: "Hana Apt",
    HNL: "Honolulu Intl Apt",
    HNK: "Hinchinbrooke Island Apt",
    HNI: "Heiweni Apt",
    HNH: "Hoonah Apt",
    HNG: "Hienghene Apt",
    HNE: "Tahneta Pass Apt",
    HND: "Haneda Apt",
    HNC: "Hatteras Apt",
    HNB: "Huntingburg Apt",
    HNA: "Hanamaki Apt",
    HMY: "Seosan Bus Station",
    HMV: "Hemavan Apt",
    HMT: "Ryan Apt",
    HMS: "Homeshore Apt",
    HMR: "Hamar Apt",
    HMO: "General Ignacio Pesqueira Apt",
    HMN: "Holloman Airforce Base",
    HMJ: "Khmelnitskiy Apt",
    HMI: "Hami Apt",
    HMG: "Hermannsburg Apt",
    HME: "Hassi Messaoud Apt",
    HMB: "Sohag International Airport",
    HMA: "Malmo City Hvc",
    HLZ: "Hamilton Apt",
    HLY: "Valley Apt",
    HLW: "Hluhluwe Apt",
    HLV: "Helenvale Apt",
    HLU: "Houailou Apt",
    HLT: "Hamilton Apt",
    HLS: "Saint Helens Apt",
    HLR: "Fort Hood AAF",
    HLP: "Halim Perdanakusuma Intl Apt",
    HLO: "Onundarfjordur",
    HLN: "Helena Regional Apt",
    HLM: "Park Township Apt",
    HLL: "Hillside Apt",
    HLJ: "Shauliaj Apt",
    HLI: "Hollister Municipal Apt",
    HLH: "Ulanhot Apt",
    HLG: "Wheeling Apt",
    HLF: "Hultsfred Apt",
    HLD: "Hailar Apt",
    HLC: "Hill City Municipal Apt",
    HLB: "Hillenbrand Industries Apt",
    HLA: "Lanseria Apt",
    HKY: "Hickory Municipal Apt",
    HKW: "Hankow",
    HKV: "Haskovo Apt",
    HKT: "Phuket Apt",
    HKS: "Hawkins Field",
    HKN: "Hoskins Apt",
    HKK: "Hokitika Apt",
    HKG: "Hong Kong Intl Apt",
    HKD: "Hakodate Apt",
    HKC: "Hong Kong China Ferry Terminal",
    HKB: "Healy Lake Municipal Apt",
    HKA: "Blytheville Municipal Apt",
    HJR: "Khajuraho Apt",
    HJJ: "Zhi Jiang Airport",
    HIX: "Hiva Oa Apt",
    HIW: "Hiroshima West Arpt",
    HIU: "Higuerote",
    HIT: "Hivaro Apt",
    HIS: "Hayman Island Apt",
    HIR: "Henderson Intl Apt",
    HIP: "Headingly Apt",
    HIO: "Hillsboro Apt",
    HIN: "Jinju Apt",
    HIL: "Shillavo Apt",
    HIK: "Hickam Air Force Base",
    HIJ: "Hiroshima Apt",
    HII: "Lake Havasu City Apt",
    HIH: "Hook Island Apt",
    HIG: "Highbury Apt",
    HIF: "Hill AFB",
    HIE: "Whitefield Apt",
    HID: "Horn Island Apt",
    HIC: "Iscor Heliport",
    HIB: "Hibbing/Chisholm Apt",
    HIA: "Lianshui",
    HHZ: "Hikueru Apt",
    HHR: "Hawthorne Municipal Apt",
    HHQ: "Hua Hin Apt",
    HHP: "Hong Kong Heliport",
    HHN: "Hahn Apt",
    HHH: "Hilton Head Apt",
    HHE: "Hachinohe Apt",
    HHA: "Huanchua Apt",
    HGZ: "Hogatza Apt",
    HGU: "Kagamugu Apt",
    HGT: "Jolon Apt",
    HGS: "Hastings Apt",
    HGR: "Washington County Regional Apt",
    HGO: "Korhogo Apt",
    HGN: "Mae Hong Son Apt",
    HGL: "Helgoland Apt",
    HGH: "Hangzhou Apt",
    HGD: "Hughenden Apt",
    HGB: "HGB",
    HGA: "Hargeisa Apt",
    HFT: "Hammerfest Apt",
    HFS: "Hagfors Apt",
    HFN: "Hornafjordur Apt",
    HFF: "Hoffman Apt",
    HFE: "Hefei Apt",
    HFD: "Brainard Apt",
    HFA: "Haifa Apt",
    HEZ: "Natchez Apt",
    HEY: "Hanchey Army Heliport",
    HEX: "Herrera Arpt",
    HEV: "Huelva Rail Station",
    HET: "Hohhot Apt",
    HES: "Hermiston Municipal Apt",
    HER: "North Kazantzakis Apt",
    HEO: "Haelogo Apt",
    HEN: "Hendon Apt",
    HEM: "Helsinki Malmi Arpt",
    HEL: "Vantaa Apt",
    HEK: "Heihe Apt",
    HEI: "Heide/Buesum Apt",
    HEH: "Heho Apt",
    HEE: "Helena Apt",
    HED: "Herendeen Bay Apt",
    HEC: "Helsinki Central Rail Station",
    HEB: "Henzada Apt",
    HEA: "Herat Apt",
    HDY: "Hat Yai Apt",
    HDS: "Hoedspruit Apt",
    HDQ: "Headquarters",
    HDN: "Yampa Valley Apt",
    HDM: "Hamadan Apt",
    HDL: "Holikachuk",
    HDG: "Hebei Handan",
    HDF: "Heringsdorf Apt",
    HDE: "Holdrege Apt",
    HDD: "Hyderabad Apt",
    HDB: "HDB",
    HDA: "Hidden Falls Apt",
    HCW: "Cheraw Apt",
    HCS: "Randburg Heliport",
    HCR: "Holy Cross Apt",
    HCQ: "Halls Creek Apt",
    HCN: "Hengchun Apt",
    HCM: "Eil Apt",
    HCJ: "Jin Cheng Jiang Rail Station",
    HCB: "Shoal Cove Apt",
    HCA: "Howard County Apt",
    HBX: "Hubli Apt",
    HBV: "Harbinxi Railway Station",
    HBT: "Hafr Albatin Apt",
    HBR: "Hobart Municipal Apt",
    HBP: "Hengyang Rail Station",
    HBO: "Humboldt Apt",
    HBN: "Phu-Bon Apt",
    HBM: "Macheng North Railway Station",
    HBL: "Babelegi Apt",
    HBI: "Harbour Island Apt",
    HBH: "Entrance Island Sea Plane Base",
    HBG: "Hattiesburg Apt",
    HBE: "Borg El Arab Apt",
    HBD: "Habi Apt",
    HBC: "Hanus Bay Apt",
    HBB: "Crossroads Inn Apt",
    HBA: "Hobart Intl Apt",
    HAZ: "Hatzfeldthaven Apt",
    HAY: "Haycock Apt",
    HAX: "Hatbox Field Arpt",
    HAW: "Haverfordwest Apt",
    HAV: "Aeropuerto Internacional Jose Marti",
    HAU: "Haugesund Apt",
    HAT: "Heathlands Apt",
    HAS: "Hail Apt",
    HAR: "Harrisburg Skyport",
    HAQ: "Hanimaadhoo Apt",
    HAP: "Long Island Apt",
    HAO: "Fairfield Apt",
    HAN: "Noibai Apt",
    HAM: "Fuhlsbuttel Apt",
    HAL: "Halali Apt",
    HAK: "Haikou Apt",
    HAJ: "Hanover Apt",
    HAI: "Three Rivers Apt",
    HAH: "Prince Said Intl Apt",
    HAG: "The Hague Apt",
    HAF: "Half Moon Bay Apt",
    HAE: "Havasupai Apt",
    HAD: "Halmstad Apt",
    HAC: "Hachijo Jima Apt",
    HAB: "Hamilton Apt",
    HAA: "Hasvik Apt",
    GZT: "Gaziantep Apt",
    GZP: "Gazipasaairport",
    GZO: "Gizo Apt",
    GZM: "Gozo Apt",
    GZI: "Ghazni Apt",
    GZA: "Gaza Apt",
    GYY: "Gary Regional Apt",
    GYU: "Liu Pan Shan Arpt",
    GYS: "Guang Yuan Apt",
    GYR: "Goodyear Apt",
    GYP: "Gympie Apt",
    GYN: "Santo Genoveva Apt",
    GYM: "General Jose Maria Yanez Apt",
    GYL: "Argyle Apt",
    GYI: "Gisenyi Apt",
    GYE: "Simon Bolivar Apt",
    GYD: "Baku Heydar International",
    GYA: "Guayaramerin Apt",
    GXY: "Greeley Apt",
    GXX: "Yagoua Apt",
    GXQ: "Teniente Vidal Apt",
    GXH: "Gannan Xiahe Airport",
    GXG: "Negage Apt",
    GXF: "Seiyun Apt",
    GWY: "Cammore Apt",
    GWV: "Fokker Field",
    GWT: "Westerland Apt",
    GWS: "Glenwood Springs Municipal Apt",
    GWO: "Greenwood Apt",
    GWN: "Gnarowein Apt",
    GWL: "Gwalior Apt",
    GWE: "Gweru Apt",
    GWD: "Gwadar Apt",
    GWA: "Gwa Apt",
    GVX: "Sandviken Apt",
    GVW: "Grandview Apt",
    GVT: "Majors Apt",
    GVR: "Governador Valadares Apt",
    GVP: "Greenvale Apt",
    GVN: "May Gatka Airport",
    GVL: "Gainesville Apt",
    GVI: "Green River Apt",
    GVE: "Gordonsville Apt",
    GVA: "Cointrin Apt",
    GUZ: "Guarapari Apt",
    GUY: "Guymon Municipal Apt",
    GUX: "Guna Apt",
    GUW: "Atyrau Apt",
    GUV: "Mougulu Apt",
    GUU: "Grundarfjordur Apt",
    GUT: "Gutersloh Apt",
    GUR: "Gurney Apt",
    GUQ: "Guanare Apt",
    GUP: "Gallup Municipal Apt",
    GUO: "Gualaco Apt",
    GUN: "Gunter AFB",
    GUM: "Antonio B. Won Pat Intl Apt",
    GUL: "Goulburn Apt",
    GUJ: "Guaratingueta Apt",
    GUI: "Guiria Apt",
    GUH: "Gunnedah Apt",
    GUG: "Guari Apt",
    GUF: "Gulf Shores Apt",
    GUD: "Goundam Apt",
    GUC: "Gunnison County Apt",
    GUB: "Guerrero Negro Apt",
    GUA: "La Aurora Apt",
    GTY: "Gettysburg Apt",
    GTW: "Zlin Apt",
    GTT: "Georgetown Apt",
    GTS: "Granites Apt",
    GTR: "Golden Triangle Regional Apt",
    GTO: "Tolotio Apt",
    GTN: "Glentanner Apt",
    GTK: "Sungei Tekai Apt",
    GTI: "Guettin Apt",
    GTG: "Grantsburg Apt",
    GTF: "Great Falls Intl Apt",
    GTE: "Alyangula Apt",
    GTC: "Green Turtle Apt",
    GTB: "Genting Apt",
    GTA: "Gatokae Apt",
    GSY: "Grimsby Apt",
    GSU: "Gedaref Apt",
    GST: "Gustavus Apt",
    GSR: "Gardo Apt",
    GSQ: "Shark Alowainat Apt",
    GSP: "Greenville/Spartanburg Apt",
    GSO: "Piedmont Triad Intl Apt",
    GSN: "Mount Gunson Apt",
    GSM: "Gheshm Apt",
    GSL: "Taltheilei Narrows Apt",
    GSI: "Guadalcanal Apt",
    GSH: "Goshen Municipal Apt",
    GSE: "Saeve Apt",
    GSC: "Gascoyne Junction Apt",
    GSB: "Goldsboro Apt",
    GSA: "Long Pasia Apt",
    GRZ: "Thalerhof Apt",
    GRY: "Grimsey Apt",
    GRX: "Granada Apt",
    GRW: "Graciosa Island Apt",
    GRV: "Groznyj Apt",
    GRU: "Guarulhos Intl Apt",
    GRT: "Gujrat Apt",
    GRS: "Grosseto Apt",
    GRR: "Kent County Intl Apt",
    GRQ: "Eelde Apt",
    GRP: "Gurupi Municipal Apt",
    GRO: "Costa Brava Apt",
    GRN: "Gordon Apt",
    GRM: "Grand Marais Apt",
    GRL: "Garasa Apt",
    GRK: "Gray AAF",
    GRJ: "George Apt",
    GRI: "Grand Island Regional Apt",
    GRH: "Garuahi Apt",
    GRG: "Gardez Apt",
    GRF: "Gray Army Air Field",
    GRE: "Greenville Apt",
    GRD: "Greenwood Apt",
    GRC: "Grand Cess Apt",
    GRB: "Austin Straubel Intl Apt",
    GRA: "Gamarra Apt",
    GQQ: "Galion Municipal Apt",
    GQJ: "Machrihanish Apt",
    GPZ: "Grand Rapids Apt",
    GPT: "Gulfport-Biloxi Regional Apt",
    GPS: "Galapagos Islands Apt",
    GPO: "General Pico Apt",
    GPN: "Garden Point Apt",
    GPL: "Guapiles Apt",
    GPI: "Guapi Municipal Apt",
    GPB: "Guarapuava Apt",
    GPA: "Patras Apt",
    GOZ: "Gorna Orjahovica Apt",
    GOY: "Gal Oya Apt",
    GOV: "Gove Apt",
    GOU: "Garoua Apt",
    GOT: "Landvetter Apt",
    GOS: "Gosford Apt",
    GOR: "Gore Apt",
    GOQ: "Golmud Apt",
    GOP: "Gorakhpur Apt",
    GOO: "Goondiwindi Apt",
    GON: "New London/Groton Apt",
    GOM: "Goma Apt",
    GOL: "Gold Beach Municipal Apt",
    GOK: "Guthrie Municipal Apt",
    GOJ: "Nizhniy Novgogrod Apt",
    GOI: "Dabolim Apt",
    GOH: "Nuuk Apt",
    GOG: "Gobabis Apt",
    GOF: "Goodfellow Air Force Base",
    GOE: "Gonalia Apt",
    GOC: "Gora Apt",
    GOB: "Goba Apt",
    GOA: "Cristoforo Colombo Apt",
    GNZ: "Ghanzi Apt",
    GNY: "Granby Apt",
    GNV: "Gainesville Regional Apt",
    GNU: "Goodnews Bay Apt",
    GNT: "Grants-Milan Municipal Apt",
    GNS: "Gunungsitoli Apt",
    GNR: "General Roca Municipal Apt",
    GNO: "Pointe Saline",
    GNN: "Ghinnir Apt",
    GNM: "Guanambi Apt",
    GNJ: "Genjem",
    GNI: "Green Island Apt",
    GNG: "Gooding Municipal Apt",
    GNF: "Gansner Field",
    GNE: "Ghent Apt",
    GND: "Saint George's Apt",
    GNB: "Saint Geoirs Apt",
    GNA: "Grodno Apt",
    GMZ: "La Gomera Apt",
    GMY: "Rheindahlen Apt",
    GMV: "Monument Valley Gouldings Apt",
    GMU: "Greenville Downtown Arpt",
    GMT: "Granite Mountain Apt",
    GMS: "Guimaraes Apt",
    GMR: "Gambier Island Apt",
    GMP: "Kimpo Intl Apt",
    GMO: "Lawanti Airport",
    GMN: "Greymouth Apt",
    GMM: "Gamboma Apt",
    GMI: "Gasmata Apt",
    GME: "Gomel Apt",
    GMC: "Guerima Apt",
    GMB: "Gambela Apt",
    GMA: "Gemena Apt",
    GLZ: "Breda Apt",
    GLY: "Goldsworthy Apt",
    GLX: "Galela Apt",
    GLW: "Glasgow Apt",
    GLV: "Golovin Apt",
    GLT: "Gladstone Apt",
    GLS: "Scholes Field",
    GLR: "Otsego County Apt",
    GLQ: "Glenallen Apt",
    GLP: "Gulgubip Apt",
    GLO: "Gloucester Apt",
    GLN: "Goulimime Apt",
    GLM: "Glenormiston Apt",
    GLL: "Gol Apt",
    GLK: "Galcaio Apt",
    GLI: "Glen Innes Apt",
    GLH: "Greenville Municipal Apt",
    GLG: "Glengyle Apt",
    GLF: "Golfito Apt",
    GLE: "Gainesville Municipal Apt",
    GLD: "Renner Field",
    GLC: "Geladi Apt",
    GLB: "San Carlos Apache",
    GLA: "Glasgow Intl Apt",
    GKT: "Gatlinburg Apt",
    GKO: "Kongo-Boumba Apt",
    GKN: "Gulkana Apt",
    GKL: "Great Keppel Island Apt",
    GKK: "Kooddoo Arpt",
    GKH: "Gorkha Apt",
    GKE: "Geilenkirchen Apt",
    GKA: "Goroka Apt",
    GJT: "Walker Field",
    GJR: "Gjogur Apt",
    GJN: "Zhijiang Rail Station",
    GJM: "Guajara-Mirim Apt",
    GJL: "Jijel Apt",
    GJA: "Guanaja Apt",
    GIZ: "Gizan Apt",
    GIY: "Giyani Apt",
    GIT: "Geita Apt",
    GIS: "Gisborne Apt",
    GIR: "Girardot Apt",
    GIO: "Geilo",
    GIM: "Miele Mimbale Apt",
    GIL: "Gilgit Apt",
    GII: "Siguiri Apt",
    GIG: "Rio Internacional Apt",
    GIF: "Winter Haven Apt",
    GID: "Gitega Apt",
    GIC: "Boigu Island Apt",
    GIB: "North Front Apt",
    GIA: "Girona Rail Station",
    GHU: "Gualeguaychu Apt",
    GHT: "Ghat Apt",
    GHN: "Guanghan Apt",
    GHM: "Centerville Municipal Apt",
    GHK: "Gush Katif Apt",
    GHF: "Giebelstadt Apt",
    GHE: "Garachine Apt",
    GHD: "Ghimbi Apt",
    GHC: "Great Harbour Cay Apt",
    GHB: "Governor's Harbour Apt",
    GHA: "Ghardaia Apt",
    GGZ: "Graz Railway Station",
    GGW: "Glasgow Intl Apt",
    GGT: "George Town Apt",
    GGS: "Gobernador Gregores Apt",
    GGR: "Garoe Apt",
    GGO: "Guiglo Apt",
    GGN: "Gagnoa Apt",
    GGM: "Kakamega Airport",
    GGL: "Gilgal Apt",
    GGG: "Gregg County Apt",
    GGE: "Georgetown Apt",
    GGD: "Gregory Downs Apt",
    GGC: "Lumbala Apt",
    GFY: "Grootfontein Apt",
    GFR: "Granville Apt",
    GFO: "Bartica Apt",
    GFN: "Grafton Apt",
    GFL: "Warren County Apt",
    GFK: "Grand Forks Intl Apt",
    GFF: "Griffith Apt",
    GFE: "Grenfell Apt",
    GFD: "Pope Field",
    GFB: "Togiak Fish Municipal Apt",
    GFA: "Malmstrom Airforce Base",
    GEY: "Greybull Apt",
    GEX: "Geelong Apt",
    GEW: "Gewoya Apt",
    GEV: "Gallivare Apt",
    GET: "Geraldton Apt",
    GES: "General Santos Apt",
    GER: "Rafael Cabrera Apt",
    GEO: "Georgetown Apt",
    GEN: "Puente Genil Rail Station",
    GEL: "Sepe Tiaraju Apt",
    GEK: "Ganes Creek Apt",
    GEI: "Green Islands Apt",
    GEG: "Spokane Intl Apt",
    GEE: "George Town Apt",
    GED: "Sussex County Apt",
    GEC: "Gecitkale Apt",
    GEB: "Gebe Apt",
    GEA: "Magenta Apt",
    GDZ: "Gelendzik Apt",
    GDX: "Magadan Apt",
    GDW: "Gladwin Apt",
    GDV: "Dawson Community Apt",
    GDU: "Guadalajara Rail Station",
    GDT: "Grand Turk Apt",
    GDS: "Gosford",
    GDQ: "Gondar Apt",
    GDP: "Guadalupe Apt",
    GDO: "Guasdualito Apt",
    GDN: "Rebiechowo Apt",
    GDM: "Gardner Municipal Apt",
    GDL: "Miguel Hidalgo y Costilla Apt",
    GDJ: "Gandajika Apt",
    GDI: "Gordil Apt",
    GDH: "Golden Horn Apt",
    GDE: "Gode Apt",
    GDD: "Gordon Downs Apt",
    GDC: "Donaldson Center Arpt",
    GDA: "Gounda Apt",
    GCY: "Municipal Greeneville Arpt",
    GCV: "Gravatai Apt",
    GCN: "Grand Canyon National Park Apt",
    GCM: "Owen Roberts Apt",
    GCK: "Garden City Regional Apt",
    GCJ: "Grand Central Apt",
    GCI: "Guernsey Apt",
    GCC: "Campbell County Apt",
    GCA: "Guacamaya Apt",
    GBZ: "Great Barrier Island Apt",
    GBX: "Granada Bus Station",
    GBV: "Gibb River Apt",
    GBU: "Khashm El Girba Apt",
    GBS: "Port Fitzroy Apt",
    GBR: "Great Barrington Apt",
    GBQ: "Muharraq Arpt",
    GBP: "Gamboola Apt",
    GBO: "Baltimore Greenbelt Arpt",
    GBN: "San Giovanni Rotondo Apt",
    GBM: "Garbaharey Apt",
    GBL: "Goulburn Island Apt",
    GBK: "Gbangbatok Apt",
    GBJ: "Marie Galante Apt",
    GBI: "Grand Bahama Apt",
    GBH: "Galbraith Apt",
    GBG: "Galesburg Municipal Apt",
    GBF: "Negarbo Apt",
    GBE: "Garbone Intl Apt",
    GBD: "Great Bend Municipal Apt",
    GBC: "Gasuke Apt",
    GBB: "Gara Djebilet Apt",
    GBA: "Big Bay Apt",
    GAZ: "Guasopa Apt",
    GAY: "Gaya Apt",
    GAX: "Gamba Apt",
    GAW: "Gangaw Apt",
    GAV: "Gag Island Apt",
    GAU: "Borjhar Apt",
    GAT: "Gap Apt",
    GAS: "Garissa Apt",
    GAR: "Garaina Apt",
    GAQ: "Gao Apt",
    GAP: "Gusap Apt",
    GAO: "Los Canos Apt",
    GAN: "Gan Island Apt",
    GAM: "Gambell Apt",
    GAL: "Galena Apt",
    GAK: "Gakona Apt",
    GAJ: "Junmachi Apt",
    GAI: "Montgomery County Airpark",
    GAH: "Gayndah Apt",
    GAG: "Gage Apt",
    GAF: "Gafsa Apt",
    GAE: "Gabes Apt",
    GAD: "Gadsden Municipal Apt",
    GAC: "Gracias Apt",
    GAB: "Gabbs Apt",
    GAA: "Guamal Apt",
    FZO: "Filton Apt",
    FYV: "Drake Field",
    FYU: "Fort Yukon Municipal Apt",
    FYT: "Faya Apt",
    FYN: "Fuyun Apt",
    FYM: "Fayetteville Apt",
    FXY: "Forest City Municipal Apt",
    FXO: "Cuamba Apt",
    FXM: "Flaxman Island Apt",
    FXE: "Ft Lauderdale Excutive",
    FWM: "Fort William Apt",
    FWL: "Farewell Apt",
    FWH: "Carswell Airforce Base",
    FWA: "Baer Field",
    FVS: "St Petersburg Rail Station",
    FVL: "Flora Valley Apt",
    FUT: "Futuna Apt",
    FUO: "Fuoshan Apt",
    FUN: "Funafuti Atol Apt",
    FUM: "Fuma Apt",
    FUL: "Fullerton Municipal Apt",
    FUK: "Fukuoka Apt",
    FUJ: "Fukue Apt",
    FUG: "Fuyang Apt",
    FUE: "Fuerteventura Apt",
    FUB: "Fulleborn Apt",
    FTY: "Fulton Cty Arpt",
    FTX: "Owando Apt",
    FTW: "Meacham Field",
    FTV: "Fort Victoria Apt",
    FTU: "Fort Dauphin Apt",
    FTL: "Fortuna Ledge Apt",
    FTI: "Fitiuta Apt",
    FTE: "El Calafate Apt",
    FTA: "Futuna Island Apt",
    FSZ: "Shizuoka Apt",
    FSU: "Fort Sumner Municipal Apt",
    FST: "Fort Stockton Apt",
    FSS: "Forres Apt",
    FSP: "Saint Pierre Apt",
    FSN: "Fort Sheridan Apt",
    FSM: "Fort Smith Municipal Apt",
    FSL: "Fossil Downs Apt",
    FSK: "Fort Scott Municipal Apt",
    FSI: "Fort Sill Apt",
    FSD: "Joe Foss Field",
    FSC: "Sud Corse Apt",
    FRZ: "Fritzlar Airbase",
    FRY: "Eastern Slopes Regional Apt",
    FRW: "Francistown Apt",
    FRU: "Bishkek Apt",
    FRT: "Frutillar Apt",
    FRS: "Santa Elena Apt",
    FRR: "Front Royal Apt",
    FRQ: "Feramin Apt",
    FRP: "Fresh Water Bay Apt",
    FRO: "Florelandet Apt",
    FRN: "Fort Richardson Apt",
    FRM: "Fairmont Apt",
    FRL: "Forli Apt",
    FRK: "Fregate Island Apt",
    FRJ: "Frejus Apt",
    FRH: "French Lick Municipal Apt",
    FRG: "Republic Apt",
    FRE: "Fera Island Apt",
    FRD: "Friday Harbor Apt",
    FRC: "Franca Apt",
    FRB: "Forbes Apt",
    FRA: "Frankfurt Intl Apt",
    FPY: "Foley Apt",
    FPR: "Saint Lucie County Intl Apt",
    FPO: "Grand Baham Intl Apt",
    FOY: "Foya Apt",
    FOX: "Fox Apt",
    FOU: "Fougamou Apt",
    FOT: "Forster Apt",
    FOS: "Forrest Apt",
    FOR: "Pintos Martins Apt",
    FOP: "Forest Park Apt",
    FOO: "Numfoor Apt",
    FON: "Fortuna Apt",
    FOM: "Foumban Apt",
    FOK: "Westhampton Apt",
    FOG: "Foggia Apt",
    FOE: "Forbes Field",
    FOD: "Fort Dodge Regional Apt",
    FOC: "Fuzhou Apt",
    FOB: "Fort Bragg Apt",
    FOA: "Foula Apt",
    FNT: "Bishop Intl Apt",
    FNR: "Funter Bay Apt",
    FNL: "Fort Collins/Loveland Municipal Apt",
    FNK: "Fin Creek Apt",
    FNJ: "Sunan Apt",
    FNI: "Garons Apt",
    FNH: "Fincha Apt",
    FNG: "Fada N'Gourma Apt",
    FNE: "Fane Apt",
    FNC: "Funchal Apt",
    FNB: "Neubrandenburg Apt",
    FNA: "Lungi Apt",
    FMY: "Page Field",
    FMS: "Fort Madison Municipal Apt",
    FMO: "Munster Apt",
    FMN: "Four Corners Regional Apt",
    FMI: "Kalemie Apt",
    FMH: "Otis AFB",
    FMG: "Flamingo Apt",
    FMC: "Five Mile Apt",
    FMA: "Formosa Municipal Apt",
    FLZ: "Ferdinand Arpt",
    FLY: "Finley Apt",
    FLX: "Fallon Municipal Apt",
    FLW: "Aeredomo das Flores",
    FLV: "Fort Leavenworth Apt",
    FLU: "Flushing Airport",
    FLT: "Flat Apt",
    FLS: "Flinders Island Apt",
    FLR: "Peretola Apt",
    FLP: "Flippin Apt",
    FLO: "Florence City-County Apt",
    FLN: "Hercillio Luz Apt",
    FLM: "Filadelfia Apt",
    FLL: "Fort Lauderdale Intl Apt",
    FLJ: "Falls Bay Apt",
    FLI: "Flateyri Apt",
    FLH: "Flotta Apt",
    FLG: "Pulliam Apt",
    FLF: "Flensburg Apt",
    FLE: "Fort Lee AAF",
    FLD: "Fond Du Lac Apt",
    FLC: "Falls Creek Apt",
    FLB: "Floriano Apt",
    FLA: "Florencia Apt",
    FKS: "Fukushima Apt",
    FKQ: "Fak Fak Apt",
    FKN: "Franklin Apt",
    FKL: "Venango Regional Apt",
    FKJ: "Fukui Apt",
    FKI: "Kisangani Apt",
    FKB: "Karlsruhe/Baden Baden Apt",
    FJR: "Al-Fujairah Apt",
    FIZ: "Fitzroy Crossing Apt",
    FIV: "Five Finger Island Apt",
    FIT: "Fitchburg",
    FIS: "Kinshasa Central Rail Stn",
    FIN: "Finschhafen Apt",
    FIL: "Fillmore Apt",
    FIK: "Finke Apt",
    FIH: "Kinshasa N'Djili Apt",
    FIG: "Fria Apt",
    FIE: "Fair Isle Apt",
    FID: "Fishers Island Apt",
    FIC: "Fire Cove Apt",
    FHZ: "Fakahina Apt",
    FHU: "Sierra Vista Apt",
    FGU: "Fangatau Apt",
    FGR: "Fuengirola Rail Station",
    FGL: "Fox Glacier Apt",
    FGI: "Fagali'i Apt",
    FGD: "F'Derik Apt",
    FFU: "Futaleufu Apt",
    FFT: "Capital City Apt",
    FFO: "Patterson AFB",
    FFM: "Fergus Falls Apt",
    FFL: "Fairfield Municipal Apt",
    FFA: "Kill Devil Apt",
    FEZ: "Fez Apt",
    FEW: "Warren AFB Heliport",
    FET: "Fremont Apt",
    FES: "San Fernando Rail Station",
    FER: "Fergusons Gulf Apt",
    FEP: "Albertus Apt",
    FEN: "Fernando De Noronha Apt",
    FEK: "Ferkessedougou Apt",
    FEJ: "Feijo Apt",
    FEG: "Fergana Apt",
    FEC: "Feira De Santana Apt",
    FEB: "Sanfebagar Apt",
    FEA: "Fetlar Apt",
    FDY: "Findlay Apt",
    FDU: "Bandundu Apt",
    FDR: "Frederick Municipal Apt",
    FDP: "Fariopur",
    FDK: "Frederick Municipal Apt",
    FDH: "Friedrichshafen Apt",
    FDF: "Lamentin Apt",
    FDE: "Bringeland Apt",
    FDA: "Fundacion",
    FCY: "Forrest City Apt",
    FCT: "Firing Center AAF",
    FCS: "Butts AAF",
    FCO: "Leonardo da Vinci (Fiumicino) Apt",
    FCN: "Cuxhaven Nordholz Arpt",
    FCM: "Flying Cloud Arpt",
    FCH: "Fresno Chandler Arpt",
    FCB: "Ficksburg Apt",
    FCA: "Glacier Park Intl Apt",
    FBY: "Fairbury Apt",
    FBR: "Fort Bridger Apt",
    FBM: "Lubumbashi Apt",
    FBL: "Faribault Apt",
    FBK: "Fairbanks Ladd AAF Arpt",
    FBG: "Fort Bragg Apt",
    FBE: "Francisco Beltrao Apt",
    FBD: "Faizabad Apt",
    FAY: "Fayetteville Municipal Apt",
    FAV: "Fakarava Apt",
    FAT: "Fresno Air Terminal",
    FAS: "Faskrudsfjordur Apt",
    FAR: "Hector Intl Apt",
    FAQ: "Freida River Apt",
    FAO: "Faro Apt",
    FAN: "Farsund Apt",
    FAM: "Farmington Apt",
    FAL: "Falcon State Park Apt",
    FAK: "False Island Municipal Apt",
    FAJ: "Diego Jimenez Torres Apt",
    FAI: "Fairbanks Intl Apt",
    FAH: "Farah Apt",
    FAG: "Fagurholsmyri Apt",
    FAF: "Fort Eustis Apt",
    FAE: "Faroe Islands Apt",
    FAC: "Faaite Island Apt",
    FAB: "Farnborough Apt",
    FAA: "Faranah Apt",
    EZV: "Berezovo Airport",
    EZS: "Elazig Apt",
    EZF: "Shannon Airport",
    EZE: "Ministro Pistarini Apt",
    EYW: "Key West Intl Apt",
    EYS: "Eliye Springs Apt",
    EYR: "Yerington Municipal Apt",
    EYP: "El Yopal Apt",
    EYL: "Yelimane Apt",
    EYK: "Beloyarsky Arpt",
    EXT: "Exeter Apt",
    EXS: "Exeter St Thomas Rail Station",
    EXM: "Exmouth Gulf Apt",
    EXI: "Excursion Inlet Municipal Apt",
    EWV: "Enshi Railway Station",
    EWR: "Newark Intl Apt",
    EWO: "Ewo Apt",
    EWN: "Simmons-Nott Apt",
    EWK: "City-County Apt",
    EWI: "Enarotali Apt",
    EWE: "Ewer Apt",
    EWD: "Wildman Lake Arpt",
    EWB: "New Bedford Regional Apt",
    EVX: "Evreux Apt",
    EVW: "Evanston Apt",
    EVV: "Evansville Regional Apt",
    EVN: "Yerevan Apt",
    EVM: "Eveleth Apt",
    EVH: "Evans Head Apt",
    EVG: "Sveg Apt",
    EVE: "Evenes Apt",
    EVD: "Eva Downs Apt",
    EVA: "Evadale Landing Strip",
    EUX: "Roosevelt Field",
    EUO: "Paratebueno Apt",
    EUN: "Laayoune Apt",
    EUM: "Neumunster Apt",
    EUG: "Mahlon Sweet Field",
    EUF: "Eufaula Apt",
    EUE: "Eureka Apt",
    EUC: "Eucla Apt",
    EUA: "Kaufana Apt",
    ETZ: "Lorraine Apt",
    ETS: "Enterprise Apt",
    ETR: "Santa Rosa Intl",
    ETN: "Eastland Municipal Apt",
    ETH: "Elat Apt",
    ETE: "Genda Wuha Apt",
    ETD: "Etadunna Apt",
    ETB: "West Bend Apt",
    ESZ: "Essen Hbf Rail Station",
    ESW: "Easton State Apt",
    ESU: "Essaouira Apt",
    EST: "Estherville Municipal Apt",
    ESS: "Essen Apt",
    ESR: "El Salvador Municipal Apt",
    ESP: "East Stroudsburg Apt",
    ESO: "San Juan Pueblo Apt",
    ESN: "Easton Apt",
    ESM: "Esmeraldas Apt",
    ESL: "Elista Apt",
    ESK: "Eskisehir",
    ESI: "Espinosa Apt",
    ESH: "Shoreham by the Sea Apt",
    ESG: "Mariscal Estigarribia Apt",
    ESF: "Esler Field",
    ESE: "Ensenada Apt",
    ESD: "Orcas Island Apt",
    ESC: "Delta County Apt",
    ESB: "Esenboga Apt",
    ESA: "Esa'Ala Apt",
    ERZ: "Erzurum Apt",
    ERV: "Kerrville Apt",
    ERU: "Erume Apt",
    ERT: "Erdenet Arpt",
    ERS: "Eros Apt",
    ERR: "Errol Apt",
    ERO: "Eldred Rock Apt",
    ERN: "Eirunepe Apt",
    ERM: "Erechim Apt",
    ERI: "Erie Intl Apt",
    ERH: "Errachidia Apt",
    ERG: "Eromanga",
    ERF: "Erfurt Apt",
    ERE: "Erave Apt",
    ERD: "Berdyansk Apt",
    ERC: "Erzincan Apt",
    ERB: "Ernabella Apt",
    ERA: "Erigavo Apt",
    EQS: "Esquel Municipal Apt",
    EPU: "Parnu Arpt",
    EPT: "Eliptamin Apt",
    EPS: "El Portillo/Samana Apt",
    EPR: "Esperance Apt",
    EPN: "Epena Apt",
    EPL: "Mirecourt Apt",
    EPK: "Episkopi Apt",
    EPI: "Epk Apt",
    EPH: "Ephrata Municipal Apt",
    EPG: "Weeping Water Apt",
    EOZ: "Elorza Apt",
    EOS: "Neosho Apt",
    EOR: "El Dorado Apt",
    EOK: "Keokuk Municipal Apt",
    EOI: "Eday Apt",
    EOH: "Enrique Olaya Herrerra Apt",
    ENY: "Yan'An Apt",
    ENW: "Kenosha Municipal Apt",
    ENV: "Wendover Apt",
    ENU: "Enugu Apt",
    ENT: "Enewetak Island Apt",
    ENS: "Twente Apt",
    ENO: "Encarnacion Apt",
    ENN: "Nenana Apt",
    ENL: "Centralia Municipal Apt",
    ENK: "Saint Angelo Apt",
    ENJ: "El Naranjo Apt",
    ENH: "Enshi Apt",
    ENF: "Enontekio Apt",
    ENE: "Ende Apt",
    END: "Vance Airforce Base",
    ENC: "Nancy",
    ENB: "Eneabba Apt",
    ENA: "Kenai Municipal Apt",
    EMY: "El Minya Apt",
    EMX: "El Maiten Apt",
    EMT: "El Monte Apt",
    EMS: "Embessa Apt",
    EMP: "Emporia Municipal Apt",
    EMO: "Emo Apt",
    EMN: "Nema Apt",
    EMM: "Kemmerer Apt",
    EML: "Emmen Apt",
    EMK: "Emmonak Municipal Apt",
    EMI: "Emirau Apt",
    EMG: "Empangeni Apt",
    EME: "Emden Apt",
    EMD: "Emerald Apt",
    EMB: "Embarcadero",
    EMA: "East Midlands Apt",
    ELZ: "Wellsville Municipal",
    ELY: "Ely Apt",
    ELX: "El Tigre Apt",
    ELW: "Ellamar Apt",
    ELU: "El Oued Apt",
    ELT: "Tour Sinai City Apt",
    ELS: "East London Apt",
    ELR: "Elelim Apt",
    ELQ: "Gassim Apt",
    ELP: "El Paso Intl Apt",
    ELO: "Eldorado Apt",
    ELN: "Bowers Field",
    ELM: "Elmira/Corning Regional Apt",
    ELL: "Ellisras Apt",
    ELK: "Elk City Municipal Apt",
    ELJ: "El Recreo Apt",
    ELI: "Elim Airstrip",
    ELH: "North Eleuthera Apt",
    ELG: "El Golea Apt",
    ELF: "El Fasher Apt",
    ELE: "El Real Apt",
    ELD: "Goodwin Field",
    ELC: "Elcho Island Apt",
    ELB: "El Banco Apt",
    ELA: "Eagle Lake Apt",
    EKX: "Addington Field",
    EKT: "Eskilstuna Apt",
    EKS: "Shakhtersk Airport",
    EKO: "J. C. Harris Field",
    EKN: "Elkins Apt",
    EKI: "Elkhart Municipal Apt",
    EKE: "Ekereku Apt",
    EKD: "Elkedra Apt",
    EKB: "Ekibastuz Apt",
    EKA: "Murray Field",
    EJQ: "Shiyan Railway Station",
    EJJ: "Jingzhou Railway Station",
    EJH: "Wedjh Apt",
    EJA: "Variguies Apt",
    EIZ: "Eisenach Hbf rail Station",
    EIY: "Ein Yahav Apt",
    EIS: "Beef Island Apt",
    EIN: "Eindhoven Apt",
    EIL: "Eielson AFB Arpt",
    EIH: "Einasleigh Apt",
    EIE: "Eniseysk Apt",
    EIB: "Eisenach Apt",
    EIA: "Eia Apt",
    EHT: "Rentschler Apt",
    EHM: "Cape Newenham Municipal Apt",
    EHL: "El Bolson Apt",
    EGX: "Egegik Municipal Apt",
    EGV: "Union Apt",
    EGS: "Egilsstadir Apt",
    EGP: "Eagle Pass Municipal Apt",
    EGO: "Belgorod Apt",
    EGN: "Geneina Apt",
    EGM: "Sege Apt",
    EGL: "Neghelli Apt",
    EGI: "Duke Field",
    EGE: "Eagle County Apt",
    EGC: "Roumaniere Apt",
    EGA: "Engati Apt",
    EFW: "Jefferson Municipal Apt",
    EFO: "East Forks Apt",
    EFL: "Kefallinia Apt",
    EFK: "Newport State Apt",
    EFG: "Efogi Apt",
    EFD: "Ellington Field",
    EFB: "Eight Fathom Bight Apt",
    EEU: "Leon Rail Station",
    EER: "Reus Rail Station",
    EEP: "Pamplona Rail Station",
    EEN: "Dillant-Hopkins Apt",
    EEM: "Albacete Rail Station",
    EEL: "Tudela Rail Station",
    EEK: "Eek Municipal Apt",
    EED: "Needles Apt",
    EDR: "Edward River Apt",
    EDQ: "Erandique Apt",
    EDO: "Edremit/Korfez Apt",
    EDM: "La Roche Sur Yon Apt",
    EDL: "Eldoret Intl Apt",
    EDK: "El Dorado Apt",
    EDI: "Turnhouse Apt",
    EDF: "Elmendorf Airforce Base",
    EDE: "Edenton Apt",
    EDD: "Erldunda Apt",
    EDB: "Eldebba Apt",
    EDA: "Edna Bay Municipal Apt",
    ECS: "Newcastle Apt",
    ECR: "El Charco Apt",
    ECP: "Northwest Florida Beaches Intl",
    ECO: "El Encanto Apt",
    ECN: "Ercan Apt",
    ECH: "Echuca Apt",
    ECG: "Elizabeth City Apt",
    ECA: "East Tawas Apt",
    EBW: "Ebolowa Apt",
    EBU: "Boutheon Apt",
    EBS: "Webster City Municipal Apt",
    EBR: "Baton Rouge Downtown Arpt",
    EBO: "Ebon Apt",
    EBN: "Ebadon Apt",
    EBM: "El-Borma Apt",
    EBL: "Erbil Arpt",
    EBJ: "Esbjerg Apt",
    EBG: "El Bagre Municipal Apt",
    EBD: "El Obeid Apt",
    EBB: "Kampala Apt",
    EBA: "Marina Di Campo Apt",
    EAU: "Eau Claire County Apt",
    EAT: "Pangborn Field",
    EAS: "San Sebastian Apt",
    EAR: "Kearney Municipal Apt",
    EAP: "Basel/Mulhouse",
    EAN: "Wheatland Apt",
    EAM: "Nejran Apt",
    EAH: "El Arish",
    EAE: "Emae Apt",
    EAB: "Abbse Apt",
    EAA: "Eagle Apt",
    DZU: "Dazu Apt",
    DZO: "Durazno Apt",
    DZN: "Zhezkazgan Apt",
    DZI: "Codazzi Apt",
    DZA: "Dzaoudzi Apt",
    DYW: "Daly Waters Apt",
    DYU: "Dushanbe Apt",
    DYS: "Dyess Airforce Base",
    DYR: "Anadyr Apt",
    DYM: "Diamantina Lakes Apt",
    DYL: "Doylestown Apt",
    DYG: "Dayong Apt",
    DYA: "Dysart Apt",
    DXR: "Danbury Municipal Apt",
    DXE: "Bruce Campbell Field Arpt",
    DXD: "Dixie Apt",
    DXB: "Dubai Apt",
    DXA: "Deux Alpes Apt",
    DWS: "Walt Disney World Arpt",
    DWN: "Downtown Airpark",
    DWH: "David Wayne Hooks Arpt",
    DWF: "Wright AFB",
    DWC: "Al Maktoum Intl Arpt",
    DWB: "Soalala Apt",
    DWA: "Dwangwa Apt",
    DVX: "Delaware Airpark",
    DVT: "Phoenix Deer Valley Airport",
    DVR: "Daly River Apt",
    DVP: "Davenport Downs Apt",
    DVO: "Davao International Apt",
    DVN: "Davenport Municipal Apt",
    DVL: "Devils Lake Municipal Apt",
    DVA: "Deva Apt",
    DUT: "Emergency Field",
    DUS: "Dusseldorf Apt",
    DUR: "Durban Intl Apt",
    DUQ: "Duncan/Quam Rail Station",
    DUN: "Dundas Apt",
    DUM: "Dumai Apt",
    DUJ: "Jefferson County Apt",
    DUI: "Duisburg Hbf Rail Station",
    DUG: "Bisbee-Douglas Intl Apt",
    DUF: "Duck Apt",
    DUE: "Dundo Apt",
    DUD: "Dunedin Apt",
    DUC: "Halliburton Field",
    DUB: "Dublin Apt",
    DUA: "Eaker Field",
    DTZ: "Dortmund Hbf Rail Station",
    DTW: "Detroit Wayne County Apt",
    DTT: "Detroit Metropolitan Area",
    DTR: "Decatur Shores Apt",
    DTN: "Shreveport Downtown Arpt",
    DTM: "Wickede Apt",
    DTL: "Detroit Lakes Apt",
    DTH: "Death Valley Apt",
    DTE: "Daet Apt",
    DTD: "Datadawai Apt",
    DTA: "Delta Apt",
    DSV: "Dansville Municipal Apt",
    DSN: "Ordos Dongsheng Apt",
    DSM: "Des Moines Intl Apt",
    DSL: "Daru Apt",
    DSK: "Dera Ismail Khan Apt",
    DSI: "Destin Apt",
    DSE: "Dessie Apt",
    DSD: "La Desirade Apt",
    DSC: "Dschang Apt",
    DSA: "Finningley Arpt",
    DRY: "Drysdale Apt",
    DRW: "Darwin Apt",
    DRU: "Drummond Apt",
    DRT: "Del Rio Intl Apt",
    DRS: "Dresden Apt",
    DRR: "Durrie Apt",
    DRO: "La Plata Apt",
    DRN: "Dirranbandi Apt",
    DRM: "Drama Apt",
    DRK: "Drake Bay Airport",
    DRJ: "Drietabbetje Apt",
    DRI: "De Ridder Apt",
    DRH: "Dabra Apt",
    DRG: "Deering Municipal Apt",
    DRF: "Drift River Apt",
    DRE: "Drummond Island Apt",
    DRD: "Dorunda Station Apt",
    DRC: "Dirico Apt",
    DRB: "Derby Apt",
    DRA: "Desert Rock Apt",
    DQA: "Saertu Airport",
    DPU: "Dumpu Apt",
    DPS: "Ngurah Rai Apt",
    DPO: "Devonport Apt",
    DPL: "Dipolog Apt",
    DPK: "Deer Park Apt",
    DPG: "Dugway Apt",
    DPE: "Dieppe Apt",
    DPA: "Du Page County Apt",
    DOY: "Deboyne",
    DOX: "Dongara Apt",
    DOV: "Dover AFB",
    DOU: "Dourados Apt",
    DOS: "Dios Apt",
    DOR: "Dori Apt",
    DOP: "Dolpa Apt",
    DOO: "Dorobisoro Apt",
    DON: "Dos Lagunas Apt",
    DOM: "Melville Hall Apt",
    DOL: "Saint Gatien Apt",
    DOK: "Donetsk Apt",
    DOI: "Doini Apt",
    DOH: "Doha Intl Apt",
    DOG: "Dongola Apt",
    DOF: "Dora Bay Municipal Apt",
    DOE: "Djoemoe Apt",
    DOD: "Dodoma Apt",
    DOC: "Dornoch Apt",
    DOB: "Dobo Apt",
    DOA: "Doany Apt",
    DNZ: "Cardak Apt",
    DNX: "Dinder Apt",
    DNV: "Vermilion County Apt",
    DNU: "Dinangat Apt",
    DNS: "Denison Municipal Apt",
    DNR: "Pleu Apt",
    DNQ: "Deniliquin Apt",
    DNP: "Dang Apt",
    DNO: "Dianopolis Apt",
    DNN: "Dalton Municipal Apt",
    DNM: "Denham Apt",
    DNL: "Daniel Field",
    DNK: "Dnepropetrovsk Apt",
    DNJ: "Dinajpur",
    DNI: "Wad Medani Apt",
    DNH: "Dunhuang Apt",
    DNG: "Doonang Apt",
    DNF: "Derna Apt",
    DNE: "Dallas North Airport",
    DND: "Dundee Apt",
    DNC: "Danane Apt",
    DNB: "Dunbar Apt",
    DNA: "Kadena AFB",
    DMU: "Dimapur Apt",
    DMT: "Diamantino Apt",
    DMS: "Sabtco Bus Station",
    DMR: "Dhamar Apt",
    DMO: "Sedalia Apt",
    DMN: "Deming Municipal Apt",
    DMM: "King Fahad Intl Apt",
    DMK: "Don Mueang International Arpt",
    DME: "Domodedovo Apt",
    DMD: "Doomadgee Mission Apt",
    DMB: "Zhambyl Apt",
    DMA: "Davis Monthan AFB",
    DLZ: "Dalanzadgad Apt",
    DLY: "Dillons Bay Apt",
    DLV: "Delissaville Apt",
    DLU: "Dali Apt",
    DLS: "The Dalles Municipal Apt",
    DLP: "Disneyland Paris Heliport",
    DLO: "Dolomi Municipal Apt",
    DLN: "Dillon Apt",
    DLM: "Dalaman Apt",
    DLL: "Dillon Apt",
    DLK: "Dulkaninna Apt",
    DLI: "Lienkhang Apt",
    DLH: "Duluth Intl Apt",
    DLG: "Dillingham Apt",
    DLF: "Laughlin AFB",
    DLE: "Dole Apt",
    DLD: "Dagali Apt",
    DLC: "Dalian Apt",
    DLB: "D'Albertis Apt",
    DLA: "Douala Apt",
    DKV: "Docker River Apt",
    DKR: "Dakar Apt",
    DKK: "Dunkirk Apt",
    DKI: "Dunk Island Apt",
    DKA: "Katsina Airport",
    DJV: "Dabajoro",
    DJU: "Djupivogur Apt",
    DJR: "Dajarra",
    DJO: "Daloa Apt",
    DJN: "Delta Junction Apt",
    DJM: "Djambala Apt",
    DJJ: "Sentani Apt",
    DJG: "Djanet Apt",
    DJE: "Djerba Apt",
    DJB: "Sultan Taha Syarifundin Apt",
    DJA: "Djougou Apt",
    DIY: "Kaplaner Apt",
    DIW: "Dickwella Mawella Lagoon SPB",
    DIV: "Divo Apt",
    DIU: "Diu Apt",
    DIS: "Loubomo Apt",
    DIR: "Dire Dawa Apt",
    DIQ: "Divinopolis Apt",
    DIP: "Diapaga Apt",
    DIO: "Diomede Island Apt",
    DIN: "Dien Bien Phu Apt",
    DIM: "Dimbokro Apt",
    DIL: "Comoro Apt",
    DIK: "Dickinson Municipal Apt",
    DIJ: "Dijon/Longvic Apt",
    DIG: "Diging Apt",
    DIE: "Antsiranana Apt",
    DIC: "Dili Apt",
    DIB: "Chabua Apt",
    DHU: "Zhuzhou Rail Station",
    DHT: "Dalhart Municipal Apt",
    DHR: "Den Helder Apt",
    DHN: "Dothan Apt",
    DHL: "Dhala Apt",
    DHI: "Dhangarhi Apt",
    DHF: "Al Dhafra Military Arpt",
    DHD: "Durham Downs Apt",
    DHB: "Deer Harbour Sea Plane Base",
    DHA: "Dhahran Apt",
    DGW: "Douglas Apt",
    DGU: "Dedougou Apt",
    DGT: "Dumaguete Apt",
    DGR: "Dargaville Apt",
    DGP: "Daugavpils Apt",
    DGO: "Guadeloupe Victoria Apt",
    DGN: "Dahlgren Apt",
    DGM: "Dongguan Apt",
    DGL: "Douglas Municipal Arpt",
    DGF: "Douglas Lake Apt",
    DGE: "Mudgee Apt",
    DGD: "Dalgaranga Apt",
    DGC: "Degahbur Apt",
    DGB: "Danger Bay Apt",
    DGA: "Dangriga Apt",
    DFW: "Dallas/Fort Worth Intl Apt",
    DFP: "Drumduff Apt",
    DFI: "Defiance Memorial Apt",
    DEZ: "Deirezzor Apt",
    DET: "Detroit City Apt",
    DES: "Desroches Apt",
    DER: "Derim Apt",
    DEN: "Denver Intl Apt",
    DEM: "Dembidollo Apt",
    DEL: "Indira Gandhi Intl Apt",
    DEI: "Denis Island Apt",
    DEH: "Decorah Municipal Apt",
    DEE: "Mendeleyevo Airport",
    DED: "Dehra Dun Apt",
    DEC: "Decatur Apt",
    DEB: "Debrecen Apt",
    DEA: "Dera Ghazi Khan Apt",
    DDV: "Changsha Rail Station",
    DDU: "Dadu Apt",
    DDP: "Dorado Apt",
    DDO: "Dorobisoro",
    DDN: "Delta Downs Apt",
    DDM: "Dodoima Apt",
    DDI: "Daydream Island Apt",
    DDG: "Dandong Apt",
    DDD: "Dabba",
    DDC: "Dodge City Regional Apt",
    DCY: "Yading Arpt",
    DCU: "Decatur Apt",
    DCT: "Duncan Town Apt",
    DCS: "Doncaster Apt",
    DCR: "Decatur Hi-Way Apt",
    DCN: "Curtain Arpt",
    DCM: "Mazamet Apt",
    DCK: "Dahl Creek Apt",
    DCI: "Decimomannu Apt",
    DCG: "Dubai Creek SPB",
    DCF: "Cane Field Apt",
    DCA: "Washington National Apt",
    DBY: "Dalby Apt",
    DBV: "Dubrovnik Apt",
    DBT: "Debre Tabor Apt",
    DBS: "Dubois Municipal Apt",
    DBQ: "Dubuque Regional Apt",
    DBP: "Debepare Apt",
    DBO: "Dubbo Apt",
    DBN: "Dublin Apt",
    DBM: "Debra Marcos Apt",
    DBL: "Dalianbei Railway Station",
    DBD: "Dhanbad Apt",
    DBA: "Dalbandin Apt",
    DAZ: "Darwaz Apt",
    DAY: "Dayton Intl Apt",
    DAX: "Daxian Apt",
    DAV: "Enrique Malek Apt",
    DAU: "Daru Apt",
    DAT: "Datong Airport",
    DAS: "Great Bear Lake Apt",
    DAR: "Dar Es Salaam Apt",
    DAP: "Darchula Apt",
    DAO: "Dabo Apt",
    DAN: "Danville Apt",
    DAM: "Damascus Apt",
    DAL: "Love Field",
    DAK: "Dakhla Oasis Apt",
    DAJ: "Dauan Island Apt",
    DAI: "Darjeeling Apt",
    DAH: "Dathina Apt",
    DAG: "Daggett Apt",
    DAF: "Daup Apt",
    DAE: "Daparizo Apt",
    DAD: "Da Nang Apt",
    DAC: "Zia Intl Apt",
    DAB: "Daytona Beach Regional Apt",
    DAA: "Fort Belvoir Apt",
    CZZ: "Campo Apt",
    CZY: "Cluny Apt",
    CZX: "Changzhou Apt",
    CZW: "Czestochowa Apt",
    CZU: "Corozal Apt",
    CZT: "Carrizo Springs Apt",
    CZS: "Cruzeiro Do Sul Apt",
    CZP: "Cape Pole Apt",
    CZO: "Chistochina Apt",
    CZN: "Chisana Apt",
    CZM: "Cozumel Municipal Apt",
    CZL: "Constantine Apt",
    CZK: "Cascade Locks State Apt",
    CZJ: "Corazon De Jesus Apt",
    CZH: "Corozal Municipal Apt",
    CZF: "Cape Romanzof Apt",
    CZE: "Coro Apt",
    CZC: "Copper Center Apt",
    CZB: "Cruz Alta Apt",
    CZA: "Chichen Itza Municipal Apt",
    CYZ: "Cauayan Apt",
    CYU: "Cuyo Apt",
    CYT: "Yakataga Apt",
    CYS: "Cheyenne Municipal Apt",
    CYR: "Colonia Apt",
    CYP: "Calbayog Apt",
    CYO: "Cayo Largo Del Sur Apt",
    CYM: "Chatham Apt",
    CYL: "Coyoles Apt",
    CYI: "Chiayi Apt",
    CYG: "Corryong Apt",
    CYF: "Chefornak Apt",
    CYE: "Crystal Lake Apt",
    CYD: "Town Airstrip",
    CYC: "Caye Chapel Apt",
    CYB: "Gerrard Smith Apt",
    CYA: "Les Cayes Apt",
    CXY: "Cat Cay Apt",
    CXT: "Charters Towers Apt",
    CXR: "Cam Ranh Arpt",
    CXQ: "Christmas Creek Apt",
    CXP: "Cilacap Apt",
    CXO: "Conroe Apt",
    CXN: "Candala Apt",
    CXM: "Camaxilo",
    CXL: "Calexico Intl Apt",
    CXJ: "Campos dos Bugres Apt",
    CXI: "Christmas Island Apt",
    CXH: "Coal Harbor Sea Plane Arpt",
    CXF: "Coldfoot Apt",
    CXC: "Chitina Apt",
    CXB: "Cox's Bazar Apt",
    CXA: "Caicara Apt",
    CWX: "Cochise County Arpt",
    CWW: "Corowa Apt",
    CWT: "Cowra Apt",
    CWS: "Center Island Municipal Apt",
    CWR: "Cowarie Apt",
    CWP: "Campbellpore Apt",
    CWO: "Ft Wolter AAF",
    CWL: "Cardiff Apt",
    CWI: "Clinton Municipal Apt",
    CWG: "Callaway Gardens Apt",
    CWC: "Chernovtsy Apt",
    CWB: "Afonso Pena Apt",
    CWA: "Central Wisconsin Regional Apt",
    CVU: "Corvo Island Apt",
    CVT: "Coventry Apt",
    CVS: "Cannon Afbarpt",
    CVR: "Culver City Apt",
    CVQ: "Carnarvon Apt",
    CVO: "Corvallis Municipal Apt",
    CVN: "Clovis Municipal Apt",
    CVM: "Ciudad Victoria Apt",
    CVL: "Cape Vogel Apt",
    CVJ: "Cuernavaca Apt",
    CVI: "Caleta Olivia Apt",
    CVH: "Caviahue Apt",
    CVG: "Cincinnati/Northern Kentucky Intl Apt",
    CVF: "Courchevel Apt",
    CVE: "Covenas Apt",
    CVC: "Cleve Apt",
    CVB: "Chungribu Apt",
    CVA: "Civic Arena Heliport",
    CUZ: "Velasco Astete Apt",
    CUY: "Cue Apt",
    CUX: "Cuddihy Field Arpt",
    CUW: "Cube Cove Apt",
    CUV: "Casigua Apt",
    CUU: "Fierro Villalobos Apt",
    CUT: "Cutral Municipal Apt",
    CUS: "Columbus Municipal Apt",
    CUR: "Hato Apt",
    CUQ: "Coen Apt",
    CUP: "Carupano Apt",
    CUO: "Caruru Apt",
    CUN: "Cancun Intl Apt",
    CUM: "Cumana Apt",
    CUL: "Federale De Bachigualato Apt",
    CUK: "Caye Caulker Apt",
    CUJ: "Culion Apt",
    CUI: "Currillo Apt",
    CUH: "Cushing Municipal Apt",
    CUG: "Cudal Apt",
    CUF: "Levaldigi Apt",
    CUE: "Mariscal Lamar Apt",
    CUD: "Caloundra Apt",
    CUC: "Camilo Daza Apt",
    CUB: "Columbia Owens Downtown Arpt",
    CUA: "Ciudad Constitucion Apt",
    CTZ: "Clinton Apt",
    CTY: "Cross City Apt",
    CTX: "Cortland Apt",
    CTW: "Cottonwood Apt",
    CTV: "Catavina",
    CTU: "Chengdu Apt",
    CTT: "Le Castellet Apt",
    CTS: "New Chitose Apt",
    CTR: "Cattle Creek Apt",
    CTQ: "Santa Vitoria Apt",
    CTP: "Carutapera Apt",
    CTN: "Cooktown Apt",
    CTM: "Chetumal Apt",
    CTL: "Charleville Apt",
    CTK: "Canton Apt",
    CTH: "Coatsville Apt",
    CTG: "Rafael Nunez Apt",
    CTF: "Coatepeque",
    CTE: "Carti Apt",
    CTD: "Chitre Apt",
    CTC: "Choya Apt",
    CTB: "Cut Bank Apt",
    CTA: "Fontanarossa Apt",
    CSZ: "Brigadier Hector Ruiz Apt",
    CSY: "Cheboksary Apt",
    CSX: "Changsha Apt",
    CSW: "Colorado Do Oeste Apt",
    CSV: "Crossville Memorial Apt",
    CSU: "Santa Cruz Do Sul Apt",
    CST: "Castaway Apt",
    CSS: "Cassilandia Apt",
    CSR: "Casuarito Apt",
    CSQ: "Creston Municipal Apt",
    CSP: "Cape Spencer Apt",
    CSO: "Cochstedt Apt",
    CSN: "Carson City Apt",
    CSM: "Sherman Apt",
    CSL: "O Sullivan Army Air Field",
    CSK: "Cap Skirring Apt",
    CSJ: "Cape Saint Jacques Apt",
    CSI: "Casino Apt",
    CSG: "Columbus Metropolitan Apt",
    CSF: "Creil Apt",
    CSE: "Crested Butte Apt",
    CSD: "Cresswell Downs Apt",
    CSC: "Canas Apt",
    CSB: "Caransebes Apt",
    CSA: "Colonsay Island Apt",
    CRZ: "Turkmenahad Apt",
    CRY: "Carlton Hill Apt",
    CRX: "Corinth Apt",
    CRW: "Yeager Apt",
    CRV: "Crotone Apt",
    CRU: "Lauriston Apt",
    CRT: "Crossett Apt",
    CRS: "Corsicana Apt",
    CRR: "Ceres Apt",
    CRQ: "Caravelas Apt",
    CRP: "Corpus Christi Intl Apt",
    CRO: "Corcoran Apt",
    CRN: "Cromarty Apt",
    CRM: "Catarman Apt",
    CRL: "Brussels South Apt",
    CRK: "Clark Field",
    CRJ: "Coorabie Apt",
    CRI: "Crooked Island Apt",
    CRH: "Cherribah Apt",
    CRG: "Craig Municipal Arpt",
    CRF: "Carnot Apt",
    CRE: "Grand Strand Arpt",
    CRD: "Comodoro Rivadavia Apt",
    CRC: "Cartago Apt",
    CRB: "Collarenebri Apt",
    CRA: "Craiova Apt",
    CQZ: "Cherokee Sound",
    CQT: "Caquetania Apt",
    CQS: "Costa Marques Apt",
    CQP: "Cape Flattery Apt",
    CQM: "Ciudad Real Central Arpt",
    CQF: "Marck Apt",
    CQD: "Shahre-Kord Apt",
    CQA: "Canarana Apt",
    CPX: "Culebra Apt",
    CPV: "Joao Suassuna Apt",
    CPU: "Cururupu Apt",
    CPT: "Cape Town Intl Apt",
    CPS: "Bi-State Parks Apt",
    CPR: "Casper Intl Apt",
    CPQ: "Campinas Intl Apt",
    CPP: "Calapan",
    CPO: "Chamonate Apt",
    CPN: "Cape Rodney Apt",
    CPM: "Compton Apt",
    CPL: "Chaparral Apt",
    CPJ: "Castellon De La Plana Rail Station",
    CPI: "Cape Orford Apt",
    CPH: "Copenhagen Apt",
    CPG: "Carmen De Patagones Apt",
    CPF: "Cepu Apt",
    CPE: "Alberto Acuna Ongay Apt",
    CPD: "Coober Pedy Apt",
    CPC: "Chapelco Apt",
    CPB: "Capurgana Apt",
    CPA: "Cape Palmas Apt",
    COZ: "Constanza Apt",
    COY: "Coolawanyah Apt",
    COX: "Congo Town Apt",
    COW: "Coquimbo Apt",
    COV: "Covilha Apt",
    COU: "Columbia Regional Apt",
    COT: "Cotulla Apt",
    COS: "Peterson Field",
    COR: "Pajas Blancas Apt",
    COQ: "Choibalsan Apt",
    COO: "Cotonou Apt",
    CON: "Concord Municipal Apt",
    COM: "Coleman Apt",
    COL: "Coll Apt",
    COK: "Cochin Apt",
    COJ: "Coonabarabran Apt",
    COI: "Merritt Island Apt",
    COH: "Cooch Behar Apt",
    COG: "Mandinga Apt",
    COF: "Patrick AFB",
    COE: "Coeur d'Alene Air Terminal",
    COD: "Cody/Yellowstone Apt",
    COC: "Comodoro Pierres Apt",
    COB: "Coolibah Apt",
    COA: "Columbia Apt",
    CNZ: "Cangamba Apt",
    CNY: "Canyonlands Apt",
    CNX: "Chiang Mai Intl Apt",
    CNW: "James Connall Apt",
    CNV: "Canavieiras Apt",
    CNU: "Martin Johnson Apt",
    CNT: "Charata Apt",
    CNS: "Cairns Intl Apt",
    CNR: "Chanaral Apt",
    CNQ: "Candra Punta Apt",
    CNP: "Neerlerit Inaat Apt",
    CNO: "Chino Apt",
    CNN: "Chulman Apt",
    CNM: "Cavern City Air Terminal",
    CNL: "Sindal Apt",
    CNK: "Blosser Municipal Apt",
    CNJ: "Cloncurry Apt",
    CNH: "Claremont Municipal Apt",
    CNG: "Cognac Apt",
    CNF: "Tancredo Neves Intl Apt",
    CNE: "Fremont County Apt",
    CND: "Kogainiceanu Apt",
    CNC: "Coconut Island Apt",
    CNB: "Coonamble Apt",
    CNA: "Cananea Apt",
    CMY: "Sparta Apt",
    CMX: "Houghton County Memorial Apt",
    CMW: "Ignacio Agramonte Apt",
    CMV: "Coromandel Apt",
    CMU: "Chimbu Apt",
    CMT: "Cameta Apt",
    CMS: "Scusciuban Apt",
    CMR: "Colmar Apt",
    CMQ: "Clermont Apt",
    CMP: "Campo Allegre Apt",
    CMO: "Obbia Apt",
    CMN: "Mohamed V Apt",
    CMM: "Carmelita Apt",
    CML: "Camooweal Apt",
    CMK: "Club Makokola Apt",
    CMJ: "Chi Mei Apt",
    CMI: "Willard University Apt",
    CMH: "Port Columbus Intl Apt",
    CMG: "Corumba Intl Apt",
    CMF: "Chambery Apt",
    CME: "Ciudad Del Carmen Apt",
    CMD: "Cootamundra Apt",
    CMC: "Camocim Apt",
    CMB: "Bandaranaike Apt",
    CMA: "Cunnamulla Apt",
    CLZ: "Calabozo Apt",
    CLY: "Sainte Catherine Apt",
    CLX: "Clorinda Apt",
    CLW: "Saint Petersburg/Clearwater Executive Apt",
    CLV: "Caldas Novas Apt",
    CLU: "Columbus Municipal Apt",
    CLT: "Douglas Intl Apt",
    CLS: "Chehalis Apt",
    CLR: "Calipatria Municipal Apt",
    CLQ: "Colima Municipal Apt",
    CLP: "Clarks Point Apt",
    CLO: "Alfonso Bonilla Aragon Apt",
    CLN: "Carolina Apt",
    CLM: "Fairchild Intl Apt",
    CLL: "Easterwood Field",
    CLK: "Clinton Municipal Arpt",
    CLJ: "Cluj Apt",
    CLI: "Clintonville Apt",
    CLH: "Coolah Apt",
    CLG: "Coalinga Municipal Apt",
    CLE: "Hopkins Intl Apt",
    CLD: "McClellan-Palomar Apt",
    CLC: "Clear Lake City Apt",
    CLB: "Castlebar Apt",
    CLA: "Comilla Apt",
    CKZ: "Canakkale Apt",
    CKY: "Conakry Apt",
    CKX: "Chicken Municipal Apt",
    CKV: "Clarksville Apt",
    CKU: "Cordova Municipal Arpt",
    CKT: "Sarakhs Apt",
    CKS: "Carajas Municipal Apt",
    CKR: "Crane Island Apt",
    CKO: "Cornelio Procopio Apt",
    CKN: "Crookston Apt",
    CKM: "Clarksdale Apt",
    CKL: "Chkalovsky Apt",
    CKK: "Cherokee Apt",
    CKI: "Croker Island Apt",
    CKH: "Chokurdah Apt",
    CKG: "Chongqing Apt",
    CKE: "Clear Lake Apt",
    CKD: "Crooked Creek Apt",
    CKC: "Cherkassy Apt",
    CKB: "Benedum Apt",
    CKA: "Kegelman Af Apt",
    CJU: "Jeju Intl Apt",
    CJT: "Copalar Apt",
    CJS: "Abraham Gonzalez Intl Aiport",
    CJR: "Chaurajhari",
    CJN: "El Cajon Apt",
    CJM: "Chumphon Apt",
    CJL: "Chitral Apt",
    CJJ: "Cheongju Apt",
    CJI: "Crafton Island",
    CJH: "Chilko Lake Apt",
    CJE: "Chang Hua",
    CJD: "Candilejas Apt",
    CJC: "El Loa Apt",
    CJB: "Peelamedu Apt",
    CJA: "Cajamarca Municipal Apt",
    CIZ: "Coari Apt",
    CIY: "Comiso Apt",
    CIX: "Comel Ruiz Apt",
    CIW: "Canouan Island Apt",
    CIV: "Chomley Apt",
    CIU: "Chippewa County Intl Apt",
    CIT: "Shimkent Apt",
    CIS: "Canton Island Apt",
    CIR: "Cairo Apt",
    CIQ: "Chiquimula Apt",
    CIP: "Chipata Apt",
    CIO: "Conception Apt",
    CIN: "Arthur N. Neu Apt",
    CIM: "Cimitarra Apt",
    CIL: "Council Apt",
    CIK: "Chalkyitsik Municipal Apt",
    CIJ: "Cap Emile Beltram Apt",
    CII: "Chitipa",
    CIH: "Changzhi Apt",
    CIG: "Moffat Apt",
    CIF: "Chifeng Apt",
    CIE: "Collie Apt",
    CID: "The Eastern Iowa Apt",
    CIC: "Chico Municipal Apt",
    CIB: "Apt in the Sky",
    CIA: "Ciampino Apt",
    CHZ: "Chiloquin State Apt",
    CHY: "Choiseul Bay Apt",
    CHX: "Changuinola Apt",
    CHW: "Jiuquan Apt",
    CHV: "Chaves Apt",
    CHU: "Chuathbaluk Apt",
    CHT: "Chatham Island Apt",
    CHS: "Charleston Intl Apt",
    CHR: "Chateauroux Apt",
    CHQ: "Souda Apt",
    CHP: "Circle Hot Springs Apt",
    CHO: "Albemarle Apt",
    CHN: "Jeonju Apt",
    CHM: "Chimbote Apt",
    CHL: "Challis Apt",
    CHK: "Chickasha Apt",
    CHJ: "Chipinge Apt",
    CHI: "Chicago Metropolitan Area",
    CHH: "Chachapoyas Apt",
    CHG: "Chaoyang Apt",
    CHF: "Jinhae Apt",
    CHD: "Williams AFB",
    CHC: "Christchurch Intl Apt",
    CHB: "Chilas Apt",
    CHA: "Lovell Field",
    CGZ: "Casa Grande Municipal Apt",
    CGY: "Lumbia Apt",
    CGV: "Caiguna Apt",
    CGU: "Ciudad Guayana Apt",
    CGT: "Chinguitti Apt",
    CGS: "College Park Apt",
    CGR: "Campo Grande Apt",
    CGQ: "Changchun Apt",
    CGP: "Patenga Apt",
    CGO: "Zhengzhou Apt",
    CGN: "Konrad Adenaur Apt",
    CGM: "Camiguin Apt",
    CGK: "Soekarno-Hatta Intl Apt",
    CGJ: "Chingola Apt",
    CGI: "Cape Girardeau Municipal Apt",
    CGH: "Aeroporto de Congonhas",
    CGG: "Casiguran Apt",
    CGF: "Cuyahoga County Airport",
    CGE: "Dorchester Apt",
    CGD: "Changde Apt",
    CGC: "Cape Gloucester Apt",
    CGB: "Marechal Rondon Apt",
    CGA: "Craig Municipal Apt",
    CFW: "Cardiff Rail Station",
    CFU: "I. Kapodistrias Apt",
    CFT: "Clifton Apt",
    CFS: "Coffs Harbour Apt",
    CFR: "Carpiquet Apt",
    CFQ: "Creston Apt",
    CFP: "Carpentaria Downs Apt",
    CFO: "Confreza Apt",
    CFN: "Donegal Apt",
    CFK: "Chlef Arpt",
    CFI: "Camfield Apt",
    CFH: "Clifton Hills Apt",
    CFG: "Cienfuegos Apt",
    CFE: "Aulnat Apt",
    CFD: "Bryan Apt",
    CFC: "Cacador Apt",
    CFB: "Cabo Frio Apt",
    CFA: "Coffee Point Municipal Apt",
    CEZ: "Montezuma County Apt",
    CEY: "Murray Apt",
    CEX: "Chena Hot Springs Apt",
    CEW: "Crestview Apt",
    CEV: "Connersville Apt",
    CEU: "Clemson Apt",
    CET: "Cholet Apt",
    CES: "Cessnock Apt",
    CER: "Maupertus Apt",
    CEQ: "Mandelieu Apt",
    CEP: "Concepcion Apt",
    CEO: "Waco Kungo Apt",
    CEN: "Ciudad Obregon Apt",
    CEM: "Central Apt",
    CEL: "Cape Eleuthera Apt",
    CEK: "Chelyabinsk Apt",
    CEJ: "Chernigov Apt",
    CEI: "Chiang Rai Intl Apt",
    CEH: "Chelinda Apt",
    CEG: "Chester Apt",
    CEF: "Westover Metro Apt",
    CEE: "Cherepovets Apt",
    CED: "Ceduna Apt",
    CEC: "Jack McNamara Field.",
    CEB: "Cebu Intl Apt",
    CEA: "Cessna Aircraft Field",
    CDZ: "Cadiz Rail Station",
    CDY: "Cagayan De Sulu Apt",
    CDW: "Essex County Apt",
    CDV: "Mile 13 Field",
    CDU: "Camden Apt",
    CDS: "Childress Apt",
    CDR: "Chadron Municipal Apt",
    CDQ: "Croydon Apt",
    CDP: "Cuddapah Apt",
    CDO: "Cradock Apt",
    CDN: "Camden Apt",
    CDL: "Candle Municipal Apt",
    CDK: "Cedar Key Apt",
    CDJ: "Conceicao do Araguaia Apt",
    CDI: "Cachoeiro de Itapemirim Apt",
    CDH: "Camden Apt",
    CDG: "Charles de Gaulle Apt",
    CDF: "Cortina D'Ampezzo Apt",
    CDE: "Caledonia Apt",
    CDD: "Cauquira Apt",
    CDC: "Cedar City Municipal Apt",
    CDB: "Cold Bay Apt",
    CDA: "Cooinda Apt",
    CCZ: "Chub Cay Apt",
    CCY: "Charles City Municipal Apt",
    CCX: "Caceres Apt",
    CCW: "Cowell Apt",
    CCV: "Craig Cove Apt",
    CCU: "Subhas Chandra Bose Apt",
    CCT: "Colonia Catriel Apt",
    CCS: "Simon Bolivar Intl Apt",
    CCR: "Buchanan Field",
    CCQ: "Cachoeira Apt",
    CCP: "Carriel Sur Apt",
    CCO: "Carimagua Apt",
    CCN: "Chakcharan Apt",
    CCM: "Crisciuma Apt",
    CCL: "Chinchilla Apt",
    CCK: "Cocos Islands Apt",
    CCJ: "Calicut Apt",
    CCI: "Concordia Apt",
    CCH: "Chile Chico Apt",
    CCG: "Crane Apt",
    CCF: "Carcassonne Apt",
    CCE: "Grand Case Apt",
    CCC: "Cayo Coco Municipal Apt",
    CCB: "Cable Apt",
    CCA: "Fort Chaffee Apt",
    CBZ: "Cabin Creek Apt",
    CBY: "Canobie Apt",
    CBX: "Condobolin Apt",
    CBW: "Campo Mourao Apt",
    CBV: "Coban Apt",
    CBU: "Cottbus Apt",
    CBT: "Catumbela Apt",
    CBS: "Cabimas Apt",
    CBR: "Canberra Apt",
    CBQ: "Calabar Apt",
    CBP: "Coimbra Apt",
    CBO: "Cotabato Apt",
    CBN: "Cirebon Apt",
    CBM: "Columbus Air Force Base",
    CBL: "Ciudad Bolivar Apt",
    CBK: "Colby Apt",
    CBJ: "Cabo Rojo Apt",
    CBI: "Cape Barren Island Apt",
    CBH: "Bechar Apt",
    CBG: "Cambridge Apt",
    CBF: "Council Bluffs Municipal Apt",
    CBE: "Wiley Ford Apt",
    CBD: "Car Nicobar Apt",
    CBC: "Cherrabun Apt",
    CBB: "Jorge Wilsterman Apt",
    CBA: "Corner Bay Apt",
    CAZ: "Cobar Apt",
    CAY: "Cayenne Apt",
    CAX: "Carlisle Apt",
    CAW: "Bartolomeu Lisandro Apt",
    CAV: "Cazombo Apt",
    CAU: "Caruaru Apt",
    CAT: "New Bight Apt",
    CAS: "Anfa Apt",
    CAR: "Caribou Municipal Apt",
    CAQ: "Caucasia Municipal Apt",
    CAP: "Cap Haitien Municipal Apt",
    CAO: "Clayton Municipal Airpark",
    CAN: "Baiyun Apt",
    CAM: "Choreti Apt",
    CAL: "Machrihanish Apt",
    CAK: "Akron/Canton Regional Apt",
    CAJ: "Canaima Apt",
    CAI: "Cairo Intl Apt",
    CAH: "Ca Mau Apt",
    CAG: "Elmas Apt",
    CAF: "Carauari Apt",
    CAE: "Columbia Metropolitan Apt",
    CAD: "Wexford County Apt",
    CAC: "Cascavel Municipal Apt",
    CAB: "Cabinda Apt",
    CAA: "Catacamas Apt",
    BZZ: "Brize Norton Raf Station",
    BZY: "Beltsy Apt",
    BZV: "Brazzaville Apt",
    BZU: "Zega Apt",
    BZT: "Brazoria Apt",
    BZR: "Vias Apt",
    BZP: "Bizant Apt",
    BZO: "Bolzano Apt",
    BZN: "Gallatin Field",
    BZM: "Bergen Opp Zoom Apt",
    BZL: "Barisal Apt",
    BZK: "Briansk Apt",
    BZI: "Balikesir Apt",
    BZH: "Bumi Hills Apt",
    BZG: "Bydgoszcz Apt",
    BZE: "Philip S. W. Goldson Intl Apt",
    BZD: "Balranald Apt",
    BZC: "Buzios Apt",
    BZB: "Bazaruto Island Apt",
    BZA: "Bonanza Apt",
    BYX: "Baniyala Apt",
    BYW: "Blakeley Island Apt",
    BYV: "Baiyer River",
    BYU: "Bindlacher Berg Apt",
    BYT: "Bantry Apt",
    BYS: "Fort Irwin Arpt",
    BYR: "Laeso Airport",
    BYQ: "Bunyu Apt",
    BYO: "Bonito Airport",
    BYN: "Bayankhongor Apt",
    BYM: "C. M. de Cespedes Apt",
    BYL: "Bella Yella Apt",
    BYK: "Bouake Apt",
    BYJ: "Beja International Airport",
    BYI: "Burley Municipal Apt",
    BYH: "Blytheville Air Force Base",
    BYG: "Buffalo Apt",
    BYF: "Albert Arpt",
    BYD: "Beidah Apt",
    BYC: "Yacuiba Apt",
    BYB: "Dibaa Apt",
    BYA: "Boundary Municipal Apt",
    BXZ: "Bunsil Apt",
    BXX: "Borama Apt",
    BXV: "Breiddalsvik Apt",
    BXU: "Butuan Apt",
    BXT: "Bontang Apt",
    BXS: "Borrego Valley Apt",
    BXR: "Bam Apt",
    BXO: "Buochs airport",
    BXN: "Imsik Apt",
    BXM: "Batom Apt",
    BXL: "Blue Lagoon Apt",
    BXK: "Buckeye Apt",
    BXJ: "Burundai Apt",
    BXI: "Boundiali Apt",
    BXH: "Balhash Apt",
    BXG: "Bendigo Apt",
    BXE: "Bakel Apt",
    BXD: "Bade Apt",
    BXC: "Boxborough Apt",
    BXB: "Babo Apt",
    BXA: "Bogalusa Apt",
    BWX: "Blimbingsari Arpt",
    BWV: "Banmethuot",
    BWU: "Bankstown Apt",
    BWT: "Wynyard Apt",
    BWS: "Blaine Municipal Apt",
    BWQ: "Brewarrina Apt",
    BWP: "Bewani Apt",
    BWO: "Balakovo Apt",
    BWN: "Bandar Seri Begawan Intl Apt",
    BWM: "Bowman Apt",
    BWL: "Blackwell Apt",
    BWK: "Bol Apt",
    BWJ: "Bawan Apt",
    BWI: "Baltimore-Washington Intl Apt",
    BWH: "Butterworth Apt",
    BWG: "Bowling Green Apt",
    BWF: "Barrow-In-Furness Apt",
    BWE: "Braunschweig Apt",
    BWD: "Brownwood Municipal Apt",
    BWC: "Brawley Apt",
    BWB: "Barrow Island Apt",
    BWA: "Bhairawa Apt",
    BVZ: "Beverley Springs Apt",
    BVY: "Beverly Municipal Apt",
    BVX: "Batesville Apt",
    BVW: "Batavia Downs Apt",
    BVV: "Burevestnik AFB",
    BVU: "Beluga Apt",
    BVS: "Breves Apt",
    BVR: "Brava Apt",
    BVP: "Bolovip Apt",
    BVO: "Bartlesville Municipal Apt",
    BVM: "Belmonte Apt",
    BVL: "Baures Apt",
    BVK: "Huacaraje Apt",
    BVI: "Birdsville Apt",
    BVH: "Vilhena Municipal Apt",
    BVG: "Berlevag Apt",
    BVF: "Bua Apt",
    BVE: "Laroche Apt",
    BVC: "Boa Vista Apt",
    BVB: "Boa Vista Municipal Apt",
    BVA: "Tille Apt",
    BUZ: "Bushehr Apt",
    BUY: "Bunbury Apt",
    BUX: "Bunia Apt",
    BUW: "Baubau Apt",
    BUV: "Bella Union Apt",
    BUU: "Buyo Apt",
    BUT: "Burtonwood Apt",
    BUS: "Batumi Apt",
    BUR: "Burbank Apt",
    BUQ: "Bulawayo Apt",
    BUP: "Bhatinda Apt",
    BUO: "Burao Apt",
    BUN: "Buenaventura Apt",
    BUM: "Butler Apt",
    BUL: "Bulolo Apt",
    BUK: "Albuq Apt",
    BUJ: "Boussaada Apt",
    BUI: "Bokondini Apt",
    BUH: "Bucharest",
    BUG: "Benguela Apt",
    BUF: "Greater Buffalo Intl Apt",
    BUE: "Buenos Aires",
    BUD: "Ferihegy Apt",
    BUC: "Burketown Apt",
    BUB: "Burwell Apt",
    BUA: "Buka Apt",
    BTZ: "Bursa Apt",
    BTY: "Beatty Apt",
    BTX: "Betoota Apt",
    BTW: "Batu Licin Apt",
    BTV: "Burlington Intl Apt",
    BTU: "Bintulu Apt",
    BTT: "Bettles Apt",
    BTS: "M. R. Stefanik Apt",
    BTR: "Ryan Field",
    BTQ: "Butare Apt",
    BTP: "Butler County Apt",
    BTO: "Botopasie Apt",
    BTN: "Bennettsville Apt",
    BTM: "Bert Mooney Apt",
    BTL: "W. K. Kellogg Apt",
    BTK: "Bratsk Apt",
    BTJ: "Blang Bintang Apt",
    BTI: "Barter Island Apt",
    BTH: "Hang Nadim Apt",
    BTG: "Batangafo Apt",
    BTF: "Bountiful Apt",
    BTE: "Bonthe Apt",
    BTD: "Brunette Downs Apt",
    BTC: "Batticaloa Apt",
    BTB: "Betou Apt",
    BTA: "Bertoua Apt",
    BSZ: "Bartletts Municipal Apt",
    BSY: "Bardera Apt",
    BSX: "Bassein Apt",
    BSW: "Boswell Bay Apt",
    BSV: "Besakoa Apt",
    BSU: "Basankusu Apt",
    BST: "Bost Apt",
    BSS: "Balsas Apt",
    BSR: "Basra Intl Apt",
    BSQ: "Bisbee Apt",
    BSP: "Bensbach Apt",
    BSO: "Basco Apt",
    BSN: "Bossangoa Apt",
    BSL: "Euro Apt",
    BSK: "Biskra Apt",
    BSJ: "Bairnsdale Apt",
    BSI: "Blairsville Apt",
    BSH: "Brighton Apt",
    BSG: "Bata Apt",
    BSF: "Pohakuloa Apt",
    BSE: "Sematan Apt",
    BSD: "Baoshan Apt",
    BSC: "Bahia Solano Municipal Apt",
    BSB: "Brasilia Intl Apt",
    BSA: "Bossaso Apt",
    BRZ: "Borotou Apt",
    BRY: "Bardstown Apt",
    BRX: "Barahona Municipal Apt",
    BRW: "Wiley Post/Will Rogers Apt",
    BRV: "Bremerhaven Apt",
    BRU: "Brussels National Apt",
    BRT: "Bathurst Island Apt",
    BRS: "Bristol Intl Apt",
    BRR: "North Bay Apt",
    BRQ: "Turany Apt",
    BRP: "Biaru Apt",
    BRO: "South Padre Island Intl Apt",
    BRN: "Belp Apt",
    BRM: "Barquisimeto Apt",
    BRL: "Burlington Municipal Apt",
    BRK: "Bourke Apt",
    BRJ: "Bright Apt",
    BRI: "Palese Macchie Apt",
    BRH: "Brahman Apt",
    BRG: "Whitesburg Apt",
    BRF: "Bradford Apt",
    BRE: "Bremen Apt",
    BRD: "Crow Wing Apt",
    BRC: "De Bariloche Intl Apt",
    BRB: "Barreirinhas Apt",
    BRA: "Barreiras Apt",
    BQZ: "Badajoz Rail Station",
    BQW: "Balgo Hills Apt",
    BQV: "Gustavus Bartlett Cove SPB",
    BQU: "Port Elizabeth Municipal Apt",
    BQT: "Brest Apt",
    BQS: "Blagoveschensk Apt",
    BQQ: "Barra Apt",
    BQO: "Bouna Apt",
    BQN: "Aeropuerto Rafael Hernandez",
    BQL: "Boulia Apt",
    BQK: "Glynco Jetport",
    BQJ: "Batagay Arpt",
    BQI: "Bagani Apt",
    BQH: "Biggin Hill Apt",
    BQE: "Bubaque Apt",
    BQD: "Budardalur",
    BQB: "Bussellton Apt",
    BQA: "Baler Apt",
    BPY: "Besalampy Apt",
    BPX: "Bangda Apt",
    BPU: "Beppu Apt",
    BPT: "Jefferson County Apt",
    BPS: "Porto Seguro Municipal Apt",
    BPR: "Bristol Parkway Rail Station",
    BPO: "Boao Rail Station",
    BPN: "Sepinggan Apt",
    BPM: "Begumpet Airport",
    BPK: "Biangabip Apt",
    BPI: "Big Piney Apt",
    BPH: "Bislig Apt",
    BPG: "Barra Do Garcas Apt",
    BPF: "Batuna Apt",
    BPE: "Bagan Apt",
    BPD: "Bapi Apt",
    BPC: "Bamenda Apt",
    BPB: "Boridi Apt",
    BPA: "Bethpage Apt",
    BOZ: "Bozoum Apt",
    BOY: "Bobo Dioulasso Apt",
    BOX: "Borroloola Apt",
    BOW: "Bartow Apt",
    BOV: "Boang Apt",
    BOU: "Bourges Apt",
    BOT: "Boset Apt",
    BOS: "Logan Intl Apt",
    BOR: "Belfort Apt",
    BOQ: "Boku Apt",
    BOP: "Bouar Apt",
    BOO: "Bodo Airport",
    BON: "Flamingo Apt",
    BOM: "Chhaprati Shivaji Intl Apt",
    BOL: "Bally Kelly Apt",
    BOK: "Brookings State Apt",
    BOJ: "Bourgas Apt",
    BOI: "Boise Air Terminal",
    BOH: "Bournemouth Intl Apt",
    BOG: "Eldorado Apt",
    BOF: "Bolling Airforce Base",
    BOE: "Boundji Apt",
    BOD: "Bordeaux Apt",
    BOC: "Bocas Del Toro Apt",
    BOB: "Motu-mute Apt",
    BOA: "Boma Apt",
    BNZ: "Banz Apt",
    BNY: "Bellona Apt",
    BNX: "Banja Luka Apt",
    BNW: "Boone Municipal Apt",
    BNV: "Boana Apt",
    BNU: "Blumenau Apt",
    BNT: "Bundi Apt",
    BNS: "Barinas Apt",
    BNR: "Banfora Apt",
    BNQ: "Baganga Apt",
    BNP: "Bannu Apt",
    BNO: "Burns Municipal Apt",
    BNN: "Bronnoysund Apt",
    BNM: "Bodinumu Apt",
    BNL: "Barnwell Apt",
    BNK: "Ballina Apt",
    BNJ: "Bonn Hauptbahnhof",
    BNI: "Benin City Apt",
    BNH: "Hartford Barnes Apt",
    BNG: "Banning Apt",
    BNF: "Baranof Apt",
    BNE: "Brisbane Intl Apt",
    BND: "Bandar Abbas Apt",
    BNC: "Beni Apt",
    BNB: "Boende Apt",
    BNA: "Nashville Intl Apt",
    BMZ: "Bamu Apt",
    BMY: "Belep Island Apt",
    BMX: "Big Mountain Apt",
    BMW: "Bordj Badji Mokhtar Apt",
    BMV: "Bu\u00F4n Ma Thu\u1ED9t Apt",
    BMU: "Bima Apt",
    BMT: "Beaumont Municipal Arpt",
    BMS: "Brumado Apt",
    BMR: "Baltrum Apt",
    BMQ: "Bamburi Apt",
    BMP: "Brampton Island Apt",
    BMO: "Bhamo Apt",
    BMN: "Bamerny Apt",
    BMM: "Bitam Apt",
    BML: "Berlin Municipal Apt",
    BMK: "Borkum Apt",
    BMJ: "Baramita Apt",
    BMI: "Bloomington Apt",
    BMH: "Bomai Apt",
    BMG: "Monroe County Apt",
    BMF: "Bakouma Apt",
    BME: "Broome Apt",
    BMD: "Belo Apt",
    BMC: "Brigham City Apt",
    BMB: "Bumba Apt",
    BMA: "Bromma Apt",
    BLZ: "Blantyre Apt",
    BLY: "Belmullet Apt",
    BLX: "Belluno Apt",
    BLW: "Waimanalo Apt",
    BLV: "Scott AFB Mid America",
    BLU: "Nyack Apt",
    BLT: "Blackwater Apt",
    BLS: "Bollon Apt",
    BLR: "Hindustan Apt",
    BLQ: "Giglielmo Marconi Apt",
    BLP: "Bellavista Apt",
    BLO: "Blondous Apt",
    BLN: "Benalla Apt",
    BLM: "Belmar Apt",
    BLL: "Billund Apt",
    BLK: "Blackpool Apt",
    BLJ: "Batna Apt",
    BLI: "Bellingham Intl Apt",
    BLH: "Blythe Apt",
    BLG: "Belaga Apt",
    BLF: "Mercer County Apt",
    BLE: "Dala Apt",
    BLD: "Boulder City Municipal Apt",
    BLC: "Bali Apt",
    BLB: "Balboa Apt",
    BLA: "Jose A. Anzoategui Apt",
    BKZ: "Bukoba Apt",
    BKY: "Bukavu Apt",
    BKX: "Brookings Municipal Apt",
    BKW: "Beckley Apt",
    BKU: "Betioky Apt",
    BKT: "Blackstone Apt",
    BKS: "Padangkemiling Apt",
    BKR: "Bokoro Apt",
    BKQ: "Blackall Apt",
    BKP: "Barkly Downs Apt",
    BKO: "Bamako Apt",
    BKN: "Birni Nkoni Apt",
    BKM: "Bakalalan Apt",
    BKL: "Burke Lakefront Arpt",
    BKK: "Suvarnabhumi Intl Apt",
    BKJ: "Boke Apt",
    BKI: "Kota Kinabalu Apt",
    BKH: "Kekaha Apt",
    BKG: "Branson Regional Arpt",
    BKF: "Brooks Lake Apt",
    BKE: "Baker City Municipal Apt",
    BKD: "Breckenridge Apt",
    BKC: "Buckland Apt",
    BKB: "Bikaner Apt",
    BKA: "Moscow Bykovo Apt",
    BJZ: "Talavera La Real Apt",
    BJY: "Batajnica Apt",
    BJX: "Guanajuato Apt",
    BJW: "Bajawa Apt",
    BJV: "Milas Apt",
    BJU: "Bajura Apt",
    BJT: "Bentota River Airport",
    BJS: "Beijing",
    BJR: "Bahar Dar Apt",
    BJP: "Braganca Paulista Apt",
    BJO: "Bermejo Apt",
    BJN: "Bajone Apt",
    BJM: "Bujumbura Intl Apt",
    BJL: "Banjul Apt",
    BJK: "Benjina Apt",
    BJJ: "Wayne County Apt",
    BJI: "Bemidji Municipal Apt",
    BJH: "Bajhang Apt",
    BJG: "Bolaang Apt",
    BJF: "Batsfjord Apt",
    BJD: "Bakkafjordur Apt",
    BJC: "Broomfield Apt",
    BJB: "Bojnord Apt",
    BJA: "Bejaia Apt",
    BIZ: "Bimin Apt",
    BIY: "Bisho Apt",
    BIW: "Billiluna Apt",
    BIV: "Bria Apt",
    BIU: "Bildudalur Apt",
    BIT: "Baitadi Apt",
    BIS: "Bismarck Municipal Apt",
    BIR: "Biratnagar Apt",
    BIQ: "Biarritz/Parme Apt",
    BIP: "Bulimba Apt",
    BIO: "Bilbao Apt",
    BIN: "Bamiyan Apt",
    BIM: "Bimini Intl Apt",
    BIL: "Logan Intl Apt",
    BIK: "Mokmer Apt",
    BII: "Bikini Atoll Apt",
    BIH: "Bishop Apt",
    BIG: "Big Delta Apt",
    BIF: "Biggs Army Air Field",
    BIE: "Beatrice Apt",
    BID: "Block Island State Apt",
    BIC: "Big Creek Municipal Apt",
    BIB: "Baidoa Apt",
    BIA: "Poretta Apt",
    BHZ: "BHZ",
    BHY: "Beihai Apt",
    BHX: "Birmingham Intl Apt",
    BHW: "Bhagatanwala Apt",
    BHV: "Bahawalpur Apt",
    BHU: "Bhavnagar Civil Apt",
    BHT: "Brighton Downs Apt",
    BHS: "Raglan Apt",
    BHR: "Bharatpur Apt",
    BHQ: "Broken Hill Apt",
    BHP: "Bhojpur Apt",
    BHO: "Bhopal Apt",
    BHN: "Beihan Apt",
    BHM: "Birmingham Intl Apt",
    BHL: "Bahia De Los Angeles Apt",
    BHK: "Bukhara Apt",
    BHJ: "Rudra Mata Apt",
    BHI: "Comandante Apt",
    BHH: "Bisha Apt",
    BHG: "Brus Laguna Apt",
    BHF: "Bahia Cupica Apt",
    BHE: "Blenheim Apt",
    BHD: "Belfast City Apt",
    BHB: "Bar Harbor Apt",
    BHA: "Bahia De Caraquez Apt",
    BGZ: "Braga Apt",
    BGY: "Orio Al Serio Apt",
    BGX: "Bage Apt",
    BGW: "Al Muthana Apt",
    BGV: "Bento Goncalves Apt",
    BGU: "Bangassou Apt",
    BGT: "Bagdad Apt",
    BGS: "Webb Airforce Base",
    BGR: "Bangor Intl Apt",
    BGQ: "Big Lake Apt",
    BGP: "Bongo Apt",
    BGO: "Flesland Apt",
    BGN: "Bruggen Apt",
    BGM: "Binghamton Regional Apt",
    BGL: "Baglung Apt",
    BGK: "Big Creek Apt",
    BGJ: "Borgarfjordur Eystri Apt",
    BGI: "Grantley Adams Intl Apt",
    BGH: "Boghe Apt",
    BGG: "Bongouanou Apt",
    BGF: "Banqui Apt",
    BGE: "Bainbridge Apt",
    BGD: "Borger Apt",
    BGC: "Braganca Apt",
    BGB: "Booue Apt",
    BGA: "Palo Negro Apt",
    BFX: "Bafoussam Apt",
    BFV: "Clinton Municipal Apt",
    BFU: "Bengbu Apt",
    BFT: "Beaufort Apt",
    BFS: "Aldergrove Intl Apt",
    BFR: "Bedford Apt",
    BFQ: "Bahia Pinas Apt",
    BFP: "Beaver County Apt",
    BFO: "Buffalo Range Apt",
    BFN: "Bloemfontein Apt",
    BFM: "Brookley Arpt",
    BFL: "Meadows Field",
    BFK: "Buckley Air National Guard Base",
    BFJ: "Ba Apt",
    BFI: "Seattle Boeing Field",
    BFH: "Bacacheri Apt",
    BFG: "Bullfrog Basin Apt",
    BFF: "Scottsbluff County Apt",
    BFE: "Bielefeld Apt",
    BFD: "Bradford Regional Apt",
    BFC: "Bloomfield Apt",
    BFB: "Blue Fox Bay Apt",
    BFA: "Boyne Falls",
    BEZ: "Beru Apt",
    BEY: "Beirut Intl Apt",
    BEX: "Benson Apt",
    BEW: "Beira Apt",
    BEV: "Beer Sheba Apt",
    BEU: "Bedourie Apt",
    BET: "Bethel Municipal Apt",
    BES: "Guipavas Apt",
    BER: "Berlin Metropolitan Area",
    BEQ: "Bury Saint Edmunds Apt",
    BEP: "Bellary Apt",
    BEO: "Belmont Apt",
    BEN: "Benghazi Apt",
    BEM: "Bossembele Apt",
    BEL: "Vale de Cans Apt",
    BEK: "Rae Bareli Apt",
    BEJ: "Berau Apt",
    BEI: "Beica Apt",
    BEH: "Ross Field",
    BEG: "Beograd Apt",
    BEF: "Bluefields Apt",
    BEE: "Beagle Bay Apt",
    BED: "Bedford Apt",
    BEC: "Beech Arpt",
    BEB: "Benbecula Apt",
    BEA: "Bereina Apt",
    BDZ: "Baindoung Apt",
    BDY: "Bandon State Apt",
    BDX: "Broadus Apt",
    BDW: "Bedford Downs Apt",
    BDV: "Moba Apt",
    BDU: "Bardufoss Apt",
    BDT: "Gbadolite Apt",
    BDS: "Papola Casale Apt",
    BDR: "Sikorsky Memorial Apt",
    BDQ: "Vadodara Apt",
    BDP: "Bhadrapur Apt",
    BDO: "Husein Sastranegara Apt",
    BDN: "Badin Apt",
    BDM: "Bandirma Apt",
    BDL: "Bradley Intl Apt",
    BDK: "Bondoukou Apt",
    BDJ: "Syamsudin Noor Apt",
    BDI: "Bird Island Apt",
    BDH: "Bandar Lengeh Apt",
    BDG: "Blanding Apt",
    BDE: "Baudette Apt",
    BDD: "Badu Island Apt",
    BDC: "Barra Do Corda Apt",
    BDB: "Bundaberg Apt",
    BDA: "Bermuda Intl Apt",
    BCZ: "Bickerton Island Apt",
    BCY: "Bulchi Apt",
    BCX: "Beloreck Apt",
    BCW: "Benguera Island Apt",
    BCV: "Belmopan Apt",
    BCU: "Bauchi Apt",
    BCT: "Boca Raton Apt",
    BCS: "Belle Chasse Apt",
    BCR: "Boca Do Acre Apt",
    BCQ: "Brack Apt",
    BCP: "Bambu Apt",
    BCO: "Jinka Apt",
    BCN: "Barcelona Apt",
    BCM: "Bacau Apt",
    BCL: "Barra Colorado Apt",
    BCK: "Bolwarra Apt",
    BCJ: "Baca Grande Apt",
    BCI: "Barcaldine Apt",
    BCH: "Baucau Apt",
    BCG: "Bemichi Apt",
    BCF: "Bouca Apt",
    BCE: "Bryce Apt",
    BCD: "Bacolod Apt",
    BCC: "Bear Creek Apt",
    BCB: "Blacksburg Apt",
    BCA: "Baracoa Apt",
    BBZ: "Zambezi Apt",
    BBY: "Bambari Apt",
    BBX: "Blue Bell Apt",
    BBW: "Broken Bow Apt",
    BBV: "Bereby Apt",
    BBU: "Baneasa Apt",
    BBT: "Berberati Apt",
    BBS: "Blackbushe Apt",
    BBR: "Basse Terre Municipal Apt",
    BBQ: "Barbuda Apt",
    BBP: "Bembridge Apt",
    BBO: "Berbera Apt",
    BBN: "Bario Apt",
    BBM: "Battambang Apt",
    BBL: "Babolsar Apt",
    BBK: "Kasane Apt",
    BBJ: "Bitburg Apt",
    BBI: "Bhubaneswar Apt",
    BBH: "Barth Apt",
    BBG: "Antakana Apt",
    BBF: "Burlington Municipal Apt",
    BBE: "Big Bell Apt",
    BBD: "Brady Apt",
    BBC: "Bay City Apt",
    BBB: "Benson Apt",
    BBA: "Teniente Vidal Apt",
    BAZ: "Barcelos Apt",
    BAY: "Baia Mare Apt",
    BAX: "Barnaul Apt",
    BAW: "Biawongue Apt",
    BAV: "Baotou Apt",
    BAU: "Bauru Apt",
    BAT: "Barretos Apt",
    BAS: "Balalae Apt",
    BAR: "Baker Island Apt",
    BAQ: "Cortissoz Apt",
    BAP: "Baibara Apt",
    BAO: "Ban Mak Khaeng Apt",
    BAN: "Basongo Apt",
    BAM: "Battle Mountain Apt",
    BAL: "Batman Arpt",
    BAK: "Baku Apt",
    BAJ: "Bali Apt",
    BAI: "Buenos Aires Apt",
    BAH: "Bahrain Intl Apt",
    BAG: "Loakan Apt",
    BAF: "Westfield Apt",
    BAE: "Barcelonnette Apt",
    BAD: "Barksdale Air Force Base",
    BAC: "Barranca De Upia Apt",
    BAB: "Beale AFB",
    BAA: "Bialla Apt",
    AZT: "Zapatoca Apt",
    AZS: "El Catey International",
    AZR: "Adrar Apt",
    AZP: "Atizapan Jimenez Cantu Airport",
    AZO: "Kalamazoo Battle Creek Intl Apt",
    AZN: "Andizhan Apt",
    AZI: "Bateen Apt",
    AZG: "Apatzingan Apt",
    AZD: "Yazd Apt",
    AZB: "Amazon Bay Apt",
    AZA: "Phoenix Mesa Gateway Airport",
    AYZ: "Amityville Apt",
    AYY: "Al Bayda",
    AYW: "Ayawasi Apt",
    AYU: "Aiyura Apt",
    AYT: "Antalya Apt",
    AYS: "Waycross Apt",
    AYR: "Ayr Apt",
    AYQ: "Connellan Apt",
    AYP: "Yanamilla Apt",
    AYO: "Ayolas Apt",
    AYN: "Anyang Apt",
    AYL: "Anthony Lagoon Apt",
    AYK: "Arkalyk Apt",
    AYI: "Yari Apt",
    AYG: "Yaguara Apt",
    AYE: "Fort Devens Apt",
    AYD: "Alroy Downs Apt",
    AYC: "Ayacucho Apt",
    AYA: "Ayapel Apt",
    AXX: "Angel Fire Apt",
    AXV: "Wapakoneta Apt",
    AXU: "Axum Apt",
    AXT: "Akita Apt",
    AXS: "Altus Municipal Apt",
    AXR: "Arutua Apt",
    AXP: "Spring Point Municipal Apt",
    AXN: "Alexandria Apt",
    AXM: "El Eden Apt",
    AXL: "Alexandria Apt",
    AXK: "Ataq Apt",
    AXG: "Algona Municipal Apt",
    AXE: "Xanxere Apt",
    AXD: "Demokritos Apt",
    AXC: "Aramac Apt",
    AXB: "Alexandria Bay Apt",
    AXA: "Wallblake Apt",
    AWZ: "Ahwaz Apt",
    AWR: "Awar Apt",
    AWP: "Austral Downs Apt",
    AWN: "Alton Downs Apt",
    AWM: "West Memphis Apt",
    AWK: "Wake Island Airfield",
    AWH: "Awareh Apt",
    AWE: "Alowe Apt",
    AWD: "Aniwa Apt",
    AWB: "Awaba Apt",
    AWA: "Awassa Apt",
    AVX: "Avalon Apt",
    AVW: "Avra Valley",
    AVV: "Avalon Apt",
    AVU: "Avu Avu Apt",
    AVS: "Avila Rail Station",
    AVP: "Wilkes-Barre/Scranton Intl Apt",
    AVO: "Avon Park Municipal Apt",
    AVN: "Avignon/Caumont Apt",
    AVL: "Asheville Regional Apt",
    AVK: "Arvaikheer Apt",
    AVJ: "Avila Bus Station",
    AVI: "Maximo Gomez Apt",
    AVG: "Auvergne Apt",
    AVF: "Avoriaz Apt",
    AVB: "Aviano Airbase",
    AUZ: "Aurora Apt",
    AUY: "Aneityum Apt",
    AUX: "Araguaina Municipal Apt",
    AUW: "Wausau Municipal Apt",
    AUV: "Aumo Apt",
    AUU: "Aurukun Mission Apt",
    AUT: "Atauro Apt",
    AUS: "Bergstrom Intl Apt",
    AUR: "Tronquieres Apt",
    AUQ: "Atuona Apt",
    AUP: "Agaun Apt",
    AUO: "Auburn Apt",
    AUN: "Auburn Apt",
    AUM: "Austin Municipal Apt",
    AUL: "Aur Island Apt",
    AUK: "Alakanuk Municipal Apt",
    AUJ: "Ambunti Apt",
    AUI: "Aua Island Apt",
    AUH: "Nadia Intl Apt",
    AUG: "Augusta State Apt",
    AUF: "Auxerre Apt",
    AUE: "Abu Rudeis Apt",
    AUD: "Augustus Downs Apt",
    AUC: "Arauca Municipal Apt",
    AUA: "Queen Beatrix Intl Apt",
    ATZ: "Assiut Apt",
    ATY: "Watertown Municipal Apt",
    ATW: "Outagamie County Apt",
    ATV: "Ati Apt",
    ATU: "Attu Island Apt",
    ATT: "Atmautluak Apt",
    ATS: "Artesia Municipal Apt",
    ATR: "Atar Apt",
    ATQ: "Raja Sansi Apt",
    ATP: "Aitape Airstrip",
    ATO: "Ohio University Arpt",
    ATN: "Namatanai Apt",
    ATM: "Altamira Municipal Apt",
    ATL: "Hartsfield Intl Apt",
    ATK: "Meade River Apt",
    ATJ: "Antsirabe Apt",
    ATI: "Artigas Municipal Apt",
    ATH: "Eleftherios Venizelos Intl Apt",
    ATG: "Attock Apt",
    ATF: "Ambato Apt",
    ATE: "Antlers Apt",
    ATD: "Atoifi Apt",
    ATC: "Arthur's Town Apt",
    ATB: "Atbara Apt",
    ATA: "Anta Apt",
    ASZ: "Asirim Apt",
    ASY: "Ashley Apt",
    ASX: "Ashland Apt",
    ASW: "Aswan Apt",
    ASV: "Amboseli Apt",
    ASU: "Silvio Pettirossi Apt",
    AST: "Astoria Regional Apt",
    ASR: "Erkilet Apt",
    ASQ: "Austin Apt",
    ASP: "Alice Springs Apt",
    ASO: "Asosa Apt",
    ASN: "Talladega Apt",
    ASM: "Asmara Intl Apt",
    ASL: "Marshall Apt",
    ASK: "Yamoussoukro Apt",
    ASJ: "Amami O Shima Apt",
    ASI: "Georgetown Apt",
    ASH: "Nashua Apt",
    ASG: "Ashburton Apt",
    ASF: "Astrakhan Apt",
    ASE: "Aspen Apt",
    ASD: "Andros Town Intl Apt",
    ASC: "Ascension Apt",
    ASB: "Ashgabat Apt",
    ASA: "Assab Apt",
    ARZ: "N'Zeto Apt",
    ARY: "Ararat Apt",
    ARW: "Arad Apt",
    ARV: "Minocqua Apt",
    ARU: "Aracatuba Apt",
    ART: "Watertown Intl Apt",
    ARS: "Aragarcas Apt",
    ARQ: "Arauquita Apt",
    ARP: "Aragip Apt",
    ARO: "Arboletas Apt",
    ARN: "Arlanda Apt",
    ARM: "Armidale Apt",
    ARL: "Arly Apt",
    ARK: "Arusha Apt",
    ARJ: "Arso Apt",
    ARI: "Chacalluta Apt",
    ARH: "Arkhangelsk Apt",
    ARG: "Walnut Ridge Apt",
    ARF: "Acaricuara Apt",
    ARE: "Arecibo Apt",
    ARD: "Alor Island Apt",
    ARC: "Arctic Village Apt",
    ARB: "Ann Arbor Municipal Apt",
    ARA: "Acadiana Regional Apt",
    AQY: "Alyeska Apt",
    AQS: "Saqani Apt",
    AQP: "Rodriguez Ballon Apt",
    AQM: "Ariquemes Apt",
    AQJ: "Aqaba Apt",
    AQI: "Qaisumah Apt",
    AQG: "Anqing Apt",
    AQB: "Quiche",
    AQA: "Araraquara Apt",
    APZ: "Zapala Apt",
    APY: "Alto Parnaiba Apt",
    APX: "Arapongas Apt",
    APW: "Faleolo Apt",
    APV: "Apple Valley Apt",
    APU: "Apucarana Apt",
    APT: "Jasper Apt",
    APS: "Anapolis Apt",
    APR: "April River Apt",
    APQ: "Arapiraca Apt",
    APP: "Asapa Apt",
    APO: "Apartado Apt",
    APN: "Alpena County Regional Apt",
    APL: "Nampula Apt",
    APK: "Apataki Apt",
    API: "Apiay Apt",
    APH: "Bowling Green Apt",
    APG: "Aberdeen Apt",
    APF: "Naples Municipal Apt",
    APE: "San Juan Aposento Apt",
    APC: "Napa County Apt",
    APB: "Apolo Apt",
    APA: "Centennial Arpt",
    AOU: "Attopeu Apt",
    AOT: "Aosta Apt",
    AOS: "Amook Municipal Apt",
    AOR: "Sultan Abdul Halim Apt",
    AOO: "Blair County Apt",
    AON: "Arona Apt",
    AOL: "Paso De Los Libres Apt",
    AOK: "Karpathos Apt",
    AOJ: "Aomori Apt",
    AOI: "Falconara Apt",
    AOH: "Lima Apt",
    AOG: "Anshan Apt",
    AOE: "Eskisehir Apt",
    AOD: "Abou Deia Apt",
    AOC: "Altenburg Apt",
    AOB: "Annanberg Apt",
    AOA: "Aroa Apt",
    ANZ: "Angus Downs Apt",
    ANY: "Anthony Municipal Apt",
    ANX: "Andenes Apt",
    ANW: "Ainsworth Apt",
    ANV: "Anvik Apt",
    ANU: "V.C. Bird Intl Apt",
    ANS: "Andahuaylas Apt",
    ANR: "Deurne Apt",
    ANQ: "Angola Apt",
    ANP: "Annapolis Apt",
    ANO: "Angoche Apt",
    ANN: "Annette Island Apt",
    ANM: "Antalaha Apt",
    ANL: "Andulo Apt",
    ANK: "Etimesgut Apt",
    ANJ: "Zanaga Apt",
    ANI: "ANI",
    ANH: "Anuha Island Resort Apt",
    ANG: "Brie Champoniers Apt",
    ANF: "Cerro Moreno Apt",
    ANE: "Marce Apt",
    AND: "Anderson Apt",
    ANC: "Anchorage Intl Apt",
    ANB: "Anniston Municipal Arpt",
    ANA: "Anaheim Apt",
    AMZ: "Ardmore Apt",
    AMY: "Ambatomainty Apt",
    AMX: "Ammaroo Apt",
    AMW: "Ames Municipal Apt",
    AMV: "Amderma Apt",
    AMU: "Amanab Apt",
    AMT: "Amata Apt",
    AMS: "Schiphol Intl Apt",
    AMR: "Arno Apt",
    AMQ: "Pattimura Apt",
    AMP: "Ampanihy Apt",
    AMO: "Mao Apt",
    AMN: "Gratiot Community Apt",
    AMM: "Queen Alia Intl Apt",
    AML: "Puerto Armuellas Apt",
    AMK: "Animas Airpark",
    AMJ: "Almenara Apt",
    AMI: "Selaparang Apt",
    AMH: "Arba Mintch Apt",
    AMG: "Amboin Apt",
    AMF: "Ama Apt",
    AME: "Alto Molocue Apt",
    AMD: "Ahmedabad Apt",
    AMC: "Am Timan Apt",
    AMB: "Ambilobe Apt",
    AMA: "Amarillo Intl Apt",
    ALZ: "Alitak Municipal Apt",
    ALY: "Alexandria Apt",
    ALX: "Alexander City Apt",
    ALW: "Walla Walla Regional Apt",
    ALV: "Andorra La Vella Hlpt",
    ALU: "Alula Apt",
    ALT: "Alenquer Apt",
    ALS: "Alamosa Municipal Apt",
    ALR: "Alexandra Apt",
    ALQ: "Alegrete Apt",
    ALP: "Aleppo Apt",
    ALO: "Waterloo Municipal Apt",
    ALN: "Alton Apt",
    ALM: "Alamogordo Municipal Apt",
    ALL: "Albenga Apt",
    ALK: "Asela Apt",
    ALJ: "Alexander Bay Apt",
    ALI: "Alice Intl Apt",
    ALH: "Albany Apt",
    ALG: "Algiers Apt",
    ALF: "Alta Apt",
    ALE: "Alpine Apt",
    ALD: "Alerta Apt",
    ALC: "Alicante Apt",
    ALB: "Albany County Apt",
    ALA: "Almaty Apt",
    AKY: "Sittwe Civil Apt",
    AKX: "Aktyubinsk Apt",
    AKV: "Akulivik Apt",
    AKU: "Aksu Apt",
    AKT: "Akrotiri Apt",
    AKS: "Auki Apt",
    AKR: "Akure Apt",
    AKQ: "Astraksetra Apt",
    AKP: "Anaktuvuk Pass Apt",
    AKO: "Akron Apt",
    AKN: "King Salmon Apt",
    AKM: "Zakouma Apt",
    AKL: "Aukland Intl Apt",
    AKK: "Akhiok Municipal Apt",
    AKJ: "Asahikawa Apt",
    AKI: "Akiak Apt",
    AKG: "Anguganak Apt",
    AKF: "Kufrah Apt",
    AKE: "Akieni Apt",
    AKD: "Akola Apt",
    AKC: "Fulton International Arpt",
    AKB: "Atka Municipal Apt",
    AKA: "Ankang Apt",
    AJY: "Agades Apt",
    AJU: "Santa Maria Apt",
    AJS: "Abreojos Apt",
    AJR: "Arvidsjaur Apt",
    AJO: "Aljouf Apt",
    AJN: "Anjouan Apt",
    AJL: "Aizawl Apt",
    AJJ: "Akjoujt Apt",
    AJI: "Agri Apt",
    AJH: "Aarhus Rail Station",
    AJF: "Jouf Apt",
    AJA: "Campo Dell Oro Apt",
    AIZ: "Lake Of The Ozarks Apt",
    AIY: "Atlantic City Bader Field",
    AIW: "Ai-Ais Apt",
    AIV: "Aliceville Apt",
    AIU: "Atiu Island Apt",
    AIT: "Aitutaki Apt",
    AIS: "Arorae Island Apt",
    AIR: "Aripuana Apt",
    AIP: "Ailinglapalap Island Apt",
    AIO: "Atlantic Municipal Apt",
    AIN: "Wainwright Municipal Apt",
    AIM: "Ailuk Island Apt",
    AIL: "Ailigandi Apt",
    AIK: "Aiken Apt",
    AII: "Alisabieh Apt",
    AIH: "Aiambak Apt",
    AIG: "Yalinga Apt",
    AIF: "Assis Apt",
    AIE: "Aiome Apt",
    AID: "Anderson Municipal Apt",
    ZZZ: "Sydney Kingsford Smiht Arpt",
    AIC: "Airok Apt",
    AIB: "Anita Bay Apt",
    AIA: "Alliance Municipal Apt",
    ZZV: "Zanesville Municipal Apt",
    ZZU: "Mzuzu Apt",
    AHZ: "Alpe D'Huez Apt",
    AHY: "Ambatolahy Apt",
    ZZN: "Oslo Nationaltheatret Rail Station",
    AHU: "Al Hoceima Apt",
    AHT: "Amchitka Apt",
    AHS: "Ahuas Municipal Apt",
    AHO: "Fertilla Apt",
    AHN: "Athens Municipal Apt",
    AHL: "Aishalton Apt",
    AHI: "Amahai Apt",
    AHH: "Amery Apt",
    AHF: "Arapahoe Apt",
    AHE: "Ahe Apt",
    AHD: "Downtown Arpt",
    AHC: "Herlong Arpt",
    ZYZ: "Berchem Railway Station",
    ZYY: "Marnardal Rail Station",
    AHB: "Abha Apt",
    AHA: "Naha AFB",
    ZYW: "Sandvika Rail Station",
    ZYV: "Vegarshei Rail Station",
    ZYU: "Utrecht Rail Station",
    ZYT: "Maastricht Rail Station",
    ZYS: "Sandejford Rail Station",
    AGZ: "Aggeneys Apt",
    ZYR: "Midi Rail Station",
    ZYQ: "Syracuse NY Railway Station",
    AGY: "Argyle Downs Apt",
    AGX: "Agatti Island Apt",
    ZYP: "Penn Station",
    ZYO: "Roosendaal Rail Station",
    AGW: "Agnew Apt",
    AGV: "Acarigua Municipal Apt",
    ZYN: "N\u00EEmes Railway Station",
    ZYM: "Arnhem Rail Station",
    AGU: "Aguascalientes Apt",
    AGT: "Ciudad Del Este Apt",
    ZYL: "Osmany Apt",
    AGS: "Bush Field",
    AGR: "Kheria Apt",
    AGQ: "Agrinion Apt",
    ZYI: "Zunyi Apt",
    ZYH: "Hague Centraal Rail Station",
    AGP: "Pablo Ruiz Picasso Apt",
    ZYG: "Gjovik Rail Station",
    AGO: "Magnolia Apt",
    AGN: "Angoon Seaplane base",
    ZYE: "Eindhoven Rail Station",
    AGM: "Tasiilaq Apt",
    AGL: "Wanigela Apt",
    AGK: "Kagua Apt",
    AGI: "Wageningen Apt",
    ZYA: "Amsterdam Central Station, NL",
    AGH: "Angelholm Apt",
    AGG: "Angoram Apt",
    AGF: "La Garenne Apt",
    AGE: "Wangerooge Apt",
    AGD: "Anggi Apt",
    AGC: "Allegheny Cty Arpt",
    ZXY: "The Hague Holland Spoor Rail Station",
    AGB: "Augsburg-Muehlhausen Apt",
    ZXX: "Rade Rail Station",
    AGA: "Agadir Apt",
    ZXU: "Rygge Rail Station",
    ZXT: "Zabrat Arpt",
    ZXS: "Exchange Street Railway Stn",
    AFY: "Afyon Apt",
    ZXP: "Perth Railway Station",
    ZXO: "Fauske Rail Station",
    ZXM: "Rognan Rail Station",
    AFT: "Afutara Apt",
    AFS: "Zarafshan Airport",
    AFR: "Afore Apt",
    AFO: "Afton Apt",
    AFN: "Jaffrey Apt",
    ZXE: "Waverley station, Edinburgh, Scotland",
    AFL: "Alta Floresta Apt",
    AFK: "Kondavattavan Tank Arpt",
    AFI: "Amalfi Apt",
    ZXA: "Aberdeen railway station",
    AFF: "USAF Academy",
    AFA: "San Rafael Apt",
    ZWW: "Hampton Newport News Rail Station",
    ZWV: "Glenview Railway Station",
    ZWU: "Union Station",
    ZWT: "Lutherstadt Wittenberg Rail Station",
    ZWS: "Stuttgart Hauptbahnhof",
    AEY: "Akureyri Apt",
    ZWP: "West Palm Beach Railway Stn",
    AEX: "Alexandria Intl Apt",
    AEV: "Ciudad De Valles",
    ZWN: "ZWN",
    ZWM: "Wismar Rail Station",
    AET: "Allakaket Apt",
    ZWL: "Wollaston Lake Apt",
    AES: "Vigra Apt",
    ZWJ: "Haugastol Rail Station",
    AER: "Adler/Sochi Apt",
    ZWI: "Wilmington Railway Station",
    ZWH: "Windhoek Railway Station",
    AEP: "Jorge Newbery Apt",
    AEO: "Aioun El Atrouss Apt",
    ZWE: "Antwerp Central Railway Stn",
    AEM: "Ayeme",
    ZWD: "Warnemuende Rail Station",
    AEL: "Albert Lea Apt",
    AEK: "Aseki Apt",
    ZWC: "Stavanger Railway Station",
    ZWB: "Newport News Williamsburg Rail Station",
    AEI: "Algeciras Arpt",
    ZWA: "Andapa Apt",
    AEH: "Abecher Apt",
    AEG: "Aek Godang Apt",
    AED: "Aleneva Apt",
    AEA: "Abemama Atoll Apt",
    ADZ: "San Andres Island Apt",
    ZVR: "Hannover Hauptbahnhof",
    ADY: "Alldays Apt",
    ADX: "Saint Andrews Apt",
    ADW: "Andrews Air Force Base",
    ADV: "Andover Apt",
    ZVM: "Hannover Messe/Laatzen RR Stn",
    ADU: "Ardabil Apt",
    ADT: "Ada Municipal Apt",
    ADS: "Addison Arpt",
    ZVK: "Savannakhet Apt",
    ZVJ: "Abu Dhabi      Bus Station",
    ADR: "Andrews Apt",
    ADQ: "Kodiak Apt",
    ZVH: "EK BUS STATION",
    ADP: "Anuradhapura Apt",
    ADO: "Andamooka Apt",
    ZVG: "Springvale Apt",
    ADN: "Andes Apt",
    ADM: "Ardmore Apt",
    ZVE: "Union Station",
    ZVD: "Drangedal Rail Station",
    ADL: "Adelaide Intl Apt",
    ADK: "Adak Island Apt",
    ZVB: "Brumunddal Rail Station",
    ADJ: "Civil-Marka Apt",
    ADI: "Arandis Apt",
    ZVA: "Miandrivazo Apt",
    ADH: "Aldan Apt",
    ADG: "Lenawee County Apt",
    ADF: "Adiyaman Apt",
    ADE: "Aden Intl Apt",
    ADD: "Addis Ababa Apt",
    ADC: "Andakombe Apt",
    ADB: "Adnan Menderes Apt",
    ADA: "Adana Apt",
    ACZ: "Zabol Apt",
    ACY: "Atlantic City Intl Apt",
    ACX: "Xingyi Arpt",
    ZUN: "Chicago Union Railway Station",
    ACV: "McKinleyville Apt",
    ACU: "Achutupo Apt",
    ZUM: "Churchill Falls Apt",
    ACT: "Waco Municipal Apt",
    ZUL: "Zilfi Apt",
    ZUK: "Lake Cook Road Railway Station",
    ZUJ: "Zhenjiang Rail Station",
    ACR: "Araracuara Apt",
    ZUH: "Zhuhai Apt",
    ZUG: "Harrisburg Railway Station",
    ACO: "Ascona Apt",
    ACN: "Ciudad Acuna Apt",
    ACM: "Arica Apt",
    ZUE: "Zuenoula Apt",
    ACL: "Aguaclara Apt",
    ZUD: "Ancud Apt",
    ACK: "Nantucket Memorial Apt",
    ZUC: "Ignace Municipal Apt",
    ZUA: "Utica Railway Station",
    ACI: "The Blaye Apt",
    ACH: "Altenrhein Apt",
    ACE: "Lanzarote Apt",
    ACD: "Acandi Municipal Apt",
    ZTZ: "Chemnitz Rail Station",
    ACC: "Kotoka International Airport",
    ZTY: "Boston Backbay Railway Stn",
    ACB: "Bellaire Apt",
    ACA: "General Juan N. Alvarez Apt",
    ZTW: "Tsuen Wan Rail Station",
    ZTV: "Sturtevant Rail Station",
    ZTT: "Cottbus Rail Station",
    ZTS: "Tahsis Water Aerodrome",
    ABZ: "Dyce Apt",
    ZTR: "Zhitomir Apt",
    ABY: "Dougherty County Apt",
    ABX: "Albury Apt",
    ZTO: "Boston South Rail Station",
    ABW: "Abau Apt",
    ZTN: "Trenton Railway Station",
    ABV: "Abuja Apt",
    ABU: "Atambua Apt",
    ZTM: "Shamattawa Apt",
    ABT: "Al-Baha Apt",
    ZTK: "Thun Rail Station",
    ABS: "Abu Simbel Apt",
    ZTJ: "Princeton Jct Rail Station",
    ABR: "Aberdeen Regional Apt",
    ABQ: "Albuquerque Intl Apt",
    ABP: "Atkamba Apt",
    ZTH: "Zakinthos Apt",
    ABO: "Aboisso Apt",
    ZTG: "Aalborg Rail Station",
    ABN: "Albina Apt",
    ZTF: "Stamford Station",
    ZTE: "Rochester NY Rail Station",
    ABM: "Bamaga Apt",
    ZTD: "Schenectady Rail Station",
    ABL: "Ambler Apt",
    ABK: "Kabri Dar Apt",
    ZTB: "Tete A La Baleine Arpt",
    ABJ: "Port Bouet Airport",
    ABI: "Abilene Municipal Apt",
    ZTA: "Tureia Apt",
    ABH: "Alpha Apt",
    ABG: "Abingdon Apt",
    ABF: "Abaiang Apt",
    ABE: "LeHigh Valley Intl Apt",
    ABD: "Abadan Apt",
    ZSZ: "Swakopmund Railway Station",
    ABC: "Los Llanos Apt",
    ABB: "Asaba International Arpt",
    ZSX: "Stralsund Rail Station",
    ABA: "Abakan Apt",
    ZSW: "Seal Cove Apt",
    ZSV: "St Louis Railway Station",
    ZSU: "Dessau Rail Station",
    ZST: "Stewart Apt",
    ZSS: "Sassandra Apt",
    AAZ: "Quetzaltenango",
    ZSR: "Schwerin",
    ZSQ: "Salzwedel Rail Station",
    AAY: "Al Ghaydah Apt",
    AAX: "Araxa Apt",
    ZSO: "Suhl Rail Station",
    AAW: "Abbottabad Apt",
    ZSN: "Stendal Rail Station",
    AAV: "Alah Apt",
    ZSM: "Santa Clara Bus Service",
    AAU: "Asau Apt",
    AAT: "Altay Apt",
    ZSK: "Pasewalk Rail Station",
    AAS: "Apalapsili Apt",
    AAR: "Tirstrup Intl Apt",
    ZSJ: "Sandy Lake Apt",
    ZSI: "Sassnitz Rail Station",
    AAQ: "Anapa Apt",
    ZSG: "Sonneberg Railway Station",
    AAO: "Anaco Apt",
    ZSF: "Springfield Rail Station",
    AAN: "Al Ain Apt",
    AAM: "Mala Mala Arpt",
    ZSE: "Saint Pierre De La Reunion Apt",
    ZSD: "Schwanheide Rail Station",
    AAL: "Aalborg Apt",
    ZSC: "Schoena Rail Station",
    AAK: "Aranuka Apt",
    AAJ: "Awaradam Apt",
    ZSB: "Salzburg \u00D6BB station",
    AAI: "Arraias Apt",
    ZSA: "San Salvador Apt",
    AAH: "Aachen Maastricht Airport",
    AAG: "Avelino Vieira Arpt",
    AAF: "Apalachicola Municipal Apt",
    AAE: "Annaba Apt",
    AAD: "Ad-Dabbah Apt",
    ZRZ: "New Carrollton Railway Station",
    AAC: "Al Arish Apt",
    AAB: "Arrabury Apt",
    ZRX: "Riesa Railway Station",
    AAA: "Anaa Apt",
    ZRV: "Providence Rail Station",
    ZRU: "Boston RT128 Rail Station",
    ZRT: "Hartford CT Railway Station",
    ZRS: "Zurs",
    ZRP: "Pennsylvania Station",
    ZRN: "Nyon Rail Station",
    ZRM: "Sarmi Apt",
    ZRL: "Lancaster PA Railway Station",
    ZRK: "Van Galder Bus Terminal",
    ZRJ: "Round Lake Apt",
    ZRI: "Serui Apt",
    ZRH: "Zurich Apt",
    ZRG: "Bratislava Bus Station",
    ZRF: "Peoria Rockford Bus Terminal",
    ZRD: "Richmond Railway Station",
    ZRB: "Frankfurt HBF Railway Service",
    ZRA: "Atlantic City Railway Station",
    ZQZ: "Zhangjiakou Arpt",
    ZQW: "Zweibruecken Apt",
    ZQV: "Worms Rail Station",
    ZQU: "Wolrsburg",
    ZQS: "Queen Charlotte Island Apt",
    ZQN: "Frankton Apt",
    ZQF: "Trier",
    ZPY: "Siegburg Rail Station",
    ZPS: "ZPS",
    ZPP: "Rail Station Reutlingen",
    ZPO: "Pine House Apt",
    ZPM: "ZPM",
    ZPL: "Recklinghausen Rail Station",
    ZPH: "Zephyrhills Municipal Apt",
    ZPF: "Passau Rail Station",
    ZPE: "Osnabrueck Rail Service",
    ZPD: "Oldenburg",
    ZPC: "Pucon Apt",
    ZPB: "Sachigo Lake Apt",
    ZPA: "Offenburg Rail Station",
    ZOY: "Oberhausen Rail Station",
    ZOX: "Oberammergau Airport",
    ZOV: "Norderstedt",
    ZOS: "Osorno Apt",
    ZOO: "Muehlheim An Der Ruhr Rail Station",
    ZOM: "Minden Rail Station",
    ZOG: "ZOG",
    ZOF: "Ocean Falls Apt",
    ZNZ: "Zanzibar Apt",
    ZNW: "Limburg Rail Station",
    ZNU: "Namu Apt",
    ZNS: "ZNS",
    ZNQ: "ZNQ",
    ZNG: "Negginan Apt",
    ZNE: "Newman Apt",
    ZND: "Zinder Apt",
    ZNC: "Nyac Apt",
    ZNB: "Hamm",
    ZNA: "Harbour Apt",
    ZMZ: "Bjerka Rail Station",
    ZMX: "Tynset Rail Station",
    ZMU: "M\u00FCnchen Hauptbahnhof",
    ZMT: "Masset Apt",
    ZMS: "Florence S.M. Novella Rail Station",
    ZMQ: "Raufoss Rail Station",
    ZMM: "Zamora Apt",
    ZML: "Milwaukee Rail Station",
    ZMJ: "Mergellina Harbour",
    ZMI: "Naples Mergellina Rail Station",
    ZMG: "Magdeburg Rail Station",
    ZMF: "Beverello Harbour",
    ZME: "New York/Newark Metropark Rail Station",
    ZMD: "Sena Madureira Apt",
    ZMB: "Hamburg Hauptbahnhof",
    ZLY: "Albany NY Rail Station",
    ZLX: "Zalengei Arpt",
    ZLU: "Ludwigslust Rail Station",
    ZLT: "La Tabatiere Apt",
    ZLS: "Liverpool Street Station",
    ZLQ: "Zurich Oerlikon Rail Station",
    ZLP: "Zurich HB Rail Station",
    ZLO: "Playa de Oro Apt",
    ZLN: "Le Mans Railway Station",
    ZLM: "Zug Rail Station",
    ZLL: "Zofingen Rail Station",
    ZLK: "Moskovsky Rail Station",
    ZLJ: "Yverdon-les-Bains Rail Station",
    ZLI: "Winterthur Rail Station",
    ZLH: "Wil Rail Station",
    ZLG: "El Gouera Apt",
    ZLF: "Wettingen Rail Station",
    ZLE: "Wengen Rail Station",
    ZLD: "Weinfelden Rail Station",
    ZLC: "Waedenswil Rail Station",
    ZLB: "Visp Rail Station",
    ZLA: "Villars sur Ollon Rail Station",
    ZKZ: "Vevey Rail Station",
    ZKX: "Uzwil Rail Station",
    ZKW: "Wetzikon Rail Station",
    ZKV: "Thalwil Rail Station",
    ZKU: "Sursee Rail Station",
    ZKS: "Solothurn Rail Station",
    ZKP: "Kasompe Apt",
    ZKO: "Sierre Siders Rail Station",
    ZKN: "Skive Rail Station",
    ZKM: "Sette Cama Apt",
    ZKL: "Steenkool Apt",
    ZKK: "Schwyz Rail Station",
    ZKJ: "Schaffhausen Rail Station",
    ZKH: "St Moritz Rail Station",
    ZKG: "Kegaska Arpt",
    ZKF: "St Margrethen Rail Station",
    ZKE: "Kaschechewan Apt",
    ZKD: "Leningradskiy Rail Station",
    ZKC: "Sarnen Rail Station",
    ZKB: "Kasaba Bay Apt",
    ZKA: "Sargans Rail Station",
    ZJZ: "Rorschach Rail Station",
    ZJW: "Rapperswil Rail Station",
    ZJV: "Pontresina Rail Station",
    ZJU: "Olten Rail Station",
    ZJS: "Jena Rail Station",
    ZJQ: "Morges Rail Station",
    ZJP: "Montreux",
    ZJO: "San Jose Bus Service",
    ZJN: "Swan River Municipal Apt",
    ZJM: "Martigny Rail Station",
    ZJL: "Lyss Rail Station",
    ZJI: "Locarno Rail Station",
    ZJH: "Aarhus Rail Station",
    ZJG: "Jenpeg Apt",
    ZJF: "Jebel Ali Free Zone Apt",
    ZJC: "Lenzburg Rail Station",
    ZJA: "Le Locle Rail Station",
    ZIX: "Zhigansk Airport",
    ZIV: "Inverness railway station, Scotland",
    ZIT: "Zittau Rail Station",
    ZIR: "Randers Rail Station",
    ZIO: "Solingen Rail Station",
    ZIN: "Interlaken Ost Rail Station",
    ZIM: "Odense Bus Service",
    ZIJ: "Sjaelland Rail Station",
    ZIH: "Zihuatanejo Intl Apt",
    ZIG: "Ziguinchor Apt",
    ZIF: "Fallowfield Railway Station",
    ZID: "Aarhus Bus Station",
    ZIC: "Victoria Apt",
    ZIB: "Nyborg Rail Station",
    ZHZ: "Leipzig/Halle Rail Station",
    ZHY: "Zhongwei Airport",
    ZHW: "Langenthal Rail Station",
    ZHV: "La Chaux de Fonds Rail Station",
    ZHU: "Kreuzlingen Rail Station",
    ZHT: "Geneva Railway Station",
    ZHS: "Klosters Rail Station",
    ZHR: "Kandersteg Rail Station",
    ZHP: "High Prairie Apt",
    ZHO: "Houston Bus Station",
    ZHN: "Herzogenbuchsee Rail Stn",
    ZHM: "Shamshernagar Apt",
    ZHL: "Heerbrugg Rail Station",
    ZHK: "Gstaad Rail Station",
    ZHJ: "Grindelwald Rail Station",
    ZHI: "Grenchen Sued Rail Stn",
    ZHH: "Gossau SG Rail Station",
    ZHG: "Glarus Rail Station",
    ZHF: "Fribourg Rail Station",
    ZHE: "Frauenfeld Rail Station",
    ZHD: "Fluelen Rail Station",
    ZHC: "Philadelphia North Rail Station",
    ZHB: "Engelberg Rail Station",
    ZHA: "Zhanjiang Apt",
    ZGX: "Viborg Rail Station",
    ZGW: "Greifswald Rail Station",
    ZGU: "Gaua Apt",
    ZGS: "Gethsemani Municipal Apt",
    ZGR: "Little Grand Rapids Apt",
    ZGO: "Gotha Rail Station",
    ZGM: "Ngoma Apt",
    ZGL: "South Galway Apt",
    ZGK: "ZGK",
    ZGJ: "ZGJ",
    ZGI: "Gods River Apt",
    ZGH: "Copenhagen Rail Station",
    ZGG: "Glasgow, Scotland",
    ZGF: "Grand Forks Apt",
    ZGE: "Goerlitz Rail Station",
    ZGD: "New London Rail Station",
    ZGC: "Zhongchuan-Lanzhou West Apt",
    ZGA: "Gera Hbf Rail Station",
    ZFZ: "Buffalo Railway Station",
    ZFV: "30th Street Station",
    ZFT: "Fort Lauderdale Rail Station",
    ZFR: "Frankfurt (Oder) Rail Station",
    ZFQ: "Bordeaux St Jean Railway Station",
    ZFN: "Fort Norman Apt",
    ZFM: "Fort McPherson Apt",
    ZFL: "South Trout Apt",
    ZFJ: "Rennes Railway Station",
    ZFI: "Chesterfield Bus Station",
    ZFD: "Fond du Lac Apt",
    ZFB: "Old Fort Bay Apt",
    ZFA: "Faro Apt",
    ZEY: "Hagen Hbf Rail Station",
    ZEX: "Guetersloh Hbf Rail Station",
    ZEU: "Goettingen Rail Station",
    ZET: "Goslar",
    ZER: "Zero Apt",
    ZEP: "Victoria Rail Station",
    ZEN: "Zenag Apt",
    ZEM: "East Main Apt",
    ZEL: "Bella Bella Municipal Apt",
    ZEJ: "Gelsenkirchen Hbf Rail Station",
    ZEI: "ZEI",
    ZEG: "Senggo Apt",
    ZEE: "Fulda Rail Station",
    ZEC: "Secunda Apt",
    ZDZ: "Einsiedeln Rail Station",
    ZDX: "Dietikon Rail Station",
    ZDW: "Delemont Rail Station",
    ZDV: "Davos Dorf Rail Station",
    ZDU: "Dundee railway station, Scotland",
    ZDT: "Chur Rail Station",
    ZDS: "Chiasso Rail Station",
    ZDR: "Chateau d Oex Rail Station",
    ZDQ: "Champery Rail Station",
    ZDP: "Burgdorf Rail Station",
    ZDO: "Buchs SG Rail Station",
    ZDL: "Brig Rail Station",
    ZDK: "Biel/Bienne Rail Station",
    ZDJ: "Bern Railway Station",
    ZDI: "Bellinzona Rail Station",
    ZDH: "Basel SBB station",
    ZDG: "Baden Rail Station",
    ZDE: "Arosa Rail Station",
    ZDD: "Arbon Rail Station",
    ZDC: "Aigle Rail Station",
    ZDA: "Aarau Rail Station",
    ZCZ: "ZCZ",
    ZCU: "She Kou Ferry",
    ZCO: "Temuco Apt",
    ZCN: "ZCN",
    ZCL: "General Leobardo Ruiz Apt",
    ZCC: "ZCC",
    ZCB: "Aschaffenburg Hbf Rail Station",
    ZBY: "Sayaboury Apt",
    ZBW: "Atibaia Arpt",
    ZBV: "Beaver Creek Van Service",
    ZBT: "Aarhus Bus Station",
    ZBR: "Chah-Bahar Apt",
    ZBQ: "Odense Rail Station",
    ZBP: "Pennsylvania Station, Baltimore",
    ZBO: "Bowen Apt",
    ZBM: "Bromont Apt",
    ZBL: "Biloela Apt",
    ZBK: "Zabljak Apt",
    ZBJ: "Fredericia Rail Station",
    ZBH: "Severac le Chateau Rail Station",
    ZBG: "Elblag Airport",
    ZBF: "Bathurst",
    ZBE: "Zabreh Apt",
    ZBD: "Jiang Men Ferry",
    ZBB: "Esjberg Rail Station",
    ZBA: "Basel Badischer Bahnhof",
    ZAZ: "Zaragoza Apt",
    ZAX: "Angermuende Apt",
    ZAU: "Aue Rail Station",
    ZAT: "Zhaotong Apt",
    ZAR: "Zaria Apt",
    ZAQ: "N\u00FCrnberg Hauptbahnhof",
    ZAP: "Appenzell Rail Station",
    ZAO: "Cahors Apt",
    ZAM: "Zamboanga Intl Apt",
    ZAL: "Valdivia Apt",
    ZAJ: "Zaranj Apt",
    ZAH: "Zahedan Apt",
    ZAG: "Pleso Apt",
    ZAF: "Arles Rail Station",
    ZAD: "Zadar Apt",
    ZAC: "York Landing Apt",
    ZAA: "Alice Arm/Silver City Water Aerodrome",
    YZY: "Ganzhou Airport",
    YZX: "Greenwood Apt",
    YZW: "Teslin Apt",
    YZV: "Sept-Iles Apt",
    YZU: "Whitecourt Apt",
    YZT: "Port Hardy Apt",
    YZS: "Coral Harbour Apt",
    YZR: "Sarnia Apt",
    YZP: "Sandspit Apt",
    YZM: "Buchans Apt",
    YZL: "Liard River Apt",
    YZH: "Slave Lake Apt",
    YZG: "Salluit Apt",
    YZF: "Yellowknife Apt",
    YZE: "Manitoulin Apt",
    YZC: "Beatton River Apt",
    YZA: "Ashcroft Rail Station",
    YYZ: "Lester B. Pearson Intl Apt",
    YYY: "Mont Joli",
    YYW: "Armstrong Rail Station",
    YYU: "Kapuskasing Municipal Arpt",
    YYT: "Saint John's Apt",
    YYR: "Goose Bay Apt",
    YYQ: "Churchill Apt",
    YYP: "Yuyao North Rail Station",
    YYN: "Swift Current Apt",
    YYM: "Cowley Apt",
    YYL: "Lynn Lake Rail Station",
    YYJ: "Victoria Intl Apt",
    YYI: "Rivers Rail Station",
    YYH: "Taloyak Municipal Apt",
    YYG: "Charlottetown Apt",
    YYF: "Penticton Apt",
    YYE: "Fort Nelson Apt",
    YYD: "Smithers Apt",
    YYC: "Calgary Intl Apt",
    YYB: "North Bay",
    YYA: "Big Bay Yacht Club Apt",
    YXZ: "Wawa Apt",
    YXY: "Whitehorse Apt",
    YXX: "Abbotsford Municipal Apt",
    YXU: "London Municipal Apt",
    YXT: "Terrace",
    YXS: "Prince George Apt",
    YXR: "Earlton Apt",
    YXQ: "Beaver Creek Apt",
    YXP: "Pangnirtung Apt",
    YXN: "Whale Cove Apt",
    YXL: "Sioux Lookout Arpt",
    YXK: "Rimouski Municipal Arpt",
    YXJ: "Fort Saint John Apt",
    YXI: "Killaloe Apt",
    YXH: "Medicine Hat Apt",
    YXF: "Snake River Apt",
    YXE: "Saskatoon Apt",
    YXD: "Edmonton Municipal Apt",
    YXC: "Cranbrook Municipal Apt",
    YWY: "Wrigley Apt",
    YWS: "Whistler Apt",
    YWR: "White River Rail Station",
    YWQ: "Chute des Passes Apt",
    YWP: "Webequie Apt",
    YWO: "Lupin Apt",
    YWN: "Winisk Apt",
    YWM: "Williams Harbour Apt",
    YWL: "Williams Lake Apt",
    YWK: "Wabush Apt",
    YWJ: "Fort Franklin Apt",
    YWH: "Inner Harbor Sea Plane Arpt",
    YWG: "Winnipeg Intl Apt",
    YWF: "Downtown Waterfront Heliport",
    YWB: "Kangiqsujuaq Municipal Apt",
    YWA: "Petawawa Apt",
    YVZ: "Deer Lake Apt",
    YVV: "Wiarton Apt",
    YVT: "Buffalo Narrows Apt",
    YVS: "Ski Rail Station",
    YVR: "Vancouver Intl Apt",
    YVQ: "Norman Wells Apt",
    YVP: "Kuujjuaq Apt",
    YVO: "Val d'Or Apt",
    YVM: "Qikiqtarjuaq Apt",
    YVH: "Hjerkinn Rail Station",
    YVG: "Vermilion Apt",
    YVE: "Vernon Apt",
    YVD: "Yeva Apt",
    YVC: "La Ronge Apt",
    YVB: "Municipal Bonaventure",
    YVA: "Iconi Apt",
    YUY: "Noranda Apt",
    YUX: "Hall Beach Apt",
    YUT: "Repulse Bay Apt",
    YUQ: "Suzhou Suzhouyuanqu Rail Station",
    YUM: "Yuma Intl Apt",
    YUL: "Dorval Intl Apt",
    YUG: "Yueyanng Rail Station",
    YUF: "Dewline Site Apt",
    YUE: "Yuendumu Apt",
    YUD: "Umiujaq Municipal Apt",
    YUB: "Tuktoyaktuk Apt",
    YTZ: "Toronto Bishop Billy City Arpt",
    YTY: "Yangzhou Taizhou Arpt",
    YTX: "Telegraph Creek Apt",
    YTU: "Tasu Apt",
    YTT: "Tisdale Aerodrome",
    YTS: "Timmns",
    YTR: "Trenton Municipal Arpt",
    YTQ: "Tasiujuaq Apt",
    YTP: "Tofino Sea Plane Base",
    YTO: "Toronto Metropolitan Area",
    YTN: "Riviere-Au-Tonnerre Apt",
    YTM: "YTM",
    YTL: "Big Trout Lake Apt",
    YTK: "Tulugak Apt",
    YTJ: "Terrace Bay Apt",
    YTI: "Triple Island Apt",
    YTH: "Thompson Arpt",
    YTG: "Sullivan Bay Municipal Apt",
    YTF: "Alma Apt",
    YTE: "Cape Dorset Apt",
    YTD: "Thicket Portage Rail Station",
    YTC: "Sturdee Apt",
    YTB: "Hartley Bay Apt",
    YTA: "Pembroke Apt",
    YSZ: "Sainte-Anne-des-Monts Apt",
    YSY: "Sachs Harbour Apt",
    YSX: "Shearwater Apt",
    YSV: "Saglek Apt",
    YSU: "Summerside Apt",
    YST: "Sainte Therese Point Municipal Apt",
    YSS: "Slate Island Apt",
    YSR: "Nanisivik Apt",
    YSQ: "Spring Island Apt",
    YSP: "Marathon Apt",
    YSO: "Postville Aerodrome",
    YSN: "Salmon Arm Municipal Apt",
    YSM: "Fort Smith Apt",
    YSL: "Saint Leonard Apt",
    YSK: "Sanikiluaq Apt",
    YSJ: "Saint John Apt",
    YSI: "San Souci Apt",
    YSH: "Smith Falls Rail Station",
    YSG: "Lutselke Snowdrift Aerodrome",
    YSF: "Stony Rapids Apt",
    YSE: "Swan River Apt",
    YSD: "Suffield Apt",
    YSC: "Sherbrooke Airport",
    YSB: "Sudbury Apt",
    YSA: "Sable Island Apt",
    YRV: "Revelstoke Apt",
    YRT: "Rankin Inlet Apt",
    YRS: "Red Sucker Lake Apt",
    YRR: "Stuart Island Municipal Apt",
    YRQ: "Trois-Rivieres Apt",
    YRO: "Rockcliffe St Arpt",
    YRN: "River's Inlet Apt",
    YRM: "Rocky Mountain House Apt",
    YRL: "Federal Red Lake",
    YRJ: "Roberval Apt",
    YRI: "Riviere Du Loup Arpt",
    YRG: "Rigolet Aerodrome",
    YRF: "Cartwright Apt",
    YRE: "Resolution Island Apt",
    YRD: "Dean River Municipal Apt",
    YRB: "Resolute Bay Apt",
    YRA: "Rae Lakes Aerodrome",
    YQZ: "Quesnel Apt",
    YQY: "Sydney Apt",
    YQX: "Gander Intl Apt",
    YQW: "North Battleford Apt",
    YQV: "Yorkton Apt",
    YQU: "Grande Prairie Apt",
    YQT: "Thunder Bay Apt",
    YQS: "Saint Thomas Apt",
    YQR: "Regina Apt",
    YQQ: "Comox",
    YQN: "Nakina Rail Station",
    YQM: "Moncton Apt",
    YQL: "Lethbridge Apt",
    YQK: "Kenora Apt",
    YQI: "Yarmouth Apt",
    YQH: "Watson Lake Apt",
    YQG: "Windsor Apt",
    YQF: "Red Deer Industrial Apt",
    YQE: "Kimberley Apt",
    YQD: "The Pas Apt",
    YQC: "Quaqtaq Apt",
    YQB: "Quebec Apt",
    YQA: "Muskoka Apt",
    YPZ: "Burns Lake Rail Station",
    YPY: "Fort Chipewyan Apt",
    YPX: "Puvirnituq Apt",
    YPW: "Powell River Apt",
    YPV: "Peawanuck",
    YPT: "Pender Harbor Apt",
    YPS: "Port Hawkesbury Apt",
    YPR: "Digby Island Apt",
    YPQ: "Peterborough Apt",
    YPP: "Pine Point Apt",
    YPO: "Peawanuck Apt",
    YPN: "Port Menier Apt",
    YPM: "Pikangikum Apt",
    YPL: "Pickle Lake Apt",
    YPJ: "Aupaluk Apt",
    YPI: "Port Simpson Apt",
    YPH: "Inukjuak Apt",
    YPG: "Portage La Prairie Rail Station",
    YPF: "Esquimalt Rail Station",
    YPE: "Peace River Apt",
    YPD: "Parry Sound Municipal Arpt",
    YPC: "Paulatuk Apt",
    YPB: "Port Alberni Apt",
    YPA: "Prince Albert Apt",
    YOY: "Valcartier Apt",
    YOW: "McDonald Cartier Intl Apt",
    YOT: "Yotvata Apt",
    YOS: "Owen Sound Apt",
    YOP: "Rainbow Lake Apt",
    YOO: "Oshawa Municipal Arpt",
    YOL: "Yola Apt",
    YOK: "Yokohama Apt",
    YOJ: "High Level Apt",
    YOH: "Oxford House Apt",
    YOG: "Ogoki Apt",
    YOE: "Falher Apt",
    YOD: "Cold Lake Regional Apt",
    YOC: "Old Crow Municipal Apt",
    YOA: "Ekati Apt",
    YNZ: "Yancheng Apt",
    YNT: "Laishan Apt",
    YNS: "Nemiscau Apt",
    YNR: "Arnes Aerodrome",
    YNP: "Natuashish Arpt",
    YNO: "North Spirit Lake Apt",
    YNN: "Yandi Apt",
    YNM: "Matagami Apt",
    YNL: "Points North Landing Aerodrome",
    YNK: "Nootka Sound Apt",
    YNJ: "Yanji Apt",
    YNI: "Nitchequon Apt",
    YNH: "Hudson Hope Apt",
    YNG: "Youngstown Municipal Apt",
    YNF: "Corner Brook Apt",
    YNE: "Norway House Apt",
    YND: "Gatineau Apt",
    YNC: "Wemindji Apt",
    YNB: "Yanbu Apt",
    YNA: "Natashquan Apt",
    YMY: "Montreal Downtown Central Rail Station",
    YMX: "Mirabel Intl Apt",
    YMW: "Maniwaki Apt",
    YMT: "Chapais Apt",
    YMS: "Yurimaguas Municipal Apt",
    YMR: "Merry Island Apt",
    YMQ: "Montreal Metropolitan Area",
    YMO: "Moosonee Arpt",
    YMN: "Makkovik Municipal Apt",
    YMM: "Fort McMurray Apt",
    YML: "Murray Bay Apt",
    YMK: "Mys Kamenny Airport",
    YMJ: "Moose Jaw Apt",
    YMI: "Minaki Rail Station",
    YMH: "Mary's Harbour Apt",
    YMG: "Manitouwadge Apt",
    YME: "Matane Apt",
    YMD: "Mould Bay Apt",
    YMC: "Maricourt Airstrip",
    YMB: "Merritt Apt",
    YMA: "Mayo Apt",
    YLY: "Lytton Apt",
    YLX: "Long Point Apt",
    YLW: "Kelowna Apt",
    YLT: "Alert Apt",
    YLS: "Lebel-sur-Quevillon Aerodrome",
    YLR: "Leaf Rapids Apt",
    YLQ: "La Tuque Rail Station",
    YLP: "Mingan Apt",
    YLO: "Shilo Apt",
    YLN: "Yilan Apt",
    YLM: "Clinton Creek Apt",
    YLL: "Lloydminster Apt",
    YLJ: "Meadow Lake Apt",
    YLI: "Ylivieska Apt",
    YLH: "Lansdowne House Apt",
    YLG: "Yalgoo Apt",
    YLE: "Wha Ti Lac La Martre Aerodrome",
    YLD: "Chapleau Rail Station",
    YLC: "Kimmirut/Lake Harbour Apt",
    YLB: "Lac La Biche Apt",
    YLA: "Langara Apt",
    YKZ: "Buttonville Municipal Airport",
    YKY: "Kindersley Apt",
    YKX: "Kirkland Lake Apt",
    YKU: "Chisasibi Aerodrome",
    YKT: "Klemtu Apt",
    YKS: "Yakutsk Apt",
    YKQ: "Waskaganish Apt",
    YKN: "Chan Gurney Municipal Apt",
    YKM: "Yakima Air Terminal",
    YKL: "Schefferville Apt",
    YKK: "Kitkatla Apt",
    YKJ: "Key Lake Apt",
    YKG: "Kangirsuk Apt",
    YKF: "Toronto Region of Waterloo Intl",
    YKE: "Knee Lake Apt",
    YKD: "Kincardine Airport",
    YKC: "Collins Bay Apt",
    YKA: "Kamloops Apt",
    YJW: "Jerez Rail Station",
    YJV: "Valencia Rail Station",
    YJT: "Stephenville Apt",
    YJR: "Vigo Rail Station",
    YJP: "Fort Providence Apt",
    YJO: "Johnny Mountain Apt",
    YJN: "Saint Jean Apt",
    YJM: "Malaga Rail Station",
    YJL: "Santander Rail Station",
    YJI: "Bilbao Abando Rail Station",
    YJH: "San Sebastian Rail Station",
    YJG: "Granada Rail Stn",
    YJF: "Fort Liard Apt",
    YJE: "Alicante Rail Stn",
    YJD: "Barcelona Franca Rail Stn",
    YJC: "A Coruna Rail Stn",
    YJB: "Barcelona Sants Rail Stn",
    YJA: "Jasper Hinton Apt",
    YIW: "Yiwu Apt",
    YIV: "Island Lake/Garden Hill Apt",
    YIP: "Willow Run Arpt",
    YIO: "Pond Inlet Apt",
    YIN: "Yining Apt",
    YIK: "Ivujivik Municipal Apt",
    YIH: "Yichang Apt",
    YIG: "Big Bay Marina Apt",
    YIF: "Pakuashipi Apt",
    YIC: "Mingyueshan Airport",
    YIB: "Atikokan Municipal Apt",
    YHZ: "Halifax Intl Apt",
    YHY: "Hay River Apt",
    YHU: "St Hubert Arpt",
    YHT: "Haines Junction Apt",
    YHS: "Sechelt Arpt",
    YHR: "Chevery Apt",
    YHP: "Poplar Hill Apt",
    YHO: "Hopedale Apt",
    YHN: "Hornepayne Rail Station",
    YHM: "Hamilton Apt",
    YHK: "Gjoa Haven Apt",
    YHI: "Holman Island Apt",
    YHH: "Harbor Airport",
    YHG: "Charlottetown Aerodrome",
    YHF: "Hearst Municipal Arpt",
    YHE: "Hope Arpt",
    YHD: "Dryden Municipal Apt",
    YHC: "Hakai Pass Apt",
    YHB: "Hudson Bay Rail Station",
    YHA: "Port Hope Simpson Aerodrome",
    YGZ: "Grise Fiord Apt",
    YGX: "Gillam Rail Station",
    YGW: "Kuujjuarapik Apt",
    YGV: "Havre Saint Pierre Apt",
    YGT: "Igloolik Apt",
    YGS: "Germansen Apt",
    YGR: "Iles de la Madeleine Apt",
    YGQ: "Geraldton Apt",
    YGP: "Gaspe Apt",
    YGO: "Gods Lake Narrows Apt",
    YGN: "Greenway Sound Apt",
    YGM: "Gimli Industrial Park Apt",
    YGL: "Riviere Apt",
    YGK: "Norman Rogers Apt",
    YGJ: "Yonago Apt",
    YGH: "Fort Good Hope Apt",
    YGG: "Ganges Harbor Apt",
    YGE: "Gorge Harbor Apt",
    YGC: "Grande Cache Aerodrome",
    YGB: "Gillies Bay Municipal Apt",
    YGA: "Gagnon Apt",
    YFX: "Fox Harbour Aerodrome",
    YFS: "Fort Simpson Apt",
    YFR: "Fort Resolution Apt",
    YFO: "Flin Flon Apt",
    YFJ: "Snare Lake Apt",
    YFH: "Fort Hope Apt",
    YFE: "Forestville Apt",
    YFC: "Fredericton Apt",
    YFB: "Iqaluit Apt",
    YFA: "Fort Albany Apt",
    YEY: "Amos Rail Station",
    YEV: "Inuvik Apt",
    YEU: "Eureka Apt",
    YET: "Edson Arpt",
    YER: "Fort Severn Apt",
    YEQ: "Yenkis Apt",
    YEP: "Estevan Point Apt",
    YEO: "Yeovilton Apt",
    YEN: "Estevan Apt",
    YEM: "Manitowaning Aerodrome",
    YEL: "Elliot Lake Municipal Apt",
    YEK: "Arviat Apt",
    YEI: "Yenisehr Apt",
    YEH: "Asaloyeh Arpt",
    YEG: "Edmonton Intl Apt",
    YED: "CFB Namao Heliport",
    YEC: "Yecheon Apt",
    YEB: "Bar River Arpt",
    YEA: "Edmonton",
    YDY: "Valdepenas",
    YDX: "Doc Creek Apt",
    YDW: "Obre Lake Apt",
    YDV: "Bloodvein Apt",
    YDU: "Kasba Lake Apt",
    YDT: "Boundary Bay Arpt",
    YDS: "Desolation Sound Apt",
    YDR: "Broadview Apt",
    YDQ: "Dawson Creek Apt",
    YDP: "Nain Apt",
    YDO: "Saint Methode Apt",
    YDN: "Dauphin Airport",
    YDL: "Dease Lake Municipal Apt",
    YDK: "Main Duck Island Apt",
    YDJ: "Hatchett Lake Apt",
    YDH: "Daniels Harbour Apt",
    YDG: "Digby Aerodrome",
    YDF: "Deer Lake Apt",
    YDE: "Paradise River Aerodrome",
    YDC: "Drayton Village Apt",
    YDB: "Burwash Landings Apt",
    YDA: "Dawson City Apt",
    YCZ: "Fairmount Springs Apt",
    YCY: "Clyde River Apt",
    YCX: "Gagetown Apt",
    YCW: "Chilliwack Arpt",
    YCV: "Cartierville Apt",
    YCU: "Yuncheng apt",
    YCT: "Coronation Apt",
    YCS: "Chesterfield Inlet Apt",
    YCR: "Charlie Sinclair Memorial Apt",
    YCQ: "Chetwynd Apt",
    YCP: "Co-Op Point Apt",
    YCO: "Kuglutuk Coppermine Apt",
    YCN: "Cochrane Rail Station",
    YCM: "St Catharines Rail Station",
    YCL: "Charlo Municipal Arpt",
    YCK: "Colville Lake Aerodrome",
    YCJ: "Cape Saint James Apt",
    YCI: "Caribou Island Apt",
    YCH: "Miramichi Apt",
    YCG: "Castlegar Apt",
    YCF: "Cortes Bay Airport",
    YCE: "Centralia Apt",
    YCD: "Cassidy Apt",
    YCC: "Cornwall Regional Arpt",
    YCB: "Cambridge Bay Apt",
    YCA: "Courtenay Rail Station",
    YBZ: "Toronto Downtown Rail Station",
    YBY: "Bonnyville Apt",
    YBX: "Blanc Sablon Apt",
    YBW: "Bedwell Harbor Apt",
    YBV: "Berens River Apt",
    YBT: "Brochet Apt",
    YBS: "Opapamiska Lake Apt",
    YBR: "Brandon Municipal Arpt",
    YBQ: "Tadoule Lake Apt",
    YBP: "Yibin Apt",
    YBO: "Bobquinn Lake Apt",
    YBN: "Borden Apt",
    YBM: "Bronson Creek Apt",
    YBL: "Campbell River Municipal Apt",
    YBK: "Baker Lake Apt",
    YBI: "Black Tickle Aerodrome",
    YBH: "Bull Harbour Apt",
    YBG: "Bagotville Apt",
    YBF: "Bamfield Apt",
    YBE: "Uranium City Apt",
    YBD: "New Westminster Apt",
    YBC: "Baie Comeau Apt",
    YBB: "Pelly Bay Kugaarak Apt",
    YBA: "Banff Apt",
    YAZ: "Tofino Municipal Apt",
    YAY: "Saint Anthony Apt",
    YAX: "Angling Lake Apt",
    YAW: "Canadian Force Base Shearwater",
    YAV: "Miner's Bay Apt",
    YAU: "Liverpool Apt",
    YAT: "Attawapiskat Apt",
    YAS: "Yasawa Island Apt",
    YAR: "Lagrande Apt",
    YAQ: "Maple Bay Apt",
    YAP: "Yap Apt",
    YAO: "Yaounde Apt",
    YAN: "Yangambi Apt",
    YAM: "Sault Sainte Marie Apt",
    YAL: "Alert Bay Municipal Apt",
    YAK: "Yakutat Apt",
    YAJ: "Lyall Harbour Apt",
    YAI: "Chillan Apt",
    YAH: "Lagrande 4 Apt",
    YAG: "Fort Frances Municipal Apt",
    YAF: "Asbestos Hill Aerodrome",
    YAE: "Alta Lake Apt",
    YAD: "Moose Lake Apt",
    YAC: "Cat Lake Apt",
    YAB: "Arctic Bay Municipal Apt",
    YAA: "Anaheim Lake Municipal Apt",
    XZZ: "Zaragoza Delicias Rail Station",
    XZV: "Toulon/Hyeres Rail Station",
    XZT: "Trondheim Central Station",
    XZP: "Off Line Point",
    XZO: "Oslo Central Station",
    XZN: "Avignon TGV Railway Station",
    XZL: "Edmonton Rail Station",
    XZK: "Amherst Rail Station",
    XZI: "Metz/Nancy Lorraine TGV Rail Stn",
    XZF: "Dammam Port",
    XZD: "Kongsvinger Rail Station",
    XZC: "Glencoe Rail Station",
    XZB: "Casselman Rail Station",
    XZA: "Zabre Apt",
    XYZ: "Harnosand Rail Station",
    XYY: "Arvika Rail Station",
    XYX: "Sala Rail Station",
    XYW: "Xinyang East Rail Station",
    XYV: "Lyon Satolas Rail Station",
    XYU: "Solvesborg Rail Station",
    XYR: "Yellow River Apt",
    XYQ: "Angelholm Railway Station",
    XYP: "Avesta Krylbo Rail Station",
    XYO: "Karlshamn Rail Station",
    XYN: "Kristinehamn Rail Station",
    XYM: "Falkenberg Rail Station",
    XYL: "Lyon Perrache Rail Station",
    XYK: "Norrkoping Railway Service",
    XYJ: "Prague Holesovice Rail Station",
    XYI: "Flen Rail Station",
    XYH: "Helsingborg Railway Station",
    XYG: "Prague Main Rail Station",
    XYF: "Falkoping Rail Station",
    XYE: "Ye Apt",
    XYD: "Lyon Part-Dieu Railway Station",
    XYC: "Herrljunga Rail Station",
    XYB: "Borl\u00E4nge Railway Station",
    XYA: "Yandina Apt",
    XXZ: "Sundsvall Railway Station",
    XXY: "Ronneby Karlskrona Rail Station",
    XXV: "Kumla Rail Station",
    XXU: "Hedemora Rail Station",
    XXT: "Arboga Rail Station",
    XXS: "Skiplane Scenic Apt",
    XXR: "Al Rail Station",
    XXQ: "Budapest Keleti Rail Station",
    XXO: "Leksand Rail Station",
    XXN: "Kil Rail Station",
    XXM: "Mjolby Rail Station",
    XXL: "Lillehammer Railway Station",
    XXK: "Katrineholm C Rail Station",
    XXJ: "Budapest Deli Rail Station",
    XXH: "Helicopter Scenic Apt",
    XXG: "Verdal Rail Station",
    XXF: "Scenic Flight Apt",
    XXE: "Vennesla Rail Station",
    XXD: "Degerfors Rail Station",
    XXC: "Bo Rail Station",
    XXA: "Alvesta Railway Service",
    XWZ: "Nykoping C Rail Station",
    XWY: "Wyoming Rail Station",
    XWX: "Nassjo Rail Station",
    XWW: "Vienna Westbahnhof Rail Station",
    XWV: "Varberg Rail Station",
    XWT: "Vienna Mitte CAT Rail Station",
    XWS: "Swindon Rail Station",
    XWR: "\u00D6rebro Railway Station",
    XWQ: "Enkoping Rail Station",
    XWP: "Hassleholm Rail Station",
    XWO: "Woking Rail Station",
    XWN: "Warrington B Q Rail Station",
    XWM: "Hallsberg Rail Station",
    XWL: "Lund",
    XWK: "Karlskrona Railway Station",
    XWI: "Wigan North Western Rail Stn",
    XWH: "Stroke on Trent Rail Station",
    XWG: "Strasbourg Rail Station",
    XWF: "Falun Railway Service",
    XWE: "Wellingborough Rail Station",
    XWD: "Wakefield Westgate Rail Station",
    XWC: "Vienna Hauptbahnhof Rail Station",
    XWB: "Stirling Rail Station",
    XWA: "Watford Rail Station",
    XVY: "Venice Mestre Rail Station",
    XVX: "Vejle Rail Station",
    XVW: "Belleville Rail Station",
    XVV: "Belleville Rail Station",
    XVU: "Durham Rail Station",
    XVS: "Valenciennes Rail Station",
    XVQ: "Venice Santa Lucia Rail Station",
    XVL: "Vinh Long Apt",
    XVK: "Voss Rail Station",
    XVJ: "Stevenage Rail Station",
    XVH: "Peterborough Rail Station",
    XVG: "Darlington Rail Station",
    XVE: "Versailles",
    XVC: "Crewe Rail Station",
    XVB: "Stafford Rail Station",
    XVA: "Stockport Rail Station",
    XUZ: "Xuzhou Apt",
    XUX: "Ustaoset Rail Station",
    XUW: "Storen Rail Station",
    XUV: "Storekvina Rail Station",
    XUU: "Stjordal Rail Station",
    XUT: "Murcia Rail Station",
    XUS: "Snartemo Rail Station",
    XUR: "Skoppum Rail Station",
    XUQ: "Ringebu Rail Station",
    XUO: "Nordagutu Rail Station",
    XUM: "Neslandsvatn Rail Station",
    XUL: "Nesbyen Rail Station",
    XUK: "Xuzhou Dong Rail Station",
    XUJ: "Moelv Rail Station",
    XUI: "Lysaker Rail Station",
    XUH: "Levanger Rail Station",
    XUG: "Holmestrand Railway",
    XUF: "Cartagena Rail Station",
    XUE: "Heimdal Rail Station",
    XUC: "Elverum Rail Station",
    XUB: "Berkak Rail Station",
    XUA: "Huesca Rail Station",
    XTY: "Strathroy Rail Station",
    XTR: "Tara Apt",
    XTQ: "Xiantao West Railway Station",
    XTO: "Taroom Apt",
    XTL: "Tadoule Lake Arpt",
    XTK: "Thirsk Rail Station",
    XTJ: "Toledo Rail Station",
    XTI: "Madrid Chamartin Rail Station",
    XTG: "Thargomindah Apt",
    XSS: "Soissons Railway Station",
    XSR: "Salisbury Rail Station",
    XSP: "Seletar Apt",
    XSO: "Siocon Apt",
    XSM: "St Marys Apt",
    XSI: "South Indian Lake Apt",
    XSH: "Tours Saint-Pierre-des-Corps Railway Station",
    XSE: "Sebba Apt",
    XSD: "Test Range",
    XSC: "South Caicos Municipal Apt",
    XSA: "Sinoe AFC",
    XRY: "La Parra Apt",
    XRU: "Rugby Rail Station",
    XRR: "Ross River Apt",
    XRP: "Riviere-A-Pierre Rail Station",
    XRL: "Mediopadana Rail Station",
    XRK: "Moscow Paveletsky Rail Station",
    XRJ: "Terminal Railway Station",
    XRG: "Rugeley Rail Station",
    XRF: "Marseille Railway Station",
    XRE: "Reading Rail Station",
    XRD: "Egersund Rail Station",
    XRC: "Runcorn Rail Station",
    XQW: "Motherwell Rail Station",
    XQU: "Qualicum Apt",
    XQT: "Lichfield Rail Station",
    XQP: "Quepos Apt",
    XQM: "Market Harborough Rail Station",
    XQL: "Lancaster Rail Station",
    XQI: "Loughborough Rail Station",
    XQH: "Derby Rail Station",
    XQG: "Berwick-Upon-Tweed Rail Station",
    XQE: "Ebbsfleet Kent Rail Station",
    XQD: "Bedford Rail Station",
    XQB: "Basingstoke Rail Station",
    XQA: "Seville Rail Stn",
    XPZ: "Harbourarpt",
    XPX: "Pointe-aux-Trembles Rail Station",
    XPW: "Didcot Parkway Rail Station",
    XPU: "West Kuparuk Apt",
    XPT: "Preston Rail Station",
    XPR: "Pine Ridge Apt",
    XPP: "Poplar River Apt",
    XPN: "Brampton Rail Station",
    XPL: "Comayagua Apt",
    XPK: "Pukatawagan Rail Station",
    XPJ: "Montpellier Railway Station",
    XPI: "Pikangikum",
    XPH: "Port Hope Rail Station",
    XPG: "Gare du Nord Railway Station",
    XPF: "Penrith Rail Station",
    XPB: "Parksville Rail Station",
    XPA: "Pama Apt",
    XOV: "St Anton Bus Station",
    XOU: "Segovia Guiomar Rail Station",
    XOR: "Otta Rail Station",
    XOQ: "Sira Rail Station",
    XOP: "Poitiers Railway Station",
    XON: "Carleton Rail Station",
    XOL: "Myrdal Rail Station",
    XOK: "Oakville Rail Station",
    XOJ: "Cordoba Rail Station",
    XOF: "London Stratford Intl Rail Station",
    XOD: "Oppdal Rail Station",
    XOC: "Atocha Railway St.",
    XOB: "Bryne Rail Station",
    XNV: "Nuneaton Rail Station",
    XNU: "Nouna Apt",
    XNT: "Xingtai Apt",
    XNO: "Northallerton Rail Station",
    XNN: "Xining Apt",
    XNM: "Nottingham Rail Station",
    XNK: "Newark North Gate Rail Station",
    XNG: "Quang Ngai Apt",
    XNE: "Newport Rail Station",
    XND: "Drammen Rail Station",
    XNC: "Milan Cadorna Rail Station",
    XNB: "Travel Mall Ey Bus Station",
    XNA: "Northwest Arkansas Regional Apt",
    XMZ: "Macclesfield Rail Station",
    XMY: "Yam Island Apt",
    XMS: "Macas Municipal Apt",
    XMP: "MacMillan Pass Apt",
    XMN: "Xiamen Intl Apt",
    XML: "Minlaton Apt",
    XMI: "Masasi Apt",
    XMH: "Manihi Apt",
    XMG: "Mahendranagar Apt",
    XMD: "Madison Municipal Apt",
    XMC: "Mallacoota Apt",
    XMB: "M'Bahiakro Apt",
    XMA: "Maramag Apt",
    XLZ: "Truro Rail Station",
    XLY: "Aldershot Rail Station",
    XLW: "Lemwerder Apt",
    XLV: "Niagara Falls Rail Station",
    XLU: "Leo Apt",
    XLS: "St Louis Airport",
    XLQ: "Guilwood Station",
    XLP: "Matapedia Rail Station",
    XLO: "Long Xuyen Apt",
    XLM: "Saint Lambert Rail Station",
    XLK: "Levis Rail Station",
    XLJ: "Quebec City Rail Station",
    XLF: "Leaf Bay Apt",
    XLE: "Lens",
    XLB: "Lac Brochet Apt",
    XKZ: "Vinstra Rail Station",
    XKY: "Kaya Apt",
    XKW: "Tonsberg Rail Station",
    XKV: "Sackville Rail Station",
    XKU: "Kusadasi",
    XKS: "Kasabonika Apt",
    XKR: "Kristiansand Rail Station",
    XKQ: "Sarpsborg Rail Station",
    XKP: "Porsgrunn Rail Station",
    XKO: "Kemano Apt",
    XKN: "Finse Rail Station",
    XKM: "Moss Rail Station",
    XKL: "KL Sentral Railway Station",
    XKK: "Larvik Rail Station",
    XKJ: "Steinkjer Rail Station",
    XKI: "Lillestrom Rail Station",
    XKH: "Xieng Khouang Apt",
    XKG: "Grong Rail Station",
    XKF: "Fredrikstad Rail Station",
    XKE: "Rena Rail Station",
    XKD: "Halden Rail Station",
    XKC: "Sandnes Rail Station",
    XKB: "Kongsberg Rail Station",
    XKA: "Kantchari Apt",
    XJY: "Paris Massy TGV Rail Station",
    XJW: "Xiaogan North Railway Station",
    XJV: "Ostrava Main Rail Station",
    XJT: "Tours Rail Station",
    XJS: "Kitzbuehel Rail Station",
    XJQ: "Jonquiere Rail Station",
    XJP: "Pardubice Main Rail Station",
    XJO: "Soria Bus Station",
    XJN: "Valladolid Bus Station",
    XJL: "Joliette Rail Station",
    XJJ: "Albacete Bus Station",
    XJI: "Ciudad Real Rail Station",
    XJG: "Darwin Railway Station",
    XJF: "Falun Bus Service",
    XJE: "Vojens Rail Station",
    XJC: "Olomouc Main Rail Station",
    XJB: "Barcelona Bus Station",
    XJA: "Alvdal Rail Station",
    XIZ: "Reims Champagne-Ardenne TGV Rail Stn",
    XIY: "Xi An Xianyang Apt",
    XIX: "Verona Porta Nuova Rail Station",
    XIV: "Valladolid Campo Grande Rail Station",
    XIU: "Erfurt Rail Station",
    XIT: "Leipzig/Halle Rail Station",
    XIR: "Dresden Rail Station",
    XIP: "Woodstock Rail Station",
    XIO: "St Marys Rail Station",
    XIN: "Xingning Apt",
    XIM: "Saint Hyacinthe Rail Station",
    XIL: "Xilinhot Apt",
    XIK: "Milan Central Station Railway",
    XII: "Prescott Rail Station",
    XIG: "Xinguara Apt",
    XIF: "Napanee Rail Station",
    XIE: "Xienglom Apt",
    XID: "Maxville Rail Station",
    XIC: "Xichang Apt",
    XIB: "Ingersoll Rail Station",
    XIA: "Guelph Central Rail Station",
    XHX: "Adelaide Keswick Rail Station",
    XHW: "Alice Springs Railway Station",
    XHU: "Huntingdon Rail Station",
    XHT: "Arna Rail Station",
    XHS: "Chemainus Rail Station",
    XHP: "Paris Gare de l'Est Rail Station",
    XHN: "Liege Guillemins Rail Station",
    XHM: "Georgetown Rail Station",
    XHL: "Nelaug Rail Station",
    XHK: "Valence TGV Railway Station",
    XHJ: "Aachen Rail Station",
    XHF: "Honefoss Rail Station",
    XGZ: "Bregenz Rail Station",
    XGY: "Grimsby Rail Station",
    XGW: "Gananoque Rail Station",
    XGU: "Asker Rail Station",
    XGS: "Gjerstad Rail Station",
    XGR: "Kangiqsualujjuaq Apt",
    XGP: "Dombas Rail Station",
    XGN: "Xangongo Apt",
    XGM: "Grantham Rail Station",
    XGL: "Granville Lake Apt",
    XGK: "Coteau Rail Station",
    XGJ: "Cobourg Rail Station",
    XGI: "Andalsnes Rail Station",
    XGH: "Fl\u00E5m",
    XGG: "Gorom-Gorom Apt",
    XGD: "Arendal Rail Station",
    XGC: "Lund C Rail Station",
    XGB: "Gare Montparnasse Arpt",
    XGA: "Gaoua Apt",
    XFZ: "Quebec Charny Rail Station",
    XFY: "Sainte-Foy Rail Station",
    XFW: "Finkenwerder Apt",
    XFV: "Brantford Rail Station",
    XFU: "Tierp Rail Station",
    XFS: "Alexandria Rail Station",
    XFR: "Malmo South Railway Service",
    XFQ: "Weymont Rail Station",
    XFP: "Malm\u00F6 Central Station",
    XFO: "Taschereau Rail Station",
    XFN: "Xiangfan Apt",
    XFM: "Shawnigan Rail Station",
    XFL: "Shawinigan Rail Station",
    XFK: "Senneterre Rail Station",
    XFJ: "Eskilstuna",
    XFI: "Port-Daniel Rail Station",
    XFH: "Strangnas Rail Station",
    XFG: "Perce Rail Station",
    XFF: "Frethun Railway Station",
    XFE: "Parent Rail Station",
    XFD: "Stratford Rail Station",
    XFC: "Fredericton Junction Rail Station",
    XFA: "Lille Flanders Rail Service",
    XEZ: "Sodertalje S Rail Station",
    XEY: "Newcastle Rail Station",
    XEX: "Aerogare Des Invalides",
    XEW: "Stockholm South / Flemingsberg Station",
    XEV: "Stockholm Central Station, Sweden",
    XES: "Lake Geneva Apt",
    XER: "Strasbourg Bus Service",
    XEN: "Xingcheng Apt",
    XEM: "New Richmond Rail Station",
    XEL: "New Carlisle Rail Station",
    XEK: "Melville Rail Station",
    XEJ: "Langford Rail Station",
    XEH: "Ladysmith Rail Station",
    XEG: "Kingston Rail Station",
    XEF: "Winnipeg Union Rail Station",
    XEE: "Lac Edouard Rail Station",
    XED: "Disneyland Paris Marne/Chessey Rail Stn",
    XEC: "Windsor Rail Station",
    XEA: "Vancouver Rail Station",
    XDZ: "The Pas Rail Station",
    XDY: "Sudbury Junction Rail Station",
    XDX: "Sarnia Rail Station",
    XDW: "Prince Rupert Rail Station",
    XDV: "Prince George Rail Station",
    XDU: "Hervey Rail Station",
    XDS: "Ottawa Rail Station",
    XDQ: "London Rail Station",
    XDP: "Moncton Rail Station",
    XDO: "Grande-Riviere Rail Station",
    XDM: "Drummondville Rail Station",
    XDL: "Chandler Rail Station",
    XDJ: "Djibo Apt",
    XDH: "Jasper Rail Station",
    XDG: "Halifax Rail Station",
    XDE: "Diebougou Apt",
    XDD: "Gaspe Rail Station",
    XDB: "Europe Rail Station",
    XCR: "Vatry Arpt",
    XCO: "Colac Apt",
    XCN: "Coron Apt",
    XCM: "Chatham Airport",
    XCL: "Cluff Lake Aerodrome",
    XCI: "Chambord Rail Station",
    XCH: "Christmas Island Apt",
    XCC: "Le Creusot/Montceau TGV Rail Stn",
    XBW: "Killineq Apt",
    XBR: "Brockville Regional Airport",
    XBO: "Boulsa Apt",
    XBN: "Biniguni Apt",
    XBL: "Buno Bedelle Apt",
    XBJ: "Birjand Apt",
    XBG: "Bogande Apt",
    XBE: "Bearskin Lake Aerodrome",
    XBB: "Blubber Bay Apt",
    XAZ: "Campbellton Rail Station",
    XAY: "Xayabury Apt",
    XAX: "Dorval Rail Station",
    XAW: "Capreol Rail Station",
    XAU: "Saul Apt",
    XAR: "Aribinda Apt",
    XAP: "Chapeco Municipal Apt",
    XAL: "Alamos Apt",
    XAK: "Herning Rail Station",
    XAH: "Silkeborg Rail Station",
    XAD: "Churchill Rail Station",
    WZY: "Nassau SPB",
    WZE: "Yichang Railway Station",
    WYS: "Yellowstone Apt",
    WYN: "Wyndham Apt",
    WYE: "Yengema Apt",
    WYB: "Yes Bay Apt",
    WYA: "Whyalla Apt",
    WXN: "Wanxian Apt",
    WXJ: "Wanning Rail Station",
    WXH: "Tanga Island",
    WXF: "Wether Field Raf",
    WWY: "West Wyalong Apt",
    WWT: "Newtok Apt",
    WWS: "Wildwood Apt",
    WWR: "West Woodward Apt",
    WWP: "Whale Pass Municipal Apt",
    WWN: "Gaviotas",
    WWK: "Boram Apt",
    WWD: "Wildwood Apt",
    WWA: "Wasilla Apt",
    WVV: "Volovan",
    WVN: "Wilhelmshaven Apt",
    WVL: "Waterville Apt",
    WVK: "Manakara Apt",
    WVI: "Watsonville Municipal Apt",
    WVB: "Walvis Bay Apt",
    WUZ: "Wuzhou Apt",
    WUX: "Wuxi Apt",
    WUV: "Wuvulu Island Apt",
    WUU: "Wau Apt",
    WUS: "Wuyishan Apt",
    WUN: "Wiluna Apt",
    WUM: "Wasum Apt",
    WUH: "Wuhan Apt",
    WUG: "Wau Apt",
    WUD: "Wudinna Apt",
    WTZ: "Whitianga Apt",
    WTT: "Wantoat Apt",
    WTS: "Tsiroanomandidy Apt",
    WTR: "White River Apt",
    WTP: "Woitape Apt",
    WTO: "Wotho Island Apt",
    WTL: "Tuntutuliak Municipal Apt",
    WTK: "Noatak Municipal Apt",
    WTE: "Wotje Island Apt",
    WTD: "West End Apt",
    WTB: "Toowoomba Brisbane West Wellcamp Arpt",
    WTA: "Tambohorano Apt",
    WSZ: "Westport Apt",
    WSY: "Airlie Beach Apt",
    WSX: "Westsound Municipal Apt",
    WSU: "Wasu Apt",
    WST: "Westerly Municipal Apt",
    WSS: "Swansea Rail Station",
    WSR: "Wasior Apt",
    WSP: "Waspam Apt",
    WSO: "Washabo Apt",
    WSN: "South Naknek Apt",
    WSM: "Wiseman Apt",
    WSJ: "San Juan Apt",
    WSH: "Brookhaven Apt",
    WSG: "Washington County Arpt",
    WSF: "Sarichef Apt",
    WSB: "Steamboat Bay Apt",
    WSA: "Wasua Apt",
    WRY: "Westray Apt",
    WRW: "Warrawagine Apt",
    WRO: "Strachowice Apt",
    WRL: "Worland Municipal Apt",
    WRH: "Wrench Creek Apt",
    WRG: "Wrangell Apt",
    WRE: "Whangarei Apt",
    WRB: "Robins Airforce Base",
    WRA: "Warder Apt",
    WPW: "Keekorok",
    WPU: "Puerto Williams Apt",
    WPR: "Porvenir Apt",
    WPO: "North Fork Valley Apt",
    WPM: "Wipim Apt",
    WPL: "Powell Lake Apt",
    WPK: "Wrotham Park Apt",
    WPC: "Pincher Creek Apt",
    WPB: "Port Berge Apt",
    WPA: "Puerto Aisen Apt",
    WOW: "Willow Apt",
    WOT: "Wonan Apt",
    WOR: "Ankorefo Apt",
    WOO: "Woodchopper Apt",
    WON: "Wondoola Apt",
    WOL: "Wollongong Apt",
    WOK: "Wonken Apt",
    WOI: "Wologissi Apt",
    WOG: "Woodgreen Apt",
    WOE: "Woensdrecht Apt",
    WOD: "Wood River Apt",
    WOA: "Wonenara Apt",
    WNZ: "Wenzhou Apt",
    WNY: "Wynyard",
    WNU: "Wanuma Apt",
    WNT: "Hierro",
    WNS: "Nawabshah Apt",
    WNR: "Windorah Apt",
    WNQ: "Labamba",
    WNP: "Naga Apt",
    WNN: "Wunnummin Lake Municipal Apt",
    WNE: "Wora-Na-Ye Apt",
    WND: "Windarra Apt",
    WNC: "Nichin Cove Apt",
    WNA: "Napakiak Arpt",
    WMX: "Wamena Apt",
    WMV: "Madirovalo Apt",
    WMR: "Mananara Apt",
    WMP: "Mampikony Apt",
    WMO: "White Mountain Apt",
    WMN: "Maroantsetra Apt",
    WML: "Malaimbandy Apt",
    WMK: "Meyers Chuck Municipal Apt",
    WMI: "Modlin Arpt",
    WMH: "Mountain Home Municipal Apt",
    WME: "Mount Keith Apt",
    WMD: "Mandabe Apt",
    WMC: "Winnemucca Municipal Apt",
    WMB: "Warrnambool Apt",
    WMA: "Mandritsara Apt",
    WLW: "Glenn County Apt",
    WLS: "Wallis Island Apt",
    WLR: "Loring Apt",
    WLP: "West Angelas",
    WLO: "Waterloo Apt",
    WLN: "Little Naukati Apt",
    WLM: "Waltham Apt",
    WLL: "Wollogorang Apt",
    WLK: "Selawik Municipal Apt",
    WLI: "Wenling Railway Station",
    WLH: "Walaha Apt",
    WLG: "Wellington Intl Apt",
    WLD: "Strother Field",
    WLC: "Walcha Apt",
    WLB: "Labouchere Bay Apt",
    WLA: "Wallal Apt",
    WKR: "Walkers Cay Municipal Apt",
    WKP: "Grass Valley",
    WKN: "Wakunai Apt",
    WKM: "Wankie National Park Apt",
    WKL: "Waikoloa Apt",
    WKK: "Aleknagik Municipal Apt",
    WKJ: "Hokkaido Apt",
    WKI: "Hwange Apt",
    WKF: "Waterkloof Airport Fawk",
    WKC: "Connellsville",
    WKB: "Warracknabeal Apt",
    WKA: "Wanaka Apt",
    WJU: "Won-Ju Apt",
    WJR: "Wajir Apt",
    WJF: "General William J. Fox Airfield",
    WJA: "Woja Apt",
    WIX: "Tuxpan",
    WIU: "Witu Apt",
    WIT: "Wittenoom Gorge Apt",
    WIR: "Wairoa Apt",
    WIO: "Wilcannia Apt",
    WIN: "Winton Apt",
    WIL: "Wilson Apt",
    WIK: "Surfdale Apt",
    WIE: "Air Base",
    WID: "Wildenrath Apt",
    WIC: "Wick Apt",
    WHZ: "Wuhan Rail Station",
    WHU: "Wuhu Apt",
    WHT: "Wharton Apt",
    WHS: "Whalsay Apt",
    WHR: "Vail Avon Stolport",
    WHP: "Whiteman Arpt",
    WHO: "Franz Josef Apt",
    WHL: "Welshpool Apt",
    WHK: "Whakatane Apt",
    WHH: "Hiltons Har H",
    WHF: "Wadi Halfa Apt",
    WHD: "Seaplane Base Airpot",
    WHC: "Fassama",
    WGY: "Wagny Apt",
    WGX: "Tremonton",
    WGU: "Wagau Apt",
    WGT: "Wangaratta Apt",
    WGP: "Waingapu Apt",
    WGO: "Winchester Apt",
    WGN: "Waitangi Apt",
    WGE: "Walgett Apt",
    WGC: "Warangal Apt",
    WGB: "Bahawalnagar Apt",
    WGA: "Forest Hill Apt",
    WFK: "Northern Aroostook Regional Apt",
    WFI: "Fianarantsoa Apt",
    WFD: "Woodford Apt",
    WFB: "Ketchikan Harbor Spb",
    WEX: "Wexford Apt",
    WEW: "Wee Waa Apt",
    WET: "Wagethe Apt",
    WES: "Weasua Apt",
    WEP: "Weam Apt",
    WEM: "West Malling Apt",
    WEL: "Welkom Apt",
    WEI: "Weipa Apt",
    WEH: "Weihai Apt",
    WEF: "Weifang Apt",
    WED: "Wedau Apt",
    WEC: "Wenchang Rail Station",
    WEB: "Webequie",
    WEA: "Weatherford Apt",
    WDY: "Kodiak Fss Apt",
    WDR: "Winder Apt",
    WDQ: "Booneville",
    WDO: "Wando",
    WDN: "Waldron Island Apt",
    WDI: "Wondai Apt",
    WDH: "Hosea Kutako Intl Apt",
    WDG: "Woodring Municipal Apt",
    WDB: "Deep Bay Apt",
    WDA: "Wadi Ain Apt",
    WCY: "Alcoota",
    WCR: "Chandalar Apt",
    WCJ: "Caleta Josefina",
    WCH: "Chaiten Apt",
    WCA: "Castro Apt",
    WBX: "Yaquara",
    WBW: "Wilkes Barre Wyoming Valley Arpt",
    WBU: "Boulder Municipal Apt",
    WBR: "Roben-Hood Apt",
    WBQ: "Beaver Apt",
    WBO: "Beroroha Apt",
    WBN: "Woburn Apt",
    WBM: "Wapenamanda Apt",
    WBI: "Broker Inn Apt",
    WBG: "Schleswig-Jaqel Apt",
    WBE: "Bealanana Apt",
    WBD: "Befandriana Apt",
    WBC: "Wapolu Apt",
    WBB: "Stebbins Apt",
    WBA: "Wahai Apt",
    WAZ: "Warwick Apt",
    WAY: "Greene County Apt",
    WAX: "Zwara Apt",
    WAW: "Warsaw Intl Apt",
    WAV: "Wave Hill Apt",
    WAU: "Wauchope Apt",
    WAT: "Waterford Apt",
    WAS: "Washington DC Metropolitan Area",
    WAR: "Waris Apt",
    WAQ: "Antsalova Apt",
    WAP: "Alto Palena Apt",
    WAO: "Wabo Apt",
    WAN: "Waverney Apt",
    WAM: "Ambatondrazaka Apt",
    WAL: "Chincoteague Apt",
    WAK: "Ankazoabo Apt",
    WAJ: "Wawoi Falls Apt",
    WAI: "Antsohihy Apt",
    WAH: "Wahpeton Apt",
    WAG: "Wanganui Apt",
    WAF: "Wana Apt",
    WAE: "Wadi Ad Dawasir Apt",
    WAD: "Andriamena Apt",
    WAC: "Waca Apt",
    WAB: "Wabag Apt",
    WAA: "Wales Municipal Apt",
    VYS: "Peru Apt",
    VYD: "Vryheid Apt",
    VXO: "Vaxjo Railway station",
    VXE: "Sao Vicente Apt",
    VXC: "Lichinga Apt",
    VWB: "Changchun Railway Station",
    VWA: "Shenyangbei Railway Station",
    VVZ: "Illizi Apt",
    VVO: "Vladivostok Apt",
    VVK: "Vastervik Apt",
    VVI: "Viru Viru Intl Apt",
    VVC: "La Vanguardia Apt",
    VVB: "Mahanoro Apt",
    VUS: "Velikij Ustyug Apt",
    VUP: "Valledupar Municipal Apt",
    VTZ: "Vishakhapatnam Apt",
    VTU: "Las Tunas Municipal Apt",
    VTN: "Valentine Apt",
    VTL: "Vittel Apt",
    VTG: "Vung Tau Apt",
    VTF: "Vatulele Apt",
    VTE: "Wattay Apt",
    VTB: "Vitebsk Apt",
    VTA: "Victoria Apt",
    VST: "V\u00E4ster\u00E5s Railway station",
    VSO: "Phuoc Long Apt",
    VSG: "Lugansk Apt",
    VSF: "Hartness State Apt",
    VSE: "Viseu Apt",
    VSA: "Carlos Rovirosa Apt",
    VRY: "Vaeroy Apt",
    VRU: "Vryburg Apt",
    VRS: "Versailles Apt",
    VRO: "Kawama Arpt",
    VRN: "Verona Apt",
    VRL: "Vila Real Apt",
    VRK: "Varkaus Apt",
    VRE: "Vredendal Apt",
    VRC: "Virac Apt",
    VRB: "Vero Beach Municipal Apt",
    VRA: "Juan Gualberto Gomez Apt",
    VQS: "Antonio Rivera Rodriguez Apt",
    VPZ: "Valparaiso Apt",
    VPY: "Chimoio Apt",
    VPS: "Ft. Walton Beach Apt",
    VPN: "Vopnafjordur Apt",
    VPE: "Ongiva Apt",
    VOZ: "Voronezh Apt",
    VOT: "Votuporanga Apt",
    VOL: "Volos Apt",
    VOK: "Camp Douglas Apt",
    VOI: "Voinjama Apt",
    VOH: "Vohemar Apt",
    VOG: "Volgograd Apt",
    VNY: "Van Nuys Apt",
    VNX: "Vilanculos Apt",
    VNT: "Ventspils Intl Arpt",
    VNS: "Babatpur Apt",
    VNR: "Vanrook Apt",
    VNO: "Vilnius Apt",
    VNL: "Vinales Bus Station",
    VNG: "Viengxay Apt",
    VNE: "Vannes Apt",
    VND: "Vangaindrano Apt",
    VNC: "Venice Municipal Apt",
    VNA: "Saravane Apt",
    VMU: "Baimuru Apt",
    VMI: "Vallemi Apt",
    VME: "Villa Mercedes Apt",
    VLV: "Carvajal Apt",
    VLU: "Velikiye Luki Apt",
    VLS: "Valesdir Apt",
    VLR: "Vallenar Apt",
    VLP: "Vila Rica Municipal Apt",
    VLO: "Vallejo Apt",
    VLN: "Valencia Apt",
    VLM: "Villamontes Apt",
    VLL: "Valladolid Apt",
    VLK: "Volgodonsk Apt",
    VLI: "Bauerfield Apt",
    VLG: "Villa Gesell Apt",
    VLE: "Valle Apt",
    VLD: "Valdosta Regional Apt",
    VLC: "Valencia Apt",
    VLA: "Vandalia Municipal Apt",
    VKW: "West Kavik Apt",
    VKT: "Vorkuta Apt",
    VKS: "Vicksburg Apt",
    VKO: "Moscow Vnukovo Apt",
    VKG: "Rachgia Apt",
    VJQ: "Gurue Apt",
    VJI: "Abingdon Apt",
    VJB: "Xai Xai Apt",
    VIY: "Villacoublay Velizy",
    VIX: "Eurico Sales Apt",
    VIV: "Vivigani Apt",
    VIU: "Viru Apt",
    VIT: "Vitoria Apt",
    VIS: "Visalia Municipal Apt",
    VIR: "Virginia Arpt",
    VIQ: "Viqueque Apt",
    VIO: "Vitoria Rail Station",
    VIN: "Vinnica Apt",
    VIL: "Dakhla Apt",
    VIK: "Kavik Apt",
    VIJ: "Virgin Gorda Apt",
    VII: "Vinh City Apt",
    VIH: "Vichy Apt",
    VIG: "El Vigia Apt",
    VIF: "Vieste Airpot",
    VIE: "Vienna Intl Apt",
    VID: "Vidin Apt",
    VIC: "Vicenza Apt",
    VIB: "Villa Constitucion Apt",
    VIA: "Videira Apt",
    VHZ: "Vahitahi Apt",
    VHY: "Vichy Apt",
    VHN: "Culberson County Apt",
    VHM: "Vilhelmina Apt",
    VHC: "Saurimo Apt",
    VGZ: "Mocoa Apt",
    VGT: "Las Vegas North Air Terminal",
    VGS: "General Villegas Apt",
    VGO: "Vigo Apt",
    VGN: "Vigan",
    VGG: "Vangrieng Apt",
    VGD: "Vologda Apt",
    VGA: "Vijayawada Apt",
    VFA: "Victoria Falls Apt",
    VEY: "Vestmannaeyjar Apt",
    VEX: "Tioga Apt",
    VEV: "Barakoma Apt",
    VER: "General Heriberto Jara Apt",
    VEL: "Vernal Apt",
    VEJ: "Vejle Apt",
    VEG: "Maikwak Apt",
    VEE: "Venetie Municipal Apt",
    VDZ: "Valdez Apt",
    VDS: "Vadso Apt",
    VDR: "Villa Dolores Apt",
    VDP: "Valle De Pascua Apt",
    VDM: "Viedma Apt",
    VDI: "Vidalia Apt",
    VDH: "Dong Hoi Arpt",
    VDE: "Valverde Apt",
    VDD: "Vienna Danubepier Hov",
    VDC: "Vitoria Da Conquista Apt",
    VDB: "Valdres Apt",
    VDA: "Eilat Intl Apt",
    VCV: "George AFB",
    VCT: "Victoria Regional Apt",
    VCS: "Con Dao",
    VCR: "Carora Apt",
    VCP: "Viracopos Apt",
    VCL: "Chulai Arpt",
    VCH: "Vichadero Apt",
    VCF: "Valcheta Apt",
    VCE: "Marco Polo Apt",
    VCD: "Victoria River Downs Apt",
    VCC: "Limbe Apt",
    VCB: "View Cove Apt",
    VCA: "Cantho Apt",
    VBY: "Visby Apt",
    VBV: "Vanuabalavu Apt",
    VBS: "Verona Brescia Apt",
    VBR: "Vyborg Railway Station",
    VBP: "Bokpyin Arpt",
    VBL: "Anshanxi Railway Station",
    VBG: "Vandenberg Airforce Base",
    VBA: "Ann Arpt",
    VAZ: "Val D'Isere Apt",
    VAW: "Vardoe Apt",
    VAV: "Lupepau'u Apt",
    VAU: "Vatukoula Apt",
    VAT: "Vatomandry Apt",
    VAS: "Sivas Apt",
    VAR: "Varna Apt",
    VAO: "Suavanao Apt",
    VAN: "Van Apt",
    VAM: "Maamigili Island Arpt",
    VAL: "Valenca Apt",
    VAK: "Chevak Apt",
    VAI: "Vanimo Apt",
    VAH: "Vallegrande Apt",
    VAG: "Varginha Apt",
    VAF: "Valence Apt",
    VAE: "Valles",
    VAD: "Moody AFB",
    VAC: "Varrelbusch Apt",
    VAB: "Yavarate Apt",
    VAA: "Vaasa Apt",
    UZU: "Curuzu Cuatia Apt",
    UZR: "Urdzhar Arpt",
    UZH: "Unayzah Apt",
    UZC: "Uzice Apt",
    UYN: "Yulin Apt",
    UYL: "Nyala Apt",
    UWP: "Wuppertal Rail Station",
    UWE: "UWE",
    UWA: "Ware Apt",
    UVO: "Uvol Apt",
    UVL: "Kharga Apt",
    UVF: "Hewanorra Apt",
    UVE: "Ouloup Apt",
    UVA: "Uvalde Apt",
    UUU: "Manumu Apt",
    UUS: "Yuzhno-Sakhalinsk Apt",
    UUN: "Baruun-urt Apt",
    UUK: "Kuparuk Apt",
    UUD: "Ulan-Ude Apt",
    UUA: "Bugulma Apt",
    UTW: "Queenstown Apt",
    UTU: "Ustupo Apt",
    UTT: "Umtata Apt",
    UTS: "Ust Tsilma Airport",
    UTR: "Uttaradit Apt",
    UTP: "Utapao Apt",
    UTO: "Utopia Creek Municipal Apt",
    UTN: "Upington Apt",
    UTM: "Tunica Municipal Arpt",
    UTL: "Torremolinos Apt",
    UTK: "Utirik Island Apt",
    UTI: "Kuovola Apt",
    UTH: "Udon Thani Apt",
    UTG: "Quthing Apt",
    UTE: "Butterworth Apt",
    UTD: "Nutwood Downs Apt",
    UTC: "Utrecht Apt",
    UTB: "Muttaburra Apt",
    UTA: "Mutare Apt",
    USX: "St Austell Rail Station",
    USU: "Busuanga Apt",
    UST: "Saint Augustine Apt",
    USS: "Sancti Spiritus Apt",
    USR: "Ust Nera Airport",
    USQ: "Usak Apt",
    USO: "Usino Apt",
    USN: "Ulsan Apt",
    USM: "Koh Samui Apt",
    USL: "Useless Loop Apt",
    USK: "Usinsk Apt",
    USI: "Mabaruma Apt",
    USH: "Islas Malvinas Apt",
    USA: "Concord Regional Arpt",
    URZ: "Uruzgan Apt",
    URY: "Gurayat Apt",
    URU: "Uroubi Apt",
    URT: "Surat Thani Apt",
    URS: "Kursk Apt",
    URR: "Urrao Municipal Apt",
    URO: "Rouen Apt",
    URM: "Uriman Apt",
    URJ: "Uraj Apt",
    URI: "Uribe Apt",
    URG: "Ruben Berta Apt",
    URE: "Kuressaare Apt",
    URD: "Burg Feuerstein Apt",
    URC: "Urumqi Apt",
    URB: "Urubupunga Apt",
    URA: "Uralsk Apt",
    UQE: "Queen Apt",
    UPV: "Upavon Apt",
    UPR: "Upiara Apt",
    UPP: "Upolu Apt",
    UPN: "General Ignacio Lopez Rayon Apt",
    UPL: "Upala Apt",
    UPG: "Hasanuddin Apt",
    UPF: "Pforzheim Apt",
    UPC: "Puerto De La Cruz Apt",
    UPA: "Punta Alegre Apt",
    UOX: "University Apt",
    UOS: "Sewanee Apt",
    UON: "Muong Sai Apt",
    UOL: "Buol Apt",
    UNU: "Juneau Apt",
    UNT: "Baltasound Apt",
    UNS: "Umnak Apt",
    UNR: "Underkhaan Apt",
    UNN: "Ranong Apt",
    UNL: "Unalaska",
    UNK: "Unalakleet Apt",
    UNI: "Union Island Apt",
    UNG: "Kiunga Apt",
    UNE: "Qachas Nek Apt",
    UND: "Kunduz Apt",
    UNC: "Unguia Apt",
    UNA: "Una Apt",
    UMY: "Sumy Apt",
    UMU: "Umuarama Apt",
    UMT: "Umiat Apt",
    UMR: "Woomera Apt",
    UMM: "Summit Apt",
    UMI: "Quincemil Apt",
    UME: "Umea Apt",
    UMD: "Uummannaq Apt",
    UMC: "Umba Apt",
    UMB: "North Umnak Apt",
    UMA: "Punta De Maisi Apt",
    ULZ: "Uliastai Apt",
    ULY: "Ulyanovsk Apt",
    ULV: "Ulyanovsk Tsentralny Arpt",
    ULU: "Gulu Apt",
    ULS: "Mulatos Apt",
    ULQ: "Tulua Apt",
    ULP: "Quilpie Apt",
    ULO: "Ulaangom Apt",
    ULN: "Ulaanbaatar Apt",
    ULM: "New Ulm Apt",
    ULL: "Mull Apt",
    ULK: "Lensk Airport",
    ULI: "Ulithi Apt",
    ULH: "Majeed Bin Abdulaziz Airport",
    ULG: "Ulgit Apt",
    ULE: "Sule Apt",
    ULD: "Ulundi Apt",
    ULC: "Los Cerrillos Arpt",
    ULB: "Ulei Apt",
    ULA: "San Julian Apt",
    UKY: "Kyoto Apt",
    UKX: "Ust Kut Airport",
    UKU: "Nuku Apt",
    UKT: "Quakertown Apt",
    UKS: "Belbek Arpt",
    UKR: "Mukeiras Apt",
    UKN: "Waukon Municipal Apt",
    UKK: "Ust-Kamenogorsk Apt",
    UKI: "Ukiah Municipal Apt",
    UKB: "Kobe Apt",
    UKA: "Ukunda Apt",
    UJE: "Ujae Island Apt",
    UIZ: "Berz-Macomb Apt",
    UIT: "Jaluit Island Apt",
    UIR: "Quirindi Apt",
    UIQ: "Quine Hill Apt",
    UIP: "Pluguffan Apt",
    UIO: "Mariscal Sucre Apt",
    UIN: "Quincy Municipal Apt",
    UIL: "Quillayute State Apt",
    UIK: "Ust-Ilimsk Apt",
    UII: "Utila Apt",
    UIH: "Quinhon Apt",
    UIB: "Quibdo Apt",
    UHO: "Uige",
    UHE: "Uherske Hradiste Apt",
    UGU: "Zugapa Apt",
    UGS: "Ugashik Apt",
    UGR: "Burgos Rail Station",
    UGO: "Uige Apt",
    UGN: "Waukegan Apt",
    UGI: "Uganik Municipal Apt",
    UGC: "Urgench Apt",
    UGB: "Ugashik Apt",
    UGA: "Bulgan Apt",
    UFA: "Ufa Apt",
    UET: "Quetta Apt",
    UES: "Waukesha Apt",
    UER: "Puertollano Rail Station",
    UEO: "Kume Jima Apt",
    UEL: "Quelimane Apt",
    UEE: "Queenstown Apt",
    UDR: "Dabok Apt",
    UDO: "Udomxay Apt",
    UDN: "Udine Apt",
    UDJ: "Uzhgorod Apt",
    UDI: "Eduardo Gomes Apt",
    UDE: "Uden Apt",
    UDD: "Bermuda Dunes Arpt",
    UDC: "UDC",
    UDA: "Undarra Apt",
    UCY: "Everett-Stewart Apt",
    UCT: "Ukhta Apt",
    UCN: "Buchanan Apt",
    UCK: "Lutsk Apt",
    UCE: "Eunice Apt",
    UCC: "Yucca Flat Apt",
    UCA: "Oneida County Apt",
    UBU: "Kalumburu Apt",
    UBT: "Ubatuba Apt",
    UBS: "Lowndes County Apt",
    UBR: "Ubrub Apt",
    UBP: "Muang Ubon Apt",
    UBJ: "Ube Apt",
    UBI: "Buin Apt",
    UBB: "Mabuiag Island Apt",
    UBA: "Uberaba Apt",
    UAX: "Uaxactun Apt",
    UAS: "Samburu Apt",
    UAQ: "San Juan Apt",
    UAP: "Ua Pou Apt",
    UAM: "Anderson Air Force Base",
    UAL: "Luau Apt",
    UAK: "Narsarsuaq Apt",
    UAI: "Suai Apt",
    UAH: "Ua Huka Apt",
    UAE: "Mount Aue Apt",
    UAC: "San Luis Rio Colorado Apt",
    TZX: "Trabzon Apt",
    TZN: "South Andros Apt",
    TZM: "Tizimin Apt",
    TZL: "Tuzla Apt",
    TZA: "Belize City Municipal Airstrip",
    TYZ: "Taylor Apt",
    TYT: "Treinta Y Tres Apt",
    TYS: "McGhee Tyson Apt",
    TYR: "Pounds Field",
    TYP: "Tobermorey Apt",
    TYO: "Tokyo",
    TYN: "Taiyuan Apt",
    TYM: "Staniel Cay Apt",
    TYL: "Talara Apt",
    TYG: "Thylungra Apt",
    TYF: "Fryklanda Apt",
    TYE: "Tyonek Apt",
    TYB: "Tibooburra Apt",
    TYA: "Yalova Apt",
    TXU: "Tabou Apt",
    TXR: "Tanbar Apt",
    TXN: "Tunxi Apt",
    TXM: "Teminabuan Apt",
    TXL: "Tegel Apt",
    TXK: "Texarkana Municipal Apt",
    TXG: "Taichung Apt",
    TXF: "Teixeira De Freitas Apt",
    TWZ: "Pukaki/Twizel Apt",
    TWY: "Tawa Apt",
    TWU: "Tawau Apt",
    TWT: "Tawitawi Apt",
    TWP: "Torwood Apt",
    TWN: "Tewantin Apt",
    TWH: "Two Harbors Apt",
    TWF: "Twin Falls Regional Apt",
    TWE: "Taylor Apt",
    TWD: "Port Townsend Apt",
    TWB: "Toowoomba Apt",
    TWA: "Twin Hills Municipal Apt",
    TVY: "Dawe Apt",
    TVX: "Tongxiang Rail Station",
    TVU: "Matei Apt",
    TVS: "Sannuhe Arpt",
    TVL: "Lake Tahoe Apt",
    TVI: "Thomasville Apt",
    TVF: "Thief River Falls Apt",
    TVC: "Cherry Capital Apt",
    TVA: "Morafenobe Apt",
    TUZ: "Tucuma Apt",
    TUY: "Tulum Apt",
    TUX: "Tumbler Ridge Apt",
    TUW: "Tubala Apt",
    TUV: "Tucupita Apt",
    TUU: "Tabuk Apt",
    TUT: "Tauta Apt",
    TUS: "Tucson Intl Apt",
    TUR: "Tucurui Intl Apt",
    TUQ: "Tougan Apt",
    TUP: "Tupelo Municipal-C.D. Lemons Apt",
    TUO: "Taupo Apt",
    TUN: "Carthage Apt",
    TUM: "Tumut Apt",
    TUL: "Tulsa Intl Apt",
    TUK: "Turbat Apt",
    TUJ: "Tum Apt",
    TUI: "Turaif Apt",
    TUH: "Arnold AFS",
    TUG: "Tuguegarao Apt",
    TUF: "Saint Symphorien Apt",
    TUE: "Tupile Apt",
    TUD: "Tambacounda Apt",
    TUC: "Tucuman Apt",
    TUB: "Mataura Apt",
    TUA: "Tulcan Municipal Apt",
    TTZ: "Zhengzhou Rail Station",
    TTX: "Truscott Mungalalu Airport",
    TTV: "Barat",
    TTU: "Tetuan Apt",
    TTT: "Taitung Apt",
    TTS: "Tsaratanana Apt",
    TTR: "Tana Toraja Apt",
    TTQ: "Tortuquero Apt",
    TTO: "Britton Municipal Apt",
    TTN: "Mercer Apt",
    TTM: "Tablon De Tamara Apt",
    TTL: "Turtle Island Apt",
    TTK: "Tottenham Hale station",
    TTJ: "Tottori Apt",
    TTI: "Tetiaroa Island Apt",
    TTG: "Tartagal Apt",
    TTE: "Babullah Apt",
    TTD: "Troutdale Apt",
    TTC: "Taltal Apt",
    TTB: "Tortoli Apt",
    TTA: "Tan Tan Apt",
    TSY: "Tasikmalaya Apt",
    TSX: "Tanjung Santan Apt",
    TSW: "Tsewi Apt",
    TSV: "Townsville Apt",
    TSU: "Tabiteuea South Apt",
    TST: "Trang Apt",
    TSS: "East 34th St Hlpt",
    TSR: "Timisoara Apt",
    TSQ: "Torres Apt",
    TSP: "Tehachapi Municipal Apt",
    TSO: "Tresco Apt",
    TSN: "Tianjin Apt",
    TSM: "Taos Municipal Apt",
    TSL: "Tamuin Apt",
    TSK: "Taskul Apt",
    TSJ: "Tsushima Apt",
    TSI: "Tsili Tsili Apt",
    TSH: "Tshikapa Apt",
    TSG: "Tanacross Apt",
    TSF: "Treviso Apt",
    TSE: "Astana Apt",
    TSD: "Tshipise Apt",
    TSC: "Taisha Apt",
    TSB: "Tsumeb Apt",
    TSA: "Sung Shan Apt",
    TRZ: "Tiruchirapally Civil Apt",
    TRY: "Tororo Apt",
    TRX: "Trenton Apt",
    TRW: "Bonriki Apt",
    TRV: "Thiruvananthapuram Intl Apt",
    TRU: "Trujillo Apt",
    TRT: "Tremonton Apt",
    TRS: "Dei Legionari Apt",
    TRR: "Trincomalee Apt",
    TRQ: "Tarauaca Apt",
    TRP: "Tree Point Apt",
    TRO: "Taree Apt",
    TRN: "Citta Di Torino Apt",
    TRM: "Thermal Apt",
    TRL: "Terrell Apt",
    TRK: "Tarakan Apt",
    TRJ: "Tarakbits Apt",
    TRI: "Tri-Cities Regional Apt",
    TRH: "Trona Apt",
    TRG: "Tauranga Apt",
    TRF: "Torp Apt",
    TRE: "Tiree Island Apt",
    TRD: "Vaernes Apt",
    TRC: "Francisco Sarabia Apt",
    TRB: "Gonzalo Apt",
    TRA: "Taramajima Apt",
    TQS: "Tres Esquinas Apt",
    TQR: "San Domino Island Arpt",
    TQN: "Taluqan Apt",
    TQL: "Tarko Sale Airport",
    TPU: "Tikapur Apt",
    TPT: "Tapeta Apt",
    TPS: "Birgi Apt",
    TPR: "Tom Price Apt",
    TPQ: "Tepic Municipal Apt",
    TPP: "Tarapoto Apt",
    TPO: "Tanalian Point Apt",
    TPN: "Tiputini Apt",
    TPL: "Draughon-Miller Municipal Apt",
    TPK: "Tapaktuan Apt",
    TPJ: "Taplejung Apt",
    TPI: "Tapini Apt",
    TPH: "Tonopah Apt",
    TPG: "Taiping Apt",
    TPF: "Peter O Knight Arpt",
    TPE: "Taoyuan Intl Apt",
    TPC: "Tarapoa Apt",
    TPB: "Bristol Temple Meads Rail Stn",
    TPA: "Tampa Intl Apt",
    TOZ: "Touba Apt",
    TOY: "Toyama Apt",
    TOX: "Tobolsk Apt",
    TOW: "Toledo Apt",
    TOV: "Tortola British Virgin Isles",
    TOU: "Touho Apt",
    TOT: "Totness Apt",
    TOS: "Langnes Apt",
    TOR: "Torrington Apt",
    TOQ: "Tocopilla Apt",
    TOP: "Philip Billard Municipal Apt",
    TOO: "San Vito Apt",
    TON: "Tonu Apt",
    TOM: "Tombouctou Apt",
    TOL: "Toledo Express Apt",
    TOK: "Torokina Apt",
    TOJ: "Torrejon AFB",
    TOI: "Troy Apt",
    TOH: "Torres Apt",
    TOG: "Togiak Apt",
    TOF: "Tomsk Apt",
    TOE: "Tozeur Apt",
    TOD: "Tioman Apt",
    TOC: "R. G. Letourneau Field",
    TOB: "Tobruk Apt",
    TOA: "Zamperini Field",
    TNX: "Stung Treng Apt",
    TNW: "Jumandy Arpt",
    TNV: "Tabuaeran Apt",
    TNU: "Newton Municipal Apt",
    TNT: "Dade Collier Arpt",
    TNS: "Tungsten Apt",
    TNR: "Antananarivo Apt",
    TNQ: "Teraina Apt",
    TNP: "Twentynine Palms Apt",
    TNO: "Tamarindo Apt",
    TNN: "Tainan Apt",
    TNL: "Ternopol Apt",
    TNK: "Tununak Apt",
    TNJ: "Kijang Apt",
    TNI: "Satna Apt",
    TNH: "Tonghua Apt",
    TNG: "Boukhalef Apt",
    TNF: "Toussus-Le-Noble Apt",
    TNE: "Tanegashima Apt",
    TND: "Trinidad Apt",
    TNC: "Tin City Municipal Apt",
    TNB: "Tanah Grogot Apt",
    TNA: "Jinan Apt",
    TMZ: "Thames Apt",
    TMY: "Tiom Apt",
    TMX: "Timimoun Apt",
    TMW: "Tamworth Apt",
    TMV: "Tianmen South Railway Station",
    TMU: "Tambor Apt",
    TMT: "Trombetas Apt",
    TMS: "Sao Tome Island Apt",
    TMR: "Tamanrasset Apt",
    TMQ: "Tambao Apt",
    TMP: "Pirkkala Apt",
    TMO: "Tumeremo Apt",
    TMN: "Tamana Island Apt",
    TMM: "Tamatave Apt",
    TML: "Tamale Apt",
    TMK: "Tam Ky Apt",
    TMJ: "Termez Apt",
    TMI: "Tumlingtar Apt",
    TMH: "Tanahmerah Apt",
    TMG: "Tomanggong Apt",
    TMF: "Thimarafushi Arpt",
    TME: "Tame Apt",
    TMD: "Timbedra Apt",
    TMC: "Tambolaka Apt",
    TMB: "Tamiami Airport",
    TMA: "Henry Tift Myers Apt",
    TLZ: "Catalao Apt",
    TLX: "Talca Apt",
    TLW: "Talasea Apt",
    TLV: "Ben Gurion Intl Apt",
    TLU: "Tolu Apt",
    TLT: "Tuluksak Apt",
    TLS: "Blagnac Apt",
    TLR: "Mefford Field",
    TLQ: "Turpan Jiaohe Airport",
    TLP: "Tumolbil Apt",
    TLO: "Tol Apt",
    TLN: "Hyeres Apt",
    TLM: "Zenata Apt",
    TLL: "Ulemiste Apt",
    TLK: "Talknafjordur Apt",
    TLJ: "Tatalina Municipal Apt",
    TLI: "Tolitoli Apt",
    TLH: "Tallahassee Regional Apt",
    TLG: "Tulaghi Island Apt",
    TLF: "Telida Apt",
    TLE: "Tulear Apt",
    TLD: "Tuli Lodge Apt",
    TLC: "Licenciado Adolfo Lopez Mateos Apt",
    TLB: "Tarbela Apt",
    TLA: "Teller Municipal Apt",
    TKZ: "Tokoroa Apt",
    TKY: "Turkey Creek Apt",
    TKX: "Takaroa Apt",
    TKW: "Tekin Apt",
    TKV: "Tatakoto Apt",
    TKU: "Turku Apt",
    TKT: "Tak Apt",
    TKS: "Tokushima Apt",
    TKR: "Thakurgaon Apt",
    TKQ: "Kigoma Apt",
    TKP: "Takapoto Apt",
    TKO: "Tlokoeng Apt",
    TKN: "Tokunoshima Apt",
    TKM: "Tikal Apt",
    TKL: "Taku Harbor Apt",
    TKK: "Chuuk Intl Apt",
    TKJ: "Tok Apt",
    TKI: "Tokeen Municipal Apt",
    TKH: "Takhli Apt",
    TKG: "Branti Apt",
    TKF: "Truckee-Tahoe Apt",
    TKE: "Tanakee Sea Plane Base",
    TKD: "Takoradi Apt",
    TKC: "Tiko Apt",
    TKB: "Tekadu Apt",
    TKA: "Talkeetna Apt",
    TJV: "Thanjavur Apt",
    TJU: "Kulob Airport",
    TJS: "Tanjung Selor Apt",
    TJQ: "Buluh Tumbang Apt",
    TJN: "Takume Apt",
    TJM: "Tyumen Apt",
    TJL: "Plinio Alarcom Arpt",
    TJK: "Tokat Apt",
    TJI: "Trujillo Apt",
    TJH: "Toyooka Apt",
    TJG: "Tanjung Apt",
    TJC: "Ticantiki Apt",
    TJB: "Tanjung Balai Apt",
    TJA: "Capitan Oriellea Plaza Apt",
    TIZ: "Tari Apt",
    TIY: "Tidjikja Apt",
    TIX: "Space Center Executive Arpt",
    TIW: "Tacoma Industrial Apt",
    TIV: "Tivat Apt",
    TIU: "Timaru Apt",
    TIS: "Thursday Island Apt",
    TIR: "Tirupati Apt",
    TIQ: "West Tinian Apt",
    TIP: "Tripoli Apt",
    TIO: "Tilin Apt",
    TIN: "Tindouf Apt",
    TIM: "Timika Apt",
    TIL: "Inverlake Apt",
    TIK: "Tinker Airforce Base Arpt",
    TIJ: "General Abelardo L. Rodriguez Apt",
    TII: "Tirinkot Apt",
    TIH: "Tikehau Atoll Apt",
    TIG: "Tingwon Apt",
    TIF: "Taif Apt",
    TIE: "Tippi Apt",
    TID: "Bou Chekif Apt",
    TIC: "Tinak Island Apt",
    TIB: "Tibu Apt",
    TIA: "Tirana Apt",
    THZ: "Tahoua Apt",
    THY: "Thohoyandou Apt",
    THX: "Turukhansk Airport",
    THV: "York Apt",
    THU: "Pituffik Apt",
    THT: "Tamchaket Apt",
    THS: "Sukhothai Apt",
    THR: "Tehran Apt",
    THQ: "Tianshui Maijishan",
    THP: "Thermopolis Apt",
    THO: "Thorshofn Apt",
    THN: "Trollhattan Apt",
    THM: "Thomsonfield Apt",
    THL: "Tachilek Apt",
    THK: "Thakhek Apt",
    THI: "Tichitt Apt",
    THH: "Taharoa Apt",
    THG: "Thangool Apt",
    THF: "Tempelhof Apt",
    THE: "Teresina Apt",
    THD: "Tho Xuan Arpt",
    THC: "Tchien Apt",
    THB: "Thaba-Tseka Apt",
    THA: "Tullahoma Northern Apt",
    TGZ: "Lland San Juan Apt",
    TGX: "Tingrela Apt",
    TGV: "Targovishte Apt",
    TGU: "Toncontin Apt",
    TGT: "Tanga Apt",
    TGS: "Chokwe Apt",
    TGR: "Touggourt Apt",
    TGQ: "Tangara Da Serra Apt",
    TGP: "Podkamennaya Tunguska",
    TGO: "Tongliao Apt",
    TGN: "La Trobe Apt",
    TGM: "Tirgu Mures Apt",
    TGL: "Tagula Apt",
    TGK: "Taganrog Airport",
    TGJ: "Tiga Apt",
    TGI: "Tingo Maria Apt",
    TGH: "Tongoa Apt",
    TGG: "Sultan Mahmood Apt",
    TGF: "Tignes Apt",
    TGE: "Tuskegee Apt",
    TGD: "Golubovci Apt",
    TGC: "Tanjung Manis Airport",
    TGB: "Tagbita Apt",
    TFY: "Tarfaya Apt",
    TFT: "Taftan Apt",
    TFS: "Sur Reina Sofia Apt",
    TFR: "Tarbes",
    TFN: "Norte Los Rodeos Apt",
    TFM: "Telefomin Apt",
    TFL: "Teofilo Otoni Apt",
    TFI: "Tufi Apt",
    TFF: "Tefe Municipal Apt",
    TFB: "Tifalmin Apt",
    TEZ: "Salonbari Apt",
    TEY: "Thingeyri Apt",
    TEX: "Telluride Regional Apt",
    TEU: "Manapouri Apt",
    TET: "Matunda Apt",
    TES: "Tessenei Apt",
    TER: "Terceira Apt",
    TEQ: "Corlu Apt",
    TEP: "Teptep Apt",
    TEO: "Terapo Apt",
    TEN: "Tongren Apt",
    TEM: "Temora Apt",
    TEL: "Telupid Apt",
    TEK: "Tatitlek Apt",
    TEJ: "Teruel Rail Station",
    TEI: "Tezu Apt",
    TEH: "Tetlin Municipal Apt",
    TEG: "Tenkodogo Apt",
    TEF: "Telfer Apt",
    TEE: "Tbessa Apt",
    TED: "Thisted Apt",
    TEC: "Telemaco Borba Apt",
    TEB: "Teterboro Apt",
    TEA: "Tela Apt",
    TDZ: "Toledo Metcalf Field",
    TDX: "TDX",
    TDW: "Tradewind Airport",
    TDV: "Tanandava Apt",
    TDT: "Tanda Tula Apt",
    TDR: "Theodore Apt",
    TDO: "Toledo Apt",
    TDN: "Theda Station Apt",
    TDL: "Tandil Apt",
    TDK: "Taldy Kurgan Apt",
    TDJ: "Tadjoura Apt",
    TDG: "Tandag Apt",
    TDD: "Arauz Apt",
    TDC: "Toccoa",
    TDB: "Tetabedi Apt",
    TDA: "Trinidad Apt",
    TCZ: "Hump Airport",
    TCY: "Tracy Municipal Apt",
    TCX: "Tabas Apt",
    TCW: "Tocumwal Apt",
    TCV: "Tete Apt",
    TCU: "Thaba Nchu Apt",
    TCT: "Takotna Municipal Apt",
    TCR: "Tuticorin Apt",
    TCQ: "Tacna Apt",
    TCP: "Taba Apt",
    TCO: "La Florida Apt",
    TCN: "Tehuacan Apt",
    TCM: "McChord AFB",
    TCL: "Tuscaloosa Municipal Apt",
    TCK: "Tinboli Apt",
    TCJ: "Torembi Apt",
    TCI: "Tenerife",
    TCH: "Tchibanga Apt",
    TCG: "Tacheng Apt",
    TCF: "Tocoa Apt",
    TCE: "Tulcea Apt",
    TCD: "Tarapaca Apt",
    TCC: "Tucumcari Municipal Apt",
    TCB: "Treasure Cay Apt",
    TCA: "Tennant Creek Apt",
    TBZ: "Tabriz Apt",
    TBW: "Tambov Apt",
    TBU: "Nuku'Alofa Intl Apt",
    TBT: "Tabatinga Intl Apt",
    TBS: "Novo Alexeyevka Apt",
    TBR: "Statesboro Apt",
    TBP: "Tumbes Apt",
    TBO: "Tabora Apt",
    TBN: "Forney Field",
    TBM: "Tumbang Samba Apt",
    TBL: "Tableland Apt",
    TBK: "Timber Creek Apt",
    TBJ: "Tabarka Apt",
    TBI: "The Bight Municipal Apt",
    TBH: "Tablas Apt",
    TBG: "Tabubil Apt",
    TBF: "Tabiteuea North Apt",
    TBE: "Timbunke Apt",
    TBD: "Timbiqui Apt",
    TBC: "Tuba City Apt",
    TBB: "Tuyhoa Apt",
    TBA: "Tabibuga Apt",
    TAZ: "Dashoguzn Apt",
    TAY: "Tartu Apt",
    TAX: "Taliabu Apt",
    TAW: "Tacuarembo Municipal Apt",
    TAU: "Tauramena Apt",
    TAT: "Poprad Tatry Arpt",
    TAS: "Vostochny Apt",
    TAR: "Taranto Apt",
    TAQ: "Tarcoola Apt",
    TAP: "Tapachula Apt",
    TAO: "Qingdao Apt",
    TAN: "Tangalooma Apt",
    TAM: "General Francisco Javier Mina Apt",
    TAL: "Ralph M. Calhoun Apt",
    TAK: "Takamatsu Apt",
    TAJ: "Tadji Apt",
    TAI: "Taiz Apt",
    TAH: "Tanna Apt",
    TAG: "Tagbilaran Apt",
    TAF: "Tafaraoui Apt",
    TAE: "Taegu Apt",
    TAD: "Perry Stokes Apt",
    TAC: "D.Z. Romualdez Apt",
    TAB: "Crown Point Apt",
    TAA: "Tarapaina Apt",
    SZZ: "Goleniow Apt",
    SZY: "Szymany Apt",
    SZX: "Shenzhen Apt",
    SZW: "Schwerin Apt",
    SZV: "Suzhou Apt",
    SZU: "Segou Apt",
    SZT: "San Cristobal De las Casas Apt",
    SZS: "Stewart Island Apt",
    SZR: "Stara Zagora Apt",
    SZQ: "Saenz Pena Apt",
    SZP: "Santa Paula Apt",
    SZO: "Shanzhou Apt",
    SZN: "Santa Cruz Island Apt",
    SZM: "Sesriem Apt",
    SZL: "Warrensburg Apt",
    SZK: "Skukuza Apt",
    SZJ: "Siguanea Apt",
    SZI: "Zaisan Apt",
    SZH: "Senipah Apt",
    SZG: "W. A. Mozart Apt",
    SZF: "Carsamba Apt",
    SZE: "Semera Apt",
    SZD: "Sheffield Apt",
    SZC: "Santa Cruz Apt",
    SZB: "Sultan Abdul Aziz Shah Apt",
    SZA: "Soyo Apt",
    SYZ: "Shiraz Apt",
    SYY: "Stornoway Apt",
    SYX: "Sanya Apt",
    SYW: "Sehwen Sharif Apt",
    SYV: "Sylvester Apt",
    SYU: "Warraber Island Apt",
    SYT: "Saint Yan Apt",
    SYS: "Yeosu Apt",
    SYR: "Hancock Intl Apt",
    SYQ: "Tobias Bolanos Apt",
    SYP: "Santiago Apt",
    SYO: "Shonai Apt",
    SYN: "Stanton Apt",
    SYM: "Simao Apt",
    SYL: "San Miguel Apt",
    SYK: "Stykkisholmur Apt",
    SYJ: "Sirjan Apt",
    SYI: "Shelbyville Apt",
    SYG: "Svalbard Apt",
    SYF: "Silva Bay Apt",
    SYE: "Sa Dah Apt",
    SYD: "Kingsford Smith Apt",
    SYB: "Seal Bay Municipal Apt",
    SYA: "Shemya Apt",
    SXZ: "Siirt Apt",
    SXY: "Sidney Municipal Apt",
    SXX: "Sao Felix Do Xingu Apt",
    SXW: "Sauren Apt",
    SXV: "Salem Apt",
    SXU: "Soddu Apt",
    SXT: "Taman Negara Apt",
    SXS: "Sahabat 16 Apt",
    SXR: "Srinagar Apt",
    SXQ: "Soldotna Apt",
    SXP: "Sheldon Point Municipal Apt",
    SXO: "Sao Felix Do Araguaia Apt",
    SXN: "Suapan Apt",
    SXM: "Saint Maarten Apt",
    SXL: "Collooney Apt",
    SXK: "Saumlaki Apt",
    SXI: "Sirri Island Apt",
    SXH: "Sehulea Apt",
    SXG: "Senanga Apt",
    SXF: "Schonefeld Apt",
    SXE: "Sale Apt",
    SXC: "Avalon Vor Apt",
    SXB: "Entzheim Apt",
    SXA: "Sialum Apt",
    SWZ: "West Sydney Apt",
    SWY: "Sitiawan Apt",
    SWX: "Shakawe Apt",
    SWW: "Sweetwater Apt",
    SWV: "Shikarpur Apt",
    SWU: "Suwon Apt",
    SWT: "Strzhewoi Apt",
    SWS: "Swansea Apt",
    SWR: "Silur Apt",
    SWQ: "Sumbawa Apt",
    SWP: "Swakopmund Apt",
    SWO: "Stillwater Municipal Apt",
    SWN: "Sahiwal Apt",
    SWM: "Suia-Missu Apt",
    SWL: "Spanish Wells Apt",
    SWJ: "South West Bay Apt",
    SWI: "Swindon Apt",
    SWH: "Swan Hill Apt",
    SWG: "Satwag Apt",
    SWF: "Stewart Intl Apt",
    SWE: "Siwea Apt",
    SWD: "Seward Apt",
    SWC: "Stawell Apt",
    SWB: "Shaw River Apt",
    SWA: "Shantou Apt",
    SVZ: "San Antonio Municipal Apt",
    SVY: "Savo Apt",
    SVX: "Ekaterinburg Apt",
    SVW: "Sparrevohn Apt",
    SVV: "De Paul Apt",
    SVU: "Savusavu Apt",
    SVT: "Savuti Apt",
    SVS: "Stevens Village Apt",
    SVR: "Svay Rieng Apt",
    SVQ: "San Pablo Apt",
    SVP: "Kuito Apt",
    SVO: "Sheremetyevo Apt",
    SVN: "Hunter AAF",
    SVM: "Saint Pauls Mission Apt",
    SVL: "Savonlinna Apt",
    SVK: "Silver Creek Apt",
    SVJ: "Svolvaer Apt",
    SVI: "San Vicente Del Caguan Apt",
    SVH: "Statesville Apt",
    SVG: "Sola Apt",
    SVF: "Save Apt",
    SVE: "Susanville Municipal Apt",
    SVD: "E. T. Joshua Apt",
    SVC: "Grant County Apt",
    SVB: "Sambava Apt",
    SVA: "Savoonga Municipal Apt",
    SUZ: "Suria Apt",
    SUY: "Sudureyri Apt",
    SUX: "Sioux Gateway Apt",
    SUW: "Superior Apt",
    SUV: "Nausori Intl Apt",
    SUU: "Travis AFB",
    SUT: "Sumbawanga Apt",
    SUS: "Spirit Of St Louis Arpt",
    SUR: "Summer Beaver Municipal Apt",
    SUQ: "Sucua Apt",
    SUP: "Sumenep Apt",
    SUO: "Sun River Apt",
    SUN: "Friedman Memorial Apt",
    SUM: "Sumter Municipal Arpt",
    SUL: "Sui Apt",
    SUK: "Samcheok Apt",
    SUJ: "Satu Mare Apt",
    SUI: "Sukhumi Apt",
    SUH: "Sur Apt",
    SUG: "Surigao Apt",
    SUF: "S. Eufemia Apt",
    SUE: "Sturgeon Bay Apt",
    SUD: "Stroud Municipal Apt",
    SUC: "Sundance Apt",
    SUB: "Juanda Apt",
    SUA: "Witham Field",
    STZ: "Confresa Apt",
    STY: "Aeropuerto Departamental de Salto",
    STX: "Henry E. Rohlsen Apt",
    STW: "Stavropol Apt",
    STV: "Surat Apt",
    STU: "Santa Cruz Apt",
    STT: "Cyril E. King Apt",
    STS: "Sonoma County Apt",
    STR: "Echterdingen Apt",
    STQ: "Saint Marys Municipal Apt",
    STP: "Downtown St Paul Arpt",
    STO: "Stockholm Metropolitan Area",
    STN: "Stansted Apt",
    STM: "Eduardo Gomes Apt",
    STL: "Lambert Intl Apt",
    STK: "Crosson Field",
    STJ: "Saint Joseph Apt",
    STI: "Santiago Municipal Apt",
    STH: "Eckert Field",
    STG: "Saint George Island Municipal Apt",
    STF: "Stephen Island Apt",
    STE: "Stevens Point Apt",
    STD: "Mayo Guerrero Apt",
    STC: "Saint Cloud Municipal Apt",
    STB: "Santa Barbara Zulia Apt",
    STA: "Stauning Apt",
    SSZ: "Santos Apt",
    SSY: "M'Banza Congo Apt",
    SSX: "Samsun Apt",
    SSW: "Stuart Island Airpark",
    SSV: "Siasi Apt",
    SSU: "White Sulphur Springs Apt",
    SST: "Santa Teresita Apt",
    SSS: "Siassi Apt",
    SSR: "Sara Apt",
    SSQ: "La Sarre Rail Station",
    SSP: "Silver Plains Apt",
    SSO: "Sao Lourenco Apt",
    SSN: "Seoul Ab",
    SSM: "Sault Sainte Marie County Apt",
    SSL: "Santa Rosalia Apt",
    SSK: "Sturt Creek Apt",
    SSJ: "Stokka Apt",
    SSI: "McKinnon Apt",
    SSH: "Sharm El Sheikh Apt",
    SSG: "Malabo Apt",
    SSF: "Stinson Municipal Arpt",
    SSE: "Sholapur Apt",
    SSD: "San Felipe Apt",
    SSC: "Shaw Air Force Base",
    SSB: "Soesterberg Seeplane Base",
    SSA: "Luis E. Magalhaes Apt",
    SRZ: "El Trompillo Apt",
    SRY: "Sary Apt",
    SRX: "Sert Apt",
    SRW: "Salisbury Apt",
    SRV: "Stony River Apt",
    SRU: "Skypark Arpt",
    SRT: "Soroti Apt",
    SRS: "San Marcos Apt",
    SRR: "Stradbroke Island Apt",
    SRQ: "Sarasota/Bradenton Intl Apt",
    SRP: "Stord Apt",
    SRO: "Santana Ramos Apt",
    SRN: "Strahan Apt",
    SRM: "Sandringham Apt",
    SRL: "Santa Rosalia Apt",
    SRK: "Sierra Leone Apt",
    SRJ: "Captain G. Q. Guardia Apt",
    SRI: "Temenggung Apt",
    SRH: "Sarh Apt",
    SRG: "Achmadyani Apt",
    SRF: "Hamilton AAF",
    SRE: "Padilla Apt",
    SRD: "San Ramon Apt",
    SRC: "Searcy Apt",
    SRB: "Santa Rosa Apt",
    SRA: "Santa Rosa Apt",
    SQY: "Sao Lourenco Do Sul Apt",
    SQX: "Sao Miguel Do Oeste Apt",
    SQW: "Skive Apt",
    SQV: "Grand View Intl Apt",
    SQU: "Saposoa Apt",
    SQT: "Samarai Island Apt",
    SQS: "San Ignacia Apt",
    SQR: "Soroako Apt",
    SQQ: "Siauliai Apt",
    SQP: "Starcke Apt",
    SQO: "Gunnarn Apt",
    SQN: "Sanana Apt",
    SQM: "Sao Miguel Do Araguaia Apt",
    SQL: "San Carlos Apt",
    SQK: "Sidi Barani Apt",
    SQJ: "Shehdi Apt",
    SQI: "Whiteside County Apt",
    SQH: "Na-San Apt",
    SQG: "Sintang Apt",
    SQF: "Solano Apt",
    SQE: "San Luis De Palenque Apt",
    SQC: "Southern Cross Apt",
    SQB: "Santa Ana Apt",
    SQA: "Santa Ynez Apt",
    SPZ: "Springdale Apt",
    SPY: "San Pedro Apt",
    SPX: "Spacelandarpt",
    SPW: "Spencer Municipal Apt",
    SPV: "Sepik Plains Apt",
    SPU: "Split Apt",
    SPT: "Sipitang Apt",
    SPS: "Wichita Falls Municipal Apt",
    SPR: "San Pedro Apt",
    SPQ: "San Pedro Apt",
    SPP: "Menongue Apt",
    SPO: "San Pablo Apt",
    SPN: "Saipan Intl Apt",
    SPM: "Spangdahlem Air Base",
    SPK: "Sapporo",
    SPJ: "Sparta Apt",
    SPI: "Capital Apt",
    SPH: "Sopu Apt",
    SPG: "Whitted Arpt",
    SPF: "Black Hills Apt-Clyde Ice Field",
    SPE: "Sepulot Apt",
    SPD: "Saidpur Apt",
    SPC: "Santa Cruz De La Palma Apt",
    SPB: "St Thomas Charlotte Amalie SPB",
    SPA: "SPA",
    SOZ: "Solanzara Apt",
    SOY: "Stronsay Apt",
    SOX: "Sogamoso Apt",
    SOW: "Show Low Apt",
    SOV: "Seldovia Apt",
    SOU: "Southampton Apt",
    SOT: "Sodankyla Apt",
    SOR: "Al Thaurah Apt",
    SOQ: "Jefman Apt",
    SOP: "Pinehurst Apt",
    SOO: "Soderhamn Apt",
    SON: "Pekoa Apt",
    SOM: "San Tome Apt",
    SOL: "Solomon Apt",
    SOK: "Semongkong Apt",
    SOJ: "Sorkjosen Apt",
    SOI: "South Molle Island Apt",
    SOH: "Solita Apt",
    SOG: "Haukasen Apt",
    SOF: "Vrazhdebna Apt",
    SOE: "Souanke Apt",
    SOD: "Sorocaba Apt",
    SOC: "Adi Sumarmo Apt",
    SOB: "Saarmelleek Balaton Arpt",
    SOA: "Soc Trang Apt",
    SNZ: "Santa Cruz Apt",
    SNY: "Sidney Apt",
    SNX: "Sabana De La Mar Apt",
    SNW: "Thandwe Apt",
    SNV: "Santa Elena Apt",
    SNU: "Santa Clara Apt",
    SNT: "Sabana De Torres Apt",
    SNS: "Salinas Municipal Apt",
    SNR: "Saint Nazaire Apt",
    SNQ: "San Quintin Apt",
    SNP: "Saint Paul Island Airport",
    SNO: "Sakon Nakhon Apt",
    SNN: "Shannon Apt",
    SNM: "San Ignacio De Moxos Apt",
    SNL: "Shawnee Municipal Apt",
    SNK: "Winston Field",
    SNJ: "San Julian Apt",
    SNI: "R E Murray Apt",
    SNH: "Stanthorpe Apt",
    SNG: "San Ignacio De Velasco Apt",
    SNF: "San Felipe Apt",
    SNE: "Sao Nicolau Apt",
    SND: "Seno Apt",
    SNC: "Salinas Apt",
    SNB: "Snake Bay Apt",
    SNA: "Orange County-John Wayne Intl Apt",
    SMZ: "Stoelmans Eiland Apt",
    SMY: "Simenti Apt",
    SMX: "Santa Maria Public Apt",
    SMW: "Smara Apt",
    SMV: "Saint Moritz Apt",
    SMU: "Sheep Mountain Apt",
    SMT: "Sun Moon Lake Apt",
    SMS: "Sainte Marie Apt",
    SMR: "Simon Bolivar Apt",
    SMQ: "Sampit Apt",
    SMP: "Stockholm Apt",
    SMO: "Santa Monica Municipal Apt",
    SMN: "Salmon Apt",
    SMM: "Semporna Apt",
    SML: "Estate Airstrip",
    SMK: "Saint Michael Apt",
    SMJ: "Sim Apt",
    SMI: "Samos Apt",
    SMH: "Sapmanga Apt",
    SMG: "Santa Maria Apt",
    SMF: "Sacramento Metropolitan Apt",
    SME: "Somerset Apt",
    SMD: "Smith Field Airport",
    SMC: "Santa Maria Apt",
    SMB: "Cerro Sombrero Apt",
    SMA: "Santa Maria Apt",
    SLZ: "Mal Cunha Machado Apt",
    SLY: "Salehard Apt",
    SLX: "Salt Cay Municipal Apt",
    SLW: "Saltillo Apt",
    SLV: "Simla Apt",
    SLU: "Vigie Apt",
    SLT: "Salida Apt",
    SLS: "Silistra Apt",
    SLR: "Sulphur Springs Municipal Apt",
    SLQ: "Sleetmute Apt",
    SLP: "San Luis Potosi Municipal Apt",
    SLO: "Leckrone Apt",
    SLN: "Salina Municipal Apt",
    SLM: "Matacan Apt",
    SLL: "Salalah Intl Apt",
    SLK: "Adirondack Regional Apt",
    SLJ: "Stellar Air Park",
    SLI: "Solwezi Apt",
    SLH: "Sola Apt",
    SLG: "Siloam Springs Apt",
    SLF: "Sulayel Apt",
    SLE: "McNary Field",
    SLD: "Sliac Apt",
    SLC: "Salt Lake City Intl Apt",
    SLB: "Storm Lake Municipal Apt",
    SLA: "General Belfrano Apt",
    SKZ: "Sukkur Apt",
    SKY: "Griffing Sandusky Apt",
    SKX: "Saransk Apt",
    SKW: "Skwentna Apt",
    SKV: "Santa Katarina Apt",
    SKU: "Skiros Apt",
    SKT: "Sialkot Apt",
    SKS: "Vojens Apt",
    SKR: "Shakiso Apt",
    SKQ: "Sekakes Apt",
    SKP: "Skopje Apt",
    SKO: "Sokoto Apt",
    SKN: "Skagen Apt",
    SKM: "Skeldon Apt",
    SKL: "Isle of Skye Apt",
    SKK: "Shaktoolik Municipal Apt",
    SKJ: "Sitkinak Island Apt",
    SKI: "Skikda Apt",
    SKH: "Surkhet Apt",
    SKG: "Makedonia Apt",
    SKF: "Kelly Airforce Base",
    SKE: "Skien Apt",
    SKD: "Samarkand Apt",
    SKC: "Suki Apt",
    SKB: "Robert L. Bradshaw Intl Apt",
    SKA: "Fairchild Airforce Base",
    SJZ: "Sao Jorge Island Apt",
    SJY: "Ilmajoki Apt",
    SJX: "Sartaneja Apt",
    SJW: "Shijiazhuang Apt",
    SJV: "San Javier Apt",
    SJU: "Luis Munoz Marin Intl Apt",
    SJT: "Mathis Field",
    SJS: "San Jose Apt",
    SJR: "San Juan De Uraba Apt",
    SJQ: "Sesheke Apt",
    SJP: "Sao Jose Do Rio Preto Apt",
    SJO: "Juan Santamaria Apt",
    SJN: "Saint Johns Apt",
    SJM: "San Juan De La Maguana Apt",
    SJL: "Da Cachoeira Apt",
    SJK: "Sao Jose Dos Campos Apt",
    SJJ: "Butmir Apt",
    SJI: "San Jose Apt",
    SJH: "San Juan Del Cesar Apt",
    SJG: "San Pedro Jagua Apt",
    SJF: "Cruz Bay SPB",
    SJE: "San Jose Del Guaviare Apt",
    SJD: "Los Cabos Apt",
    SJC: "San Jose Intl Apt",
    SJB: "San Joaquin Apt",
    SJA: "San Juan Apt",
    SIZ: "Sissano Apt",
    SIY: "Siskiyou County Apt",
    SIX: "Singleton Apt",
    SIW: "Sibisa Apt",
    SIV: "Sullivan County Apt",
    SIU: "Siuna Apt",
    SIT: "Sitka Apt",
    SIS: "Sishen Apt",
    SIR: "Sion Apt",
    SIQ: "Dabo Apt",
    SIP: "Simferopol Apt",
    SIO: "Smithton Apt",
    SIN: "Changi Intl Apt",
    SIM: "Simbai Apt",
    SIL: "Sila Apt",
    SIK: "Sikeston Apt",
    SIJ: "Siglufjordur Apt",
    SII: "Sidi Ifni Apt",
    SIH: "Silgadi Doti Apt",
    SIG: "San Juan FL Ribas Doiminicci",
    SIF: "Simra Apt",
    SIE: "Sines Apt",
    SID: "Sal Apt",
    SIC: "Sinop Apt",
    SIB: "Sibiti Apt",
    SIA: "Xi An Xiguan Military Apt",
    SHZ: "Seshutes Apt",
    SHY: "Shinyanga Apt",
    SHX: "Shageluk Apt",
    SHW: "Sharurah Apt",
    SHV: "Shreveport Regional Apt",
    SHU: "Smith Point Apt",
    SHT: "Shepparton Apt",
    SHS: "Shashi Apt",
    SHR: "Sheridan Apt",
    SHQ: "Southport Apt",
    SHP: "Qinhuangdao Apt",
    SHO: "Seolak Apt",
    SHN: "Sanderson Field",
    SHM: "Nanki Shirahama Apt",
    SHL: "Shillong Apt",
    SHK: "Sehonghong Apt",
    SHJ: "Sharjah Apt",
    SHI: "Shimojishima Apt",
    SHH: "Shishmaref Municipal Apt",
    SHG: "Shungnak Municipal Apt",
    SHF: "Shanhaiguan Apt",
    SHE: "Shenyang Apt",
    SHD: "Shenandoah Valley Regional Apt",
    SHC: "Indaselassie Apt",
    SHB: "Nakashibetsu Apt",
    SHA: "Hongqiao Apt",
    SGZ: "Songkhla Apt",
    SGY: "Skagway Apt",
    SGX: "Songea Apt",
    SGW: "Saginaw Apt",
    SGV: "Sierra Grande Apt",
    SGU: "Saint George Municipal Apt",
    SGT: "Stuttgart Apt",
    SGS: "Sanga Sanga Apt",
    SGR: "Sugar Land Municipal Apt",
    SGQ: "Sanggata Apt",
    SGP: "Shay Gap Apt",
    SGO: "Saint George Apt",
    SGN: "Tan Son Nhut Intl Apt",
    SGM: "San Ignacio Apt",
    SGL: "Sangley Point NAS",
    SGK: "Sangapi Apt",
    SGJ: "Sagarai Apt",
    SGI: "Sargodha Apt",
    SGH: "Beckley Municipal Apt",
    SGG: "Simanggang Apt",
    SGF: "Springfield Regional Apt",
    SGE: "Siegen Apt",
    SGD: "Sonderborg Apt",
    SGC: "Surgut Apt",
    SGB: "Singaua Apt",
    SGA: "Sheghnan Apt",
    SFZ: "Smithfield Apt",
    SFY: "Springfield",
    SFX: "San Felix Apt",
    SFW: "Santa Fe Apt",
    SFV: "Santa Fe Do Sul Apt",
    SFU: "Safia Apt",
    SFT: "Skelleftea Apt",
    SFS: "Subic Bay Intl Apt",
    SFR: "San Fernando Apt",
    SFQ: "Sanliurfa Apt",
    SFP: "Surfers Paradise Apt",
    SFO: "San Francisco Intl Apt",
    SFN: "Santa Fe Municipal Apt",
    SFM: "Sanford Municipal Apt",
    SFL: "Sao Filipe Apt",
    SFK: "Soure Apt",
    SFJ: "Kangerlussauq Apt",
    SFI: "Safi Apt",
    SFH: "San Felipe Apt",
    SFG: "Esperance Apt",
    SFF: "Felts Field",
    SFE: "San Fernando Apt",
    SFD: "Las Flecheras Apt",
    SFC: "Saint Francois Apt",
    SFB: "Central Florida Regional Apt",
    SFA: "Sfax Apt",
    SEZ: "Seychelles Intl Apt",
    SEY: "Selibaby Apt",
    SEX: "Sembach Apt",
    SEW: "Siwa Apt",
    SEV: "Severodoneck Apt",
    SEU: "Seronera Apt",
    SET: "San Esteban Apt",
    SES: "Selfield Apt",
    SER: "Freeman Municipal Apt",
    SEQ: "Sungai Pakning Apt",
    SEP: "Stephenville Apt",
    SEO: "Seguela Apt",
    SEN: "Southend Municipal Arpt",
    SEM: "Craig Field",
    SEL: "Seoul",
    SEK: "Ksar Es Souk Apt",
    SEJ: "Seydisfjordur Apt",
    SEI: "Senhor Do Bonfim Apt",
    SEH: "Senggeh Apt",
    SEG: "Penn Valley Apt",
    SEF: "Sebring Regional Apt",
    SEE: "Gillespie Field",
    SED: "Sedom Apt",
    SEC: "Serre Chevalier Apt",
    SEB: "Sebha Apt",
    SEA: "Seattle/Tacoma Intl Apt",
    SDZ: "Shetland Islands",
    SDY: "Sidney/Richland Apt",
    SDX: "Sedona Apt",
    SDW: "Sandwip Apt",
    SDV: "Yafo Sde Dov Apt",
    SDU: "Santos Dumont Apt",
    SDT: "Saidu Sharif Apt",
    SDS: "Sado Shima Apt",
    SDR: "Santander Apt",
    SDQ: "Las Americas Apt",
    SDP: "Sand Point Apt",
    SDO: "Ryotsu Sado Island Apt",
    SDN: "Adna Apt",
    SDM: "Brown Field Municipal",
    SDL: "Harnosand Apt",
    SDK: "Sandakan Apt",
    SDJ: "Sendai Apt",
    SDI: "Saidor Apt",
    SDH: "Santa Rosa De Copan Apt",
    SDG: "Sanandaj Apt",
    SDF: "Standiford Field",
    SDE: "Santiago del Estero Apt",
    SDD: "Lubango Apt",
    SDC: "Sandcreek Apt",
    SDB: "Saldanha Bay Apt",
    SDA: "Saddam Intl Apt",
    SCZ: "Santa Cruz Island Apt",
    SCY: "San Cristobal Apt",
    SCX: "Salina Cruz Municipal Apt",
    SCW: "Syktyvkar Apt",
    SCV: "Salcea Apt",
    SCU: "Antonio Maceo Apt",
    SCT: "Socotra Apt",
    SCS: "Scatsta Apt",
    SCR: "Scranton Municipal Arpt",
    SCQ: "Santiago De Compostela Apt",
    SCP: "Saint Crepin Apt",
    SCO: "Aktau Apt",
    SCN: "Ensheim Apt",
    SCM: "Scammon Bay Apt",
    SCL: "Arturo Merino Benitez Apt",
    SCK: "Stockton Metropolitan Apt",
    SCJ: "Smith Cove Municipal Apt",
    SCI: "San Cristobal Apt",
    SCH: "Schenectady County Apt",
    SCG: "Spring Creek Apt",
    SCF: "Scottsdale Municipal Apt",
    SCE: "State College Apt",
    SCD: "Sulaco Apt",
    SCC: "Prudhoe Bay Apt",
    SCB: "Scribner Apt",
    SCA: "Santa Catalina Apt",
    SBZ: "Sibiu Apt",
    SBY: "Wicomico County Regional Apt",
    SBX: "Shelby Apt",
    SBW: "Sibu Apt",
    SBV: "Sabah Apt",
    SBU: "Springbok Apt",
    SBT: "Tri-City Apt",
    SBS: "Bob Adams Field",
    SBR: "Saibai Island Apt",
    SBQ: "Sibi Apt",
    SBP: "San Luis Obispo County Apt",
    SBO: "Salina Apt",
    SBN: "South Bend Regional Apt",
    SBM: "Sheboygan Apt",
    SBL: "Yacuma Apt",
    SBK: "Tremuson Saint Brieuc Apt",
    SBJ: "Sao Mateus Apt",
    SBI: "Koundara Apt",
    SBH: "Saint Barthelemy Apt",
    SBE: "Suabi Apt",
    SBD: "San Bernardino Intl Apt",
    SBC: "Selbang Apt",
    SBB: "Santa Barbara Barinas Apt",
    SBA: "Santa Barbara Municipal Apt",
    SAZ: "Sasstown Apt",
    SAY: "Siena Apt",
    SAX: "Sambu Apt",
    SAW: "Sabiha Gokcen Arpt",
    SAV: "Savannah Intl Apt",
    SAU: "Sawu Apt",
    SAT: "San Antonio Intl Apt",
    SAS: "Salton Sea Apt",
    SAR: "Sparta Apt",
    SAQ: "San Andros Apt",
    SAP: "Ramon Villeda Morales Apt",
    SAO: "SAO",
    SAN: "San Diego Intl Apt - Lindbergh Field",
    SAM: "Salamo Apt",
    SAL: "San Salvador Intl Apt",
    SAK: "Saudarkrokur Apt",
    SAJ: "Sirajganj Apt",
    SAI: "San Marino Apt",
    SAH: "Sanaa Apt",
    SAG: "Sagwon Apt",
    SAF: "Santa Fe County Municipal Apt",
    SAE: "Sangir Apt",
    SAD: "Safford Apt",
    SAC: "Executive Apt",
    SAB: "J. Yrausquin Apt",
    SAA: "Saratoga Apt",
    RZZ: "Roanoke Rapids Apt",
    RZY: "Rezayieh",
    RZR: "Ramsar Apt",
    RZH: "Quartz Hill Arpt",
    RZG: "Zaragoza Bus Station",
    RZE: "Rzeszow Apt",
    RZA: "Santa Cruz Apt",
    RYO: "Rio Turbio Municipal Apt",
    RYN: "Royan Apt",
    RYL: "Royal Airport",
    RYK: "Rahim Yar Khan Apt",
    RYB: "Staroselye Airport",
    RXS: "Roxas Apt",
    RXA: "Raudha Apt",
    RWS: "Sumare Arpt",
    RWN: "Rovno Apt",
    RWL: "Rawlins Municipal Apt",
    RWI: "Wilson Apt",
    RWF: "Redwood Falls Apt",
    RWB: "Rowan Bay Apt",
    RVY: "Aeropuerto Departamental de Rivera",
    RVT: "Ravensthorpe Arpt",
    RVS: "Richard Lloyd Jones Jr Riverside Arpt",
    RVR: "Green River Apt",
    RVO: "Reivilo Apt",
    RVN: "Rovaniemi Apt",
    RVK: "Roervik Apt",
    RVH: "Rzhevka Apt",
    RVE: "Saravena Apt",
    RVD: "Rio Verde Apt",
    RVC: "River Cess Apt",
    RVA: "Farafangana Apt",
    RUY: "Copan Apt",
    RUV: "Rubelsanto Apt",
    RUU: "Ruti Apt",
    RUT: "Rutland State Apt",
    RUS: "Marau Island Apt",
    RUR: "Rurutu Apt",
    RUP: "Rupsi Apt",
    RUN: "Saint Denis de la Reunion Apt",
    RUM: "Rumjatar Apt",
    RUK: "Rukumkot Apt",
    RUI: "Sierra Blanca Regional Apt",
    RUH: "King Khalid Apt",
    RUG: "Rugao Apt",
    RUF: "Yuruf Apt",
    RUA: "Arua Apt",
    RTY: "Merty Apt",
    RTW: "Saratov Apt",
    RTS: "Rottnest Island Apt",
    RTP: "Rutland Plains Apt",
    RTO: "Colton",
    RTN: "Raton Municipal Apt",
    RTM: "Rotterdam Apt",
    RTL: "Spirit Lake Apt",
    RTI: "Roti Apt",
    RTG: "Ruteng Apt",
    RTE: "Marguerite Bay Apt",
    RTD: "Rotunda Apt",
    RTC: "Ratnagiri Apt",
    RTB: "Roatan Apt",
    RTA: "Rotuma Island Apt",
    RSW: "Southwest Florida Regional Apt",
    RSU: "Yosu Apt",
    RST: "Rochester Municipal Apt",
    RSS: "Roseires Apt",
    RSP: "Raspberry Strait Arpt",
    RSN: "Ruston Municipal Apt",
    RSL: "Russell Municipal Apt",
    RSK: "Ransiki Apt",
    RSJ: "Rosario Seaplane Base",
    RSI: "Rio Sidra Apt",
    RSH: "Russian Mission Apt",
    RSG: "Serra Pelada Apt",
    RSE: "Au-Rose Bay Apt",
    RSD: "Rock Sound Apt",
    RSB: "Roseberth Apt",
    RSA: "Santa Rosa Municipal Apt",
    RRV: "Robinson River Apt",
    RRT: "Warroad Apt",
    RRS: "Roros Apt",
    RRO: "Sorrento Apt",
    RRN: "Serra Norte Apt",
    RRM: "Marromeu Apt",
    RRL: "Merrill Apt",
    RRK: "Rourkela Apt",
    RRI: "Barora Apt",
    RRG: "Rodrigues Island Apt",
    RRE: "Marree Apt",
    RRA: "Ronda Rail Station",
    RPX: "Roundup Apt",
    RPV: "Roper Valley Apt",
    RPR: "Raipur Apt",
    RPN: "Rosh Pina Apt",
    RPB: "Roper Bar Apt",
    RPA: "Rolpa Apt",
    ROZ: "Rota Naval Station Arpt",
    ROY: "Rio Mayo Apt",
    ROX: "Roseau Apt",
    ROW: "Roswell Industrial Air Center",
    ROV: "Rostov Apt",
    ROU: "Ruse Apt",
    ROT: "Rotorua Apt",
    ROS: "Fisherton Apt",
    ROR: "Airal Apt",
    ROP: "Rota Intl Apt",
    ROO: "Rondonopolis Apt",
    RON: "Rondon Apt",
    ROM: "Rome Metropolitan Area",
    ROL: "Roosevelt Apt",
    ROK: "Rockhampton Apt",
    ROI: "Roi Et Apt",
    ROH: "Robinhood Apt",
    ROG: "Rogers Apt",
    ROD: "Robertson Apt",
    ROC: "Greater Rochester Intl Apt",
    ROB: "Roberts Intl Apt",
    ROA: "Roanoke Regional Apt",
    RNZ: "Jasper County Apt",
    RNX: "Shaoxing Rail Station",
    RNU: "Ranau Apt",
    RNT: "Renton Municipal Apt",
    RNS: "Saint Jacques Apt",
    RNR: "Robinson River Apt",
    RNP: "Rongelap Island Apt",
    RNO: "Cannon Intl Apt",
    RNN: "Bornholm Apt",
    RNL: "Rennell Apt",
    RNJ: "Yoronjima Apt",
    RNI: "Corn Island Apt",
    RNH: "New Richmond Apt",
    RNG: "Rangely Apt",
    RNE: "Renaison Apt",
    RND: "Randolph Airfoce Base",
    RNC: "McMinnville Apt",
    RNB: "Ronneby Apt",
    RNA: "Ulawa Apt",
    RMY: "Mariposa Yosemite Arpt",
    RMT: "Rimatara Airport",
    RMS: "Ramsteim Arpt",
    RMQ: "Ching Chuan Kang",
    RMP: "Rampart Municipal Apt",
    RMN: "Rumginae Apt",
    RML: "Ratmalana Apt",
    RMK: "Renmark Apt",
    RMI: "Miramare Apt",
    RMG: "Rome Apt",
    RMF: "Marsa Alam Arpt",
    RME: "Rome Apt",
    RMD: "Ramagundam Apt",
    RMB: "Buraimi Apt",
    RMA: "Roma Apt",
    RLU: "Bornite Apt",
    RLT: "Arlit Apt",
    RLP: "Rosella Plains Apt",
    RLO: "Valle Del Conlara Arpt",
    RLK: "Bayannur Airport",
    RLI: "Reilly Ahp",
    RLG: "Rostock-Laage Apt",
    RLD: "Richland Apt",
    RLA: "Rolla Apt",
    RKZ: "He Ping Airport",
    RKY: "Rokeby Apt",
    RKW: "Rockwood Apt",
    RKV: "Reykjavik (Domestic) Apt",
    RKU: "Kairuku Apt",
    RKT: "Ras Al Khaimah Apt",
    RKS: "Sweetwater County Apt",
    RKP: "Rockport Apt",
    RKO: "Sipora Apt",
    RKI: "Rokot Apt",
    RKH: "Rock Hill Apt",
    RKE: "Roskilde Apt",
    RKD: "Knox County Regional Apt",
    RKC: "Yreka Apt",
    RJN: "Rafsanjan Apt",
    RJL: "Agoncillo Arpt",
    RJK: "Rijeka Apt",
    RJI: "Rajouri Apt",
    RJH: "Rajshahi Apt",
    RJB: "Rajbiraj Apt",
    RJA: "Rajahmundry Apt",
    RIZ: "Rio Alzucar Apt",
    RIY: "Riyan Mukalla Apt",
    RIX: "Riga Apt",
    RIW: "Riverton Regional Apt",
    RIT: "Rio Tigre Apt",
    RIS: "Rishiri Apt",
    RIO: "RIO",
    RIN: "Ringi Cove Apt",
    RIM: "Rodriguez De Mendoza Apt",
    RIL: "Garfield County Regional Apt",
    RIK: "Carrillo Municipal Apt",
    RIJ: "Rioja Municipal Apt",
    RII: "Soria Rail Station",
    RIH: "Scarlett Martinez Intl Arpt",
    RIG: "Rio Grande Apt",
    RIF: "Richfield Apt",
    RIE: "Rice Lake Apt",
    RID: "Richmond Municipal Apt",
    RIC: "Richmond Intl Apt",
    RIB: "General Buech Apt",
    RIA: "Base Aerea Apt",
    RHV: "Reid Hillview Of Santa Clara County",
    RHT: "Alxa Right Banner Arpt",
    RHP: "Ramechhap Apt",
    RHO: "Diagoras Apt",
    RHN: "Rosh Pina Airport",
    RHL: "Roy Hill Apt",
    RHI: "Rhinelander Apt",
    RHG: "Ruhengeri Apt",
    RHE: "Champagne Apt",
    RHD: "Rio Hondo Apt",
    RHA: "Reykholar Apt",
    RGT: "Rengat Apt",
    RGS: "Burgos Arpt",
    RGR: "Ranger Apt",
    RGN: "Mingaladon Apt",
    RGL: "Rio Gallegos Intl Apt",
    RGK: "Gorno Altaysk Airport",
    RGI: "Rangiroa Island Apt",
    RGH: "Balurghat Apt",
    RGE: "Porgera Apt",
    RGA: "Rio Grande Municipal Apt",
    RFS: "Rosita Apt",
    RFR: "Rio Frio Apt",
    RFP: "Uturoa Apt",
    RFN: "Raufarhofn Apt",
    RFK: "Anguilla Apt",
    RFG: "Rooke Field",
    RFD: "Greater Rockford Apt",
    RFA: "Rafai Apt",
    REZ: "Resende Apt",
    REY: "Reyes Apt",
    REX: "General Lucio Blanco Apt",
    REW: "Rewa Apt",
    REU: "Reus Apt",
    RET: "Rost Stolport",
    RES: "Resistencia Apt",
    RER: "Base Aerea del Sur Apt",
    REP: "Siem Reap Apt",
    REO: "Rome State Apt",
    REN: "Orenburg Apt",
    REL: "Trelew Apt",
    REK: "Reykjavik",
    REG: "Tito Menniti Apt",
    REE: "Reese Airforce Base",
    RED: "Mifflin County Apt",
    REC: "Guararapes Intl Apt",
    REB: "Rechlin Apt",
    REA: "Reao Apt",
    RDZ: "Marcillac Apt",
    RDV: "Red Devil Municipal Apt",
    RDU: "Raleigh-Durham Intl Apt",
    RDT: "Richard Toll Apt",
    RDS: "Rincon De Los Sauces Apt",
    RDR: "Red River Apt",
    RDN: "Lts Pulau Redang Apt",
    RDM: "Roberts Field",
    RDL: "Roosevelt",
    RDG: "Reading Municipal Apt",
    RDE: "Merdey Apt",
    RDD: "Redding Municipal Apt",
    RDC: "Redencao Apt",
    RDB: "Red Dog Apt",
    RDA: "Rockhampton Downs Apt",
    RCY: "Rum Cay Apt",
    RCU: "Rio Cuarto Municipal Apt",
    RCT: "Nartron Field",
    RCS: "Rochester Apt",
    RCR: "Fulton County Apt",
    RCQ: "Reconquista Apt",
    RCO: "Rochefort Apt",
    RCN: "American River Apt",
    RCM: "Richmond Apt",
    RCL: "Redcliffe Apt",
    RCK: "Rockdale Apt",
    RCH: "Riohacha Municipal Apt",
    RCE: "Roche Harbor Apt",
    RCB: "Richards Bay Apt",
    RCA: "Ellsworth Airforce Base",
    RBY: "Ruby Municipal Apt",
    RBW: "Walterboro Apt",
    RBV: "Ramata Apt",
    RBU: "Roebourne Apt",
    RBT: "Marsabit Apt",
    RBS: "Orbost Apt",
    RBR: "Presidente Medici Apt",
    RBQ: "Rurrenabaque Apt",
    RBP: "Rabaraba Apt",
    RBO: "Robore Apt",
    RBM: "Straubing Apt",
    RBL: "Red Bluff Municipal Apt",
    RBK: "Rancho Apt",
    RBJ: "Rebun Apt",
    RBI: "Rabi Apt",
    RBH: "Brooks Lodge Municipal Apt",
    RBG: "Roseburg Municipal Apt",
    RBF: "Big Bear City Apt",
    RBE: "Ratanankiri Apt",
    RBD: "Redbirdarpt",
    RBC: "Robinvale Apt",
    RBB: "Borba Apt",
    RBA: "Rabat Apt",
    RAZ: "Rawala Kot Apt",
    RAY: "Rothesay Apt",
    RAW: "Arawa Apt",
    RAV: "Cravo Norte Apt",
    RAU: "Rangpur Apt",
    RAT: "Raduzhnyi Apt",
    RAS: "Rasht Apt",
    RAR: "Rarotonga Intl Apt",
    RAQ: "Raha Apt",
    RAP: "Rapid City Regional Apt",
    RAO: "Leite Lopes Apt",
    RAN: "Ravenna Apt",
    RAM: "Ramingining Apt",
    RAL: "Riverside Municipal Apt",
    RAK: "Marrakech Apt",
    RAJ: "Rajkot Apt",
    RAI: "Praia Apt",
    RAH: "Rafha Apt",
    RAG: "Raglan Apt",
    RAF: "Ras An Naqb Apt",
    RAE: "Arar Apt",
    RAD: "Tortola Road Town SPB",
    RAC: "John H. Batten Apt",
    RAB: "Tokua Apt",
    RAA: "Rakanda Apt",
    QZD: "QZD",
    QZB: "Zermatt Rail Station",
    QYX: "Uppsala",
    QYW: "Le Vieux Port Harbour",
    QYV: "Deventer Rail Station",
    QYU: "Gavle C Rail Station",
    QYR: "Troyes Apt",
    QYP: "QYP",
    QYO: "Olsztyn Arpt",
    QYM: "Amersfoort Railway Service",
    QYL: "Almelo Rail Station",
    QYI: "Hilversum Rail Station",
    QYH: "Hengelo Railway Service",
    QYE: "Enschede Railway Station",
    QXV: "Svendborg Rail Station",
    QXG: "Saint-Laud Railway Station",
    QXD: "Cachoeiro Itapemirim Apt",
    QXB: "Aix-en-Provence TGV Rail Station",
    QWY: "Albany Bus Station",
    QWU: "QWU",
    QWM: "Longmont Bus Service",
    QWH: "Loveland Bus Service",
    QWG: "Wilgrove Air Parkarpt",
    QWF: "Fort Collins Bus Service",
    QWD: "Mittenwald Airport",
    QWC: "Berlin Zoo Rail Station",
    QWB: "Berlin Ostbhf Rail Station",
    QWA: "Oshawa Apt",
    QUL: "Ulm Rail Station",
    QTQ: "Trento",
    QTK: "QTK",
    QTJ: "Chartres Apte",
    QSR: "Salerno Offline Point",
    QSG: "Sonderborg Rail Station",
    QSF: "San Francisco Metropolitan Area",
    QSC: "Mario Pereira Lopes Arpt",
    QRW: "Warri Airport",
    QRV: "Arras Arpt",
    QRO: "Queretaro Municipal Apt",
    QRH: "Rotterdam Central Station",
    QRE: "Carazinho Apt",
    QRA: "Randgermiston Arpt",
    QQY: "York",
    QQX: "QQX",
    QQW: "Waterloo station",
    QQU: "Euston railway station, England",
    QQS: "St Pancras Intl Rail Station",
    QQR: "Ramsgate Rail Station",
    QQP: "Paddington Rail Station",
    QQN: "Birmingham New Street railway station",
    QQM: "Manchester Piccadilly station",
    QQK: "Kings Cross Rail Station",
    QQH: "Harwich Rail Station",
    QQD: "Dover Priory Rail Station",
    QPP: "Berlin HBF Rail Station",
    QPG: "Paya Lebar Arpt",
    QPA: "Padova Arpt",
    QOW: "Sam Mbakwe Airport",
    QOB: "Ansbach Rail Station",
    QNY: "Marine Air Terminal",
    QNX: "Macon Apt",
    QNT: "Niteroi Arpt",
    QNM: "Namur Rail Station",
    QNJ: "Annemasse Apt",
    QNC: "Neuchatel Rail Station",
    QNA: "Ballina Apt",
    QMZ: "QMZ",
    QMO: "Mons Rail Station",
    QMM: "Marina Di Massa Apt",
    QLS: "Lausanne Railway Station",
    QLQ: "Lleida Rail Station",
    QLJ: "K\u00F6ln Hauptbahnhof",
    QLE: "Leeton Arpt",
    QLA: "Los Angeles Metropolitan Area",
    QKU: "Cologne Railway Station",
    QKS: "Keystone Van Service",
    QKL: "K\u00F6ln Hauptbahnhof",
    QKI: "Kielce",
    QKB: "Breckenridge Van Service",
    QJZ: "Nantes Railway Station",
    QJV: "Skagen Apt",
    QIN: "Mersin Apt",
    QIJ: "Gijon Rail Station",
    QII: "QII",
    QIG: "Iguatu Arpt",
    QHX: "Qionghai Rail Station",
    QHV: "Novo Hamburgo Arpt",
    QHD: "Heidelberg Rail Station",
    QHA: "QHA",
    QGY: "Gyor Airport",
    QGV: "Frankfurt Neu Isenburg Rail Station",
    QGN: "Tarragona/Camp Rail Station",
    QGL: "St Gallen Rail Station",
    QFZ: "Saarbruecken HBF Rail Station",
    QFV: "Bergen Railway Station",
    QFQ: "Harbour Arpt",
    QFH: "Frederikshavn",
    QFB: "Freiburg Hauptbahnhof",
    QEW: "Leicester Rail Station",
    QEQ: "Embrach/Rorbas Rail Station",
    QDU: "D\u00FCsseldorf Hauptbahnhof",
    QDK: "Greyhound Bus Service",
    QDH: "Ashford Intl Rail Station",
    QCE: "Copper Mountain Van Service",
    QBO: "Bochum Rail Station",
    QBF: "Vail Van Service",
    QBC: "Bella Coola Municipal Apt",
    QAW: "Ft Mcclellan Railway Stn",
    QAR: "QAR",
    PZY: "Piestany Apt",
    PZU: "Port Sudan Apt",
    PZS: "Manquehue Arpt",
    PZO: "Puerto Ordaz Apt",
    PZL: "Phinda Game Reserve Apt",
    PZK: "Puka Puka Island Apt",
    PZI: "Pan Zhi Hua Bao An Ying Arpt",
    PZH: "Zhob Apt",
    PZE: "Penzance Apt",
    PZC: "Penzance Rail Station",
    PZB: "Pietermaritzburg Apt",
    PZA: "Casanare Apt",
    PYY: "Pai Arpt",
    PYX: "Pattaya Apt",
    PYV: "Yaviza Apt",
    PYR: "Pyrgos Apt",
    PYO: "Putumayo Apt",
    PYN: "Payan Apt",
    PYM: "Plymouth Apt",
    PYL: "Perry Island Apt",
    PYJ: "Polyarnyj Apt",
    PYH: "Puerto Ayacucho Apt",
    PYE: "Penrhyn Island Apt",
    PYC: "Playon Chico Apt",
    PYB: "Jeypore Apt",
    PYA: "Puerto Boyaca Apt",
    PXU: "Pleiku Apt",
    PXS: "Puerto De Santa Maria Rail Station",
    PXO: "Porto Santo Apt",
    PXM: "Puerto Escondido Apt",
    PXL: "Polacca Apt",
    PWT: "Bremerton National Apt",
    PWR: "Port Walter Apt",
    PWQ: "Pavlodar Apt",
    PWO: "Pweto Apt",
    PWN: "Pitts Town Apt",
    PWM: "Portland Intl Jetport",
    PWL: "Purwokerto Apt",
    PWK: "Pal Waukee Arpt",
    PWI: "Beles Apt",
    PWE: "Pevek Apt",
    PWD: "Plentywood Apt",
    PWA: "Wiley Post Arpt",
    PVZ: "Casement Apt",
    PVY: "Pope Vanoy Apt",
    PVW: "Plainview Apt",
    PVU: "Provo Apt",
    PVS: "Provideniya Apt",
    PVR: "Gustavo Diaz Ordaz Apt",
    PVO: "Portoviejo Apt",
    PVN: "Pleven Apt",
    PVL: "Pike County Regional Arpt",
    PVK: "Aktion Apt",
    PVI: "Paranavai Apt",
    PVH: "Belmonte Apt",
    PVG: "Pu Dong Apt",
    PVF: "Placerville Apt",
    PVE: "El Porvenir Apt",
    PVD: "Theodore Francis Green Apt",
    PVC: "Provincetown Municipal Apt",
    PVA: "Providencia Apt",
    PUZ: "Puerto Cabezas Apt",
    PUY: "Pula Apt",
    PUX: "Puerto Varas Apt",
    PUW: "Pullman/Moscow Regional Apt",
    PUV: "Poum Apt",
    PUU: "Puerto Asis Apt",
    PUT: "Puttaparthi Apt",
    PUS: "Gimhae Apt",
    PUR: "Puerto Rico Apt",
    PUQ: "Presidente Ibanez Apt",
    PUP: "Po Apt",
    PUN: "Punia Apt",
    PUM: "Pomala Apt",
    PUK: "Pukarua Apt",
    PUJ: "Punta Cana Apt",
    PUI: "Pureni Apt",
    PUH: "Pochutla Apt",
    PUG: "Pagas Apt",
    PUF: "Uzein Apt",
    PUE: "Puerto Obaldia Apt",
    PUD: "Puerto Deseado Municipal Apt",
    PUC: "Price Apt",
    PUB: "Pueblo Memorial Apt",
    PUA: "Puas Apt",
    PTZ: "Pastaza Apt",
    PTY: "General Omar Torrijos Herrara Intl Airprt",
    PTX: "Pitalito Apt",
    PTW: "Pottstown Apt",
    PTV: "Porterville Municipal Apt",
    PTU: "Platinum Apt",
    PTT: "Pratt Industrial Apt",
    PTS: "Atkinson Municipal Apt",
    PTR: "Pleasant Harbour Apt",
    PTQ: "Porto De Moz Apt",
    PTP: "Le Raizet Apt",
    PTO: "Pato Branco Apt",
    PTN: "Harry P. Williams Memorial Apt",
    PTM: "Palmarito Apt",
    PTL: "Port Armstrong Apt",
    PTK: "Pontiac Apt",
    PTJ: "Portland Apt",
    PTI: "Port Douglas Apt",
    PTH: "Port Heiden Apt",
    PTG: "Pietersburg Apt",
    PTF: "Malololailai Apt",
    PTE: "Port Stephens Apt",
    PTD: "Port Alexander Municipal Apt",
    PTC: "Port Alice Apt",
    PTB: "Petersburg Municipal Apt",
    PTA: "Port Alsworth Municipal Apt",
    PSZ: "Puerto Suarez Apt",
    PSY: "Port Stanley Apt",
    PSX: "Palacios Apt",
    PSW: "Passos Apt",
    PSV: "Papa Stour Apt",
    PSU: "Putussibau Apt",
    PST: "Preston Apt",
    PSS: "Posadas Municipal Apt",
    PSR: "Liberi Apt",
    PSQ: "Essington Philadelphia Seaplane Base",
    PSP: "Palm Springs Regional Apt",
    PSO: "Cano Apt",
    PSN: "Palestine Apt",
    PSM: "Portsmouth Apt",
    PSL: "Perth Apt",
    PSK: "Pulaski Apt",
    PSJ: "Poso Apt",
    PSI: "Pasni Apt",
    PSH: "Saint Peter-Ording Apt",
    PSG: "Petersburg Apt",
    PSF: "Pittsfield Municipal Apt",
    PSE: "Mercedita Arpt",
    PSD: "Port Said Apt",
    PSC: "Tri-Cities Apt",
    PSB: "Philipsburg Apt",
    PSA: "G. Galilei Apt",
    PRZ: "Prineville Apt",
    PRY: "Wonderboom Apt",
    PRX: "Paris Apt",
    PRW: "Prentice Apt",
    PRV: "Prerov Apt",
    PRU: "Prome Apt",
    PRT: "Point Retreat Apt",
    PRS: "Parasi Apt",
    PRR: "Paruima Apt",
    PRQ: "President R Saenz Pena Apt",
    PRP: "Propriano Apt",
    PRO: "Perry Municipal Apt",
    PRN: "Pristina Apt",
    PRM: "Portimao Apt",
    PRL: "Port Oceanic Apt",
    PRK: "Prieska Apt",
    PRJ: "Capri Apt",
    PRI: "Praslin Island Apt",
    PRH: "Phrae Apt",
    PRG: "Ruzyne Apt",
    PRF: "Port Johnson Apt",
    PRE: "Pore Apt",
    PRD: "Pardoo Apt",
    PRC: "Ernest A. Love Field",
    PRB: "Paso Robles Municipal Apt",
    PRA: "Parana Apt",
    PQS: "Pilot Station Apt",
    PQQ: "Port Macquarie Apt",
    PQM: "Palenque Municipal Apt",
    PQI: "Presque Isle Apt",
    PQC: "Phuquoc Apt",
    PPZ: "Puerto Paez Apt",
    PPY: "Pouso Alegre Apt",
    PPX: "Param Apt",
    PPW: "Papa Westray Apt",
    PPV: "Port Protection Sea Plane Base",
    PPU: "Papun Apt",
    PPT: "Tahiti Faaa Apt",
    PPS: "Puerto Princesa Intl Apt",
    PPR: "Pasir Pangarayan Apt",
    PPQ: "Paraparaumu Apt",
    PPP: "Proserpine Apt",
    PPO: "Powell Point Apt",
    PPN: "Machangra Apt",
    PPM: "Pompano Beach Airpark",
    PPL: "Phaplu Apt",
    PPK: "Petropavlovsk Apt",
    PPJ: "Pulau Panjang Apt",
    PPI: "Port Pirie Apt",
    PPH: "Peraitepuy Apt",
    PPG: "Pago Pago Intl Apt",
    PPF: "Tri-City Apt",
    PPE: "Puerto Penasco Apt",
    PPD: "Palmas Del Mar",
    PPC: "Prospect Creek Apt",
    PPB: "President Prudente Apt",
    PPA: "Pampa Apt",
    POZ: "Lawica Apt",
    POY: "Powell Apt",
    POX: "Cormeille En Vexin",
    POW: "Portoroz Apt",
    POV: "Presov Apt",
    POU: "Dutchess County Apt",
    POT: "Ken Jones Apt",
    POS: "Piarco Apt",
    POR: "Pori Apt",
    POQ: "Polk Inlet Apt",
    POP: "La Union Apt",
    POO: "Pocos De Caldas Apt",
    PON: "Poptun Apt",
    POM: "Jackson Field",
    POL: "Pemba Apt",
    POK: "St Polton",
    POJ: "Patos De Minas Apt",
    POI: "Potosi Municipal Apt",
    POH: "Pocahontas Apt",
    POG: "Port Gentil Apt",
    POF: "Poplar Bluff Apt",
    POE: "Fort Polk Arpt",
    POD: "Podor Apt",
    POC: "Brackett Field",
    POB: "Pope Airforce Base",
    POA: "Salgado Filho Apt",
    PNZ: "Petrolina Apt",
    PNY: "Pondicherry Airport",
    PNX: "Sherman-Denison Apt",
    PNV: "Panevezys Apt",
    PNU: "Panguitch Apt",
    PNT: "Ten J Gallardo Apt",
    PNS: "Pensacola Regional Apt",
    PNR: "Pointe Noire Apt",
    PNQ: "Lohegaon Apt",
    PNP: "Girua Apt",
    PNO: "Pinotepa Nacional Apt",
    PNN: "Princeton Municipal Apt",
    PNL: "Pantelleria Apt",
    PNK: "Supadio Apt",
    PNJ: "Sha He Kou Arpt",
    PNI: "Pohnpei Intl Apt",
    PNH: "Pochentong Apt",
    PNG: "Paranagua Municipal Apt",
    PNF: "Petersons Point Apt",
    PNE: "N Philadelphia",
    PND: "Punta Gorda Apt",
    PNC: "Ponca City Municipal Apt",
    PNB: "Porto Nacional Apt",
    PNA: "Noain Apt",
    PMZ: "Palmar Sur Apt",
    PMY: "Puerto Madryn Apt",
    PMX: "Palmer Metropolitan Apt",
    PMW: "Palmas Apt",
    PMV: "Porlamar Apt",
    PMU: "Paimiut Apt",
    PMT: "Paramakatoi Apt",
    PMS: "Palmyra Apt",
    PMR: "Palmerston North Apt",
    PMQ: "Perito Moreno Apt",
    PMP: "Pimaga Apt",
    PMO: "Punta Raisi Apt",
    PMN: "Pumani Apt",
    PMM: "Phanom Sarakham Apt",
    PML: "Port Moller Municipal Apt",
    PMK: "Palm Island Apt",
    PMI: "Palma Mallorca Apt",
    PMH: "Greater Portsmouth Regional Apt",
    PMG: "Ponta Pora Intl Apt",
    PMF: "Parma Apt",
    PME: "Portsmouth Apt",
    PMD: "La Palmdale Regional Arpt",
    PMC: "Tepual Apt",
    PMB: "Pemina Apt",
    PMA: "Pemba Apt",
    PLZ: "Port Elizabeth Apt",
    PLY: "Plymouth Apt",
    PLX: "Semipalatinsk Apt",
    PLW: "Mutiara Apt",
    PLV: "Poltava Apt",
    PLU: "Pamphula Apt",
    PLT: "Plato Apt",
    PLS: "Providenciales Intl Apt",
    PLR: "Pell City Apt",
    PLQ: "Palanga Apt",
    PLP: "La Palma Apt",
    PLO: "Port Lincoln Apt",
    PLN: "Emmet County Apt",
    PLM: "Mahmd Badaruddin II Apt",
    PLL: "Ponta Pelada Apt",
    PLK: "Point Lookout Apt",
    PLJ: "Placencia Apt",
    PLI: "Palm Island Apt",
    PLH: "Roborough Apt",
    PLG: "La Plagne Apt",
    PLF: "Pala Apt",
    PLE: "Paiela Apt",
    PLD: "Playa Samara Apt",
    PLC: "Planeta Rica Apt",
    PLB: "Clinton County Apt",
    PLA: "Planadas Apt",
    PKZ: "Pakse Apt",
    PKY: "Panarung Apt",
    PKW: "Selebi-Phikwe Apt",
    PKV: "Pskov Apt",
    PKU: "Simpang Tiga Apt",
    PKT: "Port Keats Apt",
    PKS: "Paksane Apt",
    PKR: "Pokhara Apt",
    PKP: "Puka Puka Apt",
    PKO: "Parakou Apt",
    PKN: "Pangkalanbun Apt",
    PKM: "Port Kaituma Apt",
    PKL: "Pakatoa Island Apt",
    PKK: "Pakokku Apt",
    PKJ: "Playa Grande Apt",
    PKI: "Pakotoa Island",
    PKH: "Porto Kheli Apt",
    PKG: "Pangkor Apt",
    PKF: "Park Fall Apt",
    PKE: "Parkes Apt",
    PKD: "Park Rapids Apt",
    PKC: "Petropavlovsk-Kamchats Apt",
    PKB: "Wood County Apt",
    PKA: "Napaskiak Municipal Apt",
    PJZ: "Puerto Juarez Apt",
    PJS: "Port San Juan Apt",
    PJM: "Puerto Jimenez Apt",
    PJG: "Panjgur Apt",
    PJC: "Pedro Juan Caballero Apt",
    PJB: "Payson Apt",
    PJA: "Pajala Apt",
    PIZ: "Point Lay Municipal Apt",
    PIX: "Pico Island Apt",
    PIW: "Pikwitonei Rail Station",
    PIV: "Pirapora Apt",
    PIU: "Piura Apt",
    PIT: "Pittsburgh Intl Apt",
    PIS: "Biard Apt",
    PIR: "Pierre Regional Apt",
    PIQ: "Pipillipai Apt",
    PIP: "Pilot Point Apt",
    PIO: "Pisco Apt",
    PIN: "Parintins Apt",
    PIM: "Pine Mountain Apt",
    PIL: "Pilar Apt",
    PIK: "Prestwick Apt",
    PII: "Fairbanks Phillips Airport",
    PIH: "Pocatello Municipal Apt",
    PIG: "Pitinga Apt",
    PIF: "Pingtung Apt",
    PIE: "Saint Petersburg/Clearwater Intl Apt",
    PID: "Paradise Island Apt",
    PIC: "Pine Cay Apt",
    PIB: "Hattiesburg",
    PIA: "Greater Peoria Regional Apt",
    PHZ: "Phi Phi Island Apt",
    PHY: "Phetchabun Apt",
    PHX: "Sky Harbor Intl Apt",
    PHW: "Phalaborwa Apt",
    PHU: "Phuvinh Apt",
    PHT: "Henry County Arpt",
    PHS: "Phitsanulok Apt",
    PHR: "Pacific Harbour Apt",
    PHP: "Philip Apt",
    PHO: "Point Hope Municipal Apt",
    PHN: "Port Huron Apt",
    PHM: "Boeblingen Apt",
    PHL: "Philadelphia Intl Apt",
    PHK: "Pahokee Apt",
    PHJ: "Port Hunter Apt",
    PHI: "Pinheiro Apt",
    PHH: "Phanthiet Apt",
    PHG: "Port Harcourt City Arpt",
    PHF: "Newport News/Williamsburg Intl Apt",
    PHE: "Port Hedland Apt",
    PHD: "Harry Clever Field",
    PHC: "Port Harcourt Apt",
    PHB: "Parnaiba Apt",
    PHA: "Phanrang Apt",
    PGZ: "Santa Ana Apt",
    PGX: "Perigueux Apt",
    PGV: "Pitt-Greenville Apt",
    PGS: "Peach Springs Apt",
    PGR: "Paragould Apt",
    PGP: "Porto Alegre Apt",
    PGO: "Stevens Field",
    PGN: "Pangia Apt",
    PGM: "Port Graham Apt",
    PGL: "Pascagoula Apt",
    PGK: "Pangkalpinang Apt",
    PGI: "Chitato Apt",
    PGH: "Pantnagar Apt",
    PGG: "Progresso Apt",
    PGF: "Rivesaltes Apt",
    PGE: "Yegepa Apt",
    PGD: "Charlotte County Apt",
    PGC: "Petersburg Apt",
    PGB: "Pangoa Apt",
    PGA: "Page Municipal Apt",
    PFR: "Ilebo Apt",
    PFO: "Paphos Intl Apt",
    PFN: "Bay County Apt",
    PFL: "Florenc Bus Station",
    PFJ: "Patreksfjordur Apt",
    PFE: "Ponferrada Bus Station",
    PFD: "Port Frederick Apt",
    PFC: "Pacific City Apt",
    PFB: "Lauro Kurtz Apt",
    PFA: "Paf Warren Municipal Apt",
    PEZ: "Penza Apt",
    PEY: "Penong Apt",
    PEX: "Penchora Apt",
    PEW: "Peshawar Apt",
    PEV: "Pecs Arpt",
    PEU: "Puerto Lempira Apt",
    PET: "Federal Apt",
    PES: "Petrozavodsk Apt",
    PER: "Perth Apt",
    PEQ: "Pecos Apt",
    PEP: "Peppimenarti Apt",
    PEN: "Penang Intl Apt",
    PEM: "Puerto Maldonado Apt",
    PEL: "Pelaneng Apt",
    PEK: "Beijing Capital Apt",
    PEJ: "Peschiei Apt",
    PEI: "Matecana Apt",
    PEH: "Comodoro Zanni Apt",
    PEG: "Saint Egidio Apt",
    PEF: "Peenemuende Apt",
    PEE: "Perm Apt",
    PED: "Pardubice Apt",
    PEB: "Pebane Apt",
    PEA: "Penneshaw Apt",
    PDZ: "Pedernales Apt",
    PDX: "Portland Intl Apt",
    PDV: "Plovdiv Apt",
    PDU: "Paysandu Municipal Apt",
    PDT: "Pendelton Apt",
    PDS: "Piedras Negras Municipal Apt",
    PDR: "Presidente Dutra Apt",
    PDP: "Punta del Este Apt",
    PDO: "Pendopo Apt",
    PDN: "Parndana Apt",
    PDL: "Ponta Delgada Apt",
    PDK: "Dekalb Peachtree",
    PDI: "Pindiu Apt",
    PDG: "Tabing Apt",
    PDF: "Prado Apt",
    PDE: "Pandie Pandie Apt",
    PDC: "Mueo Apt",
    PDB: "Pedro Bay Municipal Apt",
    PDA: "Puerto Inirida Apt",
    PCW: "Par Railway Station",
    PCV: "Punta Chivato Apt",
    PCU: "Picayune Apt",
    PCT: "Princeton Apt",
    PCS: "Picos Apt",
    PCR: "Puerto Carreno Apt",
    PCP: "Principe Island Apt",
    PCO: "Punta Colorada Apt",
    PCN: "Picton Apt",
    PCM: "Playa Del Carmen Apt",
    PCL: "Capitan Rolden Apt",
    PCK: "Porcupine Creek Apt",
    PCJ: "Puerto La Victoria Arpt",
    PCI: "Palencia Rail Station",
    PCH: "Palacios Apt",
    PCG: "Paso Caballos Apt",
    PCE: "Painter Creek Apt",
    PCD: "Prairie Du Chien Apt",
    PCC: "Puerto Rico Apt",
    PCB: "Pondok Cabe Apt",
    PCA: "Portage Creek Apt",
    PBZ: "Plettenberg Bay Apt",
    PBX: "Porto Alegre Do Norte Apt",
    PBW: "Palibelo Apt",
    PBV: "Portos Dos Gauchos Apt",
    PBU: "Putao Apt",
    PBS: "Patong Beach Apt",
    PBR: "Puerto Barrios Apt",
    PBQ: "Pimenta Bueno Apt",
    PBP: "Punta Islita Apt",
    PBO: "Paraburdoo Apt",
    PBN: "Porto Amboim Apt",
    PBM: "Zanderij Intl Apt",
    PBL: "Puerto Cabello Apt",
    PBK: "Pack Creek Apt",
    PBJ: "Paama Apt",
    PBI: "Palm Beach Intl Apt",
    PBH: "Paro Apt",
    PBF: "Pine Bluff Apt",
    PBE: "Puerto Berrio Municipal Apt",
    PBD: "Porbandar Apt",
    PBC: "Hermanos Serdan Apt",
    PBB: "Paranaiba Apt",
    PBA: "Point Barrow Apt",
    PAZ: "Poza Rica Apt",
    PAY: "Pamol Apt",
    PAX: "Port De Paix Apt",
    PAW: "Pambwa Apt",
    PAV: "Paulo Afonso Apt",
    PAU: "Pauk Apt",
    PAT: "Patna Apt",
    PAS: "Paros Apt",
    PAR: "Paris Metropolitan Area",
    PAQ: "Palmer Apt",
    PAP: "Mais Gate Apt",
    PAO: "Palo Alto Apt",
    PAN: "Pattani Apt",
    PAM: "Tyndall Air Force Base",
    PAL: "Palanquero Apt",
    PAK: "Port Allen Apt",
    PAJ: "Para Chinar Apt",
    PAI: "Pailin Apt",
    PAH: "Barkley Regional Apt",
    PAG: "Pagadian Apt",
    PAF: "Pakuba Apt",
    PAE: "Snohomish County Apt",
    PAD: "Paderborn Apt",
    PAC: "Paitilla Apt",
    PAB: "Bilaspur Apt",
    PAA: "Pa-An Apt",
    OZZ: "Ouarzazate Apt",
    OZU: "Montilla Rail Station",
    OZR: "Cairns AAF",
    OZI: "Bobadilla Rail Station",
    OZH: "Zaporozhye Apt",
    OZG: "Zagora Arpt",
    OZC: "Ozamis Apt",
    OZA: "Ozona Apt",
    OYS: "Yosemite National Park Apt",
    OYP: "Saint Georges L Oyapock Apt",
    OYO: "Tres Arroyos Apt",
    OYN: "Ouyen Apt",
    OYL: "Moyale Apt",
    OYK: "Oiapoque Apt",
    OYG: "Moyo Apt",
    OYE: "Oyem Apt",
    OYA: "Goya Apt",
    OXY: "Morney Apt",
    OXR: "Oxnard/Ventura Apt",
    OXO: "Orientos Apt",
    OXG: "Angers",
    OXF: "Oxford Apt",
    OXD: "Miami University Apt",
    OXC: "Oxford Apt",
    OXB: "Bissau Apt",
    OWK: "Norridgewock Apt",
    OWE: "Owendo Apt",
    OWD: "Norwood Memorial Apt",
    OWB: "Daviess County Apt",
    OWA: "Owatonna Apt",
    OVS: "Sovetsky Arpt",
    OVR: "Olavarria Apt",
    OVL: "Ovalle Apt",
    OVI: "Oviedo Rail Station",
    OVE: "Oroville Municipal Apt",
    OVD: "Asturias Apt",
    OVB: "Tolmachevo Apt",
    OVA: "Bekily Apt",
    OUZ: "Zouerate Apt",
    OUU: "Ouanga Apt",
    OUT: "Bousso Apt",
    OUS: "Ourinhos Apt",
    OUR: "Batouri Apt",
    OUQ: "Ourense Rail Station",
    OUN: "Norman Apt",
    OUM: "Oum Hadjer Apt",
    OUL: "Oulu Apt",
    OUK: "Outer Skerries Apt",
    OUI: "Ban Houei Sai Apt",
    OUH: "Oudtshoorn Apt",
    OUG: "Ouahigouya Apt",
    OUE: "Ouesso Apt",
    OUD: "Oujda Apt",
    OUC: "Ouaga",
    OUA: "Ouagadougou Apt",
    OTZ: "Kotzebue Apt",
    OTY: "Oria Apt",
    OTV: "Ontong Java Apt",
    OTU: "Otu Apt",
    OTS: "Anacortes Apt",
    OTR: "Coto 47 Apt",
    OTP: "Otopeni Intl Apt",
    OTO: "Otto Apt",
    OTN: "Green Apt",
    OTM: "Ottumwa Industrial Apt",
    OTL: "Boutilimit Apt",
    OTJ: "Otjiwarongo Apt",
    OTI: "Morotai Island Apt",
    OTH: "North Bend Municipal Apt",
    OTG: "Worthington Municipal Apt",
    OTD: "Contadora Apt",
    OTC: "Bol Apt",
    OTA: "Mota Apt",
    OSZ: "Koszalin Apt",
    OSY: "Namsos Apt",
    OSX: "Kosciusko Apt",
    OSW: "Orsk Apt",
    OSU: "Ohio State Univ Arpt",
    OST: "Ostend Apt",
    OSS: "Osh Apt",
    OSR: "Mosnov Apt",
    OSQ: "Siping Railway Station",
    OSP: "Slupsk Apt",
    OSM: "Mosul Apt",
    OSL: "Oslo Apt",
    OSK: "Oskarshamn Apt",
    OSI: "Osijek Apt",
    OSH: "Wittman Field",
    OSE: "Omora Apt",
    OSD: "Froson Air Force Base",
    OSC: "Wurtsmith Apt",
    OSB: "Osage Beach Apt",
    OSA: "Osaka",
    ORZ: "Orange Walk Apt",
    ORY: "Orly Apt",
    ORX: "Oriximina Apt",
    ORW: "Ormara Apt",
    ORV: "R. Curtis Memorial Apt",
    ORU: "Oruro Apt",
    ORT: "Northway Apt",
    ORS: "Orpheus Island Resort Apt",
    ORR: "Yorketown Apt",
    ORQ: "Norwalk Apt",
    ORP: "Orapa Apt",
    ORO: "Yoro Apt",
    ORN: "Es Senia Apt",
    ORM: "Northampton Rail Station",
    ORL: "Herndon Apt",
    ORK: "Cork Intl Apt",
    ORJ: "Orinduik Apt",
    ORI: "Port Lions Apt",
    ORH: "Worcester Municipal Apt",
    ORG: "Zorg en Hoop Apt",
    ORF: "Norfolk Intl Apt",
    ORE: "Orleans Apt",
    ORD: "O'Hare Intl Apt",
    ORC: "Orocue Apt",
    ORB: "Bofors Apt",
    ORA: "Oran Apt",
    OPW: "Opuwa Apt",
    OPU: "Balimo Apt",
    OPS: "Sinop Apt",
    OPO: "Francisco Sa Carneiro Apt",
    OPL: "Opelousas Apt",
    OPI: "Oenpelli Apt",
    OPF: "Opa Locka Arpt",
    OPB: "Open Bay Apt",
    OPA: "Kopasker Apt",
    OOT: "Onotoa Apt",
    OOR: "Mooraberree Apt",
    OOM: "Cooma Apt",
    OOL: "Gold Coast Apt",
    OOK: "Toksook Bay Municipal Apt",
    OOA: "Oskaloosa Municipal Apt",
    ONY: "Olney Apt",
    ONX: "Colon Apt",
    ONU: "Ono I Lau Apt",
    ONT: "Ontario Intl Apt",
    ONS: "Onslow Apt",
    ONR: "Monkira Apt",
    ONQ: "Zonguldak Apt",
    ONP: "Newport Municipal Apt",
    ONO: "Ontario Municipal Apt",
    ONN: "Onion Bay Apt",
    ONM: "Socorro Municipal Apt",
    ONL: "O'Neill Apt",
    ONK: "Olenek Airport",
    ONJ: "Onate Noshiro Apt",
    ONI: "Moanamani Apt",
    ONH: "Oneonta Municipal Apt",
    ONG: "Mornington Island Apt",
    ONE: "Onepusu Apt",
    OND: "Ondangwa Apt",
    ONB: "Ononge Apt",
    ONA: "Winona Apt",
    OMY: "Oddor Meanchey Apt",
    OMS: "Omsk Apt",
    OMR: "Oradea Apt",
    OMO: "Mostar Apt",
    OMN: "Osmanabad Apt",
    OMM: "Marmul Apt",
    OML: "Omkalai Apt",
    OMK: "Omak Apt",
    OMJ: "Omura Apt",
    OMH: "Urmieh Apt",
    OMG: "Omega Apt",
    OME: "Nome Apt",
    OMD: "Oranjemund Apt",
    OMC: "Ormoc Apt",
    OMB: "Omboue Apt",
    OMA: "Eppley Airfield",
    OLZ: "Olekminsk Airport",
    OLY: "Noble Apt",
    OLV: "Olive Branch Apt",
    OLU: "Columbus Apt",
    OLS: "Nogales Apt",
    OLQ: "Olsobip Apt",
    OLP: "Olympic Dam Apt",
    OLO: "Olomouc Apt",
    OLN: "Colonia Sarmiento Apt",
    OLM: "Olympia Apt",
    OLK: "Fuerte Olimpo Arpt",
    OLJ: "Olpoi Apt",
    OLI: "Olafsvik Apt",
    OLH: "Russell Field",
    OLF: "Wolf Point Municipal Apt",
    OLE: "Olean Apt",
    OLD: "Old Town Municipal Apt",
    OLC: "Sao Paulo de Olivenca Airport",
    OLB: "Costa Smeralda Apt",
    OLA: "Orland Apt",
    OKY: "Oakey Apt",
    OKU: "Mokuti Lodge Apt",
    OKT: "Oktabrskij Apt",
    OKS: "Oshkosh Apt",
    OKR: "Yorke Island Apt",
    OKQ: "Okaba Apt",
    OKP: "Oksapmin Apt",
    OKO: "Yokota AFB",
    OKN: "Okondja Apt",
    OKM: "Okmulgee Municipal Apt",
    OKL: "Oksibil Apt",
    OKK: "Kokomo Municipal Apt",
    OKJ: "Okayama Apt",
    OKI: "Oki Island Apt",
    OKG: "Okoyo Apt",
    OKF: "Okaukuejo Apt",
    OKE: "Okino Erabu Apt",
    OKD: "Okadama Apt",
    OKC: "Will Rogers World Apt",
    OKB: "Orchid Beach Apt",
    OKA: "Naha Field",
    OJO: "Outjo",
    OJC: "Johnson County Executive Apt",
    OIT: "Oita Apt",
    OIR: "Okushiri Apt",
    OIM: "Oshima Apt",
    OIA: "Ourilandia Apt",
    OHT: "Kohat Apt",
    OHR: "Wyk Auf Fohr Apt",
    OHP: "Oban Heliport",
    OHO: "Okhotsk Apt",
    OHI: "Oshakati Apt",
    OHH: "Novostroyka Airport",
    OHE: "Gu Lian Airport",
    OHD: "Ohrid Apt",
    OHC: "North East Cape Apt",
    OHA: "Royal Air Force Base Airport",
    OGZ: "Vladikavkaz Apt",
    OGX: "Ouargla Apt",
    OGV: "Ongavia Game Reserve Apt",
    OGS: "Ogdensburg Intl Apt",
    OGR: "Bongor Apt",
    OGO: "Abengourou Apt",
    OGN: "Jima Apt",
    OGM: "Ogobsucum Arpt",
    OGL: "Ogle Apt",
    OGG: "Kahului Apt",
    OGE: "Ogeranang Apt",
    OGD: "Ogden Municipal Apt",
    OGB: "Orangeburg Apt",
    OGA: "Ogallala Apt",
    OFU: "Ofu Apt",
    OFK: "Karl Stefan Fld",
    OFJ: "Olafsfjordur Apt",
    OFI: "Ouango-Fitini Apt",
    OES: "Exupery Apt",
    OER: "Ornskoldsvik Apt",
    OEO: "Osceola Apt",
    OEM: "Paloemeu Apt",
    OEL: "Orel Apt",
    OEC: "Ocussi Apt",
    OEA: "O'Neal Apt",
    ODY: "Oudomxay Apt",
    ODW: "Oak Harbor Air Park",
    ODS: "Odessa Apt",
    ODR: "Ord River Apt",
    ODO: "Bodaybo Arpt",
    ODN: "Long Seridan Apt",
    ODM: "Garrett County Apt",
    ODL: "Cordillo Downs Apt",
    ODJ: "Ouanda Djalle Apt",
    ODE: "Odense Apt",
    ODD: "Oodnadatta Apt",
    ODB: "Cordoba Apt",
    ODA: "Ouadda Apt",
    OCV: "Ocana Municipal Apt",
    OCN: "Oceanside Municipal Apt",
    OCJ: "Boscobel Apt",
    OCI: "Oceanic Apt",
    OCH: "A.L.Mangham Apt",
    OCF: "Ocala Regional Apt",
    OCE: "Ocean City Municipal Apt",
    OCC: "Coca Apt",
    OCA: "Ocean Reef Club Apt",
    OBY: "Ittoqqortoormiit Apt",
    OBX: "Obo Apt",
    OBU: "Kobuk Municipal Apt",
    OBT: "Oakland Coliseum Street",
    OBS: "Aubenas Apt",
    OBO: "Obihiro Apt",
    OBN: "Connel Apt",
    OBM: "Morobe Apt",
    OBL: "Zoersel Apt",
    OBK: "Northbrook Apt",
    OBI: "Obidos Apt",
    OBF: "Oberpfaffenhofen Apt",
    OBE: "Okeechobee County Apt",
    OBD: "Obano Apt",
    OBC: "Obock Apt",
    OBA: "Oban Apt",
    OAX: "Xoxocotlan Apt",
    OAR: "Fritzsche Army Air Field",
    OAN: "Olanchito Apt",
    OAM: "Oamaru Apt",
    OAL: "Cacoal Apt",
    OAK: "Oakland Intl Apt",
    OAJ: "Albert J. Ellis Apt",
    OAG: "Springhill Apt",
    OAA: "Nora",
    NZY: "San Diego North Island Naval Air Station",
    NZO: "Nzoia Apt",
    NZH: "Manzhouli Airport",
    NZE: "Nzerekore Apt",
    NZC: "Cecil Field Nas",
    NZA: "Nzagi Apt",
    NYU: "Nyaung-U Apt",
    NYT: "Nay Pyi Taw International",
    NYR: "Nyurba Airport",
    NYO: "Skavsta Apt",
    NYN: "Nyngan Apt",
    NYM: "Nadym Apt",
    NYK: "Nanyuki Apt",
    NYI: "Sunyani Apt",
    NYE: "Nyeri Apt",
    NYC: "New York Metropolitan Area",
    NYA: "Nyagan Arpt",
    NXX: "Willow Grove Apt",
    NWU: "Bermuda Naval Air Station",
    NWT: "Nowata Apt",
    NWS: "Pier 11 Wall St Seaplane Base",
    NWK: "Newark Pennsylvania Railway Stn",
    NWI: "Norwich Apt",
    NWH: "Parlin Field",
    NWA: "Moheli Apt",
    NVY: "Neyveli Apt",
    NVT: "Navegantes Apt",
    NVS: "Nevers Apt",
    NVR: "Novgorod Apt",
    NVP: "Novo Aripuana Apt",
    NVK: "Framnes Apt",
    NVI: "Navoi Airport",
    NVG: "Nueva Guinea Apt",
    NVD: "Nevada Apt",
    NVA: "La Marguita Apt",
    NUX: "Novyj Urengoj Apt",
    NUU: "Nakuru Apt",
    NUT: "Nutuve Apt",
    NUS: "Norsup Apt",
    NUR: "Nullarbor Apt",
    NUQ: "Moffett Field",
    NUP: "Nunapitchuk Apt",
    NUN: "Saufley Field Naval Outlying Field",
    NUL: "Nulato Municipal Apt",
    NUK: "Nukutavake Apt",
    NUJ: "Nogeh Airport",
    NUI: "Nuiqsut Municipal Apt",
    NUH: "Nunchia Apt",
    NUG: "Nuguria Apt",
    NUE: "Nuremberg Apt",
    NUD: "En Nahud Apt",
    NUB: "Numbulwar Apt",
    NUA: "Gregorys Lake Airport",
    NTY: "Sun City Apt",
    NTX: "Natuna Apt",
    NTU: "Oceana Apt",
    NTT: "Niuatoputapu Apt",
    NTR: "Del Norte Apt",
    NTQ: "Wajima Airport",
    NTO: "Santo Antao Apt",
    NTN: "Normanton Apt",
    NTM: "Miracema Do Norte Apt",
    NTL: "Williamtown Apt",
    NTJ: "Manti Apt",
    NTI: "Bintuni Apt",
    NTH: "NTH",
    NTG: "Nantong Apt",
    NTE: "Chateau Bougon Apt",
    NTD: "Point Mugu",
    NTB: "Notodden Apt",
    NTA: "Natadola Apt",
    NSY: "Sigonella Apt",
    NSV: "Noosaville Apt",
    NST: "Nakhon Si Thammarat Apt",
    NSP: "Sangley Point Apt",
    NSO: "Scone Apt",
    NSN: "Nelson Apt",
    NSM: "Norseman Apt",
    NSK: "Norilsk Apt",
    NSI: "Nsimalen International",
    NSH: "Now Shahr Apt",
    NSF: "Camp Springs Andrew Air Force Base",
    NSE: "Milton Apt",
    NSB: "Bimini North Seaplane Base",
    NSA: "Noosa Apt",
    NRY: "Newry Apt",
    NRV: "USCG Shore Street Guam",
    NRU: "Narum",
    NRT: "Narita Apt",
    NRS: "Naval Air Station Airport",
    NRN: "Niederrhein Arpt",
    NRM: "Nara Apt",
    NRL: "North Ronaldsay Apt",
    NRK: "Kungsangen Apt",
    NRI: "Shangri-La Apt",
    NRG: "Narrogin Apt",
    NRE: "Namrole Apt",
    NRD: "Norderney Apt",
    NRC: "Aux Field Arpt",
    NRA: "Narrandera Apt",
    NQY: "Saint Mawgan Apt",
    NQX: "Key West Naval Air Station Arpt",
    NQU: "Nuqui Apt",
    NQT: "Nottingham Apt",
    NQN: "Neuquen Municipal Apt",
    NQL: "Niquelandia Apt",
    NQI: "Kingsville Apt",
    NQA: "Memphis Naval Air Station",
    NPU: "San Pedro Uraba Apt",
    NPT: "Newport Apt",
    NPP: "Napperby Apt",
    NPO: "Nangapinoh Apt",
    NPL: "New Plymouth Apt",
    NPH: "Nephi Apt",
    NPG: "Nipa Apt",
    NPE: "Hawkes Bay Apt",
    NPA: "Pensacola Naval Air Station",
    NOZ: "Novokuznetsk Apt",
    NOV: "Huambo Apt",
    NOU: "Tontouta Apt",
    NOT: "Gnoss Field",
    NOS: "Nossi-Be Apt",
    NOR: "Nordfjordur Apt",
    NOP: "Mactan Island Intl Apt",
    NOO: "Naoro Apt",
    NON: "Nonouti Apt",
    NOM: "Nomad River Apt",
    NOL: "Nokolik River Apt",
    NOK: "Novo Xavantina Apt",
    NOJ: "Nojabrxsk Apt",
    NOI: "Novorossijsk Apt",
    NOG: "Nogales Apt",
    NOE: "Norddeich Apt",
    NOD: "Norden Apt",
    NOC: "Knock Intl Apt",
    NOB: "Nosara Beach Municipal Apt",
    NOA: "Nowra Apt",
    NNY: "Nanyang Apt",
    NNX: "Nunukan Apt",
    NNU: "Nanuque Apt",
    NNT: "Nan Apt",
    NNR: "Connemara Arpt",
    NNM: "Naryan-Mar Apt",
    NNL: "Nondalton Apt",
    NNK: "Naknek Municipal Apt",
    NNI: "Namutoni Apt",
    NNG: "Nanning Apt",
    NNE: "Narbonne Rail Station",
    NND: "Nangade Apt",
    NNA: "Kenitra Apt",
    NMU: "Namu Apt",
    NMT: "Namtu Apt",
    NMS: "Namsang Apt",
    NMR: "Nappa Merrie Apt",
    NMP: "New Moon Apt",
    NMN: "Nomane Apt",
    NMG: "San Miguel Apt",
    NME: "Nightmute Municipal Apt",
    NMC: "Norman's Cay Apt",
    NMB: "Daman Apt",
    NMA: "Namangan Apt",
    NLV: "Nikolaev Apt",
    NLU: "Mexico City Santa Lucia AFB",
    NLT: "Xinyuan City Nalati Airport",
    NLS: "Nicholson Apt",
    NLP: "Nelspruit Apt",
    NLO: "Kinshasa N'Dolo Apt",
    NLL: "Nullagine Apt",
    NLK: "Norfolk Island Intl Apt",
    NLG: "Nelson Lagoon Municipal Apt",
    NLF: "Darnley Island Apt",
    NLE: "Niles Apt",
    NLD: "Quetzalcoati Apt",
    NLC: "Nas Reeves Field",
    NLA: "Ndola Apt",
    NKY: "Nkayi Apt",
    NKX: "San Diego Miramar Naval Air Station",
    NKV: "Nichen Cove Apt",
    NKU: "Nkaus Apt",
    NKT: "Sirnak Arpt",
    NKS: "Nkongsamba Apt",
    NKN: "Nankina Apt",
    NKM: "Nagoya Komaki Arpt",
    NKL: "Nkolo Apt",
    NKJ: "Nanjing Rail Station",
    NKI: "Naukiti Municipal Apt",
    NKG: "Nanjing Apt",
    NKD: "Sinak Apt",
    NKC: "Nouakchott Apt",
    NKB: "Noonkanbah Apt",
    NKA: "Nkan Apt",
    NJK: "El Centro Naval Air Facility",
    NJF: "Najaf International Arpt",
    NJC: "Nizhnevartovsk Apt",
    NJA: "Atsugi Apt",
    NIX: "Nioro Apt",
    NIT: "Niort Apt",
    NIS: "Simberi Island Apt",
    NIR: "Beeville Apt",
    NIP: "Jacksonville Nas",
    NIO: "Nioki Apt",
    NIN: "Ninilchik Apt",
    NIM: "Niamey Apt",
    NIK: "Niokolo Koba Apt",
    NIG: "Nikunau Apt",
    NIF: "Nifty Apt",
    NIE: "Niblack Apt",
    NIC: "Nicosia Apt",
    NIB: "Nikolai Municipal Apt",
    NIA: "Nimba Apt",
    NHZ: "Naval Air Station",
    NHX: "Foley Municipal Apt",
    NHV: "Terre Deserte Apt",
    NHT: "Northholt Aerodrome",
    NHS: "Nushki Apt",
    NHG: "Nanchang Railway Station",
    NHF: "New Halfa Apt",
    NHA: "Nha-Trang Apt",
    NGZ: "Alameda Apt",
    NGY: "Nogoya EK Bus Station",
    NGX: "Manang Apt",
    NGW: "Cabaniss Field Arpt",
    NGU: "Naval Air Station Chambers",
    NGS: "Nagasaki Apt",
    NGR: "Ningerum Apt",
    NGQ: "Ali Airport",
    NGP: "Corpus Christi Naval Air Station",
    NGO: "Komaki Apt",
    NGN: "Nargana Apt",
    NGM: "Agana NAS",
    NGL: "Ngala Apt",
    NGK: "Nogliki Arpt",
    NGI: "Ngau Island Apt",
    NGE: "Ngaoundere Apt",
    NGD: "Anegada Municipal Apt",
    NGC: "Grand Canyon North Rim Apt",
    NGB: "Ningbo Apt",
    NGA: "Young Apt",
    NFR: "Nafoora Apt",
    NFO: "Mata'aho Apt",
    NFL: "Fallon Nas",
    NFG: "Nefteyugansk Apt",
    NFB: "Detroit Naval Air Facility",
    NEW: "New Lakefront Arpt",
    NEV: "Newcastle Apt",
    NEU: "Sam Neua Apt",
    NES: "Skyports Inc Seaplane Base",
    NER: "Neryungri Apt",
    NEN: "Whitehouse Apt",
    NEK: "Nekemt Apt",
    NEJ: "Nejjo Apt",
    NEG: "Negril Apt",
    NEF: "Neftekamsk Apt",
    NEC: "Necochea Apt",
    NDZ: "Nordholz-Spieka Apt",
    NDY: "Sanday Apt",
    NDV: "USN Heliport",
    NDU: "Rundu Apt",
    NDS: "Sandstone Apt",
    NDR: "Nador Apt",
    NDP: "Pensacola Ellyson Naval Air Station",
    NDO: "La Palma Del Condada Rail Station",
    NDN: "Nadunumu Apt",
    NDM: "Mendi Apt",
    NDL: "Ndele Apt",
    NDK: "Namdrik Island Apt",
    NDJ: "Ndjamena Apt",
    NDI: "Namudi Apt",
    NDG: "Qiqihar Apt",
    NDF: "Ndalatandos Apt",
    NDE: "Mandera Apt",
    NDD: "Sumbe Apt",
    NDC: "Nanded Apt",
    NDB: "Nouadhibou Apt",
    NDA: "Bandanaira Apt",
    NCY: "Meythet Apt",
    NCU: "Nukus Apt",
    NCT: "Nicoya Apt",
    NCS: "Newcastle Apt",
    NCR: "San Carlos Apt",
    NCQ: "Marietta Atlanta Naval Air Station",
    NCP: "Cubi Pt NAS",
    NCO: "Quonset Point Apt",
    NCN: "New Chenega Apt",
    NCL: "Newcastle Apt",
    NCI: "Necocli Municipal Apt",
    NCH: "Nachingwea Apt",
    NCG: "Nueva Casas Grandes Apt",
    NCE: "Cote d'Azur Apt",
    NCA: "North Caicos Municipal Apt",
    NBX: "Nabire Apt",
    NBW: "Guantanamo Mariana Grajales",
    NBV: "Cana Brava Apt",
    NBS: "Changbaishan Airport",
    NBR: "Nambour Apt",
    NBP: "Battery Pk City N Cov",
    NBO: "Jomo Kenyatta Intl Apt",
    NBL: "San Blas Apt",
    NBH: "Nambucca Heads Apt",
    NBG: "Alvin Callendar Arpt",
    NBE: "Zine Elabidine Ben Ali",
    NBC: "Naberevnye Chelny Apt",
    NBB: "Barrancominas Apt",
    NBA: "Nambaiyufa Apt",
    NAY: "Nanyuan Apt",
    NAW: "Narathiwat Apt",
    NAV: "Nevsehir Apt",
    NAU: "Napuka Island Apt",
    NAT: "Augusto Severo Apt",
    NAS: "Nassau Intl Apt",
    NAR: "Nare Municipal Apt",
    NAQ: "Qaanaaq Apt",
    NAP: "Capodichino Apt",
    NAO: "Nanchong Apt",
    NAN: "Nadi Intl Apt",
    NAM: "Namlea Apt",
    NAL: "Nalchik Apt",
    NAK: "Nakhon Ratchasima Apt",
    NAJ: "Nakhichevan Apt",
    NAI: "Annai Apt",
    NAH: "Naha Apt",
    NAG: "Sonegaon Apt",
    NAF: "Banaina Apt",
    NAE: "Nattingou Apt",
    NAD: "Macanal Apt",
    NAC: "Naracoorte Apt",
    NAB: "Albany Naval Air Station",
    NAA: "Narrabri Apt",
    MZZ: "Marion Municipal Apt",
    MZY: "Mossel Bay Apt",
    MZX: "Mena Apt",
    MZV: "Mulu Apt",
    MZU: "Muzaffarpur Apt",
    MZT: "General Rafael Bueina Apt",
    MZS: "Mostyn Apt",
    MZR: "Mazar-I-Sharif Apt",
    MZQ: "Mkuze Apt",
    MZP: "Motueka Apt",
    MZO: "Sierra Maestra Apt",
    MZN: "Minj Apt",
    MZM: "Frescaty Apt",
    MZL: "Manizales Apt",
    MZK: "Marakei Apt",
    MZJ: "Marana Apt",
    MZI: "Mopti Apt",
    MZH: "Merzifon Apt",
    MZG: "Makung Apt",
    MZF: "Mzamba Apt",
    MZE: "Manatee Apt",
    MZD: "Mendez Apt",
    MZC: "Mitzic Apt",
    MZB: "Mocimboa Da Praia Apt",
    MZA: "Muzaffarnagar Apt",
    MYZ: "Monkey Bay Apt",
    MYY: "Miri Apt",
    MYX: "Menyamya Apt",
    MYW: "Mtwara Apt",
    MYV: "Yuba County Apt",
    MYU: "Ellis Field",
    MYT: "Myitkyina Apt",
    MYS: "Moyale Apt",
    MYR: "Myrtle Beach Jetport",
    MYQ: "Mysore Apt",
    MYP: "Mary Apt",
    MYO: "Myroodah Apt",
    MYN: "Mareb Apt",
    MYM: "Monkey Mountain Apt",
    MYL: "McCall Apt",
    MYK: "May Creek Apt",
    MYJ: "Matsuyama Apt",
    MYI: "Murray Island Apt",
    MYH: "Marble Canyon Apt",
    MYG: "Mayaguana Apt",
    MYF: "Montgomery Field",
    MYE: "Miyake Jima Apt",
    MYD: "Malindi Apt",
    MYC: "Maracay Apt",
    MYB: "Mayoumba Apt",
    MYA: "Moruya Apt",
    MXZ: "Meixian Apt",
    MXY: "McCarthy Apt",
    MXX: "Mora Apt",
    MXW: "Mandalgobi Apt",
    MXV: "Moron Apt",
    MXU: "Mullewa Apt",
    MXT: "Maintirano Apt",
    MXS: "Maota Savail Island Apt",
    MXR: "Mirgorod Apt",
    MXQ: "Mitchell River Apt",
    MXP: "Malpensa Apt",
    MXO: "Monticello Municipal Apt",
    MXN: "Morlaix Apt",
    MXM: "Morombe Apt",
    MXL: "Mexicali Intl Apt",
    MXK: "Mindik Apt",
    MXJ: "Minna Apt",
    MXI: "Mati Apt",
    MXH: "Moro Apt",
    MXG: "Marlborough Apt",
    MXF: "Maxwell Airforce Base",
    MXE: "Maxton Apt",
    MXD: "Marion Downs Apt",
    MXC: "Monticello Apt",
    MXB: "Masamba Apt",
    MXA: "Manila Apt",
    MWZ: "Mwanza Apt",
    MWY: "Miranda Downs Apt",
    MWX: "Muan Intl Arpt",
    MWV: "Mundulkiri Apt",
    MWU: "Mussau Apt",
    MWT: "Moolawatana Apt",
    MWS: "Mount Wilson Apt",
    MWQ: "Magwe Apt",
    MWP: "Mountain Apt",
    MWO: "Middletown Apt",
    MWN: "Mwadui Apt",
    MWM: "Windom Apt",
    MWL: "Mineral Wells Apt",
    MWK: "Matak Apt",
    MWJ: "Matthews Ridge Apt",
    MWI: "Maramuni Apt",
    MWH: "Grant County Apt",
    MWG: "Marawaka Apt",
    MWF: "Maewo Apt",
    MWE: "Merowe Apt",
    MWD: "Mianwali Apt",
    MWC: "Lawrence J Timmerman Arpt",
    MWB: "Morawa Apt",
    MWA: "Williamson County Regional Apt",
    MVZ: "Masvingo Apt",
    MVY: "Martha's Vineyard Apt",
    MVX: "Minvoul Apt",
    MVW: "Mount Vernon Apt",
    MVV: "Megeve Apt",
    MVU: "Musgrave Apt",
    MVT: "Mataiva Apt",
    MVS: "Mucuri Apt",
    MVR: "Maroua Apt",
    MVQ: "Mogilev Apt",
    MVP: "Mitu Apt",
    MVO: "Mongo Apt",
    MVN: "Mount Vernon Apt",
    MVM: "Kayenta Apt",
    MVL: "Stowe State Apt",
    MVK: "Mulka Apt",
    MVJ: "Mandeville Apt",
    MVI: "Manetai Apt",
    MVH: "Macksville Apt",
    MVG: "Mevang Apt",
    MVF: "Mossoro Apt",
    MVE: "Montevideo Apt",
    MVD: "Carrasco Apt",
    MVC: "Monroe County Apt",
    MVB: "Franceville Apt",
    MVA: "Myvatn Apt",
    MUZ: "Musoma Apt",
    MUY: "Mouyondzi Apt",
    MUX: "Multan Apt",
    MUW: "Mascara Apt",
    MUV: "Mustin ALF",
    MUU: "Huntingdon County Apt",
    MUT: "Muscatine Municipal Apt",
    MUS: "Marcus Island Apt",
    MUR: "Marudi Apt",
    MUQ: "Muccan Apt",
    MUP: "Mulga Park Apt",
    MUO: "Mountain Home AFB",
    MUN: "Quiriquire Apt",
    MUM: "Mumias Apt",
    MUL: "Spence Airport",
    MUK: "Mauke Island Apt",
    MUJ: "Mui Apt",
    MUH: "Mersa Matruh Apt",
    MUG: "Mulege Apt",
    MUF: "Muting Apt",
    MUE: "Kamuela Apt",
    MUD: "Mueda Apt",
    MUC: "Munich Intl Apt",
    MUB: "Maun Apt",
    MUA: "Munda Apt",
    MTZ: "Masada Apt",
    MTY: "Aeropuerto General Mariano Escobedo",
    MTX: "Metro Field Airport",
    MTW: "Manitowoc County Apt",
    MTV: "Mota Lava Apt",
    MTU: "Montepuez Apt",
    MTT: "Coatzarcolas Apt",
    MTS: "Manzini Apt",
    MTR: "S. Jeronimo Apt",
    MTQ: "Mitchell Apt",
    MTP: "Montauk Apt",
    MTO: "Coles County Memorial Apt",
    MTN: "Martin State Arpt",
    MTM: "Metlakatla Municipal Apt",
    MTL: "Maitland Apt",
    MTK: "Makin Island Apt",
    MTJ: "Montrose Regional Apt",
    MTI: "Mosteiros Apt",
    MTH: "Marathon Apt",
    MTG: "Mato Grosso Apt",
    MTF: "Mizan Teferi Apt",
    MTE: "Monte Alegre Apt",
    MTD: "Mount Sanford Apt",
    MTC: "Selfridge Air Natl Guard",
    MTB: "Monte Libano Apt",
    MTA: "Matamata Apt",
    MSZ: "New Mocamedes Apt",
    MSY: "New Orleans Intl Apt",
    MSX: "Mossendjo Apt",
    MSW: "Massawa Apt",
    MSV: "Sullivan County Intl Apt",
    MSU: "Moshoeshoe Intl Apt",
    MST: "Maastricht Aachen de Apt",
    MSS: "Richards Field",
    MSR: "Mus Apt",
    MSQ: "Minsk Intl Apt",
    MSP: "Minneapolis-Saint Paul Intl Apt",
    MSO: "Missoula Intl Apt",
    MSN: "Dane County Regional Apt",
    MSM: "Masi-Manimba Apt",
    MSL: "Muscle Shoals Regional Apt",
    MSK: "Mastic Point Apt",
    MSJ: "Misawa Apt",
    MSI: "Masalembo Apt",
    MSH: "Masirah Apt",
    MSG: "Matsaile Apt",
    MSF: "Mount Swan Apt",
    MSE: "Manston Apt",
    MSD: "Mount Pleasant Apt",
    MSC: "Falcon Field",
    MSB: "Marigot Seaplane Base",
    MSA: "Muskrat Dam Apt",
    MRZ: "Moree Apt",
    MRY: "Monterey Peninsula Apt",
    MRX: "Bandar Mahshahr Apt",
    MRW: "Maribo Apt",
    MRV: "Mineralnye Vody Apt",
    MRU: "Mauritius Apt",
    MRT: "Moroak Apt",
    MRS: "Provence Apt",
    MRR: "Macara Apt",
    MRQ: "Marinduque Apt",
    MRP: "Marla Apt",
    MRO: "Masterton Apt",
    MRN: "Morganton Apt",
    MRM: "Manare Apt",
    MRL: "Miner's Lake Apt",
    MRK: "Marco Island Apt",
    MRJ: "Marcala Apt",
    MRI: "Merrill Field",
    MRH: "May River Apt",
    MRG: "Mareeba Apt",
    MRF: "Marfa Apt",
    MRE: "Mara Lodges Apt",
    MRD: "Alberto Carnevalli Apt",
    MRC: "Columbia Apt",
    MRB: "Martinsburg Apt",
    MRA: "Misurata Apt",
    MQZ: "Margaret River Apt",
    MQY: "Smyrna Apt",
    MQX: "Makale Apt",
    MQW: "Mc Rae Apt",
    MQV: "Maiquetia",
    MQU: "Mariquita Apt",
    MQT: "Sawyer Intl Apt",
    MQS: "Mustique Apt",
    MQR: "Mosquera Apt",
    MQQ: "Moundou Apt",
    MQP: "Nelspruit Kruger",
    MQO: "Malam Apt",
    MQN: "Rossvoll Apt",
    MQM: "Mardin Apt",
    MQL: "Mildura Apt",
    MQK: "San Matias Apt",
    MQJ: "Merkez Apt",
    MQI: "Quincy Apt",
    MQH: "Minacu Apt",
    MQG: "Midgard Apt",
    MQF: "Magnitogorsk Apt",
    MQE: "Marqua Apt",
    MQD: "Maquinchao Apt",
    MQC: "Miquelon Apt",
    MQB: "Macomb Municipal Apt",
    MQA: "Mandora Apt",
    MPZ: "Mount Pleasant Municipal Apt",
    MPY: "Maripasoula Apt",
    MPX: "Miyanmin Apt",
    MPW: "Mariupol Apt",
    MPV: "Edward F. Knapp State Apt",
    MPU: "Mapua Apt",
    MPT: "Maliana Apt",
    MPS: "Mount Pleasant Apt",
    MPR: "McPherson Apt",
    MPQ: "Ma'An Apt",
    MPP: "Mulatupo Apt",
    MPO: "Pocono Mountains Municipal Apt",
    MPN: "Mount Pleasant Apt",
    MPM: "Maputo Apt",
    MPL: "Frejorgues Apt",
    MPK: "Mokpo Apt",
    MPJ: "Morrilton Apt",
    MPI: "Mamitupo Apt",
    MPH: "Caticlan Apt",
    MPG: "Makini Apt",
    MPF: "Mapoda Apt",
    MPE: "Griswold Apt",
    MPD: "Mirpur Khas Apt",
    MPC: "Muko-Muko Apt",
    MPB: "Miami Public Seaplane Base",
    MPA: "Mpacha Apt",
    MOZ: "Temae Apt",
    MOY: "Monterrey Apt",
    MOX: "Morris Apt",
    MOW: "Moscow Metropolitan Area",
    MOV: "Moranbah Apt",
    MOU: "Mountain Village Apt",
    MOT: "Minot Intl Apt",
    MOS: "Moses Point Apt",
    MOR: "Moore-Murrell Apt",
    MOQ: "Morondava Apt",
    MOP: "Mount Pleasant Municipal Apt",
    MOO: "Moomba Apt",
    MON: "Mount Cook Apt",
    MOM: "Moudjeria Apt",
    MOL: "Aro Apt",
    MOK: "Mankono Apt",
    MOJ: "Moengo Apt",
    MOI: "Mitiaro Apt",
    MOH: "Mohanbari Apt",
    MOG: "Mong Hsat Apt",
    MOF: "Waioti Apt",
    MOE: "Momeik Apt",
    MOD: "Modesto City-County Apt",
    MOC: "Montes Claros Apt",
    MOB: "Bates Field",
    MOA: "Orestes Acosta Apt",
    MNZ: "Manassas Apt",
    MNY: "Mono Apt",
    MNX: "Manicore Apt",
    MNW: "MacDonald Downs Apt",
    MNV: "Mountain Valley Apt",
    MNU: "Maulmyine Apt",
    MNT: "Minto Municipal Apt",
    MNS: "Mansa Apt",
    MNR: "Mongu Apt",
    MNQ: "Monto Apt",
    MNP: "Maron Apt",
    MNO: "Manono Apt",
    MNN: "Marion Municipal Apt",
    MNM: "Menominee Apt",
    MNL: "Ninoy Aquino Intl Apt",
    MNK: "Maiana Apt",
    MNJ: "Mananjary Apt",
    MNI: "William Bramble Apt",
    MNH: "Minneriya Apt",
    MNG: "Maningrida Apt",
    MNF: "Mana Island Apt",
    MNE: "Mungeranie Apt",
    MND: "Medina Apt",
    MNC: "Nacala Apt",
    MNB: "Moanda Apt",
    MNA: "Melangguane Apt",
    MMZ: "Maimana Apt",
    MMY: "Miyako Jima Apt",
    MMX: "Sturup Apt",
    MMW: "Moma Apt",
    MMU: "Morristown Apt",
    MMT: "Mc Entire Air National Guard Base Arpt",
    MMS: "Marks Apt",
    MMR: "Camp Maybry Ahp",
    MMQ: "Mbala Apt",
    MMP: "Mompos Apt",
    MMO: "Maio Apt",
    MMN: "Minute Man Air Field",
    MMM: "Middlemount Apt",
    MML: "Marshall Apt",
    MMK: "Murmansk Apt",
    MMJ: "Matsumoto Apt",
    MMI: "McMinn County Arpt",
    MMH: "Mammoth Lakes Apt",
    MMG: "Mount Magnet Apt",
    MMF: "Mamfe Apt",
    MME: "Teesside Intl Apt",
    MMD: "Minami Daito Apt",
    MMC: "Ciudad Mante Apt",
    MMB: "Memambetsu Apt",
    MMA: "Malmo",
    MLZ: "Melo Municipal Apt",
    MLY: "Manley Hot Springs Apt",
    MLX: "Erhag Apt",
    MLW: "Sprigg-Payne Apt",
    MLV: "Merluna Apt",
    MLU: "Monroe Regional Apt",
    MLT: "Millinocket Municipal Apt",
    MLS: "Miles City Municipal Apt",
    MLR: "Millicent Apt",
    MLQ: "Malalaua Apt",
    MLP: "Malabang Apt",
    MLO: "Milos Apt",
    MLN: "Melilla Apt",
    MLM: "Morelia Apt",
    MLL: "Marshall Apt",
    MLK: "Malta Apt",
    MLJ: "Milledgeville Apt",
    MLI: "Quad-City Apt",
    MLH: "Basel/Mulhouse Euro Apt",
    MLG: "Malang Apt",
    MLF: "Milford Apt",
    MLE: "Male Intl Apt",
    MLD: "Malad City Apt",
    MLC: "McAlester Regional Apt",
    MLB: "MLB",
    MLA: "Valletta Apt",
    MKZ: "Batu Berendum Apt",
    MKY: "Mackay Apt",
    MKX: "Mukalla Apt",
    MKW: "Manokwari Apt",
    MKV: "Mount Cavenagh Apt",
    MKU: "Makokou Apt",
    MKT: "Mankato Municipal Apt",
    MKS: "Mekane Selam Apt",
    MKR: "Meekatharra Apt",
    MKQ: "Mopah Apt",
    MKP: "Makemo Apt",
    MKO: "Davis Field",
    MKN: "Malekolon Apt",
    MKM: "Mukah Apt",
    MKL: "McKellar Fld",
    MKK: "Molokai Apt",
    MKJ: "Makoua Apt",
    MKI: "M'Boki Apt",
    MKH: "Mokhotlong Apt",
    MKG: "Muskegon Apt",
    MKF: "Mckenna AAF",
    MKE: "General Mitchell Intl Apt",
    MKD: "Chagni Apt",
    MKC: "Kansas City Intl Apt",
    MKB: "Mekambo Apt",
    MKA: "Marianske Lazne Apt",
    MJZ: "Mirnyj Apt",
    MJY: "Mangunjaya Apt",
    MJX: "Toms River Rail Station",
    MJW: "Mahenye Apt",
    MJV: "San Javier Apt",
    MJU: "Mamuju Apt",
    MJT: "Mytilene Apt",
    MJR: "Miramar Apt",
    MJQ: "Jackson Apt",
    MJP: "Manjimup Apt",
    MJO: "Mount Etjo Lodge Apt",
    MJN: "Majunga Apt",
    MJM: "Mbuji-Mayi Apt",
    MJL: "Mouila Apt",
    MJK: "Shark Bay Apt",
    MJJ: "Moki Apt",
    MJI: "Mitiga Apt",
    MJH: "Majma Apt",
    MJG: "Mayajigua Apt",
    MJF: "Kjaerstad Apt",
    MJE: "Majkin Apt",
    MJD: "Mohenjodaro Apt",
    MJC: "Man Apt",
    MJB: "Mejit Island Apt",
    MJA: "Manja Apt",
    MIZ: "Mainoru Apt",
    MIY: "Mittiebah Apt",
    MIX: "Miriti Apt",
    MIW: "Marshalltown Municipal Apt",
    MIV: "Millville Municipal Apt",
    MIU: "Maiduguri Apt",
    MIT: "Shafter Apt",
    MIS: "Misima Island Apt",
    MIR: "Monastir Apt",
    MIQ: "Millard Airport",
    MIP: "Mitspeh Ramon Apt",
    MIN: "Minnipa Apt",
    MIM: "Merimbula Apt",
    MIL: "Milan Metropolitan Area",
    MIK: "Mikkeli Apt",
    MIJ: "Mili Island Apt",
    MII: "Marilia Apt",
    MIH: "Mitchell Plateau Apt",
    MIG: "Mianyang Apt",
    MIF: "Monahans Apt",
    MIE: "Muncie Apt",
    MID: "Manuel Crecencio Rejon Apt",
    MIB: "Minot Airforce Base",
    MIA: "Miami Intl Apt",
    MHZ: "Mildenhall Apt",
    MHY: "Morehead Apt",
    MHX: "Manihiki Island Apt",
    MHW: "Monteagudo Apt",
    MHV: "Mojave Apt",
    MHU: "Mount Hotham Apt",
    MHT: "Manchester Apt",
    MHS: "Mount Shasta Apt",
    MHR: "Mather Air Force Base",
    MHQ: "Mariehamn Apt",
    MHP: "Minsk Intl Apt",
    MHO: "Mount House Apt",
    MHN: "Mullen Apt",
    MHM: "Minchumin Apt",
    MHL: "Marshall Apt",
    MHK: "Manhattan Municipal Apt",
    MHJ: "Misrak Gashamo Apt",
    MHI: "Musha Apt",
    MHH: "Marsh Harbour Apt",
    MHG: "Neuostheim Apt",
    MHF: "Morichal Apt",
    MHE: "Mitchell Municipal Apt",
    MHD: "Mashad Apt",
    MHC: "Mocopulli",
    MHB: "Mechanics Bay Arpt",
    MHA: "Mahdia Apt",
    MGZ: "Myeik Apt",
    MGY: "Dayton General Airport South",
    MGX: "Moabi Apt",
    MGW: "Morgantown Municipal Apt",
    MGV: "Margaret River Station Apt",
    MGU: "Manaung Apt",
    MGT: "Milingimbi Apt",
    MGS: "Mangaia Apt",
    MGR: "Moultrie/Thomasville Apt",
    MGQ: "Mogadishu Apt",
    MGP: "Manga Apt",
    MGO: "Manega Apt",
    MGN: "Magangue Apt",
    MGM: "Dannelly Field",
    MGL: "Moenchen'Bach Apt",
    MGJ: "Orange County Apt",
    MGI: "Matagorda Apt",
    MGH: "Margate Apt",
    MGG: "Margarima Apt",
    MGF: "Maringa Apt",
    MGE: "Dobbins Air Force Base",
    MGD: "Magdalena Apt",
    MGC: "Michigan City Apt",
    MGB: "Mount Gambier Apt",
    MGA: "Augusto Cesar Sandino Apt",
    MFZ: "Meselia Apt",
    MFY: "Mayfa'Ah Apt",
    MFX: "Meribel Apt",
    MFW: "Magaruque Apt",
    MFV: "Melfa Apt",
    MFU: "Mfuwe Apt",
    MFT: "Machu Picchu Apt",
    MFS: "Miraflores Apt",
    MFR: "Jackson County Apt",
    MFQ: "Maradi Apt",
    MFP: "Manners Creek Apt",
    MFO: "Manguna Apt",
    MFN: "Milford Sound Apt",
    MFM: "Macau Apt",
    MFL: "Mount Fullstop Apt",
    MFK: "Matsu Apt",
    MFJ: "Moala Apt",
    MFI: "Marshfield Apt",
    MFH: "Mesquite Apt",
    MFG: "Muzaffarabad Apt",
    MFF: "Moanda Apt",
    MFE: "Miller Intl Apt",
    MFD: "Mansfield Apt",
    MFC: "Mafeteng Apt",
    MFB: "Monfort Apt",
    MFA: "Mafia Apt",
    MEZ: "Messina Apt",
    MEY: "Meghauli Apt",
    MEX: "Juarez Intl Apt",
    MEW: "Mweka Apt",
    MEV: "Douglas County Apt",
    MEU: "Monte Dourado Apt",
    MET: "Moreton Apt",
    MES: "Polonia Apt",
    MER: "Castle Airfoce Base",
    MEQ: "Meulaboh Apt",
    MEP: "Mersing Apt",
    MEO: "Manteo Apt",
    MEN: "Mende Apt",
    MEM: "Memphis Intl Apt",
    MEL: "Melbourne Intl Apt",
    MEK: "Meknes Apt",
    MEJ: "Port Meadville Apt",
    MEI: "Key Field",
    MEH: "Mehamn Municipal Apt",
    MEG: "Malange Apt",
    MEF: "Melfi Apt",
    MEE: "Mare Apt",
    MED: "Madinah Apt",
    MEC: "Manta Apt",
    MEB: "Essendon Apt",
    MEA: "Macae Apt",
    MDZ: "El Plumerillo Apt",
    MDY: "Sand Island Field",
    MDX: "Mercedes Apt",
    MDW: "Chicago Midway Apt",
    MDV: "Medouneu Apt",
    MDU: "Mendi Apt",
    MDT: "Harrisburg Intl Apt",
    MDS: "Middle Caicos Municipal Apt",
    MDR: "Medfra Apt",
    MDQ: "Mar Del Plata Apt",
    MDP: "Mindiptana Apt",
    MDO: "Middleton Island Apt",
    MDN: "Madison Municipal Apt",
    MDM: "Munduku Apt",
    MDL: "Annisaton Apt",
    MDK: "Mbandaka Apt",
    MDJ: "Madras City-County Apt",
    MDI: "Makurdi Apt",
    MDH: "Southern Illinois Apt",
    MDG: "Mudanjiang Apt",
    MDE: "Jose Maria Cordova Intl Apt",
    MDD: "Midland Airpark",
    MDC: "Samratulangi Apt",
    MDB: "Melinda Apt",
    MDA: "Martindale AAF",
    MCZ: "Palmares Apt",
    MCY: "Sunshine Coast Apt",
    MCX: "Makhachkala Apt",
    MCW: "Mason City Municipal Apt",
    MCV: "McArthur River Apt",
    MCU: "Gueret (Lepaud) Apt",
    MCT: "Muscat Apt",
    MCS: "Monte Caseros Apt",
    MCR: "Melchor Mencos Apt",
    MCQ: "Miskolc Apt",
    MCP: "Macapa Apt",
    MCO: "Orlando Intl Apt",
    MCN: "Lewis B. Wilson Regional Apt",
    MCM: "MCM",
    MCL: "Mount McKinley Apt",
    MCK: "McCook Municipal Apt",
    MCJ: "Maicao Apt",
    MCI: "Kansas City Intl Apt",
    MCH: "General M. Serrano Apt",
    MCG: "McGrath Apt",
    MCF: "Mac Dill Airforce Base",
    MCE: "Merced Municipal Apt",
    MCD: "Mackinac Island Apt",
    MCC: "Mcclellan Airforce Base",
    MCB: "McComb Apt",
    MCA: "Macenta Apt",
    MBZ: "Maues Apt",
    MBY: "Moberly Apt",
    MBX: "Maribor Apt",
    MBW: "Moorabbin Apt",
    MBV: "Masa Apt",
    MBU: "Mbambanakira Apt",
    MBT: "Masbate Apt",
    MBS: "Tri City Intl Apt",
    MBR: "Mbout Apt",
    MBQ: "Mbarara Apt",
    MBP: "Moyobamba Apt",
    MBO: "Mamburao Apt",
    MBN: "Mount Barnett Apt",
    MBM: "Mkambati Apt",
    MBL: "Manistee Apt",
    MBK: "Matupa Apt",
    MBJ: "Sangster Intl Apt",
    MBI: "Mbeya Apt",
    MBH: "Maryborough Apt",
    MBG: "Mobridge Municipal Apt",
    MBF: "Mount Buffalo Apt",
    MBE: "Monbetsu Apt",
    MBD: "Mmabatho Intl Apt",
    MBC: "M'Bigou Apt",
    MBB: "Marble Bar Apt",
    MBA: "Moi Intl Apt",
    MAZ: "Eugenio Maria de Hostos Apt",
    MAY: "Mangrove Cay Apt",
    MAX: "Matam Apt",
    MAW: "Malden Apt",
    MAV: "Maloelap Island Apt",
    MAU: "Motu Apt",
    MAT: "Matadi Apt",
    MAS: "Momote Apt",
    MAR: "La Chinita Apt",
    MAQ: "Mae Sot Apt",
    MAP: "Mamai Apt",
    MAO: "Eduardo Gomes Intl Apt",
    MAN: "Ringway Intl Apt",
    MAM: "Matamoros Intl Apt",
    MAL: "Mangole Apt",
    MAK: "Malakal Apt",
    MAJ: "Amata Kahua Intl Apt",
    MAI: "Mangochi Apt",
    MAH: "Menorca Apt",
    MAG: "Madang Apt",
    MAF: "Midland Intl Apt",
    MAE: "Madera Municipal Apt",
    MAD: "Barajas Apt",
    MAC: "Smart Apt",
    MAB: "Maraba Apt",
    MAA: "Chennai Apt",
    LZY: "Linzhi Airport",
    LZU: "Gwinnett County Airport",
    LZS: "LINZ Railway Station",
    LZR: "Lizard Island Apt",
    LZO: "Luzhou Apt",
    LZM: "Luzamba Apt",
    LZI: "Luozi Apt",
    LZH: "Liuzhou Apt",
    LZD: "Lanzhoudong-Lanzhou East Apt",
    LZC: "Lazaro Cardenas Apt",
    LZA: "Luiza Apt",
    LYX: "Lydd Intl Apt",
    LYU: "Ely Municipal Apt",
    LYT: "Lady Elliot Island Apt",
    LYS: "Saint Exupery Apt",
    LYR: "Longyearbyen Apt",
    LYP: "Faisalabad Apt",
    LYO: "Rice County Municipal Apt",
    LYN: "Bron Apt",
    LYM: "Ashford",
    LYK: "Lunyuk Apt",
    LYI: "Linyi Apt",
    LYH: "Preston-Glenn Field",
    LYG: "Lianyungang Apt",
    LYC: "Lycksele Apt",
    LYB: "Little Cayman Municipal Air Strip",
    LYA: "Luoyang Apt",
    LXV: "Lake County Apt",
    LXU: "Lukulu Apt",
    LXS: "Lemnos Apt",
    LXR: "Luxor Apt",
    LXN: "Lexington Apt",
    LXG: "Luang Namtha Apt",
    LXA: "Lhasa Apt",
    LWY: "Lawas Apt",
    LWV: "Lawrenceville Apt",
    LWT: "Lewistown Municipal Apt",
    LWS: "Nez Perce County Apt",
    LWR: "Leeuwarden Apt",
    LWO: "Lvov Apt",
    LWN: "Gyoumri Apt",
    LWM: "Lawrence Municipal Apt",
    LWL: "Harriet Field",
    LWK: "Lerwick/Tingwall Apt",
    LWI: "Lowai Apt",
    LWH: "Lawn Hill Apt",
    LWG: "Logrono Bus Station",
    LWE: "Lewoleba Apt",
    LWC: "Lawrence Municipal Apt",
    LWB: "Greenbrier Valley Apt",
    LVS: "Las Vegas Municipal Apt",
    LVP: "Lavan Apt",
    LVO: "Laverton Apt",
    LVM: "Livingston Apt",
    LVL: "Lawrenceville Apt",
    LVK: "Livermore Municipal Apt",
    LVI: "Livingstone Apt",
    LVD: "Lime Village Municipal Apt",
    LVB: "Dos Galpoes Apt",
    LVA: "Laval Apt",
    LUZ: "Lushan Apt",
    LUY: "Lushoto Apt",
    LUX: "Findel Apt",
    LUW: "Luwuk Apt",
    LUV: "Langgur Apt",
    LUU: "Laura Apt",
    LUT: "Laura Station Apt",
    LUS: "Lusanga Apt",
    LUR: "Cape Lisburne Apt",
    LUQ: "San Luis Municipal Apt",
    LUP: "Kalaupapa Apt",
    LUO: "Luena Apt",
    LUN: "Lusaka Intl Apt",
    LUM: "Luxi Apt",
    LUL: "Hesler-Noble Field",
    LUK: "Cincinnati Municipal Arpt",
    LUJ: "Lusikisiki Apt",
    LUI: "La Union Apt",
    LUH: "Ludhiana Apt",
    LUG: "Lugano Apt",
    LUF: "Luke AFB",
    LUE: "Lucenec Apt",
    LUD: "Luderitz Apt",
    LUC: "Laucala Island Apt",
    LUB: "Lumid Pau Apt",
    LUA: "Lukla Apt",
    LTX: "Cotapaxi Intl Arpt",
    LTW: "Leonardtown Apt",
    LTV: "Lotusvale Apt",
    LTU: "Latur Airport",
    LTT: "La Mole Apt",
    LTS: "Altus Airforce Base",
    LTR: "Letterkenny Apt",
    LTQ: "Le Touquet Apt",
    LTP: "Lyndhurst Apt",
    LTO: "Loreto Intl Apt",
    LTN: "Luton Apt",
    LTM: "Lethem Apt",
    LTL: "Lastourville Apt",
    LTK: "Latakia Apt",
    LTI: "Altai Apt",
    LTH: "Lathrop Wells Apt",
    LTG: "Langtang Apt",
    LTF: "Leitre Apt",
    LTD: "Ghadames Apt",
    LTC: "Lai Apt",
    LTB: "Latrobe Apt",
    LTA: "Tzaneen Apt",
    LSZ: "Mali Losinj Apt",
    LSY: "Lismore Apt",
    LSX: "Lhok Sukon Apt",
    LSW: "Lhok Seumawe Apt",
    LSV: "Nellis Air Force Base",
    LSU: "Long Sukang Apt",
    LST: "Launceston Apt",
    LSS: "Terre-De-Haut Apt",
    LSR: "Lost River Apt",
    LSQ: "Los Angeles Apt",
    LSP: "Josepha Camejo Apt",
    LSO: "Les Sables Apt",
    LSN: "Los Banos Municipal Apt",
    LSM: "Lawas Apt",
    LSL: "Los Chiles Apt",
    LSK: "Lusk Apt",
    LSJ: "Long Island Apt",
    LSI: "Sumburgh Apt",
    LSH: "Lashio Apt",
    LSF: "Lawson AAF",
    LSE: "La Crosse Municipal Apt",
    LSD: "Creech Army Air Field",
    LSC: "La Florida Apt",
    LSB: "Lordsburg Municipal Apt",
    LSA: "Losuia Apt",
    LRV: "Los Roques Apt",
    LRU: "Las Cruces Intl Apt",
    LRT: "Lann Bihoue Apt",
    LRS: "Leros Apt",
    LRR: "Lar Apt",
    LRQ: "Laurie River Aerodrome",
    LRN: "Larson AFB",
    LRM: "La Romana Apt",
    LRL: "Niamtougou Apt",
    LRK: "Lincoln Rock Apt",
    LRJ: "Le Mars Municipal Apt",
    LRI: "Lorica Apt",
    LRH: "Laleu Apt",
    LRG: "Loralai Apt",
    LRF: "Jacksonville Apt",
    LRE: "Longreach Apt",
    LRD: "Laredo Intl Apt",
    LRB: "Leribe Apt",
    LRA: "Larissa Apt",
    LQS: "Lingshui Rail Station",
    LQQ: "Liaoyang Railway Station",
    LQP: "Chenzou Rail Station",
    LQN: "Qala-Nau Apt",
    LQM: "Puerto Leguizamo Apt",
    LQK: "Pickens Apt",
    LPY: "Loudes Apt",
    LPX: "Liepaya Apt",
    LPW: "Little Port Walter Apt",
    LPU: "Long Apung Apt",
    LPT: "Lampang Apt",
    LPS: "Lopez Island Apt",
    LPQ: "Luang Prabang Apt",
    LPP: "Lappeenranta Apt",
    LPO: "La Porte Municipal Apt",
    LPM: "Lamap Apt",
    LPL: "Speke Apt",
    LPK: "Lipetsk Apt",
    LPJ: "Pijiguaos Apt",
    LPI: "Saab Apt",
    LPH: "Lochgilphead Apt",
    LPG: "La Plata Municipal Apt",
    LPE: "La Primavera Apt",
    LPD: "La Pedrera Apt",
    LPC: "Lompoc Apt",
    LPB: "El Alto Apt",
    LPA: "Gran Canaria Apt",
    LOZ: "London Apt",
    LOY: "Loyangalani Apt",
    LOX: "Los Tablones",
    LOW: "Louisa Apt",
    LOV: "Monclova Municipal Apt",
    LOU: "Bowman Field Arpt",
    LOT: "Lewis University Apt",
    LOS: "Lagos Apt",
    LOR: "Lowe Army Heliport",
    LOQ: "Lobaste Apt",
    LOP: "Lombok International Airport",
    LOO: "Laghouat Apt",
    LON: "London Metropolitan Area",
    LOM: "Lagos De Moreno Apt",
    LOL: "Derby Field",
    LOK: "Lodwar Apt",
    LOI: "Lontras Apt",
    LOH: "Loja Municipal Apt",
    LOG: "Longview Apt",
    LOF: "Loen Apt",
    LOE: "Loei Apt",
    LOD: "Longana Apt",
    LOC: "Lock Apt",
    LOB: "Los Andes Apt",
    LOA: "Lorraine Apt",
    LNZ: "Hoersching Apt",
    LNY: "Lanai Apt",
    LNX: "Smolensk Apt",
    LNV: "Lihir Island Apt",
    LNS: "Lancaster Apt",
    LNR: "Lone Rock Apt",
    LNQ: "Loani Apt",
    LNP: "Lonesome Pine Apt",
    LNO: "Leonora Apt",
    LNN: "Lost Nation Apt",
    LNM: "Langimar Apt",
    LNL: "Land O'Lakes",
    LNK: "Lincoln Municipal Apt",
    LNJ: "Lincang Airport",
    LNI: "Lonely Apt",
    LNH: "Lake Nash Apt",
    LNG: "Lese Apt",
    LNF: "Munbil Apt",
    LNE: "Lonorore Apt",
    LND: "Lander Apt",
    LNC: "Lengbati Apt",
    LNB: "Lamen Bay Apt",
    LNA: "Palm Beach County Arpt",
    LMZ: "Palma Apt",
    LMY: "Lake Murray Apt",
    LMX: "Lopez De Micay Apt",
    LMT: "Kingsley Field",
    LMS: "Louisville Apt",
    LMR: "Lime Acres Apt",
    LMQ: "Marsa Brega Apt",
    LMP: "Lampedusa Apt",
    LMN: "Limbang Apt",
    LMM: "Federal Apt",
    LML: "Lae Island Apt",
    LMI: "Lumi Apt",
    LMH: "Limon Apt",
    LMG: "Lamassa Apt",
    LME: "Le Mans Apt",
    LMD: "Los Menucos Apt",
    LMC: "Lamacarena Apt",
    LMB: "Salima Apt",
    LMA: "Lake Minchumina Municipal Apt",
    LLY: "South Jersey Regional Apt",
    LLX: "Caledonia County Apt",
    LLW: "Lilongwe Apt",
    LLV: "LuLiang Airport",
    LLU: "Alluitsup Paa Apt",
    LLS: "Las Lomitas Apt",
    LLP: "Linda Downs Apt",
    LLO: "Lagaligo Arpt",
    LLN: "Kelila Apt",
    LLM: "Long Lama Apt",
    LLL: "Lissadell Apt",
    LLK: "Lankaran Intl Arpt",
    LLJ: "Lalmonirhat",
    LLI: "Lalibela Apt",
    LLH: "Las Limas Apt",
    LLG: "Chillagoe Apt",
    LLF: "Ling Ling Arpt",
    LLE: "Malelane Apt",
    LLD: "Loveland",
    LLA: "Kallax Apt",
    LKZ: "Brandon Apt",
    LKY: "Lake Manyara Apt",
    LKW: "Larkana",
    LKV: "Lake County Apt",
    LKU: "Lake Rudolf Apt",
    LKT: "Lakota Apt",
    LKS: "Lakeside Apt",
    LKR: "Las Khoreh Apt",
    LKP: "Lake Placid Apt",
    LKO: "Amausi Apt",
    LKN: "Leknes Apt",
    LKL: "Banak Apt",
    LKK: "Kulik Lake Apt",
    LKI: "Lakeside USAF",
    LKH: "Long Akah Arpt",
    LKG: "Lokichoggio Apt",
    LKE: "Lake Union Seaplane Base",
    LKD: "Lakeland Downs Apt",
    LKC: "Lekana Apt",
    LKB: "Lakeba Apt",
    LKA: "Larantuka Apt",
    LJU: "Ljubljana Apt",
    LJR: "Ljubljana Rail Station",
    LJN: "Lake Jackson Apt",
    LJG: "Lijiang Apt",
    LJA: "Lodja Apt",
    LIZ: "Limestone Apt",
    LIY: "Liberty County Apt",
    LIX: "Likoma Island Apt",
    LIW: "Loikaw Apt",
    LIV: "Livengood Apt",
    LIU: "Linosa Apt",
    LIT: "Adams Field",
    LIS: "Lisboa Apt",
    LIR: "Liberia Apt",
    LIQ: "Lisala Apt",
    LIP: "Lins Apt",
    LIO: "Limon Apt",
    LIN: "Linate Apt",
    LIM: "Chavez Internacional Aeroporto",
    LIL: "Lesquin Apt",
    LIK: "Likiep Island Apt",
    LIJ: "Long Island Municipal Apt",
    LII: "Mulia Apt",
    LIH: "Lihue Municipal Apt",
    LIG: "Bellegrade Apt",
    LIF: "Lifou Apt",
    LIE: "Libenge Apt",
    LID: "Leiden Apt",
    LIC: "Limon Municipal Apt",
    LIB: "Limbunya Apt",
    LIA: "Liangping Apt",
    LHX: "La Junta",
    LHW: "Lanzhou Apt",
    LHV: "William T. Piper Memorial Apt",
    LHS: "Las Heras Apt",
    LHR: "Heathrow Apt",
    LHP: "Lehu Apt",
    LHN: "Li Shan Apt",
    LHK: "Guanghua Apt",
    LHI: "Lereh Apt",
    LHG: "Lightning Ridge Apt",
    LHE: "Lahore Apt",
    LHC: "Linhai Railway Station",
    LHA: "Lahr Apt",
    LGZ: "Leguizamo Apt",
    LGY: "Lagunillas Apt",
    LGX: "Lugh Ganane Apt",
    LGW: "Gatwick Apt",
    LGV: "Logrono Rail Station",
    LGU: "Logan Apt",
    LGT: "Las Gaviotas Apt",
    LGS: "Malargue Apt",
    LGR: "Cochrane Apt",
    LGQ: "Lago Agrio Apt",
    LGP: "Legaspi Apt",
    LGO: "Langeoog Apt",
    LGN: "Linga Linga Apt",
    LGM: "Laiagam Apt",
    LGL: "Long Lellang Apt",
    LGK: "Langkawi Apt",
    LGI: "Deadmans Cay Apt",
    LGH: "Leigh Creek Apt",
    LGG: "Liege Apt",
    LGF: "Laguna AAF",
    LGE: "Lake Gregory Apt",
    LGD: "La Grande Apt",
    LGC: "Callaway Apt",
    LGB: "Long Beach Apt",
    LGA: "La Guardia Apt",
    LFW: "Lome Apt",
    LFT: "Lafayette Regional Apt",
    LFR: "La Fria Apt",
    LFP: "Lakefield Apt",
    LFO: "Kelafo Apt",
    LFN: "Louisburg Apt",
    LFK: "Angelina County Apt",
    LFI: "Langley Air Force Base",
    LEZ: "La Esperanza Apt",
    LEY: "Lelystad Apt",
    LEX: "Blue Grass Apt",
    LEW: "Lewiston/Auburn Municipal Apt",
    LEV: "Levuka Airfield",
    LEU: "Seo De Urgel Apt",
    LET: "General A. V. Cobo Apt",
    LES: "Lesobeng Apt",
    LER: "Leinster Apt",
    LEQ: "Saint Just Apt",
    LEP: "Leopoldina Apt",
    LEO: "Leconi Apt",
    LEN: "Leon Apt",
    LEM: "Lemmon Municipal Apt",
    LEL: "Lake Evella Apt",
    LEK: "Labe Apt",
    LEJ: "Leipzig Apt",
    LEI: "Almeria Apt",
    LEH: "Octeville Apt",
    LEG: "Aleg Apt",
    LEF: "Lebakeng Apt",
    LEE: "Leesburg Municipal Apt",
    LED: "Pulkovo Apt",
    LEC: "Chapada Diamantina Apt",
    LEB: "Lebanon Municipal Apt",
    LEA: "Learmonth Apt",
    LDZ: "Londolozi Apt",
    LDY: "Eglinton Apt",
    LDX: "Saint Laurent du Maroni Arpt",
    LDW: "Landsdowne Apt",
    LDU: "Lahad Datu Apt",
    LDS: "Yichun Lindu Airport",
    LDR: "Lodar Apt",
    LDO: "Ladouanie Apt",
    LDN: "Lamidanda Apt",
    LDM: "Mason County Apt",
    LDK: "Hovby Apt",
    LDJ: "Linden Apt",
    LDI: "Lindi Apt",
    LDH: "Lord Howe Island Apt",
    LDE: "Tarbes Ossun Lourdes Apt",
    LDC: "Lindeman Island Apt",
    LDB: "Londrina Apt",
    LDA: "Malda Apt",
    LCY: "London City Apt",
    LCX: "Guanzhishan Arpt",
    LCV: "Lucca Apt",
    LCS: "Las Canas Apt",
    LCR: "La Chorrera Apt",
    LCP: "Loncopue Apt",
    LCO: "Lague Apt",
    LCN: "Balcanoona Apt",
    LCM: "La Cumbre Apt",
    LCL: "La Coloma Apt",
    LCK: "Rickenbacker Intl Arpt",
    LCJ: "Lublinek Apt",
    LCI: "Laconia Municipal Apt",
    LCH: "Lake Charles Regional Apt",
    LCG: "La Coruna Apt",
    LCF: "Las Vegas Apt",
    LCE: "Goloson Intl Apt",
    LCD: "Louis Trichardt Apt",
    LCC: "Lecce Apt",
    LCB: "Pontes E Lacerda Apt",
    LCA: "Larnaca Intl Apt",
    LBZ: "Lukapa Apt",
    LBY: "La Baule Apt",
    LBX: "Lubang Apt",
    LBW: "Long Bawan Apt",
    LBV: "Libreville Apt",
    LBU: "Labuan Apt",
    LBT: "Lumberton Apt",
    LBS: "Labasa Apt",
    LBR: "Labrea Apt",
    LBQ: "Lambarene Apt",
    LBP: "Long Banga Apt",
    LBO: "Lusambo Apt",
    LBN: "Lake Baringo Apt",
    LBM: "Luabo Apt",
    LBL: "Liberal Municipal Apt",
    LBK: "Liboi Apt",
    LBJ: "Labuan Bajo Apt",
    LBI: "Albi Apt",
    LBH: "Palm Beach Arpt",
    LBG: "Le Bourget Apt",
    LBF: "North Platte Regional Apt",
    LBE: "Westmoreland County Apt",
    LBD: "Khudzhand Apt",
    LBC: "Luebeck Apt",
    LBB: "Lubbock Intl Apt",
    LBA: "Bradford Apt",
    LAZ: "Bom Jesus Da Lapa Apt",
    LAY: "Ladysmith Apt",
    LAX: "Los Angeles Intl Apt",
    LAW: "Lawton Municipal Apt",
    LAV: "Lalomalava Apt",
    LAU: "Lamu Apt",
    LAS: "McCarran Intl Apt",
    LAR: "General Brees Field",
    LAQ: "Beida Apt",
    LAP: "General Manuel M. de Leon Apt",
    LAO: "Laoag Apt",
    LAN: "Capital City Apt",
    LAM: "Los Alamos Apt",
    LAL: "Lakeland Regional Apt",
    LAK: "Aklavik Apt",
    LAJ: "Lages Apt",
    LAI: "Lannion Apt",
    LAH: "Labuha Apt",
    LAG: "La Guaira Apt",
    LAF: "Purdue University Apt",
    LAE: "Nadzab Apt",
    LAD: "Luanda Apt",
    LAC: "Pulau Layang-Layang Airstrip",
    LAB: "Lablab Apt",
    LAA: "Lamar Municipal Apt",
    KZS: "Kastelorizo Apt",
    KZR: "Zafer Airport",
    KZO: "Kzyl Orda Apt",
    KZN: "Kazan Apt",
    KZK: "Kompong-Thom Apt",
    KZI: "Phillips Apt",
    KZH: "Kizhuyak Apt",
    KZG: "Kitzingen Apt",
    KZF: "Kaintiba Apt",
    KZD: "Krakor Apt",
    KZC: "Kompong-Chhnang Apt",
    KZB: "Zachar Bay Municipal Apt",
    KYZ: "Kyzyl Apt",
    KYX: "Yalumet Apt",
    KYU: "Koyukuk Apt",
    KYT: "Kyauktaw Apt",
    KYS: "Kayes Apt",
    KYP: "Kyaukpyu Apt",
    KYO: "KYO",
    KYN: "Milton Keynes Apt",
    KYL: "Key Largo Apt",
    KYK: "Karluk Apt",
    KYI: "Yalata Mission Apt",
    KYF: "Yeelirrie Apt",
    KYE: "Tripoli Apt",
    KYD: "Orchid Island Apt",
    KYA: "Konya Apt",
    KXR: "Karoola Apt",
    KXK: "Komsomolsk Na Amure Apt",
    KXF: "Koro Apt",
    KXE: "Klerksdorp Apt",
    KXA: "Kasaan Municipal Apt",
    KWZ: "Kolwezi Apt",
    KWY: "Kiwayu Apt",
    KWX: "Kiwai Island Apt",
    KWV: "Kurwina Apt",
    KWU: "Mansion House Apt",
    KWT: "Kwethluk Apt",
    KWS: "Kwailabesi Apt",
    KWR: "Kwai Harbour Apt",
    KWQ: "Wilhelmshoehe Rail Station",
    KWP: "West Point Municipal Apt",
    KWO: "Kawito Apt",
    KWN: "Kwinhagak Apt",
    KWM: "Kowanyama Apt",
    KWL: "Guilin Apt",
    KWK: "Kwigillingok Apt",
    KWJ: "Gwangju Apt",
    KWI: "Kuwait Apt",
    KWH: "Khwahan Apt",
    KWG: "Krivoy Rog Apt",
    KWF: "Waterfall Municipal Apt",
    KWE: "Guiyang Apt",
    KWD: "Kawadjia Apt",
    KWB: "Karimunjawa Apt",
    KWA: "Kwajalein Apt",
    KVX: "Kirov Apt",
    KVU: "Korolevu Apt",
    KVN: "Kunshan Rail Station",
    KVL: "Kivalina Apt",
    KVK: "Kirovsk Apt",
    KVG: "Kavieng Apt",
    KVE: "Kitava Apt",
    KVD: "Gyandzha Apt",
    KVC: "King Cove Apt",
    KVB: "Skovde Apt",
    KVA: "Megas Alexandros Apt",
    KUZ: "Kusan Apt",
    KUY: "Kamusi Apt",
    KUX: "Kuyol Apt",
    KUW: "Kugururok River Apt",
    KUV: "Gunsan Apt",
    KUU: "Bhuntar Apt",
    KUT: "Kutaisi Apt",
    KUS: "Kulusuk Island Apt",
    KUR: "Kuran-O-Munjan Apt",
    KUQ: "Kuri Apt",
    KUP: "Kupiano Apt",
    KUO: "Kuopio Apt",
    KUN: "Kaunas Apt",
    KUM: "Yakushima Apt",
    KUL: "Kuala Lumpur International Airport",
    KUK: "Kasigluk Municipal Apt",
    KUJ: "Kushimoto Apt",
    KUI: "Kawau Island Apt",
    KUH: "Kushiro Apt",
    KUG: "Kubin Island Apt",
    KUF: "Samara Apt",
    KUE: "Kukundu Apt",
    KUD: "Kudat Apt",
    KUC: "Kuria Apt",
    KUB: "Kuala Belait Arpt",
    KUA: "Kuantan Apt",
    KTZ: "Kwun Tong Apt",
    KTX: "Koutiala Apt",
    KTW: "Pyrzowice Apt",
    KTV: "Kamarata Apt",
    KTU: "Kota Apt",
    KTT: "Kittila Apt",
    KTS: "Teller Mission Apt",
    KTR: "Tindal Apt",
    KTP: "Tinson Apt",
    KTO: "Kato Apt",
    KTN: "Ketchikan Intl Apt",
    KTM: "Kathmandu Apt",
    KTL: "Kitale Apt",
    KTK: "Kanua Apt",
    KTI: "Kratie Apt",
    KTH: "Tikchik Apt",
    KTG: "Ketapang Apt",
    KTF: "Takaka Apt",
    KTE: "Kerteh Apt",
    KTD: "Kitadaito Apt",
    KTC: "Katiola Apt",
    KTB: "Thorne Bay Municipal Apt",
    KTA: "Karratha Apt",
    KSZ: "Kotlas Apt",
    KSY: "Kars Apt",
    KSX: "Yasuru Apt",
    KSW: "Kiryat Shmona Apt",
    KSV: "Springvale Apt",
    KSU: "Kvernberget Apt",
    KST: "Kosti Apt",
    KSS: "Sikasso Apt",
    KSR: "Sandy River Apt",
    KSQ: "Karshi Apt",
    KSP: "Kosipe Apt",
    KSO: "Aristoteles Apt",
    KSN: "Kostanay Apt",
    KSM: "Saint Mary's Municipal Apt",
    KSL: "Kassala Apt",
    KSK: "Karlskoga Apt",
    KSJ: "Kasos Island Apt",
    KSI: "Kissidougou Apt",
    KSH: "Kermanshah Apt",
    KSG: "Kisengan Apt",
    KSF: "Kassel Apt",
    KSE: "Kasese Apt",
    KSD: "Karlstad Apt",
    KSC: "Barca Apt",
    KSB: "Kasanombe Apt",
    KSA: "Kosrae Apt",
    KRZ: "Kiri Apt",
    KRY: "Karamay Apt",
    KRX: "Kar Kar Apt",
    KRW: "Krasnovodsk Apt",
    KRV: "Kerio Valley Apt",
    KRU: "Kerau Apt",
    KRT: "Khartoum Apt",
    KRS: "Kjevik Apt",
    KRR: "Krasnodar Apt",
    KRQ: "Kramatorsk Apt",
    KRP: "Karup Apt",
    KRO: "Kurgan Apt",
    KRN: "Kiruna Apt",
    KRM: "Karanambo Apt",
    KRL: "Korla Apt",
    KRK: "John Paul II Balice Apt",
    KRJ: "Karawari Apt",
    KRI: "Kikori Apt",
    KRG: "Karasabai Apt",
    KRF: "Kramfors Apt",
    KRE: "Kirundo Apt",
    KRD: "Kurundi Apt",
    KRC: "Kerinci Apt",
    KRB: "Karumba Apt",
    KRA: "Kerang Apt",
    KQT: "Qurghonteppa International",
    KQL: "Kol Apt",
    KQB: "Koonibba Apt",
    KQA: "Akutan Apt",
    KPY: "Port Bailey Municipal Apt",
    KPV: "Perryville Municipal Apt",
    KPT: "Jackpot Apt",
    KPS: "Kempsey Apt",
    KPR: "Port Williams Municipal Apt",
    KPP: "Kalpowar Apt",
    KPO: "Pohang Apt",
    KPN: "Kipnuk Apt",
    KPM: "Kompiam Apt",
    KPL: "Kapal Apt",
    KPK: "Parks Apt",
    KPI: "Kapit Apt",
    KPH: "Paulof Harbour Apt",
    KPG: "Kurupung Apt",
    KPF: "Kondubol Apt",
    KPE: "Yapsiei Apt",
    KPD: "King Of Prussia Apt",
    KPC: "Port Clarence Apt",
    KPB: "Point Baker Municipal Apt",
    KPA: "Kopiago Apt",
    KOZ: "Ouzinkie Apt",
    KOX: "Kokonao Apt",
    KOW: "Ganzhou Apt",
    KOV: "Kokshetau Apt",
    KOU: "Koulamoutou Apt",
    KOT: "Kotlik Apt",
    KOS: "Sihanoukville Apt",
    KOR: "Kokoro Apt",
    KOQ: "Koethen Apt",
    KOP: "Nakhon Phanom Apt",
    KOO: "Kongolo Apt",
    KON: "Kontum Apt",
    KOM: "Komo-Manda Apt",
    KOL: "Koumbala Apt",
    KOK: "Kokkola/Pietarsaari Apt",
    KOJ: "Kagoshima Apt",
    KOI: "Kirkwall Apt",
    KOH: "Koolatah Apt",
    KOG: "Khong Apt",
    KOF: "Komatipoort Apt",
    KOE: "Eltari Apt",
    KOD: "Kotabangun Apt",
    KOC: "Koumac Apt",
    KOB: "Koutaba Apt",
    KOA: "Keahole Intl Apt",
    KNZ: "Kenieba Apt",
    KNX: "Kununurra Apt",
    KNW: "New Stuyahok Apt",
    KNV: "Knights Inlet Apt",
    KNU: "Kanpur Apt",
    KNT: "Kennett Apt",
    KNS: "King Island Apt",
    KNR: "Kangan Apt",
    KNQ: "Kone Apt",
    KNP: "Capanda Apt",
    KNO: "Knokke Apt",
    KNN: "Kankan Apt",
    KNM: "Kaniama Apt",
    KNL: "Kelanoa Apt",
    KNK: "Kakhonak Municipal Apt",
    KNJ: "Kindamba Apt",
    KNI: "Katanning Apt",
    KNH: "Kinmen Apt",
    KNG: "Kaimana Apt",
    KNF: "Marham Raf",
    KNE: "Kanainj Apt",
    KND: "Kindu Apt",
    KNC: "Ji'An Apt",
    KNB: "Kanab Apt",
    KNA: "Vina Del Mar Apt",
    KMZ: "Kaoma Apt",
    KMY: "Moser Bay Municipal Apt",
    KMX: "Khamis Mushait Apt",
    KMW: "Kostroma Apt",
    KMV: "Kalemyo Apt",
    KMU: "Kismayu Apt",
    KMT: "Kampot Apt",
    KMS: "Kumasi Apt",
    KMR: "Karimui Apt",
    KMQ: "Komatsu Apt",
    KMP: "Keetmanshoop Apt",
    KMO: "Manokotak Municipal Apt",
    KMN: "Kamina Apt",
    KMM: "Kimam Apt",
    KML: "Kamileroi Apt",
    KMK: "Makabana Apt",
    KMJ: "Kumamoto Apt",
    KMI: "Miyazaki Apt",
    KMH: "Kuruman Apt",
    KMG: "Kunming Apt",
    KMF: "Kamina Apt",
    KME: "Kamembe Apt",
    KMD: "Mandji Apt",
    KMC: "King Khalid Military Airport",
    KMB: "Koinambe Apt",
    KMA: "Kerema Apt",
    KLZ: "Kleinzee Apt",
    KLY: "Kalima Apt",
    KLX: "Kalamata Apt",
    KLW: "Klawock Municipal Apt",
    KLV: "Karlovy Vary Apt",
    KLU: "Klagenfurt Apt",
    KLT: "Kaiserslautern Rail Station",
    KLS: "Longview Apt",
    KLR: "Kalmar Apt",
    KLQ: "Keluang Apt",
    KLP: "Kelp Bay Apt",
    KLO: "Kalibo Apt",
    KLN: "Larsen Bay Municipal Apt",
    KLL: "Levelock Apt",
    KLK: "Kalokol Apt",
    KLJ: "Klaipeda Apt",
    KLI: "Kotakoli Apt",
    KLH: "Kolhapur Apt",
    KLG: "Kalskag Apt",
    KLF: "Kaluga Arpt",
    KLE: "Kaele Apt",
    KLD: "Kalinin Apt",
    KLC: "Kaolack Apt",
    KLB: "Kalabo Apt",
    KKZ: "Koh Kong Apt",
    KKY: "Kilkenny Apt",
    KKX: "Kikaiga Shima Apt",
    KKW: "Kikwit Apt",
    KKU: "Ekuk Apt",
    KKT: "Kentland Municipal Apt",
    KKR: "Kaukura Atoll Apt",
    KKQ: "Krasnoselkup Airport",
    KKP: "Koolburra Apt",
    KKO: "Kaikohe Apt",
    KKN: "Hoeybuktmoen Apt",
    KKM: "Lop Buri Apt",
    KKL: "Karluk Lake Apt",
    KKK: "Kalakaket Apt",
    KKJ: "Kita Kyushu Apt",
    KKI: "Akiachak Apt",
    KKH: "Kongiganak Apt",
    KKG: "Konawaruk Apt",
    KKF: "Kagvik Creek Apt",
    KKE: "Kerikeri Apt",
    KKD: "Kokoda Apt",
    KKC: "Khon Kaen Apt",
    KKB: "Kitoi Bay Municipal Apt",
    KKA: "Koyuk Apt",
    KJU: "Kamiraba Apt",
    KJR: "Karlsruhe Baden Baden Hbf",
    KJP: "Kerama Apt",
    KJK: "Kortrijk Apt",
    KJI: "Kanasi Arpt",
    KJH: "Kaili Huangping Airport",
    KJA: "Krasnoyarsk Apt",
    KIZ: "Kikinonda Apt",
    KIY: "Kilwa Apt",
    KIX: "Kansai Intl Apt",
    KIW: "Kitwe Apt",
    KIV: "Kishinev Apt",
    KIU: "Kiunga Apt",
    KIT: "Kithira Apt",
    KIS: "Kisumu Apt",
    KIR: "Kerry County Apt",
    KIQ: "Kira Apt",
    KIP: "Kickapoo Downtown Airpark",
    KIO: "Kili Island Apt",
    KIN: "Norman Manley Intl Apt",
    KIM: "Kimberley Apt",
    KIL: "Kilwa Apt",
    KIK: "Kirkuk Apt",
    KIJ: "Niigata Apt",
    KII: "Kibuli Apt",
    KIH: "Kish Island Apt",
    KIG: "Koinghaas Apt",
    KIF: "Kingfisher lake Arpt",
    KIE: "Kieta Apt",
    KID: "Kristianstad Apt",
    KIC: "Mesa del Rey Apt",
    KIA: "Kaiapit Apt",
    KHW: "Khwai River Lodge Apt",
    KHV: "Novyy Apt",
    KHU: "Kremenchug Apt",
    KHT: "Khost Apt",
    KHS: "Khasab Apt",
    KHR: "Kharkhorin Apt",
    KHO: "Khoka Moya Apt",
    KHN: "Nanchang Apt",
    KHM: "Khamti Apt",
    KHL: "Khulna Apt",
    KHK: "Khark Island Apt",
    KHJ: "Kauhajoki Apt",
    KHI: "Quaid-e-Azam Intl Apt",
    KHH: "Kaohsiung Intl Apt",
    KHG: "Kashi Apt",
    KHE: "Kherson Apt",
    KHD: "Khorramabad Apt",
    KHC: "Kerch Apt",
    KHA: "Khaneh Apt",
    KGZ: "Glacier Creek Apt",
    KGY: "Kingaroy Apt",
    KGX: "Grayling Apt",
    KGW: "Kagi Apt",
    KGV: "Klagenfurt Railway Station",
    KGU: "Keningau Apt",
    KGS: "Kos Apt",
    KGR: "Kulgera Apt",
    KGP: "Kogalym Intl Apt",
    KGO: "Kirovograd Apt",
    KGN: "Kasongo Lunda Apt",
    KGM: "Kungim Apt",
    KGL: "Kigali Apt",
    KGK: "New Koliganek Apt",
    KGJ: "Karonga Apt",
    KGI: "Kalgoorlie Apt",
    KGH: "Yongai Apt",
    KGG: "Kedougou Apt",
    KGF: "Karaganda Apt",
    KGE: "Kagau Apt",
    KGD: "Kaliningrad Apt",
    KGC: "Kingscote Apt",
    KGB: "Konge Apt",
    KGA: "Kananga Apt",
    KFS: "Kastamonu Apt",
    KFP: "False Pass Apt",
    KFG: "Kalkurung Apt",
    KFF: "Farranfore Apt",
    KFA: "Kiffa Apt",
    KEY: "Kericho Apt",
    KEX: "Kanabea Apt",
    KEW: "Keewaywin Apt",
    KEV: "Kuorevesi Apt",
    KEU: "Kelly Bar Apt",
    KET: "Kengtung Apt",
    KES: "Kelsey Apt",
    KER: "Kerman Apt",
    KEQ: "Kebar Apt",
    KEP: "Nepalganj Apt",
    KEO: "Odienne Apt",
    KEN: "Kenema Apt",
    KEM: "Kemi/Tornio Apt",
    KEL: "Holtenau Apt",
    KEK: "Ekwok Municipal Apt",
    KEJ: "Kemerovo Apt",
    KEI: "Kepi Apt",
    KEH: "Kenmore Air Harbor",
    KEG: "Keglsugl Apt",
    KEF: "Keflavik Apt",
    KEE: "Kelle Apt",
    KED: "Kaedi Apt",
    KEC: "Kasenga Apt",
    KEB: "Nanwalek Apt",
    KEA: "Keisah Apt",
    KDZ: "Polgolla Reservoir SPB",
    KDV: "Kandavu Apt",
    KDU: "Skardu Apt",
    KDS: "Kamaran Downs Apt",
    KDR: "Kandrian Apt",
    KDQ: "Kamberatoro Apt",
    KDP: "Kandep Apt",
    KDO: "Kadhdhoo Apt",
    KDN: "N'Dende Apt",
    KDM: "Kaadedhdhoo Apt",
    KDL: "Kardla Apt",
    KDK: "Kodiak Municipal Apt",
    KDJ: "N'Djole Apt",
    KDI: "Wolter Monginsidi Apt",
    KDH: "Kandahar Apt",
    KDG: "Kardjali Apt",
    KDE: "Koroba Apt",
    KDD: "Khuzdar Apt",
    KDC: "Kandi Apt",
    KDB: "Kambalda Apt",
    KDA: "Kolda Apt",
    KCZ: "Kochi Apt",
    KCU: "Masindi Apt",
    KCT: "Koggala Airport",
    KCS: "Kings Creek Station Apt",
    KCR: "Colorado Creek Apt",
    KCQ: "Chignik Lake Apt",
    KCP: "Kamenets Podolskiy Apt",
    KCO: "Cengiz Topel Apt",
    KCN: "Chernofski Apt",
    KCM: "Kahramanmaras Apt",
    KCL: "Chignik Lagoon Apt",
    KCJ: "Komanjo Apt",
    KCH: "Kuching Apt",
    KCG: "Chignik Fisheries Apt",
    KCF: "Kadanwari Apt",
    KCE: "Collinsville Apt",
    KCD: "Kamur Apt",
    KCC: "Coffman Cove Municipal Apt",
    KCB: "Kasikasima Apt",
    KCA: "Kuqa Apt",
    KBZ: "Kaikoura Apt",
    KBY: "Streaky Bay Apt",
    KBX: "Kambuaya Apt",
    KBW: "Chignik Bay Apt",
    KBV: "Krabi Apt",
    KBU: "Kotabaru Apt",
    KBT: "Kaben Apt",
    KBS: "Bo Apt",
    KBR: "Pengkalan Cuepa Apt",
    KBQ: "Kasungu Apt",
    KBP: "Borispol Apt",
    KBO: "Kabalo Apt",
    KBN: "Kabinda Apt",
    KBM: "Kabwum Apt",
    KBL: "Kabul Apt",
    KBK: "Klag Bay Apt",
    KBJ: "Kings Canyon Apt",
    KBI: "Kribi Apt",
    KBH: "Kalat Apt",
    KBG: "Kabalega Falls Apt",
    KBF: "Karubaga Apt",
    KBE: "Bell Island Apt",
    KBD: "Kimberley Downs Apt",
    KBC: "Birch Creek Municipal Apt",
    KBB: "Kirkimbie Apt",
    KBA: "Kabala Apt",
    KAZ: "Kau Apt",
    KAY: "Wakaya Island Apt",
    KAX: "Kalbarri Apt",
    KAW: "Kawthaung Apt",
    KAV: "Kavanayen Apt",
    KAU: "Kauhava Apt",
    KAT: "Kaitaia Apt",
    KAS: "Karasburg Apt",
    KAR: "Kamarang Apt",
    KAQ: "Kamulai Apt",
    KAP: "Kapanga Apt",
    KAO: "Kuusamo Apt",
    KAN: "Kano Apt",
    KAM: "Kamaran Island Apt",
    KAL: "Kaltag Apt",
    KAK: "Kar Apt",
    KAJ: "Kajaani Apt",
    KAI: "Kaieteur Apt",
    KAH: "City Heliport",
    KAG: "Gangneung Apt",
    KAF: "Karato Apt",
    KAE: "Kake Municipal Apt",
    KAD: "Kaduna Apt",
    KAC: "Kameshli Apt",
    KAB: "Kariba Apt",
    KAA: "Kasama Apt",
    JZH: "Jiu Zhai Huang Long",
    JYV: "Jyvaskyla Apt",
    JXS: "Jiaxing Rail Station",
    JXN: "Jackson County Apt-Reynolds Field",
    JXA: "Xingkaihu Airport",
    JWN: "Zanjan Apt",
    JWL: "Woodlawns Airport",
    JWH: "Westchase Hilton Heliport",
    JWC: "Warner Cntr Bus Plaza",
    JWA: "Jwaneng Apt",
    JVL: "Janesville Apt",
    JVI: "Kupper Apt",
    JVA: "Ankavandra Apt",
    JUZ: "Juzhou Apt",
    JUV: "Upernavik Apt",
    JUT: "Juticalpa Apt",
    JUR: "Jurien Bay Apt",
    JUP: "Cable Heliport",
    JUO: "Jurado Apt",
    JUN: "Jundah Apt",
    JUM: "Jumla Apt",
    JUL: "Juliaca Apt",
    JUJ: "El Cadillal Apt",
    JUI: "Juist Apt",
    JUH: "Jiuhuashan Arpt",
    JUB: "Juba Apt",
    JUA: "Juara Apt",
    JTY: "Astypalaia Island Apt",
    JTR: "Thira Apt",
    JTO: "Thousand Oaks Hlpt",
    JTI: "Jatai Apt",
    JTC: "Moussa Nakhl Tobias Arpt",
    JSZ: "St Tropez Hlpt",
    JSY: "Syros Island Apt",
    JSU: "Maniitsoq Apt",
    JST: "Cambria County Apt",
    JSS: "Spetsai Apt",
    JSR: "Jessore Apt",
    JSP: "JSP",
    JSO: "Sodertalje Apt",
    JSN: "Sherman Oaks Heliport",
    JSM: "Jose De San Martin Apt",
    JSL: "Steel Pier Hlpt",
    JSK: "Municipal Hpt",
    JSI: "Skiathos Apt",
    JSH: "Sitia Apt",
    JSG: "San Rafael Heliport",
    JSD: "Stratford Apt",
    JSA: "Jaisalmer Apt",
    JRS: "Jerusalem Apt",
    JRO: "Kilimanjaro Apt",
    JRN: "Juruena Apt",
    JRK: "Arsuk Apt",
    JRH: "Rowriah Apt",
    JRF: "Kalaeloa Arpt",
    JRE: "East 60th St Hlpt",
  },
};
export default {
  messages,
};

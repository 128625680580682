<script setup>
import { computed } from "vue";

const props = defineProps({
  roomResGuests: {},
  roomTypes: {},
});

const guestInfos = computed(() => {
  let mainResGuests = [];
  let primaryGuest = null;
  if (props.roomResGuests && props.roomResGuests.length > 0) {
    props.roomResGuests.forEach((roomResGuest) => {
      roomResGuest.resGuests.forEach((resGuest) => {
        if (resGuest.primaryIndicator) {
          primaryGuest = resGuest;
        } else if (resGuest.givenName != "TBA-" + resGuest.resGuestRPH) {
          mainResGuests.push(resGuest);
        }
      });
    });
    if (primaryGuest == null && mainResGuests.length > 0) {
      primaryGuest = mainResGuests[0];
      mainResGuests.splice(0, 1);
    }

    return {
      mainResGuests,
      primaryGuest,
    };
  }

  return null;
});
</script>

<template>
  <div
    class="table-responsive push"
    v-if="roomResGuests && roomResGuests.length > 0"
  >
    <p class="h5 mb-2">{{ $t("passenger-info") }}</p>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th class="text-center bg-body-light">{{ $t("room-name") }}</th>
          <th class="text-end bg-body-light">{{ $t("guest-name") }}</th>
          <th class="text-end bg-body-light">{{ $t("type") }}</th>
          <th class="text-end bg-body-light">{{ $t("email") }}</th>
          <th class="text-end bg-body-light">{{ $t("phone-number") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center">{{ roomTypes[0] }} - {{ 1 }}</td>
          <td class="text-end">
            {{ guestInfos.primaryGuest.nameTitle }}
            {{ guestInfos.primaryGuest.surname }}/{{
              guestInfos.primaryGuest.givenName
            }}
          </td>
          <td class="text-end">
            <span v-if="guestInfos.primaryGuest.ageQualifyingCode == '10'">{{
              $t("adult")
            }}</span
            ><span v-else>{{ $t("child") }}</span>
          </td>
          <td class="text-end">{{ guestInfos.primaryGuest.emailAddress }}</td>
          <td class="text-end">
            <span v-if="guestInfos.primaryGuest.countryAccessCode">+</span
            >{{ guestInfos.primaryGuest.countryAccessCode }}
            {{ guestInfos.primaryGuest.phoneNumber }}
          </td>
        </tr>
        <tr
          v-for="(roomResGuest, roomIndex) in guestInfos.mainResGuests"
          :key="roomIndex"
        >
          <td class="text-center">
            {{ roomTypes[roomIndex + 1] }} - {{ roomIndex + 2 }}
          </td>
          <td class="text-end">
            {{ roomResGuest.nameTitle }} {{ roomResGuest.givenName }}/{{
              roomResGuest.surname
            }}
          </td>
          <td class="text-end">
            <span v-if="guestInfos.primaryGuest.ageQualifyingCode == '10'">{{
              $t("adult")
            }}</span
            ><span v-else>{{ $t("child") }}</span>
          </td>
          <td class="text-end">
            <span v-if="roomResGuest.emailAddress">{{
              roomResGuest.emailAddress
            }}</span>
          </td>
          <td class="text-end">
            <span v-if="roomResGuest.countryAccessCode">+</span
            >{{ roomResGuest.countryAccessCode }} {{ roomResGuest.phoneNumber }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss"></style>

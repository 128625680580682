<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, watch, watchEffect } from "vue";
import dynamicPkgCommonTools from "@/tools/v2/dynamicPkg/common/dynamicPkgCommonTools";
import { useI18n } from "vue-i18n";
import { useTemplateStore } from "@/stores/template";
import { usePackageFlightShoppingStore } from "@/stores/v2/dynamicPkg/availability/packageFlightShoppingStore";
import { usePackageFlightUpsellStore } from "@/stores/v2/dynamicPkg/availability/packageFlightUpsellStore";
import DynamicPkgFlightItemsAndUpsellOptionSelectPanel from "@/views/v2/sales/dynamicpkg/items/panel/DynamicPkgFlightItemsAndUpsellOptionSelectPanel.vue";
import DynamicPkgFlightItemsAndFareOptionSelectPanel from "@/views/v2/sales/dynamicpkg/items/panel/DynamicPkgFlightItemsAndFareOptionSelectPanel.vue";
import { useCmsContentStore } from "@/stores/cms";

const router = useRouter();
const route = useRoute();

const { t, locale } = useI18n(); // 解构调用函数
const templateStore = useTemplateStore();
const packageFlightShoppingStore = usePackageFlightShoppingStore();
const packageFlightUpsellStore = usePackageFlightUpsellStore();

const dynamicPkgAvailFormModelRef = ref(null);
const airSearchTypeRef = ref(null);
const flightShoppingInfoRef = ref(null);

watch(
  () => route.query,
  (newValue) => {
    if (newValue) {
      airSearchTypeRef.value = newValue.airSearchType;
    } else {
      airSearchTypeRef.value = null;
    }
    dynamicPkgAvailFormModelRef.value =
      dynamicPkgCommonTools.parseDynamicPkgAvailFormModel(newValue);
    searchFlightByFormModel(
      dynamicPkgAvailFormModelRef.value,
      airSearchTypeRef.value
    );
  },
  { immediate: true, deep: true }
);

watchEffect(() => {
  if (packageFlightShoppingStore.dynamicPkgAvailFormModel) {
    flightShoppingInfoRef.value = JSON.parse(
      JSON.stringify(packageFlightShoppingStore.dynamicPkgAvailFormModel)
    );
  } else if (packageFlightUpsellStore.dynamicPkgAvailFormModel) {
    flightShoppingInfoRef.value = JSON.parse(
      JSON.stringify(packageFlightUpsellStore.dynamicPkgAvailFormModel)
    );
  }

  const originLocationCode = flightShoppingInfoRef.value.originLocationCode;
  const destinationLocationCode =
    flightShoppingInfoRef.value.destinationLocationCode;

  const checkInDate = flightShoppingInfoRef.value.checkInDate;
  const checkOutDate = flightShoppingInfoRef.value.checkOutDate;

  flightShoppingInfoRef.value.originDestinations = [
    {
      originLocationCode: originLocationCode,
      destinationLocationCode: destinationLocationCode,
      departureDate: checkInDate,
    },
    {
      originLocationCode: destinationLocationCode,
      destinationLocationCode: originLocationCode,
      departureDate: checkOutDate,
    },
  ];
  if (
    flightShoppingInfoRef.value &&
    flightShoppingInfoRef.value.roomPassengers &&
    flightShoppingInfoRef.value.roomPassengers.length > 0
  ) {
    let passengers = [];

    let adultNum = 0;
    let childNum = 0;
    flightShoppingInfoRef.value.roomPassengers.forEach((roomPassenger) => {
      adultNum += parseInt(roomPassenger.adultNum);
      childNum += parseInt(roomPassenger.childNum);
    });

    if (adultNum > 0)
      passengers.push({ quantity: adultNum, passengerTypeCode: "ADT" });
    if (childNum > 0)
      passengers.push({ quantity: childNum, passengerTypeCode: "CNN" });

    flightShoppingInfoRef.value.passengers = passengers;
  }
});

async function searchFlightByFormModel(formModel, airSearchType) {
  templateStore.pageLoader({ mode: "on" });
  if (airSearchType === "UPSELL") {
    let res = await packageFlightUpsellStore.upsellWithSegBasisCodes(formModel);
    templateStore.pageLoader({ mode: "off" });
  } else {
    let res = await packageFlightShoppingStore.airLowFareSearch(formModel);
    templateStore.pageLoader({ mode: "off" });
  }
}

function goToHotelDetails(query) {
  if (query.referenceId) delete query.referenceId;
  router.push({
    name: "sales-dynamicPkg-hotel-details-v2",
    query: query,
  });
}

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.PackageBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.PackageBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("flight-list") }}</h2>
        <ul>
          <li class="pre-page" @click="router.push({ name: 'home' })">
            {{ $t("home") }}
          </li>
          <li class="pre-page" @click="goToHotelDetails(route.query)">
            {{ $t("hotel-details") }}
          </li>

          <li>
            <i class="fas fa-circle"></i>
            {{ $t("flight-list") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <!--显示搜索条件-->
  <div class="container">
    <div class="row mg-t-10">
      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <div class="header_back">
          <a @click="goToHotelDetails(route.query)">
            <i class="bi bi-arrow-left-square back_icon" size="40"></i>
            <span style="font-size: 27px">{{ $t("back") }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="left_side_search_boxed mg-t-10">
      <div class="row">
        <div
          class="col-12"
          v-if="
            flightShoppingInfoRef &&
            flightShoppingInfoRef.originDestinations &&
            flightShoppingInfoRef.originDestinations.length > 0
          "
        >
          <h4 class="heading_theme">{{ $t("air-shopping") }}</h4>
          <div class="flex flex-wrap mg-b-10">
            <!-- OD + TIME -->
            <div
              v-for="(
                originDes, ODIndex
              ) in flightShoppingInfoRef.originDestinations"
              :key="ODIndex"
              class="mg-r-10"
            >
              <span v-if="ODIndex === 0"
                >{{ flightShoppingInfoRef.cabinTypeCode }},
              </span>
              <span class="font_weight">{{
                originDes.originLocationCode
              }}</span>
              <span class="mg-l-8 mg-r-8 text-primary">
                <i class="far fa-fw fa-arrow-alt-circle-right"></i
              ></span>
              <span class="font_weight"
                >{{ originDes.destinationLocationCode }} ,
              </span>

              <i
                class="fa-solid fa-calendar-days mg-r-8 mg-l-10 text-primary"
              ></i>
              {{ originDes.departureDate }}
            </div>
            <!-- passengers-->
            <div
              v-if="
                flightShoppingInfoRef.passengers &&
                flightShoppingInfoRef.passengers.length > 0
              "
            >
              <!--使用filter(item=>item.quantity)原因 =》暂时发现解析passengers得到的 INF只有 passengerTypeCode -->
              (<span
                v-for="(
                  passenger, passengerIndex
                ) in flightShoppingInfoRef.passengers.filter(
                  (item) => item.quantity
                )"
                :key="passengerIndex"
              >
                <span v-if="passenger && passenger.quantity"
                  >{{ passenger.quantity }} {{ passenger.passengerTypeCode }}
                  <span
                    v-if="
                      passengerIndex <
                      flightShoppingInfoRef.passengers.filter(
                        (item) => item.quantity
                      ).length -
                        1
                    "
                    >,
                  </span></span
                > </span
              >)
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--机票列表 + Fare options中心区域显示-->
    <DynamicPkgFlightItemsAndUpsellOptionSelectPanel
      v-if="airSearchTypeRef === 'UPSELL'"
    />

    <DynamicPkgFlightItemsAndFareOptionSelectPanel v-else />
    <!--机票列表 + Fare options中心区域显示END-->
  </div>
  <!--显示搜索条件end-->
</template>

<style scoped></style>

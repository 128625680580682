<script setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useSecurityStore } from "@/stores/security";
import DynamicPkgHotelRoomTypeRoomItem from "@/views/v2/sales/dynamicpkg/items/group/DynamicPkgHotelRoomTypeRoomItem.vue";

const props = defineProps({
  roomTypeItem: {},
  roomTypeIndex: {},
  dynamicPkgAvailFormModel: {},
});

const { t, locale } = useI18n(); // 解构调用函数
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const securityStore = useSecurityStore();

const isAllShowRef = ref(false);

const roomsShowRef = ref(null);
const dynamicPkgAvailFormModelRef = ref(null);
const roomNumRef = ref(1); //房间数量
watch(
  () => props.roomTypeItem,
  (newValue) => {
    if (newValue && newValue.rooms && newValue.rooms.length > 0) {
      roomsShowRef.value = sortRoomByPrice(newValue.rooms).slice(0, 2);
    }
  },
  { immediate: true, deep: true }
);
watch(
  () => props.dynamicPkgAvailFormModel,
  (newValue) => {
    dynamicPkgAvailFormModelRef.value = newValue;

    //获取到房间数量
    roomNumRef.value =
      dynamicPkgAvailFormModelRef.value &&
      dynamicPkgAvailFormModelRef.value.roomPassengers &&
      dynamicPkgAvailFormModelRef.value.roomPassengers.length > 0
        ? dynamicPkgAvailFormModelRef.value.roomPassengers.length
        : null;
  },
  { immediate: true, deep: true }
);

//region 选择房间相关+append query
async function selectRoom(roomItem, roomTypeName) {
  const referenceId = roomItem.referenceId;
  const bedTypeName = roomItem.bedTypeName;

  const query = route.query;
  query.referenceId = referenceId;
  query.roomTypeName = roomTypeName;
  query.bedTypeName = bedTypeName;
  router.push({
    name: "sales-dynamicPkg-flight-list-v2",
    query: query,
  });
}

//endregion

//region 辅助函数

function isRoomsAllShow(rooms, isAll) {
  if (isAll) {
    isAllShowRef.value = true;
    roomsShowRef.value = sortRoomByPrice(rooms);
  } else {
    isAllShowRef.value = false;
    roomsShowRef.value = sortRoomByPrice(rooms.slice(0, 2));
  }
}

// 房间按照价格排序
function sortRoomByPrice(rooms) {
  if (!rooms) return rooms;

  return rooms.sort((a, b) => {
    return a.totalPrice - b.totalPrice;
  });
}

//endregion
</script>

<template>
  <thead v-if="roomTypeItem.roomDescription" class="font-14 mg-t-5">
    {{
      roomTypeItem.roomDescription
    }}
  </thead>

  <thead class="room-type-thead">
    <tr>
      <th>{{ $t("your-choice") }}</th>
      <th>{{ $t("sleeps") }}</th>
      <th>{{ $t("today-price") }}</th>
      <th>{{ $t("operation") }}</th>
    </tr>
  </thead>

  <tbody v-if="roomsShowRef && roomsShowRef.length > 0" class="hotel-rooms">
    <tr
      v-for="(roomItem, roomIndex) in roomsShowRef"
      :key="roomIndex"
      class="border-bottom room-item"
    >
      <DynamicPkgHotelRoomTypeRoomItem
        :room-index="roomIndex"
        :room-item="roomItem"
        :room-key="roomTypeIndex + '-' + roomIndex"
        :room-num="roomNumRef"
        :room-type-item="roomTypeItem"
        @selectRoom="selectRoom"
      />
    </tr>
  </tbody>

  <!--  show more-->
  <div
    v-if="roomTypeItem.rooms.length > 2"
    class="mg-t-10"
    style="
      color: #8b3eea;
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      left: 45%;
    "
  >
    <a
      v-if="!isAllShowRef"
      class="cursor-point"
      @click="isRoomsAllShow(roomTypeItem.rooms, true)"
      >{{ $t("show-more-room-rate") }}
      <i class="fa fa-fw fa-angle-down" style="color: #8b3eea"></i
    ></a>
    <a
      v-else
      class="cursor-point"
      @click="isRoomsAllShow(roomTypeItem.rooms, false)"
      >{{ $t("show-less") }}
      <i class="fa fa-fw fa-angle-up" style="color: #8b3eea"></i
    ></a>
  </div>
  <!--  show more END-->
</template>

<style lang="scss" scoped></style>

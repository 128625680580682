<script setup>
import { useI18n } from "vue-i18n";
import { CarRentalCoverageTypeItemVO } from "@/viewobject/sales/carrental/carRentalCoverageTypeItemVO";
import { Popover } from "ant-design-vue";

const props = defineProps({
  carRentalItemIndex: {},
  carRentalItem: {},
  daysApart: {},
});

const { t, locale } = useI18n(); // 解构调用函数
const APopover = Popover;
const emits = defineEmits([]);

function findBasicProtection(insurances) {
  if (insurances && insurances.length > 0) {
    // BASIC
    const basicCoverage = new CarRentalCoverageTypeItemVO(
      true,
      true,
      false,
      "BASIC",
      "",
      0,
      null,
      null
    );

    insurances.forEach((insurance) => {
      const currency = insurance.currency;
      const amount = insurance.amount;
      const code = insurance.code;
      const description = insurance.description;
      if (insurance.included && insurance.selected && !insurance.mandatory) {
        // 包含 已选择 非强制
        basicCoverage.totalAmount += amount;
        basicCoverage.currency = currency;
        basicCoverage.addInsuranceItem(insurance);
      }
    });

    return basicCoverage;
  }
}

function selectVehicle(classCode) {
  emits("selectVehicle", classCode);
}
</script>

<template>
  <div class="left_side_search_boxed" v-if="carRentalItem">
    <div class="car_list_items">
      <div class="car_list_left">
        <!--        logo-->
        <div class="car_logo">
          <img :src="carRentalItem.pictureURL" />
        </div>
      </div>
      <div class="car_list__mid">
        <div class="h5 text-center">{{ carRentalItem.vehName }}</div>

        <!--  提示信息 - 车辆类型-->
        <div class="mg-up-10 mg-b-10" v-if="carRentalItem.className">
          OR SIMILAR {{ carRentalItem.className }}

          <a-popover :z-index="22222" placement="right">
            <template #content>
              <div>
                The model you get on your pick up day is subject to
                availability.
              </div>
            </template>
            <i
              class="bi bi-exclamation-circle-fill mg-l-8 text-muted cursor-point"
            ></i>
          </a-popover>
        </div>
        <div class="ul_top mg-l-8">
          <span
            ><i class="fa-solid fa-people-group text-muted mg-r-5"></i
            >{{ carRentalItem.passengerQuantity }}</span
          ><span>
            <img
              src="@/assets/img/car_door.png"
              style="width: 24px; height: 18px"
            />{{ carRentalItem.doorCount }}</span
          >
          <!--   行李-->
          <span>
            <i class="fa-solid fa-suitcase-rolling text-muted mg-r-5"></i
            >{{ carRentalItem.baggageQuantity }}</span
          >
          <!--   自动手动-->
          <span
            ><i class="fa-solid fa-tachometer text-muted mg-r-5"></i
            >{{ carRentalItem.transmissionType === "Manual" ? "M" : "A" }}</span
          >
          <!-- 空调-->
          <span v-if="carRentalItem.airConditionInd"
            ><i class="fa-solid fa-snowflake text-muted mg-r-5"></i>A/C</span
          >
          <!-- 驾驶年龄-->
          <span
            ><i class="fa-regular fa-id-card text-muted mg-r-5"></i
            >{{ carRentalItem.minimumDriverAge }}</span
          >
        </div>
        <!--包含的设备-->

        <div class="ul_bott mg-t-5 font-14">
          <span v-if="carRentalItem.rateQualifier"
            ><i class="bi bi-check-lg text-primary"></i> Unlimited mileage</span
          >
          <span v-if="findBasicProtection(carRentalItem.insurances)">
            <i class="bi bi-check-lg text-primary"></i>
            Basic protection included
          </span>
        </div>
        <div class="mg-t-5">
          <h6
            :data-bs-target="'#collapseExample' + carRentalItemIndex"
            aria-controls="collapseExample"
            aria-expanded="false"
            class="text-center main_color cursor-point"
            data-bs-toggle="collapse"
          >
            {{ $t("show-more") }}<i class="fas fa-chevron-down mg-l-5"></i>
          </h6>
        </div>
      </div>
      <div class="car_list__right">
        <h6 v-if="carRentalItem.totalAmount">
          {{ $t("total-uppercase") }} {{ carRentalItem.currencyCode }}
          {{ carRentalItem.totalAmount.toFixed(2) }}
        </h6>
        <span
          v-if="daysApart && carRentalItem.totalAmount"
          class="font_weight text-warning"
          >{{ carRentalItem.currencyCode }}
          {{ (carRentalItem.totalAmount / daysApart).toFixed(2) }}
          /
          {{ $t("day") }}</span
        >
        <button
          class="btn btn_theme mg-t-10"
          @click="selectVehicle(carRentalItem.classCode)"
        >
          {{ $t("select") }}
        </button>
      </div>
    </div>
    <div :id="'collapseExample' + carRentalItemIndex" class="collapse">
      <div class="show_more">
        <hr style="margin-top: -10px" />
        <h5>Full vehicle details</h5>
        <div class="ul_top mg-t-5">
          <div class="mg-r-20">
            <span
              ><i class="fa-solid fa-people-group text-muted mg-r-5"></i
              >{{ carRentalItem.passengerQuantity }} {{ $t("people") }}</span
            >
          </div>
          <div class="mg-r-20">
            <span>
              <img
                src="@/assets/img/car_door.png"
                style="width: 24px; height: 18px; margin-top: -3px"
              />{{ carRentalItem.doorCount }} {{ $t("doors") }}</span
            >
          </div>
          <div class="mg-r-20">
            <span>
              <i class="fa-solid fa-suitcase-rolling text-muted mg-r-5"></i
              >{{ carRentalItem.baggageQuantity }} {{ $t("luggage") }}</span
            >
          </div>
          <div class="mg-r-20">
            <span
              ><i class="fa-solid fa-tachometer text-muted mg-r-5"></i
              >{{ carRentalItem.transmissionType }}</span
            >
          </div>
          <!-- 燃油类型-->
          <div v-if="carRentalItem.fuelType" class="mg-r-20">
            <span
              ><i class="fa-solid fa-gas-pump text-muted mg-r-5"></i
              >{{ carRentalItem.fuelType }}</span
            >
          </div>
          <!--空调-->
          <div class="mg-r-20">
            <span v-if="carRentalItem.airConditionInd"
              ><i class="fa-solid fa-snowflake text-muted mg-r-5"></i>A/C</span
            >
          </div>
          <div class="mg-r-20">
            <span
              ><i class="fa-regular fa-id-card text-muted mg-r-5"></i
              >{{
                $t("minimum-driving-age-years", {
                  minimumDriverAge: carRentalItem.minimumDriverAge,
                })
              }}
            </span>
          </div>
        </div>
        <!--保险-->
        <div v-if="findBasicProtection(carRentalItem.insurances)">
          <hr />
          <div class="info mg-t-20">
            <p class="text-danger mb-2 font-14">INCLUDED</p>
            <p class="h5 mb-2">Basic protection</p>
            <!--            <p class="h6 mb-2">Excess: HK$5,080.29</p>-->

            <div
              v-if="
                findBasicProtection(carRentalItem.insurances).insuranceItems &&
                findBasicProtection(carRentalItem.insurances).insuranceItems
                  .length > 0
              "
            >
              <p
                class="fs-sm fw-medium mb-2"
                v-for="(insurance, index) in findBasicProtection(
                  carRentalItem.insurances
                ).insuranceItems"
                :key="index"
              >
                <span class="text-muted"
                  ><i class="bi bi-check-lg main_color"></i>
                  {{ insurance.description }}</span
                >
              </p>
            </div>

            <p class="mb-2">
              <i
                class="bi bi-exclamation-circle-fill"
                style="color: #c1c3c7"
              ></i>
              You will be able to upgrade your protection after selecting this
              vehicle
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useActivityStore } from "@/stores/v2/activity/activity";
import { reactive, ref, watch } from "vue";
import activityTools from "@/tools/v2/activity/activityTools";
import contentApi from "@/apis/contentApi";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import * as regexUtils from "@/utils/regexUtils";
import { email, integer, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import commonUtils from "@/utils/commonUtils";
import { useI18n } from "vue-i18n";
import BaseActivityPassengerFormQuestionItem from "@/views/v2/sales/activity/items/form/BaseActivityPassengerFormQuestionItem.vue";
import { useCmsContentStore } from "@/stores/cms";

const router = useRouter();
const route = useRoute();

const templateStore = useTemplateStore();
const activityStore = useActivityStore();

const { t } = useI18n();
let countries = []; //地点相关
//for initiate
// Use vuelidate
let passengerFormModel = reactive(
  new activityTools.ActivityPassengerFormModel(
    "MR",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    []
  )
);

function getCountries(keyword) {
  document
    .getElementById("destination-car-dropdown-menu")
    .classList.add("show");
  contentApi.countries(keyword).then((res) => {
    countries = res.data;
  });
}

function selectCountry(country, menuId) {
  passengerFormModel.countryCode = country.code;
  passengerFormModel.countryName = country.name;
  document.getElementById(menuId).classList.remove("show");
}

const selectActivityGroupInfo = JSON.parse(
  sessionStorage.getItem("selectActivityGroupInfo")
);

const surnameRule = (value) => regexUtils.surnameRegx.test(value);
const givenNameRule = (value) => regexUtils.givenNameRegx.test(value);
let rules = reactive({
  nameTitle: { required },
  givenName: { required, givenNameRule },
  surname: { required, surnameRule },
  emailAddress: { required, email },
  phoneNumber: { required, integer },
  countryCode: { required },
  countryName: { required },
  postalCode: { required },
  addressLines: { required },
});

let activityQuestion = reactive(
  JSON.parse(sessionStorage.getItem("activityQuestion"))
);

if (activityQuestion && activityQuestion.length > 0) {
  activityQuestion.forEach((item) => {
    const title = item.title;
    //add to rules

    const question = item.descriptionList[0].stringValue;
    // rules[title] = {
    //   required,
    // };
    passengerFormModel.question.push({
      answer: "",
      title: title,
      question: question,
    });
  });
}

const v$ = useVuelidate(rules, passengerFormModel);

let adultNameTitleOptions = ref([
  {
    value: "MR",
    label: "Mr.",
  },
  {
    value: "MS",
    label: "Ms.",
  },
]);

function getPassengerType(typeCode) {
  let typeName = "Adult";
  if (typeCode === "CNN") {
    typeName = "Child";
  } else if (typeCode === "INF") {
    typeName = "Infant";
  }
  return typeName;
}

function countryChange(value) {
  passengerFormModel.countryAccessCode = value.dialCode;
}

//region 电话号码
function phoneNUmberInput(value, event) {
  if (
    event != null &&
    event.nationalNumber != null &&
    event.nationalNumber != ""
  ) {
    passengerFormModel.phoneNumber = event.nationalNumber;
  } else if (
    event != null &&
    event.nationalNumber == null &&
    event.formatted != null
  ) {
    passengerFormModel.phoneNumber = event.formatted;
  }
}

function phoneBlur() {
  v$.value.phoneNumber.$touch();
}

async function goBook() {
  const validateResult = await v$.value.$validate();
  if (!validateResult) return;
  sessionStorage.setItem(
    "tourActivityPassengerFormModel",
    JSON.stringify(passengerFormModel)
  );

  const languageRPH = selectActivityGroupInfo.languageRPH;
  const startDate = selectActivityGroupInfo.startDate;

  templateStore.pageLoader({ mode: "on" });
  activityStore
    .groundBook(languageRPH, startDate, passengerFormModel)
    .then((res) => {
      // router.push({ name: "sales-transfer-book" });
      templateStore.pageLoader({ mode: "off" });
      if (res.success) {
        sessionStorage.setItem(
          "activityReservationDetails",
          JSON.stringify(res.reservationDetails)
        );
        router.push({ name: "sales-activity-check-out-v2" });
      } else {
        const error = res.errors.errors[0].stringValue;
        commonUtils.openToastBox("ERROR", t("error"), error);
      }
    });
}

function goDetail() {
  let query = JSON.parse(sessionStorage.getItem("activityDetailQurey"));

  router.push({ name: "sales-activity-details-v2", query: query });
}

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.ActivityBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.ActivityBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>Passengers Info</h2>
        <ul>
          <li
            class="pre-page"
            v-if="route.query && route.query.isFromHome"
            @click="router.push({ name: 'home' })"
          >
            {{ $t("home") }}
          </li>
          <li
            v-else
            class="pre-page"
            @click="router.push({ name: 'sales-activity-home-v2' })"
          >
            Tour Activity
          </li>
          <li class="pre-page">
            <i class="fas fa-circle"></i>
            Activity List
          </li>
          <li>
            <i class="fas fa-circle"></i>
            Activity Details
          </li>
          <li>
            <i class="fas fa-circle"></i>
            Passengers Info
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->
  <section id="tour_details_main" class="section_padding">
    <div class="container hotel_container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goDetail">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="tour_details_boxed">
        <h3 class="heading_theme">Who is travelling?</h3>
        <div class="row" style="margin-top: 10px">
          <div class="col-10">
            <!-- Form Inline - Default Style -->
            <form class="align-items-center" @sumbit.prevent>
              <div class="row">
                <div class="col-4">
                  <select
                    id="passenger-form-nameTitle"
                    v-model="v$.nameTitle.$model"
                    :class="{
                      'is-invalid': v$.nameTitle.$errors.length > 0,
                    }"
                    class="form-control"
                    type="text"
                  >
                    <option
                      v-for="(
                        nameTitleOption, nameTitleOptionIndex
                      ) in adultNameTitleOptions"
                      :key="nameTitleOptionIndex"
                      :value="nameTitleOption.value"
                    >
                      {{ nameTitleOption.label }}
                    </option>
                  </select>
                </div>
                <div class="col-4">
                  <input
                    id="passenger-form-surname"
                    v-model="v$.surname.$model"
                    :class="{
                      'is-invalid': v$.surname.$errors.length > 0,
                    }"
                    autocomplete="off"
                    class="form-control"
                    name="passenger-form-surname"
                    :placeholder="$t('last-name')"
                    type="text"
                    @blur="v$.surname.$touch"
                  />
                </div>
                <div class="col-4">
                  <input
                    id="passenger-form-givenName"
                    v-model="v$.givenName.$model"
                    :class="{
                      'is-invalid': v$.givenName.$errors.length,
                    }"
                    autocomplete="off"
                    class="form-control"
                    name="passenger-form-givenName"
                    :placeholder="$t('first-name')"
                    type="text"
                    @blur="v$.givenName.$touch"
                  />
                </div>
              </div>
              <div class="row mg-t-10">
                <div class="col-4">
                  <input
                    v-model="v$.countryName.$model"
                    :class="{
                      'is-invalid': v$.countryCode.$errors.length > 0,
                    }"
                    aria-expanded="false"
                    aria-haspopup="true"
                    autocomplete="off"
                    class="form-control"
                    data-bs-toggle="dropdown"
                    :placeholder="$t('where-are-you-from')"
                    type="text"
                    @input="(event) => getCountries(event.target.value)"
                  />

                  <div
                    id="destination-car-dropdown-menu"
                    class="dropdown-menu fs-sm full-width"
                    style="width: 400px"
                  >
                    <div v-if="countries.length > 0">
                      <a
                        v-for="(country, suggestIndex) in countries"
                        :key="suggestIndex"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="
                          selectCountry(
                            country,
                            'destination-car-dropdown-menu'
                          )
                        "
                      >
                        <span> <i class="fa fa-location-dot"></i> </span>
                        <span class="text-muted me-0 mg-l-8">
                          {{ country.name }}
                        </span>
                      </a>
                    </div>
                    <div v-else>
                      <div class="dropdown-item">
                        <i class="fa fa-magnifying-glass me-3"></i>
                        {{ $t("search-by-destination") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <input
                    id="passenger-form-surname"
                    v-model="v$.postalCode.$model"
                    :class="{
                      'is-invalid': v$.postalCode.$errors.length > 0,
                    }"
                    autocomplete="off"
                    class="form-control"
                    name="passenger-form-surname"
                    :placeholder="$t('postal-code')"
                    type="text"
                    @blur="v$.postalCode.$touch"
                  />
                </div>
                <div class="col-4">
                  <input
                    id="passenger-form-givenName"
                    v-model="v$.addressLines.$model"
                    :class="{
                      'is-invalid': v$.addressLines.$errors.length,
                    }"
                    autocomplete="off"
                    class="form-control"
                    name="passenger-form-givenName"
                    :placeholder="$t('address')"
                    type="text"
                    @blur="v$.addressLines.$touch"
                  />
                </div>
              </div>

              <div class="row mg-t-10">
                <div class="col-5">
                  <!--            <label class="text-muted" for="area-code-select"-->
                  <!--              >Phone Number</label-->
                  <!--            >-->
                  <VueTelInput
                    v-model="v$.phoneNumber.$model"
                    :autoFormat="false"
                    :class="{
                      'is-invalid': v$.phoneNumber.$errors.length > 0,
                    }"
                    :inputOptions="{
                      placeholder: $t('enter-your-phone-number'),
                    }"
                    autocomplete="off"
                    class="form-control flex"
                    mode="international"
                    @blur="phoneBlur"
                    @input="phoneNUmberInput"
                    @country-changed="countryChange"
                  />
                </div>
                <div class="col-5">
                  <input
                    id="passenger-form-email"
                    v-model="v$.emailAddress.$model"
                    :class="{
                      'is-invalid': v$.emailAddress.$errors.length > 0,
                    }"
                    autocomplete="off"
                    class="form-control"
                    name="passenger-form-email"
                    :placeholder="$t('enter-a-email-address')"
                    type="email"
                    @blur="v$.emailAddress.$touch"
                  />
                </div>
              </div>

              <div class="row mg-t-20">
                <div
                  class="col-10"
                  v-for="(questionOption, index) in passengerFormModel.question"
                >
                  <BaseActivityPassengerFormQuestionItem
                    :question-index="index"
                    :question-option="questionOption"
                  />
                </div>
              </div>
            </form>
            <!-- END Form Inline - Default Style -->
          </div>
          <div class="col-10 text-center mg-t-10">
            <button class="btn btn_theme" @click="goBook">
              {{ $t("continue") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

<script setup>
import { reactive, ref, watch, watchEffect } from "vue";

import useVuelidate from "@vuelidate/core";
import BaseActivityPassengerFormChildItem from "@/views/v2/sales/activity/items/form/BaseActivityPassengerFormChildItem.vue";

const props = defineProps({
  childAgesValidCheck: {},
  passengerFormModel: {},
});
const emits = defineEmits();
//region 数据初始化
let passengerLabelShow = ref("");

let passengerFormModelProps = reactive(
  JSON.parse(JSON.stringify(props.passengerFormModel))
);
//region 监听事件
//监听人数变化
watchEffect(() => {
  if (passengerFormModelProps) {
    let adultNum = passengerFormModelProps.adultNum;
    let seniorNum = passengerFormModelProps.seniorNum;
    let childNum = passengerFormModelProps.childNum;
    passengerLabelShow.value =
      adultNum +
      " adult(s) , " +
      seniorNum +
      " senior(s) ," +
      childNum +
      " child(s)";
  }
});

//当用户未选择儿童岁数时，要主动校验，让输入框显示错误

watch(
  () => props.childAgesValidCheck,
  (newValue) => {
    let formElement = document.getElementById("roomPassengerForm");
    if (newValue && formElement) {
      //展開
      formElement.classList.add("show");
    } else if (!newValue && formElement) {
      formElement.classList.remove("show");
    }
  }
);
//endregion

//region 辅助函数
function increaseChild() {
  passengerFormModelProps.childAges.splice(
    passengerFormModelProps.childAges.length - 1,
    1
  );
  passengerFormModelProps.childNum--;
}

function decreaseChild() {
  passengerFormModelProps.childNum++;
  passengerFormModelProps.childAges.push({ age: null });
}

//校验和表单提交
const v = useVuelidate();
let childAgesValidCheck = ref(false);

async function submitRoomPassengersForm() {
  childAgesValidCheck.value = false;
  //在父组件中调用，则会校验所有子组件的规则，并统一返回校验结果
  const validateResult = await v.value.$validate();
  if (v.value.$errors && v.value.$errors.length > 0) {
    v.value.$errors.forEach((item) => {
      if (item != null && item.$property == "age") {
        childAgesValidCheck.value = true;
      }
    });
  }
  if (!validateResult) return;
  let formElement = document.getElementById("roomPassengerForm");
  formElement.classList.remove("show");
  emits("submitPassengersFormModel", passengerFormModelProps);
}
</script>

<template>
  <div
    v-if="passengerFormModelProps"
    class="search_boxed dropdown_passenger_area"
  >
    <p>{{ $t("travelers") }}</p>
    <div class="dropdown">
      <button
        class="dropdown-toggle final-count"
        data-toggle="dropdown"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
      >
        {{ passengerLabelShow }}
      </button>

      <div
        class="dropdown-menu dropdown_passenger_info fs-sm dropdown-menu-400"
        aria-labelledby="dropdownMenuButton1"
        id="roomPassengerForm"
      >
        <div class="traveller-calulate-persons">
          <div class="passengers">
            <form class="p-2" @submit.prevent>
              <div class="passenger-form">
                <div class="mb-4 row">
                  <div class="col-7">
                    <label class="form-label" for="dropdown-content-form-adults"
                      >{{ $t("adult") }}(26-59)</label
                    >
                  </div>
                  <div class="col-5 number-choice">
                    <div class="passenger_operator">
                      <button
                        class="btn btn_md"
                        :class="{
                          disabled: passengerFormModelProps.adultNum <= 1,
                        }"
                        @click="passengerFormModelProps.adultNum--"
                      >
                        -
                      </button>
                    </div>
                    <input
                      type="text"
                      :value="passengerFormModelProps.adultNum"
                      class="text-center"
                      placeholder="0"
                      id="dropdown-content-form-adults"
                      name="dropdown-content-form-adults"
                    />
                    <div class="passenger_operator">
                      <button
                        class="btn btn_md"
                        @click="passengerFormModelProps.adultNum++"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mb-4 row">
                  <div class="col-7">
                    <label class="form-label" for="dropdown-content-form-adults"
                      >{{ $t("senior") }}(60+)</label
                    >
                  </div>
                  <div class="col-5 number-choice">
                    <div class="passenger_operator">
                      <button
                        class="btn btn_md"
                        :class="{
                          disabled: passengerFormModelProps.seniorNum <= 0,
                        }"
                        @click="passengerFormModelProps.seniorNum--"
                      >
                        -
                      </button>
                    </div>
                    <input
                      type="text"
                      :value="passengerFormModelProps.seniorNum"
                      class="text-center"
                      placeholder="0"
                      id="dropdown-content-form-adults"
                      name="dropdown-content-form-adults"
                    />
                    <div class="passenger_operator">
                      <button
                        class="btn btn_md"
                        @click="passengerFormModelProps.seniorNum++"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mb-4 row">
                  <div class="col-7">
                    <label class="form-label" for="dropdown-content-form-adults"
                      >{{ $t("youth-child") }}(0-25)</label
                    >
                  </div>
                  <div class="col-5 number-choice">
                    <div class="passenger_operator">
                      <button
                        class="btn btn_md"
                        :class="{
                          disabled: passengerFormModelProps.childNum <= 0,
                        }"
                        @click="increaseChild"
                      >
                        -
                      </button>
                    </div>
                    <input
                      type="text"
                      :value="passengerFormModelProps.childNum"
                      class="text-center"
                      placeholder="0"
                      id="dropdown-content-form-adults"
                      name="dropdown-content-form-adults"
                    />
                    <div class="passenger_operator">
                      <button class="btn btn_md" @click="decreaseChild">
                        +
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mb-4 row">
                  <BaseActivityPassengerFormChildItem
                    :child-age="childAge"
                    :child-index="childIndex"
                    :key="childIndex"
                    v-for="(
                      childAge, childIndex
                    ) in passengerFormModelProps.childAges"
                  />
                </div>
              </div>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn col-11"
                  @click="submitRoomPassengersForm"
                >
                  {{ $t("done") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

import { defineStore } from "pinia";

import activityTools from "@/tools/v2/activity/activityTools";
import activityApi from "@/apis/v2/activity/activityApi";

export const useActivityStore = defineStore(
  "activity",
  {
    state: () => {
      return {
        activityViewItems: null,
        activityDetailsView: null,
        reservationDetails: null,
        bookRQ: null,
      };
    },
    actions: {
      availActivity(payload) {
        const activityAvailRQ = activityTools.getSailAvailRQ(payload);
        let _this = this;

        return activityApi
          .availActivity(activityAvailRQ)
          .then((res) => {
            const availActivityRS = res.data;
            _this.activityViewItems =
              activityTools.getActivityViewItems(availActivityRS);

            return availActivityRS;
          })
          .catch((err) => {
            console.error(err);
          });
      },
      async activityDetails(payload) {
        const activityDetailsRQ = activityTools.getActivityDetailRQ(payload);
        let _this = this;

        return activityApi
          .activityDetails(activityDetailsRQ)
          .then((res) => {
            const availActivityRS = res.data;
            _this.activityDetailsView =
              activityTools.getActivityDetailsView(availActivityRS);
          })
          .catch((err) => {
            console.error(err);
            // router.push({ name: "error-500" });
          });
      },
      async groundBook(languageRPH, startDate, passengerFormModel) {
        const groundBookRQ = activityTools.getBookInitialRQ(
          languageRPH,
          startDate,
          passengerFormModel
        );

        let _this = this;
        //搜索可租车辆
        return activityApi
          .activityBook(groundBookRQ)
          .then((res) => {
            const initiateResRS = res.data;

            if (initiateResRS.success) {
              const reservationDetails = initiateResRS.reservationDetails;
              _this.reservationDetails = reservationDetails;
            }
            return initiateResRS;
          })
          .catch((err) => {
            console.error(err);
            // router.push({ name: "error-500" });
          });
      },
    },
  },
  {}
);

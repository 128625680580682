<script setup>
import { ref, watch } from "vue";
import dateUtils from "@/utils/dateUtils";
import { useI18n } from "vue-i18n";
import carRentalAvailApi from "@/apis/v2/carrental/carRentalAvailApi";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import FlatPickr from "vue-flatpickr-component";
import { useRoute, useRouter } from "vue-router";
import { CarRentalAvailFormModel } from "@/formmodel/sales/carrental/availablility/carRentalAvailFormModel";
import { useTemplateStore } from "@/stores/template";
import { useCarRentalAvailabilityStore } from "@/stores/v2/carrental/availablility/carRentalAvailabilityStore";
import commonUtils from "@/utils/commonUtils";
import carRentalCommonTools from "@/tools/v2/carrental/common/carRentalCommonTools";
import commonContentApi from "@/apis/v2/content/commonContentApi";

const props = defineProps({
  jumboable: {
    type: Boolean,
  },
  jumpTo: {
    type: String,
    description: "route name to jump",
  },
});

const { t, locale } = useI18n(); // 解构调用函数
const route = useRoute();
const router = useRouter();
const templateStore = useTemplateStore();
const carRentalAvailStore = useCarRentalAvailabilityStore();

const showVehicleTypeSelectRef = ref(false);
const activeVehicleTypeMapRef = ref({}); //记录已选择车辆类型的状态
const isSameLocationRef = ref(false);
const vehicleTypeOptionsRef = ref([
  { code: "1", name: "car" },
  { code: "5", name: "vans-trucks" },
]);
const suggestVehicleOptionsRef = ref(null);
const countryOptionsRef = ref(null);

const carRentalAvailFormModelRef = ref(
  new CarRentalAvailFormModel(
    null,
    null,
    null,
    null,
    dateUtils.getOffsetDate(new Date(), 7) + "T08:00",
    dateUtils.getOffsetDate(new Date(), 8) + "T08:00",
    null,
    null,
    null
  )
);

const flatPickrStateRef = ref({
  inlineTime: [
    carRentalAvailFormModelRef.value.pickupDatetime,
    carRentalAvailFormModelRef.value.returnDatetime,
  ],
  configInlineTime: {
    mode: "range",
    minDate: "today",
    dateFormat: "Y-m-d H:i",
    enableTime: true,
  },
});

//#region query参数请求，进行搜索
watch(
  () => route.query,
  (newValue) => {
    if (newValue != null && Object.keys(newValue).length > 0) {
      if (!props.jumboable) {
        isSameLocationRef.value = JSON.parse(newValue.isReturn); //是否地点一样 - 字符串类型需要先转换成布尔类型
        if (newValue.preferredCarType)
          //选中的车辆类型
          activeVehicleTypeMapRef.value[newValue.preferredCarType] = true;
        carRentalAvailFormModelRef.value =
          carRentalCommonTools.buildCarRentalAvailFormModal(newValue);

        searchCarRentalByFormModel(carRentalAvailFormModelRef.value);
      }
    }

    flatPickrStateRef.value.inlineTime = [];
    flatPickrStateRef.value.inlineTime.push(
      carRentalAvailFormModelRef.value.pickupDatetime,
      carRentalAvailFormModelRef.value.returnDatetime
    );
  },
  { immediate: true, deep: true }
);

//#endregion

watch(
  () => isSameLocationRef.value,
  (newValue) => {
    if (newValue) {
      carRentalAvailFormModelRef.value.returnLocation = null;
      carRentalAvailFormModelRef.value.returnLocationName = null;
    }
  },
  { immediate: true }
);

//region 表单校验
const validateReturn = () => {
  if (
    (isSameLocationRef.value &&
      carRentalAvailFormModelRef.value.pickupLocation != null) ||
    (!isSameLocationRef.value &&
      carRentalAvailFormModelRef.value.pickupLocation != null &&
      carRentalAvailFormModelRef.value.returnLocation != null)
  ) {
    return true;
  } else {
    return false;
  }
};
const rulesRef = ref({
  pickupDatetime: {
    required,
  },
  returnDatetime: {
    required,
  },
  pickupLocation: {
    required,
  },
  returnLocation: {
    validateReturn,
  },
  countryOfResidence: {
    required,
  },
});
const v = useVuelidate(rulesRef.value, carRentalAvailFormModelRef);
//endregion

//region 辅助函数
//选择车辆类型
function chooseVehicleType(vehicleTypeCode) {
  if (activeVehicleTypeMapRef.value[vehicleTypeCode]) {
    activeVehicleTypeMapRef.value = {};
    activeVehicleTypeMapRef.value[vehicleTypeCode] = false;
    carRentalAvailFormModelRef.value.preferredCarType = null;
  } else {
    activeVehicleTypeMapRef.value = {};
    activeVehicleTypeMapRef.value[vehicleTypeCode] = true;
    carRentalAvailFormModelRef.value.preferredCarType = vehicleTypeCode;
  }
}

//endregion

//region 表单相关函数

//取车地点相关
function getVehicleLocations(keyword, type) {
  if (type === "from") {
    carRentalAvailFormModelRef.value.pickupLocation = null;
    document.getElementById("sales-car-from-destination").classList.add("show");
  } else {
    document.getElementById("sales-car-to-destination").classList.add("show");
    carRentalAvailFormModelRef.value.returnLocation = null;
  }
  const searchDebounceFUN = commonUtils.debounce(
    function () {
      carRentalAvailApi.carRentalLocations(keyword, "en").then((res) => {
        suggestVehicleOptionsRef.value = res.data;
      });
    },
    "searchCarRental",
    500
  );

  searchDebounceFUN();
}

function selectVehiclePlace(suggestPlace, menuId, type) {
  if (type === "from") {
    carRentalAvailFormModelRef.value.pickupLocationName = suggestPlace.address;
    carRentalAvailFormModelRef.value.pickupLocation = suggestPlace.locationCode;
    document.getElementById(menuId).classList.remove("show");
  } else {
    carRentalAvailFormModelRef.value.returnLocationName = suggestPlace.address;
    carRentalAvailFormModelRef.value.returnLocation = suggestPlace.locationCode;
    document.getElementById(menuId).classList.remove("show");
  }
}

//选择时间
function chooseDates(dates) {
  if (dates.length === 2) {
    let checkIn = dateUtils.GMTToStr(dates[0]);
    let checkOut = dateUtils.GMTToStr(dates[1]);
    carRentalAvailFormModelRef.value.pickupDatetime = checkIn;
    carRentalAvailFormModelRef.value.returnDatetime = checkOut;
  } else {
    carRentalAvailFormModelRef.value.pickupDatetime = null;
    carRentalAvailFormModelRef.value.returnDatetime = null;
  }
}

//居住地
function getCountries(keyword) {
  document
    .getElementById("destination-car-dropdown-menu")
    .classList.add("show");
  const searchDebounceFUN = commonUtils.debounce(
    function () {
      commonContentApi.countriesAutocomplete(keyword).then((res) => {
        countryOptionsRef.value = res.data;
      });
    },
    "searchCountry",
    500
  );

  searchDebounceFUN();
}

function selectCountry(country, menuId) {
  carRentalAvailFormModelRef.value.countryOfResidence = country.code;
  carRentalAvailFormModelRef.value.countryName = country.name;
  document.getElementById(menuId).classList.remove("show");
}

async function submitCarRentalAvailFormModal(carRentalAvailFormModel) {
  const result = await v.value.$validate();
  if (!result) return;
  let carRentalAvailFormModelCopy = JSON.parse(
    JSON.stringify(carRentalAvailFormModel)
  );
  let query = carRentalAvailFormModelCopy;
  if (isSameLocationRef.value) {
    query.returnLocation = carRentalAvailFormModelCopy.pickupLocation;
    query.returnLocationName = carRentalAvailFormModelCopy.pickupLocationName;
    query.isReturn = true;
  } else {
    query.isReturn = false;
  }
  router.replace({ query: query });
  if (props.jumboable) {
    router.push({
      name: props.jumpTo,
      query: query,
    });
  } else {
    searchCarRentalByFormModel(carRentalAvailFormModelCopy);
  }
}

async function searchCarRentalByFormModel(formModel) {
  templateStore.pageLoader({ mode: "on" });
  //每次搜索之后存储表单
  sessionStorage.setItem("carRentalAvailFormModel", JSON.stringify(formModel));
  let res = await carRentalAvailStore.carRentalAvail(formModel);
  templateStore.pageLoader({ mode: "off" });
}

//endregion
</script>

<template>
  <div
    class="row"
    :class="{
      theme_search_form_area: props.jumpTo == null,
    }"
  >
    <div class="col-lg-12 col-12">
      <div
        class="h6 mg-l-15 mg-t-10 cursor-point"
        @click="showVehicleTypeSelectRef = !showVehicleTypeSelectRef"
      >
        {{ $t("type-of-vehicle") }}<i class="bi bi-chevron-down mg-l-8"></i>
      </div>
      <div v-if="showVehicleTypeSelectRef" class="row mg-up-20">
        <div class="col-6">
          <div class="vehicle-list">
            <div
              :class="{
                'vehicle-active': activeVehicleTypeMapRef[vehicleType.code],
                'vehicle-car': vehicleType.code === '1',
                'vehicle-truck': vehicleType.code === '5',
              }"
              class="text-center"
              @click="chooseVehicleType(vehicleType.code)"
              v-for="(vehicleType, vehicleIndex) in vehicleTypeOptionsRef"
              :key="vehicleIndex"
            >
              <i class="fa-solid fa-car text-secondary font-24"></i>
              <span>{{ vehicleType.name ? $t(vehicleType.name) : null }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tour_search_form">
        <form action="javascript:void(0);">
          <!-- 表单项名称 -->
          <div class="row">
            <!--            <div class="col-3">-->
            <!--              <span class="h6">{{ $t("pick-return-location") }}</span>-->
            <!--            </div>-->
            <div class="col-3">
              <input
                v-model="isSameLocationRef"
                class="form-check-input"
                type="checkbox"
              />
              <span class="mg-l-8 h6">{{ $t("same-return-location") }}</span>
            </div>
            <!--            <div class="col-4">-->
            <!--              <span class="h6">{{ $t("pickup-and-return-time") }}</span>-->
            <!--            </div>-->
            <!--            <div class="col-2">-->
            <!--              <span class="h6">{{ $t("i-live-in") }}</span>-->
            <!--            </div>-->
          </div>
          <!-- 表单项名称END -->
          <div class="row">
            <div class="col-6" v-if="isSameLocationRef">
              <div class="search_boxed">
                <p>{{ $t("pick-up-location") }}</p>
                <input
                  v-model="carRentalAvailFormModelRef.pickupLocationName"
                  :class="{
                    'is-invalid': v.pickupLocation.$errors.length > 0,
                  }"
                  aria-expanded="false"
                  aria-haspopup="true"
                  autocomplete="off"
                  data-bs-toggle="dropdown"
                  :placeholder="$t('pick-up-location')"
                  type="text"
                  @input="
                    (event) => getVehicleLocations(event.target.value, 'from')
                  "
                />
                <span
                  id="whereGo"
                  class="text-danger"
                  v-if="v.pickupLocation.$errors.length > 0"
                  >Please enter pick up location</span
                >
                <span id="whereGo" v-else
                  >{{ $t("where-are-you-going") }}?</span
                >

                <div
                  id="sales-car-from-destination"
                  aria-labelledby="sales-car-from-destination"
                  class="dropdown-menu fs-sm full-width hotel_search_dropdown"
                >
                  <div
                    v-if="
                      suggestVehicleOptionsRef &&
                      suggestVehicleOptionsRef.length > 0
                    "
                  >
                    <a
                      v-for="(
                        suggest, suggestIndex
                      ) in suggestVehicleOptionsRef"
                      :key="suggestIndex"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="
                        selectVehiclePlace(
                          suggest,
                          'sales-car-from-destination',
                          'from'
                        )
                      "
                    >
                      <div class="flex">
                        <div class="mg-r-10">
                          <i class="fa fa-location-dot"></i>
                        </div>
                        <div>
                          {{ suggest.locationName }}
                          <p class="text-muted me-0 margin-0">
                            {{ suggest.address }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div v-else>
                    <div class="dropdown-item">
                      <i class="fa fa-magnifying-glass me-3"></i>
                      {{ $t("search-by-destination") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="col-1" v-if="isSameLocationRef"></div>-->
            <div class="col-3" v-if="!isSameLocationRef">
              <div class="search_boxed">
                <p>{{ $t("pick-up-location") }}</p>
                <input
                  v-model="carRentalAvailFormModelRef.pickupLocationName"
                  :class="{
                    'is-invalid': v.pickupLocation.$errors.length > 0,
                  }"
                  aria-expanded="false"
                  aria-haspopup="true"
                  autocomplete="off"
                  data-bs-toggle="dropdown"
                  :placeholder="$t('pick-up-location')"
                  type="text"
                  @input="
                    (event) => getVehicleLocations(event.target.value, 'from')
                  "
                />
                <span
                  id="whereGo"
                  class="text-danger"
                  v-if="v.pickupLocation.$errors.length > 0"
                  >Please enter pick up location</span
                >
                <span id="whereGo" v-else
                  >{{ $t("where-are-you-going") }}?</span
                >

                <div
                  id="sales-car-from-destination"
                  aria-labelledby="sales-car-from-destination"
                  class="dropdown-menu fs-sm full-width hotel_search_dropdown"
                >
                  <div
                    v-if="
                      suggestVehicleOptionsRef &&
                      suggestVehicleOptionsRef.length > 0
                    "
                  >
                    <a
                      v-for="(
                        suggest, suggestIndex
                      ) in suggestVehicleOptionsRef"
                      :key="suggestIndex"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="
                        selectVehiclePlace(
                          suggest,
                          'sales-car-from-destination',
                          'from'
                        )
                      "
                    >
                      <div class="flex">
                        <div class="mg-r-10">
                          <i class="fa fa-location-dot"></i>
                        </div>
                        <div>
                          {{ suggest.locationName }}
                          <p class="text-muted me-0 margin-0">
                            {{ suggest.address }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div v-else>
                    <div class="dropdown-item">
                      <i class="fa fa-magnifying-glass me-3"></i>
                      {{ $t("search-by-destination") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3" v-if="!isSameLocationRef">
              <div class="search_boxed">
                <p>{{ $t("return-location") }}</p>
                <input
                  v-model="carRentalAvailFormModelRef.returnLocationName"
                  :class="{
                    'is-invalid': v.returnLocation.$errors.length > 0,
                  }"
                  aria-expanded="false"
                  aria-haspopup="true"
                  autocomplete="off"
                  data-bs-toggle="dropdown"
                  :placeholder="$t('return-location')"
                  type="text"
                  @input="
                    (event) => getVehicleLocations(event.target.value, 'to')
                  "
                />
                <span
                  id="whereGo"
                  class="text-danger"
                  v-if="v.returnLocation.$errors.length > 0"
                  >Please enter drop off location</span
                >
                <span id="whereGo" v-else
                  >{{ $t("where-are-you-going") }}?</span
                >

                <div
                  id="sales-car-to-destination"
                  aria-labelledby="sales-car-to-destination"
                  class="dropdown-menu fs-sm full-width hotel_search_dropdown"
                >
                  <div
                    v-if="
                      suggestVehicleOptionsRef &&
                      suggestVehicleOptionsRef.length > 0
                    "
                  >
                    <a
                      v-for="(
                        suggest, suggestIndex
                      ) in suggestVehicleOptionsRef"
                      :key="suggestIndex"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="
                        selectVehiclePlace(
                          suggest,
                          'sales-car-to-destination',
                          'to'
                        )
                      "
                    >
                      <div class="flex">
                        <div class="mg-r-10">
                          <i class="fa fa-location-dot"></i>
                        </div>
                        <div>
                          {{ suggest.locationName }}
                          <p class="text-muted me-0 margin-0">
                            {{ suggest.address }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div v-else>
                    <div class="dropdown-item">
                      <i class="fa fa-magnifying-glass me-3"></i>
                      {{ $t("search-by-destination") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="form_search_date">
                <div class="search_boxed">
                  <p>{{ $t("pickup-and-return-time") }}</p>
                  <FlatPickr
                    class="form-control"
                    name="flight-search-date"
                    placeholder="Y-m-d"
                    v-model="flatPickrStateRef.inlineTime"
                    :config="flatPickrStateRef.configInlineTime"
                    @on-change="chooseDates"
                  />
                  <span class="mg-l-8"
                    >{{
                      dateUtils.getDaysByDateRange(
                        carRentalAvailFormModelRef.pickupDatetime,
                        carRentalAvailFormModelRef.returnDatetime
                      )
                    }}
                    {{ $t("day") }} (s)
                  </span>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="search_boxed">
                <p>{{ $t("i-live-in") }}</p>
                <input
                  id="sales-hotel-destination"
                  v-model="carRentalAvailFormModelRef.countryName"
                  :class="{
                    'is-invalid': v.countryOfResidence.$errors.length > 0,
                  }"
                  aria-expanded="false"
                  aria-haspopup="true"
                  autocomplete="off"
                  data-bs-toggle="dropdown"
                  name="sales-hotel-destination"
                  :placeholder="$t('living-country')"
                  type="text"
                  @input="(event) => getCountries(event.target.value)"
                />
                <span
                  id="whereGo"
                  class="text-danger"
                  v-if="v.countryOfResidence.$errors.length > 0"
                  >Please enter your living country</span
                >
                <span id="whereGo" v-else>Where are you from?</span>

                <div
                  id="destination-car-dropdown-menu"
                  aria-labelledby="sales-hotel-destination"
                  class="dropdown-menu fs-sm full-width hotel_search_dropdown"
                  style="width: 100%"
                >
                  <div v-if="countryOptionsRef && countryOptionsRef.length > 0">
                    <a
                      v-for="(country, suggestIndex) in countryOptionsRef"
                      :key="suggestIndex"
                      class="dropdown-item flex font-18 align-items-center"
                      href="javascript:void(0)"
                      @click="
                        selectCountry(country, 'destination-car-dropdown-menu')
                      "
                    >
                      <i class="fa fa-location-dot"></i>
                      <div class="mg-l-8">
                        {{ country.name }}
                      </div>
                    </a>
                  </div>
                  <div v-else>
                    <div class="dropdown-item">
                      <i class="fa fa-magnifying-glass me-3"></i>
                      Search by location
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="top_form_search_button">
              <button
                class="btn btn_theme btn_md"
                @click="
                  submitCarRentalAvailFormModal(carRentalAvailFormModelRef)
                "
              >
                {{ $t("search") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

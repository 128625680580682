/**
 *
 * @param carRentalItems Array，carRentalItemVO对象数组，数据源
 * @param sortFormModel Object，carRentalResultsSortFormModel对象，包含排序条件
 * @returns carRentalItems Array，carRentalItemVO对象数组，排序后的数组
 */
function sortCarRentalItems(carRentalItems, sortFormModel) {
  if (!carRentalItems || carRentalItems.length === 0) return carRentalItems;

  let type = sortFormModel.type;
  let order = sortFormModel.order;

  return carRentalItems.sort((a, b) => {
    if (type === "PRICE" && order === "ASC") {
      //升序
      return a.totalAmount - b.totalAmount;
    } else if (type === "PRICE" && order === "DSC") {
      //降序
      return b.totalAmount - a.totalAmount;
    } else if (type === "NAME" && order === "ASC") {
      //升序
      return a.vehName.localeCompare(b.vehName);
    } else if (type === "NAME" && order === "DSC") {
      //降序
      return b.vehName.localeCompare(a.vehName);
    }
  });
}

export default {
  sortCarRentalItems,
};

<template>

    <!-- Common Banner Area -->
    <DashboardBanner />

    <!-- Dashboard Area -->
    <DashboardArea />

    <!-- Cta Area -->
    <Cta />


</template>
<script>
import DashboardBanner from '@/components/templates/dashboard/DashboardBanner.vue'
import DashboardArea from '@/components/templates/dashboard/DashboardArea.vue'
import Cta from '@/components/templates/home/Cta.vue'

export default {
    name: "DashboardView",
    components: {
        DashboardBanner, DashboardArea, Cta
    }
};
</script>
<template>
    <!-- Banner Area -->
    <Banner />

    <!-- Top Service And Tour Area-->
    <TopService />

    <!-- Holiday Destinations  Area-->
    <HolidayDestinations />

    <!-- About Area -->
    <About />

    <!-- Find travel partner -->
    <FindTravellPartner />

    <!-- Choose your destinations Area-->
    <ChooseDestination />


    <!--Client Area -->
    <ClientSays />


    <!-- Our partners Area -->
    <Partners />

    <!-- Cta Area -->
    <Cta />

</template>
  
<script>
// @ is an alias to /src
import Banner from '@/components/templates/hometwo/Banner.vue'
import TopService from '@/components/templates/hometwo/TopService.vue'
import HolidayDestinations from '@/components/templates/hometwo/HolidayDestinations.vue'
import About from '@/components/templates/hometwo/About.vue'
import FindTravellPartner from '@/components/templates/hometwo/FindTravellPartner.vue'
import ChooseDestination from '@/components/templates/hometwo/ChooseDestination.vue'
import ClientSays from '@/components/templates/hometwo/ClientSays.vue'
import Partners from '@/components/templates/home/Partners.vue'
import Cta from '@/components/templates/home/Cta.vue'
export default {
    name: 'HomeTwoView',
    components: {
        Banner, TopService, HolidayDestinations, About, FindTravellPartner, ChooseDestination, ClientSays, Partners, Cta
    }
}
</script>
  
<script setup>
import { ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import contentApi from "@/apis/contentApi";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import FlatPickr from "vue-flatpickr-component";
import commonUtils from "@/utils/commonUtils";

const props = defineProps({
  originDestinationFormModel: {},
  originDesIndex: {},
  originDesNum: {},
  tripType: {},
});

const router = useRouter();
const route = useRoute();

const emits = defineEmits();
const { t, locale } = useI18n(); // 解构调用函数

const flightOriginDesModelStateRef = ref({
  suggestFlightOriginalPlaces: [],
  suggestFlightDestinationPlaces: [],
});
const fromId = "flight-from" + props.originDesIndex;
const toId = "flight-to" + props.originDesIndex;
const originDestinationFormModelRef = ref(null);

watch(
  () => props.originDestinationFormModel,
  (newValue) => {
    originDestinationFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);
const flatPickrStateRef = ref({
  dateDefault: {
    minDate: "today",
    dateFormat: "Y-m-d",
    locale:
      locale.value === "zh_CN" ||
      locale.value === "zh_TW" ||
      locale.value === "zh_HK"
        ? "zh"
        : locale.value,
  },
  arrivalDate: {
    minDate: "today",
    dateFormat: "Y-m-d",
    locale:
      locale.value === "zh_CN" ||
      locale.value === "zh_TW" ||
      locale.value === "zh_HK"
        ? "zh"
        : locale.value,
  },
});
watch(
  () => props.originDestinationFormModel.departureDate,
  (newValue) => {
    flatPickrStateRef.value.arrivalDate = {
      minDate: newValue,
      dateFormat: "Y-m-d",
    };
  },
  { deep: true, immediate: true }
);

//region 表單校驗
// Validation rules
// const locationRule = (value) => /^[A-Z]{3}$/.test(value);
const originLocationCodeRule = (value) => {
  let valid = true;
  if (
    flightOriginDesModelStateRef.value.suggestFlightOriginalPlaces &&
    flightOriginDesModelStateRef.value.suggestFlightOriginalPlaces.length > 0
  ) {
    valid = flightOriginDesModelStateRef.value.suggestFlightOriginalPlaces.some(
      (item) => item.code === value
    );
  }

  return valid;
};

const destinationLocationCodeRule = (value) => {
  let valid = true;
  if (
    flightOriginDesModelStateRef.value.suggestFlightDestinationPlaces &&
    flightOriginDesModelStateRef.value.suggestFlightDestinationPlaces.length > 0
  ) {
    valid =
      flightOriginDesModelStateRef.value.suggestFlightDestinationPlaces.some(
        (item) => item.code === value
      );
  }

  return valid;
};
const rulesRef = ref({
  departureDate: {
    required,
  },
  originLocationCode: {
    required,
    originLocationCodeRule,
  },
  destinationLocationCode: {
    required,
    destinationLocationCodeRule,
  },
});

const v = useVuelidate(rulesRef.value, originDestinationFormModelRef);
//endregion

//region 语言改变
watch(
  () => locale.value,
  (newValue) => {
    flatPickrStateRef.value.dateDefault.locale =
      newValue === "zh_CN" || newValue === "zh_TW" || newValue === "zh_HK"
        ? "zh"
        : newValue;
    flatPickrStateRef.value.arrivalDate.locale =
      newValue === "zh_CN" || newValue === "zh_TW" || newValue === "zh_HK"
        ? "zh"
        : newValue;
  },
  { immediate: true }
);
//endregion

//region 表单对象相关函数

//地点输入事件
function getSuggestFlightPlaces(keyword, type) {
  if (type === "from") {
    document.getElementById(toId).classList.remove("show");
    originDestinationFormModelRef.value.originLocationCode =
      originDestinationFormModelRef.value.originLocationName;
    //当对应码相同时优先，对应码相同且popularity更先优先，对应码相同且popularity相同对比name，其余比较popularity
    const searchDebounceFUN = commonUtils.debounce(
      function () {
        contentApi.airportCities(keyword, "en").then((res) => {
          if (res.data && res.data.length > 0) {
            res.data.sort(function (a, b) {
              if (
                a.code === keyword.toUpperCase() &&
                b.code !== keyword.toUpperCase()
              ) {
                return -1;
              } else if (
                b.code === keyword.toUpperCase() &&
                a.code !== keyword.toUpperCase()
              ) {
                return 1;
              } else if (a.code === a.cityCode && b.code !== b.cityCode) {
                return -1;
              } else if (a.code !== a.cityCode && b.code === b.cityCode) {
                return 1;
              } else if (a.popularity === b.popularity) {
                return a.name - b.name;
              } else {
                return a.popularity - b.popularity;
              }
            });
          }
          flightOriginDesModelStateRef.value.suggestFlightOriginalPlaces =
            res.data;
        });
      },
      "searchOriginalFlight",
      500
    );
    searchDebounceFUN();
    document.getElementById(fromId).classList.add("show");
  } else {
    document.getElementById(fromId).classList.remove("show");
    originDestinationFormModelRef.value.destinationLocationCode =
      originDestinationFormModelRef.value.destinationLocationName;
    //当对应码相同时优先，对应码相同且popularity更先优先，对应码相同且popularity相同对比name，其余比较popularity
    const searchDebounceFUN = commonUtils.debounce(
      function () {
        contentApi.airportCities(keyword, "en").then((res) => {
          if (res.data && res.data.length > 0) {
            res.data.sort(function (a, b) {
              if (
                a.code === keyword.toUpperCase() &&
                b.code !== keyword.toUpperCase()
              ) {
                return -1;
              } else if (
                b.code === keyword.toUpperCase() &&
                a.code !== keyword.toUpperCase()
              ) {
                return 1;
              } else if (a.code === a.cityCode && b.code !== b.cityCode) {
                return -1;
              } else if (a.code !== a.cityCode && b.code === b.cityCode) {
                return 1;
              } else if (a.popularity === b.popularity) {
                return a.name - b.name;
              } else {
                return a.popularity - b.popularity;
              }
            });
          }
          flightOriginDesModelStateRef.value.suggestFlightDestinationPlaces =
            res.data;
        });
      },
      "searchDestinationFlight",
      500
    );
    searchDebounceFUN();
    document.getElementById(toId).classList.add("show");
  }
}

//地址自动补全选择
function selectFlightPlace(suggestPlace, type) {
  let name = suggestPlace.name;
  if (suggestPlace.code === suggestPlace.cityCode)
    name = suggestPlace.city + "(" + suggestPlace.code + ")";

  if (type === "from") {
    originDestinationFormModelRef.value.originLocationCode = suggestPlace.code;
    originDestinationFormModelRef.value.originLocationName = name;

    document.getElementById(fromId).classList.remove("show");
  } else {
    originDestinationFormModelRef.value.destinationLocationCode =
      suggestPlace.code;
    originDestinationFormModelRef.value.destinationLocationName = name;
    document.getElementById(toId).classList.remove("show");
  }
}

function deleteOrigDestDateItem(originDesIndex) {
  emits("deleteOrigDestDateItem", originDesIndex);
}

function addOrigDestDateItem(originDesIndex) {
  emits("addOrigDestDateItem", originDesIndex);
}

function changeDeparture(originDesIndex) {
  if (props.tripType !== "ONE_WAY") {
    emits("updateOriginDestinationDepartureDate", originDesIndex);
  }
}

//endregion
</script>

<template>
  <div v-if="originDestinationFormModelRef" class="row mg-b-20">
    <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
      <div class="search_boxed">
        <p>{{ $t("from") }}</p>
        <input
          id="sales-flight-from-destination"
          v-model="originDestinationFormModelRef.originLocationName"
          :class="{
            'is-invalid':
              v.originLocationCode.$errors.length > 0 ||
              (originDestinationFormModelRef.originLocationCode &&
                originDestinationFormModelRef.destinationLocationCode &&
                originDestinationFormModelRef.originLocationName ===
                  originDestinationFormModelRef.destinationLocationName),
          }"
          :disabled="tripType === 'RETURN' && originDesIndex === 1"
          :placeholder="$t('departure')"
          aria-expanded="false"
          aria-haspopup="true"
          autocomplete="off"
          data-bs-toggle="dropdown"
          name="sales-flight-from-destination"
          type="text"
          @input="(event) => getSuggestFlightPlaces(event.target.value, 'from')"
        />
        <span
          v-if="
            v.originLocationCode.$errors.length > 0 ||
            (originDestinationFormModelRef.originLocationCode &&
              originDestinationFormModelRef.destinationLocationCode &&
              originDestinationFormModelRef.originLocationName ===
                originDestinationFormModelRef.destinationLocationName)
          "
          style="display: inline-block; height: 23px; width: 300px; color: red"
          >{{ $t("please-enter-your-valid-point") }}</span
        >

        <div class="plan_icon_posation">
          <i class="fas fa-plane-departure"></i>
        </div>

        <!--下拉框-->
        <div
          :id="fromId"
          aria-labelledby="sales-hotel-destination"
          class="dropdown-menu fs-sm full-width hotel_search_dropdown"
          style="width: 100%"
        >
          <div
            v-if="
              flightOriginDesModelStateRef.suggestFlightOriginalPlaces.length >
              0
            "
          >
            <a
              v-for="(
                suggest, suggestIndex
              ) in flightOriginDesModelStateRef.suggestFlightOriginalPlaces"
              :key="suggestIndex"
              class="dropdown-item"
              href="javascript:void(0)"
              @click="selectFlightPlace(suggest, 'from')"
            >
              <div class="row">
                <div class="col-11">
                  <i
                    v-if="suggest.code === suggest.cityCode"
                    class="fa fa-location-dot"
                  ></i>
                  <i v-else class="fa fa-plane-departure"></i>
                  {{
                    suggest.code === suggest.cityCode
                      ? suggest.city + "(" + suggest.code + ")"
                      : suggest.name
                  }}
                </div>
              </div>
            </a>
          </div>
          <div v-else>
            <div class="dropdown-item">
              <i class="fa fa-magnifying-glass me-3"></i>
              {{ $t("search-by-departure") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
      <div class="search_boxed">
        <p>{{ $t("to") }}</p>
        <input
          id="sales-flight-to-destination"
          v-model="originDestinationFormModelRef.destinationLocationName"
          :class="{
            'is-invalid':
              v.destinationLocationCode.$errors.length > 0 ||
              (originDestinationFormModelRef.originLocationCode &&
                originDestinationFormModelRef.destinationLocationCode &&
                originDestinationFormModelRef.originLocationName ===
                  originDestinationFormModelRef.destinationLocationName),
          }"
          :disabled="tripType === 'RETURN' && originDesIndex === 1"
          :placeholder="$t('arrival')"
          aria-expanded="false"
          aria-haspopup="true"
          autocomplete="off"
          data-bs-toggle="dropdown"
          name="sales-flight-to-destination"
          type="text"
          @input="(event) => getSuggestFlightPlaces(event.target.value, 'to')"
        />
        <span
          v-if="
            v.destinationLocationCode.$errors.length > 0 ||
            (originDestinationFormModelRef.originLocationCode &&
              originDestinationFormModelRef.destinationLocationCode &&
              originDestinationFormModelRef.originLocationName ===
                originDestinationFormModelRef.destinationLocationName)
          "
          style="display: inline-block; height: 23px; width: 300px; color: red"
          >{{ $t("please-enter-your-valid-point") }}</span
        >
        <div class="plan_icon_posation">
          <i class="fas fa-plane-arrival"></i>
        </div>
        <!--交换地点-->
        <!--        <div class="range_plan" @click="changeOriginDestination(originDestinationFormModelRef)">-->
        <!--          <i class="fas fa-exchange-alt cursor-point"></i>-->
        <!--        </div>-->

        <div
          :id="toId"
          aria-labelledby="sales-hotel-destination"
          class="dropdown-menu fs-sm full-width hotel_search_dropdown"
          style="width: 100%"
        >
          <div
            v-if="
              flightOriginDesModelStateRef.suggestFlightDestinationPlaces
                .length > 0
            "
          >
            <a
              v-for="(
                suggest, suggestIndex
              ) in flightOriginDesModelStateRef.suggestFlightDestinationPlaces"
              :key="suggestIndex"
              class="dropdown-item"
              href="javascript:void(0)"
              @click="selectFlightPlace(suggest, 'to')"
            >
              <div class="row">
                <div class="col-11">
                  <i
                    v-if="suggest.code === suggest.cityCode"
                    class="fa fa-location-dot"
                  ></i>
                  <i v-else class="fa fa-plane-departure"></i>
                  {{
                    suggest.code === suggest.cityCode
                      ? suggest.city + "(" + suggest.code + ")"
                      : suggest.name
                  }}
                </div>
              </div>
            </a>
          </div>
          <div v-else>
            <div class="dropdown-item">
              <i class="fa fa-magnifying-glass me-3"></i>
              {{ $t("search-by-departure") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
      <div class="search_boxed">
        <p>{{ $t("departure-date") }}</p>
        <FlatPickr
          v-model="originDestinationFormModelRef.departureDate"
          :class="{
            'is-invalid': v.departureDate.$errors.length > 0,
          }"
          :config="flatPickrStateRef.dateDefault"
          class="form-control"
          name="flight-search-date"
          placeholder="Y-m-d"
          @change="changeDeparture(originDesIndex)"
        />
      </div>
    </div>
    <div
      v-if="tripType === 'MULTI_WAY'"
      class="col-xxl-1 col-xl-1 col-lg-6 col-md-12 col-sm-12 col-12"
    >
      <div class="flex-row mg-t-10">
        <button
          v-if="originDesNum >= 3 && originDesIndex !== 0"
          class="btn btn-outline-secondary mg-r-5"
          @click="deleteOrigDestDateItem(originDesIndex)"
        >
          <i class="fas fa-minus"></i>
        </button>
        <button
          v-if="originDesNum === originDesIndex + 1"
          class="btn btn-outline-secondary"
          @click="addOrigDestDateItem(originDesIndex)"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

import BookingSuccessView from "@/views/v2/sales/common/BookingSuccessView.vue";

export default [
  {
    path: "/sales/v2/booking",
    redirect: "/sales/v2/booking/booking-success",
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "booking-success",
        name: "sales-v2-booking-success",
        component: BookingSuccessView,
        meta: { requiresAuth: true },
      },
    ],
  },
];

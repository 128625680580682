export class CarRentalBookFormModel {
  constructor(
    quoteID,
    protectionPlan,
    selectedInsurances,
    selectedEquipments,
    driverFormModel
  ) {
    this.quoteID = quoteID;
    this.protectionPlan = protectionPlan;
    this.selectedInsurances = selectedInsurances;
    this.selectedEquipments = selectedEquipments;
    this.driverFormModel = driverFormModel;
  }
}

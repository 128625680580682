export class TripAirItemPaymentDetailVO {
  /**
   *
   * @param channelCode string，MO-BSP，EC-Direct
   * @param paymentType string，支付类型
   * @param cardCode string，卡所属代码
   * @param cardNumber string，卡号
   * @param seriesCode string，序列号
   * @param expireDate string，过期日期年份，yyyy-MM-dd
   * @param currencyCode string，货币单位
   * @param amount number，价格金额
   */
  constructor(
    channelCode,
    paymentType,
    cardCode,
    cardNumber,
    seriesCode,
    expireDate,
    currencyCode,
    amount
  ) {
    this.channelCode = channelCode;
    this.cardCode = cardCode;
    this.cardNumber = cardNumber;
    this.expireDate = expireDate;
    this.seriesCode = seriesCode;
    this.paymentType = paymentType;
    this.currencyCode = currencyCode;
    this.amount = amount;
  }
}

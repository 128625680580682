import PrivacyPolicyView from "@/views/privacypolicy/PrivacyPolicyView.vue";

export default [
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicyView   ,
    meta: {
      footerShow: true,
    },
  },
];

<script setup>
import { ref, watch } from "vue";
import { useTripStore } from "@/stores/v2/trip/tripStore";
import ReservationContactInfoPanel from "@/views/v2/trip/booking/commom/ReservationContactInfoPanel.vue";
import FlightPassengerInfoPanel from "@/views/v2/trip/booking/items/panel/flight/FlightPassengerInfoPanel.vue";
import FlightPriceSummaryOrBreakdownPanel from "@/views/v2/sales/flight/items/panel/book/FlightPriceSummaryOrBreakdownPanel.vue";
import { useAirContentStore } from "@/stores/v2/content/airContentStore";
import { FlightModifyFormModel } from "@/formmodel/sales/flight/modify/flightModifyFormModel";
import { TripAirItemPaymentDetailVO } from "@/viewobject/trip/flight/payment/tripAirItemPaymentDetailVO";
import FlightItineraryInfoPanel from "@/views/v2/sales/flight/items/panel/book/FlightItineraryInfoPanel.vue";
import ReservationOperationPanel from "@/views/v2/trip/booking/commom/ReservationOperationPanel.vue";
import FlightServicesPriceSummaryPanel from "@/views/v2/sales/flight/items/panel/book/FlightServicesPriceSummaryPanel.vue";
import ReservationOverViewPanel from "@/views/v2/trip/booking/commom/ReservationOverViewPanel.vue";

const props = defineProps({
  recordLocator: {},
  idContext: {},
  tripId: {},
  tripItemType: {},
  isFromTripList: {},
  equipmentType: {},
});

const emits = defineEmits();
const tripStore = useTripStore();
const airContentStore = useAirContentStore();
airContentStore.findAircraftsMap();
airContentStore.findAirlineMap();

const tripAirItemRef = ref(null);
const tripAirPriceClassesRef = ref(null);
const airlineCapabilityRef = ref([]);

//用于service summary部分管理offer
const flightModifyFormModelRef = ref(
  new FlightModifyFormModel(
    null,
    props.tripId,
    props.tripAirItemId,
    null,
    props.recordLocator,
    props.idContext,
    null,
    new TripAirItemPaymentDetailVO(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  )
);

const itineraryCancelledRef = ref(true);
const ticketVoidedRef = ref(true);
let expireInSecondRef = ref(null);
let isPaymentTimeExpired = ref(false);
let createdDateTimeRef = ref(null);
let bookingStatusRef = ref(null);
watch(
  () => tripStore.tripItems,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      const tripAirItems = newValue[0].tripAirItems;
      createdDateTimeRef.value = newValue[0].createdDateTime;
      bookingStatusRef.value = newValue[0].tripStatus;
      if (tripAirItems && tripAirItems.length > 0) {
        tripAirItemRef.value = tripAirItems[tripAirItems.length - 1];
      }

      //赋值后，再进行AirlineCapability和AirportCityMap搜索,留意tripAirItemRef被修改的地方会导致监听多次
      if (tripAirItemRef.value) {
        //1.获取所有airportCodes
        const flightItems = tripAirItemRef.value.flightItems;

        if (createdDateTimeRef.value && bookingStatusRef.value) {
          let createTime = new Date(createdDateTimeRef.value).getTime();
          if (
            bookingStatusRef.value !== "CANCELLED" &&
            bookingStatusRef.value !== "CONFIRMED"
          ) {
            let nowTime = new Date().getTime();
            expireInSecondRef.value = (createTime - nowTime) / 1000 + 15 * 60;
            if (expireInSecondRef.value < 0) {
              isPaymentTimeExpired.value = true;
            }
          }
        }
        const segments = [];
        if (flightItems && flightItems.length > 0) {
          flightItems.forEach((flight) => {
            segments.push(...flight.flightSegments);
          });
        }

        const airportCodeMap = {};
        if (segments && segments.length > 0) {
          segments.forEach((segment) => {
            if (!airportCodeMap[segment.departureAirportCode]) {
              const departureAirportCode = segment.departureAirportCode;
              airportCodeMap[departureAirportCode] = null;
            }
            if (!airportCodeMap[segment.arrivalAirportCode]) {
              const arrivalAirportCode = segment.arrivalAirportCode;
              airportCodeMap[arrivalAirportCode] = null;
            }
          });
        }
        airContentStore.findAirportCityMap(Object.keys(airportCodeMap));

        //2.将所有选择的附加服务放到flightModifyFormModel中传给需要操作修改附加服务费组件中
        const tripAirItemFare = tripAirItemRef.value.tripAirItemFare;
        if (tripAirItemFare) {
          flightModifyFormModelRef.value.servicesSelected =
            tripAirItemFare.ancillaryFares;
        }
      }
    }
  },
  { deep: true, immediate: true }
);

//region 辅助函数

function buildContactInfo(passengers) {
  if (passengers && passengers.length > 0) {
    const passenger = passengers.find((item) => item.contractPhones);
    const dialAreaCode =
      passenger &&
      passenger.contractPhones &&
      passenger.contractPhones.length > 0
        ? passenger.contractPhones[0].dialAreaCode
        : null;

    const phoneNumber =
      passenger &&
      passenger.contractPhones &&
      passenger.contractPhones.length > 0
        ? passenger.contractPhones[0].phoneNumber
        : null;

    const emailAddress =
      passenger &&
      passenger.contractEmails &&
      passenger.contractEmails.length > 0
        ? passenger.contractEmails[0].emailAddress
        : null;

    return {
      dialAreaCode: dialAreaCode,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
    };
  }
}

//endregion

function syncSessionAndSearch() {
  emits("syncSessionAndSearch");
}
</script>

<template>
  <div v-if="tripAirItemRef" class="row">
    <div class="col-10">
      <!-- 未支付订单提醒-->
      <div
        v-if="bookingStatusRef === 'CONFIRMED' && tripItemType === 'AIR'"
        class="col-12"
      >
        <div
          class="alert alert-warning d-flex align-items-center justify-content-between mg-b-20"
          role="alert"
        >
          <div class="flex-grow-1 me-3">
            <p class="mb-0">
              <i class="fa fa-fw fa-exclamation-circle"></i>
              {{ $t("the-booking-has-been-paid-and-is-waiting-to-be-issued") }}
            </p>
          </div>
        </div>
      </div>
      <!-- 未支付订单提醒END-->

      <!-- booking manage -->
      <ReservationOperationPanel
        :booking-id="tripId"
        :booking-status="bookingStatusRef"
        :equipment-type="equipmentType"
        :id-context="idContext"
        :record-locator="recordLocator"
        :show-continue-to-payment="false"
        :trip-item-id="tripAirItemRef.tripItemId"
        :trip-item-type="tripItemType"
        @syncSessionAndSearch="syncSessionAndSearch"
      />
      <!-- booking manage END-->

      <ReservationOverViewPanel
        :airline-capability="airlineCapabilityRef"
        :booking-status="bookingStatusRef"
        :created-date-time="createdDateTimeRef"
        :expire-in-second="expireInSecondRef"
        :id-context="idContext"
        :is-from-trip-list="isFromTripList"
        :itinerary-cancelled="itineraryCancelledRef"
        :record-locator="recordLocator"
        :ticket-voided="ticketVoidedRef"
        :trip-air-item="tripAirItemRef"
        :trip-id="tripId"
        :trip-item-type="tripItemType"
      />

      <!-- Itinerary Info -->

      <FlightItineraryInfoPanel
        :price-classes="tripAirPriceClassesRef"
        :trip-air-item="tripAirItemRef"
      />

      <!-- Itinerary Info END -->

      <!-- contact Info -->
      <ReservationContactInfoPanel
        :contact-info="buildContactInfo(tripAirItemRef.passengers)"
      />

      <!-- contact Info END-->

      <!-- Passenger Info -->
      <FlightPassengerInfoPanel
        :contact-info="buildContactInfo(tripAirItemRef.passengers)"
        :passengers="tripAirItemRef.passengers"
      />
      <!-- Passenger Info END-->

      <!-- Services Summary -->

      <FlightServicesPriceSummaryPanel
        v-if="
          flightModifyFormModelRef.servicesSelected &&
          flightModifyFormModelRef.servicesSelected.length > 0
        "
        :airline-capability="airlineCapabilityRef"
        :form-model="flightModifyFormModelRef"
        :id-context="idContext"
        :is-reservation-page="true"
        :passenger-items="tripAirItemRef.passengers"
        :show-title="true"
        :trip-air-item="tripAirItemRef"
      />

      <!--Services Summary  END -->

      <!-- Price Summary -->
      <FlightPriceSummaryOrBreakdownPanel
        v-if="
          tripAirItemRef &&
          tripAirItemRef.tripAirItemFare &&
          tripAirItemRef.tripAirItemFare.passengerFares
        "
        :is-flight-reservation="true"
        :trip-air-item="tripAirItemRef"
      />
      <!-- Price Summary  END -->
    </div>
  </div>
  <!-- END Page Content -->
</template>

<style lang="css" scoped></style>

<script setup>
import { useCmsContentStore } from "@/stores/cms";

const footerContentStore = useCmsContentStore();
footerContentStore.getContentManagements();

footerContentStore.getFooterContent();
</script>
<template>
  <div v-if="footerContentStore.contentManagement">
    <footer
      v-if="footerContentStore.contentManagement.showFooterContent"
      id="footer_area"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div
              v-if="
                footerContentStore.contentManagement.showNeedAnyHelpContent &&
                footerContentStore.footerContent &&
                (footerContentStore.footerContent.phoneNumber ||
                  footerContentStore.footerContent.emailAddress ||
                  footerContentStore.footerContent.facebookLink ||
                  footerContentStore.footerContent.instagramLink)
              "
              class="footer_heading_area"
            >
              <h5>{{ $t("need-help") }}</h5>
            </div>
            <div
              v-if="
                footerContentStore.footerContent &&
                footerContentStore.contentManagement.showNeedAnyHelpContent &&
                footerContentStore.footerContent.phoneNumber
              "
              class="footer_first_area"
            >
              <div
                v-if="
                  footerContentStore.contentManagement
                    .showNeedAnyHelpPhoneContent
                "
                class="footer_inquery_area"
              >
                <span>{{ $t("call-us") }}</span>
                <p>
                  <a class="main_color" href="tel:+00-123-456-789">{{
                    footerContentStore.footerContent.phoneNumber
                  }}</a>
                </p>
              </div>
              <div
                v-if="
                  footerContentStore.contentManagement
                    .showNeedAnyHelpEmailContent &&
                  footerContentStore.footerContent &&
                  footerContentStore.footerContent.emailAddress
                "
                class="footer_inquery_area"
              >
                <span>{{ $t("mail-us") }}</span>
                <p>
                  <a
                    :href="footerContentStore.footerContent.emailAddress"
                    class="main_color"
                    >{{ footerContentStore.footerContent.emailAddress }}</a
                  >
                </p>
              </div>
              <div
                v-if="
                  footerContentStore.footerContent &&
                  footerContentStore.contentManagement
                    .showNeedAnyHelpFollowUsContent &&
                  (footerContentStore.footerContent.facebookLink ||
                    footerContentStore.footerContent.instagramLink)
                "
                class="footer_inquery_area"
              >
                <span>{{ $t("follow-us") }}</span>
                <ul class="soical_icon_footer">
                  <li v-if="footerContentStore.footerContent.facebookLink">
                    <a :href="footerContentStore.footerContent.facebookLink"
                      ><i class="fab fa-facebook main_color"></i
                    ></a>
                  </li>
                  <!--                  <li>-->
                  <!--                    <a href="#!"><i class="fab fa-twitter-square"></i></a>-->
                  <!--                  </li>-->
                  <li v-if="footerContentStore.footerContent.instagramLink">
                    <a :href="footerContentStore.footerContent.instagramLink"
                      ><i class="fab fa-instagram main_color"></i
                    ></a>
                  </li>
                  <!--                  <li>-->
                  <!--                    <a href="#!"><i class="fab fa-linkedin"></i></a>-->
                  <!--                  </li>-->
                </ul>
              </div>
            </div>
          </div>

          <div
            v-if="footerContentStore.contentManagement.showCompanyContent"
            class="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12"
          >
            <div class="footer_heading_area">
              <h5>{{ $t("company") }}</h5>
            </div>
            <div class="footer_link_area">
              <ul>
                <li
                  v-if="
                    footerContentStore.contentManagement
                      .showCompanyWhoWeAreContent
                  "
                >
                  <router-link to="/about-us">{{ $t("who-we") }}</router-link>
                </li>
                <!--                <li>-->
                <!--                  <router-link to="/templates/testimonials"-->
                <!--                    >Testimonials</router-link-->
                <!--                  >-->
                <!--                </li>-->
                <!--                <li><router-link to="/templates/faqs">Rewards</router-link></li>-->
                <!--                <li>-->
                <!--                  <router-link to="/templates/terms-service"-->
                <!--                    >Work with Us</router-link-->
                <!--                  >-->
                <!--                </li>-->
                <!--                <li>-->
                <!--                  <router-link to="/templates/tour-guides"-->
                <!--                    >Meet the Team-->
                <!--                  </router-link>-->
                <!--                </li>-->
                <!--                <li><router-link to="/templates/news">Blog</router-link></li>-->
              </ul>
            </div>
          </div>

          <div
            v-if="footerContentStore.contentManagement.showSupportContent"
            class="col-lg-2 col-md-4 col-sm-6 col-12"
          >
            <div class="footer_heading_area">
              <h5>{{ $t("support") }}</h5>
            </div>
            <div class="footer_link_area">
              <ul>
                <!--                <li>-->
                <!--                  <router-link to="/templates/dashboard">Account</router-link>-->
                <!--                </li>-->
                <li
                  v-if="
                    footerContentStore.contentManagement.showSupportFaqContent
                  "
                >
                  <router-link to="/faq">Faq</router-link>
                </li>
                <!--                <li>-->
                <!--                  <router-link to="/templates/testimonials">Legal</router-link>-->
                <!--                </li>-->
                <li
                  v-if="
                    footerContentStore.contentManagement
                      .showSupportContactContent
                  "
                >
                  <router-link to="/contact-us"
                    >{{ $t("contact") }}
                  </router-link>
                </li>
                <!--                <li>-->
                <!--                  <router-link to="/templates/top-destinations">-->
                <!--                    Affiliate Program</router-link-->
                <!--                  >-->
                <!--                </li>-->
                <li
                  v-if="
                    footerContentStore.contentManagement
                      .showSupportPrivacyPolicyContent
                  "
                >
                  <router-link to="/privacy-policy"
                    >{{ $t("privacy-policy") }}
                  </router-link>
                </li>
                <!--                <li-->
                <!--                  v-if="-->
                <!--                    footerContentStore.contentManagement.showSupportTACContent-->
                <!--                  "-->
                <!--                >-->
                <!--                  <router-link to="/terms-condition">{{-->
                <!--                    $t("terms-and-condition")-->
                <!--                  }}</router-link>-->
                <!--                </li>-->
              </ul>
            </div>
          </div>
          <!--          <div class="col-lg-2 col-md-4 col-sm-6 col-12">-->
          <!--            <div class="footer_heading_area">-->
          <!--              <h5>Other Services</h5>-->
          <!--            </div>-->
          <!--            <div class="footer_link_area">-->
          <!--              <ul>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/top-destinations-details"-->
          <!--                    >Community program</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/top-destinations-details"-->
          <!--                    >Investor Relations</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/flight-search-result"-->
          <!--                    >Rewards Program</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/room-booking"-->
          <!--                    >PointsPLUS</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/testimonials"-->
          <!--                    >Partners</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/hotel-search"-->
          <!--                    >List My Hotel</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="col-lg-2 col-md-4 col-sm-6 col-12">-->
          <!--            <div class="footer_heading_area">-->
          <!--              <h5>Top cities</h5>-->
          <!--            </div>-->
          <!--            <div class="footer_link_area">-->
          <!--              <ul>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/room-details"-->
          <!--                    >Chicago</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/hotel-details"-->
          <!--                    >New York</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/hotel-booking"-->
          <!--                    >San Francisco</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/tour-search"-->
          <!--                    >California</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/tour-booking">Ohio </router-link>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <router-link to="/templates/tour-guides">Alaska</router-link>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </footer>
    <div v-if="footerContentStore.footerContent" class="copyright_area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="copyright_left">
              <p>
                {{
                  footerContentStore.footerContent.copyRight
                    ? footerContentStore.footerContent.copyRight
                    : $t("copy-right")
                }}
              </p>
            </div>
          </div>
          <!--          <div class="co-lg-6 col-md-6 col-sm-12 col-12">-->
          <!--            <div class="copyright_right">-->
          <!--              <img src="../../assets/img/common/cards.png" alt="img" />-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <!-- Common Banner Area -->
  <LoginBanner />

  <!--  Common Author Area -->
  <CommonAuthorFour />

  <!-- Cta Area -->
  <Cta />
</template>
<script>
import LoginBanner from "@/components/templates/user/EmptyBanner.vue";
import CommonAuthorFour from "@/components/templates/user/CommonAuthorFour.vue";
import Cta from "@/components/templates/home/Cta.vue";

export default {
  name: "LoginView",
  components: {
    LoginBanner,
    CommonAuthorFour,
    Cta,
  },
};
</script>

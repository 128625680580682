import HotelHomeView from "@/views/v2/sales/hotel/HotelHomeView.vue";
import HotelListView from "@/views/v2/sales/hotel/HotelListView.vue";
import HotelDetailsView from "@/views/v2/sales/hotel/HotelDetailsView.vue";
import HotelCheckOutView from "@/views/v2/sales/hotel/HotelCheckOutView.vue";

export default [
  {
    path: "/sales/v2/hotel",
    redirect: "/sales/v2/hotel/hotel-home",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "hotel-home",
        name: "sales-hotel-home-v2",
        meta: { requiresAuth: false, footerShow: true },
        component: HotelHomeView,
      },
      {
        path: "hotel-list",
        name: "sales-hotel-list-v2",
        meta: { requiresAuth: false },
        component: HotelListView,
      },
      {
        path: "hotel-details",
        name: "sales-hotel-details-v2",
        meta: { requiresAuth: false },
        component: HotelDetailsView,
      },

      {
        path: "hotel-checkout",
        name: "sales-hotel-checkout-v2",
        meta: { requiresAuth: false },
        component: HotelCheckOutView,
      },
    ],
  },
];

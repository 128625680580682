function getContactRQ(name, emailAddress, telephoneNumber, message) {
  return {
    name: name,
    emailAddress: emailAddress,
    telephoneNumber: telephoneNumber,
    message: message,
  };
}

export default {
  getContactRQ,
};

import axios from "@/utils/axiosUtils";

function carRentalRates(vehRatesRQ) {
  let url = "/api/v3/sales/shopping/vehicle/rates";
  return axios.post(url, vehRatesRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  carRentalRates,
};

export default {
  departureDate: "2023-10-09",
  returnDate: "2023-10-19",
  originLocationCode: "HKG",
  destinationLocationCode: "SIN",
  tticode: null,
  roomNum: 2,
  adultNum: 2,
  cabinClass: "Y",
  childAges: [
    {
      roomId: 0,
      age: 6,
    },
    {
      roomId: 1,
      age: 6,
    },
  ],
};

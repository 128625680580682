<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  tripHotelItem: {},
});

const roomPassengersMapRef = ref({});
watch(
  () => props.tripHotelItem,
  (newValue) => {
    if (newValue) {
      const passengers = newValue.passengers;
      if (
        newValue.roomTypes &&
        newValue.roomTypes.length > 0 &&
        passengers &&
        passengers.length > 0
      ) {
        const roomType = newValue.roomTypes[0];
        if (roomType && roomType.rooms && roomType.rooms.length > 0) {
          const roomName =
            roomType && roomType.roomTypeName
              ? roomType.roomTypeName
              : roomType.roomTypeCode;

          roomType.rooms.forEach((room, roomIndex) => {
            const resGuestRPHs = room.resGuestRPHs;
            roomPassengersMapRef.value[roomIndex + 1] = {
              roomName: null,
              passengers: [],
            };
            if (resGuestRPHs && resGuestRPHs.length > 0) {
              resGuestRPHs.forEach((rph) => {
                const passengerItem = passengers.find(
                  (passenger) => passenger.resGuestRPH === rph
                );
                if (passengerItem) {
                  roomPassengersMapRef.value[roomIndex + 1].roomName = roomName;
                  roomPassengersMapRef.value[roomIndex + 1].passengers.push(
                    passengerItem
                  );
                }
              });
            }
          });
        }
      }
    }
  },
  { deep: true, immediate: true }
);

function filterPassengers(resGuestList) {
  if (resGuestList && resGuestList.length > 0) {
    return resGuestList.filter(
      (item) =>
        (item.ageQualifyingCode === "10" && item.surname.indexOf("TBA") < 0) ||
        item.mainIndicator
    );
  }
}
</script>

<template>
  <div
    class="tour_details_boxed"
    v-if="roomPassengersMapRef && Object.keys(roomPassengersMapRef).length > 0"
  >
    <h3 class="heading_theme">{{ $t("guest-info") }}</h3>
    <div v-for="(room, roomKey) in roomPassengersMapRef" :key="roomKey">
      <div class="font_weight">
        {{ $t("room") }} {{ roomKey }} :
        <span class="text-muted">
          {{ room.roomName }}
        </span>
      </div>
      <div class="row" v-if="room && room.passengers">
        <div
          v-for="(passenger, paxIndex) in filterPassengers(room.passengers)"
          :key="paxIndex"
          class="col-6"
        >
          <div>{{ $t("passenger") }} {{ paxIndex + 1 }}</div>
          <div class="p-2">
            <div
              v-if="passenger.nameTitle"
              class="flex justify-content-between align-content-center"
            >
              <div>{{ $t("name-title") }}</div>
              <div>
                {{ passenger.nameTitle }}
              </div>
            </div>
            <div class="flex justify-content-between align-content-center">
              <div>{{ $t("name-info") }}</div>
              <div>{{ passenger.surname }} / {{ passenger.givenName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

import { defineStore } from "pinia";
import router from "@/router/modulesRouter";
import dynamicPackageTools from "@/tools/dynamicPackageTools";
import dynamicPackageApi from "@/apis/dynamicPackageApi";
import hotelTools from "@/tools/hotelTools";
import hotelApi from "@/apis/hotelApi";
import flightTools from "@/tools/flightTools";
import contentApi from "@/apis/contentApi";
export const useDynamicPackageStore = defineStore("dynamicPackage", {
  state: () => {
    return {
      hotelViewItemMap: null, //ttiCode -> hotelViewItem 的映射表
      sortedHotelViewItems: null,
      holdRoomInfo: null,
      hotelImages: null,
      preBookRoomInfo: null,
      flightViewItemsGroup: null,
      airportCityMap: null,
      origDestGroupMap: null,
      pricedItineraries: null,
      offerViewItemMap: null,
      matchFlightViewItemsGroup: null,
      pricedFlightViewItemsGroup: null,
      prebookRS: null,
      bookRQ: null,
    };
  },
  actions: {
    availDynamicPkg(pkgSearchFormModel) {
      let availRQ = dynamicPackageTools.getAvailRQ(pkgSearchFormModel);
      //区分是否是酒店详情搜索
      let _this = this;
      if (
        pkgSearchFormModel.ttiCode != null &&
        pkgSearchFormModel.ttiCode !== ""
      ) {
        let descriptiveRQ = hotelTools.getDescriptiveRQ(
          pkgSearchFormModel.ttiCode,
          pkgSearchFormModel.language
        );
        const hotelDescriptiveRSP = hotelApi
          .descriptive(descriptiveRQ)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.error(err);
            // router.push({ name: "error-500" });
          });

        const availRSP = dynamicPackageApi
          .avail(availRQ)
          .then((res) => {
            return res.data;
          })
          .catch((error) => {
            //console.log(error);
            // router.push({ name: "error-500" });
          });

        return Promise.all([hotelDescriptiveRSP, availRSP]).then((res) => {
          const hotelDescriptiveRS = res && res.length > 0 ? res[0] : {};
          const pkgAvailRS = res && res.length > 0 ? res[1] : {};
          if (
            pkgAvailRS &&
            pkgAvailRS.searchResults &&
            pkgAvailRS.searchResults.airResults &&
            pkgAvailRS.searchResults.hotelResults
          ) {
            _this.hotelViewItemMap = dynamicPackageTools.getHotelViewItemMap(
              pkgAvailRS.searchResults.hotelResults,
              hotelDescriptiveRS
            );

            _this.flightViewItemsGroup =
              dynamicPackageTools.getFlightViewItemsGroup(
                pkgAvailRS.searchResults.airResults,
                _this.transmittingOptions
              );
            //default is first origin to destination group
            _this.matchFlightViewItemsGroup =
              flightTools.getMatchFlightViewItemsGroup(
                _this.flightViewItemsGroup,
                0
              );

            if (
              pkgAvailRS.searchResults &&
              pkgAvailRS.searchResults.airResults &&
              pkgAvailRS.searchResults.airResults.length > 0 &&
              pkgAvailRS.searchResults.airResults[0].pricedItineraries &&
              pkgAvailRS.searchResults.airResults[0].pricedItineraries
                .pricedItineraries
            ) {
              _this.pricedItineraries =
                pkgAvailRS.searchResults.airResults[0].pricedItineraries.pricedItineraries;

              //获取地点名
              let airportCodeMap = flightTools.getAirportCodeMap(
                pkgAvailRS.searchResults.airResults[0].pricedItineraries
                  .pricedItineraries
              );
              _this.airportCityMap = {};
              let promises = [];
              for (const airportCodeMapKey in airportCodeMap) {
                let promise = contentApi.airportCity(airportCodeMapKey);
                promises.push(promise);
              }
              return Promise.all(promises).then((res) => {
                if (res && res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    let data = res[i].data;
                    _this.airportCityMap[data.code] = data;
                  }

                  _this.origDestGroupMap = flightTools.getOrigDestGroupMap(
                    pkgAvailRS.searchResults.airResults[0].pricedItineraries
                      .pricedItineraries,
                    _this.airportCityMap
                  );
                }
              });
            } else {
              _this.pricedItineraries = null;
              _this.origDestGroupMap = null;
              _this.airportCityMap = null;
            }
            if (
              pkgAvailRS.searchResults &&
              pkgAvailRS.searchResults.airResults &&
              pkgAvailRS.searchResults.airResults.length > 0 &&
              pkgAvailRS.searchResults.airResults[0].pricedItineraries &&
              pkgAvailRS.searchResults.airResults[0].pricedItineraries.offer &&
              pkgAvailRS.searchResults.airResults[0].pricedItineraries.offer
                .summary
            ) {
              _this.offerViewItemMap = flightTools.getOfferViewItemMap(
                pkgAvailRS.searchResults.airResults[0].pricedItineraries.offer
                  .summary
              );
            } else {
              _this.offerViewItemMap = null;
            }

            // region
          } else {
            _this.hotelViewItemMap = null;
            _this.flightViewItemsGroup = null;
            _this.offerViewItemMap = null;
            _this.matchFlightViewItemsGroup = null;
          }
        });
      } else {
        return dynamicPackageApi.avail(availRQ).then((res) => {
          let pkgAvailRS = res.data;

          _this.flightViewItemsGroup =
            dynamicPackageTools.getFlightViewItemsGroup(
              pkgAvailRS.searchResults.airResults,
              _this.transmittingOptions
            );
          if (
            pkgAvailRS &&
            pkgAvailRS.searchResults &&
            pkgAvailRS.searchResults.airResults &&
            pkgAvailRS.searchResults.hotelResults
          ) {
            _this.hotelViewItemMap = dynamicPackageTools.getHotelViewItemMap(
              pkgAvailRS.searchResults.hotelResults
            );
          } else {
            _this.hotelViewItemMap = null;
            _this.flightViewItemsGroup = null;
            _this.offerViewItemMap = null;
            _this.matchFlightViewItemsGroup = null;
          }
        });
      }
    },
    async verifyPriceDynamicPkg(pkgPassengerFormModel) {
      let verifyPriceRQ = dynamicPackageTools.getVerifyPriceRQ(
        pkgPassengerFormModel
      );

      let _this = this;
      return dynamicPackageApi.verifyPrice(verifyPriceRQ).then((res) => {
        if (res.data.success) {
          const verifyPriceRS = res.data;
          const components = verifyPriceRS.dynamicPackage.components;
          const hotelComponent = components.hotelComponents[0];
          const airComponent = components.airComponents[0];
          let holdRoomInfo =
            dynamicPackageTools.getReservationRoomInfo(hotelComponent);

          let pricedFlightViewItemsGroup = flightTools.getFlightViewItemsGroup(
            [airComponent],
            true
          );
          let offerViewItemMap = null;
          if (airComponent.offer && airComponent.offer.summary) {
            offerViewItemMap = flightTools.getOfferViewItemMap(
              airComponent.offer.summary
            );
          }

          let flightPassengersFormModel = flightTools.getFlightPassengersForm(
            airComponent.airItineraryPricingInfo.PTC_FareBreakdowns
          );
          let passengerTicketFareBreakdownItems =
            flightTools.getPassengerTicketFareBreakdownItems(
              airComponent.airItineraryPricingInfo.PTC_FareBreakdowns
            );
          let roomResGuestFormModel =
            dynamicPackageTools.getRoomResGuestFormModel(hotelComponent);

          return {
            pricedFlightViewItemsGroup,
            passengerTicketFareBreakdownItems,
            offerViewItemMap,
            holdRoomInfo,
            roomResGuestFormModel,
            flightPassengersFormModel,
            airItinerary: airComponent.airItinerary,
          };
        } else {
          let shortText = res.data.errors.errors[0].shortText;
          router.push({ name: "error-500", query: { message: shortText } });
          return {};
        }
      });
    },
    async prebookDynamicPkg(pkgPassengerFormModel) {
      let prebookRQ = dynamicPackageTools.getPrebookRQ(pkgPassengerFormModel);
      let _this = this;
      return dynamicPackageApi.prebook(prebookRQ).then((res) => {
        const prebookRS = res.data;
        _this.prebookRS = prebookRS;
        let err = "";

        if (prebookRS.success) {
          let hotelReservation =
            prebookRS.dynamicPackage.components.hotelComponents[0];
          let airReservation =
            prebookRS.dynamicPackage.components.airComponents[0];

          let bookRQ = dynamicPackageTools.getBookRQ(
            prebookRQ,
            hotelReservation,
            prebookRS.dynamicPackage.globalInfo.dynamicPkgIDs
          );
          _this.bookRQ = bookRQ;
          return {
            prebookRS,
            bookRQ,
          };
        } else {
          // router.push({ name: "error-500", query: { message: err } });
          return {};
        }
      });
    },
    async changeMatchFlightViewItemsGroup(groupNumber, preEquateKey) {
      this.matchFlightViewItemsGroup = flightTools.getMatchFlightViewItemsGroup(
        this.flightViewItemsGroup,
        groupNumber,
        preEquateKey
      );
    },
    async updateAirportCityMap(airportCityMap) {
      this.airportCityMap = airportCityMap;
    },
    async updateOrigDestGroupMap(origDestGroupMap) {
      this.origDestGroupMap = origDestGroupMap;
    },
    async updateOfferViewItemMap(offerViewItemMap) {
      this.offerViewItemMap = offerViewItemMap;
    },
    async updatePricedItineraries(pricedItineraries) {
      this.pricedItineraries = pricedItineraries;
    },
    async updateFlightViewItemsGroup(flightViewItemsGroup) {
      this.flightViewItemsGroup = flightViewItemsGroup;
    },
    async updateMatchFlightViewItemsGroup(matchFlightViewItemsGroup) {
      this.matchFlightViewItemsGroup = matchFlightViewItemsGroup;
    },
  },
});

<script setup>
import { useRoute, useRouter } from "vue-router";
import { nextTick, ref } from "vue";
import { Image } from "ant-design-vue";

const props = defineProps({
  hotelImages: {},
});

const AImage = Image;
const route = useRoute();
const router = useRouter();

//图片滚动相关
const slideIndex = ref(0);
const scroll_div = ref(null);

function currentSlide(index) {
  slideIndex.value = index;
}

function move(n, hotelImages) {
  if (hotelImages && hotelImages.length > 0) {
    if (hotelImages.length <= slideIndex.value + n) {
      slideIndex.value = 0;
      slideBelow(slideIndex);
    } else if (slideIndex.value + n < 0) {
      slideIndex.value = hotelImages.length - 1;
      slideBelow(slideIndex);
    } else {
      slideIndex.value += n;
      slideBelow(slideIndex);
    }
  }
}

// 小图轮播
function slideBelow(slideIndex) {
  nextTick(() => {
    scroll_div.value.scrollLeft = 170 * slideIndex.value;
  });
}

//endregion
</script>

<template>
  <div class="image__gallery" v-if="hotelImages && hotelImages.length > 0">
    <div class="gallery">
      <div class="gallery__slideshow">
        <div class="gallery__slides">
          <div class="gallery__slide" style="width: 930px; height: 450px">
            <img
              v-if="hotelImages[slideIndex]"
              class="gallery__img"
              alt=""
              :src="hotelImages[slideIndex].url"
              style="
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin-left: 120px;
              "
            />
          </div>
        </div>
        <a @click.prevent="move(1, hotelImages)" class="gallery__prev"
          >&#10095;
        </a>
        <a @click.prevent="move(-1, hotelImages)" class="gallery__next"
          >&#10094;</a
        >
      </div>

      <div class="gallery__content">
        <div
          class="gallery__items"
          v-if="hotelImages && hotelImages.length > 0"
          ref="scroll_div"
        >
          <div
            class="gallery__item"
            :class="{
              'gallery__item--is-acitve': slideIndex === imageIndex,
            }"
            v-for="(hotelImage, imageIndex) in hotelImages"
            :key="imageIndex"
            style="width: 170px; height: 80px"
          >
            <img
              :src="hotelImage.url"
              @click="currentSlide(imageIndex)"
              class="gallery__item-img"
              style="width: 100%; height: 100%; object-fit: cover"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

import { defineStore } from "pinia";
import cmsApi from "@/apis/cmsApi";

export const useCmsContentStore = defineStore("cms", {
  state: () => {
    return {
      websiteInfo: null,
      aboutContent: null,
      productPageBackgroundImage: null,
      contactContent: null,
      advanceQuestions: null,
      regularQuestions: null,
      generalQuestions: null,
      flightHeaderContent: null,
      flightIdealExperiences: null,
      footerContent: null,
      headerContent: null,
      hotelHeaderContent: null,
      hotelIdealExperiences: null,
      ourPartners: null,
      privacyPolicy: null,
      frontIdealExperiences: null, //首页的旅行体验推荐
      flightPromotionPrimaryDeals: null,
      hotelPromotionPrimaryDeals: null,
      promotionManagement: null,
      themeColors: null,
      contentManagement: null,
      packageHeaderContent: null,
      carRentalHeaderContent: null,
      activityHeaderContent: null,
      packageIdealExperiences: null,
      carRentalIdealExperiences: null,
      activityIdealExperiences: null,
      packagePromotionHotDeals: null,
      carRentalPromotionHotDeals: null,
      activityPromotionHotDeals: null,
      slotContents: null,
      slotContent: null,
    };
  },
  actions: {
    getWebsiteInfoContent() {
      let _this = this;
      return cmsApi
        .getWebsiteInfo()
        .then((res) => {
          _this.websiteInfo = res.data.data;
          return _this.websiteInfo;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getAboutContent() {
      let _this = this;
      cmsApi
        .getAbout()
        .then((res) => {
          _this.aboutContent = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getProductBannerBackground() {
      let _this = this;
      // console.info("search");
      return cmsApi
        .getBannerBackgroundImage()
        .then((res) => {
          // (res.data);
          _this.productPageBackgroundImage = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getContactContent() {
      let _this = this;
      cmsApi
        .getContact()
        .then((res) => {
          _this.contactContent = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getAdvanceQuestions() {
      let _this = this;
      cmsApi
        .getAdvanceQuestions()
        .then((res) => {
          _this.advanceQuestions = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getGeneralQuestions() {
      let _this = this;
      cmsApi
        .getGeneralQuestions()
        .then((res) => {
          _this.generalQuestions = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getRegularQuestions() {
      let _this = this;
      cmsApi
        .getRegularQuestions()
        .then((res) => {
          _this.regularQuestions = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFlightHeaderContent() {
      let _this = this;
      cmsApi
        .getFlightHeaderContent()
        .then((res) => {
          if (res && res.data) {
            if (res && res.data) {
              _this.flightHeaderContent = res.data.data;
            } else {
              // _this.flightHeaderContent = flightPageHeaderContent;
            }
          } else {
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFlightIdealExperiences() {
      let _this = this;
      cmsApi
        .getFlightPromotionHotDeals()
        .then((res) => {
          if (res && res.data) {
            _this.flightIdealExperiences = res.data.data;
          } else {
            // _this.flightIdealExperiences = frontPagePromotionFlights;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFooterContent() {
      let _this = this;
      cmsApi
        .getFooterContent()
        .then((res) => {
          if (res && res.data) {
            _this.footerContent = res.data.data;
          } else {
            // _this.footerContent = footerContent;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getHeaderContent() {
      let _this = this;
      cmsApi
        .searchHeaderContent()
        .then((res) => {
          if (res && res.data) {
            _this.headerContent = res.data.data;
          } else {
            // _this.headerContent = headerContent;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getHotelHeaderContent() {
      let _this = this;
      cmsApi
        .getHotelHeaderContent()
        .then((res) => {
          _this.hotelHeaderContent = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getHotelIdealExperiences() {
      let _this = this;
      cmsApi
        .getHotelPromotionHotDeals()
        .then((res) => {
          _this.hotelIdealExperiences = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getOurPartner() {
      let _this = this;
      cmsApi
        .getPartners()
        .then((res) => {
          if (res && res.data) {
            _this.ourPartners = res.data.data;
          } else {
            // _this.ourPartners = frontPageOurPartners;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPrivacyPolicy() {
      let _this = this;
      cmsApi
        .getPrivacyPolicyApi()
        .then((res) => {
          _this.privacyPolicy = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFrontIdealExperiences() {
      let _this = this;
      cmsApi
        .getFrontIdealExperiences()
        .then((res) => {
          if (res && res.data) {
            _this.frontIdealExperiences = res.data.data;
          } else {
            // _this.frontIdealExperiences = frontPagePromotionDeals;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFlightPromotionPrimaryDeals() {
      let _this = this;
      cmsApi
        .getPromotionFlights()
        .then((res) => {
          if (res && res.data) {
            _this.flightPromotionPrimaryDeals = res.data.data;
          } else {
            // _this.flightPromotionPrimaryDeals = frontPagePromotionFlights;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getHotelPromotionPrimaryDeals() {
      let _this = this;
      cmsApi
        .getPromotionHotels()
        .then((res) => {
          if (res && res.data) {
            _this.hotelPromotionPrimaryDeals = res.data.data;
          } else {
            // _this.hotelPromotionPrimaryDeals = frontPagePromotionHotels;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPromotionManagements() {
      let _this = this;
      cmsApi
        .getPromotionManagement()
        .then((res) => {
          if (res && res.data) {
            _this.promotionManagement = res.data.data;
          } else {
            // _this.promotionManagement = promotionManagement;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getThemeColorManagement() {
      let _this = this;
      return cmsApi
        .getThemeColorManagement()
        .then((res) => {
          if (res && res.data) {
            _this.themeColors = res.data.data;
            return res.data.data;
          } else {
            // _this.themeColors = themeColorManagement;
            // return themeColorManagement;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getContentManagements() {
      let _this = this;
      cmsApi
        .getContentManagement()
        .then((res) => {
          if (res && res.data) {
            _this.contentManagement = res.data.data;
          } else {
            // _this.contentManagement = contentManagement;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPackageHeaderContent() {
      let _this = this;
      cmsApi
        .getPackageHeaderContent()
        .then((res) => {
          _this.packageHeaderContent = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCarRentalHeaderContent() {
      let _this = this;
      cmsApi
        .getCarRentalHeaderContent()
        .then((res) => {
          _this.carRentalHeaderContent = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getActivityHeaderContent() {
      let _this = this;
      cmsApi
        .getActivityHeaderContent()
        .then((res) => {
          _this.activityHeaderContent = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPackageIdealExperiences() {
      let _this = this;
      cmsApi
        .getPackageIdealExperiences()
        .then((res) => {
          if (res && res.data) {
            _this.packageIdealExperiences = res.data.data;
          } else {
            // _this.packageIdealExperiences = frontPagePromotionDeals;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCarRentalIdealExperiences() {
      let _this = this;
      cmsApi
        .getCarRentalIdealExperiences()
        .then((res) => {
          if (res && res.data) {
            _this.carRentalIdealExperiences = res.data.data;
          } else {
            // _this.carRentalIdealExperiences = frontPagePromotionDeals;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getActivityIdealExperiences() {
      let _this = this;
      cmsApi
        .getActivityIdealExperiences()
        .then((res) => {
          if (res && res.data) {
            _this.activityIdealExperiences = res.data.data;
          } else {
            // _this.activityIdealExperiences = frontPagePromotionDeals;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPackagePromotionHotDeals() {
      let _this = this;
      cmsApi
        .getPackagePromotionHotDeals()
        .then((res) => {
          if (res && res.data) {
            _this.packagePromotionHotDeals = res.data.data;
          } else {
            // _this.packagePromotionHotDeals = frontPagePromotionFlights;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCarRentalPromotionHotDeals() {
      let _this = this;
      cmsApi
        .getCarRentalPromotionHotDeals()
        .then((res) => {
          if (res && res.data) {
            _this.carRentalPromotionHotDeals = res.data.data;
          } else {
            // _this.carRentalPromotionHotDeals = frontPagePromotionFlights;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getActivityPromotionHotDeals() {
      let _this = this;
      cmsApi
        .getActivityPromotionHotDeals()
        .then((res) => {
          if (res && res.data) {
            _this.activityPromotionHotDeals = res.data.data;
          } else {
            // _this.activityPromotionHotDeals = frontPagePromotionFlights;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getSlotContents() {
      let _this = this;
      cmsApi
        .getSlotContents()
        .then((res) => {
          if (res && res.data) {
            _this.slotContents = res.data.data;
          } else {
            _this.slotContents = [];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    findSlotContent(contentId) {
      let _this = this;
      return cmsApi
        .findSlotContent(contentId)
        .then((res) => {
          if (res && res.data) {
            _this.slotContent = res.data.data;
            return _this.slotContent;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
});

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useTemplateStore } from "@/stores/template";
import { useCarRentalRatesStore } from "@/stores/v2/carrental/rates/carRentalRatesStore";
import { useCarRentalReserveStore } from "@/stores/v2/carrental/reserve/carRentalReserveStore";
import { createVNode, ref, watch } from "vue";
import { CarRentalDriverFormModel } from "@/formmodel/sales/carrental/checkout/carRentalDriverFormModel";
import { CarRentalRatesQuoteFormModel } from "@/formmodel/sales/carrental/rates/carRentalRatesQuoteFormModel";
import commonUtils from "@/utils/commonUtils";
import useVuelidate from "@vuelidate/core";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { CarRentalBookFormModel } from "@/formmodel/sales/carrental/checkout/carRentalBookFormModel";
import { Modal } from "ant-design-vue";
import CarRentalDetailsPanel from "@/views/v2/sales/carrental/items/panel/CarRentalDetailsPanel.vue";
import CarRentalDriverDetailsForm from "@/views/v2/sales/carrental/items/form/checkout/CarRentalDriverDetailsForm.vue";
import CarRentalPriceSummaryPanel from "@/views/v2/sales/carrental/items/panel/CarRentalPriceSummaryPanel.vue";
import { useCmsContentStore } from "@/stores/cms";

const AModal = Modal;
const route = useRoute();
const router = useRouter();

const { t, locale } = useI18n(); // 解构调用函数
const templateStore = useTemplateStore();
const carRentalRatesStore = useCarRentalRatesStore();
const carRentalReserveStore = useCarRentalReserveStore();

const carRentalRatesQuoteFormModelRef = ref(); //用来组合验价
const carRentalItemRef = ref(null);
const carRentalDriverFormModelRef = ref(
  new CarRentalDriverFormModel("MR", null, null, null, null, null, null)
);

//region query获取
if (route.query != null && Object.keys(route.query).length > 0) {
  const query = route.query;
  carRentalRatesQuoteFormModelRef.value = buildRatesQuoteFormModel(query);
  ratesQuote(carRentalRatesQuoteFormModelRef.value);
}
//endregion

watch(
  () => carRentalRatesStore.carRentalItem,
  (newValue) => {
    carRentalItemRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function buildRatesQuoteFormModel(query) {
  const quoteID = query.quoteID;
  const protectionPlan = parseProtectionPlan(query.protectionPlanFormat);
  const selectedInsurances = parseSimpleArray(query.selectedInsurancesFormat);
  const selectedEquipments = parseSimpleArray(query.selectedEquipmentsFormat);

  return new CarRentalRatesQuoteFormModel(
    quoteID,
    protectionPlan,
    selectedInsurances,
    selectedEquipments,
    null,
    null,
    null
  );
}

function parseProtectionPlan(protectionPlanFormat) {
  const protectionPlan = {};

  const splits = protectionPlanFormat.split("::");

  splits.forEach((item) => {
    const key = item.split("==")[0];
    if (key !== "insuranceItemsFormat") {
      protectionPlan[key] = item.split("==")[1];
    } else {
      protectionPlan.insuranceItems = parseSimpleArray(item.split("==")[1]);
    }
  });

  return protectionPlan;
}

function parseSimpleArray(format) {
  if (!format || format === "") return null;
  const parseArray = [];
  for (const keyValues of format.split("||")) {
    if (!keyValues || keyValues === "") continue;

    let isStringArr = false;
    if (keyValues.indexOf("=") < 0) isStringArr = true;

    if (isStringArr) {
      parseArray.push(keyValues);
    } else {
      const keyValueSplit = keyValues.split("@"); //
      const parseObj = {};
      for (const keyValue of keyValueSplit) {
        if (!keyValue || keyValue === "") continue;
        const split = keyValue.split("=");
        const key = split[0];
        if (!key || key === "") continue;
        parseObj[key] = split[1] === null || split[1] === "" ? null : split[1];
      }
      parseArray.push(parseObj);
    }
  }

  return parseArray;
}

function ratesQuote(carRentalRatesQuoteFormModel) {
  templateStore.pageLoader({ mode: "on" });
  carRentalRatesStore
    .carRentalRatesQuote(carRentalRatesQuoteFormModel)
    .then((res) => {
      if (res && !res.success) {
        let error = "";
        if (res.errors && res.errors.errors && res.errors.errors.length > 0) {
          error = res.errors.errors[0].stringValue
            ? res.errors.errors[0].stringValue
            : res.errors.errors[0].shortText;
        }
        commonUtils.openToastBox("ERROR", t("error"), error);
      }
      templateStore.pageLoader({ mode: "off" });
    });
}

const v = useVuelidate();

async function confirmBooking(
  carRentalRatesQuoteFormModel,
  carRentalItem,
  carRentalDriverFormModel
) {
  const result = await v.value.$validate();
  if (!result) return;
  AModal.confirm({
    content: t("you-will-confirm-the-reservation-now"),
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    width: 600,
    wrapClassName: "confirmModal",
    onOk() {
      return new Promise((resolve, reject) => {
        const carRentalBookFormModel = new CarRentalBookFormModel(
          carRentalItem.quoteID,
          carRentalRatesQuoteFormModel.protectionPlan,
          carRentalRatesQuoteFormModel.selectedInsurances,
          carRentalRatesQuoteFormModel.selectedEquipments,
          carRentalDriverFormModel
        );
        templateStore.pageLoader({ mode: "on" });
        carRentalReserveStore
          .carRentalInitiate(carRentalBookFormModel)
          .then((res) => {
            if (res) {
              if (res.success) {
                const bookingInfo = getBookingInfo(
                  res.vehResRSCore.vehReservation.vehSegmentCore.confIDs
                );
                sessionStorage.setItem(
                  "bookRQ",
                  JSON.stringify(carRentalReserveStore.carRentalBookRQ)
                );

                router.push({
                  name: "v2-payment-methods",
                  query: {
                    bookingId: bookingInfo.tripId,
                    invoiceId: bookingInfo.invoiceId,
                    productType: "VEHICLE",
                  },
                });
              } else {
                const error =
                  res && res.errors && res.errors.errors[0].shortText
                    ? res.errors.errors[0].shortText
                    : res.errors.errors[0].stringValue;
                commonUtils.openToastBox("ERROR", t("oops"), error);
              }
            }

            AModal.destroyAll();
            templateStore.pageLoader({ mode: "off" });
          });
      }).catch((err) => {
        console.log(err);
        AModal.destroyAll();
        templateStore.pageLoader({ mode: "off" });
      });
    },
    cancelText: "Cancel",
    onCancel() {
      AModal.destroyAll();
    },
  });
}

function getBookingInfo(bookingReferenceIdList) {
  let recordLocator = null;
  let idContext = null;
  let airItemId = null;
  let bookingItemId = null;
  let invoiceId = null;
  let tripId = null;
  if (bookingReferenceIdList && bookingReferenceIdList.length > 0) {
    bookingReferenceIdList.forEach((item) => {
      if (item.type === "RECORD_LOCATOR") {
        recordLocator = item.id;
        idContext = item.id_Context;
      } else if (item.type === "BOOKING_AIR_ITEM_ID") {
        airItemId = item.id;
      } else if (item.type === "BOOKING_ITEM_ID") {
        bookingItemId = item.id;
      } else if (item.type === "INVOICE_ID") {
        invoiceId = item.id;
      } else if (item.type === "BOOKING_ID") {
        tripId = item.id;
      }
    });
  }

  return {
    recordLocator,
    idContext,
    airItemId,
    bookingItemId,
    tripId,
    invoiceId,
  };
}

function goExtras(query) {
  //extras页面带过来的多余参数不清楚会不会有影响，先删掉
  if (query.quoteID) {
    delete query.quoteID;
  }
  if (query.protectionPlanFormat) {
    delete query.protectionPlanFormat;
  }
  if (query.selectedInsurancesFormat) {
    delete query.selectedInsurancesFormat;
  }
  if (query.selectedEquipmentsFormat) {
    delete query.selectedEquipmentsFormat;
  }

  router.push({ name: "sales-carrental-extras-v2", query: query });
}

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.CarRentalBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.CarRentalBackgroundImage
            .url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("check-out") }}</h2>
        <ul>
          <li class="pre-page" @click="goExtras(route.query)">
            Protection & Extra
          </li>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("check-out") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goExtras(route.query)">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <!--租车信息-->
          <CarRentalDetailsPanel :car-rental-item="carRentalItemRef" />
          <!--租车信息END-->

          <!--司机表单-->
          <CarRentalDriverDetailsForm
            :car-rental-driver-form-model="carRentalDriverFormModelRef"
          />
          <!--司机表单END-->

          <!--价格信息-->
          <CarRentalPriceSummaryPanel
            :is-reservation="false"
            :trip-car-rental-item="carRentalItemRef"
            @confirmNow="
              confirmBooking(
                carRentalRatesQuoteFormModelRef,
                carRentalItemRef,
                carRentalDriverFormModelRef
              )
            "
          />
          <!--价格信息END-->
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

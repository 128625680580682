import { defineStore } from "pinia";
import dynamicPkgBookTools from "@/tools/v2/dynamicPkg/book/dynamicPkgBookTools";
import dynamicPkgBookApi from "@/apis/v2/dynamicPkg/dynamicPkgBookApi";

export const usePackageBookStore = defineStore("packageBookStore", {
  state: () => ({
    bookUniqueIDs: null,
  }),
  actions: {
    book(dynamicPkgBookFormModel) {
      const bookRQ = dynamicPkgBookTools.buildDynamicPkgBookRQ(
        dynamicPkgBookFormModel
      );
      const thisRef = this;
      return dynamicPkgBookApi
        .dynamicPkgBook(bookRQ)
        .then((res) => {
          const bookRS = res.data;
          if (
            bookRS &&
            bookRS.dynamicPackage &&
            bookRS.dynamicPackage.components
          ) {
            thisRef.bookUniqueIDs = dynamicPkgBookTools.getUniqueIDs(
              bookRS.dynamicPackage.components
            );
          } else {
            thisRef.bookUniqueIDs = null;
          }
          return bookRS;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});

<script setup>
import { reactive, ref } from "vue";
import dateUtils from "@/utils/dateUtils";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import contentApi from "@/apis/contentApi";
import BasePackagePassengersForm from "@/views/sales/package/items/form/BasePkgSearchPassengersForm.vue";

const props = defineProps({
  packageSearchFormModel: {},
  isMenuShow: {},
  isOriginLocationShow: {},
  isDestinationLocationShow: {},
  equipmentType: {},
});

let packageSearchFormModel = reactive(props.packageSearchFormModel);

let state = reactive({
  suggestFlightPlaces: [],
  day: dateUtils.format(new Date()),
  departureDateTimeWeek: dateUtils.getWeek(
    packageSearchFormModel.departureDate
  ),
  departureDateTimeReturnWeek: dateUtils.getWeek(
    packageSearchFormModel.returnDate
  ),
});

/***
 * 根据改变的日期重新计算星期
 * @param checkInDate
 * @param checkOutDate
 */
function CheckDepartureDate(checkInDate, checkOutDate) {
  let res = dateUtils.compareIfBefore(checkInDate, checkOutDate);
  if (res) {
    state.departureDateTimeWeek = dateUtils.getWeek(checkInDate);
    state.departureDateTimeReturnWeek = dateUtils.getWeek(checkOutDate);
  } else {
    packageSearchFormModel.returnDate = dateUtils.getOffsetDate(
      new Date(checkInDate),
      1
    );
    state.departureDateTimeWeek = dateUtils.getWeek(checkInDate);
    state.departureDateTimeReturnWeek = dateUtils.getWeek(
      packageSearchFormModel.returnDate
    );
  }
}

function CheckReturnDate(checkInDate, checkOutDate) {
  let res = dateUtils.compareIfBefore(checkInDate, checkOutDate);
  if (res) {
    state.departureDateTimeWeek = dateUtils.getWeek(checkInDate);
    state.departureDateTimeReturnWeek = dateUtils.getWeek(checkOutDate);
  } else {
    let date = dateUtils.getOffsetDate(new Date(checkOutDate), -1);
    if (date <= state.day) {
      //时间不可以在今天之前
      packageSearchFormModel.departureDate = dateUtils.getOffsetDate(
        state.day,
        0
      );
      packageSearchFormModel.returnDate = dateUtils.getOffsetDate(state.day, 1);
      state.departureDateTimeWeek = dateUtils.getWeek(
        packageSearchFormModel.departureDate
      );
      state.departureDateTimeReturnWeek = dateUtils.getWeek(
        packageSearchFormModel.returnDate
      );
    } else {
      packageSearchFormModel.departureDate = dateUtils.getOffsetDate(
        new Date(checkOutDate),
        -1
      );
      state.departureDateTimeWeek = dateUtils.getWeek(
        packageSearchFormModel.departureDate
      );
      state.departureDateTimeReturnWeek = dateUtils.getWeek(checkOutDate);
    }
  }
}

// Validation rules
const rules = {
  departureDate: {
    required,
  },
  originLocationCode: {
    required,
  },
  destinationLocationCode: {
    required,
  },
  returnDate: {
    required,
  },
};
const emits = defineEmits(["submitFormModel"]);

let passengersAndCabinInfo = {
  roomPassengers: [],
  cabinClassSelected: {
    code: packageSearchFormModel.cabinClass,
  },
};
for (let i = 0; i < props.packageSearchFormModel.roomNum; i++) {
  let childAges = [];
  if (
    props.packageSearchFormModel.childAges != null &&
    props.packageSearchFormModel.childAges.length > 0
  ) {
    childAges = JSON.parse(
      JSON.stringify(props.packageSearchFormModel.childAges)
    );
  }
  passengersAndCabinInfo.roomPassengers.push({
    adultNum: parseInt(props.packageSearchFormModel.adultNum),
    childNum:
      props.packageSearchFormModel.childNum == null
        ? 0
        : parseInt(props.packageSearchFormModel.childNum),
    childAges: childAges,
  });
}

let pkgPassengerFormModel = reactive(passengersAndCabinInfo);

const v = useVuelidate(rules, packageSearchFormModel);
const fromId = "sales-flight-from-destination" + props.flightIndex;
const toId = "sales-flight-to-destination" + props.flightIndex;

function getSuggestFlightPlaces(keyword, type) {
  if (type == "from") {
    packageSearchFormModel.originLocationCode = null;
    document.getElementById(fromId).classList.add("show");
  } else {
    document.getElementById(toId).classList.add("show");

    packageSearchFormModel.destinationLocationCode = null;
  }
  contentApi.airportCities(keyword, "en").then((res) => {
    res.data.map((item, index) => {
      if (item.name.indexOf("All Airports") !== -1) {
        res.data.unshift(res.data.splice(index, 1)[0]);
      }
    });
    state.suggestFlightPlaces = res.data;
  });
}

//地址自动补全选择
function selectFlightPlace(suggestPlace, menuId, type) {
  if (type == "from") {
    packageSearchFormModel.originAirportName = suggestPlace.airport;
    packageSearchFormModel.originLocationCode = suggestPlace.code;
    packageSearchFormModel.originLocationName =
      suggestPlace.city + "(" + suggestPlace.countryCode + ")";
    document.getElementById(fromId).classList.remove("show");
  } else {
    packageSearchFormModel.destinationAirportName = suggestPlace.airport;
    packageSearchFormModel.destinationLocationCode = suggestPlace.code;
    packageSearchFormModel.destinationLocationName =
      suggestPlace.city + "(" + suggestPlace.countryCode + ")";
    document.getElementById(toId).classList.remove("show");
  }
}

function changeOriginDestination() {
  let originLocationNameCopy = packageSearchFormModel.originLocationName;
  packageSearchFormModel.originLocationName =
    packageSearchFormModel.destinationLocationName;
  packageSearchFormModel.destinationLocationName = originLocationNameCopy;
}

function searchPackages() {
  let roomNum = pkgPassengerFormModel.roomPassengers.length;
  let adultNum = 0;
  let childAges = [];
  pkgPassengerFormModel.roomPassengers.forEach((roomItem, roomIndex) => {
    adultNum += roomItem.adultNum;
    if (roomItem.childAges && roomItem.childAges.length > 0) {
      roomItem.childAges.forEach((childAge) => {
        let child = {
          roomId: roomIndex,
          age: childAge.age,
        };
        childAges.push(child);
      });
    }
  });
  packageSearchFormModel.adultNum = adultNum / roomNum;
  packageSearchFormModel.cabinClass =
    pkgPassengerFormModel.cabinClassSelected.code;
  packageSearchFormModel.childAges = childAges;
  packageSearchFormModel.childNum = childAges.length / roomNum;
  packageSearchFormModel.roomNum = roomNum;
  emits("submitFormModel", packageSearchFormModel);
}
</script>

<template>
  <div v-if="equipmentType == 'iphone'" class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="search_boxed">
            <p>{{ $t("from") }}</p>
            <input
              id="sales-flight-from-destination"
              v-model="packageSearchFormModel.originLocationName"
              :class="{
                'is-invalid': v.originLocationCode.$errors.length > 0,
              }"
              aria-expanded="false"
              aria-haspopup="true"
              autocomplete="off"
              data-bs-toggle="dropdown"
              name="sales-flight-from-destination"
              placeholder="Select departure flight"
              type="text"
              @input="
                (event) => getSuggestFlightPlaces(event.target.value, 'from')
              "
            />
            <span
              style="display: inline-block; height: 23px; width: 300px"
              v-if="
                !isOriginLocationShow ||
                packageSearchFormModel.originAirportName
              "
              >{{ packageSearchFormModel.originAirportName }}</span
            >
            <span
              style="
                display: inline-block;
                height: 23px;
                width: 300px;
                color: red;
              "
              v-else
              >{{ $t("please-enter-your-departure-point") }}</span
            >
            <div class="plan_icon_posation">
              <i class="fas fa-plane-departure"></i>
            </div>

            <!--        下拉框-->
            <div
              :id="fromId"
              aria-labelledby="sales-hotel-destination"
              class="dropdown-menu fs-sm full-width hotel_search_dropdown"
              style="width: 100%"
            >
              <div v-if="state.suggestFlightPlaces.length > 0">
                <a
                  v-for="(suggest, suggestIndex) in state.suggestFlightPlaces"
                  :key="suggestIndex"
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="
                    selectFlightPlace(
                      suggest,
                      'destination-from-dropdown-menu',
                      'from'
                    )
                  "
                >
                  <div class="row">
                    <div class="col-11">
                      <i class="fa fa-location-dot"></i>
                      {{ suggest.city }}({{ suggest.countryCode }}) -
                      {{ suggest.code }}
                      <p class="text-muted me-0 margin-0">
                        {{ suggest.airport }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div v-else>
                <div class="dropdown-item">
                  <i class="fa fa-magnifying-glass me-3"></i>
                  {{ $t("search-by-departure") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="search_boxed">
            <p>{{ $t("to") }}</p>
            <input
              id="sales-flight-to-destination"
              v-model="packageSearchFormModel.destinationLocationName"
              :class="{
                'is-invalid': v.destinationLocationCode.$errors.length > 0,
              }"
              aria-expanded="false"
              aria-haspopup="true"
              autocomplete="off"
              data-bs-toggle="dropdown"
              name="sales-flight-to-destination"
              placeholder="Select a return ticket"
              type="text"
              @input="
                (event) => getSuggestFlightPlaces(event.target.value, 'to')
              "
            />
            <span
              style="display: inline-block; height: 23px; width: 300px"
              v-if="
                !isDestinationLocationShow ||
                packageSearchFormModel.destinationAirportName
              "
              >{{ packageSearchFormModel.destinationAirportName }}</span
            >
            <span
              style="
                display: inline-block;
                height: 23px;
                width: 300px;
                color: red;
              "
              v-else
              >{{ $t("please-enter-your-arrival-point") }}</span
            >
            <div class="plan_icon_posation">
              <i class="fas fa-plane-arrival"></i>
            </div>
            <!--交换地点-->
            <div class="range_plan" @click="changeOriginDestination">
              <i class="fas fa-exchange-alt"></i>
            </div>
            <div
              :id="toId"
              aria-labelledby="sales-hotel-destination"
              class="dropdown-menu fs-sm full-width hotel_search_dropdown"
              style="width: 100%"
            >
              <div v-if="state.suggestFlightPlaces.length > 0">
                <a
                  v-for="(suggest, suggestIndex) in state.suggestFlightPlaces"
                  :key="suggestIndex"
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="
                    selectFlightPlace(
                      suggest,
                      'destination-to-dropdown-menu',
                      'to'
                    )
                  "
                >
                  <div class="row">
                    <div class="col-11">
                      <i class="fa fa-location-dot"></i>
                      {{ suggest.city }}({{ suggest.countryCode }}) -
                      {{ suggest.code }}
                      <p class="text-muted me-0 margin-0">
                        {{ suggest.airport }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div v-else>
                <div class="dropdown-item">
                  <i class="fa fa-magnifying-glass me-3"></i>
                  {{ $t("search-by-destination") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="form_search_date">
            <div class="search_boxed date_flex_area">
              <div class="Journey_date" style="width: 145px">
                <p>{{ $t("departure-date") }}</p>
                <input
                  v-model="packageSearchFormModel.departureDate"
                  :class="{
                    'is-invalid': v.departureDate.$errors.length > 0,
                  }"
                  :min="state.day"
                  type="date"
                  @change="
                    CheckDepartureDate(
                      packageSearchFormModel.departureDate,
                      packageSearchFormModel.returnDate
                    )
                  "
                />
                <span
                  style="display: inline-block; height: 23px; width: 300px"
                  >{{ state.departureDateTimeWeek }}</span
                >
              </div>
              <div class="Journey_date" style="width: 145px">
                <p>{{ $t("return-date") }}</p>
                <input
                  v-model="packageSearchFormModel.returnDate"
                  :min="state.day"
                  type="date"
                  @change="
                    CheckReturnDate(
                      packageSearchFormModel.departureDate,
                      packageSearchFormModel.returnDate
                    )
                  "
                />
                <span
                  style="display: inline-block; height: 23px; width: 300px"
                  >{{ state.departureDateTimeReturnWeek }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <BasePackagePassengersForm
            :is-menu-show="isMenuShow"
            :room-passengers="pkgPassengerFormModel.roomPassengers"
            :cabin-class="pkgPassengerFormModel.cabinClassSelected"
          />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <button class="btn btn_theme" @click="searchPackages">
            {{ $t("search") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="row">
    <div class="col-10">
      <div class="row">
        <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="search_boxed">
            <p>{{ $t("from") }}</p>
            <input
              id="sales-flight-from-destination"
              v-model="packageSearchFormModel.originLocationName"
              :class="{
                'is-invalid': v.originLocationCode.$errors.length > 0,
              }"
              aria-expanded="false"
              aria-haspopup="true"
              autocomplete="off"
              data-bs-toggle="dropdown"
              name="sales-flight-from-destination"
              placeholder="Select departure flight"
              type="text"
              @input="
                (event) => getSuggestFlightPlaces(event.target.value, 'from')
              "
            />
            <span
              style="display: inline-block; height: 23px; width: 300px"
              v-if="
                !isOriginLocationShow ||
                packageSearchFormModel.originAirportName
              "
              >{{ packageSearchFormModel.originAirportName }}</span
            >
            <span
              style="
                display: inline-block;
                height: 23px;
                width: 300px;
                color: red;
              "
              v-else
              >{{ $t("please-enter-your-departure-point") }}</span
            >
            <div class="plan_icon_posation">
              <i class="fas fa-plane-departure"></i>
            </div>

            <!--        下拉框-->
            <div
              :id="fromId"
              aria-labelledby="sales-hotel-destination"
              class="dropdown-menu fs-sm full-width hotel_search_dropdown"
              style="width: 100%"
            >
              <div v-if="state.suggestFlightPlaces.length > 0">
                <a
                  v-for="(suggest, suggestIndex) in state.suggestFlightPlaces"
                  :key="suggestIndex"
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="
                    selectFlightPlace(
                      suggest,
                      'destination-from-dropdown-menu',
                      'from'
                    )
                  "
                >
                  <div class="row">
                    <div class="col-11">
                      <i class="fa fa-location-dot"></i>
                      {{ suggest.city }}({{ suggest.countryCode }}) -
                      {{ suggest.code }}
                      <p class="text-muted me-0 margin-0">
                        {{ suggest.airport }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div v-else>
                <div class="dropdown-item">
                  <i class="fa fa-magnifying-glass me-3"></i>
                  {{ $t("search-by-departure") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 pad_search_passenger"
        >
          <div class="search_boxed">
            <p>{{ $t("to") }}</p>
            <input
              id="sales-flight-to-destination"
              v-model="packageSearchFormModel.destinationLocationName"
              :class="{
                'is-invalid': v.destinationLocationCode.$errors.length > 0,
              }"
              aria-expanded="false"
              aria-haspopup="true"
              autocomplete="off"
              data-bs-toggle="dropdown"
              name="sales-flight-to-destination"
              placeholder="Select a return ticket"
              type="text"
              @input="
                (event) => getSuggestFlightPlaces(event.target.value, 'to')
              "
            />
            <span
              style="display: inline-block; height: 23px; width: 300px"
              v-if="
                !isDestinationLocationShow ||
                packageSearchFormModel.destinationAirportName
              "
              >{{ packageSearchFormModel.destinationAirportName }}</span
            >
            <span
              style="
                display: inline-block;
                height: 23px;
                width: 300px;
                color: red;
              "
              v-else
              >{{ $t("please-enter-your-arrival-point") }}</span
            >
            <div class="plan_icon_posation">
              <i class="fas fa-plane-arrival"></i>
            </div>
            <!--交换地点-->
            <div class="range_plan" @click="changeOriginDestination">
              <i class="fas fa-exchange-alt"></i>
            </div>
            <div
              :id="toId"
              aria-labelledby="sales-hotel-destination"
              class="dropdown-menu fs-sm full-width hotel_search_dropdown"
              style="width: 100%"
            >
              <div v-if="state.suggestFlightPlaces.length > 0">
                <a
                  v-for="(suggest, suggestIndex) in state.suggestFlightPlaces"
                  :key="suggestIndex"
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="
                    selectFlightPlace(
                      suggest,
                      'destination-to-dropdown-menu',
                      'to'
                    )
                  "
                >
                  <div class="row">
                    <div class="col-11">
                      <i class="fa fa-location-dot"></i>
                      {{ suggest.city }}({{ suggest.countryCode }}) -
                      {{ suggest.code }}
                      <p class="text-muted me-0 margin-0">
                        {{ suggest.airport }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div v-else>
                <div class="dropdown-item">
                  <i class="fa fa-magnifying-glass me-3"></i>
                  {{ $t("search-by-destination") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 pad_search_passenger"
        >
          <div class="form_search_date">
            <div class="search_boxed date_flex_area row">
              <div class="Journey_date col-6">
                <p>{{ $t("departure-date") }}</p>
                <input
                  v-model="packageSearchFormModel.departureDate"
                  :class="{
                    'is-invalid': v.departureDate.$errors.length > 0,
                  }"
                  :min="state.day"
                  type="date"
                  @change="
                    CheckDepartureDate(
                      packageSearchFormModel.departureDate,
                      packageSearchFormModel.returnDate
                    )
                  "
                />
                <span
                  style="display: inline-block; height: 23px; width: 300px"
                  >{{ state.departureDateTimeWeek }}</span
                >
              </div>
              <div class="Journey_date col-6">
                <p>{{ $t("return-date") }}</p>
                <input
                  v-model="packageSearchFormModel.returnDate"
                  :min="state.day"
                  type="date"
                  @change="
                    CheckReturnDate(
                      packageSearchFormModel.departureDate,
                      packageSearchFormModel.returnDate
                    )
                  "
                />
                <span
                  style="display: inline-block; height: 23px; width: 300px"
                  >{{ state.departureDateTimeReturnWeek }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-xxl-2 col-xl-2 col-lg-5 col-md-12 col-sm-12 col-12 pad_search_passenger"
    >
      <BasePackagePassengersForm
        :is-menu-show="isMenuShow"
        :room-passengers="pkgPassengerFormModel.roomPassengers"
        :cabin-class="pkgPassengerFormModel.cabinClassSelected"
      />
    </div>
    <div class="top_form_search_button">
      <button class="btn btn_theme" @click="searchPackages">
        {{ $t("search") }}
      </button>
    </div>
  </div>
</template>

<style scoped></style>

import ContactUsView from "@/views/contact/ContactUsView.vue";

export default [
    {
        path: "/contact-us",
        name: "contact-us",
        component: ContactUsView,
        meta: {
            footerShow: true,
        },
    }
]
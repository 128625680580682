export class DynamicPkgVerifyPriceFormModel {
  /**
   *
   * @param languageCode
   * @param originLocationCode
   * @param destinationLocationCode
   * @param checkInDate
   * @param checkOutDate
   * @param roomResGuests
   * @param referenceId
   * @param airItineraryRPH
   * @param travelerRefNumbers
   */
  constructor(
    languageCode,
    referenceId,
    airItineraryRPH,
    originLocationCode,
    destinationLocationCode,
    checkInDate,
    checkOutDate,
    roomResGuests,
    travelerRefNumbers
  ) {
    this.languageCode = languageCode;
    this.referenceId = referenceId;
    this.airItineraryRPH = airItineraryRPH;
    this.originLocationCode = originLocationCode;
    this.destinationLocationCode = destinationLocationCode;
    this.checkInDate = checkInDate;
    this.checkOutDate = checkOutDate;
    this.roomResGuests = roomResGuests;
    this.travelerRefNumbers = travelerRefNumbers;
  }

  /*
   *  新增一个房间-住客信息的映射
   * */
  addNewRoomResGuest(referenceId, roomTypeName, bedTypeName) {
    const roomResGuest = new HotelResRoomResGuestFormItem(
      referenceId,
      roomTypeName,
      bedTypeName
    );
    if (!this.roomResGuests) this.roomResGuests = [];
    this.roomResGuests.push(roomResGuest);
    return roomResGuest;
  }
}

export class HotelResRoomResGuestFormItem {
  /**
   *
   * @param referenceId string，房间房型唯一标识
   * @param roomTypeName
   * @param bedTypeName
   * @param adultNum
   * @param childNum
   * @param resGuests Array，HotelResGuestFormItem对象数组
   * @param remarks string，房间特殊要求
   */
  constructor(
    referenceId,
    roomTypeName,
    bedTypeName,
    adultNum,
    childNum,
    resGuests,
    remarks
  ) {
    this.referenceId = referenceId;
    this.resGuests = resGuests;
    this.roomTypeName = roomTypeName;
    this.bedTypeName = bedTypeName;
    this.adultNum = adultNum;
    this.childNum = childNum;
    this.remarks = remarks;
  }

  /*
   *
   * @param primaryIndicator boolean 判断是否为联系人,若为true，则为联系人
   * */
  addNewResGuest(primaryIndicator, rph, guestType, age) {
    let guestInfo = new HotelResGuestFormItem(
      primaryIndicator,
      age,
      guestType,
      rph,
      null,
      null,
      "MR",
      null,
      null,
      null,
      null
    );
    if (!this.resGuests) this.resGuests = [];
    this.resGuests.push(guestInfo);
    return guestInfo;
  }
}

export class HotelResGuestFormItem {
  /**
   *
   * @param primaryIndicator boolean，联系人标识
   * @param age
   * @param ageQualifyingCode
   * @param resGuestRPH
   * @param givenName
   * @param surname
   * @param nameTitle
   * @param emailAddress
   * @param countryAccessCode
   * @param areaCityCode
   * @param phoneNumber
   * @param mainIndicator boolean，房间的主要人标识
   */
  constructor(
    primaryIndicator,
    age,
    ageQualifyingCode,
    resGuestRPH,
    givenName,
    surname,
    nameTitle,
    emailAddress,
    countryAccessCode,
    areaCityCode,
    phoneNumber,
    mainIndicator
  ) {
    this.primaryIndicator = primaryIndicator;
    this.age = age;
    this.ageQualifyingCode = ageQualifyingCode;
    this.nameTitle = nameTitle;
    this.givenName = givenName;
    this.surname = surname;
    this.resGuestRPH = resGuestRPH;
    this.countryAccessCode = countryAccessCode;
    this.areaCityCode = areaCityCode;
    this.phoneNumber = phoneNumber;
    this.emailAddress = emailAddress;
    this.mainIndicator = mainIndicator;
  }
}

import { defineStore } from "pinia";
import flightFilterTools from "@/tools/v2/flight/shopping/flightFilterTools";
import dynamicPkgFlightShoppingTools from "@/tools/v2/dynamicPkg/availability/dynamicPkgFlightShoppingTools";
import dynamicPkgAvailApi from "@/apis/v2/dynamicPkg/dynamicPkgAvailApi";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import contentApi from "@/apis/contentApi";

export const usePackageFlightShoppingStore = defineStore(
  "packageFlightShoppingStore",
  {
    state: () => ({
      dynamicPkgAvailFormModel: null,
      itineraryArrangement: null,
      itineraryFareOptionKeyMap: null,
      flightItems: null,
      airportCityMap: null,
      flightKeyGroups: null,
      flightItemsShow: null,
      flightFareOptionsMap: null,
      flightFareOptionsShow: null,
      priceClasses: null,
      filterOptions: null,
      flightSegmentMap: null,
      pricedItinerariesRS: null,
    }),
    actions: {
      airLowFareSearch(dynamicPkgAvailFormModel) {
        const thisRef = this;

        thisRef.dynamicPkgAvailFormModel = dynamicPkgAvailFormModel;
        const flightShoppingRQ =
          dynamicPkgFlightShoppingTools.buildDynamicPkgAirSearchRQ(
            dynamicPkgAvailFormModel
          );

        return dynamicPkgAvailApi
          .dynamicPkgAvail(flightShoppingRQ)
          .then((res) => {
            let pricedItinerariesRS = res.data;
            thisRef.pricedItinerariesRS = pricedItinerariesRS;
            const searchResults = pricedItinerariesRS.searchResults;

            if (searchResults) {
              const pricedItinerariesRS =
                searchResults.airResults[0].pricedItineraries;

              thisRef.itineraryArrangement =
                dynamicPkgFlightShoppingTools.buildItineraryArrangement(
                  dynamicPkgAvailFormModel
                );
              //Basic Info
              thisRef.flightItems =
                dynamicPkgFlightShoppingTools.buildFlightItems(
                  pricedItinerariesRS
                );
              thisRef.flightSegmentMap = pricedItinerariesRS.flightSegmentMap;
              thisRef.airItineraryMap = pricedItinerariesRS.airItineraryMap;

              thisRef.flightKeyGroups =
                dynamicPkgFlightShoppingTools.buildFlightKeyGroups(
                  pricedItinerariesRS.airItineraryMap
                );

              thisRef.itineraryFareOptionKeyMap =
                dynamicPkgFlightShoppingTools.buildItineraryFareOptionKeyMap(
                  pricedItinerariesRS
                );

              thisRef.flightFareOptionsMap =
                dynamicPkgFlightShoppingTools.buildFlightFareOptionsMap(
                  pricedItinerariesRS
                );
              thisRef.priceClasses = flightCommonTools.buildPriceClasses(
                pricedItinerariesRS.offer
              );

              //Default trip flight items show
              thisRef.flightItemsShow =
                dynamicPkgFlightShoppingTools.findSpecificFlightItems(
                  thisRef.flightItems,
                  0
                );

              //Filter
              thisRef.filterOptions = flightFilterTools.buildFilterOptions(
                thisRef.flightItemsShow
              );

              thisRef.airportCityMap = {};
              const airportCodes =
                dynamicPkgFlightShoppingTools.findAllAirportCodes(
                  thisRef.flightItems
                );
              // const allNecessaryCodes = dynamicPkgFlightShoppingTools.findAllNecessaryCodes(
              //   thisRef.flightItems
              // );
              let promises = [];
              for (const airportCode of airportCodes) {
                let promise = contentApi.airportCity(airportCode);
                promises.push(promise);
              }

              return Promise.all(promises).then((res) => {
                if (res && res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    let data = res[i].data;
                    thisRef.airportCityMap[data.code] = data;
                  }
                }
              });
            } else {
              thisRef.emptyState();
            }
          })
          .catch((err) => {
            console.error(err);
            thisRef.emptyState();
          });
      },
      changeFlightItemsShow(
        tripSequenceNumber,
        combineKeys,
        fareOptionsSelected,
        amount,
        filterType
      ) {
        const thisRef = this;
        if (!thisRef.flightItems || !thisRef.flightKeyGroups) {
          //Default trip flight items show
          thisRef.flightItemsShow = null;

          //Filter and Sorting
          thisRef.filterOptions = null;
        } else {
          thisRef.flightItemsShow =
            dynamicPkgFlightShoppingTools.findSpecificFlightItems(
              thisRef.flightItems,
              tripSequenceNumber,
              thisRef.flightKeyGroups,
              combineKeys
            );

          if (fareOptionsSelected && fareOptionsSelected.length > 0) {
            thisRef.flightItemsShow = thisRef.flightItemsShow.filter(
              (flightItem) => {
                const itineraryFareOptionKey =
                  dynamicPkgFlightShoppingTools.buildItineraryFareOptionKey(
                    fareOptionsSelected
                  );
                const itineraryPriceClassKeySplit =
                  itineraryFareOptionKey.split("!!");
                const itineraryKey = itineraryFareOptionKey.split(":!")[0];
                const priceClassIdsKey = itineraryPriceClassKeySplit[1];
                const flightFareOptions = thisRef.flightFareOptionsMap[
                  flightItem.originDestinationKey
                ].filter((flightFareOption) => {
                  return this.fareOptionsIndicator(
                    flightFareOption,
                    filterType,
                    amount,
                    itineraryKey,
                    priceClassIdsKey
                  );
                });

                if (!flightFareOptions || flightFareOptions.length === 0)
                  return false;

                flightFareOptions.sort((a, b) => a.totalPrice - b.totalPrice);

                flightItem.bestTotalPrice = flightFareOptions[0].totalPrice;
                return true;
              }
            );
          }

          //Filter and Sorting
          thisRef.filterOptions = flightFilterTools.buildFilterOptions(
            thisRef.flightItemsShow
          );
        }
      },
      fareOptionsIndicator(
        flightFareOption,
        filterType,
        amount,
        itineraryKey,
        priceClassIdsKey
      ) {
        let match = false;

        const totalPrice = flightFareOption.totalPrice;

        if (filterType === "UP" && amount && totalPrice < amount) {
          return false;
        } else if (filterType === "DOWN" && amount && totalPrice > amount) {
          return false;
        }
        const prePriceClassIdsKey = flightFareOption.prePriceClassIdsKey;
        if (
          priceClassIdsKey &&
          prePriceClassIdsKey !== priceClassIdsKey &&
          !(
            prePriceClassIdsKey == null &&
            (priceClassIdsKey === "null" || priceClassIdsKey === "undefined")
          )
        )
          return false;
        const itineraryKeyCompare =
          itineraryKey + "#" + flightFareOption.originDestinationKey;
        const itineraryKeyCombine =
          flightFareOption.supplierCode +
          ":" +
          flightFareOption.preItineraryKey +
          "#" +
          flightFareOption.originDestinationKey;
        if (itineraryKeyCompare !== itineraryKeyCombine) {
          return false;
        } else {
          match = true;
        }
        return match;
      },
      changeFareOptionsShow(
        fareOptionsSelected,
        supplierCodes,
        originDestinationKey,
        amount,
        filterType
      ) {
        const thisRef = this;
        // if (!thisRef.flightFareOptions || thisRef.flightFareOptions.length === 0)
        //   thisRef.flightFareOptionsShow = null;
        if (
          !thisRef.flightFareOptionsMap ||
          Object.keys(thisRef.flightFareOptionsMap).length === 0
        )
          thisRef.flightFareOptionsShow = null;

        if (fareOptionsSelected && fareOptionsSelected.length > 0) {
          const itineraryFareOptionKey =
            dynamicPkgFlightShoppingTools.buildItineraryFareOptionKey(
              fareOptionsSelected
            );
          const itineraryKey = itineraryFareOptionKey.split(":!")[0];
          const itineraryPriceClassKeySplit =
            itineraryFareOptionKey.split("!!");
          const priceClassIdsKey = itineraryPriceClassKeySplit[1];

          thisRef.flightFareOptionsShow = thisRef.flightFareOptionsMap[
            originDestinationKey
          ].filter((flightFareOption) => {
            return this.fareOptionsIndicator(
              flightFareOption,
              filterType,
              amount,
              itineraryKey,
              priceClassIdsKey
            );
          });
        } else {
          thisRef.flightFareOptionsShow =
            dynamicPkgFlightShoppingTools.findSpecificFlightFareOptions(
              thisRef.flightFareOptionsMap[originDestinationKey],
              supplierCodes,
              originDestinationKey,
              amount,
              filterType
            );
        }
      },
      findItineraryRPH(itineraryFareOptionKey) {
        return this.itineraryFareOptionKeyMap[itineraryFareOptionKey];
      },
      findItineraryRPHWithPreItinKey(fareOptionSelected) {
        if (fareOptionSelected) {
          const bookingSource = fareOptionSelected.supplierCode;
          let preItineraryKey = fareOptionSelected.preItineraryKey;
          let originDestinationKey = fareOptionSelected.originDestinationKey;
          const itineratyKeyCombined = preItineraryKey
            ? bookingSource + ":" + preItineraryKey + "#" + originDestinationKey
            : bookingSource + ":" + originDestinationKey;

          let find = Object.keys(this.itineraryFareOptionKeyMap).find(
            (key) => key.indexOf(itineratyKeyCombined) !== -1
          );
          console.log(itineratyKeyCombined, find);
          return this.itineraryFareOptionKeyMap[find];
        }
      },
      emptyState() {
        const thisRef = this;
        //Basic Info
        thisRef.flightItems = null;
        thisRef.itineraryFareOptionKeyMap = null;
        thisRef.flightKeyGroups = null;
        thisRef.flightFareOptions = null;

        //Default trip flight items show
        thisRef.flightItemsShow = null;

        //Filter and Sorting
        thisRef.filterOptions = null;
      },
    },
  }
);

<script setup>
import { useTemplateStore } from "@/stores/template";
import { ref, watch } from "vue";
import FlightShoppingForm from "@/views/v2/sales/flight/items/form/shopping/FlightShoppingForm.vue";
import HotelAvailForm from "@/views/v2/sales/hotel/items/form/availability/HotelAvailForm.vue";
import ActivitySearchForm from "@/views/v2/sales/activity/items/form/ActivitySearchForm.vue";
import CarRentalAvailForm from "@/views/v2/sales/carrental/items/form/availability/CarRentalAvailForm.vue";
import DynamicPkgAvailForm from "@/views/v2/sales/dynamicpkg/items/form/avail/DynamicPkgAvailForm.vue";
import headerTabs from "@/data/headerTabs";

const templateStore = useTemplateStore();

let equipmentType = ref(templateStore.responsive.equipmentType);
const productTabsRef = ref(null);
const productTabActiveCodeRef = ref(null);

watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);

watch(
  () => templateStore.webContent,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      productTabsRef.value = [];
      newValue.forEach((code) => {
        if (headerTabs.find((item) => item.code === code)) {
          productTabsRef.value.push(
            headerTabs.find((item) => item.code === code)
          );
        }
      });
    } else {
      //没有设置的话默认所有的
      productTabsRef.value = headerTabs;
    }

    if (productTabsRef.value && productTabsRef.value.length > 0) {
      productTabActiveCodeRef.value = productTabsRef.value[0].code;
    }
  },
  { immediate: true, deep: true }
);

function chooseProduct(code) {
  productTabActiveCodeRef.value = code;
}
</script>

<template>
  <section id="theme_search_form">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <div class="theme_search_form_tabbtn">
              <ul v-if="equipmentType === 'iphone'" class="nav nav-tabs">
                <li
                  v-for="(product, productIndex) in productTabsRef"
                  :key="productIndex"
                  class="nav-item"
                >
                  <button
                    :class="{
                      active: productTabActiveCodeRef === product.code,
                    }"
                    class="nav-link flex justify-content-center align-items-center"
                    type="button"
                    @click="chooseProduct(product.code)"
                  >
                    <span v-if="product.code === 'PACKAGE'">
                      <i class="fas fa-hotel"></i>
                      +
                      <i class="fas fa-plane-departure"></i>
                    </span>
                    <i v-else :class="product.icon"></i>
                  </button>
                </li>
              </ul>

              <ul v-else class="flex">
                <li
                  v-for="(product, productIndex) in productTabsRef"
                  :key="productIndex"
                  class="mg-r-10"
                >
                  <button
                    :class="{
                      btn_theme: productTabActiveCodeRef === product.code,
                    }"
                    class="btn btn_add_bal btn_md"
                    @click="chooseProduct(product.code)"
                  >
                    <i :class="product.icon" class="mg-r-5"></i
                    >{{ $t(product.name) }}
                  </button>
                </li>
              </ul>
            </div>

            <div>
              <div
                v-if="productTabActiveCodeRef === 'HOTEL'"
                id="hotels"
                class="tab-pane"
              >
                <HotelAvailForm
                  :equipment-type="equipmentType"
                  :is-from-home="true"
                  :jumboable="true"
                  :jump-to="'sales-hotel-list-v2'"
                />
              </div>
              <div
                v-if="productTabActiveCodeRef === 'AIR'"
                id="flights"
                class="tab-pane"
              >
                <FlightShoppingForm
                  :equipment-type="equipmentType"
                  :is-from-home="true"
                  :jumboable="true"
                  :jump-to="'sales-flight-list-v2'"
                />
              </div>

              <div
                v-if="productTabActiveCodeRef === 'PACKAGE'"
                id="package-flight-hotel"
                class="tab-pane"
              >
                <DynamicPkgAvailForm
                  :equipment-type="equipmentType"
                  :jumboable="true"
                  :jump-to="'sales-dynamicPkg-hotel-list-v2'"
                />
              </div>

              <div
                v-if="productTabActiveCodeRef === 'ACTIVITY'"
                id="tour-activity"
                class="tab-pane"
              >
                <ActivitySearchForm
                  :equipment-type="equipmentType"
                  :is-from-home="true"
                  :jumboable="true"
                  :jump-to="'sales-activity-list-v2'"
                />
              </div>

              <div
                v-if="productTabActiveCodeRef === 'VEHICLE'"
                id="car-rental"
                class="tab-pane"
              >
                <CarRentalAvailForm
                  :equipment-type="equipmentType"
                  :is-from-home="true"
                  :jumboable="true"
                  :jump-to="'sales-carrental-list-v2'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

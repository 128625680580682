<template>
  <Header />
  <router-view />
  <Footer v-if="route.meta ? route.meta.footerShow : true" />
</template>

<script setup>
import Header from "@/components/custom/Header.vue";
import Footer from "@/components/custom/Footer.vue";
import { onMounted, ref, watch } from "vue";
import { useTemplateStore } from "@/stores/template";
import { storeToRefs } from "pinia";
import { useCmsContentStore } from "@/stores/cms";
import { useRoute } from "vue-router";

import themeColorManagement from "@/data/cms/themeColorManagement.json";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
let templateStore = useTemplateStore();
let cmsContentStore = useCmsContentStore();
let route = useRoute();
addLoaderPageToApp();
loadCustomThemeColor();

/*Theme Color Start*/
function loadCustomThemeColor() {
  let themeColors = cmsContentStore.themeColors;
  if (!themeColors) {
    cmsContentStore.getThemeColorManagement().then((res) => {
      if (res) {
        setThemeColor(res);
      } else {
        setThemeColor(themeColorManagement);
      }
    });
  } else {
    setThemeColor(themeColors);
  }
}

function setThemeColor(themeColors) {
  let root = document.querySelector(":root");
  if (themeColors.mainColor)
    root.style.setProperty("--main-color", themeColors.mainColor);
  if (themeColors.whiteColor)
    root.style.setProperty("--white-color", themeColors.whiteColor);
  if (themeColors.contentColor)
    root.style.setProperty("--content-color", themeColors.contentColor);
  if (themeColors.controlColor)
    root.style.setProperty("--control-color", themeColors.controlColor);
  if (themeColors.blackColor)
    root.style.setProperty("--black-color", themeColors.blackColor);
  if (themeColors.blackColorOpacity)
    root.style.setProperty(
      "--black-color-opacity",
      themeColors.blackColorOpacity
    );
  if (themeColors.paragraphColor)
    root.style.setProperty("--paragraph-color", themeColors.paragraphColor);
  if (themeColors.shadowColor)
    root.style.setProperty("--shadow-color", themeColors.shadowColor);
  if (themeColors.fontColor)
    root.style.setProperty("--font-color", themeColors.fontColor);
  if (themeColors.linkColor)
    root.style.setProperty("--link-color", themeColors.linkColor);
  if (themeColors.bgColor)
    root.style.setProperty("--bg-color", themeColors.bgColor);
}

/*Theme Color End*/

/*Page Loading Start*/
const { settings } = storeToRefs(templateStore);

function addLoaderPageToApp() {
  let pageLoader = document.createElement("div");
  pageLoader.setAttribute("id", "page_loader");
  document.getElementById("app").appendChild(pageLoader);
}

watch(
  () => templateStore.settings.pageLoader,
  () => {
    if (templateStore.settings.pageLoader) {
      openLoaderPage();
    } else if (!templateStore.settings.pageLoader) {
      closeLoaderPage();
    }
  }
);

function getLoaderTxt() {
  return t("trying-to-check-please-wait-for-a-moment");
}

function openLoaderPage() {
  window.scrollTo({
    left: 0,
    top: 0,
    behavior: "instant",
  });
  let pageLoader = document.getElementById("page_loader");
  const html =
    `<span class="spinner-border spinner-border-sm loader_text"></span>
        <span class="loader_text" >` +
    getLoaderTxt() +
    `</span>`;
  pageLoader.innerHTML = html;
  pageLoader.classList.add("page_loader");
  document.body.classList.add("page_loader_hidden");
}

function closeLoaderPage() {
  document.getElementById("page_loader").classList.remove("page_loader");
  document.getElementById("page_loader").innerHTML = null;
  document.body.classList.remove("page_loader_hidden");
}

/*Page Loading End*/

//region 设备监听
// 屏幕宽度
const windowWidth = ref(0);
// 生命周期
onMounted(() => {
  getWindowResize();
  window.addEventListener("resize", getWindowResize);
});
// 获取屏幕尺寸
const getWindowResize = function () {
  windowWidth.value = window.innerWidth;
};

//根据不同的设备，给根标签添加类名用来调整样式
watch(
  () => windowWidth.value,
  (newValue, oldValue) => {
    if (newValue >= 390 && newValue <= 760) {
      templateStore.responsive.equipmentType = "iphone";
    } else if (newValue >= 761 && newValue <= 1370) {
      templateStore.responsive.equipmentType = "ipad";
    } else if (newValue > 1371) {
      templateStore.responsive.equipmentType = "pc";
    }
  }
);
//endregion
</script>

export class FlightAncillaryServiceFormModel {
  /**
   *
   * @param airItineraryRPH string，
   * @param flightSegmentRPHs Array，string数组
   * @param id string，recordLocator标识
   * @param type string，id类型，如RECORD_LOCATOR
   * @param idContext string，id所属系统，如SQ
   */
  constructor(airItineraryRPH, flightSegmentRPHs, id, type, idContext) {
    this.airItineraryRPH = airItineraryRPH;
    this.flightSegmentRPHs = flightSegmentRPHs;
    this.id = id;
    this.type = type;
    this.idContext = idContext;
  }
}

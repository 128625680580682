import { defineStore } from "pinia";
import carRentalSortTools from "@/tools/v2/carrental/availability/carRentalSortTools";
import carRentalFilterTools from "@/tools/v2/carrental/availability/carRentalFilterTools";
import dynamicPkgAvailApi from "@/apis/v2/dynamicPkg/dynamicPkgAvailApi";
import { CarRentalItemsSortFormModel } from "@/formmodel/sales/carrental/availablility/carRentalItemsSortFormModel";
import dynamicPkgCarRentalAvailTools from "@/tools/v2/dynamicPkg/availability/dynamicPkgCarRentalAvailTools";

export const usePackageCarRentalAvailStore = defineStore(
  "packageCarRentalAvailStore",
  {
    state: () => ({
      dynamicPkgAvailFormModel: null,
      carRentalItems: null,
      carRentalItemsMatch: null,
      totalResult: 0,
      carRentalItemsShow: null,
      filterOptions: null,
      limit: 6, //每页限制条数
      currentPage: 1,
      pageSize: 0, //总页数
    }),
    actions: {
      carRentalAvail(dynamicPkgAvailFormModel) {
        let thisRef = this;
        thisRef.dynamicPkgAvailFormModel = dynamicPkgAvailFormModel;
        const availCarRentalRQ =
          dynamicPkgCarRentalAvailTools.buildDynamicPkgCarRentalSearchRQ(
            dynamicPkgAvailFormModel
          );

        // 搜索可租车辆
        return dynamicPkgAvailApi
          .dynamicPkgAvail(availCarRentalRQ)
          .then((res) => {
            const availVehiclesRS = res.data;
            if (
              availVehiclesRS &&
              availVehiclesRS.searchResults &&
              availVehiclesRS.searchResults.carResults
            ) {
              const carResult = availVehiclesRS.searchResults.carResults[0];
              thisRef.carRentalItems =
                dynamicPkgCarRentalAvailTools.buildCarRentalItems(carResult);

              if (!thisRef.carRentalItems) thisRef.carRentalItems = [];
              thisRef.pageSize = Math.ceil(
                thisRef.carRentalItems.length / thisRef.limit
              );
              thisRef.totalResult = thisRef.carRentalItems.length;
              //先排序
              if (thisRef.carRentalItems && thisRef.carRentalItems.length > 0) {
                thisRef.carRentalItems = carRentalSortTools.sortCarRentalItems(
                  thisRef.carRentalItems,
                  new CarRentalItemsSortFormModel("PRICE", "ASC")
                );
              }
              thisRef.carRentalItemsShow =
                dynamicPkgCarRentalAvailTools.findSpecificCarRentalItems(
                  thisRef.carRentalItems,
                  1,
                  thisRef.limit
                );

              thisRef.carRentalItemsMatch = thisRef.carRentalItems;
            } else {
              thisRef.carRentalItems = null;
              thisRef.carRentalItemsShow = null;
              thisRef.carRentalItemsMatch = null;
            }
          })
          .catch((err) => {
            thisRef.carRentalItems = null;
            thisRef.carRentalItemsShow = null;
            thisRef.carRentalItemsMatch = null;
            console.error(err);
          });
      },
      changeCarRentalItemsShow(
        currentPage,
        isLoadMore,
        carRentalItemsFilterFormModel,
        carRentalItemsSortFormModel
      ) {
        const thisRef = this;
        if (isLoadMore) {
          thisRef.currentPage = currentPage;
          thisRef.carRentalItemsShow =
            dynamicPkgCarRentalAvailTools.findSpecificCarRentalItems(
              thisRef.carRentalItemsMatch,
              currentPage,
              thisRef.limit
            );
        } else {
          thisRef.currentPage = 1;
          let filterCarRentalItemsMatch = [];
          if (carRentalItemsFilterFormModel) {
            filterCarRentalItemsMatch =
              carRentalFilterTools.filterCarRentalItems(
                thisRef.carRentalItems,
                carRentalItemsFilterFormModel
              );
          }

          if (carRentalItemsSortFormModel) {
            filterCarRentalItemsMatch = carRentalSortTools.sortCarRentalItems(
              filterCarRentalItemsMatch,
              carRentalItemsSortFormModel
            );
          }
          thisRef.carRentalItemsMatch = filterCarRentalItemsMatch;
          if (filterCarRentalItemsMatch) {
            thisRef.totalResult = filterCarRentalItemsMatch.length;
            thisRef.carRentalItemsShow =
              dynamicPkgCarRentalAvailTools.findSpecificCarRentalItems(
                filterCarRentalItemsMatch,
                1,
                thisRef.limit
              );
          } else {
            thisRef.totalResult = 0;
            thisRef.carRentalItemsShow = [];
          }
        }
      },
    },
  }
);

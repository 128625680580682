<script setup>
import { ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { required } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

const props = defineProps({
  resGuest: {},
  passengerFormModelItems: {},
  equipmentType: {},
});
const { t } = useI18n();

const resGuestModelRef = ref(null);
const currentPassengerRef = ref(null);

const passengerOptionsRef = ref(null);

watch(
  () => props.resGuest,
  (newValue) => {
    resGuestModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);
watch(
  () => props.passengerFormModelItems,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      passengerOptionsRef.value = newValue.filter(
        (item) => item.surname && item.givenName
      );
    }

    if (!passengerOptionsRef.value || passengerOptionsRef.value.length === 0) {
      currentPassengerRef.value = null;
      resGuestModelRef.value.resGuestRPH = null;
      resGuestModelRef.value.surname = null;
      resGuestModelRef.value.givenName = null;
    } else if (
      currentPassengerRef.value &&
      (!passengerOptionsRef.value.find(
        (item) => item.resGuestRPH === currentPassengerRef.value.resGuestRPH
      ) ||
        !passengerOptionsRef.value.find(
          (item) => item.resGuestRPH === currentPassengerRef.value.resGuestRPH
        ).surname ||
        !passengerOptionsRef.value.find(
          (item) => item.resGuestRPH === currentPassengerRef.value.resGuestRPH
        ).givenName)
    ) {
      currentPassengerRef.value = null;
      resGuestModelRef.value.resGuestRPH = null;
      resGuestModelRef.value.surname = null;
      resGuestModelRef.value.givenName = null;
    }
  },
  { deep: true, immediate: true }
);

const rulesRef = ref({
  resGuestRPH: { required },
});

const v$ = useVuelidate(rulesRef.value, resGuestModelRef);

function changePassenger(currentPassenger) {
  resGuestModelRef.value.resGuestRPH = currentPassenger.travelerRefNumberRPH;
  resGuestModelRef.value.surname = currentPassenger.surname;
  resGuestModelRef.value.givenName = currentPassenger.givenName;
}
</script>

<template>
  <form class="row g-3 align-items-center" @sumbit.prevent>
    <div class="col-3">
      <label for="passenger-form-title"
        >{{ $t("contact-name") }}
        <small class="text-lowercase"><code>*</code></small></label
      >
      <select
        class="form-select form-control"
        id="passenger-form-title"
        name="passenger-form-title"
        v-model="currentPassengerRef"
        :class="{
          'is-invalid': v$.resGuestRPH.$errors.length > 0,
        }"
        :disabled="!passengerOptionsRef || passengerOptionsRef.length === 0"
        type="text"
        @change="changePassenger(currentPassengerRef)"
      >
        <option :value="null">{{ $t("select-a-guest") }}</option>
        <option
          v-for="(passenger, passengerIndex) in passengerOptionsRef"
          :key="passengerIndex"
          :value="passenger"
        >
          {{ passenger.surname }} / {{ passenger.givenName }}
        </option>
      </select>
    </div>
  </form>
</template>

<style scoped></style>

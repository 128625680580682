<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  dynamicPkgCarRentalRatesQuoteFormModel: {},
});

const emits = defineEmits([]);

const dynamicPkgCarRentalRatesQuoteFormModelRef = ref(null);
watch(
  () => props.dynamicPkgCarRentalRatesQuoteFormModel,
  (newValue) => {
    dynamicPkgCarRentalRatesQuoteFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function removeAvailableCoverage(
  dynamicPkgCarRentalRatesQuoteFormModel,
  insurance,
  insuranceIndex
) {
  dynamicPkgCarRentalRatesQuoteFormModel.removeInsurance(insuranceIndex);
}

function removeAvailableExtra(
  dynamicPkgCarRentalRatesQuoteFormModel,
  equipment,
  equipmentIndex
) {
  dynamicPkgCarRentalRatesQuoteFormModel.removeEquipment(equipmentIndex);
}

function confirmQuoteRates(dynamicPkgCarRentalRatesQuoteFormModel) {
  emits("confirmQuoteRates", dynamicPkgCarRentalRatesQuoteFormModel);
}
</script>

<template>
  <div
    class="tour_details_boxed"
    v-if="dynamicPkgCarRentalRatesQuoteFormModelRef"
  >
    <h3 class="heading_theme">Price summary</h3>
    <div class="row">
      <div class="col-12">
        <div class="row text-muted">
          <div class="col-8">{{ $t("description-uppercase") }}</div>
          <div class="col-4 text-end">
            {{ $t("price-uppercase") }} ({{
              dynamicPkgCarRentalRatesQuoteFormModelRef.currencyCode
            }})
          </div>
        </div>
        <hr />
        <div
          class="mg-b-10"
          v-if="dynamicPkgCarRentalRatesQuoteFormModelRef.baseAmount"
        >
          <div class="row">
            <div class="col-8">Base Amount</div>
            <div class="col-4 text-end">
              {{ dynamicPkgCarRentalRatesQuoteFormModelRef.currencyCode }}
              {{
                dynamicPkgCarRentalRatesQuoteFormModelRef.baseAmount.toFixed(2)
              }}
            </div>
          </div>
        </div>

        <div
          class="mg-b-10"
          v-if="
            dynamicPkgCarRentalRatesQuoteFormModelRef.protectionPlan &&
            dynamicPkgCarRentalRatesQuoteFormModelRef.protectionPlan.totalAmount
          "
        >
          <div class="font_weight">Protection plan</div>
          <div class="row p-1">
            <div class="col-8">
              {{
                dynamicPkgCarRentalRatesQuoteFormModelRef.protectionPlan.type
              }}
            </div>
            <div class="col-4 text-end">
              {{ dynamicPkgCarRentalRatesQuoteFormModelRef.currencyCode }}
              {{
                dynamicPkgCarRentalRatesQuoteFormModelRef.protectionPlan.totalAmount.toFixed(
                  2
                )
              }}
            </div>
          </div>
        </div>

        <div
          v-if="
            dynamicPkgCarRentalRatesQuoteFormModelRef.selectedInsurances &&
            dynamicPkgCarRentalRatesQuoteFormModelRef.selectedInsurances
              .length > 0
          "
          class="mg-b-10"
        >
          <div class="font_weight">Protections</div>
          <div
            class="row p-1"
            v-for="(
              insurance, insuranceIndex
            ) in dynamicPkgCarRentalRatesQuoteFormModelRef.selectedInsurances"
            :key="insuranceIndex"
          >
            <div class="col-8">
              {{ insurance.description }}
            </div>
            <div class="col-4 text-end">
              <span
                >{{ dynamicPkgCarRentalRatesQuoteFormModelRef.currencyCode }}
                {{ insurance.amount.toFixed(2) }}</span
              >
              <i
                class="bi bi-x-square cursor-point text-warning mg-l-10"
                @click="
                  removeAvailableCoverage(
                    dynamicPkgCarRentalRatesQuoteFormModelRef,
                    insurance,
                    insuranceIndex
                  )
                "
              ></i>
            </div>
          </div>
        </div>
        <div
          v-if="
            dynamicPkgCarRentalRatesQuoteFormModelRef.selectedEquipments &&
            dynamicPkgCarRentalRatesQuoteFormModelRef.selectedEquipments
              .length > 0
          "
        >
          <div class="font_weight">Available extras</div>
          <div
            class="row p-1"
            v-for="(
              equipment, equipmentIndex
            ) in dynamicPkgCarRentalRatesQuoteFormModelRef.selectedEquipments"
            :key="equipmentIndex"
          >
            <div class="col-8">
              {{ equipment.name }} x
              {{ equipment.quantity }}
            </div>
            <div class="col-4 text-end">
              <span>
                {{ dynamicPkgCarRentalRatesQuoteFormModelRef.currencyCode }}
                {{ (equipment.amount * equipment.quantity).toFixed(2) }}</span
              >
              <i
                class="bi bi-x-square cursor-point text-warning mg-l-10"
                @click="
                  removeAvailableExtra(
                    dynamicPkgCarRentalRatesQuoteFormModelRef,
                    equipment,
                    equipmentIndex
                  )
                "
              ></i>
            </div>
          </div>
        </div>

        <div class="row font_weight mg-t-10">
          <div class="col-lg-8 col-8">
            <figure>
              <div>
                {{ $t("total-uppercase") }}
                ({{ dynamicPkgCarRentalRatesQuoteFormModelRef.currencyCode }})
              </div>
            </figure>
          </div>
          <div class="col-lg-4 col-4">
            <figure class="text-end text-danger">
              <div>
                {{ dynamicPkgCarRentalRatesQuoteFormModelRef.currencyCode }}
                {{
                  dynamicPkgCarRentalRatesQuoteFormModelRef.totalAmount
                    ? dynamicPkgCarRentalRatesQuoteFormModelRef.totalAmount.toFixed(
                        2
                      )
                    : "0.00"
                }}
              </div>
            </figure>
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-center mg-t-10 mg-b-10"
      @click="confirmQuoteRates(dynamicPkgCarRentalRatesQuoteFormModelRef)"
    >
      <button class="btn btn-outline-primary">
        {{ $t("confirm") }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

import axios from "@/utils/axiosUtils";

function getParties(partyQueryFormModel) {
  let url = "/api/v3/parties";
  return axios.get(url, {
    params: partyQueryFormModel,
  });
}

function findParty(partyId) {
  let url = "/api/v3/parties/" + partyId;
  return axios.get(url);
}

function addParty(partyRQ) {
  let url = "/api/v3/parties";
  return axios.post(url, partyRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function modifyParty(partyRQ, partyId) {
  let url = "/api/v3/parties/" + partyId;
  return axios.put(url, partyRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function findPartyInfoFull(partyId) {
  let url = "/api/v3/parties/" + partyId;
  return axios.get(url, {
    headers: {
      Prefer: "full",
    },
  });
}

function getPartyGrades() {
  let url = "/api/v3/humanres/position/emplpositionclasstypes";
  return axios.get(url);
}

function uploadParties(file, lastUpdatedUserLoginId) {
  const formData = new FormData();
  formData.append("import", file);

  return axios.post(
    "/api/v3/party/party/parties/import?lastUpdatedUserLoginId=" +
      lastUpdatedUserLoginId,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

function modifyPartyAlertMessage(alertRQ, partyId) {
  let url = "/api/v3/parties/" + partyId;
  return axios.put(url, alertRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  getParties,
  findParty,
  addParty,
  modifyParty,
  findPartyInfoFull,
  getPartyGrades,
  uploadParties,
  modifyPartyAlertMessage,
};

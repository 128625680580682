import PaymentView from "@/views/v2/sales/common/PaymentView.vue";

export default [
  {
    path: "/v2/payment",
    redirect: "/v2/payment/payment-methods",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "payment-methods",
        name: "v2-payment-methods",
        component: PaymentView,
        meta: { requiresAuth: true },
      },
    ],
  },
];

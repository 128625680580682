import { defineStore } from "pinia";
import dynamicPkgVerifyPriceTools from "@/tools/v2/dynamicPkg/price/dynamicPkgVerifyPriceTools";
import dynamicPkgPriceApi from "@/apis/v2/dynamicPkg/dynamicPkgPriceApi";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";

export const usePackageVerifyPriceStore = defineStore(
  "packageVerifyPriceStore",
  {
    state: () => ({
      tripPackageItem: null,
      passengerFormModelItems: null,
      priceClasses: null,
    }),
    actions: {
      verifyPrice(dynamicPkgVerifyPriceFormModel) {
        const verifyPriceRQ =
          dynamicPkgVerifyPriceTools.buildDynamicVerifyPriceRQ(
            dynamicPkgVerifyPriceFormModel
          );
        const thisRef = this;
        return dynamicPkgPriceApi
          .verifyPrice(verifyPriceRQ)
          .then((res) => {
            const verifyPriceRS = res.data;
            if (
              verifyPriceRS &&
              verifyPriceRS.dynamicPackage &&
              verifyPriceRS.dynamicPackage.components
            ) {
              thisRef.tripPackageItem =
                dynamicPkgVerifyPriceTools.buildTripPackageItem(
                  verifyPriceRS.dynamicPackage.components
                );
              const airComponents =
                verifyPriceRS.dynamicPackage.components.airComponents;
              if (airComponents && airComponents.length > 0) {
                const airComponent = airComponents[0];
                thisRef.priceClasses = flightCommonTools.buildPriceClasses(
                  airComponent.offer
                );

                thisRef.passengerFormModelItems =
                  dynamicPkgVerifyPriceTools.buildFlightPassengerFormItems(
                    airComponent
                  );
              }
            } else {
              thisRef.tripPackageItem = null;
              thisRef.passengerFormModelItems = null;
              thisRef.priceClasses = null;
            }
            return verifyPriceRS;
          })
          .catch((error) => {
            console.log(error);
            thisRef.tripPackageItem = null;
            thisRef.priceClasses = null;
            return error;
          });
      },
    },
  }
);

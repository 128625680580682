/*
 * 获取搜索酒店描述的请求
 * @param ttiCode string 可选，酒店代码，从地区范围搜索的结果中获取，例如，7788996889
 */
function buildHotelDescriptiveRQ(ttiCode, language) {
  if (language == null) {
    language = "en";
  }
  return {
    primaryLangID: language,
    hotelDescriptiveInfos: {
      hotelDescriptiveInfoRequestInfo: [
        {
          tticode: ttiCode,
        },
      ],
    },
  };
}

export default {
  buildHotelDescriptiveRQ,
};

<script setup>
import { ref, watch } from "vue";
import contentApi from "@/apis/contentApi";
import { useI18n } from "vue-i18n";
import { useProfileStore } from "@/stores/profile";
import CustomerProfileAdditionalTravellerItem from "@/views/profiles/items/group/CustomerProfileAdditionalTravellerItem.vue";
import UserProfileFormModel from "@/formmodel/user/profile/userProfileFormModel";
import CustomerProfileBasicInformationModifyForm from "@/views/profiles/items/form/profile/CustomerProfileBasicInformationModifyForm.vue";
import commonUtils from "@/utils/commonUtils";
import { useTemplateStore } from "@/stores/template";

const props = defineProps({
  profileInfo: {},
});

const { t, locale } = useI18n(); // 解构调用函数
const emits = defineEmits([]);

const templateStore = useTemplateStore();
const profileStore = useProfileStore();

const profileViewItemsRef = ref(null);
const additionalTravellerFormModalRef = ref(null);

watch(
  () => profileStore.profileViewItems,
  (newValue) => {
    profileViewItemsRef.value = commonUtils.sortByParams(newValue, "surname");
  },
  { immediate: true, deep: true }
);

const addTravellerShowRef = ref(false);

function addNewTraveller() {
  addTravellerShowRef.value = !addTravellerShowRef.value;
  //创建这个新的traveller的profile表单
  additionalTravellerFormModalRef.value = new UserProfileFormModel(
    null,
    null,
    "MR",
    "MALE",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );
}

function removeTraveller(travellerPartyId) {
  const additionalTravellers = props.profileInfo
    ? props.profileInfo.additionalTravellers
    : null;
  let relationShipId = null;
  if (additionalTravellers && additionalTravellers.length > 0) {
    const traveller = additionalTravellers.find(
      (traveller) => traveller.partyIdFrom === travellerPartyId
    );
    if (traveller) {
      relationShipId = traveller.partyRelationshipId;
    }
  }
  templateStore.pageLoader({ mode: "on" });
  profileStore.removeAdditionalTraveller(relationShipId).then((res) => {
    if (res && !res.success) {
      const error = res.errors.errors[0].stringValue;
      commonUtils.openToastBox("ERROR", t("this-is-an-error-message"), error);
    } else {
      commonUtils.openToastBox(
        "SUCCESS",
        t("this-is-an-success-message"),
        "Removed successfully!",
        function () {
          profileStore.loadUserProfile(props.profileInfo.partyId);
        }
      );
    }
    templateStore.pageLoader({ mode: "off" });
  });
}

//region获取所有的航空公司联盟
let frequentFlyerProgramOptionRef = ref([]);
frequentFlyerProgramOptionRef.value.push({
  programID: "",
  programName: t("all-programmes"),
});
contentApi.frequentFlyerPrograms().then((res) => {
  if (res.data && res.data.length > 0) {
    res.data.sort(function (a, b) {
      if (a.programName < b.programName) {
        return -1;
      }
      if (a.programName > b.programName) {
        return 1;
      }
      return 0;
    });
    frequentFlyerProgramOptionRef.value.push(...res.data);
  }
});

//endregion
function modifyAdditionalBasicInfo(userProfileFormModal) {
  const arrangerPartyId = props.profileInfo.partyId;
  templateStore.pageLoader({ mode: "on" });
  profileStore
    .addAdditionalTraveller(arrangerPartyId, userProfileFormModal)
    .then((res) => {
      if (res && !res.success) {
        const error = res.errors.errors[0].stringValue;
        commonUtils.openToastBox("ERROR", t("this-is-an-error-message"), error);
      } else {
        commonUtils.openToastBox(
          "SUCCESS",
          t("this-is-an-success-message"),
          "Added successfully!",
          function () {
            profileStore.loadUserProfile(arrangerPartyId);
            addNewTraveller();
          }
        );
      }
      templateStore.pageLoader({ mode: "off" });
    });
}
</script>

<template>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <form id="profile_form_area">
        <h3>Other Travellers</h3>
        <button class="btn btn_theme mg-t-10" @click="addNewTraveller">
          <i class="fa-solid fa-plus mg-r-5"></i> Add new traveller
        </button>
        <div v-if="addTravellerShowRef">
          <CustomerProfileBasicInformationModifyForm
            :user-profile-form-modal="additionalTravellerFormModalRef"
            :is-additional-traveller="true"
            @cancelAddAdditional="addNewTraveller"
            @modifyBasicInformation="modifyAdditionalBasicInfo"
          />
        </div>

        <!--    所有的Additional traveller-->
        <div class="row mg-t-20">
          <CustomerProfileAdditionalTravellerItem
            v-for="(traveller, travellerIndex) in profileViewItemsRef"
            :key="travellerIndex"
            :traveller-info="traveller"
            @removeTraveller="removeTraveller"
          />
        </div>
      </form>
    </div>
    <div class="col-1"></div>
  </div>
</template>

<style scoped></style>

<script setup>
import BaseFlightItineraryPanelItem from "@/views/sales/flight/items/panel/BaseFlightItineraryPanelItem.vue";
import { ref } from "vue";
import { useFlightStore } from "@/stores/flight";
import { useTemplateStore } from "@/stores/template";

let props = defineProps({
  pricedFlightViewItemsGroup: {},
  offerViewItemMap: {},
  airportCityMap: {},
  quoteId: {},
});

const flightStore = useFlightStore();
const templateStore = useTemplateStore();
initialFareRules(props.quoteId);
let errorMessage = ref();
function initialFareRules(quoteId) {
  templateStore.pageLoader({ mode: "on" });
  flightStore.searchFareRules(quoteId).then((res) => {
    if (res.errors) {
      errorMessage.value = res.errors.errors[0].stringValue;
    }
    templateStore.pageLoader({ mode: "off" });
  });
}
</script>

<template>
  <h6 v-if="errorMessage">
    <div class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>
  </h6>
  <div
    class="tour_booking_form_box margin-top-20"
    v-for="(
      pricedFlightViewItems,
      pricedFlightViewItemsKey,
      pricedFlightViewItemsIndex
    ) in pricedFlightViewItemsGroup"
    :key="pricedFlightViewItemsKey"
  >
    <div
      v-for="(
        pricedFlightViewItem, pricedFlightViewItemKey, pricedFlightViewItemIndex
      ) in pricedFlightViewItems"
      :key="pricedFlightViewItemIndex"
    >
      <BaseFlightItineraryPanelItem
        :offer-view-item-map="offerViewItemMap"
        :flight-view-items="pricedFlightViewItem"
        :itinerary-index="pricedFlightViewItemsIndex"
        :airport-city-map="airportCityMap"
      />
    </div>
  </div>
</template>

<style scoped></style>

import axios from "../utils/axiosUtils";

/*
 *用户资料
 */
function contact(contactRQ) {
  let url = "/api/v3/sales/contactus";
  return axios.post(url, contactRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  contact,
};

<script setup>
import { ref, watch } from "vue";
import { useFlightShoppingStore } from "@/stores/v2/flight/shopping/flightShoppingStore";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import commonUtils from "@/utils/commonUtils";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import dateUtils from "@/utils/dateUtils";

const flightShoppingStore = useFlightShoppingStore();
const props = defineProps({
  flightInfo: {},
  flightInfoIndex: {},
  previousOptionPrice: {},
  airlineItemMap: {},
  aircraftItemMap: {},
  airportCityMap: {},
});

const { t, locale } = useI18n(); // 解构调用函数
let router = useRouter();
let route = useRoute();

const emits = defineEmits();

const flightInfoRef = ref(null);
watch(
  () => props.flightInfo,
  (newValue) => {
    flightInfoRef.value = newValue;
  },
  { immediate: true, deep: true }
);

//价格千分位表示
function formatPriceShow(currentTotalPrice, previousOptionPrice) {
  let priceDiff = parseInt(currentTotalPrice - previousOptionPrice).toString();
  return priceDiff
    .split("")
    .reverse()
    .reduce((prev, next, index) => {
      return (index % 3 ? next : next + ",") + prev;
    });
}

function select(
  originDestinationKey,
  supplierCodes,
  bestTotalPrice,
  flightInfo
) {
  //flightInfo用于记录在fare options部分显示所选行程信息
  emits(
    "viewFareOptions",
    originDestinationKey,
    supplierCodes,
    bestTotalPrice,
    flightInfo
  );
}

function filterSameMarketing(flightSegments) {
  if (flightSegments && flightSegments.length > 0) {
    return commonUtils.unique(flightSegments, "marketingAirlineCode");
  }
}

const segmentsShowRef = ref(false);

function showSegments() {
  segmentsShowRef.value = !segmentsShowRef.value;
}
</script>

<template>
  <div class="flight_list_item cursor-point" v-if="flightInfoRef">
    <div
      class="row"
      @click="
        select(
          flightInfoRef.originDestinationKey,
          flightInfoRef.supplierCodes,
          flightInfoRef.bestTotalPrice,
          flightInfoRef
        )
      "
    >
      <div class="col-6">
        <div class="orgin-des">
          <div class="font_weight">
            {{
              dateUtils.formatLocalTime(flightInfoRef.departureDateTime).time
            }}
            (
            {{
              dateUtils.formatEnglishDate(flightInfoRef.departureDateTime).ddate
            }}
            {{
              dateUtils.formatEnglishDate(flightInfoRef.departureDateTime)
                .month
            }}) -
            {{ dateUtils.formatLocalTime(flightInfoRef.arrivalDateTime).time }}
            (
            {{
              dateUtils.formatEnglishDate(flightInfoRef.arrivalDateTime).ddate
            }}
            {{
              dateUtils.formatEnglishDate(flightInfoRef.arrivalDateTime).month
            }})
          </div>

          <div class="airline-info flex align-content-center">
            <img
              :src="
                commonUtils.getAirlinePicture(
                  flightInfoRef.flightSegments[0].marketingAirlineCode
                )
              "
              alt="img"
              class="airline-logo"
            />
            <div class="markting-airline">
              <span
                v-for="(segment, segmentIndex) in filterSameMarketing(
                  flightInfoRef.flightSegments
                )"
                :key="segmentIndex"
              >
                {{
                  flightCommonTools.getAirlineName(
                    locale,
                    segment.marketingAirlineCode,
                    airlineItemMap
                  )
                }}
                <span
                  v-if="
                    segmentIndex <
                    filterSameMarketing(flightInfoRef.flightSegments).length - 1
                  "
                  class="mg-l-5 mg-r-8"
                  >-</span
                ></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="price-info">
          <h6 class="mg-t-5">
            {{ dateUtils.formatHourMinute(flightInfoRef.durationInMins) }} (
            {{ flightInfoRef.numStops }}-{{ $t("stop") }})
          </h6>
          <div class="location font_weight text-muted">
            {{ flightInfoRef.departureAirportCode }} -
            {{ flightInfoRef.arrivalAirportCode }}
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="select-panel">
          <div class="main_color font_weight price">
            <span v-if="!previousOptionPrice" class="font-14 text-muted mg-r-8"
              ><i class="fa-solid fa-arrow-up"></i
            ></span>
            <span v-else class="mg-r-8">+</span>
            {{ flightInfoRef.currencyCode }}
            {{
              formatPriceShow(flightInfoRef.bestTotalPrice, previousOptionPrice)
            }}
          </div>

          <!--          <button-->
          <!--            class="btn btn-outline-primary"-->
          <!--            @click="-->
          <!--              select(-->
          <!--                flightInfoRef.originDestinationKey,-->
          <!--                flightInfoRef.supplierCodes,-->
          <!--                flightInfoRef.bestTotalPrice,-->
          <!--                flightInfoRef-->
          <!--              )-->
          <!--            "-->
          <!--          >-->
          <!--            {{ $t("select") }}-->
          <!--          </button>-->
          <div @click.stop="showSegments" class="text-center cursor-point">
            <div class="font-14">
              {{ $t("view-segments") }}<i class="fas fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="segmentsShowRef" class="p-l-3 mg-up-10 mg-b-10">
      <hr />
      <div
        v-for="(segment, segIndex) in flightInfoRef.flightSegments"
        :key="segIndex"
        class="flight_segments mg-up-20"
      >
        <div class="row">
          <div class="col-12">
            <div class="airline-details">
              <div class="img">
                <img
                  :src="
                    commonUtils.getAirlinePicture(segment.marketingAirlineCode)
                  "
                  alt="img"
                />
              </div>
              <span class="airlineName fw-500"
                >{{
                  flightCommonTools.getAirlineName(
                    locale,
                    segment.marketingAirlineCode,
                    airlineItemMap
                  )
                }}
                {{ segment.flightNumber }} &nbsp;</span
              >
              <span v-if="segment.equipmentCode" class="flightNumber">{{
                flightCommonTools.getAircraftName(
                  segment.equipmentCode,
                  aircraftItemMap
                )
              }}</span>
            </div>
          </div>
        </div>
        <div class="row p-3">
          <div class="col-9">
            <div class="row">
              <div class="col-5">
                <div>
                  <span class="font_weight">
                    {{
                      flightCommonTools.getAirportCityName(
                        locale,
                        segment.departureAirportCode,
                        airportCityMap
                      )
                    }}</span
                  >
                  <span class="text-muted mg-l-8">
                    {{
                      dateUtils.formatLocalTime(segment.departureDateTime).time
                    }}
                    ({{
                      dateUtils.formatLocalTime(segment.departureDateTime)
                        .timeZone
                    }})</span
                  >
                </div>
                <div>
                  {{
                    flightCommonTools.getAirportName(
                      locale,
                      segment.departureAirportCode,
                      airportCityMap
                    )
                  }}
                </div>
                <div class="font-14 text-muted">
                  {{
                    dateUtils.formatEnglishDate(segment.departureDateTime).ddate
                  }}
                  {{
                    dateUtils.formatEnglishDate(segment.departureDateTime).month
                  }}
                  {{
                    dateUtils.formatEnglishDate(segment.departureDateTime).year
                  }}
                </div>
              </div>
              <div class="col-2">
                <div class="flight_segment_date_info">
                  <div class="arrow_right"></div>
                  <div class="font-14">
                    {{
                      dateUtils.TimeDecrement(
                        segment.arrivalDateTime,
                        segment.departureDateTime
                      )
                    }}
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div>
                  <span class="font_weight">
                    {{
                      flightCommonTools.getAirportCityName(
                        locale,
                        segment.arrivalAirportCode,
                        airportCityMap
                      )
                    }}</span
                  >
                  <span class="text-muted mg-l-8">
                    {{
                      dateUtils.formatLocalTime(segment.arrivalDateTime).time
                    }}
                    ({{
                      dateUtils.formatLocalTime(segment.arrivalDateTime)
                        .timeZone
                    }})
                  </span>
                </div>
                <div>
                  {{
                    flightCommonTools.getAirportName(
                      locale,
                      segment.arrivalAirportCode,
                      airportCityMap
                    )
                  }}
                </div>
                <div class="font-14 text-muted">
                  {{
                    dateUtils.formatEnglishDate(segment.arrivalDateTime).ddate
                  }}
                  {{
                    dateUtils.formatEnglishDate(segment.arrivalDateTime).month
                  }}
                  {{
                    dateUtils.formatEnglishDate(segment.arrivalDateTime).year
                  }}
                </div>
              </div>
            </div>
            <div class="basis-code mg-t-10">
              <div class="flex">
                <div class="mg-r-8">{{ $t("operating-airline") }}:</div>
                <div class="text-muted text-start width-200">
                  {{
                    flightCommonTools.getAirlineName(
                      locale,
                      segment.operatingAirlineCode,
                      airlineItemMap
                    )
                  }}
                </div>
              </div>

              <!--              <div class="flex">-->
              <!--                <div class="mg-r-8">{{ $t("baggage-allowance") }}:</div>-->
              <!--                <div-->
              <!--                  class="text-muted text-start width-200"-->
              <!--                  v-if="!segment.baggageAllowance"-->
              <!--                >-->
              <!--                  {{ $t("non-baggage") }}-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <hr v-if="segIndex < flightInfoRef.flightSegments.length - 1" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>

import axios from "@/utils/axiosUtils";

function carRentalLocations(keyword, language) {
  language = language == null ? "en" : language;
  let url = "/api/v3/content/vehicle/vehicle-locations";
  return axios.get(url, {
    params: {
      language: language,
      keyword: keyword,
      type: "http",
    },
  });
}

function carRentalAvail(vehiclesRQ) {
  let url = "/api/v3/sales/shopping/vehicle/vehicles";
  return axios.post(url, vehiclesRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  carRentalLocations,
  carRentalAvail,
};

<script setup>
import { reactive, ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const props = defineProps({
  childIndex: {
    type: Number,
    description: "儿童索引",
  },
  childAge: {
    type: Object,
    description: "儿童岁数",
  },
  disabled: {
    type: Boolean,
  },
});
let childAgeRef = ref(0);

watch(
  () => props.childAge,
  (newValue) => {
    childAgeRef.value = newValue;
  },
  { immediate: true, deep: true }
);
// options for select
const childAgeOptions = reactive([
  { value: null, text: "Please select" },
  { value: "1", text: "1" },
  { value: "2", text: "2" },
  { value: "3", text: "3" },
  { value: "4", text: "4" },
  { value: "5", text: "5" },
  { value: "6", text: "6" },
  { value: "7", text: "7" },
  { value: "8", text: "8" },
  { value: "9", text: "9" },
  { value: "10", text: "10" },
  { value: "11", text: "11" },
  { value: "12", text: "12" },
]);

// Use vuelidate
const rules = {
  age: {
    required,
  },
};
const v = useVuelidate(rules, childAgeRef.value);
</script>

<template>
  <div class="col-6">
    <label class="form-label" for="'val-childAge' + childIndex"
      >{{ $t("child-index-age", { childIndex: childIndex + 1 })
      }}<span class="text-danger">*</span></label
    >
    <select
      id="'val-childAge' + childIndex"
      class="form-select"
      :class="{
        'is-invalid': v.age.$errors.length > 0,
      }"
      v-model="childAgeRef.age"
      @blur="v.age.$touch"
      :disabled="disabled"
      @change="$emit('changeAge')"
    >
      <option
        v-for="(option, index) in childAgeOptions"
        :value="option.value"
        :key="`option-${index}`"
        :class="{
          'is-invalid': v.age.$errors.length > 0,
        }"
      >
        {{ option.text }}
      </option>
    </select>

    <div
      v-if="v.age.$errors.length > 0"
      class="invalid-feedback animated fadeIn"
    >
      {{ $t("select-the-child-age") }}
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

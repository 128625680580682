import { defineStore } from "pinia";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import contentApi from "@/apis/contentApi";
import flightShoppingTools from "@/tools/v2/flight/shopping/flightShoppingTools";
import dynamicPkgAvailApi from "@/apis/v2/dynamicPkg/dynamicPkgAvailApi";
import dynamicPkgFlightUpsellTools from "@/tools/v2/dynamicPkg/availability/dynamicPkgFlightUpsellTools";
import flightFilterTools from "@/tools/v2/flight/shopping/flightFilterTools";

export const usePackageFlightUpsellStore = defineStore(
  "packageFlightUpsellStore",
  {
    state: () => ({
      dynamicPkgAvailFormModel: null, //upsell时方便显示表单搜索信息
      originalTripAirItem: null,
      itineraryArrangement: null,
      tripAirItems: null,
      flightItems: null,
      itineraryFareOptionKeyMap: null,
      filterOptions: null,
      airportCityMap: null,
      flightItemsShow: null,
      flightFareOptionsMap: null,
      flightFareOptionsShow: null,
      priceClasses: null,
      upsellFareOptionsSelected: null,
    }),
    actions: {
      /*
       * 用于列表页面Upsell
       * */
      upsellWithSegBasisCodes(formModel) {
        const upsellRQ =
          dynamicPkgFlightUpsellTools.buildDynamicPkgAirUpsellRQ(formModel);
        const thisRef = this;
        thisRef.dynamicPkgAvailFormModel = formModel;

        return dynamicPkgAvailApi
          .dynamicPkgAvail(upsellRQ)
          .then((res) => {
            let pricedItinerariesRS = res.data;
            thisRef.pricedItinerariesRS = pricedItinerariesRS;
            const searchResults = pricedItinerariesRS.searchResults;

            if (searchResults) {
              const pricedItinerariesRS =
                searchResults.airResults[0].pricedItineraries;
              //Basic Info
              thisRef.flightItems =
                dynamicPkgFlightUpsellTools.buildFlightItems(
                  pricedItinerariesRS
                );

              thisRef.itineraryFareOptionKeyMap =
                dynamicPkgFlightUpsellTools.buildItineraryFareOptionKeyMap(
                  pricedItinerariesRS
                );

              thisRef.flightFareOptionsMap =
                dynamicPkgFlightUpsellTools.buildFlightFareOptionsMap(
                  pricedItinerariesRS
                );

              thisRef.priceClasses = flightCommonTools.buildPriceClasses(
                pricedItinerariesRS.offer
              );

              //直接显示该OD的flightItem
              thisRef.flightItemsShow =
                dynamicPkgFlightUpsellTools.findSpecificFlightItems(
                  thisRef.flightItems,
                  1
                );

              //Filter
              thisRef.filterOptions = flightFilterTools.buildFilterOptions(
                thisRef.flightItemsShow
              );

              thisRef.airportCityMap = {};
              const airportCodes = flightShoppingTools.findAllAirportCodes(
                thisRef.flightItems
              );
              let promises = [];
              for (const airportCode of airportCodes) {
                let promise = contentApi.airportCity(airportCode);
                promises.push(promise);
              }

              Promise.all(promises).then((res) => {
                if (res && res.length > 0) {
                  for (let i = 0; i < res.length; i++) {
                    let data = res[i].data;
                    thisRef.airportCityMap[data.code] = data;
                  }
                }
              });
            } else {
              thisRef.emptyState();
            }

            return pricedItinerariesRS;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      saveShoppingFormModal(formModel) {
        this.dynamicPkgAvailFormModel = formModel;
        this.itineraryArrangement =
          dynamicPkgFlightUpsellTools.buildItineraryArrangement(formModel);
      },
      /*
       * 该方法用于列表页指定OD的fare options显示，不能用于checkout 页
       * */
      showUpsellOptionsShow(originDestinationKey) {
        const thisRef = this;
        thisRef.flightFareOptionsShow = null;

        if (
          !thisRef.flightFareOptionsMap ||
          Object.keys(thisRef.flightFareOptionsMap).length === 0
        )
          thisRef.flightFareOptionsShow = null;
        else
          thisRef.flightFareOptionsShow =
            thisRef.flightFareOptionsMap[originDestinationKey];
      },

      fareOptionsIndicator(
        flightFareOption,
        itineraryKey,
        priceClassIdsKey,
        amount,
        filterType
      ) {
        let match = false;

        const totalPrice = flightFareOption.totalPrice;

        if (filterType === "UP" && amount && totalPrice < amount) {
          return false;
        } else if (filterType === "DOWN" && amount && totalPrice > amount) {
          return false;
        }

        const prePriceClassIdsKey = flightFareOption.prePriceClassIdsKey;
        if (
          (prePriceClassIdsKey == null &&
            priceClassIdsKey !== "null" &&
            priceClassIdsKey !== "undefined" &&
            priceClassIdsKey !== "" &&
            priceClassIdsKey !== null) ||
          (!priceClassIdsKey && prePriceClassIdsKey)
        ) {
          //选择的非空,被选的为空 || 选择的为空，备选的非空
          return false;
        }

        if (priceClassIdsKey && prePriceClassIdsKey !== priceClassIdsKey) {
          return false;
        }

        //没有brand fare的情况处理

        const itineraryKeyCompare =
          itineraryKey + "#" + flightFareOption.originDestinationKey;
        const itineraryKeyCombine =
          flightFareOption.supplierCode +
          ":" +
          flightFareOption.preItineraryKey +
          "#" +
          flightFareOption.originDestinationKey;

        if (itineraryKeyCompare !== itineraryKeyCombine) {
          return false;
        } else {
          match = true;
        }
        return match;
      },
      findItineraryRPH(itineraryFareOptionKey) {
        return this.itineraryFareOptionKeyMap[itineraryFareOptionKey];
      },
      findItineraryRPHWithPreItinKey(fareOptionSelected) {
        if (fareOptionSelected) {
          const bookingSource = fareOptionSelected.supplierCode;
          let preItineraryKey = fareOptionSelected.preItineraryKey;
          let originDestinationKey = fareOptionSelected.originDestinationKey;
          const itineratyKeyCombined = preItineraryKey
            ? bookingSource + ":" + preItineraryKey + "#" + originDestinationKey
            : bookingSource + ":" + originDestinationKey;

          let find = Object.keys(this.itineraryFareOptionKeyMap).find(
            (key) => key.indexOf(itineratyKeyCombined) === 0
          );
          return this.itineraryFareOptionKeyMap[find];
        }
      },
      /*
       * 该方法用于最后一个OD选完Fare Option之后，通过增加价格匹配，获取指定airItineraryRPH,
       * 已验证，列表页Upsell可用
       * */
      findItineraryRPHWithFinalPrice(fareOptionSelected) {
        if (fareOptionSelected) {
          const bookingSource = fareOptionSelected.supplierCode;
          let preItineraryKey = fareOptionSelected.preItineraryKey;
          let originDestinationKey = fareOptionSelected.originDestinationKey;
          const itineratyKeyCombined = preItineraryKey
            ? bookingSource +
              ":" +
              preItineraryKey +
              "#" +
              originDestinationKey +
              ":!" +
              fareOptionSelected.currencyCode +
              fareOptionSelected.totalPrice
            : bookingSource +
              ":" +
              originDestinationKey +
              ":!" +
              fareOptionSelected.currencyCode +
              fareOptionSelected.totalPrice;
          console.info(itineratyKeyCombined);

          let find = Object.keys(this.itineraryFareOptionKeyMap).find(
            (key) => key.indexOf(itineratyKeyCombined) === 0
          );
          return this.itineraryFareOptionKeyMap[find];
        }
      },
      emptyState() {
        const thisRef = this;
        //Basic Info
        thisRef.originalTripAirItem = null;
        thisRef.itineraryArrangement = null;
        thisRef.tripAirItems = null;
        thisRef.flightItems = null;
        thisRef.itineraryFareOptionKeyMap = null;
        thisRef.airportCityMap = null;
        thisRef.flightItemsShow = null;
        thisRef.flightFareOptionsMap = null;
        thisRef.flightFareOptionsShow = null;
        thisRef.priceClasses = null;
        thisRef.upsellFareOptionsSelected = null;
      },
    },
  }
);

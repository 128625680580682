<template>
  <section id="offer_area" class="section_padding_top">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="offer_area_box d-none-phone img_animation">
            <img src="../../../assets/img/offer/offer1.png" alt="img" />
            <div class="offer_area_content">
              <h2>Special Offers</h2>
              <p>
                Invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et accusam et justo duo dolores et ea
                rebum. Stet clita kasd dolor sit amet. Lorem ipsum dolor sit
                amet.
              </p>
              <a href="#!" class="btn btn_theme">Holiday deals</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-12">
          <div class="offer_area_box img_animation">
            <img src="../../../assets/img/offer/offer2.png" alt="img" />
            <div class="offer_area_content">
              <h2>News letter</h2>
              <p>
                Invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et.
              </p>
              <a href="#!" class="btn btn_theme">Subscribe now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-12">
          <div class="offer_area_box img_animation">
            <img src="../../../assets/img/offer/offer3.png" alt="img" />
            <div class="offer_area_content">
              <h2>Travel tips</h2>
              <p>
                Invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et.
              </p>
              <a href="#!" class="btn btn_theme">Get tips</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Offer",
};
</script>

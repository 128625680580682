<script setup>
import { nextTick, ref } from "vue";

const props = defineProps({
  hotelViewItemImages: {},
});
////hotel image
function imageError(source) {
  return true;
}

let slideIndex = ref(0);

function currentSlide(index) {
  slideIndex.value = index;
}

function move(n) {
  if (props.hotelViewItemImages && props.hotelViewItemImages.length > 0) {
    if (props.hotelViewItemImages.length <= slideIndex.value + n) {
      slideIndex.value = 0;
      slideBelow(slideIndex);
    } else if (slideIndex.value + n < 0) {
      slideIndex.value = props.hotelViewItemImages.length - 1;
      slideBelow(slideIndex);
    } else {
      slideIndex.value += n;
      slideBelow(slideIndex);
    }
  }
}

const scroll_div = ref(null);

// 小图轮播
function slideBelow(slideIndex) {
  nextTick(() => {
    scroll_div.value.scrollLeft = 170 * slideIndex.value;
  });
}
</script>

<template>
  <div
    class="image__gallery"
    v-if="hotelViewItemImages && hotelViewItemImages.length > 0"
  >
    <div class="gallery">
      <div class="gallery__slideshow">
        <div class="gallery__slides">
          <div class="gallery__slide" style="width: 930px; height: 450px">
            <img
              v-if="hotelViewItemImages[slideIndex]"
              class="gallery__img"
              alt=""
              :src="hotelViewItemImages[slideIndex].url"
              style="
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin-left: 120px;
              "
            />
          </div>
        </div>
        <a @click.prevent="move(1)" class="gallery__prev">&#10095; </a>
        <a @click.prevent="move(-1)" class="gallery__next">&#10094;</a>
      </div>

      <div class="gallery__content">
        <div
          class="gallery__items"
          v-if="hotelViewItemImages && hotelViewItemImages.length > 0"
          ref="scroll_div"
        >
          <div
            class="gallery__item"
            :class="{
              'gallery__item--is-acitve': slideIndex === imageIndex,
            }"
            v-for="(hotelImage, imageIndex) in hotelViewItemImages"
            :key="imageIndex"
            style="width: 170px; height: 80px"
          >
            <img
              :src="hotelImage.url"
              @error="imageError"
              @click="currentSlide(imageIndex)"
              class="gallery__item-img"
              style="width: 100%; height: 100%; object-fit: cover"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

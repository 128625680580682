import axios from "@/utils/axiosUtils";

/*
 * 酒店搜索，根据请求内容的不同，分为城市范围搜索和指定酒店搜索
 * @param hotelAvailRQ object 必填，通过@tools/hotelTools#getHotelAvailRQ方法获取
 * */
function avail(hotelAvailRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-avail";
  return axios.post(url, hotelAvailRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/*
 * 酒店描述搜索，搜索指定酒店的描述内容，包括星级，地址，文字描述，图片，设施等
 * @param hotelDescriptiveRQ object 必填，通过@tools/hotelTools#getHotelDescriptiveRQ方法获取
 * */
function descriptive(hotelDescriptiveRQ) {
  let url = "/api/v3/sales/shopping/hotel/hotel-descriptive-info";
  return axios.post(url, hotelDescriptiveRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  avail,
  descriptive,
};

<script setup>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import dateUtils from "@/utils/dateUtils";
import { required } from "@vuelidate/validators";
import contentApi from "@/apis/contentApi";
import DateUtils from "@/utils/dateUtils";

const props = defineProps({
  tripType: {
    String,
  },
  originDestinationDateItem: {
    Object,
  },
  flightIndex: {},
  isOriginLocationShow: {},
  isDestinationLocationShow: {},
});

//定义传值函数
// const emits = defineEmits(["changeDate"]);

let originDestinationDateFormItem = reactive(props.originDestinationDateItem);
let state = reactive({
  suggestFlightPlaces: [],
  day: dateUtils.format(new Date()),
  departureDateTimeWeek: "",
  departureDateTimeReturnWeek: "",
});

state.departureDateTimeWeek = dateUtils.getWeek(
  originDestinationDateFormItem.departureDateTime
);

if (props.tripType == "roundtrip") {
  if (!originDestinationDateFormItem.returnDateTime) {
    originDestinationDateFormItem.returnDateTime = dateUtils.getOffsetDate(
      state.day,
      8
    );
  }

  state.departureDateTimeReturnWeek = dateUtils.getWeek(
    originDestinationDateFormItem.returnDateTime
  );
}

/***
 * 根据改变的日期重新计算星期
 * @param checkInDate
 * @param checkOutDate
 */
function CheckDepartureDate(checkInDate, checkOutDate) {
  let res = dateUtils.compareIfBefore(checkInDate, checkOutDate);
  if (res) {
    state.departureDateTimeWeek = dateUtils.getWeek(checkInDate);
    state.departureDateTimeReturnWeek = dateUtils.getWeek(checkOutDate);
  } else {
    originDestinationDateFormItem.returnDateTime = dateUtils.getOffsetDate(
      new Date(checkInDate),
      1
    );
    state.departureDateTimeWeek = dateUtils.getWeek(checkInDate);
    state.departureDateTimeReturnWeek = dateUtils.getWeek(
      originDestinationDateFormItem.returnDateTime
    );
  }
}

function CheckReturnDate(checkInDate, checkOutDate) {
  let res = dateUtils.compareIfBefore(checkInDate, checkOutDate);
  if (res) {
    state.departureDateTimeWeek = dateUtils.getWeek(checkInDate);
    state.departureDateTimeReturnWeek = dateUtils.getWeek(checkOutDate);
  } else {
    let date = dateUtils.getOffsetDate(new Date(checkOutDate), -1);
    if (date <= state.day) {
      //时间不可以在今天之前
      originDestinationDateFormItem.departureDateTime = dateUtils.getOffsetDate(
        state.day,
        0
      );
      originDestinationDateFormItem.returnDateTime = dateUtils.getOffsetDate(
        state.day,
        1
      );
      state.departureDateTimeWeek = dateUtils.getWeek(
        originDestinationDateFormItem.departureDateTime
      );
      state.departureDateTimeReturnWeek = dateUtils.getWeek(
        originDestinationDateFormItem.returnDateTime
      );
    } else {
      originDestinationDateFormItem.departureDateTime = dateUtils.getOffsetDate(
        new Date(checkOutDate),
        -1
      );
      state.departureDateTimeWeek = dateUtils.getWeek(
        originDestinationDateFormItem.departureDateTime
      );
      state.departureDateTimeReturnWeek = dateUtils.getWeek(checkOutDate);
    }
  }
}
const emits = defineEmits();
function changeDepartureDateTime(flightIndex) {
  state.departureDateTimeWeek = dateUtils.getWeek(
    originDestinationDateFormItem.departureDateTime
  );
  emits("changeDate", flightIndex, false);
}
// Validation rules
const rules = {
  departureDateTime: {
    required,
  },
  originLocationCode: {
    required,
  },
  destinationLocationCode: {
    required,
  },
};
if (props.tripType == "roundtrip") {
  rules.returnDateTime = {
    required,
  };
}

const v = useVuelidate(rules, originDestinationDateFormItem);
const fromId = "sales-flight-from-destination" + props.flightIndex;
const toId = "sales-flight-to-destination" + props.flightIndex;
function getSuggestFlightPlaces(keyword, type) {
  if (type == "from") {
    originDestinationDateFormItem.originLocationCode = null;
    document.getElementById(fromId).classList.add("show");
  } else {
    // document
    //   .getElementById("destination-to-dropdown-menu")
    //   .classList.add("show");
    document.getElementById(toId).classList.add("show");

    originDestinationDateFormItem.destinationLocationCode = null;
  }
  contentApi.airportCities(keyword, "en").then((res) => {
    res.data.map((item, index) => {
      if (item.name.indexOf("All Airports") !== -1) {
        res.data.unshift(res.data.splice(index, 1)[0]);
      }
    });
    state.suggestFlightPlaces = res.data;
  });
}

//地址自动补全选择
function selectFlightPlace(suggestPlace, menuId, type) {
  if (type == "from") {
    originDestinationDateFormItem.originAirportName = suggestPlace.airport;
    originDestinationDateFormItem.originLocationCode = suggestPlace.code;
    originDestinationDateFormItem.originLocationName =
      suggestPlace.city + "(" + suggestPlace.countryCode + ")";
    document.getElementById(fromId).classList.remove("show");
  } else {
    originDestinationDateFormItem.destinationAirportName = suggestPlace.airport;
    originDestinationDateFormItem.destinationLocationCode = suggestPlace.code;
    originDestinationDateFormItem.destinationLocationName =
      suggestPlace.city + "(" + suggestPlace.countryCode + ")";
    document.getElementById(toId).classList.remove("show");
  }
}

function changeOriginDestination() {
  let originLocationNameCopy = originDestinationDateFormItem.originLocationName;
  originDestinationDateFormItem.originLocationName =
    originDestinationDateFormItem.destinationLocationName;
  originDestinationDateFormItem.destinationLocationName =
    originLocationNameCopy;
}
</script>

<template>
  <div class="row">
    <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
      <div class="search_boxed">
        <p>{{ $t("from") }}</p>
        <input
          id="sales-flight-from-destination"
          v-model="originDestinationDateFormItem.originLocationName"
          :class="{
            'is-invalid': v.originLocationCode.$errors.length > 0,
          }"
          aria-expanded="false"
          aria-haspopup="true"
          autocomplete="off"
          data-bs-toggle="dropdown"
          name="sales-flight-from-destination"
          placeholder="Select departure flight"
          type="text"
          @input="(event) => getSuggestFlightPlaces(event.target.value, 'from')"
        />
        <span
          style="display: inline-block; height: 23px; width: 300px"
          v-if="
            !isOriginLocationShow ||
            originDestinationDateFormItem.originAirportName
          "
          >{{ originDestinationDateFormItem.originAirportName }}</span
        >
        <span
          style="display: inline-block; height: 23px; width: 300px; color: red"
          v-else
          >{{ $t("please-enter-your-departure-point") }}</span
        >
        <div class="plan_icon_posation">
          <i class="fas fa-plane-departure"></i>
        </div>

        <!--下拉框-->
        <div
          :id="fromId"
          aria-labelledby="sales-hotel-destination"
          class="dropdown-menu fs-sm full-width hotel_search_dropdown"
          style="width: 100%"
        >
          <div v-if="state.suggestFlightPlaces.length > 0">
            <a
              v-for="(suggest, suggestIndex) in state.suggestFlightPlaces"
              :key="suggestIndex"
              class="dropdown-item"
              href="javascript:void(0)"
              @click="
                selectFlightPlace(
                  suggest,
                  'destination-from-dropdown-menu',
                  'from'
                )
              "
            >
              <div class="row">
                <div class="col-11">
                  <i class="fa fa-location-dot"></i>

                  {{ suggest.city }}({{ suggest.countryCode }}) -
                  {{ suggest.code }}
                  <p class="text-muted me-0 margin-0">
                    {{ suggest.airport }}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div v-else>
            <div class="dropdown-item">
              <i class="fa fa-magnifying-glass me-3"></i>
              {{ $t("search-by-departure") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
      <div class="search_boxed">
        <p>{{ $t("to") }}</p>
        <input
          id="sales-flight-to-destination"
          v-model="originDestinationDateFormItem.destinationLocationName"
          :class="{
            'is-invalid': v.destinationLocationCode.$errors.length > 0,
          }"
          aria-expanded="false"
          aria-haspopup="true"
          autocomplete="off"
          data-bs-toggle="dropdown"
          name="sales-flight-to-destination"
          placeholder="Select a return ticket"
          type="text"
          @input="(event) => getSuggestFlightPlaces(event.target.value, 'to')"
        />
        <span
          style="display: inline-block; height: 23px; width: 300px"
          v-if="
            !isDestinationLocationShow ||
            originDestinationDateFormItem.destinationAirportName
          "
          >{{ originDestinationDateFormItem.destinationAirportName }}</span
        >
        <span
          style="display: inline-block; height: 23px; width: 300px; color: red"
          v-else
          >{{ $t("please-enter-your-arrival-point") }}</span
        >
        <div class="plan_icon_posation">
          <i class="fas fa-plane-arrival"></i>
        </div>
        <!--交换地点-->
        <div class="range_plan" @click="changeOriginDestination">
          <i class="fas fa-exchange-alt"></i>
        </div>

        <div
          :id="toId"
          aria-labelledby="sales-hotel-destination"
          class="dropdown-menu fs-sm full-width hotel_search_dropdown"
          style="width: 100%"
        >
          <div v-if="state.suggestFlightPlaces.length > 0">
            <a
              v-for="(suggest, suggestIndex) in state.suggestFlightPlaces"
              :key="suggestIndex"
              class="dropdown-item"
              href="javascript:void(0)"
              @click="
                selectFlightPlace(suggest, 'destination-to-dropdown-menu', 'to')
              "
            >
              <div class="row">
                <div class="col-11">
                  <i class="fa fa-location-dot"></i>
                  {{ suggest.city }}({{ suggest.countryCode }}) -
                  {{ suggest.code }}
                  <p class="text-muted me-0 margin-0">
                    {{ suggest.airport }}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div v-else>
            <div class="dropdown-item">
              <i class="fa fa-magnifying-glass me-3"></i>
              {{ $t("search-by-destination") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- if roundtrip grid-->
    <div
      v-if="props.tripType == 'roundtrip'"
      class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 pad_search_passenger"
    >
      <div class="search_boxed date_flex_area">
        <div class="row">
          <div class="Journey_date col-6">
            <p>{{ $t("departure-date") }}</p>
            <input
              v-model="originDestinationDateFormItem.departureDateTime"
              :class="{
                'is-invalid': v.departureDateTime.$errors.length > 0,
              }"
              :min="state.day"
              type="date"
              @change="
                CheckDepartureDate(
                  originDestinationDateFormItem.departureDateTime,
                  originDestinationDateFormItem.returnDateTime
                )
              "
            />
            <span style="display: inline-block; height: 23px; width: 300px">{{
              state.departureDateTimeWeek
            }}</span>
          </div>
          <div class="Journey_date col-6">
            <p>{{ $t("return-date") }}</p>
            <input
              v-model="originDestinationDateFormItem.returnDateTime"
              :min="state.day"
              type="date"
              @change="
                CheckReturnDate(
                  originDestinationDateFormItem.departureDateTime,
                  originDestinationDateFormItem.returnDateTime
                )
              "
            />
            <span style="display: inline-block; height: 23px; width: 300px">{{
              state.departureDateTimeReturnWeek
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 pad_search_passenger"
    >
      <div class="form_search_date">
        <div class="search_boxed date_flex_area">
          <div class="Journey_date">
            <p>{{ $t("departure-date") }}</p>
            <input
              v-model="originDestinationDateFormItem.departureDateTime"
              :min="state.day"
              type="date"
              @change="changeDepartureDateTime(flightIndex)"
            />
            <span style="display: inline-block; height: 23px; width: 300px">{{
              state.departureDateTimeWeek
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

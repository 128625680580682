const sortOptions = [
  // {
  //   name: "Recommends(Highest)",
  //   label: "recommends-highest",
  //   type: "RECOMMENDS",
  //   order: "DSC",
  // },
  {
    name: "Price(Lowest)",
    label: "price-lowest",
    type: "PRICE",
    order: "ASC",
  },
  {
    name: "Price(Highest)",
    label: "price-highest",
    type: "PRICE",
    order: "DSC",
  },
  {
    name: "Duration(Shortest)",
    label: "duration-shortest",
    type: "DURATION",
    order: "ASC",
  },
  {
    name: "Duration(Longest)",
    label: "duration-longest",
    type: "DURATION",
    order: "DSC",
  },
  {
    name: "Departure(Earliest)",
    label: "departure-earliest",
    type: "DEPARTURE",
    order: "ASC",
  },
  {
    name: "Departure(Latest)",
    label: "departure-latest",
    type: "DEPARTURE",
    order: "DSC",
  },
  {
    name: "Arrival(Earliest)",
    label: "arrival-earliest",
    type: "ARRIVAL",
    order: "ASC",
  },
  {
    name: "Arrival(Latest)",
    label: "arrival-latest",
    type: "ARRIVAL",
    order: "DSC",
  },
];

export default {
  sortOptions,
};

export class CarRentalCoverageItemVO {
  constructor(
    included,
    selected,
    mandatory,
    code,
    description,
    amount,
    currency
  ) {
    this.included = included;
    this.selected = selected;
    this.mandatory = mandatory;
    this.code = code;
    this.description = description;
    this.amount = amount;
    this.currency = currency;
  }
}

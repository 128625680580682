<script setup>
import { computed } from "vue";

const props = defineProps({
  roomViewItems: {
    type: Array,
  },
  roomTypes: {
    type: Array,
  },
});

const containSpecialRequests = computed(() => {
  let containSpecialRequests = false;
  if (props.roomViewItems && props.roomViewItems.length > 0) {
    props.roomViewItems.forEach((roomViewItem) => {
      if (roomViewItem.remarks) {
        containSpecialRequests = true;
      }
    });
  }

  return containSpecialRequests;
});
</script>

<template>
  <div
    class="table-responsive push"
    v-if="roomViewItems && roomViewItems.length > 0 && containSpecialRequests"
  >
    <p class="h5 mb-2">{{$t("special-requests")}}</p>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th class="text-center bg-body-light">{{$t("room-name")}}</th>
          <th class="text-center bg-body-light">{{$t("special-requests")}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(roomViewItem, roomIndex) in roomViewItems" :key="roomIndex">
          <td class="text-center">
            {{ roomTypes[roomIndex] }} - {{ roomIndex + 1 }}
          </td>
          <td>
            {{ roomViewItem.remarks }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss"></style>

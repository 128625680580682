<script setup>
import BaseHotelItemPanel from "@/views/sales/hotel/items/panel/BaseHotelItemPanel.vue";
import { onMounted, reactive, ref, watch, watchEffect } from "vue";
import BasePackageSearchForm from "@/views/sales/package/items/form/BasePackageSearchForm.vue";
import Banner from "@/components/custom/Banner.vue";
import { useDynamicPackageStore } from "@/stores/dynamicPackage";
import { useTemplateStore } from "@/stores/template";
import { useRoute, useRouter } from "vue-router";
import hotelTools from "@/tools/hotelTools";
import BasePackageHotelFilterForm from "@/views/sales/package/items/form/BasePackageHotelFilterForm.vue";
import BasePackageHotelSortForm from "@/views/sales/package/items/form/BasePackageHotelSortForm.vue";
import { useContentStore } from "@/stores/content";
import { Drawer, Empty, Spin } from "ant-design-vue";
import { useCmsContentStore } from "@/stores/cms";

const AEmpty = Empty;
const ASpin = Spin;
const ADrawer = Drawer;
//region 数据初始化
//导航面包屑
const navigation = reactive({
  title: "Package Search Result",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Hotel Search",
    },
  ],
});
//vue-router特性相关
const router = useRouter();
let route = useRoute();
//Pinia相关
const dynamicPackageStore = useDynamicPackageStore();
let contentStore = useContentStore();
const templateStore = useTemplateStore();
const bannerContentStore = useCmsContentStore();
contentStore.$reset();
//自定义辅助变量
//过滤排序相关
let filterOptionRef = ref({
  starRatings: [],
  userRating: "",
  mealPlans: [],
  priceRange: [0, 10000],
});
let sortingOptionRef = ref("");
//数据渲染相关
let packageHotelViewItemsShow = ref({});
let packageHotelViewItemsShowLength = ref(0);
let bannerContent = reactive(null);
bannerContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrlRef = ref("");
let backgroundImageUrlRef = ref("");
//分页相关变量
let showMaxIndex = ref(9);
let isShowMore = ref(true);
//endregion 数据初始化

//region 页面vue监听事件
watch(
  () => dynamicPackageStore.hotelViewItemMap,
  (newValue) => {
    if (newValue) {
      packageHotelViewItemsShow.value = null;
      templateStore.pageLoader({ mode: "on" });
      setTimeout(() => {
        packageHotelViewItemsShow.value = JSON.parse(JSON.stringify(newValue));
        //統一迭代邏輯
        packageHotelViewItemsShow.value[Symbol.iterator] = function* () {
          yield* [...Object.values(this)].sort((a, b) => {
            return 0;
          });
        };
        packageHotelViewItemsShowLength.value = Object.keys(
          packageHotelViewItemsShow.value
        ).length;

        templateStore.pageLoader({ mode: "off" });
      }, 10);
    } else {
      packageHotelViewItemsShow.value = null;
    }
  }
);

watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrlRef.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.packageBackgroundImage
      ? bannerContentStore.productPageBackgroundImage.packageBackgroundImage.url
      : "";
  backgroundImageUrlRef.value = baseUrl + imageUrlRef.value;
});
//endregion 页面vue监听事件

//region 表单事件

//过滤排序方法总入口函数
function sortAndFilter(sortingOption, filterOption) {
  packageHotelViewItemsShow.value = null;
  templateStore.pageLoader({ mode: "on" });
  setTimeout(() => {
    if (sortingOption == null) {
      sortingOption = sortingOptionRef.value;
    }
    if (filterOption == null) {
      filterOption = filterOptionRef.value;
    }
    if (filterOption) {
      filter(filterOption);
    }
    if (sortingOption) {
      sorting(sortingOption);
    }

    resetShowMore();
    templateStore.pageLoader({ mode: "off" });
  }, 1000);
}

//endregion 表单事件

//region 辅助函数
//排序入口方法
function sorting(sortingOption) {
  sortingOptionRef.value = sortingOption;
  //解析排序条件
  let sortingOptionObj = null;
  if (sortingOptionRef.value != "" && sortingOptionRef.value) {
    const splits = sortingOptionRef.value.split("-");
    let type = splits[0];
    let order = splits[1];
    sortingOptionObj = new hotelTools.SortingOption(type, order);
  }
  let packageHotelViewItemsShowCopy = JSON.parse(
    JSON.stringify(packageHotelViewItemsShow.value)
  );

  if (sortingOptionObj.type == "PRICE") {
    sortingByPrice(packageHotelViewItemsShowCopy, sortingOptionObj.order);
  }

  //guest rating 排序
  if (sortingOptionObj.type == "GUEST_RATING") {
    sortingByGuestRating(packageHotelViewItemsShowCopy, sortingOptionObj.order);
  }

  //starRating 排序
  if (sortingOptionObj.type == "STAR_RATING") {
    sortingByStarRating(packageHotelViewItemsShowCopy, sortingOptionObj.order);
  }
}

function sortingByPrice(baseItems, order) {
  if (!baseItems) return;
  packageHotelViewItemsShow.value[Symbol.iterator] = function* () {
    yield* [...Object.values(this)].sort((a, b) => {
      if ("ASC" == order) {
        return a.bestPrice - b.bestPrice;
      } else {
        return b.bestPrice - a.bestPrice;
      }
    });
  };
}

function sortingByGuestRating(baseItems, order) {
  if (!baseItems) return;
  packageHotelViewItemsShow.value[Symbol.iterator] = function* () {
    // (Object.values(this));
    yield* [...Object.values(this)].sort((a, b) => {
      if ("ASC" == order) {
        return a.userRating - b.userRating;
      } else {
        return b.userRating - a.userRating;
      }
    });
  };
}

function sortingByStarRating(baseItems, order) {
  if (!baseItems) return;
  packageHotelViewItemsShow.value[Symbol.iterator] = function* () {
    // (Object.values(this));
    yield* [...Object.values(this)].sort((a, b) => {
      if ("ASC" == order) {
        return a.starRating - b.starRating;
      } else {
        return b.starRating - a.starRating;
      }
    });
  };
}

//过滤入口方法
function filter(filterOption) {
  filterOptionRef.value = filterOption;
  let packageHotelViewItemsShowTemp = null;
  if (dynamicPackageStore.hotelViewItemMap) {
    packageHotelViewItemsShowTemp = JSON.parse(
      JSON.stringify(dynamicPackageStore.hotelViewItemMap)
    );
  }

  //根据价格区间筛选
  if (filterOption.priceRange && filterOption.priceRange.length > 0) {
    for (const key in packageHotelViewItemsShowTemp) {
      if (packageHotelViewItemsShowTemp[key]) {
        if (filterOption.priceRange[1] == 10000) {
          //这个时候选择的价格是xx-10000+,只需要做最小值处理
          if (
            !(
              packageHotelViewItemsShowTemp[key].averagePrice >=
              filterOption.priceRange[0]
            )
          ) {
            delete packageHotelViewItemsShowTemp[key];
          }
        } else {
          if (
            !(
              packageHotelViewItemsShowTemp[key].averagePrice >=
                filterOption.priceRange[0] &&
              packageHotelViewItemsShowTemp[key].averagePrice <=
                filterOption.priceRange[1]
            )
          ) {
            delete packageHotelViewItemsShowTemp[key];
          }
        }
      }
    }
    packageHotelViewItemsShow.value = packageHotelViewItemsShowTemp;
    //統一迭代邏輯
    packageHotelViewItemsShow.value[Symbol.iterator] = function* () {
      yield* [...Object.values(this)].sort((a, b) => {
        return 0;
      });
    };
  }

  //根据Guest Rating筛选
  if (filterOption.userRating) {
    for (const key in packageHotelViewItemsShowTemp) {
      if (packageHotelViewItemsShowTemp[key]) {
        if (
          !(
            packageHotelViewItemsShowTemp[key].userRating >=
            filterOption.userRating
          )
        ) {
          delete packageHotelViewItemsShowTemp[key];
        }
      }
    }
    packageHotelViewItemsShow.value = packageHotelViewItemsShowTemp;
    //統一迭代邏輯
    packageHotelViewItemsShow.value[Symbol.iterator] = function* () {
      yield* [...Object.values(this)].sort((a, b) => {
        return 0;
      });
    };
  }

  //根据Star rating筛选
  if (filterOption.starRatings && filterOption.starRatings.length > 0) {
    if (filterOption.starRatings.indexOf("") > -1) {
      //統一迭代邏輯
      packageHotelViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    } else {
      let starRatingsKeyMap = {};
      //1.第一次遍历先选出key
      for (const key in packageHotelViewItemsShowTemp) {
        filterOption.starRatings.forEach((starRating) => {
          if (packageHotelViewItemsShowTemp[key].starRating == starRating) {
            //等于的key先保存一份
            starRatingsKeyMap[key] = "1";
          }
        });
      }
      //2.再次去遍历时删除不符合上面的key
      for (const key in packageHotelViewItemsShowTemp) {
        //4.如果当前的key和上面的都不符合，就delete
        if (!starRatingsKeyMap[key]) {
          delete packageHotelViewItemsShowTemp[key];
        }
      }

      packageHotelViewItemsShow.value = packageHotelViewItemsShowTemp;
      //統一迭代邏輯
      packageHotelViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    }
  }

  //根据Meal plans 筛选
  if (filterOption.mealPlans && filterOption.mealPlans.length > 0) {
    if (filterOption.mealPlans.indexOf("") > -1) {
      //統一迭代邏輯
      packageHotelViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    } else {
      //1.先将mealPlans参数转成数据需要的字段
      let mealOptionMap = formatMealPlans(filterOption.mealPlans);
      // (mealOptionMap);
      let mealTypes = {};
      for (const key in packageHotelViewItemsShowTemp) {
        let mealMatch = true;
        let roomTypes = packageHotelViewItemsShowTemp[key].roomTypes;
        if (roomTypes) {
          for (const roomTypeKey in roomTypes) {
            if (roomTypes[roomTypeKey] && roomTypes[roomTypeKey].rooms) {
              let rooms = roomTypes[roomTypeKey].rooms;
              let mealPlans = [];
              if (rooms && rooms.length > 0) {
                rooms.forEach((room) => {
                  let mealPlan = room.mealPlan;
                  Object.keys(mealOptionMap).forEach((optionKey) => {
                    if (!mealPlan[optionKey]) {
                      mealMatch = false;
                    }
                  });
                });
              }
            }
          }
          if (!mealMatch) delete packageHotelViewItemsShowTemp[key];
        }
      }

      packageHotelViewItemsShow.value = packageHotelViewItemsShowTemp;
      //統一迭代邏輯
      packageHotelViewItemsShow.value[Symbol.iterator] = function* () {
        yield* [...Object.values(this)].sort((a, b) => {
          return 0;
        });
      };
    }
  }

  packageHotelViewItemsShowLength.value = Object.keys(
    packageHotelViewItemsShow.value
  ).length;
}

function formatMealPlans(mealPlanParams) {
  let mealOptionMap = {};
  mealPlanParams.forEach((item) => {
    let meal = null;
    if (item == 19) {
      meal = "breakfast";
    } else if (item == 21) {
      meal = "lunch";
    } else if (item == 22) {
      meal = "dinner";
    }
    if (meal) mealOptionMap[meal] = true;
  });
  return mealOptionMap;
}

//格式化
function formatChildAges(childAges) {
  let formatArr = [];
  childAges.forEach((childAge) => {
    let format = childAge.roomId + ":" + childAge.age;
    formatArr.push(format.toString());
  });
  return formatArr;
}

function selectHotel(index) {
  let formModel = JSON.parse(sessionStorage.getItem("packageSearchFormModel"));
  //跳转前
  let packageSearchFormModelCopy = JSON.parse(JSON.stringify(formModel));
  //首页跳转传参
  let format = formatChildAges(packageSearchFormModelCopy.childAges);
  formModel.childAgesFormat = format;
  let query = formModel;
  const hotelItemKey = Object.keys(dynamicPackageStore.hotelViewItemMap)[index];
  query.ttiCode = dynamicPackageStore.hotelViewItemMap[hotelItemKey].ttiCode;
  JSON.stringify(
    sessionStorage.setItem(
      "packageHotelTtiCode",
      dynamicPackageStore.hotelViewItemMap[hotelItemKey].ttiCode
    )
  );
  router.push({ name: "sales-package-hotel-details", query: query });
}

//加载数据相关
function resetShowMore() {
  showMaxIndex.value = 9;
  isShowMore.value = true;
}

function loadMoreItems() {
  showMaxIndex.value += 9;
  if (
    dynamicPackageStore.hotelViewItemMap &&
    showMaxIndex.value >=
      Object.keys(dynamicPackageStore.hotelViewItemMap).length
  ) {
    isShowMore.value = false;
  }
}

initiateChildrenPlaces(route.query);

//初始化函数
function initiateChildrenPlaces(query) {
  if (query.placeId) {
    contentStore.loadChildrenPlaces(query.placeId);
  }
}

function getNeighborhoods(childrenPlaces) {
  if (childrenPlaces == null || childrenPlaces.length === 0) return null;
  let childrenPlacesCopy = [
    {
      placeId: route.query.placeId,
      placeTypeId: route.query.type,
      name: route.query.placeName,
    },
  ];
  childrenPlacesCopy.push(...childrenPlaces);
  return childrenPlacesCopy;
}

//endregion 辅助函数

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
//ant design
let filterOpen = ref(false);
const showFilterDrawer = () => {
  filterOpen.value = true;
};
const closeFilterDrawer = () => {
  filterOpen.value = false;
};

function handleScroll() {
  let distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;

  if (distance == 0) {
    setTimeout(() => {
      loadMoreItems();
    }, 500);
  }
}

onMounted(() => {
  if (equipmentType.value == "iphone") {
    //是手机端的采用滚动
    window.addEventListener("scroll", handleScroll);
  }
});
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />
  <section
    id="theme_search_form_tour"
    class="fligth_top_search_main_form_wrapper"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <div class="row">
              <div class="col-lg-12">
                <BasePackageSearchForm
                  :jumpable="false"
                  :equipment-type="equipmentType"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section_padding">
    <div class="container">
      <!-- Section Heading -->
      <div class="row" v-if="equipmentType == 'iphone'">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mg-t-10">
          <div class="filter-sort">
            <div class="filtrate_btn h5" @click="showFilterDrawer">
              {{ $t("filtrate") }}<i class="fa fa-fw fa-angle-down"></i>
            </div>
            <div class="sort_btn">
              <BasePackageHotelSortForm
                @sorting="(sortingOption) => sortAndFilter(sortingOption, null)"
              />
            </div>
          </div>
          <!--  sort部分 -->
          <!--  filter部分 -->
          <a-drawer
            title="Filter Option"
            placement="bottom"
            :open="filterOpen"
            @close="closeFilterDrawer"
          >
            <BasePackageHotelFilterForm
              :max-price="10000"
              :min-price="0"
              :neighborhoods="getNeighborhoods(contentStore.childrenPlaces)"
              @filter="(filterOption) => sortAndFilter(null, filterOption)"
            />
          </a-drawer>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2 v-if="packageHotelViewItemsShowLength">
              {{ packageHotelViewItemsShowLength }}
              hotel(s) found
            </h2>
            <h2 v-else>{{ $t("num-hotel", { length: 0 }) }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3" v-if="equipmentType != 'iphone'">
          <BasePackageHotelSortForm
            @sorting="(sortingOption) => sortAndFilter(sortingOption, null)"
          />
          <BasePackageHotelFilterForm
            :max-price="10000"
            :min-price="0"
            :neighborhoods="getNeighborhoods(contentStore.childrenPlaces)"
            @filter="(filterOption) => sortAndFilter(null, filterOption)"
          />
        </div>

        <div class="col-lg-9">
          <div class="row" v-if="packageHotelViewItemsShow">
            <div
              class="col-lg-4 col-md-6 col-sm-6 col-12"
              v-for="(
                packageHotelInfos, packageHotelIndex
              ) in packageHotelViewItemsShow"
              :key="packageHotelIndex"
            >
              <BaseHotelItemPanel
                v-if="showMaxIndex > packageHotelIndex"
                :item="packageHotelInfos"
                @click="selectHotel(packageHotelIndex)"
              />
            </div>
            <div
              class="text-center"
              v-if="
                packageHotelViewItemsShowLength > 6 &&
                isShowMore &&
                equipmentType == 'iphone'
              "
            >
              <Spin></Spin>
            </div>
            <div
              class="load_more_flight"
              v-if="
                packageHotelViewItemsShowLength > 6 &&
                isShowMore &&
                equipmentType != 'iphone'
              "
            >
              <button class="btn btn_md" @click="loadMoreItems()">
                <i class="fas fa-spinner"></i> Load more..
              </button>
            </div>
          </div>
          <div v-else>
            <AEmpty></AEmpty>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

import { FlightPriceClassDescriptionVO } from "@/viewobject/sales/flight/shopping/flightPriceClassDescriptionVO";

export class FlightPriceClassVO {
  /**
   *
   * @param priceClassId string，价格类型标识
   * @param priceClassName string，价格类型名
   * @param priceDescriptions Array，FlightPriceClassDescriptionVO对象数组
   * @param origDestRPH string，每个航班的唯一标识
   */
  constructor(priceClassId, priceClassName, priceDescriptions, origDestRPH) {
    this.priceClassId = priceClassId;
    this.priceClassName = priceClassName;
    this.priceDescriptions = priceDescriptions;
    this.origDestRPH = origDestRPH;
  }

  addNewPriceDescription(code, description) {
    const flightPriceClassDescriptionVO = new FlightPriceClassDescriptionVO(
      code,
      description
    );
    if (this.priceDescriptions == null) this.priceDescriptions = [];
    this.priceDescriptions.push(flightPriceClassDescriptionVO);
    return flightPriceClassDescriptionVO;
  }
}

<script setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import dateUtils from "@/utils/dateUtils";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import { useTemplateStore } from "@/stores/template";
import { Empty, Popover } from "ant-design-vue";
import commonUtils from "@/utils/commonUtils";
import flightShoppingTools from "@/tools/v2/flight/shopping/flightShoppingTools";
import { useFlightPriceStore } from "@/stores/v2/flight/price/flightPriceStore";
import { useFlightUpsellStore } from "@/stores/v2/flight/upsell/flightUpsellStore";

const props = defineProps({
  airlineItemMap: {},
  airItineraryRPH: {},
});

const { t, locale } = useI18n(); // 解构调用函数
const AEmpty = Empty;

const APopover = Popover;

const emits = defineEmits([]);
const templateStore = useTemplateStore();
const flightPriceStore = useFlightPriceStore();
const flightUpsellStore = useFlightUpsellStore();

//upsell
upsell();

async function upsell() {
  templateStore.pageLoader({ mode: "on" });

  let res = await flightUpsellStore.upsell(props.airItineraryRPH);
  templateStore.pageLoader({ mode: "off" });
}

const originDestinationIndexRef = ref(0);
const activeTabRef = ref(null);
const flightItemsShowRef = ref(null);
const flightFareOptionsShowRef = ref(null);
const priceClassesRef = ref(null);
const flightItemShowRef = ref(null);
const originalTripAirItemRef = ref(null);
const fareOptionsSelectedRef = ref([]);
const currentFareOptionSelectedRef = ref(null);

watch(
  () => flightUpsellStore.flightItemsShow,
  (newValue) => {
    flightItemsShowRef.value = newValue;

    if (flightItemsShowRef.value && flightItemsShowRef.value.length > 0) {
      flightItemShowRef.value = flightItemsShowRef.value[0];
      if (flightItemShowRef.value) {
        activeTabRef.value =
          flightItemShowRef.value.originDestinationKey + "-tab";
        //默认先显示当前可选的fare
        const originDestinationKey =
          flightItemShowRef.value.originDestinationKey;
        flightUpsellStore.changeFareOptionsShow(
          originDestinationKey,
          fareOptionsSelectedRef.value,
          flightItemShowRef.value.bestTotalPrice
        );
      }
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => flightUpsellStore.flightFareOptionsShow,
  (newValue) => {
    flightFareOptionsShowRef.value = newValue;
    if (newValue && newValue.length > 0) {
      //如果已经upsell过,则默认选中上一次的fare选项
      if (
        flightUpsellStore.upsellFareOptionsSelected &&
        flightUpsellStore.upsellFareOptionsSelected.length > 0 &&
        originDestinationIndexRef.value === 0
      ) {
        currentFareOptionSelectedRef.value =
          flightUpsellStore.upsellFareOptionsSelected[0];
      } else {
        const originalItem = newValue.find((fare) => fare.origFareIndicator);
        if (originalItem) {
          currentFareOptionSelectedRef.value = originalItem;
        } else {
          currentFareOptionSelectedRef.value = newValue[0];
        }
      }
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => flightUpsellStore.priceClasses,
  (newValue) => {
    priceClassesRef.value = newValue;
  },
  { deep: true, immediate: true }
);
watch(
  () => flightUpsellStore.originalTripAirItem,
  (newValue) => {
    originalTripAirItemRef.value = newValue;
  },
  { deep: true, immediate: true }
);

function getPriceClassName(priceClasses, priceClassId) {
  if (!priceClasses || priceClasses.length === 0) return "Original Selection";

  if (priceClassId) {
    if (priceClassId.indexOf("@@") >= 0) {
      let brandFareNames = [];
      //todo 是否去重
      const split = priceClassId.split("@@");
      split.forEach((id) => {
        priceClasses.forEach((e) => {
          if (
            id === e.priceClassId &&
            brandFareNames.indexOf(e.priceClassName) < 0
          ) {
            brandFareNames.push(e.priceClassName);
          }
        });
      });

      return brandFareNames.join(" + ");
    } else {
      return priceClasses.find((item) => item.priceClassId === priceClassId)
        .priceClassName;
    }
  } else {
    return "Original Selection";
  }
}

function getPriceLabel(originalTripAirItem, fareOption, previousOption) {
  let originTotalAmount = null;
  if (originalTripAirItem) {
    originTotalAmount = originalTripAirItem.tripAirItemFare.totalAmount;
  } else {
    originTotalAmount = 0;
  }
  const currencyCode = fareOption.currencyCode;
  const currentFarePrice = fareOption.totalPrice;
  const origFareIndicator = fareOption.origFareIndicator;
  let comparePrice = null;
  if (originDestinationIndexRef.value === 0) {
    comparePrice = originTotalAmount;
  } else if (originDestinationIndexRef.value > 0 && previousOption) {
    comparePrice = previousOption.totalPrice;
  }
  //origFareIndicator 以及是否为当前选择决定是否是included
  if (
    origFareIndicator &&
    currentFareOptionSelectedRef.value &&
    currentFareOptionSelectedRef.value.priceClassId === fareOption.priceClassId
  ) {
    return "included";
  } else if (comparePrice <= currentFarePrice) {
    const price = commonUtils.formatPriceThousands(
      currentFarePrice - comparePrice
    );
    return "+ " + price + " " + currencyCode;
  } else if (comparePrice > currentFarePrice) {
    const price = commonUtils.formatPriceThousands(
      comparePrice - currentFarePrice
    );
    return "- " + price + " " + currencyCode;
  }
}

function changeFlight(originDesIndex, tabId, flightInfo, notUpsell) {
  originDestinationIndexRef.value = originDesIndex;
  activeTabRef.value = tabId;
  flightItemShowRef.value = flightInfo;

  const originDestinationKey = flightItemShowRef.value.originDestinationKey;
  console.log(fareOptionsSelectedRef.value);
  //changeFare之前记录最新一次的fare选择
  if (notUpsell) {
    //只是关闭弹窗
  } else {
    flightUpsellStore.changeUpsellFareOptionsSelected(
      fareOptionsSelectedRef.value
    );
  }

  //todo 往回change逻辑
  if (originDestinationIndexRef.value === 0) {
    fareOptionsSelectedRef.value = [];
  } else {
    //将之后的选择清除
    fareOptionsSelectedRef.value.splice(
      originDestinationIndexRef.value,
      fareOptionsSelectedRef.value.length - originDestinationIndexRef.value
    );
  }

  flightUpsellStore.changeFareOptionsShow(
    originDestinationKey,
    fareOptionsSelectedRef.value,
    flightItemShowRef.value.bestTotalPrice
  );
}

function changeFareOptions(
  currentFareOptionSelected,
  fareOptionsSelected,
  tripAirItems
) {
  if (fareOptionsSelected.length === 0) {
    fareOptionsSelected.push(currentFareOptionSelected);
  } else {
    fareOptionsSelected.splice(originDestinationIndexRef.value); //清除该OD及之后的选项
    fareOptionsSelected[originDestinationIndexRef.value] =
      currentFareOptionSelected;
  }

  originDestinationIndexRef.value++;
  flightItemShowRef.value =
    flightItemsShowRef.value[originDestinationIndexRef.value];
  if (flightItemShowRef.value) {
    activeTabRef.value = flightItemShowRef.value.originDestinationKey + "-tab";
    const originDestinationKey = flightItemShowRef.value.originDestinationKey;
    flightUpsellStore.changeFareOptionsShow(
      originDestinationKey,
      fareOptionsSelected,
      flightItemShowRef.value.bestTotalPrice
    );
  } else {
    //选完了
    const itineraryFareOptionKey =
      flightShoppingTools.buildItineraryFareOptionKey(fareOptionsSelected);

    const airItineraryRPH = flightUpsellStore.findItineraryRPH(
      itineraryFareOptionKey
    );
    //将tripAirItem的信息更新

    if (tripAirItems && tripAirItems.length > 0) {
      const tripAirItemMatch = tripAirItems.find(
        (tripAirItem) => tripAirItem.airItineraryRPH === airItineraryRPH
      );

      if (tripAirItemMatch) {
        flightPriceStore.updateTripAirItem(tripAirItemMatch);
        flightUpsellStore.changeOriginalTripAirItem(tripAirItemMatch);
      } else {
        console.log("没匹配到tripAirItem");
      }
    }

    //关闭弹窗，清除选择
    originDestinationIndexRef.value = 0;
    changeFlight(
      originDestinationIndexRef.value,
      flightItemsShowRef.value[originDestinationIndexRef.value]
        .originDestinationKey + "-tab",
      flightItemsShowRef.value[originDestinationIndexRef.value]
    );
    emits("upsell", airItineraryRPH);
  }
}

function filterPriceClassDescriptions(priceClasses, fareItem) {
  const priceClassId = fareItem.priceClassId;
  if (priceClassId) {
    const segmentPriceClass = fareItem.segmentPriceClass;
    const priceClassIdSplit = priceClassId.split("@@");
    const priceClassesAll = [];
    priceClassIdSplit.forEach((id) => {
      const priceClassItem = priceClasses.find(
        (item) => item.priceClassId === id
      );
      const segmentPriceClasses = segmentPriceClass.filter(
        (item) => item.bundleID === id
      ); //找到该OD表示这种priceClassId的priceClasses

      if (segmentPriceClasses && segmentPriceClasses.length > 0) {
        segmentPriceClass.forEach((segmentPriceClassItem) => {
          const priceClassName = priceClassItem.priceClassName;
          const priceDescriptions = priceClassItem.priceDescriptions;
          priceClassesAll.push({ priceClassName, priceDescriptions });
        });
      }
    });

    return commonUtils.unique(priceClassesAll, "priceClassName");
  }
}

function sortDescriptions(priceDescriptions) {
  if (
    priceDescriptions &&
    priceDescriptions.length > 0 &&
    priceDescriptions.some((item) => item.code)
  ) {
    return commonUtils.sortByParams(priceDescriptions, "code");
  } else {
    return priceDescriptions;
  }
}

function sortFareOptions(flightFareOptionsShow) {
  if (flightFareOptionsShow && flightFareOptionsShow.length > 0) {
    return flightFareOptionsShow.sort((a, b) => a.totalPrice - b.totalPrice);
  }
}

function closeUpsellModal() {
  //关闭之前先还原初始化设置
  originDestinationIndexRef.value = 0;
  const flightInfo =
    flightItemsShowRef.value && flightItemsShowRef.value.length > 0
      ? flightItemsShowRef.value[originDestinationIndexRef.value]
      : null;
  if (flightInfo)
    changeFlight(
      originDestinationIndexRef.value,
      flightInfo.originDestinationKey + "-tab",
      flightInfo,
      true
    );
  emits("closeUpsellModal");
}

function getFareOptionLabel(fareItem) {
  let label = null;

  if (fareItem.outOfPolicy) {
    label = "out-of-policy-uppercase";
  } else if (fareItem.upgradeAllowed) {
    label = "upgrade-allowed-uppercase";
  } else if (fareItem.approvalRequired) {
    label = "approval-required-uppercase";
  }
  return label;
}
</script>

<template>
  <div id="upsell-panel" class="upsell-panel">
    <div class="text-end" @click="closeUpsellModal">
      <i class="fa-solid fa-xmark font-20 cursor-point"></i>
    </div>
    <div class="upsell-content">
      <ul class="nav nav-tabs nav-tabs-block" role="tablist">
        <li
          v-for="(originDes, originDesIndex) in flightItemsShowRef"
          :key="originDesIndex"
          class="nav-item"
        >
          <button
            :id="originDes.originDestinationKey + '-tab'"
            :aria-controls="originDes.originDestinationKey + '-tab'"
            :class="{ active: originDestinationIndexRef === originDesIndex }"
            :data-bs-target="'#' + originDes.originDestinationKey + '-tab'"
            :disabled="originDesIndex > originDestinationIndexRef"
            aria-selected="true"
            class="nav-link"
            data-bs-toggle="tab"
            role="tab"
            type="button"
            @click="
              changeFlight(
                originDesIndex,
                originDes.originDestinationKey + '-tab',
                originDes
              )
            "
          >
            {{ originDes.originLocationCode }} >
            {{ originDes.destinationLocationCode }}
          </button>
        </li>
      </ul>

      <div class="block-content tab-content overflow-hidden">
        <div :id="activeTabRef">
          <div class="p-3">
            <div v-if="flightItemShowRef">
              {{ dateUtils.getWeek(flightItemShowRef.departureDateTime) }}
              ,
              {{
                dateUtils.formatEnglishDate(flightItemShowRef.departureDateTime)
                  .ddate
              }}
              {{
                dateUtils.formatEnglishDate(flightItemShowRef.departureDateTime)
                  .month
              }}
              {{
                dateUtils.formatEnglishDate(flightItemShowRef.departureDateTime)
                  .year
              }},
              <span class="text-primary">{{
                flightItemShowRef.originLocationCode
              }}</span>
              {{
                dateUtils.formatEnglishDate(flightItemShowRef.departureDateTime)
                  .time
              }}
              >
              <span class="text-primary">{{
                flightItemShowRef.destinationLocationCode
              }}</span>
              {{
                dateUtils.formatEnglishDate(flightItemShowRef.arrivalDateTime)
                  .time
              }}
              <span
                v-if="
                  flightItemShowRef.flightSegments &&
                  flightItemShowRef.flightSegments.length > 0
                "
              >
                |
                <span
                  v-for="(
                    segment, segIndex
                  ) in flightItemShowRef.flightSegments"
                  :key="segIndex"
                >
                  <span class="font_weight"
                    >{{ segment.marketingAirlineCode }}
                    {{ segment.flightNumber }}</span
                  >
                  {{ $t("operated-by") }}
                  <span>
                    {{
                      flightCommonTools.getAirlineName(
                        locale,
                        segment.operatingAirlineCode,
                        airlineItemMap
                      )
                    }}
                  </span>
                  <span
                    v-if="
                      segIndex < flightItemShowRef.flightSegments.length - 1
                    "
                    >,
                  </span>
                </span>
              </span>
            </div>

            <hr
              v-if="
                flightFareOptionsShowRef && flightFareOptionsShowRef.length > 0
              "
            />
            <div
              v-if="
                flightFareOptionsShowRef && flightFareOptionsShowRef.length > 0
              "
              class="fare-info"
              style="position: relative"
            >
              <div class="fare-names">
                <div
                  v-for="(fareOption, fareIndex) in sortFareOptions(
                    flightFareOptionsShowRef
                  )"
                  :key="fareIndex"
                  class="mg-b-10"
                >
                  <div class="form-check">
                    <input
                      :id="'time-radio-box' + fareIndex"
                      v-model="currentFareOptionSelectedRef"
                      :name="'time-radio-box' + fareIndex"
                      :value="fareOption"
                      class="form-check-input"
                      type="radio"
                    />
                    <label
                      :for="'time-radio-box' + fareIndex"
                      class="form-check-label"
                    >
                      {{
                        getPriceClassName(
                          priceClassesRef,
                          fareOption.priceClassId
                        )
                      }}
                      <span
                        v-if="
                          fareOption.bookingClassCodes &&
                          fareOption.bookingClassCodes.length > 0
                        "
                        >({{ fareOption.bookingClassCodes.toString() }})
                      </span>
                    </label>
                  </div>
                  <div class="mg-l-8 text-muted">
                    {{
                      getPriceLabel(
                        originalTripAirItemRef,
                        fareOption,
                        fareOptionsSelectedRef[originDestinationIndexRef - 1]
                      )
                    }}
                  </div>
                </div>
              </div>

              <div class="fare-rules">
                <div v-if="currentFareOptionSelectedRef">
                  <div
                    class="p-2"
                    style="width: 800px; background-color: #f0f0f0"
                  >
                    <div class="flex flex-row align-items-center">
                      <img
                        v-if="
                          flightItemShowRef &&
                          flightItemShowRef.flightSegments &&
                          flightItemShowRef.flightSegments.length > 0
                        "
                        :src="
                          commonUtils.getAirlinePicture(
                            flightItemShowRef.flightSegments[0]
                              .marketingAirlineCode
                          )
                        "
                        alt="img"
                        class="airline-logo mg-r-20"
                        style="height: 55px; width: 55px"
                      />
                      <h5
                        class="flex flex-column justify-content-center mg-t-10"
                      >
                        <div>{{ $t("currently-selected") }}</div>
                        <div class="text-primary mg-t-5">
                          {{
                            getPriceClassName(
                              priceClassesRef,
                              currentFareOptionSelectedRef.priceClassId
                            )
                          }}
                        </div>
                      </h5>
                    </div>
                    <div style="margin-left: 80px">
                      <div
                        v-if="currentFareOptionSelectedRef.negotiatedFareCode"
                      >
                        <span
                          >{{ $t("fare-type-code") }}:
                          {{
                            currentFareOptionSelectedRef.negotiatedFareCode
                          }}</span
                        >
                      </div>
                      <div
                        v-if="
                          currentFareOptionSelectedRef.cabinTypes &&
                          currentFareOptionSelectedRef.cabinTypes.length > 0
                        "
                      >
                        <span
                          >{{ $t("cabin") }}:
                          {{
                            currentFareOptionSelectedRef.cabinTypes.toString()
                          }}</span
                        >
                      </div>
                      <div
                        v-if="
                          currentFareOptionSelectedRef.fareBasisCodes &&
                          currentFareOptionSelectedRef.fareBasisCodes.length > 0
                        "
                      >
                        <span
                          >{{ $t("fare-basis-code") }}:
                          {{
                            currentFareOptionSelectedRef.fareBasisCodes.toString()
                          }}</span
                        >
                      </div>
                      <div
                        v-if="
                          currentFareOptionSelectedRef.baggageAllowances &&
                          currentFareOptionSelectedRef.baggageAllowances
                            .length > 0
                        "
                      >
                        <span
                          >{{ $t("baggage-allowance") }}:
                          {{
                            currentFareOptionSelectedRef.baggageAllowances.toString()
                          }}</span
                        >
                      </div>

                      <div
                        v-if="currentFareOptionSelectedRef.bookingSourceType"
                      >
                        {{ $t("channel") }} :
                        {{ currentFareOptionSelectedRef.bookingSourceType }}
                      </div>
                    </div>
                    <div
                      class="flex flex-column justify-content-center align-items-center"
                    >
                      <div class="font_weight" style="font-size: 30px">
                        {{
                          getPriceLabel(
                            originalTripAirItemRef,
                            currentFareOptionSelectedRef,
                            fareOptionsSelectedRef[
                              originDestinationIndexRef - 1
                            ]
                          ).split(" ")[0]
                        }}{{
                          getPriceLabel(
                            originalTripAirItemRef,
                            currentFareOptionSelectedRef,
                            fareOptionsSelectedRef[
                              originDestinationIndexRef - 1
                            ]
                          ).split(" ")[1]
                        }}
                      </div>
                      <div class="mg-t-5 text-muted">
                        {{
                          getPriceLabel(
                            originalTripAirItemRef,
                            currentFareOptionSelectedRef,
                            fareOptionsSelectedRef[
                              originDestinationIndexRef - 1
                            ]
                          ).split(" ")[2]
                        }}
                        {{ $t("including-tax-uppercase") }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      filterPriceClassDescriptions(
                        flightUpsellStore.priceClasses,
                        currentFareOptionSelectedRef
                      )
                    "
                    class="p-2"
                  >
                    <div
                      v-for="(
                        priceClassItem, segmentRPH
                      ) in filterPriceClassDescriptions(
                        flightUpsellStore.priceClasses,
                        currentFareOptionSelectedRef
                      )"
                      :key="segmentRPH"
                    >
                      <div class="p-xl-3">
                        <div
                          v-if="
                            filterPriceClassDescriptions(
                              flightUpsellStore.priceClasses,
                              currentFareOptionSelectedRef
                            ).length > 1
                          "
                          class="font_weight font-18"
                        >
                          {{ priceClassItem.priceClassName }}
                        </div>
                        <div
                          v-for="(
                            descriptionItem, descriptionIndex
                          ) in sortDescriptions(
                            priceClassItem.priceDescriptions
                          )"
                          :key="descriptionIndex"
                        >
                          <div>
                            <span v-html="descriptionItem.description"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fare-details">
                <!--                <div-->
                <!--                  class="p-2 flex"-->
                <!--                  v-if="-->
                <!--                    filterPriceClassDescriptions(-->
                <!--                      flightUpsellStore.priceClasses,-->
                <!--                      currentFareOptionSelectedRef-->
                <!--                    )-->
                <!--                  "-->
                <!--                >-->
                <!--                  <div-->
                <!--                    v-for="(-->
                <!--                      priceClassItem, segmentRPH-->
                <!--                    ) in filterPriceClassDescriptions(-->
                <!--                      flightUpsellStore.priceClasses,-->
                <!--                      currentFareOptionSelectedRef-->
                <!--                    )"-->
                <!--                    :key="segmentRPH"-->
                <!--                  >-->
                <!--                    <div class="mg-up-10 p-xl-3">-->
                <!--                      <div-->
                <!--                        v-for="(-->
                <!--                          descriptionItem, descriptionIndex-->
                <!--                        ) in sortDescriptions(priceClassItem.priceDescriptions)"-->
                <!--                        :key="descriptionIndex"-->
                <!--                      >-->
                <!--                        <div>-->
                <!--                          <span v-html="descriptionItem.description"></span>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
            <!-- 数据为空显示 -->
            <div v-else class="text-center">
              <AEmpty :description="$t('no-fares')"></AEmpty>
            </div>
            <!-- 数据为空显示END -->
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="currentFareOptionSelectedRef && flightItemShowRef"
      class="upsell-footer"
    >
      <div class="row text-bg-light p-1">
        <div class="col-3">
          <div v-if="originalTripAirItemRef">
            <div class="text-muted">
              {{ $t("original-selection-including-tax") }}
            </div>
            <div class="font_weight" style="font-size: 23px">
              {{
                fareOptionsSelectedRef[originDestinationIndexRef - 1] &&
                fareOptionsSelectedRef[originDestinationIndexRef - 1].totalPrice
                  ? commonUtils.formatPriceThousands(
                      fareOptionsSelectedRef[originDestinationIndexRef - 1]
                        .totalPrice
                    )
                  : commonUtils.formatPriceThousands(
                      originalTripAirItemRef.tripAirItemFare.totalAmount
                    )
              }}
              {{ originalTripAirItemRef.tripAirItemFare.currencyCode }}
            </div>
          </div>
        </div>
        <div class="col-3">
          <div v-if="originalTripAirItemRef">
            <div class="text-muted">
              {{ flightItemShowRef.originLocationCode }} >
              {{ flightItemShowRef.destinationLocationCode }}
            </div>
            <div class="font_weight text-primary" style="font-size: 23px">
              {{
                getPriceLabel(
                  originalTripAirItemRef,
                  currentFareOptionSelectedRef,
                  fareOptionsSelectedRef[originDestinationIndexRef - 1]
                )
              }}
            </div>
          </div>
        </div>
        <div class="col-4 text-end">
          <div class="text-muted">{{ $t("total-including-tax") }}</div>
          <div class="font_weight text-danger" style="font-size: 23px">
            {{
              commonUtils.formatPriceThousands(
                currentFareOptionSelectedRef.totalPrice
              )
            }}
          </div>
        </div>
      </div>
      <div class="text-end mg-t-20">
        <button
          v-if="originDestinationIndexRef > 0"
          class="btn btn-outline-secondary mg-r-20"
          type="button"
          @click="
            changeFlight(
              originDestinationIndexRef - 1,
              flightItemsShowRef[originDestinationIndexRef - 1]
                .originDestinationKey + '-tab',
              flightItemsShowRef[originDestinationIndexRef - 1]
            )
          "
        >
          {{ $t("back") }}
        </button>

        <button
          class="btn btn-outline-primary"
          type="button"
          @click="
            changeFareOptions(
              currentFareOptionSelectedRef,
              fareOptionsSelectedRef,
              flightUpsellStore.tripAirItems
            )
          "
        >
          {{ $t("continue") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.upsell-panel {
  position: relative;
  height: 100vh;

  overflow-y: hidden;
  overflow-x: hidden;

  .upsell-content {
    height: 80%;

    .fare-info {
      display: flex;
      flex-direction: row;
      height: 65vh;
      overflow-y: scroll;

      .fare-names {
        width: 20%;

        //border-right: 1px solid #c0c0c0;
        //margin-right: 10px;
      }

      .fare-rules {
        width: 35%;

        //border-right: 1px solid #c0c0c0;
      }

      .fare-details {
        width: 35%;
      }
    }
  }

  .upsell-footer {
    position: absolute;
    bottom: 0;
    width: 98%;
    height: 20%;
  }
}
</style>

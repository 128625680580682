function buildCarRentalInitiateRQ(carRentalBookFormModel) {
  const quoteID = carRentalBookFormModel.quoteID;
  const driverFormModel = carRentalBookFormModel.driverFormModel;
  const protectionPlan = carRentalBookFormModel.protectionPlan;
  const equipments = carRentalBookFormModel.selectedEquipments;
  const insurances = carRentalBookFormModel.selectedInsurances;
  let specialEquipPrefs = null;
  if (equipments != null && equipments.length > 0) {
    specialEquipPrefs = [];
    equipments.forEach((equipment) => {
      specialEquipPrefs.push({
        code: equipment.code,
        quantity: equipment.quantity,
      });
    });
  }

  let coveragePrefs = null;
  if (insurances && insurances.length > 0) {
    if (!coveragePrefs) coveragePrefs = [];
    insurances.forEach((insurance) => {
      coveragePrefs.push({
        code: insurance.code,
      });
    });
  }

  if (protectionPlan) {
    if (!coveragePrefs) coveragePrefs = [];
    const code = protectionPlan.code;
    coveragePrefs.push({
      code: code,
    });
  }

  return {
    vehResRQCore: {
      status: "Initiate",
      rateQualifier: {
        rateQualifier: quoteID,
      },
      customer: {
        primary: {
          personName: {
            givenName: driverFormModel.givenName,
            surname: driverFormModel.surname,
            nameTitle: driverFormModel.nameTitle,
          },
          citizenCountryName: {
            code: driverFormModel.citizenCountry,
          },
          telephoneList: [
            {
              phoneNumber: driverFormModel.phoneNumber,
              countryAccessCode: driverFormModel.countryAccessCode,
            },
          ],
          emailList: [
            {
              stringValue: driverFormModel.emailAddress,
            },
          ],
        },
      },
      specialEquipPrefs: {
        specialEquipPrefs: specialEquipPrefs,
      },
    },
    vehResRQInfo: {
      coveragePrefs: coveragePrefs,
    },
  };
}

function buildCarRentalBookRQ(confIDs) {
  return {
    vehResRQCore: {
      status: "Book",
      uniqueIDs: confIDs,
    },
  };
}

export default {
  buildCarRentalInitiateRQ,
  buildCarRentalBookRQ,
};

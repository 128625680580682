<template>
    <section id="holiday_destinations" class="section_padding_top">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="holiday_left_heading">
                                <div class="heading_left_area">
                                    <h2>Explore most popular holiday <span>destinations</span></h2>
                                    <h5>Discover your ideal experience with us</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="holiday_small_boxed">
                                <router-link to="/templates/top-destinations-details">
                                    <img src="../../../assets/img/holiday-img/holiday-1.png" alt="img">
                                    <div class="holiday_small_box_content">
                                        <div class="holiday_inner_content">
                                            <div class="rating_outof">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <h5>5 Out of 5</h5>
                                            </div>
                                            <h3>China | $6000</h3>
                                            <h4>8 days 7 nights</h4>
                                            <p>Cupidatat consectetur ea cillum nt
                                                consectetur excepteur.</p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="holiday_small_boxed">
                                <router-link to="/templates/top-destinations-details">
                                    <img src="../../../assets/img/holiday-img/holiday-2.png" alt="img">
                                    <div class="holiday_small_box_content">
                                        <div class="holiday_inner_content">
                                            <div class="rating_outof">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <h5>5 Out of 5</h5>
                                            </div>
                                            <h3>China | $6000</h3>
                                            <h4>8 days 7 nights</h4>
                                            <p>Cupidatat consectetur ea cillum nt
                                                consectetur excepteur.</p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="holiday_small_boxed">
                                <router-link to="/templates/top-destinations-details">
                                    <img src="../../../assets/img/holiday-img/holiday-3.png" alt="img">
                                    <div class="holiday_small_box_content">
                                        <div class="holiday_inner_content">
                                            <div class="rating_outof">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <h5>5 Out of 5</h5>
                                            </div>
                                            <h3>China | $6000</h3>
                                            <h4>8 days 7 nights</h4>
                                            <p>Cupidatat consectetur ea cillum nt
                                                consectetur excepteur.</p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="holiday_small_boxed">
                                <router-link to="/templates/top-destinations-details">
                                    <img src="../../../assets/img/holiday-img/holiday-4.png" alt="img">
                                    <div class="holiday_small_box_content">
                                        <div class="holiday_inner_content">
                                            <div class="rating_outof">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <h5>5 Out of 5</h5>
                                            </div>
                                            <h3>China | $6000</h3>
                                            <h4>8 days 7 nights</h4>
                                            <p>Cupidatat consectetur ea cillum nt
                                                consectetur excepteur.</p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div class="holiday_small_boxed">
                                        <router-link to="/templates/top-destinations-details">
                                            <img src="../../../assets/img/holiday-img/holiday-5.png" alt="img">
                                            <div class="holiday_small_box_content">
                                                <div class="holiday_inner_content">
                                                    <div class="rating_outof">
                                                        <i class="fas fa-star"></i>
                                                        <i class="fas fa-star"></i>
                                                        <i class="fas fa-star"></i>
                                                        <i class="fas fa-star"></i>
                                                        <i class="fas fa-star"></i>
                                                        <h5>5 Out of 5</h5>
                                                    </div>
                                                    <h3>China | $6000</h3>
                                                    <h4>8 days 7 nights</h4>
                                                    <p>Cupidatat consectetur ea cillum nt
                                                        consectetur excepteur.</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div class="holiday_small_boxed">
                                        <router-link to="/templates/top-destinations-details">
                                            <img src="../../../assets/img/holiday-img/holiday-6.png" alt="img">
                                            <div class="holiday_small_box_content">
                                                <div class="holiday_inner_content">
                                                    <div class="rating_outof">
                                                        <i class="fas fa-star"></i>
                                                        <i class="fas fa-star"></i>
                                                        <i class="fas fa-star"></i>
                                                        <i class="fas fa-star"></i>
                                                        <i class="fas fa-star"></i>
                                                        <h5>5 Out of 5</h5>
                                                    </div>
                                                    <h3>China | $6000</h3>
                                                    <h4>8 days 7 nights</h4>
                                                    <p>Cupidatat consectetur ea cillum nt
                                                        consectetur excepteur.</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "HolidayDestinations"
};
</script>
<script setup>
import { Empty, Modal, Popover } from "ant-design-vue";
import { ref, watch } from "vue";
import dateUtils from "@/utils/dateUtils";
import FlightFareOptionItemNormalPanel from "@/views/v2/sales/flight/items/panel/shopping/FlightFareOptionItemNormalPanel.vue";
import commonUtils from "@/utils/commonUtils";
import { useFlightShoppingStore } from "@/stores/v2/flight/shopping/flightShoppingStore";
import { passengerTypeOptions } from "@/formmodel/sales/flight/common/options/flightCommonOption";
import { useI18n } from "vue-i18n";
import { useTemplateStore } from "@/stores/template";

const props = defineProps({
  itineraryFareOptionKeyMap: {},
  fareOptionsSelected: {},
  fareItem: {},
  flightInfo: {},
  fareClock: {},
  fareActiveKey: {},
  fareIndex: {},
  activeFlightGroupNumber: {},
  priceClasses: {},
  previousOptionPrice: {},
});

const AModal = Modal;
const AEmpty = Empty;
const APopover = Popover;
const templateStore = useTemplateStore();
const flightShoppingStore = useFlightShoppingStore();
const emits = defineEmits([]);

const { t } = useI18n();
const fareDescriptionModalOpenRef = ref(false);
const structuredRuleModalOpenRef = ref(false);
const fareActiveKeyRef = ref(null);
const passengerTypeOptionsRef = ref(passengerTypeOptions);
const flightInfoRef = ref(null);

watch(
  () => props.fareActiveKey,
  (newValue) => {
    fareActiveKeyRef.value = newValue;
  },
  { deep: true, immediate: true }
);
watch(
  () => props.flightInfo,
  (newValue) => {
    flightInfoRef.value = newValue;
  },
  { deep: true, immediate: true }
);

function changeFare(fareOptionsSelected, fareClock, keyIndex, fareItem) {
  clearInterval(fareClock);
  if (fareItem.expirationDateTime) emits("clock", fareItem.expirationDateTime);
  emits("changeFareActiveKey", keyIndex);
  fareOptionsSelected[props.activeFlightGroupNumber] = fareItem;
}

const descriptionActiveIndexRef = ref(0);

function closeFareDescriptionModal() {
  fareDescriptionModalOpenRef.value = false;
  descriptionActiveIndexRef.value = 0;
}

function formatPriceShow(currencyCode, currentTotalPrice, previousOptionPrice) {
  if (!previousOptionPrice)
    return (
      currencyCode +
      " " +
      currentTotalPrice
        .toString()
        .split("")
        .reverse()
        .reduce((prev, next, index) => {
          return (index % 3 ? next : next + ",") + prev;
        })
    );

  const result = parseInt(currentTotalPrice - previousOptionPrice);
  if (result < 0) {
    let priceDiff = result.toString();
    return (
      '<span v-else class="mg-r-8">-</span>' +
      currencyCode +
      " " +
      priceDiff
        .substring(1)
        .split("")
        .reverse()
        .reduce((prev, next, index) => {
          return (index % 3 ? next : next + ",") + prev;
        })
    );
  } else {
    let priceDiff = result.toString();
    return (
      '<span v-else class="mg-r-8">+</span>' +
      currencyCode +
      " " +
      priceDiff
        .split("")
        .reverse()
        .reduce((prev, next, index) => {
          return (index % 3 ? next : next + ",") + prev;
        })
    );
  }
}

function getPriceClassName(priceClasses, priceClassId) {
  if (!priceClasses || priceClasses.length === 0) return "";

  if (priceClassId.indexOf("@@") >= 0) {
    let brandFareNames = [];
    //todo 是否去重
    const split = priceClassId.split("@@");
    split.forEach((id) => {
      priceClasses.forEach((e) => {
        if (
          id === e.priceClassId &&
          brandFareNames.indexOf(e.priceClassName) < 0
        ) {
          brandFareNames.push(e.priceClassName);
        }
      });
    });

    return brandFareNames.join(" + ");
  } else {
    return priceClasses.find((item) => item.priceClassId === priceClassId)
      .priceClassName;
  }
}

function filterPriceClassDescriptions(
  priceClasses,
  fareItem,
  flightSegmentMap
) {
  const priceClassId = fareItem.priceClassId;
  const segmentPriceClass = fareItem.segmentPriceClass;
  if (priceClassId) {
    const priceClassIdSplit = priceClassId.split("@@");
    const priceClassesMap = {};
    priceClassIdSplit.forEach((id) => {
      const priceClassItem = priceClasses.find(
        (item) => item.priceClassId === id
      );
      const segmentPriceClasses = segmentPriceClass.filter(
        (item) => item.bundleID === id
      ); //找到该OD表示这种priceClassId的priceClasses

      if (segmentPriceClasses && segmentPriceClasses.length > 0) {
        segmentPriceClass.forEach((segmentPriceClassItem) => {
          const flightSegmentRPH = segmentPriceClassItem.flightSegmentRPH;
          const priceClassName = priceClassItem.priceClassName;
          const priceDescriptions = priceClassItem.priceDescriptions;
          const segmentInfo =
            flightSegmentMap && flightSegmentMap[flightSegmentRPH]
              ? flightSegmentMap[flightSegmentRPH]
              : null;
          priceClassesMap[flightSegmentRPH] = {
            priceClassName,
            priceDescriptions,
            segmentInfo,
          };
        });
      }
    });

    return priceClassesMap;
  }
}

function sortDescriptions(priceDescriptions) {
  if (
    priceDescriptions &&
    priceDescriptions.length > 0 &&
    priceDescriptions.some((item) => item.code)
  ) {
    return commonUtils.sortByParams(priceDescriptions, "code");
  } else {
    return priceDescriptions;
  }
}

function buildStructuredFareMap(penaltyOptions) {
  let fareMap = null;
  if (penaltyOptions && penaltyOptions.length > 0) {
    fareMap = penaltyOptions.reduce((acc, currentObj) => {
      const key = currentObj.passengerTypeCode;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(currentObj);
      return acc;
    }, {});
  }

  return fareMap;
}

function getDepartureStatusLabel(departureStatus) {
  let label = departureStatus;
  if (departureStatus === "BEFORE_DEPARTURE") {
    label = t("before-departure");
  } else if (departureStatus === "AFTER_DEPARTURE") {
    label = t("after-departure");
  }
  return label;
}

function buildShortFareDescriptions(penaltyOptions) {
  const options = [];
  if (penaltyOptions && penaltyOptions.length > 0) {
    const beforeDeparture = penaltyOptions.find(
      (item) =>
        item.departureStatus === "BEFORE_DEPARTURE" &&
        item.passengerTypeCode === "ADT"
    );
    penaltyOptions.forEach((item) => {
      const passengerTypeCode = item.passengerTypeCode;
      if (passengerTypeCode === "ADT") {
        const departureStatus = item.departureStatus;
        if (departureStatus === "BEFORE_DEPARTURE") {
          options.push(item);
        }
      }
    });
  }

  return options;
}

function getPassengerType(typeCode) {
  if (typeCode === "ADT") {
    return t("adult");
  } else if (typeCode === "CNN") {
    return t("child");
  } else if (typeCode === "INF") {
    return t("infant");
  } else {
    if (
      passengerTypeOptionsRef.value &&
      passengerTypeOptionsRef.value.length > 0
    ) {
      const passengerType = passengerTypeOptionsRef.value.find(
        (item) => item.code === typeCode
      );
      return passengerType ? passengerType.name : "";
    }
  }
}

function buildStructuredDepartureFareMap(penaltyOptions) {
  let fareMap = null;
  if (penaltyOptions && penaltyOptions.length > 0) {
    fareMap = penaltyOptions.reduce((acc, currentObj) => {
      const key = currentObj.departureStatus;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(currentObj);
      return acc;
    }, {});
  }

  return fareMap;
}
</script>

<template>
  <div
    :class="{
      'fare-active':
        fareActiveKeyRef ===
        flightInfoRef.originDestinationKey + '@' + fareIndex,
    }"
    class="theme_common_box_two img_hover cursor-point"
    @click="changeFare(fareOptionsSelected, fareClock, fareIndex, fareItem)"
  >
    <div id="flight_type_1`" class="flight_ticket_content">
      <h4 v-if="fareItem.priceClassId" class="text-muted text-wrap">
        {{ getPriceClassName(priceClasses, fareItem.priceClassId) }}
      </h4>
      <h4>
        <span
          v-html="
            formatPriceShow(
              fareItem.currencyCode,
              fareItem.totalPrice.toFixed(2),
              previousOptionPrice
            )
          "
        ></span>
      </h4>
      <h6 v-if="fareItem.fareTypeName || fareItem.fareTypeCode">
        {{ fareItem.fareTypeName }} ({{ fareItem.fareTypeCode }})
      </h6>
      <div>
        <div v-if="fareItem.expirationDateTime">
          {{ $t("expiry-date") }}:
          <span class="text-warning">{{
            dateUtils.formatDateTime(fareItem.expirationDateTime)
          }}</span>
        </div>
        <div v-if="fareItem.cabinTypes && fareItem.cabinTypes.length > 0">
          <span>{{ $t("cabin") }}: {{ fareItem.cabinTypes.toString() }}</span>
        </div>
        <div
          v-if="
            fareItem.baggageAllowances && fareItem.baggageAllowances.length > 0
          "
        >
          <span
            >{{ $t("baggage-allowance") }}:
            {{ fareItem.baggageAllowances.toString() }}</span
          >
        </div>
        <!-- structured rules-->
        <div
          v-if="
            fareItem.penaltyOptions &&
            fareItem.penaltyOptions.length > 0 &&
            buildShortFareDescriptions(fareItem.penaltyOptions) &&
            buildShortFareDescriptions(fareItem.penaltyOptions).length > 0
          "
          class="mg-t-10"
        >
          <div class="font_weight">
            {{ $t("flexibility-fare-info")
            }}<i
              class="fa-solid fa-eye mg-l-8 cursor-point"
              @click="structuredRuleModalOpenRef = true"
            ></i>
          </div>
          <div
            v-for="(
              passengerFare, passengerFareIndex
            ) in buildShortFareDescriptions(fareItem.penaltyOptions)"
            :key="passengerFareIndex"
            class="mg-t-5"
          >
            <!-- 是否可退 -->
            <div v-if="passengerFare.penaltyType === 'REFUND_PENALTY'">
              <div v-if="passengerFare.volChangeInd" class="row">
                <div class="col-8">
                  <i class="fa-solid fa-dollar-sign mg-r-8"></i>
                  {{ $t("cancellation-fee") }}
                </div>
                <div class="col-4">
                  <span v-if="passengerFare.percent"
                    >{{ $t("flight-price") }} x
                    {{ passengerFare.percent }}</span
                  >
                  <span v-else-if="passengerFare.amount === 0">
                    {{ $t("free") }}</span
                  >
                  <span v-else>
                    {{ passengerFare.currencyCode }}
                    {{ passengerFare.amount }}</span
                  >
                </div>
              </div>
              <div v-else class="row">
                <div class="col-12">
                  <i class="fa-solid fa-xmark mg-r-8"></i>
                  {{ $t("non-refundable") }}
                </div>
              </div>
            </div>

            <!-- 是否可改航班  -->
            <div v-else-if="passengerFare.penaltyType === 'CHANGE_PENALTY'">
              <div v-if="passengerFare.volChangeInd" class="row">
                <div class="col-8">
                  <i class="fa-solid fa-dollar-sign mg-r-8"></i>
                  {{ $t("change-fee") }}
                </div>
                <div class="col-4">
                  <span v-if="passengerFare.percent"
                    >{{ $t("flight-price") }} x
                    {{ passengerFare.percent }}</span
                  >
                  <span v-else-if="passengerFare.amount === 0">
                    {{ $t("free") }}</span
                  >
                  <span v-else>
                    {{ passengerFare.currencyCode }}
                    {{ passengerFare.amount }}</span
                  >
                </div>
              </div>
              <div v-else class="row">
                <div class="col-12">
                  <i class="fa-solid fa-xmark mg-r-8"></i>
                  {{ $t("unchangeable") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mg-t-10 mg-b-10"
          style="display: flex; justify-content: center; align-items: center"
        >
          <div
            :class="{
              'fare-active':
                fareActiveKeyRef ===
                flightInfoRef.originDestinationKey + '@' + fareIndex,
            }"
            class="fare-btn"
          ></div>
        </div>

        <div
          v-if="fareItem.priceClassId"
          class="main_color cursor-point text-end mg-t-20"
        >
          <span class="mg-l-8" @click="fareDescriptionModalOpenRef = true">
            {{ $t("normal-rules") }}
          </span>
        </div>

        <div v-else class="main_color cursor-point text-end mg-t-20">
          <FlightFareOptionItemNormalPanel
            :fare-index="fareIndex"
            :fare-item="fareItem"
            :flight-info="flightInfoRef"
            :itinerary-fare-option-key-map="itineraryFareOptionKeyMap"
            :price-classes="priceClasses"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- 无需请求 fare 描述 -->
  <a-modal
    v-model:open="fareDescriptionModalOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :maskClosable="false"
    :ok-button-props="{ style: { display: 'none' } }"
    :z-index="111111"
    centered
    width="40%"
    wrapClassName="fare-modal"
    @cancel="closeFareDescriptionModal"
  >
    <div class="mg-t-20">
      <h3>{{ $t("fare-rules") }}</h3>
      <div
        v-if="
          filterPriceClassDescriptions(
            priceClasses,
            fareItem,
            flightShoppingStore.flightSegmentMap
          )
        "
        class="p-2"
      >
        <div
          v-for="(priceClassItem, segmentRPH) in filterPriceClassDescriptions(
            priceClasses,
            fareItem,
            flightShoppingStore.flightSegmentMap
          )"
          :key="segmentRPH"
        >
          <div class="font_weight font-18">
            {{ priceClassItem.priceClassName }}
            <span
              v-if="priceClassItem.segmentInfo"
              class="text-muted mg-l-8 font-14"
            >
              ( {{ priceClassItem.segmentInfo.departureAirportCode }} -
              {{ priceClassItem.segmentInfo.arrivalAirportCode }} -
              {{ priceClassItem.segmentInfo.marketingAirlineCode }}
              {{ priceClassItem.segmentInfo.flightNumber }}
              )</span
            >
          </div>

          <div class="mg-up-10 p-xl-3">
            <div
              v-for="(descriptionItem, descriptionIndex) in sortDescriptions(
                priceClassItem.priceDescriptions
              )"
              :key="descriptionIndex"
            >
              <div>
                <span v-html="descriptionItem.description"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="p-2">
        <AEmpty :description="$t('there-are-no-fare-rules')"></AEmpty>
      </div>
    </div>
  </a-modal>
  <!--无需请求 fare描述 END -->

  <!-- structured fare 描述 -->
  <a-modal
    v-model:open="structuredRuleModalOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :maskClosable="false"
    :ok-button-props="{ style: { display: 'none' } }"
    :z-index="111111"
    centered
    width="30%"
    wrapClassName="fare-modal"
    @cancel="structuredRuleModalOpenRef = false"
  >
    <div class="mg-t-20">
      <h3>{{ $t("fare-rules") }}</h3>
      <div
        v-for="(passengerFares, passengerFareKey) in buildStructuredFareMap(
          fareItem.penaltyOptions
        )"
        :key="passengerFareKey"
      >
        <div class="font_weight">{{ getPassengerType(passengerFareKey) }}</div>
        <div
          v-for="(penaltyFares, penaltyKey) in buildStructuredDepartureFareMap(
            passengerFares
          )"
          :key="penaltyKey"
          class="mg-b-5"
        >
          <div class="mg-t-10">{{ getDepartureStatusLabel(penaltyKey) }}:</div>
          <div
            v-for="(penaltyFare, penaltyIndex) in penaltyFares"
            :key="penaltyIndex"
            class="mg-b-5"
          >
            <!-- 是否可退 -->
            <div v-if="penaltyFare.penaltyType === 'REFUND_PENALTY'">
              <div v-if="penaltyFare.volChangeInd" class="row">
                <div class="col-8">
                  <i class="fa-solid fa-dollar-sign mg-r-8"></i>
                  {{ $t("cancellation-fee") }}
                </div>
                <div class="col-4">
                  <span v-if="penaltyFare.percent"
                    >{{ $t("flight-price") }} x {{ penaltyFare.percent }}</span
                  >
                  <span v-else-if="penaltyFare.amount === 0">
                    {{ $t("free") }}</span
                  >
                  <span v-else>
                    {{ penaltyFare.currencyCode }}
                    {{ penaltyFare.amount }}</span
                  >
                </div>
              </div>
              <div v-else class="row">
                <div class="col-12">
                  <i class="fa-solid fa-xmark mg-r-8"></i>
                  {{ $t("non-refundable") }}
                </div>
              </div>
            </div>

            <!-- 是否可改航班  -->
            <div v-else-if="penaltyFare.penaltyType === 'CHANGE_PENALTY'">
              <div v-if="penaltyFare.volChangeInd" class="row">
                <div class="col-8">
                  <i class="fa-solid fa-dollar-sign mg-r-8"></i>
                  {{ $t("change-fee") }}
                </div>
                <div class="col-4">
                  <span v-if="penaltyFare.percent"
                    >{{ $t("flight-price") }} x {{ penaltyFare.percent }}</span
                  >
                  <span v-else-if="penaltyFare.amount === 0">
                    {{ $t("free") }}</span
                  >
                  <span v-else>
                    {{ penaltyFare.currencyCode }}
                    {{ penaltyFare.amount }}</span
                  >
                </div>
              </div>
              <div v-else class="row">
                <div class="col-12">
                  <i class="fa-solid fa-xmark mg-r-8"></i>
                  {{ $t("unchangeable") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
  <!--structured fare描述 END -->
</template>

<style scoped></style>

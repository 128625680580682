export class FlightReshopAmountVO {
  /**
   *
   * @param currencyCode string，
   * @param totalAmount number，
   * @param operation string，指reshop的操作
   */
  constructor(currencyCode, totalAmount, operation) {
    this.currencyCode = currencyCode;
    this.totalAmount = totalAmount;
    this.operation = operation;
  }
}

import { FlightSegmentVO } from "@/viewobject/sales/flight/shopping/flightSegmentVO";

export class FlightItemVO {
  /**
   *
   * @param airItineraryKey string，该航班所属行程的信息键，例如，SIN-HKG-20240513-SQ-0874#HKG-SIN-20240521-SQ-0895#SIN-HKG-20240602-SQ-0874+HKG-TPE-20240602-CX-0832
   * @param tripSequenceNumber number，该航班在整个旅程中的排序号，例如，1,2,3
   * @param originLocationCode string，该航班的起飞地点代码，例如，BJS
   * @param originLocationName string，该航班的起飞地点名，例如，Beijing - All Airports (BJS)
   * @param departureAirportCode string，该航班的起飞机场代码，例如，PEK - Beijing Capital Airport (PEK)
   * @param destinationLocationCode string，该航班的终点代码，例如，SIN
   * @param destinationLocationName string，该航班的终点代码，例如，Changi International Airport (SIN)
   * @param arrivalAirportCode string，该航班的终点机场名，例如，Changi International Airport (SIN)
   * @param durationInMins number，该航班的总飞行时间（包含停靠时间）
   * @param originDestinationKey string，航班key，例如，SIN-HKG-20240513-SQ-0874
   * @param originDestRPH string，航班唯一标识
   * @param connections number，该航班的转机次数
   * @param numStops number，该航班的停靠次数
   * @param departureDateTime string，该航班开始起飞日期时间，格式为yyyy-MM-dd'T'HH:mm Z,例如，2022-01-09T00:20:00.000+08:00
   * @param arrivalDateTime string，该航班最后到达日期时间，格式为yyyy-MM-dd'T'HH:mm Z,例如，2022-01-09T06:10:00.000+01:00
   * @param currencyCode string，货币单位
   * @param bestTotalPrice number，最优总价
   * @param bookingSourceTypes Array，string数组，机票来源系统，例如，["GDS"],["GDS"，"NDC"]
   * @param supplierCodes Array，string数组，机票供应商代码数组，例如，["SQ"], ["SQ", "IB"]
   * @param priceClassId string,
   * @param flightSegments Array，FlightSegmentVO对象数组，航段信息
   */
  constructor(
    airItineraryKey,
    tripSequenceNumber,
    originLocationCode,
    originLocationName,
    departureAirportCode,
    destinationLocationCode,
    destinationLocationName,
    arrivalAirportCode,
    departureDateTime,
    arrivalDateTime,
    originDestRPH,
    originDestinationKey,
    durationInMins,
    connections,
    numStops,
    currencyCode,
    bestTotalPrice,
    bookingSourceTypes,
    supplierCodes,
    priceClassId,
    flightSegments
  ) {
    this.airItineraryKey = airItineraryKey;
    this.tripSequenceNumber = tripSequenceNumber;
    this.originLocationCode = originLocationCode;
    this.originLocationName = originLocationName;
    this.departureAirportCode = departureAirportCode;
    this.destinationLocationCode = destinationLocationCode;
    this.destinationLocationName = destinationLocationName;
    this.arrivalAirportCode = arrivalAirportCode;
    this.departureDateTime = departureDateTime;
    this.arrivalDateTime = arrivalDateTime;
    this.originDestRPH = originDestRPH;
    this.originDestinationKey = originDestinationKey;
    this.durationInMins = durationInMins;
    this.connections = connections;
    this.numStops = numStops;
    this.currencyCode = currencyCode;
    this.bestTotalPrice = bestTotalPrice;
    this.priceClassId = priceClassId;
    this.bookingSourceTypes = bookingSourceTypes;
    this.supplierCodes = supplierCodes;
    this.flightSegments = flightSegments;
  }

  addBookingSourceType(bookingSourceType) {
    if (this.bookingSourceTypes == null) this.bookingSourceTypes = [];
    this.bookingSourceTypes.push(bookingSourceType);
  }
  addSupplierCode(supplierCode) {
    if (this.supplierCodes == null) this.supplierCodes = [];
    this.supplierCodes.push(supplierCode);
  }

  addAllBookingSourceTypes(bookingSourceTypes) {
    this.bookingSourceTypes = bookingSourceTypes;
  }

  addNewFlightSegment(
    departureDateTime,
    arrivalDateTime,
    segmentKey,
    flightNumber,
    fareBasisCode,
    fareTypeName,
    resBookDesigCode,
    numberInParty,
    stopoverIn,
    departureAirportCode,
    arrivalAirportCode,
    operatingAirlineCode,
    operatingAirlineName,
    equipmentCode,
    marketingAirlineCode,
    cabinClass,
    baggageAllowance,
    rph
  ) {
    let flightSegmentVO = new FlightSegmentVO(
      departureDateTime,
      arrivalDateTime,
      segmentKey,
      flightNumber,
      fareBasisCode,
      fareTypeName,
      resBookDesigCode,
      numberInParty,
      stopoverIn,
      departureAirportCode,
      arrivalAirportCode,
      operatingAirlineCode,
      operatingAirlineName,
      equipmentCode,
      marketingAirlineCode,
      cabinClass,
      baggageAllowance,
      rph
    );

    if (this.flightSegments == null) this.flightSegments = [];
    this.flightSegments.push(flightSegmentVO);
    return flightSegmentVO;
  }

  /**
   *
   * @param flightSegmentVO 已构建好的FlightSegmentVO对象
   * @returns FlightSegmentVO对象
   */
  addFlightSegment(flightSegmentVO) {
    if (this.flightSegments == null) this.flightSegments = [];
    this.flightSegments.push(flightSegmentVO);
    return flightSegmentVO;
  }
}

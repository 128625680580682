export class HotelFeeAndTaxVO {
    /**
     *
     * @param amount number，价格
     * @param currencyCode string，货币单位
     * @param name string，税费名
     * @param textFormat string，格式化的税费描述
     */
    constructor(amount,
                currencyCode,
                name,
                textFormat) {

        this.amount = amount;
        this.currencyCode = currencyCode;
        this.name = name;
        this.textFormat = textFormat;

    }
}
function sortFlightItems(flightItemVOs, flightShoppingSortFormModel) {
  if (!flightItemVOs || flightItemVOs.length === 0) return flightItemVOs;

  //Sort Option
  const sortType = flightShoppingSortFormModel.type;
  const sortOrder = flightShoppingSortFormModel.order;

  return flightItemVOs.sort((a, b) => {
    switch (sortType) {
      case "PRICE":
        if ("DSC" === sortOrder) return b.bestTotalPrice - a.bestTotalPrice;
        return a.bestTotalPrice - b.bestTotalPrice;

      case "DURATION":
        if ("DSC" === sortOrder) return b.durationInMins - a.durationInMins;
        return a.durationInMins - b.durationInMins;
      case "DEPARTURE":
        if ("DSC" === sortOrder)
          return new Date(b.departureDateTime) - new Date(a.departureDateTime);
        return new Date(a.departureDateTime) - new Date(b.departureDateTime);
      case "ARRIVAL":
        if ("DSC" === sortOrder)
          return new Date(b.arrivalDateTime) - new Date(a.arrivalDateTime);
        return new Date(a.arrivalDateTime) - new Date(b.arrivalDateTime);
      default:
    }
  });
}

export default {
  sortFlightItems,
};

<script setup>
import { createVNode, ref, watch } from "vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useTemplateStore } from "@/stores/template";
import { usePackageVerifyPriceStore } from "@/stores/v2/dynamicPkg/price/packageVerifyPriceStore";
import { usePackageBookStore } from "@/stores/v2/dynamicPkg/book/packageBookStore";
import dynamicPkgCommonTools from "@/tools/v2/dynamicPkg/common/dynamicPkgCommonTools";
import { TripContactFormModel } from "@/formmodel/sales/trip/tripContactFormModel";
import { DynamicPkgVerifyPriceFormModel } from "@/formmodel/sales/dynamicPkg/price/dynamicPkgVerifyPriceFormModel";
import HotelItinerarySummaryPanel from "@/views/v2/sales/hotel/items/panel/HotelItinerarySummaryPanel.vue";
import FlightItineraryInfoPanel from "@/views/v2/sales/flight/items/panel/book/FlightItineraryInfoPanel.vue";
import TripContactInfoForm from "@/views/v2/sales/common/items/form/TripContactInfoForm.vue";
import HotelPoliciesPanel from "@/views/v2/sales/hotel/items/panel/tab/HotelPoliciesPanel.vue";
import FlightPenaltyOptionsPanel from "@/views/v2/sales/flight/items/panel/book/FlightPenaltyOptionsPanel.vue";
import DynamicPkgPriceSummaryOrBreakdownPanel from "@/views/v2/sales/dynamicpkg/items/panel/DynamicPkgPriceSummaryOrBreakdownPanel.vue";
import useVuelidate from "@vuelidate/core";
import DynamicPkgFlightPassengerForm from "@/views/v2/sales/dynamicpkg/items/form/checkout/DynamicPkgFlightPassengerForm.vue";
import DynamicPkgHotelCheckOutRoomPassengerForm from "@/views/v2/sales/dynamicpkg/items/form/checkout/DynamicPkgHotelCheckOutRoomPassengerForm.vue";
import { Modal } from "ant-design-vue";
import commonUtils from "@/utils/commonUtils";
import { useCmsContentStore } from "@/stores/cms";

const router = useRouter();
const route = useRoute();

const { t, locale } = useI18n(); // 解构调用函数
const AModal = Modal;
const templateStore = useTemplateStore();
const packageVerifyPriceStore = usePackageVerifyPriceStore();
const packageBookStore = usePackageBookStore();

const dynamicPkgVerifyPriceFormModelRef = ref(null);
const tripPackageItemRef = ref(null);
const passengerFormModelItemsRef = ref(null);
const priceClassesRef = ref(null);

const tripContactInfoRef = ref(
  new TripContactFormModel(null, null, null, null)
); //联系方式
const remarksCheckShowRef = ref(false);

//#region query参数请求，进行hold
if (route.query != null && Object.keys(route.query).length > 0) {
  dynamicPkgVerifyPriceFormModelRef.value = parseDynamicPkgVerifyPriceFormModel(
    route.query
  );
  verifyPrice(dynamicPkgVerifyPriceFormModelRef.value);
}
//#endregion

watch(
  () => packageVerifyPriceStore.tripPackageItem,
  (newValue) => {
    tripPackageItemRef.value = newValue;

    //获取airline capability
    if (tripPackageItemRef.value && tripPackageItemRef.value.tripAirItem) {
      const airItineraryRPH =
        tripPackageItemRef.value.tripAirItem.airItineraryRPH;
      if (airItineraryRPH)
        dynamicPkgVerifyPriceFormModelRef.value.airItineraryRPH =
          airItineraryRPH;
    }
  },
  { immediate: true, deep: true }
);
watch(
  () => packageVerifyPriceStore.passengerFormModelItems,
  (newValue) => {
    passengerFormModelItemsRef.value = newValue;
  },
  { deep: true, immediate: true }
);

watch(
  () => packageVerifyPriceStore.priceClasses,
  (newValue) => {
    priceClassesRef.value = newValue;
  },
  { immediate: true, deep: true }
);

async function verifyPrice(dynamicPkgVerifyPriceFormModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await packageVerifyPriceStore.verifyPrice(
    dynamicPkgVerifyPriceFormModel
  );
  templateStore.pageLoader({ mode: "off" });
}

const v = useVuelidate();

async function confirmBooking(
  dynamicPkgVerifyPriceFormModel,
  tripContactInfo,
  tripPackageItem,
  passengerFormModelItems
) {
  const result = await v.value.$validate();
  remarksCheckShowRef.value = false;
  if (v.value.$errors && v.value.$errors.length > 0) {
    v.value.$errors.forEach((item) => {
      if (item.$property == "remarks") {
        remarksCheckShowRef.value = true;
      }
    });
  }
  if (!result) return;
  AModal.confirm({
    content: t("you-will-confirm-the-trip-now"),
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    width: 600,
    wrapClassName: "confirmModal",
    onOk() {
      return new Promise((resolve, reject) => {
        const dynamicPkgVerifyPriceFormModelCopy = JSON.parse(
          JSON.stringify(dynamicPkgVerifyPriceFormModel)
        );
        let uniqueIDs = null;
        if (tripPackageItem && tripPackageItem.tripHotelItem) {
          uniqueIDs = tripPackageItem.tripHotelItem.uniqueIDs;
        }

        dynamicPkgVerifyPriceFormModelCopy.passengers = passengerFormModelItems;
        const dynamicPkgBookFormModel = mergeFormModelAboutContactInfo(
          dynamicPkgVerifyPriceFormModelCopy,
          tripContactInfo
        );
        dynamicPkgBookFormModel.uniqueIDs = uniqueIDs;
        templateStore.pageLoader({ mode: "on" });
        packageBookStore.book(dynamicPkgBookFormModel).then((res) => {
          AModal.destroyAll();
          if (res.success) {
            const bookingInfo = getBookingInfo(packageBookStore.bookUniqueIDs);
            router.push({
              name: "v2-payment-methods",
              query: {
                bookingId: bookingInfo.bookingId,
                invoiceId: bookingInfo.invoiceId,
                productType: "PACKAGE",
              },
            });
            packageVerifyPriceStore.$reset();
          } else {
            if (res.errors) {
              const error = res.errors.errors[0].shortText
                ? res.errors.errors[0].shortText
                : res.errors.errors[0].stringValue;

              commonUtils.openToastBox(
                "ERROR",
                t("this-is-an-error-message"),
                error
              );
            } else if (res.warnings) {
              const error = res.warnings.warnings[0].shortText
                ? res.warnings.warnings[0].shortText
                : res.warnings.warnings[0].stringValue;
              commonUtils.openToastBox(
                "WARNING",
                t("this-is-a-warning-message"),
                error
              );
            }
          }
          templateStore.pageLoader({ mode: "off" });
        });
      }).catch((err) => {
        console.log(err);
        AModal.destroyAll();
      });
    },
    cancelText: "Cancel",
    onCancel() {
      AModal.destroyAll();
    },
  });
}

function getBookingInfo(bookingReferenceIdList) {
  let recordLocator = null;
  let idContext = null;
  let airItemId = null;
  let bookingItemId = null;
  let invoiceId = null;
  let tripId = null;
  if (bookingReferenceIdList && bookingReferenceIdList.length > 0) {
    bookingReferenceIdList.forEach((item) => {
      if (item.type === "RECORD_LOCATOR") {
        recordLocator = item.id;
        idContext = item.id_Context;
      } else if (item.type === "BOOKING_AIR_ITEM_ID") {
        airItemId = item.id;
      } else if (item.type === "BOOKING_ITEM_ID") {
        bookingItemId = item.id;
      } else if (item.type === "INVOICE_ID") {
        invoiceId = item.id;
      } else if (item.type === "BOOKING_ID") {
        tripId = item.id;
      }
    });
  }

  return {
    recordLocator,
    idContext,
    airItemId,
    bookingItemId,
    tripId,
    invoiceId,
  };
}

function mergeFormModelAboutContactInfo(
  dynamicPkgVerifyPriceFormModel,
  tripContactInfo
) {
  const passengers = dynamicPkgVerifyPriceFormModel.passengers;

  if (passengers && passengers.length > 0 && tripContactInfo) {
    passengers.forEach((passenger) => {
      if (!passenger.phoneNumber) {
        passenger.phoneNumber = tripContactInfo.phoneNumber;
        passenger.countryAccessCode = tripContactInfo.countryAccessCode;
        passenger.dialAreaCode = tripContactInfo.dialAreaCode;
      }
      if (!passenger.emailAddress) {
        passenger.emailAddress = tripContactInfo.emailAddress;
      }
    });
  }

  return dynamicPkgVerifyPriceFormModel;
}

function parseDynamicPkgVerifyPriceFormModel(query) {
  const languageCode = query.languageCode;
  const referenceId = query.referenceId;
  const airItineraryRPH = query.airItineraryRPH;
  const checkInDate = query.checkInDate;
  const checkOutDate = query.checkOutDate;
  const roomTypeName = query.roomTypeName;
  const bedTypeName = query.bedTypeName;
  const originLocationCode = query.originLocationCode;
  const destinationLocationCode = query.destinationLocationCode;
  const roomPassengersFormat = query.roomPassengersFormat;
  const travelerRefNumbersFormat = query.travelerRefNumbersFormat;
  const roomPassengers =
    dynamicPkgCommonTools.parseRoomPassengers(roomPassengersFormat);

  const travelerRefNumbers = dynamicPkgCommonTools.parseSimpleArray(
    travelerRefNumbersFormat
  );

  const dynamicPkgVerifyPriceFormModel = new DynamicPkgVerifyPriceFormModel(
    languageCode,
    referenceId,
    airItineraryRPH,
    originLocationCode,
    destinationLocationCode,
    checkInDate,
    checkOutDate,
    null,
    travelerRefNumbers
  );
  let containPrimaryGuest = false;
  // let guestRPH = 0;
  roomPassengers.forEach((roomPassenger) => {
    //添加房间
    const roomResGuestFormItem =
      dynamicPkgVerifyPriceFormModel.addNewRoomResGuest(
        referenceId,
        roomTypeName,
        bedTypeName
      );
    const adultNum = roomPassenger.adultNum;
    const childAges = roomPassenger.childAges;

    if (adultNum) {
      let containMain = false;
      for (let i = 0; i < adultNum; i++) {
        //添加住客
        const resGuest = roomResGuestFormItem.addNewResGuest(
          null,
          null,
          "10",
          30
        );
        if (!containMain) {
          resGuest.mainIndicator = true;
          containMain = true;
        }
        if (!containPrimaryGuest) {
          resGuest.primaryIndicator = true;
          containPrimaryGuest = true;
        }
        // guestRPH++;
      }
    }
    if (childAges && childAges.length > 0) {
      for (const childAge of childAges) {
        const age = childAge.age;
        roomResGuestFormItem.addNewResGuest(null, null, "8", age);
        // guestRPH++;
      }
    }
  });
  return dynamicPkgVerifyPriceFormModel;
}

function goToHotelDetails(query) {
  if (query.referenceId) delete query.referenceId;
  if (query.airSearchType) delete query.airSearchType;
  if (query.airItineraryRPH) delete query.airItineraryRPH;
  if (query.fareSegmentBasisCodesFormat)
    delete query.fareSegmentBasisCodesFormat;
  router.push({
    name: "sales-dynamicPkg-hotel-details-v2",
    query: query,
  });
}

function goToFlightList(query) {
  if (query.airSearchType) delete query.airSearchType;
  if (query.airItineraryRPH) delete query.airItineraryRPH;
  if (query.fareSegmentBasisCodesFormat)
    delete query.fareSegmentBasisCodesFormat;
  router.push({
    name: "sales-dynamicPkg-flight-list-v2",
    query: query,
  });
}

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.PackageBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.PackageBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("check-out") }}</h2>
        <ul>
          <li class="pre-page" @click="router.push({ name: 'home' })">
            {{ $t("home") }}
          </li>
          <li class="pre-page" @click="goToHotelDetails(route.query)">
            {{ $t("hotel-details") }}
          </li>
          <li class="pre-page" @click="goToFlightList(route.query)">
            {{ $t("flight-list") }}
          </li>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("check-out") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goToFlightList(route.query)">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="tripPackageItemRef"
        class="col-9 reservation_details reservation-review"
      >
        <!-- hotel Info -->
        <HotelItinerarySummaryPanel
          :trip-hotel-item="tripPackageItemRef.tripHotelItem"
        />
        <!-- hotel Info END-->

        <!-- Itinerary Info -->
        <FlightItineraryInfoPanel
          :price-classes="priceClassesRef"
          :show-upsell="false"
          :trip-air-item="tripPackageItemRef.tripAirItem"
        />
        <!-- Itinerary Info END-->

        <!-- Contact Info Form -->
        <TripContactInfoForm :tripContactInfo="tripContactInfoRef" />
        <!-- Contact Info Form END-->

        <!-- Passenger Form -->
        <DynamicPkgFlightPassengerForm
          v-if="tripPackageItemRef.tripAirItem"
          :passenger-form-model-items="passengerFormModelItemsRef"
          :trip-air-item="tripPackageItemRef && tripPackageItemRef.tripAirItem"
        />
        <!-- Passenger Form END-->

        <!--Room  Passenger Form -->
        <DynamicPkgHotelCheckOutRoomPassengerForm
          v-if="tripPackageItemRef && tripPackageItemRef.tripHotelItem"
          :passenger-form-model-items="passengerFormModelItemsRef"
          :remarks-check-show="remarksCheckShowRef"
          :room-passenger-items="
            dynamicPkgVerifyPriceFormModelRef &&
            dynamicPkgVerifyPriceFormModelRef.roomResGuests
              ? dynamicPkgVerifyPriceFormModelRef.roomResGuests
              : []
          "
          :trip-hotel-item="tripPackageItemRef.tripHotelItem"
        />
        <!--Room Passenger Form END-->

        <!--   Policies     -->
        <HotelPoliciesPanel
          v-if="
            tripPackageItemRef.tripHotelItem &&
            tripPackageItemRef.tripHotelItem.policies &&
            tripPackageItemRef.tripHotelItem.policies.length > 0
          "
          :policies="tripPackageItemRef.tripHotelItem.policies"
          :title="$t('hotel') + ' ' + $t('policies')"
        />
        <!--   Policies   END  -->

        <!-- Penalty Options -->
        <FlightPenaltyOptionsPanel
          v-if="tripPackageItemRef.tripAirItem"
          :title="$t('flight') + ' ' + $t('penalty-options')"
          :trip-air-item-fare="tripPackageItemRef.tripAirItem.tripAirItemFare"
        />
        <!-- Penalty Options END -->

        <!-- Price Summary -->
        <DynamicPkgPriceSummaryOrBreakdownPanel
          :trip-package-item="tripPackageItemRef"
          @confirmBooking="
            confirmBooking(
              dynamicPkgVerifyPriceFormModelRef,
              tripContactInfoRef,
              tripPackageItemRef,
              passengerFormModelItemsRef
            )
          "
        />
        <!-- Price Summary  END -->
      </div>
    </div>
  </section>
</template>

<style scoped></style>

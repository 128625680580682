export class FlightFareOption {
  /**
   *
   * @param supplierCode string，机票供应商代码数组，例如，"SQ"
   * @param bookingSourceType string，机票来源系统，例如，"GDS"
   * @param priceClassId string，价格类型ID
   * @param prePriceClassIdsKey string，前置价格类型id组合键
   * @param preItineraryKey string，前置行程类型id组合键
   * @param originDestinationKey string，航班键值
   * @param currencyCode string，货币单位
   * @param totalPrice string，该航班所属行程的总价格
   * @param cabinTypes
   * @param negotiatedFareCode
   * @param bookingClassCodes
   * @param fareTypeCode
   * @param fareTypeName
   * @param fareBasisCodes
   * @param expirationDateTime
   * @param outOfPolicy boolean, CBT是否在权限之外
   * @param approvalRequired boolean，是否需要申请
   * @param upgradeAllowed
   * @param reasons
   * @param baggageAllowances
   * @param notes
   * @param segmentPriceClass
   * @param origFareIndicator
   * @param penaltyOptions
   * @param fareSegmentBasisCodes
   * @param travelerRefNumbers
   * @param fareSegmentSeatsRemainings 剩余的座位
   */
  constructor(
    supplierCode,
    bookingSourceType,
    priceClassId,
    prePriceClassIdsKey,
    preItineraryKey,
    originDestinationKey,
    currencyCode,
    totalPrice,
    cabinTypes,
    negotiatedFareCode,
    bookingClassCodes,
    fareTypeCode,
    fareTypeName,
    fareBasisCodes,
    expirationDateTime,
    outOfPolicy,
    approvalRequired,
    upgradeAllowed,
    reasons,
    baggageAllowances,
    notes,
    segmentPriceClass,
    origFareIndicator,
    penaltyOptions,
    fareSegmentBasisCodes,
    travelerRefNumbers,
    fareSegmentSeatsRemainings
  ) {
    this.supplierCode = supplierCode;
    this.bookingSourceType = bookingSourceType;
    this.priceClassId = priceClassId;
    this.prePriceClassIdsKey = prePriceClassIdsKey;
    this.preItineraryKey = preItineraryKey;
    this.originDestinationKey = originDestinationKey;
    this.currencyCode = currencyCode;
    this.totalPrice = totalPrice;
    this.cabinTypes = cabinTypes;
    this.negotiatedFareCode = negotiatedFareCode;
    this.bookingClassCodes = bookingClassCodes;
    this.baggageAllowances = baggageAllowances;
    this.fareTypeCode = fareTypeCode;
    this.fareTypeName = fareTypeName;
    this.fareBasisCodes = fareBasisCodes;
    this.expirationDateTime = expirationDateTime;
    this.outOfPolicy = outOfPolicy;
    this.approvalRequired = approvalRequired;
    this.upgradeAllowed = upgradeAllowed;
    this.reasons = reasons;
    this.notes = notes;
    this.segmentPriceClass = segmentPriceClass;
    this.origFareIndicator = origFareIndicator;
    this.penaltyOptions = penaltyOptions;
    this.fareSegmentBasisCodes = fareSegmentBasisCodes;
    this.travelerRefNumbers = travelerRefNumbers;
    this.fareSegmentSeatsRemainings = fareSegmentSeatsRemainings;
  }

  addFareBasisCode(fareBasisCode) {
    if (this.fareBasisCodes == null) this.fareBasisCodes = [];

    this.fareBasisCodes.push(fareBasisCode);
  }

  addFareSegmentBasisCode(fareSegmentBasisCode) {
    if (this.fareSegmentBasisCodes == null) this.fareSegmentBasisCodes = [];

    const flightRefNumber = fareSegmentBasisCode.flightRefNumber;
    const fareBasisCode = fareSegmentBasisCode.fareBasisCode;
    //如果不存在相同航段
    if (
      !this.fareSegmentBasisCodes.find(
        (item) => item.flightRefNumber === flightRefNumber
      )
    ) {
      this.fareSegmentBasisCodes.push(fareSegmentBasisCode);
      this.addFareBasisCode(fareBasisCode);
    }
  }

  addFareSegmentSeatsRemaining(segmentSeatsRemaining) {
    if (this.fareSegmentSeatsRemainings == null)
      this.fareSegmentSeatsRemainings = [];
    this.fareSegmentSeatsRemainings.push(segmentSeatsRemaining);
  }

  addBaggageAllowances(baggageAllowance) {
    if (this.baggageAllowances == null) this.baggageAllowances = [];
    if (this.baggageAllowances.indexOf(baggageAllowance) >= 0) return;

    this.baggageAllowances.push(baggageAllowance);
  }

  addBookingClassCode(bookingClassCode) {
    if (this.bookingClassCodes == null) this.bookingClassCodes = [];
    if (this.bookingClassCodes.indexOf(bookingClassCode) >= 0) return;

    this.bookingClassCodes.push(bookingClassCode);
  }

  addSegmentPriceClassID(flightSegmentRPH, priceClassID) {
    if (!this.segmentPriceClass) this.segmentPriceClass = [];
    this.segmentPriceClass.push({ flightSegmentRPH, bundleID: priceClassID });
  }

  addCabinType(cabinType) {
    if (this.cabinTypes == null) this.cabinTypes = [];

    if (this.cabinTypes.indexOf(cabinType) >= 0) return;

    this.cabinTypes.push(cabinType);
  }

  addNegotiatedFareCode(negotiatedFareCode) {
    this.negotiatedFareCode = negotiatedFareCode;
  }

  addPenaltyOption(penaltyOption) {
    if (this.penaltyOptions == null) this.penaltyOptions = [];
    this.penaltyOptions.push(penaltyOption);
  }
}

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useHotelStore } from "@/stores/hotel";
import { reactive, ref } from "vue";
import { useDynamicPackageStore } from "@/stores/dynamicPackage";
import BasePackageHotelRoomItem from "@/views/sales/package/items/group/BasePackageHotelRoomItem.vue";

const props = defineProps({
  roomNum: {
    type: Number,
  },
  duration: {
    type: Number,
  },
  roomTypeItem: {
    type: Object,
  },
  modelIndex: {},
  hotelViewItem: {},
});
//router相关
const router = useRouter();
const route = useRoute();
const dynamicPackageStore = useDynamicPackageStore();
const templateStore = useTemplateStore();
const hotelStore = useHotelStore();
templateStore.pageLoader({ mode: "on" });

let hotelViewItem = reactive(props.hotelViewItem);
/*
 * @param roomViewItem
 * @param roomQuantity 若用户未重新搜索，则从route.query中获取，若用户已重新搜索，则从hotelSearchFormModel中获取
 */
// eslint-disable-next-line no-unused-vars
function selectRoom(roomViewItem, roomQuantity, roomTypeViewItem) {
  // sessionStorage.clear();
  let wrapPkgRoomViewItem = {
    ...roomViewItem,
    roomNum: roomQuantity,
    roomTypeInfo: roomTypeViewItem,
    guestCounts: roomTypeViewItem.guestCounts,
    hotelViewItem: hotelViewItem,
    roomViewItems: roomTypeViewItem.rooms,
    roomViewItem: roomViewItem,
  };
  //房间相关
  sessionStorage.setItem(
    "wrapPkgRoomViewItem",
    JSON.stringify(wrapPkgRoomViewItem)
  );
  //机票相关
  // let matchFlightViewItemsGroup = dynamicPackageStore.matchFlightViewItemsGroup;
  // let flightViewItemsGroup = dynamicPackageStore.flightViewItemsGroup;
  // let pricedItineraries = dynamicPackageStore.pricedItineraries;
  // let origDestGroupMap = dynamicPackageStore.origDestGroupMap;
  // let airportCityMap = dynamicPackageStore.airportCityMap;
  // sessionStorage.setItem(
  //   "pkgMatchFlightViewItemsGroup",
  //   JSON.stringify(matchFlightViewItemsGroup)
  // );
  // sessionStorage.setItem(
  //   "pkgFlightViewItemsGroup",
  //   JSON.stringify(flightViewItemsGroup)
  // );
  // console.log(pricedItineraries);
  //
  // sessionStorage.setItem(
  //   "pkgPricedItineraries",
  //   JSON.stringify(pricedItineraries)
  // );
  // sessionStorage.setItem(
  //   "pkgOrigDestGroupMap",
  //   JSON.stringify(origDestGroupMap)
  // );
  // sessionStorage.setItem("pkgAirportCityMap", JSON.stringify(airportCityMap));
  sessionStorage.setItem("pkgAirSearchQuery", JSON.stringify(route.query));
  router.push({ name: "sales-package-flight-list" });
}

function formatChildAges(childAges) {
  let formatArr = [];
  childAges.forEach((childAge) => {
    let format = childAge.roomId + ":" + childAge.age;
    formatArr.push(format.toString());
  });
  return formatArr;
}

const isShow = ref(false);

function showMore() {
  isShow.value = !isShow.value;
}
</script>

<template>
  <!-- BaseHotelRoomTypeItem   -->
  <thead>
    <tr>
      <th>{{ $t("your-choice") }}</th>
      <th>{{ $t("sleeps") }}</th>
      <th>{{ $t("today-price") }}</th>
    </tr>
  </thead>

  <tbody>
    <BasePackageHotelRoomItem
      v-for="(room, roomIndex) in roomTypeItem.rooms"
      :key="roomIndex"
      :model-index="modelIndex + '-' + roomIndex"
      :room-index="roomIndex"
      :room-item="room"
      :room-num="roomNum"
      :room-type-item="roomTypeItem"
      :show-more="isShow"
      style="border-bottom: 1px solid #cccccc"
      @submit="
        (roomItem, roomNum) => selectRoom(roomItem, roomNum, roomTypeItem)
      "
    >
    </BasePackageHotelRoomItem>
    <tr>
      <td>
        <a
          v-if="!isShow && roomTypeItem.rooms.length > 2"
          style="
            color: #8b3eea;
            font-size: 18px;
            font-weight: bold;
            position: absolute;
            left: 45%;
          "
          @click="showMore"
          >Show More Room Rate
          <i class="fa fa-fw fa-angle-down" style="color: #8b3eea"></i
        ></a>
        <a
          v-show="isShow"
          style="
            color: #8b3eea;
            font-size: 18px;
            font-weight: bold;
            position: absolute;
            left: 45%;
          "
          @click="showMore"
          >Show less <i class="fa fa-fw fa-angle-up" style="color: #8b3eea"></i
        ></a>
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss" scoped></style>

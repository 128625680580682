<script setup>
import { onMounted, ref, watch } from "vue";

import { useTemplateStore } from "@/stores/template";
import { FlightShoppingSortFormModel } from "@/formmodel/sales/flight/shopping/flightShoppingSortFormModel";
import { FlightShoppingFilterFormModel } from "@/formmodel/sales/flight/shopping/flightShoppingFilterFormModel";
import flightSortTools from "@/tools/v2/flight/shopping/flightSortTools";
import flightFilterTools from "@/tools/v2/flight/shopping/flightFilterTools";
import { useRoute, useRouter } from "vue-router";
import { Drawer, Empty, Spin } from "ant-design-vue";
import { useAirContentStore } from "@/stores/v2/content/airContentStore";
import { useI18n } from "vue-i18n";

import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import dateUtils from "@/utils/dateUtils";
import { usePackageFlightUpsellStore } from "@/stores/v2/dynamicPkg/availability/packageFlightUpsellStore";
import { useSecurityStore } from "@/stores/security";
import FlightShoppingFilterAndSortForm from "@/views/v2/sales/flight/items/form/shopping/FlightShoppingFilterAndSortForm.vue";

import DynamicPkgFlightFareOptionsSelectPanel from "@/views/v2/sales/dynamicpkg/items/panel/DynamicPkgFlightFareOptionsSelectPanel.vue";
import BaseFlightListItem from "@/views/v2/sales/flight/items/group/BaseFlightListItem.vue";

const route = useRoute();
const router = useRouter();

const ADrawer = Drawer;
const AEmpty = Empty;
const { t, locale } = useI18n(); // 解构调用函数
const templateStore = useTemplateStore();
const packageFlightUpsellStore = usePackageFlightUpsellStore();
const airContentStore = useAirContentStore();
const securityStore = useSecurityStore();

airContentStore.findAircraftsMap();
airContentStore.findAirlineMap();

const fareOptionShowRef = ref(false);

const flightItemsShowRef = ref(null);
const flightItemsShowLengthRef = ref(null);

const itineraryArrangementRef = ref(null);

const airlineItemMapRef = ref(null);
const aircraftItemMapRef = ref(null);
const airportCityMapRef = ref(null);

//过滤排序相关
const shoppingSortFormModelRef = ref(
  new FlightShoppingSortFormModel("PRICE", "ASC")
);
const shoppingFilterFormModelRef = ref(
  new FlightShoppingFilterFormModel([], [], [], [], [], [], [])
);
const filterOptionsRef = ref(null);
const combineKeys = ref([]); //更新flightItemsShowRef相关
const flightGroupInfosRef = ref([]);
const activeFlightGroupNumberRef = ref(
  route.query.tripSequenceNumber ? parseInt(route.query.tripSequenceNumber) : 1
); //记录当前行程序号
const preFareOptionPriceRef = ref(route.query.preFareOptionPrice);
const fareKeyRef = ref(null);
const specificFlightFareOptionsRef = ref(null);
const fareOptionsSelectedRef = ref([]);

if (sessionStorage.getItem("dynamicPkgAvailFormModel")) {
  const dynamicPkgAvailFormModel = JSON.parse(
    sessionStorage.getItem("dynamicPkgAvailFormModel")
  );
  packageFlightUpsellStore.saveShoppingFormModal(dynamicPkgAvailFormModel);
}

if (sessionStorage.getItem("flightGroupInfos")) {
  flightGroupInfosRef.value = JSON.parse(
    sessionStorage.getItem("flightGroupInfos")
  );
}

//#region pinia监听，数据获取
watch(
  () => packageFlightUpsellStore.flightItemsShow,
  (newValue) => {
    flightItemsShowRef.value = flightSortTools.sortFlightItems(
      newValue,
      shoppingSortFormModelRef.value
    );

    if (flightItemsShowRef.value && flightItemsShowRef.value.length > 0) {
      flightItemsShowLengthRef.value = flightItemsShowRef.value.length;
    }
  },
  { immediate: true, deep: true }
);
watch(
  () => packageFlightUpsellStore.filterOptions,
  (newValue) => {
    filterOptionsRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watch(
  () => packageFlightUpsellStore.itineraryArrangement,
  (newValue) => {
    if (newValue) {
      itineraryArrangementRef.value = newValue.flights;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => packageFlightUpsellStore.flightFareOptionsShow,
  (newValue) => {
    if (newValue) {
      specificFlightFareOptionsRef.value = newValue;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => airContentStore.airlineItemMap,
  (newValue) => {
    airlineItemMapRef.value = newValue;
  },
  { immediate: true, deep: true }
);
watch(
  () => airContentStore.aircraftItemMap,
  (newValue) => {
    aircraftItemMapRef.value = newValue;
  },
  { immediate: true, deep: true }
);
watch(
  () => packageFlightUpsellStore.airportCityMap,
  (newValue) => {
    airportCityMapRef.value = newValue;
  },
  { immediate: true, deep: true }
);
//endregion

const showMaxIndex = ref(10);
const isLoadMore = ref(true);

function resetShowMore() {
  showMaxIndex.value = 10;
  isLoadMore.value = true;
}

function loadMoreItems() {
  showMaxIndex.value += 10;
  if (showMaxIndex.value >= flightItemsShowLengthRef.value) {
    isLoadMore.value = false;
  }
}

function mouseover() {
  let over = null;
  console.info("over");
}

//#region 过滤排序总入口
function sortAndFilter(sortFormModel, filterFormModel) {
  const flightItemsShowCopy = JSON.parse(
    JSON.stringify(packageFlightUpsellStore.flightItemsShow)
  );
  if (filterFormModel) {
    shoppingFilterFormModelRef.value = filterFormModel;
    flightItemsShowRef.value = flightFilterTools.filterFlightItems(
      flightItemsShowCopy,
      shoppingFilterFormModelRef.value
    );
  }

  if (sortFormModel) {
    shoppingSortFormModelRef.value = sortFormModel;
    flightItemsShowRef.value = flightSortTools.sortFlightItems(
      flightItemsShowRef.value,
      shoppingSortFormModelRef.value
    );
  }

  if (flightItemsShowRef.value && flightItemsShowRef.value.length > 0) {
    flightItemsShowLengthRef.value = flightItemsShowRef.value.length;
  }
  resetShowMore();
}

//#endregion

//#region 辅助函数

//显示指定飞行顺序的航班列表
function goToSpecificFlightGroup(
  changeType,
  tripSequenceNumber,
  itineraryArrangement,
  fareOptionsSelected,
  flightGroupInfos
) {
  //完成所有航班Fare Options的选择
  const fareOptionSelected = fareOptionsSelected.find(
    (item) => item && item.originDestinationKey
  );
  if (tripSequenceNumber === itineraryArrangement.length) {
    const airItineraryRPH =
      packageFlightUpsellStore.findItineraryRPHWithFinalPrice(
        fareOptionSelected
      );

    let travelerRefNumbersFormat = null;
    if (fareOptionSelected)
      travelerRefNumbersFormat = formatSimpleArray(
        fareOptionSelected.travelerRefNumbers
      );

    const query = route.query;
    query.airItineraryRPH = airItineraryRPH;
    query.travelerRefNumbersFormat = travelerRefNumbersFormat;
    delete query.airSearchType;
    const carChoose = JSON.parse(query.carChoose);
    if (carChoose) {
      // 跳转到carRental
      router.push({
        name: "sales-dynamicPkg-car-rental-list-v2",
        query: query,
      });
    } else {
      //跳转到checkout
      router.push({
        name: "sales-dynamicPkg-check-out-v2",
        query: query,
      });
    }
  }
  //通过change flight，跳转至Flight options
  else if (changeType === "CHANGE" && tripSequenceNumber >= 0) {
    const specificFlightGroupInfo = flightGroupInfos[tripSequenceNumber];
    combineKeys.value = specificFlightGroupInfo.combineKey;
    clearFlightAndFareOptionSelected(tripSequenceNumber);

    const queryInfo = flightGroupInfos[tripSequenceNumber].queryInfo;
    if (queryInfo) router.replace({ query: queryInfo });

    activeFlightGroupNumberRef.value = tripSequenceNumber;
    fareOptionShowRef.value = false;
  }
  //选择完Fare options，跳转至下一航班Flight options
  else if (changeType === "UPSELL") {
    const upsellShoppingQueryModel = buildUpsellShoppingQueryModel(
      flightGroupInfos,
      fareOptionsSelected,
      route.query,
      tripSequenceNumber
    );

    router.replace({ query: upsellShoppingQueryModel });
  }

  clearFlightSortAndFilterConditions();
  //划到顶部
  window.scrollTo(0, 0);
}

function buildUpsellShoppingQueryModel(
  flightGroupInfos,
  fareOptionsSelected,
  routeQuery,
  tripSequenceNumber
) {
  let queryFormModal = {
    airSearchType: "UPSELL",
    fareSegmentBasisCodes: [],
    airItineraryRPH: null,
  };

  const fareOptionSelected = fareOptionsSelected.find(
    (item) => item && item.fareSegmentBasisCodes
  );

  flightGroupInfos.forEach((flightGroupInfo) => {
    const fareOptionSelected = flightGroupInfo.fareOptionSelected;

    if (fareOptionSelected) {
      const fareSegmentBasisCodes = fareOptionSelected.fareSegmentBasisCodes;
      queryFormModal.fareSegmentBasisCodes.push(...fareSegmentBasisCodes);
    }
  });
  queryFormModal.airItineraryRPH =
    packageFlightUpsellStore.findItineraryRPHWithPreItinKey(fareOptionSelected);
  queryFormModal.fareSegmentBasisCodes.push(
    ...fareOptionSelected.fareSegmentBasisCodes
  );
  const upsellShoppingQueryModel = buildBasicShoppingQueryModel(queryFormModal);

  flightGroupInfosRef.value[activeFlightGroupNumberRef.value].queryInfo =
    routeQuery;

  sessionStorage.setItem(
    "flightGroupInfos",
    JSON.stringify(flightGroupInfosRef.value)
  );

  Object.keys(routeQuery).forEach(
    (key) => (upsellShoppingQueryModel[key] = routeQuery[key])
  );

  upsellShoppingQueryModel.tripSequenceNumber = tripSequenceNumber;
  upsellShoppingQueryModel.preFareOptionPrice = fareOptionSelected.totalPrice;
  upsellShoppingQueryModel.airItineraryRPH = queryFormModal.airItineraryRPH;
  upsellShoppingQueryModel.fareSegmentBasisCodes =
    queryFormModal.fareSegmentBasisCodes;
  upsellShoppingQueryModel.fareSegmentBasisCodesFormat = formatSimpleArray(
    queryFormModal.fareSegmentBasisCodes
  );

  return upsellShoppingQueryModel;
}

//查看Fare options
function viewFareOptions(
  currentTripSequenceNumber,
  itineraryArrangement,
  fareOptionsSelected,
  originDestinationKey,
  supplierCodes,
  bestTotalPrice,
  flightInfo,
  fareIndex
) {
  fareKeyRef.value = currentTripSequenceNumber + "_" + fareIndex;
  if (!combineKeys.value) combineKeys.value = [];

  combineKeys.value.push(originDestinationKey);
  //记录当前选择航班
  flightGroupInfosRef.value[currentTripSequenceNumber].flightInfo = flightInfo;
  flightGroupInfosRef.value[currentTripSequenceNumber].marketingAirline =
    flightInfo.flightSegments[0].marketingAirlineCode;
  //存储下一航班所需过滤条件
  const nextTripSequenceNumber = currentTripSequenceNumber + 1;
  if (currentTripSequenceNumber < itineraryArrangement.length - 1) {
    const specificFlightGroupInfo =
      flightGroupInfosRef.value[nextTripSequenceNumber];
    specificFlightGroupInfo.supplierCodes = supplierCodes;

    specificFlightGroupInfo.combineKey = combineKeys.value;

    specificFlightGroupInfo.marketingAirline =
      flightInfo.flightSegments[0].marketingAirlineCode;
  }

  packageFlightUpsellStore.showUpsellOptionsShow(originDestinationKey);
  fareOptionShowRef.value = true;
}

//清除指定飞行顺序后的所有已选择的航班
function clearFlightAndFareOptionSelected(activeFlightGroupNumber) {
  fareOptionsSelectedRef.value.splice(
    activeFlightGroupNumber,
    fareOptionsSelectedRef.value.length - activeFlightGroupNumber
  );
  for (
    let i = activeFlightGroupNumber + 1;
    i < flightGroupInfosRef.value.length;
    i++
  ) {
    const specificFlightGroupInfo =
      flightGroupInfosRef.value[activeFlightGroupNumber];
    specificFlightGroupInfo.flightInfo = null;
  }
}

function clearFlightSortAndFilterConditions() {
  shoppingSortFormModelRef.value = new FlightShoppingSortFormModel(
    "PRICE",
    "ASC"
  );
  shoppingFilterFormModelRef.value = new FlightShoppingFilterFormModel(
    [],
    [],
    [],
    [],
    [],
    [],
    []
  );
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

function handleScroll() {
  const distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;
  if (distance > -2 && distance < 2) {
    loadMoreItems();
  }
}

function closeFareOptions() {
  fareOptionShowRef.value = false;
}

function getFlightEmptyLabel(flightShoppingFormModel) {
  let label = t("no-flights");
  if (flightShoppingFormModel) {
    const originLocationName = flightShoppingFormModel.originLocationName;
    const destinationLocationName =
      flightShoppingFormModel.destinationLocationName;
    const departureDate = flightShoppingFormModel.checkOutDate;
    label = t("no-flight-in-origin-des", {
      originLocationName: destinationLocationName,
      destinationLocationName: originLocationName,
      month: departureDate
        ? dateUtils.formatEnglishDate(departureDate).month
        : null,
      date: departureDate
        ? dateUtils.formatEnglishDate(departureDate).ddate
        : null,
    });
  }

  return label;
}

//#endregion

function buildBasicShoppingQueryModel(formModel) {
  const shoppingQueryModel = {};

  for (const key of Object.keys(formModel)) {
    const value = formModel[key];

    if (typeof value !== "string") continue;
    shoppingQueryModel[key] = formModel[key];
  }

  return shoppingQueryModel;
}

function formatSimpleArray(array) {
  let format = "";
  if (array == null || array.length === 0) return format;

  for (const ele of array) {
    //遍历数组中的每一项
    if (!ele || (typeof ele === "object" && Object.keys(ele) === 0)) {
      continue;
    }

    if (typeof ele === "string") {
      format += ele; //如果是字符串数组则直接与元素分隔符拼接
    } else {
      for (const key of Object.keys(ele)) {
        if (!key) continue;
        const value = ele[key] ? ele[key] : "";
        if (!value) continue;
        if (format !== "") format += "@"; //变量分隔符
        format += key + "=" + value;
      }
    }
    format += "||"; //元素分隔符
  }
  return format;
}
</script>

<template>
  <div class="list-or-fare-show">
    <!--行程导航显示-->
    <div class="row">
      <div class="col-12">
        <div class="flight-list-navigation p-3 font-14">
          <div
            v-if="itineraryArrangementRef && itineraryArrangementRef.length > 0"
            class="flex align-items-center"
          >
            <div class="flex list-navigation-items justify-content-start">
              <div
                v-for="(
                  originDesItem, originDesIndex
                ) in itineraryArrangementRef"
                :key="originDesIndex"
                class="list-nav-item mg-r-8 flex cursor-point"
              >
                <div class="flex flex-column">
                  <div
                    :class="{
                      font_weight:
                        activeFlightGroupNumberRef === originDesIndex &&
                        !fareOptionShowRef,
                      'text-muted':
                        activeFlightGroupNumberRef !== originDesIndex,
                    }"
                  >
                    <div
                      v-if="
                        originDesIndex < activeFlightGroupNumberRef ||
                        (originDesIndex === activeFlightGroupNumberRef &&
                          fareOptionShowRef)
                      "
                    >
                      <div>
                        {{
                          flightCommonTools.getAirlineName(
                            locale,
                            flightGroupInfosRef[originDesIndex]
                              .marketingAirline,
                            airlineItemMapRef
                          )
                        }}
                        · {{ originDesItem.originLocationCode }} ->
                        {{ originDesItem.destinationLocationCode }}
                      </div>
                      <span
                        v-if="
                          (fareOptionShowRef &&
                            originDesIndex <= activeFlightGroupNumberRef) ||
                          (!fareOptionShowRef &&
                            originDesIndex < activeFlightGroupNumberRef)
                        "
                        class="text-primary"
                        @click="
                          goToSpecificFlightGroup(
                            'CHANGE',
                            originDesIndex,
                            itineraryArrangementRef,
                            fareOptionsSelectedRef,
                            flightGroupInfosRef
                          )
                        "
                        >{{ $t("change-flight") }}</span
                      >
                    </div>
                    <div
                      v-else-if="
                        originDesIndex === activeFlightGroupNumberRef &&
                        !fareOptionShowRef
                      "
                    >
                      {{
                        $t("choose-flight-to", {
                          locationName: flightCommonTools.getAirportCityName(
                            locale,
                            itineraryArrangementRef[originDesIndex]
                              .destinationLocationCode,
                            airportCityMapRef
                          ),
                        })
                      }}
                    </div>
                    <div
                      v-if="
                        originDesIndex > activeFlightGroupNumberRef &&
                        !fareOptionShowRef
                      "
                    >
                      {{
                        $t("choose-flight-to", {
                          locationName: flightCommonTools.getAirportCityName(
                            locale,
                            originDesItem.destinationLocationCode,
                            airportCityMapRef
                          ),
                        })
                      }}
                    </div>
                  </div>
                </div>
                <i
                  v-if="
                    (originDesIndex <= activeFlightGroupNumberRef &&
                      fareOptionShowRef) ||
                    (originDesIndex < itineraryArrangementRef.length - 1 &&
                      !fareOptionShowRef)
                  "
                  class="fa-solid fa-angle-right mg-t-3 mg-l-8"
                ></i>
                <div
                  v-if="
                    fareOptionShowRef &&
                    activeFlightGroupNumberRef === originDesIndex
                  "
                  :class="{
                    font_weight: fareOptionShowRef,
                  }"
                  class="mg-l-8"
                >
                  {{ $t("fare-options") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--行程导航显示END-->

    <!-- 过滤与机票列表 -->
    <div class="row">
      <div
        v-if="shoppingFilterFormModelRef && shoppingSortFormModelRef"
        class="col-3"
      >
        <FlightShoppingFilterAndSortForm
          :active-flight-group-info="
            itineraryArrangementRef && itineraryArrangementRef.length
              ? itineraryArrangementRef[activeFlightGroupNumberRef]
              : {}
          "
          :filterOptions="filterOptionsRef"
          :flightItemsLength="flightItemsShowLengthRef"
          :shoppingFilterFormModel="shoppingFilterFormModelRef"
          :shoppingSortFormModel="shoppingSortFormModelRef"
          @sortAndFilter="sortAndFilter"
        />
      </div>
      <!-- 机票列表-->
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-12">
            <div
              class="flight_list"
              v-if="flightItemsShowRef && flightItemsShowRef.length > 0"
            >
              <div
                v-for="(flightItem, flightIndex) in flightItemsShowRef"
                :key="flightIndex"
              >
                <BaseFlightListItem
                  :class="{
                    'flight-active':
                      fareKeyRef ===
                      activeFlightGroupNumberRef + '_' + flightIndex,
                  }"
                  v-if="flightIndex < showMaxIndex"
                  :aircraft-item-map="aircraftItemMapRef"
                  :airline-item-map="airlineItemMapRef"
                  :airport-city-map="airportCityMapRef"
                  :flight-info="flightItem"
                  :flight-info-index="flightIndex"
                  :previous-option-price="
                    flightGroupInfosRef[activeFlightGroupNumberRef] &&
                    flightGroupInfosRef[activeFlightGroupNumberRef]
                      .bestTotalPrice
                      ? flightGroupInfosRef[activeFlightGroupNumberRef]
                          .bestTotalPrice
                      : null
                  "
                  @viewFareOptions="
                    (
                      originDestinationKey,
                      supplierCodes,
                      bestTotalPrice,
                      flightInfo
                    ) =>
                      viewFareOptions(
                        activeFlightGroupNumberRef,
                        itineraryArrangementRef,
                        fareOptionsSelectedRef,
                        originDestinationKey,
                        supplierCodes,
                        bestTotalPrice,
                        flightInfo,
                        flightIndex
                      )
                  "
                />
              </div>
            </div>

            <div class="text-center" v-else>
              <AEmpty
                :description="
                  getFlightEmptyLabel(
                    packageFlightUpsellStore.dynamicPkgAvailFormModel
                  )
                "
              ></AEmpty>
            </div>

            <div
              class="load_more_flight"
              v-if="
                flightItemsShowRef &&
                flightItemsShowRef.length > 15 &&
                isLoadMore
              "
            >
              <Spin></Spin>
            </div>
          </div>
        </div>
      </div>
      <!-- 机票列表END-->
    </div>
    <!-- 过滤与机票列表END -->

    <!-- Fare Options -->
    <div class="fare-options-fixed" v-if="fareOptionShowRef">
      <DynamicPkgFlightFareOptionsSelectPanel
        :active-flight-group-number="activeFlightGroupNumberRef"
        :fare-option-selected="fareOptionsSelectedRef"
        :fare-options="specificFlightFareOptionsRef"
        :flight-info="
          flightGroupInfosRef[activeFlightGroupNumberRef]
            ? flightGroupInfosRef[activeFlightGroupNumberRef].flightInfo
            : null
        "
        :previous-option-price="preFareOptionPriceRef"
        :price-classes="packageFlightUpsellStore.priceClasses"
        @closeFareOptions="closeFareOptions"
        @goToSpecificFlightGroup="
          (nextFlightGroupNumber) =>
            goToSpecificFlightGroup(
              'UPSELL',
              nextFlightGroupNumber,
              itineraryArrangementRef,
              fareOptionsSelectedRef,
              flightGroupInfosRef
            )
        "
      />
    </div>
    <!-- Fare Options END -->
  </div>
</template>

<style scoped lang="scss">
.list-or-fare-show {
  .flight-active {
    border: 1px solid #8b3eea;
  }

  .fare-options-fixed {
    width: 60%;
    height: 100vh;
    position: fixed;
    z-index: 111111;
    right: 0;

    top: 0;
    overflow-y: scroll;
    margin-bottom: 100px;
  }
}
</style>

import HotelBookingPreviewView from "@/views/sales/hotel/HotelBookingPreviewView.vue";

const HotelHomeView = () => import("@/views/sales/hotel/HotelHomeView.vue");
const HotelListView = () => import("@/views/sales/hotel/HotelListView.vue");
const HotelDetailsView = () =>
  import("@/views/sales/hotel/HotelDetailsView.vue");
const HotelPhotosView = () => import("@/views/sales/hotel/HotelPhotosView.vue");
const HotelPassengersView = () =>
  import("@/views/sales/hotel/HotelPassengersView.vue");
export default [
  {
    path: "/sales/hotel",
    redirect: "/sales/hotel/hotel-home",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "hotel-home",
        name: "sales-hotel-home",
        meta: { requiresAuth: false },
        component: HotelHomeView,
      },
      {
        path: "hotel-list",
        name: "sales-hotel-list",
        component: HotelListView,
        meta: { requiresAuth: false, footerShow: false },
      },
      {
        path: "hotel-details",
        name: "sales-hotel-details",
        component: HotelDetailsView,
        meta: { requiresAuth: false },
      },
      {
        path: "hotel-passengers",
        name: "sales-hotel-passengers",
        component: HotelPassengersView,
        meta: { requiresAuth: true },
      },
      {
        path: "hotel-booking-preview",
        name: "sales-hotel-booking-preview",
        component: HotelBookingPreviewView,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/sales/content",
    redirect: "/sales/content/hotel-photos",
    children: [
      {
        path: "hotel-photos",
        name: "content-hotel-photos",
        component: HotelPhotosView,
        meta: { requiresAuth: false },
      },
    ],
  },
];

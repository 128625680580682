import axios from "@/utils/axiosUtils";

function getDocumentTypes() {
  let url = "/api/v3/party/documenttypes";
  return axios.get(url);
}

function enumerationOptions(enumTypeId) {
  let url = "/api/v3/common/enumeration/enums?enumTypeId=" + enumTypeId;
  return axios.get(url);
}

export default {
  getDocumentTypes,
  enumerationOptions,
};

import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router/modulesRouter";

import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "bootstrap"; // Template components
import BaseBlock from "@/components/custom/BaseBlock.vue"; // Main CSS Add
import "./assets/app.css";
import { pinia } from "@/stores";
import { useSecurityStore } from "@/stores/security";
import securityApi from "@/apis/securityApi";
import i18n from "@/i18n"; // Bootstrap framework
import * as bootstrap from "bootstrap";
import { useCmsContentStore } from "@/stores/cms";
import vue3GoogleLogin from "vue3-google-login";

window.bootstrap = bootstrap;

let app = createApp(App);
// Register global components
app.component("BaseBlock", BaseBlock);
// Use Pinia
app.use(pinia);

//User i18n
app.use(i18n);

//google login

//获取谷歌客户端id
let clientId =
  // "764595691158-9m3agl8de3n9jstvpkcd6ehd2s6av03u.apps.googleusercontent.com"; //这里替换成你自己的谷歌客户端id
  "579414133307-m40i8nqseta8514jdlnn38ppq5arqvb1.apps.googleusercontent.com"; //这里替换成你自己的谷歌客户端id
//使用谷歌登录插件
app.use(vue3GoogleLogin, {
  clientId,
});

//facebook

//Vue Router
const securityStore = useSecurityStore(pinia);
const cmsStore = useCmsContentStore(pinia);

router.beforeEach(async (to, from) => {
  let query = from.query;
  query.routeName = from.name;
  query.routePath = from.path;

  //保存路由信息
  if (to.name === "security-sign-in" && from.name !== "security-sign-in") {
    sessionStorage.setItem("routeInfo", JSON.stringify(query));
    sessionStorage.setItem("previousURL", window.location.href);
  }

  if (
    to.meta.requiresAuth &&
    to.name !== "security-sign-in" &&
    (securityStore.userInfo == null || securityStore.userInfo === "")
  ) {
    const { data } = await securityApi.getSessionInfo();
    securityStore.userInfo = data;
    if (securityStore.userInfo == null || securityStore.userInfo === "") {
      securityStore.$reset();
      sessionStorage.removeItem("user");
      return { name: "security-sign-in", query: query };
    } else {
      sessionStorage.setItem("user", JSON.stringify(data));
    }
  }
  const baseUrl = "/api/v3/content/collections/images";
  if (!cmsStore.websiteInfo) {
    cmsStore.getWebsiteInfoContent().then((res) => {
      const webInfo = res;

      if (webInfo) {
        document.title = webInfo.websiteSummaryTitle
          ? webInfo.websiteSummaryTitle
          : "CustomerPortal";
        document
          .querySelector('meta[name="description"]')
          .setAttribute(
            "content",
            webInfo.websiteSummaryDescription
              ? webInfo.websiteSummaryDescription
              : "CustomerPortal"
          );
        document
          .querySelector('link[rel="icon"]')
          .setAttribute(
            "href",
            webInfo.websiteSummaryFavicon
              ? baseUrl + webInfo.websiteSummaryFavicon.url
              : ""
          );
      }
    });
  } else {
    const webInfo = cmsStore.websiteInfo;
    document.title = webInfo.websiteSummaryTitle
      ? webInfo.websiteSummaryTitle
      : "CustomerPortal";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        webInfo.websiteSummaryDescription
          ? webInfo.websiteSummaryDescription
          : "CustomerPortal"
      );
    document
      .querySelector('link[rel="icon"]')
      .setAttribute(
        "href",
        webInfo.websiteSummaryFavicon
          ? baseUrl + webInfo.websiteSummaryFavicon.url
          : ""
      );
  }
});
app.use(router);

app.mount("#app");

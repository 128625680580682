import { defineStore } from "pinia";
import hotelFilterTools from "@/tools/v2/hotel/availability/hotelFilterTools";
import hotelSortTools from "@/tools/v2/hotel/availability/hotelSortTools";
import { HotelItemsSortFormModel } from "@/formmodel/sales/hotel/availability/hotelItemsSortFormModel";
import dynamicPkgHotelAvailTools from "@/tools/v2/dynamicPkg/availability/dynamicPkgHotelAvailTools";
import dynamicPkgAvailApi from "@/apis/v2/dynamicPkg/dynamicPkgAvailApi";
import hotelDescriptiveInfoTools from "@/tools/v2/hotel/availability/hotelDescriptiveInfoTools";
import hotelAvailabilityApi from "@/apis/v2/hotel/hotelAvailabilityApi";

export const usePackageHotelAvailStore = defineStore("packageHotelAvailStore", {
  state: () => ({
    dynamicPkgAvailFormModel: null,
    hotelItems: null,
    hotelItemsMatch: null,
    totalResult: 0,
    hotelItemsShow: null,
    filterOptions: null,
    limit: 6, //每页限制条数
    currentPage: 1,
    pageSize: 0, //总页数
  }),
  actions: {
    hotelAvail(dynamicPkgAvailFormModel) {
      const hotelAvailRQ =
        dynamicPkgHotelAvailTools.buildDynamicPkgHotelAvailRQ(
          dynamicPkgAvailFormModel
        );
      const ttiCode = dynamicPkgAvailFormModel.ttiCode;
      const languageCode = dynamicPkgAvailFormModel.languageCode;
      const hotelDescriptiveRQ =
        hotelDescriptiveInfoTools.buildHotelDescriptiveRQ(
          ttiCode,
          languageCode
        );
      const thisRef = this;
      thisRef.dynamicPkgAvailFormModel = dynamicPkgAvailFormModel;
      if (dynamicPkgAvailFormModel.locationType === "HOTEL") {
        //详情页面

        //描述信息
        const hotelDescriptiveRSP = hotelAvailabilityApi
          .descriptive(hotelDescriptiveRQ)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.error(err);
          });

        //酒店信息
        const hotelAvailRSP = dynamicPkgAvailApi
          .dynamicPkgAvail(hotelAvailRQ)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.error(err);
          });

        return Promise.all([hotelDescriptiveRSP, hotelAvailRSP]).then((res) => {
          const hotelDescriptiveRS = res[0]; //酒店详情返回结果
          const searchResultRS = res[1];

          if (
            searchResultRS &&
            searchResultRS.searchResults &&
            searchResultRS.searchResults.hotelResults &&
            searchResultRS.searchResults.hotelResults.length > 0
          ) {
            const hotelAvailRS = searchResultRS.searchResults.hotelResults[0];
            thisRef.hotelItems = dynamicPkgHotelAvailTools.buildHotelItems(
              hotelAvailRS,
              hotelDescriptiveRS
            );
          }
        });
      } else {
        //列表页面
        return dynamicPkgAvailApi.dynamicPkgAvail(hotelAvailRQ).then((res) => {
          const searchResultRS = res.data;
          if (
            searchResultRS &&
            searchResultRS.searchResults &&
            searchResultRS.searchResults.hotelResults &&
            searchResultRS.searchResults.hotelResults.length > 0
          ) {
            const hotelAvailRS = searchResultRS.searchResults.hotelResults[0];

            thisRef.hotelItems =
              dynamicPkgHotelAvailTools.buildHotelItems(hotelAvailRS);
            if (!thisRef.hotelItems) thisRef.hotelItems = [];
            thisRef.pageSize = Math.ceil(
              thisRef.hotelItems.length / thisRef.limit
            );
            thisRef.totalResult = thisRef.hotelItems.length;
            if (thisRef.hotelItems && thisRef.hotelItems.length > 0) {
              thisRef.hotelItems = hotelSortTools.sortHotelItems(
                thisRef.hotelItems,
                new HotelItemsSortFormModel("PRICE", "ASC")
              );
            }
            thisRef.hotelItemsShow =
              dynamicPkgHotelAvailTools.findSpecificHotelItems(
                thisRef.hotelItems,
                1,
                thisRef.limit
              );

            thisRef.hotelItemsMatch = thisRef.hotelItems;
          }
        });
      }
    },
    changeHotelItemsShow(
      currentPage,
      isLoadMore,
      hotelItemsFilterFormModel,
      hotelItemsSortFormModel
    ) {
      const thisRef = this;
      if (isLoadMore) {
        thisRef.currentPage = currentPage;
        thisRef.hotelItemsShow =
          dynamicPkgHotelAvailTools.findSpecificHotelItems(
            thisRef.hotelItemsMatch,
            currentPage,
            thisRef.limit
          );
      } else {
        thisRef.currentPage = 1;
        let filterHotelItemsMatch = [];
        if (hotelItemsFilterFormModel) {
          filterHotelItemsMatch = hotelFilterTools.filterHotelItems(
            thisRef.hotelItems,
            hotelItemsFilterFormModel
          );
        }

        if (hotelItemsSortFormModel) {
          filterHotelItemsMatch = hotelSortTools.sortHotelItems(
            filterHotelItemsMatch,
            hotelItemsSortFormModel
          );
        }
        thisRef.hotelItemsMatch = filterHotelItemsMatch;
        if (filterHotelItemsMatch) {
          thisRef.totalResult = filterHotelItemsMatch.length;
          thisRef.hotelItemsShow =
            dynamicPkgHotelAvailTools.findSpecificHotelItems(
              filterHotelItemsMatch,
              1,
              thisRef.limit
            );
        } else {
          thisRef.totalResult = 0;
          thisRef.hotelItemsShow = [];
        }
      }
    },
  },
});

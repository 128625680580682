<script setup>
import { useRoute } from "vue-router";
import { useFlightAncillaryServiceStore } from "@/stores/v2/flight/ancillaryservice/flightAncillaryServiceStore";
import { useTemplateStore } from "@/stores/template";
import { ref, watch } from "vue";
import flightAncillaryServiceTools from "@/tools/v2/flight/ancillaryservice/flightAncillaryServiceTools";
import { Empty, Modal } from "ant-design-vue";
import FlightAncillaryServiceMenuPanel from "@/views/v2/sales/flight/items/panel/ancillaryservice/FlightAncillaryServiceMenuPanel.vue";
import FlightAncillaryServiceItemOperationPanel from "@/views/v2/sales/flight/items/panel/ancillaryservice/FlightAncillaryServiceItemOperationPanel.vue";
import FlightAncillaryServiceSelectedPanel from "@/views/v2/sales/flight/items/panel/ancillaryservice/FlightAncillaryServiceSelectedPanel.vue";

const route = useRoute();
const props = defineProps({
  airItineraryRPH: {},
  tripAirItem: {},
  passengerFormModelItems: {},
  flightPriceFormModel: {},
});
const AEmpty = Empty;
const AModal = Modal;
const emits = defineEmits();
const templateStore = useTemplateStore();
const ancillaryServiceStore = useFlightAncillaryServiceStore();

const flightPriceFormModelRef = ref(null);
const ancillaryOptionsRef = ref(null);
const ancillaryServiceMenuRef = ref(null);

const tripSegmentsRef = ref([]);
const currentSegmentRef = ref({});
const currentPassengerRef = ref({});

const paxSegmentAncillaryOptionsRef = ref(null);

const currentServiceToSelectedRef = ref({});
const serviceOperationFormShowRef = ref(false);

watch(
  () => props.passengerFormModelItems,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      currentPassengerRef.value = newValue[0];
    }
  },
  { deep: true, immediate: true }
);
watch(
  () => props.tripAirItem,
  (newValue) => {
    tripSegmentsRef.value = [];
    if (newValue && newValue.flightItems && newValue.flightItems.length > 0) {
      newValue.flightItems.forEach((item, index) => {
        tripSegmentsRef.value.push(...item.flightSegments);
        if (index === 0)
          currentSegmentRef.value =
            item.flightSegments && item.flightSegments.length > 0
              ? item.flightSegments[0]
              : {};
      });
    }
  },
  { deep: true, immediate: true }
);
watch(
  () => props.flightPriceFormModel,
  (newValue) => {
    flightPriceFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

watch(
  () => ancillaryServiceStore.ancillaryServiceMenu,
  (newValue) => {
    ancillaryServiceMenuRef.value = newValue;
  },
  { immediate: true, deep: true }
);
watch(
  () => ancillaryServiceStore.ancillaryOptions,
  (newValue) => {
    ancillaryOptionsRef.value = newValue;
    if (newValue) {
      paxSegmentAncillaryOptionsRef.value =
        flightAncillaryServiceTools.findSpecificAncillaryOptions(
          newValue,
          "SERVICE",
          null,
          currentSegmentRef.value.rph,
          currentPassengerRef.value.travelerRefNumberRPH
        );
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => ancillaryServiceStore.currentTaxonomyCode,
  (newValue) => {
    //点击菜单项，更新options显示
    paxSegmentAncillaryOptionsRef.value =
      flightAncillaryServiceTools.findSpecificAncillaryOptions(
        ancillaryOptionsRef.value,
        "SERVICE",
        newValue,
        currentSegmentRef.value.rph,
        currentPassengerRef.value.travelerRefNumberRPH
      );
  }
);

function closeAncillaryService() {
  emits("closeAncillaryService");
}

//切换当前航段和乘客
function changeSegmentAndPassenger(
  segmentRPH,
  travelerRPH,
  currentTaxonomyCode
) {
  paxSegmentAncillaryOptionsRef.value =
    flightAncillaryServiceTools.findSpecificAncillaryOptions(
      ancillaryOptionsRef.value,
      "SERVICE",
      currentTaxonomyCode,
      segmentRPH,
      travelerRPH
    );
}

function chooseServiceToSelected(service) {
  currentServiceToSelectedRef.value = service;
  serviceOperationFormShowRef.value = true;
}

function closeOperationForm() {
  serviceOperationFormShowRef.value = false;
  currentServiceToSelectedRef.value = {};
}
</script>

<template>
  <h3>{{ $t("add-ancillary-service") }}</h3>
  <div class="text-muted font-18">
    {{ $t("flexibility-your-own-flying-experience") }}
  </div>

  <div class="row ancillary_service">
    <!-- 乘客和航段信息 -->
    <div class="col-12 font-16 p-2">
      <div class="row p-2">
        <div class="col-2">
          <FlightAncillaryServiceMenuPanel :nodes="ancillaryServiceMenuRef" />
        </div>
        <div class="col-7">
          <div class="row p-2">
            <!-- 当前航段和乘客概览 -->
            <div
              v-if="currentSegmentRef && currentPassengerRef"
              class="col-12 mg-b-10"
            >
              <h6>
                <span v-if="currentSegmentRef.departureDateTime">
                  {{ currentSegmentRef.departureDateTime.split("T")[0] }}
                </span>

                <span class="mg-l-10"
                  >{{ currentSegmentRef.departureAirportCode }}
                  <i class="bi bi-arrow-right main_color"></i>
                  {{ currentSegmentRef.arrivalAirportCode }}</span
                >

                <span class="mg-l-10">
                  {{ currentSegmentRef.marketingAirlineCode
                  }}{{ currentSegmentRef.flightNumber }}
                </span>

                <span class="mg-l-10">
                  {{ $t("passenger") }}
                  {{ currentPassengerRef.travelerRefNumberRPH }} ({{
                    currentPassengerRef.passengerTypeCode
                  }})
                </span>
              </h6>
            </div>
            <!-- 当前航段和乘客概览END -->

            <!-- 航段和乘客选择 -->
            <div
              v-if="tripSegmentsRef && tripSegmentsRef.length > 0"
              class="col-4"
            >
              <label class="form-label" for="example-text-input"
                >{{ $t("current-segment") }}
                <small class="text-lowercase"><code>*</code></small></label
              >
              <select
                v-model="currentSegmentRef"
                aria-label="Floating label select example"
                class="form-select"
                @change="
                  changeSegmentAndPassenger(
                    currentSegmentRef.rph,
                    currentPassengerRef.travelerRefNumberRPH,
                    ancillaryServiceStore.currentTaxonomyCode
                  )
                "
              >
                <option
                  v-for="(segment, segIndex) in tripSegmentsRef"
                  :key="segIndex"
                  :selected="segIndex === 0"
                  :value="segment"
                >
                  {{ $t("segment") }} {{ segIndex + 1 }}:
                  {{ segment.departureAirportCode }}-{{
                    segment.arrivalAirportCode
                  }}
                </option>
              </select>
            </div>
            <div class="col-4">
              <label class="form-label" for="example-text-input"
                >{{ $t("current-passenger") }}
                <small class="text-lowercase"><code>*</code></small></label
              >
              <select
                v-model="currentPassengerRef"
                aria-label="Floating label select example"
                class="form-select"
                @change="
                  changeSegmentAndPassenger(
                    currentSegmentRef.rph,
                    currentPassengerRef.travelerRefNumberRPH,
                    ancillaryServiceStore.currentTaxonomyCode
                  )
                "
              >
                <option
                  v-for="(passenger, psIndex) in passengerFormModelItems"
                  :key="psIndex"
                  :value="passenger"
                >
                  {{ $t("passenger") }} {{ psIndex + 1 }} :
                  {{ passenger.travelerRefNumberRPH }} -
                  {{ passenger.passengerTypeCode }}
                </option>
              </select>
            </div>
            <!-- 航段和乘客选择END -->

            <!-- 附加服务选择区域 -->
            <div class="col-12 mg-t-10">
              <div
                v-if="
                  paxSegmentAncillaryOptionsRef &&
                  paxSegmentAncillaryOptionsRef.length > 0
                "
                class="row"
              >
                <div
                  v-for="(
                    service, serviceIndex
                  ) in paxSegmentAncillaryOptionsRef"
                  :key="serviceIndex"
                  class="col-4 d-md-flex"
                >
                  <BaseBlock content-full style="width: 100%">
                    <template #content>
                      <img
                        v-if="service.imageUrl"
                        :src="service.imageUrl"
                        alt=""
                        class="img-fluid"
                      />
                      <div
                        v-else
                        style="height: 158px; width: 100%; background: #f0f0f0"
                      />
                      <div class="block-content">
                        <div class="font_weight mg-b-5">
                          {{ service.name }}
                        </div>
                        <div class="font_weight flex justify-content-between">
                          <div class="text-primary">
                            {{ service.currencyCode }} {{ service.amount }}
                          </div>
                          <div
                            :data-bs-target="
                              '#collapseExample' + 'C' + serviceIndex
                            "
                            aria-controls="collapseExample"
                            aria-expanded="false"
                            class="cursor-point"
                            data-bs-toggle="collapse"
                          >
                            {{ $t("see-descriptions") }}
                            <i class="fas fa-chevron-down"></i>
                          </div>
                        </div>

                        <div
                          v-for="(description, index) in service.descriptions"
                          :id="'collapseExample' + 'C' + serviceIndex"
                          :key="index"
                          class="fs-sm text-muted collapse"
                        >
                          <span v-if="description.name">
                            {{ description.name }} -
                            {{ description.text }}</span
                          >
                          <span v-else> {{ description.text }}</span>
                        </div>
                        <div class="text-center">
                          <button
                            class="btn btn-outline-primary mb-2 mg-t-20"
                            @click="chooseServiceToSelected(service)"
                          >
                            {{ $t("choose") }}
                          </button>
                        </div>
                      </div>
                    </template>
                  </BaseBlock>
                </div>
              </div>
              <div v-else>
                <AEmpty :description="$t('no-services')"></AEmpty>
              </div>
            </div>
            <!-- 附加服务选择区域 END-->
          </div>
        </div>
        <div class="col-3">
          <div class="service-selected-price-summary">
            <!--已选择的附加服务-->
            <h6>{{ $t("selection") }}</h6>
            <div>
              <FlightAncillaryServiceSelectedPanel
                :flight-price-form-model="flightPriceFormModelRef"
                :segments="tripSegmentsRef"
                :passengers="passengerFormModelItems"
                :services-selected="flightPriceFormModelRef.servicesSelected"
                :id-context="tripAirItem ? tripAirItem.supplierCode : null"
                @closeServicesModalPanel="closeAncillaryService"
              />
            </div>
            <!--已选择的附加服务END-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  service operation content-->
  <a-modal
    v-model:open="serviceOperationFormShowRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :closable="false"
    :maskClosable="false"
    :ok-button-props="{ style: { display: 'none' } }"
    :z-index="12222"
    centered
    width="35%"
  >
    <FlightAncillaryServiceItemOperationPanel
      :current-passenger="currentPassengerRef"
      :current-segment="currentSegmentRef"
      :flight-price-form-model="flightPriceFormModelRef"
      :service="currentServiceToSelectedRef"
      @closeOperationForm="closeOperationForm"
    />
  </a-modal>
  <!--  service operation content END-->
</template>

<style lang="scss" scoped></style>

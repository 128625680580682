import { defineStore } from "pinia";
import flightTools from "@/tools/flightTools";
import flightApi from "@/apis/flightApi";
import router from "@/router/modulesRouter";
import contentApi from "@/apis/contentApi";

export const useFlightStore = defineStore("flight", {
  state: () => ({
    airlowfareSearchRS: null,
    flightViewItemsGroup: null,
    offerViewItemMap: null,
    matchFlightViewItemsGroup: null, //显示对象
    airportCityMap: null,
    origDestGroupMap: null,
    airPriceRS: null,
    pricedFlightViewItemsGroup: null,
    passengerTicketFareBreakdownItems: null,
    flightPassengersFormModel: null,
    airFareRulesRS: null,
    airBookRS: null,
    quoteID: null,
    flightRuleViewItemMap: null,
  }),
  actions: {
    async searchFlight(flightSearchFormModel) {
      let lowFareSearchRQ = flightTools.getLowFareSearchRQ(
        flightSearchFormModel
      );
      let _this = this;
      //using for loading page loader
      return flightApi
        .lowFareSearch(lowFareSearchRQ)
        .then((res) => {
          let airlowfareSearchRS = res.data;
          if (airlowfareSearchRS.pricedItineraryList) {
            _this.airlowfareSearchRS = airlowfareSearchRS;
            _this.flightViewItemsGroup = flightTools.getFlightViewItemsGroup(
              airlowfareSearchRS.pricedItineraryList
            );

            //default is first origin to destination group
            _this.matchFlightViewItemsGroup =
              flightTools.getMatchFlightViewItemsGroup(
                _this.flightViewItemsGroup,
                0
              );
            _this.offerViewItemMap = flightTools.getOfferViewItemMap(
              airlowfareSearchRS.offer.summary
            );

            //获取地点名
            let airportCodeMap = flightTools.getAirportCodeMap(
              airlowfareSearchRS.pricedItineraryList
            );
            _this.airportCityMap = {};
            for (const airportCodeMapKey in airportCodeMap) {
              contentApi.airportCity(airportCodeMapKey).then((res) => {
                let data = res.data;
                _this.airportCityMap[data.code] = data;
              });
            }
            _this.origDestGroupMap = flightTools.getOrigDestGroupMap(
              airlowfareSearchRS.pricedItineraryList
            );
            // (_this.origDestGroupMap);
          } else {
            _this.airlowfareSearchRS = null;
            _this.origDestGroupMap = null;
            _this.airportCityMap = null;
          }
        })
        .catch((err) => {
          console.error(err);
          router.push({
            name: "error-500",
            query: { message: "flight unavailable" },
          });
        });
    },
    async changeMatchFlightViewItemsGroup(groupNumber, preEquateKey) {
      this.matchFlightViewItemsGroup = flightTools.getMatchFlightViewItemsGroup(
        this.flightViewItemsGroup,
        groupNumber,
        preEquateKey
      );
    },
    async verifyPrice(specifiedPricedItinerary) {
      // let specifiedPricedItinerary = flightTools.getSpecifiedPricedItinerary(
      //   airItineraryRPH,
      //   this.airlowfareSearchRS.pricedItineraryList
      // );
      // ("选出指定组合");
      // (specifiedPricedItinerary);

      let verifyPriceRQ = flightTools.getVerifyPriceRQ(
        specifiedPricedItinerary
      );
      // ("Verify RQ");
      // (verifyPriceRQ);
      let _this = this;
      return flightApi
        .verifyPrice(verifyPriceRQ)
        .then((res) => {
          if (res && res.data.success) {
            let data = res.data;
            _this.airPriceRS = data;
            _this.pricedFlightViewItemsGroup =
              flightTools.getFlightViewItemsGroup(
                data.pricedItineraryList,
                true
              );
            _this.quoteID = flightTools.getFlightViewItemQuoteID(
              data.pricedItineraryList
            );
            _this.offerViewItemMap = flightTools.getOfferViewItemMap(
              data.offer.summary
            );
            _this.flightPassengersFormModel =
              flightTools.getFlightPassengersForm(
                data.pricedItineraryList[0].airItineraryPricingInfo
                  .PTC_FareBreakdowns
              );
            _this.passengerTicketFareBreakdownItems =
              flightTools.getPassengerTicketFareBreakdownItems(
                data.pricedItineraryList[0].airItineraryPricingInfo
                  .PTC_FareBreakdowns
              );
          } else {
            _this.airPriceRS = null;
            _this.pricedFlightViewItemsGroup = null;
            _this.offerViewItemMap = null;
          }
        })
        .catch((err) => {
          console.error(err);
          router.push({
            name: "error-500",
            query: { message: "flight unavailable" },
          });
        });
    },
    async updatePricedFlightViewItemsGroup(pricedFlightViewItemsGroup) {
      this.pricedFlightViewItemsGroup = pricedFlightViewItemsGroup;
    },
    async updateOrigDestGroupMap(origDestGroupMap) {
      this.origDestGroupMap = origDestGroupMap;
    },
    async updateAirportCityMap(airportCityMap) {
      this.airportCityMap = airportCityMap;
    },
    async updateOfferViewItemMap(offerViewItemMap) {
      this.offerViewItemMap = offerViewItemMap;
    },
    async updateFlightPassengersFormModel(flightPassengersFormModel) {
      this.flightPassengersFormModel = flightPassengersFormModel;
    },
    async updatePassengerTicketFareBreakdownItems(
      passengerTicketFareBreakdownItems
    ) {
      this.passengerTicketFareBreakdownItems =
        passengerTicketFareBreakdownItems;
    },
    async searchFareRules(quoteId) {
      let airRuleRQ = flightTools.getAirRuleRQ(quoteId);
      let _this = this;
      return flightApi
        .fareRules(airRuleRQ)
        .then((res) => {
          let data = res.data;
          if (data.success) {
            _this.airFareRulesRS = data;
            _this.flightRuleViewItemMap = flightTools.getFlightRuleViewItemMap(
              data.fareRuleResponseInfos
            );
          } else {
            _this.airFareRulesRS = null;
          }
          return res.data;
        })
        .catch((err) => {
          console.error(err);
          router.push({
            name: "error-500",
            query: { message: "flight unavailable" },
          });
        });
    },
    async airBook(flightBookingFormModel) {
      let airBookRQ = flightTools.getAirBookRQ(flightBookingFormModel);
      let _this = this;
      return flightApi.book(airBookRQ).then((res) => {
        // (res);
        _this.airBookRS = res.data;
      });
    },
  },
});

//region 请求对象RQ

function getSailAvailRQ(sailAvailFormModel) {
  let services = [];

  const originDestinations = sailAvailFormModel.originDestinations;
  const adultNum = sailAvailFormModel.adultNum;
  const childNum = sailAvailFormModel.childNum;
  const infantNum = sailAvailFormModel.infantNum;

  if (originDestinations && originDestinations.length > 0) {
    originDestinations.forEach((item) => {
      const dateTime = item.departureDateTime;
      const originLocationCode = item.originLocationCode;
      const originLocationType = item.originLocationType;
      const destinationLocationCode = item.destinationLocationCode;
      const destinationLocationType = item.destinationLocationType;

      let pickup = {
        dateTime: dateTime,
        address: {
          locationType: {
            code: originLocationCode,
            type: originLocationType,
          },
        },
      };
      let dropoff = {
        dateTime: dateTime,
        address: {
          locationType: {
            code: destinationLocationCode,
            type: destinationLocationType,
          },
        },
      };
      services.push({ pickup: pickup, dropoff: dropoff });
    });
  }

  return {
    services: services,
    passengers: [
      {
        age: 18,
        quantity: adultNum,
      },
      {
        age: 10,
        quantity: childNum,
      },
      {
        age: 1,
        quantity: infantNum,
      },
    ],
  };
}

function getGroundBookRQ(payload) {
  const passengerModel = payload.passengerModel;
  const selectedIds = payload.selectedIds;
  const resStatus = payload.resStatus;

  let groundServices = [];
  if (selectedIds && selectedIds.length > 0) {
    selectedIds.forEach((item) => {
      let id = {
        reference: {
          id: item,
        },
      };
      groundServices.push(id);
    });
  }

  return {
    resStatus: resStatus,
    groundReservations: [
      {
        passenger: {
          primary: {
            personName: {
              givenName: passengerModel.givenName,
              surname: passengerModel.surname,
              nameTitle: passengerModel.nameTitle,
            },
            telephoneList: [
              {
                phoneNumber:
                  passengerModel.countryAccessCode +
                  "-" +
                  passengerModel.phoneNumber,
              },
            ],
            emailList: [
              {
                stringValue: passengerModel.emailAddress,
              },
            ],
          },
        },
        groundServices: groundServices,
      },
    ],
  };
}

//endregion

//region  初始化视图对象

/***
 * originDestination对象
 */
class OriginDestination {
  constructor(
    departureDateTime,
    returnDateTime,
    originLocationCode,
    originLocationName,
    originLocationType,
    destinationLocationCode,
    destinationLocationName,
    destinationLocationType
  ) {
    this.departureDateTime = departureDateTime;
    this.returnDateTime = returnDateTime;
    this.originLocationCode = originLocationCode;
    this.originLocationName = originLocationName;
    this.originLocationType = originLocationType;
    this.destinationLocationCode = destinationLocationCode;
    this.destinationLocationName = destinationLocationName;
    this.destinationLocationType = destinationLocationType;
  }
}

/***
 * SearchFormModel对象
 */
class TransferSearchFormModel {
  constructor(originDestinations, adultNum, childNum, infantNum) {
    this.originDestinations = originDestinations;
    this.adultNum = adultNum;
    this.childNum = childNum;
    this.infantNum = infantNum;
  }
}

/***
 *  CarTransferViewItem对象
 */
class CarTransferViewItem {
  constructor(
    pickUpDateTime,
    remark,
    addressLines,
    emialAddress,
    pickUplocationCode,
    pickUpLocationName,
    customerMaxWaitingTime,
    customerMaxWaitingTimeUnit,
    supplierMaxWaitingTime,
    supplierMaxWaitingTimeUnit,
    dropoffLocationName,
    vehicleType,
    vehiclePic,
    vehicleName,
    imageItems,
    serviceItems,
    totalAmount,
    currency,
    id,
    instance,
    adults,
    children,
    infants
  ) {
    this.pickUpDateTime = pickUpDateTime;
    this.remark = remark;
    this.addressLines = addressLines;
    this.emialAddress = emialAddress;
    this.pickUplocationCode = pickUplocationCode;
    this.pickUpLocationName = pickUpLocationName;
    this.customerMaxWaitingTime = customerMaxWaitingTime;
    this.customerMaxWaitingTimeUnit = customerMaxWaitingTimeUnit;
    this.supplierMaxWaitingTime = supplierMaxWaitingTime;
    this.supplierMaxWaitingTimeUnit = supplierMaxWaitingTimeUnit;
    this.dropoffLocationName = dropoffLocationName;
    this.vehicleType = vehicleType;
    this.vehiclePic = vehiclePic;
    this.vehicleName = vehicleName;
    this.imageItems = imageItems;
    this.serviceItems = serviceItems;
    this.totalAmount = totalAmount;
    this.currency = currency;
    this.id = id;
    this.instance = instance;
    this.adults = adults;
    this.children = children;
    this.infants = infants;
  }
}

/***
 *  CarTransferPassengerFormModel对象
 */
class CarTransferPassengerFormModel {
  constructor(nameTitle, surname, givenName, phoneNumber, emailAddress) {
    this.nameTitle = nameTitle;
    this.surname = surname;
    this.givenName = givenName;
    this.phoneNumber = phoneNumber;
    this.emailAddress = emailAddress;
  }
}

/***
 *  CarTransferBookingViewItem对象
 */
class CarTransferBookingViewItem {
  constructor(passenger, groundServices) {
    this.passenger = passenger;
    this.groundServices = groundServices;
  }
}

//endregion

//region 构造视图对象
function getCarTransferViewItemsMap(availTransferRS) {
  const groundServices = availTransferRS.groundServices;

  let groundInstanceMap = {};
  if (groundServices && groundServices.length > 0) {
    groundInstanceMap = groundServices.reduce((acc, currentObj) => {
      const key = currentObj.reference.instance;
      if (!acc[key]) {
        acc[key] = [];
      }
      const service = currentObj.service;
      const totalCharge = currentObj.totalCharge;
      const reference = currentObj.reference;

      const location = service.location;
      let pickup = location.pickup;
      let pickUpDateTime = pickup.dateTime;
      const address = pickup.address;
      let remark = address.remark; //额外备注
      let addressLines =
        address.addressLines && address.addressLines.length > 0
          ? address.addressLines[0]
          : null; //详细地址
      let emialAddress = address.postalCode; //邮箱地址
      const locationType = address.locationType;
      const pickUplocationCode = locationType.code;
      const pickUpLocationName = locationType.description;
      const checkPickup = pickup.checkPickup;
      let customerMaxWaitingTime = checkPickup.customerMaxWaitingTime;
      let customerMaxWaitingTimeUnit = checkPickup.customerMaxWaitingTimeUnit;
      let supplierMaxWaitingTime = checkPickup.supplierMaxWaitingTime;
      let supplierMaxWaitingTimeUnit = checkPickup.supplierMaxWaitingTimeUnit;

      let dropoff = location.dropoff;
      const dropoffAddress = dropoff.address;
      const dropoffLocationType = dropoffAddress.locationType;
      let dropoffLocationName = dropoffLocationType.description;

      const vehicle = service.vehicleType;
      const vehicleMakeModel = service.vehicleMakeModel;
      const multimediaDescriptions = service.multimediaDescriptions;
      const adults = service.adults;

      const children = service.children;
      const infants = service.infants;
      let multimediaDescriptionLists =
        multimediaDescriptions.multimediaDescriptionLists;
      let imageItems = [];
      let serviceItems = [];
      if (multimediaDescriptionLists && multimediaDescriptionLists.length > 0) {
        imageItems = multimediaDescriptionLists[0].imageItems;
        serviceItems = multimediaDescriptionLists[0].textItems;
      }

      let vehicleType = vehicle.description;
      let vehiclePic = vehicle.sourceURL;
      let vehicleName = vehicleMakeModel.name;

      let totalAmount = totalCharge.estimatedTotalAmount;
      let currency = totalCharge.currencyCode;

      let id = reference.id;
      let instance = reference.instance;
      const carTransferViewItem = new CarTransferViewItem(
        pickUpDateTime,
        remark,
        addressLines,
        emialAddress,
        pickUplocationCode,
        pickUpLocationName,
        customerMaxWaitingTime,
        customerMaxWaitingTimeUnit,
        supplierMaxWaitingTime,
        supplierMaxWaitingTimeUnit,
        dropoffLocationName,
        vehicleType,
        vehiclePic,
        vehicleName,
        imageItems,
        serviceItems,
        totalAmount,
        currency,
        id,
        instance,
        adults,
        children,
        infants
      );
      acc[key].push(carTransferViewItem);

      return acc;
    }, {});
    return groundInstanceMap;
  } else {
    return null;
  }
}

function getCarTransferBookingViewItem(passenger, groundReservation) {
  const nameTitle = passenger.primary.personName.nameTitle;
  const givenName = passenger.primary.personName.givenName;
  const surname = passenger.primary.personName.surname;
  const phoneNumber =
    passenger.primary.telephoneList &&
    passenger.primary.telephoneList.length > 0
      ? passenger.primary.telephoneList[0].phoneNumber
      : null;
  const emailAddress =
    passenger.primary.emailList && passenger.primary.emailList.length > 0
      ? passenger.primary.emailList[0].stringValue
      : null;
  let passengerInfo = {
    nameTitle: nameTitle,
    givenName: givenName,
    surname: surname,
    phoneNumber: phoneNumber,
    emailAddress: emailAddress,
  };

  const groundServices = groundReservation.groundServices;

  let carTransferServices = [];
  if (groundServices && groundServices.length > 0) {
    groundServices.forEach((groundService) => {
      const service = groundService.service;
      const totalCharge = groundService.totalCharge;
      const reference = groundService.reference;

      const location = service.location;
      let pickup = location.pickup;
      let pickUpDateTime = pickup.dateTime;
      const address = pickup.address;
      let remark = address.remark; //额外备注
      let addressLines =
        address.addressLines && address.addressLines.length > 0
          ? address.addressLines[0]
          : null; //详细地址
      let emialAddress = address.postalCode; //邮箱地址
      const locationType = address.locationType;
      const pickUplocationCode = locationType.code;
      const pickUpLocationName = locationType.description;
      const checkPickup = pickup.checkPickup;
      let customerMaxWaitingTime = checkPickup.customerMaxWaitingTime;
      let customerMaxWaitingTimeUnit = checkPickup.customerMaxWaitingTimeUnit;
      let supplierMaxWaitingTime = checkPickup.supplierMaxWaitingTime;
      let supplierMaxWaitingTimeUnit = checkPickup.supplierMaxWaitingTimeUnit;

      let dropoff = location.dropoff;
      const dropoffAddress = dropoff.address;
      const dropoffLocationType = dropoffAddress.locationType;
      let dropoffLocationName = dropoffLocationType.description;

      const vehicle = service.vehicleType;
      const vehicleMakeModel = service.vehicleMakeModel;
      const adults = service.adults;

      const children = service.children;
      const infants = service.infants;
      const multimediaDescriptions = service.multimediaDescriptions;
      let multimediaDescriptionLists =
        multimediaDescriptions.multimediaDescriptionLists;
      let imageItems = [];
      let serviceItems = [];

      let vehiclePic = null;
      if (multimediaDescriptionLists && multimediaDescriptionLists.length > 0) {
        imageItems = multimediaDescriptionLists[0].imageItems;
        serviceItems = multimediaDescriptionLists[0].textItems;
        if (imageItems && imageItems.length > 0) {
          vehiclePic = imageItems.find(
            (item) => item.imageFormatList[0].dimensionCategory === "MEDIUM"
          ).url;
        }
      }

      let vehicleType = vehicle.description;
      let vehicleName = vehicleMakeModel.name;

      let totalAmount = totalCharge.estimatedTotalAmount;
      let currency = totalCharge.currencyCode;

      let id = reference.id;
      let instance = reference.instance;
      const carTransferViewItem = new CarTransferViewItem(
        pickUpDateTime,
        remark,
        addressLines,
        emialAddress,
        pickUplocationCode,
        pickUpLocationName,
        customerMaxWaitingTime,
        customerMaxWaitingTimeUnit,
        supplierMaxWaitingTime,
        supplierMaxWaitingTimeUnit,
        dropoffLocationName,
        vehicleType,
        vehiclePic,
        vehicleName,
        imageItems,
        serviceItems,
        totalAmount,
        currency,
        id,
        instance,
        adults,
        children,
        infants
      );
      carTransferServices.push(carTransferViewItem);
    });
  }
  const carTransferBookingViewItem = new CarTransferBookingViewItem(
    passengerInfo,
    carTransferServices
  );
  return carTransferBookingViewItem;
}

function getBookResRQ(confIDs) {
  return {
    vehResRQCore: {
      status: "Book",
      uniqueIDs: confIDs,
    },
  };
}
//endregion
export default {
  getSailAvailRQ,
  getGroundBookRQ,

  OriginDestination,
  TransferSearchFormModel,
  CarTransferViewItem,
  CarTransferPassengerFormModel,
  CarTransferBookingViewItem,

  getCarTransferViewItemsMap,
  getCarTransferBookingViewItem,
  getBookResRQ,
};

export class FlightSegmentVO {
    constructor(
        departureDateTime,
        arrivalDateTime,
        segmentKey,
        flightNumber,
        fareBasisCode,
        fareTypeName,
        resBookDesigCode,
        numberInParty,
        stopoverIn,
        departureAirportCode,
        arrivalAirportCode,
        operatingAirlineCode,
        operatingAirlineName,
        equipmentCode,
        marketingAirlineCode,
        cabinClass,
        baggageAllowance,
        rph
    ) {
        this.departureDateTime = departureDateTime;
        this.arrivalDateTime = arrivalDateTime;
        this.segmentKey = segmentKey;
        this.flightNumber = flightNumber;
        this.fareBasisCode = fareBasisCode;
        this.fareTypeName = fareTypeName;
        this.resBookDesigCode = resBookDesigCode;
        this.numberInParty = numberInParty;
        this.stopoverIn = stopoverIn;
        this.departureAirportCode = departureAirportCode;
        this.arrivalAirportCode = arrivalAirportCode;
        this.operatingAirlineCode = operatingAirlineCode;
        this.operatingAirlineName = operatingAirlineName;
        this.equipmentCode = equipmentCode;
        this.marketingAirlineCode = marketingAirlineCode;
        this.cabinClass = cabinClass;
        this.baggageAllowance = baggageAllowance;
        this.rph = rph;
    }
}
<script setup>
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import ActivitySearchForm from "@/views/v2/sales/activity/items/form/ActivitySearchForm.vue";
import BaseActivitySortForm from "@/views/v2/sales/activity/items/form/BaseActivitySortForm.vue";
import { useActivityStore } from "@/stores/v2/activity/activity";
import { useTemplateStore } from "@/stores/template";
import { Empty, Spin } from "ant-design-vue";
import BaseActivityItem from "@/views/v2/sales/activity/items/goup/BaseActivityItem.vue";
import flightTools from "@/tools/flightTools";
import { useCmsContentStore } from "@/stores/cms";

const router = useRouter();
const route = useRoute();
const activityStore = useActivityStore();
const templateStore = useTemplateStore();
const AEmpty = Empty;
const ASpin = Spin;

//region 数据初始化
let activityViewItemsShow = ref([]);
let showMaxIndex = ref(8);
let isShowMore = ref(true);
let filterOptionRef = ref({
  priceRange: [],
});
let sortOptionRef = ref("");
//endregion

//region 数据监听pinia 获得activity列表
watch(
  () => activityStore.activityViewItems,
  (newValue) => {
    //获取新数据前，先将原来的数据清除
    activityViewItemsShow.value = [];

    if (newValue) {
      activityViewItemsShow.value = newValue;
      filterOptionRef.value = {
        priceRange: [],
      };
      sortOptionRef.value = "";
      sortAndFilter(sortOptionRef.value, filterOptionRef.value);
    }
  },
  { deep: true, immediate: true }
);
//endregion

//region 过滤排序总入口

function sortAndFilter(sortingOption, filterOption) {
  activityViewItemsShow.value = null;

  setTimeout(() => {
    if (sortingOption) {
      sortOptionRef.value = sortingOption;
    }
    if (filterOption) {
      filterOptionRef.value = filterOption;
    }
    if (filterOptionRef.value) {
      filterByOptions(filterOptionRef.value);
    }
    if (sortOptionRef.value) {
      sortByOptions(sortOptionRef.value);
    }
    resetShowMore();
  }, 100);
}

//过滤逻辑
function filterByOptions(filterOption) {
  let activityViewItemsShowTemp = null;

  //获取过滤前的数据源
  if (activityStore.activityViewItems) {
    activityViewItemsShowTemp = JSON.parse(
      JSON.stringify(activityStore.activityViewItems)
    );
  }
  //根据价格区间筛选
  if (filterOption.priceRange && filterOption.priceRange.length > 0) {
    if (filterOption.priceRange[0] && filterOption.priceRange[1]) {
      activityViewItemsShow.value = activityViewItemsShowTemp.filter(
        (item) =>
          item.minPrice >= filterOption.priceRange[0] &&
          item.minPrice <= filterOption.priceRange[1]
      );
    }
  } else {
    activityViewItemsShow.value = activityViewItemsShowTemp;
  }
}

//排序逻辑
function sortByOptions(sortingOptionRef) {
  let sortingOptionObj = null;
  if (sortingOptionRef.value) {
    const splits = sortingOptionRef.value.split("-");
    let type = splits[0];
    let order = splits[1];
    sortingOptionObj = new flightTools.SortingOption(type, order);

    let activityViewItemsShowCopy = null;
    if (activityStore.activityViewItems) {
      activityViewItemsShowCopy = JSON.parse(
        JSON.stringify(activityStore.activityViewItems)
      );
    }

    sort(
      sortingOptionObj.type,
      sortingOptionObj.order,
      activityViewItemsShowCopy
    );
  }
}

function sort(type, order, activityViewItemsShowCopy) {
  if (activityViewItemsShowCopy) {
    activityViewItemsShow.value = activityViewItemsShowCopy;
    if (type === "PRICE" && order === "ASC") {
      //升序
      if (
        activityViewItemsShow.value &&
        activityViewItemsShow.value.length > 0
      ) {
        activityViewItemsShow.value.sort((a, b) => {
          return a.minPrice - b.minPrice;
        });
      }
    } else if (type === "PRICE" && order === "DSC") {
      //降序
      if (
        activityViewItemsShow.value &&
        activityViewItemsShow.value.length > 0
      ) {
        activityViewItemsShow.value.sort((a, b) => {
          return b.minPrice - a.minPrice;
        });
      }
    }
  }
}

//endregion

//region辅助函数
function resetShowMore() {
  showMaxIndex.value = 8;
  isShowMore.value = true;
}

function loadMoreItems() {
  showMaxIndex.value += 8;
  if (showMaxIndex.value >= activityViewItemsShow.value.length) {
    isShowMore.value = false;
  }
}

function selectActivity(id) {
  let query = route.query;

  query.activityId = id;
  sessionStorage.setItem("activityDetailQurey", JSON.stringify(query));
  router.push({ name: "sales-activity-details-v2", query: query });
}

function handleScroll() {
  let distance =
    document.documentElement.scrollHeight -
    document.documentElement.scrollTop -
    document.documentElement.clientHeight;

  if (distance === 0) {
    loadMoreItems();
  }
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});
//endregion

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.ActivityBackgroundImage
        ? cmsContentStore.productPageBackgroundImage.ActivityBackgroundImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>Activity List</h2>
        <ul>
          <li
            class="pre-page"
            v-if="route.query && route.query.isFromHome"
            @click="router.push({ name: 'home' })"
          >
            {{ $t("home") }}
          </li>
          <li
            v-else
            class="pre-page"
            @click="router.push({ name: 'sales-activity-home-v2' })"
          >
            Tour Activity
          </li>
          <li>
            <i class="fas fa-circle"></i>
            Activity List
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="theme_search_form_tour">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!--搜索表单 -->
          <ActivitySearchForm :jumpable="false" />
          <!--搜索表单-->
        </div>
      </div>
    </div>
  </section>

  <section class="section_padding">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-8"
          v-if="activityViewItemsShow && activityViewItemsShow.length > 0"
        >
          <h5 class="fw-bold mg-t-10">
            {{ activityViewItemsShow.length }} {{ $t("properties") }}
          </h5>
        </div>
        <div class="col-4">
          <BaseActivitySortForm
            @sort="(sortOption) => sortAndFilter(sortOption, filterOptionRef)"
          />
        </div>

        <div class="col-lg-12">
          <div
            class="row"
            v-if="activityViewItemsShow && activityViewItemsShow.length > 0"
          >
            <div
              class="col-3"
              v-for="(
                activityViewItem, activityViewItemIndex
              ) in activityViewItemsShow"
              :key="activityViewItemIndex"
            >
              <BaseActivityItem
                v-if="showMaxIndex > activityViewItemIndex"
                :item="activityViewItem"
                @selectActivity="selectActivity"
              />
            </div>
            <div
              class="text-center"
              v-if="
                isShowMore &&
                activityStore.activityViewItems &&
                activityStore.activityViewItems.length > 0 &&
                activityStore.activityViewItems.length > showMaxIndex &&
                activityViewItemsShow > showMaxIndex
              "
            >
              <Spin></Spin>
              <span class="mg-l-8 text-primary">{{ $t("load-more") }}..</span>
            </div>
          </div>
          <div v-else>
            <AEmpty></AEmpty>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

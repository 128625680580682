/**
 *
 * @param hotelReserveFormModel HotelReserveFormModel对象
 */
function buildHoldResRQ(hotelReserveFormModel) {
  const languageCode = hotelReserveFormModel.languageCode;
  const checkIn = hotelReserveFormModel.checkIn;
  const checkOut = hotelReserveFormModel.checkOut;
  const roomResGuests = hotelReserveFormModel.roomResGuests;

  const roomStays = [];

  const resGuestsOta = [];
  let resGuestRPH = 0;
  let roomIndex = 0;
  roomResGuests.forEach((roomResGuest) => {
    let guestCounts = [];
    roomResGuest.resGuests.forEach((resGuest) => {
      let ageQualifyingCode = resGuest.ageQualifyingCode;
      let age = resGuest.age;

      let guestCount = {
        resGuestRPH: resGuestRPH,
        ageQualifyingCode: ageQualifyingCode,
        age: age,
        count: 1,
      };
      resGuestsOta.push(guestCount);
      guestCounts.push(guestCount);
      resGuestRPH++;
    });

    let roomStay = {
      roomTypes: [
        {
          quantity: 1,
        },
      ],
      guestCounts: {
        guestCountList: guestCounts,
      },
      reference: {
        type: "UUID",
        id: roomResGuest.referenceId,
      },
      roomStayIndex: roomIndex,
    };
    roomIndex++;
    roomStays.push(roomStay);
  });

  return buildHotelResRQ(
    "Hold",
    checkIn,
    checkOut,
    roomStays,
    resGuestsOta,
    null,
    languageCode
  );
}

/**
 *
 * @param hotelReserveFormModel HotelReserveFormModel对象
 */
function buildPreBookResRQ(hotelReserveFormModel) {
  let checkIn = hotelReserveFormModel.checkIn;
  const languageCode = hotelReserveFormModel.languageCode;
  let checkOut = hotelReserveFormModel.checkOut;
  let roomResGuests = hotelReserveFormModel.roomResGuests;

  const roomStays = [];
  const resGuestsOta = [];
  let roomIndex = 0;
  roomResGuests.forEach((roomResGuest) => {
    let guestCounts = [];
    let resGuests = roomResGuest.resGuests;
    resGuests.forEach((resGuest) => {
      let resGuestRPH = resGuest.resGuestRPH;
      let ageQualifyingCode = resGuest.ageQualifyingCode;
      let age = resGuest.age;
      resGuestsOta.push(resGuest);

      let guestCount = {
        resGuestRPH: resGuestRPH,
        ageQualifyingCode: ageQualifyingCode,
        age: age,
        count: 1,
      };
      guestCounts.push(guestCount);
    });

    let roomStay = {
      roomTypes: [
        {
          quantity: 1,
        },
      ],
      guestCounts: {
        guestCountList: guestCounts,
      },
      reference: {
        type: "UUID",
        id: roomResGuest.referenceId,
      },
      roomStayIndex: roomIndex,
    };
    if (roomResGuest.remarks) {
      roomStay.specialRequests = {
        specialRequests: [
          {
            text: roomResGuest.remarks,
          },
        ],
      };
    }
    roomIndex++;
    roomStays.push(roomStay);
  });

  return buildHotelResRQ(
    "Initiate",
    checkIn,
    checkOut,
    roomStays,
    resGuestsOta,
    null,
    languageCode
  );
}

function buildBookResRQ(prebookRQ, prebookRS) {
  let uniqueIDList = prebookRS.hotelReservationList[0].uniqueIDList;
  prebookRQ.resStatus = "Book";
  prebookRQ.hotelReservationList.forEach((hotelReservation) => {
    hotelReservation.resStatus = "Book";
    hotelReservation.uniqueIDList = uniqueIDList;
  });
  return prebookRQ;
}

function buildHotelResRQ(
  resStatus,
  checkInDate,
  checkOutDate,
  roomStayList,
  resGuestList,
  uniqueIDList,
  languageCode
) {
  // if (uniqueIDList == null) {
  //   uniqueIDList = [];
  // }

  resGuestList.forEach((resGuest) => {
    if (
      resGuest.surname == null &&
      !resGuest.mainIndicator &&
      !resGuest.primaryIndicator
    ) {
      resGuest.surname = "TBA-" + resGuest.resGuestRPH;
      resGuest.givenName = "TBA-" + resGuest.resGuestRPH;
    }
    if (resGuest.nameTitle == null) {
      resGuest.nameTitle = "Mr.";
    }
  });

  return {
    resStatus: resStatus,
    primaryLangID: languageCode,
    hotelReservationList: [
      {
        resGlobalInfo: {
          timeSpan: {
            start: checkInDate,
            end: checkOutDate,
          },
        },
        uniqueIDList: uniqueIDList,
        roomStayList: roomStayList,
        resStatus: resStatus,
        resGuestList: resGuestList,
      },
    ],
  };
}

export default {
  buildHoldResRQ,
  buildPreBookResRQ,
  buildBookResRQ,
};

<script setup>
import flightSortOptions from "@/formmodel/sales/flight/shopping/options/flightSortOptions";
import flightFilterOptions from "@/formmodel/sales/flight/shopping/options/flightFilterOptions";
import { ref, watch } from "vue";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import { useI18n } from "vue-i18n";

const props = defineProps({
  filterOptions: {},
  flightItemsLength: {},
  shoppingFilterFormModel: {},
  shoppingSortFormModel: {},
  activeFlightGroupInfo: {},
  airlineItemMap: {},
  airportCityMap: {},
});

const { t, locale } = useI18n(); // 解构调用函数
const emits = defineEmits();
const shoppingSortFormModelRef = ref(null);
const shoppingFilterFormModelRef = ref(null);

watch(
  () => props.shoppingSortFormModel,
  (newValue) => {
    shoppingSortFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);
watch(
  () => props.shoppingFilterFormModel,
  (newValue) => {
    shoppingFilterFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function sortAndFilter() {
  emits(
    "sortAndFilter",
    shoppingSortFormModelRef.value,
    shoppingFilterFormModelRef.value
  );
}

function getAirportName(airportCode) {
  return "airport." + airportCode;
}

function getAirlineName(airlineCode) {
  return "airlineName." + airlineCode;
}
</script>

<template>
  <div class="flight_filter_sort">
    <div class="left_side_search_boxed">
      <h4 class="mg-b-20">
        {{
          $t("num-flights-found", {
            flightViewItemsShowLength: flightItemsLength,
          })
        }}
      </h4>
      <!-- 排序区域 -->
      <div class="mg-b-20">
        <div class="left_side_search_heading">
          <h5>{{ $t("sort-by") }}</h5>
        </div>
        <div class="filter_review" v-if="flightSortOptions">
          <form class="review_star">
            <div
              class="form-check p-0"
              v-for="(sort, index) in flightSortOptions.sortOptions"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                v-model="shoppingSortFormModelRef"
                :id="'sort-radios' + index"
                :name="'sort-radios' + index"
                :value="{
                  type: sort.type,
                  order: sort.order,
                }"
                @change="sortAndFilter"
              />
              <label class="form-check-label" :for="'sort-radios' + index">{{
                $t(sort.label)
              }}</label>
            </div>
          </form>
        </div>
      </div>
      <!-- 排序区域END -->

      <!-- 过滤区域 -->
      <div v-if="filterOptions">
        <div class="left_side_search_heading">
          <h5>{{ $t("filter-by") }}</h5>
        </div>
        <div
          class="filter_review"
          v-if="flightFilterOptions && activeFlightGroupInfo"
        >
          <form class="review_star">
            <label class="form-label"
              >{{ $t("departure-time") }} {{ $t("in") }}
              {{ activeFlightGroupInfo.originLocationCode }}</label
            >
            <div
              class="form-check"
              v-for="(time, index) in flightFilterOptions.timePeriodOptions"
              :key="index"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="shoppingFilterFormModelRef.departureTimePeriods"
                :id="'time-check-box' + index"
                :name="'time-check-box' + index"
                :value="time.value"
                @change="sortAndFilter"
              />
              <label class="form-check-label" :for="'time-check-box' + index"
                >{{ $t(time.label) }} ({{ time.value }})
              </label>
            </div>
          </form>
        </div>

        <div
          class="filter_review"
          v-if="flightFilterOptions && activeFlightGroupInfo"
        >
          <form class="review_star">
            <label class="form-label">
              {{ $t("arrival-time") }} {{ $t("in") }}
              {{ activeFlightGroupInfo.destinationLocationCode }}</label
            >
            <div
              class="form-check"
              v-for="(time, index) in flightFilterOptions.timePeriodOptions"
              :key="index"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="shoppingFilterFormModelRef.arrivalTimePeriods"
                :id="'time-check-box' + index"
                :name="'time-check-box' + index"
                :value="time.value"
                @change="sortAndFilter"
              />
              <label class="form-check-label" :for="'time-check-box' + index"
                >{{ $t(time.label) }} ({{ time.value }})
              </label>
            </div>
          </form>
        </div>

        <div class="filter_review">
          <form class="review_star">
            <label class="form-label"> {{ $t("stops") }}</label>
            <div
              class="form-check"
              v-for="(stop, index) in filterOptions.stopsOptions"
              :key="index"
            >
              <input
                class="form-check-input"
                v-model="shoppingFilterFormModelRef.stops"
                type="checkbox"
                :id="'stop-check-box' + index"
                :name="'stop-check-box' + index"
                :value="stop.value"
                @change="sortAndFilter"
              />
              <label
                class="form-check-label flex-between"
                :for="'stop-check-box' + index"
                ><span
                  >{{
                    stop.label == 0
                      ? $t("direct")
                      : $t("number-stops", { stopNumber: stop.label })
                  }} </span
                ><span>{{ stop.quantity }}</span></label
              >
            </div>
          </form>
        </div>

        <div class="filter_review">
          <form class="review_star">
            <label class="form-label"> {{ $t("airlines") }}</label>
            <div
              class="form-check"
              v-for="(airline, index) in filterOptions.airlineOptions"
              :key="index"
            >
              <input
                class="form-check-input"
                v-model="shoppingFilterFormModelRef.airlineCodes"
                type="checkbox"
                :id="'airline-check-box' + index"
                :name="'airline-check-box' + index"
                :value="airline.value"
                @change="sortAndFilter"
              />
              <label
                class="form-check-label flex-between"
                :for="'airline-check-box' + index"
                ><span>
                  {{
                    flightCommonTools.getAirlineName(
                      locale,
                      airline.label,
                      airlineItemMap
                    )
                  }}</span
                ><span>{{ airline.quantity }}</span></label
              >
            </div>
          </form>
        </div>

        <div
          class="filter_review"
          v-if="
            filterOptions.stopoverAirportOptions &&
            filterOptions.stopoverAirportOptions.length > 0
          "
        >
          <form class="review_star">
            <label class="form-label"> {{ $t("stopover-airport") }}</label>
            <div
              class="form-check"
              v-for="(airport, index) in filterOptions.stopoverAirportOptions"
              :key="index"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="shoppingFilterFormModelRef.stopoverAirportCodes"
                :id="'airport-check-box' + index"
                :name="'airport-check-box' + index"
                :value="airport.value"
                @change="sortAndFilter"
              />
              <label
                class="form-check-label flex-between"
                :for="'airport-check-box' + index"
                ><span>
                  {{
                    flightCommonTools.getAirportCityName(
                      locale,
                      airport.label,
                      airportCityMap
                    )
                  }} </span
                ><span>{{ airport.quantity }}</span></label
              >
            </div>
          </form>
        </div>

        <!--        <div-->
        <!--          class="filter_review"-->
        <!--          v-if="activeFlightGroupInfo.refNumber === 'OD1'"-->
        <!--        >-->
        <!--          <form class="review_star">-->
        <!--            <label class="form-label"> {{ $t("suppliers") }}</label>-->
        <!--            <div-->
        <!--              class="form-check"-->
        <!--              v-for="(supplier, index) in filterOptions.supplierOptions"-->
        <!--              :key="index"-->
        <!--            >-->
        <!--              <input-->
        <!--                class="form-check-input"-->
        <!--                type="checkbox"-->
        <!--                v-model="shoppingFilterFormModelRef.supplierCodes"-->
        <!--                :id="'supplier-check-box' + index"-->
        <!--                :name="'supplier-check-box' + index"-->
        <!--                :value="supplier.value"-->
        <!--                @change="sortAndFilter"-->
        <!--              />-->
        <!--              <label-->
        <!--                class="form-check-label flex-between"-->
        <!--                :for="'supplier-check-box' + index"-->
        <!--                ><span>{{ supplier.label }} </span-->
        <!--                ><span>{{ supplier.quantity }}</span></label-->
        <!--              >-->
        <!--            </div>-->
        <!--          </form>-->
        <!--        </div>-->
      </div>
      <!-- 过滤区域END -->
    </div>
  </div>
</template>

<style scoped></style>

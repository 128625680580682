<template>
     <section id="about_two_area" class="section_padding_top">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                    <div class="about_two_left">
                        <div class="about_two_left_top">
                            <h5>About us</h5>
                            <h2>Discover your all the destinations with us!</h2>
                            <p>
                                Sint est eu sit ipsum enim amet esse sunt incididunt. Occaecat aliquip commodo ipsum
                                officia
                                in Lorem commodo aliquip dolore. Nisi domip excepteur commodo ea nostrud mollit.
                            </p>
                        </div>
                        <div class="about_two_left_middel">
                            <div class="about_two_item">
                                <div class="about_two_item_icon">
                                    <img src="../../../assets/img/icon/about-1.png" alt="icon">
                                </div>
                                <div class="about_two_item_text">
                                    <h3>Experienced tour guide</h3>
                                    <p>Dolore ullamco voluptate duis est voluptate exercitation officia ad qui nostrud
                                        adipisicing non.</p>
                                </div>
                            </div>
                            <div class="about_two_item">
                                <div class="about_two_item_icon">
                                    <img src="../../../assets/img/icon/about-2.png" alt="icon">
                                </div>
                                <div class="about_two_item_text">
                                    <h3>Affordable tour packages</h3>
                                    <p>Dolore ullamco voluptate duis est voluptate exercitation officia ad qui nostrud
                                        adipisicing non.</p>
                                </div>
                            </div>
                            <div class="about_two_item">
                                <div class="about_two_item_icon">
                                    <img src="../../../assets/img/icon/about-3.png" alt="icon">
                                </div>
                                <div class="about_two_item_text">
                                    <h3>Explore top places over the world</h3>
                                    <p>Dolore ullamco voluptate duis est voluptate exercitation officia ad qui nostrud
                                        adipisicing non.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                    <div class="about_two_left_img">
                        <img src="../../../assets/img/common/about_two.png" alt="img">
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>
<script>

export default {
    name: "About"
};
</script>
<script setup>
import BaseFlightSearchForm from "@/views/sales/flight/items/form/BaseFlightSearchForm.vue";
import { onMounted, reactive } from "vue";
import BaseFlightFilterForm from "@/views/sales/flight/items/form/BaseFlightFilterForm.vue";
import BaseFlightItemPanel from "@/views/sales/flight/items/panel/BaseFlightItemPanel.vue";
import data from "@/data";
import Banner from "@/components/custom/Banner.vue";

import { useFlightStore } from "@/stores/flight";

let flightStore = useFlightStore();
// ("根据表单对象，搜索机票");
// let res = await flightStore.searchFlight(flightSearchFormModelDummy);
//
// (flightStore.airlowfareSearchRS);
// ("First Way");
// ("通过Pinia获取默认航程的组合，为页面显示的航程列表");
// (flightStore.matchFlightViewItemsGroup);
//
// ("选择第一个航程的飞机");
// let firstKey = Object.keys(flightStore.matchFlightViewItemsGroup)[0];
// (firstKey);
//
// ("根据选择的第一个航程的飞机，更换页面显示的航程列表");
// await flightStore.changeMatchFlightViewItemsGroup(1, firstKey);
// ("Second Way");
// ((flightStore.matchFlightViewItemsGroup));
//
// ("选择第二个航程的飞机，更换页面显示的航程列表");
// let secondKey = Object.keys(flightStore.matchFlightViewItemsGroup)[0];
// (secondKey);
// ("构建preEquateKy");
// let combinedKey = firstKey + secondKey;
// await flightStore.changeMatchFlightViewItemsGroup(2, combinedKey);
// ("Third Way");
// (flightStore.matchFlightViewItemsGroup);
//
// ("选择第三个航程的飞机");
// let thirdKey = Object.keys(flightStore.matchFlightViewItemsGroup)[0];
// let airItineraryRPH =
//   flightStore.matchFlightViewItemsGroup[thirdKey][0].airItineraryRPH;
// (airItineraryRPH);
// ("根据第三个航程的飞机的airItineraryRPH，进行验价操作");
// await flightStore.verifyPrice(airItineraryRPH);
//
// let airPriceRS = flightStore.airPriceRS;
// (airPriceRS);
//
// ("根据验价后的结果，查看飞机各个航程的规则");
// let quoteID = airPriceRS.pricedItineraryList[0].airItineraryPricingInfo.quoteID;
// await flightStore.searchFareRules(quoteID);
// (flightStore.airFareRulesRS);
// (flightStore.flightRuleViewItemMap);

const navigation = reactive({
  title: "Flight Search Result",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Flight Search Result",
    },
  ],
});

let value = reactive([0, 75]);
let items = reactive([]);

onMounted(() => {
  items.push(...data.flightData);
});

function getAirlinePicture(airlineCode) {
  return "http://images.skywidetech.com/airlines/" + airlineCode + ".png";
}
function getAirlineName(airlineCode) {
  let airlineNameKey = "airlineName." + airlineCode;
  return airlineNameKey;
}
function getAirportName(airportCode) {
  let airportNameKey = "airport." + airportCode;
  return airportNameKey;
}
function getAircraftName(aircraftCode) {
  let aircraftNameKey = "aircraft." + aircraftCode;
  return aircraftNameKey;
}
</script>

<template>
  <!-- Common Banner Area -->
  <Banner :navigate="navigation" />
  <!-- Form Area -->
  <section class="fligth_top_search_main_form_wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <!--            <BaseFlightSearchForm />-->
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Flight Search Areas -->

  <section id="explore_area" class="section_padding">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>42 flights found</h2>
            <h2>{{ $t("agency-portal") }}</h2>
            <h2>{{ $t("airport.HAN") }}</h2>
            <h2>{{ $t(getAirportName("HAN")) }}</h2>
            <h2>{{ $t(getAirlineName("VN")) }}</h2>
            <h2>{{ $t(getAircraftName("321")) }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="left_side_search_area">
            <BaseFlightFilterForm />
          </div>
        </div>

        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-12">
              <div class="flight_search_result_wrapper">
                <div
                  v-for="(flightInfos, index) in items"
                  class="flight_search_item_wrappper"
                >
                  <BaseFlightItemPanel :flight-infos="flightInfos" />
                </div>
              </div>
              <div class="load_more_flight">
                <button class="btn btn_md">
                  <i class="fas fa-spinner"></i> Load more..
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <SearchResult />
</template>

<style scoped></style>

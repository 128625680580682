<script setup>
import { computed, reactive, ref, watch, watchEffect } from "vue";
import Banner from "@/components/custom/Banner.vue";
import { useFlightStore } from "@/stores/flight";
import BaseFlightItineraryPanel from "@/views/sales/flight/items/panel/BaseFlightItineraryPanel.vue";
import BaseFlightPriceBreakdownPanel from "@/views/sales/flight/items/panel/BaseFlightPriceBreakdownPanel.vue";
import router from "@/router/modulesRouter";
import { useRoute, useRouter } from "vue-router";

let route = useRoute();
// let router = useRouter();
let query = route.query ? route.query : {};

import { useCmsContentStore } from "@/stores/cms";
import { useTemplateStore } from "@/stores/template";

const templateStore = useTemplateStore();
const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrl.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.flightBackgroundImage
      ? bannerContentStore.productPageBackgroundImage.flightBackgroundImage.url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});
const navigation = reactive({
  title: "Itinerary Review",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Flight Search Result",
      path: "/sales/flight/flight-list",
    },
    {
      name: "Itinerary Review",
    },
  ],
});

let pricedFlightViewItemsGroup = JSON.parse(
  sessionStorage.getItem("pricedFlightViewItemsGroup")
);

let quoteId = ref(sessionStorage.getItem("quoteID"));
let airportCityMap = JSON.parse(sessionStorage.getItem("airportCityMap"));
let offerViewItemMap = JSON.parse(sessionStorage.getItem("offerViewItemMap"));
let passengerTicketFareBreakdownItems = JSON.parse(
  sessionStorage.getItem("passengerTicketFareBreakdownItems")
);
//(airportCityMap);

let flightStore = useFlightStore();
flightStore.updatePricedFlightViewItemsGroup(pricedFlightViewItemsGroup);
flightStore.updateAirportCityMap(airportCityMap);
flightStore.updateOfferViewItemMap(offerViewItemMap);
flightStore.updatePassengerTicketFareBreakdownItems(
  passengerTicketFareBreakdownItems
);
// flightStore.updateFlightPassengersFormModel(flightPassengersFormModel);

let totalAmountContent = computed(() => {
  let totalAmount = 0;
  let currency = "";
  let adultTotalAmount = 0;
  let childTotalAmount = 0;
  let infantTotalAmount = 0;
  let adultNum = 0;
  let childNum = 0;
  let infantNum = 0;
  let taxesTotalAmount = 0;
  if (flightStore.passengerTicketFareBreakdownItems) {
    flightStore.passengerTicketFareBreakdownItems.forEach((breakdownItem) => {
      let totalFare = breakdownItem.totalFare;
      totalAmount += totalFare;
      taxesTotalAmount += breakdownItem.taxes;
      currency = breakdownItem.currency;

      if (breakdownItem.passengerTypeCode == "ADT") {
        adultNum++;
        adultTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "CNN") {
        childNum++;
        childTotalAmount += breakdownItem.baseFare;
      } else if (breakdownItem.passengerTypeCode === "INF") {
        infantNum++;
        infantTotalAmount += breakdownItem.baseFare;
      }
    });
  }
  return {
    totalAmount,
    currency,
    adultTotalAmount,
    childTotalAmount,
    infantTotalAmount,
    infantNum,
    adultNum,
    childNum,
    taxesTotalAmount,
  };
});

function formatOriginDestinations(originDestinations, tripType) {
  let formatArr = [];
  if (tripType && tripType === "roundtrip") {
    originDestinations.forEach((originDestination) => {
      if (originDestination.returnDateTime) {
        let format =
          originDestination.originLocationCode +
          ":" +
          originDestination.originLocationName +
          ":" +
          originDestination.originAirportName +
          ":" +
          originDestination.destinationLocationCode +
          ":" +
          originDestination.destinationLocationName +
          ":" +
          originDestination.destinationAirportName +
          ":" +
          originDestination.departureDateTime +
          ":" +
          originDestination.returnDateTime;

        formatArr.push(format.toString());
      }
    });
  } else {
    originDestinations.forEach((originDestination) => {
      // ("1", originDestination);
      //HKG:Hong kong:SIN:Singapore:2012-12-12
      let format =
        originDestination.originLocationCode +
        ":" +
        originDestination.originLocationName +
        ":" +
        originDestination.originAirportName +
        ":" +
        originDestination.destinationLocationCode +
        ":" +
        originDestination.destinationLocationName +
        ":" +
        originDestination.destinationAirportName +
        ":" +
        originDestination.departureDateTime +
        ":" +
        originDestination.returnDateTime;
      formatArr.push(format.toString());
    });
  }
  return formatArr;
}

function goBack() {
  let flightSearchFormModelCopy = JSON.parse(
    sessionStorage.getItem("flightSearchFormModel")
  );
  let format = formatOriginDestinations(
    flightSearchFormModelCopy.originDestinations,
    flightSearchFormModelCopy.tripType
  );
  flightSearchFormModelCopy.originDestinationsFormat = format;

  router.push({ name: "sales-flight-list", query: flightSearchFormModelCopy });
  // router.back();
}

function goTo() {
  router.push({ name: "sales-flight-passengers" });
}

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <!-- Common Banner Area -->
  <Banner :navigate="navigation" :background-image-url="backgroundImageUrl" />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <h3 class="heading_theme">{{ $t("itinerary-review") }}</h3>
      <div class="row">
        <div class="col-lg-8">
          <div class="tou_booking_form_Wrapper">
            <div
              class="booking_tour_form"
              v-if="
                flightStore.pricedFlightViewItemsGroup &&
                flightStore.airportCityMap
              "
            >
              <BaseFlightItineraryPanel
                :priced-flight-view-items-group="
                  flightStore.pricedFlightViewItemsGroup
                "
                :airport-city-map="flightStore.airportCityMap"
                :offer-view-item-map="flightStore.offerViewItemMap"
                :quote-id="quoteId"
              />
            </div>
            <div
              class="booking_tour_form_submit"
              v-if="equipmentType != 'iphone'"
            >
              <button @click="goBack" class="btn btn_theme">
                {{ $t("go-back") }}
              </button>
              <button
                @click="goTo"
                class="btn btn_theme"
                style="margin-left: 20px"
              >
                {{ $t("continue") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <BaseFlightPriceBreakdownPanel
            v-if="totalAmountContent"
            :total-amount-content="totalAmountContent"
            :show-button="false"
          />
        </div>
        <div class="col-lg-12 text-center" v-if="equipmentType == 'iphone'">
          <button @click="goTo" class="btn btn_theme" style="margin-top: -10px">
            {{ $t("continue") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

<script setup>
import ContactArea from "@/components/templates/contact/ContactArea.vue";
import { useCmsContentStore } from "@/stores/cms";
import { ref, watch } from "vue";

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
cmsContentStore.getContactContent();
const baseUrl = "/api/v3/content/collections/images";
let imageUrl = ref("");
let backgroundImageUrl = ref("");

watch(
  () => cmsContentStore.productPageBackgroundImage,
  (newValue) => {
    imageUrl.value =
      cmsContentStore.productPageBackgroundImage &&
      cmsContentStore.productPageBackgroundImage.contactUsBackgroupImage
        ? cmsContentStore.productPageBackgroundImage.contactUsBackgroupImage.url
        : "";
    backgroundImageUrl.value = baseUrl + imageUrl.value;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("contact-us") }}</h2>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <!-- Contact Area -->
  <ContactArea :contact-info="cmsContentStore.contactContent" />
</template>

<style scoped></style>

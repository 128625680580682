import ContentView from "@/views/content/ContentView.vue";

export default [
  {
    path: "/content",
    name: "content",
    component: ContentView,
    meta: {
      footerShow: true,
    },

  },
];

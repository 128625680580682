import UserProfileVO from "@/viewobject/user/userProfileVO";

//region RQ创建
function getProfileReadRQ(username) {
  return {
    uniqueID: {
      id: username,
      id_context: "USERNAME",
    },
  };
}

function getBasicInformationModifyRQ(profileFormModel) {
  return {
    person: {
      firstName: profileFormModel.givenName,
      lastName: profileFormModel.surname,
      personalTitle: profileFormModel.nameTitle,
      gender: profileFormModel.gender,
      birthDate: profileFormModel.birthDate,
    },
    partyId: profileFormModel.partyId,
  };
}

function getProfileModifyRQ(profiles) {
  const telephoneList = [];
  const documentList = [];
  const custLoyaltyList = [];
  if (profiles.mobileNumber) {
    telephoneList.push({
      phoneTechType: "Mobile",
      phoneNumber: profiles.mobileNumber,
      countryAccessCode: profiles.mobileCountryAccessCode,
    });
  }
  if (profiles.emergencyNumber) {
    telephoneList.push({
      phoneTechType: "Emergency",
      phoneNumber: profiles.emergencyNumber,
      countryAccessCode: profiles.emergencyCountryAccessCode,
    });
  }
  if (profiles.knownTravellerNumber) {
    documentList.push({
      docID: profiles.knownTravellerNumber,
      docType: "KNOWN_TRAVELER_NUMBER",
      expireDate: "",
      docIssueCountry: "",
      docHolderNationality: "",
    });
  }
  if (profiles.redressNumber) {
    documentList.push({
      docID: profiles.redressNumber,
      docType: "REDRESS_NUMBER",
      expireDate: "",
      docIssueCountry: "",
      docHolderNationality: "",
    });
  }
  if (profiles.documents && profiles.documents.length > 0) {
    for (const document of profiles.documents) {
      const docType = "PASSPORT";
      const docID = document.docID;
      const expireDate = document.expireDate;
      const docIssueCountry = document.docIssueCountry;
      const docHolderNationality = document.docHolderNationality;

      if (docID !== null && docID !== "")
        documentList.push({
          docID: docID,
          docType: docType,
          expireDate: expireDate,
          docIssueCountry: docIssueCountry,
          docHolderNationality: docHolderNationality,
        });
    }
  }
  if (profiles.custLoyalties && profiles.custLoyalties.length > 0) {
    for (const custLoyalty of profiles.custLoyalties) {
      const membershipID = custLoyalty.membershipID;
      const programID = custLoyalty.programID;
      const vendorCode = custLoyalty.vendorCode;

      if (membershipID !== null && membershipID !== "")
        custLoyaltyList.push({
          membershipID: membershipID,
          programID: programID,
          vendorCode: vendorCode,
        });
    }
  }
  return {
    profileModify: {
      customer: {
        personName: {
          nameTitle: profiles.nameTitle,
          givenName: profiles.givenName,
          surname: profiles.surname,
        },
        gender: profiles.gender,
        telephoneList: telephoneList,
        documentList: documentList,
        custLoyaltyList: custLoyaltyList,
        emailList: [
          {
            stringValue: profiles.emailAddress,
          },
        ],
        addressList: [
          {
            // addressLines: ["广东", "罗湖"],
            cityName: profiles.city,
            countryName: {
              country: profiles.country,
            },
          },
        ],
      },
      userIDList: [
        {
          pinNumber: profiles.password,
          id: profiles.username,
        },
      ],
    },
  };
}

function getAddAdditionalTravellerRQ(arrangerPartyId, profileFormModel) {
  const telephoneList = [];
  const documentList = [];
  const custLoyaltyList = [];
  if (profileFormModel.mobileNumber) {
    telephoneList.push({
      phoneTechType: "Mobile",
      phoneNumber: profileFormModel.mobileNumber,
      countryAccessCode: profileFormModel.mobileCountryAccessCode,
    });
  }
  if (profileFormModel.emergencyNumber) {
    telephoneList.push({
      phoneTechType: "Emergency",
      phoneNumber: profileFormModel.emergencyNumber,
      countryAccessCode: profileFormModel.emergencyCountryAccessCode,
    });
  }
  if (profileFormModel.knownTravellerNumber) {
    documentList.push({
      docID: profileFormModel.knownTravellerNumber,
      docType: "KNOWN_TRAVELER_NUMBER",
      expireDate: "",
      docIssueCountry: "",
      docHolderNationality: "",
    });
  }
  if (profileFormModel.redressNumber) {
    documentList.push({
      docID: profileFormModel.redressNumber,
      docType: "REDRESS_NUMBER",
      expireDate: "",
      docIssueCountry: "",
      docHolderNationality: "",
    });
  }
  if (profileFormModel.documents && profileFormModel.documents.length > 0) {
    for (const document of profileFormModel.documents) {
      const docType = "PASSPORT";
      const docID = document.docID;
      const expireDate = document.expireDate;
      const docIssueCountry = document.docIssueCountry;
      const docHolderNationality = document.docHolderNationality;

      if (docID !== null && docID !== "")
        documentList.push({
          docID: docID,
          docType: docType,
          expireDate: expireDate,
          docIssueCountry: docIssueCountry,
          docHolderNationality: docHolderNationality,
        });
    }
  }
  if (
    profileFormModel.custLoyalties &&
    profileFormModel.custLoyalties.length > 0
  ) {
    for (const custLoyalty of profileFormModel.custLoyalties) {
      const membershipID = custLoyalty.membershipID;
      const programID = custLoyalty.programID;
      const vendorCode = custLoyalty.vendorCode;

      if (membershipID !== null && membershipID !== "")
        custLoyaltyList.push({
          membershipID: membershipID,
          programID: programID,
          vendorCode: vendorCode,
        });
    }
  }
  return {
    partyTypeId: "PERSON",
    statusId: "PARTY_ENABLED",
    person: {
      firstName: profileFormModel.givenName,
      lastName: profileFormModel.surname,
      personalTitle: profileFormModel.nameTitle,
      gender: profileFormModel.gender,
      birthDate: profileFormModel.birthDate,
    },
    partyRoles: [
      {
        roleTypeId: "CUSTOMER",
      },
    ],
    partyRelationshipFromSet: [
      {
        partyIdTo: arrangerPartyId,
        comments: "B2C - additional traveler",
        partyRelationshipTypeId: "ARRANGER",
      },
    ],
  };
}

//endregion

//region 构建视图对象
function getProfileViewItem(profile, seatPrefs, mealPrefs, documentTypes) {
  const userProfileVO = new UserProfileVO(
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );
  const person = profile.person;
  userProfileVO.username = profile.username;
  userProfileVO.partyId = profile.partyGroup
    ? profile.partyGroup.partyId
    : null;
  const documents = profile.documents;
  const partyContactMechs = profile.partyContactMechs;
  const partyRelationshipToSet = profile.partyRelationshipToSet;
  const custLoyaltySet = profile.custLoyaltySet;
  if (person) {
    userProfileVO.givenName = person.firstName;
    userProfileVO.surname = person.lastName;
    userProfileVO.nameTitle = person.personalTitle;
    userProfileVO.gender = person.gender;
    userProfileVO.birthDate = person.birthDate;
  }

  //根据所有的证件类型，匹配上对应用户信息的证件
  let passportTypeId = null;
  let knownTravellerNumberTypeId = null;
  let redressNumberTypeId = null;
  if (documentTypes && documentTypes.length > 0) {
    passportTypeId = documentTypes.find(
      (item) => item.otaDocCode === "PASSPORT"
    )
      ? documentTypes.find((item) => item.otaDocCode === "PASSPORT")
          .partyDocumentTypeId
      : null;
    knownTravellerNumberTypeId = documentTypes.find(
      (item) => item.otaDocCode === "KNOWN_TRAVELER_NUMBER"
    )
      ? documentTypes.find(
          (item) => item.otaDocCode === "KNOWN_TRAVELER_NUMBER"
        ).partyDocumentTypeId
      : null;
    redressNumberTypeId = documentTypes.find(
      (item) => item.otaDocCode === "REDRESS_NUMBER"
    )
      ? documentTypes.find((item) => item.otaDocCode === "REDRESS_NUMBER")
          .partyDocumentTypeId
      : null;
  }

  if (documents && documents.length > 0) {
    documents.forEach((document) => {
      const partyDocumentId = document.partyDocumentId;
      const docTypeId = document.docTypeId;
      const docID = document.docID;
      const expireDate = document.expireDate;
      const docIssueCountry = document.docIssueCountry;
      const docHolderNationality = document.docHolderNationality;

      if (docTypeId === passportTypeId) {
        //PASSPORT
        userProfileVO.addNewDocument(
          docTypeId,
          docID,
          expireDate,
          docIssueCountry,
          docHolderNationality,
          partyDocumentId
        );
      } else if (docTypeId === knownTravellerNumberTypeId) {
        userProfileVO.knownTravellerNumber = docID;
        userProfileVO.knownTravellerNumberId = partyDocumentId;
      } else if (docTypeId === redressNumberTypeId) {
        userProfileVO.redressNumber = docID;
        userProfileVO.redressNumberId = partyDocumentId;
      }
    });
  }

  if (partyContactMechs && partyContactMechs.length > 0) {
    partyContactMechs.forEach((partyContact) => {
      const partyContactMechId = partyContact.partyContactMechId;
      const telecomNumber = partyContact.contactMech.telecomNumber;
      const email = partyContact.infoString;
      const postalAddress = partyContact.contactMech.postalAddress;
      if (partyContact.contactMechPurposeTypeId === "PHONE_MOBILE") {
        userProfileVO.mobileNumber = telecomNumber.contactNumber;
        userProfileVO.mobileCountryAccessCode = telecomNumber.countryCode;
        userProfileVO.mobileCountryAccessCode = telecomNumber.countryCode;
        userProfileVO.mobileContactMechId = partyContactMechId;
      } else if (partyContact.contactMechPurposeTypeId === "PHONE_HOME") {
        userProfileVO.emergencyNumber = telecomNumber.contactNumber;
        userProfileVO.emergencyCountryAccessCode = telecomNumber.countryCode;
        userProfileVO.emergencyContactName = telecomNumber.askForName; //紧急联系人名字，
        userProfileVO.emergencyContactMechId = partyContactMechId;
      } else if (partyContact.contactMechPurposeTypeId === "PRIMARY_EMAIL") {
        userProfileVO.emailAddress = email;
        userProfileVO.emailContactMechId = partyContactMechId;
      } else if (partyContact.contactMechPurposeTypeId === "PRIMARY_LOCATION") {
        userProfileVO.country = postalAddress.attnName;
        userProfileVO.province = postalAddress.address1;
        userProfileVO.city = postalAddress.city;
        userProfileVO.region = postalAddress.address2;
        userProfileVO.postCode = postalAddress.postalCode;
        userProfileVO.addressContactMechId = partyContactMechId;
      }
    });
  }
  if (partyRelationshipToSet && partyRelationshipToSet.length > 0) {
    userProfileVO.additionalTravellers = partyRelationshipToSet.filter(
      (item) =>
        item.partyRelationshipTypeId === "ARRANGER" ||
        item.comments === "B2C - additional traveler"
    );
  }
  if (custLoyaltySet && custLoyaltySet.length > 0) {
    custLoyaltySet.forEach((custLoyalty) => {
      const programID = custLoyalty.programID;
      const custLoyaltyId = custLoyalty.custLoyaltyId;
      const membershipID = custLoyalty.membershipID;
      userProfileVO.addNewCustLoyalty(programID, membershipID, custLoyaltyId);
    });
  }

  const seatPreferences = [];
  const mealPreferences = [];
  if (seatPrefs && seatPrefs.length > 0) {
    const seatPref = seatPrefs[0];
    if (seatPref) {
      const preference = seatPref.seatPreference;
      const prefId = seatPref.seatPrefId;
      userProfileVO.seatPref = preference;
      userProfileVO.seatPrefId = prefId;
    }

    // seatPrefs.forEach((seatPref) => {
    //   const description = seatPref.description;
    //   const preference = seatPref.seatPreference;
    //   const prefId = seatPref.seatPrefId;
    //   seatPreferences.push({ description, preference, prefId });
    // });
  }
  if (mealPrefs && mealPrefs.length > 0) {
    const mealPref = mealPrefs[0];
    if (mealPref) {
      const preference = mealPref.mealType;
      const prefId = mealPref.mealPrefId;
      userProfileVO.mealPref = preference;
      userProfileVO.mealPrefId = prefId;
    }
    // mealPrefs.forEach((mealPref) => {
    //   const description = mealPref.mealType;
    //   const preference = mealPref.mealType;
    //   const prefId = mealPref.mealPrefId;
    //   mealPreferences.push({ description, preference, prefId });
    // });
  }

  return userProfileVO;
}

//endregion

export default {
  getBasicInformationModifyRQ,
  getProfileReadRQ,
  getProfileModifyRQ,
  getAddAdditionalTravellerRQ,
  getProfileViewItem,
};

<script setup>
import { useI18n } from "vue-i18n";
import { email, required } from "@vuelidate/validators";
import { ref, watch } from "vue";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import useVuelidate from "@vuelidate/core";
import * as regexUtils from "@/utils/regexUtils";
import { adultNameTitleOptions } from "@/formmodel/sales/flight/common/options/flightCommonOption";
import FlatPickr from "vue-flatpickr-component";

const { t, locale } = useI18n(); // 解构调用函数

const props = defineProps({
  carRentalDriverFormModel: {},
});

const carRentalDriverFormModelRef = ref(null);
const adultNameTitleOptionsRef = ref(adultNameTitleOptions);

watch(
  () => props.carRentalDriverFormModel,
  (newValue) => {
    carRentalDriverFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

const surnameRule = (value) => regexUtils.surnameRegx.test(value);

const givenNameRule = (value) => regexUtils.givenNameRegx.test(value);
const phoneRule = (value) =>
  regexUtils.numberRegx.test(value) && regexUtils.phoneNumberRegx.test(value);

const rulesRef = ref({
  nameTitle: { required },
  givenName: { required, givenNameRule },
  surname: { required, surnameRule },
  birthDate: { required },
  // citizenCountry: { required },
  phoneNumber: { required, phoneRule },
  emailAddress: { required, email },
});

const v = useVuelidate(rulesRef.value, carRentalDriverFormModelRef);

function countryChange(value) {
  carRentalDriverFormModelRef.value.countryAccessCode = value.dialCode;
}

//region 电话号码
function phoneNumberInput(value, event) {
  let isPhoneNumber =
    regexUtils.phoneNumberRegx.test(value) && regexUtils.numberRegx.test(value);
  if (
    isPhoneNumber &&
    event != null &&
    event.nationalNumber != null &&
    event.nationalNumber != ""
  ) {
    carRentalDriverFormModelRef.value.phoneNumber = event.nationalNumber;
  } else if (
    isPhoneNumber &&
    event != null &&
    event.nationalNumber == null &&
    event.formatted != null &&
    event.formatted !== ""
  ) {
    carRentalDriverFormModelRef.value.phoneNumber = event.formatted;
  } else if (typeof value === "string") {
    carRentalDriverFormModelRef.value.phoneNumber = value;
  }
}
</script>

<template>
  <div class="tour_details_boxed">
    <h3 class="heading_theme">Driver Details</h3>
    <div class="mg-b-10">
      <form class="mg-up-10" @sumbit.prevent>
        <div class="row">
          <div class="col-10">
            <div class="row">
              <div class="col-3">
                <label class="form-label" for="passenger-form-nameTitle"
                  >{{ $t("name-title") }}
                  <small class="text-lowercase"><code>*</code></small></label
                >

                <select
                  v-model="v.nameTitle.$model"
                  :class="{
                    'is-invalid': v.nameTitle.$errors.length > 0,
                  }"
                  class="form-control"
                  name="passenger-form-nameTitle"
                >
                  <option :value="null" selected>
                    {{ $t("select") }}
                  </option>
                  <option
                    v-for="(
                      nameTitleOption, nameTitleOptionIndex
                    ) in adultNameTitleOptionsRef"
                    :key="nameTitleOptionIndex"
                    :value="nameTitleOption.code"
                  >
                    {{ nameTitleOption.name }}
                  </option>
                </select>
              </div>

              <div class="col-3">
                <label class="form-label" for="passenger-form-surname"
                  >{{ $t("last-name") }}
                  <small class="text-lowercase"><code>*</code></small></label
                >
                <input
                  v-model="v.surname.$model"
                  :class="{
                    'is-invalid': v.surname.$errors.length > 0,
                  }"
                  :placeholder="$t('last-name')"
                  autocomplete="off"
                  class="form-control"
                  name="passenger-form-surname"
                  type="text"
                  @blur="v.surname.$touch"
                />
              </div>
              <div class="col-3">
                <label class="form-label" for="passenger-form-givenName"
                  >{{ $t("first-name") }}
                  <small class="text-lowercase"><code>*</code></small></label
                >
                <input
                  v-model="v.givenName.$model"
                  :class="{
                    'is-invalid': v.givenName.$errors.length,
                  }"
                  :placeholder="$t('first-name')"
                  autocomplete="off"
                  class="form-control"
                  name="passenger-form-givenName"
                  type="text"
                  @blur="v.givenName.$touch"
                />
              </div>
              <div class="col-3">
                <label class="form-label" for="birth-flatpickr"
                  >{{ $t("birth-date") }}
                  <small class="text-lowercase"><code>*</code></small></label
                >
                <FlatPickr
                  v-model="v.birthDate.$model"
                  :class="{
                    'is-invalid': v.birthDate.$errors.length > 0,
                  }"
                  :config="{
                    maxDate: 'today',
                  }"
                  :placeholder="$t('birth-date')"
                  class="form-control"
                  name="birth-flatpickr"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-6">
                <label class="form-label" for="example-text-input"
                  >{{ $t("phone-number") }}
                  <small class="text-lowercase"><code>*</code></small></label
                >
                <VueTelInput
                  :autoFormat="false"
                  :inputOptions="{
                    placeholder: $t('please-enter'),
                    autoFormat: false,
                  }"
                  v-model="v.phoneNumber.$model"
                  :class="{
                    'is-invalid': v.phoneNumber.$errors.length > 0,
                  }"
                  @blur="v.phoneNumber.$touch"
                  @input="phoneNumberInput"
                  @country-changed="countryChange"
                  autocomplete="off"
                  class="form-control flex"
                  mode="international"
                />
              </div>
              <div class="col-lg-6 col-6">
                <label class="form-label" for="example-text-input"
                  >{{ $t("email-address") }}
                  <small class="text-lowercase"><code>*</code></small></label
                >
                <input
                  id="passenger-form-email"
                  :placeholder="$t('please-enter')"
                  v-model="v.emailAddress.$model"
                  :class="{
                    'is-invalid': v.emailAddress.$errors.length > 0,
                  }"
                  @blur="v.emailAddress.$touch"
                  autocomplete="off"
                  class="form-control"
                  name="passenger-form-email"
                  type="email"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

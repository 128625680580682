<script setup>
import { ref, watchEffect } from "vue";

const props = defineProps({
  backgroundImageUrl: {},
  headerContent: {},
});
let backgroundSizeRef = ref(null);
let paddingRef = ref(null);
watchEffect(() => {
  if (props.headerContent) {
    if (props.headerContent.bannerHeight) {
      backgroundSizeRef.value = props.headerContent.bannerHeight + "px";
    }
    if (props.headerContent.padding) {
      paddingRef.value = props.headerContent.padding;
    }
  }
});
</script>
<template>
  <div
    id="home_one_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
      height: backgroundSizeRef,
      padding: paddingRef,
    }"
  >
    <div v-if="headerContent" class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="banner_one_text">
            <h1>{{ headerContent.title }}</h1>
            <h3>{{ headerContent.subtitle }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  <div-->
  <!--    v-else-->
  <!--    id="home_one_banner"-->
  <!--    :style="{-->
  <!--      height: backgroundSizeRef,-->
  <!--    }"-->
  <!--  >-->
  <!--    <div class="container" v-if="headerContent">-->
  <!--      <div class="row align-items-center">-->
  <!--        <div class="col-lg-12">-->
  <!--          <div class="banner_one_text">-->
  <!--            <h1>{{ headerContent.title }}</h1>-->
  <!--            <h3>{{ headerContent.subtitle }}</h3>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

import { defineStore } from "pinia";
import paymentApi from "@/apis/paymentApi";

export const usePaymentStore = defineStore("payment", {
  state: () => ({
    saveNecessaryInfoRS: null, //ttiCode -> hotelViewItem 的映射表
  }),
  actions: {
    async saveNecessaryInfo(invoiceId, productType, bookingRQ) {
      return paymentApi
        .saveNecessaryInfo(invoiceId, productType, bookingRQ)
        .then((res) => {
          // (res.data);
        });
    },
  },
});

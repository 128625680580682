export class CarRentalEquipItemVO {
  /**
   *
   * @param mandatory
   * @param selected
   * @param name
   * @param code
   * @param chargeType RENTAL 指计算价格单位为每次租赁， DAY指计算价格单位为每天
   * @param amount
   * @param currency
   * @param quantity
   */
  constructor(
    mandatory,
    selected,
    name,
    code,
    chargeType,
    amount,
    currency,
    quantity
  ) {
    this.mandatory = mandatory;
    this.selected = selected;
    this.name = name;
    this.code = code;
    this.chargeType = chargeType;
    this.amount = amount;
    this.currency = currency;
    this.quantity = quantity;
  }
}

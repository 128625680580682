<template>

  <!-- Banner Area -->
  <Banner />

  <!-- Form Area -->
  <Form />

  <!-- imagination Area -->
  <Imagination />

 

  <!-- Top destinations -->
  <TopDestinations />


  <!-- Explore our hot deals -->
  <ExploreDeals />

  <!-- Offer Area -->
  <Offer />

  <!--Promotional Tours Area -->
  <PromotionalTours />

  <!-- Destinations Area -->
  <Destinations />

  <!-- News Area -->
  <News />

  <!-- Our partners Area -->
  <Partners />

  <!-- Cta Area -->
  <Cta />

</template>

<script>
// @ is an alias to /src
import Banner from '@/components/templates/home/Banner.vue'
import Form from '@/components/templates/home/Form.vue'
import Imagination from '@/components/templates/home/Imagination.vue'
import TopDestinations from '@/components/templates/home/TopDestinations.vue'
import ExploreDeals from '@/components/templates/home/ExploreDeals.vue'
import Offer from '@/components/templates/home/Offer.vue'
import PromotionalTours from '@/components/templates/home/PromotionalTours.vue'
import Destinations from '@/components/templates/home/Destinations.vue'
import News from '@/components/templates/home/News.vue'
import Partners from '@/components/templates/home/Partners.vue'
import Cta from '@/components/templates/home/Cta.vue'



export default {
  name: 'HomeView',
  components: {
    Banner, Form, Imagination, TopDestinations, ExploreDeals, Offer, PromotionalTours, Destinations, News, Partners, Cta
  }
}
</script>
import axios from "@/utils/axiosUtils";

function airBookModify(airBookModifyRQ) {
  let url = "/api/v3/sales/shopping/air/air-book-modify";
  return axios.post(url, airBookModifyRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function airReshop(airReshopRQ) {
  let url = "/api/v3/sales/shopping/air/air-reshop";
  return axios.post(url, airReshopRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}


export default {
  airReshop,
  airBookModify
}
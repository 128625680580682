/**
 *
 * @param hotelItems Array，HotelItemVO对象数组，数据源
 * @param filterFormModel Object，HotelResultsFilterFormModel对象，包含过滤条件
 * @returns hotelItems Array，HotelItemVO对象数组，过滤后的数组
 */
function filterHotelItems(hotelItems, filterFormModel) {
  if (!hotelItems || hotelItems.length === 0) return hotelItems;
  const hotelItemsCopy = JSON.parse(JSON.stringify(hotelItems));

  const hotelName = filterFormModel.hotelName;
  const ttiCode = filterFormModel.ttiCode;
  const starRatings = filterFormModel.starRatings;
  const userRating = filterFormModel.userRating;
  const propertyClass = filterFormModel.propertyClass;
  const mealPlans = filterFormModel.mealPlans;
  const priceRange = filterFormModel.priceRange;

  return hotelItemsCopy.filter((hotelItem) => {
    const starRatingCompare = hotelItem.starRating;
    const userRatingCompare = hotelItem.userRating;
    const ttiCodeCompare = hotelItem.ttiCode;
    const hotelNameCompare = hotelItem.hotelName;
    const propertyNamesCompare = hotelItem.propertyNames;
    const bestAvgUnitPriceCompare = hotelItem.bestAvgUnitPrice;
    const bestTotalPriceCompare = hotelItem.bestTotalPrice;
    const roomTypes = hotelItem.roomTypes;

    //根据ttiCode过滤
    if (ttiCode && ttiCode !== "") {
      if (ttiCode !== ttiCodeCompare) return false;
    }

    //根据酒店名过滤
    if (hotelName && hotelName !== "") {
      // if (hotelNameCompare.toUpperCase().indexOf(hotelName.toUpperCase()) < 0)
      //   return false;
      if (propertyNamesCompare && propertyNamesCompare.length > 0) {
        let contain = propertyNamesCompare.some(
          (item) =>
            item.name.toUpperCase().indexOf(hotelName.toUpperCase()) !== -1
        );

        if (!contain) return false;
      }
    }

    //根据mealPlans筛选
    if (mealPlans && mealPlans.length > 0) {
      let oneMatch = true;
      for (const roomType of roomTypes) {
        let rooms = roomType.rooms;
        for (const room of rooms) {
          for (const mealPlanCode of mealPlans) {
            switch (mealPlanCode) {
              case "1": //全部包含
                oneMatch =
                  room.mealPlan.breakfast &&
                  room.mealPlan.lunch &&
                  room.mealPlan.dinner;
                break;
              case "19": //包含早餐
                oneMatch = room.mealPlan.breakfast;
                break;
              case "21": //包含午餐
                oneMatch = room.mealPlan.lunch;
                break;
              case "22": //包含晚餐
                oneMatch = room.mealPlan.dinner;
                break;
              default:
            }
          }
        }
      }
      if (!oneMatch) return false;
    }

    //根据starRating筛选
    if (starRatings && starRatings.length > 0) {
      let oneMatch = false;
      for (const starRating of starRatings) {
        if (starRating === starRatingCompare) {
          oneMatch = true;
        }
      }
      if (!oneMatch) return false;
    }

    //根据Guest Rating筛选
    if (userRating && userRating !== "") {
      if (userRatingCompare < userRating) return false;
    }

    //根据平均每天房价范围过滤
    if (priceRange && priceRange.length > 0) {
      const minDailyPrice = priceRange[0];
      const maxDailyPrice = priceRange[1];

      if (bestAvgUnitPriceCompare < minDailyPrice) return false;
      if (maxDailyPrice !== 1000 && bestAvgUnitPriceCompare > maxDailyPrice)
        return false;
    }

    return true;
  });
}

export default {
  filterHotelItems,
};

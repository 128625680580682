import { HotelPolicyVO } from "@/viewobject/sales/hotel/availability/hotelPolicyVO";
import { HotelRoomTypeVO } from "@/viewobject/sales/hotel/availability/hotelRoomTypeVO";

export class TripHotelItemVO {
  /**
   *
   * @param tripItemStatus  string，该酒店订单状态
   * @param createdDateTime
   * @param tripItemId number，对应gallop中bookingItem的id
   * @param tripHotelItemId number，对应gallop中hotel item的id
   * @param recordLocatorId string，对应供应商系统中的订单id
   * @param idContext string，对应供应商系统的代码
   * @param supplierCode string，供应商代码
   * @param passengers Array，TripHotelPassengerItemVO对象数组
   * @param mainImage
   * @param hotelName
   * @param cityName
   * @param starRating
   * @param userRating
   * @param address
   * @param currencyCode
   * @param unitPrice
   * @param totalPrice
   * @param promotionDescription
   * @param longitude
   * @param latitude
   * @param ttiCode
   * @param description
   * @param roomQuantity
   * @param roomTypes
   * @param amenities
   * @param policies
   * @param outOfPolicy
   * @param approvalRequired
   * @param reasonRequired
   * @param reasons
   * @param bookingHotelItemIds
   */
  constructor(
    invoiceId,
    tripItemStatus,
    createdDateTime,
    tripItemId,
    tripHotelItemId,
    recordLocatorId,
    idContext,
    supplierCode,
    passengers,
    mainImage,
    hotelName,
    cityName,
    starRating,
    userRating,
    address,
    currencyCode,
    unitPrice,
    totalPrice,
    promotionDescription,
    longitude,
    latitude,
    ttiCode,
    description,
    roomQuantity,
    roomTypes,
    amenities,
    policies,
    outOfPolicy,
    approvalRequired,
    reasonRequired,
    reasons,
    bookingHotelItemIds
  ) {
    this.invoiceId = invoiceId;
    this.tripItemStatus = tripItemStatus;
    this.createdDateTime = createdDateTime;
    this.tripItemId = tripItemId;
    this.tripHotelItemId = tripHotelItemId;
    this.recordLocatorId = recordLocatorId;
    this.idContext = idContext;
    this.supplierCode = supplierCode;
    this.passengers = passengers;
    this.mainImage = mainImage;
    this.hotelName = hotelName;
    this.cityName = cityName;
    this.starRating = starRating;
    this.userRating = userRating;
    this.address = address;
    this.currencyCode = currencyCode;
    this.unitPrice = unitPrice;
    this.totalPrice = totalPrice;
    this.promotionDescription = promotionDescription;
    this.longitude = longitude;
    this.latitude = latitude;
    this.ttiCode = ttiCode;
    this.description = description;
    this.roomQuantity = roomQuantity;
    this.roomTypes = roomTypes;
    this.amenities = amenities;
    this.policies = policies;
    this.outOfPolicy = outOfPolicy;
    this.approvalRequired = approvalRequired;
    this.reasonRequired = reasonRequired;
    this.reasons = reasons;
    this.bookingHotelItemIds = bookingHotelItemIds;
  }

  addSupplierCode(supplierCode) {
    if (this.supplierCodes == null) this.supplierCodes = [];
    if (this.supplierCodes.indexOf(supplierCode) >= 0) return;

    this.supplierCodes.push(supplierCode);
  }

  addNewPolicy(code, name, contents) {
    let hotelPolicyVO = new HotelPolicyVO(code, name, contents);
    if (this.policies == null) this.policies = [];
    this.policies.push(hotelPolicyVO);
    return hotelPolicyVO;
  }

  addNewRoomType(
    roomTypeName,
    roomTypeCode,
    ttiCode,
    roomDescription,
    minOccupancy,
    maxOccupancy,
    amenities,
    rooms
  ) {
    const roomTypeVO = new HotelRoomTypeVO(
      roomTypeName,
      roomTypeCode,
      ttiCode,
      roomDescription,
      minOccupancy,
      maxOccupancy,
      amenities,
      rooms
    );
    if (this.roomTypes == null) this.roomTypes = [];
    this.roomTypes.push(roomTypeVO);

    return roomTypeVO;
  }

  addRoomType(roomTypeVO) {
    if (this.roomTypes == null) this.roomTypes = [];
    this.roomTypes.push(roomTypeVO);
    return roomTypeVO;
  }

  addPassenger(passenger) {
    if (this.passengers == null) this.passengers = [];
    this.passengers.push(passenger);
    return passenger;
  }
}

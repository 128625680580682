import axios from "../utils/axiosUtils";

/*
 * 酒店地点自动补全
 * @param keyword string 必填，地点关键字，自动补全采用prefix匹配模式
 * @param language string 可选，响应内容的语言内容，默认为en
 */
function hotelPlaces(keyword, language) {
  language = language == null ? "en" : language;
  let url = "/api/v3/content/hotel/places";
  return axios.get(url, {
    params: {
      language: language,
      keyword: keyword,
      type: "http",
    },
  });
}

function childrenPlaces(placeId) {
  let url = "/api/v3/content/hotel/places/" + placeId + "/children";
  return axios.get(url);
}

/*
 * 飞机地点自动补全
 * @param keyword string 必填，地点关键字，自动补全采用prefix匹配模式
 * @param language string 可选，响应内容的语言内容，默认为en
 */
function airportCities(keyword, language) {
  language = language == null ? "en" : language;
  let url = "/api/v3/content/air/airport-cities";
  return axios.get(url, {
    params: {
      language: language,
      keyword: keyword,
      type: "http",
    },
  });
}

function frequentFlyerPrograms() {
  let url = "/api/v3/content/air/frequent-flyer-programs";
  return axios.get(url);
}

function airportCity(airportCode) {
  let url = "/api/v3/content/air/airportcity/" + airportCode;
  return axios.get(url);
}

function airportCountryCode(code) {
  let url = "/api/v3/content/air/airportcity/" + code;
  return axios.get(url);
}

function passport(from, to) {
  let url = "/api/v3/content/traveldoc/passport-required";
  return axios.get(url, {
    params: {
      fromCountryCode: from,
      toCountryCode: to,
    },
  });
}

function countries(keyword) {
  let url = "/api/v3/content/common/countries/autocomplete";
  return axios.get(url, {
    params: {
      language: "en",
      keyword: keyword,
      type: "http",
    },
  });
}

export default {
  hotelPlaces,
  childrenPlaces,
  airportCities,
  airportCity,
  frequentFlyerPrograms,
  airportCountryCode,
  passport,
  countries,
};

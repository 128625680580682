function buildDynamicPkgCarRentalRatesRQ(dynamicPkgAvailFormModel) {
  const ttiCode = dynamicPkgAvailFormModel.tticode;
  const locationType = dynamicPkgAvailFormModel.locationType;
  const originLocation = dynamicPkgAvailFormModel.originLocationCode;
  const destinationLocation = dynamicPkgAvailFormModel.destinationLocationCode;
  const checkInDate = dynamicPkgAvailFormModel.checkInDate;
  const checkOutDate = dynamicPkgAvailFormModel.checkOutDate;
  const roomPassengers = dynamicPkgAvailFormModel.roomPassengers;
  const referenceId = dynamicPkgAvailFormModel.referenceId;
  const cabinTypeCode = dynamicPkgAvailFormModel.cabinTypeCode;
  const airItineraryRPH = dynamicPkgAvailFormModel.airItineraryRPH;
  const preferredCarType = dynamicPkgAvailFormModel.preferredCarType;

  let hotelRef = {
    hotelCityCode: null,
    hotelCodeContext: "PLACE",
  };

  if ("AIRPORT" === locationType) {
    hotelRef.hotelCodeContext = "AIRPORT";
  }

  if ("HOTEL" === locationType) {
    hotelRef.tticode = ttiCode;
    hotelRef.hotelCodeContext = "HOTEL";
  } else {
    hotelRef.hotelCityCode = destinationLocation;
  }
  const passengerTypeQuantities = [];
  let roomStayCandidates = [];

  if (roomPassengers && roomPassengers.length > 0) {
    roomPassengers.forEach((roomPassenger) => {
      let adultNum = roomPassenger.adultNum;
      passengerTypeQuantities.push({
        code: "ADT",
        quantity: adultNum,
      });
      let childNum = roomPassenger.childNum;

      if (childNum > 0)
        passengerTypeQuantities.push({
          code: "CNN",
          quantity: childNum,
        });
      let roomStayCandidate = {
        roomTypes: [
          {
            quantity: 1,
          },
        ],
        guestCounts: {
          guestCountList: [
            {
              ageQualifyingCode: "10",
              count: adultNum,
            },
          ],
        },
        reference: {
          type: "UUID",
          id: referenceId,
        },
      };

      roomStayCandidates.push(roomStayCandidate);
      let childAges = roomPassenger.childAges;
      if (childAges != null && childAges.length > 0) {
        childAges.forEach((childAge) => {
          let age = childAge.age;
          let childGuest = {
            ageQualifyingCode: "8",
            age: age,
            count: 1,
          };
          roomStayCandidate.guestCounts.guestCountList.push(childGuest);
        });
      }
    });
  }

  return {
    searchCriteria: {
      carSearch: [
        {
          vehicleAvailRQCore: {
            status: "rate",
            vehRentalCore: {
              pickUpDateTime: checkInDate + "T10:00",
              returnDateTime: checkOutDate + "T10:00",
              pickUpLocations: [
                {
                  locationCode: "LCYT01",
                },
              ],
              returnLocations: [
                {
                  locationCode: "LCYT01",
                },
              ],
            },
            vehPrefs: [
              {
                vehClass: {
                  vehicleClassCode: preferredCarType,
                },
              },
            ],
          },
          vehicleAvailRQInfo: {
            customer: {
              primary: {
                citizenCountryName: {
                  // "code": "HK"
                },
              },
            },
          },
        },
      ],
    },
    dynamicPackage: {
      components: {
        hotelComponents: [
          {
            roomStayList: roomStayCandidates,
          },
        ],
        airComponents: [
          {
            airItinerary: {
              airItineraryRPH: airItineraryRPH,
            },
            quoteID: airItineraryRPH,
          },
        ],
      },
    },
  };
}

export default {
  buildDynamicPkgCarRentalRatesRQ,
};

<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
// import { useSecurityStore } from "@/stores/security";
import useVuelidate from "@vuelidate/core";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { email, integer, minLength, required } from "@vuelidate/validators";
import securityTools from "@/tools/securityTools";
import securityApi from "@/apis/securityApi";
import { useTemplateStore } from "@/stores/template";
import * as regexUtils from "@/utils/regexUtils";

const templateStore = useTemplateStore();
// Vuelidate, for more info and examples you can check out https://github.com/vuelidate/vuelidate

// Main store and Router
const router = useRouter();
const route = useRoute();
// const securityStore = useSecurityStore();

// Input state variables
let state = reactive({
  firstname: "",
  lastname: "",
  email: "",
  phoneNumber: null,
  password: null,
  username: null,
});

defineProps({
  isLoginPage: Boolean,
});

let regString = /^[A-Za-z]*(\s[A-Za-z]*)*$/;
let regNumber = /^[0-9]*$/;
const passwordRule = (value) => regexUtils.passwordRegx.test(value);
const mustBeString = (value) => regString.test(value);
const mustBeNumber = (value) => regNumber.test(value);
// Validation rules
//自定义正则表达式校验器
const rules = computed(() => {
  return {
    username: {
      required,
      minLength: minLength(3),
    },
    password: {
      required,
      passwordRule,
    },
    firstname: {
      required,
      mustBeString,
      minLength: minLength(2),
    },
    lastname: {
      required,
      mustBeString,
      minLength: minLength(2),
    },
    email: {
      required,
      email,
    },
    phoneNumber: {
      required,
      mustBeNumber,
      integer,
    },
    countryAccessCode: {
      required,
    },
  };
});

// Use vuelidate
const v$ = useVuelidate(rules, state);

function selectcountryAccessCode(ele) {
  //(ele.target.value);
  //(resGuestModel);
}

function countryChange(value) {
  state.countryAccessCode = value.dialCode;
}

function phoneNUmberInput(value, event) {
  //(value);
  //(typeof value);

  if (value && typeof value !== "object") {
    state.phoneNumber = value;
    validatePhoneNumber();
  }
  // if (
  //   event != null &&
  //   event.nationalNumber != null &&
  //   event.nationalNumber != ""
  // ) {
  //   (event.nationalNumber);
  //   state.phoneNumber = event.nationalNumber;
  // } else if (
  //   event != null &&
  //   event.nationalNumber == null &&
  //   event.formatted != null
  // ) {
  //   (event.formatted);
  //   state.phoneNumber = event.formatted;
  // }
}

function phoneBlur() {
  validatePhoneNumber();
}

function validatePhoneNumber() {
  v$.value.phoneNumber.$touch();
  if (v$.value.phoneNumber.$errors.length > 0) {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.add("is-invalid");
      }
    }
  } else {
    let phoneEle = document.getElementById("phoneEle");
    for (let i = 0; i < phoneEle.children.length; i++) {
      let childListElement = phoneEle.children[i];
      if (childListElement.tagName === "INPUT") {
        childListElement.classList.remove("is-invalid");
      }
    }
  }
}

/**
 * Sign Up
 */
let notifyMessage = ref(null);
let alterType = ref(null);

async function signUp() {
  const result = await v$.value.$validate();
  validatePhoneNumber();
  if (!result) {
    // notify user form is invalid
    return;
  }

  templateStore.pageLoader({ mode: "on" });
  let createUserRQ = securityTools.getCreateUserRQ(
    state.firstname,
    state.lastname,
    state.email,
    state.phoneNumber,
    state.countryAccessCode,
    state.username,
    state.password
  );
  let domain = window.location.protocol + "//" + window.location.host + "/api";
  let res = await securityApi.signUp(createUserRQ, domain);
  templateStore.pageLoader({ mode: "off" });
  if (res.data.success) {
    openAlert(
      "Sign up successfully, and you need to active your account by the link we just sent to you ",
      "success"
    );
    state = reactive({
      firstname: null,
      lastname: null,
      email: null,
      phoneNumber: null,
      password: null,
      username: null,
    });
  } else {
    let shortText = res.data.errors.errors[0].shortText;
    openAlert(shortText, "failed");
  }
  // Go to dashboard
  // router.push({ name: "security-sign-in" });
}

function openAlert(message, type) {
  const errorAlert = document.getElementById(type + "notifyMessageAlert");
  errorAlert.classList.add("show");
  notifyMessage.value = message;
  alterType.value = type;
  setTimeout(() => {
    errorAlert.classList.remove("show");
  }, 15000);
}

onMounted(() => {
  let phoneEle = document.getElementById("phoneEle");
  phoneEle.style.border = "none";
  for (let i = 0; i < phoneEle.children.length; i++) {
    let childListElement = phoneEle.children[i];
    // (childListElement);
    // (childListElement.tagName);
    if (childListElement.tagName === "INPUT") {
      childListElement.classList.add("form-control");
    }
  }
});
</script>

<template>
  <!-- ALERT -->
  <div
    v-show="notifyMessage && 'success' === alterType"
    id="successnotifyMessageAlert"
    class="alert alert-dismissible alert-success fade"
    role="alert"
  >
    <p class="mb-0 text-black">
      {{ notifyMessage }}
    </p>
    <button
      aria-label="Close"
      class="btn-close"
      data-bs-dismiss="alert"
      type="button"
    ></button>
  </div>
  <div
    v-show="notifyMessage && 'failed' === alterType"
    id="failednotifyMessageAlert"
    class="alert alert-dismissible alert-danger fade"
    role="alert"
  >
    <p v-if="notifyMessage" class="mb-0">
      {{ notifyMessage }}
    </p>
    <button
      aria-label="Close"
      class="btn-close"
      data-bs-dismiss="alert"
      type="button"
    ></button>
  </div>
  <!-- ALERT -->
  <form id="main_author_form" action="#" @submit.prevent="signUp">
    <div class="form-group">
      <input
        id="regis-firstname"
        v-model="state.firstname"
        :class="{
          'is-invalid': v$.firstname.$errors.length,
        }"
        :placeholder="$t('enter-your-first-name')"
        class="form-control"
        name="regis-firstname"
        type="text"
        @blur="v$.firstname.$touch"
      />
      <div
        v-if="v$.firstname.$errors.length"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("please-enter-a-valid-firstname") }}
      </div>
    </div>
    <div class="form-group">
      <input
        id="regis-lastname"
        v-model="state.lastname"
        :class="{
          'is-invalid': v$.lastname.$errors.length,
        }"
        :placeholder="$t('enter-your-last-name')"
        class="form-control"
        name="regis-lastname"
        type="text"
        @blur="v$.lastname.$touch"
      />
      <div
        v-if="v$.lastname.$errors.length"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("please-enter-a-valid-lastname") }}
      </div>
    </div>
    <div class="form-group">
      <input
        id="regis-email"
        v-model="state.email"
        :class="{
          'is-invalid': v$.email.$errors.length,
        }"
        :placeholder="$t('enter-your-email-address')"
        class="form-control"
        name="regis-email"
        type="text"
        @blur="v$.email.$touch"
      />
      <div
        v-if="v$.email.$errors.length"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("please-enter-a-valid-email") }}
      </div>
    </div>
    <div class="form-group">
      <VueTelInput
        id="phoneEle"
        v-model="state.phoneNumber"
        :autoFormat="false"
        :class="{
          'is-invalid': v$.phoneNumber.$errors.length > 0,
        }"
        :inputOptions="{
          placeholder: $t('enter-your-phone-number'),
          autocomplete: false,
        }"
        mode="international"
        @blur="phoneBlur"
        @input="phoneNUmberInput"
        @country-changed="countryChange"
      />
      <div
        v-if="v$.phoneNumber.$errors.length"
        id="invalid-feedback"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("please-enter-a-valid-phone-number") }}
      </div>
    </div>
    <div class="form-group">
      <input
        id="regis-username"
        v-model="state.username"
        :class="{
          'is-invalid': v$.username.$errors.length,
        }"
        :placeholder="$t('enter-your-username')"
        class="form-control"
        name="regis-username"
        type="text"
        @blur="v$.username.$touch"
      />
      <div
        v-if="v$.username.$errors.length"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("please-enter-a-valid-username") }}
      </div>
    </div>
    <div class="form-group">
      <input
        id="regis-password"
        v-model="state.password"
        :class="{
          'is-invalid': v$.password.$errors.length,
        }"
        :placeholder="$t('enter-your-password')"
        class="form-control"
        name="regis-password"
        type="password"
        @blur="v$.password.$touch"
      />
      <div
        v-if="v$.password.$errors.length"
        class="invalid-feedback animated fadeIn"
      >
        {{ $t("password-enter-warning") }}
      </div>
    </div>
    <div class="common_form_submit">
      <button class="btn btn_theme">{{ $t("register") }}</button>
    </div>
    <div class="have_acount_area other_author_option">
      <div class="line_or">
        <span>{{ $t("or") }}</span>
      </div>
      <ul>
        <li>
          <a href="#!"><img alt="icon" src="@/assets/img/icon/google.png" /></a>
        </li>
        <li>
          <a href="#!"
            ><img alt="icon" src="@/assets/img/icon/facebook.png"
          /></a>
        </li>
        <li>
          <a href="#!"
            ><img alt="icon" src="@/assets/img/icon/twitter.png"
          /></a>
        </li>
      </ul>
      <p>
        {{ $t("already-have-an-account") }}
        <router-link class="main_color" to="/security/sign-in"
          >{{ $t("sign-in-now") }}
        </router-link>
      </p>
    </div>
  </form>
</template>

<style scoped></style>

import PackagePassengersView from "@/views/sales/package/PackagePassengersView.vue";
import PackageBookingPreviewView from "@/views/sales/package/PackageBookingPreviewView.vue";
import PackageHomeView from "@/views/sales/package/PackageHomeView.vue";
import PackageHotelListView from "@/views/sales/package/PackageHotelListView.vue";
import PackageHotelDetailsView from "@/views/sales/package/PackageHotelDetailsView.vue";
import PackageFlightListView from "@/views/sales/package/PackageFlightListView.vue";
import PackageFlightTicketTypeView from "@/views/sales/package/PackageFlightTicketTypeView.vue";
import PackageHotelPhotosView from "@/views/sales/package/PackageHotelPhotosView.vue";
import PackageItineraryReviewView from "@/views/sales/package/PackageItineraryReviewView.vue";
import PackageHotelListViewDemo from "@/views/sales/package/PackageHotelListViewDemo.vue";

export default [
  {
    path: "/sales/package",
    redirect: "/sales/package/package-home",
    meta: {
      requiresAuth: false, //此时表示进入这个路由是需要登录的
    },
    children: [
      {
        path: "package-home",
        name: "sales-package-home",
        meta: { requiresAuth: false,footerShow:true},

        component: PackageHomeView,

      },
      {
        path: "hotel-list",
        name: "sales-package-hotel-list",
        component: PackageHotelListView,
        meta: { requiresAuth: false },
      },
      {
        path: "hotel-list-demo",
        name: "sales-package-hotel-list-demo",
        component: PackageHotelListViewDemo,
        meta: { requiresAuth: false },
      },
      {
        path: "hotel-details",
        name: "sales-package-hotel-details",
        component: PackageHotelDetailsView,
        meta: { requiresAuth: false },
      },
      {
        path: "flight-list",
        name: "sales-package-flight-list",
        component: PackageFlightListView,
        meta: { requiresAuth: false },
      },
      {
        path: "itinerary-review",
        name: "sales-package-itinerary-review",
        component: PackageItineraryReviewView,
        meta: { requiresAuth: false },
      },
      {
        path: "flight-ticket-type",
        name: "sales-package-flight-ticket-type",
        component: PackageFlightTicketTypeView,
        meta: { requiresAuth: false },
      },
      {
        path: "passengers",
        name: "sales-package-passengers",
        component: PackagePassengersView,
        meta: { requiresAuth: false },
      },
      {
        path: "booking-preview",
        name: "sales-package-booking-preview",
        component: PackageBookingPreviewView,
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/sales/content",
    redirect: "/sales/content/package-hotel-photos",
    children: [
      {
        path: "hotel-photos",
        name: "content-package-hotel-photos",
        component: PackageHotelPhotosView,
        meta: { requiresAuth: false },
      },
    ],
  },
];

<template>

    <!-- Common Banner Area -->
    <FlightBanner />

    <!-- Dashboard Area -->
    <FlightDashboard />

    <!-- Cta Area -->
    <Cta />

</template>
<script>
import FlightBanner from '@/components/templates/dashboard/FlightBanner.vue'
import FlightDashboard from '@/components/templates/dashboard/FlightDashboard.vue'
import Cta from '@/components/templates/home/Cta.vue'

export default {
    name: "FlightBookingView",
    components: {
        FlightBanner, FlightDashboard, Cta
    }
};
</script>
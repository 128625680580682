export class HotelAvailFormModel {
  /**
   *
   * @param bestOnlyIndicator boolean true仅返回最优价格房间，false返回全部匹配房间
   * @param languageCode string 参照ISO639，中文有zh-CN,zh-HK,zh-TW等
   * @param locationType string  地点类型，用来标识locationCode，例如PLACE为place系统id，AIRPORT为IATA AIR系统id，HOTEL为IATA TTI系统id
   * @param locationCode string 地点代码
   * @param locationName string  地点名
   * @param checkInDate string  入住日期
   * @param checkOutDate string 离店日期
   * @param roomPassengers Array RoomPassengerFormItem数组
   */
  constructor(
    bestOnlyIndicator,
    languageCode,
    locationType,
    locationCode,
    locationName,
    checkInDate,
    checkOutDate,
    roomPassengers
  ) {
    this.bestOnlyIndicator = bestOnlyIndicator;
    this.languageCode = languageCode;
    this.locationType = locationType;
    this.locationCode = locationCode;
    this.locationName = locationName;
    this.checkInDate = checkInDate;
    this.checkOutDate = checkOutDate;
    this.roomPassengers = roomPassengers;
  }

  addNewRoomPassenger(adultNum, childNum, childAges) {
    // let roomPassenger = new HotelAvailRoomPassengerFormItem(1, 0, []);
    if (!this.roomPassengers) this.roomPassengers = [];

    if (this.roomPassengers.length === 0) {
      let roomPassenger = new HotelAvailRoomPassengerFormItem(
        adultNum,
        childNum,
        childAges
      );
      this.roomPassengers.push(roomPassenger);
    } else {
      const roomPassenger = this.roomPassengers[0];
      this.roomPassengers.push(roomPassenger);
    }
  }

  removeRoomPassenger(index) {
    if (this.roomPassengers.length === 1) return;
    this.roomPassengers.splice(index, 1);
  }
}

class HotelAvailRoomPassengerFormItem {
  /*
   * @param childAges number类型数组 [6,8,9]
   */
  constructor(adultNum, childNum, childAges) {
    this.adultNum = adultNum;
    this.childNum = childNum;
    this.childAges = childAges;
  }

  addChildNum() {
    this.childNum++;
    this.childAges.push({ age: null });
  }

  removeChildNum() {
    if (this.childNum === 0) return;
    this.childAges.splice(this.childAges.length - 1, 1);
    this.childNum--;
  }
}

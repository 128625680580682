<script setup>
import { reactive } from "vue";
import FlatPickr from "vue-flatpickr-component";
import dateUtils from "@/utils/dateUtils";

defineProps({
  equipmentType: {},
});
// Flatpickr variables
const flatPickrState = reactive({
  // Initial values
  dateRange: null,

  // Configuration, get more form https://chmln.github.io/flatpickr/options/
  configRange: {
    mode: "range",
    maxDate: "today",
  },
});

let initiateData = {
  start: null,
  end: null,
  status: "",
  limit: 10,
  offset: 0,
};

const bookingSearchFormModel = reactive({ ...initiateData });

function chooseDates(dates) {
  if (dates.length == 2) {
    let start = dateUtils.format(dates[0]);
    let end = dateUtils.format(dates[1]);
    bookingSearchFormModel.start = start;
    bookingSearchFormModel.end = end;
  } else {
    bookingSearchFormModel.start = null;
    bookingSearchFormModel.end = null;
  }
}

function resetFormModel() {
  flatPickrState.dateRange = null;
  bookingSearchFormModel.status = "";
}
</script>

<template>
  <div class="content" style="margin-top: 20px">
    <div class="row mb-2">
      <div class="col-lg-12 col-12">
        <!-- Form Inline - Default Style -->
        <form
          v-if="equipmentType == 'iphone'"
          class="row row-cols-lg-auto g-3 align-items-center"
          @sumbit.prevent
        >
          <div class="col-lg-12 col-12">
            <div class="input-group">
              <span class="input-group-text bg-white">
                <i class="bi bi-calendar-date"></i>
              </span>
              <div class="form-floating">
                <FlatPickr
                  id="example-flatpickr-range"
                  class="form-control empty-border-left"
                  placeholder="Booking Date Range"
                  v-model="flatPickrState.dateRange"
                  :config="flatPickrState.configRange"
                  @on-change="chooseDates"
                />
                <label for="example-text-input-readonly-floating">{{
                  $t("booking-date-range")
                }}</label>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-12">
            <div class="form-floating">
              <select
                class="form-select"
                id="example-select"
                name="example-select"
                v-model="bookingSearchFormModel.status"
              >
                <option value="" selected>{{ $t("select-status") }}</option>
                <option value="BOOKING_CONFIRMED">{{ $t("confirmed") }}</option>
                <option value="BOOKING_CANCELLED">{{ $t("cancelled") }}</option>
                <option value="BOOKING_IN_PROCESS">
                  {{ $t("booking-in-process") }}
                </option>
              </select>
              <label for="example-text-input-readonly-floating">{{
                $t("booking-status")
              }}</label>
            </div>
          </div>

          <div
            class="col-lg-12 col-12"
            style="
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin-left: 15px;
            "
          >
            <div class="text-center">
              <button
                type="button"
                class="btn btn_theme"
                :class="{ btn_md: equipmentType != 'iphone' }"
                @click="$emit('submit', bookingSearchFormModel)"
              >
                {{ $t("search") }}
              </button>
            </div>
            <div class="text-center" style="margin-left: 5px">
              <button
                type="button"
                :class="{ btn_md: equipmentType != 'iphone' }"
                class="btn btn_theme"
                @click="resetFormModel"
              >
                {{ $t("reset") }}
              </button>
            </div>
          </div>
        </form>
        <form
          v-else
          class="row row-cols-lg-auto g-3 align-items-center"
          @sumbit.prevent
        >
          <div class="col-lg-5 col-5">
            <div class="input-group">
              <span class="input-group-text bg-white">
                <i class="bi bi-calendar-date"></i>
              </span>
              <div class="form-floating">
                <FlatPickr
                  id="example-flatpickr-range"
                  class="form-control empty-border-left"
                  placeholder="Booking Date Range"
                  v-model="flatPickrState.dateRange"
                  :config="flatPickrState.configRange"
                  @on-change="chooseDates"
                />
                <label for="example-text-input-readonly-floating">{{
                  $t("booking-date-range")
                }}</label>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-3">
            <div class="form-floating">
              <select
                class="form-select"
                id="example-select"
                name="example-select"
                v-model="bookingSearchFormModel.status"
              >
                <option value="" selected>{{ $t("select-status") }}</option>
                <option value="BOOKING_CONFIRMED">{{ $t("confirmed") }}</option>
                <option value="BOOKING_CANCELLED">{{ $t("cancelled") }}</option>
                <option value="BOOKING_IN_PROCESS">
                  {{ $t("booking-in-process") }}
                </option>
              </select>
              <label for="example-text-input-readonly-floating">{{
                $t("booking-status")
              }}</label>
            </div>
          </div>

          <div
            class="col-lg-3 col-3"
            style="
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin-left: 15px;
            "
          >
            <div class="text-center">
              <button
                type="button"
                class="btn btn_theme"
                :class="{ btn_md: equipmentType == 'pc' }"
                @click="$emit('submit', bookingSearchFormModel)"
              >
                {{ $t("search") }}
              </button>
            </div>
            <div class="text-center" style="margin-left: 5px">
              <button
                type="button"
                class="btn btn_theme"
                :class="{ btn_md: equipmentType == 'pc' }"
                @click="resetFormModel"
              >
                {{ $t("reset") }}
              </button>
            </div>
          </div>
        </form>
        <!-- END Form Inline - Default Style -->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// Flatpickr + Custom overrides
@import "flatpickr/dist/flatpickr.css";
</style>

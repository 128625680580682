<script setup>
import { useRouter } from "vue-router";

const props = defineProps({
  isReservation: {},
  tripActivityItem: {},
});

const router = useRouter();
const emits = defineEmits([]);

function confirmNow() {
  emits("confirmNow");
}
</script>

<template>
  <div class="tour_booking_form_box" v-if="tripActivityItem">
    <h3 class="heading_theme">
      {{ $t("price-summary") }}
    </h3>
    <div class="row">
      <div class="col-12">
        <div class="row text-muted">
          <div class="col-8">{{ $t("description-uppercase") }}</div>
          <div class="col-4 text-end">
            {{ $t("price-uppercase") }} ({{ tripActivityItem.currencyCode }})
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-8 col-8">
            <figure
              v-for="(
                participantCate, index
              ) in tripActivityItem.participantCategories"
              :key="index"
            >
              {{
                participantCate.ageQualifyingCode === "ADULT" ||
                participantCate.ageQualifyingCode === "10"
                  ? $t("per-adult")
                  : $t("per") + " " + $t("child")
              }}
            </figure>
          </div>
          <div class="col-lg-4 col-4">
            <figure
              class="text-end"
              v-for="(
                participantCate, index
              ) in tripActivityItem.participantCategories"
              :key="index"
            >
              {{ tripActivityItem.currencyCode }}
              {{
                participantCate.price && participantCate.price.amount
                  ? participantCate.price.amount.toFixed(2)
                  : "0:00"
              }}
            </figure>
          </div>
          <hr class="mg-up-10" />
        </div>

        <div class="row font_weight">
          <div class="col-lg-8 col-8">
            <figure>
              <div>
                {{ $t("total-uppercase") }}
                ({{ tripActivityItem.currencyCode }})
              </div>
            </figure>
          </div>
          <div class="col-lg-4 col-4">
            <figure class="text-end text-danger">
              <div>
                {{ tripActivityItem.currencyCode }}
                {{ tripActivityItem.totalAmount.toFixed(2) }}
              </div>
            </figure>
          </div>
        </div>
      </div>
    </div>
    <div class="row mg-t-10 mg-b-20" v-if="!isReservation">
      <div class="col-12 text-center">
        <button class="btn btn_theme" type="button" @click="confirmNow">
          {{ $t("confirm") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

<script setup>
import { ref, watch } from "vue";
import { Rate } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import commonUtils from "@/utils/commonUtils";

let props = defineProps({
  item: {},
});

const { t, locale } = useI18n(); // 解构调用函数
const ARate = Rate;
const itemRef = ref();
watch(
  () => props.item,
  (newValue) => {
    itemRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function filterNameByLanguage(propertyNames, hotelName, language) {
  let resultArr = [];
  if (propertyNames && propertyNames.length > 0) {
    resultArr = propertyNames.filter(
      (item) => item.languageCode !== language && item.name !== hotelName
    );
    return commonUtils.unique(resultArr, "name");
  }
}
</script>

<template>
  <div v-if="itemRef" class="theme_common_box_two img_hover">
    <div class="theme_two_box_img" style="height: 250px; overflow: hidden">
      <img
        :src="itemRef.mainImage"
        class="img-fluid rounded"
        style="width: 100%; height: 100%; object-fit: cover"
      />
      <p class="text-light">
        <i class="fas fa-map-marker-alt"></i>{{ itemRef.address }}
      </p>
    </div>
    <div class="theme_two_box_content">
      <h4>
        <div class="hotelName">{{ itemRef.hotelName }}</div>
      </h4>
      <div
        v-if="
          filterNameByLanguage(
            itemRef.propertyNames,
            itemRef.hotelName,
            locale
          ) &&
          filterNameByLanguage(itemRef.propertyNames, itemRef.hotelName, locale)
            .length > 0
        "
        class="text-muted"
      >
        <span
          v-for="(item, index) in filterNameByLanguage(
            itemRef.propertyNames,
            itemRef.hotelName,
            locale
          )"
          :key="index"
          >{{ item.name }}
          <span
            v-if="
              index <
              filterNameByLanguage(
                itemRef.propertyNames,
                itemRef.hotelName,
                locale
              ).length -
                1
            "
            >,
          </span></span
        >
      </div>
      <div class="text-muted rate">
        <a-rate :value="itemRef.starRating" disabled />
      </div>
      <p>
        <span class="review_rating">
          {{ itemRef.userRating }}/5 {{ $t("trip-advisor") }}
        </span>
      </p>
      <h3 v-if="itemRef.bestAvgUnitPrice" class="text-end">
        <span> {{ itemRef.currencyCode }}</span>
        {{ itemRef.bestAvgUnitPrice.toFixed(2) }}
      </h3>
    </div>
  </div>
</template>

<style scoped></style>

import { FlightBaggageAllowanceVO } from "@/viewobject/sales/flight/shopping/flightBaggageAllowanceVO";
import { TaxFeeVO } from "@/viewobject/common/taxFeeVO";

export class TripAirItemPassengerTypeFareVO {
  /**
   *
   * @param priceQuoteNumber string，GDS Fare的标识
   * @param travelerRefNumbers Array，string数组，乘客唯一标识数组
   * @param flightSegmentRefNumbers Array，string数组，航段唯一标识数组
   * @param passengerTypeCode
   * @param quantity
   * @param currencyCode
   * @param baseAmount
   * @param taxesAmount
   * @param feesAmount
   * @param totalAmount
   * @param discount
   * @param taxFeeSummary Array，TaxFeeVO对象数组
   * @param baggageAllowances Array，FlightBaggageAllowanceVO对象数组
   * @param localCreateDateTime Array，FlightBaggageAllowanceVO对象数组
   */
  constructor(
    priceQuoteNumber,
    flightSegmentRefNumbers,
    travelerRefNumbers,
    passengerTypeCode,
    quantity,
    currencyCode,
    baseAmount,
    taxesAmount,
    feesAmount,
    totalAmount,
    taxFeeSummary,
    baggageAllowances,
    localCreateDateTime,
    discount
  ) {
    this.priceQuoteNumber = priceQuoteNumber;
    this.flightSegmentRefNumbers = flightSegmentRefNumbers;
    this.travelerRefNumbers = travelerRefNumbers;
    this.passengerTypeCode = passengerTypeCode;
    this.quantity = quantity;
    this.currencyCode = currencyCode;
    this.baseAmount = baseAmount;
    this.feesAmount = feesAmount;
    this.taxesAmount = taxesAmount;
    this.totalAmount = totalAmount;
    this.taxFeeSummary = taxFeeSummary;
    this.baggageAllowances = baggageAllowances;
    this.localCreateDateTime = localCreateDateTime;
    this.discount = discount;
  }

  addNewBaggageAllowance(
    unitOfMeasureQuantity,
    unitOfMeasure,
    flightSegmentRPH
  ) {
    const baggageAllowanceVO = new FlightBaggageAllowanceVO(
      unitOfMeasureQuantity,
      unitOfMeasure,
      flightSegmentRPH
    );
    if (this.baggageAllowances == null) this.baggageAllowances = [];
    this.baggageAllowances.push(baggageAllowanceVO);
    return baggageAllowanceVO;
  }

  addBaggageAllowance(baggageAllowanceVO) {
    if (this.baggageAllowances == null) this.baggageAllowances = [];
    this.baggageAllowances.push(baggageAllowanceVO);
    return baggageAllowanceVO;
  }

  addNewTaxFeeSummary(code, type, inclusive, name, currencyCode, amount) {
    const taxFeeVO = new TaxFeeVO(
      code,
      type,
      inclusive,
      name,
      currencyCode,
      amount
    );
    if (this.taxFeeSummary == null) this.taxFeeSummary = [];
    this.taxFeeSummary.push(taxFeeVO);
    return taxFeeVO;
  }
}

<script setup>
import { ref } from "vue";
import { passengerTypeOptions } from "@/formmodel/sales/flight/common/options/flightCommonOption";
import { useI18n } from "vue-i18n";

const props = defineProps({
  title: {},
  tripAirItemFare: {},
});

const { t } = useI18n();
const passengerTypeOptionsRef = ref(passengerTypeOptions);

function buildStructuredPassengerFareMap(penaltyOptions) {
  let fareMap = null;
  if (penaltyOptions && penaltyOptions.length > 0) {
    fareMap = penaltyOptions.reduce((acc, currentObj) => {
      const key = currentObj.passengerTypeCode;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(currentObj);
      return acc;
    }, {});
  }

  return fareMap;
}

function buildStructuredDepartureFareMap(penaltyOptions) {
  let fareMap = null;
  if (penaltyOptions && penaltyOptions.length > 0) {
    fareMap = penaltyOptions.reduce((acc, currentObj) => {
      const key = currentObj.departureStatus;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(currentObj);
      return acc;
    }, {});
  }

  return fareMap;
}

function getDepartureStatusLabel(departureStatus) {
  let label = departureStatus;
  if (departureStatus === "BEFORE_DEPARTURE") {
    label = t("before-departure");
  } else if (departureStatus === "AFTER_DEPARTURE") {
    label = t("after-departure");
  }
  return label;
}

function getPassengerType(typeCode) {
  if (typeCode === "ADT") {
    return t("adult");
  } else if (typeCode === "CNN") {
    return t("child");
  } else if (typeCode === "INF") {
    return t("infant");
  } else {
    if (
      passengerTypeOptionsRef.value &&
      passengerTypeOptionsRef.value.length > 0
    ) {
      const passengerType = passengerTypeOptionsRef.value.find(
        (item) => item.code === typeCode
      );
      return passengerType.name;
    }
  }
}
</script>

<template>
  <div
    class="tour_details_boxed"
    v-if="
      tripAirItemFare &&
      tripAirItemFare.penaltyOptions &&
      tripAirItemFare.penaltyOptions.length > 0
    "
  >
    <h3 class="heading_theme">{{ title }}</h3>
    <div
      v-for="(
        passengerFares, passengerFareKey
      ) in buildStructuredPassengerFareMap(tripAirItemFare.penaltyOptions)"
      :key="passengerFareKey"
    >
      <div class="font_weight">{{ getPassengerType(passengerFareKey) }}</div>
      <div
        v-for="(penaltyFares, penaltyKey) in buildStructuredDepartureFareMap(
          passengerFares
        )"
        :key="penaltyKey"
        class="mg-b-5"
      >
        <div class="mg-t-10">{{ getDepartureStatusLabel(penaltyKey) }}:</div>
        <div
          v-for="(penaltyFare, penaltyIndex) in penaltyFares"
          :key="penaltyIndex"
          class="mg-b-5"
        >
          <!-- 是否可退 -->
          <div v-if="penaltyFare.penaltyType === 'REFUND_PENALTY'">
            <div v-if="penaltyFare.volChangeInd" class="row">
              <div class="col-8">
                <i class="fa-solid fa-dollar-sign mg-r-8"></i>
                {{ $t("cancellation-fee") }}
              </div>
              <div class="col-4">
                <span v-if="penaltyFare.percent"
                  >{{ $t("flight-price") }} x {{ penaltyFare.percent }}</span
                >
                <span v-else-if="penaltyFare.amount === 0">
                  {{ $t("free") }}</span
                >
                <span v-else>
                  {{ penaltyFare.currencyCode }}
                  {{ penaltyFare.amount }}</span
                >
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12">
                <i class="fa-solid fa-xmark mg-r-8"></i>
                {{ $t("non-refundable") }}
              </div>
            </div>
          </div>

          <!-- 是否可改航班  -->
          <div v-else-if="penaltyFare.penaltyType === 'CHANGE_PENALTY'">
            <div v-if="penaltyFare.volChangeInd" class="row">
              <div class="col-8">
                <i class="fa-solid fa-dollar-sign mg-r-8"></i>
                {{ $t("change-fee") }}
              </div>
              <div class="col-4">
                <span v-if="penaltyFare.percent"
                  >{{ $t("flight-price") }} x {{ penaltyFare.percent }}</span
                >
                <span v-else-if="penaltyFare.amount === 0">
                  {{ $t("free") }}</span
                >
                <span v-else>
                  {{ penaltyFare.currencyCode }}
                  {{ penaltyFare.amount }}</span
                >
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12">
                <i class="fa-solid fa-xmark mg-r-8"></i>
                {{ $t("unchangeable") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

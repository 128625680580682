<template>
    <!-- Common Banner Area -->
    <Banner />

    <!-- Form Area -->
    <Form />

    <!-- Flight Search Areas -->
    <SearchResult />

    <!-- Cta Area -->
    <Cta />

</template>
  
<script>
// @ is an alias to /src
import Banner from '@/components/templates/flight/Banner.vue'
import Form from '@/components/templates/flight/Form.vue'
import SearchResult from '@/components/templates/flight/SearchResult.vue'
import Cta from '@/components/templates/home/Cta.vue'
export default {
    name: 'FlightSearchResultView',
    components: {
        Banner, Form, SearchResult, Cta
    }
}
</script>
function getCreateUserRQ(
  firstname,
  lastname,
  email,
  phoneNumber,
  countryAccessCode,
  username,
  password
) {
  return {
    profile: {
      profileType: "1",
      statusCode: "5",
      customer: {
        gender: "male",
        personName: {
          givenName: lastname,
          surname: firstname,
          nameTitle: "MR",
        },
        telephoneList: [
          {
            phoneNumber: phoneNumber,
            countryAccessCode: countryAccessCode,
          },
        ],
        emailList: [
          {
            stringValue: email,
          },
        ],
      },
      userIDList: [
        {
          pinNumber: password,
          id: username,
        },
      ],
    },
  };
}

export default {
  getCreateUserRQ,
};

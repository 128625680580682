<script setup>
import BaseHotelPolicyItem from "@/views/sales/hotel/items/group/BaseHotelPolicyItem.vue";
import { useHotelStore } from "@/stores/hotel";
import { useBookingStore } from "@/stores/booking";
import { computed } from "vue";
import { useDynamicPackageStore } from "@/stores/dynamicPackage";

//Pinia store 相关
const hotelStore = useHotelStore();
const bookingStore = useBookingStore();
let dynamicPackageStore = useDynamicPackageStore();

//酒店信息响应对象
const policyItems = computed(() => {
  const hotelViewItemMap = hotelStore.hotelViewItemMap;
  const pkgHotelViewItemMap = dynamicPackageStore.hotelViewItemMap;
  //酒店确认订单页需要
  const preBookRoomInfo = hotelStore.preBookRoomInfo;
  //订单详情页
  const bookingDetailsViewItem = bookingStore.bookingDetailsViewItem;

  let hotelViewItem = null;
  if (hotelViewItemMap != null) {
    for (const hotelViewItemMapKey in hotelViewItemMap) {
      hotelViewItem = hotelViewItemMap[hotelViewItemMapKey];
      break;
    }
  }

  if (hotelViewItem != null && hotelViewItem.propTextContent) {
    const propTextContent = hotelViewItem.propTextContent;
    let policyItems = {
      checkPolicy: propTextContent["CHECK_IN"],
      feesPolicy: propTextContent["FEES"],
      importantPolicy: propTextContent["POLICY"],
    };
    return policyItems;
  } else if (
    pkgHotelViewItemMap != null &&
    pkgHotelViewItemMap.propTextContent
  ) {
    const propTextContent = pkgHotelViewItemMap.propTextContent;
    let policyItems = {
      checkPolicy: propTextContent["CHECK_IN"],
      feesPolicy: propTextContent["FEES"],
      importantPolicy: propTextContent["POLICY"],
    };
    return policyItems;
  } else if (
    preBookRoomInfo != null &&
    preBookRoomInfo.propTextContent != null
  ) {
    const propTextContent = preBookRoomInfo.propTextContent;
    let policyItems = null;
    if (propTextContent) {
      policyItems = {
        checkPolicy: propTextContent["CHECK_IN"],
        feesPolicy: propTextContent["FEES"],
        importantPolicy: propTextContent["POLICY"],
      };
    }
    return policyItems;
  } else if (
    bookingDetailsViewItem != null &&
    bookingDetailsViewItem.hotelItems &&
    bookingDetailsViewItem.hotelItems.length > 0
  ) {
    let policyItems = null;
    bookingDetailsViewItem.hotelItems.forEach((hotelViewItem) => {
      const propTextContent = hotelViewItem.propTextContent;
      if (propTextContent) {
        policyItems = {
          checkPolicy: propTextContent["CHECK_IN"],
          feesPolicy: propTextContent["FEES"],
          importantPolicy: propTextContent["POLICY"],
        };
      }
    });
    return policyItems;
  }
  return null;
});
</script>

<template>
  <div class="tour_details_boxed" v-if="policyItems">
    <h3 class="heading_theme">{{$t("policies")}}</h3>
    <div
      class="tour_details_boxed_inner"
      v-if="policyItems && policyItems.checkPolicy"
    >
      <!-- Policies -->
      <BaseHotelPolicyItem
        :items="policyItems.checkPolicy"
      ></BaseHotelPolicyItem>
    </div>
    <div
      class="tour_details_boxed_inner"
      v-if="policyItems && policyItems.feesPolicy"
    >
      <BaseHotelPolicyItem
        :items="policyItems.feesPolicy"
      ></BaseHotelPolicyItem>
    </div>
    <div
      class="tour_details_boxed_inner"
      v-if="policyItems && policyItems.importantPolicy"
    >
      <BaseHotelPolicyItem
        :items="policyItems.importantPolicy"
      ></BaseHotelPolicyItem>
    </div>
    <!-- END Policies -->
  </div>
</template>

<style scoped></style>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  carRentalRatesQuoteFormModel: {},
  availableCoverages: {},
});

const emits = defineEmits([]);

const carRentalRatesQuoteFormModelRef = ref(null);
watch(
  () => props.carRentalRatesQuoteFormModel,
  (newValue) => {
    carRentalRatesQuoteFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function removeAvailableCoverage(
  carRentalRatesQuoteFormModel,
  insurance,
  insuranceIndex
) {
  carRentalRatesQuoteFormModel.removeInsurance(insuranceIndex);
}

function removeAvailableExtra(
  carRentalRatesQuoteFormModel,
  equipment,
  equipmentIndex
) {
  carRentalRatesQuoteFormModel.removeEquipment(equipmentIndex);
}

function confirmQuoteRates(carRentalRatesQuoteFormModel) {
  emits("confirmQuoteRates", carRentalRatesQuoteFormModel);
}

function filterSelectedAvailableCoverages(availableCoverages) {
  if (availableCoverages && availableCoverages.length > 0) {
    return availableCoverages.filter((insurance) => insurance.selected);
  }
}
</script>

<template>
  <div class="tour_details_boxed" v-if="carRentalRatesQuoteFormModelRef">
    <h3 class="heading_theme">Price Summary</h3>
    <div class="row">
      <div class="col-12">
        <div class="row text-muted">
          <div class="col-8">{{ $t("description-uppercase") }}</div>
          <div class="col-4 text-end">
            {{ $t("price-uppercase") }}
            <!--            ({{-->
            <!--              carRentalRatesQuoteFormModelRef.currencyCode-->
            <!--            }})-->
          </div>
        </div>
        <hr />
        <div class="mg-b-10" v-if="carRentalRatesQuoteFormModelRef.baseAmount">
          <div class="row">
            <div class="col-8">Base Amount</div>
            <div class="col-4 text-end">
              {{ carRentalRatesQuoteFormModelRef.baseAmount }}
            </div>
          </div>
        </div>

        <div
          class="mg-b-10"
          v-if="
            carRentalRatesQuoteFormModelRef.protectionPlan &&
            carRentalRatesQuoteFormModelRef.protectionPlan.totalAmount
          "
        >
          <div class="font_weight">Protection plan</div>
          <div class="row p-1">
            <div class="col-8">
              {{ carRentalRatesQuoteFormModelRef.protectionPlan.description }}
            </div>
            <div class="col-4 text-end">
              {{ carRentalRatesQuoteFormModelRef.protectionPlan.currency }}
              {{
                carRentalRatesQuoteFormModelRef.protectionPlan.totalAmount.toFixed(
                  2
                )
              }}
            </div>
          </div>
        </div>

        <div
          class="mg-b-10"
          v-if="
            filterSelectedAvailableCoverages(availableCoverages) ||
            (carRentalRatesQuoteFormModelRef.selectedInsurances &&
              carRentalRatesQuoteFormModelRef.selectedInsurances.length > 0)
          "
        >
          <div class="font_weight">
            {{ $t("protections") }} ({{ $t("vat-included") }})
          </div>
          <div v-if="filterSelectedAvailableCoverages(availableCoverages)">
            <div
              class="row p-1"
              v-for="(
                insurance, insuranceIndex
              ) in filterSelectedAvailableCoverages(availableCoverages)"
              :key="insuranceIndex"
            >
              <div class="col-8">
                {{ insurance.description }} ({{ $t("mandatory") }})
              </div>
              <div class="col-4 text-end">
                <span
                  >{{ insurance.currency }}
                  {{ insurance.amount.toFixed(2) }}</span
                >
              </div>
            </div>
          </div>
          <div
            v-if="
              carRentalRatesQuoteFormModelRef.selectedInsurances &&
              carRentalRatesQuoteFormModelRef.selectedInsurances.length > 0
            "
          >
            <div
              class="row p-1"
              v-for="(
                insurance, insuranceIndex
              ) in carRentalRatesQuoteFormModelRef.selectedInsurances"
              :key="insuranceIndex"
            >
              <div class="col-8">
                {{ insurance.description }}
              </div>
              <div class="col-4 text-end">
                <span
                  >{{ insurance.currency }}
                  {{ insurance.amount.toFixed(2) }}</span
                >
                <i
                  class="bi bi-x-square cursor-point text-warning mg-l-10"
                  @click="
                    removeAvailableCoverage(
                      carRentalRatesQuoteFormModelRef,
                      insurance,
                      insuranceIndex
                    )
                  "
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            carRentalRatesQuoteFormModelRef.selectedEquipments &&
            carRentalRatesQuoteFormModelRef.selectedEquipments.length > 0
          "
        >
          <div class="font_weight">
            Available extras ({{ $t("vat-included") }})
          </div>
          <div
            class="row p-1"
            v-for="(
              equipment, equipmentIndex
            ) in carRentalRatesQuoteFormModelRef.selectedEquipments"
            :key="equipmentIndex"
          >
            <div class="col-8">
              {{ equipment.name }} x
              {{ equipment.quantity }}
            </div>
            <div class="col-4 text-end">
              <span>
                {{ equipment.currency }}
                {{ (equipment.amount * equipment.quantity).toFixed(2) }}</span
              >
              <i
                class="bi bi-x-square cursor-point text-warning mg-l-10"
                @click="
                  removeAvailableExtra(
                    carRentalRatesQuoteFormModelRef,
                    equipment,
                    equipmentIndex
                  )
                "
              ></i>
            </div>
          </div>
        </div>

        <!--        <div class="row font_weight mg-t-10">-->
        <!--          <div class="col-lg-8 col-8">-->
        <!--            <figure>-->
        <!--              <div>-->
        <!--                {{ $t("total-uppercase") }}-->
        <!--                ({{ carRentalRatesQuoteFormModelRef.currencyCode }})-->
        <!--              </div>-->
        <!--            </figure>-->
        <!--          </div>-->
        <!--          <div class="col-lg-4 col-4">-->
        <!--            <figure class="text-end text-danger">-->
        <!--              <div>-->
        <!--                {{ carRentalRatesQuoteFormModelRef.currencyCode }}-->
        <!--                {{-->
        <!--                  carRentalRatesQuoteFormModelRef.totalAmount-->
        <!--                    ? carRentalRatesQuoteFormModelRef.totalAmount.toFixed(2)-->
        <!--                    : "0.00"-->
        <!--                }}-->
        <!--              </div>-->
        <!--            </figure>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div
      class="text-center mg-t-10 mg-b-10"
      @click="confirmQuoteRates(carRentalRatesQuoteFormModelRef)"
    >
      <button class="btn btn_theme">
        {{ $t("confirm") }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>

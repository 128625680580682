//region 请求相关
import { CarRentalItemVO } from "@/viewobject/sales/carrental/carRentalItemVO";

function buildCarRentalAvailRQ(carRentalAvailFormModel) {
  let vehAvailRQCore = {
    status: "vehicle",
    vehRentalCore: {
      pickUpDateTime: carRentalAvailFormModel.pickupDatetime,
      returnDateTime: carRentalAvailFormModel.returnDatetime,
      pickUpLocations: [
        {
          locationCode: carRentalAvailFormModel.pickupLocation,
        },
      ],
      returnLocations: [
        {
          locationCode: carRentalAvailFormModel.returnLocation,
        },
      ],
    },
  };
  if (carRentalAvailFormModel.preferredCarType) {
    vehAvailRQCore.vehPrefs = [
      {
        vehType: {
          vehicleCategory: carRentalAvailFormModel.preferredCarType,
        },
      },
    ];
  }

  return {
    vehAvailRQCore: vehAvailRQCore,
    vehAvailRQInfo: {
      customer: {
        primary: {
          citizenCountryName: {
            code: carRentalAvailFormModel.countryOfResidence,
          },
        },
      },
    },
  };
}

//endregion

//region 构建视图对象相关

function buildCarRentalItems(availVehiclesRS) {
  if (
    !availVehiclesRS ||
    !availVehiclesRS.vehAvailRSCore ||
    !availVehiclesRS.vehAvailRSCore.vehVendorAvails ||
    availVehiclesRS.vehAvailRSCore.vehVendorAvails.length === 0 ||
    !availVehiclesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails ||
    !availVehiclesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails ||
    availVehiclesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails
      .length === 0
  )
    return null;

  const vehAvails =
    availVehiclesRS.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails;

  const carRentalItems = [];
  vehAvails.forEach((vehAvail) => {
    const vehAvailInfo = vehAvail.vehAvailInfo;
    const pricedCoverages = vehAvailInfo.pricedCoverages;

    const vehAvailCore = vehAvail.vehAvailCore;
    const rentalRates = vehAvailCore.rentalRates[0];
    const minimumDriverAge = rentalRates.rateRestrictions.minimumAge;
    const quoteID = rentalRates.quoteID;
    let rateQualifier = null;
    const rateDistances = rentalRates.rateDistances;

    if (rateDistances && rateDistances.length > 0) {
      rateQualifier = rateDistances[0].unlimited;
    }
    const vehicleCharges = rentalRates.vehicleCharges;
    const totalCharges = vehAvailCore.totalCharges[0];
    const pricedEquips = vehAvailCore.pricedEquips;
    const currencyCode = totalCharges.currencyCode;
    const totalAmount = totalCharges.estimatedTotalAmount;
    const vehicle = vehAvailCore.vehicle;
    const vehType = vehicle.vehType;
    const vehClass = vehicle.vehClass;
    const classCode = vehClass.vehClassCode;
    const className = vehClass.vehClassName;
    const vehMakeModel = vehicle.vehMakeModel;
    const vehName = vehMakeModel.name;

    const pictureURL = vehicle.pictureURL;
    const passengerQuantity = vehicle.passengerQuantity;
    const baggageQuantity = vehicle.baggageQuantity;

    const airConditionInd = vehicle.airConditionInd;
    const transmissionType = vehicle.transmissionType;
    const fuelType = vehicle.fuelType;
    const doorCount = vehType.doorCount;

    const carRentalItemVO = new CarRentalItemVO(
      pictureURL,
      vehName,
      currencyCode,
      totalAmount,
      airConditionInd,
      transmissionType,
      fuelType,
      doorCount,
      passengerQuantity,
      baggageQuantity,
      classCode,
      className,
      minimumDriverAge,
      quoteID,
      rateQualifier,
      null,
      null,
      null,
      null
    );

    //保险
    if (pricedCoverages != null && pricedCoverages.length > 0) {
      pricedCoverages.forEach((pricedCoverage) => {
        const selected = pricedCoverage.selected;
        const required = pricedCoverage.required;
        const coverageCode = pricedCoverage.coverage.code;
        const coverageDescription = pricedCoverage.coverage.description;
        const amount = pricedCoverage.charge.amount;
        const coverageCurrency = pricedCoverage.charge.currencyCode;
        const coverageIncluded = selected && amount === 0;

        carRentalItemVO.addNewInsurance(
          coverageIncluded,
          selected,
          required,
          coverageCode,
          coverageDescription,
          amount,
          coverageCurrency
        );
      });
    }

    //设备
    if (pricedEquips && pricedEquips.length > 0) {
      pricedEquips.forEach((pricedEquip) => {
        const required = pricedEquip.required;
        const selected = pricedEquip.selected;
        const equipment = pricedEquip.equipment;
        const equipmentCode = equipment.code;
        const description = equipment.description;
        const charge = pricedEquip.charge;
        const equipAmount = charge.amount;
        const chargeCurrency = charge.currencyCode;
        //RENTAL 指计算价格单位为每次租赁， DAY指计算价格单位为每天
        const unitName = charge.calculations[0].unitName;

        carRentalItemVO.addNewEquipment(
          required,
          selected,
          equipment,
          equipmentCode,
          unitName,
          equipAmount,
          chargeCurrency
        );
      });
    }

    //费用
    if (vehicleCharges && vehicleCharges.length > 0) {
      vehicleCharges.forEach((vehicleCharge) => {
        const description = vehicleCharge.description;
        const amount = vehicleCharge.amount;
        const currencyCode = vehicleCharge.currencyCode;
        const taxInclusive = vehicleCharge.taxInclusive;
        const taxAmounts = vehicleCharge.taxAmounts;
        const purpose = vehicleCharge.purpose; // 1 for vehicle rental, 4 for coverage, 6 for fee, 7 for tax

        carRentalItemVO.addNewCharge(
          description,
          amount,
          currencyCode,
          taxInclusive,
          taxAmounts
        );
      });
    }

    carRentalItems.push(carRentalItemVO);
  });

  return carRentalItems;
}

/**
 *
 * @param carRentalItems 数据源数组
 * @param currentPage 指定页
 * @param limit 每页数据量
 * @returns {*|null}
 */
function findSpecificCarRentalItems(carRentalItems, currentPage, limit) {
  let start = 0;
  let end = start + currentPage * limit;
  if (carRentalItems && end > carRentalItems.length) {
    end = carRentalItems.length;
  }

  const carRentalItemsCopy = JSON.parse(JSON.stringify(carRentalItems));
  return carRentalItemsCopy.slice(start, end);
}

//endregion

export default {
  buildCarRentalAvailRQ,
  buildCarRentalItems,
  findSpecificCarRentalItems,
};

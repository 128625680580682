<script setup>
import { ref, watch } from "vue";

import { useI18n } from "vue-i18n";
import HotelDailyRatesPanel from "@/views/v2/sales/hotel/items/panel/HotelDailyRatesPanel.vue";

const props = defineProps({
  tripPackageItem: {},
});

const emits = defineEmits([]);
const { t, locale } = useI18n(); // 解构调用函数
const isPriceSummaryShowRef = ref(true);
const tripPackageItemRef = ref(null);
const segmentsRef = ref(null);
const priceBreakdownContentRef = ref({
  roomInfo: null,
  roomQuantity: null,
  roomTypeName: null,
  nightsStay: null,
  passengerFares: null,
  feesTotalAmount: 0,
  taxesTotalAmount: 0,
  discountTotalAmount: 0,
  hasDiscount: false,
});
const serviceTotalAmountRef = ref(null);

watch(
  () => props.tripPackageItem,
  (newValue) => {
    tripPackageItemRef.value = newValue;
    priceBreakdownContentRef.value.passengerFares = {};
    //酒店价格
    if (tripPackageItemRef.value && tripPackageItemRef.value.tripHotelItem) {
      const roomQuantity = tripPackageItemRef.value.tripHotelItem.roomQuantity;
      const roomTypeItem =
        tripPackageItemRef.value.tripHotelItem.roomTypes &&
        tripPackageItemRef.value.tripHotelItem.roomTypes.length > 0
          ? tripPackageItemRef.value.tripHotelItem.roomTypes[0]
          : {};
      const roomTypeName = roomTypeItem.roomTypeName;
      const roomItem =
        roomTypeItem.rooms && roomTypeItem.rooms.length > 0
          ? roomTypeItem.rooms[0]
          : {};

      const nightsStay = roomItem.nightsStay;

      priceBreakdownContentRef.value.roomInfo = roomItem;
      priceBreakdownContentRef.value.roomQuantity = roomQuantity;
      priceBreakdownContentRef.value.roomTypeName = roomTypeName;
      priceBreakdownContentRef.value.nightsStay = nightsStay;
    }
    // 机票价格
    if (
      tripPackageItemRef.value &&
      tripPackageItemRef.value.tripAirItem &&
      tripPackageItemRef.value.tripAirItem.tripAirItemFare
    ) {
      const tripAirItemFare =
        tripPackageItemRef.value.tripAirItem.tripAirItemFare;

      const totalDiscount = tripAirItemFare ? tripAirItemFare.totalDiscount : 0;

      serviceTotalAmountRef.value = {
        amount: 0,
        currencyCode: null,
      };

      if (
        tripAirItemFare &&
        tripAirItemFare.passengerFares &&
        tripAirItemFare.passengerFares.length > 0
      ) {
        for (const passengerFare of tripAirItemFare.passengerFares) {
          const passengerTypeCode =
            passengerFare && passengerFare.passengerTypeCode
              ? passengerFare.passengerTypeCode
              : null;
          priceBreakdownContentRef.value.taxesTotalAmount +=
            passengerFare && passengerFare.taxesAmount
              ? passengerFare.taxesAmount * passengerFare.quantity
              : 0;
          priceBreakdownContentRef.value.discountTotalAmount +=
            passengerFare && passengerFare.discount
              ? passengerFare.discount * passengerFare.quantity
              : 0;
          priceBreakdownContentRef.value.hasDiscount =
            passengerFare && passengerFare.discount ? true : false;
          priceBreakdownContentRef.value.feesTotalAmount +=
            passengerFare && passengerFare.feesAmount
              ? passengerFare.feesAmount * passengerFare.quantity
              : 0;

          if (
            priceBreakdownContentRef.value.passengerFares[passengerTypeCode]
          ) {
            const quantity = passengerFare.quantity;
            priceBreakdownContentRef.value.passengerFares[
              passengerTypeCode
            ].baseAmount += passengerFare.baseAmount * quantity;
            priceBreakdownContentRef.value.passengerFares[
              passengerTypeCode
            ].totalAmount += passengerFare.totalAmount * quantity;
            priceBreakdownContentRef.value.passengerFares[
              passengerTypeCode
            ].quantity += quantity;
          } else {
            priceBreakdownContentRef.value.passengerFares[passengerTypeCode] = {
              baseAmount:
                passengerFare && passengerFare.baseAmount
                  ? passengerFare.baseAmount * passengerFare.quantity
                  : 0,
              totalAmount:
                passengerFare && passengerFare.totalAmount
                  ? passengerFare.totalAmount * passengerFare.quantity
                  : 0,
              quantity:
                passengerFare && passengerFare.quantity
                  ? passengerFare.quantity
                  : 1,
            };
          }
        }

        priceBreakdownContentRef.value.discountTotalAmount += totalDiscount;
        if (priceBreakdownContentRef.value.discountTotalAmount)
          priceBreakdownContentRef.value.hasDiscount = true;
      }

      let ancillaryFares = [];

      if (tripAirItemFare && tripAirItemFare.ancillaryFares) {
        ancillaryFares = tripAirItemFare.ancillaryFares;
        if (ancillaryFares && ancillaryFares.length > 0) {
          ancillaryFares.forEach((item) => {
            serviceTotalAmountRef.value.amount += item.amount;
            serviceTotalAmountRef.value.currencyCode = item.currencyCode;
          });
        }
      }

      segmentsRef.value = [];
      if (
        tripPackageItemRef.value.tripAirItem &&
        tripPackageItemRef.value.tripAirItem.flightItems &&
        tripPackageItemRef.value.tripAirItem.flightItems.length > 0
      ) {
        tripPackageItemRef.value.tripAirItem.flightItems.forEach(
          (item, index) => {
            segmentsRef.value.push(...item.flightSegments);
          }
        );
      }
    }
  },
  { deep: true, immediate: true }
);

//添加乘客序号 -》遍历
function addPassengerIndex(passengerFares) {
  const passengerFaresCopy = JSON.parse(JSON.stringify(passengerFares)); //不改变元数据结构

  if (passengerFaresCopy && passengerFaresCopy.length > 0) {
    let index = 1;
    for (let i = 0; i < passengerFaresCopy.length; i++) {
      const passengerFare = passengerFaresCopy[i];
      const travelerRefNumbers = passengerFare.travelerRefNumbers;
      let travelerNumbers = [];
      if (travelerRefNumbers && travelerRefNumbers.length > 0) {
        for (let j = 0; j < travelerRefNumbers.length; j++) {
          travelerNumbers.push({
            rph: travelerRefNumbers[j],
            passengerIndex: index++,
          });
        }
      }
      passengerFare.travelerNumbers = travelerNumbers;
    }
  }
  return passengerFaresCopy;
}

//按照成年人优先显示
function sortPassengerFares(passengerFares) {
  const passengerFareSort = {};
  if (passengerFares) {
    Object.keys(passengerFares)
      .sort()
      .map((item) => {
        passengerFareSort[item] = passengerFares[item];
      });
  }
  return passengerFareSort;
}

function getPassengerServices(servicesSelected, travelerRefNumberRPH) {
  let services = [];
  if (servicesSelected && servicesSelected.length > 0) {
    services = servicesSelected.filter(
      (item) => item.travelerRPH === travelerRefNumberRPH
    );
  }
  return services;
}

function getPassengerServicesTotalAmount(
  servicesSelected,
  travelerRefNumberRPH
) {
  let services = [];
  if (servicesSelected && servicesSelected.length > 0) {
    services = servicesSelected.filter(
      (item) => item.travelerRPH === travelerRefNumberRPH
    );
  }

  let totalAmount = 0;
  let currencyCode = null;
  if (services && services.length > 0) {
    services.forEach((item) => {
      totalAmount += item.amount;
      currencyCode = item.currencyCode;
    });
  }
  return {
    amount: totalAmount,
    currencyCode: currencyCode,
  };
}

function getSegmentFlight(flightSegmentRPH, segments) {
  if (segments && segments.length > 0) {
    let segmentItem = segments.find((item) => item.rph === flightSegmentRPH);
    if (segmentItem) {
      return segmentItem.marketingAirlineCode + segmentItem.flightNumber;
    }
  }
}

function getPassengerType(typeCode) {
  let typeName = t("adult");
  if (typeCode === "CNN") {
    typeName = t("child");
  } else if (typeCode === "INF") {
    typeName = t("infant");
  }
  return typeName;
}

function changePriceSummaryShow() {
  isPriceSummaryShowRef.value = !isPriceSummaryShowRef.value;
}

function confirmBooking() {
  emits("confirmBooking");
}
</script>

<template>
  <div class="tour_booking_form_box mg-t-30">
    <div class="row">
      <div class="col-6">
        <h3 class="heading_theme">
          {{
            isPriceSummaryShowRef ? $t("price-summary") : $t("price-breakdown")
          }}
        </h3>
      </div>
      <div class="col-6 text-end">
        <button
          class="btn btn_border"
          type="reset"
          @click="changePriceSummaryShow"
        >
          {{
            isPriceSummaryShowRef
              ? $t("view-price-breakdown")
              : $t("view-price-summary")
          }}
        </button>
      </div>
    </div>
    <div v-if="tripPackageItemRef">
      <div v-if="isPriceSummaryShowRef">
        <div class="row">
          <div class="col-12">
            <div class="row text-muted">
              <div class="col-8">{{ $t("description-uppercase") }}</div>
              <div class="col-4 text-end">
                {{ $t("price-uppercase") }} ({{
                  tripPackageItemRef.currencyCode
                }})
              </div>
            </div>
            <hr />
            <div v-if="priceBreakdownContentRef" class="row">
              <!--酒店-->
              <div
                class="col-12 font_weight"
                v-if="priceBreakdownContentRef.roomInfo"
              >
                {{ $t("hotel") }}
              </div>
              <div
                class="col-lg-8 col-8"
                v-if="priceBreakdownContentRef.roomInfo"
              >
                <figure class="mg-l-5">
                  <div
                    v-if="
                      priceBreakdownContentRef.roomInfo &&
                      priceBreakdownContentRef.roomInfo.totalBasePrice
                    "
                  >
                    {{ priceBreakdownContentRef.roomQuantity }}
                    {{ $t("room") }} ({{
                      priceBreakdownContentRef.roomTypeName
                    }}) *

                    {{ priceBreakdownContentRef.nightsStay }}
                    {{ $t("nights") }}
                    <span
                      class="text-pointer cursor-point mg-l-5 text-muted font-14"
                      data-bs-target="#modal-daily-prices"
                      data-bs-toggle="modal"
                    >
                      {{ $t("more-details") }} <i class="fa fa-circle-info"></i>
                    </span>
                  </div>

                  <div>{{ $t("taxes") }}</div>
                  <div>{{ $t("hotel-price") }}</div>
                </figure>
              </div>
              <div
                v-if="priceBreakdownContentRef.roomInfo"
                class="col-lg-4 col-4"
              >
                <figure class="text-end">
                  <div
                    v-if="
                      priceBreakdownContentRef.roomInfo &&
                      priceBreakdownContentRef.roomInfo.totalBasePrice
                    "
                  >
                    {{ tripPackageItemRef.currencyCode }}
                    {{
                      priceBreakdownContentRef.roomInfo.totalBasePrice
                        ? (
                            priceBreakdownContentRef.roomInfo.totalBasePrice *
                            priceBreakdownContentRef.roomQuantity
                          ).toFixed(2)
                        : null
                    }}
                  </div>
                  <div>
                    {{ tripPackageItemRef.currencyCode }}
                    {{
                      priceBreakdownContentRef.roomInfo &&
                      priceBreakdownContentRef.roomInfo.totalFeesTaxesInc
                        ? (
                            priceBreakdownContentRef.roomInfo
                              .totalFeesTaxesInc *
                            priceBreakdownContentRef.roomQuantity
                          ).toFixed(2)
                        : " 0.00"
                    }}
                  </div>
                  <div>
                    {{ tripPackageItemRef.currencyCode }}
                    {{
                      tripPackageItemRef.tripHotelItem.totalPrice
                        ? tripPackageItemRef.tripHotelItem.totalPrice.toFixed(2)
                        : " 0.00"
                    }}
                  </div>
                </figure>
              </div>
              <!--机票-->
              <div class="col-12 font_weight">
                {{ $t("flight") }}
              </div>
              <div class="col-lg-8 col-8">
                <figure class="mg-l-5">
                  <div
                    v-if="
                      priceBreakdownContentRef &&
                      priceBreakdownContentRef.passengerFares
                    "
                  >
                    <div
                      v-for="(
                        passengerFare, passengerFareKey
                      ) in sortPassengerFares(
                        priceBreakdownContentRef.passengerFares
                      )"
                      :key="passengerFareKey"
                    >
                      {{ passengerFareKey }} x
                      {{ passengerFare.quantity }}
                    </div>
                  </div>
                  <div
                    v-if="
                      priceBreakdownContentRef &&
                      priceBreakdownContentRef.taxesTotalAmount
                    "
                  >
                    {{ $t("taxes") }}
                  </div>

                  <div
                    v-if="
                      priceBreakdownContentRef &&
                      priceBreakdownContentRef.feesTotalAmount
                    "
                    v-html="$t('transaction-fees')"
                  ></div>
                  <div
                    v-if="
                      priceBreakdownContentRef &&
                      priceBreakdownContentRef.hasDiscount
                    "
                  >
                    {{ $t("discount-uppercase") }}
                  </div>
                  <div
                    v-if="
                      serviceTotalAmountRef &&
                      serviceTotalAmountRef.currencyCode
                    "
                  >
                    {{ $t("additional-service-charge") }}
                  </div>
                </figure>
              </div>
              <div class="col-lg-4 col-4">
                <figure class="text-end">
                  <div
                    v-if="
                      priceBreakdownContentRef &&
                      priceBreakdownContentRef.passengerFares
                    "
                  >
                    <div
                      v-for="(
                        passengerFare, passengerFareKey
                      ) in sortPassengerFares(
                        priceBreakdownContentRef.passengerFares
                      )"
                      :key="passengerFareKey"
                    >
                      {{ tripPackageItemRef.currencyCode }}
                      {{ passengerFare.baseAmount.toFixed(2) }}
                    </div>
                  </div>
                  <div
                    v-if="
                      priceBreakdownContentRef &&
                      priceBreakdownContentRef.taxesTotalAmount
                    "
                  >
                    {{ tripPackageItemRef.currencyCode }}
                    {{ priceBreakdownContentRef.taxesTotalAmount.toFixed(2) }}
                  </div>

                  <div
                    v-if="
                      priceBreakdownContentRef &&
                      priceBreakdownContentRef.feesTotalAmount
                    "
                  >
                    {{ tripPackageItemRef.currencyCode }}
                    {{ priceBreakdownContentRef.feesTotalAmount.toFixed(2) }}
                  </div>
                  <div
                    v-if="
                      priceBreakdownContentRef &&
                      priceBreakdownContentRef.hasDiscount
                    "
                  >
                    {{ tripPackageItemRef.currencyCode }}
                    {{
                      priceBreakdownContentRef.discountTotalAmount.toFixed(2)
                    }}
                  </div>
                  <div
                    v-if="
                      serviceTotalAmountRef &&
                      serviceTotalAmountRef.currencyCode
                    "
                  >
                    {{ serviceTotalAmountRef.currencyCode }}
                    {{ serviceTotalAmountRef.amount.toFixed(2) }}
                  </div>
                </figure>
              </div>
              <hr class="mg-up-10" />
            </div>

            <div class="row font_weight">
              <div class="col-lg-8 col-8">
                <figure>
                  <div>
                    {{ $t("total-uppercase") }}
                    ({{ tripPackageItemRef.currencyCode }})
                  </div>
                </figure>
              </div>
              <div class="col-lg-4 col-4">
                <figure class="text-end text-danger">
                  <div>
                    {{ tripPackageItemRef.currencyCode }}
                    {{ tripPackageItemRef.totalAmount.toFixed(2) }}
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!--  酒店-->
        <div
          v-if="
            tripPackageItemRef.tripHotelItem &&
            tripPackageItemRef.tripHotelItem.roomTypes &&
            tripPackageItemRef.tripHotelItem.roomTypes.length > 0 &&
            tripPackageItemRef.tripHotelItem.roomTypes[0]
          "
        >
          <div class="font_weight">{{ $t("hotel") }}</div>
          <div class="p-1">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    {{ $t("room-index") }}
                  </th>
                  <th>{{ $t("date") }}</th>
                  <th>
                    {{ $t("room-price") }}
                  </th>
                  <th>{{ $t("taxes") }}</th>
                  <th class="text-end">
                    {{ $t("amount") }}
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="(roomViewItem, roomIndex) in tripPackageItemRef
                  .tripHotelItem.roomTypes[0].rooms"
                :key="roomIndex"
              >
                <tr
                  v-for="(dailyRate, rateIndex) in roomViewItem.dailyRates"
                  :key="rateIndex"
                >
                  <td>{{ $t("room") }} {{ roomIndex + 1 }}</td>
                  <td>
                    {{
                      dailyRate.expireDate
                        ? dailyRate.date + " - " + dailyRate.expireDate
                        : dailyRate.date
                    }}
                  </td>
                  <td>
                    <div v-if="dailyRate.roomBasePrice">
                      {{ tripPackageItemRef.currencyCode }}
                      {{ dailyRate.roomBasePrice.toFixed(2) }}
                    </div>
                    <div v-else>-</div>
                  </td>
                  <td>
                    {{ tripPackageItemRef.currencyCode }}
                    {{
                      dailyRate.taxAndFeesPrice
                        ? dailyRate.taxAndFeesPrice.toFixed(2)
                        : 0.0
                    }}
                  </td>
                  <td class="text-end">
                    <div v-if="dailyRate.totalPrice">
                      {{ tripPackageItemRef.currencyCode }}
                      {{ dailyRate.totalPrice.toFixed(2) }}
                    </div>
                    <div v-else>-</div>
                  </td>
                </tr>

                <tr
                  v-if="
                    tripPackageItemRef.tripHotelItem.roomTypes[0].rooms.length >
                      0 &&
                    roomIndex ===
                      tripPackageItemRef.tripHotelItem.roomTypes[0].rooms
                        .length -
                        1
                  "
                >
                  <td colspan="4">
                    {{ $t("room-amount") }}
                  </td>
                  <td class="text-end">
                    <div v-if="roomViewItem.totalBasePrice">
                      {{ tripPackageItemRef.currencyCode }}
                      {{
                        (
                          roomViewItem.totalBasePrice *
                          tripPackageItemRef.tripHotelItem.roomTypes[0].rooms
                            .length
                        ).toFixed(2)
                      }}
                    </div>
                    <div v-else>-</div>
                  </td>
                </tr>
                <tr
                  v-if="
                    tripPackageItemRef.tripHotelItem.roomTypes[0].rooms.length >
                      0 &&
                    roomIndex ===
                      tripPackageItemRef.tripHotelItem.roomTypes[0].rooms
                        .length -
                        1
                  "
                >
                  <td colspan="4">
                    {{ $t("taxes-amount") }}
                  </td>
                  <td class="text-end">
                    {{ tripPackageItemRef.currencyCode }}
                    {{
                      roomViewItem.totalFeesTaxesInc
                        ? (
                            roomViewItem.totalFeesTaxesInc *
                            tripPackageItemRef.tripHotelItem.roomTypes[0].rooms
                              .length
                          ).toFixed(2)
                        : 0.0
                    }}
                  </td>
                </tr>
                <tr
                  v-if="
                    tripPackageItemRef.tripHotelItem.roomTypes[0].rooms.length >
                      0 &&
                    roomIndex ===
                      tripPackageItemRef.tripHotelItem.roomTypes[0].rooms
                        .length -
                        1 &&
                    tripPackageItemRef.tripHotelItem
                  "
                  class="font_weight"
                >
                  <td colspan="4">
                    {{ $t("total-amount") }}
                  </td>
                  <td class="text-end">
                    {{ tripPackageItemRef.currencyCode }}
                    {{
                      tripPackageItemRef.tripHotelItem.totalPrice
                        ? tripPackageItemRef.tripHotelItem.totalPrice.toFixed(2)
                        : null
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          v-if="
            tripPackageItem.tripAirItem &&
            tripPackageItem.tripAirItem.tripAirItemFare &&
            tripPackageItem.tripAirItem.tripAirItemFare.passengerFares
          "
        >
          <div class="font_weight">{{ $t("flight") }}</div>
          <div class="p-1">
            <div
              v-for="(passengerFare, passengerFareIndex) in addPassengerIndex(
                tripPackageItem.tripAirItem.tripAirItemFare.passengerFares
              )"
              :key="passengerFareIndex"
              class="row"
            >
              <div class="col-12">
                <div
                  v-for="(
                    travelerRef, travelerRefNumberIndex
                  ) in passengerFare.travelerNumbers"
                  :key="travelerRefNumberIndex"
                  class="row"
                >
                  <div class="col-lg-8 col-8">
                    <figure>
                      <div>
                        <span class="font_weight text-muted"
                          >{{ $t("traveller") }}
                          {{ travelerRef.passengerIndex }}
                        </span>
                        {{ getPassengerType(passengerFare.passengerTypeCode) }}
                        ({{ travelerRef.rph }} )
                      </div>
                      <div>{{ $t("flight") }}</div>
                      <div>
                        {{ $t("taxes") }}
                        <i
                          class="fa-solid fa-eye mg-l-5 cursor-point"
                          data-bs-target="#modal-flight-taxes"
                          data-bs-toggle="modal"
                          v-if="
                            passengerFare.taxFeeSummary &&
                            passengerFare.taxFeeSummary.length > 0
                          "
                        ></i>
                      </div>

                      <div
                        v-html="$t('transaction-fees')"
                        v-if="passengerFare.feesAmount"
                      ></div>
                      <div
                        v-if="
                          priceBreakdownContentRef &&
                          priceBreakdownContentRef.hasDiscount
                        "
                      >
                        {{ $t("discount") }}
                      </div>
                      <div
                        v-if="
                          tripPackageItem.tripAirItem.tripAirItemFare
                            .ancillaryFares &&
                          tripPackageItem.tripAirItem.tripAirItemFare
                            .ancillaryFares.length > 0 &&
                          getPassengerServices(
                            tripPackageItem.tripAirItem.tripAirItemFare
                              .ancillaryFares,
                            travelerRef.rph
                          ) &&
                          getPassengerServices(
                            tripPackageItem.tripAirItem.tripAirItemFare
                              .ancillaryFares,
                            travelerRef.rph
                          ).length > 0
                        "
                      >
                        <div class="font_weight">
                          {{ $t("services-summary") }}
                        </div>
                        <div class="p-1 font-14">
                          <div
                            v-for="(
                              service, serviceIndex
                            ) in getPassengerServices(
                              tripPackageItem.tripAirItem.tripAirItemFare
                                .ancillaryFares,
                              travelerRef.rph
                            )"
                            :key="serviceIndex"
                            class="mg-l-10"
                          >
                            {{ service.name }}
                            <span class="text-muted"
                              >({{
                                getSegmentFlight(
                                  service.flightSegmentRPH,
                                  segmentsRef
                                )
                              }})</span
                            >
                            <span
                              v-if="service.status === 'PRICED'"
                              class="fw-semibold d-inline-block py-1 px-3 fs-sm rounded bg-danger-light mg-l-5"
                              >{{ $t("unpaid") }}</span
                            >
                          </div>
                          <div
                            v-if="
                              getPassengerServicesTotalAmount(
                                tripPackageItem.tripAirItem.tripAirItemFare
                                  .ancillaryFares,
                                travelerRef.rph
                              )
                            "
                            class="font_weight"
                          >
                            {{ $t("services-total") }}
                          </div>
                        </div>
                      </div>
                    </figure>
                  </div>
                  <div class="col-lg-4 col-4">
                    <figure class="text-end" v-if="passengerFare">
                      <div
                        class="font_weight"
                        v-if="
                          passengerFare.totalAmount != null ||
                          passengerFare.totalAmount === 0
                        "
                      >
                        {{ passengerFare.currencyCode }}
                        {{ passengerFare.totalAmount.toFixed(2) }}
                      </div>
                      <div
                        class="text-muted font-14"
                        v-if="
                          passengerFare.baseAmount != null ||
                          passengerFare.baseAmount === 0
                        "
                      >
                        {{ passengerFare.currencyCode }}
                        {{ passengerFare.baseAmount.toFixed(2) }}
                      </div>
                      <div
                        class="text-muted font-14"
                        v-if="
                          passengerFare.taxesAmount != null ||
                          passengerFare.taxesAmount === 0
                        "
                      >
                        {{ passengerFare.currencyCode }}
                        {{ passengerFare.taxesAmount.toFixed(2) }}
                      </div>
                      <div
                        class="text-muted font-14"
                        v-if="passengerFare.feesAmount"
                      >
                        {{ passengerFare.currencyCode }}
                        {{ passengerFare.feesAmount.toFixed(2) }}
                      </div>

                      <div
                        class="text-muted font-14"
                        v-if="
                          passengerFare.discount != null ||
                          passengerFare.discount === 0
                        "
                      >
                        {{ passengerFare.currencyCode }}
                        {{ passengerFare.discount.toFixed(2) }}
                      </div>
                      <div
                        v-if="
                          tripPackageItem.tripAirItem.tripAirItemFare
                            .ancillaryFares &&
                          tripPackageItem.tripAirItem.tripAirItemFare
                            .ancillaryFares.length > 0 &&
                          getPassengerServices(
                            tripPackageItem.tripAirItem.tripAirItemFare
                              .ancillaryFares,
                            travelerRef.rph
                          ) &&
                          getPassengerServices(
                            tripPackageItem.tripAirItem.tripAirItemFare
                              .ancillaryFares,
                            travelerRef.rph
                          ).length > 0
                        "
                      >
                        <div class="font_weight">
                          {{ $t("price") }}
                        </div>
                        <div class="p-1 font-14">
                          <div
                            v-for="(
                              service, serviceIndex
                            ) in getPassengerServices(
                              tripPackageItem.tripAirItem.tripAirItemFare
                                .ancillaryFares,
                              travelerRef.rph
                            )"
                            :key="serviceIndex"
                          >
                            {{ service.currencyCode }}
                            {{ service.amount.toFixed(2) }}
                          </div>
                          <div
                            v-if="
                              getPassengerServicesTotalAmount(
                                tripPackageItem.tripAirItem.tripAirItemFare
                                  .ancillaryFares,
                                travelerRef.rph
                              )
                            "
                            class="font_weight"
                          >
                            {{
                              getPassengerServicesTotalAmount(
                                tripPackageItem.tripAirItem.tripAirItemFare
                                  .ancillaryFares,
                                travelerRef.rph
                              ).currencyCode
                            }}
                            {{
                              getPassengerServicesTotalAmount(
                                tripPackageItem.tripAirItem.tripAirItemFare
                                  .ancillaryFares,
                                travelerRef.rph
                              ).amount.toFixed(2)
                            }}
                          </div>
                        </div>
                      </div>
                    </figure>
                  </div>

                  <hr class="mg-up-10" />
                </div>
              </div>
              <!-- taxes 详情 -->
              <div
                id="modal-flight-taxes"
                aria-labelledby="modal-flight-taxes"
                aria-hidden="true"
                class="modal fade"
                role="dialog"
                tabindex="-1"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <BaseBlock class="mb-0" transparent>
                      <template #content>
                        <div
                          class="block-content fs-sm"
                          v-if="passengerFare && passengerFare.taxFeeSummary"
                        >
                          <div class="p-3">
                            <div class="row font_weight">
                              <div class="col-9">
                                {{ $t("name") }}
                              </div>

                              <div class="col-3">
                                {{ $t("amount") }}
                              </div>
                            </div>
                            <div
                              class="row"
                              v-for="(
                                taxItem, taxIndex
                              ) in passengerFare.taxFeeSummary"
                              :key="taxIndex"
                            >
                              <div class="col-9">
                                {{
                                  taxItem.name
                                    ? taxItem.name + "(" + taxItem.code + ")"
                                    : taxItem.code
                                }}
                              </div>

                              <div class="col-3">
                                {{ taxItem.currencyCode }}
                                {{ taxItem.amount.toFixed(2) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="block-content block-content-full text-end bg-body"
                        >
                          <button
                            class="btn btn-sm btn-alt-secondary me-1"
                            data-bs-dismiss="modal"
                            type="button"
                          >
                            {{ $t("close") }}
                          </button>
                        </div>
                      </template>
                    </BaseBlock>
                  </div>
                </div>
              </div>
              <!--taxes 详情END -->
            </div>
            <div
              class="row"
              v-if="priceBreakdownContentRef.discountTotalAmount"
            >
              <div class="col-lg-8 col-8">
                <figure>
                  <div>
                    <span
                      v-if="
                        tripPackageItem.tripAirItem.tripAirItemFare
                          .totalDiscount
                      "
                      >Discount on Reservation</span
                    >
                  </div>
                  <div>
                    <span
                      class="font_weight"
                      v-if="priceBreakdownContentRef.discountTotalAmount"
                      >Discount Total</span
                    >
                  </div>
                </figure>
              </div>
              <div class="col-lg-4 col-4">
                <figure class="text-end">
                  <div
                    v-if="
                      tripPackageItem.tripAirItem.tripAirItemFare.totalDiscount
                    "
                  >
                    {{
                      tripPackageItem.tripAirItem.tripAirItemFare.currencyCode
                    }}
                    {{
                      tripPackageItem.tripAirItem.tripAirItemFare.totalDiscount.toFixed(
                        2
                      )
                    }}
                  </div>
                  <div
                    class="font_weight"
                    v-if="priceBreakdownContentRef.discountTotalAmount"
                  >
                    {{
                      tripPackageItem.tripAirItem.tripAirItemFare.currencyCode
                    }}
                    {{
                      priceBreakdownContentRef.discountTotalAmount.toFixed(2)
                    }}
                  </div>
                </figure>
              </div>

              <hr class="mg-up-10" />
            </div>
          </div>
        </div>

        <div class="row font_weight">
          <div class="col-lg-8 col-8">
            <figure>
              <div>
                {{ $t("total-uppercase") }}
                ({{ tripPackageItemRef.currencyCode }})
              </div>
            </figure>
          </div>
          <div class="col-lg-4 col-4">
            <figure class="text-end text-danger">
              <div>
                {{ tripPackageItemRef.currencyCode }}
                {{ tripPackageItemRef.totalAmount.toFixed(2) }}
              </div>
            </figure>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center">
      <button class="btn btn_theme mb-2" @click="confirmBooking">
        {{ $t("confirm-booking") }}
      </button>
    </div>
  </div>

  <!-- 日期价格 -->
  <div
    id="modal-daily-prices"
    aria-hidden="true"
    aria-labelledby="modal-daily-prices"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <HotelDailyRatesPanel
          :room-item="
            priceBreakdownContentRef ? priceBreakdownContentRef.roomInfo : {}
          "
          :room-num="
            priceBreakdownContentRef
              ? priceBreakdownContentRef.roomQuantity
              : null
          "
          :room-type-name="
            priceBreakdownContentRef
              ? priceBreakdownContentRef.roomTypeName
              : null
          "
        />
      </div>
    </div>
  </div>
  <!-- 日期价格 END-->
</template>

<style lang="css" scoped></style>

<script setup>
import BaseHotelAmenityItem from "@/views/sales/hotel/items/group/BaseHotelAmenityItem.vue";
import { useHotelStore } from "@/stores/hotel";
import { computed } from "vue";
import { useDynamicPackageStore } from "@/stores/dynamicPackage";
//Pinia store 相关
const hotelStore = useHotelStore();
const dynamicPackageStore = useDynamicPackageStore();

const amenities = computed(() => {
  //单独酒店，酒店详情页
  const hotelViewItemMap = hotelStore.hotelViewItemMap;
  let hotelViewItem = null;
  //机加酒，酒店详情页
  const pkgHotelViewItemMap = dynamicPackageStore.hotelViewItemMap;

  if (hotelViewItemMap != null) {
    for (const hotelViewItemMapKey in hotelViewItemMap) {
      hotelViewItem = hotelViewItemMap[hotelViewItemMapKey];
      break;
    }
    let facilities = null;
    if (hotelViewItem) facilities = hotelViewItem.facilities;
    return {
      hotelAmenities: facilities,
      roomAmenities: [],
    };
  } else if (pkgHotelViewItemMap != null) {
    for (const hotelViewItemMapKey in pkgHotelViewItemMap) {
      hotelViewItem = pkgHotelViewItemMap[hotelViewItemMapKey];
      break;
    }
    let facilities = null;
    if (hotelViewItem) facilities = hotelViewItem.facilities;
    return {
      hotelAmenities: facilities,
      roomAmenities: [],
    };
  }

  return null;
});
</script>

<template>
  <div class="tour_details_boxed" v-if="amenities">
    <h3 class="heading_theme">{{$t("hotel-amenities")}}</h3>
    <div class="tour_details_boxed_inner">
      <!-- Hotel amenities -->
      <BaseHotelAmenityItem
        v-if="amenities.hotelAmenities"
        :items="amenities.hotelAmenities"
      ></BaseHotelAmenityItem>
      <!-- END Hotel amenities -->
      <hr
        v-if="amenities.roomAmenities && amenities.roomAmenities.length > 0"
      />
      <!-- Room amenities -->
      <BaseHotelAmenityItem
        v-if="amenities.roomAmenities && amenities.roomAmenities.length > 0"
        :items="amenities.roomAmenities"
      ></BaseHotelAmenityItem>
      <!-- END Room amenities -->
    </div>
  </div>
</template>

<style scoped></style>

export class TripItemVO {
  constructor(
    tripId,
    invoiceId,
    tripStatus,
    currencyCode,
    totalAmount,
    createdDateTime,
    requesterName,
    passengerName,
    tripActivityItemCount,
    tripAirItemCount,
    tripCarRentalItemCount,
    tirpGroundItemCount,
    tripCruiseItemCount,
    tripFerryItemCount,
    tripHotelItemCount,
    tripInsuranceItemCount,
    tripRailItemCount,
    tripPackageItemCount,
    tripHolidayItemCount,
    tripTravelDocumentItemCount,
    tripActivityItems,
    tripAirItems,
    tripCarRentalItems,
    tirpGroundItems,
    tripCruiseItems,
    tripFerryItems,
    tripHotelItems,
    tripInsuranceItems,
    tripRailItems,
    tripPackageItems,
    tripHolidayItems,
    tripTravelDocumentItems
  ) {
    this.tripId = tripId;
    this.invoiceId = invoiceId;
    this.tripStatus = tripStatus;
    this.currencyCode = currencyCode;
    this.totalAmount = totalAmount;
    this.createdDateTime = createdDateTime;
    this.requesterName = requesterName;
    this.passengerName = passengerName;
    this.tripActivityItemCount = tripActivityItemCount;
    this.tripAirItemCount = tripAirItemCount;
    this.tripCarRentalItemCount = tripCarRentalItemCount;
    this.tirpGroundItemCount = tirpGroundItemCount;
    this.tripCruiseItemCount = tripCruiseItemCount;
    this.tripFerryItemCount = tripFerryItemCount;
    this.tripHotelItemCount = tripHotelItemCount;
    this.tripInsuranceItemCount = tripInsuranceItemCount;
    this.tripRailItemCount = tripRailItemCount;
    this.tripPackageItemCount = tripPackageItemCount;
    this.tripHolidayItemCount = tripHolidayItemCount;
    this.tripTravelDocumentItemCount = tripTravelDocumentItemCount;
    this.tripActivityItems = tripActivityItems;
    this.tripAirItems = tripAirItems;
    this.tripCarRentalItems = tripCarRentalItems;
    this.tirpGroundItems = tirpGroundItems;
    this.tripCruiseItems = tripCruiseItems;
    this.tripFerryItems = tripFerryItems;
    this.tripHotelItems = tripHotelItems;
    this.tripInsuranceItems = tripInsuranceItems;
    this.tripRailItems = tripRailItems;
    this.tripPackageItems = tripPackageItems;
    this.tripHolidayItems = tripHolidayItems;
    this.tripTravelDocumentItems = tripTravelDocumentItems;
  }

  addNewAirItem(tripAirItem) {
    if (this.tripAirItems == null) this.tripAirItems = [];
    this.tripAirItems.push(tripAirItem);
    this.tripAirItemCount = this.tripAirItems.length;
  }

  addNewTripActivityItem(tripActivityItem) {
    if (this.tripActivityItems == null) this.tripActivityItems = [];
    this.tripActivityItems.push(tripActivityItem);
    this.tripActivityItemCount = this.tripActivityItems.length;
  }

  addNewTripCarRentalItem(tripCarRentalItem) {
    if (this.tripCarRentalItems == null) this.tripCarRentalItems = [];
    this.tripCarRentalItems.push(tripCarRentalItem);
    this.tripCarRentalItemCount = this.tripCarRentalItems.length;
  }

  addNewTripGroundItem(tirpGroundItem) {
    if (this.tirpGroundItems == null) this.tirpGroundItems = [];
    this.tirpGroundItems.push(tirpGroundItem);
    this.tirpGroundItemCount = this.tirpGroundItems.length;
  }

  addNewTripCruiseItem(tripCruiseItem) {
    if (this.tripCruiseItems == null) this.tripCruiseItems = [];
    this.tripCruiseItems.push(tripCruiseItem);
    this.tripCruiseItemCount = this.tripCruiseItems.length;
  }

  addNewTripFerryItem(tripFerryItem) {
    if (this.tripFerryItems == null) this.tripFerryItems = [];
    this.tripFerryItems.push(tripFerryItem);
    this.tripFerryItemCount = this.tripFerryItems.length;
  }

  addNewTripHotelItem(tripHotelItem) {
    if (this.tripHotelItems == null) this.tripHotelItems = [];
    this.tripHotelItems.push(tripHotelItem);
    this.tripHotelItemCount = this.tripHotelItems.length;
  }

  addNewTripInsuranceItem(tripInsuranceItem) {
    if (this.tripInsuranceItems == null) this.tripInsuranceItems = [];
    this.tripInsuranceItems.push(tripInsuranceItem);
    this.tripInsuranceItemCount = this.tripInsuranceItems.length;
  }

  addNewTripRailItem(tripRailItem) {
    if (this.tripRailItems == null) this.tripRailItems = [];
    this.tripRailItems.push(tripRailItem);
    this.tripRailItemCount = this.tripRailItems.length;
  }

  addNewTripPackageItem(tripPackageItem) {
    if (this.tripPackageItems == null) this.tripPackageItems = [];
    this.tripPackageItems.push(tripPackageItem);
    this.tripPackageItemCount = this.tripPackageItems.length;
  }

  addNewTripHolidayItem(tripHolidayItem) {
    if (this.tripHolidayItems == null) this.tripHolidayItems = [];
    this.tripHolidayItems.push(tripHolidayItem);
    this.tripHolidayItemCount = this.tripHolidayItems.length;
  }

  addNewTripHolidayItem(tripHolidayItem) {
    if (this.tripHolidayItems == null) this.tripHolidayItems = [];
    this.tripHolidayItems.push(tripHolidayItem);
    this.tripHolidayItemCount = this.tripHolidayItems.length;
  }
}

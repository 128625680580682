export class FlightPriceClassDescriptionVO {

    /**
     *
     * @param code 描述代码
     * @param description 描述内容
     */
    constructor(code, description) {
        this.code = code;
        this.description = description;
    }
}
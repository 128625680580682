<script setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n(); // 解构调用函数
const props = defineProps({
  tripAirItem: {},
  passengerFormModelItems: {},
  servicesSelected: {},
  flightPriceFormModel: {},
});

const emits = defineEmits();

const tripAirItemFareRef = ref(null);
const passengerFormModelItemsRef = ref(null);
const isPriceSummaryShow = ref(true);
const segmentsRef = ref([]);

const priceBreakdownContentRef = ref({
  passengerFares: {},
  taxesTotalAmount: 0,
});

const serviceTotalAmountRef = ref({
  amount: 0,
  currencyCode: null,
});

watch(
  () => props.tripAirItem,
  (newValue) => {
    if (newValue && newValue.tripAirItemFare) {
      tripAirItemFareRef.value = newValue.tripAirItemFare;

      const totalDiscount = tripAirItemFareRef.value
        ? tripAirItemFareRef.value.totalDiscount
        : 0;

      priceBreakdownContentRef.value = {
        passengerFares: {},
        feesTotalAmount: 0,
        taxesTotalAmount: 0,
        discountTotalAmount: 0,
        hasDiscount: false,
      };

      serviceTotalAmountRef.value = {
        amount: 0,
        currencyCode: null,
      };

      if (
        tripAirItemFareRef.value &&
        tripAirItemFareRef.value.passengerFares &&
        tripAirItemFareRef.value.passengerFares.length > 0
      ) {
        for (const passengerFare of tripAirItemFareRef.value.passengerFares) {
          const passengerTypeCode =
            passengerFare && passengerFare.passengerTypeCode
              ? passengerFare.passengerTypeCode
              : null;
          priceBreakdownContentRef.value.taxesTotalAmount +=
            passengerFare && passengerFare.taxesAmount
              ? passengerFare.taxesAmount * passengerFare.quantity
              : 0;
          priceBreakdownContentRef.value.discountTotalAmount +=
            passengerFare && passengerFare.discount
              ? passengerFare.discount * passengerFare.quantity
              : 0;
          priceBreakdownContentRef.value.hasDiscount =
            passengerFare && passengerFare.discount ? true : false;
          priceBreakdownContentRef.value.feesTotalAmount +=
            passengerFare && passengerFare.feesAmount
              ? passengerFare.feesAmount * passengerFare.quantity
              : 0;

          if (
            priceBreakdownContentRef.value.passengerFares[passengerTypeCode]
          ) {
            const quantity = passengerFare.quantity;
            priceBreakdownContentRef.value.passengerFares[
              passengerTypeCode
            ].baseAmount += passengerFare.baseAmount * quantity;
            priceBreakdownContentRef.value.passengerFares[
              passengerTypeCode
            ].totalAmount += passengerFare.totalAmount * quantity;
            priceBreakdownContentRef.value.passengerFares[
              passengerTypeCode
            ].quantity += quantity;
          } else {
            priceBreakdownContentRef.value.passengerFares[passengerTypeCode] = {
              baseAmount:
                passengerFare && passengerFare.baseAmount
                  ? passengerFare.baseAmount * passengerFare.quantity
                  : 0,
              totalAmount:
                passengerFare && passengerFare.totalAmount
                  ? passengerFare.totalAmount * passengerFare.quantity
                  : 0,
              quantity:
                passengerFare && passengerFare.quantity
                  ? passengerFare.quantity
                  : 1,
            };
          }
        }

        priceBreakdownContentRef.value.discountTotalAmount += totalDiscount;
        if (priceBreakdownContentRef.value.discountTotalAmount)
          priceBreakdownContentRef.value.hasDiscount = true;
      }
    }

    let ancillaryFares = [];

    if (tripAirItemFareRef.value && tripAirItemFareRef.value.ancillaryFares) {
      ancillaryFares = tripAirItemFareRef.value.ancillaryFares;
      if (ancillaryFares && ancillaryFares.length > 0) {
        ancillaryFares.forEach((item) => {
          serviceTotalAmountRef.value.amount += item.amount;
          serviceTotalAmountRef.value.currencyCode = item.currencyCode;
        });
      }
    }

    segmentsRef.value = [];
    if (newValue && newValue.flightItems && newValue.flightItems.length > 0) {
      newValue.flightItems.forEach((item, index) => {
        segmentsRef.value.push(...item.flightSegments);
      });
    }
  },
  { deep: true, immediate: true }
);
watch(
  () => props.passengerFormModelItems,
  (newValue) => {
    passengerFormModelItemsRef.value = newValue;
  },
  { deep: true, immediate: true }
);

const flightPriceFormModelRef = ref(null);

watch(
  () => props.flightPriceFormModel,
  (newValue) => {
    flightPriceFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

function changePriceSummaryShow() {
  isPriceSummaryShow.value = !isPriceSummaryShow.value;
}

function getPassengerType(typeCode) {
  let typeName = t("adult");
  if (typeCode === "CNN") {
    typeName = t("child");
  } else if (typeCode === "INF") {
    typeName = t("infant");
  }
  return typeName;
}

//添加乘客序号 -》遍历
function addPassengerIndex(passengerFares) {
  const passengerFaresCopy = JSON.parse(JSON.stringify(passengerFares)); //不改变元数据结构

  if (passengerFaresCopy && passengerFaresCopy.length > 0) {
    let index = 1;
    for (let i = 0; i < passengerFaresCopy.length; i++) {
      const passengerFare = passengerFaresCopy[i];
      const travelerRefNumbers = passengerFare.travelerRefNumbers;
      let travelerNumbers = [];
      if (travelerRefNumbers && travelerRefNumbers.length > 0) {
        for (let j = 0; j < travelerRefNumbers.length; j++) {
          travelerNumbers.push({
            rph: travelerRefNumbers[j],
            passengerIndex: index++,
          });
        }
      }
      passengerFare.travelerNumbers = travelerNumbers;
    }
  }
  return passengerFaresCopy;
}

function getPassengerServices(servicesSelected, travelerRefNumberRPH) {
  let services = [];
  if (servicesSelected && servicesSelected.length > 0) {
    services = servicesSelected.filter(
      (item) => item.travelerRPH === travelerRefNumberRPH
    );
  }
  return services;
}

function getPassengerServicesTotalAmount(
  servicesSelected,
  travelerRefNumberRPH
) {
  let services = [];
  if (servicesSelected && servicesSelected.length > 0) {
    services = servicesSelected.filter(
      (item) => item.travelerRPH === travelerRefNumberRPH
    );
  }

  let totalAmount = 0;
  let currencyCode = null;
  if (services && services.length > 0) {
    services.forEach((item) => {
      totalAmount += item.amount;
      currencyCode = item.currencyCode;
    });
  }
  return {
    amount: totalAmount,
    currencyCode: currencyCode,
  };
}

function getSegmentFlight(flightSegmentRPH, segments) {
  if (segments && segments.length > 0) {
    let segmentItem = segments.find((item) => item.rph === flightSegmentRPH);
    if (segmentItem) {
      return segmentItem.marketingAirlineCode + segmentItem.flightNumber;
    }
  }
}

//按照成年人优先显示
function sortPassengerFares(passengerFares) {
  const passengerFareSort = {};
  if (passengerFares) {
    Object.keys(passengerFares)
      .sort()
      .map((item) => {
        passengerFareSort[item] = passengerFares[item];
      });
  }
  return passengerFareSort;
}
</script>

<template>
  <div class="tour_booking_form_box mg-t-30">
    <div class="row">
      <div class="col-6">
        <h3 class="heading_theme">
          {{ isPriceSummaryShow ? $t("price-summary") : $t("price-breakdown") }}
        </h3>
      </div>
      <div class="col-6 text-end">
        <button
          class="btn btn_border"
          type="reset"
          @click="changePriceSummaryShow"
        >
          {{
            isPriceSummaryShow
              ? $t("view-price-breakdown")
              : $t("view-price-summary")
          }}
        </button>
      </div>
    </div>

    <div
      v-if="
        isPriceSummaryShow && priceBreakdownContentRef && tripAirItemFareRef
      "
    >
      <div class="row">
        <div class="col-12">
          <div class="row text-muted">
            <div class="col-8">{{ $t("description-uppercase") }}</div>
            <div v-if="tripAirItemFareRef" class="col-4 text-end">
              {{ $t("price-uppercase") }} ({{
                tripAirItemFareRef.currencyCode
              }})
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-8 col-8">
              <figure>
                <div
                  v-if="
                    priceBreakdownContentRef &&
                    priceBreakdownContentRef.passengerFares
                  "
                >
                  <div
                    v-for="(
                      passengerFare, passengerFareKey
                    ) in sortPassengerFares(
                      priceBreakdownContentRef.passengerFares
                    )"
                    :key="passengerFareKey"
                  >
                    {{ passengerFareKey }} x
                    {{ passengerFare.quantity }}
                  </div>
                </div>
                <div
                  v-if="
                    priceBreakdownContentRef &&
                    priceBreakdownContentRef.taxesTotalAmount
                  "
                >
                  {{ $t("taxes") }}
                </div>

                <div
                  v-if="
                    priceBreakdownContentRef &&
                    priceBreakdownContentRef.feesTotalAmount
                  "
                  v-html="$t('transaction-fees')"
                ></div>
                <div
                  v-if="
                    priceBreakdownContentRef &&
                    priceBreakdownContentRef.hasDiscount
                  "
                >
                  {{ $t("discount-uppercase") }}
                </div>
                <div v-if="serviceTotalAmountRef.currencyCode">
                  {{ $t("additional-service-charge") }}
                </div>
              </figure>
            </div>
            <div class="col-lg-4 col-4">
              <figure class="text-end">
                <div
                  v-if="
                    priceBreakdownContentRef &&
                    priceBreakdownContentRef.passengerFares
                  "
                >
                  <div
                    v-for="(
                      passengerFare, passengerFareKey
                    ) in sortPassengerFares(
                      priceBreakdownContentRef.passengerFares
                    )"
                    :key="passengerFareKey"
                  >
                    {{ tripAirItemFareRef.currencyCode }}
                    {{ passengerFare.baseAmount.toFixed(2) }}
                  </div>
                </div>
                <div
                  v-if="
                    priceBreakdownContentRef &&
                    priceBreakdownContentRef.taxesTotalAmount
                  "
                >
                  {{ tripAirItemFareRef.currencyCode }}
                  {{ priceBreakdownContentRef.taxesTotalAmount.toFixed(2) }}
                </div>

                <div
                  v-if="
                    priceBreakdownContentRef &&
                    priceBreakdownContentRef.feesTotalAmount
                  "
                >
                  {{ tripAirItemFareRef.currencyCode }}
                  {{ priceBreakdownContentRef.feesTotalAmount.toFixed(2) }}
                </div>
                <div
                  v-if="
                    priceBreakdownContentRef &&
                    priceBreakdownContentRef.hasDiscount
                  "
                >
                  {{ tripAirItemFareRef.currencyCode }}
                  {{ priceBreakdownContentRef.discountTotalAmount.toFixed(2) }}
                </div>
                <div
                  v-if="
                    serviceTotalAmountRef && serviceTotalAmountRef.currencyCode
                  "
                >
                  {{ serviceTotalAmountRef.currencyCode }}
                  {{ serviceTotalAmountRef.amount.toFixed(2) }}
                </div>
              </figure>
            </div>
            <hr class="mg-up-10" />
          </div>

          <div v-if="tripAirItemFareRef" class="row font_weight">
            <div class="col-lg-8 col-8">
              <figure>
                <div>
                  {{ $t("total-uppercase") }}
                  ({{ tripAirItemFareRef.currencyCode }})
                </div>
              </figure>
            </div>
            <div class="col-lg-4 col-4">
              <figure class="text-end text-danger">
                <div>
                  {{ tripAirItemFareRef.currencyCode }}
                  {{ tripAirItemFareRef.totalAmount.toFixed(2) }}
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div
          v-if="
            tripAirItemFareRef &&
            tripAirItemFareRef.passengerFares &&
            tripAirItemFareRef.passengerFares.length > 0
          "
          class="col-12"
        >
          <div class="row text-muted">
            <div class="col-8">{{ $t("description-uppercase") }}</div>
            <div class="col-4 text-end">
              {{ $t("price-uppercase") }} ({{
                tripAirItemFareRef.currencyCode
              }})
            </div>
          </div>
          <hr />
          <div
            v-for="(passengerFare, passengerFareIndex) in addPassengerIndex(
              tripAirItemFareRef.passengerFares
            )"
            :key="passengerFareIndex"
            class="row"
          >
            <div class="col-12">
              <div
                v-for="(
                  travelerRef, travelerRefNumberIndex
                ) in passengerFare.travelerNumbers"
                :key="travelerRefNumberIndex"
                class="row"
              >
                <div class="col-lg-8 col-8">
                  <figure>
                    <div>
                      <span class="font_weight"
                        >{{ $t("traveler") }}
                        {{ travelerRef.passengerIndex }}
                      </span>
                      {{ getPassengerType(passengerFare.passengerTypeCode) }}
                      ({{ travelerRef.rph }} )
                    </div>
                    <div class="mg-l-10">{{ $t("flight") }}</div>
                    <div class="mg-l-10">
                      {{ $t("taxes") }}
                      <i
                        class="fa-solid fa-eye mg-l-5 cursor-point"
                        data-bs-target="#modal-flight-taxes"
                        data-bs-toggle="modal"
                        v-if="
                          passengerFare.taxFeeSummary &&
                          passengerFare.taxFeeSummary.length > 0
                        "
                      ></i>
                    </div>

                    <div
                      class="mg-l-10"
                      v-html="$t('transaction-fees')"
                      v-if="passengerFare.feesAmount"
                    ></div>
                    <div
                      class="mg-l-10"
                      v-if="
                        priceBreakdownContentRef &&
                        priceBreakdownContentRef.hasDiscount
                      "
                    >
                      {{ $t("discount") }}
                    </div>
                    <div
                      v-if="
                        tripAirItemFareRef.ancillaryFares &&
                        tripAirItemFareRef.ancillaryFares.length > 0 &&
                        getPassengerServices(
                          tripAirItemFareRef.ancillaryFares,
                          travelerRef.rph
                        ) &&
                        getPassengerServices(
                          tripAirItemFareRef.ancillaryFares,
                          travelerRef.rph
                        ).length > 0
                      "
                    >
                      <div class="font_weight">
                        {{ $t("services-summary") }}
                      </div>
                      <div class="p-1 font-14">
                        <div
                          v-for="(
                            service, serviceIndex
                          ) in getPassengerServices(
                            tripAirItemFareRef.ancillaryFares,
                            travelerRef.rph
                          )"
                          :key="serviceIndex"
                          class="mg-l-10"
                        >
                          {{ service.name }}
                          <span class="text-muted"
                            >({{
                              getSegmentFlight(
                                service.flightSegmentRPH,
                                segmentsRef
                              )
                            }})</span
                          >
                          <span
                            v-if="service.status === 'PRICED'"
                            class="fw-semibold d-inline-block py-1 px-3 fs-sm rounded bg-danger-light mg-l-5"
                            >{{ $t("unpaid") }}</span
                          >
                        </div>
                        <div
                          v-if="
                            getPassengerServicesTotalAmount(
                              tripAirItemFareRef.ancillaryFares,
                              travelerRef.rph
                            )
                          "
                          class="font_weight mg-l-10"
                        >
                          {{ $t("services-total") }}
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
                <div class="col-lg-4 col-4">
                  <figure class="text-end" v-if="passengerFare">
                    <div
                      class="font_weight"
                      v-if="
                        passengerFare.totalAmount != null ||
                        passengerFare.totalAmount === 0
                      "
                    >
                      {{ passengerFare.currencyCode }}
                      {{ passengerFare.totalAmount.toFixed(2) }}
                    </div>
                    <div
                      class="text-muted font-14"
                      v-if="
                        passengerFare.baseAmount != null ||
                        passengerFare.baseAmount === 0
                      "
                    >
                      {{ passengerFare.currencyCode }}
                      {{ passengerFare.baseAmount.toFixed(2) }}
                    </div>
                    <div
                      class="text-muted font-14"
                      v-if="
                        passengerFare.taxesAmount != null ||
                        passengerFare.taxesAmount === 0
                      "
                    >
                      {{ passengerFare.currencyCode }}
                      {{ passengerFare.taxesAmount.toFixed(2) }}
                    </div>
                    <div
                      class="text-muted font-14"
                      v-if="passengerFare.feesAmount"
                    >
                      {{ passengerFare.currencyCode }}
                      {{ passengerFare.feesAmount.toFixed(2) }}
                    </div>

                    <div
                      class="text-muted font-14"
                      v-if="
                        passengerFare.discount != null ||
                        passengerFare.discount === 0
                      "
                    >
                      {{ passengerFare.currencyCode }}
                      {{ passengerFare.discount.toFixed(2) }}
                    </div>
                    <div
                      v-if="
                        tripAirItemFareRef.ancillaryFares &&
                        tripAirItemFareRef.ancillaryFares.length > 0 &&
                        getPassengerServices(
                          tripAirItemFareRef.ancillaryFares,
                          travelerRef.rph
                        ) &&
                        getPassengerServices(
                          tripAirItemFareRef.ancillaryFares,
                          travelerRef.rph
                        ).length > 0
                      "
                    >
                      <div class="font_weight">
                        {{ $t("price") }}
                      </div>
                      <div class="p-1 font-14">
                        <div
                          v-for="(
                            service, serviceIndex
                          ) in getPassengerServices(
                            tripAirItemFareRef.ancillaryFares,
                            travelerRef.rph
                          )"
                          :key="serviceIndex"
                        >
                          {{ service.currencyCode }}
                          {{ service.amount.toFixed(2) }}
                        </div>
                        <div
                          v-if="
                            getPassengerServicesTotalAmount(
                              tripAirItemFareRef.ancillaryFares,
                              travelerRef.rph
                            )
                          "
                          class="font_weight"
                        >
                          {{
                            getPassengerServicesTotalAmount(
                              tripAirItemFareRef.ancillaryFares,
                              travelerRef.rph
                            ).currencyCode
                          }}
                          {{
                            getPassengerServicesTotalAmount(
                              tripAirItemFareRef.ancillaryFares,
                              travelerRef.rph
                            ).amount.toFixed(2)
                          }}
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>

                <hr class="mg-up-10" />
              </div>
            </div>
            <!-- taxes 详情 -->
            <div
              id="modal-flight-taxes"
              aria-labelledby="modal-flight-taxes"
              aria-hidden="true"
              class="modal fade"
              role="dialog"
              tabindex="-1"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <BaseBlock class="mb-0" transparent>
                    <template #content>
                      <div
                        class="block-content fs-sm"
                        v-if="passengerFare && passengerFare.taxFeeSummary"
                      >
                        <div class="p-3">
                          <div class="row font_weight">
                            <div class="col-9">
                              {{ $t("name") }}
                            </div>

                            <div class="col-3">
                              {{ $t("amount") }}
                            </div>
                          </div>
                          <div
                            class="row"
                            v-for="(
                              taxItem, taxIndex
                            ) in passengerFare.taxFeeSummary"
                            :key="taxIndex"
                          >
                            <div class="col-9">
                              {{
                                taxItem.name
                                  ? taxItem.name + "(" + taxItem.code + ")"
                                  : taxItem.code
                              }}
                            </div>

                            <div class="col-3">
                              {{ taxItem.currencyCode }}
                              {{ taxItem.amount.toFixed(2) }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="block-content block-content-full text-end bg-body"
                      >
                        <button
                          class="btn btn-sm btn-alt-secondary me-1"
                          data-bs-dismiss="modal"
                          type="button"
                        >
                          {{ $t("close") }}
                        </button>
                      </div>
                    </template>
                  </BaseBlock>
                </div>
              </div>
            </div>
            <!--taxes 详情END -->
          </div>
          <div class="row" v-if="priceBreakdownContentRef.discountTotalAmount">
            <div class="col-lg-8 col-8">
              <figure>
                <div>
                  <span v-if="tripAirItemFareRef.totalDiscount"
                    >Discount on Reservation</span
                  >
                </div>
                <div>
                  <span
                    class="font_weight"
                    v-if="priceBreakdownContentRef.discountTotalAmount"
                    >Discount Total</span
                  >
                </div>
              </figure>
            </div>
            <div class="col-lg-4 col-4">
              <figure class="text-end">
                <div v-if="tripAirItemFareRef.totalDiscount">
                  {{ tripAirItemFareRef.currencyCode }}
                  {{ tripAirItemFareRef.totalDiscount.toFixed(2) }}
                </div>
                <div
                  class="font_weight"
                  v-if="priceBreakdownContentRef.discountTotalAmount"
                >
                  {{ tripAirItemFareRef.currencyCode }}
                  {{ priceBreakdownContentRef.discountTotalAmount.toFixed(2) }}
                </div>
              </figure>
            </div>

            <hr class="mg-up-10" />
          </div>
          <div v-if="tripAirItemFareRef" class="row font_weight">
            <div class="col-lg-8 col-8">
              <figure>
                <div>
                  {{ $t("total-uppercase") }}
                  ({{ tripAirItemFareRef.currencyCode }})
                </div>
              </figure>
            </div>
            <div class="col-lg-4 col-4">
              <figure class="text-end text-danger">
                <div v-if="tripAirItemFareRef.totalAmount">
                  {{ tripAirItemFareRef.currencyCode }}
                  {{ tripAirItemFareRef.totalAmount.toFixed(2) }}
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

export class FlightReshopItemVO {
  /**
   *
   * @param tripAirItem TripAirItemVO对象
   * @param addItems FlightReshopAddItemVO对象数组
   */
  constructor(tripAirItem, addItems) {
    this.tripAirItem = tripAirItem;
    this.addItems = addItems;
  }

  addReshopAddItem(reshopAddItem) {
    if (!this.addItems) this.addItems = [];
    this.addItems.push(reshopAddItem);
  }
}

<script setup>
import { ref, watch } from "vue";
import { integer } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import carRentalSortOptions from "@/formmodel/sales/carrental/availablility/options/carRentalSortOptions";
import carRentalFilterOptions from "@/formmodel/sales/carrental/availablility/options/carRentalFilterOptions";

const props = defineProps({
  carRentalItemsLength: {},
  carRentalFilterFormModel: {},
  carRentalSortFormModel: {},
});

const emits = defineEmits("sortAndFilter");
const carRentalSortFormModelRef = ref(null);
const carRentalFilterFormModelRef = ref(null);

watch(
  () => props.carRentalSortFormModel,
  (newValue) => {
    carRentalSortFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watch(
  () => props.carRentalFilterFormModel,
  (newValue) => {
    carRentalFilterFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

//region 价格区间校验，当最低价格低于最高价格或者二者均为空时校验通过
const priceMinAndMaxRef = ref({
  min: null,
  max: null,
});
const priceRangeRule = () =>
  priceMinAndMaxRef.value.min < priceMinAndMaxRef.value.max ||
  (priceMinAndMaxRef.value.min === priceMinAndMaxRef.value.max &&
    priceMinAndMaxRef.value.min === null) ||
  (priceMinAndMaxRef.value.min === priceMinAndMaxRef.value.max &&
    priceMinAndMaxRef.value.min === "") ||
  (!priceMinAndMaxRef.value.min && !priceMinAndMaxRef.value.max);
const rules = {
  min: { integer, priceRangeRule },
  max: { integer, priceRangeRule },
};
const v = useVuelidate(rules, priceMinAndMaxRef);

async function changePrice(priceMinAndMax, sortFormModel, filterFormModel) {
  const validateResult = await v.value.$validate();
  if (!validateResult) return;

  if (priceMinAndMax.min === 0 && priceMinAndMax.max === 0) {
    priceMinAndMaxRef.value = {
      min: null,
      max: null,
    };
  }
  if (!priceMinAndMax.min && !priceMinAndMax.max) {
    filterFormModel.priceRange = [];
  } else {
    filterFormModel.priceRange = [priceMinAndMax.min, priceMinAndMax.max];
  }
  emits("sortAndFilter", sortFormModel, filterFormModel);
}

//endregion

function sortAndFilter(sortFormModel, filterFormModel) {
  emits("sortAndFilter", sortFormModel, filterFormModel);
}
</script>

<template>
  <!-- Inbox Side Navigation -->
  <div class="flight_filter_sort">
    <div class="left_side_search_boxed">
      <h4 class="mg-b-20">
        {{
          carRentalItemsLength
            ? $t("vehicle-num", { carRentalItemsLength: carRentalItemsLength })
            : $t("vehicle-num", { carRentalItemsLength: 0 })
        }}
      </h4>
      <!-- 排序区域 -->
      <div class="mg-b-20">
        <div class="left_side_search_heading">
          <h5>{{ $t("sort-by") }}</h5>
        </div>
        <div class="filter_review" v-if="carRentalSortOptions">
          <form class="review_star">
            <div
              class="form-check p-0"
              v-for="(sort, index) in carRentalSortOptions.sortOptions"
              :key="index"
            >
              <input
                class="form-check-input"
                type="radio"
                :id="'sort-radios' + index"
                v-model="carRentalSortFormModelRef"
                :name="'sort-radios' + index"
                :value="{
                  type: sort.type,
                  order: sort.order,
                }"
                @change="
                  sortAndFilter(
                    carRentalSortFormModelRef,
                    carRentalFilterFormModelRef
                  )
                "
              />
              <label :for="'sort-radios' + index" class="form-check-label">{{
                $t(sort.label)
              }}</label>
            </div>
          </form>
        </div>
      </div>
      <!-- 排序区域END -->
      <!-- 过滤区域 -->
      <div v-if="carRentalFilterOptions">
        <div class="left_side_search_heading">
          <h5>{{ $t("filter-by") }}</h5>
        </div>
        <div class="filter_review">
          <form class="review_star">
            <label
              class="form-label text-danger"
              v-if="v.min.$errors.length > 0 || v.max.$errors.length > 0"
              >{{ $t("please-enter-price-range") }}
            </label>
            <label class="form-label" v-else
              >{{ $t("filter-by-price") }}
            </label>
            <div class="mg-t-10 form-group flex-between align-content-center">
              <input
                id="login-username"
                v-model="v.min.$model"
                :class="{
                  'is-invalid': v.min.$errors.length > 0,
                }"
                class="form-control"
                name="login-username"
                :placeholder="$t('min-price')"
                type="number"
                @blur="v.min.$touch"
                @change="
                  changePrice(
                    priceMinAndMaxRef,
                    carRentalSortFormModelRef,
                    carRentalFilterFormModelRef
                  )
                "
              />
              <div
                style="
                  padding-left: 10px;
                  padding-right: 10px;
                  padding-top: 25px;
                "
              >
                <div
                  style="width: 20px; height: 1px; background-color: #cccccc"
                ></div>
              </div>
              <input
                id="login-username"
                v-model="v.max.$model"
                :class="{
                  'is-invalid': v.max.$errors.length > 0,
                }"
                class="form-control"
                name="login-username"
                :placeholder="$t('max-price')"
                type="number"
                @blur="v.max.$touch"
                @change="
                  changePrice(
                    priceMinAndMaxRef,
                    carRentalSortFormModelRef,
                    carRentalFilterFormModelRef
                  )
                "
              />
            </div>
          </form>
        </div>

        <div class="filter_review">
          <form class="review_star">
            <label class="form-label"> Transmission</label>
            <div
              class="form-check p-0"
              v-for="(
                transmission, index
              ) in carRentalFilterOptions.transmissionOption"
              :key="index"
            >
              <input
                :id="'example-radios-default' + index"
                v-model="carRentalFilterFormModelRef.transmission"
                :name="'example-radios-default' + index"
                :value="transmission.value"
                class="form-check-input"
                type="radio"
                @change="
                  sortAndFilter(
                    carRentalSortFormModelRef,
                    carRentalFilterFormModelRef
                  )
                "
              />
              <label
                :for="'example-radios-default' + index"
                class="form-check-label"
                >{{ transmission.label ? $t(transmission.label) : null }}</label
              >
            </div>
          </form>
        </div>
        <div class="filter_review">
          <form class="review_star">
            <label class="form-label"> {{ $t("seats") }}</label>
            <div
              v-for="(seat, index) in carRentalFilterOptions.seatOption"
              :key="index"
              class="form-check"
            >
              <input
                :id="'star-rating-radio' + index"
                v-model="carRentalFilterFormModelRef.seats"
                :name="'example-checkbox-inline' + index"
                :value="seat.value"
                class="form-check-input"
                type="checkbox"
                @change="
                  sortAndFilter(
                    carRentalSortFormModelRef,
                    carRentalFilterFormModelRef
                  )
                "
              />
              <label class="form-check-label">
                {{ $t(seat.label) }}
              </label>
            </div>
          </form>
        </div>
      </div>
      <!-- 过滤区域END -->
    </div>
  </div>
  <!-- END Inbox Side Navigation -->
</template>

<style lang="scss" scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>

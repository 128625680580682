import axios from "@/utils/axiosUtils";

function addContact(rq, partyId) {
  let url = "/api/v3/parties/" + partyId + "/contactmechs";
  return axios.post(url, rq, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function modifyContact(rq, partyId, contactMechId) {
  let url = "/api/v3/parties/" + partyId + "/contactmechs/" + contactMechId;
  return axios.put(url, rq, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  addContact,
  modifyContact,
};

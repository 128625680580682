<script setup>
import PrivacyPolicyContent from "@/components/templates/pages/PrivacyPolicyContent.vue";
import { ref, watch } from "vue";
import { useCmsContentStore } from "@/stores/cms";

const privacyPolicyStore = useCmsContentStore();
privacyPolicyStore.getPrivacyPolicy();
privacyPolicyStore.getProductBannerBackground();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => privacyPolicyStore.productPageBackgroundImage,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    const imageUrl =
      privacyPolicyStore.productPageBackgroundImage &&
      privacyPolicyStore.productPageBackgroundImage.privacyPolicyBackgropImage
        ? privacyPolicyStore.productPageBackgroundImage
            .privacyPolicyBackgropImage.url
        : "";
    backgroundImageUrlRef.value = baseUrl + imageUrl;
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrlRef + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("privacy-policy") }}</h2>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <!-- Privacy Policy Areas -->
  <PrivacyPolicyContent :privacy-policys="privacyPolicyStore.privacyPolicy" />
</template>

<script setup>
import { reactive, computed, ref, onMounted, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import dateUtils from "@/utils/dateUtils";
import BaseHotelAmenityGroup from "@/views/sales/hotel/items/group/BaseHotelAmenityGroup.vue";
import BaseHotelPolicyGroup from "@/views/sales/hotel/items/group/BaseHotelPolicyGroup.vue";
import { useVuelidate } from "@vuelidate/core";
import { useTemplateStore } from "@/stores/template";
import { Loader } from "@googlemaps/js-api-loader";
import BasePackagePassengersForm from "@/views/sales/package/items/form/BasePkgSearchPassengersForm.vue";
import BasePackageHotelRoomTypeItem from "@/views/sales/package/items/group/BasePackageHotelRoomTypeItem.vue";
import { useDynamicPackageStore } from "@/stores/dynamicPackage";
// Main store and Router
const templateStore = useTemplateStore();
//router相关
const route = useRoute();
const router = useRouter();
const dynamicPackageStore = useDynamicPackageStore();
const props = defineProps({
  hotelViewItem: {},
});
//获取参数 - 得到表单对象 - 更新pinia - start
let packageHotelSearchFormModel = reactive(null);
let packageRoomPassengersFormModel = ref([]);
let cabinClassSelected = ref({ code: null });
if (route.query != null && Object.keys(route.query).length > 0) {
  packageHotelSearchFormModel = reactive(getPackageHotelSearchFormModel(route));
  cabinClassSelected.value.code = packageHotelSearchFormModel.cabinClass;
  packageRoomPassengersFormModel.value = [];
  for (let i = 0; i < packageHotelSearchFormModel.roomNum; i++) {
    let childAges = [];
    if (
      packageHotelSearchFormModel.childAges &&
      packageHotelSearchFormModel.childAges.length > 0
    ) {
      packageHotelSearchFormModel.childAges.forEach((childAge) => {
        if (childAge.roomId == i) {
          let child = {
            roomId: i,
            age: childAge.age,
          };
          childAges.push(child);
        }
      });
    }
    let roomPassengersModelItem = {
      adultNum: packageHotelSearchFormModel.adultNum,
      childNum:
        packageHotelSearchFormModel.childNum == null
          ? 0
          : packageHotelSearchFormModel.childNum,
      childAges: childAges,
    };
    packageRoomPassengersFormModel.value.push(roomPassengersModelItem);
  }
  searchHotelViewItemsByPackage(packageHotelSearchFormModel);
}

async function searchHotelViewItemsByPackage(packageHotelSearchFormModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await dynamicPackageStore.availDynamicPkg(
    packageHotelSearchFormModel
  );
  templateStore.pageLoader({ mode: "off" });
}

function parseChildAges(formats) {
  let childAges = [];
  if (formats && formats.length > 0) {
    formats.forEach((format) => {
      let splits = format.split(":");
      let roomId = splits[0];
      let age = splits[1];

      let childAge = {
        roomId: roomId,
        age: age,
      };
      childAges.push(childAge);
    });
  }
  return childAges;
}

function getPackageHotelSearchFormModel(route) {
  let childAges = null;
  if (route.query.childAgesFormat) {
    let childAgesFormat = route.query.childAgesFormat;
    // console.info(childAgesFormat);
    if (typeof childAgesFormat === "string") {
      childAgesFormat = [childAgesFormat];
    }
    childAges = parseChildAges(childAgesFormat);
  }

  let adultNum = route.query.adultNum;
  let childNum = route.query.childNum;
  let cabinClass = route.query.cabinClass;
  let departureDate = route.query.departureDate;
  let destinationAirportName = route.query.destinationAirportName;
  let destinationLocationCode = route.query.destinationLocationCode;
  let destinationLocationName = route.query.destinationLocationName;
  let originAirportName = route.query.originAirportName;
  let originLocationCode = route.query.originLocationCode;
  let originLocationName = route.query.originLocationName;
  let returnDate = route.query.returnDate;
  let roomNum = parseInt(route.query.roomNum);
  let ttiCode = route.query.ttiCode;

  return {
    adultNum: adultNum,
    childNum: childNum,
    cabinClass: cabinClass,
    childAges: childAges,
    departureDate: departureDate,
    destinationAirportName: destinationAirportName,
    destinationLocationCode: destinationLocationCode,
    destinationLocationName: destinationLocationName,
    originAirportName: originAirportName,
    originLocationCode: originLocationCode,
    originLocationName: originLocationName,
    returnDate: returnDate,
    roomNum: roomNum,
    ttiCode: ttiCode,
  };
}

//获取参数 - 得到表单对象 - 更新pinia - end
//location  start
const loader = new Loader({
  apiKey: "AIzaSyCe_boE_sbAadOwLs0eOnmEExGlF0ZgLeQ",
  version: "weekly",
  libraries: ["places"],
});
let googleMap = reactive({
  google: null,
  map: null,
  markers: null,
  center: { lat: 25.0425, lng: 121.5468 },
});

watchEffect(() => {
  if (props.hotelViewItem) {
    initiateMap(props.hotelViewItem.latitude, props.hotelViewItem.longitude);
  }
});

// 地图
function initiateMap(latitude, longitude) {
  if (googleMap) {
    googleMap.center.lat = latitude;
    googleMap.center.lng = longitude;

    let center = googleMap.center;
    const mapOptions = {
      center: center,
      zoom: 12,
      disableDefaultUI: true,
    };
    loader.load().then((google) => {
      const map = new google.maps.Map(
        document.getElementById("googleMap"),
        mapOptions
      );
      new google.maps.Marker({
        position: center,
        map,
      });
    });
  }
}

//location  end

//hotel search
const state = reactive({
  checkInWeek: "",
  checkOutweek: "",
  day: dateUtils.format(new Date()),
});
state.checkInWeek = dateUtils.getWeek(
  packageHotelSearchFormModel.departureDate
);
state.checkOutWeek = dateUtils.getWeek(packageHotelSearchFormModel.returnDate);

/***
 * 根据改变的日期重新计算星期
 * @param checkInDate
 * @param checkOutDate
 */
function changeCheckInDate(checkInDate, checkOutDate) {
  let res = dateUtils.compareIfBefore(checkInDate, checkOutDate);
  if (res) {
    state.checkInWeek = dateUtils.getWeek(checkInDate);
    state.checkOutWeek = dateUtils.getWeek(checkOutDate);
  } else {
    packageHotelSearchFormModel.returnDate = dateUtils.getOffsetDate(
      new Date(checkInDate),
      1
    );
  }
}

function changeCheckOutDate(checkInDate, checkOutDate) {
  let res = dateUtils.compareIfBefore(checkInDate, checkOutDate);
  if (res) {
    state.checkInWeek = dateUtils.getWeek(checkInDate);
    state.checkOutWeek = dateUtils.getWeek(checkOutDate);
  } else {
    packageHotelSearchFormModel.departureDate = dateUtils.getOffsetDate(
      new Date(checkOutDate),
      -1
    );
  }
}

const reserveCondition = computed(() => {
  const checkOutDate = packageHotelSearchFormModel.returnDate;
  const checkInDate = packageHotelSearchFormModel.departureDate;

  const roomNum = packageRoomPassengersFormModel.value.length;
  const duration = dateUtils.getDaysByDateRange(checkInDate, checkOutDate);
  return {
    roomNum,
    duration,
  };
});

let isMenuShow = ref(false);
//校验和表单提交
const v = useVuelidate();

async function searchHotels() {
  const result = await v.value.$validate();
  isMenuShow.value = false;
  if (v.value.$errors && v.value.$errors.length > 0) {
    v.value.$errors.forEach((item) => {
      if (item.$property == "age") {
        isMenuShow.value = true;
      }
    });
  }
  if (!result) return;

  let roomNum = packageRoomPassengersFormModel.value.length;
  let adultNum = 0;
  let childAges = [];

  packageRoomPassengersFormModel.value.forEach((roomItem, roomIndex) => {
    adultNum += parseInt(roomItem.adultNum);
    if (roomItem.childAges && roomItem.childAges.length > 0) {
      roomItem.childAges.forEach((childAge) => {
        if (childAge.roomId == roomIndex) {
          let child = {
            roomId: roomIndex,
            age: childAge.age,
          };
          childAges.push(child);
        }
      });
    }
  });
  // console.info(childAges);
  packageHotelSearchFormModel.adultNum = adultNum / roomNum;
  packageHotelSearchFormModel.childNum = childAges.length / roomNum;
  packageHotelSearchFormModel.childAges = childAges;
  packageHotelSearchFormModel.roomNum = roomNum;

  //每次在详情页搜索之后改变query
  let packageSearchFormModelCopy = JSON.parse(
    JSON.stringify(packageHotelSearchFormModel)
  );
  // //首页跳转传参
  let format = formatChildAges(packageSearchFormModelCopy.childAges);
  packageSearchFormModelCopy.childAgesFormat = format;
  router.replace({ query: packageSearchFormModelCopy });
  sessionStorage.setItem(
    "packageSearchFormModel",
    JSON.stringify(packageHotelSearchFormModel)
  );
  searchHotelViewItemsByPackage(packageHotelSearchFormModel);
}

function formatChildAges(childAges) {
  let formatArr = [];
  childAges.forEach((childAge) => {
    let format = childAge.roomId + ":" + childAge.age;
    formatArr.push(format.toString());
  });
  return formatArr;
}

function sortRoomTypes(roomTypes) {
  //   //按照房间的价格升序展示
  roomTypes[Symbol.iterator] = function* () {
    yield* [...Object.values(this)].sort((a, b) => {
      return a.bestPrice - b.bestPrice;
    });
  };

  return roomTypes;
}
</script>

<template>
  <!-- Overview  -->
  <div
    class="tour_details_boxed"
    v-if="hotelViewItem && hotelViewItem.description"
  >
    <h3 class="heading_theme">{{ $t("overview") }}</h3>
    <div class="tour_details_boxed_inner" v-if="hotelViewItem">
      <p v-html="hotelViewItem.description"></p>
    </div>
  </div>
  <!-- End Overview -->

  <!-- Select your room-->
  <div class="tour_details_boxed" v-if="packageHotelSearchFormModel">
    <h3 class="heading_theme">{{ $t("select-your-room") }}</h3>
    <div class="room_select_area">
      <div class="tab-content" id="myTabContent">
        <div class="row">
          <div class="col-lg-12">
            <form action="javascript:void(0);">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="form_search_date">
                    <div class="search_boxed date_flex_area">
                      <div class="Journey_date">
                        <p>{{ $t("check-in-date") }}</p>
                        <input
                          type="date"
                          v-model="packageHotelSearchFormModel.departureDate"
                          @change="
                            changeCheckInDate(
                              packageHotelSearchFormModel.departureDate,
                              packageHotelSearchFormModel.returnDate
                            )
                          "
                          :min="state.day"
                        />
                        <span>{{ state.checkInWeek }}</span>
                      </div>

                      <div class="Journey_date">
                        <p>{{ $t("check-out-date") }}</p>
                        <input
                          type="date"
                          v-model="packageHotelSearchFormModel.returnDate"
                          @change="
                            changeCheckOutDate(
                              packageHotelSearchFormModel.departureDate,
                              packageHotelSearchFormModel.returnDate
                            )
                          "
                          :min="state.day"
                        />
                        <span>{{ state.checkOutWeek }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                  <BasePackagePassengersForm
                    v-if="cabinClassSelected && cabinClassSelected.code"
                    :is-menu-show="isMenuShow"
                    :room-passengers="packageRoomPassengersFormModel"
                    :cabin-class="cabinClassSelected"
                  />
                </div>

                <div class="col-lg-2 top_form_search_button">
                  <button
                    class="btn btn_theme"
                    type="button"
                    @click="searchHotels"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---  rooms -->
  <div class="row" v-if="hotelViewItem && hotelViewItem.roomTypes">
    <div
      v-for="(roomType, roomTypeKey, roomTypeIndex) in sortRoomTypes(
        hotelViewItem.roomTypes
      )"
      :key="roomTypeIndex"
      style="margin-top: 25px"
    >
      <div class="tour_details_boxed">
        <h3 class="heading_theme" v-if="roomType.roomTypeName">
          {{ roomType.roomTypeName }}
        </h3>
        <h3 v-else class="heading_theme">{{ $t("no-room-type-name") }}</h3>
        <table class="table table-hover">
          <BasePackageHotelRoomTypeItem
            :roomNum="reserveCondition.roomNum"
            :duration="reserveCondition.duration"
            :room-type-item="roomType"
            :hotel-view-item="hotelViewItem"
            :model-index="roomTypeKey"
          />
        </table>
      </div>
    </div>
  </div>
  <!-- Location  -->
  <div class="tour_details_boxed" v-if="hotelViewItem">
    <h3 class="heading_theme">{{ $t("hotel-location") }}</h3>
    <div class="map_area" v-if="hotelViewItem">
      <!--Map-->
      <div class="detail-map" id="googleMap" style="height: 300px"></div>
    </div>
  </div>
  <!-- End Location -->

  <!-- Amenity  -->
  <BaseHotelAmenityGroup
    v-if="
      hotelViewItem &&
      hotelViewItem.facilities &&
      hotelViewItem.facilities.length > 0
    "
  />
  <!-- End Amenity -->

  <!-- Policy  -->
  <BaseHotelPolicyGroup v-if="hotelViewItem && hotelViewItem.propTextContent" />
  <!-- End Policy -->
</template>

<style scoped></style>

import { CarRentalCoverageItemVO } from "@/viewobject/sales/carrental/carRentalCoverageItemVO";
import { CarRentalEquipItemVO } from "@/viewobject/sales/carrental/carRentalEquipItemVO";
import { CarRentalItemFareVO } from "@/viewobject/sales/carrental/carRentalItemFareVO";

export class CarRentalItemVO {
  /**
   *
   * @param pictureURL
   * @param vehName
   * @param currencyCode
   * @param totalAmount
   * @param airConditionInd
   * @param transmissionType
   * @param fuelType
   * @param doorCount
   * @param passengerQuantity
   * @param baggageQuantity
   * @param classCode
   * @param className
   * @param minimumDriverAge
   * @param quoteID
   * @param rateQualifier  里程限制
   * @param fees
   * @param equipments
   * @param insurances
   * @param charges
   * @param confIDs
   * @param driver
   * @param pickUpDateTime
   * @param returnDateTime
   * @param pickUpLocation
   * @param returnLocation
   * @param origNetPrice
   */
  constructor(
    pictureURL,
    vehName,
    currencyCode,
    totalAmount,
    airConditionInd,
    transmissionType,
    fuelType,
    doorCount,
    passengerQuantity,
    baggageQuantity,
    classCode,
    className,
    minimumDriverAge,
    quoteID,
    rateQualifier,
    fees,
    equipments,
    insurances,
    charges,
    confIDs,
    driver,
    pickUpDateTime,
    returnDateTime,
    pickUpLocation,
    returnLocation,
    origNetPrice
  ) {
    this.pictureURL = pictureURL;
    this.vehName = vehName;
    this.currencyCode = currencyCode;
    this.totalAmount = totalAmount;
    this.airConditionInd = airConditionInd;
    this.transmissionType = transmissionType;
    this.fuelType = fuelType;
    this.doorCount = doorCount;
    this.passengerQuantity = passengerQuantity;
    this.baggageQuantity = baggageQuantity;
    this.classCode = classCode;
    this.className = className;
    this.minimumDriverAge = minimumDriverAge;
    this.quoteID = quoteID;
    this.rateQualifier = rateQualifier;
    this.equipments = equipments;
    this.insurances = insurances;
    this.charges = charges;
    this.confIDs = confIDs;
    this.driver = driver;
    this.pickUpDateTime = pickUpDateTime;
    this.returnDateTime = returnDateTime;
    this.pickUpLocation = pickUpLocation;
    this.returnLocation = returnLocation;
    this.origNetPrice = origNetPrice;
  }

  addNewInsurance(
    included,
    selected,
    mandatory,
    code,
    description,
    amount,
    currency
  ) {
    let carRentalCoverageItemVO = new CarRentalCoverageItemVO(
      included,
      selected,
      mandatory,
      code,
      description,
      amount,
      currency
    );
    if (this.insurances == null) this.insurances = [];
    this.insurances.push(carRentalCoverageItemVO);
    return carRentalCoverageItemVO;
  }

  addNewEquipment(
    mandatory,
    selected,
    name,
    code,
    chargeType,
    amount,
    currency,
    quantity
  ) {
    let carRentalEquipItemVO = new CarRentalEquipItemVO(
      mandatory,
      selected,
      name,
      code,
      chargeType,
      amount,
      currency,
      quantity
    );
    if (this.equipments == null) this.equipments = [];
    this.equipments.push(carRentalEquipItemVO);
    return carRentalEquipItemVO;
  }

  addNewCharge(description, amount, currency, taxInclusive, taxAmounts) {
    let carRentalItemFareVO = new CarRentalItemFareVO(
      description,
      amount,
      currency,
      taxInclusive,
      taxAmounts
    );
    if (this.charges == null) this.charges = [];
    this.charges.push(carRentalItemFareVO);
    return carRentalItemFareVO;
  }
}

export class FlightReshopAddItemVO {
  /**
   *
   * @param originalItem FlightReshopFareItemVO对象，reshop前的对象的原价
   * @param newItem FlightReshopFareItemVO对象，reshop后的新对象和价格，包括关联的航班和乘客
   * @param penaltyAmount FlightReshopAmountVO对象，reshop罚款金额
   * @param feesAmount FlightReshopAmountVO，reshop需要支付的总费用，包括罚款金额
   */
  constructor(originalItem, newItem, penaltyAmount, feesAmount) {
    this.originalItem = originalItem;
    this.newItem = newItem;
    this.penaltyAmount = penaltyAmount;
    this.feesAmount = feesAmount;
  }
}

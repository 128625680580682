<script setup>
import { reactive, ref, watch, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { useCmsContentStore } from "@/stores/cms";
import { useBookingStore } from "@/stores/booking";

const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const bookingStore = useBookingStore();

const recordLocator = route.query.recordLocator;
const idContext = route.query.idContext;
const tripAirItemId = route.query.tripAirItemId;
const tripIdRef = ref(
  route.query.bookingId ? route.query.bookingId : route.query.tripId
);

const bookingDetailsViewItemRef = ref(null);

if (tripIdRef.value) {
  syncSessionAndSearch(tripIdRef.value);
}

async function syncSessionAndSearch(id) {
  templateStore.pageLoader({ mode: "on" });
  let condition = {
    type: "BOOKING_ID",
    id: id,
    limit: 1,
    offset: 0,
  };
  await bookingStore.searchBookingDetails(condition);
  templateStore.pageLoader({ mode: "off" });
}

watch(
  () => bookingStore.bookingDetailsViewItem,
  (newValue) => {
    bookingDetailsViewItemRef.value = newValue;
  },
  { immediate: true, deep: true }
);

const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContent = cmsContentStore.productPageBackgroundImage;
  imageUrl.value =
    cmsContentStore.productPageBackgroundImage &&
    cmsContentStore.productPageBackgroundImage.bookingSuccessBackgroundImage
      ? cmsContentStore.productPageBackgroundImage.bookingSuccessBackgroundImage
          .url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});

function viewBookingDetails(bookingViewItem) {
  let query = {
    tripItemType: null,
    tripId: null,
    isFromTripList: true,
  };
  if (bookingViewItem) {
    query.tripItemType =
      bookingViewItem.productType === "Flight"
        ? "AIR"
        : bookingViewItem.productType === "Hotel"
        ? "HOTEL"
        : bookingViewItem.productType === "Tour Activity"
        ? "ACTIVITY"
        : bookingViewItem.productType === "Car Rental"
        ? "CARRENTAL"
        : bookingViewItem.productType;
    if (query.tripItemType === "AIR") {
      const recordLocators = bookingViewItem.recordLocators;
      if (recordLocators && recordLocators.length > 0) {
        const recordLocatorItem = recordLocators.find(
          (item) => item.type === "RECORD_LOCATOR"
        );
        if (recordLocatorItem) {
          query.recordLocator = recordLocatorItem.id;
          query.idContext = recordLocatorItem.id_Context;
        }
      }
    }
    query.tripId = bookingViewItem.bookingId;
  }
  router.push({
    name: "sales-v2-booking-details",
    query: query,
  });
}
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("booking-success") }}</h2>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row margin-top-20">
        <div class="col-lg-8" style="margin-left: 15%">
          <div class="tou_booking_form_Wrapper">
            <div class="tour_booking_form_box mb-4">
              <div class="booking_success_arae">
                <div class="booking_success_img">
                  <img alt="img" src="@/assets/img/icon/right.png" />
                </div>
                <div class="booking_success_text">
                  <h3>
                    {{ $t("booking-confirmed", { bookingId: tripIdRef }) }}
                  </h3>
                  <h6>
                    {{ $t("check-email-info") }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <a class="btn btn_theme mg-r-8" @click="router.push('/')">{{
            $t("go-to-home")
          }}</a>
          <a
            class="btn btn_theme"
            @click="viewBookingDetails(bookingDetailsViewItemRef)"
            >{{ $t("view-your-booking") }}</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

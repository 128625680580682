<script setup>
import useVuelidate from "@vuelidate/core";
import { ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import HotelAvailRoomPassengerFormItem from "@/views/v2/sales/hotel/items/form/availability/HotelAvailRoomPassengerFormItem.vue";

const props = defineProps({
  hotelAvailFormModel: {},
  childAgesValidCheck: {},
  equipmentType: {},
});
const { t, locale } = useI18n(); // 解构调用函数

const hotelAvailFormModelRef = ref(null);
const roomPassengerShowRef = ref(null);
const isRoomPassengersFormCorrectRef = ref(true);
watch(
  () => props.hotelAvailFormModel,
  (newValue) => {
    hotelAvailFormModelRef.value = newValue;

    if (
      newValue &&
      newValue.roomPassengers &&
      newValue.roomPassengers.length > 0
    ) {
      const roomCount = newValue.roomPassengers.length;
      let travelersNumber = 0;
      newValue.roomPassengers.forEach((roomPassenger) => {
        travelersNumber +=
          parseInt(roomPassenger.adultNum) + parseInt(roomPassenger.childNum);
      });

      roomPassengerShowRef.value = t("travel-room-num", {
        travelersNumber: travelersNumber,
        roomCount: roomCount,
      });
    }
  },
  { immediate: true, deep: true }
);

//当用户未选择儿童岁数时，要主动校验，让输入框显示错误
watchEffect(() => {
  let formElement = document.getElementById("roomPassengerForm");
  if (props.childAgesValidCheck && formElement) {
    formElement.classList.add("show");
  } else if (!props.childAgesValidCheck && formElement) {
    formElement.classList.remove("show");
  }
});

//添加房间
function addRoomPassenger(hotelAvailFormModel) {
  hotelAvailFormModel.addNewRoomPassenger();
}

//删除房间
function removeRoomPassengers(roomIndex) {
  hotelAvailFormModelRef.value.removeRoomPassenger(roomIndex);
}

//提交房间信息表单
const v = useVuelidate();

async function submitRoomPassengersForm() {
  //在父组件中调用，则会校验所有子组件的规则，并统一返回校验结果
  isRoomPassengersFormCorrectRef.value = await v.value.$validate();
  if (!isRoomPassengersFormCorrectRef.value) return;
  let formElement = document.getElementById("roomPassengerForm");
  formElement.classList.remove("show");
}
</script>

<template>
  <div class="search_boxed dropdown_passenger_area">
    <p>{{ $t("guests") }}, {{ $t("room") }}</p>
    <div class="dropdown">
      <button
        class="dropdown-toggle final-count"
        data-toggle="dropdown"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
      >
        {{ roomPassengerShowRef }}
      </button>

      <div
        class="dropdown-menu dropdown_passenger_info fs-sm dropdown-menu-400"
        aria-labelledby="dropdownMenuButton1"
        id="roomPassengerForm"
      >
        <div class="traveller-calulate-persons">
          <div class="passengers">
            <form class="p-2" @submit.prevent>
              <div
                v-for="(
                  roomPassenger, roomPassengerIndex
                ) in hotelAvailFormModelRef.roomPassengers"
                :key="roomPassengerIndex"
              >
                <HotelAvailRoomPassengerFormItem
                  :room-index="roomPassengerIndex"
                  :room-passenger-form-item-model="roomPassenger"
                  @removeRoomPassengers="removeRoomPassengers"
                />
              </div>
              <a class="d-flex justify-content-sm-end"
                ><span
                  style="font-size: 16px"
                  class="cursor-point"
                  @click="addRoomPassenger(hotelAvailFormModelRef)"
                  >{{ $t("add-another-room") }}</span
                ></a
              >
              <div class="text-center">
                <button
                  type="submit"
                  class="btn col-11"
                  @click="submitRoomPassengersForm"
                >
                  {{ $t("done") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <span> {{ roomPassengerShowRef.split(",")[1] }}</span>
  </div>
</template>

<style scoped></style>

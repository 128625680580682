/*
 * Main and demo navigation arrays
 *
 * 'to' attribute points to the route name, not the path url
 */

export default {
    cabin_en: [
        {code: "Y", name: "Economy(Y)"},
        {code: "W", name: "Premium Economy(W)"},
        {code: "F", name: "First(F)"},
        {code: "C", name: "Business(C)"},
    ],
    cabin_zh: [
        {code: "Y", name: "经济舱(Y)"},
        {code: "W", name: "豪华经济舱(W)"},
        {code: "F", name: "头等舱(F)"},
        {code: "C", name: "商务舱(C)"},
    ],
    cabin_tw: [
        {code: "Y", name: "經濟艙(Y)"},
        {code: "W", name: "豪華經濟艙(W)"},
        {code: "F", name: "頭等艙(F)"},
        {code: "C", name: "商務艙(C)"},
    ],
    cabin_ja: [
        {code: "Y", name: "エコノミークラス(Y)"},
        {code: "W", name: "エコノミークラスです(W)"},
        {code: "F", name: "ファ—ストクラス(F)"},
        {code: "C", name: "ビジネスクラス(C)"},
    ],
    cabin_ko: [
        {code: "Y", name: "이코노미 클래스(Y)"},
        {code: "W", name: "디럭스 이코노미석(W)"},
        {code: "F", name: "일등 선실(F)"},
        {code: "C", name: "비즈니스 클래스(C)"},
    ],
    cabin_fr: [
        {code: "Y", name: "Classe économique(Y)"},
        {code: "W", name: "Luxe classe économique(W)"},
        {code: "F", name: "Première classe(F)"},
        {code: "C", name: "Classe affaires(C)"},
    ],
};

<template>
  <section id="cta_area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="cta_left">
            <div class="cta_icon">
              <img src="../../../assets/img/common/email.png" alt="icon" />
            </div>
            <div class="cta_content">
              <h4>{{ $t("get-latest-news-offers") }}</h4>
              <h2>{{ $t("subscribe-our-newsletter") }}</h2>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="cat_form">
            <form id="cta_form_wrappper">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your mail address"
                />
                <button class="btn btn_theme" type="button">
                  {{ $t("subscribe") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Cta",
};
</script>

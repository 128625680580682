export class TripCarRentalCustomerItemVO {
  constructor(
    nameTitle,
    surname,
    givenName,
    phoneNumber,
    countryAccessCode,
    emailAddress,
    cityzenCountry
  ) {
    this.nameTitle = nameTitle;
    this.surname = surname;
    this.givenName = givenName;
    this.phoneNumber = phoneNumber;
    this.countryAccessCode = countryAccessCode;
    this.emailAddress = emailAddress;
    this.cityzenCountry = cityzenCountry;
  }
}

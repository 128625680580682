import axios from "../utils/axiosUtils";

/*
 * 用户登录
 * @param username string 必填，用户名
 * @param password string 必填，密码
 */
function signIn(username, password) {
  let formData = new URLSearchParams();
  formData.append("username", username);
  formData.append("password", password);

  let url = "/api/v3/security/login/authentication";
  return axios.post(url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

/*
 * 退出
 * */
function signOut() {
  let url = "/api/v3/security/userlogin/logout";
  return axios.post(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/***
 * 用户注册
 * @param createUserRQ
 * @returns {Promise<axios.AxiosResponse<any>>}
 */

function signUp(createUserRQ, domain) {
  let url = "/api/v3/party/profiles/profileCreate?callbackHostName=" + domain;
  return axios.post(url, createUserRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

/*
 * 获取服务器中登录状态，若为null，则登录时效以过期
 *
 * return object 登录者信息
 * */
async function getSessionInfo() {
  let url = "/api/v3/security/login/userlogins/session";
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

function forgetPassword(email, callbackHostName) {
  let url =
    "/api/v3/security/login/userlogins/forget-password?userLoginId=" +
    email +
    "&callbackHostName=" +
    callbackHostName;
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
function reactiveAccount(email, callbackHostName) {
  let url =
    "/api/v3/security/login/userlogins/reactivation?userLoginId=" +
    email +
    "&callbackHostName=" +
    callbackHostName;
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export default {
  signIn,
  signOut,
  signUp,
  getSessionInfo,
  forgetPassword,
  reactiveAccount,
};

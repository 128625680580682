export default [
  {
    code: "HOTEL",
    name: "hotels",
    to: "sales-hotel-home-v2",
    icon: "fas fa-hotel",
  },
  {
    code: "AIR",
    name: "flights",
    to: "sales-flight-home-v2",
    icon: "fas fa-plane-departure",
  },
  {
    code: "PACKAGE",
    name: "package-flight-hotel",
    to: "sales-dynamicPkg-home-v2",
    icon: "fas fa-globe",
  },
  {
    code: "VEHICLE",
    name: "car-rental",
    to: "sales-carrental-home-v2",
    icon: "fa-solid fa-car",
  },
  {
    code: "ACTIVITY",
    name: "tour-activity",
    to: "sales-activity-home-v2",
    icon: "fa-solid fa-ticket",
  },
];

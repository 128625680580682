export class HotelMealPlanVO {
    /**
     *
     * @param breakfast boolean，是否包含早餐
     * @param lunch boolean，是否包含午餐
     * @param dinner boolean，是否包含晚餐
     */
    constructor(breakfast, lunch, dinner) {
        this.breakfast = breakfast;
        this.lunch = lunch;
        this.dinner = dinner;
    }
}
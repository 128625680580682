<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  carRentalRatesQuoteFormModel: {},
  availableCoverages: {},
  protectionPlans: {},
  equipmentOptions: {},
});

const carRentalRatesQuoteFormModelRef = ref(null);
const activeProtectionPlanIndexRef = ref(0);
watch(
  () => props.carRentalRatesQuoteFormModel,
  (newValue) => {
    carRentalRatesQuoteFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function selectProtectionPlan(
  carRentalRatesQuoteFormModel,
  protectionPlan,
  protectionPlanIndex
) {
  activeProtectionPlanIndexRef.value = protectionPlanIndex;

  carRentalRatesQuoteFormModel.addNewProtectionPlan(protectionPlan);
}

function selectAvailableCoverage(carRentalRatesQuoteFormModel, insurance) {
  carRentalRatesQuoteFormModel.addNewInsurance(insurance);
}

function operateEquipment(carRentalRatesQuoteFormModel, equipment, type) {
  if (type === "ADD") {
    carRentalRatesQuoteFormModel.addNewEquipment(equipment);
  } else {
    if (
      carRentalRatesQuoteFormModel.selectedEquipments &&
      carRentalRatesQuoteFormModel.selectedEquipments.length > 0
    ) {
      const selectedEquipment =
        carRentalRatesQuoteFormModel.selectedEquipments.find(
          (item) => item.code === equipment.code
        );
      if (selectedEquipment.quantity > 0) selectedEquipment.quantity--;
    }
  }
}

function sortByPrice(equipments) {
  return equipments.sort((a, b) => a.amount - b.amount);
}

function filterSelectedAvailableCoverages(availableCoverages) {
  if (availableCoverages && availableCoverages.length > 0) {
    return availableCoverages.filter((insurance) => !insurance.selected);
  }
}

function isCoverageDisable(carRentalRatesQuoteFormModel, insurance) {
  const selectedInsurances = carRentalRatesQuoteFormModel.selectedInsurances;
  let disabled = false;
  if (selectedInsurances && selectedInsurances.length > 0) {
    disabled = selectedInsurances.some((item) => item.code === insurance.code);
  }

  return disabled;
}
</script>

<template>
  <!-- 保险计划 -->
  <div
    class="tour_details_boxed"
    v-if="protectionPlans && protectionPlans.length > 0"
  >
    <h3 class="heading_theme">Protection plans</h3>
    <div class="protection_plans">
      <div class="protection_plans_list">
        <div
          v-for="(protectionPlan, protectionPlanIndex) in protectionPlans"
          :key="protectionPlanIndex"
          :class="{
            protection_plans_active:
              protectionPlanIndex === activeProtectionPlanIndexRef,
          }"
          class="protection_plans_item cursor-point"
        >
          <div class="protection_type mg-b-10">
            <p class="h4 mb-2 mg-t-30">
              {{ protectionPlan.type }}
              <i
                v-for="(i, index) in protectionPlanIndex + 1"
                :key="index"
                class="fa fa-star me-1"
              ></i>
            </p>

            <p class="h5 mb-2">
              {{
                protectionPlan.type === "BASIC"
                  ? $t("included")
                  : protectionPlan.currency +
                    " " +
                    protectionPlan.totalAmount +
                    "/" +
                    "day"
              }}
            </p>

            <div
              class="ul_bott mg-t-5"
              v-if="
                protectionPlan &&
                protectionPlan.insuranceItems &&
                protectionPlan.insuranceItems.length > 0
              "
            >
              <span
                v-for="(insurance, index) in protectionPlan.insuranceItems"
                :key="index"
                ><i class="bi bi-check-lg main_color"></i>
                {{ insurance.description }}</span
              >
            </div>

            <p class="mg-t-5 protections_img">
              <button
                :disabled="protectionPlanIndex === activeProtectionPlanIndexRef"
                class="btn btn_theme"
                @click="
                  selectProtectionPlan(
                    carRentalRatesQuoteFormModelRef,
                    protectionPlan,
                    protectionPlanIndex
                  )
                "
              >
                {{ $t("select") }}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 保险计划END -->

  <!--    可购买的额外保险    -->
  <div
    class="tour_details_boxed"
    v-if="availableCoverages && availableCoverages.length > 0"
  >
    <h3 class="heading_theme">Protections</h3>
    <table class="table table-hover table-borderless table-vcenter fs-sm mb-0">
      <thead>
        <tr class="text-uppercase">
          <th>{{ $t("name") }}</th>
          <th>{{ $t("total-amount") }}</th>
          <th class="fw-bold">{{ $t("purchase") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(insurance, insuranceIndex) in sortByPrice(
            filterSelectedAvailableCoverages(availableCoverages)
          )"
          :key="insuranceIndex"
        >
          <td width="40%">
            <span>{{ insurance.description }}</span>
          </td>
          <td>
            <span
              :class="{
                'text-success': insurance.amount < 1000,
                'text-warning':
                  insurance.amount <= 3000 && insurance.amount >= 1000,
                'text-danger': insurance.amount > 3000,
              }"
              >{{ insurance.currency }} {{ insurance.amount }}</span
            >
          </td>
          <td>
            <button
              class="btn btn_theme"
              type="button"
              :disabled="
                isCoverageDisable(carRentalRatesQuoteFormModelRef, insurance)
              "
              @click="
                selectAvailableCoverage(
                  carRentalRatesQuoteFormModelRef,
                  insurance
                )
              "
            >
              {{ $t("select") }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!--    可购买的额外保险END    -->

  <!--    可购买的设备    -->
  <div
    class="tour_details_boxed"
    v-if="equipmentOptions && equipmentOptions.length > 0"
  >
    <h3 class="heading_theme">Available extras</h3>
    <table class="table table-hover table-borderless table-vcenter fs-sm mb-0">
      <thead>
        <tr class="text-uppercase">
          <th>{{ $t("name") }}</th>
          <th>{{ $t("total-amount") }}</th>
          <th class="fw-bold">{{ $t("purchase") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(equipment, equipmentIndex) in sortByPrice(equipmentOptions)"
          :key="equipmentIndex"
        >
          <td width="40%">
            {{ equipment.name }}
          </td>
          <td>
            <span
              :class="{
                'text-success': equipment.amount < 1000,
                'text-warning':
                  equipment.amount <= 3000 && equipment.amount >= 1000,
                'text-danger': equipment.amount > 3000,
              }"
              >{{ equipment.currency }} {{ equipment.amount }}</span
            >
          </td>
          <td>
            <div
              class="flex align-items-center"
              v-if="equipment.chargeType === 'DAY'"
            >
              <button
                class="btn btn_theme"
                type="button"
                :disabled="equipment.quantity <= 0"
                @click="
                  operateEquipment(
                    carRentalRatesQuoteFormModelRef,
                    equipment,
                    'DEC'
                  )
                "
              >
                -
              </button>
              <span class="mg-l-5 mg-r-5"> {{ equipment.quantity }}</span>
              <button
                class="btn btn_theme"
                type="button"
                @click="
                  operateEquipment(
                    carRentalRatesQuoteFormModelRef,
                    equipment,
                    'ADD'
                  )
                "
              >
                +
              </button>
            </div>
            <div class="flex align-items-center" v-else>
              <button
                class="btn btn_theme"
                :class="{
                  'btn-alt-danger': equipment.quantity > 0,
                  'btn-alt-secondary': equipment.quantity === 0,
                }"
                type="button"
                :disabled="equipment.quantity <= 0"
                @click="
                  operateEquipment(
                    carRentalRatesQuoteFormModelRef,
                    equipment,
                    'ADD'
                  )
                "
              >
                {{ $t("add") }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!--    可购买的设备END    -->
</template>

<style scoped lang="scss"></style>

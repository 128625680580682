<script setup>
import ForgetPasswordForm from "@/views/auth/items/ForgetPasswordForm.vue";
import { useCmsContentStore } from "@/stores/cms";
import { reactive, ref, watchEffect } from "vue";

const cmsContentStore = useCmsContentStore();
cmsContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const baseUrl = "/api/v3/content/collections/images";

let imageUrl = ref("");
let backgroundImageUrl = ref("");
watchEffect(() => {
  bannerContent = cmsContentStore.productPageBackgroundImage;
  imageUrl.value =
    cmsContentStore.productPageBackgroundImage &&
    cmsContentStore.productPageBackgroundImage.forgetPasswordBackgroundImage
      ? cmsContentStore.productPageBackgroundImage.forgetPasswordBackgroundImage
          .url
      : "";
  backgroundImageUrl.value = baseUrl + imageUrl.value;
});
</script>

<template>
  <!--   Banner Area -->
  <section
    id="page_banner"
    :style="{
      backgroundImage: 'url(' + backgroundImageUrl + ')',
    }"
  >
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("reset-password") }}</h2>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="common_author_area" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3 offset-lg-2">
          <div class="header_back">
            <a @click="$router.back">
              <i class="bi bi-arrow-left-square back_icon"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="common_author_boxed">
            <div class="common_author_heading">
              <h3>{{ $t("forgot-password") }}</h3>
              <h2>{{ $t("reset-password") }}</h2>
            </div>
            <div class="common_author_form">
              <ForgetPasswordForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

<script setup>
import { ref } from "vue";

const emits = defineEmits(["sorting"]);

let sortingOptionRef = ref("");
function selectSorting() {
  emits("sorting", sortingOptionRef.value);
}
</script>

<template>
  <div class="form-floating mb-4">
    <select
      class="form-select"
      id="example-select-floating"
      name="example-select-floating"
      v-model="sortingOptionRef"
      @change="selectSorting"
      aria-label="Floating label select example"
    >
      <option value="" selected>{{ $t("recommended") }}</option>
      <option value="PRICE-ASC">{{ $t("price-low-to-high") }}</option>
      <option value="PRICE-DSC">{{ $t("price-high-to-low") }}</option>
      <option value="GUEST_RATING-DSC">
        {{ $t("guest-rating-our-picks") }}
      </option>
      <option value="STAR_RATING-DSC">{{ $t("star-rating") }}</option>
    </select>
    <label for="example-select-floating"> {{ $t("sort-by") }}</label>
  </div>
</template>

<style scoped></style>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { reactive, ref, watch } from "vue";
import { useCmsContentStore } from "@/stores/cms";
import { Modal } from "ant-design-vue";
import BackButton from "@/components/custom/BackButton.vue";
import Banner from "@/components/custom/Banner.vue";
import { useI18n } from "vue-i18n";
import { useTripFlightStore } from "@/stores/v2/trip/flight/tripFlightStore";
import { useFlightAncillaryServiceStore } from "@/stores/v2/flight/ancillaryservice/flightAncillaryServiceStore";
import { useAirContentStore } from "@/stores/v2/content/airContentStore";
import { FlightRepriceFormModel } from "@/formmodel/sales/flight/price/flightRepriceFormModel";
import { FlightAncillaryServiceFormModel } from "@/formmodel/sales/flight/ancillaryservice/flightAncillaryServiceFormModel";
import FlightChangeSeatSelectionPanel from "@/views/v2/trip/servicing/items/panel/FlightChangeSeatSelectionPanel.vue";

const { t, locale } = useI18n(); // 解构调用函数

const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const tripFlightStore = useTripFlightStore();
const ancillaryServiceStore = useFlightAncillaryServiceStore();
const airContentStore = useAirContentStore();
airContentStore.findAircraftsMap();
airContentStore.findAirlineMap();

const recordLocator = route.query.recordLocator;
const idContext = route.query.idContext;
const tripId = route.query.tripId;
const isFromTripList = route.query.isFromTripList
  ? JSON.parse(route.query.isFromTripList)
  : false;

const errorServerMessageRef = ref(null);

const tripAirItemRef = ref(null);
const airItineraryRPH = ref(null);
const passengersRef = ref(null);

const flightRePriceFormModelRef = new FlightRepriceFormModel(
  "REPRICE",
  null,
  recordLocator,
  "RECORD_LOCATOR",
  idContext,
  passengersRef.value,
  null,
  null,
  null
);

resRetrieveAndGetOffer(recordLocator, idContext);

//region 背景
const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const AModal = Modal;
const navigation = reactive({
  title: "Manage Seat Selection",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Manage Seat Selection",
    },
  ],
});
//endregion

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgroundImage) {
      const backgoundImage = newValue.backgroundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

//region设备
let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
//endregion

watch(
  () => tripFlightStore.tripAirItem,
  (newValue) => {
    tripAirItemRef.value = newValue;
    if (tripAirItemRef.value) {
      airItineraryRPH.value = tripAirItemRef.value.airItineraryRPH
        ? tripAirItemRef.value.airItineraryRPH
        : null;

      if (tripAirItemRef.value.passengers) {
        passengersRef.value = tripAirItemRef.value.passengers;
      }
    }
  },
  { deep: true, immediate: true }
);

//region 辅助函数

function resRetrieveAndGetOffer(recordLocator, idContext) {
  templateStore.pageLoader({ mode: "on" });
  buildPromises(recordLocator, idContext)
    .then((res) => {
      if (res && res.length > 0) {
        const retrieveRS = res[1];
        if (retrieveRS && !retrieveRS.success) {
          let error = "";
          if (
            retrieveRS.errors &&
            retrieveRS.errors.errors &&
            retrieveRS.errors.errors.length > 0
          ) {
            error = retrieveRS.errors.errors[0].stringValue;
          }
          errorServerMessageRef.value = error ? error : "Sever error!";
        }
      }

      templateStore.pageLoader({ mode: "off" });
    })
    .catch((err) => {
      templateStore.pageLoader({ mode: "off" });
    });
}

function buildPromises(recordLocator, idContext) {
  const promises = [];
  const tripFlightRSP = tripFlightStore.retrieve(
    recordLocator,
    "RECORD_LOCATOR",
    idContext
  );

  const flightAncillaryServiceFormModel = new FlightAncillaryServiceFormModel(
    null,
    null,
    recordLocator,
    "RECORD_LOCATOR",
    idContext
  );

  const offerRSP = ancillaryServiceStore.airGetOffers(
    flightAncillaryServiceFormModel
  );

  promises.push(tripFlightRSP, offerRSP);
  return Promise.all(promises);
}

function goBack() {
  const query = route.query;
  router.push({
    name: "sales-v2-booking-details",
    query: query,
  });
}
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row" v-if="equipmentType !== 'iphone'">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <BackButton
            :button-name="'Back to booking details'"
            @goBack="goBack"
          ></BackButton>
        </div>
      </div>

      <FlightChangeSeatSelectionPanel
        :passengers="passengersRef"
        :trip-air-item="tripAirItemRef"
        :flight-re-price-form-model="flightRePriceFormModelRef"
        :id-context="idContext"
        :is-from-trip-list="isFromTripList"
        :record-locator="recordLocator"
        :trip-id="tripId"
      />
    </div>
  </section>
</template>

<style scoped></style>

<script setup>
import BaseFlightPassengersFormItem from "@/views/sales/flight/items/form/BaseFlightPassengersFormItem.vue";
import { computed, reactive, ref } from "vue";
import contentApi from "@/apis/contentApi";

defineProps({
  formModel: {
    type: Object,
  },
});

const passengerFormItemRef = ref(null);
//获取所有的航空公司联盟
let frequentFlyerProgramOption = ref([]);
frequentFlyerProgramOption.value.push({
  programID: null,
  programName: "Choose Frequent Flyer Airline(option)",
});
contentApi.frequentFlyerPrograms().then((res) => {
  if (res.data && res.data.length > 0) {
    res.data.sort(function (a, b) {
      if (a.programName < b.programName) {
        return -1;
      }
      if (a.programName > b.programName) {
        return 1;
      }
      return 0;
    });
    frequentFlyerProgramOption.value.push(...res.data);

    sessionStorage.setItem(
      "frequentFlyerProgram",
      JSON.stringify(frequentFlyerProgramOption.value)
    );
  }
});

const validatePhoneAndPassport = () => {
  passengerFormItemRef.value.forEach((ele) => {
    ele.validateAllCustom();
  });
};

defineExpose({
  validatePhoneAndPassport,
});
</script>
<template>
  <form action="!#" id="tour_bookking_form_item">
    <BaseFlightPassengersFormItem
      class="margin-bottom-20"
      ref="passengerFormItemRef"
      v-for="(passenger, passengerIndex) in formModel.passengers"
      :key="passengerIndex"
      :passenger-index="passengerIndex + 1"
      :passenger-model="passenger"
      :frequent-flyer-programs="frequentFlyerProgramOption"
    />
  </form>
</template>

<style scoped></style>
